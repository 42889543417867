<div class="row mb-4 pb-4" *ngIf="dispError">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <ul>
      <li title="error" class="msg-error" rel="tooltip" data-alert>{{resourceBundle.errorPleaseTryAgain}}</li>
    </ul>
  </div>
</div>
<div *ngIf="!dispError">
  <router-outlet></router-outlet>
</div>
