import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, CBHelperService, Cookie } from 'common-ui-lib';
import { CookieDomain } from 'common-ui-lib/lib/constants/domain.model';
import { CBLoaderService } from 'common-ui-lib/lib/shared/cbloader/cbloader.service';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorageService } from 'ngx-webstorage';
import { PostLoginService } from './postlogin.service';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from 'common-ui-lib';
@Component({
  selector: 'app-postlogin',
  templateUrl: './postlogin.component.html',
  styleUrls: ['./postlogin.component.scss'],
})
export class PostloginComponent implements OnInit, OnDestroy {
  private oktaSessionId: string;

  constructor(
    private spinnerService: CBLoaderService,
    @Inject(OKTA_AUTH) private okta: OktaAuth,
    private activatedRouter: ActivatedRoute,
    private cookieService: CookieService,
    private sessionStorage: SessionStorageService,
    private cbHelper: CBHelperService,
    private router: Router,
    private postLoginService: PostLoginService,
    private config: AppConfig
  ) {
    this.spinnerService.show();
    // console.log("OKTAFLOW-LoginService Cons...");
  }

  ngOnInit() {
    // console.log("OKTAFLOW-PostloginComponent Init");
    this.oktaSessionId = this.activatedRouter.snapshot.queryParams['sid'];
    if (this.oktaSessionId) {
      this.main();
    } else {
      this.processInit();
    }
  }
  async processInit() {
    const authenticated = await this.okta.isAuthenticated();
    if (authenticated) {
      // console.log("OKTAFLOW-PostloginComponent Already LOGGED IN - ");
      if (this.sessionStorage.retrieve('isSwithUser') || this.sessionStorage.retrieve('isEditUser')) {
        if (this.sessionStorage.retrieve('isSwithUser')) {
          this.sessionStorage.clear('isSwithUser');
        }
        return;
      } else if (this.cbHelper.isMyAdmin()) {
        this.router.navigate(['/csradmin/start']);
      } else {
        this.router.navigate(['/home/landing']);
      }
    }
  }

  private async main() {
    const user = await this.cbHelper.getAccessTokenSub();
    const referrer = await this.okta.getOriginalUri();
    //const referrer = await this.okta.getFromUri();
    this.setCBMACookie(user);
    this.setCoxdotcomCookie(user);
    this.postLoginService.setCbccCookie();
    this.postLoginService.redirect(true, referrer);
  }
  private setCBMACookie(user: string) {
    const domain = new CookieDomain();
    const attributes = this.cbHelper.isLocal() ? ";path=/;" : `;path=/;domain=${domain.cbma};SameSite=None;secure`;
    this.setCookie(`${this.config.getConfig("cookies")["currentUserSessionId"]}=${user}++${this.oktaSessionId}${attributes}`);
    this.setCookie(`${this.config.getConfig("cookies")["currentUser"]}=${user}${attributes}`);
  }
  private setCoxdotcomCookie(user) {
    const domain = new CookieDomain();
    const attributes = this.cbHelper.isLocal() ? ';path=/;' : `;path=/;domain=${domain.coxdotcom};SameSite=None;secure`;
    const userRole = domain.cbma.includes('myaccount') ? 'USER' : 'CSR';
    const rememberMe = this.cookieService.get('ln');
    rememberMe
      ? this.setCookie(`${this.config.getConfig("cookies")["rememberMe"]}=${this.cbHelper.encodeBase64(user)}${attributes};max-age=14400;`)
      : this.cookieService.delete("cox-cb-rememberme-user", attributes);
    this.setCookie(`${this.config.getConfig("coxdotcom")["cbCurrentUserCookieKey"]}=${user}${attributes}`);
    this.setCookie(`${this.config.getConfig("cookies")["CBSID"]}=${this.getEncryptedEncodedValue(this.oktaSessionId)}${attributes}`);
    this.setCookie(`${this.config.getConfig("cookies")["CBAT"]}=${this.cbHelper.encryptByCrypto(this.cbHelper.oktaAccessToken)}${attributes}`);
    this.setCookie(`${this.config.getConfig("cookies")["oktaSessionId"]}=${this.oktaSessionId}${attributes}`);
    this.setCookie(`${this.config.getConfig("cookies")["userRole"]}=${userRole}${attributes}`);
  }
  private getEncryptedEncodedValue(value) {
    value = this.cbHelper.encryptByCrypto(value);
    value = this.cbHelper.encodeBase64(value);
    return value;
  }
  private setCookie(cookie: string): void {
    document.cookie = cookie;
  }

  ngOnDestroy(): void {
    this.spinnerService.hide();
  }
}
