import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, NavigationStart, Router, UrlTree } from '@angular/router';
import { AppConfig, CBHelperService, LocalStorageKeys, NewRelicService, RefreshTokenClient,
  TealiumBuilderService, TealiumUtagService } from 'common-ui-lib';
import { HeaderFooterEnum } from 'common-ui-lib/lib/services/cbhelper.service';
import { OnboardingService } from 'onboarding/onboarding.service';
import { interval, Subscription } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { LoginProfileClient } from 'common-ui-lib';
import { PostLoginService } from 'boot/postlogin/postlogin.service';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'app-boot',
  templateUrl: './boot.component.html',
})
export class BootComponent implements OnInit {
  public link: string;
  public hideHeader = false;
  public hideFooter = false;
  public isCappPage = false;
  public viewFullScreen = false;
  private adobeFuncCalled = false;
  private renewToken = true;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public cbHelper: CBHelperService,
    private onboardingService: OnboardingService,
    public service: PostLoginService,
    private loginProfileClientService: LoginProfileClient,
    private tealiumbuilder: TealiumBuilderService,
    private tealiumService: TealiumUtagService,
    private config: AppConfig,
    private newRelicService: NewRelicService,
    private _storageService: LocalStorageService,
    private keepAliveService: RefreshTokenClient
  ) {
    console.log('OKTATIME - BootComponent cons Myaccount Application started loading', new Date().getTime());
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const urlTree: UrlTree = this.router.parseUrl(event.url);
        if (urlTree.root.children.primary &&
          urlTree.root.children.primary.segments &&
          urlTree.root.children.primary.segments[0].path === 'cbma'
        ) {
          urlTree.root.children.primary.segments.splice(0, 1);
          this.router.navigateByUrl(urlTree);
        }
      });

    //comment for CBMA1-64059
    this.renewToken = this._storageService.get(LocalStorageKeys.RenewAuthtoken, 'cbma');
    if (this.renewToken) {
      this.keepAliveService.getRefreshToken()
        .subscribe((data: any) => {
          this._storageService.remove(LocalStorageKeys.RenewAuthtoken, 'cbma');
        });
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event: Data) => {
        this.cbHelper.processCredentials();
        if (!this.cbHelper.isMyAdmin() && this.cbHelper.isOBUrl() && !this.cbHelper.isHNScenario) {
          console.log('OKTAFLOW-myaccount non HN OB Flow');
        } else {
          this.cbHelper._hnScenarioObserver.next(this.cbHelper.isHNScenario);
        }
        this.handleUtag(event);
        this.link = '';
        this.isCappPage = window.location.href.includes('/home/spm');
        this.viewFullScreen = event?.useFullScreen;
        this.getSkipLink(window.location.href);
      });
  }

  public ngOnInit(): void {
    this.cbHelper.HNScenario.subscribe((isHn: boolean) => {
      this.hideAEMHeader(isHn);
    });
    if (
      !this.cbHelper.isHNScenario &&
      this.cbHelper.isOBUrl() &&
      !this.cbHelper.isMyAdmin() &&
      this.onboardingService.accountFlow === 'createprofile'
    ) {
      this.hideAEMHeader(true);
    }
    this.cbHelper.hideHeaderFooterSub.subscribe((data) => {
      switch (data) {
        case HeaderFooterEnum.BOTH:
          this.hideHeader = this.hideFooter = true;
          break;
        case HeaderFooterEnum.NONE:
          this.hideHeader = this.hideFooter = false;
          break;
        case HeaderFooterEnum.FOOTER:
          this.hideHeader = false;
          this.hideFooter = true;
          break;
        case HeaderFooterEnum.HEADER:
          this.hideHeader = true;
          this.hideFooter = false;
          break;
        default:
          break;
      }
    });
  }

  public hideAEMHeader(hidehn: boolean): void {
    const interval$ = interval();
    let subscription$: Subscription = new Subscription();
    subscription$ = interval$.subscribe((data) => {
      if (data === 60) {
        return subscription$.unsubscribe();
      }
      const defaultHeader = document.getElementById('cbDefaultHeader');
      const defaultFooter = document.getElementById('cbDefaultFooter');
      if (defaultHeader || defaultFooter) {
        if (hidehn || this.router.url.includes('onboarding') || this.router.url.includes('register')) {
          defaultHeader?.setAttribute('style', 'display: none');
          defaultFooter?.setAttribute('style', 'display: none');
        } else {
          defaultHeader?.style.removeProperty('display');
          defaultFooter?.style.removeProperty('display');
        }
      }
    });
  }

  public async handleUtag(event: Data): Promise<void> {
    const tealium = event['tealium'];
    if (tealium) {
      // eslint-disable-next-line
      window.utag_data = await this.tealiumbuilder.buildTealiumObj(event);

      if (!window.utag_data.accountNumber9) {
        if (this.tealiumbuilder.checkSimpleAccData && this.loginProfileClientService.udoAccounts) {
          window.utag_data.accountNumber9 = this.loginProfileClientService.udoAccounts;
          window.utag_data.siteID = this.loginProfileClientService.udoSiteIds;
          window.utag_data.cAccontNbrCount = this.loginProfileClientService.activeAccountsInProfile;
        } else if (this.tealiumbuilder.checkSimpleAccData && !this.loginProfileClientService.udoAccounts) {
          this.loginProfileClientService.simpleAccountUdoData.subscribe((result) => {
            if (result) {
              window.utag_data.accountNumber9 = this.loginProfileClientService.udoAccounts;
              window.utag_data.siteID = this.loginProfileClientService.udoSiteIds;
              window.utag_data.cAccontNbrCount = this.loginProfileClientService.activeAccountsInProfile;
            }
          });
        }
      }

      /* CBMA1-28759 : Uncommenting the utag.view in app.component */
      if (window.utag && window.utag.data) {
        this.tealiumService.view(window.utag_data);
         //Uncomment for CBMA7-3194
         this.newRelicService.setPageViewName(window.utag_data);
        // on route change call tealium 'view' function
      }
      if (!window.utag) {
        const scriptUrl = this.cbHelper.isMyAdmin()
          ? this.config.getConfig('CBVM')['tealiumMyAdminURL']
          : this.config.getConfig('CBVM')['tealiumMyAccountURL'];
        console.log('load script', scriptUrl);
        await this.getScript(scriptUrl);
        console.log('script loaded', window?.utag, window?.utag?.view);
      }
    }
    if (this.cbHelper.isUserAuthenticated() && this.cbHelper.isCalnet && !this.adobeFuncCalled) {
      try {
        /* eslint-disable */
        window.utag_data.profile_type = "calnet";
        window.adobe.target.getOffer({
          mbox: "target-global-mbox",
          success(offer: any) {
            window.adobe.target.applyOffer({
              mbox: "target-global-mbox",
              offer,
            });
          },
          error(status: any, error: any) {
            console.log("Error", status, error);
          },
        });
        /* eslint-enable */
      } catch (error) {
        console.log('Error while calling adobe : ' + error);
      }
      this.adobeFuncCalled = true;
    }
  }

  private async getScript(src: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.charset = 'utf-8';
      script.src = src;
      script.addEventListener(
        'load',
        () => {
          resolve();
        },
        false
      );
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  private getSkipLink(url: string) {
    if (url.indexOf('#mainContent') > -1) {
      const newLink = url.replace('#mainContent', '');
      this.link = `${newLink}#mainContent`;
    } else {
      this.link = `${url}#mainContent`;
    }
  }
}
