<div [ngClass]="{'mt-n5': validateContactService?.validateUserInfoContact}"><app-validate-contact-header></app-validate-contact-header></div>
<ng-container *ngIf="validateContactService?.contentLoaded; else loadSkeletonRadioOptionsTemplate">
    <div 
        [ngClass]="{'margin-top-6em-imp': (!validateContactService.commonMsg.displayMessage.show && validateContactService?.validateUserInfoContact)}">
        <div [ngClass]="{'modal-body pt-0 pb-0': validateContactService?.validateUserInfoContact}">
            <p class="text-center">{{resourceBundle.pageDescription}}
                {{validateContactService?.validatecontact?.contactValue | phone}}</p>
            <div class="mt-5 dead-center" [ngClass]="{'margin-right-1 margin-left-1em': validateContactService?.validateUserInfoContact && isMobile }">
                <form [formGroup]="codeForm" class="col-lg-6 col-md-7 col-sm-9 col-xs-12 wide100 oneColumnGrid"
                    [ngClass]="{'pl-4 ml-1': !isMobile ,'col-lg-10':validateContactService?.validateUserInfoContact ,'col-md-10' :validateContactService?.validateUserInfoContact && isTablet}">
                    <label for="code" [ngClass]="{ labelRedColor: invalidCode ,'margin-bottom-15px':validateContactService?.validateUserInfoContact}">{{resourceBundle.verifyCodeLabel}}</label>
                    <input type="password" id="code" [ngClass]="{'wide100': !isMobile, 'error mb-2': invalidCode}" maxlength="6"
                        formControlName="code" (input)="resetError()">
                </form>
            </div>
        </div>
        <div class="text-center col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1"
            [ngClass]="{'mb-1 pl-0 pr-0 margin-top-40px': validateContactService?.validateUserInfoContact , 'margin-top-20' : isMobile , 'mb-5' :!validateContactService?.validateUserInfoContact}">
            <span>{{resourceBundle.didNotReceiveText}}</span>
            <br *ngIf="validateContactService?.validateUserInfoContact && isMobile">
            <a href="javascript:void(0)"
                class="pl-2 display-inline-block"
                (click)="onclickOfResendVerificationCode(reSendVerificationCodeFlag)">{{resourceBundle.resendCodeLink}}</a>
            <div *ngIf="!validateContactService?.validateUserInfoContact"
                [ngClass]="isMobile ? 'mt-4 ml-2 mr-2 mb-3' : 'mt-5 ml-5 mb-4'">
                <button *ngIf="!isMobile" class="button btn-long-text button-secondary mr-5 pl-3 pr-3 wide-auto"
                    (click)="handleRedirect(resourceBundle.contactMethodUrl);">
                    {{resourceBundle.chooseAnotherMethodBtn}}</button>
                <button id="continueBtn"
                    class="button btn-long-text button-primary pad-right-4-5-imp pad-left-4-5-imp wide-auto track-clicks"
                    [disabled]="!(codeForm.get('code')?.value?.length === 6)" [value]="resourceBundle.continueBtn"
                    (click)="onClickOfContinue()">{{resourceBundle.continueBtn}}</button>
                <button *ngIf="isMobile" class="button btn-long-text button-secondary pl-3 pr-3 wide-auto mt-2"
                    (click)="handleRedirect(resourceBundle.contactMethodUrl)">
                    {{resourceBundle.chooseAnotherMethodBtn}}</button>
            </div>
            <div [ngClass]="{'margin-top-45': validateContactService?.validateUserInfoContact ,'margin-top-20' : isMobile }" *ngIf="validateContactService?.validateUserInfoContact">
                <hr class="hr mb-2">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 display-flex pr-0 pl-0"
                    [ngClass]="{'flex-column-reverse align-items-center': isMobile, 'justify-content-flexEnd': !isMobile}">
                    <button id="closebutton" class="button width-228-px button-secondary"
                        [ngClass]="{'margin-right-20px': !isMobile ,'mt-1' : isMobile}"
                        (click)="close()">{{resourceBundle.cancel}}</button>
                    <button type="button" class="button width-228-px track-clicks" [ngClass]="{'mr-4': !isMobile}" [value]="resourceBundle.continueBtn"
                        [disabled]="!(codeForm.get('code')?.value?.length === 6)" (click)="onClickOfContinue()">
                        <span *ngIf="!showBtnSpinner">{{resourceBundle.continueBtn}}</span>
                        <div *ngIf="showBtnSpinner" class="loading-spinner"></div>
                    </button>
                </div>
            </div>
            <a *ngIf="!validateContactService?.validateUserInfoContact" href="javascript:void(0)"
                (click)="onclickofvalidate(resourceBundle.dashboard)">
                {{resourceBundle.validateLaterLink}}</a>
        </div>
    </div>
</ng-container>

<ng-template #loadSkeletonRadioOptionsTemplate>
    <div class="dead-center">
        <div *ngIf="validateContactService?.validateUserInfoContact" [ngClass]="{'col-lg-7 col-md-7 col-sm-7 col-xs-7' : !isMobile, 'col-lg-4 col-md-4 col-xs-4 col-sm-4': isMobile}" >
            <app-skeletonloader formName="contactMethodForm" formSkeleton="true" rowCount="3"></app-skeletonloader>
        </div>  
    </div>
</ng-template>

<!-- Leave site modal -->
<ng-template #leaveSiteModal let-c="close" let-d="dismiss" role="dialog">
    <comn-leavingcoxsitemodal [secondaryBtnRequired]=true [primaryBtnRequired]=true [switchPage]="leavingPage"
        [modaltemplate]="modaltemplate" (onPrimaryClick)="onPrimaryClick($event, c)"
        (onSecondaryClick)="onSecondaryClick(c)">
    </comn-leavingcoxsitemodal>
</ng-template>