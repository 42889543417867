export interface CBAccountConnection {
  edges: [CBAccountEdge];
  nodes: [CBAccount];
  pageInfo: PageInfo;
  totalCount: number;
}

export interface CBAccountEdge {
  cursor: string;
  node: CBAccount;
}

export interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}
export interface CBAccount {
  accountNumber12: string;
  alias: string;
  guid: string;
  accountExternalAttrs: CBAccountExternalAttrs;
  billStatements: BillStatement;
  methodOfPayments: MethodOfPayments;
}

export interface CBAccountExternalAttrs {
  serviceAddress: Address;
  status: AccountStatusEnum;
}

export interface Address {
  houseNumber: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
}

export enum AccountStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FORMER = 'FORMER',
  FORMER_NO_STATEMENTS = 'FORMER_NO_STATEMENTS',
}

export interface BillStatement {
  statements: [Statement];
  accountTotalAmtDue: string;
  accountTotalAmtPastDue: string;
  accountTotalOverDue: boolean;
  accountTotalDueDate: string;
}

export interface Statement {
  code: string;
  identifier: string;
  totalAmountDue: string;
  totalPastAmountDue: string;
  isPaperlessEligible: boolean;
  isAutoPayEligible: boolean;
  isEnrolledInAutoPay: boolean;
  serviceCategories: [string];
  billHandlingPreferenceType: string;
  billHandlerCode: string;
  currentDue: string;
  amountDueToday: string;
  nextPaymentDate: string;
  dueDate: string;
  customerName: string;
  customerBusinessName: string;
  billingAddress: Address;
}
export interface StatementList {
  guid: string;
  alias: string;
  serviceAddress: string;
  accountNumber12: string;
  accountName: string;
  totalAmountDue: number;
  paymentAmount: number;
  dueDate: string;
  nextPaymentDate: string;
  totalPastAmountDue: number;
  name: string;
  code: string;
  identifier: string;
  isChecked: boolean;
  isEdit: boolean;
  cursor: string;
  methodOfPayments: MethodOfPayments;
  isEnrolledInAutoPay: boolean;
}
export abstract class BillingGenericResponse {
  transactionId: string;
  code: string;
  message: string;
  warning: string;
}
export class Statement extends BillingGenericResponse {
  pdfData: Blob;
  code: string;
  identifier: string;
  totalAmountDue: string;
  totalPastAmountDue: string;
  isPaperlessEligible: boolean;
  isAutoPayEligible: boolean;
  isEnrolledInAutoPay: boolean;
  serviceCategories: [string];
  billHandlingPreferenceType: string;
  billHandlerCode: string;
  currentDue: string;
  amountDueToday: string;
  nextPaymentDate: string;
  dueDate: string;
  customerName: string;
  customerBusinessName: string;
  billingAddress: Address;
}

export enum BankAccountTypes {
  S = 'S',
  C = 'C'
}

export class UpdateMopList {
  mopList: UpdateMop[];
}

export class UpdateMop {
  mopFlags: MopFlags;
  mopIdentifier: MopIdentifier;
  mopDetails: MopDetails;
  statementCodes: string[];
  authorizationDetails: PaymentAuthorizationDetails;
}

export class MopFlags {
  isDefaultMop: boolean;
  isEasyPayMop: boolean;
  saveMop?: boolean;
}

export class MopIdentifier {
  mopCode: string;
  mopSequence: string;
  methodOfPaymentId: string;
}

export class MopDetails {
  methodOfPaymentId: string;
  customerName: string;
  cardExpirationDate: string;
  routingCode: string;
  holderZip: string;
  bankAccountType: BankAccountType;
  countryCode?: string;
}


export enum BankAccountType {
  SAVINGS = 'SAVINGS',
  CHECKING = 'CHECKING'
}

export class PaymentAuthorizationDetails {
  authorizationName: string;
  authorizationEmail: string;
  authorizationText: string;
  ip?: string;
}

export class ReviewAndSubmitObj {
  accountAlias?: string;
  serviceAddress?: string;
  accountNumber?: string[];
  relatedStatement?: string[];
  nextPaymentDate?: string;
  paymentMethod?: string[];
  payMethodWithAmount?: string[]; //added for showing amount along with card no
  paymentAmount?: string;
  guid?: string;
  code?: string;
  dueDate?: string;
  totalPastAmountDue?: number;
  isEnrolledInAutoPay?: boolean;
  scheduledFuturePayDate?: string;
}

export class SubmitPayment {
  payByCreditCardDetails: PaymentByCreditCard[];
  payByBankAccountDetails: PaymentByBankAccount[];
  payByMopDetails: PaymentByMop[];
  authorizationDetails: PaymentAuthorizationDetails;
}

export class PaymentByCreditCard {
  creditCardDetails: BillingCreditCardDetails;
  chaseDetails: ChaseDetails;
  accountBillingMopDetails: SubmitAccountBillPaymentDetails;
}

export class PaymentByBankAccount {
  bankDetails: BillingBankDetails;
  accountBillingMopDetails: SubmitAccountBillPaymentDetails;
}

export class BillingBankDetails {
  bankRoutingNumber: String;
  bankAccountNumber: String;
  bankAccountType: BankAccountType;
  bankAccountHolderName: String;
  bankPaymentMethodClass: PaymentMethodClass;
  holderZip: String;
}

export class PaymentByMop {
  mopIdentifier: MopIdentifier;
  accountBillingMopDetails: SubmitAccountBillPaymentDetails;
}

export class BillingCreditCardDetails {
  cardNumberEncrypted: String;
  cardHolderName: String;
  cardExpirationDate: String;
  cardCSVEncrypted: String;
  cardPaymentMethodClass: PaymentMethodClass;
  holderZip: String;
  cardPaymentMethod: PaymentMethod;
}

export enum PaymentMethod {
  BANK = 'Bank',
  AMEX = 'American Express',
  ATM = 'ATM',
  ACH = 'Bank',
  MASTERCARD = 'Mastercard',
  DISCOVER = 'Discover',
  AMERICANEXPRESS = 'American Express',
  VISA = 'Visa',
  DEBIT = 'ATM',
  UNKNOWN = 'Unknown',
}

export enum PaymentMethodClass {
  ACH = 'ACH',
  MASTERCARD = 'MASTERCARD',
  DISCOVER = 'DISCOVER',
  AMERICANEXPRESS = 'AMERICANEXPRESS',
  VISA = 'VISA'
}

export class ChaseDetails {
  formatId: String;
  integrityCheck: String;
  subscriberId: String;
  cardNumber: String;
  cardCSV: String;
}

export class SubmitAccountBillPaymentDetails {
  accountNumber12: string;
  billingPaymentDetails: BillingPaymentDetail;
  mopFlags: MopFlags;
}

export class BillingPaymentDetail {
  statementCode: string;
  amount: string;
  dateScheduled: string;
  type?: string;
}

export interface MethodOfPayments {
  restrictedMop?: string;
  isSPMAccount?: boolean;
  methodOfPayments?: MethodOfPaymentList[];
}
export interface MethodOfPaymentList {
  id?: string;
  type?: PaymentMethod;
  code?: string;
  sequence?: string;
  description?: string;
  customerMOPStatus?: string;
  bankAccountType?: string;
  routingCode?: string;
  customerAccountNumber?: string;
  customerName?: string;
  cardExpirationDate?: string;
  customerZipCode?: string;
  isDefaultMop?: boolean;
  isEasyPayMop?: boolean;
  isStoredMop?: boolean;
  statementCodes?: string[];
  isMopSelected?: boolean;
  amountDue?: number;
  editAmount?: boolean;
  labelText?: string;
  bankPaymentMethodClass?: string;
  holderZip?: string;
  chaseDetails?: CreditChaseInput;
  cardNumberEncrypted?: string;
  cardCSVEncrypted?: string;
  cardHolderName?: string;
  cardPaymentMethodClass?: string;
  saveMop?: boolean;
  isNewMop?: boolean;
  countryCode?: string;
  isAddMOP?: boolean;

}

export interface CreditChaseInput {
  integrityCheck: string;
  formatId: string;
  subscriberId: string;
  cardCsV: string;
  cardNumber: string;
}
export class PaymentConfirmationObj {
  confirmationNumber: string[];
  paymentDate: string;
}

export class EditMopObj {
  accountNumber: string;
  name: string;
  expirationYear: string;
  expirationMonth: string;
  customerZipCode: string;
  isDefaultMethod: boolean;
  countryCode?: string;
}
export class FailedStatementList {
  statement: StatementList;
  payByCreditCardDetail: PaymentByCreditCard;
  payByBankAccountDetail: PaymentByBankAccount;
  payByMopDetail: PaymentByMop;
  errorCode: string;
  errorMessage: string;
}

export class FailedStatement {
  authorizationDetails: PaymentAuthorizationDetails;
  failedStatementList: FailedStatementList[];
}

export enum MOPAccountTypeEnum {
  SAVINGS = 'Savings',
  CHECKING = 'Checking',
  BANK = 'Bank',
  AMEX = 'American Express',
  ATM = 'ATM',
  ACH = 'Bank',
  MASTERCARD = 'Mastercard',
  DISCOVER = 'Discover',
  AMERICANEXPRESS = 'American Express',
  VISA = 'Visa',
  DEBIT = 'ATM',
  UNKNOWN = 'Unknown'
}