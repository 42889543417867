import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AEMClientService, CBHelperService, CommonMessageService, PhonePipe, ResourceBundle } from 'common-ui-lib';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { DateTimePipe } from '../../directives/dateTime.pipe';
import { EventTypeGroup } from './unplanned-outage-modal.model';
import { UnplannedOutageModalService } from './unplanned-outage-modal.service';


@Component({
  selector: 'app-unplanned-outage-modal',
  templateUrl: './unplanned-outage-modal.component.html',
  styleUrls: ['./unplanned-outage-modal.component.scss']
})
export class UnplannedOutageModalComponent implements OnInit {

  private onDestroy$ = new Subject<boolean>();
  public resourceBundle: ResourceBundle = {};
  public isMobileDevice = false;
  public outageAccountList: any[] = [];
  public datatableRows: any[] = [];
  public showGradient: boolean;
  public totalCount: number = 0;
  public outageCheckNextPage: boolean;
  public selectall: boolean;
  public datatableRowWidth = [];
  public phoneNumber: any = '';
  public isMobileNumber = false;
  public userProfile: any;
  public isMultiOutages: boolean;
  public outageDesc: string;
  public multiOutagesView = false;
  public rowDetails: any;
  public disableButton = true;
  public rowSelect: boolean;
  public contentLoaded = false;
  public singleAccViewLoaded = false;
  public disableSelectAll = false;
  @ViewChild('outageAccounts', { static: true }) outageAccounts: DatatableComponent;
  constructor(public aemClient: AEMClientService, public activeModal: NgbActiveModal,
    public cbHelperService: CBHelperService, public unplannedOutageModalService: UnplannedOutageModalService,
    private deviceService: DeviceDetectorService, public commonMessageService: CommonMessageService) {
    this.aemClient.getSharedBundle('unplannedOutage')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => {
          this.resourceBundle = data;
        }
      );
  }

  ngOnInit() {
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }
    this.datatableRowWidth.push({ name: 50, accountAlias: 100, serviceAddress: 190, estRepairTime: 210, status: 70 });
    this.userProfile = this.cbHelperService.getLoggedInUserDetails().profile.userToken;
    this.getPhoneNumDetails();
    this.getOutageDetails();
  }

  close(event: any) {
    this.activeModal.close(event);
  }

  // Query call to get the preferred contact number
  public getPhoneNumDetails() {
    this.unplannedOutageModalService.getUserInfo(this.userProfile.guid).subscribe((results: any) => {
      if (results.data.getUser !== null) {
        const userInfo = results.data?.getUser;
        userInfo.contacts.forEach(contact => {
          if (contact.contactType === 'PRIMARY_PHONE') {
            if (contact.isMobileNumber === true) {
              const phonePipe = new PhonePipe();
              this.phoneNumber = contact.contactValue;
              this.phoneNumber = phonePipe.transform(this.phoneNumber);
              this.isMobileNumber = true;
              this.disableButton = false;
            }
          }
        });
      }
      if (results.errors) {
        const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'getUser');
        if (errorInfo) {
          window.scroll(0, 0);
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'unplannedOutage');
        }
      }
    });
  }

  // Query call to get the unplanned outage details
  public getOutageDetails() {
    this.unplannedOutageModalService.getUnplannedOutageDetails()
      .pipe(finalize(() => { this.contentLoaded = true; }))
      .subscribe((results: any) => {
        if (results.data.listOutages !== null && results.data.listOutages[0].outage !== null) {
          this.outageAccountList = results.data?.listOutages;
          this.isMultiOutages = this.outageAccountList?.length > 1;
          if (this.isMultiOutages) {
            this.multiOutagesView = true;
            this.outageAccountList = this.outageAccountList.map(v => ({ ...v, isEnrolled: false }));
            this.outageAccountList = this.outageAccountList.map(v => ({ ...v, dateTime: '' }));
            this.outageAccountList = this.outageAccountList.map(v => ({ ...v, serviceAddress: '' }));
            this.totalCount = this.outageAccountList.length;
            this.outageAccountList.forEach((ele) => {
              const dateTimePipe = new DateTimePipe();
              ele.dateTime = dateTimePipe.transform(ele.outage.etr);
              ele.serviceAddress=this.formattedAddress(ele?.accountExternalAttrs?.serviceAddress);
            })
            if (this.totalCount > 10) {
              this.showGradient = true;
            }
            this.checkIfAllChronicOutage();
            this.checkOutageSelected();
          } else {
            this.multiOutagesView = false;
            this.singleAccOutageDetails(this.outageAccountList[0]);
          }
        }
        if (results.errors) {
          const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'getOutageDetails');
          if (errorInfo) {
            window.scroll(0, 0);
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'unplannedOutage');
          }
        }
      });
  }

  // Pagination effect on datatable scroll
  public onScroll(event) {
    if ((event.currentTarget?.scrollLeft === 0 && event.offsetX === undefined) || (event.offsetX === 0 && event.currentTarget?.scrollLeft === undefined)) {
      if (event.currentTarget?.scrollTop) {
        if ((event.currentTarget.scrollTop + 25) >= event.currentTarget.scrollHeight - event.currentTarget.offsetHeight && !this.outageCheckNextPage) {
          this.showGradient = false;
        } else {
          this.showGradient = true;
        }
      }
    }
  }

  // Display each row outage details on click of row
  public singleAccOutageDetails(row) {
    if (this.isMultiOutages) {
      this.multiOutagesView = false;
    }
    this.singleAccViewLoaded = false;
    this.getOutageDescription(row.guid);
    const phonePipe = new PhonePipe();
    const dateTimePipe = new DateTimePipe();
    this.rowDetails = {
      guid: row.guid,
      serviceAddress: this.formattedAddress(row?.accountExternalAttrs?.serviceAddress),
      estimatedTime: row.outage.eventTypeGroup === EventTypeGroup.CHRONIC ? 'Not Available' : dateTimePipe.transform(row.outage.etr),
      isEnrolled: row.outage.cnsFeedback ? 'Yes' : 'No',
      eventTypeGroup: row.outage.eventTypeGroup,
      phoneNum: this.phoneNumber ? this.phoneNumber : phonePipe.transform(row.outage.phoneNumber),
      isMobileNum: this.isMobileNumber ? this.isMobileNumber : false
    }
    if (this.rowDetails.phoneNum.length >= 12) {
      this.disableButton = false;
    }
  }

  public selectAllAccounts(event) {
    if (event.target.checked) {
      this.outageAccountList.forEach((element: any) => {
        if (element.outage.eventTypeGroup !== EventTypeGroup.CHRONIC) {
          element.isEnrolled = true;
        }
      });
      if (this.phoneNumber && this.phoneNumber.length === 12) {
        this.disableButton = false;
      }
    } else {
      this.outageAccountList.forEach((element: any) => {
        if (element.outage.eventTypeGroup !== EventTypeGroup.CHRONIC) {
          element.isEnrolled = false;
        }
      });
      if (this.phoneNumber && this.phoneNumber.length === 12) {
        this.disableButton = true;
      }
    }
  }

  public selectAccount(row) {
    if (row.isEnrolled) {
      if (this.phoneNumber && this.phoneNumber.length === 12) {
        this.disableButton = false;
      }
    } else {
      for (let i = 0; i < this.outageAccountList.length; i++) {
        if (this.outageAccountList[i].isEnrolled === false) {
          this.disableButton = true;
        } else {
          this.disableButton = false;
          return;
        }
      }
    }
  }

  public checkSelectAll() {
    for (let i = 0; i < this.outageAccountList.length; i++) {
      if (this.outageAccountList[i].outage.eventTypeGroup !== EventTypeGroup.CHRONIC) {
        if (this.outageAccountList[i].isEnrolled) {
          this.selectall = true;
        } else {
          this.selectall = false;
          return;
        }
      }
    }
  }

  public getOutageDescription(accountGuid: string) {
    this.unplannedOutageModalService.getOutageVerbiage(accountGuid)
      .pipe(finalize(() => { this.singleAccViewLoaded = true; }))
      .subscribe((results: any) => {
        if (results.data) {
          this.outageDesc = results.data.getAccount?.outage.message;
        }
        if (results.errors) {
          const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'getOutageDescription');
          if (errorInfo) {
            window.scroll(0, 0);
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'unplannedOutage');
          }
        }
      });
  }

  public getOutageUpdates() {
    const accGuidArray = [];
    let input = null;
    if (this.isMultiOutages && this.multiOutagesView) {
      this.outageAccountList.forEach((element) => {
        if (element.isEnrolled === true) {
          accGuidArray.push(element.guid);
        }
      });
      input = {
        phoneNumber: this.phoneNumber.replaceAll('-', ''),
        accountGuid: accGuidArray,
        saveNumber: this.isMobileNumber
      }
    } else {
      input = {
        phoneNumber: this.rowDetails.phoneNum.replaceAll('-', ''),
        accountGuid: this.rowDetails.guid,
        saveNumber: this.rowDetails.isMobileNum
      }
    }
    this.unplannedOutageModalService.updatePhoneNumber(input).subscribe((results: any) => {
      if (results.errors) {
        const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'updatePhoneNumber');
        if (errorInfo) {
          window.scroll(0, 0);
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'unplannedOutage');
        }
      }
    })
  }

  public backToMultiAccView() {
    this.multiOutagesView = true;
    this.rowDetails = null;
    this.checkOutageSelected();
  }

  public validatePhoneNum() {
    if (this.isMultiOutages && this.multiOutagesView) {
      if (this.phoneNumber && this.phoneNumber.length === 12) {
        this.outageAccountList.forEach((element: any) => {
          if (element.isEnrolled === true) {
            this.disableButton = false;
          }
        });
      } else {
        this.disableButton = true;
      }
    } else {
      if (this.rowDetails && this.rowDetails.phoneNum?.length === 12) {
        this.disableButton = false;
      } else {
        this.disableButton = true;
      }
    }
  }

  public get EventTypeGroup() {
    return EventTypeGroup
  }

  public checkOutageSelected() {
    if (this.phoneNumber && this.phoneNumber.length === 12) {
      for (let i = 0; i < this.outageAccountList.length; i++) {
        if (this.outageAccountList[i].isEnrolled === false) {
          this.disableButton = true;
        } else {
          this.disableButton = false;
          return;
        }
      }
    }
  }

  public checkIfAllChronicOutage() {
    for (let i = 0; i < this.outageAccountList.length; i++) {
      if (this.outageAccountList[i].outage.eventTypeGroup === EventTypeGroup.CHRONIC) {
        this.disableSelectAll = true;
      } else {
        this.disableSelectAll = false;
        return;
      }
    }
  }

  // Accessibility -> to activate row when keyboard focus is on respective row
  public activateRow(event) {
    if (document.activeElement.classList.contains('datatable-body-row')) {
      document.querySelectorAll('datatable-body-row').forEach((ele) => {
        if (ele.classList.contains('active')) {
          ele.classList.remove('active');
        }
      });
    }
    document.activeElement.classList.add('active');
  }

  // Accessibility -> to make the datatable focusable element
  public datatableAccessibility(event) {
    document.querySelectorAll('datatable-body-row').forEach((ele) => {
      ele.setAttribute('tabindex', '0')
    })
  }

  public formattedAddress(serviceAddress:any){
    return `${this.cbHelperService.replaceNull(serviceAddress.street)}${this.cbHelperService.replaceNull(serviceAddress.houseNumber)}${this.cbHelperService.replaceNull(serviceAddress.city)}${this.cbHelperService.replaceNull(serviceAddress.state,true)}${this.cbHelperService.replaceNull(serviceAddress.zipCode,true)}`;
  }

}
