import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BaseAccount } from '../../models/accounts/baseaccounts.model';
import { AEMClientService } from '../../../services/aemclient.service';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-accountwidget7plusaccounts-basic',
  templateUrl: './accountwidget7plusaccounts-basic.component.html',
  styleUrls: ['./accountwidget7plusaccounts-basic.component.scss']
})
export class Accountwidget7plusaccountsBasicComponent implements OnInit, OnDestroy {
  @Input() baseAccounts: BaseAccount[];
  @Output() selectedAccount = new EventEmitter<BaseAccount>();
  oddAccounts: BaseAccount[] = [];
  evenAccounts: BaseAccount[] = [];
  allAccounts: BaseAccount[] = [];
  accountid: any;
  oddAccountsFlag: boolean;
  evenAccountsFlag: boolean;
  allAccountsFlag: boolean = true;
  toggleAccordian: boolean = false;
  showselect: boolean = false;
  filterType: string = 'an';
  inputEntered: any;
  results: BaseAccount[] = [];
  resultsDisplayed: number = 0;
  resultsLength: number = 0;
  noResults: boolean = true;
  pageLength: number = 7;
  resourceBundle: any = {};
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService) {
    this.aemClient.getSharedBundle('accountwidget7plusaccounts-basicResourceBundles')
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
      (data: any) => { this.resourceBundle = data; },
      error => {}
    );
  }

  ngOnInit() {
  }

  displayFilterText(filterType: string) {
    this.selectedAccount.emit(null);
    this.inputEntered = '';
    this.resetSearch();
    this.pageLength = 7;
    this.noResults = true;
    this.filterType = filterType;
  }

  isSelected(filterType: string) {
    if (this.filterType == filterType) {
      return true;
    } else {
      return false;
    }
  }

  resetSearch() {
    this.results = [];
    this.oddAccounts = [];
    this.oddAccountsFlag = false;
    this.evenAccounts = [];
    this.evenAccountsFlag = false;
    this.allAccounts = [];
    this.allAccountsFlag = false;
    this.resultsDisplayed = 0;
    this.resultsLength = 0;
  }

  searchBaseAccountsList(input: string) {

    if (this.inputEntered.length < 3) {
      this.noResults = true;
      this.resetSearch();
      this.pageLength = 7;
    } else {
      this.handleSearchCriteria();
    }
  }

  handleSearchCriteria() {
    this.noResults = true;
    this.resetSearch();
    this.baseAccounts.forEach(element => {
      if (this.matches(element)) {
        this.results.push(element);
      }
    });

    this.sortResults();
    this.arrangeAccounts();

  }

  matches(element: BaseAccount) {
    switch (this.filterType) {
      // ACCOUNT NUMBER\
      case "an":
        return element.accountNumber.indexOf(this.inputEntered) >= 0;

      // ADDRESS (FORMAT:- ADDRESS1, ADDRESS2, CITY, STATE ZIP)
      case "ad":
        var address = element.address.address1;
        address += element.address.address2;
        address += element.address.city;
        address += element.address.state;
        address += element.address.zip;

        // REMOVE NON-ALPHANUMBERIC CHARACTERS FOR THE SEARCH VALUE & CRITERIA
        return this.stripNonAlphaNumericCharacters(address).toUpperCase().indexOf(this.stripNonAlphaNumericCharacters(this.inputEntered).toUpperCase()) >= 0;

      // ACCOUNT ALIAS
      case "aa":
        return element.alias.toUpperCase().indexOf(this.inputEntered.toUpperCase()) >= 0;

      // ACCOUNT NAME
      case "aname":
        return element.name.toUpperCase().indexOf(this.inputEntered.toUpperCase()) >= 0;

      default:
        break;
    }

  }

  stripNonAlphaNumericCharacters(arg: string) {
    var re = /[^a-zA-Z0-9]+/g;
    return arg.replace(re, '');
  }

  sortResults() {
    if (this.results.length == 0) {
      this.noResults = false;
    }

    switch (this.filterType) {
      // ACCOUNT NUMBER\
      case "an":
        return this.results.sort(this.sortAccNo);

      // ACCOUNT ALIAS
      case "aa":
        return this.results.sort(this.alphanumSortAccAlias.bind(this));

      // ADDRESS (FORMAT:- ADDRESS1, ADDRESS2, CITY, STATE ZIP)
      case "ad":
        return this.results.sort(this.alphanumSortAddress.bind(this));

      // ACCOUNT NAME
      case "aname":
        return this.results.sort(this.alphanumSortAccName.bind(this));

      default:
        break;
    }
  }

  sortAccNo(accObj1: BaseAccount, accObj2: BaseAccount) {
    return parseInt(accObj1.accountNumber) - parseInt(accObj2.accountNumber);
  }

  alphanumSortAddress(accObj1: BaseAccount, accObj2: BaseAccount) {
    var address1 = accObj1.address.address1;
    address1 += accObj1.address.address2;
    address1 += accObj1.address.city;
    address1 += accObj1.address.state;
    address1 += accObj1.address.zip;
    var address2 = accObj2.address.address1;
    address2 += accObj2.address.address2;
    address2 += accObj2.address.city;
    address2 += accObj2.address.state;
    address2 += accObj2.address.zip;

    var aa = this.chunkify(address1.toLowerCase());
    var bb = this.chunkify(address2.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        var c = Number(aa[x]), d = Number(bb[x]);
        if (c == aa[x] && d == bb[x]) {
          return c - d;
        } else return (aa[x] > bb[x]) ? 1 : -1;
      }
    }
    return aa.length - bb.length;
  }

  chunkify(t: any) {
    var tz = new Array();
    var x = 0, y = -1, n = 0, i, j;

    while (i = (j = t.charAt(x++)).charCodeAt(0)) {
      var m;
      if (i == 46 || (i >= 48 && i <= 57)) {
        m = 1;
      } else {
        m = 0;
      }
      if (m !== n) {
        tz[++y] = "";
        n = m;
      }
      tz[y] += j;
    }
    return tz;
  }

  alphanumSortAccName(accObj1: BaseAccount, accObj2: BaseAccount) {

    var aa = this.chunkify(accObj1.name.toLowerCase());
    var bb = this.chunkify(accObj2.name.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        var c = Number(aa[x]), d = Number(bb[x]);
        if (c == aa[x] && d == bb[x]) {
          return c - d;
        } else return (aa[x] > bb[x]) ? 1 : -1;
      }
    }
    return aa.length - bb.length;
  }

  alphanumSortAccAlias(accObj1: BaseAccount, accObj2: BaseAccount) {

    var aa = this.chunkify(accObj1.alias.toLowerCase());
    var bb = this.chunkify(accObj2.alias.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        var c = Number(aa[x]), d = Number(bb[x]);
        if (c == aa[x] && d == bb[x]) {
          return c - d;
        } else return (aa[x] > bb[x]) ? 1 : -1;
      }
    }
    return aa.length - bb.length;
  }

  arrangeAccounts() {
    let scope = this;
    this.resultsLength = this.results.length;
    this.results.forEach(function (eachresult, i) {
      if (i <= scope.pageLength) {
        if (i % 2) {
          scope.evenAccountsFlag = true;
          scope.evenAccounts.push(eachresult);
        } else {
          scope.oddAccountsFlag = true;
          scope.oddAccounts.push(eachresult);
        }
        scope.allAccountsFlag = true;
        scope.allAccounts.push(eachresult);
      } else {
        return false;
      }
      scope.resultsDisplayed++;
    });

  }

  seeMoreResults() {
    this.pageLength += 8;
    this.handleSearchCriteria();
  }

  searchAgain() {
    this.inputEntered = '';
    this.resetSearch();
    this.selectedAccount.emit(null);
    this.filterType = 'an';
    this.pageLength = 7;
    window.scrollTo(0, 0);
  }

  displayAccountSelected(baseAccount: BaseAccount) {
    this.toggleAccordian = !this.toggleAccordian;
    this.selectedAccount.emit(baseAccount);
    this.accountid = baseAccount.accountId;
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }   
}
