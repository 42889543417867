<ng-container #fileConversionModal>
    <div class="file-conversion-container" 
    [ngClass]="{'pad-right-1-5 pad-left-1-5': fileConversionfailed }"> 
      <ng-container *ngIf="!fileConversionfailed">
        <h4 class="bold">{{resourceBundle.oneMoment}}</h4>
        <div class="text-center">{{resourceBundle.fileConversionDescription}}</div>
        <div class="bold margin-top-2em-imp">{{resourceBundle.converting}}</div>
        <div class="margin-bottom-20px pad-left-p-20">
          <div class="cb-spinner" role="alert" aria-live="polite" aria-label="Loading">
            <svg preserveAspectRatio="xMidYMid meet" height="30" width="30" focusable="false" viewBox="0 0 100 100" alt="Processing... please wait">
              <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
                style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%;">
              </circle>
            </svg>
          </div>
        </div>
      </ng-container>
  
      <ng-container *ngIf="fileConversionfailed">
        <h4 class="bold">{{resourceBundle.conversionError}}</h4>
        <div class="text-center p-1">{{resourceBundle.conversionErrorDescription}}</div>
  
        <div class="mt-5 wide100 display-flex justify-content-space-between">
          <button class="button button-secondary p-0 width-10" (click)="onClickOfCancel(close)">{{resourceBundle.cancel}}</button>
          <button class="button mr-0 p-0 width-10" (click)="onClickOfTryAgain()">{{resourceBundle.tryAgain}}</button>
        </div>
        <div class="mt-3 w-100 display-flex justify-content-end">
          <a [href]="voiceSupportUrl" target="_blank" class="bold linkUnderline">{{resourceBundle.getSupport}}</a>
        </div>
  
      </ng-container>
    </div>
  </ng-container>