<span class="sr-only" aria-label="opens a new dialog window"></span>
<div class="col-md-12 margin-bottom-20 modalheader">
  <div class="col-lg-11 col-md-11 col-sm-11 col-xs-11 margin-bottom-20 margin-top-15">
    <h2 *ngIf="schData.mode !== 'edit-event'" role="heading" aria-level="2" translate>Add Schedule/Event</h2>
    <h2 *ngIf="schData.mode === 'edit-event'" role="heading" aria-level="2" translate>Edit Event</h2>
  </div>
  <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-center margin-bottom-20 margin-top-15">
    <a title="Close" role="button" class="cb-modal-close-btn" id="closeButton" href="javascript:void(0)" (click)="closeModal()">
    </a>
  </div>
</div>
<div class="col-md-12 margin-bottom-20" *ngIf="errMsg.show">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
    <ul>
      <li *ngIf="errMsg.type === 'error'" title="error" class="msg-error" rel="tooltip" data-alert aria-label="Please correct all highlighted fields below.">{{errMsg.message}}</li>
      <li *ngIf="errMsg.type === 'success'" title="success" class="msg-success" rel="tooltip" data-alert>{{errMsg.message}}</li>
    </ul>
  </div>
</div>
<!-- Description -->
<div class="bodyAlignment">
<div class="row margin-top-30 margin-bottom-20">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <span *ngIf="schData.mode !== 'edit-event'">To add an event, select or create a schedule. If appropriate, indicate if the event recurs.</span>
    <span *ngIf="schData.mode === 'edit-event'">Edit your Event name, date/time, and recurrence options below.</span>
  </div>
</div>

<form #scheduleForm="ngForm" novalidate class="pb-4">
  <!--Schedule Section -->
  <div [class]="isEventEdit ? 'row margin-bottom-20' :'row margin-bottom-30px margin-top-25'">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-1 pr-0">
      <span *ngIf="isSchNew || isEventEdit">
        <label for="newSchduleName" id="newSchduleNameLabel" class="bold asterisk"><span class="sr-only">* </span>Schedule Name: </label>
      </span>
      <span *ngIf="!isSchNew && !isEventEdit">
          <label for="existingSchedule"  id="existingScheduleLabel" class="bold asterisk"><span class="sr-only">* </span>Schedule Name: </label>
      </span>
    </div>
    <!-- New or  Existing Schedule -->
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pl-0" *ngIf="isSchNew">
      <input type="text" class="w-100 pl-1" id="newSchduleName" aria-label="Enter Schedule Name." [(ngModel)]="newSchduleName" required maxlength="40" #newSchControl="ngModel"
             [ngClass]="{'error': newSchControl.invalid && newSchControl.dirty}" (keydown)="onKeyDown($event, 'newSchduleName')" (input)="inputName($event, 'newSchduleName')"
             name="newSchduleName" placeholder="Enter Schedule Name Here" [required]="isSchNew">
             <span *ngIf="newSchControl.invalid && newSchControl.dirty" class="text-danger form-error margin-bottom-0 mt-1">Required.</span>
             
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pl-0" *ngIf="isEventEdit">
      <p class="pad-top-11">{{schData.scheduleName}}</p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pl-0" *ngIf="!isSchNew && !isEventEdit">
      <select id="existingSchedule" class="w-100" aria-label="Schedule Name" tabindex="0" name="existingSchedule" #existingSchControl="ngModel"
              [ngClass]="{'error': existingSchControl.invalid && existingSchControl.dirty}"
              [(ngModel)]= "existingSchedule" (change)="changeSchdule(existingSchedule)" [required]="!isSchNew">
        <option aria-label="Schedule Name combo box list" value="">Select</option>
          <optgroup label="Group Schedules" *ngIf="scheduleListGrp.length > 0 && schType==='Time'">
            <option aria-label="Schedule Name combo box list selected" *ngFor="let grp of scheduleListGrp" value={{grp.scheduleName}}>{{grp.scheduleName}}</option>
          </optgroup>
          <optgroup label="Group Schedules" *ngIf="holidayScheduleListGrp.length > 0 && schType==='Holiday'">
            <option aria-label="Schedule Name combo box list selected" *ngFor="let grp of holidayScheduleListGrp" value={{grp.scheduleName}}>{{grp.scheduleName}}</option>
          </optgroup>
          <optgroup label="Personal Schedules" *ngIf="scheduleListPersonal.length > 0 && schType==='Time' && !onlyGroup">
            <option aria-label="Schedule Name combo box list selected" *ngFor="let per of scheduleListPersonal" value={{per.scheduleName}}>{{per.scheduleName}}</option>
          </optgroup>
          <optgroup label="Personal Schedules" *ngIf="holidayScheduleListPersonal.length > 0 && schType==='Holiday' && !onlyGroup">
            <option aria-label="Schedule Name combo box list selected" *ngFor="let per of holidayScheduleListPersonal" value={{per.scheduleName}}>{{per.scheduleName}}</option>
          </optgroup>
      </select>
    </div>
    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 pt-2 pl-0 mt-1" *ngIf="isSchNew && !isEventEdit">
      <span><a href="javascript:void(0)" (click)="isSchNew = !isSchNew;onClickFocus()">Use Existing Schedule</a></span>
      <!--<div class="text-inline align-middle">-->
      <!--<a href="javascript:void(0)"-->
      <!--tooltip="Use Existing Schedule."-->
      <!--placement="right" container="body" containerClass="CBTooltip">-->
      <!--<i class="fa fa-question-circle fa-cb-info"></i></a></div>-->
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pt-2 pl-o" *ngIf="!isSchNew && !isEventEdit">
      <span><a href="javascript:void(0)" (click)="isSchNew = !isSchNew;onClickFocus()">Add New Schedule</a></span>
    </div>
  </div>
  <div class="row margin-bottom-20" *ngIf="isSchNew && (!isEventEdit || showType)">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pr-0 pt-1">
      <label for="type1" class="bold">Type: </label>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 pl-0 pt-1" [hidden]="!showType">
      <p>{{schType === 'Time' ? 'Regular' : 'Holiday'}}</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 pl-0 pt-1" [hidden]="showType">
        <fieldset>
          <legend class="sr-only">Schedule</legend>
          <input type="radio" id="type1" [(ngModel)]="schType" name="schType" value="Time">
          <label for="type1">Regular</label>
          <input type="radio" id="type2" [(ngModel)]="schType" name="schType" value="Holiday">
          <label for="type2">Holiday</label>
        </fieldset>
    </div>
  </div>
  <div *ngIf="!isPersonalDisabled">
    <div class="row margin-bottom-20" *ngIf="isSchNew && (!isEventEdit || showType)">
      <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pr-0 pt-1">
        <label for="access1" class="bold">Access: </label>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 pl-0 pt-1">
        <fieldset *ngIf="!onlyGroup">
          <legend class="sr-only">Schedule</legend>
          <input type="radio" id="access1" [(ngModel)]="accessType" [ngClass]="getGroupStyles()" name="accessType" value="Group">
          <label for="access1" [ngClass]="getGroupStyles()" class="pr-2" tabindex="0">Group</label>
          <input type="radio" id="access2" [(ngModel)]="accessType" [ngClass]="getMyStyles()" name="accessType" value="User">
          <label [ngClass]="getMyStyles()" for="access2" tabindex="0">Personal</label>
        </fieldset>
        <fieldset  *ngIf="onlyGroup">
          <legend class="sr-only">Schedule</legend>
          <label class="pr-2">Group</label>
        </fieldset>
      </div>
    </div>
  </div>
  
  <!-- Event Section -->
  <div class="row margin-bottom-20">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 pr-0 mt-1">
      <label for="eventName" id="eventNameLabel" class="bold asterisk"><span class="sr-only">* </span>Event Name: </label>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pl-0">
      <input type="text" class="w-100 pl-1" id="eventName"  aria-label="Enter Event Name." [(ngModel)]="eventName" required #eventNameControl="ngModel"
             [ngClass]="{'error': eventNameControl.invalid && eventNameControl.dirty}" (keydown)="onKeyDown($event, 'eventName')" (input)="inputName($event, 'eventName')"
             name="eventName"  placeholder="Enter Event Name Here">
             <span *ngIf="eventNameControl.invalid && eventNameControl.dirty" class="text-danger form-error margin-bottom-0 mt-1">Required.</span>
            </div> 
  </div>

  <!--Start Time -->
  <div class="row margin-bottom-20">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 pr-0 mt-1">
      <label for="startDate" id="startDateLabel" class="bold asterisk"><span class="sr-only"></span>Start Date: </label>
    </div>
    <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 pl-0">
      <div  [class]="isEventEdit || !isSchNew ? 'display-inline-block vertical-align-top edit' : 'display-inline-block vertical-align-top'" >
      <!-- <input id="startdate" type="text" name="startDate" class="sch-datepicker pl-1 mr-1" bsDatepicker [bsValue]="startDate" [(ngModel)]="startDate" placeholder="mm/dd/yyyy"
             #startDateControl="ngModel" placement="right" [minDate]="minDate" (bsValueChange)="onValueChange($event)"
             [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-dark-blue', showWeekNumbers: false }" required
             (ngModelChange)="dateModelChanged($event)"> -->
             <p-calendar inputId="startDate" name="startDate" [(ngModel)]="startDate" placeholder="mm/dd/yyyy" [minDate]="minDate" [calendarName]="'Start Date'"
             (ngModelChange)="dateModelChanged($event)" #startDateControl="ngModel" baseZIndex="1" required [showIcon]="true" appPcalendar></p-calendar>
             <!-- Only for screen readers accessibility -->
        <span class="sr-only" role="text" aria-live="true">{{startDate}}</span>
      </div>
      <span class="display-inline-block margin-left-10 pt-2 mt-1 vertical-align-top">at</span>
      <div class="display-inline-block margin-left-10 vertical-align-top" #startTime>
        <ngb-timepicker id="starttime" name="starttime" [(ngModel)]="starttime" [meridian]="true" #startTimeControl="ngModel" [ngClass]="{'error': startTimeControl.invalid && startTimeControl.dirty}"
                        [spinners]="false" [disabled]="alldayselected" required
                        (ngModelChange)="dateModelChanged($event)"></ngb-timepicker>
                        <span *ngIf="startTimeControl.invalid && startTimeControl.dirty" class="text-danger form-error margin-bottom-0 mt-1">Required.</span>
      </div>
      <div class="display-inline-block vertical-align-top">
          <label for="startmeridian" class="sr-only">Select start meridian</label>
        <select id="startmeridian" class="width-6" name="startMeridian" [disabled]="alldayselected" (ngModelChange)="toggleMeridian($event,'start')" [(ngModel)]="startMeridian">
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </select>
      </div>
      <div class="display-inline-block margin-left-10 vertical-align-top mt-1">
        <input type="checkbox" id="alldayselected" name="alldayselected" [(ngModel)]="alldayselected" (ngModelChange)="dateModelChanged($event)">
        <label for="alldayselected" class="margin-top-10">All Day</label>
      </div>
    </div>
  </div>

  <!--End Time -->
  <div class="row margin-bottom-20">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 pr-0 mt-1">
      <label for="endDate" id="endDateLabel" class="bold asterisk"><span class="sr-only"></span>End Date: </label>
    </div>
    <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 pl-0">
      <div [class]="isEventEdit || !isSchNew ? 'display-inline-block vertical-align-top edit' : 'display-inline-block vertical-align-top'  ">
        <!-- <input id="enddate" type="text" class="sch-datepicker mr-1 pl-1" name="endDate" bsDatepicker [bsValue]="endDate" [(ngModel)]="endDate" placeholder="mm/dd/yyyy"
               #endDateControl="ngModel"  placement="right" [minDate]="minDate"
               [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-dark-blue', showWeekNumbers: false }" required
               (ngModelChange)="dateModelChanged($event)"> -->
               <p-calendar inputId="endDate" name="endDate" [(ngModel)]="endDate" placeholder="mm/dd/yyyy" [minDate]="minDate" [calendarName]="'End Date'"
               (ngModelChange)="dateModelChanged($event)" #endDateControl="ngModel" baseZIndex="1" required [showIcon]="true" appPcalendar></p-calendar>
               <!-- Only for screen readers accessibility -->
        <span class="sr-only" role="text" aria-live="true">{{endDate}}</span>
      </div>
      <span class="display-inline-block margin-left-10 pt-2 mt-1 vertical-align-top">at</span>
      <div class="display-inline-block margin-left-10 vertical-align-top" #endTime>
        <ngb-timepicker id="endtime" name="endtime" [(ngModel)]="endtime" [meridian]="true" #endtimeControl="ngModel"
                        #endTimeControl="ngModel" [ngClass]="{'error': endTimeControl.invalid && endTimeControl.dirty}"
                        [spinners]="false" [disabled]="alldayselected" required
                        (ngModelChange)="dateModelChanged($event)" ></ngb-timepicker>
                        <span *ngIf="endTimeControl.invalid && endTimeControl.dirty" class="text-danger form-error margin-bottom-0 mt-1">Required.</span>                 
      </div>
      <div class="display-inline-block vertical-align-top">
        <label for="endmeridian" class="sr-only">Select end meridian</label>
        <select id="endmeridian" class="width-6" name="endMeridian" [disabled]="alldayselected"  (ngModelChange)="toggleMeridian($event,'end')" [(ngModel)]="endMeridian">
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </select>
      </div>
    </div>
  </div>

  <!-- Error message -->
  <div class="row margin-top-neg-15 margin-bottom-20" *ngIf="dtErrMsg">
    <div class="col-lg-8 offset-lg-2 col-md-3 offset-md-4 col-sm-12 col-xs-12 pl-0">
      <p class="red">End time should be greater than start time.</p>
    </div>
  </div>
  <div class="row margin-top-neg-15 margin-bottom-20" *ngIf="curDateErr">
    <div class="col-lg-8 offset-lg-2 col-md-3 offset-md-4 col-sm-12 col-xs-12 pl-0">
      <p class="red">Please enter correct start date.</p>
    </div>
  </div>
  <!--Recurs -->
  <div class="row margin-bottom-20">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 mt-1 pr-0">
      <label for="recur" class="bold">Recurs: </label>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pl-0">
      <select id="recur" class="w-100" [(ngModel)]="recurtype" name="recurtype" (change)="changeRecur(recurtype)">
        <option value="never">Never</option>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
      </select>
    </div>
  </div>
  <!--Daily -->
  <div class="row margin-bottom-20" *ngIf="recurtype === 'daily'">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 mt-1 pr-0">
      <label for="dailyoccurence" class="bold" aria-label="Every:"><span *ngIf="recurtype === 'daily'"></span> Every: </label>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-4 col-xs-4 pl-0">
      <input type="number" maxlength="3" id="dailyoccurence" min="1" max="999" [(ngModel)]="dailyoccurence" class="pl-2" #dailyoccurenceControl="ngModel"
             [ngClass]="{'error': dailyoccurenceControl.invalid && dailyoccurenceControl.dirty}"
             name="dailyoccurence" class="w-100 pl-1" role="text" [required]="recurtype === 'daily'" oninput="validity.valid||(value='');">
    </div>
    <div class="col-lg-1 col-md-1 col-sm-4 col-xs-4 m-0 p-0 pt-2 mt-1">
      Days
    </div>
  </div>

  <!--Weekly -->
  <div class="row margin-bottom-20" *ngIf="recurtype === 'weekly'">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 mt-1 pr-0">
      <label for="weeklyoccurence" class="bold"  aria-label="Every:"><span *ngIf="recurtype === 'weekly'">* </span>Every: </label>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-3 pl-0">
      <input type="number" maxlength="2" id="weeklyoccurence" min="1" max="99" [(ngModel)]="weeklyoccurence" #weeklyoccurenceControl="ngModel"
             [ngClass]="{'error': weeklyoccurenceControl.invalid && weeklyoccurenceControl.dirty}"
             name="weeklyoccurence" role="text" class="w-100 pl-1" [required]="recurtype === 'weekly'" oninput="validity.valid||(value='');">
    </div>
    <div class="col-lg-1 col-md-1 col-sm-4 col-xs-4 m-0 p-0 pt-2">
      Weeks on
    </div>
    <div class="col-lg-8 col-md-7 col-sm-12 col-xs-12 p-0">
      <fieldset>
          <legend class="sr-only">Weeks-on</legend>
      <span *ngFor="let weekday of weekdays; let i = index;" class="col-md-2 p-0 width-4">
        <input type="checkbox" id="{{ weekday.key }}" name="{{ weekday.key }}" [(ngModel)]="weekday.state"
               (change)="weekday.state != weekday.state; onWeeklyChange();"
               [ngClass]="{'error': recurtype === 'weekly' && !atLeastOnePropIsTrue}"
               [required]="recurtype === 'weekly' && !atLeastOnePropIsTrue">
        <label class="schedule-label" attr.for="{{ weekday.key }}" attr.aria-label="{{weekday.key}}"><span class="ml-2">{{ weekday.value }}</span></label>
      </span>
      </fieldset>

      <!--<input type="checkbox" id="sun" name="sun" [(ngModel)]="sun">-->
      <!--<label for="sun" class="schedule-label">S</label>-->
      <!--<input type="checkbox" id="mon" name="mon" [(ngModel)]="mon">-->
      <!--<label for="mon"  class="schedule-label">M</label>-->
      <!--<input type="checkbox" id="tue" name="tue" [(ngModel)]="tue">-->
      <!--<label for="tue"  class="schedule-label">T</label>-->
      <!--<input type="checkbox" id="wed" name="wed" [(ngModel)]="wed">-->
      <!--<label for="wed"  class="schedule-label">W</label>-->
      <!--<input type="checkbox" id="thu" name="thu" [(ngModel)]="thu">-->
      <!--<label for="thu"  class="schedule-label">T</label>-->
      <!--<input type="checkbox" id="fri" name="fri" [(ngModel)]="fri">-->
      <!--<label for="fri"  class="schedule-label">F</label>-->
      <!--<input type="checkbox" id="sat" name="sat" [(ngModel)]="sat">-->
      <!--<label for="sat"  class="schedule-label">S</label>-->
      <!--<div class="btn-group btn-group-lg">-->
      <!--<label class="btn btn-primary cb-btn-primary" btnCheckbox>S</label>-->
      <!--<label class="btn btn-primary cb-btn-primary" btnCheckbox>M</label>-->
      <!--<label class="btn btn-primary cb-btn-primary" btnCheckbox>T</label>-->
      <!--<label class="btn btn-primary cb-btn-primary" btnCheckbox>W</label>-->
      <!--<label class="btn btn-primary cb-btn-primary" btnCheckbox>T</label>-->
      <!--<label class="btn btn-primary cb-btn-primary" btnCheckbox>F</label>-->
      <!--<label class="btn btn-primary cb-btn-primary" btnCheckbox>S</label>-->
      <!--</div>-->
    </div>
  </div>

  <!--Monthly -->
  <div class="row margin-bottom-20" *ngIf="recurtype === 'monthly'">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 mt-1 pr-0">
      <label for="monthlyoccurence" class="bold" ><span *ngIf="recurtype === 'monthly'">* </span>Every: </label>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-4 col-xs-4 pl-0">
      <input type="number" role="text" maxlength="2" min="1" max="99" id="monthlyoccurence" [(ngModel)]="monthlyoccurence"
             name="monthlyoccurence" class="w-100 pl-1" aria-label="Every:" #monthlyoccurenceControl="ngModel"
             [ngClass]="{'error': monthlyoccurenceControl.invalid && monthlyoccurenceControl.dirty}"
            [required]="recurtype === 'monthly'" oninput="validity.valid||(value='');">
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 m-0 p-0 pt-2 mt-1">
      Months on
    </div>
    <div class="col-lg-7 col-md-6 col-sm-12 col-xs-12 pl-0 margin-left-neg-25">
      <!-- Monthly day selection -->
      <div class="row pl-0">
        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 pt-2">
          <fieldset>
            <legend class="sr-only"><label for="monthly1"><span *ngIf="recurtype === 'monthly'">* </span>Please enter the day of month</label></legend>
            <input type="radio" id="monthly1" [(ngModel)]="monthlysel" name="monthlysel" value="monthday" #monthly1Control="ngModel"
              [ngClass]="{'error': monthly1Control.invalid && monthly1Control.dirty}" [required]="recurtype === 'monthly'" (click)="setMonthlyDayOccurences()">
            <label for="monthly1" tabindex="0">Day</label>
        </fieldset>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 p-0">
          <label for="monthday" class="sr-only"><span *ngIf="monthlysel === 'monthday'">* </span>Please enter the day of month</label>
          <input type="text" maxlength="2" id="monthday" [(ngModel)]="monthday"
                 name="monthday" class="pl-1 w-100" #monthdayControl="ngModel"
                 [ngClass]="{'error': monthdayControl.invalid && monthdayControl.dirty}"
                 [required]="monthlysel === 'monthday'" (blur)="restrictNumeric(monthday)">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-0 pt-2 mt-1">
          of the month
          <!-- <div class="text-inline pl-1 align-text-top">
            <a href="javascript:void(0)"
               tooltip="Some months may contain less days than the day of the month you are entering. For these months,
               the recurrence will fall on the last day of the month."
               placement="right" container="body" containerClass="CBTooltip">
              <i class="fa fa-question-circle fa-cb-info"><span class="sr-only">help-icon</span></i></a></div> -->
        </div>
      </div>
      <div class="row margin-top-neg-15 margin-bottom-20" *ngIf="dayErrMsg">
        <div class="col-lg-8 offset-lg-2 col-md-3 offset-md-4 col-sm-12 col-xs-12 pl-0">
          <p class="red">The date must be between 1 and 31 and must not exceed the number of days for the particular month.</p>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pt-2">
          <fieldset>
            <legend class="sr-only"><label for="monthly2"><span *ngIf="recurtype === 'monthly'">* </span>Month of the week</label></legend>
            <input type="radio" id="monthly2" [(ngModel)]="monthlysel" name="monthlysel" value="monthweek" class="w-100 pl-1" #monthly2Control="ngModel"
              [ngClass]="{'error': monthly2Control.invalid && monthly2Control.dirty}" [required]="recurtype === 'monthly'" (click)="setMonthlyTheOccurences()">
            <label for="monthly2">The</label>
        </fieldset>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pl-0">
          <label for="dayOfWeekInMonth" class="sr-only"><span *ngIf="monthlysel === 'monthweek'">* </span>Select week of the month</label>
            <select id="dayOfWeekInMonth" class="w-100" name="dayOfWeekInMonth" 
            [(ngModel)]="dayOfWeekInMonth" #dayOfWeekInMonthControl="ngModel"
              [ngClass]="{'error': dayOfWeekInMonthControl.invalid && dayOfWeekInMonthControl.dirty}" 
              [required]="monthlysel === 'monthweek'">
            <option value="First">First</option>
            <option value="Second">Second</option>
            <option value="Third">Third</option>
            <option value="Fourth">Fourth</option>
            <option value="Last">Last</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label for="dayOfWeek" class="sr-only"><span *ngIf="monthlysel === 'monthweek'">* </span>Select day of the week</label>
          <select id="dayOfWeek" class="wide-80" [(ngModel)]="dayOfWeek" name="dayOfWeek" 
          #dayOfWeekControl="ngModel" [ngClass]="{'error': dayOfWeekControl.invalid && dayOfWeekControl.dirty}"
            [required]="monthlysel === 'monthweek'">
            <option value="Monday">Mon</option>
            <option value="Tuesday">Tue</option>
            <option value="Wednesday">Wed</option>
            <option value="Thursday">Thu</option>
            <option value="Friday">Fri</option>
            <option value="Saturday">Sat</option>
            <option value="Sunday">Sun</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <!--Yearly -->
  <div class="row margin-bottom-20" *ngIf="recurtype === 'yearly'">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 mt-1 pr-0">
      <label for="yearlyoccurence" class="bold"><span *ngIf="recurtype === 'yearly'" aria-label="Every:">* </span>Every: </label>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-4 col-xs-4 pl-0">
      <input type="number" maxlength="2" min="1" max="99" id="yearlyoccurence" [(ngModel)]="yearlyoccurence"
             name="yearlyoccurence" class="w-100 pl-1"  #yearlyoccurenceControl="ngModel"
             [ngClass]="{'error': yearlyoccurenceControl.invalid && yearlyoccurenceControl.dirty}"
            [required]="recurtype === 'yearly'" role="text" oninput="validity.valid||(value='');">
    </div>
    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 m-0 p-0 pt-2">
      Years on
    </div>
    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
      <!-- Yearly day selection -->
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 pt-2">
          <fieldset>
            <legend class="sr-only"><label for="yearly1"><span *ngIf="recurtype === 'yearly'">* </span>Year of the day</label></legend>
            <input type="radio" id="yearly1" [(ngModel)]="yearlysel" name="yearlysel" value="yearday" #yearly1Control="ngModel" [ngClass]="{'error': yearly1Control.invalid && yearly1Control.dirty}"
              [required]="recurtype === 'yearly'" (change)="setYearlyDayOccurences()">
            <label for="yearly1"><span class="sr-only" *ngIf="recurtype === 'yearly'">* </span>Day</label>
        </fieldset>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 p-0">
          <label for="yearday" class="sr-only"><span *ngIf="yearlysel === 'yearday'"></span>Please enter day of the year</label>
          <input type="text" maxlength="2" id="yearday" [(ngModel)]="yearlydayoccur"
                 name="yearlydayoccur" class="w-100 pl-1"
                 #yearlydayoccurControl="ngModel"
             [ngClass]="{'error': yearlydayoccurControl.invalid && yearlydayoccurControl.dirty}"
            [required]="yearlysel === 'yearday'" (blur)="restrictNumeric(yearlydayoccur)">
        </div>
        <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 m-0 pt-2 px-2">
          of
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 m-0 pl-0">
            <label for="yearlymonsel" class="sr-only"><span *ngIf="yearlysel === 'yearday'"></span>Select Month of the year</label>
          <select id="yearlymonsel" class="col-md-12" [(ngModel)]="yearlymonthsel" name="yearlymonthsel"
          #yearlymonselControl="ngModel"
             [ngClass]="{'error': yearlymonselControl.invalid && yearlymonselControl.dirty}"
            [required]="yearlysel === 'yearday'">
            <optgroup>
            <option value="January">Jan</option>
            <option value="February">Feb</option>
            <option value="March">Mar</option>
            <option value="April">Apr</option>
            <option value="May">May</option>
            <option value="June">Jun</option>
            <option value="July">Jul</option>
            <option value="August">Aug</option>
            <option value="September">Sep</option>
            <option value="October">Oct</option>
            <option value="November">Nov</option>
            <option value="December">Dec</option>
            </optgroup>
          </select>
        </div>
      </div>
      <div class="row margin-top-neg-15 margin-bottom-20" *ngIf="dayErrMsg">
        <div class="col-lg-8 offset-lg-2 col-md-3 offset-md-4 col-sm-12 col-xs-12 pl-0">
          <p class="red">The date must be between 1 and 31 and must not exceed the number of days for the particular month.</p>
        </div>
      </div>
      <!-- Yearly the selection -->
      <div class="row mt-2">
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pt-2">
            <fieldset>
                <legend class="sr-only"><label for="yearly2"><span>*</span> selection</label></legend>
                <input type="radio" id="yearly2" [(ngModel)]="yearlysel" name="yearlysel" value="yearweek" #yearly2Control="ngModel" [ngClass]="{'error': yearly2Control.invalid && yearly2Control.dirty}"
                  [required]="recurtype === 'yearly'" (change)="setYearlyTheOccurences()">
                <label for="yearly2"><span class="sr-only"  *ngIf="recurtype === 'yearly'" tabindex="0">* </span> The</label>
            </fieldset>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pl-0 pr-0">
            <label for="yearlydaysel" class="sr-only"><span *ngIf="yearlysel === 'yearweek'"></span>Select week of the month</label>
          <select id="yearlydaysel" class="w-100" [(ngModel)]="yearlydaysel" name="yearlydaysel"
          #yearlydayselControl="ngModel"
             [ngClass]="{'error': yearlydayselControl.invalid && yearlydayselControl.dirty}"
            [required]="yearlysel === 'yearweek'">
            <option value="First">First</option>
            <option value="Second">Second</option>
            <option value="Third">Third</option>
            <option value="Fourth">Fourth</option>
            <option value="Last">Last</option>
          </select>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 pr-0">
            <label for="yearlydaynamesel" class="sr-only"><span *ngIf="yearlysel === 'yearweek'"></span>Select day of the week</label>
          <select id="yearlydaynamesel" class="col-md-12" [(ngModel)]="yearlyweeksel" name="yearlyweeksel"
          #yearlydaynameselControl="ngModel"
             [ngClass]="{'error': yearlydaynameselControl.invalid && yearlydaynameselControl.dirty}"
            [required]="yearlysel === 'yearweek'">
              <option value="Monday">Mon</option>
              <option value="Tuesday">Tue</option>
              <option value="Wednesday">Wed</option>
              <option value="Thursday">Thu</option>
              <option value="Friday">Fri</option>
              <option value="Saturday">Sat</option>
              <option value="Sunday">Sun</option>
          </select>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 m-0 pr-0 pt-2 pl-2">
          of
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pl-0 m-0">
            <label for="yearlymonsel2" class="sr-only"><span *ngIf="yearlysel === 'yearweek'"></span>Select Month of the year</label>
          <select id="yearlymonsel2" class="col-md-12" [(ngModel)]="yearlymonsel" name="yearlymonsel"
          #yearlymonsel2Control="ngModel"
             [ngClass]="{'error': yearlymonsel2Control.invalid && yearlymonsel2Control.dirty}"
            [required]="yearlysel === 'yearweek'">
            <optgroup>
            <option value="January">Jan</option>
            <option value="February">Feb</option>
            <option value="March">Mar</option>
            <option value="April">Apr</option>
            <option value="May">May</option>
            <option value="June">Jun</option>
            <option value="July">Jul</option>
            <option value="August">Aug</option>
            <option value="September">Sep</option>
            <option value="October">Oct</option>
            <option value="November">Nov</option>
            <option value="December">Dec</option>
          </optgroup>
          </select>
        </div>
      </div>
    </div>
  </div>

  <!-- ENDS -->
  <div class="row" *ngIf="recurtype !== 'never'">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 pt-2 mt-1 pr-0">
      <label for="endtype" class="bold">End: </label>
    </div>
    <div class="col-lg-3 col-md-2 col-sm-4 col-xs-4 pl-0">
      <select id="endtype" class="w-100" [(ngModel)]="endtype" name="endtype">
        <option value="never">Never</option>
        <option value="date">Date</option>
        <option value="after">After</option>
      </select>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pl-o" *ngIf="endtype === 'date'">
      <!-- <input id="endrecdate" type="text" class="datepicker mr-1" bsDatepicker placement="top" name="endRecurDate" [bsValue]="endRecurDate" [(ngModel)]="endRecurDate"
             [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-dark-blue', showWeekNumbers: false }"> -->
             <p-calendar id="endrecdate" name="endRecurDate" [(ngModel)]="endRecurDate" placeholder="mm/dd/yyyy" [minDate]="minDate" [calendarName]="'End Date'"
                 baseZIndex="1" [showIcon]="false" [required]="!isSchNew" #endRecurDateControl="ngModel"
                 [ngClass]="{'error': endRecurDateControl.invalid && endRecurDateControl.dirty}" appPcalendar></p-calendar>
                 <label for="endrecdate"><span class="sr-only">endrecdate*</span></label>
                 <!-- Only for screen readers accessibility -->
        <span class="sr-only" role="text" aria-live="true">{{endRecurDate}}</span>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-4 pl-0" *ngIf="endtype === 'after'">
      <input type="number" id="endoccurence" min="1" [required]="!isSchNew" maxlength="3" max="999" role="button" name="endoccurence" class="w-100 pl-1" [(ngModel)]="endRecurVal" name="endRecurVal"
      #endRecurValControl="ngModel" [ngClass]="{'error': endRecurValControl.invalid && endRecurValControl.dirty}" oninput="validity.valid||(value='');">
      <label for="endoccurence"><span class="sr-only">Enter the number of occurence to end the schedule</span></label>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3 pt-2 mt-1 pl-0" *ngIf="endtype === 'after'">
      Occurrences
    </div>
  </div>
</form>
</div>
<hr class="hr">
<div class="row margin-top-10 margin-right-25 margin-left-25" *ngIf="!showType">
  <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
    <button class="button button-secondary track-clicks" value="'Cancel'" (click)="closeModal()">Cancel</button>
  </div>
  <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 text-right"
       [ngClass]="{'pr-0' : !isEventEdit}">
    <button class="button mr-4 track-clicks" value="'Save'" (click)="saveScheduleCheck(false, startTime, endTime)"  [disabled]="disableSave === true" >Save</button>
   <span *ngIf="!isEventEdit"><button class="button btn-long-text track-clicks" value="'Save & Add Another'" (click)="saveScheduleCheck(true, startTime, endTime)" [disabled]="disableSave === true" >Save & Add Another</button></span>
  </div>
</div>
<div class="row margin-top-10 margin-right-25 margin-left-25" *ngIf="showType">
  <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
    <button class="button-secondary button track-clicks" value="'Cancel'" (click)="closeModal()">Cancel</button>
  </div>
  <div class="col-lg-2 col-md-2 offset-lg-7 offset-md-7 col-sm-12 col-xs-12 pl-0">
    <button class="button track-clicks" value="'Save'" (click)="saveScheduleCheck(false, startTime, endTime)">Save</button>
  </div>
</div>

