/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewChecked, Directive, ElementRef, HostListener, Input,
  OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ignoreElements } from 'rxjs/operators';

@Directive({
  selector: '[comnAccessTable]',
})
export class DatatableDirective implements OnInit, AfterViewChecked, OnChanges {
  @Input() tableName: string;
  @Input() noofRows: number;
  @Input() public table: DatatableComponent;
  @Input() public ngxPagination = false;
  public datatablepagerflag = false;
  constructor(private el: ElementRef, private readonly hostElement: ElementRef, private renderer: Renderer2) {
    console.log(el);
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.tableName){
          if(!this.el.nativeElement.getAttribute('role')) {
            this.el.nativeElement?.setAttribute('role', 'table');
          }
          this.el.nativeElement?.firstElementChild?.removeAttribute('role');
          const label = this.renderer.createElement('label');
          this.renderer.addClass(label, 'sr-only');
          this.renderer.setAttribute(label, 'id', 'dataTableName');
          this.renderer.appendChild(label, this.renderer.createText('This is the '+this.tableName));
          this.renderer.insertBefore(this.el.nativeElement.parentNode, label, this.hostElement.nativeElement);
          this.el.nativeElement?.setAttribute('aria-labelledby', 'dataTableName');
      }
  }
  ngOnInit() {
    this.hostElement.nativeElement.getElementsByClassName('datatable-body')[0].addEventListener('scroll', event => {
      if(event.target.firstElementChild.childNodes[0].childNodes.length === 0){
        this.hostElement.nativeElement.getElementsByClassName('datatable-header')[0].scrollLeft =
        event.srcElement.scrollLeft;
      }
    });
  }

  ngAfterViewChecked(){
    if(document?.querySelector('.datatable-pager')){
      document?.querySelectorAll('.pages > *')?.forEach(child => {
        child.setAttribute('role', 'button');
      });
      document?.querySelectorAll('.pager  li')?.forEach(li => {
        li.setAttribute('role', 'listitem');
      });
      document?.querySelector('.pages')?.setAttribute('role', 'listitem');
      document?.querySelector('.pager')?.setAttribute('role', 'list');
    }
    document?.querySelector('.datatable-body')?.setAttribute('role', 'none');

    if (this.hostElement.nativeElement.getElementsByClassName('empty-row').length !== 0){
      this.hostElement.nativeElement.getElementsByClassName('empty-row')[0].style.width =
      this.hostElement.nativeElement.getElementsByClassName('datatable-row-center')[0]?.style?.width;
    }
    if(this.noofRows === 0 ||
      this.el.nativeElement?.children[0]?.children[1]?.children[0]?.children[0]?.classList.contains('empty-row')) {
      this.el.nativeElement?.children[0]?.children[1]?.setAttribute('role','row');
      this.el.nativeElement?.children[0]?.children[1]?.children[0]?.children[0]?.setAttribute('role','cell');
    }

}

  @HostListener('window:keyup', ['$event'])
  // tslint:disable-next-line: cognitive-complexity
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 9 || event.keyCode === 13) {
      const length = this.el.nativeElement.children[0].children[0].children[0].children[1].children.length;
      for (let i = 0; i < length; i++) {
        if (i === 0) {
          this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0]
            .children[0].children[0].setAttribute('id', 'firstColumn');
        }
        if (this.table._internalColumns[i].sortable) {
          this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0]
            .children[1].tabIndex = 0;
          this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].
            children[1].setAttribute('role', 'button');
          this.sortingAccessibility(i);
          const children = this.el.nativeElement.children[0]?.children[0]?.children[0]?.children[1]?.children;
          children[i]?.addEventListener('click', this.sortingAccessibility.bind(this, i));
        }

      }

      if (this.el.nativeElement.children[0].children[2]?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]
        ?.children[0]?.children) {
        const length2 = this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
          .children[0].children[0].children.length;
          this.el.nativeElement.children[0].children[2].children[0].children[0].removeAttribute('role');
        for (let i = 0; i < length2; i++) {
          let label = '';
          if(!this.ngxPagination) {
            if (i === 0) {
              label = 'go to first page';
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].children[0]
                .children[0].children[i].ariaLabel = '';
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].children[0]
                .children[0].children[i].children[0].ariaLabel = label;
              if (event.keyCode === 13 && this.el.nativeElement.children[0].children[2].children[0].children[0].
                children[0].children[0].children[0].children[0].children[i].className !== 'disabled') {
                this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
                  children[0].children[0].children[i].children[0].onkeyup = () => {
                    let element: HTMLElement;
                    element = document.getElementById('firstColumn');
                    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    document.getElementById('firstColumn').focus();
                  };
              }
            } else if (i === 1) {
              label = 'go to previous page';
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
                children[0].children[0].children[i].ariaLabel = '';
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
                children[0].children[0].children[i].children[0].ariaLabel = label;
              if (event.keyCode === 13 && this.el.nativeElement.children[0].children[2].children[0].children[0].
                children[0].children[0].children[0].children[0].children[i].className !== 'disabled') {
                this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
                  // tslint:disable-next-line: no-identical-functions
                  children[0].children[0].children[i].children[0].onkeyup = () => {
                    let element: HTMLElement;
                    element = document.getElementById('firstColumn');
                    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    document.getElementById('firstColumn').focus();
                  };
              }
            } else if (i === this.el.nativeElement.children[0].children[2].children[0].children[0].children[0]
              .children[0].children[0].children[0].children.length - 2) {
              label = 'go to next page';
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
                .children[0].children[0].children[i].ariaLabel = '';
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
                .children[0].children[0].children[i].children[0].ariaLabel = label;
              if (event.keyCode === 13 && this.el.nativeElement.children[0].children[2].children[0].children[0]
                .children[0].children[0].children[0].children[0].children[i].className !== 'disabled') {
                this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
                  // tslint:disable-next-line: no-identical-functions
                  children[0].children[0].children[i].children[0].onkeyup = () => {
                    let element: HTMLElement;
                    element = document.getElementById('firstColumn');
                    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    document.getElementById('firstColumn').focus();
                  };
              }
            } else if (i === this.el.nativeElement.children[0].children[2].children[0].children[0].children[0]
              .children[0].children[0].children[0].children.length - 1) {
              label = 'go to last page';
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
                .children[0].children[0].children[i].ariaLabel = '';
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
                .children[0].children[0].children[i].children[0].ariaLabel = label;
              if (event.keyCode === 13 && this.el.nativeElement.children[0].children[2].children[0].children[0]
                .children[0].children[0].children[0].children[0].children[i].className !== 'disabled') {
                this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
                  // tslint:disable-next-line: no-identical-functions
                  children[0].children[0].children[i].children[0].onkeyup = () => {
                    let element: HTMLElement;
                    element = document.getElementById('firstColumn');
                    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    document.getElementById('firstColumn').focus();
                  };
              }
            } else {
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
                .children[0].children[0].children[i].children[0].ariaLabel = this.el.nativeElement.children[0]
                  .children[2].children[0].children[0].children[0].children[0].children[0].children[0]
                  .children[i].ariaLabel;
              this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
                .children[0].children[0].children[i].children[0].setAttribute('role', 'button');
              if (event.keyCode === 13 && this.el.nativeElement.children[0].children[2].children[0].children[0]
                .children[0].children[0].children[0].children[0].children[i].className !== 'disabled') {
                this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
                  // tslint:disable-next-line: no-identical-functions
                  children[0].children[0].children[i].children[0].onkeyup = () => {
                    let element: HTMLElement;
                    element = document.getElementById('firstColumn');
                    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    document.getElementById('firstColumn').focus();
                  };
              }
            }
          }

          if (this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
            .children[0].children[0].children[i].className === 'disabled') {
            this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0]
              .children[0].children[0].children[i].children[0].ariaLabel += ' - Unavailable';
          }
        }
      }
      if (this.el.nativeElement.children[0]?.children[2]?.children[0]?.children[0]?.children[0]?.children[0]?.
        children[0]?.children[0]?.children) {
    const paginationLength =  this.el.nativeElement.children[0].children[2].children[0].
    children[0].children[0].children[0].children[0].children[0].children.length;
    for (let i = 0; i < paginationLength; i++) {
      this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
      children[0].children[0].children[i].ariaLabel = 'Page ' +
      this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
      children[0].children[0].children[i].innerText;
      if (this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
        children[0].children[0].children[i].classList.contains('active')) {
          this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
          children[0].children[0].children[i].ariaLabel = 'Page ' +
          this.el.nativeElement.children[0].children[2].children[0].children[0].children[0].children[0].
        children[0].children[0].children[i].innerText + ' selected';
        }
      }
      }
    }
  }
  public sortingAccessibility(i: number) {
      if (this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].
        children[1].classList.contains('sort-asc')) {
        this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].
          children[1].ariaLabel =
          this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].
            children[0].children[0].innerText + ' column sorted in ascending order';
      } else if (this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].
        children[1].classList.contains('sort-desc')) {
        this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].children[1]
        .ariaLabel =
          this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].children[0]
            .children[0].innerText + ' column sorted in descending order';
      } else if (this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0]
        .children[1].classList.contains('sort-btn')) {
        this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].children[1]
          .ariaLabel =
          this.el.nativeElement.children[0].children[0].children[0].children[1].children[i].children[0].children[0]
            .children[0].innerText + ' column press enter to sort it in ascending order';
      }
  }
}
