import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, SimpleChanges } from "@angular/core";
import { BaseAccount } from '../../models/accounts/baseaccounts.model';
import { AEMClientService } from '../../../services/aemclient.service';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

@Component({
  selector: "app-accountwidget2to6accounts-basic",
  templateUrl: "./accountwidget2to6accounts-basic.component.html",
  styleUrls: ["./accountwidget2to6accounts-basic.component.scss"]
})
export class Accountwidget2to6accountsBasicComponent implements OnInit, OnDestroy {
  @Input() baseAccounts: BaseAccount[];
  @Output() selectedAccount = new EventEmitter<BaseAccount>();
  oddAccounts: BaseAccount[] = [];
  evenAccounts: BaseAccount[] = [];
  allAccounts: BaseAccount[] = [];
  accountid: any;
  oddAccountsFlag: boolean;
  evenAccountsFlag: boolean;
  allAccountsFlag: boolean = true;
  accountselected: boolean = false;
  resourceBundle: any = {};
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService) {
    this.aemClient.getSharedBundle('accountwidget2to6accounts-basicResourceBundles')
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
      (data: any) => { this.resourceBundle = data; },
      error => {}
    );
  }

  ngOnInit() {
    let scope = this;
    this.baseAccounts.forEach(function(baseAccount, i) {
      if (i % 2) {
        scope.evenAccountsFlag = true;
        scope.evenAccounts.push(baseAccount);
      } else {
        scope.oddAccountsFlag = true;
        scope.oddAccounts.push(baseAccount);
      }
      scope.allAccounts.push(baseAccount);
    });
  }

  accountSelected(baseAccount: BaseAccount) {
    this.selectedAccount.emit(baseAccount);
    this.accountselected = true;
    this.accountid = baseAccount.accountId;
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }   
}
