import { NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache, ApolloClientOptions } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import config from 'assets/config/graphql.json';
import { onError } from '@apollo/client/link/error';
import { Apollo, ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
let URI;
let DP_URI;
for (const env of config.environments) {
  if (window.location.hostname.includes(env)) {
    URI = config.graphql[env]['graphqlEndpoint'];
    DP_URI = config.graphql[env]['digitalProfile_graphqlEndpoint'];
    break;
  }
  if (window.location.hostname.includes('uat')) {
    URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['uat']['aws-appsync_redirection'] :
      config.graphql['uat']['graphqlEndpoint'];
    DP_URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['uat']['digitalProfile_redirection'] :
      config.graphql['uat']['digitalProfile_graphqlEndpoint'];
    break;
  }
  if (window.location.hostname.includes('ppe')) {
    URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['ppe']['aws-appsync_redirection'] :
      config.graphql['ppe']['graphqlEndpoint'];
    DP_URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['ppe']['digitalProfile_redirection'] :
      config.graphql['ppe']['digitalProfile_graphqlEndpoint'];
    break;
  }
  URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['prod']['aws-appsync_redirection'] :
      config.graphql['prod']['graphqlEndpoint'];
    DP_URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['prod']['digitalProfile_redirection'] :
      config.graphql['prod']['digitalProfile_graphqlEndpoint'];
}

@NgModule({
  imports: [
    ApolloModule,
    HttpClientModule,
  ]
})
export class GraphQLModule {
  constructor(apollo: Apollo, httpLink: HttpLink,
    private cookieService: CookieService) {
    //configured graphqlendpoint
    const http = httpLink.create({ uri: URI });
    const dpHttp = httpLink.create({ uri: DP_URI });

    //handling unauthorized access
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error = onError(({ networkError, response }: any) => {
      if(networkError?.error?.errors[0]?.message.includes('406')) {
        window.location.href = window.location.origin + '/cbma/unauth/logout';
        return;
      }
      if ((networkError && networkError.status === 401) || (response && response.errors[0]?.path?.length === 1
        && (response.errors[0]?.errorType === '117' || response.errors[0]?.errorType === '113'))) {
        window.location.href = window.location.origin + '/cbma/home/landing?unauthorized=true';
      }
    });
    const link = ApolloLink.from([error, http]);
    const dpLink = ApolloLink.from([error, dpHttp]);
    apollo.create({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all'
        }
      }
    }
    );
    apollo.create({
      link: dpLink,
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all'
        }
      }
    }, 'digitalProfile');
  }
}
