<div class="mt-4">
    <app-commonmessage
    *ngIf="(service.commonMsg.displayMessage.id === 'validationMethodMsg' && service.commonMsg.displayMessage.show) 
    || service.commonMsg.displayMessage.id === 'codeValidationMsg'" [type]="service.commonMsg.displayMessage.type"
    [globalMessage]="service.commonMsg.displayMessage.message" [level]="'global'">
    </app-commonmessage>
</div>
<div class="text-center">
    <h1 class="pt-5 mb-3" *ngIf="!service.validateUserInfoContact" [ngClass]="{'mt-5': !service.commonMsg.displayMessage.show}">{{resourceBundle.pageTitle}}</h1>
</div>
