import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Injectable()
export class CSVExportMultipleTablesService {
  public title1: string;
  public title2: string;
  public title3: string;
  public updatedTitle: any;
  constructor() { }
  public exportAsCSVFile(json: any[], csvFileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(csvFileName);

    // Display table1
    this.modifyTitle(this.title1);
    this.fetchRowData(this.updatedTitle, json[0], worksheet);
    worksheet.addRow([]);

    // Display table2
    if (json[1]) {
      this.modifyTitle(this.title2)
      this.fetchRowData(this.updatedTitle.filter(Boolean), json[1], worksheet);
      worksheet.addRow([]);
    }

    // Display table3
    if (json[2]) {
      this.modifyTitle(this.title3)
      this.fetchRowData(this.updatedTitle.filter(Boolean), json[2], worksheet);
    }

    workbook.csv.writeBuffer().then((buffer) => {
      const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
      FileSaver.saveAs(data, csvFileName);
    });
  }

  private modifyTitle(title) {
    this.updatedTitle = title.replace(/\r\n/g, '\n').replace(/\ufeff/g, '').split('\n');
    return this.updatedTitle;
  }

  private fetchRowData(title, jsonData, worksheet) {
    if (title.length > 0) {
      title.forEach((line) => worksheet.addRow([line]));
    }
    if (jsonData?.length) {
      const headers = Object.keys(jsonData[0]);
      worksheet.addRow(headers);
      jsonData.forEach((row) => {
        const rowData = headers.map((key) => row[key] ?? '')
        worksheet.addRow(rowData);
      });
    }
  }
}
