import { Injectable } from '@angular/core';
import { IpResponse } from '@data/datatool/datatool.model';
import { AppConfig, BaseAccount, CBHelperService, CoxHttpClient, MicroserviceResponse } from 'common-ui-lib';
import { AccountInfo } from 'common-ui-lib/lib/shared/redesign/pageheader/pageheader.model';
import { Observable } from 'rxjs/internal/Observable';
import { LicenseDetails, NonPayStatus, SendInstallLinkResponse, UserProfileData } from './malblockservice.model';

@Injectable()
export class MalblockService {
    selectedAccount: any;
    constructor(
        private coxhttpClient: CoxHttpClient,
        private config: AppConfig,
        public cbHelper: CBHelperService,
    ) {
    }

  public getLoginProfileDetails(): Observable<UserProfileData> {
    let loginDetailsUrl = '';
    if (this.cbHelper.isBroadsoftUpgradeEnabled('loginprofiles/v1/login ')) {
      loginDetailsUrl = '/api/cbma/userauthorization/services/loginprofiles/v1/login ';
    } else {
      loginDetailsUrl = '/api/cbma/userauthorization/services/loginprofiles/login';
    }
    return this.coxhttpClient.get<UserProfileData>(loginDetailsUrl,
    );
  }
    public getLoggedInUserProfileDetail(loggedInUserEmail: string): Observable<MicroserviceResponse> {
      const requestBody = loggedInUserEmail ? { id: null, userEmail: loggedInUserEmail }
        : { id: null, userEmail: null };
      const url = this.config.getConfig('APIGW')['baseURL']
        + this.config.getConfig('APPURI')['userProfileV3'];
      return this.coxhttpClient.post<MicroserviceResponse>(url, requestBody,
        { disableSpinner: true, customeErrorMessage: '' });
    }

    getDnsIp(): Observable<MicroserviceResponse> {
        return this.coxhttpClient.get(
          '/api/cbma/datainternetgateway/services/datainternetgateway/dnsipResolver',
        );
      }

      public async getDnsIpNew(accountNumber12: string): Promise<IpResponse> {
        return this.coxhttpClient.getAsync<IpResponse>(
          '/api/cbma/datainternetgateway/services/datainternetgateway/account/viewipaddress/' + accountNumber12,
          { disableSpinner: true, customeErrorMessage: '' });
      }

      getNonPayStatus(selectedAccountNumber12: string): Observable<MicroserviceResponse> {

        const getNonPayStatusUsrl = '/api/cbma/account/services/account/accounts/activeAccounts?accountNumber12='
        + selectedAccountNumber12;
        return this.coxhttpClient.get<NonPayStatus>(getNonPayStatusUsrl,
        );
      }

  public async sendInstallLink(accGuid: string): Promise<SendInstallLinkResponse> {
    return this.coxhttpClient.postAsync<SendInstallLinkResponse>
      (`/api/cbma/user/services/user/send/installLink?accountGuid=`+ accGuid, null,
        { disableSpinner: true, customeErrorMessage: '' });
  }

  public async getLicenseDetails(accGuid: string): Promise<LicenseDetails> {
    return this.coxhttpClient.getAsync<LicenseDetails>(`/api/cbma/user/services/user/malblock/licensedetails/` + accGuid,
      { disableSpinner: true, customeErrorMessage: '' });
  }
}
