import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../core/app.config';
import { AEMClientService } from '../../services/aemclient.service';

@Injectable()
export class InfomessageService {

  constructor(private config: AppConfig,
              private http: HttpClient,
              private aEMClientService: AEMClientService) { }

  public getInfoMessageContent(moduleName: string): Observable<ResourceBundle> {
    const _aemBaseURL = this.config.getConfig('AEM')['getResourceBundleURL'];
    const aemFilename = 'infomessage.html';
    const request = this.http.get(_aemBaseURL + moduleName + '/' + aemFilename, { responseType: 'text' });
    return request.map((response: string) => {
      const tempDiv = document.createElement('DIV');
      tempDiv.innerHTML = response;
      let resString: string;
      resString = (tempDiv.getElementsByClassName('htmlCodeBlock')[0] as HTMLElement).innerHTML;
      return JSON.parse(this.aEMClientService.convertToParsableString(resString));
    }).catch((error) => {
      return error;
    });
  }

}

export interface ResourceBundle {
  // tslint:disable-next-line: no-any
  [key: string]: any;
}
