import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CBHelperService, CommonMessageService, ResourceBundle, AEMClientService,
DeviceDetectorService, SSOService, AppConfig, CbHeaderService, DigitalProfileSharedService,
TealiumUtagService,
LocalStorageKeys,
UserType} from 'common-ui-lib';
import { SSOPageName } from 'common-ui-lib/lib/constants/ssopagename.model';
import { AccountInfo } from 'common-ui-lib/lib/shared/redesign/pageheader/pageheader.model';
import { LocalStorageService } from 'ngx-localstorage';
import { DigitalProfileDashboardService } from 'digital-profile-dashboard/digital-profile-dashboard.service';
import { FeatureName } from '../../cb-header.model';

@Component({
  selector: 'app-cbcc-alert',
  templateUrl: './cbcc-alert.component.html',
  styleUrls: ['./cbcc-alert.component.scss']
})
export class CBCCAlertComponent implements OnInit {
  public isCSR = false;
  public isMultiAccount = false;
  public resourceBundle: ResourceBundle = {};
  public featureName: string;
  public isMobileDevice = false;
  public isTablet: boolean;
  public accSelected = true;
  public isZoomedIn: boolean;
  public authorizedUser = false;
  public selectedAccountNumber: string;
  public lastTrialAccNum: string;
  public optedIn = false;
  public defaultAccNumberDetails: AccountInfo;
  public multiAccountModalRef: NgbModalRef;
  public endUser = false;

  constructor(
    public aemClient: AEMClientService,public cbhelperService: CBHelperService,
    public commonmessage: CommonMessageService,
    public deviceService: DeviceDetectorService,
    public activeModal: NgbActiveModal, public modalService: NgbModal,
    public cbHeaderService: CbHeaderService,
    public digitalProfileService: DigitalProfileSharedService,
    public digitalProfileDashboardService: DigitalProfileDashboardService,
    private ssoService: SSOService,
    public tealium: TealiumUtagService,
    private config: AppConfig,
    private _storageService: LocalStorageService
  ) { }

  ngOnInit() {
    if (this.cbhelperService.isMyAdmin()) {
      this.isCSR = true;
    }
    this.aemClient.getSharedBundle('cbccalert')
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
          if (this.isCSR) {
            this.commonmessage.showMessage(this.resourceBundle.cbccCsrInfo, 'info', 'infoMessage');
          }
        }
    );
    if (this.cbhelperService.getLoggedInUserDetails().profile.userToken.userType === UserType.END_USER) {
      this.endUser = true;
    }
    if (this.endUser && !this.digitalProfileService.isEUWOP) {
      this.featureName = FeatureName.ALL;
    } else {
      this.featureName = FeatureName.CBCCOPTIN;
    }
    if(this.cbHeaderService.showAccountModal){
      this.isMobileDevice = this.digitalProfileDashboardService.isMobileDevice;
      this.isMultiAccount = this.cbHeaderService?.checkMultiAccount;
      this.onClickOfOptIn();
    } else {
      this.isMultiAccount = this.digitalProfileService?.checkMultiAccount;
    }
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }
    if(this.deviceService.isPageZoomedto175()){
      this.isZoomedIn = true;
    }
    this.isTablet = this.deviceService.isTablet();
    if(!this.isMultiAccount){
    this.authCheckResponse();
    }
  }

  public triggerTealium(eventName: string){
    this.tealium.button(eventName, eventName);
  }

  public onClickOfOptIn() {
    if (this.isMultiAccount) {
      if (this.digitalProfileService.isEUWOP) {
        this.ssoRedirect();
      } else {
        this.optedIn = true;
        document.getElementById('cbccCloseBtn')?.focus();
      }
    } else {
      this.ssoRedirect();
    }
}
public close(eventName: string) {
    this.cbHeaderService.showAccountModal = false;
    this.activeModal.close(eventName);
    this._storageService.set(LocalStorageKeys.SelectedAccount, null);
  }

  public selectedAccount(event: any) {
    this.accSelected = false;
    this.selectedAccountNumber = event.accountNumber12;
    this.authCheckResponse(event?.guid);
  }

public authCheckResponse(guid?: string){
  const accountguid = this.isMultiAccount ? guid : this.digitalProfileService.accGuid;
  this.cbHeaderService.authorizeCheck(accountguid).subscribe((data:any)=>{
    this.authorizedUser = data?.authorizedUser;
  });
}

public getAccountInfoList(accountNumbers: AccountInfo[]) {
  this.lastTrialAccNum = this.cbHeaderService.showAccountModal?
  this.cbHeaderService.emailTrialDate :this.digitalProfileService?.cbccAlertData?.lastTrialAccountNum;
  const accountNum12 = this.lastTrialAccNum?.split('-');
  const siteId = accountNum12[0]?.padStart(3, '0');
  const accountNum9 = accountNum12[1]?.padStart(9, '0');
  const lastTrailAccNum12 = siteId + accountNum9;
  accountNumbers?.forEach(ele => {
    if (lastTrailAccNum12 === ele.accountNumber12) {
      this.defaultAccNumberDetails = ele;
      return;
    }
  });
  this._storageService.set(LocalStorageKeys.SelectedAccount, this.defaultAccNumberDetails.guid);
}

  public async ssoRedirect(){
    this.close('Close click');
    if (this.authorizedUser && !this.digitalProfileService.isEUWOP) {
      try {
        await this.ssoService.getSSOService(SSOPageName.CBCCOPTIN_CBFORCE, this.selectedAccountNumber);
        window.open(this.config.getConfig('SSO')['PI9']['getCbForceUpdateUrl'], '_blank');
      } catch (error) {
        window.open(this.config.getConfig('cbccLeadForm'), '_blank');
      }
    } else {
      window.open(this.config.getConfig('cbccLeadForm'), '_blank');
    }
  }
}
