/* eslint-disable @typescript-eslint/naming-convention */

import { MicroserviceResponse } from 'common-ui-lib';

/* eslint-disable no-shadow */
export interface CustomerProfileDetails extends ErrorCodeDetails {
    profileName?: string;
    orderId?: string;
    user?: User;
    registrationCode?: string;
}

export interface CustomerProfileDetailsRes extends MicroserviceResponse {
    profileName: string;
    orderId: string;
    user: User;
    registrationCode?: string;
}
export interface ErrorCodeDetails {
    errorMsg?: string;
    errorType?: 'success' | 'error' | 'warning' | 'info';
}

export interface User {
    id?: string;
    firstName: string;
    lastName: string;
    userGuid?: string;
    userType: string;
    contacts: Contact[];
}

export interface Contact {
    id: string;
    contactType: string;
    contactValue: string;
    isPreferred: boolean;
    isBounced?: boolean;
    isMobileNumber?: boolean | string;
    verificationStatus: boolean;
    verificationShown: boolean;
}

export enum ContactType {
    EMAIL = 'PRIMARY_EMAIL',
    PHONE = 'PRIMARY_PHONE'
}

export interface CreatePasswordResponseJSON {
    transactionId: string;
    code: string;
    message: string;
    warning: string;
    id: string;
}

export interface ValidateOtpReqBody {
    userGuid: string;
    contactType: string;
    contactValue: string;
    token: string;
}

export interface ResendOtpReqBody {
    guid: string;
    entityType: string;
    entityValue: string;
}

export interface ConfirmOrderDetails {
    emailId: string;
    orderId: string ;
}
export interface SendInvitesReqBody{
    profileGuid: string;
    userEmails: string[];
}

export interface RegisterContext{
    profileName?: string;
    user: User;
    profileGuid?: string;
	userEmail?: string;
	loggedInUserType?: string;
    existingCustomer?: boolean;
    serviceAddress?: string;
    hierarchyCode?: RegistrationCode | NonAlignedHierarchy | AlignedHierarchy;
}

export interface ResendInvitesReqBody{
    userEmails: string[];
    token: string;
}

export enum RegistrationSteps {
    VERIFY_ORDER = 'verifyorder',
    VERIFY_EMAIL = 'verifyemail',
    VERIFY_PROFILE = 'verifyprofile',
    VERIFY_MOBILE_NUMBER = 'verifymobilenumber',
    CREATE_PASSWORD = 'createpassword',
    REGISTER_LOGIN = 'registerlogin'
}

export const registrationFlowSteps = [
    RegistrationSteps.VERIFY_ORDER,
    RegistrationSteps.VERIFY_EMAIL,
    RegistrationSteps.VERIFY_PROFILE,
    RegistrationSteps.VERIFY_MOBILE_NUMBER,
    RegistrationSteps.CREATE_PASSWORD,
    RegistrationSteps.REGISTER_LOGIN
];

export enum AlignedHierarchy {
    ALIGNED_END_USER = '003',
    ALIGNED_PO_PA = '004',
}

export enum NonAlignedHierarchy {
    NON_ALIGNED_USER = '005',
    NON_ALIGNED_USER_NOT_FOUND = '006'
}

export enum RegistrationCode {
    ALIGNED_SOFT_ASSOSCIATION_WITH_REGISTERED_OR_ACTIVE_STATUS = '001',
    SOFT_ASSOSCIATION_END_USER = '003',
    SOFT_ASSOSCIATION_PO_PA = '004',
    NON_ALIGNED_USER = '005',
    NON_ALIGNED_USER_NOT_FOUND = '006'
}

export interface ConfirmAccountDetails {
    businessName: string;
    emailAddress: string;
    serviceAddress: string;
}

export enum EntityType {
    EMAIL = 'email',
    SMS = 'sms',
    PHONE = 'phone'
}

export interface ModifyEmailRequestBody {
    orderSubmitterEmailAddress: string;
    newEmailAddress: string;
}

export enum EmailAssociationCodes {
    NOT_ASSOCIATED_TO_ANY_PROFILE = '000',
    ASSOCIATED_TO_ONE_SHELL_PROFILE = '001',
    ASSOCIATED_TO_REGISTERED_OR_ACTIVE_PROFILE = '002',
    ASSOCIATED_TO_MULTIPLE_PROFILES = '003'
}