<app-custom-carousel #carousel id="carouselExampleControls" data-interval="false" interval="0" keyboard="true"
  class="carousel-position" role="banner">
 
  <ng-template ngbSlide>
    <div
      class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 bannerColor background-slide1 hidden-xs second-banner-position">
      <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2 p-0">
        <img class="img-alignment pad-bottom-p-10 pad-top-0-4" alt="covid img"
          src="assets/images/blueprint/icons/woman-shield.svg" />
      </div>
      <div class="covid-para-height col-lg-11 col-md-11 col-sm-10 col-xs-10">
        <p class="banner-covid-heading-voice mb-0 bold" aria-label="Voice Compliance Updates">Voice Compliance Updates
        </p>
        <p class="covid-para-voice">
          Cox Business is introducing improved 911 features so customers can update their dispatchable location
          information
          when using 911. <a [attr.href]="raybaumActInfo" role="link"
            aria-label="link for Customize your info that’s shared with local emergency dispatchers."
            target="_blank">Customize your info that’s shared with local emergency dispatchers.</a>
          Cox Business is also validating both originating calls and terminating calls across its voice network.
          &nbsp;<a [attr.href]="tracedActInfo" role="link"
            aria-label="link for Learning more about call validation and STIR/SHAKEN standards." target="_blank">Learn
            more about call validation and STIR/SHAKEN standards.</a>
        </p>
      </div>
    </div>
    <div
      class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 bannerColor background-slide pr-0 banner-position-xs visible-xs">
      <p class="banner-covid-heading-mobile mb-0 bold" aria-label="Voice Compliance Updates">Voice Compliance Updates
      </p>
      <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-0">
        <p class="covid-para-mobile"> Cox Business is introducing improved 911 features so customers can update their
          dispatchable location information
          when using 911.&nbsp;<a [attr.href]="raybaumActInfo" role="link"
            aria-label="link for Customize your info that’s shared with local emergency dispatchers."
            target="_blank">Customize your info that’s shared with local emergency dispatchers.</a>
          Cox Business is also validating both originating calls and terminating calls across its voice network.
          &nbsp;<a [attr.href]="tracedActInfo" target="_blank">Learn more about call validation and STIR/SHAKEN
            standards.</a> </p>
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="support-banner">
      <div
        class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 bannerColor background-slide2 hidden-xs second-banner-position">
        <div class="col-lg-4 col-md-3 col-sm-3 col-xs-3 p-0 mt-4 carousel-ml-10">
          <p class="banner-font-cox mb-0 bold">COX Business Complete Care<sup>SM</sup></p>
          <p class="banner-font">Small Business 24/7 IT Support</p>
        </div>
        <div class="col-lg-5 col-md-3 col-sm-3 col-xs-3 p-0 mt-3 mb-0">
          <p class="networkText mb-0">Network Troubleshooting | Virus Protection |</p>
          <p class="networkText carousel-ml-5">Device and Cloud Support | Email Support</p>
        </div>
        <div class="col-lg-1 col-md-3 col-sm-3 col-xs-3 p-0 mt-3">
          <p class="only mb-0">For only</p>
          <p class="price mb-0 bold">$29 <sup class="sup">.99</sup></p>
          <p class="per">per/mo.</p>
        </div>
        <div class=" bannerSecond col-lg-2 col-md-3 col-sm-3 col-xs-3 p-0" tabindex="0">
          <a [attr.href]="learnMoreHref" target="_blank" role="link"
            aria-label="Learn more about Cox business complete care">
            <img class="mobileapp-width learn-img-alignment pad-left-1" alt="Learn more"
              src="assets/images/blueprint/learn more button.svg" />
          </a>
        </div>
      </div>
    </div>
    <div class="row col-lg-6 col-md-6 col-sm-12 col-xs-12 bannerColor background-slide2 banner-position-xs visible-xs">
      <p class="banner-font-cox-mobile mb-0 mt-0 bold">COX Business Complete Care<sup>SM</sup></p>
      <div class="col-8 col-lg-3 col-md-3 col-sm-6 col-xs-6 p-0 mt-2">
        <p class="banner-font-mobile mb-0">Small Business 24/7 IT</p>
        <p class="banner-font-mobile mb-0">Support. Only $29.99</p>
        <p class="banner-font-mobile">per month</p>
      </div>
      <div class="col-4 col-lg-3 col-md-3 col-sm-6 col-xs-6 p-0 carousel-b-0" tabindex="0">
        <a [attr.href]="learnMoreHref" target="_blank" role="link"
          aria-label="Learn more about Cox business complete care">
          <img class="mobileapp-width pad-bottom-p-10 pad-top-0-5 pad-left-1" alt="Learn more"
            src="assets/images/blueprint/learn more button.svg" />
        </a>
      </div>
    </div>
  </ng-template>
</app-custom-carousel>