import { MicroserviceResponse } from '../../../services/coxhttpclient.service';

export interface AccountInfo {
	id: string;
	guid: string;
	legacyId: string;
	siteId: string;
	accountName: string;
	accountNumber: string;
	accountNumber9: string;
	accountNumber12: string;
	accountNumber13: string;
	accountNumber16: string;
	companyId: string;
	divisionId: string;
	alias: string;
	isCalnet: boolean;
	isHN: boolean;
	accountExternalAttrs: CBAccountExternalAttr;
	serviceFeature: CBServiceFeature;
	accountsCount?: number;
}

export interface CBAccountExternalAttr {
	serviceAddress: Address;
	status: AccountStatusEnum;
	vipCodes?: string[];
}
export interface CBServiceFeature {
	accountAuthUser: boolean;
}

export enum AccountStatusEnum {
	ACTIVE = 'ACTIVE',
	NEVER = 'NEVER',
	FORMER = 'FORMER'
}

export enum DDosFeatureStatus{
	NOT_ELIGIBLE = 'not_eligible',
	ACTIVATE = 'Activate',
	ACTIVATE_TRIAL= 'Activate_Trial',
	TRUE = 'true',
	FALSE = 'false',
	ACTIVATION_IN_PROGRESS = 'Activation in Progress',
	TRY_AGAIN = 'Try Again',
	PAWS_DOWN_ERROR = 'paws_down_error',
	WARNING = 'warning'
}

export interface Address {
	houseNumber: string;
	street: string;
	city: string;
	state: string;
	zipCode: string;
	fullAddress?: string;
}

export interface UserRoles extends MicroserviceResponse  {
    role?: string;
    userList: SwitchuserData[];
}

export interface SwitchuserData {
    email: string;
    firstName: string;
    lastName: string;
    userId: number;
    userType: string;
    requiresPasswordChange: number;
    profileAdmin: boolean;
}

export interface PageTitleAndDesc {
	title: string;
	description: string;
}
