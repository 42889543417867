import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import {
  AEMClientService, CBHelperService, CommonMessageService, CoxHttpClient,
  DeviceDetectorService, ResourceBundle
} from 'common-ui-lib';
import { AccountInfo } from 'common-ui-lib/lib/shared/redesign/pageheader/pageheader.model';
import { BankName } from 'home/billingtools/billingtools.model';
import {
  AddMethodOfPaymentInput, EnrollInAutoPayPaperlessBillingInput,
  StatementDetails
} from 'redesign/admin/account/add-account/add-account.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  Address,
  PaperlessToggleInputList, RefreshBillingDataObj, UpdateBillingAddressInput,
  UpdateMopList
} from './billing-home.model';
import { MethodOfPaymentModal } from './redesignmethodofpayments/methodofpayments.modal.component';
import { PaymentArrangementsComponent } from './payment-arrangements/payment-arrangements.component';

@Injectable({
  providedIn: 'root'
})
export class BillingHomeService {
  public error: boolean = false;
  public statementDetailsList: StatementDetails[];
  public selectedAccountDetails: AccountInfo;
  public paymentArrangementsModalRef: NgbModalRef;
  public isMobileView: boolean;
  public refreshBillingScreenObj = new RefreshBillingDataObj();
  public refreshStatementDetails = new BehaviorSubject<RefreshBillingDataObj>(this.setRefreshData(false, false));
  public selectedStatement = new BehaviorSubject<StatementDetails>(null);
  public isMultiAccount = false;
  public isMultiStatement = false;
  public resourceBundle: ResourceBundle = {};
  public modalRef = null;
  public isFormerAccount = new BehaviorSubject<boolean>(false);
  public statementObservabledelay = 0;
  public currentMobilePage = 'home';
  public autopayMobilePages = ['autopay-off', 'autopay-on-single', 'autopay-on-multi', 'autopay-add-mop'];
  public payArrangeFlow = false;
  public payArrangementModalClose = false;
  public showInteractiveBill = false;
  public autopaySearchString = null;
  public showDelete = false;
  private accountDetails = new BehaviorSubject<AccountInfo>(null);
  private onDestroy$ = new Subject<boolean>();
  constructor(private apollo: Apollo, public coxhttp: CoxHttpClient, private modalService: NgbModal,
    public commonMessageService: CommonMessageService, private aemClient: AEMClientService,
    public deviceService: DeviceDetectorService, private cbHelperService: CBHelperService) {
    this.aemClient.getRedesignBundle('billing/mopmodal')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => {
          this.resourceBundle = data;
        }
      );
    this.isMobileView = this.deviceService.isMobile();
  }

  public billingDetials(id: string, accountGuid: string): Observable<any> {
    const statetementDetails = gql`
      query statetementDetails($id: ID!) {
        getAccount(id: $id) {
          serviceFeature(featureCategoryName: BILLING) {
            cbServices {
              subServiceDetails {
                category
                subServiceList {
                  featureName
                  role
                  subCategory
                }
              }
            }
          }
          billStatements(requestType: "paperless") {
            statements {
              status
              code
              identifier
              totalAmountDue
              totalPastAmountDue
              isPaperlessEligible
              isAutoPayEligible
              isEnrolledInAutoPay
              billHandlingPreferenceType
              billHandlerCode
              currentDue
              amountDueToday
              nextPaymentDate
              dueDate
              statementDueDate
              billingAddress {
                houseNumber
                street
                city
                state
                zipCode
              }
              customerBusinessName
              customerName
            }
          }
          methodOfPayments(requestType: "easypay") {
            methodOfPayments{
              id
              type
              code
              sequence
              description
              customerMOPStatus
              bankAccountType
              routingCode
              customerAccountNumber
              customerName
              cardExpirationDate
              customerZipCode
              isDefaultMop
              isEasyPayMop
              isStoredMop
              statementCodes
              countryCode
            }
          }
          scheduledPayment(requestType: "ALL") {
            id
            amount
            currentState
            confirmation
            dateScheduled
            dateUpdated
            statementCode
            type
          }
          billPayInvoices {
            cycleDates {
            value
            id
          }
            listOfCycleDates
            statementCode
            stmtIdentifier
          }
        }
      }
    `;

    return this.apollo.query({
      query: statetementDetails,
      variables: {
        id
      },
    });
  }

  public getAgreementAndPromotionDetails(accountGuid: string) {
    const getAccountInfoQuery = gql`
    query getAccountDetails($accountGuid: ID!){
      getAccount(id: $accountGuid) {
        promotionDetails {
	        amount
	        description
	        currencyType
	        expirationDate
        }
        activeAgreement {
          expirationDate
          commercialServiceAgreement
        }
      }
    }`;

    return this.apollo.query({
      query: getAccountInfoQuery,
      variables: {
        accountGuid
      },
    });
  }

  public getAccountDetails(): Observable<any> {
    return this.accountDetails.asObservable();
  }

  public setAccountDetails(accountDetails: AccountInfo) {
    this.accountDetails.next(accountDetails);
  }

  public getSelectedStatements(): Observable<any> {
    return this.selectedStatement.asObservable();
  }

  public setSelectedStatements(selectedStatement: StatementDetails) {
    this.selectedStatement.next(selectedStatement);
  }

  public getIsFormerAccount(): Observable<any> {
    return this.isFormerAccount.asObservable();
  }

  public setIsFormerAccount(isformer: boolean) {
    this.isFormerAccount.next(isformer);
  }


  public getrefreshStatementDetails(): Observable<any> {
    return this.refreshStatementDetails.asObservable();
  }

  public setrefreshStatementDetails(refresh: RefreshBillingDataObj) {
    this.refreshStatementDetails.next(refresh);
  }
  public turnOnOffAutoPay(input: UpdateMopList) {
    const autoPayRole = gql`
      mutation turnOffAutoPay($input: UpdateMopList){
        updateMethodOfPayment(input: $input){
          statuses {
            responseStatus {
            code
            id
            message
            transactionId
            warning
            }
            statementCode
            }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: autoPayRole,
      variables: {
        input
      },
    });

  }

  public turnOffPaperless(guid: string, input: EnrollInAutoPayPaperlessBillingInput) {
    const unenrollPaperless = gql`
         mutation paymentDetailsMutation($guid: String! ,$input: EnrollInAutoPayPaperlessBillingInput!){
       enrollInAutoPayPaperlessBilling(guid: $guid, input:$input) {
         guid
        }
     }
    `;
    return this.apollo.mutate({
      mutation: unenrollPaperless,
      variables: {
        guid,
        input
      }
    });
  }

  public methodofPayments(id: string, request: String, disableAutoPay: boolean) {
    const methodOfPayments = gql`
  query methodOfPayments($id: ID!, $request: String!, $disableAutoPay: Boolean!) {
      getAccount(id: $id) {
        methodOfPayments(requestType: $request, disableAutoPay: $disableAutoPay) {
          restrictedMop
          isSPMAccount
          methodOfPayments{
            id
            type
            code
            sequence
            description
            customerMOPStatus
            bankAccountType
            routingCode
            customerAccountNumber
            customerName
            cardExpirationDate
            customerZipCode
            isDefaultMop
            isEasyPayMop
            isStoredMop
            statementCodes
            countryCode
          }
        }
      }
    }`;

    return this.apollo.query({
      query: methodOfPayments,
      variables: {
        id,
        request,
        disableAutoPay
      },
    });
  }

  public addMOP(input: AddMethodOfPaymentInput): Observable<any> {
    const addMOPMutation = gql`
         mutation addMOPMutation($input: AddMethodOfPaymentInput){
          addMethodOfPayment(input: $input) {
            id
            }
          }
        `;

    return this.apollo.mutate({
      mutation: addMOPMutation,
      variables: {
        input
      }
    });
  }

  public async getBankInfoDetails(routingNumber: string, accountNumber: string) {
    const updateContactInfoQuery = gql`
    mutation routingStatus($routingNumber: String!,$accountNumber: String!) {
      routingStatus(
        input: {routingNumber: $routingNumber, accountNumber: $accountNumber}) {
        bank {
          id
          name
        }
        code
        message
        transactionId
        warning
      }
    }`;
    try{
      return await this.apollo.mutate({
              mutation: updateContactInfoQuery,
              variables: {
                routingNumber,
                accountNumber
              },
            }).toPromise();
    } catch(error){
        throw error;
    }
  }

  public encryptCC(ccNumber: string, cvv: string) {
    if (this.pieEncryptionEnabled() && this.encryptCHS(ccNumber, cvv) !== null) {
      this.commonMessageService.displayMessage.show = null;
      return this.encryptCHS(ccNumber, cvv);
    } else {
      this.commonMessageService.showMessage(this.resourceBundle.encryptionerror, 'error', 'encryptionfailure');
      this.modalScroll();
      return null;
    }
  }
  public pieEncryptionEnabled(): boolean {
    return (isPieKeyDownloadError() && isPieEncryptionDownloadError()) === false;

    function isPieKeyDownloadError() {
      if ((typeof ((<any>window).PIE) === 'undefined') || (typeof ((<any>window).PIE.K) === 'undefined') ||
        (typeof ((<any>window).PIE.L) === 'undefined') || (typeof ((<any>window).PIE.E) === 'undefined') ||
        (typeof ((<any>window).PIE.key_id) === 'undefined') || (typeof ((<any>window).PIE.phase) === 'undefined')) {
        return true;
      }
      return false;
    }
    function isPieEncryptionDownloadError() {
      if ((typeof (<any>window).ValidatePANChecksum !== 'function') ||
        (typeof (<any>window).ProtectPANandCVV !== 'function')) {
        return true;
      }
      return false;
    }
  }

  public encryptCHS(ccNumber: string, cvv: string) {
    const result = (<any>window).ProtectPANandCVV(ccNumber, cvv, false); // NEVER EMBEDDING
    let encryptedCardDetails;
    if (result != null && result.length === 3) {
      encryptedCardDetails = {
        ccNumEncrypted: result[0],
        ccLast4Digits: 'xx' + ccNumber.substring(ccNumber.length - 4),
        ccCsv: result[1],
        integrityCheck: result[2]
      };
      return encryptedCardDetails;
    } else {
      return null;
    }
  }

  public async openMethodOfPaymentsListModal() {
    try {
      const modalRef = this.modalService.open(MethodOfPaymentModal,
        { windowClass: 'sch-modal-md', backdrop: 'static' });
      this.methodofPayments(this.selectedAccountDetails.guid, 'billpay', false)
        .subscribe(async (results: any) => {
          if (!results.errors) {
            modalRef.componentInstance.availableMopList = results.data.getAccount.methodOfPayments.methodOfPayments;
            modalRef.componentInstance.restrictedMop = results.data.getAccount.methodOfPayments.restrictedMop;
            modalRef.componentInstance.mopLoaded = true;
            modalRef.componentInstance.isSPMAccount = results.data.getAccount.methodOfPayments.isSPMAccount;
            modalRef.componentInstance.hasMaxNumberOfMops = (results.data.getAccount.methodOfPayments.methodOfPayments > 4) ? true : false;
          }
          if (results.errors) {
            this.modalScroll();
            const errorInfo = this.commonMessageService.getErrorInfo(results?.errors, 'methodofPayments');
            if (errorInfo) {
              this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'mopModal');
            }
            modalRef.componentInstance.mopLoaded = true;
          }
          modalRef.result.then(res => {
          });
        });
    } catch (error) {
      this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'mopModal');
      window.scroll(0, 0);
    }
  }

  public modalScroll() {
    const modalWindow = document.querySelectorAll('ngb-modal-window');
    if (modalWindow && modalWindow.length > 0) {
      modalWindow[0].scrollTop = 0;
    }
  }

  public getPhoneAndEmail(id: string, accountGuid: string) {
    const getPhoneAndEmail = gql`
       query getUser($id: ID!,$accountGuid: String) {
          getUser(id: $id) {
             login {
                contactValue
                contactType
               }
              contacts {
              contactValue
              contactType
           }
           notificationCollection(after: "0", before: null, first: 1, search: $accountGuid, type: BILLING_PAYMENT_UPDATE, last: 0) {
            edges {
            cursor
            node {
            isEmailEnabled
            isSMSEnabled
            }
            }
            }
         }
        }`;

    return this.apollo.query({
      query: getPhoneAndEmail,
      variables: {
        id,
        accountGuid
      },
    });
  }

  // Mutation  Update mailing address
  public updateMailingAddress(guid: string, input: UpdateBillingAddressInput): Observable<any> {
    const updateAddress = gql`
      mutation updateAddress($guid:String!, $input: UpdateBillingAddressInput) {
        updateBillingAddress(guid:$guid, input: $input){
          billStatements(requestType: "paperless") {
            statements {
            billingAddress {
              houseNumber
              street
              city
              state
              zipCode
            }
            customerBusinessName
          	customerName
            }
          }
        }
      }
    `;

    return this.apollo.mutate({
      mutation: updateAddress,
      variables: {
        guid,
        input,
      },
    });
  }

  /**
   * this method is used in make a payment flow
   *
   * @param restricted string which tells us restricted type
   * @returns info msg
   */
  public setrestrictedMopmsg(restricted?: string) {
    switch (restricted) {
      case 'BANK':
        return this.resourceBundle.bankRestriction;
      case 'CARD':
        return this.resourceBundle.cardRestriction;
      case 'BOTH':
        return this.resourceBundle.bothRestriction;
    }
  }

  public paperlessToggleEnrollmentMutation(input: PaperlessToggleInputList) {
    const PaperlessToggleEnrollmentMutation = gql`
      mutation paperlessToggleEnrollment($input: PaperlessToggleInputList!) {
        paperlessToggleEnrollment(input: $input) {
          statuses {
            statementCode
            responseStatus {
              code
              message
              transactionId
              warning
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: PaperlessToggleEnrollmentMutation,
      variables: {
        input
      },
    });
  }

  public getPinpointAlert(accountStatusFilter: string) {

    const getPinpointQuery = gql`

    query getProfile($accountStatusFilter: String!){
        getProfile(accountStatusFilter: $accountStatusFilter) {
          pinpointAlerts {
            indicator
            alertsList {
              action
              message
            }
          }
        }
    }
`;

    return this.apollo.query({
      query: getPinpointQuery,
      variables: {
        accountStatusFilter
      },
    });
  }

  public deleteScheduledPayment(input: string) {
    const deleteScheduledPaymentMutation = gql`
  mutation deleteScheduledPayment($input: String!) {
    deleteScheduledPayment (input: $input) {
      code
      id
      message
      warning
    }
  }
  `;
    return this.apollo.mutate({
      mutation: deleteScheduledPaymentMutation,
      variables: {
        input
      },
    });
  }

  public viewPaymentArrangements(error?: boolean) {
    this.error = error;
    this.currentMobilePage = 'payment-arrangements';
    if (!this.isMobileView) {
      this.paymentArrangementsModalRef = this.modalService
        .open(PaymentArrangementsComponent, { windowClass: 'redesign-modal-sm', backdrop: 'static' });
      this.paymentArrangementsModalRef.componentInstance.error = error;
      this.paymentArrangementsModalRef.result.then((result) => {
        if (!this.payArrangementModalClose && result !== 'SCHP') {
          this.commonMessageService.showMessage(this.resourceBundle.scheduledFuturePaySuccessMsg,
            'success', 'billingHomeOverlay');
        }
      });
    }
  }

  /**
   *
   * @param statements refresh statement details
   * @param transactions refrsh Transaction history
   * @returns
   */
  public setRefreshData(statements: boolean, transactions: boolean) {
    this.refreshBillingScreenObj.refreshTransactions = transactions;
    this.refreshBillingScreenObj.refreshstatements = statements;
    return this.refreshBillingScreenObj;
  }

  /**
   * service addresss formatter with necessary seperator
   *
   * @param seperator (, \ ' ' \ ', ')
   * @returns
   */
  public selectedAddressFormat(address: Address) {
    const consolidatedServiceAddress =
      this.cbHelperService.replaceNull(address?.street) + this.cbHelperService.replaceNull(address?.houseNumber) +
      this.cbHelperService.replaceNull(address?.city) + this.cbHelperService.replaceNull(address?.state, true) +
      this.cbHelperService.replaceNull(address?.zipCode, true);
    return consolidatedServiceAddress;
  }

  /**
    * Open delete confirmation tooltip
    *
    * @param action yes/no
    * @returns
    */
  public opendeletepop(action: string, mop?: any) {
    if (action === 'yes') {
      this.showDelete = true;
    }
  }
}
