<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12">
    <div id="account-detailslist-gt7" class="col-xs-12 col-md-12 accordion-trigger-standalone-MALP card" [ngClass]="{ 'accordion-trigger-standalone-open-MALP': toggleAccordian }">
      <span id="selectedaccountdivwrappergt7" *ngIf="accountselected; then selectedaccountdiv"></span>
      <ng-template #selectedaccountdiv>
        <div (click)="showFilters()" id="selectedaccountdivgt7" class="accordion-trigger-standalone-table col-md-12 col-sm-12 col-xs-12 selected landscape"
          [ngClass]="{ 'hidden': toggleAccordian }">
          <table class="accTable  table-layout  visible-sm visible-md visible-lg"  tabindex="0" role="text">
            <caption class="sr-only">Account Table</caption>
            <tr aria-hidden="true">
              <th class="pad-bottom-0-5">{{resourceBundle.accountName}}</th>
              <th class="pad-bottom-0-5">{{resourceBundle.accountAlias}}</th>
              <th class="pad-bottom-0-5">{{resourceBundle.accountNumber}}</th>
              <th class="pad-bottom-0-5">{{resourceBundle.locationAddress}}</th>
            </tr>
            <tr>
              <td data-account-name [title]="accountname"><p class="sr-only" aria-label="Account Name" role="text"></p>{{ accountname }}</td>
              <td class="truncate" data-account-alias [title]="accountalias"><p class="sr-only" aria-label="Account Alias" role="text"></p>{{ accountalias }}</td>
              <td data-account-number [title]="accountnumber"><p class="sr-only" aria-label="Account Number" role="text"></p>{{ accountnumber }}</td>
              <td class="truncate" [title]="accountaddress"><p class="sr-only" aria-label="Location Address" role="text"></p>{{ accountaddress| truncate:['21', '...'] }}</td>
            </tr>
          </table>
          <div id="xs-selected-account" class="visible-xs col-xs-12">
            <div class="col-xs-12 margin-left-2">
              <span data-account-name>{{ accountname }}</span>
            </div>
            <div class="col-xs-12 margin-left-2 break-word">
              <span data-account-alias>{{ accountalias }}</span>
            </div>
            <div class="col-xs-12 margin-left-2">
              <span data-account-number>{{ accountnumber }}</span>
            </div>
            <div class="col-xs-12 margin-left-2">
              <span data-account-address1>{{ accountaddress1 }}</span>
            </div>
            <div class="col-xs-12 margin-left-2">
              <span data-account-address2>{{ accountaddress2 }}</span>
            </div>
            <div class="col-xs-12 margin-left-2">
              <span data-account-addresspostal>{{ accountaddresspostal }}</span>
            </div>
          </div>
        </div>
      </ng-template>
      <div [ngClass]="{ 'hidden': showselect }">
           <span class="h2" (click)="toggleAccordion();expandWidget();" aria-controls="accountWidgetSection" tabindex="0" aria-expanded="false" id="account-detailslist-gt7-accordian-title" aria-labelledby="selectAnAccount numberofaccounts accounts" role="button" >{{resourceBundle.selectAnAccount}}
            <span class="sr-only"> with </span> 
            <span class="hidden-md">({{ numberofaccounts }})</span>
          <span class="pull-right black pad-right-1 hidden-xs hidden-xs-landscape-visible">{{ numberofaccounts }} {{resourceBundle.accounts}}</span>
        </span>
        <div [ngClass]="{ 'hidden': !toggleAccordian }" id="accountWidgetSection" aria-labelledby="account-detailslist-gt7-accordian-title">
          <div id="lookuphelptext" class="col-xs-12 col-sm-12 col-md-12">
            <p>{{resourceBundle.searchAccountText}}</p>
            <p>{{resourceBundle.selectAccountBox}}</p>
          </div>
          <div id="filterOptionsWrapper" class="col-xs-12 col-sm-12 col-md-12 text-center">
            <div id="filterOptions" class="radio-box display-inline-block centered">
                <fieldset>
                  <legend class="hidden">Installation Type</legend>
                  <input id="accountnum" type="radio" name="filterType" value="an" [checked]="filterType == 'an'" (click)="displayFilterText('an')">
                  <label for="accountnum">
                    <span role="button" tabindex="0" (click)="selectAccNo()" aria-label="Account Number Selected" id="accNumber">{{resourceBundle.account}}<wbr>{{resourceBundle.number}}</span>
                    </label>
                  <div class="separator">&nbsp;</div>
                  <input id="address" type="radio" name="filterType" value="ad" [checked]="filterType == 'ad'" (click)="displayFilterText('ad')">
                  <label for="address"> 
                    <span role="button" tabindex="0" (click)="selectAddr()" id="accAddress">{{resourceBundle.address}}</span>
                  </label>
                  <div class="separator">&nbsp;</div>
                  <input id="accountalias" type="radio" name="filterType" value="aa" [checked]="filterType == 'aa'" (click)="displayFilterText('aa')">
                  <label for="accountalias">
                    <span role="button" tabindex="0" (click)="selectAlias()" id="accAlias">{{resourceBundle.account}}<wbr>{{resourceBundle.alias}}</span>
                  </label>
                  <div class="separator">&nbsp;</div>
                  <input id="accountname" type="radio" name="filterType" value="aname" [checked]="filterType == 'aname'" (click)="displayFilterText('aname')">
                  <label for="accountname">
                    <span role="button" tabindex="0" (click)="selectName()" id="accName">{{resourceBundle.account}}<wbr>{{resourceBundle.name}}</span>
                    </label>
                </fieldset>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12">
              <p id="filterOptionsText">
                <span *ngIf="isSelected('an')" role="alert">{{resourceBundle.filterOptionAccountNumber}}</span>
                <span *ngIf="isSelected('ad')" role="alert">{{resourceBundle.filterOptionAddress}}</span>
                <span *ngIf="isSelected('aa')" role="alert">{{resourceBundle.filterOptionAccountAlias}}</span>
                <span *ngIf="isSelected('aname')" role="alert">{{resourceBundle.filterOptionAccountName}}</span>
              </p>
            </div>
          </div>
          <div id="searchCriteriaHelperText" class="col-xs-12 col-sm-12 col-md-12">
            <p>{{resourceBundle.enter3CharsSearch}}</p>
          </div>
          <div id="searchCriteriaWrapper" class="col-xs-12 col-sm-12 col-md-12">
            <label for="searchCriteria" class="hidden">Input for account search</label>
            <input type="text" id="searchCriteria" autofocus  value="" class="mb-20" [(ngModel)]="inputEntered" (ngModelChange)="searchBaseAccountsList($event)"
             tabindex="0" (keyup)="enterSearch($event)"/>
          </div>
          <div id="searchCriteriaInfoWrapper" class="col-xs-12 col-sm-12 col-md-12" role="text" aria-live="true">
            <p id="searchResultsInfo" [ngClass]="{'hidden': !resultsLength}">
              {{resourceBundle.showing}}
              <b> {{resultsDisplayed}}</b> {{resourceBundle.of}} {{resultsLength}} {{resourceBundle.resultsReturned}}
              <span *ngIf="resultsDisplayed < resultsLength">{{ resourceBundle.seemoreresults }}</span>
            </p>
          </div>
          <div id="searchResults" class="position-relative">
            <div id="huge" class="wide100 xs-gray-bg pull-left md-no-bottom-border">
              <div *ngIf="oddAccountsFlag; then oddAccountsTemp"></div>
              <div *ngIf="evenAccountsFlag; then evenAccountsTemp"></div>
              <div *ngIf="allAccountsFlag; then allAccountsTemp"></div>
            </div>
          </div>
          <div id="pagingWrapper" class="col-xs-12 col-sm-12 col-md-12">
              <div id="nomatches" class="msg-default col-xs-12 col-sm-12 col-md-12 row" [ngClass]="{'hidden': noResults}">
                <span class="msg-alert" tabindex="0" role="alert">
                  <span>{{resourceBundle.noResultsMessage}}
                  </span>
                </span>
              </div>
              <span id="seeMore" tabindex="0" role="link" class="link-enabled" [ngClass]="{'hidden': !(resultsDisplayed < resultsLength)}" (click)="seeMoreResults()">{{resourceBundle.seeMore}}</span>
              <span id="searchAgain" tabindex="0" role="link" class="link-enabled" [ngClass]="{'hidden': !(resultsDisplayed !=0 && resultsDisplayed === resultsLength)}"
                (click)="searchAgain()">{{resourceBundle.searchAgain}}</span>
            </div>
        </div>
      </div>
    </div>
    <ng-template #oddAccountsTemp>
      <div id="multi-col-2" class="hidden-xs col-sm-6 col-md-6">
        <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of oddAccounts;" (click)="displayAccountSelected(baseAccount);"
          id="oddAccountsTemp_{{baseAccount.accountId}}" aria-label="Account Details" tabindex="0" [ngClass]="{'selected': baseAccount.accountId === accountid}">
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
            <div class="col-xs-12">
              <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
            <div class="col-xs-12">
              <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-address-postal title="{{baseAccount.address.city}},  
                    {{baseAccount.address.state}} {{baseAccount.address.zip}}">
                {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
            </div>
          </div>
            <div id="select_oddAccountsTemp_{{baseAccount.accountId}}" class="col-xs-12 col-md-12 col-sm-12 pad-0">
              <a class="pull-right pad-right-1" name="selectName_oddAccountsTemp_{{baseAccount.accountId}}">
                <b class="selected">{{resourceBundle.select}}</b>
              </a>
            </div>
         
        </div>
      </div>
    </ng-template>
    <ng-template #evenAccountsTemp> 
      <div id="multi-col-1" class="hidden-xs col-sm-6 col-md-6">
        <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of evenAccounts;" (click)="displayAccountSelected(baseAccount)"
          id="evenAccountsTemp_{{baseAccount.accountId}}" aria-label="Account Details" tabindex="0" [ngClass]="{'selected': baseAccount.accountId === accountid}">
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
            <div class="col-xs-12">
              <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
            <div class="col-xs-12">
              <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-address-postal title="{{baseAccount.address.city}},  
                    {{baseAccount.address.state}} {{baseAccount.address.zip}}">
                {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
            </div>
          </div>
            <div id="select_evenAccountsTemp_{{baseAccount.accountId}}" class="col-xs-12 col-md-12 col-sm-12 pad-0">
              <a class="pull-right pad-right-1" name="selectName_evenAccountsTemp_{{baseAccount.accountId}}">
                <b class="selected">{{resourceBundle.select}}</b>
              </a>
            </div>
          
        </div>
      </div>
    </ng-template>
    <ng-template #allAccountsTemp>
      <div id="multi-col" class="visible-xs-block col-xs-12">
        <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of allAccounts;" (click)="displayAccountSelected(baseAccount)"
          id="allAccountsTemp_{{baseAccount.accountId}}" aria-label="Account Details" tabindex="0" [ngClass]="{'selected': baseAccount.accountId === accountid}">
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
            <div class="col-xs-12">
              <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
            <div class="col-xs-12">
              <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
            </div>
            <div class="col-xs-12">
              <span data-account-address-postal title="{{baseAccount.address.city}},  
                        {{baseAccount.address.state}} {{baseAccount.address.zip}}">
                {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
            </div>
          </div>
            <div id="select_allAccountsTemp_{{baseAccount.accountId}}" class="col-xs-12 col-md-12 col-sm-12 pad-0">
              <a class="pull-right pad-right-1" name="selectName_allAccountsTemp_{{baseAccount.accountId}}">
                <b class="selected">{{resourceBundle.select}}</b>
              </a>
            </div>
          
        </div>
      </div>
    </ng-template>
  </div>
</div>