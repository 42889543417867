import { Component, Input, OnInit} from '@angular/core';
import { DeviceDetectorService } from 'common-ui-lib';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-page-hero',
  templateUrl: './pagehero.component.html',
  styleUrls: ['./pagehero.component.scss'],
})
export class PageHeroComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() contentCenter = false;
  @Input() hideRule = false;
  @Input() indicator = false;
  @Input() status: string;
  @Input() featureName: string;
  @Input() hasWelcomeWidget = true;
  constructor(public deviceService: DeviceDetectorService){ }
  ngOnInit() {
    if (this.hasWelcomeWidget === false) {
     document.getElementById('headingLevel').setAttribute('aria-level', '1');
    } else {
      document.getElementById('headingLevel').setAttribute('aria-level', '2');
    }
  }
}
