
import { AfterViewInit, Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AEMClientService, DeviceDetectorService, Regex, ResourceBundle } from 'common-ui-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageDetail, PaginationOptions } from './paginationinput.model';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit,DoCheck, OnDestroy {
  @Input() options: PaginationOptions;
  @Input() nonCentredPaginator = false;
  @Input() currentPage: number;
  @Input() curPage: number;
  @Input() pageSize: number;
  @Input() dataTable: DatatableComponent;
  @Input() totalRows: number;
  @Input() serverPaginate = true;
  @Output() paginationResult = new EventEmitter<any>();

  public isDesktop: boolean;
  public isMobile: boolean;
  public isTablet: boolean;
  public isLargeScreen: boolean;
  public selectedPage: number;
  public resourceBundle: ResourceBundle = {};
  private onDestroy$ = new Subject<boolean>();
  private tableLoaded = false;
  public pageOptions = [];
  constructor(public deviceDetector: DeviceDetectorService,
    private aemClient: AEMClientService) { }

  ngOnInit(): void {
    this.aemClient.getSharedBundle('paginationlookup')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
          this.pageOptions = this.resourceBundle.itemsOptions;
          if(this.options.itemsPerPage < 10){
            this.pageOptions.unshift(this.options.itemsPerPage);
          }
        },
        error => { }
      );
      this.isDesktop = this.deviceDetector.isDesktop();
      this.isMobile = this.deviceDetector.isMobile();
      this.isTablet = this.deviceDetector.isTablet();
      this.isLargeScreen = this.deviceDetector.isLargeScreen();
  }

  ngDoCheck() {
    if (this.options.datatableLoaded !== this.tableLoaded) {
      this.tableLoaded = true;
      setTimeout(this.setTooltip, 50, this.options.maxSize);
    }
    const previousPage = document.querySelector('li.pagination-previous')?.children[0];
    previousPage?.setAttribute('aria-label', 'Previous Page');
    const nextPage = document.querySelector('li.pagination-next')?.children[0];
    nextPage?.setAttribute('aria-label', 'Next Page');
  }

  /**
   * page click event emits page details
   *
   * @param event
   */
  public onPageChanges(event: number): void {
    this.handleTooltip();
    const pageDetails: PageDetail = {
      startIndex: (event - 1) * this.options.itemsPerPage + 1,
      endIndex: event * this.options.itemsPerPage,
      currentPage: event,
      itemsPerPage: this.options.itemsPerPage
    };
    this.paginationResult.emit(pageDetails);
    this.setPaginationFocus();
  }

  /**
   * change event on items option changes
   *
   * @param event
   */
  public pageItemCount(event: any): void {
    this.selectedPage = null;
    const pageDetails: PageDetail = {
      startIndex: 1,
      endIndex: parseInt(event),
      currentPage: 1,
      itemsPerPage: parseInt(event)
    };
    this.paginationResult.emit(pageDetails);
    this.handleTooltip();
  }

  /**
   * On Goto Click, emits page details
   */
  public onGoToPageClick(): void {
    const count = Math.ceil(this.calculateRowCount());
    if (!this.selectedPage) {
      if (!this.options.currentPage) {
        this.options.currentPage = 0;
        this.selectedPage = 1;
      } else {
        this.selectedPage = this.options.currentPage + 1;
      }
    }
    if (this.selectedPage > count) {
      this.selectedPage = count;
    }
    if(new RegExp(Regex.ONLY_ZEROS_REGEX).test(this.selectedPage.toString())){
      this.selectedPage = 1;
    }
    const pageDetails: PageDetail = {
      startIndex: (this.selectedPage - 1) * this.options.itemsPerPage + 1,
      endIndex: this.selectedPage * this.options.itemsPerPage,
      currentPage: this.selectedPage,
      itemsPerPage: this.options.itemsPerPage
    };
    this.handleTooltip();
    this.paginationResult.emit(pageDetails);
    this.selectedPage = null;
  }

  /**
   * method used to create a span for tooltip on hover/focus
   * @param size no. of pages shown
   */
  public setTooltip(size: number): void {
    const count = Math.ceil((size - 4) / 2);
    const parent = document.querySelector('.ngx-pagination li:nth-child(4)');
    if (parent?.classList.contains('ellipsis')) {
      const _tempAnchor = parent?.children[0];
      if (!_tempAnchor?.classList.contains('tooltipped')) {
        _tempAnchor?.classList.add('tooltipped');
        _tempAnchor?.children[1]?.classList.add('hideellipse');
        const _node2 = document.createElement('span');
        _node2?.setAttribute('class', 'tooltiptext');
        const _textnode1 = (count === 1) ?  document.createTextNode('Previous ' + count + '  Page') :  document.createTextNode('Previous ' + count + '  Pages');
        _node2?.appendChild(_textnode1);
        _tempAnchor?.appendChild(_node2);
      }
    }

    const parent2 = document.querySelector('.ngx-pagination li:nth-last-child(3)');
    if (parent2?.classList.contains('ellipsis')) {
      const _tempAnchor = parent2?.children[0];
      if (!_tempAnchor?.classList.contains('tooltipped')) {
        _tempAnchor?.classList.add('tooltipped');
        _tempAnchor?.children[1]?.classList.add('hideellipse');
        const _node2 = document.createElement('span');
        _node2?.setAttribute('class', 'tooltiptext');
        const _textnode1 = (count === 1) ?  document.createTextNode('Next ' + count + ' Page') :  document.createTextNode('Next ' + count + ' Pages');
        _node2?.appendChild(_textnode1);
        _tempAnchor?.appendChild(_node2);
      }
    }
  }
  /**
   * clearout the span of tooltip 
   */
  public resetMsg() {
    const parent = document.querySelector('.ngx-pagination li:nth-child(4)');
    const _tempAnchor = parent?.children[0];
    _tempAnchor?.classList?.remove('tooltipped');
    _tempAnchor?.children[1]?.classList?.remove('hideellipse');
    _tempAnchor?.children[2]?.classList?.contains('tooltiptext') ? _tempAnchor?.children[2]?.remove() : null;

    const parent2 = document.querySelector('.ngx-pagination li:nth-last-child(3)');
    const _tempAnchor2 = parent2?.children[0];
    _tempAnchor2?.classList?.remove('tooltipped');
    _tempAnchor2?.children[1]?.classList?.remove('hideellipse');
    _tempAnchor2?.children[2]?.classList?.contains('tooltiptext') ? _tempAnchor2?.children[2]?.remove() : null;

  }

  // Set focus on paginator on click of Page links
  public setPaginationFocus(){
    if(this.options.currentPage === 1){
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ele: any = document.querySelector('li.pagination-next')?.children[0];
        ele?.focus();
      });
    } else {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ele: any = document.querySelector('li.pagination-previous')?.children[0];
        ele?.focus();
      });
    }
  }

  /**
   * 
   * @returns number of pages for th records in table /div
   */
  private calculateRowCount(): number {
    const pages = (this.options.totalItems) ? (this.options.totalItems / this.options.itemsPerPage) :
      (this.options.clientSideTotalRecords / this.options.itemsPerPage);
    return pages;
  }
  /**
   * helper method to reset & set tooltip, as its calculated on each click
   */
  private handleTooltip() {
    this.resetMsg();
    setTimeout(this.setTooltip, 50, this.options.maxSize);
  }

  ngOnDestroy() {
    this.tableLoaded = false;
  }
}
