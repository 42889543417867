<ng-container *ngIf="!isMobileDevice">
    <div href="javascript:void(0)" id="main-menu" [attr.aria-label]="'Hi, '+ menuItems?.firstName +' '+'More Menu expanded'"
        [ngbPopover]="mainMenuPopover" [placement]="popoverPlacement" #mainMenuLinkPopover="ngbPopover" role="button"
        [autoClose]="true" popoverClass="mainMenuLinkPopover" (hidden)="cbHeaderService.showMainMenu=false">
            <img src="assets/images/blueprint/cb_header/profile.svg" alt="profile icon" role="presentation"
                class="mb-2 pr-2" />
            <span class="font-size-p-14 font-weight-5 line-height-18 black">Hi, {{menuItems?.firstName | truncate:['10', '...']}}</span>
            <img src="assets/images/blueprint/cb_header/arrow_up.svg" alt="profile icon" role="presentation"
                class=" mb-2 pl-2 mt-1 mr-2" />
    </div>
    <div id="closeMsgs" class="sr-only" aria-live="polite"></div>
</ng-container>

<ng-container *ngIf="deviceService.isMobile()">
    <ng-container *ngTemplateOutlet="mainMenuPopover"></ng-container>
</ng-container>

<div class="margin-top-10px">
    <ng-template #mainMenuPopover>
        <div [ngClass]="{'mobile-view':deviceService.isMobile() ,'collection-of-items': !deviceService.isMobile()}"
            [style.left.px]="leftAlignmentInPixel" aria-hidden="true">
            <ul>
                <div *ngFor="let item of menuItems?.headerOptions"
                    class="text-font-16 lh-22 font-cerapro-regular font-weight-5"
                    [ngClass]="{'listDarkBackground' :  darkBackgroundArr.includes(item?.name)}"
                    (keydown)="outOfMegaMenu($event, item?.name)">
                    <li class="p-1" *ngIf="(item?.name && item?.enabled) &&  !((item?.name === 'VIEW_AND_PAY_BILL') && (digitalService.isConsolidated))">
                        <a href="javascript:void(0)" class="ml-3 d-block"
                            (click)="navigateOnClick(item?.url,item?.openInNewWindow,item?.name)">{{item.displayName}}</a>
                    </li>
                </div>
            </ul>
        </div>
    </ng-template>
</div>