import { Component, Input, OnInit } from '@angular/core';
import { CBHelperService, ResourceBundle } from 'common-ui-lib';
import { ContactMethod, ContactMethodType, GenerateOtpRequest, StepNum } from '../../reset-password.model';
import { ResetPasswordService } from '../../reset-password.service';

@Component({
  selector: 'app-scanid-contact-method',
  templateUrl: './scanid-contact-method.component.html',
  styleUrls: ['./scanid-contact-method.component.scss']
})
export class ScanidContactMethodComponent implements OnInit {

  @Input() public resourceBundle: ResourceBundle = {};
  public contactMethod = ContactMethod;
  public isMobile: boolean;
  public displayContactMethods: boolean;
  public showSmsMethod: boolean;
  public showEmailMethod: boolean;
  public isMyadmin: boolean;
  public contactMethodType = ContactMethodType;
  public otpPhoneValue: string;
  public otpEmailValue: string;

  constructor(public resetPasswordService: ResetPasswordService,
              public cbHelperService: CBHelperService) { }

  ngOnInit() {
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    this.resetPasswordService.verificationMethodsResponse?.verificationMethodsList?.forEach(verificationMethod => {
      if (verificationMethod.contactmethod === ContactMethod.authenticid) {
       this.displayContactMethods = verificationMethod.enabled;
       verificationMethod?.contacttype?.forEach(contType => {
        if (ContactMethodType.sms === contType?.type) {
          this.showSmsMethod = verificationMethod.enabled;
          this.otpPhoneValue = contType?.maskedvalue;
        }
        if (ContactMethodType.email === contType?.type) {
          this.showEmailMethod = verificationMethod.enabled;
          this.otpEmailValue = contType?.maskedvalue;
        }
      });
      }
    });
    this.isMobile = this.resetPasswordService.isMobile;
    this.isMyadmin= this.cbHelperService.isMyAdmin();
    document.getElementById('closeBtn').focus();
  }

  public async generateOTP(method: string, type: string) {
    let contactValue: string;
    this.resetPasswordService.resetPwdMsg.hideMessage();
    this.resetPasswordService.verificationMethodsResponse?.verificationMethodsList?.forEach(verificationMethod => {
      if (method === verificationMethod?.contactmethod){
        verificationMethod?.contacttype?.forEach(contType => {
          if (type === contType?.type) {
            contactValue = contType?.value;
            this.resetPasswordService.maskedScanIdContact = contType?.maskedvalue;
          }
        });
      }
    });

    const reqBody: GenerateOtpRequest = {
      contactmethod: method,
      contacttype: type,
      userEmail: this.resetPasswordService.userEmail,
    };
    this.resetPasswordService.generateOtpRequest = reqBody;
    try {
      const data = await this.resetPasswordService.generateOtp(reqBody);
      if (data.code === '0') {
        this.resetPasswordService.authenticid = data['id'];
        this.resetPasswordService.currentStep = StepNum.step2b;
        this.resetPasswordService.modalHeading  = this.resourceBundle.idScan;
      } else{
        this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.generateIdScanError,
           'error', 'resetPwdError');
        this.resetPasswordService.modalScroll();
      }
    } catch (error) {
      this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.generateIdScanError,
         'error', 'resetPwdError');
      this.resetPasswordService.modalScroll();
    }
  }
}
