import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CommonMessageService, CBHelperService } from 'common-ui-lib';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import gql from 'graphql-tag';

@Injectable()
export class DefaultHeaderService {

    public contentLoaded = false;
    public unreadCount: string;
    public isSwapAlert = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public messagesList: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getProfileResponse = new BehaviorSubject<any>(null);
    // Observable string sources
    // private initMethodCall = new Subject<any>();
    constructor(private apollo: Apollo, public commonMessageService: CommonMessageService,
         public cbHelperService: CBHelperService) { }

    public getOutageAlert(accountStatusFilter: string) {
        const profileGuid = this.cbHelperService.getLoggedInUserDetails().profile.userToken.profileGuid;
        const getOutageAlertQuery = gql`
        query getProfile($accountStatusFilter: String!,$profileGuid: String!){  
            getProfile(accountStatusFilter: $accountStatusFilter) {
              deviceInstallDetails {
                selfInstallStatus
                euAcctGuid
              }
              indicatorEvents {
                plannedOutageIndicator
                promotionalOutageIndicator
            }
                unplannedOutageIndicator
                pinpointAlerts {
                    indicator
                    alertsList {
                        action
                        message
                    }
                }
                headerTicketList {
                    ticketsCount
                    ticketsList {
                      workOrderNumber
                      truckRoll
                      statusType
                      requestType
                      id
                      createDateTime
                    }
                }
                bwGroupId
                bwSpId
                companyName
                guid
                id
                isMultiAccount
                profileStatus(profileGuid: $profileGuid) {
                  hasActiveAccount
                  hasFormerAccount
                }
                hasServiceAppointment(profileGuid: $profileGuid) {
                  accountGuid
                  date
                  isMultiple
                  time
                }
                serviceFeature(featureCategoryName: ALL) {
                    cbFeatures {
                      userAccountsFlag
                      featuresList {
                        featureName
                        role
                      }
                    }
                    cbServices {
                      voiceService
                      tvService
                      securityService
                      networkingService
                      internetService
                      appService
                      subServiceDetails {
                        category
                        subServiceList {
                          subCategory
                          featureName
                          role
                        }
                      }
                    }
                    accountAuthUser
                }
                support {
                    noCaas
                    cbCare {
                      description
                      passCode
                      title
                    }
                }  
            }
        }
    `;

        return this.apollo.query({
            query: getOutageAlertQuery,
            variables: {
                accountStatusFilter,
                profileGuid
            },
        });
    }

    public getProfileDetails(): Observable<unknown> {
      return this.getProfileResponse.asObservable();
    }

    public setProfileDetails(response: unknown) {
      this.getProfileResponse.next(response);
    }
}
