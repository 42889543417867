import { Injectable } from '@angular/core';
import {
  VoiceFileConversionRequest,
  VoiceFileConversionResponse
} from './voice-conversion.model';
import { Observable } from 'rxjs/Observable';
import { CoxHttpClient } from 'common-ui-lib';

@Injectable()
export class VoiceConversionService {

  constructor(private coxhttpClient: CoxHttpClient) { }

  public convertAudioFile(payload: VoiceFileConversionRequest): Observable<VoiceFileConversionResponse> {
    const converAudioFileurl = '/api/cbma/voicecallmanagement/services/voicecallmanagement/v1/musiconhold/convertaudiofile';
    return this.coxhttpClient.post<VoiceFileConversionResponse>(converAudioFileurl, payload);
  }
}
