import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService, AppConfig, CBHelperService, CommonMessageService, LocalStorageKeys, ResourceBundle, Role,
  SSOService, WebexactivationService, DeviceDetectorService, StringUtilService, TealiumUtagService
} from 'common-ui-lib';
import { SSOPageName } from 'common-ui-lib/lib/constants/ssopagename.model';
import { DataToolsService } from 'redesign/sub-landing/data-tools/data-tools.service';
import {
  IpAddressManagerModalComponent
} from 'redesign/sub-landing/data-tools/ip-address-manager-modal/ip-address-manager-modal.component';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-localstorage';
import { DDosFeatureStatus } from '../pageheader/pageheader.model';


@Component({
  selector: 'app-featurecards',
  templateUrl: './featurecards.component.html',
  styleUrls: ['./featurecards.component.scss']
})
export class FeaturecardsComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('ddosDisableModal') public ddosDisableModal: NgbModalRef;
  @Input() accountNumber12: string;
  @Input() selectedAccountDetails: any;
  @Output() viewIpAddressMobile = new EventEmitter<boolean>();
  @Input() getFeatureArr: any[] = [];
  @Input() title: string;
  @Input() description: string;
  @Input() category: string;
  @Input() ddosFeatureStatus: DDosFeatureStatus;
  public resourceBundle: ResourceBundle = {};
  public configRoles: any = [];
  public featuresList: any = [];
  public featuresListLength: number;
  public webex: boolean;
  public isMyadmin = false;
  public url: any;
  public searchObj: any;
  public isMobileDevice = false;
  public isTabletDevice = false;
  public categoryName: string;
  public loggedInUserEmailId: string;
  public ddostile: any={};
  public ddosErrorMsg: string;
  public toggleStatus: boolean;
  public toggleCheck: boolean;
  public closeResult: string;
  public clearInterval = false;
  public spinnerFlag = false;
  public ddosFeature: string;
  public accountGuid: string;
  public ddosStatus = DDosFeatureStatus;
  public focusFlag: boolean;
  public installedWorkOrder = false;
  public ddosErrorMsgFlag: boolean;
  public createWorkOrderErrorMsg: boolean;
  public ddosBisErrorMsg: string;
  public noFeatureDisplay: boolean;
  private subscriptions: Subscription[] = [];
  private ddosTileStatusSubscription: Subscription;
  private getDDosStatusSubscription: Subscription;
  private onDestroy$ = new Subject<boolean>();

  constructor(public aemClient: AEMClientService,
    public commonMessageService: CommonMessageService,
    public cbHelper: CBHelperService,
    public stringUtilService: StringUtilService,
    private ssoService: SSOService,
    private deviceService: DeviceDetectorService,
    private webexactivationService: WebexactivationService,
    private modalService: NgbModal,
    private dataToolsService: DataToolsService,
    private _storageService: LocalStorageService,
    private config: AppConfig,
    private router: Router,
    private readonly tealium: TealiumUtagService
    ) { }


  ngOnChanges(changes: SimpleChanges) {
    this.getFeatureArr = Object.assign([], this.getFeatureArr);
    this.noFeatureDisplay = false;
    if (changes.getFeatureArr?.currentValue.length > 0 || this.showDdosTile() ) {
      this.ddosErrorMsgFlag = false;
      this.createWorkOrderErrorMsg = false;
      this.getFeatures();
    } else {
      this.noFeatureDisplay = true;
    }
  }

  ngOnInit(): void {
    this.webex = this.webexactivationService.isWebexActivated();
    this.isMyadmin = this.cbHelper.isMyAdmin();
    this.loggedInUserEmailId = this.cbHelper.getLoggedInUserDetails().profile.userToken.email;
    this.focusFlag = false;
    const subscription = this.aemClient.getSharedBundle('redesignsublandingroles')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => {
          this.configRoles = Object.assign([], data);
        }
      );
    this.subscriptions.push(subscription);
    this.aemClient.getRedesignBundle('malp/sublanding')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => {
          this.resourceBundle = data;
          this.ddostile={
            rank: this.resourceBundle.ddosRank,
            featureName: this.resourceBundle.ddosHeading,
            icon: this.resourceBundle.ddosIcon,
            featureUrl: this.resourceBundle.ddosFeatureUrl,
            target: this.resourceBundle.ddosUrlTarget,
            framework: this.resourceBundle.ddosUrlFramework,
          };
        }
      );
    this.isMobileDevice = false;
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
      this.focusFlag = true;
    }
    if (this.deviceService.isTablet()) {
      this.isTabletDevice = true;
    }
  }

  public getFeatures() {
    this.featuresList = [];
    for (let i = 0; i < this.configRoles.length; i++) {
      for (let j = 0; j < this.getFeatureArr.length; j++) {
        if (this.configRoles[i].role === this.getFeatureArr[j].role &&
          this.configRoles[i].category.toUpperCase() === this.category.toUpperCase()) {
          this.categoryName = this.configRoles[i].category;
          this.featuresList.push(this.configRoles[i]);
          this.fetureRankSort(this.featuresList, this.configRoles[i].rank);
        }
      }
    }
    this.accountGuid = this._storageService.get(LocalStorageKeys.SelectedAccount, 'cbma');
    if (this.showDdosTile()) {
      if (this.isMyadmin) {
        this.featuresList.push(this.ddostile);
        if (this.ddosToggleStatus()) {
          this.toggleStatus = this.ddosFeatureStatus === DDosFeatureStatus.TRUE;
          this.toggleCheck = true;
        } else if(this.ddosFeatureStatus === DDosFeatureStatus.ACTIVATION_IN_PROGRESS){
          this.ddosErrorMsgFlag = false;
          this.installedWorkOrder=true;
          this.ddosPollingStatus();
        } else if (this.ddosFeatureStatus === DDosFeatureStatus.PAWS_DOWN_ERROR) {
          this.ddosFeature = DDosFeatureStatus.TRY_AGAIN;
          this.ddosErrorMsgFlag = true;
          this.installedWorkOrder = true;
         } else if (this.ddosFeatureStatus === DDosFeatureStatus.WARNING) {
          this.ddosErrorMsgFlag = true;
          this.commonMessageService.showMessage
          (this.resourceBundle.ipAddressNotAssignedWarning, 'warning', 'featuresSection');
        } else {
          this.toggleCheck = false;
        }
      } else {
        if (this.ddosToggleStatus()) {
          this.featuresList.push(this.ddostile);
        }
      }
      this.ddosFeature = this.ddosFeatureStatus === DDosFeatureStatus.PAWS_DOWN_ERROR ?
      DDosFeatureStatus.TRY_AGAIN : (this.ddosFeatureStatus === DDosFeatureStatus.WARNING) ?
      DDosFeatureStatus.ACTIVATE: this.ddosFeatureStatus.split('_').join(' ');
      this.fetureRankSort(this.featuresList, this.ddostile.rank);
      this.ddosErrorMsg = this.ddosFeatureStatus === DDosFeatureStatus.WARNING ?
        this.resourceBundle.ipConfigMismatch: this.resourceBundle.unableToRetrieveStatus;
    }
    this.featuresListLength = this.featuresList.length;
  }

  public showDdosTile(){
    return (this.ddosFeatureStatus !== DDosFeatureStatus.NOT_ELIGIBLE && this.ddosFeatureStatus);
  }

  public ddosToggleStatus(){
    return (this.ddosFeatureStatus === DDosFeatureStatus.TRUE || this.ddosFeatureStatus === DDosFeatureStatus.FALSE);
  }

  public updateToggleCall(){
      this.dataToolsService.updateToggle(this.accountGuid, this.toggleStatus).subscribe((results: any) => {
        if(results.errors){
          this.genericErrorMessage();
          window.scroll(0, 0);
          this.toggleStatus=!this.toggleStatus;
        }
      });
  }

  public openDisableModel() {
      if (this.toggleStatus) {
        this.modalService.open(this.ddosDisableModal, { backdrop: 'static', windowClass: 'redesign-modal-md' })
          .result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          });
      } else {
        this.toggleStatus=!this.toggleStatus;
        this.updateToggleCall();
      }
  }

  public onCancel(close) {
    this.tealium.track('link', {
      event_name: 'DisableDDosMitigationServiceEssential-Cancel',
      my_key: 'DisableDDosMitigationServiceEssential-Cancel',
      my_filters_selected: ['f1', 'f2']
    });
    this.toggleStatus = true;
    close('Close click');
  }

  public onClickOfDisable(close) {
    this.tealium.track('link', {
      event_name: 'DisableDDosMitigationServiceEssential-Disable',
      my_key: 'DisableDDosMitigationServiceEssential-Disable',
      my_filters_selected: ['f1', 'f2']
    });
    this.updateToggleCall();
    close('Close click');
  }

  public getSearchResults(event: any) {
    this.featuresList = event;
  }

  public fetureRankSort(featuresList: any[], rank: string) {
    featuresList.sort((a: any, b: any) => {
      const c = parseInt(a.rank.substring(1), 10);
      const d = parseInt(b.rank.substring(1), 10);
      return c - d;
    });
    this.searchObj = {
      searchType: 'client',
      searchArray: this.featuresList,
      placeHolder: 'Search',//changed 'Feature Name'
      searchColumns: ['featureName']
    };
  }

  public onTileFocusOut() {
    this.focusFlag=false;
  }

  public onTileFocus() {
    this.focusFlag=true;
  }

  // May be we need to do CB Email Migration Code clean up in this method AND in JSON files.
  public onClickUrl(featureArr: any) {
    this.isMyadmin = this.cbHelper.isMyAdmin();
    if (this.isMyadmin === true && featureArr.role === 'appdir') {
      return;
    } else if (!this.isMyadmin && this.showDdosTile() &&
      featureArr.featureName === this.resourceBundle.ddosHeading){
      return;
    } else {
      if (featureArr.framework === 'externalLink' && this.focusFlag) {
        this.url = this.config.getConfig(featureArr.featureUrl);
        if (featureArr.role === 'ea') {
          this.url = this.config.getConfig(featureArr?.openExchangeEmailPlatformUrl);
        }
        if (featureArr.role === 'vtr') {
          this.onVoiceTrafficClick(featureArr.role, this.url);
        } else if (featureArr.role === 'ipm' || featureArr.role === 'ippr') {
          this.onIpmClick(featureArr.role, this.url);
        } else if (featureArr.role === 'epmr' || featureArr.role === 'ipvpn') {
          this.onClickIpvpnEpmr(featureArr.role, this.url);
        } else {
          window.open(this.url, featureArr.target);
        }
      } else if (featureArr.framework === 'angular') {
        if (featureArr.role === 'vipar') {
          this.viewIpAddressOpen();
        } else if (featureArr.role === 'emar') {
          this.url = `${this.config.getConfig(featureArr.emailAdministrationUrl)}`;
          window.open(this.url, '_blank');
        } else {
          this.router.navigate([featureArr.featureUrl]);
        }
      } else if (featureArr.framework === 'externalSSOLink') {
        this.generateSSOUrl(featureArr.role, featureArr.featureUrl, this.accountNumber12);
      }
    }
  }


  public viewIpAddressOpen() {
    this._storageService.set(LocalStorageKeys.SelectedAccount,this.dataToolsService.parentAccSelected);
    if (!this.deviceService.isMobile()) {
      const modalRef = this.modalService
      .open(IpAddressManagerModalComponent, { windowClass: 'modal-lg-autopay', backdrop: 'static' });
      modalRef.componentInstance.selectedAccountDetails = this.selectedAccountDetails;
    } else {
      this.viewIpAddressMobile.emit(true);
    }
  }

  // TODO Replace role names with the Enum(Role)
  public async generateSSOUrl(role?: string, featureUrl?: string, accountNumber?: string) {
    try {
      let data;
      switch (role) {
        case 'adcsso':
          data = await this.ssoService.getSSOService(SSOPageName.ALARM);
          if (data.code === '0') {
            window.open(this.config.getConfig('getAdcSsoUrl'), '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case 'sdwansso':
          data = await this.ssoService.getSSOService(SSOPageName.RSSDWAN3, accountNumber);
          if (data.code === '0') {
            window.open(this.config.getConfig('SSO')['getSSORapidScaleUrl'], '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case 'cbcore':
          data = await this.ssoService.getSSOService(SSOPageName.CB_CORE);
          if (data.code === '0') {
            window.open(this.config.getConfig('getCbCoreUrl'), '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case 'mrr':
          const pageid = SSOPageName.RAPID_SCALE;
          data = await this.ssoService.getSSOService(pageid);
          if (data.code === '0') {
            window.open(this.config.getConfig('getusrmanagedrouterUrlNew'), '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case 'mrsr':
          data = await this.ssoService.getSSOService(SSOPageName.RSSDWAN3, accountNumber);
          if (data.code === '0') {
            window.open(this.config.getConfig('SSO')['getSSORapidScaleUrl'], '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case 'mrnr':
          const pagename = SSOPageName.RAPID_SCALE;
          data = await this.ssoService.getSSOService(pagename);
          if (data.code === '0') {
            window.open(this.config.getConfig('getusrmanagedrouterUrlNew'), '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case 'mcfr':
          data = await this.ssoService.getSSOService(SSOPageName.AKAMAI_PREMIUM, accountNumber);
          if (data.code === '0') {
            window.open(this.config.getConfig('getPremiumCloudUrl'), '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case 'eppr':
          data = await this.ssoService.getSSOService(SSOPageName.ENDPOINT_PROTECT);
          if (data.code === '0') {
            window.open(this.config.getConfig(featureUrl), '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case Role.UNIFIED_WIFI_PORTAL_ROLE:
          data = await this.ssoService.getSSOService(SSOPageName.UNIFIED_WIFI_PORTAL);
          if (data.code === '0') {
            window.open(this.config.getConfig(featureUrl), '_blank');
          } else {
            this.genericErrorMessage();
          }
          break;
        case 'appdir':
            data = await this.ssoService.getSSOService(SSOPageName.VSB_APPDIRECT);
            if (data.code === '0') {
              window.open(this.config.getConfig('getAppDirectUrl'), '_blank');
            } else {
              this.genericErrorMessage();
            }
          break;
        default:
          window.open(featureUrl, '_blank');
          break;
      }
    } catch (error) {
      this.genericErrorMessage();
    }
  }

  /**
   *  Handling SSO login
   * */
   onVoiceTrafficClick(role: any, url: any) {
    if (role === 'vtr') {
      let action = url;
      const s = action.split('?');
      action = s[0];
      const co = s[1];
      const email = this.loggedInUserEmailId;
      const voicetrafficservicecode = 'HUTLRPT';
      let element1: any; let element2: any; let element3: any;

      const form = document.createElement('form');
      form.method = 'post';
      form.action = action;
      form.target = '_blank';
      document.body.appendChild(form);

      element1 = document.createElement('INPUT');
      element1.name = 'userEmail';
      element1.value = email;
      element1.type = 'hidden';
      form.appendChild(element1);

      element2 = document.createElement('INPUT');
      element2.name = 'serviceCode';
      element2.value = voicetrafficservicecode;
      element2.type = 'hidden';
      form.appendChild(element2);

      element3 = document.createElement('INPUT');
      element3.name = 'companyId';
      element3.value = co;
      element3.type = 'hidden';
      form.appendChild(element3);
      form.submit();
      return false;
    }
  }

  onIpmClick(role: any, url: any) {
    // use map for roles incase added more
    if (role === 'ipm' || role === 'ippr') {
    let action = url;
    const p = action.split('?');
    action = p[0];
    const co = p[1];
    const email = this.loggedInUserEmailId;
    let ipmservicecode = '';
    role === 'ipm'? ipmservicecode = 'IPM': ipmservicecode = 'IPPR';
    let element1: any; let element2: any; let element3: any;

    const form = document.createElement('form');
    form.method = 'post';
    form.action = action;
    form.target = '_blank';
    document.body.appendChild(form);

    element1 = document.createElement('INPUT');
    element1.name = 'userEmail';
    element1.value = email;
    element1.type = 'hidden';
    form.appendChild(element1);

    element2 = document.createElement('INPUT');
    element2.name = 'serviceCode';
    element2.value = ipmservicecode;
    element2.type = 'hidden';
    form.appendChild(element2);

    element3 = document.createElement('INPUT');
    element3.name = 'companyId';
    element3.value = co;
    element3.type = 'hidden';
    form.appendChild(element3);
    form.submit();
    event.stopPropagation();
    return false;
    }
  }

  onClickIpvpnEpmr(role: any, url: any) {
    let ipvpnepmrservicecode = '';
    if (role === 'epmr') {
      ipvpnepmrservicecode = 'EPMR';
    } else if (role === 'ipvpn') {
      ipvpnepmrservicecode = 'IPVPN';
    }
    let action = url;
    const p = action.split('?');
    action = p[0];
    const co = p[1];
    const email = this.loggedInUserEmailId;
    let element1: any; let element2: any; let element3: any;

    const form = document.createElement('form');
    form.method = 'post';
    form.action = action;
    form.target = '_blank';
    document.body.appendChild(form);

    element1 = document.createElement('INPUT');
    element1.name = 'userEmail';
    element1.value = email;
    element1.type = 'hidden';
    form.appendChild(element1);

    element2 = document.createElement('INPUT');
    element2.name = 'serviceCode';
    element2.value = ipvpnepmrservicecode;
    element2.type = 'hidden';
    form.appendChild(element2);

    element3 = document.createElement('INPUT');
    element3.name = 'companyId';
    element3.value = co;
    element3.type = 'hidden';
    form.appendChild(element3);
    form.submit();
    event.stopPropagation();
    return false;
  }


  public genericErrorMessage() {
    this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'sublandingError');
  }

  public async ddosActivate(installedWO: boolean){
    if(installedWO === false){
      this.spinnerFlag = true;
        const requestBody= {
        guid:this.accountGuid,
        ddosFeatures: this.ddosFeatureStatus
      };
      try{
        this.getDDosStatusSubscription = this.dataToolsService.getDDosStatus(requestBody).subscribe((data)=>{
          this.spinnerFlag = false;
          if(data && data.message === 'Success'){
            this.ddosFeature = DDosFeatureStatus.ACTIVATION_IN_PROGRESS;
            this.installedWorkOrder=true;
            this.ddosPollingStatus();
          }
        },
        (error)=>{
          this.spinnerFlag = false;
          this.createWorkOrderErrorMsg = true;
          this.ddosBisErrorMsg = this.resourceBundle.ddosBisApiErrorMsg + this.config.getConfig('ddosCustomerSupport') +
          this.resourceBundle.troubleshooting;
          this.commonMessageService.showMessage(this.ddosBisErrorMsg, 'error', 'featuresSection');
          const element: HTMLElement = document?.getElementById('featuresSection');
          element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          document?.getElementById('featuresSection').focus();
        });
      } catch {
        this.spinnerFlag = false;
        this.ddosBisErrorMsg = this.resourceBundle.ddosBisApiErrorMsg + this.config.getConfig('ddosCustomerSupport') +
        this.resourceBundle.troubleshooting;
        this.commonMessageService.showMessage(this.ddosBisErrorMsg, 'error', 'featuresSection');
        const element: HTMLElement = document?.getElementById('featuresSection');
        element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        document?.getElementById('featuresSection').focus();
      }
    } else {
      this.ddosErrorMsgFlag = false;
      this.spinnerFlag = true;
      this.ddosPollingStatus();
    }
  }

  public ddosPollingStatus(){
    this.clearInterval = false;
    this.getDdosTileStatus();
    const interval = this.config.getConfig('ddosPollingTimer');
    const duration = this.config.getConfig('ddosPollingClearTimer');
    let elapsedTime =0 ;
    const intervalId = setInterval(()=>{
      elapsedTime += interval;
       if(elapsedTime>= duration || this.clearInterval){
        clearInterval(intervalId);
        if (!this.toggleCheck) {
        this.spinnerFlag = false;
        this.ddosFeature = DDosFeatureStatus.TRY_AGAIN;
        this.ddosErrorMsgFlag = true;
        }
       } else {
        this.getDdosTileStatus();
       }
    },interval);
  }
  public getDdosTileStatus(){
  this.ddosTileStatusSubscription =this.dataToolsService.getDdosTileStatus(this.accountGuid, '/')
  .subscribe((results: any)=>{
    const status = results?.data?.getAccount?.getDdosTileStatus?.status;
      if(status === DDosFeatureStatus.TRUE || status === DDosFeatureStatus.FALSE) {
        this.clearInterval = true;
        this.spinnerFlag = false;
        this.toggleCheck = true;
        this.toggleStatus = JSON.parse(status);
        this.ddosFeature = this.ddosFeatureStatus.split('_').join(' ');
    } else {
      if (this.ddosFeatureStatus === DDosFeatureStatus.PAWS_DOWN_ERROR ||
        this.ddosFeature === DDosFeatureStatus.TRY_AGAIN) {
      this.spinnerFlag = false;
      this.ddosFeature = DDosFeatureStatus.TRY_AGAIN;
      this.ddosErrorMsgFlag = true;
      this.clearInterval = true;
      }
    }
  });
}

  ngOnDestroy(): void {
    this.clearInterval = true;
    this.ddosTileStatusSubscription?.unsubscribe();
    this.getDDosStatusSubscription?.unsubscribe();
  }

}
