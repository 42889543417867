<div class="container-fluid">
    <div id="customHeader">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                <div class="margin-bottom-0-4">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6">
                             <a (click)="goToBusinessLink()"><img id="header-logo" class="height-45 margin-top-1" src="assets/images/blueprint/onboarding/CoxBusiness_logo.svg" alt="LOGO"></a>
                        </div>
                        <div class="col-lg-5 col-md-4 col-sm-5 hidden-xs text-right">
                            <h3 class="margin-top-1 mr-4"></h3>
                        </div>
                        <div class="col-md-4 text-right" *ngIf="isUserAuthenticated">
                            <div class="mt-4">
                                <div>
                                    <img class="margin-top-neg-5" src="assets/images/blueprint/onboarding/header-profile-icon-blk.svg" alt="profile-icon">
                                    <span class="bold pl-2">Welcome, {{lastName}}!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="onboarding-header pt-0">
    <div class="header-gradientline"></div>
</div>
<div class="pt-0" *ngIf="isUserAuthenticated">
    <div class="pull-right bold">
        <a routerLink="/unauth/logout">Logout</a>
    </div>
</div>