import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EditUserService {
  public jwtHelper: JwtHelperService = new JwtHelperService();
  public isEditUserSubject;

  constructor() {
    this.isEditUserSubject = new BehaviorSubject<boolean>(this.hasToken());
  }

  isEditingUser(): boolean {
    return this.isEditUserSubject.value;
  }

  getToken(): string {
    if (this.isEditUserSubject.value) {
      return localStorage.getItem('cbma_user_token');
     } else {
      return localStorage.getItem('cbma_authtoken');
    }
  }

  getCbUser(): string {
    let userToken: string;
    if (this.isEditUserSubject.value) {
      userToken = localStorage.getItem('cbma_user_token');
    } else {
      userToken = localStorage.getItem('cbma_authtoken');
    }

    if (userToken) {
      const _decodedUserToken = this.jwtHelper.decodeToken(userToken);
      return _decodedUserToken.sub;
    }
  }

  getBWObj(): any {
    const bwObj: any = {};
    let userToken: any;
    if (this.isEditUserSubject.value) {
      userToken = localStorage.getItem('cbma_user_token');
    } else {
      userToken = localStorage.getItem('cbma_authtoken');
    }

    if (userToken) {
      const _decodedUserToken = this.jwtHelper.decodeToken(userToken);
      const _parsedUserToken = JSON.parse(_decodedUserToken.profile)['userToken'];
      bwObj.id = _parsedUserToken['id'];
      bwObj.email = _parsedUserToken['email'];
      bwObj.bwSwitchInstance = _parsedUserToken['bwSwitchInstance'];
      bwObj.bwGroupId = _parsedUserToken['bwGroupId'];
      bwObj.bwSpId = _parsedUserToken['bwSpId'];
      bwObj.assignedPhoneNumber = _parsedUserToken['assignedPhoneNumber'];
      bwObj.phoneNumber = _parsedUserToken['phoneNumber'];
      const _userRoles = JSON.parse(_decodedUserToken.profile)['roles'];
      bwObj.roles = _userRoles;
    }
    return bwObj;
  }

  private hasToken(): boolean {
    return !!localStorage.getItem('cbma_user_token');
  }
}
