import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService, TealiumUtagService } from 'common-ui-lib';
import { TileMenuViewService } from './tile-menu-view.service';

@Component({
  selector: 'app-tile-menu-view',
  templateUrl: './tile-menu-view.component.html',
  styleUrls: ['./tile-menu-view.component.scss'],
})
export class TileMenuViewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() tileCategories: any;
  @Input() showContentView = false;
  @Input() formDirty = false;
  @Input() hasTileChanged = false;
  @Input() activeTileName = null;
  @Output() closeTileMenuView = new EventEmitter<boolean>();
  @ContentChild('tileContent') tileTemplate: TemplateRef<unknown>;
  @ContentChild('menuContent') menuTemplate: TemplateRef<unknown>;
  public activeTile: string;
  public menuHeader: string;
  public selectedTileId: string;
  public isTabletView: boolean;
  public isMobileView: boolean;
  constructor(
    public deviceService: DeviceDetectorService,
    public activeRoute: ActivatedRoute,
    public tileMenuViewService: TileMenuViewService,
    public router: Router,
    private tealium: TealiumUtagService,
  ) {}

  ngOnInit() {
    this.isTabletView = this.deviceService.isTablet();
    this.isMobileView = this.deviceService.isMobile();
  }

  ngAfterViewInit() {
    document.getElementById('tileContentClose')?.focus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showContentView?.currentValue || changes.activeTileName?.currentValue || this.activeRoute.snapshot?.fragment) {
      if (this.activeRoute.snapshot?.fragment || changes.activeTileName?.currentValue) {
        for (let i = 0; i <= this.tileCategories.length; i++) {
          if (this.tileCategories[i]?.path.includes(this.activeRoute.snapshot?.fragment) ||
          this.tileCategories[i]?.displayname.includes(changes.activeTileName?.currentValue)) {
            this.activeTile = 'tile' + i;
            this.selectedTileId = 'tile' + i;
            this.menuHeader = this.tileCategories[i].displayname;
            this.tileSelected('tile' + i, this.tileCategories[i]);
            break;
          }
        }
      }
    }
    if (changes.hasTileChanged?.currentValue) {
      this.activeTile = this.tileMenuViewService.selectedTileInfo?.id;
      this.selectedTileId = this.tileMenuViewService.selectedTileInfo?.id;
      this.menuHeader = this.tileMenuViewService.selectedTileInfo?.details?.displayname;
    }
  }

  // On click of each tile change the tile header and emit the selected tile
  public tileSelected(tileId: string, tile) {
    if(!(!tile.authorized && tile.shortname === 'VMC')) {
      this.showContentView = true;
      this.tileMenuViewService.selectedTileInfo = { id: tileId, details: tile };
      if(this.activeRoute.snapshot.queryParamMap.get('phonenumber') === 'assigned' && !tile.path.includes('assigned')) {
        tile.path = tile.path+'&phonenumber=assigned';
      } else if(tile.path.includes('assigned')) {
        tile.path = tile.path.replace('&phonenumber=assigned', '');
      }
      if(this.activeRoute.snapshot.queryParamMap.get('path') === 'header' && !tile.path.includes('header')) {
        tile.path = tile.path+'&path=header';
      } else {
        tile.path = tile.path.replace('&path=header', '');
      }
      this.router.navigateByUrl(tile.path, { replaceUrl: true });
      this.tealium.button(tile.displayname, tile.displayname);
      setTimeout(() => {
        document.getElementById('tileContentClose')?.focus();
      });
    }
  }

  // on close click in menu view toggle to tile view
  public onClickOfCloseButton() {
    if (!this.formDirty) {
      this.showContentView = false;
      this.tileMenuViewService.selectedTileInfo = null;
      this.tealium.button('close-tile-menu-view', 'close-tile-menu-view');
    }
    this.closeTileMenuView.emit(true);
  }

  //on each tile click in tile-menu view change the menu header name
  public menuTileChange(tileId: string, category) {
    if (tileId === this.selectedTileId) {
      this.onClickOfCloseButton();
    } else {
      this.tileSelected(tileId, category);
    }
  }

  ngOnDestroy(): void {
    this.tileMenuViewService.selectedTileInfo = null;
  }
}
