import { MicroserviceResponse } from '../../services/coxhttpclient.service';

export enum EquipmentCategoryEnum {
    VOICE = 'Voice',
    INTERNET = 'Internet',
    TV = 'Television',
}

export enum EquipmentTypeEnum {
    EMTA_MODEM = 'EMTA Modem',
    ATA_DEVICE = 'ATA',
    POE_SWITCH = 'POE SWITCH',
    ESBC_DEVICE = 'ESBC',
    CABLE_MODEM = 'Cable Modem',
    NOKIA_ONT = 'Nokia ONT',
    CALIX_ONT = 'Calix ONT',
    GATEWAY = 'Gateway',
    LTE_ROUTER = 'LTE Router',
    SET_TOP_BOX = 'Cable Receiver',
    IAD_DEVICE = 'IAD',
}

export enum EquipmentImageEnum {
    EMTA_MODEM = 'emta-modem.svg',
    ATA_DEVICE = 'ata-device.svg',
    ATA_DEVICE_122 = 'ata-cisco-spa-122.svg',
    ATA_POLY_OBI='ata-poly-obi302.svg',
    POE_SWITCH = 'poe-switch.svg',
    ESBC_DEVICE = 'esbc-device.svg',
    CABLE_MODEM = 'cable-modem.svg',
    CVA4004_CABLE_MODEM = 'Cable-Modem-CVA4004.svg',
    CALIX_ONT = 'calix-ont.svg',
    LTE_ROUTER = 'lte-router.svg',
    SET_TOP_BOX = 'set-top-box.svg',
    CBS350_24P_4G_NA = 'CBS350-24P-4G-NA.svg',
    CBS350_24FP_4G_NA = 'CBS350-24FP-4G-NA.svg',
    CBS350_48P_4G_NA = 'CBS350-48P-4G-NA.svg',
    CBS350_48FP_4G_NA = 'CBS350-48FP-4G-NA.svg',
    IAD_DEVICE = 'iad_device.svg',
    CBS350_8S_E_2G_NA = 'CBS350-8S-E-2G-NA.svg',
    CBS350_8FP_2G_NA = 'CBS350-8FP-2G-NA.svg',
    POLYCOM_ROVE_R8 = 'polycom-rove-b8.svg',
    Device_4332 = '4332_Device.svg'
}

export enum EquipmentStatusImageEnum {
    WARNING = 'warning.svg',
    ERROR = 'circular_error.svg',
    CONFIRMATION = 'confirmation.svg',
    ERROR_BIG = 'alert_error-big.svg',
    CONFIRMATION_BIG = 'alert_success-big.svg',
    WIFI_ACTIVE = 'wifi status green.svg',
    WIFI_INACTIVE = 'wifi status grey.svg'
}

export enum ConnectionStatusEnum {
    CONNECTED = 'Connected',
    INTERRUPTED = 'Connection Interrupted',
    NOT_CONNECTED = 'Not Connected',
    CONNECTED_VIA_LTE = 'Connected via LTE',
    UNKNOWN = 'Unknown',
    LOADING = 'Loading',
    READY = 'Ready',
    UNPLUGGED = 'Unplugged',
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    UNKNOW_MODEL='Unknow Model Name',
    ERROR_STATUS = 'Error'
}

export enum ItemNumberEnum {
    CISCO_SPA_122 = 'TCI1122',
    POLY_OBI302 = 'TPCI302',
    CBS350_24P_4G_NA = 'NCI4052',
    CBS350_24FP_4G_NA = 'NCI4054',
    CBS350_48P_4G_NA = 'NCI4053',
    CBS350_48FP_4G_NA = 'NCI4055',
    CBS350_8S_E_2G_NA = 'TCI4060',
    CBS350_8FP_2G_NA = 'TCI4061',
    POLYCOM_ROVE_R8 = 'TPC1153'
}
export interface EquipmentStatus extends MicroserviceResponse {
    deviceStatus: string;
    primaryWANStatus: string;
    backupWANStatus: string;
    equipmentType: string;
    isCbigDevice: boolean;
    macAddress: string;
    serialNumber?: string;
    ipv4?: string;
    ipv6?: string;
    upTime?: string;
    wanMac?: string;
    deviceName?: string;
}

export enum EncryptionStatus {
    EncryptionUnavailable = 'Encryption Unavailable',
    EncryptionActive = 'Encryption Active',
    EncryptionInactive = 'Encryption Inactive'
}

export enum EncryptionImages {
    Lock = 'lock.svg',
    Unlock = 'unlock.svg',
    DisabledLock = 'disabled-lock.svg'
}

//Net Assurance Topology Network Images
export enum NetAssuranceTopology {
    TWO_DEVICES_1_1A_2_3_3A_5_5A = '2-device-states-1-1a-2-3-3a-5-5a.svg',
    TWO_DEVICES_4_6 = '2-device-states-4-6.svg',
    TWO_DEVICES_7_7NC_9_9NC = '2-device-states-7-7nc-9-9nc.svg',
    TWO_DEVICES_8A_8NC = '2-device-states-8a-8nc.svg',
    TWO_DEVICES_RED_GREEN = '2-device-states-red-green.svg',
    THREE_DEVICES_1_3 = '3-device-states-1-3.svg',
    THREE_DEVICES_1A_2_3A_5_5A = '3-device-states-1a-2-3a-5-5a.svg',
    THREE_DEVICES_4_6 = '3-device-states-4-6.svg',
    THREE_DEVICES_7_7NC_9_9NC = '3-device-states-7-7nc-9-9nc.svg',
    THREE_DEVICES_8A_8NC = '3-device-states-8a-8nc.svg',
    THREE_DEVICES_RED_GREEN_RED = '3-device-states-red-green-red.svg',
}

//Net Assurance Topology Devices Status Indicator Images
export enum TopologyStatusImage {
    ICON_GREEN_CHECK_MARK = 'icon-green-check-mark.svg',
    ICON_GREEN_LTE_TOWER = 'icon-green-lte-tower.svg',
    ICON_YELLOW_WARNING_TRIANGLE = 'icon-yellow-warning-triangle.svg',
    ICON_RED_WARNING_TRIANGLE = 'icon-red-warning-triangle.svg'
}


