import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,
   SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, AppConfig, CBHelperService, CommonMessageService, EquipmentCategoryEnum, LocalStorageKeys, PhonePipe,
   ResourceBundle, TealiumUtagService } from 'common-ui-lib';
import { ShowMessage } from 'common-ui-lib/lib/shared/redesign/commonmessage/commonmessage.component';
import { EquipmentListObj } from 'home/myequipment/myequipment.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Subscription } from 'rxjs';
import { finalize,takeUntil } from 'rxjs/operators';
import { Equipment } from 'redesign/sub-landing/business-voice/business-voice.model';
import { CBUser, ContactType, EligibleRebootDevice, OtherEquipmentRebootInput, RebootCaseStatus,
   RebootStatusDetails, UserContact, UserType } from './redesign-reboot-widget.model';
import { RedesignRebootWidgetService } from './redesign-reboot-widget.service';
import { EquipmentCardDetailService } from '../equipment-card-detail/equipment-card-detail.service';
import { DateTimePipe } from '../../directives/dateTime.pipe';
import { BusinessVoiceService } from 'redesign/sub-landing/business-voice/business-voice.service';
import { FeatureName } from './redesign-reboot-widget.model';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-localstorage';
@Component({
  selector: 'app-redesign-reboot-widget',
  templateUrl: './redesign-reboot-widget.component.html',
  styleUrls: ['./redesign-reboot-widget.component.scss']
})
export class RedesignRebootWidgetComponent implements OnInit, OnChanges, OnDestroy {

  @Input() checkEmtaDeviceType = false;
  @Input() selectedPhoneEquipment: Equipment;
  @Input() selectedOtherEquipment: EquipmentListObj;
  @Input() equipmentType: string;
  @Input() accountGuid: string;
  @Input() equipmentName = '';
  @Input() voiceFlag: boolean;
  @Input() accountNumber: number;
  @Input() macAddress: string;
  @Input() smartHelpFlag: boolean;
  @Output() focusTrap = new EventEmitter<KeyboardEvent>();
  @ViewChild('rebootHistoryModalTem') public rebootHistoryModalTem: NgbModalRef;
  @ViewChild('helpModal') public helpModal: NgbModalRef;
  @ViewChild('beginRebootModel') public beginRebootModel: NgbModalRef;
  public resourceBundle: ResourceBundle = {};
  public mobileNumber = '';
  public showButtonSpinner = false;
  public contentLoaded = false;
  public rebootHistoryRows = [];
  public rebootHistoryRef: NgbModalRef;
  public rebootDeviceDetails: EligibleRebootDevice;
  public inEligibleWarningMessage: ShowMessage;
  public mobileUpdateWarningMsg: ShowMessage;
  public isBeginReboot = false;
  public isMobileDevice=false;
  public displaymobilePhoneError = false;
  public mobilePhoneRequiredError = false;
  public isNotificationCheck = false;
  public makeDefaultCheck = false;
  public validMobNumber = false;
  public rebootDone = false;
  public rebootSuccess = false;
  public equipmentRebootInput: OtherEquipmentRebootInput;
  public rebootTimer = '';
  public userGuid: string;
  public userDetails: CBUser;
  public userType: UserType;
  public contactDetails: UserContact[];
  public hideBeginReboot = false;
  public equipmentCategoryEnum = EquipmentCategoryEnum;
  public isEmtaDeviceType = false;
  private interval: NodeJS.Timer;
  private subscriptions: Subscription[] = [];
  private modalRef: NgbModalRef;
  public beginRebootModelRef: NgbModalRef;
  private onDestroy$ = new Subject<boolean>();
  public serviceEligibility: string;

  constructor(public aemClient: AEMClientService, public equipmentCardDetailService: EquipmentCardDetailService,
    public rebootService: RedesignRebootWidgetService, public voiceService: BusinessVoiceService,
    public cbHelper: CBHelperService, private tealium: TealiumUtagService, public router: Router,
    private modalService: NgbModal, public commonMessageService: CommonMessageService, private http: HttpClient, private readonly config:AppConfig,
    public deviceService: DeviceDetectorService, public activeModal: NgbActiveModal, 
    private _storageService: LocalStorageService) {
    this.inEligibleWarningMessage = new ShowMessage();
    this.mobileUpdateWarningMsg = new ShowMessage();
  }

  ngOnInit(): void {
    if(this.deviceService.isMobile()) {
    this.isEmtaDeviceType = this.checkEmtaDeviceType;
    }
    this.aemClient.getRedesignBundle('malp/redesignRebootWidget')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
          this.resourceBundle = data;
        }
      );
    this.userGuid = this.cbHelper.getLoggedInUserDetails().profile.userToken.guid;
    this.getUserDetails();
    if(this.deviceService.isMobile()) {
      if(this.equipmentType === 'phone') {
        if(this.selectedPhoneEquipment?.deviceDetails?.equipmentStatus &&
        !this.selectedPhoneEquipment?.deviceDetails?.isIPPhoneWithSoftKeys) {
          this.getRebootDeviceEligibility();
        } else {
          this.hideBeginReboot = false;
          this.contentLoaded = true;
        }
      } else {
        this.getRebootDeviceEligibility();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.selectedOtherEquipment?.currentValue?.equipmentStatus) {
      this.isBeginReboot = false;
      this.showButtonSpinner = false;
      this.displaymobilePhoneError = false;
      this.mobilePhoneRequiredError = false;
      this.validMobNumber = false;
      this.isNotificationCheck = false;
      this.makeDefaultCheck = false;
      this.rebootDone = false;
      this.mobileUpdateWarningMsg.show = false;
      this.pauseTimer();
      this.subscriptions.forEach((elem) => {
      if (elem && elem.unsubscribe) {
        elem.unsubscribe();
      }
    });
      this.getRebootDeviceEligibility();
    }
    if(changes?.selectedPhoneEquipment?.currentValue?.deviceDetails.equipmentStatus &&
      !changes?.selectedPhoneEquipment?.currentValue?.deviceDetails.isIPPhoneWithSoftKeys) {
      this.isBeginReboot = false;
      this.showButtonSpinner = false;
      this.displaymobilePhoneError = false;
      this.mobilePhoneRequiredError = false;
      this.validMobNumber = false;
      this.isNotificationCheck = false;
      this.makeDefaultCheck = false;
      this.rebootDone = false;
      this.mobileUpdateWarningMsg.show = false;
      this.pauseTimer();
      this.subscriptions.forEach((elem) => {
      if (elem && elem.unsubscribe) {
        elem.unsubscribe();
      }
    });
      this.getRebootDeviceEligibility();
    }
    if (changes?.selectedPhoneEquipment?.currentValue?.deviceDetails?.voiceDeviceType) {
      this.isEmtaDeviceType = this.voiceService.isValidVoiceDeviceType(this.selectedPhoneEquipment?.deviceDetails?.voiceDeviceType, true);
    }
	  if (changes.selectedPhoneEquipment?.currentValue !== undefined &&
      ((!changes?.selectedPhoneEquipment?.currentValue?.deviceDetails.isIPPhoneWithSoftKeys &&
        changes?.selectedPhoneEquipment?.currentValue?.deviceDetails?.voiceDeviceType!=='emta') ||
        changes?.selectedPhoneEquipment?.currentValue?.deviceDetails?.isIPPhoneWithSoftKeys)) {
      this.hideBeginReboot = false;
      this.contentLoaded = true;
    }
    this.macAddress = this.macAddress.replace(/:/g, '').toUpperCase();
    this.serviceEligibility = FeatureName.INTERNET;
  }

  public rebootHistoryModal() {
    this.equipmentCardDetailService.contentLoaded = false;
    this.getRebootHistory();
    this.modalService.dismissAll();
    this.rebootHistoryRef = this.modalService.open(this.rebootHistoryModalTem,
      { windowClass: 'redesign-modal-md', backdrop: 'static' });
    // this.tealium.trackModalTrigger('online-tools:reset-device:reboot-history');
  }

  // On click reboot History query call - Other equipment
  public getRebootHistory() {
    if (this.equipmentType === 'other') {
    this.equipmentCardDetailService.getOtherDeviceDetails(this.accountGuid, this.selectedOtherEquipment.encryptedValue)
      .pipe(finalize(() => {
        this.equipmentCardDetailService.contentLoaded = true;
      }))
      .subscribe((results: any) => {
        if (!results.errors) {
          const rebootHistoryDetails = results.data.getAccount?.rebootHistory?.caseInfo;
          this.rebootHistoryRows = [...rebootHistoryDetails];
          if (this.rebootHistoryRows?.length > 0) {
            this.rebootHistoryRows = this.rebootHistoryRows.map(v => ({ ...v, date: '' }));
            this.rebootHistoryRows = this.rebootHistoryRows.map(v => ({ ...v, time: '' }));
            this.rebootHistoryRows?.forEach(element => {
              if(element.caseStatus === 'Resolved-Completed') {
                element.caseStatus = 'Successful';
              } else {
                element.caseStatus = 'Failed';
              }
              const createDateTimePipe = new DateTimePipe();
              element.createDateTime = createDateTimePipe.transform(parseInt(element.createDateTime, 10));
              element.date = element.createDateTime.split(' ')[0];
              element.time =
                element.createDateTime.split(' ')[1]
                .concat(' ').concat(element.createDateTime.split(' ')[2].split('+')[0]);
            });
          };
        }
        if (results.errors) {
          const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'rebootHistory');
          if (errorInfo) {
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error');
          }
        }
      });
    } else {
      this.rebootPhoneHistory()
    }
  }

   // On click reboot History query call - Phone equipment
   rebootPhoneHistory() {
    this.equipmentCardDetailService.getOtherDeviceDetails(this.accountGuid, this.selectedPhoneEquipment.deviceDetails.encryptedValue)
      .pipe(finalize(() => {
        this.equipmentCardDetailService.contentLoaded = true;
      }))
      .subscribe((results: any) => {
        if (!results.errors) {
          const rebootHistoryDetails = results.data.getAccount?.rebootHistory?.caseInfo;
          this.rebootHistoryRows = [...rebootHistoryDetails];
          if (this.rebootHistoryRows?.length > 0) {
            this.rebootHistoryRows = this.rebootHistoryRows.map(v => ({ ...v, date: '' }));
            this.rebootHistoryRows = this.rebootHistoryRows.map(v => ({ ...v, time: '' }));
            this.rebootHistoryRows?.forEach(element => {
              if(element.caseStatus === 'Resolved-Completed') {
                element.caseStatus = 'Successful';
              } else {
                element.caseStatus = 'Failed';
              }
              const createDateTimePipe = new DateTimePipe();
              element.createDateTime = createDateTimePipe.transform(parseInt(element.createDateTime, 10));
              element.date = element.createDateTime.split(' ')[0];
              element.time =
                element.createDateTime.split(' ')[1]
                .concat(' ').concat(element.createDateTime.split(' ')[2].split('+')[0]);
            });
          };
        }
        if (results.errors) {
          const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'rebootHistory');
          if (errorInfo) {
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error');
          }
        }
      });
  }

  public async getRebootDeviceEligibility() {
    this.contentLoaded = false;
    try {
      if(this.equipmentType === 'other') {
      this.rebootDeviceDetails = await this.rebootService.
      getDeviceEligibility(this.selectedOtherEquipment?.encryptedValue);
    } else {
      this.rebootDeviceDetails = await this.rebootService.
      getDeviceEligibility(this.selectedPhoneEquipment?.deviceDetails?.encryptedValue);
    }
      if (this.rebootDeviceDetails.mobileNumber) {
        const phonePipe = new PhonePipe();
        this.mobileNumber = this.rebootDeviceDetails.mobileNumber;
        this.mobileNumber = phonePipe.transform(this.mobileNumber);
      } else {
        this.mobileNumber = '';
      }
      this.validateMobNumber();
      if (this.rebootDeviceDetails.caseId !== null) {
        if (this.rebootDeviceDetails.status !== null) {
          this.rebootStatusCheck(this.rebootDeviceDetails.status, this.rebootDeviceDetails.remainingTime,
            this.rebootDeviceDetails.caseId);
        } else if (this.rebootDeviceDetails && (this.rebootDeviceDetails.remainingTime !== null) &&
          (Number(this.rebootDeviceDetails.remainingTime) > 0)) {
          this.rebootDeviceDetails.eligibleDevice = true;
          this.isBeginReboot = true;
          this.showButtonSpinner = true;
          this.startTimer(this.rebootDeviceDetails.remainingTime, this.rebootDeviceDetails.caseId);
        }
      } else {
        this.isBeginReboot = false;
      }
    } catch (error) {
        window.scroll(0, 0);
        this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'voicetools');
    }
    this.contentLoaded = true;
  }

  public getUserDetails() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.rebootService.getUserDetails(this.userGuid).subscribe((results: any)=>{
      if(results.data && results.data.getUser) {
        this.userDetails = results.data.getUser;
        if (this.userDetails.isEndUser) {
          this.userType = UserType.EU;
        } else if (this.userDetails.isProfileAdmin) {
          this.userType = UserType.PA;
        } else if (this.userDetails.isProfileOwner) {
          this.userType = UserType.PO;
        } else if (this.userDetails.isSPA) {
          this.userType = UserType.SPA;
        } else if (this.userDetails.isSPO) {
          this.userType = UserType.SPO;
        }
      }
      this.userDetails = Object.assign({}, this.userDetails);
    });
  }

  public onMobileNumChange(event) {
    this.mobileNumber = event.target.value;
    this.validateMobNumber();
  }

  public getNotificationToggle() {
    this.isNotificationCheck = !this.isNotificationCheck;
    this.validateMobNumber();
  }

  public makeDefaultToggle() {
    this.makeDefaultCheck = !this.makeDefaultCheck;
  }

  public beginReboot() {
      if(this.voiceFlag && this.rebootDeviceDetails.convergedVoiceDevice){
        this.beginRebootModelRef =
        this.modalService.open(this.beginRebootModel, {
            backdrop: 'static'
        });
      }else{
        this.confirmReboot();
      }
  }
  public confirmReboot(){
    this.tealium.button('Begin Reboot', 'Begin Reboot');
    if (this.rebootDeviceDetails && this.rebootDeviceDetails.ineligibleCode !== null &&
      !this.rebootDeviceDetails.eligibleDevice) {
        this.inEligibleWarningMessage.show = true;
        this.inEligibleWarningMessage.type = 'warning';
        this.inEligibleWarningMessage.message = this.resourceBundle.inEligibleDesc;
        this.isBeginReboot = true;
    } else {
      this.equipmentRebootInput = {
        phoneNumber: this.mobileNumber === '' ? null : this.mobileNumber.replace(/-/g, ''),
        encryptedValue: this.selectedOtherEquipment?.encryptedValue || this.selectedPhoneEquipment?.deviceDetails?.encryptedValue,
        notificationOptIn : this.isNotificationCheck ? 'Y' : 'N'
      };
      if(this.isNotificationCheck && this.makeDefaultCheck) {
        this.saveMobNumber();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.rebootService.otherEquipmentReboot(this.equipmentRebootInput).subscribe((results: any)=> {
        if(!results.errors) {
          this.showButtonSpinner = !this.showButtonSpinner;
          this.isBeginReboot = true;
          const etr = results.data?.otherEquipmentReboot?.estimatedProcessingTime;
          const caseId = results.data?.otherEquipmentReboot?.caseID;
          this.startTimer(etr, caseId);
          this.tealium.trackSuccessFailureEvents('rsetDevSuccess', 'online-tools:reset-device:success');
        } else {
          const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'rebootWidget');
          if (errorInfo) {
            this.isBeginReboot = false;
            window.scroll(0, 0);
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'voicetools');
          }
          this.tealium.trackSuccessFailureEvents('rsetDevFail', 'online-tools:reset-device:fail');
        }
      });
    }
    this.beginRebootModelRef.close();
  }

  public saveMobNumber() {
    this.contactDetails = [];
    this.userDetails.contacts.forEach((contact)=> {
      if(contact.contactType === ContactType.PRIMARY_PHONE) {
        const phoneContact = {
          id: contact.id,
          contactType: ContactType.PRIMARY_PHONE,
          contactValue: this.mobileNumber.replace(/-/g, ''),
          preferred: contact.preferred === null ? false : contact.preferred,
          isMobileNumber: true
        };
        this.contactDetails.push(phoneContact);
      } else {
        this.contactDetails.push(contact);
      }
    });
    let coxTnInput = null;
    if(this.userDetails.coxTN != null) {
      coxTnInput = {
        welcomeEmail: false,
        accountNumber12: this.userDetails.coxTN.account.accountNumber12,
        coxTN: this.userDetails.coxTN.number,
        extn: this.userDetails.coxTN.extension,
        userGuid: this.userDetails.guid
      };
    } else {
      coxTnInput = {
        welcomeEmail: false,
        accountNumber12: '',
        coxTN: '',
        extn: '',
        userGuid: this.userDetails.guid
      };
    }
    const loginDetails = {
      id: this.userDetails.login.id,
      contactType: this.userDetails.login.contactType,
      contactValue: this.userDetails.login.contactValue,
      preferred: this.userDetails.login.preferred,
      isMobileNumber: this.userDetails.login.isMobileNumber
    };
    const input = {
      firstName: this.userDetails.firstName,
      lastName: this.userDetails.lastName,
      userType: this.userType,
      contacts: this.contactDetails,
      coxTn: coxTnInput,
      login: loginDetails
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.rebootService.saveMobileNumber(this.userDetails.guid, input).subscribe((results: any)=> {
      if(results.errors) {
        this.mobileUpdateWarningMsg.show = true;
        this.mobileUpdateWarningMsg.type = 'warning';
        this.mobileUpdateWarningMsg.message = this.resourceBundle.mobileUpdateFailMessage;
      }
    });
  }

  public async startTimer(rebootEta: string, caseId: string) {
    let rebootEstTime = Number(rebootEta);
    const lastTime = Number(rebootEta);
    const minutes: number = Math.floor(rebootEstTime / 60);
    const seconds: number = rebootEstTime - minutes * 60;
    this.rebootTimer = minutes + ':' + (seconds < 10 ? ('0' + seconds) : seconds);

    this.interval = setInterval(async () => {
      if (rebootEstTime === 0) {
        this.pauseTimer();
        if(this.equipmentType === 'other') {
          this.caseDetailsAPICall(caseId, this.selectedOtherEquipment.encryptedValue, true);
          } else {
            this.caseDetailsAPICall(caseId, this.selectedPhoneEquipment.deviceDetails.encryptedValue, true);
          }
      } else {
        rebootEstTime--;
        const minute: number = Math.floor(rebootEstTime / 60);
        this.rebootTimer = minute + ':' + ((rebootEstTime - minute * 60) < 10 ?
          ('0' + (rebootEstTime - minute * 60)) : (rebootEstTime - minute * 60));
        if ((lastTime - rebootEstTime) % 30 === 0) {
          if(this.equipmentType === 'other') {
            this.caseDetailsAPICall(caseId, this.selectedOtherEquipment.encryptedValue);
            } else {
              this.caseDetailsAPICall(caseId, this.selectedPhoneEquipment.deviceDetails.encryptedValue);
            }
        }
      }
    }, 1000);
  }

  public pauseTimer() {
    clearInterval(this.interval);
  }

  public async rebootStatusCheck(caseStatus: string, newEstTime?: string, caseId?: string) {
    if (caseStatus === RebootCaseStatus.ACTIVE || caseStatus === RebootCaseStatus.REBOOTING ) {
      this.rebootDone = false;
      if (newEstTime) {
        this.pauseTimer();
        this.rebootDeviceDetails.eligibleDevice = true;
        this.isBeginReboot = true;
        this.showButtonSpinner = true;
        this.startTimer(newEstTime, caseId);
      }
    } else if (caseStatus === RebootCaseStatus.FAILURE) {
      this.rebootDone = true;
      this.rebootSuccess = false;
      this.pauseTimer();
    } else if (caseStatus === RebootCaseStatus.SUCCESS) {
      this.rebootDone = true;
      this.rebootSuccess = true;
      this.pauseTimer();
    }
  }

  public triggerTealium(eventName: string , isModal: boolean) {
    if (isModal) {
      setTimeout(() => {
        this.modalRef.close();
      }, 10000);
    }
    this.tealium.button(eventName, eventName);
  }

  public reloadPage() {
    this.tealium.button('Reload-Page', 'Reload-Page');
    this.rebootDone = false;
    this.close('close click');
    const currentPageURL = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentPageURL);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public helpModalOpen(e: any) {
    this.tealium.button('Open-help-Modal', 'Open-help-Modal');
    this.close('close click');
    this.modalRef = this.modalService.open(this.helpModal, {
      windowClass: 'sch-modal-lg modal-width',
      backdrop: 'static',
    });
    // this.tealium.trackModalTrigger('online-tools:reset-device:lets-help-you-further');
    document.getElementById('reboot-oliver-chat').focus();
  }

  public smartHelpNavigate(smartHelpParamForm: HTMLFormElement){
    smartHelpParamForm.setAttribute('method', 'POST');
    smartHelpParamForm.setAttribute('action', 'https://www.two.qa.cox.com/smarthelp/business/home.html');
    smartHelpParamForm.submit();
    this._storageService.set(LocalStorageKeys.RenewAuthtoken, 'true');
  }

  public beginPhoneReboot() {
    this.hideBeginReboot = true;
    this.tealium.button('beginRebootButton', 'beginRebootButton');
    this.rebootService.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    phoneEquipmentReboot(this.selectedPhoneEquipment?.deviceDetails?.deviceName).subscribe((results: any) => {
      if (!results.errors) {
        this.tealium.trackSuccessFailureEvents('rsetDevSuccess', 'online-tools:reset-device:success');
        setTimeout(() => {
          this.hideBeginReboot = false;
        }, 30000);
      } else {
        this.tealium.trackSuccessFailureEvents('rsetDevFail', 'online-tools:reset-device:fail');
        const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'phoneReboot');
        if (errorInfo) {
          window.scroll(0, 0);
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error');
        }
      }
    });
  }

  public cardDetailFocus(event) {
    this.focusTrap.emit(event);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  close(event: any) {
    this.activeModal.close(event);
  }

  ngOnDestroy() {
    this.pauseTimer();
    this.subscriptions.forEach((elem) => {
      if (elem && elem.unsubscribe) {
        elem.unsubscribe();
      }
    });
  }

  private validateMobNumber() {
    if (this.isNotificationCheck && this.mobileNumber === '') {
      this.mobilePhoneRequiredError = true;
      this.displaymobilePhoneError = false;
      return;
    } else if (this.mobileNumber &&
      (this.mobileNumber.replace(/-/g, '').length > 10 || this.mobileNumber.replace(/-/g, '').length <= 9)) {
      this.displaymobilePhoneError = true;
      this.mobilePhoneRequiredError = false;
      return;
    } else if (!this.isNotificationCheck && this.mobileNumber === '') {
      this.validMobNumber = false;
      this.displaymobilePhoneError = false;
      this.mobilePhoneRequiredError = false;
    } else {
      this.displaymobilePhoneError = false;
      this.mobilePhoneRequiredError = false;
      this.validMobNumber = true;
    }
  }

  private caseDetailsAPICall(caseid: string, key: string, isFinalCall: boolean = false) {
    const subscription = this.rebootService.callRebootAPI(caseid, key)
      .pipe()
      .subscribe(
        (data: RebootStatusDetails) => {
          if (data.code === '0') {
            if (isFinalCall && data.CaseStatus !== RebootCaseStatus.SUCCESS) {
              this.rebootStatusCheck(RebootCaseStatus.FAILURE);
            } else {
              this.rebootStatusCheck(data.CaseStatus);
            }
          } else {
            this.rebootStatusCheck(RebootCaseStatus.FAILURE);
          }
        },
        (error) => {
          this.rebootStatusCheck(RebootCaseStatus.FAILURE);
        },
      );
    this.subscriptions.push(subscription);
  }
}
