import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PostLoginService } from "boot/postlogin/postlogin.service";

@Component({
  selector: "app-postlogin",
  templateUrl: "./postcblogin.component.html",
  styleUrls: ["./postcblogin.component.scss"],
})
export class PostCbLoginComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, public loginService: PostLoginService) {
    console.log("OKTAFLOW-PostCbLoginComponent Cons");
  }

  ngOnInit() {
    console.log("OKTAFLOW-PostCbLoginComponent Init");
    this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params["referrer"]) {
        this.loginService.redirect(true, params["referrer"]);
        return;
      }
    });
    this.loginService.redirect(true, "/");
  }
}
