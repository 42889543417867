import {
  Component, EventEmitter, HostListener, Input,
  OnChanges, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal, NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService, AppConfig, CommonMessageService,
  DeviceDetectorService, LocalStorageKeys, LoginProfileClient, Modaltemplate, PageHeaderService, ResourceBundle, VoiceGlobals
} from 'common-ui-lib';

import { LocalStorageService } from 'ngx-localstorage';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditUserService } from '../../../services/edituser.service';
import { Select2libComponent, Select2OptionData } from '../../ng2-select2/lib/ng2-select2';
import { PhonePipe } from '../../pipes/phone.pipe';
import { AccountInfo, PageTitleAndDesc, SwitchuserData } from './pageheader.model';

@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class PageheaderComponent implements OnInit, OnChanges {
  @ViewChild('unSavedChangesModal') public unSavedChangesModal: NgbModalRef;
  @Input() showAccountWidget = false;
  @Input() featureName = null;
  @Input() moreLink = true;
  @Input() hideWidgetTitle = false;
  @Input() showAccNumber = false;
  @Input() backLocation: string;
  @Input() callWidget = false;
  @Input() accessedAs: string;
  @Input() showSwitchUser = false;
  @Input() processSwitchUser = false;
  @Input() checkFormDirty = false;
  @Input() backWithUnsavedChanges: string;
  @Input() titleAndDesc = false;
  @Input() titleAndDescDetails: PageTitleAndDesc;
  @Input() displayLevel = 'global';
  @Input() pageErrorMsgs: string[] = [];
  @Output() selectedAccountEvent = new EventEmitter<AccountInfo>();
  @Output() errorAlertEvent = new EventEmitter<boolean>();
  @Output() backEvent = new EventEmitter<boolean>();
  @Output() accountListEvent = new EventEmitter<AccountInfo[]>();
  @Output() accountWidgetLoading = new EventEmitter<boolean>();
  @Output() backToList = new EventEmitter<boolean>();
  @Output() featuresList = new EventEmitter<unknown>();
  @Output() cbServices = new EventEmitter<boolean>();
  @ViewChild('tip3') tip3: NgbPopover;
  public selectedAccountDetails: AccountInfo;
  public widgetLoaded = false;
  public isMobileView: boolean;
  public jwtHelper: JwtHelperService = new JwtHelperService();
  public phonePipe: PhonePipe = new PhonePipe();
  public enableBackToFeatureLink: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public editTab: any;
  public isHoteling = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public backLink: any;
  public popoverPlacement = 'bottom';
  public dropdownopen = false;
  public swtchbutton = false;
  public lastName: string;
  public selectedUser: string;
  public backNavigationClass: string;
  public accountWidgetClass: string;
  public resourceBundle: ResourceBundle = {};
  public isVoiceSettingsSubMenu = false;
  public modaltemplate: Modaltemplate;
  private onDestroy$ = new Subject<boolean>();

  constructor(public deviceService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    private _storageService: LocalStorageService,
    public router: Router, private config: AppConfig,
    public pageHeaderService: PageHeaderService,
    private voiceGlobalRef: VoiceGlobals,
    private sessionStorage: SessionStorageService,
    private loginclient: LoginProfileClient,
    private editUser: EditUserService,
    public commonMessageService: CommonMessageService,
    public aemClient: AEMClientService, private modalService: NgbModal) {
    const currentUrlData = this.activatedRoute.snapshot.data;
    if (currentUrlData.showAccessedAs) {
      const userAuthToken = this._storageService.get('user_token', 'cbma');
      if (userAuthToken !== null && userAuthToken !== undefined) {
        const decodedToken = this.jwtHelper.decodeToken(userAuthToken);
        const userProfile = JSON.parse(decodedToken.profile);
        // To-Do read from temp token
        this.accessedAs = userProfile.userToken.lastName + ', '
          + userProfile.userToken.firstName;
        this.accessedAs += (userProfile.userToken.assignedPhoneNumber ? ' (' +
          this.phonePipe.transform(userProfile.userToken.assignedPhoneNumber) + ')' : '');
        if (currentUrlData.showBackToFeatureLink) {
          this.enableBackToFeatureLink = true;
        } else {
          this.enableBackToFeatureLink = false;
        }
      }
    } else {
      this.enableBackToFeatureLink = false;
    }
  }

  @HostListener('click', ['$event'])
  select2open(event: MouseEvent) {
    if (this.tip3?.isOpen()) {
      const node = document.querySelectorAll('.select2-container.select2-container--default.select2-container--open');
      if (node.length > 0) {
        (node[1] as HTMLElement).style.zIndex = '1090';
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (<HTMLElement>document.querySelector('.select2-container--default .select2-results>.select2-results__options'))
          .style.overflowX = 'hidden';
      }
    }
  }

  public templatefunction = (templateResult: Select2OptionData): JQuery | string => {
    if (!templateResult.type) {
      return templateResult.text;
    }
    return jQuery('<b>' + templateResult.text + '</b>');
  };

  ngOnInit(): void {
    this.aemClient.getSharedBundle('pageHeader')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
          this.resourceBundle = data;
        }
      );
    this.isMobileView = this.deviceService.isMobile();
    this.isVoiceSettingsSubMenu = this._storageService.get(LocalStorageKeys.VoiceSettingsSubMenu, 'cbma') ?
      this._storageService.get(LocalStorageKeys.VoiceSettingsSubMenu, 'cbma') : false;
    if (this.showSwitchUser) {
      this.pageHeaderService.getUserList(this.lastName);
    }
    this.navigationClassSelection();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.editTab = this.activatedRoute.snapshot.data['tab'];
    if (this.router.url.includes('hoteling')) {
      this.isHoteling = true;
    } else {
      this.backLink = 'https://'
        + window.location.hostname + '/cbma/usermanagement/userandsysmanagement/userfeaturesettings';
    }
    this.lastName = this.voiceGlobalRef.lastName;
  }

  emitBackToListEvent() {
    this.backToList.emit(true);
  }

  backToFeatureLinkClick() {
    if (this.editTab) {
      this.router.navigate(['/voice/userandsysmanagement/userfeaturesettings/edit'],
        { queryParams: { tab: this.editTab } });
    } else {
      this.router.navigate(['/voice/userandsysmanagement/userfeaturesettings/edit']);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedEvent(event: any) {
    this.selectedAccountDetails = event;
    this.widgetLoaded = true;
    this.selectedAccountEvent.emit(event);
  }

  public errorOccured(event: boolean) {
    this.errorAlertEvent.emit(event);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public backEventOccured(event: any) { //added for naviagtion header
    this.commonMessageService.displayMessage.show = false;
    this.backEvent.emit(event);
  }

  public getAccountList(e: AccountInfo[]) {
    this.accountListEvent.emit(e);
  }

  public updateAccountWidgetLoading(event: boolean) {
    this.accountWidgetLoading.emit(event);
  }

  public profileFeaturesList(event) {
    this.featuresList.emit(event);
  }

  public availableServices(event) {
    this.cbServices.emit(event);
  }

  // Closing Switch User Popup on Escape
  public onKeyupSwitchUser(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.tip3.close();
    }
  }

  public popovermousedown(event: MouseEvent, element: Select2libComponent) {
    event.stopPropagation();
  }

  // Focus Trap for Switch User - Accessibility
  public switchUserFocus(event: KeyboardEvent) {
    const firstFocusableEl = document.getElementById('dismiss');
    let lastFocusableEl = null;
    if (this.swtchbutton === true) {
      lastFocusableEl = document.getElementById('confirm');
    } else if (this.swtchbutton === false) {
      lastFocusableEl = document.getElementById('comboDropdown');
    }
    if (event.key === 'Tab' && document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      event.preventDefault();
    } else if (event.shiftKey && event.key === 'Tab' && document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      event.preventDefault();
    }
  }

  public cancelClick(): void {
    const elements = document.getElementsByClassName('select2-container');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
      this.dropdownopen = false;
    }
    this.tip3.close();
    this.swtchbutton = false;
  }

  public userOptionSelected(ev: { data: Select2OptionData[] }) {
    if (ev.data[0]) {
      this.selectedUser = ev.data[0].mail;
      this.swtchbutton = true;
      this.sessionStorage.store('userId', ev.data[0].id);
      if (ev.data[0].text === '---Clear---') {
        this.swtchbutton = false;
      }
    }
  }

  public async switchinguser(): Promise<void> {
    if(!this.processSwitchUser) {
    this.tip3.close();
    this.sessionStorage.store('isSwithUser', true);
    return new Promise<void>((resolve, reject) => {
      this.loginclient.reloadProfile(this.selectedUser).subscribe((data) => {
        let cururl = this.router.url + '';
        // this condition is to remove generic error message verbiage
        if (cururl && cururl.includes('/home/landing')) {
          cururl = '/home/landing';
        }
        if (cururl?.includes('?')) {
          cururl = `${cururl}&switchuser=true`;
        } else {
          cururl = `${cururl}?switchuser=true`;
        }
        if (this.editUser.isEditingUser()) {
          this.pageHeaderService.loadLocationMappings(cururl)
            .then((location: string) => {
              location = `${location}?switchuser=true`;
              this.editUser.isEditUserSubject.next(false);
              this._storageService.remove('user_token', 'cbma');
              this._storageService.remove(LocalStorageKeys.EditingUserguid, 'cbma');
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl(location);
              });
            });
        } else {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(cururl);
          });
        }
        window.scrollTo(0, 0);
        const msg = data.loggedInUserDetails.contact.firstName + ', ' + data.loggedInUserDetails.contact.lastName + '.';
        const successMsg = 'You have successfully logged in as ' + msg;
        this.commonMessageService.showMessage(successMsg, 'success', 'switchUserMsg');
        setTimeout(() => {
          this.commonMessageService.displayMessage.show = false;
        }, 5000);
        resolve();
      }, (error) => {
        reject(error);
      });
    });
    } else {
      this.modaltemplate = {
        modalTitleRequired: true,
        modalTitle: this.resourceBundle.leaveThisPageTitle,
        modalBodyDescription: this.resourceBundle.leaveThisPageDesc,
        secondaryButtonText: this.resourceBundle.no,
        primaryButtonText: this.resourceBundle.leaveThisPageBtn
      };
      this.modalService.open(this.unSavedChangesModal, { backdrop: 'static'});
    }
  }

  onPrimaryClick(close: any) {
    close('Close click');
    this.processSwitchUser = false;
    this.switchinguser();
  }

  onSecondaryClick(close: any) {
    close('Close click');
    this.processSwitchUser = true;
    this.swtchbutton = false;
  }

  // Set bootstrap class to the back link div depending on page heading to be displayed or no
  private navigationClassSelection() {
    if (this.showAccountWidget && !this.deviceService.isPageZoomed()) {
      this.backNavigationClass = 'col-lg-5 col-md-5 col-sm-5 col-xs-12';
      this.accountWidgetClass = 'col-lg-7 col-md-7 col-sm-7 col-xs-12';
    } else {
      if (this.deviceService.isPageZoomed() && (window.innerWidth >= 512 && window.innerWidth <= 515)) {
        this.backNavigationClass = 'col-8';
        this.accountWidgetClass = 'col-4';
      } else if (this.deviceService.isPageZoomedto175()) {
        this.backNavigationClass = 'col-10 ml-4';
        this.accountWidgetClass = 'col-12';
      } else {
        this.backNavigationClass = 'col-lg-7 col-md-5 col-sm-6 col-xs-12';
        this.accountWidgetClass = 'col-lg-5 col-md-7 col-sm-6 col-xs-12';
      }
    }
  }
}
