/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-classes-per-file
import {
	Component, Input, ElementRef, ViewChild, AfterContentInit, TemplateRef,
	ContentChildren, QueryList, NgModule, NgZone, EventEmitter,
	Output, ContentChild, ChangeDetectionStrategy, ViewEncapsulation,
	ChangeDetectorRef, SimpleChanges, AfterContentChecked, OnChanges, OnDestroy
} from '@angular/core';
import { PrimeTemplate, CarouselSharedModule, Header, Footer } from './carouselSharedModule';
import { RippleModule } from './carouselRipple/ripple';
import { CommonModule } from '@angular/common';
import { DeviceDetectorService } from 'common-ui-lib';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'redesign-carousel',
	template: `
		<div [attr.id]="id" [ngClass]="{'carousel p-component':true,
		 'carousel-vertical': isVertical(), 'carousel-horizontal': !isVertical()}"
		  [ngStyle]="style" [class]="styleClass" class="col-md-12 mb-3 p-0">
			<div class="carousel-header" *ngIf="headerFacet || headerTemplate">
                <ng-content select="p-header"></ng-content>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
			</div>
			<div [class]="contentClass" [ngClass]="'carousel-content'">
				<div class="carousel-container">
					<button tabindex="0" type="button" *ngIf="showNavigators" [ngClass]="{'carousel-prev':true,
					'nav-disabled': isBackwardNavDisabled()}"  id="carousel-prev-btn" [disabled]="disableBackwardNav()"
					(click)="navBackward($event)" [attr.aria-label]="prevNavBtnAriaLabel ? prevNavBtnAriaLabel : 'Previous'" pRipple>
						<span [ngClass]="{'carousel-prev-icon pi': true, 'pi-chevron-left': !isVertical(),
						'pi-chevron-up': isVertical()}"></span>
					</button>
					<div class="carousel-items-content"
					 [ngStyle]="{'height': isVertical() ? verticalViewPortHeight : 'auto'}">
						<div #itemsContainer class="carousel-items-container" (transitionend)="onTransitionEnd()"
						 (touchend)="onTouchEnd($event)" (touchstart)="onTouchStart($event)"
						  (touchmove)="onTouchMove($event)">
                            <div *ngFor="let item of clonedItemsForStarting; let index = index"
							 id="{{index}}" class="track-clicks"
							  [ngClass]= "{'carousel-tile carousel-item-cloned': true,
                                'carousel-item-active': (totalShiftedItems * -1) === (value.length),
							    'carousel-item-start': 0 === index,
							    'carousel-item-end': (clonedItemsForStarting.length - 1) === index,
								'carousel-tile-highlight': tileSelected === index,
								'invisible': !transitionFlag }" (click)="itemHighlight($event)">
								<ng-container *ngTemplateOutlet="itemTemplate;
								context: {$implicit: item}"></ng-container>
							</div>
                            <div *ngFor="let item of value; let index = index" id="{{index}}" class="track-clicks"
							 [ngClass]= "{'carousel-tile': true,
                                'carousel-item-active': (firstIndex() <= index && lastIndex() >= index),
							    'carousel-item-start': firstIndex() === index,
							    'carousel-item-end': lastIndex() === index, 'carousel-tile-highlight':
								 tileSelected === index}"
								 (click)="itemHighlight($event)">
								<ng-container *ngTemplateOutlet="itemTemplate;
								 context: {$implicit: item}"></ng-container>
							</div>
                            <div *ngFor="let item of clonedItemsForFinishing; let index = index"
							id="{{index}}" class="track-clicks"
							 [ngClass]= "{'carousel-tile carousel-item-cloned': true,
                                'carousel-item-active': ((totalShiftedItems *-1) === numVisible),
							    'carousel-item-start': 0 === index,
							    'carousel-item-end': (clonedItemsForFinishing.length - 1) === index,
								'carousel-tile-highlight': tileSelected === index,
								'invisible': !transitionFlag }" (click)="itemHighlight($event)">
								<ng-container *ngTemplateOutlet="itemTemplate;
								context: {$implicit: item}"></ng-container>
							</div>
						</div>
					</div>
					<button tabindex="0" type="button" *ngIf="showNavigators"
					[ngClass]="{'carousel-next': true, 'nav-disabled': isForwardNavDisabled()}"
					id="carousel-next-btn" [disabled]="disableForwardNav()" (click)="navForward($event)"
					[attr.aria-label]="nextNavBtnAriaLabel ? nextNavBtnAriaLabel : 'Next'" pRipple>
						<span [ngClass]="{'carousel-prev-icon pi': true, 'pi-chevron-right': !isVertical(),
						'pi-chevron-down': isVertical()}"></span>
					</button>
				</div>
				<ul [ngClass]="'carousel-indicators p-reset'" [class]="indicatorsContentClass"
				 [ngStyle]="indicatorsContentStyle" *ngIf="showIndicators">
					<li *ngFor="let totalDot of totalDotsArray(); let i = index"
					 [ngClass]="{'carousel-indicator':true,'p-highlight': _page === i}">
						<button type="button" [ngClass]="'p-link'" (click)="onDotClick($event, i)"
						 [class]="indicatorStyleClass" [ngStyle]="indicatorStyle"></button>
					</li>
				</ul>
			</div>
			<div class="carousel-footer" *ngIf="footerFacet || footerTemplate">
                <ng-content select="p-carouselfooter"></ng-content>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
			</div>
		</div>
    `,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./redesignCarousel.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class Carousel implements AfterContentInit, AfterContentChecked, OnChanges, OnDestroy {

	@Input() emitChevronClick: boolean;
	@Input() smallCarousel: boolean;
	@Input() translateStyle = 14;
	@Input() emptySlotView = false;
	@Input() get page(): number {
		return this._page;
	}
	set page(val: number) {
		if (this.isCreated && val !== this._page) {
			if (this.autoplayInterval) {
				this.stopAutoplay();
				this.allowAutoplay = false;
			}

			if (val > this._page && val <= (this.totalDots() - 1)) {
				this.step(-1, val);
			} else if (val < this._page) {
				this.step(1, val);
			}
		}

		this._page = val;
	}
	@Input() get numVisible(): number {
		return this._numVisible;
	}
	set numVisible(val: number) {
		this._numVisible = val;
	}
	@Input() get numScroll(): number {
		return this._numVisible;
	}
	set numScroll(val: number) {
		this._numScroll = val;
	}

	@Input() responsiveOptions: any[];
	@Input() orientation = 'horizontal';
	@Input() verticalViewPortHeight = '300px';
	@Input() contentClass = '';
	@Input() indicatorsContentClass = '';
	@Input() indicatorsContentStyle: any;
	@Input() indicatorStyleClass = '';
	@Input() indicatorStyle: any;
	@Input() get value(): any[] {
		return this._value;
	};
	set value(val) {
		this._value = val;
	}
	@Input() circular = false;
	@Input() showIndicators: boolean;
	@Input() autoplayInterval = 0;
	@Input() style: any;
	@Input() styleClass: string;
	@Input() tileSelected = -1;

	/**set this boolean when u want to bring the carousal to inial set of cards
	 * Scenario : you can set this when, we are in the middle of list and want to reset on search / filter updated
	 */
	@Input() resetToInitials: boolean;
	@Input() prevNavBtnAriaLabel: string;
	@Input() nextNavBtnAriaLabel: string;

	@Output() onPage: EventEmitter<any> = new EventEmitter();

	@ViewChild('itemsContainer') itemsContainer: ElementRef;
	@ContentChild(Header) headerFacet;
	@ContentChild(Footer) footerFacet;
	@ContentChildren(PrimeTemplate) templates: QueryList<any>;
	_numVisible = 1;
	_numScroll = 1;
	_oldNumScroll = 0;
	prevState: any = {
		numScroll: 0,
		numVisible: 0,
		value: []
	};
	defaultNumScroll = 1;
	defaultNumVisible = 1;
	_page = 0;
	_value: any[];
	carouselStyle: any;
	id: string;
	showNavigators = true;
	totalShiftedItems;
	isRemainingItemsAdded = false;
	animationTimeout: any;
	translateTimeout: any;
	remainingItems = 0;
	_items: any[];
	startPos: any;
	documentResizeListener: any;
	clonedItemsForStarting: any[];
	clonedItemsForFinishing: any[];
	allowAutoplay: boolean;
	interval: any;
	isCreated: boolean;
	swipeThreshold = 20;
	transitionFlag: boolean;
	itemTemplate: TemplateRef<any>;
	headerTemplate: TemplateRef<any>;
	footerTemplate: TemplateRef<any>;

	constructor(public el: ElementRef, public zone: NgZone, public cd: ChangeDetectorRef,
		private deviceService: DeviceDetectorService) {
		this.totalShiftedItems = this.page * this.numScroll * -1;
	}

	ngOnChanges(simpleChange: SimpleChanges) {
		if (simpleChange.value) {
			if (this.resetToInitials) { //looking if we also want to reset value when original data has changed
				this.totalShiftedItems = 0;
				this.page = 0;
			}
			if (this.circular && this._value) {
				this.setCloneItems();
			}
			if (!this.isBackwardNavDisabled() || !this.isForwardNavDisabled()) {
				this.showNavigators = true;
			}
			if(this.prevNavBtnAriaLabel?.length > 0) {
				document.getElementById('carousel-prev-btn')?.setAttribute('aria-label', this.prevNavBtnAriaLabel);
			}
			if(this.nextNavBtnAriaLabel?.length > 0) {
				document.getElementById('carousel-next-btn')?.setAttribute('aria-label', this.nextNavBtnAriaLabel);
			}
		}

		if (this.isCreated) {

			if (simpleChange.numVisible) {
				if (this.responsiveOptions) {
					this.defaultNumVisible = this.numVisible;
				}

				if (this.isCircular()) {
					this.setCloneItems();
				}

				this.createStyle();
				this.calculatePosition();
			}

			if (simpleChange.numScroll) {
				if (this.responsiveOptions) {
					this.defaultNumScroll = this.numScroll;
				}
			}
		}
	}

	ngAfterContentInit() {
		this.allowAutoplay = !!this.autoplayInterval;
		if (this.circular) {
			this.setCloneItems();
		}

		if (this.responsiveOptions) {
			this.defaultNumScroll = this._numScroll;
			this.defaultNumVisible = this._numVisible;
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 0; i < this.responsiveOptions.length; i++) {
				const res = this.responsiveOptions[i];
				if (res.breakpoint === '1024px' || res.breakpoint === '768px') {
					if (this._value.length <= this._numVisible) {
						this.showNavigators = false;
					}
				} else if (res.breakpoint === '560px' && (this._value.length > 1)) {
					this.showNavigators = true;
				}
			}
			this.bindDocumentListeners();
		}

		this.createStyle();
		this.calculatePosition();
		this.templates.forEach((item) => {
			switch (item.getType()) {
				case 'header':
					this.headerTemplate = item.template;
					break;

				case 'footer':
					this.footerTemplate = item.template;
					break;

				default:
					this.itemTemplate = item.template;
					break;
			}
		});
	}

	ngAfterContentChecked() {
		const isCircular = this.isCircular();
		let totalShiftedItems = this.totalShiftedItems;

		if (this.value && this.itemsContainer && (this.prevState.numScroll !== this._numScroll ||
			this.prevState.numVisible !== this._numVisible || this.prevState.value.length !== this.value.length)) {
			if (this.autoplayInterval) {
				this.stopAutoplay();
			}

			this.remainingItems = (this.value.length - this._numVisible) % this._numScroll;

			let page = this._page;
			if (this.totalDots() !== 0 && page >= this.totalDots()) {
				page = this.totalDots() - 1;
				this._page = page;
				this.onPage.emit({
					page: this.page
				});
			}

			totalShiftedItems = (page * this._numScroll) * -1;
			if (isCircular) {
				totalShiftedItems -= this._numVisible;
			}

			if (page === (this.totalDots() - 1) && this.remainingItems > 0) {
				totalShiftedItems += (-1 * this.remainingItems) + this._numScroll;
				this.isRemainingItemsAdded = true;
			} else {
				this.isRemainingItemsAdded = false;
			}

			if (totalShiftedItems !== this.totalShiftedItems) {
				this.totalShiftedItems = totalShiftedItems;
			}

			this._oldNumScroll = this._numScroll;
			this.prevState.numScroll = this._numScroll;
			this.prevState.numVisible = this._numVisible;
			this.prevState.value = [...this._value];

			if (this.totalDots() > 0 && this.itemsContainer.nativeElement) {
				if (this.deviceService.isMobile() || this.smallCarousel) {
					this.itemsContainer.nativeElement.style.transform = this.isVertical() ?
						`translate3d(0, ${totalShiftedItems * (100 / this._numVisible)}%, 0)` :
						`translate3d(${totalShiftedItems * (this.translateStyle)}em, 0, 0)`;
				} else {
					this.itemsContainer.nativeElement.style.transform = this.isVertical() ?
						`translate3d(0, ${totalShiftedItems * (100 / this._numVisible)}%, 0)` :
						`translate3d(${totalShiftedItems * (100 / this._numVisible)}%, 0, 0)`;
				}
			}

			this.isCreated = true;

			if (this.autoplayInterval && this.isAutoplay()) {
				this.startAutoplay();
			}
		}

		if (isCircular) {
			if (this.page === 0) {
				totalShiftedItems = -1 * this._numVisible;
			} else if (totalShiftedItems === 0) {
				totalShiftedItems = -1 * this.value.length;
				if (this.remainingItems > 0) {
					this.isRemainingItemsAdded = true;
				}
			}

			if (totalShiftedItems !== this.totalShiftedItems) {
				this.totalShiftedItems = totalShiftedItems;
			}
		}
	}

	createStyle() {
		if (!this.carouselStyle) {
			this.carouselStyle = document.createElement('style');
			this.carouselStyle.type = 'text/css';
			document.body.appendChild(this.carouselStyle);
		}
		if (this.responsiveOptions) {
			this.responsiveOptions.sort((data1, data2) => {
				const value1 = data1.breakpoint;
				const value2 = data2.breakpoint;
				let result = null;
				if (!value1 && value2) {
					result = -1;
				} else if (value1 && !value2) {
					result = 1;
				} else if (!value1 && !value2) {
					result = 0;
				} else if (typeof value1 === 'string' && typeof value2 === 'string') {
					result = value1.localeCompare(value2, undefined, { numeric: true });
				} else {
					result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
				}
				return -1 * result;
			});
		}
	}

	// this.carouselStyle.innerHTML = innerHTML;

	calculatePosition() {
		if (this.responsiveOptions) {
			const windowWidth = window.innerWidth;
			let matchedResponsiveData = {
				numVisible: this.defaultNumVisible,
				numScroll: this.defaultNumScroll
			};

			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 0; i < this.responsiveOptions.length; i++) {
				const res = this.responsiveOptions[i];
				if (parseInt(res.breakpoint, 10) >= windowWidth) {
					matchedResponsiveData = res;
				}
			}

			if (this._numScroll !== matchedResponsiveData.numScroll) {
				let page = this._page;
				page = Math.floor((page * this._numScroll) / matchedResponsiveData.numScroll);
				let totalShiftedItems = (matchedResponsiveData.numScroll * this.page) * -1;
				if (this.isCircular()) {
					totalShiftedItems -= matchedResponsiveData.numVisible;
				}
				this.totalShiftedItems = totalShiftedItems;
				this._numScroll = matchedResponsiveData.numScroll;

				this._page = page;
				this.onPage.emit({
					page: this.page
				});
			}
			if (this._numVisible !== matchedResponsiveData.numVisible) {
				this._numVisible = matchedResponsiveData.numVisible;
				this.setCloneItems();
			}
			this.cd.markForCheck();
		}
	}

	setCloneItems() {
		this.clonedItemsForStarting = [];
		this.clonedItemsForFinishing = [];
		if (this.isCircular()) {
			this.clonedItemsForStarting.push(...this.value.slice(-1 * this._numVisible));
			this.clonedItemsForFinishing.push(...this.value.slice(0, this._numVisible));
		}
	}

	firstIndex() {
		return this.isCircular() ? (-1 * (this.totalShiftedItems + this.numVisible)) : (this.totalShiftedItems * -1);
	}

	lastIndex() {
		return this.firstIndex() + this.numVisible - 1;
	}

	totalDots() {
		return this.value ? Math.ceil((this.value.length - this._numVisible) / this._numScroll) + 1 : 0;
	}

	totalDotsArray() {
		const totalDots = this.totalDots();
		return totalDots <= 0 ? [] : Array(totalDots).fill(0);
	}

	isVertical() {
		return this.orientation === 'vertical';
	}

	isCircular() {
		return this.circular && this.value && this.value.length >= this.numVisible;
	}

	isAutoplay() {
		return this.autoplayInterval && this.allowAutoplay;
	}

	isForwardNavDisabled() {
		if (this.emitChevronClick) {
			return false;
		} else {
			return this.isEmpty() || (this._page >= (this.totalDots() - 1) && !this.isCircular());
		}
	}

	disableForwardNav() {
		if (this.emitChevronClick && !this.isCircular()) {
			return this.isEmpty() || this._page >= (this.totalDots() - 1);
		}
	}

	isBackwardNavDisabled() {
		if (this.emitChevronClick) {
			return false;
		} else {
			return this.isEmpty() || (this._page <= 0 && !this.isCircular());
		}
	}

	disableBackwardNav() {
		if (this.emitChevronClick && !this.isCircular()) {
			return this.isEmpty() || this._page <= 0;
		}
	}

	isEmpty() {
		return !this.value || this.value.length === 0;
	}

	navForward(e, index?) {
		if (this.emitChevronClick) {
			this.onPage.emit({
				next: true
			});
		}
		if (this.isCircular() || this._page < (this.totalDots() - 1)) {
			this.step(-1, index);
		}

		if (this.autoplayInterval) {
			this.stopAutoplay();
			this.allowAutoplay = false;
		}

		if (e && e.cancelable) {
			e.preventDefault();
		}
	}

	navBackward(e, index?) {
		if (this.emitChevronClick) {
			this.onPage.emit({
				prev: true
			});
		}
		if (this.isCircular() || this._page !== 0) {
			this.step(1, index);
		}

		if (this.autoplayInterval) {
			this.stopAutoplay();
			this.allowAutoplay = false;
		}

		if (e && e.cancelable) {
			e.preventDefault();
		}
	}

	onDotClick(e, index) {
		const page = this._page;
		if (this.autoplayInterval) {
			this.stopAutoplay();
			this.allowAutoplay = false;
		}
		if (index > page) {
			this.navForward(e, index);
		} else if (index < page) {
			this.navBackward(e, index);
		}
	}

	itemHighlight(event) {
		if (this.tileSelected || this.tileSelected === 0) {
			this.tileSelected = null;
			let itemId = true;
			if (event && (event.path || event.composedPath())) {
				(event.path ? event.path : event.composedPath()).forEach((element) => {
					if (element?.classList?.value?.includes('carousel-tile') &&
						element?.id && itemId && /^\d+$/.test(element.id)) {
						this.tileSelected = parseInt(element.id, 10);
						itemId = false;
					}
				});
			}
		}
	}

	step(dir, page) {
		let totalShiftedItems = this.totalShiftedItems;
		const isCircular = this.isCircular();
		if (page >= 0) {
			totalShiftedItems = (this._numScroll * page) * -1;
			if (isCircular) {
				totalShiftedItems -= this._numVisible;
			}
			this.isRemainingItemsAdded = false;
		} else {
			totalShiftedItems += (this._numScroll * dir);
			if (this.isRemainingItemsAdded) {
				if (!this.emptySlotView) {
					totalShiftedItems += this.remainingItems - (this._numScroll * dir);
				}
				this.isRemainingItemsAdded = false;
			}
			const originalShiftedItems = isCircular ? (totalShiftedItems + this._numVisible) : totalShiftedItems;
			page = Math.abs(Math.floor((originalShiftedItems / this._numScroll)));
		}
		if (isCircular && this.page === (this.totalDots() - 1) && dir === -1) {
			totalShiftedItems = -1 * (this.value.length + this._numVisible);
			page = 0;
		} else if (isCircular && this.page === 0 && dir === 1) {
			totalShiftedItems = 0;
			page = (this.totalDots() - 1);
		} else if (page === (this.totalDots() - 1) && this.remainingItems > 0) {
			if (!this.emptySlotView) {
				totalShiftedItems += ((this.remainingItems * -1) - (this._numScroll * dir));
			}
			this.isRemainingItemsAdded = true;
		}

		if (this.itemsContainer) {
			if (this.deviceService.isMobile() || this.smallCarousel) {
				this.itemsContainer.nativeElement.style.transform = this.isVertical() ?
					`translate3d(0, ${totalShiftedItems * (100 / this._numVisible)}%, 0)` :
					`translate3d(${totalShiftedItems * (this.translateStyle)}em, 0, 0)`;
			} else {
				this.itemsContainer.nativeElement.style.transform = this.isVertical() ?
					`translate3d(0, ${totalShiftedItems * (100 / this._numVisible)}%, 0)` :
					`translate3d(${totalShiftedItems * (100 / this._numVisible)}%, 0, 0)`;
			}
			this.transitionFlag = true;
			this.itemsContainer.nativeElement.style.transition = this.value.length < 1000 ? 'transform 500ms ease 0ms' : 'transform 0ms ease 0ms';
		}

		this.totalShiftedItems = totalShiftedItems;
		this._page = page;
		this.onPage.emit({
			page: this.page
		});
	}

	startAutoplay() {
		this.interval = setInterval(() => {
			if (this.totalDots() > 0) {
				if (this.page === (this.totalDots() - 1)) {
					this.step(-1, 0);
				} else {
					this.step(-1, this.page + 1);
				}
			}
		},
		this.autoplayInterval);
	}

	stopAutoplay() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	onTransitionEnd() {
		if (this.itemsContainer) {
			this.itemsContainer.nativeElement.style.transition = '';
			this.transitionFlag = false;
			if ((this.page === 0 || this.page === (this.totalDots() - 1)) && this.isCircular()) {
				if (this.deviceService.isMobile() || this.smallCarousel) {
					this.itemsContainer.nativeElement.style.transform = this.isVertical() ?
						`translate3d(0, ${this.totalShiftedItems * (100 / this._numVisible)}%, 0)` :
						`translate3d(${this.totalShiftedItems * (this.translateStyle)}em, 0, 0)`;
				} else {
					this.itemsContainer.nativeElement.style.transform = this.isVertical() ?
						`translate3d(0, ${this.totalShiftedItems * (100 / this._numVisible)}%, 0)` :
						`translate3d(${this.totalShiftedItems * (100 / this._numVisible)}%, 0, 0)`;
				}
			}
		}
	}

	onTouchStart(e) {
		const touchobj = e.changedTouches[0];

		this.startPos = {
			x: touchobj.pageX,
			y: touchobj.pageY
		};
	}

	onTouchMove(e) {
		if (e.cancelable) {
			e.preventDefault();
		}
	}
	onTouchEnd(e) {
		const touchobj = e.changedTouches[0];

		if (this.isVertical()) {
			this.changePageOnTouch(e, (touchobj.pageY - this.startPos.y));
		} else {
			this.changePageOnTouch(e, (touchobj.pageX - this.startPos.x));
		}
	}

	changePageOnTouch(e, diff) {
		if (Math.abs(diff) > this.swipeThreshold) {
			if (diff < 0) {
				this.navForward(e);
			} else {
				this.navBackward(e);

			}
		}
	}

	bindDocumentListeners() {
		if (!this.documentResizeListener) {
			this.documentResizeListener = (e) => {
				this.calculatePosition();
			};

			window.addEventListener('resize', this.documentResizeListener);
		}
	}

	unbindDocumentListeners() {
		if (this.documentResizeListener) {
			window.removeEventListener('resize', this.documentResizeListener);
			this.documentResizeListener = null;
		}
	}

	ngOnDestroy() {
		if (this.responsiveOptions) {
			this.unbindDocumentListeners();
		}
		if (this.autoplayInterval) {
			this.stopAutoplay();
		}
	}

}
@NgModule({
	imports: [CommonModule, CarouselSharedModule, RippleModule],
	exports: [CommonModule, Carousel, CarouselSharedModule],
	declarations: [Carousel]
})
export class CarouselModule { }
