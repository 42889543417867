
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig, CBHelperService, Cookie, Regex, ResourceBundle } from 'common-ui-lib';
import { CookieService } from 'ngx-cookie-service';
import { StepNum, ValidateUserId } from '../reset-password.model';
import { ResetPasswordService } from '../reset-password.service';

@Component({
  selector: 'app-validate-userid',
  templateUrl: './validate-userid.component.html',
  styleUrls: ['./validate-userid.component.scss']
})
export class ValidateUseridComponent implements OnInit {


  @Input() public resourceBundle: ResourceBundle = {};
  @Output() closeClick = new EventEmitter();
  public isMobile: boolean;
  public siteKey: string;
  public displayEmailError = false;
  public resetPasswordForm: UntypedFormGroup;
  public emailErrorMessage = '';
  public formSubmitted: boolean;
  public captchaValue: string;
  public disableButton = false;
  public showturnOffButtonSpinner: boolean;
  public isMyAdmin = false;

  constructor(
    private config: AppConfig,
    public resetPasswordService: ResetPasswordService,
    public activeModal: NgbActiveModal,
    public cbHelper: CBHelperService,
    public cookieService: CookieService
  ) {
    this.isMyAdmin = this.cbHelper.isMyAdmin();
  }

  ngOnInit() {
    this.siteKey = this.config.getConfig('reCaptchsiteKey');
    this.resetPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(Regex.EMAIL_REGEX).bind(this),
      ]),
    });
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    this.isMobile = this.resetPasswordService.isMobile;
    this.cookieService.delete(Cookie.TalonToken, this.resetPasswordService.attributes);
  }

  //On click of choose contact methods
  public async chooseContactMethods(email: string) {
    this.resetPasswordService.checkNoAttempts = false;
    this.resetPasswordService.displayErrMsg = false;
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    if(this.cbHelper.isMyAdmin()) {
      const reqBody: ValidateUserId = {
        userEmail: email,
        recaptchaCode: null
      };
      try {
        this.showturnOffButtonSpinner = true;
        const response = await this.resetPasswordService.getVerificationMethods(reqBody);
        this.resetPasswordService.verificationMethodsResponse = response;
        if (response.code === '0') {
          this.setCookie(`${Cookie.TalonToken}=${this.resetPasswordService.talonToken}${this.resetPasswordService.attributes}`);
          this.resetPasswordService.currentStep = StepNum.step2;
          this.resetPasswordService.modalHeading = this.resourceBundle.selectContactMethod;
        } else if(response.code === '1003') {
          this.resetPasswordService.verificationMethodsResponse = response;
          this.resetPasswordService.currentStep = StepNum.step2;
          this.resetPasswordService.modalHeading = this.resourceBundle.selectContactMethod;
        } else if (response.code === '1006') {
          this.resetPasswordService.verificationMethodsResponse = response;
          this.resetPasswordService.isIdScanFailed = true;
          this.resetPasswordService.currentStep = StepNum.step2;
          this.resetPasswordService.modalHeading = this.resourceBundle.selectContactMethod;
        }  else if (response.code === '8802') {
          this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.userIdCheckMsg, 'success',
            'resetPwdError');
          this.resetPasswordService.modalScroll();
        } else {
          this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.genericerrorMsg, 'error',
            'resetPwdError');
          this.resetPasswordService.modalScroll();
        }
      } catch {
        this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.genericerrorMsg, 'error',
         'resetPwdError');
        this.resetPasswordService.modalScroll();
      }
    } else {
      if (this.captchaValue) {
        this.displayEmailError = !this.resetPasswordForm.get('email').value ||
        !this.resetPasswordForm.get('email').valid;
        if (this.displayEmailError) {
          this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.correctInfoErrorMsg, 'error',
           'resetPwdError');
          this.resetPasswordService.modalScroll();
          if (this.resetPasswordForm.get('email').errors['required']) {
            this.emailErrorMessage = this.resourceBundle.emailReq;
          } else if (this.resetPasswordForm.get('email').errors['pattern']) {
            this.emailErrorMessage = this.resourceBundle.emailIncorrectFormat;
          }
          this.formSubmitted = true;
        } else {
          const reqBody: ValidateUserId = {
            userEmail: email,
            recaptchaCode: this.captchaValue
          };
          try {
            this.showturnOffButtonSpinner = true;
            const response = await this.resetPasswordService.getVerificationMethods(reqBody);
            if (response.code === '0') {
              this.setCookie(`${Cookie.TalonToken}=${this.resetPasswordService.talonToken}${this.resetPasswordService.attributes}`);
              this.resetPasswordService.verificationMethodsResponse = response;
              this.resetPasswordService.currentStep = StepNum.step2;
              this.resetPasswordService.modalHeading = this.resourceBundle.selectContactMethod;
            } else if (response.code === '1003') {
              this.resetPasswordService.verificationMethodsResponse = response;
              this.resetPasswordService.currentStep = StepNum.step2;
            this.resetPasswordService.modalHeading = this.resourceBundle.selectContactMethod;
            } else if (response.code === '8802') {
              this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.userIdCheckMsg, 'success',
                'resetPwdError');
              this.resetPasswordService.modalScroll();
            } else if (response.code === '1006') {
              this.resetPasswordService.verificationMethodsResponse = response;
              this.resetPasswordService.isIdScanFailed = true;
              this.resetPasswordService.currentStep = StepNum.step2;
            this.resetPasswordService.modalHeading = this.resourceBundle.selectContactMethod;
            } else {
              this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.genericerrorMsg, 'error',
                'resetPwdError');
              this.resetPasswordService.modalScroll();
            }
          } catch {
            this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.genericerrorMsg, 'error',
             'resetPwdError');
            this.resetPasswordService.modalScroll();
          }
        }
      } else {
        this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.genericerrorMsg, 'error',
         'resetPwdError');
        this.resetPasswordService.modalScroll();
      }
    }
    this.showturnOffButtonSpinner = false;
  }

  //On click of forgot User ID link
  public forgotUid() {
    this.activeModal?.close('forgotUserId');
  }

  // checking captcha response
  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.disableButton = true;
      this.captchaValue = captchaResponse;
    } else {
      this.disableButton = false;
      this.captchaValue = null;
    }
  }

  public onValidateUserIdClose() {
    this.closeClick.emit();
  }

  private setCookie(cookie: string): void {
    document.cookie = cookie;
  }
}
