import { Injectable } from '@angular/core';

@Injectable()
export class NewRelicService {

    constructor() {
      }

    /**
     * @param attribute attribute label
     * @param value attribute value
     * sets the provided custom attribute
     */

    public setNewRelicAttribute(attribute: string, value: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //  (window as any).browserAgent.setCustomAttribute(attribute, value);//Uncomment for CBMA7-3303
        (window as any).newrelic.setCustomAttribute(attribute, value);
    }

    public unsetCustomAttributes(attribute: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //  (window as any).browserAgent.setCustomAttribute(attribute, null);//Uncomment for CBMA7-3303
        (window as any).newrelic.setCustomAttribute(attribute, null);
    }

    //Uncomment for CBMA7-3303
    public setPageViewName(data){
        if ((window as any).newrelic !== null) {
            console.log('pageViewName', data?.pageName);
            (window as any).newrelic.setCurrentRouteName(data?.pageName);
            console.log('setPageViewName is executed!');
        } else {
            console.log('New relic is not available');
        }
    }

    public setModalInteraction(pageName: string){
        if ((window as any).newrelic !== null) {
            console.log('pageInteractionName', pageName);
            (window as any).newrelic.addPageAction('ModalClick', {ModalName: 'modal:cox:bus:add-payment-method'});
            console.log('setModalInteraction is executed!');
        }  else {
            console.log('New relic is not available');
        }
    }
}
