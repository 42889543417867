import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, AEMMessagesService, CBHelperService, ResourceBundle } from 'common-ui-lib';
import { DateTimePipe } from 'common-ui-lib/lib/shared/directives/dateTime.pipe';
import { DeviceDetectorService } from 'common-ui-lib';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-plannedoutage',
  templateUrl: './plannedoutage.component.html'
})
export class PlannedoutageComponent implements OnInit {
  public isMultiOutages: boolean;
  public resourceBundle: ResourceBundle = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public outageListMain: any;
  public outageRows = [];
  public accountAlias: string;
  public serviceAddress: string;
  public dateTime: string;
  public isMobileDevice: boolean;
  public showSingleAcc: boolean;
  public contentLoad: boolean;
  public totalCount: number;
  public showGradient: boolean;
  constructor(
    public aemClient: AEMClientService,
    public messageService: AEMMessagesService,
    public cbHelper: CBHelperService,
    private deviceService: DeviceDetectorService,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.aemClient.getSharedBundle('futureOutage')
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
          this.resourceBundle = data;
        }
      );
    this.populateDatatable();
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }
  }

  public populateDatatable() {
    this.contentLoad = false;
    this.showSingleAcc = false;
    const dateTimePipe = new DateTimePipe();
    this.messageService.getOutageList()
      .pipe(finalize(() => {
        this.contentLoad = true;
      }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((results: any) => {
        if (!results.errors) {
          this.outageRows = [];
          this.outageListMain = results.data?.listOutages;
          this.isMultiOutages = this.outageListMain?.length > 1;
          if (this.isMultiOutages) {
            this.outageListMain.forEach(element => {
              const outRows = {
                accountAlias: element.alias,
                serviceAddress: this.formattedAddress(element?.accountExternalAttrs?.serviceAddress),
                dateTime: dateTimePipe.transform(element.outage.outageDate)
              };
              this.outageRows.push(outRows);
            });
          } else {
            this.serviceAddress = this.formattedAddress(this.outageListMain[0].accountExternalAttrs?.serviceAddress);
            this.dateTime = dateTimePipe.transform(this.outageListMain[0].outage.outageDate);
          }
          this.totalCount = this.outageListMain.length;
          if (this.totalCount > 10) {
            this.showGradient = true;
          }
        } else {
          window.scroll(0, 0);
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public multiAccountModal(row: any) {
    this.accountAlias = row.accountAlias;
    this.serviceAddress = this.formattedAddress(row.serviceAddress);
    this.dateTime = row.dateTime;
    this.showSingleAcc = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public close(event: any) {
    this.activeModal.close(event);
  }
  public onScroll(event) {
    if (event.currentTarget.scrollTop >= event.currentTarget.scrollHeight - event.currentTarget.offsetHeight) {
      this.showGradient = false;
    } else {
      this.showGradient = true;
    }
  }
  public activateRow(event) {
    if (document.activeElement.classList.contains('datatable-body-row')) {
      document.querySelectorAll('datatable-body-row').forEach((ele) => {
        if (ele.classList.contains('active')) {
          ele.classList.remove('active');
        }
      });
    }
    document.activeElement.classList.add('active');
  }
  public datatableAccessibility(event) {
    document.querySelectorAll('datatable-body-row').forEach((ele) => {
      ele.setAttribute('tabindex', '0');
    });
}

public formattedAddress(serviceAddress:any){
  const address = {
    street:this.cbHelper.replaceNull(serviceAddress.street),
    houseNumber:this.cbHelper.replaceNull(serviceAddress.houseNumber),
    city:this.cbHelper.replaceNull(serviceAddress.city),
    state:this.cbHelper.replaceNull(serviceAddress.state,true),
    zipCode: this.cbHelper.replaceNull(serviceAddress.zipCode,true)
   };
   return `${address.street}${address.houseNumber}${address.city}${address.state}${address.zipCode}`;
}
}
