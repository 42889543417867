import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneMask]',
})
export class PhoneMaskDirective {

  constructor(public ngControl: NgControl) { }
  /**
   * Used to change the value to the model of ngControl
   */
  setngControlValuetoModel() {
    const initialOnChange = (this.ngControl.valueAccessor as any).onChange;
    (this.ngControl.valueAccessor as any).onChange = (value) => initialOnChange(this.onInputChange(value,false));
  }

  @HostListener('ngModelChange', ['$event'])
   onModelChange(event) {
    this.setngControlValuetoModel();
    this.ngControl.valueAccessor.writeValue(this.onInputChange(event,false));
  }

  @HostListener('keydown.backspace', ['$event'])
   keydownBackspace(event) {
   this.setngControlValuetoModel();
   //ValueAccessor.writeValue() changes the value to view of the ngcontrol
   this.ngControl.valueAccessor.writeValue(this.onInputChange(event.target.value,false));
  }

  @HostListener('keydown', ['$event'])
  onkeydownAlphabet(event) {
    this.setngControlValuetoModel();
    let newValue: string;
    
      if (event.code && event.code.includes('Numpad')) {
        newValue = event.code.substr(5, 1);
      } else {
        newValue = String.fromCharCode(event.which).replace(/\D/g, '');
      }
    
    
    if (event.code && event.code.includes('Numpad')) {
      newValue = event.code.substr(5, 1);
    } else {
      newValue = String.fromCharCode(event.which).replace(/\D/g, '');
    }
  
    if (event.keyCode === 8 || event.keyCode === 9) {
      return;
    }
    
      if (window.event) {
        if ((window as any).event.shiftKey) {
          event.preventDefault();
        }
      }
  }

  onInputChange(event, backspace?:boolean) {
    if (event) {
        let newVal = event.replace(/\D/g, '');
        if (backspace && newVal.length <= 6) {
        newVal = newVal.substring(0, newVal.length);
      }
        if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length <= 3) {
        newVal = newVal.replace(/^(\d{0,3})/, '$1');
      } else if (newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
      } else if (newVal.length <= 10) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
      } else {
        newVal = newVal.substring(0, 10);
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
      }
      return newVal;
    }
  }
}
