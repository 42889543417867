import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  BillingSummary, CbccTrialAlert, DigitalProfileData,
  Profile, TicketDTO, VisitorType, NewRelicAttributes
} from '../shared/cb-header/cb-header.model';
import { GraphQLSubscription } from '@aws-amplify/api';
import { AWS } from '../constants/aws.model';
import { API } from 'aws-amplify';
import { AwsHelperService } from './awshelper.service';
import { CommonMessageService } from '../shared/redesign/commonmessage/commonmessage.service';
import {
  DigitalProfile, MapDetails,
  ServiceLocations
} from '../shared/cb-maps/cb-maps.model';
import {
  Asset, CacheType, EvictionRequest,
  EvictionType, Features, AssetFamily, TicketDetails, SelfInstallStatus, DigitalProfileResponse, E911NotificationStatus,
  OrderConnection
} from 'digital-profile-dashboard/digital-profile-dashboard.model';
import { Router } from '@angular/router';
import { CbHeaderService } from '../shared/cb-header/cb-header.service';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig, CBHelperService, CoxHttpClient, LocalStorageKeys, NewRelicService } from 'common-ui-lib';
import { v4 as uuid } from 'uuid';
import { LocalStorageService } from 'ngx-localstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable()
export class DigitalProfileSharedService {
  public hasOutage = new BehaviorSubject<boolean>(null);
  public notificationData: Profile;
  public ticketsData: TicketDTO;
  public netAssuranceStatus = false;
  public digitalProfileData = new DigitalProfileData();
  public getDigitalProfileResponse = new BehaviorSubject<DigitalProfileData>(null);
  public getServiceLocationResponse = new BehaviorSubject<MapDetails>(null);
  public getBillingSummaryResponse = new BehaviorSubject<BillingSummary>(null);
  public tickets = new BehaviorSubject<TicketDetails>(null);
  public event = new BehaviorSubject<Event>(null);
  public selfInstallDetails = new BehaviorSubject<SelfInstallStatus>(null);
  public orderConnection = new BehaviorSubject<OrderConnection>(null);
  public getAssets = new BehaviorSubject<Asset[]>(null);
  public errorResponse = new BehaviorSubject<Features>(null);
  public accountGuid = new BehaviorSubject<string>(null);
  public outageSubs: Subscription;
  public billingSubs: Subscription;
  public serviceLocations: ServiceLocations[];
  public outageSubCounter = 1;
  public billingSubCounter = 1;
  public isBillingData = false;
  public lastFetchedOutageDateTime: number;
  public lastFetchedBillingDateTime: number;
  public outageTimer: number;
  public billingTimer: number;
  public outageHeathError = false;
  public profileGuid: string;
  public cbccAlertData: CbccTrialAlert;
  public accGuid: string;
  public alertDesc: string;
  public checkMultiAccount = false;
  public digitalProfileResp: DigitalProfileResponse;
  public profileData: Profile;
  public mapDetails: MapDetails;
  public hideSelfInstall = false;
  public isEUWOP = false;
  public isOutageData = false;
  public isConsolidated: boolean;
  public ticket: string;
  public hasVoicePermission: boolean;
  public e911NotificationStatus: boolean;
  public isNewCustomer = false;
  public backLocation: string; 
  private serviceAppointments = [];
  private genericErrorMessage = 'We\'re sorry. We can\'t complete your request at this time. Please try again.';
  private jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private apollo: Apollo,
    public cbHeaderService: CbHeaderService,
    private _storageService: LocalStorageService,
    private awsService: AwsHelperService,
    private commonMessageService: CommonMessageService,
    private router: Router,
    private datePipe: DatePipe,
    private cookieService: CookieService,
    private config: AppConfig,
    private httpClient: CoxHttpClient,
    private cbHelper: CBHelperService,
    private newRelicService: NewRelicService
    ) { }

  // To trigger query call to fetch digital profile data
  public async getDigitalProfileDetails(profileGuid: string) {
    //Eligibility call is made only when user is logged in and isConsolidated is undefined
    if(this.isConsolidated === undefined && this.cbHelper.getLoggedInUserDetails()){
      this.isConsolidatedAccount();
    }
    this.isNewCustomer = this.cbHelper.getLoggedInUserDetails()?.profile?.userToken?.profileStatus === 'REGISTERED';
    this.hasVoicePermission = this.cbHelper.getLoggedInUserDetails()?.profile?.roles.includes('vmgr');
    this.profileData = null;
    this.outageSubs?.unsubscribe();
    this.billingSubs?.unsubscribe();
    if(this.isNewCustomer) {
      const results = {
        data: {
          getDigitalProfile: {
            ticketDetails: {
              openCount: 0,
              resolvedCount: 0,
              totalCount: 0,
              vipCustomer: false,
              tickets: [],
            },
            profileAssets: {
              assets: [],
              serviceLocationAssets: [],
            },
            cbccTrialAlert: {
              alertStatus: false,
              trialEndDate: null,
              lastTrialAccountNum: null,
            },
            profileGuid: '',
            serviceLocations: [],
            e911NotificationStatus: {
              e911OptedOut: false,
            },
            events: [],
            billingSummary: {
              lastFetchedDateTime: 0,
              balanceDue: 0,
              dueDate: null,
              pastDueStatementCount: 0,
              currentStatementCount: 0,
              accountCount: 0,
              isIBill: false,
              isSmartBill: false,
              currentStatements: [],
              pastDueStatements: [],
            }
          }
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.fetchRegisteredProfileDetails(profileGuid).subscribe(async (response: any) => {
        results.data.getDigitalProfile.profileAssets = response.data.getDigitalProfile.profileAssets;
        this.digitalProfileResp = results.data.getDigitalProfile;
        await this.fetchServiceLocations(profileGuid);
        results.data.getDigitalProfile.serviceLocations = this.serviceLocations;
        results.data.getDigitalProfile.profileGuid = this.profileGuid;
        this.cbHeaderService.setProfileDetails(results);
        const deviceInstallDetails = {
          euAcctGuid: null,
          selfInstallStatus: 'No Install'
        };
        this.setSelfInstall(deviceInstallDetails);
        this.setOrderConnection(response.data?.getDigitalProfile?.orders);
      });
    } else {
      console.log('Subscription - Initial parallel call for billing and outage', new Date());
      this.outageSubscriptionCall(profileGuid);
      this.billingSummarySubscription(profileGuid);
      this.fetchServiceLocations(profileGuid);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.getProfileDetails('ALL', profileGuid).subscribe((response: any) => {
        if (response?.data?.getProfile) {
          this.profileData = response?.data?.getProfile;
          this.setSelfInstall(this.profileData?.deviceInstallDetails);
          this.setHeaderMenuDetails(this.digitalProfileResp?.outageData, this.profileData,
            this.digitalProfileResp?.ticketDetails);
        } else {
          this.setSelfInstall(null);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.getDigitalProfile(profileGuid).subscribe((results: any) => {
        let outageData;
        let ticketsData;
        let profileData;
        if (results?.data && results?.data?.getDigitalProfile) {
          outageData = results.data.getDigitalProfile.outageData;
          ticketsData = results.data.getDigitalProfile.ticketDetails;
          this.cbccAlertData = results?.data?.getDigitalProfile?.cbccTrialAlert;
          // Need for CBMA1-60462
          this.e911NotificationStatus =
            results?.data?.getDigitalProfile?.e911NotificationStatus?.e911OptedOut ? true : false;
          this.digitalProfileResp = results.data.getDigitalProfile;
          this.getServiceAppointments(results.data?.getDigitalProfile?.events);
          this.setOrderConnection(results.data?.getDigitalProfile?.orders);
        } else {
          this.serviceLocations = [];
          this.digitalProfileResp = null;
          outageData = null;
          ticketsData = null;
          // Need for CBMA1-60462
          this.e911NotificationStatus = false;
        }
        results.data.getDigitalProfile.serviceLocations = this.serviceLocations;
        results.data.getDigitalProfile.profileGuid = this.profileGuid;
        this.setHeaderMenuDetails(this.digitalProfileResp?.outageData, this.profileData,
          this.digitalProfileResp?.ticketDetails);
        this.setServiceLocationOutages(results.data.getDigitalProfile, true);
        this.cbHeaderService.setProfileDetails(results);
        if (results?.data?.getDigitalProfile?.billingSummary) {
          this.isBillingData = true;
          this.lastFetchedBillingDateTime =
            results?.data?.getDigitalProfile?.billingSummary?.lastFetchedDateTime;
          this.billingSubs?.unsubscribe();
          console.log('Subscription - unsubscribed as billingSummary received in query call', new Date());
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.getProfileDetails('ALL', profileGuid).subscribe((response: any) => {
          if (response?.data?.getProfile) {
            profileData = response?.data?.getProfile;
            this.checkMultiAccount = profileData?.isMultiAccount;
          }
        });
        if (results?.errors) {
          const assetsErrorInfo = this.commonMessageService.getErrorInfo(results.errors, 'profileAssets');
          if (assetsErrorInfo && !this.isNewCustomer) {
            this.commonMessageService.showMessage(this.genericErrorMessage, 'error', 'digitalProfile');
          }
        }
      });
    }
  }

  public async fetchServiceLocations(profileGuid: string) {
    let data: DigitalProfile;
    try {
      data = await this.getServiceLocationList(profileGuid);
      this.serviceLocations = data?.serviceLocations;
      this.profileGuid = data?.profileGuid;
      this.accGuid = this.serviceLocations[0]?.billingAccounts[0]?.accountGuid;
      this.setAccountGuid(this.accGuid);
      if (this.digitalProfileResp) {
        this.digitalProfileResp.serviceLocations = this.serviceLocations;
        this.setServiceLocationOutages(this.digitalProfileResp, true);
      }
    } catch (error) {
      throw new Error('digital profile call fail');
    }
    this.setCustomNewRelicAttribute(data);
  }

  // To trigger Outage Details subscription call
  public async outageSubscriptionCall(profileGuid: string) {
    this.outageSubs = this.outageSubscription(profileGuid).subscribe((response) => {
      if (response.value?.data?.subscribeOutageDetails) {
        const outageDetails = response.value.data.subscribeOutageDetails;
        this.trackOutageStatus(outageDetails);
        this.outageSubs?.unsubscribe();
        console.log('Subscription - unsubscribed outage as data received in the response', new Date());
        const locationIds = [];
        outageDetails.outages.forEach((item) => {
          locationIds.push(item.saSfdcId);
        });
        this.serviceLocations?.forEach((ele) => {
          const itemIndex = locationIds.indexOf((ele.saSfdcId));
          if (itemIndex > -1) {
            ele.outages = outageDetails.outages[itemIndex];
          } else {
            ele.outages = {
              saSfdcId: ele.saSfdcId,
              outageDetails: []
            };
          }
        });
        this.setHeaderMenuDetails(outageDetails, this.profileData,
          this.getDigitalProfileResponse?.value?.headerTickets);
        this.lastFetchedOutageDateTime = outageDetails?.lastFetchedDateTime ?
         outageDetails?.lastFetchedDateTime : this.lastFetchedOutageDateTime;
        this.mapDetails = {
          serviceLocations: this.serviceLocations,
          lastFetchedDateTime: this.lastFetchedOutageDateTime,
          uuid: uuid()
        };
        this.setUpdatedServiceLocationDetails(this.mapDetails);
        this.isOutageData = true;
      } else {
        if (this.outageSubCounter === 1) {
          console.log('Subscription - Retry once for outage if subscription response is error', new Date());
          this.outageSubscriptionCall(profileGuid);
          this.outageSubCounter++;
        } else {
          if (!this.serviceLocations[0]?.outages || this.lastFetchedOutageDateTime) {
            this.setErrorResponse(Features.OUTAGE);
            this.outageHeathError = !this.lastFetchedOutageDateTime ? true : false;
          }
        }
      }
    });
    this.outageTimer = Date.now();
    this.awsService.configureTimeout('outage');
    this.awsService.subsTimeBehavior.subscribe((response) => {
      let time = Date.now() - this.outageTimer;
      time = Math.floor(time / 1000);
      if (response === 'outage' && time === 90) {
        this.outageSubs?.unsubscribe();
        if (this.outageSubCounter === 1 && !this.isOutageData) {
          this.outageSubCounter++;
          console.log('Subscription - Retry once with parallel calls for outage', new Date());
          this.outageSubscriptionCall(profileGuid);
          this.getOutageInfo(profileGuid);
        } else {
          if (this.serviceLocations.length && (!this.serviceLocations[0]?.outages || this.lastFetchedOutageDateTime)) {
            this.setErrorResponse(Features.OUTAGE);
            this.outageHeathError = !this.lastFetchedOutageDateTime ? true : false;
          }
        }
      }
    });
  }

  // To trigger Billing Summary subscription call
  public billingSummarySubscription(profileGuid: string) {
    this.billingSubs = this.billingSubscription(profileGuid).subscribe((response) => {
      if (response?.value?.data?.subscribeBillingSummary?.billingSummary) {
        const billingResponse = response?.value?.data?.subscribeBillingSummary.billingSummary;
        this.lastFetchedBillingDateTime = billingResponse?.lastFetchedDateTime;
        this.setBillingSummary(billingResponse);
        this.isBillingData = true;
        this.billingSubs?.unsubscribe();
        console.log('Subscription - unsubscribed billing as data received in the response', new Date());
      } else {
        if (this.billingSubCounter === 1) {
          console.log('Subscription - Retry once with parallel calls for billing', new Date());
          this.billingSummarySubscription(profileGuid);
          this.billingSubCounter++;
        } else {
          this.setErrorResponse(Features.BILLING);
        }
      }
    });
    this.billingTimer = Date.now();
    this.awsService.configureTimeout('billing');
    this.awsService.subsTimeBehavior.subscribe((response) => {
      let time = Date.now() - this.billingTimer;
      time = Math.floor(time / 1000);
      if (response === 'billing' && time === 90) {
        this.billingSubs?.unsubscribe();
        if (this.billingSubCounter === 1 && !this.isBillingData) {
          this.billingSubCounter++;
          console.log('Subscription - Retry once for billing if subscription response is error', new Date());
          this.billingSummarySubscription(profileGuid);
          this.getBillingInfo(profileGuid);
        } else {
          if (!this.isBillingData || this.lastFetchedBillingDateTime) {
            this.setErrorResponse(Features.BILLING);
          }
        }
      }
    });
  }

  // To create outage indicators for header
  public notificationIndicatorData(outageData, profileData?, digitalProfileData?: DigitalProfileResponse) {
    this.notificationData = {
      indicatorEvents: {
        plannedOutageIndicator: outageData?.plannedOutageIndicator ? outageData.plannedOutageIndicator : false,
        promotionalOutageIndicator: profileData?.indicatorEvents?.promotionalOutageIndicator ?
          profileData?.indicatorEvents?.promotionalOutageIndicator : false
      },
      unplannedOutageIndicator: outageData?.unplannedOutageIndicator ? outageData?.unplannedOutageIndicator : false,
      deviceInstallDetails: profileData?.deviceInstallDetails,
      pinpointAlerts: profileData?.pinpointAlerts,
      profileStatus: profileData?.profileStatus,
      isMultiAccount: profileData?.isMultiAccount,
      cbccTrialAlert: this.cbccAlertData,
      hasServiceAppointment: this.getServiceAppointmentNotification()
    };
    return this.notificationData;
  }

  // To create header tickets data
  public headerTicketsData(ticketDetails) {
    const totalCount = ticketDetails?.openCount;
    const ticketList = ticketDetails?.ticketsList;
    const vipCustomer = ticketDetails?.vipCustomer;
    this.ticketsData = {
      ticketsCount: totalCount,
      ticketsList: ticketList,
      vipCustomer
    };
    return this.ticketsData;
  }

  // To assign indiviual service location outage details from profile outage details
  public setServiceLocationOutages(digitalProfileData, updateAssets?: boolean) {
    if (this.serviceLocations?.length === 0) {
      this.isOutageData = true;
      this.outageSubs?.unsubscribe();
      return;
    }
    const profileOutage = digitalProfileData?.outageData?.outages;
    const locationIds = [];
    if (profileOutage) {
      profileOutage?.forEach((item) => {
        locationIds.push(item.saSfdcId);
      });
      this.serviceLocations?.forEach((ele) => {
        const itemIndex = locationIds.indexOf((ele?.saSfdcId));
        if (itemIndex > -1) {
          ele.outages = profileOutage[itemIndex];
        } else {
          ele.outages = {
            saSfdcId: ele.saSfdcId,
            outageDetails: []
          };
        }
      });
    }
    if (updateAssets) {
      const assetIds = [];
      const serviceLocationAssets = digitalProfileData?.profileAssets?.serviceLocationAssets;
      serviceLocationAssets?.forEach((item) => {
        assetIds.push(item.saSfdcId);
      });
      this.serviceLocations?.forEach((ele) => {
        const itemId = assetIds.indexOf(ele?.saSfdcId);
        if (itemId > -1) {
          const supportAssetIndex = serviceLocationAssets[itemId].assets
            .findIndex(asset => asset.assetFamily === AssetFamily.SUPPORT);
          if (supportAssetIndex > -1) {
            serviceLocationAssets[itemId].assets.splice(supportAssetIndex, 1);
          }
          ele.assets = serviceLocationAssets[itemId].assets;
        }
      });
    }
    this.lastFetchedOutageDateTime = digitalProfileData.outageData?.lastFetchedDateTime ?
     digitalProfileData.outageData?.lastFetchedDateTime : this.lastFetchedOutageDateTime;
    this.mapDetails = {
      serviceLocations: this.serviceLocations,
      lastFetchedDateTime: this.lastFetchedOutageDateTime,
      uuid: uuid()
    };
    this.setUpdatedServiceLocationDetails(this.mapDetails);
    if (this.serviceLocations?.length > 0) {
      const hasOutageData = this.serviceLocations[0]?.outages;
      if (hasOutageData) {
        this.isOutageData = true;
        this.outageSubs?.unsubscribe();
        console.log('Subscription - unsubscribed as outageDetails received in query', new Date());
      }
    }
  }

  //To fetch Header details on click
  public fetchHeaderDetails(profileGuid: string) {
    let outageData;
    let ticketsData;
    this.profileData = null;
    this.hasVoicePermission = this.cbHelper.getLoggedInUserDetails()?.profile?.roles?.includes('vmgr');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.getNotificationIndicators(profileGuid).subscribe((results: any) => {
      if (results?.data && results?.data?.getDigitalProfile) {
        if (!this.router.url.includes('/portal')) {
          this.isBillingData = false;
          this.isOutageData = false;
          this.serviceLocations = [];
        }
        // Need for CBMA1-60786
        this.e911NotificationStatus = results?.data?.getDigitalProfile?.e911NotificationStatus?.e911OptedOut
        ? true : false;
        outageData = results.data.getDigitalProfile.outageData;
        ticketsData = results.data.getDigitalProfile.ticketDetails;
        this.cbccAlertData = results?.data?.getDigitalProfile?.cbccTrialAlert;
        this.digitalProfileResp = results.data.getDigitalProfile;
        this.getServiceAppointments(results.data?.getDigitalProfile?.events);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.getProfileDetails('ALL', profileGuid).subscribe((response: any) => {
          if (response?.data && response?.data?.getProfile) {
            this.profileData = response.data.getProfile;
            this.checkMultiAccount = this.profileData?.isMultiAccount;
            this.setHeaderMenuDetails(this.digitalProfileResp?.outageData, this.profileData,
              this.digitalProfileResp?.ticketDetails);
            this.setTickets(results?.data?.getDigitalProfile?.ticketDetails);
            this.setEvents(results?.data?.getDigitalProfile?.events);
            this.setSelfInstall(this.profileData?.deviceInstallDetails);
          }
        });
      }
    });
    this.setCustomNewRelicAttribute();
  }

  // To fetch outage details when subscription is timed out
  public getOutageInfo(profileGuid: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.getOutageData(profileGuid).subscribe((results: any) => {
      if (results?.data?.getDigitalProfile?.outageData) {
        this.outageSubs?.unsubscribe();
        const outageDetails = results?.data?.getDigitalProfile?.outageData;
        this.trackOutageStatus(outageDetails);
        const locationIds = [];
        outageDetails.outages.forEach((item) => {
          locationIds.push(item.saSfdcId);
        });
        this.serviceLocations.forEach((ele) => {
          const itemIndex = locationIds.indexOf((ele.saSfdcId));
          if (itemIndex > -1) {
            ele.outages = outageDetails.outages[itemIndex];
          } else {
            ele.outages = {
              saSfdcId: ele.saSfdcId,
              outageDetails: []
            };
          }
        });
        this.setHeaderMenuDetails(outageDetails, this.profileData,
          this.getDigitalProfileResponse?.value?.headerTickets);
        this.lastFetchedOutageDateTime = outageDetails?.lastFetchedDateTime ?
         outageDetails?.lastFetchedDateTime : this.lastFetchedOutageDateTime;
        this.mapDetails = {
          serviceLocations: this.serviceLocations,
          lastFetchedDateTime: this.lastFetchedOutageDateTime,
          uuid: uuid()
        };
        this.setUpdatedServiceLocationDetails(this.mapDetails);
        this.isOutageData = true;
      }
      if (results?.errors) {
        const outageErrorInfo = this.commonMessageService.getErrorInfo(results.errors, 'outageData');
        if (outageErrorInfo?.code !== '204') {
          if (!this.serviceLocations[0]?.outages) {
            this.setErrorResponse(Features.OUTAGE);
            this.outageHeathError = true;
          }
        }
      }
    });
  }

  // To fetch billing summary when subscription is timed out
  public getBillingInfo(profileGuid: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.getBillingSummary(profileGuid).subscribe((results: any) => {
      if (results?.data?.getDigitalProfile?.billingSummary) {
        this.billingSubs.unsubscribe();
        const billingResponse = results?.data?.getDigitalProfile?.billingSummary;
        this.lastFetchedBillingDateTime = billingResponse?.lastFetchedDateTime;
        this.setBillingSummary(billingResponse);
        this.isBillingData = true;
      } else {
        const billingErrorInfo = this.commonMessageService.getErrorInfo(results.errors, 'billingSummary');
        if (billingErrorInfo?.code !== '204') {
          this.setErrorResponse(Features.BILLING);
        }
      }
    });
  }

  //fetch outage/billing details on refresh of large profile scenario
  public getRefreshDetails(profileGuid: string, cacheType: Features) {
    const sessionId = this.cookieService.get(this.config.getConfig('cookies')['currentUserSessionId']);
    const input: EvictionRequest = {
      sessionId,
      entityId: profileGuid,
      cacheType: CacheType[cacheType],
      profileGuid,
      rebuild: false,
      evictionType: EvictionType.REFRESH,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.evictCache(input).subscribe((results: any) => {
      if (results.data?.evictCache?.code === '0') {
        this.refreshSubscription(profileGuid, cacheType);
      }
      if (results.errors) {
        const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'evictCache');
        if (errorInfo?.code === '121') {
          this.refreshSubscription(profileGuid, cacheType);
        } else {
          this.setErrorResponse(cacheType);
        }
      }
    });
  }

  // outage/billing subscription based on cache type
  public refreshSubscription(profileGuid: string, cacheType: Features) {
    switch (cacheType) {
      case Features.BILLING_LARGE_PROFILE:
        this.billingSummarySubscription(profileGuid);
        this.billingSubCounter = 1;
        this.isBillingData = false;
        break;
      case Features.OUTAGE_LARGE_PROFILE:
        this.outageSubscriptionCall(profileGuid);
        this.outageSubCounter = 1;
        this.isOutageData = false;
        break;
    }
  }

  public getTrialdaysLeft(endDate) {
    const currentDate = new Date();
    const remainingDays = endDate?.getTime() - currentDate?.getTime();
    const totalRemainingDays = Math.floor(remainingDays / (1000 * 60 * 60 * 24)) + 1 <= 0 ? 0 :
      Math.floor(remainingDays / (1000 * 60 * 60 * 24)) + 1;
    this.alertDesc = totalRemainingDays === 1 ? (totalRemainingDays.toString() + ' day') :
      (totalRemainingDays.toString() + ' days');
  }

  // Query call to fetch Digital Profile Details
  public getDigitalProfile(profileGuid: string) {
    if(this.isConsolidated && this.hasVoicePermission){
      const digitalProfileQuery = gql`
      query getDigitalProfile($profileGuid: ID!) {
        getDigitalProfile(id: $profileGuid) {
          ticketDetails(ticketListFlag:true){
            openCount
            resolvedCount
            totalCount
            vipCustomer
            ticketsList {
              id
              requestType
              createDateTime
              statusType
              truckRoll
              workOrderNumber
            }
          }
          profileAssets {
            assets {
              assetFamily
              assetName
              assetType
              id
              status
              feature {
                featureName
                buyFlowEligible
              }
            }
            serviceLocationAssets {
              saSfdcId
              assets {
                assetFamily
                assetName
                assetType
                status
                feature {
                  featureName
                }
              }
            }
          }
          cbccTrialAlert {
            alertStatus
            trialEndDate
            lastTrialAccountNum
          }
          e911NotificationStatus{
            e911OptedOut
          }
          outageData {
            lastFetchedDateTime
            plannedOutageIndicator
            unplannedOutageIndicator
            outages {
              saSfdcId
              outageDetails {
                outageType
                outageDate
                message
                eventTypeGroup
                etr
                notificationFlag
                phoneNumber
                status
                symptoms
              }
            }
          }
          events {
            saSfdcId
            type
            data {
              serviceType
              subType
              scheduleDate
              timeSlot
              estimatedDuration
              accountGuid
            }
          }
          billingSummary {
            accountCount
            isIBill
          }
          orders(first: 1, last: 10, fromDate: "", toDate: "", searchString: "") {
            totalCount
            edges {
              cursor
              node {
                selfInstallCount
                orderId
                orderNumber
                products {
                  devices {
                    selfInstall
                    accountGuid
                    deliveryTracker {
                      status
                    }
                  }
                  appointment {
                    type
                    data {
                      accountGuid
                      status
                    }
                  }
                }

              }
            }
          }
        }
      }    
      `;
      return this.apollo.use('digitalProfile').query({
        query: digitalProfileQuery,
        variables: {
          profileGuid
        }
      });
    } else if(this.isConsolidated && !this.hasVoicePermission){
      const digitalProfileQuery = gql`
      query getDigitalProfile($profileGuid: ID!) {
        getDigitalProfile(id: $profileGuid) {
          ticketDetails(ticketListFlag:true){
            openCount
            resolvedCount
            totalCount
            vipCustomer
            ticketsList {
              id
              requestType
              createDateTime
              statusType
              truckRoll
              workOrderNumber
            }
          }
          profileAssets {
            assets {
              assetFamily
              assetName
              assetType
              id
              status
              feature {
                featureName
                buyFlowEligible
              }
            }
            serviceLocationAssets {
              saSfdcId
              assets {
                assetFamily
                assetName
                assetType
                status
                feature {
                  featureName
                }
              }
            }
          }
          cbccTrialAlert {
            alertStatus
            trialEndDate
            lastTrialAccountNum
          }
          outageData {
            lastFetchedDateTime
            plannedOutageIndicator
            unplannedOutageIndicator
            outages {
              saSfdcId
              outageDetails {
                outageType
                outageDate
                message
                eventTypeGroup
                etr
                notificationFlag
                phoneNumber
                status
                symptoms
              }
            }
          }
          events {
            saSfdcId
            type
            data {
              serviceType
              subType
              scheduleDate
              timeSlot
              estimatedDuration
              accountGuid
            }
          }
          billingSummary {
            accountCount
            isIBill
          }
         orders(first: 1, last: 10, fromDate: "", toDate: "", searchString: "") {
            totalCount
            edges {
              cursor
              node {
                selfInstallCount
                orderId
                orderNumber
                products {
                  devices {
                    selfInstall
                    accountGuid
                    deliveryTracker {
                      status
                    }
                  }
                  appointment {
                    type
                    data {
                      accountGuid
                      status
                    }
                  }
                }

              }
            }
          }
        }      
      }    
      `;
      return this.apollo.use('digitalProfile').query({
        query: digitalProfileQuery,
        variables: {
          profileGuid
        }
      });
    }else if (this.hasVoicePermission) {
      const digitalProfileQuery = gql`
      query getDigitalProfile($profileGuid: ID!) {
        getDigitalProfile(id: $profileGuid) {
          ticketDetails(ticketListFlag:true){
            openCount
            resolvedCount
            totalCount
            vipCustomer
            ticketsList {
              id
              requestType
              createDateTime
              statusType
              truckRoll
              workOrderNumber
            }
          }
          profileAssets {
            assets {
              assetFamily
              assetName
              assetType
              id
              status
              feature {
                featureName
                buyFlowEligible
              }
            }
            serviceLocationAssets {
              saSfdcId
              assets {
                assetFamily
                assetName
                assetType
                status
                feature {
                  featureName
                }
              }
            }
          }
          cbccTrialAlert {
            alertStatus
            trialEndDate
            lastTrialAccountNum
          }
          e911NotificationStatus{
            e911OptedOut
          }
          outageData {
            lastFetchedDateTime
            plannedOutageIndicator
            unplannedOutageIndicator
            outages {
              saSfdcId
              outageDetails {
                outageType
                outageDate
                message
                eventTypeGroup
                etr
                notificationFlag
                phoneNumber
                status
                symptoms
              }
            }
          }
          events {
            saSfdcId
            type
            data {
              serviceType
              subType
              scheduleDate
              timeSlot
              estimatedDuration
              accountGuid
            }
          }
          billingSummary {
            lastFetchedDateTime
            balanceDue
            dueDate
            pastDueStatementCount
            currentStatementCount
            accountCount
            isIBill
            isSmartBill
            currentStatements {
              accountNumber
              statementCode
              statementPdfId
              cycleDate
            }
            pastDueStatements {
              accountNumber
              statementCode
              statementPdfId
              cycleDate
            }
          }

           orders(first: 1, last: 10, fromDate: "", toDate: "", searchString: "") {
            totalCount
            edges {
              cursor
              node {
                selfInstallCount
                orderId
                orderNumber
                products {
                  devices {
                    selfInstall
                    accountGuid
                    deliveryTracker {
                      status
                    }
                  }
                  appointment {
                    type
                    data {
                      accountGuid
                      status
                    }
                  }
                }

              }
            }
          }
        }
      }
          
      `;
      return this.apollo.use('digitalProfile').query({
        query: digitalProfileQuery,
        variables: {
          profileGuid
        }
      });
    } else {
      const digitalProfileQuery = gql`
      query getDigitalProfile($profileGuid: ID!) {
        getDigitalProfile(id: $profileGuid) {
          ticketDetails(ticketListFlag:true){
            openCount
            resolvedCount
            totalCount
            vipCustomer
            ticketsList {
              id
              requestType
              createDateTime
              statusType
              truckRoll
              workOrderNumber
            }
          }
          profileAssets {
            assets {
              assetFamily
              assetName
              assetType
              id
              status
              feature {
                featureName
                buyFlowEligible
              }
            }
            serviceLocationAssets {
              saSfdcId
              assets {
                assetFamily
                assetName
                assetType
                status
                feature {
                  featureName
                }
              }
            }
          }
          cbccTrialAlert {
            alertStatus
            trialEndDate
            lastTrialAccountNum
          }
          outageData {
            lastFetchedDateTime
            plannedOutageIndicator
            unplannedOutageIndicator
            outages {
              saSfdcId
              outageDetails {
                outageType
                outageDate
                message
                eventTypeGroup
                etr
                notificationFlag
                phoneNumber
                status
                symptoms
              }
            }
          }
          events {
            saSfdcId
            type
            data {
              serviceType
              subType
              scheduleDate
              timeSlot
              estimatedDuration
              accountGuid
            }
          }
          billingSummary {
            lastFetchedDateTime
            balanceDue
            dueDate
            pastDueStatementCount
            currentStatementCount
            accountCount
            isIBill
            isSmartBill
            currentStatements {
              accountNumber
              statementCode
              statementPdfId
              cycleDate
            }
            pastDueStatements {
              accountNumber
              statementCode
              statementPdfId
              cycleDate
            }
          }
          orders(first: 1, last: 10, fromDate: "", toDate: "", searchString: "") {
            totalCount
            edges {
              cursor
              node {
                selfInstallCount
                orderId
                orderNumber
                products {
                  devices {
                    selfInstall
                    accountGuid
                    deliveryTracker {
                      status
                    }
                  }
                  appointment {
                    type
                    data {
                      accountGuid
                      status
                    }
                  }
                }

              }
            }
          }
        }
      }
          
      `;
      return this.apollo.use('digitalProfile').query({
        query: digitalProfileQuery,
        variables: {
          profileGuid
        }
      });
    }
  }

  // Query call to fetch details for Registered profile
  public fetchRegisteredProfileDetails(profileGuid: string) {
    const registeredDigitalProfileQuery = gql`
      query getRegisteredProfileDetails($profileGuid: ID!) {
        getDigitalProfile(id: $profileGuid) {
          profileAssets {
            assets {
              assetFamily
              assetName
              assetType
              id
              status
              feature {
                featureName
                buyFlowEligible
              }
            }
            serviceLocationAssets {
              saSfdcId
              assets {
                assetFamily
                assetName
                assetType
                status
              }
            }
          }
           orders(first: 1, last: 10, fromDate: "", toDate: "", searchString: "") {
            totalCount
            edges {
              cursor
              node {
                selfInstallCount
                orderId
                orderNumber
                products {
                  devices {
                    selfInstall
                    accountGuid
                    deliveryTracker {
                      status
                    }
                  }
                  appointment {
                    type
                    data {
                      accountGuid
                      status
                    }
                  }
                }

              }
            }
          }

        }
      }    
      `;
      return this.apollo.use('digitalProfile').query({
        query: registeredDigitalProfileQuery,
        variables: {
          profileGuid
        }
      });
  }

  // Query call to fetch only header details
  public getNotificationIndicators(profileGuid: string) {
    if (this.hasVoicePermission) {
    const notificationsQuery = gql`
    query getNotificationIndicators($profileGuid: ID!) {
      getDigitalProfile(id: $profileGuid) {
        outageData {
          plannedOutageIndicator
          unplannedOutageIndicator
        }
        ticketDetails(ticketListFlag:true){
          openCount
          resolvedCount
          totalCount
          vipCustomer
          ticketsList {
            id
            requestType
            createDateTime
            statusType
            truckRoll
            workOrderNumber
          }
        }
        cbccTrialAlert {
          alertStatus
          trialEndDate
          lastTrialAccountNum
        }
        e911NotificationStatus{
          e911OptedOut
        }
        events {
          saSfdcId
          type
          data {
            serviceType
	          subType
	          scheduleDate
	          timeSlot
	          estimatedDuration
	          accountGuid
          }
        }
      }
    }
        
    `;
    return this.apollo.use('digitalProfile').query({
      query: notificationsQuery,
      variables: {
        profileGuid
      }
    });
  } else {
    const notificationsQuery = gql`
    query getNotificationIndicators($profileGuid: ID!) {
      getDigitalProfile(id: $profileGuid) {
        outageData {
          plannedOutageIndicator
          unplannedOutageIndicator
        }
        ticketDetails(ticketListFlag:true){
          openCount
          resolvedCount
          totalCount
          vipCustomer
          ticketsList {
            id
            requestType
            createDateTime
            statusType
            truckRoll
            workOrderNumber
          }
        }
        cbccTrialAlert {
          alertStatus
          trialEndDate
          lastTrialAccountNum
        }
        events {
          saSfdcId
          type
          data {
            serviceType
	          subType
	          scheduleDate
	          timeSlot
	          estimatedDuration
	          accountGuid
          }
        }
      }
    }
        
    `;
    return this.apollo.use('digitalProfile').query({
      query: notificationsQuery,
      variables: {
        profileGuid
      }
    });
  }
  }

  // Query call to fetch Profile Details
  public getProfileDetails(accountStatusFilter: string, profileGuid: string) {
    const getProfileQuery = gql`
    query getProfile($accountStatusFilter: String!,$profileGuid: String!){  
      getProfile(accountStatusFilter: $accountStatusFilter) {
        deviceInstallDetails {
          selfInstallStatus
          euAcctGuid
        }
        indicatorEvents {
          promotionalOutageIndicator
        }
        pinpointAlerts {
          indicator
          alertsList {
            action
            message
          }
        }
        profileStatus(profileGuid: $profileGuid) {
          hasActiveAccount
          hasFormerAccount
        }
        isMultiAccount
      }
    }`;

    return this.apollo.query({
      query: getProfileQuery,
      variables: {
        accountStatusFilter,
        profileGuid
      },
    });
  }

  // Query call to fetch Outage Data
  public getOutageData(profileGuid: string) {
    const outageDataQuery = gql`
    query getDigitalProfile($profileGuid: ID!) {
      getDigitalProfile(id: $profileGuid) {
        outageData {
          lastFetchedDateTime
          plannedOutageIndicator
          unplannedOutageIndicator
          outages {
            saSfdcId
            outageDetails {
              outageType
              outageDate
              message
              eventTypeGroup
              etr
              notificationFlag
              phoneNumber
              status
              symptoms
            }
          }
        }
      }
    }`;
    return this.apollo.use('digitalProfile').query({
      query: outageDataQuery,
      variables: {
        profileGuid
      }
    });
  }

  // Query call to fetch Billing Summary
  public getBillingSummary(profileGuid: string) {
    if(this.isConsolidated){
      const billingProfileQuery = gql`
    query getDigitalProfile($profileGuid: ID!) {
      getDigitalProfile(id: $profileGuid) {
        billingSummary {
          isIBill
          accountCount
        }
      }
    }`;
    return this.apollo.use('digitalProfile').query({
      query: billingProfileQuery,
      variables: {
        profileGuid
      }
    });
  }else{
    const billingProfileQuery = gql`
    query getDigitalProfile($profileGuid: ID!) {
      getDigitalProfile(id: $profileGuid) {
        billingSummary {
          lastFetchedDateTime
          balanceDue
          dueDate
          pastDueStatementCount
          currentStatementCount
          accountCount
          isIBill
          isSmartBill
          currentStatements {
            accountNumber
            statementCode
            statementPdfId
            cycleDate
          }
          pastDueStatements {
            accountNumber
            statementCode
            statementPdfId
            cycleDate
          }
        }
      }
    }`;
    return this.apollo.use('digitalProfile').query({
      query: billingProfileQuery,
      variables: {
        profileGuid
      }
    });
  }
  }

//Subscription call to check if Account is Consolidated or not
  public  async isConsolidatedAccount(): Promise<void> {
    const consolidatedFlag  = this._storageService.get(LocalStorageKeys.IsConsolidated, 'cbma');
      const decodedConsolidatedFlag = consolidatedFlag ? this.cbHelper.decryptByCrypto(consolidatedFlag) : '';
      this.isConsolidated = decodedConsolidatedFlag === 'true' ? true: false;
  }

  // Subscription call to fetch Outage Details
  public outageSubscription(profileGuid: string) {
    const input = `${profileGuid}++${this.cookieService.get('cbma-okta-session-id')}`;
    const subscriptionForOutageDetails =
      `subscription subscribeOutageDetails($input: ID!) {
      subscribeOutageDetails(id:$input) {
        id
        lastFetchedDateTime
        plannedOutageIndicator
        unplannedOutageIndicator
        outages {
          saSfdcId
          outageDetails {
            etr
            eventTypeGroup
            message
            notificationFlag
            outageDate
            outageType
            phoneNumber
            symptoms
          }
        }
      }
    }`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return API.graphql<GraphQLSubscription<Observable<any>>>({
      query: subscriptionForOutageDetails,
      variables: {
        input
      },
      authMode: AWS.AUTHENTICATION_TYPE,
      authToken: this.awsService.headers()
    });
  }

  // Subscription call to fetch Billing Summary
  public billingSubscription(profileGuid: string) {
    const input = `${profileGuid}++${this.cookieService.get('cbma-okta-session-id')}`;
    const subscriptionForBillingSummary =
      `subscription subscribeBillingSummary($input: ID!) {
        subscribeBillingSummary(id: $input) {
          id
          billingSummary {
            lastFetchedDateTime
            balanceDue
            accountCount
            dueDate
            pastDueStatementCount
            currentStatementCount
            isIBill
            isSmartBill
            pastDueStatements {
              accountNumber
              cycleDate
              statementCode
              statementPdfId
            }
            currentStatements {
              accountNumber
              cycleDate
              statementCode
              statementPdfId
            }
          }
        }
    }`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return API.graphql<GraphQLSubscription<Observable<any>>>({
      query: subscriptionForBillingSummary,
      variables: {
        input
      },
      authMode: AWS.AUTHENTICATION_TYPE,
      authToken: this.awsService.headers()
    });
  }

  // Mutation call to evict cache
  public evictCache(input: EvictionRequest) {
    const evictCacheMutation = gql`	
      mutation evictCache($input: EvictionRequest!) {	
        evictCache(input: $input) {	
          transactionId	
          code	
          message	
          warning	
        }	
      }	
    `;
    return this.apollo.use('digitalProfile').mutate({
      mutation: evictCacheMutation,
      variables: {
        input
      },
    });
  }

  public async getServiceLocationList(profileGuid: string): Promise<DigitalProfile> {
    return this.httpClient.getAsync<DigitalProfile>
      (`/api/cbma/digitalprofile/services/digitalprofile/companyprofile/${profileGuid}?billingProfile=false`,
        { disableSpinner: true, customeErrorMessage: '' });
  }

  public setDigitalProfileDetails(response: DigitalProfileData) {
    this.getDigitalProfileResponse.next(response);
  }

  public setUpdatedServiceLocationDetails(response: MapDetails) {
    this.getServiceLocationResponse.next(response);
  }

  public setBillingSummary(response: BillingSummary) {
    this.getBillingSummaryResponse.next(response);
  }

  public setErrorResponse(response: Features) {
    this.errorResponse.next(response);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getErrorResponse(): Observable<any> {
    return this.errorResponse.asObservable();
  }


  public setAccountGuid(guid: string) {
    return this.accountGuid.next(guid);
  }

  public getAccountGuid(): Observable<string> {
    return this.accountGuid.asObservable();
  }

  public setEvents(events: Event) {
    this.event.next(events);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getEvents(): Observable<any> {
    return this.event.asObservable();
  }

  public setSelfInstall(selfInstallDetails: SelfInstallStatus) {
    this.selfInstallDetails.next(selfInstallDetails);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getSelfInstall(): Observable<any> {
    return this.selfInstallDetails.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getTickets(): Observable<any> {
    return this.tickets.asObservable();
  }

  public setTickets(tickets: TicketDetails) {
    this.tickets.next(tickets);
  }

  public setOrderConnection(orderConnection: OrderConnection | any) {
    this.orderConnection.next(orderConnection);
  }

  public hasOutageResponse(): Observable<any> {
    return this.hasOutage.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getOrderConnection(): Observable<any> {
    return this.orderConnection.asObservable();
  }

  public getServiceAppointments(serviceAppointments) {
    this.serviceAppointments = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < serviceAppointments?.length; i++) {
      if (serviceAppointments[i]?.data?.length) {
        this.serviceAppointments.push(serviceAppointments[i].data);
      }
    }
    this.serviceAppointments = [...this.serviceAppointments.flat()];
    this.filterUpcomingAppointments();
    this.sortAppointments();

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getServiceAppointmentNotification(): Profile | any {
    let appointment = {};
    if (this.serviceAppointments.length && this.serviceAppointments[0]?.scheduleDate ===
      this.serviceAppointments[1]?.scheduleDate &&
      this.serviceAppointments[0]?.timeSlot === this.serviceAppointments[1]?.timeSlot) {
      return appointment = {
        accountGuid: this.serviceAppointments[0]?.accountGuid,
        date: this.serviceAppointments[0]?.scheduleDate,
        isMultiple: true,
        time: this.serviceAppointments[0]?.timeSlot
      };
    } else {
      return appointment = {
        accountGuid: this.serviceAppointments[0]?.accountGuid ? this.serviceAppointments[0]?.accountGuid : null,
        date: this.serviceAppointments[0]?.scheduleDate ? this.serviceAppointments[0]?.scheduleDate : null,
        isMultiple: false,
        time: this.serviceAppointments[0]?.timeSlot ? this.serviceAppointments[0]?.timeSlot : null
      };
    }
  }
  // function to display buyflow links based on flag
  public isAddOnAvailable(featureName: string) {
    if(this.config.getConfig('featuredFlag')['buyFlowSwitchFlag'][featureName]?.active &&
    JSON.parse(this.config.getConfig('featuredFlag')['buyFlowSwitchFlag'][featureName]?.active)) {
      return true;
    } else if(this.config.getConfig('featuredFlag')['buyFlowSwitchFlag'][featureName]?.startDate?.length > 0 &&
    this.config.getConfig('featuredFlag')['buyFlowSwitchFlag'][featureName]?.endDate?.length > 0) {
      const startDate = new Date(this.config.getConfig('featuredFlag')
      ['buyFlowSwitchFlag'][featureName]['startDate']);
      const endDate = new Date(this.config.getConfig('featuredFlag')
      ['buyFlowSwitchFlag'][featureName]['endDate']);
      const currentDate = new Date();
      if(currentDate >= startDate && currentDate <= endDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  private sortAppointments() {
    this.serviceAppointments.sort((a, b) => {
      if (a.scheduleDate > b.scheduleDate) {
        return 1;
      } else if (a.scheduleDate < b.scheduleDate) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  private filterUpcomingAppointments() {
    const currentDate = this.datePipe.transform(new Date(), 'yyyyMMdd');
    this.serviceAppointments =
      this.serviceAppointments.filter((event) => event.scheduleDate >= currentDate);
  }

  // To set header menu details
  private setHeaderMenuDetails(outageData, profileData, ticketDetails) {
    this.digitalProfileData = {
      headerNotifications: this.notificationIndicatorData(outageData, profileData),
      headerTickets: this.headerTicketsData(ticketDetails)
    };
    this.setDigitalProfileDetails(this.digitalProfileData);
  }

  private setCustomNewRelicAttribute(data?: DigitalProfile) {
    const companyProfile = (data?.companyProfileId && data?.profileName) ?
      `${data?.companyProfileId}-${data?.profileName}` : '';
    const userId = this.cbHelper.isMyAdmin() ?
      this.cbHelper.getLoggedInUserDetails().profile.userToken.loggedInUserEmail :
      this.cbHelper.getLoggedInUserDetails().profile.userToken.email;
    const visitorType = this.cbHelper.isMyAdmin() ? VisitorType.CSR : VisitorType.CUSTOMER;
    this.newRelicService.setNewRelicAttribute(NewRelicAttributes.CompanyProfile, companyProfile);
    this.newRelicService.setNewRelicAttribute(NewRelicAttributes.UserID, userId || '');
    this.newRelicService.setNewRelicAttribute(NewRelicAttributes.VisitorType, visitorType);
  }

  private trackOutageStatus(outageDetails: any) {
    if (outageDetails?.plannedOutageIndicator || outageDetails?.unplannedOutageIndicator) {
      this.hasOutage.next(true);
    } else {
      this.hasOutage.next(false);
    }
  }
}
