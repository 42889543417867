/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { CBHelperService } from '../services/cbhelper.service';
@Injectable()
export class TealiumUtagService {
  scriptSrc = '';
  private userGuid: string;
  // Typically set 'noview' flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(private cbhelperService: CBHelperService) {
    this.userGuid = cbhelperService.getLoggedInUserDetails()?.profile?.userToken?.guid;
    (window as any).utag_cfg_ovrd = { noview: false };
    (window as any).utag_data = {};
  }

  // Generic script loader with callback
  // eslint-disable-next-line @typescript-eslint/ban-types
  getScript(src: string, callback: Function) {
    const d = document;
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    const o = { callback: callback || function() { } };
    if (typeof src === 'undefined') {
      return;
    }
    const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.charset = 'utf-8'; s.src = src;
    if (typeof o.callback === 'function') {
      if (d.addEventListener) {
        s.addEventListener('load', () => {
          o.callback();
        }, false);
      }
    }
    const t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  // Config settings used to build the path to the utag.js file
  setConfig(config: { account: string; profile: string; environment: string }) {
    if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
      this.scriptSrc =
       'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
    }
  }

  // Data layer is optional set of key/value pairs
  track(tealiumEvent: string, data?: any) {
    if (this.scriptSrc === '') {
      console.log('Tealium config not set.');
      //return;//Not executing the return here to facilitate the manual tealium tagging
    }
    if ((window as any).utag === undefined) {
      this.getScript(this.scriptSrc, () => {
        (window as any).utag.track(tealiumEvent, data);
      });
    } else {
      (window as any).utag.track(tealiumEvent, data);
    }
  }

  view(data?: any) {
    this.track('view', data);
  }

  link(data?: any) {
    this.track('link', data);
  }

  button(eventName: string, eventKey: string) {
    this.track('link', {
      eventName,
      selfHelpName: eventName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      my_key: eventKey,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      my_filters_selected: ['f1', 'f2']
    });
  }

  trackSuccessFailureEvents(eventName: string, selfhelpName: string) {
    this.track('link', {
      eventNames: eventName,
      selfHelpName: `cox:cb:${selfhelpName}`,
    });
  }

  trackModalTrigger(eventName: string) {
    this.track('link', {
      eventNames: 'modalTriggeredUser',
      modalName: `cox:cb:modal:${eventName}`, //Remove before $ for CBMA7-3194
      businessUnit: 'cb',
      windowViewType: 'modal'
    });
  }

  public trackToggleEvent(eventName: string, selfHelpname: string) {
    this.track('link', {
      eventNames: eventName,
      selfHelpName: selfHelpname
    });
  }

  public trackOutageStatus(profileGuid: string, accountGuid: string, networkStatus: string) {
    this.track('link', {
      userGuid: this.userGuid,
      profileGuid,
      accountGuid,
      networkStatus
    });
  }

  public setUDOStatus(data: Data) {
    window.utag_data[data.keyName] = data.keyValue;
    return;
  }
}

interface Data {
  keyName: string;
  keyValue: string;
}
