import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  // tslint:disable-next-line: cognitive-complexity
  transform(tel: string, args?: string): string {
    if (tel) {
      const value = tel.toString().trim().replace(/^\+1(-)?/, '');
      if (value.match(/[^0-9]/)) {
        return tel;
      }

      if (args?.length && args === 'phone') {
        const phoneNumber = value.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (phoneNumber) {
          return `(${phoneNumber[1]}) ${phoneNumber[2]}-${phoneNumber[3]}`;
        }
      }

      // tslint:disable-next-line:one-variable-per-declaration
      let country, city, number;

      switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;

        case 11: // +CPPP####### -> CCC (PP) ###-####
          country = value[0];
          city = value.slice(1, 4);
          number = value.slice(4);
          break;

        case 12: // +CCCPP####### -> CCC (PP) ###-####
          country = value.slice(0, 3);
          city = value.slice(3, 5);
          number = value.slice(5);
          break;
        case 13: // +CCCPP####### -> CCC (PP) ###-####
          country = 1;
          if (value) {
            const tmp = value.split('-')[1];
            if (tmp) {
              city = tmp.slice(0, 3);
              number = tmp.slice(3);
            } else {
              return tel;
            }
          } else {
            return tel;
          }
          break;

        default:
          return tel;
      }

      if (country === 1) {
        country = '';
      } else {
        country = country + '-';
      }

      if (number) {
        number = number.slice(0, 3) + '-' + number.slice(3);
      }

      return (country + city + '-' + number).trim();
    }
  }
}
