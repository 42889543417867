import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable()
export class NavigationService {

  private pageTitle: string;
  private backPath: string;
  private navigateToOrigin: boolean; 
  private url: string;
  private param: string;
  private ariaPageTitle:string;
  constructor(private router: Router) { }

  public setUrl(url) {
    this.url = url;
  }
  
  public getUrl() {
    return this.url;
  }

  public setParam(param) {
    this.param = param;
  }
  
  public getParam() {
    return this.param;
  }

  public getBackPath() {
    return this.backPath;
  }

  public setBackPath(backPath: string) {
    this.backPath = backPath;
  }

  public getOrigin() {
    return this.navigateToOrigin;
  }

  public setOrigin(isOrigin: boolean) {
    this.navigateToOrigin = isOrigin;
  }


  /**
    * Set the pageTitle in navigation component
    * @param pageTitle
    * @returns viod
    */
  public setAriaPageTitle(ariaPageTitle: string) {
    this.ariaPageTitle = ariaPageTitle;
  }

  public setPageTitle(pageTitle: string) {
    this.pageTitle = pageTitle;
  }
  /**
    * Set the pageTitle if it is empty in navigation component
    * returns true if the title is updated in navigation component
    * @param pageTitle
    * @returns boolean
    */
  public setPageTitleIfEmpty(pageTitle: string): boolean {
    if (this.pageTitle) {
      this.pageTitle = pageTitle;
      return true;
    } else {
      return false;
    }
  }

  /**
    * Get the pageTitle from navigation component
    * @returns string
    */
  public getPageTitle(): string {
    return this.pageTitle;
  }

  public getAriaPageTitle(): string{
    return this.ariaPageTitle;
  }

  /**
     * Navigate to path and set the page title in route data
     * @param path
     * @param pageTitle
     * @returns void
     * ```typescript
     * equivalent to
     * router.navigate([<Path>], { state: { pageTitle: <pageTitle> } });
     * or
     * <a routerLink="<Path>" [state]="{ pageTitle: <pageTitle> }">Link</a>
     * ```
     */
  public navigateWithTitle(path: string, pageTitle: string): void {
    this.router.navigate([path], { state: { pageTitle: pageTitle } });
  }

}


export const enum NavigationConstants {
    CUSTOM_TITLE = 'CUSTOM_TITLE',
    AUTOMATIC_RETRIEVE = 'Automatic Hold/Retrieve',
    CALL_DETAILS_RECORDS = 'Call Detail Records',
    CALL_PARK = 'Call Park',
    CALL_TRANSFER = 'Call Transfer',
    IP_CENTREX_RINGDOWN = 'IP Centrex Ringdown',
    SPEED_DIAL = 'Speed Dial',
    VMR_SETTINGS = 'VoiceManager Ringdown Settings',
    ALTERNATE_NUMBER = 'Alternate Numbers',
    AUTO_ATTENDANT = 'Auto Attendant',
    CALL_ACCEPTANCE_AND_REJECTION = 'Call Acceptance and Rejection',
    ROBOCALL_PROTECT_ADMIN = 'Robocall Protect Admin',
    CALLING_LINE_ID = 'Calling Line ID',
    CALLING_WAITING = 'Call Waiting',
    CUSTOM_RING_BACK = 'Custom Ringback',
    DO_NOT_DISTURB = 'Do Not Disturb',
    GROUP_NIGHT_SERVICE = 'Group Night Service',
    PRIORITY_ALERT = 'Priority Alert',
    PUSH_TO_TALK = 'Push To Talk',
    MANAGE_PHONENUMBERS = 'Manage Phone Numbers',
    RECEPTIONIST_CONSOLE = 'Download Receptionist Console',
    EULA = 'Security And Privacy Information',
    SYSLOG_CONFIG_MANAGEMENT = 'Syslog Configuration Management',
    DAILTONE_WORKSHEET = 'Dial Tone Worksheet',
    MOBILITY = 'Mobility',
    CALL_CENTER_QUEUE = 'Call Center Queues',
    DETECTION = 'Security Solutions - Detection',
    SURVEILLANCE = 'Security Solutions - Surveillance',
    SECURITYSOLUTION = 'Security Solutions',
    SECURITYSOLUTIONLEGACY = 'Security Solutions',
    VOICE_MAIL_GREETINGS = 'Voicemail Greetings',
    VOICE_MAIL_GUIDES = 'Voicemail Guides',
    VOICE_MAILBOX_PIN_CODE = 'Voicemailbox PIN Code',
    VOICE_MAIL_TOOLS = 'Voicemail Tools',
    VOICE_MAIL_PREFERENCES = 'Voicemail Preferences',
    VOICE_MAIL_NOTIFICATIONS = 'Voicemail Notifications',
    SECURITY_SUITE = 'Security Suite',
    DATA_TOOLS = 'Data Tools',
    INTERNET_GATEWAY_SETTINGS = 'Internet Gateway Settings',
    INTERNET_GATEWAY = 'Internet Gateway',
    MALBLOCK_IP_ADDRESS_MANAGEMENT = 'MalBlock IP Address Management',
    MALBLOCK_DNS_SECURITY = 'MalBlock DNS Security',
    COXHOSTING_CONTROL_PANEL = 'CoxHosting Control Panel',
    WIFIBROADCAST = 'Cox WIFI Broadcast Opt In/Opt Out',
    WIFIACCESS = 'Cox Wifi Access Administration',
    CUSTOMER_COPYRIGHT_INFRINGEMENT = 'Customer Copyright Infringement',
    ADD_ONS = 'Add-ons',
    EMAIL_ADMINISTRATION = 'Email Administration',
    G729_CONFIGURATION = 'G.729 Configuration Management',
    ASSOCIATE_NON_COX_TN = 'Associate Non-Cox Numbers',
    CONFIGURE_VLAN = "Configure VLAN Settings",
    IP_PHONE_CALLPATH = "IP Phone Call Path Settings",
    IP_PHONE_PASSWORD_RESET = "IP Phone Password Reset",
    PHONE_EQUIPMENT = "Phone Equipment",
    MANAGE_DEVICE = "Device Details",
    MANAGE_HANDSET="Manage Handsets",
    CALL_PICK_UP = "Call Pickup",
    BUSY_LAMP = "Busy Lamp",
    CALL_PICKUP_BARGEIN = "Directed Call Pickup with Barge-In",
    GROUP_PAGING = "Group Paging",
    HUNT_AND_SERIES = "Hunting and Series Completion",
    INSTANT_GROUP_CALL = "Instant Group Call",
    CONTACT_LIST = "Contact List",
    ASSOCIATE_NON_COX = "Associate Non-Cox Numbers",
    BARGEIN_EXEMPT = "Barge-In Exempt",
    MUSIC_ON_HOLD = "Music On Hold",
    WIRELESS_HEADSET = "Wireless Headset",
    TICKETSUMMARY = 'Ticket Summary',
    TICKETDETAILS = 'Ticket Details',
    EDITTICKETDETAILS = 'Edit Ticket Details',
    SERVICEORDERREPORT = 'Service Order Reporting',
    SERVICEORDERACTIVITY = 'Service Order Activity',
    SERVICEORDERDASHBOARD = 'Service Order Dashboard',
    EMAILDISTRIBUTIONLIST = 'Enterprise Portal Email Distribution List',
    VIEWSERVICEORDER = 'View Service Order',
    CALLFORWARDING = 'Call Forwarding',
    CALLNOTIFY = 'Call Notify',
    PERSONALSTATUSMANAGER = 'Personal Status Manager',
    SEQUENTIALRING = 'Sequential Ring',
    SIMULTANEOUSRING = 'Simultaneous Ring',
    ACCOUNTSANDAUTHORIZATION = 'Account & Authorization Codes',
    INCOMINGCALLINGPLAN = 'Incoming Calling Plan',
    OUTGOINGCALLINGPLAN = 'Outgoing Calling Plan',
    NOTIFICATIONS = 'My Messages',
    ENTERPRISE_GROUP = 'Group',
    TRUNK_MANAGEMENT = 'Trunk Management',
    EDIT_TRUNK_GROUP = 'Edit Trunk Group',
    EDIT_ASSIGNED_TN = 'Edit Assigned Telephone Numbers',
    TRUNK_GROUP_MANAGEMENT = 'Trunk Group Management',
    ADD_ENTERPRISE_TRUNK = 'Create Custom Enterprise Trunk',
    EDIT_ENTERPRISE_TRUNK = 'Edit Enterprise Trunk',
    ENTERPRISE_MANAGEMENT = 'Enterprise Management',
    ENTERPRISE_TRUNK = 'Enterprise Trunk',
    ENTERPRISE_TRUNK_DETAILS = 'Enterprise Trunk Details',
    TRUNK_GROUP_DETAILS = 'Trunk Group Details',
    GROUP_DETAILS = 'Group Details',
    CONFIGURE_EMAIL_DOMAIN = 'Configure Email Domain',
    SETUP_EMAIL_ACCOUNTS = 'Setup Email Accounts',
    EMAIL_SETUP_COMPLETE = 'Email Setup Complete',
    DOMAIN_SETTINGS = 'Domain Settings',
    CREATE_TICKET = 'Create a Ticket',
    VOICE_ENCRYPTION_REPORTING = 'Voice Encryption Reporting',
    //CBMA3-30546
    FEATURE_PACKAGES = 'Feature Packages',
    REMOTE_OFFICE = 'Remote Office',
    UNIFY_CALL_PULL = 'Call Pull',
    ACTIVATE_UNIFY = 'Activate Unify',
    MONITORED_USERS = 'Monitored Users',
    FEATURE_ALLOCATION = 'Feature Alloacation',
    GROUP_DIRECTORY = 'Group Directory',
    GROUP_EXTENSION = 'Group Extension',
    HOTELING = 'Hoteling',
    PERSONAL_MOBILITY = 'Personal Mobility',
    PRIVACY = 'Privacy',
    SCHEDULES = 'Schedules',
    USER_EXTENSION = 'User Extensions',
    USER_FEATURE_SETTINGS = 'User Feature Settings',
    AUTOMATIC_CALL_BACK = 'Automatic Callback',
    ASSOCIATE_BROADWORKS = 'Associate Broadworks',
    AUDIO_CALL_RECORDING = 'Audio Call Recording',
    CALLER_ID_MANAGMENT = 'Caller ID Management',
    INTER_DIGIT_TIMEOUT = 'Interdigit Timeout',
    MANAGE_OUTSIDE_LINEACCESS = 'Manage Outside Line Access Code',
    FAX_TO_EMAIL = 'Fax To Email',
    FEATURE_ACCESSCODE = 'Feature AccessCode',
    MANAGEE911 = 'Manage E911',
    ALI_RECORD_HISTORY = 'AliRecordHistory',
    CURRENT_ALI_ADDRESS = 'CurrentAliAddress',
    ADDRESS_UPLOAD_DOWNLOAD = 'Address Upload Download',
    MANAGE_MY_E911_ADDRESS = 'Manage E911 Settings',
    DID_LICENSE_MANAGEMENT = 'DID License Management',
    OUTLOOK_INTEGRATION = 'Outlook Integration',
    VOICE_PORTAL = 'Voice Portal',
    FEATURE_PACKAGE_SERVICE = 'Feature Service Package',
    //CBMA3-30546
    GATEWAY_OVERVIEW = 'Gateway Overview',
    UsersAndAccounts = 'Users and Accounts',
    RECEPTIONIST_CONSOLE_Download = 'Receptionist Console Download',
}


export enum LocationDetailsStack {
  landing = '/home/landing',
  Malp = '/dashboard',
  CallcenterQueuesCCA = '/voice/advancedcallsettings/callcenterqueues?accordian=cca',
  CallcenterQueuesSCCS = 'voice/advancedcallsettings/callcenterqueues?accordian=sccs',
  AgentThresholdProfiles = '/voice/advancedcallsettings/agentthresholdprofiles',
  DefaultAgentThresholdProfile = '/voice/advancedcallsettings/defaultagentthresholdprofile',
  AddStandardCallcenter = '/voice/advancedcallsettings/addstandardcallcenter',
  EditStandardCallcenter = '/voice/advancedcallsettings/editstandardcallcenter',
  CallcenterQueues = '/voice/advancedcallsettings/callcenterqueues',
  StandardCallCenter = '/voice/advancedcallsettings/standardcallcenterdetails',
  VoiceSublanding = '/sublanding/voicetools',
  voiceWithRingCentral = '/sublanding/voicewithringcentral',
  InternetSublanding = '/sublanding/datatools',
  CallPark = '/voice/callsettings/callpark',
  AutoAttendant = '/voice/callsettings/autoattendant',
  CallAcceptanceAndRejection = '/voice/callsettings/callacceptanceandrejection',
  CustomRingBack = '/voice/callsettings/customringback',
  PriorityAlert = '/voice/callsettings/priorityalert',
  UFSCallcenterquesues = '/voice/userandsysmanagement/userfeaturesettings/edit?tab=uas',
  CreateTicketStepOne = '/ticket/ticketsummary/createticket/stepone',
  CreateTicketStepTwo = '/ticket/ticketsummary/createticket/steptwo',
  CreateTicketStepThree = '/ticket/ticketsummary/createticket/stepthree',
  CreateTicketStepFour = '/ticket/ticketsummary/createticket/stepfour',
  CreatesTicketStepFive = '/ticket/ticketsummary/createticket/stepfive',
  ServiceOrderDashBoard = '/ticket/serviceorder',
  VoiceMailTools = '/voice/voicemail/voice/voicemail',
  SequentialRingSublanding = '/voice/callsettings/sequentialring',
  Manage_Tabs = '/voice/userandsysmanagement/manageE911/managetabs',
  Manage_OtherAddress = '/voice/userandsysmanagement/manageE911/manageotheruseraddress',
  VoiceHome = '/voice/home',
  AppsSublanding = '/sublanding/downloadapps',
  MyAdmin = '/csradmin/start',
  UsersAndAccounts = '/admin/list',
  EmailAdministration = '/administration/emailadministration',
  BulkAccounts = '/csradmin/bulkaccounts',
  BulkAccountsMyAdmin = '/csradmin/start?addBulkAccsPage=true',
  EnterpriseTrunkManagement = '/voice/callsettings/trunkgroupmanagement',
  voiceCallsettings= '/voice/callsettings/callacceptanceandrejection',
  manageE911 = 'voice/userandsysmanagement/manageE911/manageE911notifications',
  voiceUserandSysmanagement = '/voice/userandsysmanagement/userfeaturesettings/edit/callacceptanceandrejection',
  Licence_Management = '/voice/userandsysmanagement/manageE911/national911licensemanagement',
}
