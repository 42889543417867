import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AEMClientService, ResourceBundle } from '../../services/aemclient.service';
import { TealiumUtagService } from 'common-ui-lib';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-timeout-modal',
  templateUrl: './timeoutmodal.component.html',
})
export class TimeOutComponent implements OnInit, OnDestroy {
  resourceBundle: ResourceBundle = {};
  public isModelOpen: boolean;
  private onDestroy$ = new Subject<boolean>();
  visibleFocusableElements = [];
  documentKeydownListener;

  constructor(
    public activeModal: NgbActiveModal,
    private tealium: TealiumUtagService,
    private aemClient: AEMClientService,
    private zone: NgZone,
  ) {
    this.aemClient.getSharedBundle('timeoutmodalResourceBundles')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
        });
  }

  ngOnInit() {

  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
  dialogOpenCall() {
    this.isModelOpen = true;
    this.addEventListner();
    const element = document.getElementsByClassName('row-fluid')[0];
    const focusableElements = this.find(element,
      `button:not([tabindex = '-1']):not([disabled]):not([style*='display:none']):not([hidden]),
  [href][clientHeight][clientWidth]:not([tabindex = '-1']):not([disabled]):not([style*='display:none']):not([hidden]),
  input:not([tabindex = '-1']):not([disabled]):not([style*='display:none']):not([hidden]),
   select:not([tabindex = '-1']):not([disabled]):not([style*='display:none']):not([hidden]),
  textarea:not([tabindex = '-1']):not([disabled]):not([style*='display:none']):not([hidden]),
   [tabIndex]:not([tabIndex = '-1']):not([disabled]):not([style*='display:none']):not([hidden]),
  [contenteditable]:not([tabIndex = '-1']):not([disabled]):not([style*='display:none']):not([hidden])`,
    );
    this.visibleFocusableElements = [];
    for (const focusableElement of focusableElements) {
      if (getComputedStyle(focusableElement).display !== 'none' &&
        getComputedStyle(focusableElement).visibility !== 'hidden') {
        this.visibleFocusableElements.push(focusableElement);
      }
    }
  }
  // tslint:disable-next-line: no-any
  find(element: any, selector: string): any[] {
    return Array.from(element.querySelectorAll(selector));
  }
  addEventListner() {
    this.zone.runOutsideAngular(() => {
      this.documentKeydownListener = this.onKeydown.bind(this);
      window.document.addEventListener('keydown', this.documentKeydownListener);
    });
  }
  removeEventListner() {
    this.isModelOpen = false;
    if (this.documentKeydownListener) {
      window.document.removeEventListener('keydown', this.documentKeydownListener);
      this.documentKeydownListener = null;
      document.getElementById('skip').focus();
    }
  }
  // tslint:disable-next-line: cognitive-complexity
  onKeydown(event: KeyboardEvent) {
    if (event.which === 9 && this.isModelOpen) {
      event.preventDefault();
      if (this.visibleFocusableElements && this.visibleFocusableElements.length > 0) {
        if (!document.activeElement) {
          this.visibleFocusableElements[0].focus();
        } else {
          const focusedIndex = this.visibleFocusableElements.indexOf(document.activeElement);

          if (event.shiftKey) {
            if (focusedIndex === -1 || focusedIndex === 0) {
              this.visibleFocusableElements[this.visibleFocusableElements.length - 1].focus();
            } else {
              this.visibleFocusableElements[focusedIndex - 1].focus();
            }
          } else {
            if (focusedIndex === -1 || focusedIndex === (this.visibleFocusableElements.length - 1)) {
              this.visibleFocusableElements[0].focus();
            } else {
              this.visibleFocusableElements[focusedIndex + 1].focus();
            }
          }
        }
      }
    }
  }

  public triggerOkTealium(eventName: string){
    this.tealium.button(eventName, eventName);
  }
}
