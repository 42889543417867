import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customfooter',
  templateUrl: './customfooter.component.html',
  styleUrls: ['./customfooter.component.scss'],
})
export class CustomFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
