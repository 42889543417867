import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { BaseAccount } from '../../models/accounts/baseaccounts.model';
import { AEMClientService } from '../../../services/aemclient.service';
import { Router, ActivatedRoute, UrlSegment, UrlSegmentGroup, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { SessionStorageService } from 'ngx-webstorage';
import {TealiumUtagService} from '../../../tealium/utag.service';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { LocalStorageKeys } from 'common-ui-lib';

@Component({
  selector: 'app-accountwidget7plusaccounts',
  templateUrl: './accountwidget7plusaccounts.component.html',
  styleUrls: ['./accountwidget7plusaccounts.component.scss']
})
export class Accountwidget7plusaccountsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() baseAccounts: BaseAccount[];
  @Input() accountDeselect: Boolean = true;
  @Output() selectedAccountChange = new EventEmitter<BaseAccount>();
  private _preselectedAcc: BaseAccount;
  public searchItem: string;
  @Input()
  public set selectedAccount(value: BaseAccount) {
    this._preselectedAcc = value;
    this.selectedLatestAccount = this.selectedAccount;
    if (this.selectedAccount) {
      this.selectedAccountToggle = true;
      this.displayAccountSelected(this.selectedAccount, false);
    }
  }
  public get selectedAccount(): BaseAccount {
    return this._preselectedAcc;
  }
  numberofaccounts: number;
  oddAccounts: BaseAccount[] = [];
  evenAccounts: BaseAccount[] = [];
  allAccounts: BaseAccount[] = [];
  accountid: any;
  accountnumber: any;
  accountname: any;
  accountalias: any;
  accountaddress: any;
  accountaddress1: any;
  accountaddress2: any;
  accountaddresspostal: any;
  oddAccountsFlag: boolean;
  evenAccountsFlag: boolean;
  allAccountsFlag: boolean = true;
  toggleAccordian: boolean = false;
  accountselected: boolean = false;
  showselect: boolean = false;
  filterType: string = 'an';
  inputEntered: any;
  results: BaseAccount[] = [];
  resultsDisplayed: number = 0;
  resultsLength: number = 0;
  noResults: boolean = true;
  pageLength: number = 7;
  resourceBundle: any = {};
  selectedLatestAccount: any;
  selectedAccountToggle = true;
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService,
    private activatedRoute: ActivatedRoute,
    private _storageService: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private tealium: TealiumUtagService) { }

  ngOnInit() {
    this.aemClient.getSharedBundle('accountWidget7PlusAccountsResourceBundle')
    .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => { this.resourceBundle = data; },
        error => { }
      );
    if (this.selectedAccount) {
      this.selectedLatestAccount = this.selectedAccount;
      this.displayAccountSelected(this.selectedAccount, false);
      this.selectedAccountToggle = false;
    }
    if (window.location.href.indexOf('/updatecontactinformation') > 0) {
      if (this._storageService.get(LocalStorageKeys.StepUsed, 'cbma') === 'true') {
        this.selectedLatestAccount = JSON.parse(this._storageService.get(LocalStorageKeys.LatestSelectedAccount, 'cbma'));
        if (this.selectedLatestAccount.accountId !== undefined) {
          this.displayAccountSelected(this.selectedLatestAccount);
        }
        this._storageService.remove(LocalStorageKeys.StepUsed);
      }
    } else if (window.location.href.indexOf('/malblockservice') > 0) {
      this.selectedLatestAccount = JSON.parse(this.sessionStorage.retrieve('selectedMyServicesAccount'));
      if (this.selectedLatestAccount.accountId !== undefined) {
          this.displayAccountSelected(this.selectedLatestAccount);
      }
      this.selectedAccountToggle = false;
    } else if (window.location.href.indexOf('/malblockipaddressmanagement') > 0) {
      this.selectedLatestAccount = JSON.parse(this.sessionStorage.retrieve('selectedAccountForMalblockService'));
      if (this.selectedLatestAccount.accountId !== undefined) {
        this.displayAccountSelected(this.selectedLatestAccount);
      }
    } else if (window.location.href.indexOf('/datatool') > 0) {
      this.selectedLatestAccount = JSON.parse(this.sessionStorage.retrieve('selectedMyServicesAccount'));
      if (this.selectedLatestAccount.accountId !== undefined) {
        this.displayAccountSelected(this.selectedLatestAccount);
      }
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.numberofaccounts = this.baseAccounts.length;
    if (this.accountDeselect === false) {
      this.showselect = false;
      this.accountselected = false;
      this.accountnumber = null;
      this.inputEntered = '';
      this.resetSearch();
    }
  }

  toggleAccordion() {
    this.toggleAccordian = !this.toggleAccordian;
    if (!this.toggleAccordian) {
      if (this.accountnumber != null) {
        this.showselect = true;
      }
    } else {
      this.showselect = false;
    }
  }

  // Function added for Account Widget Expand/Collapse - Accessibility
  public expandWidget() {
    let accWidget = document.getElementById('account-detailslist-gt7-accordian-title').getAttribute('aria-expanded');
    if (accWidget === 'true') {
      accWidget = 'false';
    } else {
      accWidget = 'true';
    }
    document.getElementById('account-detailslist-gt7-accordian-title').setAttribute('aria-expanded', accWidget);
  }
 // Function added for selecting Account Number - Accessibility
  public selectAccNo() {
    this.setAriaLabel('accNumber', 'Account Number Selected');
    this.setAriaLabel('accAddress', 'Address');
    this.setAriaLabel('accAlias', 'AccountAlias');
    this.setAriaLabel('accName', 'AccountName');
  }
  // tslint:disable-next-line: no-identical-functions // Function added for selecting Account Number - Accessibility
  public selectAddr() {
    this.setAriaLabel('accAddress', 'Address Selected');
    this.setAriaLabel('accNumber', 'AccountNumber');
    this.setAriaLabel('accAlias', 'Account Alias');
    this.setAriaLabel('accName', 'Account Name');
  }
  // tslint:disable-next-line: no-identical-functions // Function added for selecting Account Alias - Accessibility
  public selectAlias() {
    this.setAriaLabel('accAlias', 'Account Alias Selected');
    this.setAriaLabel('accAddress', 'Address');
    this.setAriaLabel('accNumber', 'Account Number');
    this.setAriaLabel('accName', 'Account Name');
  }
  // tslint:disable-next-line: no-identical-functions // Function added for selecting Account Name - Accessibility
  public selectName() {
    this.setAriaLabel('accName', 'Account Name Selected');
    this.setAriaLabel('accAddress', 'Address');
    this.setAriaLabel('accAlias', 'Account Alias');
    this.setAriaLabel('accNumber', 'Account Number');
  }

  private setAriaLabel(elementId: string, attrVal: string): void {
    document.getElementById(elementId).setAttribute('aria-label', attrVal);
  }
  displayFilterText(filterType: string) {
    this.inputEntered = '';
    this.resetSearch();
    this.pageLength = 7;
    this.noResults = true;
    this.filterType = filterType;
  }

  isSelected(filterType: string) {
    if (this.filterType == filterType) {
      return true;
    } else {
      return false;
    }
  }

  resetSearch() {
    this.results = [];
    this.oddAccounts = [];
    this.oddAccountsFlag = false;
    this.evenAccounts = [];
    this.evenAccountsFlag = false;
    this.allAccounts = [];
    this.allAccountsFlag = false;
    this.resultsDisplayed = 0;
    this.resultsLength = 0;
  }

  searchBaseAccountsList(input: string) {

    if (this.inputEntered.length < 3) {
      this.noResults = true;
      this.resetSearch();
      this.pageLength = 7;
    } else {
      this.handleSearchCriteria();
    }
  }

  handleSearchCriteria() {
    this.noResults = true;
    this.resetSearch();
    this.baseAccounts.forEach(element => {
      if (this.matches(element)) {
        this.results.push(element);
      }
    });

    this.sortResults();
    this.arrangeAccounts();

  }

  matches(element: BaseAccount) {
    switch (this.filterType) {
      // ACCOUNT NUMBER\
      case "an":
        return element.accountNumber.indexOf(this.inputEntered) >= 0;

      // ADDRESS (FORMAT:- ADDRESS1, ADDRESS2, CITY, STATE ZIP)
      case "ad":
        var address = element.address.address1;
        address += element.address.address2;
        address += element.address.city;
        address += element.address.state;
        address += element.address.zip;

        // REMOVE NON-ALPHANUMBERIC CHARACTERS FOR THE SEARCH VALUE & CRITERIA
        return this.stripNonAlphaNumericCharacters(address).toUpperCase().indexOf(this.stripNonAlphaNumericCharacters(this.inputEntered).toUpperCase()) >= 0;

      // ACCOUNT ALIAS
      case "aa":
        return element.alias.toUpperCase().indexOf(this.inputEntered.toUpperCase()) >= 0;

      // ACCOUNT NAME
      case "aname":
        return element.name.toUpperCase().indexOf(this.inputEntered.toUpperCase()) >= 0;

      default:
        break;
    }

  }

  stripNonAlphaNumericCharacters(arg: string) {
    var re = /[^a-zA-Z0-9]+/g;
    return arg.replace(re, '');
  }

  sortResults() {
    if (this.results.length == 0) {
      this.noResults = false;
    }

    switch (this.filterType) {
      // ACCOUNT NUMBER\
      case "an":
        return this.results.sort(this.sortAccNo);

      // ACCOUNT ALIAS
      case "aa":
        return this.results.sort(this.alphanumSortAccAlias.bind(this));

      // ADDRESS (FORMAT:- ADDRESS1, ADDRESS2, CITY, STATE ZIP)
      case "ad":
        return this.results.sort(this.alphanumSortAddress.bind(this));

      // ACCOUNT NAME
      case "aname":
        return this.results.sort(this.alphanumSortAccName.bind(this));

      default:
        break;
    }
  }

  sortAccNo(accObj1: BaseAccount, accObj2: BaseAccount) {
    return parseInt(accObj1.accountNumber) - parseInt(accObj2.accountNumber);
  }

  alphanumSortAddress(accObj1: BaseAccount, accObj2: BaseAccount) {
    var address1 = accObj1.address.address1;
    address1 += accObj1.address.address2;
    address1 += accObj1.address.city;
    address1 += accObj1.address.state;
    address1 += accObj1.address.zip;
    var address2 = accObj2.address.address1;
    address2 += accObj2.address.address2;
    address2 += accObj2.address.city;
    address2 += accObj2.address.state;
    address2 += accObj2.address.zip;

    var aa = this.chunkify(address1.toLowerCase());
    var bb = this.chunkify(address2.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        var c = Number(aa[x]), d = Number(bb[x]);
        if (c == aa[x] && d == bb[x]) {
          return c - d;
        } else return (aa[x] > bb[x]) ? 1 : -1;
      }
    }
    return aa.length - bb.length;
  }

  chunkify(t: any) {
    var tz = new Array();
    var x = 0, y = -1, n = 0, i, j;

    while (i = (j = t.charAt(x++)).charCodeAt(0)) {
      var m;
      if (i == 46 || (i >= 48 && i <= 57)) {
        m = 1;
      } else {
        m = 0;
      }
      if (m !== n) {
        tz[++y] = "";
        n = m;
      }
      tz[y] += j;
    }
    return tz;
  }

  alphanumSortAccName(accObj1: BaseAccount, accObj2: BaseAccount) {
    var aa = this.chunkify(accObj1.name.toLowerCase());
    var bb = this.chunkify(accObj2.name.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        var c = Number(aa[x]), d = Number(bb[x]);
        if (c == aa[x] && d == bb[x]) {
          return c - d;
        } else return (aa[x] > bb[x]) ? 1 : -1;
      }
    }
    return aa.length - bb.length;
  }

  alphanumSortAccAlias(accObj1: BaseAccount, accObj2: BaseAccount) {
    var aa = this.chunkify(accObj1.alias.toLowerCase());
    var bb = this.chunkify(accObj2.alias.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        var c = Number(aa[x]), d = Number(bb[x]);
        if (c == aa[x] && d == bb[x]) {
          return c - d;
        } else return (aa[x] > bb[x]) ? 1 : -1;
      }
    }
    return aa.length - bb.length;
  }

  arrangeAccounts() {
    let scope = this;
    this.resultsLength = this.results.length;
    this.results.forEach(function (eachresult, i) {
      if (i <= scope.pageLength) {
        if (i % 2) {
          scope.evenAccountsFlag = true;
          scope.evenAccounts.push(eachresult);
        } else {
          scope.oddAccountsFlag = true;
          scope.oddAccounts.push(eachresult);
        }
        scope.allAccountsFlag = true;
        scope.allAccounts.push(eachresult);
      } else {
        return false;
      }
      scope.resultsDisplayed++;
    });

  }

  seeMoreResults() {
    this.pageLength += 8;
    this.handleSearchCriteria();
  }

  searchAgain() {
    this.inputEntered = '';
    this.resetSearch();
    this.filterType = 'an';
    this.pageLength = 7;
    if (!(window.location.href.indexOf('/landing') > 0)) {
      window.scrollTo(0, 0);
    }
  }

  displayAccountSelected(baseAccount: BaseAccount, silent: boolean = false) {
    this.tealium.track('link', {
      eventName: 'AccountWidgetSelection',
      accountGuid: baseAccount.guid
      });
    if (this.selectedLatestAccount && this.selectedAccountToggle) {
      this.toggleAccordian = !this.toggleAccordian;
    }
    this.toggleAccordian = !this.toggleAccordian;
    if (!silent) {
      this.selectedAccountChange.emit(baseAccount);
    }
    this.accountselected = true;
    this.showselect = true;
    this.accountid = baseAccount.accountId;
    this.accountnumber = baseAccount.siteId + '-' + baseAccount.accountNumber;
    this.accountname = baseAccount.name;
    this.accountalias = baseAccount.alias;
    let address2 = '';
    if (baseAccount.address.address2 != '')
      address2 = baseAccount.address.address2 + ', ';
    this.accountaddress = baseAccount.address.address1 + ', ' +
      address2 + baseAccount.address.city + ', ' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
    this.accountaddress1 = baseAccount.address.address1;
    this.accountaddress2 = baseAccount.address.address2;
    this.accountaddresspostal = baseAccount.address.city + ',' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
    if (!(window.location.href.indexOf('/landing') > 0)) {
      window.scrollTo(0, 0);
    }
  }

  public enterSearch(event: KeyboardEvent) {
    if (event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    if ( this.filterType === 'an') {
     this.searchItem = 'Account Number';
    } else if ( this.filterType === 'ad') {
      this.searchItem = 'Address';
    } else if ( this.filterType === 'aa') {
      this.searchItem = 'Account Alias';
    }  else if ( this.filterType === 'aname') {
      this.searchItem = 'Account Name';
    }
    document.getElementById('searchCriteria').setAttribute('aria-label',
    'Please enter at least 3 characters to begin your ' + this.searchItem + ' search');
  }
  }

  showFilters() {
    this.toggleAccordion();
    this.searchAgain();
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}