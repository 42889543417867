
import { CdkPortal } from '@angular/cdk/portal';
import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

/** Used to flag tab labels for use with the portal directive */
// tslint:disable-next-line: max-classes-per-file
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[mat-tab-label], [matTabLabel]',
})
// tslint:disable-next-line: directive-class-suffix
export class MatTabLabel extends CdkPortal {
  // tslint:disable-next-line: no-any
  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}
