import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CBHelperService } from '../../services/cbhelper.service';
import { ComponentInstanceService } from '../../services/component-instance.service';
import { LoginProfileClient } from '../../services/loginprofileclient.service';
import { SplitIdentifierService } from '../../services/split-idententifier.service';
import { TealiumBuilderService } from '../../tealium/tealiumbuilder';
import { TealiumUtagService } from '../../tealium/utag.service';
import { VoiceLandingPageService } from '../voice-landing-page/voice-landing-page.service';
import { BreadCrumb } from './breadcrumb';
import { AppConfig } from 'common-ui-lib';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    @ViewChild('cancelConfirm') public cancelConfirm: NgbModalRef;
    breadcrumbs: BreadCrumb[] = [];
    voicebreadcrumbDisplay = false;
    datatoolsbreadcrumbDisplay = false;
    myadminhomeFlag = false;
    profileadminhomeflag = false;
    isAomsDisplay = true;
    aomsFlag: boolean;
    public deleteSingleAccount: string;
    isSplit: boolean;
    private onDestroy$ = new Subject<boolean>();
    public isHN: boolean;
    private voicemanagertoolsUrl = '/voice/cbvm/voicemanagertools';

    constructor(
        private activatedRoute: ActivatedRoute,
                private router: Router,
                private tealium: TealiumUtagService,
                private voiceLandingPageService: VoiceLandingPageService,
                private componentInstanceService: ComponentInstanceService,
                private modalService: NgbModal,
                private splitIdentifierService: SplitIdentifierService,
                private loginservice: LoginProfileClient,
                private config: AppConfig,
                public cbHelper: CBHelperService) {
        router.events.pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                if (data instanceof NavigationEnd) {
                    if (!this.activatedRoute.snapshot.data['isVoiceBreadCrumbNA']) {
                        this.voicebreadcrumbDisplay = this.activatedRoute.snapshot.data['bundle'] === 'Voice';
                    }
                    this.datatoolsbreadcrumbDisplay = false;
                    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
                }
            });
        this.isSplit = this.splitIdentifierService.getSplitStatus();
    }

    ngOnInit() {
        if(this.cbHelper?.isHNCustomer){
                this.isHN=true;
        }
        if (this.cbHelper.getLoggedInUserDetails() &&
        this.cbHelper.getLoggedInUserDetails().profile.userToken.aomsRole === 'NCOA_Admin' &&
        this.activatedRoute.snapshot.data['isAoms']) {
          this.isAomsDisplay =  this.activatedRoute.snapshot.data['bundle'] === 'aoms';
          this.aomsFlag = true;
       }
        const hostname = window.location.hostname;
        const arrayStr = hostname.split('.');
        if (arrayStr[0] === 'myadmin') {
            this.myadminhomeFlag = true;
        } else {
            this.myadminhomeFlag = false;
        }
        if (!this.activatedRoute.snapshot.data['isVoiceBreadCrumbNA']) {
            this.voicebreadcrumbDisplay = this.activatedRoute.snapshot.data['bundle'] === 'Voice';
        }
        this.profileadminhomeflag = this.activatedRoute.snapshot.data['bundle'] === 'profileadmin';
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
        // Build your breadcrumb starting with the root route of your current activated route
        if (this.activatedRoute.snapshot.queryParamMap) {
            this.deleteSingleAccount = this.activatedRoute.snapshot.queryParamMap.get('deleteSingleAccount');
        }
        if (this.deleteSingleAccount === 'true') {

            window.history.replaceState({}, document.title, '/' + `cbma/csradmin/start`);
        }
    }

    // tslint:disable-next-line: cognitive-complexity
    buildBreadCrumb(route: ActivatedRoute, breadcrumbs: BreadCrumb[] = [], url: string = ''): BreadCrumb[] {
        if (route.snapshot.data['bundle'] === 'datatools'
            && route.snapshot.firstChild) {
            this.datatoolsbreadcrumbDisplay = true;
            return this.buildBreadCrumb(route.firstChild);
        } else if (route.snapshot.data['bundle'] === 'csrprofileadmin'
            && route.snapshot.firstChild) {
            this.profileadminhomeflag = true;
            return this.buildBreadCrumb(route.firstChild);
        }

        // If no routeConfig is avalailable we are on the root path
        let breadcrumb = null;
        let path = route.snapshot.url.length ? route.snapshot.url.join('/') : '';
        if (!breadcrumbs.length && url) {
            path = path ? ([url, path]).join('/') : url;
        }
        if (route.snapshot.data['breadcrumb']) {
            const label = route.snapshot.data['breadcrumb'];
            if (path === 'voicetools') {
                path = this.voicemanagertoolsUrl;
            }
            const accRedirect = route.snapshot.data['accPageCall'];

            breadcrumb = {
                label,
                url: path,
                accType: null,
                splitUi: route.snapshot.data['splitUi'],
            };

            if (accRedirect) {
                breadcrumb.accType = accRedirect;
            }
            if (route.snapshot.data['isEditUser']) {
                breadcrumb.url = '';
                if (breadcrumbs.length > 0) {
                    const parentUrl = breadcrumbs[breadcrumbs.length - 1].url;
                    if (parentUrl && parentUrl.indexOf('/') === 0) {
                        breadcrumb.url = parentUrl + '/' + path;
                    } else {
                        breadcrumb.url = '/' + parentUrl + '/' + path;
                    }
                    if (route.snapshot.firstChild && route.snapshot.firstChild.data['tab']) {
                        breadcrumb.queryParams = { tab: route.snapshot.firstChild.data['tab'].toString() };
                    }
                } else {
                    breadcrumb.url = '/' + path;
                }
            }
        }
        if (route.snapshot.data['breadcrumb'] && ((breadcrumbs.length &&
            breadcrumb.label !== breadcrumbs[breadcrumbs.length - 1].label) || !breadcrumbs.length)) {
            breadcrumbs = [...breadcrumbs, breadcrumb];
        }
        if (route.snapshot.firstChild) {
            // If we are not on our current path yet,
            // there will be more children to look after, to build our breadcumb
            return this.buildBreadCrumb(route.firstChild, breadcrumbs, path);
        }
        return breadcrumbs;
    }

    navigateFromBreadCrumbs(breadcrumb: BreadCrumb) {
        const componentInstance = this.componentInstanceService.getComponentInstance();
        if (componentInstance && componentInstance.canDeactivate && componentInstance.canDeactivate()) {
            this.modalService.open(this.cancelConfirm).result.then((result) => {
                if (result) {
                    componentInstance.canDeactivate = () => {
                        return false;
                    };
                    this.processBreadcrumbRedirection(breadcrumb);
                }
            });
        } else {
            this.processBreadcrumbRedirection(breadcrumb);
        }
    }

    // tslint:disable-next-line: cognitive-complexity
    processBreadcrumbRedirection(breadcrumb: BreadCrumb) {
        if (breadcrumb.accType) {
            this.voiceLandingPageService.setSelectedCart(breadcrumb.accType);
            if (this.isSplit) {
                window.location.href = window.location.protocol + '//' + window.location.hostname
                    + (window.location.port ? ':' + window.location.port : '') +
                    '/voice/cbvm/home?tab=' + breadcrumb.accType;
            } else {
                this.router.navigate(['/voice/cbvm/home'], { queryParams: { tab: breadcrumb.accType } });
            }
            return;
        } else {
            if (breadcrumb.splitUi) {
                let qs = '';
                if (breadcrumb.queryParams) {
                    Object.entries(breadcrumb.queryParams).forEach(([key, value]) => {
                        qs += `${key}=${value}&`;
                    });
                    qs = qs.substr(0, qs.length - 1);
                }
                window.location.href = window.location.protocol + '//' + window.location.hostname
                    + (window.location.port ? ':' + window.location.port : '') + '/'
                    + breadcrumb.splitUi.module + breadcrumb.url + '?' + qs;
            } else {
                const finalRoute = this.getRouteUrl(this.router.url, breadcrumb.url);
                if (breadcrumb.queryParams) {
                    const obj = {
                        queryParams: breadcrumb.queryParams,
                    };
                    this.router.navigate([finalRoute], obj);
                } else {
                    this.router.navigate([finalRoute], { queryParams: {}});
                }
            }
        }
    }

    /**
     * Temporary work around fix for R57
     */
    getRouteUrl(routerUrl: string, url: string) {
      if ('' === routerUrl && routerUrl.indexOf('/edit') > 0 ) {
        routerUrl = '/voice/userandsysmanagement/userfeaturesettings';
      } else if ('' === routerUrl && routerUrl.indexOf('/voicemail') > 0 ) {
        routerUrl = '/voice/voicemail';
      } else if (routerUrl.indexOf('/voicetools') > 0 && 'voice/voicetools' === url) {
        // case to handle manage phonenumber breadcrumb only
        routerUrl = this.voicemanagertoolsUrl;
      } else if (routerUrl) {
        routerUrl =  routerUrl.substring(0, routerUrl.indexOf(url)) + url;
      }
      return routerUrl;
    }

    navigateToMALP() {
        if (this.cbHelper.isHNCustomer) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/home/landing'], { queryParams: { cust: 'HN' } });
              });
        } else {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/home/landing']);
              });
        }
    }
    public logout() {
        this.tealium.track('link', {
            event_name: 'Logout',
            my_key: 'Logout',
            my_filters_selected: ['f1', 'f2'],
        });
        this.loginservice.logout();
    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
