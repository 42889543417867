import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CBHelperService, LoginProfileClient, Role } from "common-ui-lib";

@Component({
  selector: "app-deploymentinprogress",
  templateUrl: "./deploymentinprogress.component.html",
  styleUrls: ["./deploymentinprogress.component.scss"],
})
export class DeploymentInProgressComponent implements OnInit {
  public isCbCore: boolean;
  constructor(
    private router: Router,
    public cbHelper: CBHelperService,
    private loginProfileClient: LoginProfileClient
  ) {
    console.log("OKTAFLOW-DeploymentInProgressComponent Cons");
  }

  ngOnInit() {
    console.log("OKTAFLOW-DeploymentInProgressComponent Init");
    const permissions = this.cbHelper.isUserAuthenticated() ? this.cbHelper.getLoggedInUserDetails().profile.roles : [];
    if (permissions.includes(Role.CB_CORE_ROLE)) {
      this.isCbCore = true;
    }
  }
}
