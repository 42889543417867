<div>
    <ng-container [ngTemplateOutlet]="backText ? defaultBack : pageTitle"></ng-container>
</div>

<ng-template #pageTitle>
    <ng-container *ngIf="navigationService.getPageTitle() !== null; else loadSpinTemplate">
        <div class="col-lg-12 col-sm-12 col-xs-12 p-0 display-flex" *ngIf="deviceService.isMobile()">
            <div class="col-lg-6 col-sm-12 col-xs-12 pl-0">
                <h1 class="bold custom-header">
                    <a [attr.aria-label]="navigationService.getPageTitle() !== null ? resourceBundle.pageLink + navigationService.getPageTitle() + resourceBundle.page: resourceBundle.backLink"
                      class="black" href="javascript:void(0)" (click)="navigateBack()" id="navFix">
                        <span class="ml-4 display-inline-block" *ngIf="navigationService.getPageTitle(); else backTextSkeleton">{{navigationService.getPageTitle()}}</span>
                        <ng-template #backTextSkeleton>
                            <div class="col-12 justify-content-center">
                                    <app-skeletonloader freeformSkeleton="true" count="1" sectionName="Back Text"
                                        widthSizeInPixels="200" heightSizeInPixels="30"></app-skeletonloader>
    
                            </div>
                        </ng-template>
                    </a>
                </h1>          
            </div>
            <div class="col-2 mt-n3 pr-0" *ngIf="moreLink">
                <app-more-link [selectedAccountDetails]="selectedAccountDetails"></app-more-link>
            </div>
        </div>
        <ng-container *ngIf="!deviceService.isMobile()">
            <div [ngClass]="{'headerText':showAccountWidget}">
                <h1 class="bold row-text-overflow custom-header">
                    <a *ngIf="navigationService.getPageTitle(); else backTextSkeleton"
                        [attr.aria-label]="navigationService.getPageTitle() !== null ? resourceBundle.pageLink + (navigationService.getAriaPageTitle()  ? navigationService.getAriaPageTitle() : navigationService.getPageTitle()) + resourceBundle.page: resourceBundle.backLink"
                        class="black" href="javascript:void(0)" (click)="navigateBack()" id="navFix">
                        <span [title]="navigationService.getPageTitle()" class="ml-4">{{navigationService.getPageTitle()}}</span>
                    </a>
                    <ng-template #backTextSkeleton>
                        <div class="col-12 justify-content-center">
                            <app-skeletonloader freeformSkeleton="true" count="1" sectionName="Back Text" widthSizeInPixels="270"
                                heightSizeInPixels="30"></app-skeletonloader>
                        </div>
                    </ng-template>
                </h1>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #defaultBack>
    <div class="col-lg-12 col-sm-12 col-xs-12 pl-1 pr-0 display-flex justify-content-spaceBtwn div-center-align">
        <div>
            <div class="custom-back-header">
                <a [attr.aria-label]="'Back'"
                  class="d-flex div-center-align" href="javascript:void(0)" (click)="navigateBack()">
                  <img src="assets/images/blueprint/arrow_prev_blue.svg" [height]="16" [width]="16" role="presentation"
                  alt="Arrow Back Icon"/>
                  <span class="display-inline-block ml-2">Back</span>
                </a>
            </div>          
        </div>
        <div *ngIf="moreLink">
            <app-more-link [selectedAccountDetails]="selectedAccountDetails" [headerWithTitle]="true"></app-more-link>
        </div>
    </div>
</ng-template>

<ng-template #loadSpinTemplate>
    <div class="col-lg-3 col-sm-1 col-xs-1">
        <app-skeletonloader formSkeleton="true" rowCount="1"></app-skeletonloader>
    </div>
</ng-template>

<!-- Leave site modal -->
<ng-template #leaveSiteModal let-c="close" let-d="dismiss" role="dialog">
    <comn-leavingcoxsitemodal [modaltemplate]="modaltemplate" [secondaryBtnRequired]=true [primaryBtnRequired]=true
        (onPrimaryClick)="onPrimaryClick($event, c)" (onSecondaryClick)="onSecondaryClick(c)">
    </comn-leavingcoxsitemodal>
</ng-template>