import {
  Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService, AppConfig, CBHelperService, CommonMessageService, EquipmentCategoryEnum,
  EquipmentUtilService, PhonePipe, ResourceBundle,
  StringUtilService,
  TealiumUtagService, UserType
} from 'common-ui-lib';
import { EquipmentListObj } from 'home/myequipment/myequipment.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Equipment, EquipmentViewMore, UpdateEquipmentPersonalNameInput, UpdateEquipmentWorkorderInput,
  UpdatePersonalizedDeviceNameInput
} from 'redesign/sub-landing/business-voice/business-voice.model';
import { RedesignRebootWidgetComponent } from '../redesign-reboot-widget/redesign-reboot-widget.component';
import { EquipmentCardDetailService } from './equipment-card-detail.service';
import { DeviceEncryption, DeviceEncryptionInput } from '../../models/userprofile/deviceEncryption.model';
import {
  DataUtilization, EncryptionPemissions, EquipmentDetailsObj, OperatingMode,
  PhoneEquipmentDetailsObj, UpdateEncryptionResponse
} from './equipment-card-detail.model';
import { EquipmentTypeEnum } from '../../equipment/equipmentcommon.model';
import { MalpService } from 'redesign/malp/malp.service';
import crypto from 'crypto-es';
import { BusinessVoiceService } from 'redesign/sub-landing/business-voice/business-voice.service';

@Component({
  selector: 'app-equipment-card-datail',
  templateUrl: './equipment-card-detail.component.html',
  styleUrls: ['./equipment-card-detail.component.scss']
})
export class EquipmentCardDetailComponent implements OnInit, OnChanges, OnDestroy {

  @Input() equipmentType: 'phone' | 'other' = 'phone';
  @Input() selectedPhoneEquipment: Equipment;
  @Input() selectedOtherEquipment: EquipmentListObj;
  @Input() accountGuid: string;
  @Input() phoneEquipAdditionalDetails: EquipmentViewMore;
  @Input() accountNumber12: string;
  @Input() itemNumberArray: any;
  @Input() voiceFlag: boolean;
  @Input() smartHelpFlag: boolean;
  @Output() updatedEquipment = new EventEmitter<any>();
  @Output() cardDetailClose = new EventEmitter<boolean>();

  public resourceBundle: ResourceBundle = {};
  public equipmentName = '';
  public baseName: string;
  public encryptedValue: string;
  public showInput = false;
  public isInputChange = false;
  public isspace = false;
  public t38Checked = false;
  public phoneDeviceEncrypted: boolean;
  public updatedName: UpdateEquipmentPersonalNameInput;
  public updatedPhoneName: UpdatePersonalizedDeviceNameInput;
  public updateT38: UpdateEquipmentWorkorderInput;
  public contentLoaded = false;
  public channelInitiated: string;
  public date: string;
  public time: string;
  public isMobileDevice = false;
  public deviceEncryptionInputList: DeviceEncryption[] = [];
  public rebootHistoryRows: any[] = [];
  public packageTypeList: string[];
  public OperatingMode = OperatingMode ;
  public encryptionError: {
    showError: boolean;
    errorMessage: string;
  };
  public equipmentDetailsObj: EquipmentDetailsObj;
  public phoneEquipmentDetailsObj: PhoneEquipmentDetailsObj;
  public showManageGateway = false;
  public userType: string;
  public isRefreshing = false;
  public noStatusDevices = ['ATA', 'ESBC', 'POE SWITCH'];
  public equipmentCategoryEnum = EquipmentCategoryEnum;
  public encryptionDisallowed =  EncryptionPemissions.DISALLOWED;
  public isInFootprint = false;
  public dataUtilization: DataUtilization;
  public showDataUtilization = false;
  public sbgDevices: boolean;
  public is4332device: boolean;
  public isDesktopDevice = false;
  public validVoiceDeviceTypes = ['emta', 'ata', 'emtapc20'];
  public isValidDeviceType = false;
  public isEmtaDeviceType = false;
  private onDestroy$ = new Subject<boolean>();
  private phoneEquipmentDeviceDetails: any = {};
  private phone: PhonePipe;
  constructor(
    public aemClient: AEMClientService,
    private tealium: TealiumUtagService,
    private sessionStorage: SessionStorageService,
    private router: Router,
    public cBHelperService: CBHelperService,
    public malpService: MalpService,
    public equipmentCardDetailService: EquipmentCardDetailService,
    public commonMessageService: CommonMessageService,
    public deviceService: DeviceDetectorService,
    private modalService: NgbModal,
    private config: AppConfig,
    public stringUtilService: StringUtilService,
    public equipmentUtilService: EquipmentUtilService,
    public voiceService: BusinessVoiceService
  ) {
    this.phone = new PhonePipe();
    }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateEquipmentName();
    if(this.equipmentType === 'phone') {
      this.t38Checked = (this.selectedPhoneEquipment?.deviceDetails?.t38Flag === 'enabled');
    }
    if (this.equipmentType === 'other' && changes.selectedOtherEquipment?.currentValue) {
      this.createEquipmentObj();
    }

    if (this.equipmentType === 'phone' && changes.selectedPhoneEquipment?.currentValue) {
      this.createPhoneEquipmentObj();
      this.isValidDeviceType = this.voiceService.isValidVoiceDeviceType(this.selectedPhoneEquipment?.deviceDetails?.voiceDeviceType, false);
      this.isEmtaDeviceType = this.voiceService.isValidVoiceDeviceType(this.selectedPhoneEquipment?.deviceDetails?.voiceDeviceType, true);
    }
  }

  ngOnInit() {
    document.getElementById('cardDetailClose').focus();
    const dataUtilizationFlag = JSON.parse(this.config.getConfig('featuredFlag')['dataUtilizationFlag']);
    this.showDataUtilization = dataUtilizationFlag &&
      this.equipmentDetailsObj?.equipmentType === EquipmentTypeEnum.LTE_ROUTER;
    this.userType = this.cBHelperService.getLoggedInUserDetails().profile.userToken.userType;
    if (this.userType === UserType.PROFILE_OWNER ||
      this.userType === UserType.PROFILE_ADMIN || this.userType === UserType.CSR || (!this.malpService.isEUWithoutPermissions && this.is4332device)) {
      this.showManageGateway = true;
    }
    this.resetEncryptionErrorMsg();
    if (this.deviceService.isDesktop()) {
      this.isDesktopDevice = true;
    } else if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }

      this.aemClient.getRedesignBundle('malp/equipmentCardDetail')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (data: any) => {
            this.resourceBundle = data;
            if (this.showDataUtilization) {
              this.getCradlePointDataUtilizationDetails();
            } else {
              this.contentLoaded = true;
            }
          }
        );

  }

  public createEquipmentObj() {
    const selectedEquipmentItemNumber = this.selectedOtherEquipment?.itemNumber;
    this.sbgDevices = this.config.getConfig('sbwgItemNumber')?.includes(selectedEquipmentItemNumber) ? true : false;
    this.is4332device = selectedEquipmentItemNumber?.includes(this.config.getConfig('sbwgItemNumber')[0]) ? true : false;
    if (this.is4332device) {
      this.showManageGateway = !this.malpService.isEUWithoutPermissions ? true : false;
    } else {
      this.showManageGateway = (this.userType === UserType.PROFILE_OWNER ||
        this.userType === UserType.PROFILE_ADMIN || this.userType === UserType.CSR) ? true : false;
    }
    this.equipmentDetailsObj = {
      status: this.selectedOtherEquipment?.equipmentStatus,
      operatingMode: this.selectedOtherEquipment?.operatingMode,
      macAddress: this.selectedOtherEquipment?.macAddress?.toUpperCase(),
      wanMac: this.selectedOtherEquipment?.wanMac?.toUpperCase(),
      ipv4Address: this.selectedOtherEquipment?.ipv4,
      ipv6Address: this.selectedOtherEquipment?.ipv6,
      connectedSince: this.selectedOtherEquipment?.upTime,
      installedDate: this.selectedOtherEquipment?.installedDate,
      serialNumber: this.selectedOtherEquipment?.serialNumber,
      vendor: this.selectedOtherEquipment?.itemModelMake,
      type: (this.selectedOtherEquipment?.itemModelMake ? this.selectedOtherEquipment?.itemModelMake + ' ' : '')
        + (this.selectedOtherEquipment?.itemModelNumber ? this.selectedOtherEquipment?.itemModelNumber + ' ' : '')
        + (this.selectedOtherEquipment?.equipmentType && !this.sbgDevices ? this.selectedOtherEquipment?.equipmentType : ''),
      equipmentType: this.selectedOtherEquipment?.equipmentType,
      backupWANStatus: this.selectedOtherEquipment?.backupWANStatus,
      primaryWANStatus: this.selectedOtherEquipment?.primaryWANStatus,
      batteryType: this.selectedOtherEquipment?.batteryType   /*CBMA1-60907, Net Assurance: Display Battery icon*/
    };
  }

  public createPhoneEquipmentObj() {
    this.phoneEquipmentDetailsObj = {
      status: this.selectedPhoneEquipment?.deviceDetails?.equipmentStatus,
      macAddress: this.selectedPhoneEquipment?.deviceDetails?.macAddress?.toUpperCase(),
      serialNumber: this.selectedPhoneEquipment?.deviceDetails?.serialNumber,
      equipmentType: this.selectedPhoneEquipment?.deviceDetails?.voiceDeviceType
    };
  }

  public onClickOfEdit() {
    this.showInput = true;
  }

  public onClickOfCancel() {
    this.showInput = false;
    this.updateEquipmentName();
  }

  public onChangeOfT38(event: boolean) {
    this.updateT38 = {
      accountNumber12: this.selectedPhoneEquipment?.accountNumber12,
      serviceOccurrenceSet: [],
      isT38On: event,
      serialNumber: this.selectedPhoneEquipment?.deviceDetails?.serialNumber
    };
    try {
      this.equipmentCardDetailService.updateT38Flag(this.updateT38).subscribe((results) => {
        if (!results.errors) {
          this.t38Checked = event;
          this.selectedPhoneEquipment.deviceDetails.t38Flag = (event)?'enabled':'disabled';
          this.updatedEquipment.emit(this.selectedPhoneEquipment);
        } else {
          this.t38Checked = !this.t38Checked;
        }
      });
    } catch {
      this.t38Checked = !this.t38Checked;
    }
  }

  public onChange() {
    this.isInputChange = true;
    const str = this.equipmentName.trim();
    if (!str || 0 === str.length) {
      this.isspace = true;
    } else {
      this.isspace = false;
    }
  }

  public onSave() {
    if (this.equipmentType === 'phone') {
      this.updatedPhoneName = {
        deviceName: this.selectedPhoneEquipment?.deviceDetails?.deviceName,
        displayName: this.equipmentName
      };
      this.equipmentCardDetailService.updatePhoneName(this.updatedPhoneName).subscribe((results) => {
        if (!results.errors) {
          this.selectedPhoneEquipment = {
            ...this.selectedPhoneEquipment,
            deviceDetails: {
              ...this.selectedPhoneEquipment.deviceDetails,
              personalizedDeviceName: this.equipmentName,
              novelPersonalizedDeviceName: this.equipmentName
            }
          };
          this.updateEquipmentName();
          this.updatedEquipment.emit(this.selectedPhoneEquipment);
        }
      });
    } else {
      this.updatedName = {
        personalName: this.equipmentName,
        encryptedValue: this.selectedOtherEquipment?.encryptedValue,
      };
      this.equipmentCardDetailService.updateName(this.updatedName).subscribe((results) => {
        if (!results.errors) {
          this.selectedOtherEquipment.equipmentName = this.equipmentName;
          this.updatedEquipment.emit(this.selectedOtherEquipment);
        }
      });
    }
    this.showInput = false;
  }

  public navigateToDevicedDetail() {
    this.phoneEquipmentDeviceDetails.userType = this.cBHelperService.isMyAdmin() ? 'CSR' : 'EU';
    this.phoneEquipmentDeviceDetails.kem = this.selectedPhoneEquipment?.kemDetails?.kemProvisioned;
    this.tealium.link('Navigate-Detail');
    this.phoneEquipmentDeviceDetails.deviceManufacture = this.selectedPhoneEquipment?.deviceDetails?.deviceManufacturer;
    this.phoneEquipmentDeviceDetails.deviceType = this.selectedPhoneEquipment?.deviceDetails?.deviceType;
    this.phoneEquipmentDeviceDetails.deviceName = this.selectedPhoneEquipment?.deviceDetails?.deviceName;
    this.phoneEquipmentDeviceDetails.kemCount = this.selectedPhoneEquipment?.kemDetails?.kemCount;
    this.phoneEquipmentDeviceDetails.kemType = this.selectedPhoneEquipment?.kemDetails?.kemType;
    this.phoneEquipmentDeviceDetails.kemProvisioned = this.selectedPhoneEquipment?.kemDetails?.kemProvisioned;
    this.phoneEquipmentDeviceDetails.baseName = this.baseName;
    this.phoneEquipmentDeviceDetails.deviceRowId = 1;
    this.phoneEquipmentDeviceDetails.model = `${this.selectedPhoneEquipment?.deviceDetails?.description} (${this.selectedPhoneEquipment?.deviceDetails?.purchaseStatus})`;
    this.phoneEquipmentDeviceDetails.primaryPhoneNumber = this.selectedPhoneEquipment?.deviceDetails?.phoneNumber;
    this.sessionStorage.store('deviceDetailsArray', this.phoneEquipmentDeviceDetails);
    if(this.selectedPhoneEquipment?.deviceDetails?.isMultiPortDevice) {
      this.router.navigate(['/sublanding/phoneequipment']);
    } else {
      this.router.navigate(['/voice/phoneequipment/phoneequipment/devicedetails']);
    }
  }

  public onClickOfCloseButton() {
    this.cardDetailClose.emit(true);
    this.commonMessageService.displayMessage.show = null;
  }

  public openRebootWidget() {
    const modalRef = this.modalService.open(RedesignRebootWidgetComponent,
      { windowClass: 'sch-modal-lg modal-phone-width', backdrop: 'static' });
    modalRef.componentInstance.equipmentType = this.equipmentType;
    modalRef.componentInstance.selectedPhoneEquipment = this.selectedPhoneEquipment;
    modalRef.componentInstance.selectedOtherEquipment = this.selectedOtherEquipment;
    modalRef.componentInstance.checkEmtaDeviceType = this.isEmtaDeviceType;
  }

  public navigateToGateway(isReboot: boolean) {
        const selectedDeviceDetails=
      {
        upstreamMac : this.selectedOtherEquipment?.upstreamMac,
        operatingMode: this.selectedOtherEquipment?.operatingMode,
        equipmentName: this.equipmentName,
        macAddress: this.selectedOtherEquipment?.macAddress,
        wanMac: this.selectedOtherEquipment?.wanMac,
        reboot: isReboot,
        vendor: this.equipmentDetailsObj.vendor,
        type: this.equipmentDetailsObj.type,
        wifiTabStatus: this.selectedOtherEquipment?.hideWifi,
        accountNumber12: this.accountNumber12
      };
    const encryptedSelectedDeviceDetails = encodeURIComponent(crypto.AES.encrypt(JSON.stringify(selectedDeviceDetails),
     'deviceData').toString());
     this.sessionStorage.store('sbgDeviceSerialNumber', this.selectedOtherEquipment?.serialNumber);
     this.router.navigate(['/home/gatewayoverview'], {queryParams: {key: this.selectedOtherEquipment?.encryptedValue,
      data: encryptedSelectedDeviceDetails}});
  }

  public toggleEncryption(event: boolean) {
    this.resetEncryptionErrorMsg();
    this.deviceEncryptionInputList.length = 0;
    const updateReq = this.setEncryptionReqSingle(event);
    this.deviceEncryptionInputList.push(updateReq);
    const encryptioninput: DeviceEncryptionInput = {
      deviceEncryptionList: this.deviceEncryptionInputList
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.equipmentUtilService.updateDeviceEncryption(encryptioninput).subscribe((results: any) => {
      if (results.data && results.data.updatePhoneEquipmentEncryption
        && results.data.updatePhoneEquipmentEncryption.updateEncryptionResponse) {
        //considering index 0, since we will always have one status always in this usecase
        if ((['8121', '8122'].includes(results.data.updatePhoneEquipmentEncryption.updateEncryptionResponse[0].code))) {
          this.encryptionError.showError = true;
          this.phoneDeviceEncrypted = !event;
          this.encryptionError.errorMessage =
          results.data.updatePhoneEquipmentEncryption.updateEncryptionResponse[0].message;
        } else if (results.data.updatePhoneEquipmentEncryption.updateEncryptionResponse[0].code === '0') {
          this.updateDeviceEncryptedValue(results.data.updatePhoneEquipmentEncryption.updateEncryptionResponse[0]);
          this.commonMessageService.showMessage(this.resourceBundle.encryptionSuccessMsg,
            'success', 'businessVoiceOverlay');
        } else {
          window.scrollTo(0, 0);
          this.phoneDeviceEncrypted = !event;
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'voicetools');
        }
      }
      if (results.errors) {
        const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'updatePhoneEquipmentEncryption');
        if (errorInfo) {
          window.scrollTo(0, 0);
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'voicetools');
        }
      }
    });
  }

  public getEquipmentStatus() {
    this.isRefreshing = true;
    this.equipmentUtilService.getConnectionStatusByKey(this.selectedOtherEquipment.encryptedValue)
    .subscribe((statusObj) => {
      if(statusObj.code === '0') {
        this.selectedOtherEquipment.equipmentStatus = statusObj.deviceStatus;
        this.equipmentDetailsObj.status = statusObj.deviceStatus;
      }
      this.isRefreshing = false;
      });
  }

  public getPhoneEquipmentStatus() {
    this.isRefreshing = true;
    if(this.selectedPhoneEquipment.deviceDetails.encryptedValue && this.voiceService.isValidVoiceDeviceType(this.selectedPhoneEquipment?.deviceDetails?.voiceDeviceType, true)) {
    this.equipmentUtilService.getConnectionStatusByKey(this.selectedPhoneEquipment.deviceDetails.encryptedValue)
    .subscribe((statusObj) => {
      if(statusObj.code === '0') {
        this.selectedPhoneEquipment.deviceDetails.equipmentStatus = statusObj.deviceStatus;
        this.phoneEquipmentDetailsObj.status = statusObj.deviceStatus;
      }
      this.isRefreshing = false;
      });
    }
  }

  public isValidVoiceDeviceType() {
    return this.validVoiceDeviceTypes.includes(this.selectedPhoneEquipment?.deviceDetails?.voiceDeviceType);
  }

  public cardDetailFocus(event: KeyboardEvent) {
    const firstFocusableEl = document.getElementById('cardDetailClose');
    const lastFocusableEl = document.getElementById('rebootHistory') || document.getElementById('messageBtn');
    if (event.key === 'Tab' && (document.activeElement === lastFocusableEl)) {
      firstFocusableEl.focus();
      event.preventDefault();
    } else if ((event.shiftKey && event.key === 'Tab') && document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      event.preventDefault();
    }
  }

  public getCradlePointDataUtilizationDetails() {
    this.equipmentCardDetailService.getCradlePointDataUtilizationDetails(this.accountNumber12)
      .subscribe((results: any) => {
        if (!results?.errors) {
          this.dataUtilization = {
            backupUsage: results?.data?.dataUtilization?.cycleDataUsages?.backupUsage,
            asOfDate: results?.data?.dataUtilization?.cycleDataUsages?.asOfDate,
            billCycleNumber: results?.data?.dataUtilization?.cycleDataUsages?.billCycleNumber,
            billPeriodCharges: results?.data?.dataUtilization?.cycleDataUsages?.billPeriodCharges,
            cycleEndDate: results?.data?.dataUtilization?.cycleDataUsages?.cycleEndDate,
            cycleStartDate: results?.data?.dataUtilization?.cycleDataUsages?.cycleStartDate,
            overageRate: results?.data?.dataUtilization?.cycleDataUsages?.overageRate,
            remainingData: results?.data?.dataUtilization?.cycleDataUsages?.remainingData
          };
          this.isInFootprint = (this.dataUtilization?.billPeriodCharges &&
            this.dataUtilization?.remainingData) ? true : false;

          if (this.dataUtilization.asOfDate) {
            this.resourceBundle.backupUsageDetailWithDate = this.stringUtilService.formatByPlaceHolder(
              this.resourceBundle.backupUsageDetailWithDate,
              [this.dataUtilization.backupUsage, this.dataUtilization.asOfDate]);
          }
          this.resourceBundle.resets = this.stringUtilService.formatByPlaceHolder(this.resourceBundle.resets,
            [this.dataUtilization?.cycleEndDate?.slice(0, 3) + ' ' +
              this.dataUtilization?.cycleEndDate?.slice(-2)]);
          if (!this.isInFootprint) {
            this.resourceBundle.outFootPrintDetail_1 =
              this.stringUtilService.formatByPlaceHolder(this.resourceBundle.outFootPrintDetail_1,
                [this.dataUtilization.overageRate,
                this.dataUtilization.remainingData]);
            this.resourceBundle.outFootPrintDetail_2 =
              this.stringUtilService.formatByPlaceHolder(this.resourceBundle.outFootPrintDetail_2,
                [this.dataUtilization.billPeriodCharges,
                this.dataUtilization.cycleStartDate,
                this.dataUtilization.cycleEndDate]);
          }
          this.contentLoaded = true;
        } else {
          const errorInfo = this.commonMessageService.getErrorInfo(results?.errors, 'equipmentCardDetail');
          if (errorInfo) {
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'equipmentCardDetail');
          }
          this.contentLoaded = true;
        }
      });
  }

  ngOnDestroy() {
    this.equipmentCardDetailService.contentLoaded = false;
  }

  private updateEquipmentName() {
    this.resetEncryptionErrorMsg();
    if (this.equipmentType === 'phone') {
      this.equipmentName = (this.selectedPhoneEquipment?.deviceDetails?.personalizedDeviceName) ?
        this.selectedPhoneEquipment?.deviceDetails?.personalizedDeviceName
        : this.selectedPhoneEquipment?.deviceDetails?.deviceType;
      this.baseName = (this.selectedPhoneEquipment?.deviceDetails?.novelPersonalizedDeviceName) ?
        this.selectedPhoneEquipment?.deviceDetails?.novelPersonalizedDeviceName
        : this.selectedPhoneEquipment?.deviceDetails?.deviceType;
      this.phoneDeviceEncrypted = (this.selectedPhoneEquipment?.deviceDetails?.encrypted) ?
      this.selectedPhoneEquipment?.deviceDetails?.encrypted : false;
      this.packageTypeList = this.selectedPhoneEquipment?.userDetails?.seatType?.split(',');
    } else {
      this.equipmentName = this.selectedOtherEquipment?.equipmentName;
      this.encryptedValue = this.selectedOtherEquipment?.encryptedValue;
      this.packageTypeList = this.selectedOtherEquipment?.userDetails?.seatType?.split(',');
      this.phoneDeviceEncrypted = (this.selectedOtherEquipment?.deviceDetails?.encrypted) ?
      this.selectedOtherEquipment?.deviceDetails?.encrypted : false;
    }
  }
  private updateDeviceEncryptedValue(event: UpdateEncryptionResponse) {
    if(this.selectedPhoneEquipment){
      this.selectedPhoneEquipment = {
        ...this.selectedPhoneEquipment,
        deviceDetails: {
          ...this.selectedPhoneEquipment.deviceDetails,
          encrypted: event.encrypted,
          encryptionPermission: event.encryptionPermission,
        }
      };
      this.phoneDeviceEncrypted = event.encrypted;
      this.updatedEquipment.emit(this.selectedPhoneEquipment);
    } else {
      this.selectedOtherEquipment = {
        ...this.selectedOtherEquipment,
        deviceDetails: {
          ...this.selectedOtherEquipment.deviceDetails,
          encrypted: event.encrypted,
          encryptionPermission: event.encryptionPermission,
        }
      };
      this.phoneDeviceEncrypted = event.encrypted;
      this.updatedEquipment.emit(this.selectedOtherEquipment);
    }
  }

  private resetEncryptionErrorMsg() {
    this.encryptionError = {
      showError: false,
      errorMessage: ''
    };
  }

  private setEncryptionReqSingle(event): DeviceEncryption{
    let deviceObj: DeviceEncryption ;
    if(this.selectedPhoneEquipment){
      deviceObj =  {
        bwDeviceName: this.selectedPhoneEquipment?.deviceDetails?.deviceName,
        encrypted: event,
        accountNumber:
        `${this.selectedPhoneEquipment?.accountNumber12.slice(0, 3)}-
        ${this.selectedPhoneEquipment?.accountNumber12.slice(3)}`,
        handsetSerialNumber: this.selectedPhoneEquipment?.deviceDetails?.serialNumber,
        phoneNumber: this.phone.transform(this.selectedPhoneEquipment?.deviceDetails?.phoneNumber),
        deviceType: this.selectedPhoneEquipment?.deviceDetails?.deviceType
      };
    } else {
      deviceObj =  {
        bwDeviceName: this.selectedOtherEquipment?.deviceDetails?.deviceName,
        encrypted: event,
        accountNumber:
        `${this.selectedOtherEquipment?.accountNumber12.slice(0, 3)}-
        ${this.selectedOtherEquipment?.accountNumber12.slice(3)}`,
        handsetSerialNumber: this.selectedOtherEquipment.deviceDetails?.serialNumber,
        phoneNumber: this.phone.transform(this.selectedOtherEquipment.deviceDetails?.phoneNumber),
        deviceType: this.selectedOtherEquipment.deviceDetails?.deviceType
      };
    }
    return deviceObj;
  }
}
