
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CBHelperService, ResourceBundle, TealiumUtagService } from 'common-ui-lib';
import { asapScheduler } from 'rxjs';
import { StepNum, ContactMethod, ContactMethodType, GenerateOtpRequest,
SelectedContactMethodType } from '../reset-password.model';
import { ResetPasswordService } from '../reset-password.service';

@Component({
  selector: 'app-choose-verify-method',
  templateUrl: './choose-verify-method.component.html',
  styleUrls: ['./choose-verify-method.component.scss']
})
export class ChooseVerifyMethodComponent implements OnInit {

  @Input() public resourceBundle: ResourceBundle = {};
  @Input() public userGuid: string;
  @Output() public closeClick = new EventEmitter();
  public contactMethod = ContactMethod;
  public contactMethodType = ContactMethodType;
  public contactValue: string;
  public otpPhoneShow: boolean;
  public otpVoiceShow: boolean;
  public otpSmsShow: boolean;
  public otpEmailShow: boolean;
  public isMobile: boolean;
  public userType: string;
  public stepNum = StepNum;
  public isMyadmin: boolean;
  public otpPhoneValue: string;
  public otpEmailValue: string;
  public getPaCount: number;
  public hideUseIdScan: boolean;
  public contactUs: string;
  public showEditProfile= false;
  private code: string;


  constructor(public resetPasswordService: ResetPasswordService,
              public cbHelperService: CBHelperService,
              public activeModal: NgbActiveModal,
              private tealium: TealiumUtagService,
              public router: Router) { }

  ngOnInit() {
    this.isMyadmin= this.cbHelperService.isMyAdmin();
    this.contactUs = `<a *ngIf="!${this.isMyadmin}" role="link" tabindex="0" attr.aria-label="${this.resourceBundle.contactUs}"
    class="linkUnderline chat-trigger blue mouse-link chat">${this.resourceBundle.contactUs}</a>`;
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    this.userType = this.resetPasswordService.verificationMethodsResponse?.userType;
    this.getPaCount = this.resetPasswordService.verificationMethodsResponse?.paCount;
    this.code = this.resetPasswordService.verificationMethodsResponse?.code;
    this.resetPasswordService.verificationMethodsResponse?.verificationMethodsList?.forEach(verificationMethod => {
      if (verificationMethod.contactmethod === ContactMethod.otpphone) {
       this.otpPhoneShow = verificationMethod.enabled;
       verificationMethod?.contacttype?.forEach(contType => {
        if (ContactMethodType.sms === contType?.type && verificationMethod.enabled ===true) {
          this.otpSmsShow = verificationMethod.enabled;
          this.otpPhoneValue = contType?.maskedvalue;
        }
        if (ContactMethodType.voice === contType?.type && verificationMethod.enabled ===true) {
          this.otpVoiceShow = verificationMethod.enabled;
          this.otpPhoneValue = contType?.maskedvalue;
        }
      });
      }
      if (verificationMethod.contactmethod === ContactMethod.otpemail && verificationMethod.enabled ===true) {
        this.otpEmailShow = verificationMethod.enabled;
        this.otpEmailValue = verificationMethod?.contacttype[0]?.maskedvalue;
      }
    });
    this.isMobile = this.resetPasswordService.isMobile;
    if (this.resetPasswordService.checkNoAttempts) {
      const contactType = this.resetPasswordService.generateOtpRequest.contacttype;
      if (contactType === 'voice' || contactType === 'sms') {
        this.resetPasswordService.selectedContactType = SelectedContactMethodType.otpSmsOrVoice;
      } else if (contactType === 'email') {
        this.resetPasswordService.selectedContactType = SelectedContactMethodType.otpEmail;
      }
      if (this.resetPasswordService.displayErrMsg) {
        this.resetPasswordService.displayErrMsg = false;
      if (this.otpPhoneShow || this.otpEmailShow) {
        const phoneOrEmailErrMsg = this.resourceBundle.noAttemptOtpErrMsg +
        this.resetPasswordService.selectedContactType +
        this.resourceBundle.noAttemptOtpErrMsg1 +
        '<a href="https://www.cox.com/business/support/trouble-signing-in-on-the-cox-website.html" class="linkUnderline" target="_blank"> Need Help? </a>';
        asapScheduler.schedule(() =>
        this.resetPasswordService.resetPwdMsg.showMessage(phoneOrEmailErrMsg, 'error', 'resetPwdError'));
        this.resetPasswordService.modalScroll();
      } else {
        this.checkUserType();
      }
    }
    }
    if (!this.otpPhoneShow && !this.otpEmailShow) {
      this.checkUserType();
    }
    document.getElementById('closeBtn').focus();
    this.validateUserName(this.code);
   }

  public triggerChat() {
    const element = document.querySelector('.chat');
    if (element) {
      element.addEventListener('click', ()=>{
        this.triggerTealium(this.resourceBundle.contactUs);
      });
    }
  }

  public triggerTealium(eventName: string) {
    this.tealium.button(eventName, eventName);
  }

  //generate otp on click of verification method
  public async generateOTP(method: string, type: string){
    this.resetPasswordService.resetPwdMsg.hideMessage();
    this.resetPasswordService.verificationMethodsResponse?.verificationMethodsList?.forEach(verificationMethod => {
      if (method === verificationMethod?.contactmethod){
        verificationMethod?.contacttype?.forEach(contType => {
          if (type === contType?.type) {
            this.contactValue = contType?.value;
            this.resetPasswordService.maskedContact = contType.maskedvalue;
          }
        });
      }
    });

    const reqBody: GenerateOtpRequest = {
      contactmethod: method,
      contacttype: type,
      userEmail: this.resetPasswordService.userEmail,
    };
    this.resetPasswordService.generateOtpRequest = reqBody;
    try {
      const data = await this.resetPasswordService.generateOtp(reqBody);
      if (data.code === '0') {
        this.resetPasswordService.currentStep = StepNum.step3;
        this.resetPasswordService.modalHeading = this.resourceBundle.verificationCode;
      } else{
        this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.generateOtpError,
           'error', 'resetPwdError');
        this.resetPasswordService.modalScroll();
      }
    } catch (error) {
      this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.generateOtpError,
         'error', 'resetPwdError');
      this.resetPasswordService.modalScroll();
    }
  }

  // Check usertype to display error message
  public checkUserType() {
  if(this.userType === 'profileowner' || this.userType === 'profileadmin') {
    // Error message if user is PO/PA with AuthID.
    if (this.code === '1003') {
      return this.validateUserName(this.code);
    }
      const toolTipErrMsg = '<span class="useScanIdTooltip"></span>';
      const lockedPoPaErrMsg = this.resourceBundle.disableContMthdPoPaErrMsg;
      asapScheduler.schedule(() =>
        this.resetPasswordService.resetPwdMsg.showMessage(lockedPoPaErrMsg + toolTipErrMsg, 'error', 'resetPwdError'));
      setTimeout(() => { const element = document.getElementsByClassName('useScanIdTooltip')[0];
      element.setAttribute('id', 'scanIdTooltip');
      }, 0);
      this.resetPasswordService.modalScroll();
   } else {
      // Error message if user is an EU
      const lockedEuErrMsg = this.resourceBundle.lockAllContactMthdErrMSg;
      asapScheduler.schedule(() =>
      this.resetPasswordService.resetPwdMsg.showMessage(lockedEuErrMsg, 'error', 'resetPwdError'));
      this.resetPasswordService.modalScroll();
    }
}

  public navigateToScanId() {
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    this.resetPasswordService.currentStep = this.resetPasswordService.isIdScanFailed ? StepNum.step2b : StepNum.step2a;
    this.resetPasswordService.modalHeading = this.resetPasswordService.isIdScanFailed ?
      this.resourceBundle.idScan : this.resourceBundle.selectContactMethod;
  }

  public onchooseCnctMthdClose() {
    this.closeClick.emit();
    this.resetPasswordService.currentStep = StepNum.step1;
    this.resetPasswordService.modalHeading = this.resourceBundle.forgotPassword;
  }
  public navigateToEditProfile(){
    if(this.userGuid){
      this.router.navigateByUrl(`/admin/user/edit?guid=${this.userGuid}`);
    }
  }
  private validateUserName(code: string) {
    if ((this.userType === 'profileowner' || this.userType === 'profileadmin') && code === '1003') {
      if (this.isMyadmin) {
        const errorMsg =
          this.getPaCount > 0 ? this.resourceBundle.errorMsgWithPa : this.resourceBundle.errorMsgWithoutPa;
        asapScheduler.schedule(() =>
          this.resetPasswordService.resetPwdMsg.showMessage(errorMsg, 'error', 'resetPwdError'));
        this.showEditProfile = true;
        this.resetPasswordService.modalScroll();
      }else {
        this.hideUseIdScan = true;
        const errMsgMyAccount = this.getPaCount > 0 ? this.resourceBundle.missingProfileInfoErrMsg :
          this.resourceBundle.missingProfileInfoErrMsgAdm;
        asapScheduler.schedule(() =>
        this.resetPasswordService.resetPwdMsg.showMessage(errMsgMyAccount + this.contactUs, 'error', 'resetPwdError'));
        this.resetPasswordService.modalScroll();
        setTimeout(() => this.triggerChat());
      }
    }
  }
}
