<div class="accountlookup col-xs-12 col-md-12 col-sm-12">
    <app-equipmentaccountwidgetsingleaccount (selectedAccount)="getSelectedAccount($event)" [baseAccounts]="baseAccounts"  *ngIf="singleAccount"></app-equipmentaccountwidgetsingleaccount>
    <app-equipmentaccountwidget2to6accounts (selectedAccount)="getSelectedAccount($event)"  [baseAccounts]="baseAccounts" *ngIf="twotosixAccounts"></app-equipmentaccountwidget2to6accounts>
    <app-equipmentaccountwidget7plusaccounts (selectedAccount)="getSelectedAccount($event)"  [baseAccounts]="baseAccounts" *ngIf="sevenplusAccounts"></app-equipmentaccountwidget7plusaccounts>
    </div>
    
    
    
    



