import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CacheInterceptor } from '../interceptors/cache.interceptor';
import { ErrorHandlerInterceptor } from '../interceptors/error-handler.interceptor';
import { AppConfig } from './app.config';
import { HttpCacheService } from './http/http-cache.service';
import { HttpService } from './http/http.service';
import { I18nService } from './i18n.service';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { HeaderComponent } from './shell/header/header.component';
import { ShellComponent } from './shell/shell.component';

export function configLoad(config: AppConfig) {
  // tslint:disable-next-line: prefer-immediate-return
  const x = () => config.load();
  return x;
}

export function loadMyAccountAppConfig(config: AppConfig) {
  // tslint:disable-next-line: prefer-immediate-return
  const x = () => config.loadMyAccountAppConfig();
  return x;
}

export function loadMyAccountRolesAppConfig(config: AppConfig) {
  // tslint:disable-next-line: prefer-immediate-return
  const x = () => config.loadMyAccountRolesAppConfig();
  return x;
}

export function loadPageIdMappings(config: AppConfig) {
   // tslint:disable-next-line: prefer-immediate-return
  const x = () => config.loadPageIdMappings();
  return x;
 }
export function loadMessages(config: AppConfig) {
  // tslint:disable-next-line: prefer-immediate-return
  const x = () => config.loadMessages();
  return x;
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    NgbModule,
    RouterModule,
  ],
  declarations: [
    HeaderComponent,
    ShellComponent,
  ],
})
export class CoreSharedModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
          ngModule: CoreSharedModule,
          providers: [
            { provide: APP_INITIALIZER, useFactory: configLoad,
              deps: [AppConfig], multi: true },
            { provide: APP_INITIALIZER, useFactory: loadMyAccountAppConfig,
              deps: [AppConfig], multi: true },
              { provide: APP_INITIALIZER, useFactory: loadMyAccountRolesAppConfig,
                deps: [AppConfig], multi: true },
                { provide: APP_INITIALIZER, useFactory: loadPageIdMappings,
                  deps: [AppConfig], multi: true },
                  { provide: APP_INITIALIZER, useFactory: loadMessages,
                    deps: [AppConfig], multi: true },
            I18nService,
            HttpCacheService,
            ErrorHandlerInterceptor,
            CacheInterceptor,
            {
              provide: HttpClient,
              useClass: HttpService,
            },
            {
              provide: RouteReuseStrategy,
              useClass: RouteReusableStrategy,
            },
          ],
        };
      }
}
