import { MicroserviceResponse } from '../services/coxhttpclient.service';

export enum CaseNum {
	case1 = 'Pending',
    case2 = 'Shipped',
    case3 = 'Delivered',
    case4 = 'All Installed'
}

export interface stepsResponse extends MicroserviceResponse{
    deviceContent: deviceContentList[];
}

export interface deviceContentList {
    name: string;
    content: string;
}

export enum ChannelStatus {
    Ready = 'Ready',
    Proceed = 'Proceed',
    Installed = 'Installed',
    Failure = 'Failure'
}
export enum DeviceType{
    INTERNETDEVICE= '1p',
    NONINTERNETDEVICE= 'non1p'
}

export interface deviceDetails {
    deviceType: string;
    itemNumber: string;
    name: string;
    trackingUrl: string;
    shippingTrackingNumber: string;
    shippingStatus: string;
    workOrderNumber: string;
    serialNumber: string;
    channelSolutionID: string;
    installStatus: string;
    caseId: string;
}

export interface caseDetails {
    caseId: string;
    caseStatus: string;
    caseType: string;
}

export enum SelfInstallStatus {
    SUCCESS = 'Success',
    INPROGRESS = 'In-Progress',
    FAILED = 'Failed'
}

export enum NetAssuranceDevices {
    NCPA300 = 'NCPA300',
    NCPA100 = 'NCPA100'
}