import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AEMClientService, CBHelperService, DeviceDetectorService, ResourceBundle } from 'common-ui-lib';
import { Notification } from 'redesign/users-and-accounts/user-settings/user-settings.model';
import { Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { PageDetail, PaginationOptions } from '../../pagination/paginationinput.model';
import { TextTransformRequest } from '../../pipes/text-transform.model';
import { CommonMessageService } from '../commonmessage/commonmessage.service';
import { FilterEnum, NotificationCategoryEnum, NotificationChannelEnum,
   NotificationResourcebundleEnum, NotificationUserPreferenceInput } from './notification-template.model';
import { NotificationTemplateService } from './notification-template.service';

@Component({
  selector: 'app-notification-template',
  templateUrl: './notification-template.component.html',
  styleUrls: ['./notification-template.component.scss']
})
export class NotificationTemplateComponent implements OnInit {

  @Input() subCategories: Notification[];
  @Input() guidanceText: string;
  @Output() updatedPreferences = new EventEmitter<Notification[]>();
  public selectedCategory: Notification;
  public resourceBundle: ResourceBundle = {};
  public userGuid: string;
  public searchObj;
  public deleteTag;
  public notificationTableData;
  public selectedTabIndex = 0;
  public selectedTab: string;
  public totalCount: number;
  public categorySelected: string;
  public tableContentLoaded = false;
  public isMultiAccount: boolean;
  public pageOptions: PaginationOptions;
  public showPagination: boolean;
  public isLargeScreen: boolean;
  public isMobile: boolean;
  public isTablet: boolean;
  public datatableRowWidth = [];
  public turnedOnfiltertags = [];
  public masterEmail: boolean;
  public masterSms: boolean;
  public masterEmailDisable: boolean;
  public masterSmsDisable: boolean;
  private onDestroy$ = new Subject<boolean>();
  private searchStringValue$ = new Subject();
  constructor(public aemClient: AEMClientService,
    public activeRoute: ActivatedRoute,
    public notificationTemplateService: NotificationTemplateService,
    public commonMessageService: CommonMessageService,
    public deviceService: DeviceDetectorService,
    public cbHelperService: CBHelperService) {
      this.pageOptions = new PaginationOptions();
    }

  ngOnInit(): void {
    this.aemClient.getSharedBundle('notificationTemplate')
        .pipe(takeUntil(this.onDestroy$))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .subscribe((data: any) => {
          this.resourceBundle = data;
          const category = NotificationResourcebundleEnum[this.subCategories[0].categoryName];
          this.guidanceText = this.resourceBundle[category];
        });
    this.isMultiAccount = this.cbHelperService.getLoggedInUserDetails().profile.userToken.multiAccount;
    if (this.activeRoute.snapshot.queryParamMap) {
      this.userGuid = this.activeRoute.snapshot.queryParamMap.get('guid');
    }
    console.log('subCategories>>>>',this.subCategories);
    this.isLargeScreen = this.deviceService.isLargeScreen();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.selectedTab = this.subCategories[0].categoryName;
    this.masterEmail = this.subCategories[0].isEmailPreference;
    this.masterSms = this.subCategories[0].isSmsPreference;
    this.createSearchObj();
    this.getNotificationAccounts('0', null, 10, null, '', this.subCategories[0].categoryName, false);
    this.setTableWidth();
    this.searchStringValue$.pipe(debounceTime(500)).subscribe((data: string) => {
      const searchString = data;
      if (searchString !== '') {
        this.tableContentLoaded = false;
        this.showPagination = false;
        this.getNotificationAccounts('0', null, 10, 0, searchString, this.selectedTab, false);
      }
    });
  }

  // On selection of child categories from the expanded view tabs
  public ontabChange(event) {
    this.selectedTabIndex = event;
    this.selectedTab = this.subCategories[this.selectedTabIndex].categoryName;
    this.masterEmail = this.subCategories[this.selectedTabIndex].isEmailPreference;
    this.masterSms = this.subCategories[this.selectedTabIndex].isSmsPreference;
    const category = NotificationResourcebundleEnum[this.selectedTab];
    this.guidanceText = this.resourceBundle[category];
    this.tableContentLoaded = false;
    this.showPagination = false;
    this.getNotificationAccounts('0', null, 10, null, '', this.selectedTab, false);
    console.log(event);
  }

  // Query call to fetch list of accounts for notification preferences
  public getNotificationAccounts(after: string, before: string, first: number,
    last: number, search: string, type: string, paginated: boolean) {
    this.notificationTemplateService.getNotificationAccounts(this.userGuid, after, before, first, last, search, type)
      .pipe(finalize(() => {
        this.tableContentLoaded = true; this.pageOptions.datatableLoaded = paginated;
      }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((results: any) => {
        if (results.data !== null && results.data.getUser?.notificationCollection !== null) {
          const accountList = results.data?.getUser.notificationCollection?.edges;
          this.totalCount = results.data?.getUser.notificationCollection?.totalCount;
          this.notificationTableData = accountList;
          // eslint-disable-next-line max-len
          this.notificationTableData = this.notificationTableData.map(v => ({ ...v, serviceAddress: this.replaceNull(v['node'].accountServiceAddress.houseNumber) + ' ' + this.replaceNull(v['node'].accountServiceAddress.street) + ' ' + this.replaceNull(v['node'].accountServiceAddress.city) + ' ' + this.replaceNull(v['node'].accountServiceAddress.state) + ' ' + this.replaceNull(v['node'].accountServiceAddress.zipCode) }));
          console.log(this.notificationTableData);
          if (this.totalCount > 10 && after === '0') {
            this.showPagination = true;
            this.initializeTablePaginationOptions();
          }
        }
        if (results.errors) {
          const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'notificationCollection');
          if (errorInfo) {
            window.scroll(0, 0);
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'userSettings');
          }
        }
      });
  }

   //On Load ngx-pagination options
   public initializeTablePaginationOptions() {
    this.pageOptions.id = 'notificationPrefPaginator';
    this.pageOptions.showGoto = false;
    this.pageOptions.showItemsPerpage = false;
    this.pageOptions.currentPage = 1;
    this.pageOptions.maxSize = 5;
    this.pageOptions.totalItems = this.totalCount;
    this.pageOptions.responsive = false;
    this.pageOptions.previousLabel = '...';
    this.pageOptions.nextLabel = '...';
  }

  // On click of next, previous or page number link of the pagination
  public clickNextOrPrevTable(detail: PageDetail) {
    this.pageOptions.currentPage = detail.currentPage;
    this.tableContentLoaded = false;
    this.getNotificationAccounts(
      (detail.startIndex - 1).toString(), null, 10, null, '', this.selectedTab, true);
    console.log(detail);
  }

  //replace null values
  public replaceNull(value: string) {
    return (value == null) ? '' : value;
  }

  //Creating options for searchable columns
  public createSearchObj(){
    const _clearAll = { ...{ clear: 'clearall', initialLoad: true } };
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (this.notificationTableData?.length !== 0) ?
     this.deleteTag = _clearAll : this.turnedOnfiltertags = [];
    this.searchObj = {
      searchType: 'server',
      searchArray: this.notificationTableData,
      placeHolder: 'Search',
      searchColumns: ['accountAlias', 'serviceAddress'],
      filteredArray: this.setFilterObject(),
      searchPreceedFilter: true
    };
  }

  // On server side search on entering or clearing search string
  public getSearchResults(searchString) {
    console.log('searchString>>>',searchString);
    if (searchString.length > 0) {
      this.searchStringValue$.next(searchString);
    } else {
      this.tableContentLoaded = false;
      this.showPagination = false;
      this.getNotificationAccounts('0', null, 10, null, '', this.selectedTab, false);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getFiltertags(event: any) {
   this.turnedOnfiltertags = [...event];
  }

  // On filter tag clear
  public clearFilterTag(event: string) {
    this.deleteTag = event;
  }

  //Filter Options
  public setFilterObject() {
    const categoriesList = [];
    let category = {};
    category = {
       categoryTitle: 'Email',
       options: this.statusCategoriesOptions([TextTransformRequest.turnedonn, TextTransformRequest.turnedoff])
      };
    categoriesList.push(category);
    category = {
      categoryTitle: 'SMS',
      options: this.statusCategoriesOptions([TextTransformRequest.turnedonn, TextTransformRequest.turnedoff])
     };
   categoriesList.push(category);
    return categoriesList;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public statusCategoriesOptions(categoriesOptions: any[]) {
    const _optionsList = [];
    if (!categoriesOptions.every((ele) => ele === null)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      categoriesOptions.forEach((element: any) => {
        if (element !== null) {
          const option = {
            optionValue: element?.toLowerCase(),
            isChecked: false
          };
          _optionsList.push(option);
        }
      });
    }
    return _optionsList;
  }

  // MOBILE VIEW - On selecting child category link load the sub category content
  public loadSubMenuContent(category: Notification){
    this.selectedCategory = category;
    this.notificationTemplateService.mobileChildCategoryActive = true;
    document.getElementById('npSubMenu').classList.toggle('hide');
    document.getElementById('npSubMenuContent').classList.toggle('hide');
  }

  //On switch toggle trigger mutation to save changes
  public onSwitchToggle(changedPreference: boolean, accountGuid: string,
     type: NotificationChannelEnum, filter: string) {
    let toggledAccountGuid: string;
    if ((type === NotificationChannelEnum.EMAIL) || (type === NotificationChannelEnum.SMS)) {
      if (filter === FilterEnum.ALL) {
        toggledAccountGuid = '';
      } else if (this.isMultiAccount) {
        toggledAccountGuid = accountGuid;
      }
    }

    const input: NotificationUserPreferenceInput = {
      userGuid: this.userGuid,
      accountGuid: toggledAccountGuid,
      notificationCategory: NotificationCategoryEnum[this.selectedTab],
      notificationChannel: type,
      value: changedPreference
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.notificationTemplateService.updateUserPreferences(filter, input).subscribe((results: any) => {
      if (results.data !== null && results.data.updateUserPreference !== null) {
        const updatedNotification = results.data.updateUserPreference?.notifications;
        this.updatedPreferences.emit(results.data.updateUserPreference?.notifications);
        this.tableContentLoaded = false;
        this.showPagination = false;
        this.getNotificationAccounts('0', null, 10, null, '', this.selectedTab, false);
        updatedNotification.forEach((element) => {
          if (element.categoryName === this.selectedTab) {
            this.masterEmail = element.isEmailPreference;
            this.masterSms = element.isSmsPreference;
          }
        });
      }
      if (results.errors) {
        window.scroll(0, 0);
        this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'userSettings');
      }
    });
  }

  // To set Datatable column width depending on device width
  private setTableWidth() {
    if(this.isTablet) {
      this.datatableRowWidth.push({accountDetails: 300, notification: 300});
    } else if(this.isMobile) {
      this.datatableRowWidth.push({accountDetails: 250, notification: 300});
    } else if(this.isLargeScreen) {
      this.datatableRowWidth.push({accountAlias: 300, serviceAddress: 400, notification: 300});
    } else {
      this.datatableRowWidth.push({accountDetails: 530, notification: 300});
    }
  }

}
