import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from './component-can-deactivate';
import { UnsavedPromptService } from './unsavedPrompt.service';

/**
 * Use this guard with UnsavedPromptService, using of ComponentCanDeactivate is deprecated!
 * ```typescript
 * export class MyComponent {
 *  constructor(private service: UnsavedPromptService) {
 *     service.promptMessage = 'Your changes might lost!'; // set custom message if necessary
 *     // it will be reset to default message once you go to another page
 *     service.hasChanges = true; // set value to true when guard should be active;
 *   }
 * }
 * ```
 */
export const UnsavedChangeGuard: CanDeactivateFn<ComponentCanDeactivate> = (component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> | boolean => {
        // Obsolete
        const service = inject(UnsavedPromptService);
        if (component && component.canDeactivate && component.handleRedirect) {
            if (component.canDeactivate(nextState)) {
                return component.handleRedirect(); // remove that once ComponentCanDeactivate will be removed out
            }
            return true;
        }

        if (service.hasChanges && !currentState?.url?.includes('/settings/user')) {
            return service.showPrompt();
        }
        return true;
}
