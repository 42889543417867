<ng-template #leaveSiteModal>
<div role="dialog" aria-describedby="modal-heading" aria-modal="true">
  <span class="sr-only" id="modal-heading">{{modaltemplate.modalTitle}} heading level 4</span>
  <div class="modalheader pt-3 pb-3 modal-header" *ngIf="modaltemplate.modalTitleRequired">
    <h4 class="h4 modal-title">{{modaltemplate.modalTitle}}</h4>
    <a href="javascript:void(0)" title="Close" class="cb-modal-close-btn float-right ml-2" role="button"
      (click)="onSecondaryBtnClick()"></a>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="showAppMsg">
    <app-message [type]="appMessage.type" [message]="appMessage.message"></app-message>
  </div>
  <div class="modal-body col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 px-0">
      <p [innerHTML]="modaltemplate.modalBodyDescription"></p>
    </div>
  </div>
  <hr class="hr">
  <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
    <div class="col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="secondaryBtnRequired">
      <button class="button button-secondary"
        (click)="onSecondaryBtnClick()">{{modaltemplate.secondaryButtonText}}</button>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-right pull-right" *ngIf="primaryBtnRequired">
      <button class="button" [ngClass]="{'btn-long-text':showBtnLongText }"
        (click)="onPrimaryBtnClick($event)">{{modaltemplate.primaryButtonText}}</button>
    </div>
  </div>
</div>
</ng-template>
<!--unsaved changes phase3 modal-->
<div role="dialog" aria-describedby="modal-heading" aria-modal="true"  *ngIf="features;else leaveSiteModal">
  <span class="sr-only" id="modal-heading">{{resourceBundle.unsavedChangesModalHeadingLabel}}</span>
  <div class="modalheader pt-3 pb-3 modal-header">
    <h4 class="h4 modal-title">{{resourceBundle.unsavedChangesModalHeading}}</h4>
    <a href="javascript:void(0)" title="Close"  role="button" class="cb-modal-close-btn float-right ml-2"
      (click)="onSecondaryBtnClick()"></a>
  </div>
  <div class="modal-body col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 px-0">
      <p>Unsaved Changes to <strong [innerHtml]="unsavedfeaturetabs"></strong> will be lost if you continue. Are you sure you want to leave this {{switchPage ? 'page?' : 'tab?'}}</p>
    </div>
  </div>
  <hr class="hr">
  <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="!isMobileDevice">
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 pl-0 pr-0" *ngIf="secondaryBtnRequired">
      <button class="button button-secondary" [ngClass]="{'button-leavesite-modal': !isMobileDevice }"
      (click)="onSecondaryBtnClick()">{{switchPage ? resourceBundle.stayOnPage : resourceBundle.stayOnTab}}</button>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-right pr-0 pull-right pl-0" *ngIf="primaryBtnRequired">
      <button class="button" [ngClass]="{'button-leavesite-modal': !isMobileDevice }"
      (click)="onPrimaryBtnClick($event)">{{switchPage ? resourceBundle.leavePage : resourceBundle.leaveTab}}</button>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="isMobileDevice">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-right pr-3 pull-right pl-3" *ngIf="primaryBtnRequired">
        <button class="button" [ngClass]="{'button-leavesite-modal': !isMobileDevice }"
        (click)="onPrimaryBtnClick($event)">{{switchPage ? resourceBundle.leavePage : resourceBundle.leaveTab}}</button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 pl-3 pr-3" *ngIf="secondaryBtnRequired">
        <button class="button button-secondary" [ngClass]="{'button-leavesite-modal': !isMobileDevice }"
        (click)="onSecondaryBtnClick()">{{switchPage ? resourceBundle.stayOnPage : resourceBundle.stayOnTab}}</button>
      </div>
    </div>
  </div>
</div>
