import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UserInfoService {
  // Observable sources
  private loggedInUser = new Subject<any>();

  // Observable string streams
  // loggedInUser$ = this.loggedInUser.asObservable();

  // Service message commands
  setUser(user: any) {
    this.loggedInUser.next(user);
  }

}
