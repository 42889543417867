import { MicroserviceResponse } from 'common-ui-lib';

export interface MarketRollOut extends MicroserviceResponse{
    testMarket: boolean;
    mmconsolidated: boolean;
}

export enum API{
    ELIGIBILTY_API = '/api/cbma/profile/services/profile/treatment/eligibility' 
}
