import {
    AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy,
    OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation,
} from '@angular/core';

import 'select2';
import { Select2TemplateFunction } from './ng2-select2';
import { Select2OptionData } from './ng2-select2.interface';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'lib-app-select2',
    template: `
        <select #selector>
            <ng-content select="option, optgroup">
            </ng-content>
        </select>`,
    styleUrls: ['./ng2-select2.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Select2libComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
    @ViewChild('selector') selector: ElementRef;

    // data for select2 drop down
    @Input() data: Select2OptionData[];

    // value for select2
    @Input() value: string | string[];

    // enable / disable default style for select2
    @Input() cssImport = false;

    // width of select2 input
    @Input() width: string;

    // enable / disable select2
    @Input() disabled = false;

    @Input() clearable = false;

    // all additional options
    @Input() options: Select2Options;

    // emitter when value is changed
    @Output() valueChanged = new EventEmitter();

    @Output() open = new EventEmitter();

    @Output() close = new EventEmitter();

    @Input() TemplateResult: Select2TemplateFunction ;

    public element: JQuery = undefined;
    private check = false;
    private style = `CSS`;

    constructor(private renderer: Renderer2) { }

    ngOnInit() {
        if (this.cssImport) {
            const head :any = document.getElementsByTagName('head')[0];
            const link: any = head.children[head.children.length - 1];

            if (!link.version) {
                const newLink = this.renderer.createElement(head, 'style');
                this.renderer.setProperty(newLink, 'type', 'text/css');
                this.renderer.setProperty(newLink, 'version', 'select2');
                this.renderer.setProperty(newLink, 'innerHTML', this.style);
            }

        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (!this.element) {
            return;
        }

        if (changes['data'] &&
        JSON.stringify(changes['data'].previousValue) !== JSON.stringify(changes['data'].currentValue)) {
            await this.initPlugin();

            const newValue: string = this.element.val() as string;
            if (this.element.val() === '-1') {
                this.valueChanged.emit({
                    value: undefined,
                    data: this.element.select2('data'),
                });
            } else {
                this.valueChanged.emit({
                    value: newValue,
                    data: this.element.select2('data'),
                });
            }
        }

        if (changes['value'] && changes['value'].previousValue !== changes['value'].currentValue) {
            const newValue: string = changes['value'].currentValue;

            this.setElementValue(newValue);
            if (this.element.val() === '-1') {
                this.valueChanged.emit({
                    value: undefined,
                    data: this.element.select2('data'),
                });
            } else {
                this.valueChanged.emit({
                    value: newValue,
                    data: this.element.select2('data'),
                });
            }
        }

        if (changes['disabled'] && changes['disabled'].previousValue !== changes['disabled'].currentValue) {
            this.renderer.setProperty(this.selector.nativeElement, 'disabled', this.disabled);
        }
    }

    async ngAfterViewInit() {
        this.element = jQuery(this.selector.nativeElement);
        await this.initPlugin();

        if (typeof this.value !== 'undefined') {
            this.setElementValue(this.value);
        }

        this.element.on('select2:select select2:unselect', () => {
            if (this.element.val() === '-1') {
                this.valueChanged.emit({
                    value: undefined,
                    data: this.element.select2('data'),
                });
                return;
            }
            this.valueChanged.emit({
                value: this.element.val(),
                data: this.element.select2('data'),
            });
        });
        this.element.on('select2:open', () => {
            this.open.emit();
        });
        this.element.on('select2:close', () => {
            this.close.emit();
        });
    }

    ngOnDestroy() {
        if (this.element && this.element.off) {
            this.element.off('select2:select');
        }
    }

    // tslint:disable-next-line: cognitive-complexity
    private async initPlugin() {
        const clearText = '---Clear---';
        if (!this.element.select2) {
            if (!this.check) {
                this.check = true;
            }

            return;
        }

        // If select2 already initialized remove him and remove all tags inside
        if (this.element.hasClass('select2-hidden-accessible') === true) {
            this.element.select2('destroy');
            this.renderer.setProperty(this.selector.nativeElement, 'innerHTML', '');
        }

        let values = [];
        if (this.data) {
            if (this.clearable ) {
                values.push({ id: '-1', text: clearText});
            }
            for (const dataItem of this.data) {
                values.push(dataItem);
            }
        }

        const options: Select2Options = {
            data: values,
            width: (this.width) ? this.width : 'resolve',
        };

        if (this.TemplateResult) {
            options.templateResult = this.TemplateResult;
        }
        Object.assign(options, this.options);

        if (options.matcher) {
            const oldMatcher: any = await this.requireOldMatcher();
            options.matcher = oldMatcher(options.matcher);
            this.element.select2(options);

            if (typeof this.value !== 'undefined') {
                this.setElementValue(this.value);
            }
        } else {
            if (this.clearable) {
                options.matcher = (term: any, option: any) => {
                    if (option.text === clearText && this.element.val()) {
                        return option;
                    }
                    if (option.text === clearText && !this.element.val()) {
                        return null;
                    }
                    if (!term.term) {
                        return option;
                    }
                    return option.text.toLowerCase().includes(term.term.toLowerCase()) ? option : null;
                };
            }
            this.element.select2(options);
        }

        if (this.disabled) {
            this.renderer.setProperty(this.selector.nativeElement, 'disabled', this.disabled);
        }
    }

    private async requireOldMatcher(): Promise<any> {
        return new Promise<any[]>((resolve) => {
            jQuery.fn.select2.amd.require(['select2/compat/matcher'], (oldMatcher: any) => {
                resolve(oldMatcher);
            });
        });
    }

    private setElementValue(newValue: string | string[]) {
        if (Array.isArray(newValue)) {
            for (const option of this.selector.nativeElement.options) {
                if (newValue.indexOf(option.value) > -1) {
                    this.renderer.setProperty(option, 'selected', 'true');
                }
            }
        } else {
            this.renderer.setProperty(this.selector.nativeElement, 'value', newValue);
        }

        this.element.trigger('change.select2');
    }
}
