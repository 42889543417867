import { Injectable } from '@angular/core';

@Injectable()
export class SplitIdentifierService {
    private isSplit: boolean;

    constructor() {
        this.isSplit = true;
    }

    setSplitStatus(flag: boolean) {
        this.isSplit = flag;
    }

    getSplitStatus(): boolean {
        return this.isSplit;
    }
}
