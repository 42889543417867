import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CoxHttpClient } from 'common-ui-lib';
import { UserInput } from 'redesign/admin/user/edit-user/edit-user.model';
import { Observable } from 'rxjs';
import { EligibleRebootDevice, OtherEquipmentRebootInput, RebootStatusDetails } from './redesign-reboot-widget.model';

@Injectable()
export class RedesignRebootWidgetService {
  constructor(private apollo: Apollo, private coxhttpClient: CoxHttpClient) {
  }

  public async getDeviceEligibility(key: string): Promise<EligibleRebootDevice> {
    const requestBody = {
      encryptedValue: key,
    };
    return this.coxhttpClient.postAsync<EligibleRebootDevice>
      (`/api/cbma/accountequipment/services/accountequipment/equipments/eligibleRebootDevice`, requestBody,
        { disableSpinner: true, customeErrorMessage: '' });
  }

  public callRebootAPI(caseid: string, key: string): Observable<RebootStatusDetails> {
    const requestBody = {
      caseId: caseid,
      encryptedValue: key,
    };
    return this.coxhttpClient.post(`/api/cbma/accountequipment/services/accountequipment/casedetail`,
      requestBody);
  }

  public otherEquipmentReboot(input: OtherEquipmentRebootInput) {
    const OtherEquipmentReboot = gql`
      mutation OtherEquipReboot($input: OtherEquipmentRebootInput) {
        otherEquipmentReboot(input: $input) {
          caseID
          caseType
          estimatedProcessingTime
          status
          transactionId
          error {
            code
            detail
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: OtherEquipmentReboot,
      variables: {
        input
      },
    });

  }

  public getUserDetails(userId: string) {
    const getUserDetailsQuery = gql`
    query getUserDetails($userId: ID!){
      getUser(id: $userId){
        guid
        firstName
        lastName
        isProfileAdmin
        isProfileOwner
        isSPO
        isEndUser
        coxTN {
          number
          extension
          account {
            accountNumber12
          }
        }
        login {
          contactValue
          id
          contactType
          preferred
          isMobileNumber
        }
        contacts {
          id
          contactType
          contactValue
          preferred
          isMobileNumber
        }
      }
    }
  `;

    return this.apollo.query({
      query: getUserDetailsQuery,
      variables: {
        userId
      },
    });
  }

  public saveMobileNumber(guid: string, input: UserInput): Observable<any> {
    const updateMobileNumber = gql`
     mutation updateMobileNumber($guid: String!, $input:UserInput) {
      updateUser(guid: $guid, input: $input) {
        guid
        }
      }
    `;

    return this.apollo.mutate({
      mutation: updateMobileNumber,
      variables: {
        guid,
        input
      }
    });
  }
  
  public phoneEquipmentReboot(deviceName: string) {
    const phoneEquipmentReboot = gql`
    mutation phoneEquipmentReboot($deviceName: String!) {
    phoneEquipmentReboot(deviceName: $deviceName) {
    statuses {
    code
    id
    message
    transactionId
    warning
    }
    }
    }
    `;
    return this.apollo.mutate({
      mutation: phoneEquipmentReboot,
      variables: {
        deviceName
      },
    });
  }
}