import {Component, Input} from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-schedule-modal',
  template: `
      <app-schedule-form (modalClose)="close($event)"  [schData]="schData" [onlyGroup]="onlyGroup"></app-schedule-form>
  `,
})
export class ScheduleModalComponent {
  @Input()
  // tslint:disable-next-line: no-any
  schData: any;
  @Input() onlyGroup = false;
  constructor(public activeModal: NgbActiveModal) {}
  // tslint:disable-next-line: no-any
  close(event: any) {
    this.activeModal.close(event);
  }
}
