import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AEMClientService, ResourceBundle } from '../../services/aemclient.service';
import { CBHelperService, NavigationService, DeviceDetectorService } from 'common-ui-lib';

  @Component({
    selector: 'app-error',
    template: `
    <div class="row" [ngClass]="{'margin-top-40': !backnavigation}">
    <div class="col-md-12 col-sm-12 col-xs-12" [ngClass]="{'margin-top-15': !backnavigation}">
    <app-pageheader *ngIf="backnavigation" [moreLink]="false" [backLocation]="'MyAdmin'"></app-pageheader>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12" 
    [ngClass]="{'pl-4': isMobileDevice,'pt-3': backnavigation && isMobileDevice}">
      <h3>
        {{ errorDescription }}
      </h3>
    </div>
  </div>`
})
export class HttpErrorComponent implements OnInit, OnDestroy {
  public backnavigation: boolean;
  public isMobileDevice = false;
  resourceBundle: ResourceBundle = {};
  errorDescription: string;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private aemClient: AEMClientService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    public cbHelper: CBHelperService,
    public deviceService: DeviceDetectorService
    ) {
    this.aemClient.getErrorBundle('errorpageResourceBundle')
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
      (data: any) => {
        this.resourceBundle = data;
        this.displayErrorMessage();
      },
      error => {
      }
    );
  }

  ngOnInit() {
    this.backnavigation = !this.cbHelper.isProfileInContext() ? true : false;
    this.navigationService.setPageTitle('Back to MyAdmin Home');
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }
  }

  displayErrorMessage(){
    this.activatedRoute.queryParams
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(params => {
      switch(params['errorCode']){
        case '400':
          this.errorDescription = this.resourceBundle.error400Desc;
          break;
        case '401':
          this.errorDescription = this.resourceBundle.error401Desc;
          break;
        case '402':
          this.errorDescription = this.resourceBundle.error402Desc;
          break;
        case '403':
          this.errorDescription = this.resourceBundle.error403Desc;
          break;
        case '404':
          this.errorDescription = this.resourceBundle.error404Desc;
          break;
        case '405':
          this.errorDescription = this.resourceBundle.error405Desc;
          break;
        case '408':
          this.errorDescription = this.resourceBundle.error408Desc;
          break;
        case '414':
          this.errorDescription = this.resourceBundle.error414Desc;
          break;
        case '500':
          this.errorDescription = this.resourceBundle.error500Desc;
          break;
        case '501':
          this.errorDescription = this.resourceBundle.error501Desc;
          break;
        case '502':
          this.errorDescription = this.resourceBundle.error502Desc;
          break;
        case '504':
          this.errorDescription = this.resourceBundle.error504Desc;
          break;
        default:
          this.errorDescription = `HTTP ERROR: ${params['errorCode']}`;
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
