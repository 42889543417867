/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { NgModuleRef, PlatformRef, TestabilityRegistry } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Subject ,  Subscription } from 'rxjs';
// Uncomment for CBMA7-3303
// import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
// import { Metrics } from '@newrelic/browser-agent/features/metrics';
// import { Spa } from '@newrelic/browser-agent/features/spa';
import 'assets/config/pendo.js';
import { BootModule } from 'boot/boot.module';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
let platform: PlatformRef;
// Initialize New Relic Browser agent
// Uncomment for CBMA7-3303
// const NreumAppId = ((location.hostname.toLowerCase().includes("myaccount")) && (location.hostname.toLowerCase().includes("qa") || location.hostname.toLowerCase().includes("sit") || location.hostname.toLowerCase().includes("uat"))) ? '1120346225' : '1120346226';
// console.log('Newrelic Application Id', NreumAppId);
// const options = {
// info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"b1b8050adb",applicationID:NreumAppId,sa:1},
// loader_config: {accountID:"1413889",trustKey:"1190893",agentID:"1120346226",licenseKey:"b1b8050adb",applicationID:NreumAppId},
// init: { distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}, feature_flags: ['soft_nav'] },
// features: [Metrics, Spa]
// };

// const getNewRelic = () => {
//   window.browserAgent = new BrowserAgent(options);
//   console.log(window.browserAgent);
//   const script = document.createElement('script');
// script.setAttribute('src', 'your-script.js');
// document.head.appendChild(script);
// };
// Loading Scripts
const scriptArray = [
  'assets/config/cbmalibs.js',
  'assets/scripts/select2.js',
  'assets/scripts/slick.min.js',
];
const getScript = (url: string,name?:string) => new Promise<void>((resolve) => {
    const scriptEle = document.createElement('script');
    if(name === 'evergage'){
      scriptEle.id='evergage';
    }
    scriptEle.type = 'text/javascript';
    scriptEle.async = true;
    scriptEle.charset = 'utf-8';
    scriptEle.src = url;
    scriptEle.addEventListener('load', () => resolve(), false);
    document.getElementsByTagName('head')[0].appendChild(scriptEle);
});
const loadScripts = async () => {
  for (const script of scriptArray) {
    await getScript(script);
  }

  for (const key in jsObj) {
    if (jsObj.hasOwnProperty(key) && jsObj[key]) {
        console.log('CBMA.jslibs_' + key);
        getScript(jsObj[key],key);
    }
  }
};
  

// Uncomment for CBMA7-3303
// const originalPromise = window.Promise;
// getNewRelic();
// if (window.Promise !== originalPromise) {
//   window.Promise = originalPromise;
// }
loadScripts();
platformBrowserDynamic().bootstrapModule(BootModule).then((ref: NgModuleRef<BootModule>) => {
  platform = ref.injector.get(PlatformRef);
}).catch((err) => console.error(err));

const subject = new Subject();
window.destroySubject = subject;

const destroySubscription: Subscription = subject.subscribe({
  next: () => {
    console.log('Destroying Platform');
    const testabilityRegistry: TestabilityRegistry = platform.injector.get(TestabilityRegistry);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (testabilityRegistry as any)._applications.clear();

    platform.destroy();
    platform = null;

    console.log('Freeing up Memory');
    delete window.webpackJsonp;
    delete window.frameworkStabilizers;
    delete window.getAngularTestability;
    delete window.getAllAngularTestabilities;
    delete window.getAllAngularRootElements;
    delete window.ng;

    destroySubscription.unsubscribe();
  },
});
