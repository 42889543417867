import { Injectable, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CoxHttpClient, LocalStorageKeys, LoginProfileClient } from 'common-ui-lib';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject ,  Observable ,  Subscription } from 'rxjs';
// import { EmailConfigDetailsResponse } from './emailconfiguration/emailconfiguration.model';
import { CbccAlert, SymphonyFlow, TargetedOffers,UpdateProductsStatus, VoicemailInventory } from './myaccounthome.model';

@Injectable()
export class MyaccountHomeService implements OnDestroy {

  decodedtoken: any;
  tokenPayload: any;
  userEmail: '';
  jwtHelper: JwtHelperService = new JwtHelperService();
  masterUserEmail: any;
  setInterval: any;
  profile: any;
  private encryptedUserEmail: string;
  subscriptions: Subscription[] = [];
  landingPageResourceBundles: any = {};
  internetLandingPageResourceBundles: any = {};
  public encryptedUserEmailSub = new BehaviorSubject<string>('');

  constructor(
    private _storageService: LocalStorageService,
    private coxHttpClient: CoxHttpClient,
    private loginservice: LoginProfileClient,
  ) {
    this.getUpdatedRolesAndInfo();
  }

  getInactivProducts(userEmail: string) {
    return this.coxHttpClient.post('/api/cbma/user/services/user/product/v1', {email: userEmail});
  }

  updateActivateProducts(userEmail: string, updateProductsStatus: UpdateProductsStatus) {
    return this.coxHttpClient.post('/api/cbma/user/services/user/product/' + userEmail, updateProductsStatus);
  }

  getVoicemailInventory(): Observable<VoicemailInventory> {
    return this.coxHttpClient.get<VoicemailInventory>('/api/cbma/voicemail/services/voicemail/inventory');
  }

  // getEncryptedAccount12(account: string) {
  //   return this.coxHttpClient.get('/api/cbma/profile/services/profile/encrypt/' + account);
  // }

  getProfile() {
    return this.profile;
  }

  getEncryptedUserEmail() {
    return this.encryptedUserEmail;
  }

  setEncryptedUserEmail(encryptedUserEmail: string) {
    this.encryptedUserEmail = encryptedUserEmail;
    this.encryptedUserEmailSub.next(encryptedUserEmail);
  }

  getUpdatedRolesAndInfo() {
    this.encryptedUserEmail = '';
    this.decodedtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    if (this.decodedtoken) {
      this.tokenPayload = this.jwtHelper.decodeToken(this.decodedtoken);
      this.masterUserEmail = this.tokenPayload.sub;
      if (null !== JSON.parse(this.tokenPayload.profile)
        && undefined !== JSON.parse(this.tokenPayload.profile)
        && '' !== JSON.parse(this.tokenPayload.profile)) {
        this.profile = JSON.parse(this.tokenPayload.profile);
      }
    } else {
      const subscription = this.loginservice.userAuthenticated.subscribe((data) => {
        if (data) {
          this.decodedtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
          if (this.decodedtoken) {
            this.tokenPayload = this.jwtHelper.decodeToken(this.decodedtoken);
            this.masterUserEmail = this.tokenPayload.sub;
            if (null !== JSON.parse(this.tokenPayload.profile)
              && undefined !== JSON.parse(this.tokenPayload.profile)
              && '' !== JSON.parse(this.tokenPayload.profile)) {
              this.profile = JSON.parse(this.tokenPayload.profile);
            }
          }
        }
      });
      this.subscriptions.push(subscription);
    }
  }

  public getTargetedOffers(offerCode:string){
    const url = `/api/cbma/user/services/user/offerDetailSSO/${offerCode}`
    return this.coxHttpClient.getAsync<TargetedOffers>(url);
  }

  public getSymphonyFlow(){
    const url = `/api/cbma/user/services/user/symphony/swap`
    return this.coxHttpClient.getAsync<SymphonyFlow>(url);
  }

  public getCbccAlert(){
    const url = `/api/cbma/account/services/account/getcbccalert/email`;
    return this.coxHttpClient.get<CbccAlert>(url);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((elem) => {
      if (elem && elem.unsubscribe) {
        elem.unsubscribe();
      }
    });
  }
}
