import { Injectable } from '@angular/core';
import { CoxHttpClient, MicroserviceResponse } from 'common-ui-lib';
import { AccountContactResponse, BillingMailingAddress, BillingMailingAddressResponse, UserListRemoteResponse } from '../billingtools.model';
import { AccountContact, PaperlessBillingToggleRequestBody, UpdateAddressBody, UpdateBillingAddressResponse, User } from './paperlessbilling.model';

@Injectable()
export class PaperlessbillingService {
    constructor(
        private httpClient: CoxHttpClient,
    ) {
    }

    public async fetchMailingAddress(accNumber12: string): Promise<BillingMailingAddressResponse> {
        return this.httpClient.getAsync<BillingMailingAddressResponse>('/api/cbma/billing/v2/services/billing/billingaddress/account?accountnumber12=' + accNumber12
            , { disableSpinner: true, customeErrorMessage: '' });
    }

    public async fetchAccountContact(accountGuid: string): Promise<AccountContactResponse> {
        return this.httpClient.getAsync<AccountContactResponse>(
            `/api/cbma/account/services/account/${accountGuid}?permissionsReqd=true&contactsReqd=true&contactType=ALL`
            , { disableSpinner: true, customeErrorMessage: '' });
    }

    public async fetchUsers(): Promise<UserListRemoteResponse> {
        return this.httpClient.getAsync<UserListRemoteResponse>('/api/cbma/user/services/user/getusers?getServiceCodes=true'
            , { disableSpinner: true, customeErrorMessage: '' });
    }

    public async togglePaperless(accountNumber16: string, statementCode: string, body: PaperlessBillingToggleRequestBody): Promise<MicroserviceResponse> {
        return this.httpClient.putAsync(`/api/cbma/billing/v2/services/billing/billhandling/account?accountnumber16=${accountNumber16}&statementcode=${statementCode}`, body
            , { disableSpinner: true, customeErrorMessage: '' });
    }

    public async updateAccountContact(guid: string, id: string,
                                      accountContacts: AccountContact[]): Promise<MicroserviceResponse> {
        return this.httpClient.postAsync(`/api/cbma/account/services/account/contacts/update/${guid}`, {
            id,
            accountContacts,
        }, { disableSpinner: true, customeErrorMessage: '' });
    }

    public async updateBillingAddress(accountNumber12: string,
                                      statementCode: string, body: UpdateAddressBody):
        Promise<UpdateBillingAddressResponse> {
        return this.httpClient.postAsync<UpdateBillingAddressResponse>(
            `/api/cbma/billing/v2/services/billing/billingaddress/account/v2?accountnumber12=${accountNumber12}&statementcode=${statementCode}`, body,
            { disableSpinner: true, customeErrorMessage: '' });
    }
}
