import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CBHelperService, ResetPasswordService, ResourceBundle, StringUtilService
} from 'common-ui-lib';
import { StepNum, VerifyAuthenticIdRequest } from '../../reset-password.model';

@Component({
  selector: 'app-scanid-contact-method-confirmation',
  templateUrl: './scanid-contact-method-confirmation.component.html',
  styleUrls: ['./scanid-contact-method-confirmation.component.scss']
})
export class ScanidContactMethodConfirmationComponent implements OnInit {

  @Input() public resourceBundle: ResourceBundle = {};
  @Output() public closeClick = new EventEmitter();
  public isMobile: boolean;
  public isMyAdmin: boolean;
  public showturnOffButtonSpinner: boolean;
  public idScanDescription: string;
  public hasScanIdAttempts = false;
  constructor(
    public resetPasswordService: ResetPasswordService,
    public cbHelperService: CBHelperService,
    public stringUtilService: StringUtilService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.resetPasswordService.isMobile;
    this.isMyAdmin= this.cbHelperService.isMyAdmin();
    document.getElementById('closeBtn').focus();
    if(this.resetPasswordService.isIdScanFailed) {
      this.idScanDescription = this.resourceBundle.idScanFailedDescription;
      this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.idScanFailed, 'error', 'resetPwdError');
    } else {
      this.idScanDescription = this.stringUtilService
      .formatByPlaceHolder(this.resourceBundle.idScanDescription, [this.resetPasswordService.maskedScanIdContact]);
    }
  }

  public backToChooseOption() {
    if (this.hasScanIdAttempts) {
      this.resetPasswordService.isIdScanFailed = false;
    }
    this.resetPasswordService.currentStep = StepNum.step2a;
    this.resetPasswordService.modalHeading = this.resourceBundle.selectContactMethod;
  }

  public closeModal() {
    this.closeClick.emit();
    this.resetPasswordService.currentStep = StepNum.step1;
    this.resetPasswordService.modalHeading = this.resourceBundle.forgotPassword;
  }

  public async continueIdScan() {
    this.hasScanIdAttempts = false;
    this.showturnOffButtonSpinner = true;
    const reqBody: VerifyAuthenticIdRequest = {
      authenticid: this.resetPasswordService.authenticid,
      userEmail: this.resetPasswordService.userEmail,
    };
    this.resetPasswordService.resetPwdMsg.displayMessage.show=false;
    try {
      const data = await this.resetPasswordService.verifyAuthenticId(reqBody);
      const encryptedResult = data?.result;
      const decryptedResult = this.cbHelperService.decryptWithSaltandPadding(encryptedResult);
      const values = decryptedResult.split(';');
      const successOrFailure = values[1];
      const emailAddress = values[2];
      if(data.code === '0' && successOrFailure === 'Success' && emailAddress === this.resetPasswordService.userEmail) {
        this.showturnOffButtonSpinner = false;
        this.resetPasswordService.currentStep = StepNum.step4;
        this.resetPasswordService.modalHeading = this.resourceBundle.resetPasswordModalTitle;
      } else if (data.code === '1006') {
        this.showturnOffButtonSpinner = false;
        this.resetPasswordService.isIdScanFailed = true;
        this.idScanDescription = this.resourceBundle.idScanFailedDescription;
        this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.idScanFailed, 'error', 'resetPwdError');
        this.resetPasswordService.modalScroll();
        document.getElementById('closeBtn').focus();
      } else if (data.code === '1002') {
        this.showturnOffButtonSpinner = false;
        this.resetPasswordService.isIdScanFailed = true;
        this.hasScanIdAttempts = true;
        this.idScanDescription = this.resourceBundle.idScanFailedDescriptionAdmin;
        this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.idScanFailed, 'error', 'resetPwdError');
        this.resetPasswordService.modalScroll();
        document.getElementById('closeBtn').focus();
      } else {
        this.showturnOffButtonSpinner = false;
        this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.idScanError, 'error', 'resetPwdError');
        this.resetPasswordService.modalScroll();
        document.getElementById('closeBtn').focus();
      }
    } catch(error) {
      this.showturnOffButtonSpinner = false;
      this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.idScanError,
        'error', 'resetPwdError');
      this.resetPasswordService.modalScroll();
      document.getElementById('closeBtn').focus();
    }
  }

}
