import { DatePipe } from '@angular/common';
import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input,
  OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CBLoaderService } from 'common-ui-lib/lib/shared/cbloader/cbloader.service';
import { LocalStorageService } from 'ngx-localstorage';
import { NgxRolesService } from 'ngx-permissions';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppErrorMessageService } from '../../services/app-error-message.service';
import { CBHelperService } from '../../services/cbhelper.service';
import { CreateUserCommonService } from '../../services/createUserCommon.service';
import { EditUserService } from '../../services/edituser.service';
import { Message } from '../messages/message.component';
import { VoiceGlobals } from '../models/globals/voice.globals';
import { CreateUserRequest } from '../models/userprofile/createUserCommon.model';
import {
  AddScheduleReqObj, EventDetailsList, RecurDailyEvent, RecurMonthlyEvent, RecurWeeklyEvent, RecurYearlyEvent,
  ScheduleEntryList,
} from './scheduleform.model';
import { SchedulesService } from './scheduleform.service';
import { AppConfig } from '../../core/app.config';
import { mergeMap } from 'rxjs/operators';
import { LocalStorageKeys } from 'common-ui-lib';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './scheduleform.component.html',
  styleUrls: ['./scheduleforms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleFormComponent implements AfterViewInit, OnInit, OnDestroy {
  starttime: any;
  endtime: any;
  curDate = new Date();
  startDate: Date;
  endDate: Date;
  endRecurDate: Date;
  minDate: Date = new Date();
  recurtype = 'never';
  endtype = 'never';
  alldayselected = false;
  isSchNew = true;
  isEventEdit = false;
  existingSchedule = '';
  schedulelist: ScheduleEntryList[] = [];
  scheduleListGrp: ScheduleEntryList[] = [];
  scheduleListPersonal: ScheduleEntryList[] = [];
  holidayScheduleListGrp: ScheduleEntryList[] = [];
  holidayScheduleListPersonal: ScheduleEntryList[] = [];
  newSchduleName: string;
  eventName: string;
  schType: string;
  accessType: string;
  dailyoccurence: string;
  monthlyoccurence: string;
  monthlysel: string;
  monthday: string;
  dayOfWeek: string;
  dayOfWeekInMonth: string;
  weeklyoccurence: string;
  curDateErr = false;
  weekdays = [
    { key: 'sunday', value: 'S', state: false },
    { key: 'monday', value: 'M', state: false },
    { key: 'tuesday', value: 'T', state: false },
    { key: 'wednesday', value: 'W', state: false },
    { key: 'thursday', value: 'T', state: false },
    { key: 'friday', value: 'F', state: false },
    { key: 'saturday', value: 'S', state: false },
  ];
  atLeastOnePropIsTrue = false;
  sun: string;
  mon: string;
  tue: string;
  wed: string;
  thu: string;
  fri: string;
  sat: string;
  yearlyoccurence: string;
  yearlysel: string;
  yearlydayoccur: string;
  yearlydaysel: string;
  yearlymonthsel: string;
  yearlyweeksel: string;
  yearlymonsel: string;
  endRecurVal: string;
  errMsg: Message;
  isAnotherAdded = false;
  dtErrMsg = false;
  disableSave = false;
  startMeridian = 'am';
  endMeridian = 'am';
  editingSchName: string;
  editingAccess: string;
  editingType: string;
  oldEventName: string;
  isPersonalDisabled = false;
  showType = false;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dayErrMsg = false;
  provisionUser: string;
  jwtHelper: JwtHelperService = new JwtHelperService();
  phoneNumber: string;
  userId: string;
  createUserReqBody: CreateUserRequest;
  setHeader: boolean;
  getSchtype: string;
  private onDestroy$ = new Subject<boolean>();
  loggedInUserRole: string;

  constructor(private schedulesService: SchedulesService,
              private spinnerService: CBLoaderService, 
              private _storageService: LocalStorageService,
              private datePipe: DatePipe, private cdr: ChangeDetectorRef,
              private rolesService: NgxRolesService, private globals: VoiceGlobals,
              private appError: AppErrorMessageService,
              private cbHelper: CBHelperService,
              private sessionStorage: SessionStorageService,
              private createUserService: CreateUserCommonService,
              public router: Router,
              private editUser: EditUserService,
              private config: AppConfig) {
    this.errMsg = new Message();
  }
  @Input() schData: any;
  @Input() hideLink: boolean;
  @Input() userTn: boolean;
  @Input() onlyGroup = false;
  @Output() modalClose = new EventEmitter();
  @ViewChild('scheduleForm') public scheduleForm: NgForm;

  static replacer(key: any, value: any) {
    if (value === null) {
      return undefined;
    }
    return value;
  }

  ngOnInit() {
    // initialze default values
    this.startDate = new Date();
    this.endDate = new Date();
    this.dailyoccurence = '1';
    this.weeklyoccurence = '1';
    this.monthlyoccurence = '1';
    this.yearlyoccurence = '1';

    // if (this.rolesService.getRole('FONEADMIN_WITH_PHONE') || this.rolesService.getRole('FONEADMIN_NO_PHONE')
    //   || this.rolesService.getRole('END_USER_WITH_PHONE') || this.rolesService.getRole('END_USER_NO_PHONE')) {
    //   this.hideLink = true;
    // } else {
    //   this.hideLink = false;
    // }
    // if (this.rolesService.getRole('ADMIN_WITH_PHONE') || this.rolesService.getRole('FONEADMIN_WITH_PHONE')
    //   || this.rolesService.getRole('END_USER_WITH_PHONE')) {
    //   this.userTn = true;
    // } else {
    //   this.userTn = false;
    // }
    // Schedule Modal different mode switch-case
    if (this.editUser.isEditingUser() && !this.router.url.includes('schedules')) {
      if (this.rolesService.getRole('FONEADMIN_WITH_PHONE') || this.rolesService.getRole('FONEADMIN_NO_PHONE')
        || this.rolesService.getRole('END_USER_WITH_PHONE') || this.rolesService.getRole('END_USER_NO_PHONE')) {
        this.hideLink = true;
      } else {
        this.hideLink = false;
      }
      if (this.rolesService.getRole('ADMIN_WITH_PHONE') || this.rolesService.getRole('FONEADMIN_WITH_PHONE')
        || this.rolesService.getRole('END_USER_WITH_PHONE')) {
        this.userTn = true;
      } else {
        this.userTn = false;
      }
    } else {
      const _parsedToken = this.cbHelper.getLoggedInUserDetails();
      const isFoneAdmin = _parsedToken.profile.userToken.foneAdmin;
      const userType = _parsedToken.profile.userToken.userType;
      const assignedPhoneNumber = _parsedToken.profile.userToken.assignedPhoneNumber;
      if (assignedPhoneNumber) {
        this.loggedInUserRole = 'WITH_PHONE';
      } else {
        this.loggedInUserRole = 'NO_PHONE';
      }
      if (userType === 'PROFILE_ADMIN' || userType === 'PROFILE_OWNER') {
        this.loggedInUserRole = 'ADMIN_' + this.loggedInUserRole;
      } else if (userType === 'END_USER') {
        if (isFoneAdmin) {
          this.loggedInUserRole = 'FONEADMIN_' + this.loggedInUserRole;
        } else {
          this.loggedInUserRole = 'END_USER_' + this.loggedInUserRole;
        }
      }
      if (this.loggedInUserRole === 'FONEADMIN_WITH_PHONE' || this.loggedInUserRole === 'FONEADMIN_NO_PHONE'
        || this.loggedInUserRole === 'END_USER_WITH_PHONE' || this.loggedInUserRole === 'END_USER_NO_PHONE') {
        this.hideLink = true;
      } else {
        this.hideLink = false;
      }
      if (this.loggedInUserRole === 'ADMIN_WITH_PHONE' || this.loggedInUserRole === 'FONEADMIN_WITH_PHONE'
        || this.loggedInUserRole === 'END_USER_WITH_PHONE') {
        this.userTn = true;
      } else {
        this.userTn = false;
      }
    }
    switch (this.schData.mode) {
      case 'add-event-group':
        this.isSchNew = false;
        this.isEventEdit = false;
        this.existingSchedule = this.schData.scheduleName;
        const eventIndex = this.schedulelist.map((x: any) => x.scheduleName).indexOf(this.schData.scheduleName);
        this.accessType = this.schData.scheduleAccess;
        this.schType = this.schData.scheduleType;
        this.starttime = null;
        break;
      case 'edit-event':
        this.editingSchName = '';
        this.oldEventName = '';
        this.editingAccess = '';
        this.editingType = '';
        this.isSchNew = false;
        this.isEventEdit = true;
        this.disableSave = false;
        this.editingSchName = this.schData.scheduleName;
        this.editingAccess = this.schData.eventAccess;
        this.editingType = this.schData.eventType;
        this.oldEventName = this.schData.eventName;
        this.eventName = this.schData.eventName;
        this.startDate = new Date(this.schData.startDate);
        this.endDate = new Date(this.schData.endDate);
        if (!this.schData.allDayEvent) {
          // start date
          const stTime = this.schData.startTime.split(' ')[0];
          const stMeridian = this.schData.startTime.split(' ')[1];
          this.starttime = { hour: parseInt(stTime.split(':')[0]), minute: parseInt(stTime.split(':')[1])};
          this.startMeridian = stMeridian.toLowerCase();
          // end date
          const endTime = this.schData.endTime.split(' ')[0];
          const endMeridian = this.schData.endTime.split(' ')[1];
          this.endtime = { hour: parseInt(endTime.split(':')[0]), minute: parseInt(endTime.split(':')[1]) };
          this.endMeridian = endMeridian.toLowerCase();
        } else {
          // all day
          this.alldayselected = this.schData.allDayEvent;
          this.startMeridian = 'am';
          this.endMeridian = 'am';
        }
        // recur
        if (this.schData.dailyRecurrence) {
          this.recurtype = 'daily';
          this.dailyoccurence = this.schData.dailyRecurrence.dailyrecurInterval;
        } else if (this.schData.weeklyRecurrence) {
          this.recurtype = 'weekly';
          this.weeklyoccurence = this.schData.weeklyRecurrence.weeklyRecurInterval;
          // tslint:disable-next-line: no-ignored-return
          this.weekdays.map((index) => {
            if (index.key === 'sunday') {
              index.state = this.schData.weeklyRecurrence.sunday;
            } else if (index.key === 'monday') {
              index.state = this.schData.weeklyRecurrence.monday;
            } else if (index.key === 'tuesday') {
              index.state = this.schData.weeklyRecurrence.tuesday;
            } else if (index.key === 'wednesday') {
              index.state = this.schData.weeklyRecurrence.wednesday;
            } else if (index.key === 'thursday') {
              index.state = this.schData.weeklyRecurrence.thursday;
            } else if (index.key === 'friday') {
              index.state = this.schData.weeklyRecurrence.friday;
            } else if (index.key === 'saturday') {
              index.state = this.schData.weeklyRecurrence.saturday;
            }
          });
          this.atLeastOnePropIsTrue = this.weekdays.find((a) => a.state === true) != null;
        } else if (this.schData.monthlyRecurrence) {
          this.recurtype = 'monthly';
          this.monthlyoccurence = this.schData.monthlyRecurrence.monthlyRecurInterval;
          if (this.schData.monthlyRecurrence.dayOfMonth && this.schData.monthlyRecurrence.dayOfMonth !== 0) {
            this.monthlysel = 'monthday';
            this.monthday = this.schData.monthlyRecurrence.dayOfMonth;
          } else {
            this.monthlysel = 'monthweek';
            this.dayOfWeek = this.schData.monthlyRecurrence.dayOfWeek;
            this.dayOfWeekInMonth = this.schData.monthlyRecurrence.dayOfWeekInMonth;
          }
        } else if (this.schData.yearlyRecurrence) {
          this.recurtype = 'yearly';
          this.yearlyoccurence = this.schData.yearlyRecurrence.yearlyRecurInterval;
          if (this.schData.yearlyRecurrence.dayOfMonth && this.schData.yearlyRecurrence.dayOfMonth !== 0) {
            this.yearlysel = 'yearday';
            this.yearlydayoccur = this.schData.yearlyRecurrence.dayOfMonth;
            this.yearlymonthsel = this.schData.yearlyRecurrence.dayOfMonthName;
          } else {
            this.yearlysel = 'yearweek';
            this.yearlydaysel = this.schData.yearlyRecurrence.dayOfWeekInMonth;
            this.yearlyweeksel = this.schData.yearlyRecurrence.dayOfWeek;
            this.yearlymonsel = this.schData.yearlyRecurrence.dayOfWeekInMonthName;
          }
        } else {
          this.recurtype = 'never';
        }

        if (!this.schData.recurForEver) {
          if (this.schData.recurEndOccurrence && this.schData.recurEndOccurrence !== 0) {
            this.endtype = 'after';
            this.endRecurVal = this.schData.recurEndOccurrence;
          } else if (this.schData.recurEndDate) {
            this.endtype = 'date';
            this.endRecurDate = new Date(this.schData.recurEndDate);
          }
        } else {
          this.endtype = 'never';
        }

        break;
      case 'add-holiday-schedule':
        this.isSchNew = true;
        this.isEventEdit = false;
        if (this.hideLink && this.userTn) {
          this.accessType = 'User';
        } else {
          this.accessType = 'Group';
        }
        this.schType = 'Holiday';
        this.showType = true;
        break;
      case 'add-regular-schedule':
        this.isSchNew = true;
        this.isEventEdit = false;
        if (this.hideLink && this.userTn) {
          this.accessType = 'User';
        } else {
          this.accessType = 'Group';
        }
        this.schType = 'Time';
        this.showType = true;
        break;
      default:
        this.isSchNew = true;
        this.isEventEdit = false;
        if (this.hideLink && this.userTn) {
          this.accessType = 'User';
        } else {
          this.accessType = 'Group';
        }
        this.schType = 'Time';
    }
  }

  ngAfterViewInit() {
    this.spinnerService.show();
    const role = this.rolesService.getRoles();
    if (this.rolesService.getRole('ADMIN_NO_PHONE') || this.rolesService.getRole('FONEADMIN_NO_PHONE')) {
      this.getSchtype = 'group';
    } else if (this.rolesService.getRole('END_USER_WITH_PHONE')) {
      this.getSchtype = 'user';
    }
    // if (!this.cbHelper.isEditingUserSameasLoggedInUser() && this.editUser.isEditingUser()) {
    //   this.getSchtype = 'group';
    // }
    this.schedulesService.getSchedulesDetails(this.getSchtype)
      .pipe(finalize(() => { 
        this.spinnerService.hide();
       }))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data) => {
          this.setScheduleList(data);
        },
        (error) => {
        },
      );
  }

  onClickFocus(){
    document.getElementById('closeButton').focus();
  }

  setScheduleList(data: any) {
    this.schedulelist = data.scheduleEntryList;
    if (this.schedulelist) {
      this.scheduleListGrp = [];
      this.scheduleListPersonal = [];
      this.holidayScheduleListGrp = [];
      this.holidayScheduleListPersonal = [];
      // tslint:disable-next-line: no-ignored-return
      this.schedulelist.map((obj: any) => {
        if (obj['scheduleType'].toLowerCase() === 'time') {
          if (obj['scheduleAccess'] === 'Group') {
            this.scheduleListGrp.push(obj);
          } else if (obj['scheduleAccess'] === 'User') {
            this.scheduleListPersonal.push(obj);
          }
        } else {
          if (obj['scheduleAccess'] === 'Group') {
            this.holidayScheduleListGrp.push(obj);
          } else if (obj['scheduleAccess'] === 'User') {
            this.holidayScheduleListPersonal.push(obj);
          }
        }
      });
    }
  }

  onWeeklyChange() {
    this.atLeastOnePropIsTrue = this.weekdays.find((a) => a.state === true) != null;
  }

  changeSchdule(schedule: any) {
    const eventIndex = this.schedulelist.map((x: any) => x.scheduleName).indexOf(schedule);
    this.accessType = this.schedulelist[eventIndex]['scheduleAccess'];
    this.schType = this.schedulelist[eventIndex]['scheduleType'];
  }
  saveScheduleCheck(openAnother: boolean, startTimeEvent: any, endTimeEvent: any) {
    const modalWindow = document.querySelectorAll('ngb-modal-window');
    if (modalWindow && modalWindow.length > 0) {
      modalWindow[0].scrollTop = 0;
    }
    this.provisionUser = this.sessionStorage.retrieve('provisionUser');
    if (this.isSchNew && this.accessType === 'User' && this.provisionUser === 'routeListMember') {
      this.setHeader = true;
      const userToken = this._storageService.get(LocalStorageKeys.UserToken, 'cbma');
      if (userToken) {
        const decodedToken = this.jwtHelper.decodeToken(userToken);
        const userProfile = JSON.parse(decodedToken.profile);
        this.phoneNumber = userProfile.userToken.phoneNumber;
        this.userId = userProfile.userToken.email;
        this.createUserReqBody = new CreateUserRequest();
        this.createUserReqBody.userEmail = this.userId;
        this.createUserReqBody.phoneNumber = this.phoneNumber.toString().trim().replace(/-/g, '');
        this.createUserService.createuser(this.createUserReqBody, this.setHeader)
          .pipe(finalize(() => { 
            this.spinnerService.hide(); 
          }))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (data) => {
              if (data && data.code.toString() === '0') {
                if (data.provisioningUserType && data.provisioningUserType === 'standardUserExisted') {
                  this.sessionStorage.store('provisionUser', data.provisioningUserType);
                  this.spinnerService.hide();
                  this.errMsg.show = true;
                  this.errMsg.type = 'error';
                  this.errMsg.level = 'global';
                  this.errMsg.message =
                    'These settings have already been modified, please try again.';
                  window.scroll(0, 0);
                  return;
                } else {
                  this.sessionStorage.store('provisionUser', data.provisioningUserType);
                  this.saveSchedule(openAnother);
                }
              }
            }, (error: any) => {
              this.spinnerService.hide();
              this.errMsg.show = true;
              this.errMsg.type = 'error';
              this.errMsg.level = 'global';
              this.errMsg.message = 'We\'re sorry, we can\'t find this part of the page right now.';
              window.scroll(0, 0);
            },
          );
      }
    } else {
      this.saveSchedule(openAnother, startTimeEvent, endTimeEvent);
    }
  }
  
  saveSchedule(openAnother: boolean = false, startTimeEvent?: any, endTimeEvent?: any) {
    const form = this.scheduleForm;
    if (!form.valid) {
      Object.keys(form.controls).forEach((control) => {
        form.controls[control].markAsDirty();
      });
      this.errMsg.show = true;
      this.errMsg.type = 'error';
      this.errMsg.message = this.config.getMessage('formGenericMessage');
      this.setTimeErrorCss();
      if (this.scheduleForm.controls.newSchduleName !== undefined) {
        if (this.scheduleForm.controls.newSchduleName.invalid === true) {
          var element = document.getElementById("newSchduleNameLabel");
          element.classList.add("labelRedColor");
        }
      }
      if (this.scheduleForm.controls.existingSchedule !== undefined) {
        if (this.scheduleForm.controls.existingSchedule.invalid === true) {
          var element = document.getElementById("existingScheduleLabel");
          element.classList.add("labelRedColor");
        }
      }
      
      if (this.scheduleForm.controls.eventName.invalid === true) {
        var element = document.getElementById("eventNameLabel");
        element.classList.add("labelRedColor");
      }
      if (this.scheduleForm.controls.starttime.invalid === true) {
        var element = document.getElementById("startDateLabel");
        element.classList.add("labelRedColor");
      }
      if (this.scheduleForm.controls.endtime.invalid === true) {
        var element = document.getElementById("endDateLabel");
        element.classList.add("labelRedColor");
      }
      return Observable.of('INVALID_FORM');
    }
    if(openAnother){
      startTimeEvent = startTimeEvent?.firstChild?.firstChild?.firstChild;
      startTimeEvent?.children[0]?.children[0]?.classList?.remove('error');
      startTimeEvent?.children[2]?.children[0]?.classList?.remove('error');
      endTimeEvent = endTimeEvent?.firstChild?.firstChild?.firstChild;
      endTimeEvent?.children[0]?.children[0]?.classList?.remove('error');
      endTimeEvent?.children[2]?.children[0]?.classList?.remove('error');
    }
    this.spinnerService.show();
    // new schedule
    const bwObj = this.editUser.getBWObj();
    const newSchObj = new AddScheduleReqObj();

    const newSchedule = new ScheduleEntryList();
    if (this.isSchNew) {
      newSchedule.scheduleName = form.value.newSchduleName;
      newSchedule.scheduleType = this.schType;
      newSchedule.scheduleAccess = this.accessType;
    } else {
      newSchedule.scheduleName = form.value.existingSchedule;
      newSchedule.scheduleType = this.schType;
      newSchedule.scheduleAccess = this.accessType;
    }

    const newEvent = new EventDetailsList();
    if (this.isEventEdit) {
      newSchedule.scheduleName = this.editingSchName;
      newSchedule.scheduleType = this.editingType;
      newSchedule.scheduleAccess = this.editingAccess;
      newEvent.newEventName = form.value.eventName;
      newEvent.eventName = this.oldEventName;
    } else {
      newEvent.eventName = form.value.eventName;
    }
    newEvent.startDate = this.datePipe.transform(form.value.startDate, 'MM/dd/yyyy ') + this.globals.timeZone;
    newEvent.startTime = null;
    newEvent.endDate = this.datePipe.transform(form.value.endDate, 'MM/dd/yyyy ') + this.globals.timeZone;
    newEvent.endTime = null;

    if (form.value.alldayselected) {
      newEvent.allDayEvent = true;
    } else {
      newEvent.allDayEvent = false;
      const _stTime = new Date(form.value.startDate)
        .setHours(this.getTimein24HrFormat(this.starttime['hour'], this.startMeridian), this.starttime['minute']);
      const _endTime = new Date(form.value.endDate)
        .setHours(this.getTimein24HrFormat(this.endtime['hour'], this.endMeridian), this.endtime['minute']);
      newEvent.startTime = this.datePipe.transform(_stTime, 'shortTime');
      newEvent.endTime = this.datePipe.transform(_endTime, 'shortTime');
    }

    switch (form.value.recurtype) {
      case 'daily': {
        const _daily = new RecurDailyEvent();
        _daily.dailyrecurInterval = form.value.dailyoccurence;
        newEvent.dailyRecurrence = _daily;
        break;
      }
      case 'weekly': {
        const _weekly = new RecurWeeklyEvent();
        _weekly.weeklyRecurInterval = form.value.weeklyoccurence;
        _weekly.monday = form.value.monday ? form.value.monday : false;
        _weekly.tuesday = form.value.tuesday ? form.value.tuesday : false;
        _weekly.wednesday = form.value.wednesday ? form.value.wednesday : false;
        _weekly.thursday = form.value.thursday ? form.value.thursday : false;
        _weekly.friday = form.value.friday ? form.value.friday : false;
        _weekly.saturday = form.value.saturday ? form.value.saturday : false;
        _weekly.sunday = form.value.sunday ? form.value.sunday : false;
        newEvent.weeklyRecurrence = _weekly;
        break;
      }
      case 'monthly': {
        const _monthly = new RecurMonthlyEvent();
        _monthly.monthlyRecurInterval = form.value.monthlyoccurence;
        _monthly.dayOfMonth = null;
        _monthly.dayOfWeek = null;
        _monthly.dayOfWeekInMonth = null;

        if (form.value.monthlysel === 'monthday') {
          _monthly.dayOfMonth = form.value.monthday;
        } else if (form.value.monthlysel === 'monthweek') {
          _monthly.dayOfWeek = form.value.dayOfWeek;
          _monthly.dayOfWeekInMonth = form.value.dayOfWeekInMonth;
        }
        newEvent.monthlyRecurrence = _monthly;
        break;
      }
      case 'yearly': {
        const _yearly = new RecurYearlyEvent();
        _yearly.yearlyRecurInterval = form.value.yearlyoccurence;
        _yearly.dayOfWeekInMonth = null;
        _yearly.dayOfWeek = null;
        _yearly.dayOfMonth = null;
        _yearly.dayOfMonthName = null;
        _yearly.dayOfWeekInMonthName = null;
        if (form.value.yearlysel === 'yearday') {
          _yearly.dayOfMonth = form.value.yearlydayoccur;
          _yearly.dayOfMonthName = form.value.yearlymonthsel;
        } else if (form.value.yearlysel === 'yearweek') {
          _yearly.dayOfWeek = form.value.yearlyweeksel;
          _yearly.dayOfWeekInMonth = form.value.yearlydaysel;
          _yearly.dayOfWeekInMonthName = form.value.yearlymonsel;
        }
        newEvent.yearlyRecurrence = _yearly;
        break;
      }
      default: {
        newEvent.dailyRecurrence = null;
        newEvent.weeklyRecurrence = null;
        newEvent.monthlyRecurrence = null;
        newEvent.yearlyRecurrence = null;
        break;
      }
    }

    if (form.value.endtype === 'date') {
      newEvent.recurForEver = false;
      newEvent.recurEndDate = this.datePipe.transform(form.value.endRecurDate, 'MM/dd/yyyy Z');
    } else if (form.value.endtype === 'after') {
      newEvent.recurForEver = false;
      newEvent.recurEndOccurrence = form.value.endRecurVal;
    } else if (form.value.endtype === 'never') {
      newEvent.recurForEver = true;
      newEvent.recurEndDate = null;
      newEvent.recurEndOccurrence = null;
    }

    if (this.isSchNew) {
      newSchObj.newSchedule = true;
    } else if (!this.isEventEdit) {
      newSchObj.newSchedule = false;
    }

    newSchObj.eventDetails = newEvent;
    newSchObj.scheduleDetails = newSchedule;
    newSchObj.id = bwObj.id;

    const _finalSchObj = JSON.stringify(newSchObj, ScheduleFormComponent.replacer);

    let saveScheduleUpdateReq = this.schedulesService.updateEvent(newSchObj);
    if (openAnother) {
      saveScheduleUpdateReq = this.schedulesService.updateEvent(newSchObj)
      .pipe(mergeMap((resp) =>
        this.schedulesService.getSchedulesDetails(this.getSchtype)));
    }
    if (this.isEventEdit) {
      saveScheduleUpdateReq.pipe(finalize(() => { 
        this.spinnerService.hide(); 
      }))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          if (data.code === '0') {
            if (openAnother) {
              this.setScheduleList(data);
              this.errMsg.show = true;
              this.errMsg.type = 'success';
              this.errMsg.message
                = 'The schedule was saved successfully.';
              if (this.hideLink && this.userTn) {
                  this.scheduleForm.reset({
                    accessType: 'User',
                    schType: 'Time',
                    recurtype: 'never',
                  });
                } else {
                  this.scheduleForm.reset({
                    accessType: 'Group',
                    schType: 'Time',
                    recurtype: 'never',
                  });
                }
            } else {
              const _successSch: any = newSchedule;
              if (this.isSchNew) {
                _successSch.isNewSchedule = true;
              } else {
                _successSch.isNewSchedule = false;
              }
              _successSch.result = 'SUCCESS_CLOSE';
              this.modalClose.emit(_successSch);
            }
          } else {
            this.errMsg.show = true;
            this.errMsg.type = 'error';
            this.errMsg.message
              = 'We\'re sorry, an error occurred while updating your event. Please try again.';
          }
        },
          (error) => {
            this.errMsg.show = true;
            this.errMsg.type = 'error';
            this.appError.getErrorMessage('broadsoft', error.toString())
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((data) => {
                if (data) {
                  this.errMsg.message = data;
                } else {
                  this.errMsg.message
                    = 'We\'re sorry, an error occurred while updating your event. Please try again.';
                }
              });
          },
        );
    } else {
      let saveScheduleReq = this.schedulesService.saveSchedules(newSchObj);
      if (openAnother) {
        saveScheduleReq = this.schedulesService.saveSchedules(newSchObj).pipe(mergeMap((resp) =>
          this.schedulesService.getSchedulesDetails(this.getSchtype)));
      }
      saveScheduleReq.pipe(finalize(() => { 
        this.spinnerService.hide();
       }))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          if (data.code === '0') {
            if (openAnother) {
              this.startDate = null;
              this.endDate = null;
              document.getElementById("newSchduleNameLabel")?.classList?.remove('labelRedColor');
              document.getElementById("eventNameLabel")?.classList?.remove('labelRedColor');
              document.getElementById("startDateLabel")?.classList?.remove('labelRedColor');
              document.getElementById("endDateLabel")?.classList?.remove('labelRedColor');
              this.setScheduleList(data);
              this.errMsg.show = true;
              this.errMsg.type = 'success';
              this.errMsg.message
                = 'The schedule was saved successfully.';
              if (this.hideLink && this.userTn) {
              this.scheduleForm.reset({
                accessType: 'User',
                schType: 'Time',
                startMeridian: 'am',
                endMeridian: 'am',
                recurtype: 'never',
              });
             } else {
              this.scheduleForm.reset({
                accessType: 'Group',
                schType: 'Time',
                startMeridian: 'am',
                endMeridian: 'am',
                recurtype: 'never',
              });
             }
            } else {
              const _successSch: any = newSchedule;
              if (this.isSchNew) {
                _successSch.isNewSchedule = true;
              } else {
                _successSch.isNewSchedule = false;
              }
              _successSch.result = 'SUCCESS_CLOSE';
              this.modalClose.emit(_successSch);
            }
          } else {
            this.errMsg.show = true;
            this.errMsg.type = 'error';
            this.errMsg.message
              = 'We\'re sorry, an error occurred while updating your Schedule. Please try again.';
          }
        },
          (error) => {
            this.errMsg.show = true;
            this.errMsg.type = 'error';
            this.appError.getErrorMessage('broadsoft', error.toString())
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((data) => {
                if (data) {
                  this.errMsg.message = data;
                } else {
                  this.errMsg.message
                    = 'We\'re sorry, an error occurred while updating your Schedule. Please try again.';
                }
              });
          },
        );
    }
  }

  closeModal() {
    this.modalClose.emit('close');
  }

  toggleMeridian(event: any, meridian: string) {
    if (meridian === 'start') {
      this.startMeridian = event;
    } else {
      this.endMeridian = event;
    }
    this.dateModelChanged(event);
  }

  onValueChange(value: Date): void {
    this.endDate = value;
  }
  getTimein24HrFormat(time: any, meridian: string) {
    let hours = time;
    if (hours === '12') {
      hours = '00';
    }
    if (meridian === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return hours;
  }
  
  dateModelChanged(event: any) {
    if ((this.startDate || this.startDate==null) && this.endDate) {
    const _startDate = new Date(this.startDate).setHours(0, 0, 0, 0);
    const _curdate = new Date(this.curDate).setHours(0, 0, 0, 0);
    if (_startDate < _curdate) {
      this.curDateErr = true;
      this.disableSave = true;
    } else {
      this.curDateErr = false;
      this.disableSave = false;
    }
    if (this.alldayselected) {
        const _stTime = new Date(this.startDate);
        const _endTime = new Date(this.endDate);
        if (_endTime < _stTime) {
            this.dtErrMsg = true;
            this.disableSave = true;
        } else {
          this.dtErrMsg = false;
          this.disableSave = false;
        }
      } else {
        this.setTimeErrorCss();
        if (this.starttime && this.endtime) {
          const _stTime = new Date(this.startDate)
            .setHours(this.getTimein24HrFormat(this.starttime['hour'], this.startMeridian), this.starttime['minute']);
          const _endTime = new Date(this.endDate)
            .setHours(this.getTimein24HrFormat(this.endtime['hour'], this.endMeridian), this.endtime['minute']);
          if (_endTime <= _stTime) {
            this.dtErrMsg = true;
            this.disableSave = true;
          } else {
            this.dtErrMsg = false;
            this.disableSave = false;
          }
          if (this.starttime['hour'] > 12) {
            this.starttime['hour'] = parseInt(this.starttime['hour'], 10) - 12;
          }
          if (this.endtime['hour'] > 12) {
            this.endtime['hour'] = parseInt(this.endtime['hour'], 10) - 12;
          }
        }
      }
    if (this.recurtype === 'weekly') {
        this.setDayOfWeek(true);
      } else if (this.recurtype === 'monthly') {
        if (this.monthlysel === 'monthweek') {
          this.setMonthlyTheOccurences();
        } else {
          this.setMonthlyDayOccurences();
        }
      } else if (this.recurtype === 'yearly') {
        if (this.yearlysel === 'yearday') {
          this.setYearlyDayOccurences();
        } else {
          this.setYearlyTheOccurences();
        }
      }
    if (this.dtErrMsg || this.curDateErr) {
      this.disableSave = true;
    }
  }
}

  changeRecur(recurtype: any) {
    if (this.recurtype === 'weekly') {
      this.setDayOfWeek(true);
    } else if (this.recurtype === 'monthly') {
      this.monthlysel = 'monthday';
      this.setDayOfMonth();
    } else if (this.recurtype === 'yearly') {
      this.yearlysel = 'yearday';
      this.setYearlyDayOccurences();
    }
  }
  setMonthlyDayOccurences() {
    // clear The field
    this.dayOfWeekInMonth = '';
    this.dayOfWeek = '';

    this.setDayOfMonth();
  }
  setMonthlyTheOccurences() {
    // clear Day field
    this.monthday = '';

    this.setDayOfWeekInMonth('month');
    this.setDayOfWeek(false, 'month');
  }
  setYearlyDayOccurences() {
    // clear The field
    this.yearlydaysel = '';
    this.yearlyweeksel = '';
    this.yearlymonsel = '';

    this.setYear('day');
    if (this.startDate) {
      this.yearlydayoccur = this.startDate.getDate().toString();
    }
  }
  setYearlyTheOccurences() {
    // clear Day fields
    this.yearlydayoccur = '';
    this.yearlymonthsel = '';

    this.setDayOfWeekInMonth('year');
    this.setDayOfWeek(false, 'year');
    this.setYear('the');
  }

  /**
   * Set day of week for occurences weekly, monthly and yearly
   * @param checkbox
   * @param type
   */
  setDayOfWeek(checkbox?: boolean, type?: any) {
    if (this.startDate) {
      if (checkbox) {
        for (let count = 0; count < this.weekdays.length; count++) {
          this.weekdays[count].state = false;
        }
        const dayOfweek = this.startDate.getDay();
        this.weekdays[dayOfweek].state = true;
        this.atLeastOnePropIsTrue = true;
      } else {
        if ('month' === type) {
          this.dayOfWeek = this.days[this.startDate.getDay()];
        } else {
          this.yearlyweeksel = this.days[this.startDate.getDay()];
        }
      }
    }
  }
  setDayOfMonth() {
    if (this.startDate) {
      this.monthday = this.startDate.getDate().toString();
    }
  }

  /**
   * Set day of week in month
   * @param type
   */
  setDayOfWeekInMonth(type: string) {
    if (this.startDate) {
      const dayOfWeekInMonthList = ['First', 'Second', 'Third', 'Fourth', 'Last'];
      const floatValue = this.startDate.getDate() / 7;
      const weekOfMonth = Math.ceil(floatValue);
      if ('month' === type) {
        this.dayOfWeekInMonth = dayOfWeekInMonthList[weekOfMonth - 1];
      } else {
        this.yearlydaysel = dayOfWeekInMonthList[weekOfMonth - 1];
      }
    }
  }

  /**
   * Set year of start date
   * @param type
   */
  setYear(type: string) {
    if (this.startDate) {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December'];
      if ('day' === type) {
        this.yearlymonthsel = monthNames[this.startDate.getMonth()];
      } else {
        this.yearlymonsel = monthNames[this.startDate.getMonth()];
      }
    }
  }

  setTimeErrorCss() {
    const stH = document.querySelector('ngb-timepicker[name="starttime"] input[aria-label="Hours"]');
    const stM = document.querySelector('ngb-timepicker[name="starttime"] input[aria-label="Minutes"]');
    if (!this.starttime) {
      stH.classList.add('error');
      stM.classList.add('error');
    } else {
      stH.classList.remove('error');
      stM.classList.remove('error');
    }

    const etH = document.querySelector('ngb-timepicker[name="endtime"] input[aria-label="Hours"]');
    const etM = document.querySelector('ngb-timepicker[name="endtime"] input[aria-label="Minutes"]');
    if (!this.endtime) {
      etH.classList.add('error');
      etM.classList.add('error');
    } else {
      etH.classList.remove('error');
      etM.classList.remove('error');
    }
  }
  public restrictNumeric(day: string) {
    if ((parseInt(day) < 1) || (parseInt(day) > 31)) {
      this.dayErrMsg = true;
    } else {
      this.dayErrMsg = false;
    }

  }
  inputName(event, fieldName){
    const HTML_SPL_CHAR_REGEX = /[<>'"%;)(&+]/g;
    if (event?.target?.value) {
      event.target.value = event.target.value.replace(HTML_SPL_CHAR_REGEX, '');
      this.scheduleForm.controls[fieldName].setValue(event.target.value);
    }
  }
  onKeyDown($event: any, key: string) {
    if (($event.code === 'ArrowLeft') || ($event.code === 'ArrowRight') ||
      ($event.code === 'ArrowUp') || ($event.code === 'ArrowDown')) {
      return;
    } else {
      this.scheduleForm.controls[key].markAsDirty();
    }
  }
  public getMyStyles() {
    if (this.editUser.isEditingUser()) {
      if (!this.cbHelper.isEditingUserSameasLoggedInUser()
      && this.router.url.includes('schedules')) {
        return 'displayNone';
      } else {
        if (!this.hideLink && !this.userTn) {
          return 'displayNone';
        } else {
          return 'displayInline';
        }
      }
    } else {
      if (!this.hideLink && !this.userTn) {
        return 'displayNone';
      } else {
        return 'displayInline';
      }
    }
  }
  public getGroupStyles() {
    if (this.editUser.isEditingUser()) {
      if ( !this.cbHelper.isEditingUserSameasLoggedInUser() &&
       this.router.url.includes('schedules')) {
        return 'displayInline';
      } else {
        if (this.hideLink && this.userTn) {
          return 'displayNone';
        } else {
          return 'displayInline';
        }
      }
    } else {
      if (this.hideLink && this.userTn) {
        return 'displayNone';
      } else {
        return 'displayInline';
      }
    }
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
