import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Apollo } from 'apollo-angular';
import { AppConfig, DigitalProfileSharedService, TealiumUtagService } from 'common-ui-lib';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from 'ngx-localstorage';
import { BillingHomeService } from 'redesign/billing-home/billing-home.service';
import { MalpService } from 'redesign/malp/malp.service';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { finalize } from 'rxjs/operators';
import { AEMClientService, ResourceBundle } from '../services/aemclient.service';
import { CBHelperService } from '../services/cbhelper.service';
import { CommonMessageService } from '../shared/redesign/commonmessage/commonmessage.service';
import { SelfInstallComponent } from './self-install.component';
import { caseDetails, CaseNum, SelfInstallStatus, stepsResponse } from './self-install.model';
import {ChannelStatus, DeviceType} from './self-install.model';
import gql from 'graphql-tag';
import { UserService } from 'redesign/admin/user/user.service';
@Injectable()
export class SelfInstallService {

  public selfInstallStatus: string;
  public mobileView = false;
  public accountGuid: string;
  public isMobileDevice: boolean;
  public confirmedEquipmentList = [];
  public orderDetailsEquipmentRows: any[] = [];
  public deviceName: [];
  public caseDetails: caseDetails;
  public workOrderId: string;
  public resourceBundle: ResourceBundle = {};
  public deviceDetailsContentLoaded = false;
  public isNonIpEnabled = false;
  public onepStatus: string;
  public deviceInstallComplete = false;
  public allDevicesInstalled: boolean;
  public netAssuranceUnsuccessful = false;
  public netAssuranceSuccessful = false;
  public netAssuranceInprogress = false;
  public selfInstallTitle: string;
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private modalService: NgbModal,
    public deviceDetectorService: DeviceDetectorService,
    public billingService: BillingHomeService,
    private _storageService: LocalStorageService,
    private apollo: Apollo,
    public selfInstallMsg: CommonMessageService,
    private aemClient: AEMClientService,
    public malpService: MalpService,
    private config: AppConfig,
    public cbHelperService: CBHelperService,
    public tealium: TealiumUtagService,
    public digitalProfileService: DigitalProfileSharedService
  ) {
    this.isMobileDevice = this.deviceDetectorService.isMobile();
    this.aemClient.getBundle('selfinstall', 'app')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: ResourceBundle) => {
        this.resourceBundle = data;
      });

  }

  // Open Order details modal
  public openOrderDetailsModal(deviceList?:any,selfInstallStatus?:string) {
    this.selfInstallMsg.displayMessage.show = false;
    this.accountGuid = this.cbHelperService.accountGuid;
    this.modalService
      .open(SelfInstallComponent, {
        windowClass: this.mobileView ? '' : 'redesign-modal-md',
        backdrop: 'static',
      }).result.then((result) => {
      });
    if(!this.digitalProfileService.netAssuranceStatus){
    this.getConfirmedEquipmentsList();
    }else {
      if (deviceList && selfInstallStatus) {
        this.handleDeviceList(deviceList, selfInstallStatus);
      } else {
        this.selfInstallMsg
          .showMessage(this.resourceBundle.genericerrorMsg, 'error', 'selfInstallError');
        this.modalScroll();
      }
      this.deviceDetailsContentLoaded = true;
    } 
  }

  // Get order details call
  public getConfirmedEquipmentsList() {
    this.allDevicesInstalled = false;
    const userType = this.cbHelperService.getLoggedInUserDetails()?.profile?.userToken?.userType;
    if (this.accountGuid === null || this.accountGuid?.length === 0 || this.accountGuid === undefined) {
      if (userType !== 'END_USER') {
      this.getAccounts('ALL')
      .pipe(
        finalize(() => {
          
        })
      )
        .subscribe((results) => {
          this.deviceDetailsContentLoaded = true;
          if (results?.data !== undefined && results?.data !== null && results.data?.userAccountsByFeature?.length === 1) {
              this.accountGuid = results?.data?.userAccountsByFeature[0]?.guid;
          } else {
            this.selfInstallMsg
              .showMessage(this.resourceBundle.genericerrorMsg, 'error', 'selfInstallError');
            this.modalScroll();
          }
          if (results?.errors) {
            this.selfInstallMsg
              .showMessage(this.resourceBundle.genericerrorMsg, 'error', 'selfInstallError');
            this.modalScroll();
          }
          this.getDeviceDetails();
        });
      }
    }
    if (userType === 'END_USER' || (userType !== 'END_USER' && this.accountGuid)) {
      this.getDeviceDetails();
    }
  }

  public getDeviceDetails() {
    if (this.accountGuid?.length > 0) {
      this.deviceDetailsContentLoaded = false;
      this.deviceDetails(this.accountGuid)
        .pipe(
          finalize(() => {
            this.deviceDetailsContentLoaded = true;
          })
        )
        .subscribe((results) => {
          if (results?.data?.getAccount !== undefined && results?.data?.getAccount !== null) {
            this.handleDeviceList(results?.data?.getAccount?.deviceInstallDetails?.deviceList,
              results?.data?.getAccount?.deviceInstallDetails?.selfInstallStatus);
          } else {
            this.selfInstallMsg
              .showMessage(this.resourceBundle.genericerrorMsg, 'error', 'selfInstallError');
            this.modalScroll();
          }
          if (results?.errors) {
            this.selfInstallMsg
              .showMessage(this.resourceBundle.genericerrorMsg, 'error', 'selfInstallError');
            this.modalScroll();
          }
        });
    } else {
      this.deviceDetailsContentLoaded = true;
      this.selfInstallMsg
        .showMessage(this.resourceBundle.genericerrorMsg, 'error', 'selfInstallError');
      this.modalScroll();
    }
  }

  public handleDeviceList(deviceList, selfInstallStatus) {
    const isMyAdmin = this.cbHelperService.isMyAdmin();
    this.orderDetailsEquipmentRows = [];
    this.confirmedEquipmentList = deviceList;
    this.selfInstallStatus = selfInstallStatus;
    if(this.selfInstallStatus === CaseNum['case1']){
      this.selfInstallTitle = this.resourceBundle.modalTitleOrderDetails;
    }else if(this.selfInstallStatus === CaseNum['case2']){
      this.selfInstallTitle = this.resourceBundle.modalTitleOrderTracking;
    }else if(this.selfInstallStatus === CaseNum['case3']){
      this.selfInstallTitle = this.resourceBundle.modalTitleOrderDelivery;
    }
    if (this.confirmedEquipmentList) {
      this.workOrderId = this.confirmedEquipmentList[0]?.workOrderNumber;
      this.confirmedEquipmentList.forEach(element => {
        if (element?.itemNumber) {
        this.orderDetailsEquipmentRows.push(element);
        }
      });
      this.orderDetailsEquipmentRows = [...this.orderDetailsEquipmentRows];
      if(this.selfInstallStatus === CaseNum.case3) {
      this.allDevicesInstalled = true;
      this.isNonIpEnabled = false;
      this.orderDetailsEquipmentRows.find((obj)=>{
        const deviceName = obj?.name?.toLowerCase().replaceAll(' ', '-');
        if(obj?.deviceType === DeviceType.INTERNETDEVICE){
          if(obj?.installStatus === 'N' || obj?.installStatus === 'F' || obj?.installStatus === 'L'){
            this.isNonIpEnabled = true;
            this.allDevicesInstalled = false;
          }
          if(obj?.installStatus === 'F'){
            this.onepStatus = ChannelStatus.Failure;
            this.tealium.trackSuccessFailureEvents('selfInstallFail', `${deviceName}:self-install:fail`);
          }
          if(obj?.installStatus === 'Y'){
            this.deviceInstallComplete = true;
            this.tealium.trackSuccessFailureEvents('selfInstallSuccess', `${deviceName}:self-install:success`);
          }
        } else if (obj?.deviceType === DeviceType.NONINTERNETDEVICE && (obj?.itemNumber === 'NCPA300' || obj?.itemNumber === 'NCPA100') && !isMyAdmin) {
          this.digitalProfileService.netAssuranceStatus = true;
          if (obj.installStatus === SelfInstallStatus.FAILED) {
            this.netAssuranceUnsuccessful = true;
            this.onepStatus = ChannelStatus.Failure;
            this.tealium.trackSuccessFailureEvents('selfInstallFail', `${deviceName}:self-install:fail`);
            this.allDevicesInstalled = false;
          } else if (obj.installStatus === SelfInstallStatus.SUCCESS) {
            this.netAssuranceSuccessful = true;
            this.allDevicesInstalled = false;
          } else if (obj.installStatus === SelfInstallStatus.INPROGRESS) {
            this.netAssuranceInprogress = true;
            this.allDevicesInstalled = false;
          } else if (obj?.installStatus === 'Y') {
            this.tealium.trackSuccessFailureEvents('selfInstallSuccess', `${deviceName}:self-install:success`);
            this.deviceInstallComplete = true;
          } else {
            this.allDevicesInstalled = false;
          }
        } else if (obj?.installStatus === 'Y') {
          this.deviceInstallComplete = true;
          this.tealium.trackSuccessFailureEvents('selfInstallSuccess', `${deviceName}:self-install:success`);
        } else {
          this.allDevicesInstalled = false;
        }
      });
      this.showSurveyPage();
    }
    if(this.selfInstallStatus === CaseNum.case4) {
      this.allDevicesInstalled = true;
      this.showSurveyPage();
    }
    }
  }

  public showSurveyPage() {
    if (this.allDevicesInstalled) {
      setTimeout(() => {
      window.KAMPYLE_ONSITE_SDK?.updatePageView();
      }, 200);
      if (document?.getElementById('web-messenger-container')) {
        const letsChatButton: HTMLElement = document?.getElementById('web-messenger-container');
        letsChatButton?.classList?.add('defocus');
      } else if(document?.getElementsByClassName('bcFloat')?.length > 0) {
        const letsChatButton = document?.getElementsByClassName('bcFloat')[0];
        letsChatButton?.classList?.add('defocus');
      }
      this.digitalProfileService.hideSelfInstall = true;
    }
  }

  public modalScroll() {
    const modalWindow = document.querySelectorAll('ngb-modal-window');
    if (modalWindow && modalWindow.length > 0) {
      modalWindow[0].scrollTop = 0;
    }
  }

  public triggerTealium(eventName: string) {
    if(eventName === 'Close'){
      if (document?.getElementById('web-messenger-container')) {
        const letsChatButton: HTMLElement = document?.getElementById('web-messenger-container');
        letsChatButton?.classList?.remove('defocus');
      } else if(document?.getElementsByClassName('bcFloat')?.length > 0){
        const letsChatButton = document?.getElementsByClassName('bcFloat')[0];
        letsChatButton?.classList.remove('defocus');
      }
      }
  }

  // Device details query call
  public deviceDetails(accountGuid: string): Observable<any> {
    const orderDeviceDetails = gql`
      query orderDeviceDetails($accountGuid: ID!) {
        getAccount(id: $accountGuid) {
          deviceInstallDetails {
            selfInstallStatus
            deviceList
              {
                deviceType
                itemNumber
                name
                serialNumber
                shippingStatus
                trackingUrl
                shippingTrackingNumber
                installStatus
                channelSolutionID
                workOrderNumber
                caseId
              }
          }
        }
      }
    `;

    return this.apollo.query({
      query: orderDeviceDetails,
      variables: {
        accountGuid
      },
    });
  }

  // Deep link Device details query call
  public deepLinkDeviceDetails(encryptedValue: string): Observable<any> {
    const orderDeviceDetails = gql`
      query MyQuery($encryptedValue: String!) {
        getNetAssuranceDeepLinkDetails(encryptedValue: $encryptedValue) {
          deepLinkExpired
          deviceList {
            caseId
            channelSolutionID
            deviceType
            installStatus
            itemNumber
            name
            serialNumber
            shippingStatus
            shippingTrackingNumber
            trackingUrl
            workOrderNumber
          }
        selfInstallStatus
        }
      }
    `;

    return this.apollo.query({
      query: orderDeviceDetails,
      variables: {
        encryptedValue
      },
    });
  }
  
  // Account guid query call
  public getAccounts(featureName: string): Observable<any> {
    const getAccountInfoQuery = gql`
            query getAccountDetails($featureName: FeatureLookup!){
              userAccountsByFeature(feature: $featureName) {
                guid
              }
            }`;

    return this.apollo.query({
      query: getAccountInfoQuery,
      variables: {
        featureName
      },
    });
  }

  public getInstallationSteps(accountGuid: string,channelSolutionId: string){
    const getStepsForInstallation = gql`
    query getStepsForInstallation($accountGuid: ID!,$channelSolutionId: String!) {
      getAccount(id: $accountGuid) {
        getSelfInstallDeviceDetails(channelSolutionId: $channelSolutionId) {
          deviceContent {
            name
            content
          }
        }
      }
    }`;
    return this.apollo.query({
      query: getStepsForInstallation,
      variables: {
        accountGuid,
        channelSolutionId
      },
    });
  }

  public updateNon1pDeviceStatus(itemNumber: string,serialNumber: string){
    const updateNon1pDevice = gql`
    mutation updateNon1pDevice($itemNumber: String!,$serialNumber: String!) {
      updateSelfInstallNon1PStatus(itemNumber: $itemNumber,serialNumber: $serialNumber) {
        code
        message
        transactionId
        }
    }`;
    return this.apollo.mutate({
      mutation: updateNon1pDevice,
      variables: {
        itemNumber,
        serialNumber
      },
    });
  }

  public getContactDetails(): Observable<any> {
    const getContactInfoQuery = gql`
            query MyQuery {
				getNetAssuranceDeviceContactDetails {
				email
				isMobileNumber
				phoneNumber
			}
    }`;
    return this.apollo.query({
      query: getContactInfoQuery
    });
  }

  public updateContactDetails(email: string,phoneNumber: string,serialNumber: string){
    const updateContactInfoQuery = gql`
    mutation MyMutation($email: String!,$phoneNumber: String!,$serialNumber: String!) {
      updateNetAssuranceDeviceContactDetails(
        input: {email: $email, phoneNumber: $phoneNumber, serialNumber: $serialNumber}) {
        message
        transactionId
        code
      }
    }`;
    return this.apollo.mutate({
      mutation: updateContactInfoQuery,
      variables: {
        email,
        phoneNumber,
        serialNumber
      },
    });
  }

  public getInstallStatus(caseId: string, serialNumber: string){
    const getInstallStatusQuery = gql`
    mutation MyMutation($caseId: String!, $serialNumber: String!) {
      notifyCradlepointBootInitiated(
        input: {caseId: $caseId, serialNumber: $serialNumber}) {
        message
        transactionId
        code
      }
    }`;
    return this.apollo.mutate({
      mutation: getInstallStatusQuery,
      variables: {
        caseId, serialNumber
      },
    });
  }
}


