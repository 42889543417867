import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, CBHelperService, CommonMessageService, Cookie, ResourceBundle } from 'common-ui-lib';
import { CookieService } from 'ngx-cookie-service';
import { StepNum } from './reset-password.model';
import { ResetPasswordService } from './reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit, OnDestroy {

  @Input() userName: string;
  @Input() userEmail: string;
  @Input() userGuid: string;
  @Output() closeClick = new EventEmitter();
  public resourceBundle: ResourceBundle = {};
  public isMobile: boolean;
  public stepNum = StepNum;

  constructor(
    private aemClient: AEMClientService,
    public resetPasswordService: ResetPasswordService,
    public activeModal: NgbActiveModal,
    public cookieService: CookieService,
    public cbHelper: CBHelperService,
    public commonMessageService: CommonMessageService,
  ) { }

    ngOnInit() {
    this.aemClient.getBundle('resetpassword', 'app')
      .subscribe((data: ResourceBundle) => {
        this.resourceBundle = data;
        this.resetPasswordService.modalHeading = this.resourceBundle.forgotPassword;
      });
    this.isMobile = this.resetPasswordService.isMobile;
    this.resetPasswordService.userName = this.userName;
    this.resetPasswordService.userEmail = this.userEmail;
  }

  //On Reset Password Modal Close
  public onResetPwdClose() {
    this.activeModal?.close();
    this.closeClick.emit();
    this.resetPasswordService.currentStep = StepNum.step1;
    this.resetPasswordService.modalHeading = this.resourceBundle.forgotPassword;
    this.resetPasswordService.encryptedPassword= null;
    this.cookieService.delete(Cookie.TalonToken, this.resetPasswordService.attributes);
    this.commonMessageService.displayMessage.show = false;
    }

  ngOnDestroy() {
    this.resetPasswordService.currentStep = StepNum.step1;
    this.resetPasswordService.modalHeading = this.resourceBundle.forgotPassword;
    this.resetPasswordService.isIdScanFailed = false;
    this.cookieService.delete(Cookie.TalonToken, this.resetPasswordService.attributes);
  }
}

