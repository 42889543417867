import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	OutageNotificationInfo,
	OutageType,
	ServiceLocationOutageInput
} from './cb-maps.model';

@Injectable()
export class CbMapsService {

	public outageViewDetailsClicked = new BehaviorSubject<OutageNotificationInfo>(null);

	constructor(private apollo: Apollo) { }

	public setOutageViewDetailsClicked(clicked: boolean, outageType: OutageType) {
		this.outageViewDetailsClicked.next({ clicked, outageType });
	}

	public getOutageViewDetailsClicked(): Observable<unknown> {
		return this.outageViewDetailsClicked.asObservable();
	}

	public getOutagePhoneUpdates(input: ServiceLocationOutageInput) {
		const createOutageNotificationMutation = gql`
		mutation createOutageNotification($input: ServiceLocationOutageInput!) {
			createOutageNotification(input: $input) {
				profileGuid
			}
		}
		`;
		return this.apollo.use('digitalProfile').mutate({
			mutation: createOutageNotificationMutation,
			variables: {
				input
			},
		});
	}

	public modalScroll() {
		const modalWindow = document.querySelectorAll('ngb-modal-window');
		if (modalWindow && modalWindow.length > 0) {
			modalWindow[0].scrollTop = 0;
		}
	}

	public getOutageDescription(saSfdcId: string) {
		const outageDescQuery = gql`
		query getOutageDescription($saSfdcId: String!) {
			getServiceLocation(saSfdcId: $saSfdcId) {
				outages(outageMessage: true) {
					outageDetails {
					  message
					}
				}
		  	}
		}`;
		return this.apollo.use('digitalProfile').query({
		  query: outageDescQuery,
		  variables: {
			saSfdcId
		  }
		});
	}
}
