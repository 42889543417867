<ng-container *ngIf="!isMobileDevice">
  <div class="row col-md-12 flex-nowrap mt-2 pt-1 sub-category">
    <img  src="assets/images/blueprint/admin_redesign/megamenu-avatar.svg"
      alt="Profile Avatar" class="mt-2 avatarSize sub-category"> <!--[ngClass]="isCollapsed? 'active':''"-->
      <a href="javascript:void(0)" class="mega-menu col-md-12 col-sm-12 col-xs-12" [attr.aria-label]="'Hi, '+ menuItems?.firstName +' '+'More Menu'" role="button" [attr.aria-expanded]="isCollapsed"> <!--[ngClass]="isCollapsed? 'active':''"-->
      <span *ngIf="menuItems" class="col-md-8 col-sm-8 col-xs-8 p-0 row-text-overflow sub-category" title="{{menuItems?.firstName}}">Hi, {{menuItems?.firstName}} </span><br><span class="sub-category"><b class="sub-category">MyAccount</b></span>
      <span *ngIf="isCollapsed" class="pl-lg-2 ml-lg-2">
        <img class="mega-menu-img-angle" src="assets/images/blueprint/icons/arrow-up-small-blue.svg" alt="">
      </span>
      <span *ngIf="!isCollapsed" class="pl-lg-2 ml-lg-2">
        <img class="mega-menu-img-angle" src="assets/images/blueprint/icons/arrow-down-small-blue.svg" alt="">
      </span>
    </a>
  </div>
  <div class="collection-of-items" *ngIf="isCollapsed">
      <ul>
        <div *ngFor="let item of menuItems?.options" [ngClass]="{'listDarkBackground' :  darkBackgroundArr.includes(item.name)}" (keydown)="outOfMegaMenu($event, item.name)">
        <ng-container *ngFor="let type of resourceBundle?.menuType">
          <li class="p-1" *ngIf="type.name === item.name && item.enabled">
            <a href="javascript:void(0)" class="pl-3" (click)="navigateOnClick(item.url,item.openInNewWindow)"
              *ngIf="isCollapsed">{{type.displayName}}</a>
          </li>
        </ng-container>
      </div>
      </ul>
  </div>
  </ng-container>
  
  <ng-container *ngIf="isMobileDevice">
    <div class="mt-2 pt-1">
        <a href="javascript:void(0)" class="mega-menu pb-0" (click)="toggleMegaMenuLink()" id="megaMenumobile">
          <img src="assets/images/blueprint/admin_redesign/megamenu-avatar.svg" alt="Profile Avatar">
          <img class="mega-menu-img-angle mt-0" src="assets/images/blueprint/icons/arrow-down-small-blue.svg" alt="">
        </a>
        <span *ngIf="menuItems" class="col-xs-11 p-0 row-text-overflow" title="{{menuItems?.firstName}}">Hi, {{menuItems?.firstName}} </span>
    </div>
    <div class="wrapper" id="megaMenuMobile" *ngIf="isMobileDevice && menuItems">
      <!-- Sidebar -->
      <nav id="sidebarmegamenu">
        <div id="sideBarCloseButton" class="col-lg-8 col-md-8 col-xs-8 pt-3 pb-3">
          <div class="col-12">
              <img class="mega-menu-close-img pull-right" src="assets/images/vertical-menu-close.svg" (click)="closeSideBar()" alt="">
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-xs-8 mt-2 p-0">
          <ul *ngFor="let item of menuItems?.options" class="ul-style"
            [ngClass]="{'listDarkBackground' :  darkBackgroundArr.includes(item.name)}">
            <ng-container *ngFor="let type of resourceBundle.menuType">
              <li class="p-2" *ngIf="type.name === item.name && item.enabled && type.name != 'CAPP'">
                <a href="javascript:void(0)" class="displaytext pl-2"
                  (click)="navigateOnClick(item.url, item.openInNewWindow)">{{type.displayName}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </nav>
  </div>
  </ng-container>