<div class="cb-spinner">
    <svg preserveAspectRatio="xMidYMid meet"
        focusable="false"
        viewBox="0 0 100 100">
        <circle cx="50%" cy="50%" r="45"
            class="ng-star-inserted"
            style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-dashoffset: 56.5487px; stroke-width: 10%;">
        </circle>
    </svg>
</div>
<div class="height-300-px"></div>