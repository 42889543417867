export class ScheduleList {
  transactionId: string;
  code: string;
  message: string;
  warning: string;
  scheduleEntryList: [ScheduleEntryList];

  // fromJSON is used to convert an serialized version
  // of the User to an instance of the class
  static fromJSON(json: ScheduleListJSON|string): ScheduleList {
    if (typeof JSON === 'string') {
      // if it's a string, parse it first
      // return JSON.parse(json, ScheduleList.reviver);
    } else {
      // create an instance of the User class
      const user = Object.create(ScheduleList.prototype);
      // copy all the fields from the json object
      return Object.assign(user, json);
    }
  }

  // reviver can be passed as the second parameter to JSON.parse
  // to automatically call User.fromJSON on the resulting value.
  static reviver(key: string, value: any): any {
    return key === '' ? ScheduleList.fromJSON(value) : value;
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): ScheduleListJSON {
    // copy all fields from `this` to an empty object and return in
    return Object.assign({}, this);
  }

  constructor(values: Object = {}) {
    // Constructor initialization
    Object.assign(this, values);
  }
}

export class ScheduleEntryList {
  scheduleName: string;
  scheduleAccess: string;
  scheduleType: string;
}

export interface ScheduleListJSON {
  transactionId: string;
  code: string;
  message: string;
  warning: string;
  scheduleEntryList: [ScheduleEntryList];
}

export class EventListReqObj {
  id: string;
  scheduleName: string;
  scheduleType: string;
  scheduleAccess: string;
  newScheduleName?: string;
  eventNameForDelete?: string;


  constructor(id: string, scheduleName: string, scheduleType: string, scheduleAccess: string) {
    this.id = id;
    this.scheduleName = scheduleName;
    this.scheduleType = scheduleType;
    this.scheduleAccess = scheduleAccess;
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): any {
    // copy all fields from `this` to an empty object and return in
    return Object.assign({}, this);
  }
}
export class EventListResponse {
  transactionId: string;
  code: string;
  message: string;
  warning: string;
  eventDetails: [EventDetailsList];

  // fromJSON is used to convert an serialized version
  // of the User to an instance of the class
  static fromJSON(json: EventListResJSON|string): EventListResponse {
    if (typeof JSON === 'string') {
      // if it's a string, parse it first
      // return JSON.parse(json, ScheduleList.reviver);
    } else {
      // create an instance of the User class
      const user = Object.create(EventListResponse.prototype);
      // copy all the fields from the json object
      return Object.assign(user, json);
    }
  }

  // reviver can be passed as the second parameter to JSON.parse
  // to automatically call User.fromJSON on the resulting value.
  static reviver(key: string, value: any): any {
    return key === '' ? EventListResponse.fromJSON(value) : value;
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): EventListResJSON {
    // copy all fields from `this` to an empty object and return in
    return Object.assign({}, this);
  }

  constructor(values: Object = {}) {
    // Constructor initialization
    Object.assign(this, values);
  }
}

export class EventDetailsList {
  eventName: string;
  newEventName?: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  allDayEvent: boolean;
  recurForEver: boolean;
  recurEndDate: string;
  recurEndOccurrence: any;
  dailyRecurrence: RecurDailyEvent;
  weeklyRecurrence: RecurWeeklyEvent;
  monthlyRecurrence: RecurMonthlyEvent;
  yearlyRecurrence: RecurYearlyEvent;
  eventAccess?: string;
  eventType?: string;
}


export class RecurDailyEvent {
  dailyrecurInterval: any;
}

export class RecurWeeklyEvent {
  weeklyRecurInterval: any;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

export class RecurMonthlyEvent {
  monthlyRecurInterval: any;
  dayOfMonth: any;
  dayOfWeekInMonth: string;
  dayOfWeek: string;
}
export class RecurYearlyEvent {
  yearlyRecurInterval: any;
  dayOfMonth: any;
  dayOfMonthName: string;
  dayOfWeekInMonth: string;
  dayOfWeek: string;
  dayOfWeekInMonthName: string;
}

export interface EventListResJSON {
  transactionId: string;
  code: string;
  message: string;
  warning: string;
  eventDetails: [EventDetailsList];
}

export class AddScheduleReqObj {
  id: string;
  scheduleDetails: ScheduleEntryList;
  eventDetails: EventDetailsList;
  newSchedule: boolean;
}

export class ScheduleDeleteResponseObj {
  transactionId: string;
  code: string;
  message: string;
  warning: string;

  // fromJSON is used to convert an serialized version
  // of the User to an instance of the class
  static fromJSON(json: ScheduleDeleteResponseObjJSON|string): ScheduleDeleteResponseObj {
    if (typeof json === 'string') {
      // if it's a string, parse it first
      return JSON.parse(json, ScheduleDeleteResponseObj.reviver);
    } else {
      // create an instance of the User class
      const user = Object.create(ScheduleDeleteResponseObj.prototype);
      // copy all the fields from the json object
      return Object.assign(user, json);
    }
  }
  // reviver can be passed as the second parameter to JSON.parse
  // to automatically call User.fromJSON on the resulting value.
  static reviver(key: string, value: any): any {
    return key === '' ? ScheduleDeleteResponseObj.fromJSON(value) : value;
  }
}
export interface ScheduleDeleteResponseObjJSON {
  transactionId: string;
  code: string;
  message: string;
  warning: string;
}

export class ScheduleUpdateReqObj {
  id: string;
  scheduleName: string;
  scheduleType: string;
  scheduleAccess: string;
  newScheduleName: string;
  eventNameForDelete: string;
}

