import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { search } from 'assets/scripts/search.js';
import { AEMClientService, AEMMessagesService, AppConfig, DigitalProfileSharedService } from 'common-ui-lib';
import { CBHelperService } from 'common-ui-lib/lib/services/cbhelper.service';
import { DeviceDetectorService } from 'common-ui-lib';
import { filter, finalize } from 'rxjs/operators';
import { Profile } from './defaultHeader.model';
import { DefaultHeaderService } from './defaultHeader.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-default-header',
    templateUrl: './defaultHeader.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./defaultHeader.component.scss']
})

export class DefaultHeaderComponent implements OnInit, OnDestroy {
    @Input()
    public fullGradiantLine = false;
    public topHeaderBundle: string;
    public isMobileDevice = false;
    public msgs = null;
    public showExternalSearchImg: boolean;
    public showHeaderBundle = true;
    public appLoaded = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public headerTicketList: any;
    public alerts: Profile;
    public isSearchDropdownOpen = false;
    public link: string;
    private searchField: HTMLElement;
    private getProfileSubscription: Subscription;

    constructor(public messageService: AEMMessagesService,
        private aemClient: AEMClientService, private activatedRoute: ActivatedRoute, public cbHelper: CBHelperService,
        public defaultHeaderService: DefaultHeaderService,
        private deviceService: DeviceDetectorService, private config: AppConfig,
        private router: Router, public digitalProfileService: DigitalProfileSharedService) {
        this.showHeaderBundle = (window.location.href.indexOf('unauth/login') > -1) ? false : true;
        //this logic has been added when we do switch user or navigate to different pages to fetch
        // the data at compoent level
        router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            this.showHeaderBundle = (window.location.href.indexOf('unauth/login') > -1) ? false : true;
            if (this.cbHelper.isProfileInContext() && (!window.location.href.includes('/unauth/logout')
                && !window.location.href.includes('/unauth/login') && !this.cbHelper.isOBUrl() &&
                !window.location.href.includes('/unauth/myadminlogout'))) {
                console.log('OKTAFLOW-DefaultHeaderComponent cons router event');
                this.defaultHeaderService.setProfileDetails(null);
                const marketRolloutFlag = this.cbHelper.getMarketRollOut;
                if(marketRolloutFlag) {
                    const profileGuid = this.cbHelper.getLoggedInUserDetails().profile.userToken.profileGuid;
                    this.getDigitalProfileDetails(profileGuid);
                } else {
                    this.getOutageAndCBOTDetails();
                }
            }
        });
        this.cbHelper.initiateHeaderRefreshSub.subscribe((data) => {
            if (data) {
                this.appLoaded = true;
            }
        });
        this.link = '';
        this.getSkipLink(window.location.href);
    }

    // Close dropdown on click of outside
    @HostListener('window:click', ['$event'])
    public onMouseClick(event: MouseEvent) {
        if (!this.isMobileDevice) {
            if ($(event.target).hasClass('search-text') || $(event.target).hasClass('searchIconText')
                || $(event.target).hasClass('searchIcon')) {
                if (!this.isSearchDropdownOpen) {
                    this.isSearchDropdownOpen = true;
                    this.searchOnInput();
                    document.getElementsByClassName('yxt-SearchBar-form')[0].setAttribute('id', 'header-search-form');
                } else {
                    this.isSearchDropdownOpen = false;
                    document.getElementById('searchField')?.classList.add('yxt-SearchBar-close');
                    setTimeout(() => {
                        this.showExternalSearchImg = true;
                        document.getElementById('searchField')?.classList.add('hide');
                    }, 500);
                    document.getElementById('clear-button')?.classList.add('hide');
                    document.getElementById('search-button')?.classList.add('hide');
                    if (document.querySelector('.yxt-SearchBar-input')) {
                        this.onClickOfClearSearch(event);
                    }
                }
            } else if ($(event.target).hasClass('yxt-SearchBar-input') ||
                $(event.target).hasClass('clear-search') ||
                $(event.target).hasClass('yxt-SearchBar-button')) {
                this.isSearchDropdownOpen = true;
                document.getElementById('searchField')?.classList.remove('hide');
                document.getElementById('clear-button')?.classList.remove('hide');
                document.getElementById('searchField')?.classList.remove('yxt-SearchBar-close');
                document.getElementById('search-button')?.classList.remove('hide');
                document.getElementsByClassName('yxt-SearchBar-form')[0].setAttribute('id', 'header-search-form');
            } else {
                this.isSearchDropdownOpen = false;
                document.getElementById('searchField')?.classList.add('yxt-SearchBar-close');
                setTimeout(() => {
                    this.showExternalSearchImg = true;
                    document.getElementById('searchField')?.classList.add('hide');
                }, 500);
                document.getElementById('clear-button')?.classList.add('hide');
                document.getElementById('search-button')?.classList.add('hide');
                if (document.querySelector('.yxt-SearchBar-input')) {
                    this.onClickOfClearSearch(event);
                }
            }
        }
        if(this.isMobileDevice) {
            if($(event.target).hasClass('yxt-SearchBar-input')) {
                document.getElementsByClassName('yxt-SearchBar-form')[0].setAttribute('id', 'header-search-form');
            }
        }
    }

    ngOnInit() {
        if (window.location.href.includes('/implicit/callback') || window.location.href.includes('postlogin') ||
            window.location.href.includes('/unauth/login') || window.location.href.includes('/unauth/logout')
            || this.router.url.includes('/unauth/myadminlogout')) {
            this.appLoaded = false;
        } else {
            this.appLoaded = true;
        }
        if (sessionStorage.getItem('isSwap')) {
            this.appLoaded = true;
        }
        this.aemClient.getTopHeaderHTMLContent('topHeaderContent', 'app')
            .subscribe(
                (data: string) => {
                    this.topHeaderBundle = data;
                },
                (error) => {
                    console.error(error);
                },
            );
        this.isMobileDevice = false;
        this.showExternalSearchImg = true;
        if (this.deviceService.isMobile()) {
            this.isMobileDevice = true;
            this.searchOnInput();
        }
        this.isSearchDropdownOpen = false;
    }

    // Profile Query
    public getOutageAndCBOTDetails() {
        console.log('OKTAFLOW-DefaultHeaderComponent getOutageAndCBOTDetails');
        this.defaultHeaderService.getOutageAlert('ALL')
            .pipe(finalize(() => {
                console.log('getOutageAndCBOTDetails finalize');
            })).subscribe((results: any) => {
                if (results) {
                    this.defaultHeaderService.setProfileDetails(results);
                    this.getProfileSubscription = this.defaultHeaderService.getProfileResponse
                        .subscribe((response: any) => {
                            if (response?.data !== null) {
                                this.headerTicketList = response?.data?.getProfile?.headerTicketList;
                                this.alerts = response?.data?.getProfile;
                            }
                        });
                }
            });
    }

    // Digital Profile Query
    public getDigitalProfileDetails(profileGuid: string) {
        if(this.router.url.includes('/portal')) {
            this.digitalProfileService.getDigitalProfileDetails(profileGuid);
        } else {
            this.digitalProfileService.fetchHeaderDetails(profileGuid);
        }
        this.getProfileSubscription = this.digitalProfileService.getDigitalProfileResponse
            .subscribe((response: any) => {
                if (response) {
                    this.headerTicketList = response?.headerTickets;
                    this.alerts = response?.headerNotifications;
                }
            });
    }

    public searchOnInput() {
        this.searchField = document.getElementById('searchField');
        if (!document.querySelector('.yxt-SearchBar')) {
            this.loadScripts();
        }
        this.showExternalSearchImg = false;
        document.getElementById('searchField').classList.remove('hide');
        document.getElementById('clear-button').classList.remove('hide');
        document.getElementById('search-button').classList.remove('hide');
        document.getElementById('searchField').classList.remove('yxt-SearchBar-close');
        const interval = setInterval(() => {
            const inputTxtFocus: any = document.getElementsByClassName('yxt-SearchBar-input')[0];
            if (inputTxtFocus) {
                if (!this.isMobileDevice) {
                    inputTxtFocus.focus();
                }
                inputTxtFocus.value = '';
                clearInterval(interval);
            }
        }, 300);
    }

    public onClickOfClearSearch(event: any) {
        if (event.key === 'Enter' || event.button === 0) {
            const input: any = document.querySelectorAll('.yxt-SearchBar-input')[0];
            if (input) {
                input.value = '';
            }
        }
    }

    public goToBusinessLink() {
        const subscription = this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.app !== 'webex') {
                window.open(this.config.getConfig('coxdotcom')['cbhomeUrl'], '_blank');
            }
        });
        subscription.unsubscribe();
    }

    ngOnDestroy() {
        this.appLoaded = false;
        this.getProfileSubscription.unsubscribe();
    }

    private getScript = (url: string) => new Promise<void>((resolve) => {
        const scriptEle = document.createElement('script');
        scriptEle.type = 'text/javascript';
        scriptEle.async = true;
        scriptEle.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(scriptEle);
        scriptEle.src = url;
        scriptEle.onload = search;
        scriptEle.addEventListener('load', () => resolve(), false);
    });

    private async loadScripts() {
        await this.getScript(this.config.getConfig('answersUrl'));
    }

    private getSkipLink(url: string) {
        if (url.indexOf('#mainContent') > -1) {
            const newLink = url.replace('#mainContent', '');
            this.link = `${newLink}#mainContent`;
        } else {
            this.link = `${url}#mainContent`;
        }
    }
}