<ng-container *ngIf="!isMyAdmin">
<div class="modal-body" [ngClass]="{'px-3': isMobile}">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 text-center" [ngClass]="{'margin-bottom-30px': !isMobile}">
        <h5 *ngIf="!resetPasswordService.forgotPasswordFlow" class="h1 font-cerapro-regular-imp">{{resourceBundle.changePasswordTitle}}</h5>
        <div [ngClass]="{'margin-botton-30p-imp' : !isMobile}" class="pad-top-p-20">
            <span>{{resourceBundle.changePasswordDescription}}</span>
            <form [formGroup]="changePasswordForm">
                <div class="position-relative" [ngClass]="{'display-grid justify-content-center display-inline-block col-10' : !isMobile}">
                    <label for="newPwd" [ngClass]="{'labelRedColor': displayNewPasswordError,'ml-2': isMobile}"
                        class="labelClass col-form-label display-flex">{{resourceBundle.newPasswordLabel}}</label>
                    <input #newPassword type="password" formControlName="newPassword" class="form-control input-pad" id="newPwd" aria-describedby="instructions"
                        name="newPassword" required [ngClass]="{'wide-27-5rem' : !isMobile, 'wide100': isMobile, 'error': displayNewPasswordError}">
                        <span class="showPassword">
                            <div aria-label="Show new password icon" role="button" class="link-hover" tabindex="0"
                            (click)="showHideEyeImg('newPwd', 'text')" id="showNewEye" (keyup.enter)="showHideEyeImg('newPwd', 'text')"
                                 *ngIf="showEyeNew">
                                <img src="assets/images/eye.svg" role="presentation">
                            </div>
                            <div aria-label="Hide new password icon" role="button" class="link-hover" tabindex="0"
                            (click)="showHideEyeImg('newPwd', 'password')" id="hideNewEye" (keyup.enter)="showHideEyeImg('newPwd', 'password')"
                                 *ngIf="!showEyeNew">
                                <img src="assets/images/eye-strikethrough.svg" role="presentation">
                            </div>
                        </span>
                    <p *ngIf="displayNewPasswordError"
                        class="text-danger form-error mt-1 mb-0 pull-left text-left" role="alert">
                        {{ newPwdErrorMsg }}
                    </p>
                </div>
                <div class="position-relative" [ngClass]="{'display-grid justify-content-center display-inline-block col-10' : !isMobile, 'margin-top-45': displayConfirmPasswordError && isMobile}">
                    <label for="confirmPwd" [ngClass]="{'labelRedColor': displayConfirmPasswordError,'ml-2': isMobile, 'float-left':displayConfirmPasswordError && isMobile }"
                        class="labelClass col-form-label display-flex">{{resourceBundle.confirmPasswordLabel}}</label>
                    <input #confirmPassword type="password" formControlName="confirmPassword" class="form-control input-pad"
                        id="confirmPwd" name="confirmPassword" required [ngClass]="{'wide-27-5rem' : !isMobile, 'wide100': isMobile, 'error': displayConfirmPasswordError}">
                        <span class="showPassword">
                            <div aria-label="Show confirm password icon" role="button" class="link-hover" tabindex="0"
                            (click)="showHideEyeImg('confirmPwd', 'text')" id="showConfirmEye" (keyup.enter)="showHideEyeImg('confirmPwd', 'text')" 
                                *ngIf="showEyeConfirm">
                                <img src="assets/images/eye.svg" role="presentation">
                            </div>

                            <div aria-label="Hide confirm password icon" role="button" class="link-hover" tabindex="0"
                            (click)="showHideEyeImg('confirmPwd', 'password')" id="hideConfirmEye" (keyup.enter)="showHideEyeImg('confirmPwd', 'text')" 
                                 *ngIf="!showEyeConfirm">
                                <img src="assets/images/eye-strikethrough.svg" role="presentation">
                            </div>

                        </span>
                    <p *ngIf="displayConfirmPasswordError"
                        class="text-danger form-error mt-1 mb-0 pull-left text-left" role="alert">
                        {{ confirmPwdErrorMsg }}
                    </p>
                </div>
            </form>
        </div>
            <div *ngIf="!isMobile" id="instructions" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 display-flex">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-4 ml-2 mt-4 pr-0 text-left">
                    <ul>
                        <li>{{resourceBundle.minCharLength}}</li>
                        <li>{{resourceBundle.oneNumber}}</li>
                        <li>{{resourceBundle.oneUpperCase}}</li>
                        <li>{{resourceBundle.oneLowerCase}}</li>
                    </ul>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-4 pl-0 text-left">
                    <ul>
                        <li>{{resourceBundle.commonPassword}}</li>
                        <li>{{resourceBundle.notPreviousPassword}}</li>
                        <li>{{resourceBundle.cannotContainUserName}}</li>
                    </ul>
                </div>
            </div>
            <div *ngIf="isMobile" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 display-flex pl-0 margin-bottom-30px">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0  mt-4 pr-0 text-left">
                    <ul>
                        <li>{{resourceBundle.minCharLength}}</li>
                        <li>{{resourceBundle.oneNumber}}</li>
                        <li>{{resourceBundle.oneUpperCase}}</li>
                        <li>{{resourceBundle.oneLowerCase}}</li>
                        <li>{{resourceBundle.commonPassword}}</li>
                        <li>{{resourceBundle.notPreviousPassword}}</li>
                        <li>{{resourceBundle.cannotContainUserName}}</li>
                    </ul>
                </div>
            </div>
    </div>
</div>
<hr class="hr">
<div class="col-sm-12 col-md-12 col-xs-12">
    <button class="button btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em track-clicks" [value]="resourceBundle.submitBtn" [disabled]="!(changePasswordForm.get('newPassword').dirty || changePasswordForm.get('confirmPassword').dirty)"
     (click)="submitPassword()" *ngIf="!showturnOffButtonSpinner">{{resourceBundle.submitBtn}}</button>
     <div *ngIf="showturnOffButtonSpinner" class="position-relative">
        <button class="button submitButton btn-long-text mb-3 mt-2" [ngClass]="isMobile ? 'spinButton-mobile' : 'spinButton'" type="button"
            aria-label="Button spinner"></button>
        <div class="button-spinner cb-spinner" [ngClass]="isMobile ? 'button-spinner-mobile' : 'buttonSpinner'">
            <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100"
                alt="Processing... please wait">
                <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
                    style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%;">
                </circle>
            </svg>
        </div>
    </div>
</div>
</ng-container>
<ng-container *ngIf="isMyAdmin">
<div class="modal-body">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0" [ngClass]="{'mt-0' : resetPasswordService.resetPwdMsg.displayMessage.show, 
    'margin-top-15' : !isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show,
    'margin-top-10px' : isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show}">
        <h5 class="h1 font-cerapro-regular-imp text-center">{{resourceBundle.resetPassModalHeader}}</h5>
        <p class="text-center" [ngClass]="{'margin-botton-30p-imp pad-top-p-20' : !isMobile,
        'pad-bottom-2-188rem pad-top-2-188rem' : isMobile}">{{resourceBundle.resetPswdDescNew}} <strong>{{resetPasswordService.userName}} ({{resetPasswordService.userEmail}})</strong> {{resourceBundle.resetPswdDescNew2}}</p>
    </div>
    <div class="col-sm-12 col-md-12 col-xs-12 text-center" *ngIf="tempPswdContentLoaded; else boxSkeleton">
        <ng-container *ngIf="resetPasswordService.encryptedPassword">
        <p>
            <b>{{resetPasswordService.encryptedPassword}}</b>
            <a href="javascript:void(0)" class="ml-4" (click)="cbHelper.copyMessageToClipboard(resetPasswordService.encryptedPassword)" role="button"><U>Copy</U></a>
        </p>
    </ng-container>
    </div>
</div>

<hr class="hr margin-top-10" />
<div>
    <div class="col-sm-12 col-md-12 col-xs-12">
        <button class="button btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em" (click)="resetPwdDone()">Done</button>
    </div>
</div>
</ng-container>
<ng-template #boxSkeleton>
    <div class="col-12 justify-content-center display-flex">
        <app-skeletonloader freeformSkeleton="true" count="1" sectionName="Change Password" widthSizeInPixels="200"
            heightSizeInPixels="22"></app-skeletonloader>
    </div>
</ng-template>
