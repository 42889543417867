import { Component, OnInit } from '@angular/core';
import { AppConfig, AuthTokenService, CBHelperService } from 'common-ui-lib';

@Component({
  selector: 'app-customheader',
  templateUrl: './customheader.component.html',
  styleUrls: ['./customheader.component.scss'],
})
export class CustomHeaderComponent implements OnInit {
  public isUserAuthenticated = false;
  public lastName: string;
  constructor(public cBHelperService: CBHelperService, private config: AppConfig, public authTokenService: AuthTokenService) { }

  ngOnInit() {
    if (this.cBHelperService.isUserAuthenticated()) {
      this.lastName = this.authTokenService.getProfile().userToken.lastName;
      this.isUserAuthenticated = true;
    }
  }
  public goToBusinessLink() {
    window.open(this.config.getConfig('coxdotcom')['cbhomeUrl'], '_blank');
}

}
