import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoxHttpClient, CoxHttpConfig } from '../services/coxhttpclient.service';
import { EncryptResponse } from './interfaces/profile';

@Injectable()
export class ProfileMicroservice {
    constructor(private coxHttpClient: CoxHttpClient) {}

    /**
     * @param data any string which should be encrypted by profile service, email, account12...
     * @returns encrypted version of your passed data
     */
    public Encrypt(data: string, config?: CoxHttpConfig): Observable<string> {
        return this.coxHttpClient.get<EncryptResponse>(
            '/api/cbma/profile/services/profile/encrypt/' + data, config).map((response) => {
            return response.encryptedEmail;
        });
    }

    /**
     * @param data any string which should be encrypted by profile service, email, account12...
     * @returns encrypted version of your passed data
     */
    public async EncryptAsync(data: string, config?: CoxHttpConfig): Promise<string> {
        const response = await this.coxHttpClient.getAsync<EncryptResponse>(
            '/api/cbma/profile/services/profile/encrypt/' + data, config);
        return response.encryptedEmail;
    }
}
