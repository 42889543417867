<ng-container *ngIf="aomsFlag || isHN">
<div id="breadcrumbs" class="row hidden-xs hidden-sm">
  <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-0" role="navigation">
    <div class="breadcrumb">
      <ul>
        <li>
          <a *ngIf="aomsFlag && isAomsDisplay == true" aria-label="MyAdmin" id="myadminaoms" routerLink="/csradmin/start">
            <span class="divider hidden">/ </span>
            <span class="target">MyAdmin</span>
          </a>
        </li>
        <li>
          <a *ngIf="isSplit && !aomsFlag" aria-label="MyAccount Home" tabindex="0" id="myaccount-home" routerLink="/home/landing">
            <span class="divider hidden">/ </span>
            <span class="target">MyAccount Home</span>
          </a>
          <span class="sr-only">,</span>
          <!-- <a *ngIf="!isSplit" id="myaccount-home" routerLink="/home/landing"> -->
            <a *ngIf="!isSplit && !aomsFlag" id="myaccount-home" aria-label="MyAccount Home" tabindex="0" href="javascript:void(0)" (click)="navigateToMALP()">
            <span class="divider hidden">/ </span>
            <span class="target">MyAccount Home</span>
          </a>
        </li>
        <li *ngIf="myadminhomeFlag && profileadminhomeflag">
          <a *ngIf="isSplit" aria-label="MyAdmin Home" href="/csradmin/start">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">MyAdmin Home</span>
          </a>
          <a *ngIf="!isSplit" aria-label="MyAdmin Home" routerLink="/csradmin/start">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">MyAdmin Home</span>
          </a>
        </li>
        <li *ngIf="voicebreadcrumbDisplay==true">
          <a *ngIf="breadcrumbs && breadcrumbs.length > 0 && isSplit" class="pl-0" aria-label="Voice Tools" tabindex="0" id="voicemanager-tools" href="/voice/cbvm/voicemanagertools">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">Voice Tools</span>
          </a>
          <a *ngIf="breadcrumbs && breadcrumbs.length > 0 && !isSplit" class="pl-0" aria-label="Voice Tools" tabindex="0" id="voicemanager-tools" routerLink="/voice/cbvm/voicemanagertools">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">Voice Tools</span>
          </a>
          <a *ngIf="breadcrumbs.length === 0" class="pl-0" aria-label="Voice Tools current page" tabindex="0" role="presentation" id="voicemanager-tools" href="javascript:void(0)" class="disabled">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">Voice Tools</span>
          </a>
        </li>
        <li *ngIf="datatoolsbreadcrumbDisplay==true">
          <a *ngIf="breadcrumbs && breadcrumbs.length > 0" class="pl-0" id="datatool-home" aria-label="Data Tools" routerLink="/data/datatool">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">Data Tools</span>
          </a>
          <a *ngIf="breadcrumbs.length === 0" id="datatool-home" class="pl-0" aria-label="Data Tools current page" role="presentation" href="javascript:void(0)" class="disabled">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">Data Tools</span>
          </a>

        </li>
        <li *ngFor="let breadcrumb of breadcrumbs; let i = index">
          <a *ngIf="i!= breadcrumbs.length-1 && !cbHelper.isHNCustomer" class="pl-0" attr.aria-label="{{breadcrumb.label}}" href="javascript:void(0)" (click)="navigateFromBreadCrumbs(breadcrumb)">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">{{ breadcrumb.label }}</span>
          </a>
          <a *ngIf="(i == breadcrumbs.length-1)" class="pl-0" attr.aria-label="{{breadcrumb.label}} current page" role="presentation" href="javascript:void(0)" class="disabled">
            <img src="assets/images/breadcrumb-arrow.svg" class="align-bottom" title="Breadcrumb Arrow" alt="Breadcrumb Arrow" />
            <span class="divider hidden">/ </span>
            <span class="target">{{ breadcrumb.label }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-2 col-sm-2 col-xs-12 margin-auto margin-top-10 text-center" *ngIf="cbHelper.isHNCustomer">
    <a href="javascript:void(0)" class="hn-logout-link" (click)="logout()">Logout</a>
  </div>
</div>
</ng-container>
<!-- <div id="hnlogout" class="row hidden-md hidden-lg" *ngIf="isHNCustomer"> -->
<div id="hnlogout" class="row hidden-md hidden-lg pl-0" *ngIf="cbHelper.isHNCustomer">
  <div class="col-xs-12 margin-auto margin-top-10">
    <a href="javascript:void(0)" class="hn-logout-link" (click)="logout()">Logout</a>
  </div>
</div>

<!-- <ng-template #cancelConfirm let-close="close" let-dismiss="dismiss">
  <div class="modal-body">
    <div class="row margin-top-30">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <p>If you have not saved your changes, they will not be retained when you leave this page. Are you sure you
          want to proceed?
        </p>
      </div>
    </div>
    <div class="row margin-top-20">
      <div class="col-lg-4 col-md-4 col-sm-12 col-12">
        <button class="button-secondary" style="width: 100%;" (click)="close(false)">No</button>
      </div>
      <div class="col-lg-4 offset-lg-4 offset-md-4 col-md-4 col-sm-12 col-12 text-right">
        <button class="button" style="width: 100%;" (click)="close(true)">Yes</button>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #cancelConfirm let-close="close" let-dismiss="dismiss">
  <div class="col-md-12 margin-bottom-20 modalheader">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-0 margin-bottom-20 margin-top-15">
      <span class="modal-title h4">Leave this page?</span>
      <a href="javascript:void(0)" title="Close Location Selection" class="cb-modal-close-btn float-right" (click)="close(false)"></a>
    </div>
  </div>
  <div class="row margin-left-25 margin-right-25">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 margin-bottom-20">
      <p>Your unsaved changes will be lost if you continue. Are you sure you want to leave this page?</p>
    </div>
  </div>
  <hr class="hr">
  <div class="row margin-right-25 margin-left-25 margin-bottom-10">
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 pl-0">
      <button class="button button-secondary" (click)="close(false)">No</button>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-right pr-0">
      <button class="button btn-long-text"  (click)="close(true)">Leave This Page</button>
    </div>
  </div>
</ng-template>

