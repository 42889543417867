
import {TemplatePortal} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {Subject} from 'rxjs';
import {MatTabContent} from '../tab-content/tab-content.component';
import { MatTabLabel } from '../tab-label/tab-label.component';

// tslint:disable-next-line: max-classes-per-file
@Component({ 
  // tslint:disable-next-line: component-selector
  selector: 'mat-tab',
  templateUrl: 'mattab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'matTab',
})
// tslint:disable-next-line: component-class-suffix
export class MatTab implements OnInit, OnChanges, OnDestroy {
  @Input() disabled;
  /** Content for the tab label given by `<ng-template mat-tab-label>`. */
  @ContentChild(MatTabLabel) templateLabel: MatTabLabel;

  /**
   * Template provided in the tab content that will be used if present, used to enable lazy-loading
   */
  // tslint:disable-next-line: no-any
  @ContentChild(MatTabContent, {read: TemplateRef, static: true}) _explicitContent: TemplateRef<any>;

  /** Template inside the MatTab view that contains an `<ng-content>`. */
  // tslint:disable-next-line: no-any
  @ViewChild(TemplateRef) _implicitContent: TemplateRef<any>;

  /** The plain text label for the tab, used when there is no template label. */
  // tslint:disable-next-line: no-input-rename
  @Input('label') textLabel = '';

  /** The portal that will be the hosted content of the tab */
  private _contentPortal: TemplatePortal | null = null;

  /** @docs-private */
  get content(): TemplatePortal | null {
    return this._contentPortal;
  }

  /** Emits whenever the label changes. */
  readonly _labelChange = new Subject<void>();

  /** Emits whenever the disable changes */
  readonly _disableChange = new Subject<void>();

  /**
   * The relatively indexed position where 0 represents the center, negative is left, and positive
   * represents the right.
   */
  position: number | null = null;

  /**
   * The initial relatively index origin of the tab if it was created and selected after there
   * was already a selected tab. Provides context of what position the tab should originate from.
   */
  origin: number | null = null;

  /**
   * Whether the tab is currently active.
   */
  isActive = false;

  constructor(private _viewContainerRef: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('textLabel')) {
      this._labelChange.next();
    }

    if (changes.hasOwnProperty('disabled')) {
      this._disableChange.next();
    }
  }

  ngOnDestroy(): void {
    this._disableChange.complete();
    this._labelChange.complete();
  }

  ngOnInit(): void {
    this._contentPortal = new TemplatePortal(
        this._explicitContent || this._implicitContent, this._viewContainerRef);
  }
}
