<nav>    
    <a [href]="link" class="skip" id="skip">Skip to Main Content</a>
    <div id="cbDefaultHeader" class="container-fluid p-0">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="top-header" *ngIf="cbHelper.isUserAuthenticated()">
                <div class="constrain-grid mx-auto">
                    <div [innerHTML]="topHeaderBundle">
                    </div>
                </div>
            </div>

            <!-- Mobile View -->
            <div [class]="cbHelper.isUserAuthenticated() ? 'row mobileViewHeader' : 'row'" *ngIf="isMobileDevice  && showHeaderBundle" [ngClass]="{'notLoggedIn' : (cbHelper.isUserAuthenticated() && !cbHelper.isProfileInContext()) || !cbHelper.isUserAuthenticated()}">
                <ng-container *ngIf="cbHelper.isUserAuthenticated() && (!cbHelper.isMyAdmin() || cbHelper.isProfileInContext()) && appLoaded">
                    <div class="col-lg-2 col-md-2 col-2 counterLinks">
                        <div class="row mx-0">
                            <app-header-tickets class="ml-2 mt-2"></app-header-tickets>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-2 pl-md-3 counterLinks">
                        <div class="row mx-0">
                            <app-aem-messages [alerts]="alerts" class="ml-2 mt-2"></app-aem-messages>
                        </div>
                    </div>
                </ng-container>
                <div class="col-lg-3 col-md-3 col-4 pl-md-5 coxLogo ml-md-4">
                    <a (click)="goToBusinessLink()"><img id="header-logo" class="height-45 margin-top-1" src="assets/images/blueprint/onboarding/CoxBusiness_logo.svg" alt="Cox Business Logo"></a>
                </div>
                <ng-container *ngIf="cbHelper.isUserAuthenticated() && appLoaded">
                    <div class="col-lg-3 col-md-3 col-4 pl-md-3 accountAvatar p-0 ml-md-4">
                        <app-mega-menu></app-mega-menu>
                    </div>
                </ng-container>
            </div>

            <!-- Desktop View -->
            <div class="row constrain-grid mx-auto" [ngClass]="{'notLoggedIn' : (cbHelper.isUserAuthenticated() && !cbHelper.isProfileInContext()) || !cbHelper.isUserAuthenticated(), 'hide': !showHeaderBundle, 'mobileViewHeader': isMobileDevice}">
                <div class="col-lg-2 col-md-2 col-2 pl-md-5 coxLogo" [ngClass]="{'hide': isMobileDevice}">
                    <a (click)="goToBusinessLink()"><img id="header-logo" class="height-45 margin-top-1"
                            src="assets/images/blueprint/onboarding/CoxBusiness_logo.svg" alt="Cox Business Logo"></a>
                </div>
                <div class="col-md-10 col-sm-10 display-flex p-0" [ngClass]="{'justify-content-flexEnd': !isMobileDevice, 'justify-content-center': isMobileDevice}">
                    <div [ngClass]="{'hide': !appLoaded, 'display-contents': appLoaded}">
                        <div class="search-container"
                            [ngClass]="{'hide': !(cbHelper.isUserAuthenticated() && (!cbHelper.isMyAdmin() || cbHelper.isProfileInContext()))}">
                            <div class="mt-2 hide overflow-hidden custom_search_form yxt-SearchBar-wrapper" id="searchField"></div>
                            <img title="Clear Search" role="button" src='assets/images/blueprint/close-black.svg' id="clear-button" alt="Clear search"
                                class="clear-search" [ngClass]="{'right-2-75-em': isMobileDevice, 'hide': !isMobileDevice}" (keydown)="onClickOfClearSearch($event)" (click)="onClickOfClearSearch($event)" tabindex="0">
                            <button type="submit" form="header-search-form" class="yxt-SearchBar-button" title="Search Icon" [ngClass]="{'right-1-rem': isMobileDevice, 'hide': !isMobileDevice}" id="search-button" tabindex="0"></button>

                            <div class="mt-4 margin-right-5" [ngClass]="{'hide': isMobileDevice}">
                                <a #searchBtn href="javascript:void(0)" aria-label="Search" role="button"
                                    class="globalHeaderStyle searchIconText" id="searchForm">
                                    <img *ngIf="showExternalSearchImg"
                                        src="assets/images/blueprint/magnifying-glass.svg" title="Search icon"
                                        alt="Search icon" role="presentation" class="mb-2 searchIcon" />
                                    <span class="black search-text ml-1">Search</span>
                                </a>
                            </div>
                        </div>
                        <ng-container *ngIf="cbHelper.isUserAuthenticated() && cbHelper.isProfileInContext()">
                            <div class="counterLinks pl-2 pr-2 mt-3" [ngClass]="{'hide': isMobileDevice}">
                                <div class="row mx-0">
                                    <app-header-tickets [headerTicketList]="headerTicketList" class="ml-1 mt-2">
                                    </app-header-tickets>
                                </div>
                            </div>
                            <div class="counterLinks mt-3" [ngClass]="{'hide': isMobileDevice}">
                                <div class="row mx-0">
                                    <app-aem-messages [alerts]="alerts" class="ml-2 mt-2"></app-aem-messages>
                                </div>
                            </div>
                        </ng-container>
                        <div class="accountAvatar col-4 mt-md-0 col-lg-3 col-md-3"
                            [ngClass]="{'hide': !(cbHelper.isUserAuthenticated() || cbHelper.isProfileInContext()) || isMobileDevice}"
                            *ngIf="cbHelper.isUserAuthenticated() && appLoaded">
                            <app-mega-menu></app-mega-menu>
                        </div>
                    </div>
                </div>
            </div>

            <div class="header-gradientline mx-auto"
            [ngClass]="showHeaderBundle ? 'mt-2' : 'margin-top-5em'">
            </div>
        </div>
    </div>
</nav>