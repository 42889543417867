import { MicroserviceResponse } from "common-ui-lib";

export interface EligibleRebootDevice extends MicroserviceResponse {
    eligibleDevice: boolean;
    ineligibleCode: string;
    eligibleMessage: string;
    mobileNumber: string;
    remainingTime: string;
    caseId: string;
    userId: string;
    guid: string;
    operationType: string;
    addContactType: string;
    contactDetails: ContactDetail[];
    status: string;
	convergedVoiceDevice: boolean;
}

export interface ContactDetail {
    userContactId: string;
    type: string;
    value: string;
}

export class OtherEquipmentRebootInput {
	phoneNumber: string
	encryptedValue: string
	notificationOptIn: string
}

export class OtherEquipmentRebootStatus {
	status: string
	caseID: string
	error: ErrorCode
	caseType: string
	transactionId: string
	estimatedProcessingTime: string
}

export class ErrorCode {
	code: string
	detail: string
}

export enum RebootCaseStatus {
    REBOOTING = 'Rebooting',
    FAILURE = 'Failure',
    SUCCESS = 'Success',
    ACTIVE = 'Active',
}

export interface RebootStatusDetails extends MicroserviceResponse {
    TransactionId: string;
    CaseId: string;
    AccountNumber: string;
    CaseStatus: string;
    Error: Error;
    EstimatedPowerOn: string;
}

export class CBUser {
	guid: string
	isProfileOwner: boolean
	isProfileAdmin: boolean
	isEndUser: boolean
	isSPO: boolean
	isSPA: boolean
	coxTN: CoxTN
	login: UserContact
	contacts: UserContact[]
	firstName: string
	lastName: string
}
export class CoxTN {
	number: string
	extension: string
	account: CBAccount
}

export class CBAccount {
	accountNumber12: string
}

export class UserContact {
	id: string
	contactType: ContactType
	contactValue: string
	preferred: boolean
	isMobileNumber: boolean
}

export enum ContactType {
	EMAIL = 'EMAIL',
	ALT_EMAIL = 'ALT_EMAIL',
	CB_EMAIL = 'CB_EMAIL',
	PRIMARY_PHONE = 'PRIMARY_PHONE'
}

export enum UserType {
	PO = "PO",
	EU = "EU",
	PA = "PA",
	SPA = "SPA",
	SPO = "SPO"
}

export enum FeatureName {
	INTERNET = 'Internet',
  }