import { Injectable } from '@angular/core';
@Injectable()
export class DeviceDetectorService {

  public mobile: boolean;
  public tablet: boolean;
  public desktop: boolean;
  MOBILE_MAX_WIDTH = 767;  //Adjust as needed
  TABLET_MAX_WIDTH = 996; //Adjust as needed

  constructor() {
    this.mobile = window.innerWidth <= this.MOBILE_MAX_WIDTH;
    this.tablet = window.innerWidth <= this.TABLET_MAX_WIDTH && window.innerWidth > this.MOBILE_MAX_WIDTH;
    this.desktop = window.innerWidth > this.TABLET_MAX_WIDTH && window.innerWidth < 1600;
  }

  public isMobile(): boolean {
    return (window.innerWidth <= this.MOBILE_MAX_WIDTH) ? true : false;
  }

  public isTablet(): boolean {
    return (window.innerWidth <= this.TABLET_MAX_WIDTH && window.innerWidth > this.MOBILE_MAX_WIDTH) ? true : false;
  }

  public isDesktop(): boolean {
    return this.desktop;
  }

  public isLargeScreen(): boolean {
    return (window.innerWidth > 1599) ? true : false;
  }

  public isPageZoomed(): boolean {
    return (screen.width / window.innerWidth > 1) ? true : false;
  }

  public isPageZoomedto175(): boolean {
    return window.innerWidth < 750 ? true : false;
  }

  public isPageZoomedto400(): boolean{
    return window.innerWidth < 300 ? true : false;
   }

   public innerWidth(){
    return window.innerWidth;
   }
}
