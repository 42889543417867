import { Injectable } from '@angular/core';
import { ShowMessage } from './commonmessage.component';
import { InlineErroMsg } from './commonmessage.model';

@Injectable()
export class CommonMessageService {

  public displayMessage: ShowMessage;

  constructor() {
    this.displayMessage = new ShowMessage();
  }

  // Hide message
  public hideMessage(): void {
    this.displayMessage.show = false;
  }

  // Show message
  public showMessage(message: string | string[], type: 'success' | 'error' | 'warning' | 'info', id?: string): void {
    this.displayMessage.show = true;
    this.displayMessage.type = type;
    this.displayMessage.message = message;
    this.displayMessage.id = id;
  }

  public getErrorInfo(errors: any, errSection: string) {
    const errList = errors;
    const resultsArray = errList?.filter((err: any) => {
      const pathList = err.path;
      if (pathList?.indexOf(errSection) > -1) {
        return err;
      }
    });
    if (resultsArray?.length) {
      if (resultsArray[0]?.errorInfo !== null) {
        if (resultsArray[0]?.errorInfo?.code === '113' || resultsArray[0]?.errorInfo?.code === '117') {
          return false;
        } else {
          return resultsArray[0]?.errorInfo;
        }
      } else {
        return resultsArray[0]?.message;
      }
    }
  }

  public handlePartialError(errors: any, code: string) {
    let error = null;
    errors.forEach(err => {
      if (err.errorType === 'PARTIAL_ERROR') {
        const errorsList = err.errorInfo;
        errorsList.forEach((errorObj: any) => {
          if (errorObj.code === code) {
            error = errorObj;
          }
        });
      }
    });
    return error;
  }

  /**
   * call this method to show inline success msg
   *
   * @param id the element where we show the calss
   * @param successMsg message, which screen reader announces
   * @param msgTimer default set to 5s as per accesibility standard , set as zero when
   * @param type  message type
   */
  public addInlineSuccessicon(type: string, id: string, successMsg: string, accessible = true, msgTimer = 5000) {
    const _className = type;
    const _parentNode = document?.getElementById(id);
    const _temporaryDomId = `_tempSuccessId_${id}`;
    this.accesibilityHelper(_parentNode, successMsg, _temporaryDomId, accessible);
    if (_parentNode != null && msgTimer) {
      _parentNode.className += _className;
      setTimeout(function () {
        const elmnt = document?.getElementById(_temporaryDomId);
        elmnt?.remove();
        _parentNode.classList.remove(_className);
      }, msgTimer);
    }
  }

  /**
   * Helper method to create a dom element to announce the success msg
   * and clears out after 5sec
   *
   * @param parentNode
   * @param infoMessage
   * @param temporaryid
   */
  private accesibilityHelper(parentNode: HTMLElement, infoMessage: string, temporaryid: string, accessible: boolean) {
    const _node = document.createElement('span');
    const _textnode = document.createTextNode(infoMessage);
    _node.setAttribute('id', temporaryid);
    (accessible) ? _node.setAttribute('role', 'alert') : null;
    _node.className = 'sr-only';
    _node.appendChild(_textnode);
    parentNode.appendChild(_node);
  }

}