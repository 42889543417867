import { Injectable } from '@angular/core';
import { AEMClientService, AppConfig, CommonMessageService, CoxHttpClient, ResourceBundle, TealiumUtagService } from 'common-ui-lib';
import { Statement } from '../constants/statement.model';

@Injectable()
export class DownloadstatementService {

  public showButtonSpinner = false;
  public currentStatementSpinner = false;
  public allStatementSpinner = false;
  public getStatementUrl: string;
  private resourceBundle: ResourceBundle = {};
  constructor(public coxHttpClient: CoxHttpClient,
    public commonMessageService: CommonMessageService,
    public tealium: TealiumUtagService,
    private aemClient: AEMClientService,
    private config: AppConfig) {
    this.aemClient.getRedesignBundle('billing/billing-home')
      .subscribe((data: unknown) => {
        this.resourceBundle = data;
      }
      );
  }

  public async getStatementByteArray(
    accountNumber13: string,
    statementCode?: string,
    cycleId?: string,
    cycleDate?: string
  ): Promise<Statement> {
      this.getStatementUrl =
        `/api/cbma/billing/v2/services/billing/account/v2/statementpdf?accountNumber13=${accountNumber13}&statementCode=${statementCode}`;
      if (cycleDate) {
        this.getStatementUrl = this.getStatementUrl + '&statementPdfId=' + cycleId + '&cycleDate=' + cycleDate;
      }
    return this.coxHttpClient.getAsync<any>(this.getStatementUrl, { disableSpinner: true, customeErrorMessage: "" });
  }

  public downloadStatementAsPDFservice(accountNumber13: string, stmCode: string,
    fileName: string, messageId: string, cycleId?: string, cycleDate?: string) {
    this.showButtonSpinner = true;
    var win = window.open("about:blank", "");
    win.document.body.innerHTML = '<p>' + this.config.getConfig('downloadVerbiage') + '</p>';
    this.getStatementByteArray(accountNumber13, stmCode, cycleId, cycleDate)
      .then((data: any) => {
        if (data && data.code.toString() === '0') {
          this.tealium.trackSuccessFailureEvents('viewStmtSuccess', 'manage:view-statements:success');
          const byteCharacters = atob(<any>data.pdfData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const navigate = window.navigator as any;
          if (navigate.msSaveOrOpenBlob) {
            navigate.msSaveOrOpenBlob(blob, fileName + '.pdf');
            return;
          }
          const blobData = window.URL.createObjectURL(blob);
          win.location.href = blobData;
          // keeping the below commented code for reference, please dont remove
          // setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          // window.URL.revokeObjectURL(blobData);
          // }, 100);
        } else {
          this.tealium.trackSuccessFailureEvents('viewStmtFail', 'manage:view-statements:fail');
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', messageId);
          window.scroll(0, 0);
        }
        this.showButtonSpinner = false;
        this.currentStatementSpinner = false;
        this.allStatementSpinner = false;
      }, () => {
        this.tealium.trackSuccessFailureEvents('viewStmtFail', 'manage:view-statements:fail');
        this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', messageId);
        window.scroll(0, 0);
        this.showButtonSpinner = false;
        this.currentStatementSpinner = false;
        this.allStatementSpinner = false;
      },
      );
  }
}
