export interface ContactInfo {
    contactType: string;
    contactValue: string;
    id: string;
    isVerified?: boolean;
    isMobileNumber?: false;
    preferred?: true;
}

export enum ContactType {
    EMAIL = 'EMAIL',
    PHONE = 'PRIMARY_PHONE',
    ALTEMAIL = 'ALT_EMAIL'
}

export interface validateOtp {
    guid: string;
    entityType: string;
    entityValue: string ;
}

export interface verifyOtp {
    contactType: string;
    contactValue: string;
    token: string;
    userGuid: string;
    userId: string;
}

export enum contacttypes{
    Email= 'Email',
    phone ='phone',
    sms='sms'
}

export interface UserInput {
	firstName: string;
	lastName: string;
	userType: UserType;
	login?: ContactInfo;
    isValidationShown: boolean;
    isContactUpdateReq: boolean;
}

export enum UserType {
	PO = 'PO',
	EU = 'EU',
	PA = 'PA',
	SPA = 'SPA',
	SPO = 'SPO'
}

export enum ContactTypeAcc {
    EMAIL = 'Email',
    PRIMARY_PHONE = 'Mobile Number',
    ALT_EMAIL = 'Alternate Email'
}

export interface ValidateMethodInput {
    unVerifiedContactMethods: ContactInfo[];
    chosenContactMethod: string;
    userGuid: string;
    userId: string;
}


