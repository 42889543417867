import {Directive, ElementRef, Input} from '@angular/core';

/**
 * Used in the `mat-tab-group` view to display tab labels.
 * @docs-private
 */
// tslint:disable-next-line: max-classes-per-file
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[matTabLabelWrapper]',
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    '[class.mat-tab-disabled]': 'disabled',
  },
})
// tslint:disable-next-line: directive-class-suffix
export class MatTabLabelWrapper {
  @Input() disabled;
    constructor(public elementRef: ElementRef) {
  }

  /** Sets focus on the wrapper element */
  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  getOffsetLeft(): number {
    return this.elementRef.nativeElement.offsetLeft;
  }

  getOffsetWidth(): number {
    return this.elementRef.nativeElement.offsetWidth;
  }
}
