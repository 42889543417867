<!-- Datatable Skeleton Loader-->
<div class="col-md-12" *ngIf="datatableSkeleton" role="alert">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 " *ngFor="let rowCount of numberOfRows(10)">
            <hr class="hr mb-3">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ background: '#EBEFF0'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>
<!--Form Skeleton Loader-->
<div class="row item" [ngClass]="{'pb-0 pt-1': subsidyCards}" *ngIf="formSkeleton" role="alert">
    <div class="col-md-12 mb-3" *ngFor="let rowCount of numberOfRows(rowCount)">
        <ng-container *ngIf="!colCount; else elseTemplate">
            <div class="col-md-12">
                <ngx-skeleton-loader count="1" [theme]="{ height: '25px', background: '#EBEFF0'}"></ngx-skeleton-loader>
            </div>
        </ng-container>
        <ng-container #elseTemplate *ngFor="let colCounti of numberOfRows(colCount)">            
            <div class="col-md-12" *ngIf="colCount == '1'">
                <ng-container *ngTemplateOutlet="forSkeletonLoader"></ng-container>
            </div>
            <div class="col-md-6" *ngIf="colCount == '2'">
                <ng-container *ngTemplateOutlet="forSkeletonLoader"></ng-container>
            </div>
            <div class="col-md-4" *ngIf="colCount == '3'">
                <ng-container *ngTemplateOutlet="forSkeletonLoader"></ng-container>
            </div>
        </ng-container>
        <ng-template #forSkeletonLoader>
            <ngx-skeleton-loader count="1" [theme]="{ height: '15px', 'margin-bottom': '0', width: '80%', background: '#EBEFF0'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ background: '#EBEFF0'}"></ngx-skeleton-loader>
        </ng-template>
    </div>
</div>
<!--PickList Loader-->
<div class="row" *ngIf="pickListSkeleton" role="alert">
    <div class="col-md-12">
        <div class="col-md-4">
            <ngx-skeleton-loader count="1"  [theme]="{ height: '20em', 'margin-bottom': '0', background: '#EBEFF0'}"></ngx-skeleton-loader>
        </div>
        <div class="col-md-3 mr-4 ml-4 mt-5">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ background: '#EBEFF0'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ background: '#EBEFF0'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ background: '#EBEFF0'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ background: '#EBEFF0'}"></ngx-skeleton-loader>
        </div>
        <div class="col-md-4">
            <ngx-skeleton-loader count="1"  [theme]="{ height: '20em', 'margin-bottom': '0', background: '#EBEFF0'}"></ngx-skeleton-loader>
        </div>
    </div>
    <div class="col-md-4 offset-md-4 mt-4">
        <ngx-skeleton-loader count="1" [theme]="{ height: '30px', background: '#EBEFF0'}"></ngx-skeleton-loader>
    </div>
</div>
<!--Accordion Loader-->
<div class="col-md-12" *ngIf="accordionSkeleton" role="alert">
    <ngx-skeleton-loader count="1" [theme]="{ height: '20px', width: '40%', background: '#EBEFF0'}"></ngx-skeleton-loader>
    <ng-container *ngFor="let rowCount of numberOfRows(rowCount)">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ background: '#EBEFF0'}"></ngx-skeleton-loader>
    </ng-container>
</div>


<div class="row" *ngIf="freeformSkeleton" role="alert">
    <ngx-skeleton-loader count="{{count}}" appearance="line" [theme]="{
            'width.px': widthSizeInPixels,
            'height.px': heightSizeInPixels,
            'border-radius': borderRadiusInPixels,
            'margin': '10px'
          }">
    </ngx-skeleton-loader>
</div>

<!-- responsive width skeleton loader -->
<div *ngIf="responsiveSkeleton" role="alert">
    <ngx-skeleton-loader appearance="line"
        class="skeletons-animation-no-animation-via-binding" 
        [theme]="{
            'height.px': heightSizeInPixels,
            'border-radius': borderRadiusInPixels,
            'margin': '0px'
        }">
    </ngx-skeleton-loader>
</div>