<div class="modal-body">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0" [ngClass]="{'mt-0' : resetPasswordService.resetPwdMsg.displayMessage.show, 
    'margin-top-15' : !isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show,
    'margin-top-10px' : isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show}">
        <h5 *ngIf="!resetPasswordService.forgotPasswordFlow" class="h1 font-cerapro-regular-imp text-center">{{resourceBundle.verifyOtpTitle}}</h5>
        <p class="text-center" [ngClass]="{'margin-botton-30p-imp pad-top-p-20' : !isMobile,
        'pad-bottom-2-188rem pad-top-2-188rem' : isMobile}">{{verifyOtpDesc}}</p>
    </div>
    <div class="col-sm-12 col-md-12 col-xs-12">
        <div [ngClass]="{'display-grid justify-content-center' : !isMobile}">
            <label for="resetpwd" class="labelClass">{{resourceBundle.verifyOtpLabel}}</label>
            <input type="text" class="width-16-rem pad-left-p-10" id="otp" placeholder="--- --- --- --- --- --- "
              [attr.aria-label]="resourceBundle.verifyOtpLabel" name="otp" [(ngModel)]="otp" maxlength="6" (keyup)="enableVerifyOtpBtn($event)">
        </div>
        <div class="text-center mt-4 mb-5">
            <p>
                {{resourceBundle.verifyOtpResendLinkDesc}}
                <br *ngIf="isMobile">
                <a role="link" href="javascript:void(0)" (click)="resendOtp()" class="linkUnderline mt-4 pt-1">{{resourceBundle.verifyOtpResendLink}}</a>
            </p>
        </div>
    </div>
</div>

<hr class="hr margin-top-10" />
<div>
    <div class="col-sm-12 col-md-12 col-xs-12">
        <button class="button btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em track-clicks" (click)="validateOtp()" [value]="resourceBundle.verifyOtpVerifyButton" [disabled]="!checkOtpMaxLength">{{resourceBundle.verifyOtpVerifyButton}}</button>
        <button type="button" class="button button-secondary btn-long-text mt-2 pull-right" (click)="backToChooseOption()">
            {{resourceBundle.verifyOtpChooseOtherButton}}
        </button>
    </div>
</div>