import { Pipe, PipeTransform } from '@angular/core';
import { TextTransformRequest, TransformedResult } from './text-transform.model';

@Pipe({
  name: 'textTransform'
})
export class TextTransformPipe implements PipeTransform {

  transform(value: any, args?: string): any {
    //return null;
    switch(args || null) {
      case TextTransformRequest.turnedonn:
        return TransformedResult.turnedonn;
      case TextTransformRequest.turnedoff:
        return TransformedResult.turnedoff;
      case TextTransformRequest.read:
        return TransformedResult.read;
      case TextTransformRequest.unred:
        return TransformedResult.unred;
      case TextTransformRequest.flagged:
        return TransformedResult.flagged;
      case TextTransformRequest.last30days:
        return TransformedResult.last30days;
      case TextTransformRequest.last60days:
        return TransformedResult.last60days;
      case TextTransformRequest.last90days:
        return TransformedResult.last90days;
      default:
      return value;
    }
  }

}
