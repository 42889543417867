<div class="modal-body" [ngClass]="{'pt-0' : resetPasswordService.resetPwdMsg.displayMessage.show}">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0" [ngClass]="{'mt-0' : resetPasswordService.resetPwdMsg.displayMessage.show, 
    'margin-top-15' : !isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show,
    'margin-top-10px' : isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show}">
        <h5 *ngIf="!resetPasswordService.forgotPasswordFlow" class="h1 font-cerapro-regular-imp text-center">{{isMyAdmin ? resourceBundle.myAdminTitle : resourceBundle.title}}</h5>
        <p class="text-center" [ngClass]="{'margin-botton-30p-imp pad-top-p-20' : !isMobile,
        'pad-bottom-2-188rem pad-top-2-188rem' : isMobile}">{{isMyAdmin ? resourceBundle.myAdminResetPwdDescription : resourceBundle.resetPwdDescription}}</p>
        <ng-container *ngIf="!isMyAdmin">
            <form [formGroup]="resetPasswordForm">
                <div [ngClass]="{'display-grid justify-content-center' : !isMobile}">
                    <label for="resetpwd" [ngClass]="{'labelRedColor': !resetPasswordForm.get('email').valid && formSubmitted,'ml-2': isMobile}"
                        class="labelClass col-form-label">{{resourceBundle.emailLabel}}</label>
                    <input #resetByEmail type="text" formControlName="email" class="form-control col-12" id="resetpwd"
                        name="resetPassword" required [ngClass]="{'wide-27-5rem' : !isMobile,'wide-19rem ml-2': isMobile}">
                    <p *ngIf="!resetPasswordForm.get('email').valid && formSubmitted" class="text-danger form-error mt-1 mb-0"
                        role="alert">
                        {{ emailErrorMessage }}
                    </p>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="isMyAdmin">
            <div class="display-grid justify-content-center" [ngClass]="{'pb-5': !isMobile, 'pad-bottom-2-188rem': isMobile}">
                <div class="row">
                    <span class="pb-2"><b>{{resourceBundle.userName}}</b>{{resetPasswordService.userName}}</span>
                </div>
                <div class="row">
                    <span><b>{{resourceBundle.userId}}</b>{{resetPasswordService.userEmail}}</span>
                </div>
            </div>
        </ng-container>
    </div>
    <div id="forgotPswdRecaptcha"
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 display-flex justify-content-center margin-top-30 margin-bottom-3-125rem " *ngIf="!isMyAdmin">
        <re-captcha (resolved)="resolved($event)" #invisible [siteKey]="siteKey" [ngClass]="{'recaptcha-mobile': isMobile}"></re-captcha>
    </div>
</div>
<hr class="hr mt-0" [ngClass]="{'margin-bottom-20' : !isMobile && !showturnOffButtonSpinner}"/>
<div>
    <div class="col-sm-12 col-md-12 col-xs-12" [ngClass]="{'display-flex justify-content-flexEnd pad-right-p-20 pad-bottom-p-20' : !isMobile,'mb-3 text-center' : isMobile,
        'pb-2' : showturnOffButtonSpinner}">
        <a *ngIf="!isMobile && isMyAdmin" role="link" href="javascript:void(0)"
        class="linkUnderline margin-right-2-5rem forgotLink-alignment" [ngClass]="showturnOffButtonSpinner ? 'mt-3 pt-2' : 'mt-2 pt-1'" (click)="onValidateUserIdClose()">{{resourceBundle.cancel}}<img
        src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon" role="presentation"
        class="width-20px margin-top-neg-5 pl-1"></a>
        <a *ngIf="!isMobile && !isMyAdmin" role="link" href="javascript:void(0)" (click)="forgotUid()"
            class="linkUnderline margin-right-2-5rem forgotLink-alignment"  [ngClass]="showturnOffButtonSpinner ? 'mt-3 pt-2' : 'mt-2 pt-1'">{{resourceBundle.forgotUid}}<img
            src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon" role="presentation"
            class="width-20px margin-top-neg-5 pl-1"></a>
        <button class="button track-clicks" [ngClass]="{'chooseContactMethodsBtn mt-0 mb-0' : !isMobile}" *ngIf="!showturnOffButtonSpinner" [value]="resourceBundle.continue"
            [disabled]="isMyAdmin ? !(resetPasswordService.userEmail.length>0) : (!captchaValue || !this.resetPasswordForm.get('email').value)"
            (click)="isMyAdmin ? chooseContactMethods(resetPasswordService.userEmail) : chooseContactMethods(resetPasswordForm.get('email').value)">{{resourceBundle.continue}}</button>
        <div *ngIf="showturnOffButtonSpinner" class="position-relative">
            <button class="button continueButton btn-long-text mb-2" [ngClass]="isMobile ? 'spinButton-mobile' : 'spinButton'" type="button"
                aria-label="Button spinner"></button>
            <div class="button-spinner cb-spinner" [ngClass]="isMobile ? 'button-spinner-mobile' : 'buttonSpinner'">
                <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100"
                    alt="Processing... please wait">
                    <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
                        style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%;">
                    </circle>
                </svg>
            </div>
        </div>
        <button *ngIf="isMobile && !isMyAdmin" type="button" class="button button-secondary btn-long-text mt-1" (click)="forgotUid()">
            {{resourceBundle.forgotUid}}</button>
        <a *ngIf="isMobile && isMyAdmin" role="link" href="javascript:void(0)" class="linkUnderline mt-1" (click)="onValidateUserIdClose()"
            aria-label="Cancel">{{resourceBundle.cancel}}<img
            src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon" role="presentation"
            class="width-20px margin-top-neg-5 pl-1"></a>     
    </div>
</div>
