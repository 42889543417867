import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UnsavedChangesPromptComponent } from './unsavedPrompt.component';

@Injectable()
export class UnsavedPromptService {
    private _customMessage: string = null;
    private _handlerComponent: UnsavedChangesPromptComponent;
    private _hasChanges: boolean | (() => boolean) = false;

    constructor(router: Router) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.hasChanges = false;
            }
        });
    }

    public get hasChanges(): boolean | (() => boolean) {
        if (typeof this._hasChanges === 'function') {
            return this._hasChanges();
        }
        return this._hasChanges;
    }

    public set hasChanges(value: boolean | (() => boolean)) {
        this._hasChanges = value;
    }

    public get promptMessage(): string {
        return this._customMessage;
    }

    public set promptMessage(value: string) {
        this._customMessage = value;
    }

    public set promptHandler(value: UnsavedChangesPromptComponent) {
        this._handlerComponent = value;
    }

    public showPrompt(): Observable<boolean> | boolean {
        if (!this._handlerComponent) {
            console.warn('Unsaved prompt service: handler component not set!');
            return true;
        }
        return this._handlerComponent.showModal();
    }
}
