<!-- Header for HN view -->
<div class="constrain-grid mx-auto">
    <div *ngIf="cbHelper.isHNScenario">
        <app-customerheader></app-customerheader>
    </div>
</div>

<!-- Header for non HN view -->
<div class="skip-boot fixedHeader dsm-grid mx-auto" *ngIf="!cbHelper.isHNScenario && !hideHeader">
    <app-cb-header></app-cb-header>
</div>

<!-- Body -->
<div class="mx-auto" id="mainGrid"
    [ngClass]=" {'constrain-grid-capp-996-px': isCappPage, 'dsm-grid': viewFullScreen, 'constrain-grid': !viewFullScreen && !isCappPage}">
    <main role="main" id="mainContent" tabindex="-1" class="min-height-200p">
        <router-outlet></router-outlet>
        <app-chatdiv></app-chatdiv>
    </main>
</div>

<!-- Footer for non HN view -->
<div class="dsm-grid mx-auto" *ngIf="!cbHelper.isHNScenario && !hideFooter">
    <app-default-footer></app-default-footer>
</div>