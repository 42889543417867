<div class="modal-content">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 modalheader modal-header">
    <div class="col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
      <h4 class="modal-title line-height-28" id="dialogHeader" attr.aria-label="{{resourceBundle.opensDialog}} {{resourceBundle.fiberInternetTitle}}
       {{resourceBundle.headingLevel}}"> {{resourceBundle.fiberInternetTitle}}</h4>
    </div>
    <div>
      <a href="javascript:void(0)" role="button" class="cb-modal-close-btn" id="closeBtn" aria-label="Close"
        (click)="close('Close')" appModalfocus></a>
    </div>
  </div>
  <div class="modal-body scrollbox">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <app-commonmessage id="fttxErrorMsg" [type]="commonMessageService.displayMessage.type"
        [globalMessage]="commonMessageService.displayMessage.message" [level]="'globalPage'"
        *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id == 'getFttxDetails'">
      </app-commonmessage>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pb-2 mb-3">
        {{resourceBundle.fiberInternetDesc}} <a href="{{resourceBundle.fiberInternetInfoUrl}}" class="linkUnderline"
          target="_blank" role="link" attr.aria-label="{{resourceBundle.fiberInternetLink}}">
          {{resourceBundle.here}}</a> {{resourceBundle.fiberInternetDesc1}}
      </div>
      <ng-container>
        <ng-container *ngIf="isMobileDevice">
          <ng-container *ngTemplateOutlet="promotionalFttxMobileView"></ng-container>
        </ng-container>
        <ng-container *ngIf="!isMobileDevice">
          <ng-container *ngTemplateOutlet="promotionalFttxDesktopView"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <hr class="hr my-0">
  <div class="col-lg-12 col-md-12 col-sm-12 col-12 py-2 pr-4">
    <div class="col-md-5 col-sm-12 pull-right px-0">
      <button class="button pull-right" aria-label="Done" (click)="close('Done')">{{resourceBundle.doneBtn}}</button>
    </div>
  </div>
  <ng-template #promotionalFttxMobileView>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" *ngIf="contentLoad; else loadSpinnerTemplate">
      <ngx-datatable class="dataTable bootstrap mb-4" comnAccessTable [noofRows]="fiberInternetRows.length"
        [tableName]="'Fiber Internet Details'" role="table" [table]="fiberInternetDetails" id="fiberInternetDetails"
        #fiberInternetDetails [reorderable]="false" [columnMode]="'force'" [rows]="fiberInternetRows"
        [scrollbarV]="false" [rowHeight]="'auto'" [headerHeight]="'auto'">
        <ngx-datatable-column [sortable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
            <div class="bold">{{resourceBundle.accountAlias}}</div>
            <div>{{row.accountAlias}}</div>
            <div class="bold mt-2">{{resourceBundle.serviceAddress}}</div>
            <div>{{row.serviceAddress}}</div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </ng-template>

  <ng-template #promotionalFttxDesktopView>
    <div class="col-xs-12 col-lg-12 col-md-12 col-sm-12">
      <div *ngIf="contentLoad; else loadSpinnerTemplate">
        <ngx-datatable class="dataTable bootstrap mb-4 redesignTableHeader" comnAccessTable [noofRows]="fiberInternetRows.length"
          [tableName]="'Fiber Internet Details'" role="table" [table]="fiberInternetDetails" id="fiberInternetDetails"
          #fiberInternetDetails [reorderable]="false" [columnMode]="'force'" [rows]="fiberInternetRows"
          [scrollbarV]="false" [rowHeight]="'auto'" [headerHeight]="'auto'">
          <ngx-datatable-column name="Account Alias" prop="accountAlias" [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Service Address" prop="serviceAddress" [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
              <div class="row-text-overflow"
                title="{{value}}">
                {{value}}
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>

  </ng-template>
</div>
<ng-template #loadSpinnerTemplate>
  <div class="mt-3">
    <app-skeletonloader accordionName="Fiber Internet Details" accordionSkeleton="true" rowCount="2">
    </app-skeletonloader>
  </div>
</ng-template>
