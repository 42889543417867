import { Injectable } from '@angular/core';

@Injectable()
export class VoiceGlobals {
  userName: string;
  firstName: string;
  lastName: string;
  userType: string;
  serviceCodes: string;
  assignedPhoneNumber: string;
  accountNumber: string;
  package?: string;
  hasChildAccount ? = false;
  isGlobalError ? = false;
  timeZone?: string;
  isFoneAdmin ? = false;
  nonceEpochOffset:number;
}
