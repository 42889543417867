<div id="onboardingFooter" class="padding-top-120">
  <div class="onboarding-main-footer">
    <div class="footer-content" id="toaster-care">
      <img src="assets/images/blueprint/onboarding/ob-toaster-care.svg" class="ae-img mt-3 mr-2" alt="ob-toaster-care">
      <span class="pt-1 mediumfont">Contact Customer Service</span>
      <br>
      <div class="margin-top-neg-20 margin-left-neg-2 mediumfont pl-2">1-888-876-3882</div>
    </div>
  </div>
  <div class="onboarding-footer pt-0">
  <div class="header-gradientline"></div>
  <p class="bold content-clr pl-4 pt-2 text-center"> © 1998 - 2021 Cox Communications, Inc.
  </p>
  </div>
</div>
