<div class="col-12 p-0 display-flex">
    <ng-container>
        <ng-template [ngTemplateOutlet]="showContentView ? menuView : tileView"></ng-template>
    </ng-container>
</div>

<!-- Tiles content template-->
<ng-template #tileView>
    <div id="tilesWrapper" class="col-12 p-0 tiles-wrapper" [ngClass]="{'margin-bottom-3-125rem': isMobileView}">
        <ng-container *ngFor="let category of tileCategories; let i=index">
            <a class="tile-section" href="javascript:void(0)" tabindex="0" *ngIf="category.isEnabled"
            attr.aria-label="{{category.displayname}} {{category.description}} tile expand" [attr.aria-disabled]="!category.authorized && category.shortname === 'VMC'" role="link"
                (click)="tileSelected('tile' + i, category)" [ngClass]="{'pointer-none-1': !category.authorized && category.shortname === 'VMC'}">
                <ng-container *ngTemplateOutlet="tileTemplate; context: {$implicit: category}"></ng-container>
            </a>
        </ng-container>
    </div>
</ng-template>

<!--Each tile menu item content template -->
<ng-template #menuView>
    <ul ngbNav #menuTabs="ngbNav" [(activeId)]="activeTile" id="menuTiles" class="menu-tiles nav-pills vertical"
        [keyboard]="true" orientation="vertical">
        <ng-container *ngFor="let category of tileCategories; let i=index">
            <li [ngbNavItem]="'tile' + i" *ngIf="category.isEnabled">
                <a ngbNavLink (click)="menuTileChange('tile' + i, category)" [attr.aria-disabled]="!category.authorized && category.shortname === 'VMC'"
                    [attr.aria-label]="activeTile === 'tile' + i ? category.displayname + ' tile collapse' : category.displayname + ' tile expand'" [ngClass]="{'pointer-none-2': !category.authorized && category.shortname === 'VMC'}" aria-describedby="user-dashboard-tile-description">
                    <ng-container *ngTemplateOutlet="tileTemplate; context: {$implicit: category}"></ng-container>
                </a>
            </li>
        </ng-container>
    </ul>
    <div id="menu-tabs" class="margin-left-20">
        <div class="menu-header pr-3" *ngIf="showContentView">
            <h1 class="col-11 pad-left-20 pr-0" [ngClass]="{ 'h3': !isTabletView, 'h6': isTabletView }">
                {{menuHeader}}</h1>
            <img class="menu-header-close mouse-link" [ngClass]="{ 'mt-1': !isTabletView }" id="tileContentClose"
                tabindex="0" attr.aria-label="Close {{menuHeader}}" role="button" width="24" height="24"
                (click)="onClickOfCloseButton()" src="assets/images/close_white.svg" alt="Close">
        </div>
        <div class="col-12 p-0">
            <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
        </div>
    </div>
</ng-template>