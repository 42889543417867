import { Pipe, PipeTransform } from '@angular/core';
import { Regex } from '../../constants/regex.model';

@Pipe({
    name: 'appSearch',
})

export class SearchPipe implements PipeTransform {
    /**
     * @param items from array
     * @param searchText search keyword
     * @return array of filtered items
     */
    transform<T>(items: T[], searchText: string,
        args: {
            minSearchLength?: number; includeCols?: string[];
            specificCols?: string[]; strict?: boolean;
        } = {}) {
        if (!searchText || !items || (args.minSearchLength && searchText.length < args.minSearchLength)) {
            return items;
        }
        return this.filterContent(items, searchText, args.strict, args.includeCols, args.specificCols);
    }

    private filterContent<T>(items: T[], searchText: string, strict: boolean, includeCols: string[],
        specificCols: string[]) {
        return items.filter((item) => this.checkContent(item, searchText, strict, includeCols, specificCols));
    }

    private checkContent<T>(item: T, searchText: string, strict: boolean = false, includeCols: string[] = [],
        specificCols: string[] = []) {
        let textToCompare = searchText.toLowerCase();
        if (typeof item === 'string' && item.toString().toLowerCase().includes(textToCompare)) {
            return true;
        }
        let textOccurence = 0;
        for (const key in item) {
            textToCompare = searchText.toLowerCase();
            if (item[key] === null || item[key] === undefined || (includeCols.length != 0 && !includeCols.includes(key))) {
                continue;
            }
            if ((key === 'phoneNumber' || key === 'searchPhoneNumber') && (searchText.match(Regex.PHONE_PATTERN_REGEX) || searchText === '-')) {           //searchPhoneNumber key will contain Phone Numbers without hyphen and phoneNumber key will have phone numbers the way it is being sent from MW (with or without hyphen).
                textToCompare = searchText.split('-').join('');
            }
            if (searchText.match(/(\-)\1{1}/)) {
                return false;
            }
            if (typeof item[key] === 'object') {
                if (this.checkContent(item[key], searchText, strict)) {
                    return true;
                }
            }
            if (!strict && JSON.stringify(item[key]).toLowerCase().includes(textToCompare)) {
                this.checkSearchPresentCols(specificCols, textOccurence, item, key);
                return true;
            } else if (strict && item[key].toString().toLowerCase() === textToCompare) {
                this.checkSearchPresentCols(specificCols, textOccurence, item, key);
                return true;
            }

            // hold a local variable as count, increment if present atleast once ->
            // if > 0 then set the value else make it false;
        }
        return false;
    }

    private checkSearchPresentCols(columns: string[], occurance: number, item: any, key: any) {
        if (columns.length > 0) {
            if (columns.includes(key)) {
                occurance++;
            }
            if (item['searchAvailable']) {
                item['searchAvailable'] = (occurance > 0) ? true : false;
            } else {
                return true;
            }
        }
    }
}
