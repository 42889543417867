import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable } from 'rxjs';
import { Role } from '../constants/role.model';
import { CBHelperService } from '../services/cbhelper.service';
import { LoginProfileClient } from '../services/loginprofileclient.service';
import { AppConfig } from '../core/app.config';
import { LocalStorageKeys } from '../constants/storage.model';

@Injectable()
export class TealiumBuilderService {
  jsonData: string[] = [];
  jwtHelper: JwtHelperService = new JwtHelperService();
  public checkSimpleAccData = false;
  private noCaasDetails = null;
  constructor(
    private http: HttpClient,
    private route: Router,
    private deviceService: DeviceDetectorService,
    private _storageService: LocalStorageService,
    private location: Location,
    private loginService: LoginProfileClient,
    public cbHelper: CBHelperService,
    private config: AppConfig,) {
    this.getJSON();
     }

  public async buildTealiumObj(event?: any, accArray?: any[]): Promise<Observable<any>> {
    const utag_data: any = {};
    const hostname = window.location.hostname;
    const domain = hostname.split('.')[0];
    let userType = '';
    const loggedInUser = JSON.parse(this._storageService.get(LocalStorageKeys.LoggedInUserDetails, 'cbma'));
    const authToken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    const decodedToken = authToken ? this.jwtHelper.decodeToken(authToken) : '';
    if (loggedInUser) {
      userType = loggedInUser.userType;
    }
    const _url = this.location.prepareExternalUrl(this.location.path());
    utag_data.businessUnit = 'bus:' + domain;
    utag_data.channel = 'cox:bus:' + domain;
    utag_data.dateStamp = Math.floor(new Date() as any / 1000);
    utag_data.language = 'en';
    if (this.deviceService.isMobile()) {
      utag_data.responsiveDisplayType = 'mobile';
    } else if (this.deviceService.isTablet()) {
      utag_data.responsiveDisplayType = 'tablet';
    } else if (this.deviceService.isDesktop()) {
      utag_data.responsiveDisplayType = 'desktop';
    }
    utag_data.visitorServiceability = 'serviceable';
    if (userType) {
      if (userType === 'PROFILE_ADMIN') {
        utag_data.visitorAuthenticatedUserRole = 'cb:pa';
      } else if (userType === 'PROFILE_OWNER') {
        utag_data.visitorAuthenticatedUserRole = 'cb:po';
      } else {
        utag_data.visitorAuthenticatedUserRole = 'cb:usr';
      }
    }

    if (!this.cbHelper.isCbmaAuthTokenExpired() && this.cbHelper.isUserAuthenticated() && !this.cbHelper.isMyAdmin()) {
      this.checkSimpleAccData = true;
      if (event?.tealium === 'add-account-billing' || event === 'remove-account') {
        this.loginService.setSpecialCaseUDOAccounts();
      } else if (accArray?.length > 0) {
        this.loginService.setUDOAccounts(accArray);
      } else if (!this.loginService.udoAccounts) {
        this.loginService.setSpecialCaseUDOAccounts();
      }
      // utag cAccontNbrCount for getting active accounts in a profile
      window.utag_data.cAccontNbrCount = this.loginService.activeAccountsInProfile;
    }

    utag_data.pageUrl = _url;
    if (this.cbHelper.isCalnet) {
      utag_data.profile_type = 'calnet';
    } else {
      utag_data.profile_type = '';
    }
    utag_data.pageType = 'cb:' + domain;
    utag_data.visitorType = 'customer';
    if (event && event['bundle']) {
      utag_data.pageName = `cox:bus:${domain}:${event['bundle']}:${this.teliumPageName()}`;
    } else {
      utag_data.pageName = `cox:bus:${domain}:${this.teliumPageName()}`;
    }
    if(event && event['bundle'] === 'Voice') {
        utag_data.subsection = 'cb:bus:' + domain + ':' + 'voicemanager';
        const urlsplit = _url.split('/');
        utag_data.tabName = urlsplit[urlsplit.length - 2];
    }

    utag_data.customerType = 'existing customer';
    utag_data.visitorLoginStatus = 'Not logged in';
    if (decodedToken && decodedToken.profile) {
      const decodedTokenProfileObject = JSON.parse(decodedToken.profile);
      if (decodedTokenProfileObject && decodedTokenProfileObject.userToken) {
        utag_data.profileGuid = decodedTokenProfileObject.userToken.profileGuid;
        utag_data.userGuid = decodedTokenProfileObject.userToken.guid;

        if (decodedTokenProfileObject.userToken.loggedInUserEmail) {
          utag_data.impersonateeUserGuid = decodedTokenProfileObject.userToken.profileGuid;
          utag_data.visitorLoginStatus = 'logged in - csr as usr';
        } else {
          utag_data.visitorLoginStatus = 'logged in';
        }
      }
      if (this.cbHelper.isUserAuthenticated() && decodedTokenProfileObject.roles.includes(Role.ONLINE_TICKETING_ROLE)) {
        utag_data.cbot_access = 'true';
      } else {
        utag_data.cbot_access = 'false';
      }

      if (this.cbHelper.isUserAuthenticated() && decodedTokenProfileObject.roles.includes(Role.NO_CAAS_ROLE)) {
        utag_data.nocaas_access = 'true';
      } else {
        utag_data.nocaas_access = 'false';
      }
      utag_data.first_name = decodedTokenProfileObject.userToken.firstName;
      utag_data.last_name = decodedTokenProfileObject.userToken.lastName;
      utag_data.phone = decodedTokenProfileObject.userToken.phoneNumber;
      utag_data.userID = decodedTokenProfileObject.userToken.email;

      // Setting No CAAS detail in UDO object
      if (this.cbHelper.isUserAuthenticated() && this.cbHelper.isProfileInContext()) {
        if (!this.noCaasDetails) {
          const noCaasStorageDetails = this._storageService.get(LocalStorageKeys.NoCaasDetails, 'cbma');
          if (noCaasStorageDetails) {
            this.noCaasDetails = JSON.parse(noCaasStorageDetails);
          } else {
            const id = decodedTokenProfileObject.userToken.id;
            this.noCaasDetails = await this.loginService.getNoCaasDetails(id);
            this._storageService.set(LocalStorageKeys.NoCaasDetails, JSON.stringify(this.noCaasDetails));
          }
        }
        if (this.noCaasDetails && this.noCaasDetails.code === '0') {
          utag_data.cox_business_email = this.noCaasDetails.cbEmail;
          utag_data.alternate_email_address = this.noCaasDetails.alternateEmail;
          utag_data.cox_business_phone = this.noCaasDetails.phoneNumber;
          utag_data.customer_name = this.noCaasDetails.customerName;
        }
      }
    } else {
      utag_data.cbot_access = 'false';
    }
    utag_data.optedin = true;
    return utag_data;
  }

  // Method to generate page name in tealium tags
  // By taking current url using Router service, we checks for path parameter first,
  // if path params are present ,it will return url by removing params
  // else it will check for query params, and will return url by removing query params
  // if no paramter is present then simple url will be returned
  // in each case , in returned url we are replacing / by :
  private teliumPageName(): string {
   const currentUrl = this.route.url;
   const returnedUrl = this.removePathPrams(currentUrl);
   if (returnedUrl) {
     return returnedUrl;
   } else if (currentUrl && currentUrl.includes('?')) {
     const urlParts = currentUrl.split('?');
     return urlParts[0].substr(1).replace(/\//g, ':');
   } else {
     return currentUrl.substr(1).replace(/\//g, ':');
   }
 }

 // If current URL matches with any of the URL from JsonData , then we are returning that URL from Json file 
 // or we can say, we removing path parameters from the current URL
 private removePathPrams(url: string): string {
   if (this.jsonData && this.jsonData.length > 0) {
     for (const m of this.jsonData) {
      if (url.includes(m)) {
        const index = m.lastIndexOf('/');
        return m.substring(1, index).replace(/\//g, ':');
      }
     }
   }
 }

 // This method calls a json file that has list of URL's with path parameters.
 private async getJSON() {
  return new Promise((resolve, reject) => {
   const request = this.http.get('assets/config/tealium_page_names.json');
   if (request) {
     request
       .subscribe((responseData: any) => {
             this.jsonData =  responseData.pathParamUrls;
             resolve(responseData.pathParamUrls);
       }, (error) => {
         console.log(error);
         reject(error);
       });
   }
 });
}

}
