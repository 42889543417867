import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'ngx-localstorage';
import { SessionStorageService } from 'ngx-webstorage';
// import { timer } from 'rxjs/internal/observable/timer';
import { switchMap, takeUntil } from 'rxjs/operators';
// import { Subject } from 'rxjs';
import { Subscription, Subject, timer } from 'rxjs';
import 'select2';
import { AppConfig } from '../../core/app.config';
import { AEMClientService, ResourceBundle } from '../../services/aemclient.service';
import { CBHelperService } from '../../services/cbhelper.service';
import { LoginProfileClient } from '../../services/loginprofileclient.service';
import { SplitIdentifierService } from '../../services/split-idententifier.service';
import { TealiumUtagService } from '../../tealium/utag.service';
import { ErrorMessageService } from '../messages/error-message.service';
import { VoiceGlobals } from '../models/globals/voice.globals';
import { Select2libComponent, Select2OptionData } from '../ng2-select2/lib/ng2-select2';
import { PhonePipe } from '../pipes/phone.pipe';
import { Idmapping, SwitchuserData, User, VoicemailInventory } from './welcomewidget.model';
import { WelcomeWidgetService } from './welcomewidget.service';
import { EditUserService } from '../../services/edituser.service';
import { LocalStorageKeys } from 'common-ui-lib';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-welcome-widget',
  templateUrl: './welcomewidget.component.html',
  styleUrls: ['./welcomewidget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WelcomeWidgetComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {
  @Input() showBrand: boolean;
  @Input() accessedAs: string;
  @Input() isVoicemail: boolean;
  @Output() backToList: EventEmitter<any> = new EventEmitter<any>();
  //@ViewChild('userselect2') userselect2: Select2libComponent;

  userName: string;
  loggedInUserDetailsObj: any;
  firstName: string;
  element: any;
  public selectedUser: string;
  public usersList: SwitchuserData[];
  public users: User[];
  public userOptions: Select2OptionData[];
  public newArr: Select2OptionData[];
  public selectedUserOption: string;
  public isMyadmin: boolean;
  masterUserFirstName: string;
  lastName: string;
  contactPhone: string;
  assignedPhoneNumber?: string;
  userType: string;
  formattedUserType: string;
  formattedCBMAUserType: string;
  email: string;
  serviceCodes: string;
  package?: string;
  hasChildAccount = false;
  showLinks = false;
  voiceOnly = false;
  showMyAdmin = false;
  impersonatedUser: string;
  hasVoicePermissions = false;
  callforwarding = false;
  voicemail = false;
  voicemanagertools = false;
  businessEmailUrl: string;
  jwtHelper: JwtHelperService = new JwtHelperService();
  resourceBundle: ResourceBundle = {};
  backLink: any;
  isImpersonated = false;
  baseUserList: any;
  enableBackToFeatureLink: boolean;
  phonePipe: PhonePipe = new PhonePipe();
  featureSettingLink: any;
  editTab: any;
  isCSR: boolean;
  isHoteling = false;
  isSplit: boolean;
  private onDestroy$ = new Subject<boolean>();
  moreIcon = false;
  popoverPlacement = 'bottom';
  quickLinksArray: any[];
  administrationquickLinkList: any[];
  voiceQuickLinkList: any[];
  subscriptions: Subscription[] = [];
  coxAdministrationFlag: boolean;
  userProfile: any;
  userId: any;
  loggedInUserType: any;
  businessEmailLinkUrl = '';
  more = 'More';
  profileQuickLinkList: any[] = [];
  role: any[];
  businessEmailLinkList: any[] = [];
  accountAdminLinkList: any[] = [];
  profileAdminLinkList: any[] = [];
  coxAdminLinkList: any[] = [];
  notificationList: any[] = [];
  productAdminList: any[] = [];
  loginAs = false;
  manageUser = false;
  subscription: Subscription;
  unreadVoicemail: string;
  showBadge: boolean;
  @ViewChild('tip1') tip1: NgbPopover;
  @ViewChild('tip3') tip3: NgbPopover;
  showVoiceMailBadge: boolean;
  userID: any;
  unread: any;
  showNotification: boolean;
  notificationCount: any;
  quicklinkArray: any[] = [];
  aeRole = false;
  eaRole = false;
  berRole = false;
  showBusinessEmailNotification = false;
  businessEmailnotificationCount: string;
  idmapping: Idmapping[];
  public swtchbutton = false;
  public link = '';
  public usertype: string;
  public hideswitchuser = false;
  public securityPermEU = false;
  public dropdownopen = false;
  public switchuserflag: boolean;
  public poUserObj: Select2OptionData;
  public currentpage: string;
  public templatefunction = (TemplateResult: Select2OptionData): JQuery | string => {
    if (!TemplateResult.type) {
      return TemplateResult.text;
    }
    return jQuery('<b>' + TemplateResult.text + '</b>');
  }
  constructor(private _storageService: LocalStorageService,
    private sessionStorage: SessionStorageService,
    // private spinnerService: Ng4LoadingSpinnerService,
    private cookieService: CookieService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private loginclient: LoginProfileClient,
    private voiceGlobalRef: VoiceGlobals,
    private activatedRoute: ActivatedRoute,
    private editUser: EditUserService,
    private config: AppConfig,
    public cbHelperService: CBHelperService,
    private welcomeWidgetService: WelcomeWidgetService,
    private aemClient: AEMClientService,
    public router: Router,
    public errorMessageService: ErrorMessageService,
    private splitIdentifierService: SplitIdentifierService,
    private tealium: TealiumUtagService) {

    this.aemClient.getSharedBundle('welcomeWidgetResourceBundles')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
        },
        (error) => {
        },
      );
    this.isMyadmin = this.cbHelperService.isMyAdmin();
    const currentUrlData = this.activatedRoute.snapshot.data;
    if (currentUrlData.showAccessedAs) {
      const userAuthToken = this._storageService.get(LocalStorageKeys.UserToken, 'cbma');
      if (userAuthToken !== null && userAuthToken !== undefined) {
        const decodedToken = this.jwtHelper.decodeToken(userAuthToken);
        const userProfile = JSON.parse(decodedToken.profile);
        // To-Do read from temp token
        let accessedAs = userProfile.userToken.lastName + ', '
          + userProfile.userToken.firstName;
        accessedAs += (userProfile.userToken.assignedPhoneNumber ? ' (' +
          this.phonePipe.transform(userProfile.userToken.assignedPhoneNumber) + ')' : '');
        this.accessedAs = accessedAs;
        if (currentUrlData.showBackToFeatureLink) {
          this.enableBackToFeatureLink = true;
        } else {
          this.enableBackToFeatureLink = false;
        }
      }
    } else {
      this.accessedAs = null;
      this.enableBackToFeatureLink = false;
    }
    this.isSplit = this.splitIdentifierService.getSplitStatus();
  }

  ngOnInit() {
    if (!this.cbHelperService.isUserAuthenticated()) {
      return;
    }
    this.welcomeWidgetService.refreshUserList.subscribe(() => { this.getUserList(); });
    this.userProfile = this.cbHelperService.getLoggedInUserDetails().profile.userToken;
    this.userID = this.userProfile.id;
    this.role = this.cbHelperService.getLoggedInUserDetails().profile.roles;
    this.loggedInUserType = this.userProfile.loggedInUserType;
    this.userType = this.userProfile.userType;
    if ((this.userType === 'END_USER') && (this.loggedInUserType === null)) {
      this.hideswitchuser = true;
    }
    if((this.userType === 'END_USER') && this.role.includes('adcssr')){
      this.securityPermEU = true;
    }
    this.businessEmailUrl = this.config.getConfig('getBusinessEmailUrl');
    this.switchuserflag = true;
    this.loadQuickLink();
    if ((this.loggedInUserType === null || this.userProfile.loggedInUserEmail === this.userProfile.email) &&
      this.role && this.role.includes('vmr')) {
      this.getVoicemailInventory();
    }
    this.getreadUnreadMessagesCall();
    this.getUserList();
  }

  ngAfterViewInit() {
    this.welcomeWidgetService.popover = this.tip3;
  }

  setLoadSelect2() {
    this.element = this.elementRef.nativeElement.querySelector('#switchdivtest');
    //this.welcomeWidgetService.select2 = this.userselect2;
  }

  getVoicemailInventory() {
    this.subscription = timer(0, 300000).pipe(
      switchMap(() => this.welcomeWidgetService.getVoicemailInventory()),
    ).subscribe((response: VoicemailInventory) => {
      this.setUnreadVoiceMailCount(response.unread);
    });
  }

  setUnreadVoiceMailCount(unread: string) {
    if (unread && (unread !== '0')) {
      this.showVoiceMailBadge = true;
      unread.length === 1 ? this.unreadVoicemail = ('0' + unread).slice(-2) : this.unreadVoicemail = unread;
    } else {
      this.showVoiceMailBadge = false;
    }
  }
  // TODO: should be refactored!
  // tslint:disable-next-line: cognitive-complexity
  ngOnChanges() {
    if (this.activatedRoute.snapshot.data['bundle'] === 'Voice') {
      this.voiceOnly = true;
    }
    this.editTab = this.activatedRoute.snapshot.data['tab'];
    if (this.router.url.includes('hoteling')) {
      this.isHoteling = true;
    } else {
      this.backLink = 'https://'
        + window.location.hostname + '/cbma/usermanagement/userandsysmanagement/userfeaturesettings';
    }

    this.userName = this.voiceGlobalRef.userName;
    this.userType = this.voiceGlobalRef.userType;
    this.firstName = this.voiceGlobalRef.firstName;
    this.lastName = this.voiceGlobalRef.lastName;
    this.serviceCodes = this.voiceGlobalRef.serviceCodes;
    this.assignedPhoneNumber = this.voiceGlobalRef.assignedPhoneNumber;
    this.package = this.voiceGlobalRef.package;
    this.hasChildAccount = this.voiceGlobalRef.hasChildAccount;
    this.hasVoicePermissions = this.voiceGlobalRef.isFoneAdmin;
    this.masterUserFirstName = this.firstName;
    if (this.userType === 'PROFILE_OWNER' || this.userType === 'PROFILE_ADMIN' || this.userType === 'CSR') {
      this.showLinks = true;
    }
    const tmpUserType = this.userType === 'PROFILE_ADMIN' ? 'PROFILE_ADMINISTRATOR' : this.userType;
    this.formattedUserType = tmpUserType ? tmpUserType.replace(/_/g, ' ') : '';
    this.formattedCBMAUserType = this.formattedUserType;

    if (this.cbHelperService.isUserAuthenticated()) {
      const permissions = this.cbHelperService.getLoggedInUserDetails().profile.roles;
      if (permissions.includes('cfr')) {
        this.callforwarding = true;
      }
      if (permissions.includes('vmr')) {
        this.voicemail = true;
      }
      if (permissions.includes('vmgr')) {
        this.voicemanagertools = true;
      }
      if (!this.isMyadmin) {
        if (null !== this.cbHelperService.getLoggedInUserDetails().profile.userToken.loggedInUserEmail
          && undefined !== this.cbHelperService.getLoggedInUserDetails().profile.userToken.loggedInUserEmail
          && '' !== this.cbHelperService.getLoggedInUserDetails().profile.userToken.loggedInUserEmail
          && (this.cbHelperService.getLoggedInUserDetails().profile.userToken.loggedInUserEmail !==
            this.cbHelperService.getLoggedInUserDetails().profile.userToken.email)) {
          this.isImpersonated = true;
          this.masterUserFirstName = this.cbHelperService.getLoggedInUserDetails()
            .profile.userToken.loggedInUserFirstName;
        }
      } else {
        this.masterUserFirstName = this.cbHelperService.getLoggedInUserDetails()
          .profile.userToken.loggedInUserFirstName;
        // to display MyAdmin home link only for CSR users
        const loggedInUserType = this.cbHelperService.getLoggedInUserDetails().profile.userToken.loggedInUserType;
        this.isCSR = (loggedInUserType === 'CSR');
      }
    }
    if (!this.voiceOnly) {
      const parsedToken = this.cbHelperService.getLoggedInUserDetails().profile;
      this.contactPhone = parsedToken.userToken.phoneNumber;
      this.email = parsedToken.userToken.email;
      if (parsedToken.userToken.loggedInUserEmail) {
        this.showMyAdmin = true;
        this.impersonatedUser = parsedToken.userToken.loggedInUserEmail;
      }
    }
  }

  public updateNumbers() {
    this.assignedPhoneNumber = this.voiceGlobalRef.assignedPhoneNumber;
  }

  onEditClick() {
    this.sessionStorage.store('isEditUser', true);
    const editUserUrl = '/administration/profileadministration/manageuser/edituser';
    if (!this.cbHelperService.isHNCustomer) {
      if ((window.location.href.indexOf('/edituser') === -1)) {
        // this.router.navigate([editUserUrl]);
        this.router.navigate(['/admin/user/edit'], {queryParams: {guid: this.userProfile.guid, path: true}});
      } else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.sessionStorage.store('isEditUser', true);
          // this.router.navigate([editUserUrl]);
          this.router.navigate(['/admin/user/edit'], {queryParams: {guid: this.userProfile.guid, path: true}});
        });
      }
    } else {
      // tslint:disable-next-line: no-hardcoded-credentials
      this.router.navigate([editUserUrl], { queryParams: { changepassword: 'true' } });
    }
  }

  emitBackToListEvent() {
    this.backToList.emit();
  }
  onInfoLinkClick() {
    if ((window.location.href.indexOf('/updatecontactinformation') === -1)) {
      this.router.navigate(['/administration/profileadministration/updatecontactinformation']);
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/administration/profileadministration/updatecontactinformation']));
    }
  }

  backToFeatureLinkClick() {
    if (this.editTab) {
      this.router.navigate(['/voice/userandsysmanagement/userfeaturesettings/edit'],
        { queryParams: { tab: this.editTab } });
    } else {
      this.router.navigate(['/voice/userandsysmanagement/userfeaturesettings/edit']);
    }
  }
  redirectionHandler(url: string) {
    if (this.isSplit) {
      window.location.href = window.location.protocol + '//' + window.location.hostname
        + (window.location.port ? ':' + window.location.port : '') + url;
    } else {
      this.router.navigate([url]);
    }
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  toggleMoreIcon() {
    this.moreIcon = !this.moreIcon;
  }

  toggleMoreLink() {
    const moreLink = 'More';
    const closeLink = 'Close';
    if (this.more === moreLink) {
      this.more = closeLink;
    } else {
      this.more = moreLink;
    }
  }

  // Function added only for screen readers accessibility
  public moreLinksCloseBtn() {
    this.tip1.close();
  }
  // Closing More Links Popup on Escape
  public onKeyupMoreLinks(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      const moreLink = 'More';
      this.tip1.close();
      this.more = moreLink;
      this.moreIcon = !this.moreIcon;
    }
  }
  // Closing Switch User Popup on Escape
  public onKeyupSwitchUser(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.tip3.close();
    }
  }

  // Focus Trap for Switch User - Accessibility
  public switchUserFocus(event: KeyboardEvent) {
    const firstFocusableEl = document.getElementById('dismiss');
    let lastFocusableEl = null;
    if (this.swtchbutton === true) {
      lastFocusableEl = document.getElementById('confirm');
    } else if (this.swtchbutton === false) {
      lastFocusableEl = document.getElementById('comboDropdown');
    }
    if (event.key === 'Tab' && document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      event.preventDefault();
    } else if (event.shiftKey && event.key === 'Tab' && document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      event.preventDefault();
    }
  }

  // Focus Trap for More Link - Accessibility
  public moreLinkFocus(event: KeyboardEvent) {
    const firstFocusableEl = document.getElementById('administration');
    const lastFocusableEl = document.getElementById('closebutton');
    if (event.key === 'Tab' && document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      event.preventDefault();
    } else if (event.shiftKey && event.key === 'Tab' && document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      event.preventDefault();
    }
  }

  loadQuickLink() {
    const subscription = this.aemClient.getMyaccountHomeBundle('newquicklink')
      .subscribe(
        (data: ResourceBundle) => {
          this.quickLinksArray = Object.assign([], data);
          this.getQuickLinkList();
        });
    this.subscriptions.push(subscription);
  }

  // TODO: should be refactored!
  // tslint:disable-next-line: cognitive-complexity
  private async getQuickLinkList() {
    this.coxAdministrationFlag = false;
    const hostname = window.location.hostname;
    const appName = hostname.split('.');
    this.userType = this.userProfile.userType;
    this.userId = this.userProfile.id;
    this.loggedInUserType = this.userProfile.loggedInUserType;
    this.quickLinksArray.forEach((element) => {

      if (element.featureGroup === 'Email Administration') {
        if (element.featureName === 'Email Login') {
          element.featureUrl = this.config.getConfig('getEmailLoginurl');
          this.businessEmailLinkList.push(element);
        } else {
          if (this.role.includes('eacr')) {
            element.featureUrl = this.config.getConfig('getEmailAdminControlUrl');
            this.businessEmailLinkList.push(element);
          }
        }
      } else if (element.featureGroup === 'Account Administration') {
        // Account Administration
        if (this.userType === 'PROFILE_OWNER' || this.userType === 'PROFILE_ADMIN') {
          if (element.featureName !== 'Account Authorization Settings') {
            this.accountAdminLinkList.push(element);
          }
          if (element.featureName === 'Account Authorization Settings' && this.userType === 'PROFILE_OWNER') {
            this.accountAdminLinkList.push(element);
          }
        } else if (this.userType === 'END_USER') {
          if (element.featureName === 'Edit Account Alias' && this.role.includes('elr')) {
            this.accountAdminLinkList.push(element);
          }
          if (element.featureName === 'Update Contacts' && this.role.includes('ucr')) {
            this.accountAdminLinkList.push(element);
          }
        }
      } else if (element.featureGroup === 'Profile Administration') {
        // Profile Administration
        this.manageUser = true;
        if ((this.userType === 'PROFILE_OWNER' || this.userType === 'PROFILE_ADMIN') &&
          element.featureName !== 'Log In As' && element.featureName !== 'Manage Security Permissions') {
          this.profileAdminLinkList.push(element);
        }

        if (element.featureName === 'Log In As') {
          this.loginAs = true;
          if (appName[0] === 'myaccount') {
            if ((this.userType === 'PROFILE_OWNER' || this.userType === 'PROFILE_ADMIN') ||
              (this.loggedInUserType === 'PROFILE_OWNER' || this.loggedInUserType === 'PROFILE_ADMIN')) {
              this.profileAdminLinkList.push(element);
            }
          } else {
            if (this.userProfile.profileOwnerId !== null) {
              this.profileAdminLinkList.push(element);
            }
          }
        }
        if (element.featureName === 'Manage Security Permissions' && this.role.includes('adcspr')) {
          this.profileAdminLinkList.push(element);
        }
      } else if (this.loggedInUserType === 'CSR') {
        // Cox Administration
        this.coxAdministrationFlag = true;
        if (element.featureGroup === 'Cox Administration') {
          if (element.featureName !== 'Manage Security Profile Owner'
            && element.featureName !== 'Cox User Management' && element.featureName !== 'Monitoring') {
            this.coxAdminLinkList.push(element);
          } else if (element.featureName === 'Manage Security Profile Owner') {
            if (this.role.includes('adcspr')) {
              this.coxAdminLinkList.push(element);
            }
          } else if (element.featureName === 'Cox User Management' &&
            this.sessionStorage.retrieve('isLoggedInUserAdmin')) {
            this.coxAdminLinkList.push(element);
          }
        } else if (element.featureGroup === 'Product Administration') {
          this.productAdminList.push(element);
        }
      }
    });

    if (this.role.includes('ae')) {
      this.aeRole = true;
    } else if (this.role.includes('ea')) {
      this.eaRole = true;
      try {
        const data = await this.welcomeWidgetService.getBusinessEmailNotificationCount(this.userID);
        if (data.code === '0' && data.messageCount) {
          const count = Number(data.messageCount);
          if (count !== 0) {
            this.showBusinessEmailNotification = true;
            this.businessEmailnotificationCount = count > 99 ? '99+' : count.toString();
          }
        }
      } catch (e) {
        window.scroll(0, 0);
      }
    } else if (this.role.includes('ber')) {
      this.berRole = true;
    }

  }

  onLinkListclick(framework: any, featureUrl: any) {

    if (framework === 'jsf') {
      window.open('https://' + window.location.hostname + featureUrl, '_self');
    } else if (framework === 'split') {
      window.open('https://' + window.location.hostname + featureUrl, '_self');
    } else if (framework === 'externalLink') {
      window.open(featureUrl, '_blank');
    } else if (featureUrl.includes('?')) {
      const val = featureUrl.split('?');
      const query = val[1].split('&').map((param: any) => param.split('='))
        .reduce((values: any, [key, value]: any) => {
          values[key] = value;
          return values;
        }, {})
        ;
      this.router.navigate([val[0]], { queryParams: query });
    } else if (framework === '') {
      return false;
    } else {
      this.router.navigate([featureUrl]);
    }
  }
  getreadUnreadMessagesCall() {
    this.welcomeWidgetService.getreadUnreadMessages(this.userID).pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => {
          if (data.code === '0' && data.unreadCount && (data.unreadCount !== 0)) {
            this.showNotification = true;
            this.notificationCount = data.unreadCount > 99 ? '99+' : data.unreadCount;
          }
        },
        (error) => {
          window.scrollTo(0, 0);
        },
      );
  }

  linkClick(link: any, details: any) {
    this.tealium.button(link, details);
  }
  //getting the users consuming new api
  async getUserList() {
    // tslint:disable-next-line:max-line-length
    const url = this.router.url.split('?')[0];
    const pageid = this.config.getloadPageIdMappings(url) ? this.config.getloadPageIdMappings(url) : 'default';
    const userData = await this.welcomeWidgetService.getUsersinPage(pageid);
    this.usersList = userData.userList;
    this.userOptions = [];
    this.selectedUserOption = this.lastName;
    for (const rolelist of this.usersList) {
      if (rolelist.userType === 'PO') {
        this.poUserObj = {
          id: rolelist.userId.toString(),
          text: `${rolelist.lastName}, ${rolelist.firstName}(Profile Owner)`,
          mail: `${rolelist.email}`,
        };
        if (rolelist.userId.toString() !== this.userProfile.id) {
          this.poUserObj.type = true;
        }
      } else if (rolelist.userType === 'PA') {
        this.userOptions.push({
          id: rolelist.userId.toString(),
          text: `${rolelist.lastName}, ${rolelist.firstName}(Profile Admin)`,
          mail: `${rolelist.email}`,
        });
      } else {
        this.userOptions.push({
          id: rolelist.userId.toString(),
          text: `${rolelist.lastName}, ${rolelist.firstName}`,
          mail: `${rolelist.email}`,
        });
      }
    }
    this.selectedUserOption = this.userProfile.id;
    this.userOptions = [this.poUserObj, ...this.userOptions];
  }

  async getAllList() {
    const userAllData = await this.welcomeWidgetService.getUsersListinProfile(true);
    this.users = userAllData.userList;
    this.userOptions = [];
    for (const user of this.users) {
      if (user.userType === 'PROFILE_OWNER') {
        this.poUserObj = {
          id: user.id,
          text: `${user.contact.lastName}, ${user.contact.firstName}(${user.userType})`,
          mail: `${user.contact.email}`,
        };
        if (user.id !== this.userProfile.id) {
          this.poUserObj.type = true;
        }
      } else if (user.userType === 'PROFILE_ADMIN') {
        this.userOptions.push({
          id: user.id,
          text: `${user.contact.lastName}, ${user.contact.firstName}(${user.userType})`,
          mail: `${user.contact.email}`,
        });
      } else {
        this.userOptions.push({
          id: user.id,
          text: `${user.contact.lastName}, ${user.contact.firstName}`,
          mail: `${user.contact.email}`,
        });
      }
    }
    this.selectedUserOption = this.userProfile.id;
    this.userOptions = [this.poUserObj, ...this.userOptions];
  }

  public userOptionSelected(ev: { data: Select2OptionData[] }) {
    if (ev.data[0]) {
      this.selectedUser = ev.data[0].mail;
      this.swtchbutton = true;
      this.sessionStorage.store('userId', ev.data[0].id);
    }
  }

  public cancelClick(): void {
    const elements = document.getElementsByClassName('select2-container');
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
        this.dropdownopen = false;
    }
    this.tip3.close();
    this.swtchbutton = false;
  }
  public async switchinguser(): Promise<void> {
    this.tip3.close();
    this.sessionStorage.store('isSwithUser', true);
    return new Promise<void>((resolve, reject) => {
      //this.spinnerService.show();
      this.loginclient.reloadProfile(this.selectedUser).subscribe((data) => {
        let cururl = this.router.url + '';
        // this condition is to remove generic error message verbiage
        if (cururl && cururl.includes('/home/landing')) {
          cururl = '/home/landing';
        }
        if(cururl?.includes('?')){
          cururl = `${cururl}&switchuser=true`
        }else{
          cururl = `${cururl}?switchuser=true`
        }
        if (this.editUser.isEditingUser()) {
          this.welcomeWidgetService.loadLocationMappings(cururl)
            .then((location: string) => {
              location = `${location}?switchuser=true`
              this.editUser.isEditUserSubject.next(false);
              this._storageService.remove(LocalStorageKeys.UserToken, 'cbma');
              this._storageService.remove(LocalStorageKeys.EditingUserguid, 'cbma');
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl(location);
              });
            });
        }else {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(cururl);
          });
        }
        //this.spinnerService.hide();
        window.scrollTo(0, 0);
        this.errorMessageService.welcomewidgetMessage.show = true;
        this.errorMessageService.welcomewidgetMessage.type = 'success';
        this.errorMessageService.welcomewidgetMessage.level = 'global';
        const msg = data.loggedInUserDetails.contact.firstName + ',' + data.loggedInUserDetails.contact.lastName + '.';
        this.errorMessageService.welcomewidgetMessage.message = 'You have successfully logged in as ' + msg;
        setTimeout(() => {
          this.errorMessageService.welcomewidgetMessage.show = false;
        }, 5000);
        resolve();
      }, (error) => {
        console.log('Show error Msg');
        console.log(error);
        //this.spinnerService.hide();
        reject(error);
      });
    });
  }
  @HostListener('click', ['$event'])
  select2open(event: MouseEvent) {
    if (this.tip3.isOpen()) {
      const node = document.querySelectorAll('.select2-container.select2-container--default.select2-container--open');
      if (node.length > 0) {
        (node[1] as HTMLElement).style.zIndex = '1090';
        (<HTMLElement>document.querySelector('.select2-container--default .select2-results>.select2-results__options')).style.overflowX = 'hidden';
      }
    }
    let after = window.performance.now();
  }
  public popovermousedown(event: MouseEvent, element: Select2libComponent) {
    event.stopPropagation();
  }

  public navigateToUserNotifications(){
    this.sessionStorage.store('fromConfigureNotifications', true);
        this.router.navigate(['/admin/user/edit'], {queryParams: {guid: this.userProfile.guid, path: true}});
  }

}
