<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12">
    <div class="col-md-12 col-sm-12 col-xs-12 account mouse-default gray-bg-imp">
      <table class="visible-sm visible-md visible-lg table-layout wide100" tabindex="0" role="text">
        <caption class="sr-only">Account Table</caption>
        <tr  aria-hidden="true">
          <th class="pad-bottom-0-5">{{resourceBundle.accountName}}</th>
          <th class="pad-bottom-0-5">{{resourceBundle.accountAlias}}</th>
          <th class="pad-bottom-0-5">{{resourceBundle.accountNumber}}</th>
          <th class="pad-bottom-0-5">{{resourceBundle.locationAddress}}</th>
        </tr>
        <tr>
          <td class="truncate" [title]="accountName" data-account-name><p class="sr-only" aria-label="Account Name" role="text"></p>{{ accountName }}</td>
          <td class="truncate" [title]="accountAlias" data-account-alias><p class="sr-only" aria-label="Account Alias" role="text"></p>{{ accountAlias }}</td>
          <td data-account-number [title]="accountNumber"><p class="sr-only" aria-label="Account Number" role="text"></p>{{ accountNumber }}</td>
          <td class="truncate" [title]="accountAddress" data-account-address><p class="sr-only" aria-label="Location Address" role="text"></p>{{ accountAddress | truncate:['21', '...'] }}</td>
        </tr>
      </table>
      <div class="visible-xs col-xs-12">
        <div class="col-xs-12">
          <span data-account-name>{{ accountName }}</span>
        </div>
        <div class="col-xs-12">
          <span data-account-alias class="text-normal">{{ accountAlias }}</span>
        </div>
        <div class="col-xs-12">
          <span data-account-number>{{ accountNumber }}</span>
        </div>
        <div class="col-xs-12">
          <span data-account-address1>{{ accountAddress1 }}</span>
        </div>
        <div class="col-xs-12">
          <span data-account-address2 class="text-normal">{{ accountAddress2 }}</span>
        </div>
        <div class="col-xs-12">
          <span data-account-address-postal>{{ accountAddressPostal }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
