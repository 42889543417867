import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConfig } from '../../core/app.config';
import { AEMClientService, ResourceBundle } from '../../services/aemclient.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-bookmarkerrorpage',
    templateUrl: './bookmarkerrorpage.component.html',
})
export class BookmarkErrorpageComponent implements OnInit, OnDestroy {
    errorMessage: string;
    private onDestroy$ = new Subject<boolean>();

    constructor(
        private config: AppConfig,
        private aemClient: AEMClientService,
    ) { }

    ngOnInit() {
        this.aemClient.getErrorBundle('errorpageResourceBundle')
        .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (data: ResourceBundle) => {
                    const loginURL = this.config.getConfig('loginUrl');
                    const myaccountLoginUrl = '<a href=https://' + window.location.hostname + loginURL + '>sign-in</a>';

                    const replacerObj = {
                        0: myaccountLoginUrl,
                    };
                    data.bookmarkURLError = this.aemClient.replacer(data.bookmarkURLError, replacerObj);
                    this.errorMessage = data.bookmarkURLError;
                },
                (error) => { },
            );
    }
    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
      }
}
