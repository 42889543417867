import { state, style, trigger } from '@angular/animations';
import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild,
  ElementRef, HostListener, SimpleChanges, OnChanges
} from '@angular/core';
import { DeviceDetectorService } from 'common-ui-lib';
import { SearchPipe } from '../../../pipes/search.pipe';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ width: '0', transition: 'width 0.3s ease-in-out', padding: '0px' })),
      state('expanded', style({ width: '677px', transition: 'width 0.5s ease-in-out' })),
      state('input-collapsed', style({
        width: '0', transition: 'width 0.3s ease-in-out',
        padding: '0px', border: '0'
      })),
      state('input-expanded', style({ width: '677px', transition: 'width 0.5s ease-in-out' }))
    ]),
    trigger('detailExpandMobile', [
      state('collapsed', style({ width: '0', transition: 'width 0.3s ease-in-out', padding: '0px' })),
      state('expanded', style({ width: '350px', transition: 'width 0.5s ease-in-out', right: '-6.5rem' })),
      state('input-collapsed', style({
        width: '0', transition: 'width 0.3s ease-in-out',
        padding: '0px', border: '0'
      })),
      state('input-expanded', style({ width: '350px', transition: 'width 0.5s ease-in-out' }))
    ]),
    trigger('detailExpandTablet', [
      state('collapsed', style({ width: '0', transition: 'width 0.3s ease-in-out', padding: '0px' })),
      state('expanded', style({ width: '504px', transition: 'width 0.5s ease-in-out' })),
      state('input-collapsed', style({
        width: '0', transition: 'width 0.3s ease-in-out',
        padding: '0px', border: '0'
      })),
      state('input-expanded', style({ width: '504px', transition: 'width 0.5s ease-in-out' }))
    ]),
    trigger('detailExpandLiveSearch', [
      state('collapsed', style({ width: '0', padding: '0px' })),
      state('expanded', style({ width: '659px', position: 'inherit' })),
      state('input-collapsed', style({ width: '0', padding: '0px', border: '0' })),
      state('input-expanded', style({ width: '659px' }))
    ]),
    trigger('detailExpandMsTeamsSearch', [
      state('collapsed', style({ width: '0', padding: '0px', border: '0' })),
      state('expanded', style({ width: '450px', position: 'relative', transition: 'width 0.5s ease-in-out' })),
      state('input-collapsed', style({ width: '0', padding: '0px', transition: 'width 0.3s ease-in-out', border: '0' })),
      state('input-expanded', style({ width: '450px', transition: 'width 0.5s ease-in-out' }))
    ]),
    trigger('detailExpandSinglePayerSearch', [
      state('collapsed', style({ width: '0', transition: 'width 0.3s ease-in-out', padding: '0px' })),
      state('expanded', style({ width: '677px', transition: 'width 0.5s ease-in-out', 'margin-left': '26em' })),
      state('input-collapsed', style({
        width: '0', transition: 'width 0.3s ease-in-out',
        padding: '0px', border: '0'
      })),
      state('input-expanded', style({
        width: '677px', transition: 'width 0.5s ease-in-out',
        'margin-left': '26em', padding: '0 2.5em'
      }))
    ]),
    trigger('detailExpandVoiceEncryptionSearch', [
      state('collapsed', style({ width: '0', transition: 'width 0.3s ease-in-out', padding: '0px' })),
      state('expanded', style({ width: '950px', transition: 'width 0.5s ease-in-out', 'margin-left': '26em' })),
      state('input-collapsed', style({
        width: '0', transition: 'width 0.3s ease-in-out',
        padding: '0px', border: '0'
      })),
      state('input-expanded', style({
        width: '677px', transition: 'width 0.5s ease-in-out',
        'margin-left': '20.5em', padding: '0 2.5em'
      }))
    ]),
    trigger('detailExpandVoiceEncryptionSearchMobile', [
      state('collapsed', style({ width: '0', transition: 'width 0.3s ease-in-out', padding: '0px' })),
      state('expanded', style({ width: '270px', transition: 'width 0.5s ease-in-out', right: '-7.5rem' })),
      state('input-collapsed', style({
        width: '0', transition: 'width 0.3s ease-in-out',
        padding: '0px', border: '0'
      })),
      state('input-expanded', style({ width: '270px', transition: 'width 0.5s ease-in-out' }))
    ]),
    trigger('detailExpandVoiceEncryptionSearchTablet', [
      state('collapsed', style({ width: '0', transition: 'width 0.3s ease-in-out', padding: '0px' })),
      state('expanded', style({ width: '400px', transition: 'width 0.5s ease-in-out' })),
      state('input-collapsed', style({
        width: '0', transition: 'width 0.3s ease-in-out',
        padding: '0px', border: '0'
      })),
      state('input-expanded', style({ width: '400px', transition: 'width 0.5s ease-in-out' }))
    ]),
    trigger('detailExpandZoomedView', [
      state('collapsed', style({ width: '0', transition: 'width 0.3s ease-in-out', padding: '0px' })),
      state('expanded', style({ width: '260px', transition: 'width 0.5s ease-in-out', right: '-6.5rem' })),
      state('input-collapsed', style({
        width: '0', transition: 'width 0.3s ease-in-out',
        padding: '0px', border: '0'
      })),
      state('input-expanded', style({ width: '260px', transition: 'width 0.5s ease-in-out' }))
    ]),
  ]
})
export class TypeaheadSearchComponent implements OnInit, OnChanges {
  @Input() searchDataSource: any[];
  @Input() placeholder: string;
  @Output() userSelectedResult: EventEmitter<object>;
  @Output() closeSearchEvent: EventEmitter<boolean>;
  @Output() searchString: EventEmitter<string>;
  @Input() id: string;
  @Input() tempText: string;                //to persist search Text
  @Input('expanded') expand: string;
  @Input('input-collapsed') inputCollapsed: string;
  @Input() isDatatable: boolean;
  @Input() msTeamsSearch: boolean;
  @Input() voiceEncryptionSearch: boolean;
  @Input() singlePayerSearch: boolean;

  @ViewChild('filterInput') public userInput: ElementRef;
  @ViewChild('filterList') public filterList: ElementRef;
  public resultDataList: any[];
  public searchValue: string;
  public searchList: string;
  public isMobileDevice = false;
  public isTabletDevice = false;
  public isZoomed400 = false;
  public showIcons = false;

  constructor(private deviceService: DeviceDetectorService, private searchPipe: SearchPipe) {
    this.userSelectedResult = new EventEmitter<object>();
    this.closeSearchEvent = new EventEmitter<boolean>();
    this.searchString = new EventEmitter<string>();
  }

  @HostListener('document:click', ['$event'])
  closeSearch(event): void {
    if (!this.isDatatable) {
      const clearIcon = document.getElementById('search-clear');
      if (!(this.userInput.nativeElement.contains(event.target) ||
        this.filterList?.nativeElement.contains(event.target) || clearIcon?.contains(event.target))) {
        this.expand = 'collapsed';
        this.inputCollapsed = 'input-collapsed';
        this.showIcons = false;
        document.getElementById('filterInput').setAttribute('tabindex', '-1');

        if (!this.isDatatable) {
          const filterListHTMLElement: HTMLElement = this.filterList?.nativeElement;
          if (filterListHTMLElement != null) {
            filterListHTMLElement.style.visibility = 'hidden';
          }
        }
        this.closeSearchEvent.emit(true);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resultDataList = [];
    this.enableSearch().then((dataSource) => {
      setTimeout(() => {
        if (this.filterList?.nativeElement) {
          const filterListHTMLElement: HTMLElement = this.filterList?.nativeElement;
          filterListHTMLElement.style.visibility = 'visible';
          filterListHTMLElement.style.backgroundColor = '#fff';
        }
      });
      dataSource.forEach((element) => {
        this.resultDataList.push(element);
      });
    });
  }

  ngOnInit() {
    if (this.placeholder) {
      this.placeholder = ' ' + this.placeholder;
    }
    this.searchList = this.id;
    if (this.tempText) {
      this.searchValue = this.tempText;
    }
    this.expand = 'collapsed';
    this.inputCollapsed = 'input-collapsed';
    setTimeout(() => {
      this.expand = 'expanded';
      this.inputCollapsed = 'input-expanded';
      document.getElementById('filterInput').setAttribute('tabindex', '0');
      this.showIcons = true;
      const userInputHTMLElement: HTMLElement = this.userInput.nativeElement;
      userInputHTMLElement.focus();
    });
    this.isMobileDevice = this.deviceService.isMobile();
    this.isTabletDevice = this.deviceService.isTablet();
    this.isZoomed400 = this.deviceService.isPageZoomed();
  }

  // To emit the search string to make query call
  searchQueryOnDataSource($event) {
    if ($event.target.value !== '') {
      this.searchString.emit($event.target.value);
    }
  }

  enableSearch(): Promise<any> {
    return Promise.resolve(this.searchDataSource.map(item => Object.assign({}, item)));
  }

  // To emit the selected result from search dropdown
  selectedResult(result: any) {
    let closeEvent = null;
    if (result.phoneNumber) {
      this.userInput.nativeElement.value = result.name + ' | ' + result.email + '|' + result.phoneNumber;
    } else {
      this.userInput.nativeElement.value = result.name + ' | ' + result.email;
    }

    this.userSelectedResult.emit({
      target: (result || null)
    });

    this.resultDataList = [];
    this.expand = 'collapsed';
    this.inputCollapsed = 'input-collapsed';
    this.showIcons = false;
    closeEvent = { tempText: this.searchValue, closeSearch: true };

    document.getElementById('filterInput').setAttribute('tabindex', '-1');
    if (!this.isDatatable) {
      const filterListHTMLElement: HTMLElement = this.filterList.nativeElement;
      filterListHTMLElement.style.visibility = 'hidden';
    }
    this.closeSearchEvent.emit(true);
  }

  // Clear search input box on click of 'X'
  clearSearchBox() {
    this.userInput.nativeElement.value = '';
    this.searchValue = '';
    this.resultDataList = null;
    const userInputHTMLElement: HTMLElement = this.userInput.nativeElement;
    this.searchString.emit(this.searchValue);
    userInputHTMLElement.focus();
  }

  //Accessibility close the search on tab when focus is inside the list
  public outOfSearchList(event, elementId: string = null) {
    if (elementId === 'filterInput') {
      if (event.key === 'Tab' && event.shiftKey) {
        this.closeSearchOnTab();
      }
    } else if (event.key === 'Tab' && !event.shiftKey && elementId === 'search-clear') {
      const filterListHTMLElement: HTMLElement = this.filterList?.nativeElement;
      if (typeof filterListHTMLElement === 'undefined') {
        this.closeSearchOnTab();
      }
    } else if (event.key === 'Tab' && (!event.shiftKey ||
      (event.shiftKey && elementId !== 'search-clear'))) {
      this.closeSearchOnTab();
    }
  }

  public clearSearchEvent(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      document.getElementById('filterInput').removeAttribute('role');
    }
  }

  private closeSearchOnTab() {
    document.getElementsByClassName('overlay-search')[0].classList.add('hide');
    const clearIcon = document.getElementById('search-clear');
    this.expand = 'collapsed';
    this.inputCollapsed = 'input-collapsed';
    this.showIcons = false;
    document.getElementById('filterInput').setAttribute('tabindex', '-1');

    const filterListHTMLElement: HTMLElement = this.filterList?.nativeElement;
    if (filterListHTMLElement != null) {
      filterListHTMLElement.style.visibility = 'hidden';
    }
    this.closeSearchEvent.emit(true);
  }
}