export class CreateUserRequest {
  userEmail: string;
  phoneNumber: string;
}

// tslint:disable-next-line: max-classes-per-file
export class CreateUserResponse {
    transactionId: string;
    code: string;
    message: string;
    warning: string;
    userId: string;
    provisioningUserType: string;
}
