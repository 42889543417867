import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BillingHomeService } from '../billing-home.service';
import { AccountInfo } from 'common-ui-lib/lib/shared/redesign/pageheader/pageheader.model';
import { StatementDetails } from 'redesign/admin/account/add-account/add-account.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AEMClientService, AppConfig, CommonMessageService, CoxHttpClient, IStep, ResourceBundle } from 'common-ui-lib';
import {
  FailedStatement, MethodOfPaymentList, MethodOfPayments, PaymentConfirmationObj,
  ReviewAndSubmitObj, Statement, SubmitPayment
} from '../make-payment/make-payment.model';
import { finalize, takeUntil } from 'rxjs/operators';
import { NewMethodOfPayment } from '../billing-home.model';
import { MaxCollecDueDatesPaymentArrngType } from './payment-arrangements.model';

@Injectable()
export class PaymentArrangementsService {
  public selectedAccountDetails: AccountInfo;
  public selectedStatement = new BehaviorSubject<StatementDetails>(null);
  public contentLoaded = false;
  public title: string;
  public optionName: string;
  public optionCode: string;
  public statementMopDetails: MethodOfPayments;
  public statementDetails: StatementDetails[];
  public resourceBundle: ResourceBundle = {};
  public selectedMopForPayment = [];
  public newMOPObj: NewMethodOfPayment[] = [];
  public reviewAndSubmitObj: ReviewAndSubmitObj;
  public paymentFailure = false;
  public scheduledDate: string;
  public paymentConfirmationObj: PaymentConfirmationObj;
  public failedStatements: FailedStatement;
  public minDueAmt: string;
  public steps: IStep[];
  public editedMOPDetails: MethodOfPaymentList[];
  public getStatementUrl: string;
  private onDestroy$ = new Subject<boolean>();
  private _currentStep: number;
  public get currentStep(): number {
    return this._currentStep;
  }
  public set currentStep(value: number) {
    this._currentStep = value;
  }

  constructor(private apollo: Apollo, public billingService: BillingHomeService,
    public commonMessageService: CommonMessageService, private aemClient: AEMClientService,
    private coxhttp: CoxHttpClient, public payArrangeMsg: CommonMessageService,
    private config: AppConfig) {
    this.selectedAccountDetails = this.billingService.selectedAccountDetails;
    this.selectedStatement = this.billingService.selectedStatement;
    this.aemClient.getRedesignBundle('billing/payment-arrangements/payment-arrangements')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
          this.resourceBundle = data;
        }
      );
  }
  public initializeStepper() {
    if (this.optionName !== this.resourceBundle.scheduleFuturePaymentTitle) {
      this.steps = [
        {
          icon: '1',
          label: 'Select Date',
        },
        {
          icon: '2',
          label: 'Confirm',
        },
        {
          icon: '3',
          label: 'Reminder',
        },
      ];
    } else {
      this.steps = [
        {
          icon: '1',
          label: 'Select Date',
        },
        {
          icon: '2',
          label: 'Payment Method',
        },
        {
          icon: '3',
          label: 'Reminder',
        },
      ];
    }
  }

  //Query call to get payment arrangement options
  public getPaymentArrangementOptions(userId: string, stmtCode: string, type: string) {
    const paymentArrangementOptions = gql`
    query getPaymentArrangementOptionsQuery($userId: ID!, $stmtCode: String!){
      getAccount(id: $userId) {
        billStatementPaymentArrangement(statementCode: $stmtCode, type: ALL) {
          paymentArrangments {
            paymentArrangementType
            isEligibile
            failedRules {
              failureReason
              ruleId
            }
          }
        }
      }
    }
    `;

    return this.apollo.query({
      query: paymentArrangementOptions,
      variables: {
        userId,
        stmtCode,
      },
    });
  }
  //Query call to get request and reschedule extension details
  public getRequestRescheduleExtensionDetails(userId: string, stmtCode: string,
    type: MaxCollecDueDatesPaymentArrngType,
    totalBalance: string, pastDueAmount: string, scheduledDate: string | null) {
    const getRequestRescheduleExtensionDetails = gql`
    query getRequestRescheduleExtensionDetailsQuery($userId: ID!, $stmtCode: String!,
    $type: MaxCollecDueDatesPaymentArrngType, $totalBalance: String!, $pastDueAmount: String!, $scheduledDate: String){
      getAccount(id: $userId) {
        maxCollectionDueDates(pastDueAmount: $pastDueAmount, scheduledDate: $scheduledDate,
        statementCode: $stmtCode, totalBalance: $totalBalance, type: $type) {
          billCycleDate
          maxDueDate
          minDueAmount
          paymentArrangementType
          ruleId
        }
      }
    }
    `;

    return this.apollo.query({
      query: getRequestRescheduleExtensionDetails,
      variables: {
        userId,
        stmtCode,
        type,
        totalBalance,
        pastDueAmount,
        scheduledDate
      },
    });
  }

  //Query call to get Min Due amt
  public getMinDue(userId: string, pastDueAmount: string, scheduledDate: string,
    stmtCode: string, totalBalance: string, type: MaxCollecDueDatesPaymentArrngType) {
    const getMinDue = gql`
    query getMinDueQuery($userId: ID!, $pastDueAmount: String!, $scheduledDate: String!,
       $stmtCode: String!, $totalBalance: String!, $type: MaxCollecDueDatesPaymentArrngType!){
      getAccount(id: $userId) {
        maxCollectionDueDates(pastDueAmount: $pastDueAmount, scheduledDate: $scheduledDate,
           statementCode: $stmtCode, totalBalance: $totalBalance, type: $type) {
          billCycleDate
          maxDueDate
          minDueAmount
          paymentArrangementType
          ruleId
        }
      }
    }
    `;

    return this.apollo.query({
      query: getMinDue,
      variables: {
        userId,
        pastDueAmount,
        scheduledDate,
        totalBalance,
        type,
        stmtCode,
      },
    });
  }


  public submitPaymentDetails(input: SubmitPayment) {
    const SubmitPaymentDetails = gql`
      mutation submitPaymentDetails($input: SubmitPayment) {
        submitPayment(input: $input) {
          statuses {
            code
            id
            message
            transactionId
            warning
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: SubmitPaymentDetails,
      variables: {
        input
      },
    });
  }

  //Api to get statement pdf details
  public getStatementByteArray(guid: string, statementCode?: string,
    cycleId?: string, cycleDate?: string): Observable<Statement> {
      this.getStatementUrl =
        `/api/cbma/billing/services/billing/account/v3/statementpdf?guid=${guid}&statementCode=${statementCode}`;
      if (cycleDate) {
        this.getStatementUrl = this.getStatementUrl + '&statementPdfId=' + cycleId + '&cycleDate=' + cycleDate;
      }
    return this.coxhttp.get<Statement>(this.getStatementUrl,
      { disableSpinner: true, customeErrorMessage: '' }).map((res) => {
        if (res.code !== '0') {
          throw new Error(res.message);
        }
        return res;
      });
  }

  //To download statement pdf details
  public downloadStatementAsPDF(guid: string, stmCode: string, fileName: string) {
    this.getStatementByteArray(guid, stmCode)
      .pipe(finalize(() => {
      }))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data && data.code.toString() === '0') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const byteCharacters = atob(data.pdfData as any);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const navigate = window.navigator as any;
          if (navigate?.msSaveOrOpenBlob) {
            navigate?.msSaveOrOpenBlob(blob, fileName + '.pdf');
            return;
          }
          const blobData = window.URL.createObjectURL(blob);
          window.open(blobData);
        } else {
          window.scroll(0, 0);
          this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'statementPdf');
        }
      }, () => {
        window.scroll(0, 0);
        this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'statementPdf');
      },
      );
  }
}
