import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageKeys } from 'common-ui-lib';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AppConfig } from '../core/app.config';
import { CBHelperService } from './cbhelper.service';
import { EditUserService } from './edituser.service';


@Injectable()
export class RefreshTokenClient {
  constructor(
    private config: AppConfig,
    private _storageService: LocalStorageService,
    private cbHelper: CBHelperService,
    private http: HttpClient,
    private editUserService: EditUserService
    ) { }

  keepAliveUri = this.config?.getConfig('APIGW')['baseURL'] +
    this.config?.getConfig('APPURI')['keepAlive'];

  public getRefreshToken(): Observable<any> {
    if (this.cbHelper.isUserAuthenticated() && !this.cbHelper.isCbmaAuthTokenExpired()) {
      const cb_session = this.editUserService.getCbUser();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'MA_TRANSACTION_ID': uuid(),
          'clientid': this.config.getConfig('APIGW')['clientId'],
          'apikey': this.config.getConfig('APIGW')['apiKey'],
          'CB_SESSION': cb_session,
          'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
        }),
      };
      return this.http.get<any>(this.keepAliveUri,
        { headers: httpOptions.headers, observe: 'response' }).map((response) => {
          if (response.body.code === '0') {
            this._storageService.set(LocalStorageKeys.Authtoken, response.headers.get('cbma_authtoken'));
            this.cbHelper.processCredentials();
          } else {
            throw Error;
          }
        });
    }
  }
}
