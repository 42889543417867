import { Injectable } from '@angular/core';
@Injectable({providedIn: 'root'})
export class FocusTrapsService {
public makePaymentMopFocus(event: KeyboardEvent,firstBtn,lastBtn) {
    const firstFocusableEl = document.getElementById(firstBtn);
    const lastFocusableEl = document.getElementById(lastBtn);
    if (event.keyCode === 9 && document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      event.preventDefault();
    } else if (event.shiftKey && event.key === 'Tab' && document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      event.preventDefault();
    }
  }

public makePaymentCancelMopFocus(event: KeyboardEvent, initialBtn, firstBtn,lastBtn,conditonValue) {
  const initialFocusableEl = document.getElementById(initialBtn);
  const firstFocusableEl = document.getElementById(firstBtn);
  const lastFocusableEl = document.getElementById(lastBtn);
  if (conditonValue == false) {
    if (event.keyCode === 9 && event.key === 'Tab' && document.activeElement === initialFocusableEl) {
      firstFocusableEl.focus();
      event.preventDefault();
    }
  }
  else if (conditonValue == true) {
    if (event.keyCode === 9 && event.key === 'Tab' && document.activeElement === initialFocusableEl) {
      window.setTimeout(function () {
        lastFocusableEl.focus({ preventScroll: true });
      }, 0);

      event.preventDefault();
    }
  }
}
}
