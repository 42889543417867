import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { OutageUpdateInput } from './unplanned-outage-modal.model';

@Injectable()
export class UnplannedOutageModalService {

    constructor(private apollo: Apollo) { }

  public getUserInfo(userId: string) {
    const getUserInfoQuery = gql`
        query getUserDetails($userId: ID!){
          getUser(id: $userId) {
            guid
            contacts {
              id
              contactType
              contactValue
              preferred
              isBounced
              isMobileNumber
            }
          }
        }
      `;

    return this.apollo.query({
      query: getUserInfoQuery,
      variables: {
        userId
      },
    });
  }

  public getUnplannedOutageDetails() {
    const getOutageDetailsQuery = gql`
      query getOutageDetails {
        listOutages(filter: UNPLANNED) {
          accountExternalAttrs {
            serviceAddress {
              city
              houseNumber
              state
              street
              zipCode
            }
          }
          alias
          guid
          outage(outageFilter : false) {
            etr
            eventTypeGroup
            symptoms
            cnsFeedback
            phoneNumber
          }
        }

      }
    `;

    return this.apollo.query({
      query: getOutageDetailsQuery,
    });
  }

  public updatePhoneNumber(input: OutageUpdateInput) {
    const UpdatePhone = gql`
      mutation UpdateAccountContact($input: OutageUpdateInput!) {
        outageUpdates(input: $input) {
          guid
        }
      }
    `;
    return this.apollo.mutate({
      mutation: UpdatePhone,
      variables: {
        input
      },
    });
  }

  public getOutageVerbiage(accountGuid: string) {
    const getOutageVerbiageQuery = gql`
        query getOutageVerbiage($accountGuid: ID!) {
          getAccount(id: $accountGuid) {
            outage(outageFilter: true) {
              message
            }
          }
        }
      `;

    return this.apollo.query({
      query: getOutageVerbiageQuery,
      variables: {
        accountGuid
      },
    });
  }

}