import {Component, AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements AfterViewInit  {

  constructor() { }

  ngAfterViewInit() {}
}
