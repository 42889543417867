import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

@Injectable({providedIn : 'root'})
export class MoreLinkService {
  public profileGuid: string;

    constructor(
        private apollo: Apollo
    ) { }

    public getAccount(userId: string): Observable<any> {
        const getAccountQuery = gql`
            query getAccountDetails($userId: ID!){
                getAccount(id: $userId) {
                    serviceFeature(featureCategoryName: ALL) {
                      cbFeatures {
                        featuresList {
                          role
                          featureName
                        }
                        userAccountsFlag
                      }
                      accountAuthUser
                      cbServices {
                        appService
                        internetService
                        networkingService
                        securityService
                        subServiceDetails {
                          subServiceList {
                            featureName
                            role
                            subCategory
                          }
                          category
                        }
                        voiceService
                        tvService
                      }
                    }
                  }
            }`;

        return this.apollo.query({
            query: getAccountQuery,
            variables: {
              userId
            },
        });
    }

    public getProfile() {
      const getProfileQuery = gql`
      query getProfileDetails {
        getProfile(accountStatusFilter: "ALL") {
          bwGroupId
          bwSpId
          companyName
          guid
          id
          isMultiAccount
          serviceFeature(featureCategoryName: ALL) {
            cbFeatures {
              userAccountsFlag
              featuresList {
                featureName
                role
              }
            }
            cbServices {
              voiceService
              ringcentralService
              tvService
              securityService
              networkingService
              internetService
              appService
              subServiceDetails {
                category
                subServiceList {
                  subCategory
                  featureName
                  role
                }
              }
            }
            accountAuthUser
          }
        }
      }`;

      return this.apollo.query({
          query: getProfileQuery
      });
  }
}