import { Injectable } from '@angular/core';

import {LocalStorageService} from 'ngx-localstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { VoiceGlobals } from '../shared/models/globals/voice.globals';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { AppConfig } from '../core/app.config';
import { EditUserService } from './edituser.service';
import { LocalStorageKeys } from 'common-ui-lib';
@Injectable()
export class AuthTokenService {
  decodedtoken: any;
  tokenPayload: any;
  jwtHelper: JwtHelperService = new JwtHelperService();

  profile: any;

  constructor(private _storageService: LocalStorageService,
    private voiceGlobal: VoiceGlobals,
    private permissionService: NgxPermissionsService,
    private rolesService: NgxRolesService,
    private config: AppConfig,
    private editUserService: EditUserService) {
    this.decodedtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    if (null != this.decodedtoken) {
      this.tokenPayload = this.jwtHelper.decodeToken(this.decodedtoken);
      if (null !== JSON.parse(this.tokenPayload.profile)
          && undefined !== JSON.parse(this.tokenPayload.profile)
          && '' !== JSON.parse(this.tokenPayload.profile)) {
          this.profile = JSON.parse(this.tokenPayload.profile);
        }
    }
   }

   getProfile() {
     if (this.profile) {
       return this.profile;
     } else {
      this.decodedtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
      if (null != this.decodedtoken) {
        this.tokenPayload = this.jwtHelper.decodeToken(this.decodedtoken);
        if (null !== JSON.parse(this.tokenPayload.profile)
            && undefined !== JSON.parse(this.tokenPayload.profile)
            && '' !== JSON.parse(this.tokenPayload.profile)) {
            this.profile = JSON.parse(this.tokenPayload.profile);
            return this.profile;
          }
      }
     }
   }

   setGlobals() {
    const _authToken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    const _decodedToken = this.jwtHelper.decodeToken(_authToken);
    const _parsedToken = JSON.parse(_decodedToken.profile);
    this.voiceGlobal.userName = _parsedToken['userToken']['email'];
    this.voiceGlobal.userType = _parsedToken['userToken']['userType'];
    this.voiceGlobal.firstName = _parsedToken['userToken']['firstName'];
    this.voiceGlobal.lastName = _parsedToken['userToken']['lastName'];
    this.voiceGlobal.assignedPhoneNumber = _parsedToken['userToken']['assignedPhoneNumber'];
    this.voiceGlobal.package = _parsedToken['userToken']['switchPackage'];
    // get timezone
    let timeZone = this.config.getConfig('defaultUserTimeZone');
    if (_parsedToken['userToken']['timeZoneDisplayName']) {
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(_parsedToken['userToken']['timeZoneDisplayName']);
      timeZone = matches[1];
    }
    this.voiceGlobal.timeZone = timeZone;
    this.voiceGlobal.hasChildAccount = this.hasChildAccount();
    this.setPermissions();
  }

  setPermissions() {
    this.permissionService.flushPermissions();
    this.rolesService.flushRoles();
    let role: string;
    const _authToken = this.editUserService.getToken();
    if(_authToken) {
    const _decodedToken = this.jwtHelper.decodeToken(_authToken);
    const _parsedToken = JSON.parse(_decodedToken.profile);
    const isFoneAdmin = _parsedToken['userToken']['foneAdmin'];
    const userType = _parsedToken['userToken']['userType'];
    const assignedPhoneNumber = _parsedToken['userToken']['assignedPhoneNumber'];

    if (assignedPhoneNumber) {
      role = 'WITH_PHONE';
    } else {
      role = 'NO_PHONE';
    }
    if (userType === 'PROFILE_ADMIN' || userType === 'PROFILE_OWNER') {
      role = 'ADMIN_' + role;
    } else if (userType === 'END_USER') {
      if (isFoneAdmin) {
        role = 'FONEADMIN_' + role;
      } else {
        role = 'END_USER_' + role;
      }
    }
    // const authToken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    // const decodedToken = this.jwtHelper.decodeToken(authToken);
    const permissions = JSON.parse(_decodedToken.profile).roles;
    this.permissionService.loadPermissions(permissions);
    this.rolesService.addRole(role, permissions);
  }
  }

  hasChildAccount(): boolean {
    const _authToken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    const _decodedToken = this.jwtHelper.decodeToken(_authToken);
    const _parsedToken = JSON.parse(_decodedToken.profile);
    return _parsedToken['userToken']['hasTrunk'];
  }

}
