// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version,
  cdnUrl: 'https://webcdn-business.ppe.cox.com/R24.12.1/',
  serverUrl: 'https://coxbusiness-voicemanager-cb-dev.bss-np.corp.cox.com/services/v1',
  cbHeaderUrl: 'https://www.one.qa.cox.com/presentation/cmsjs/api?v=2.0&appid=cmsbusiness-myaccount',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR',
  ],
};
