import { inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AppConfig, CBHelperService } from 'common-ui-lib';
import { Observable } from 'rxjs';

export const RedesignGuardService: CanActivateFn = (route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {

    const router = inject(Router);
    const cbHelperService = inject(CBHelperService);
    const config = inject(AppConfig);
    const location = inject(Location);
        const role = cbHelperService.getLoggedInUserDetails().profile.roles;
        const guid = cbHelperService.getLoggedInUserDetails()?.profile?.userToken?.guid;
        if (!role.includes("phnf") && state.url.includes('/administration')) {
            //this redirection is to handle changepassword for cbemail
            if (!role.includes("phnf") && state.url.includes('/administration/profileadministration/manageuser/edituser?changepassword=true')) {
                return router.navigateByUrl(`/admin/user/edit?guid=${guid}`)
            }
            if (state.url.includes('/addaccount')) {
                router.navigateByUrl("/admin/account/addaccount", { skipLocationChange: true });
                location.replaceState('/admin/account/addaccount');
            } else if (state.url.includes('/adduser')) {
                router.navigateByUrl("/admin/user/adduser", { skipLocationChange: true });
                location.replaceState('/admin/user/adduser');
            } else {
                router.navigateByUrl("/admin/list", { skipLocationChange: true });
                location.replaceState('/admin/list');
            }
            return false;
        }
        return true;
}
