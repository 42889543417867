import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoxHttpClient } from '../../services/coxhttpclient.service';
import { BaseAccountObject } from '../models/accounts/baseaccounts.model';

@Injectable()
export class AccountWidgetService {

  constructor(private httpClient: CoxHttpClient) { }

  getBaseAccountsList(pageId: string): Observable<BaseAccountObject> {
    return this.httpClient.get<BaseAccountObject>('/api/cbma/account/services/account/list/' + pageId);
  }

}
