import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { AEMClientService } from '../../services/aemclient.service';
import { ResourceBundle } from '../../services/aemclient.service';

@Injectable()
export class VoiceLandingPageService {
  public topFeatureSectionLink = false;
  selectedCard: string;
  menuNamesArray: string[] = [];
  userRolesFeatureDetailsArray: BehaviorSubject<any> = new BehaviorSubject([]);
  userProdAdminFeatureDetailsArray: any = [];
  userRolesArray: any = [];
  userRolesFileName = '';

  constructor(private aemClient: AEMClientService) {
    this.selectedCard = '';
  }

  getUserBasedJsonFile(userType: any): Observable<ResourceBundle> {
    if (userType['ADMIN_WITH_PHONE']) {
      this.userRolesFileName = 'adminwithphoneroles';
    } else if (userType['ADMIN_NO_PHONE']) {
      this.userRolesFileName = 'adminwithoutphoneroles';
    } else if (userType['FONEADMIN_WITH_PHONE']) {
      this.userRolesFileName = 'accountadminwithphoneroles';
    } else if (userType['FONEADMIN_NO_PHONE']) {
      this.userRolesFileName = 'accountadminwithoutphoneroles';
    } else if (userType['END_USER_WITH_PHONE']) {
      this.userRolesFileName = 'enduserwithphoneroles';
    } else if (userType['END_USER_NO_PHONE']) {
      this.userRolesFileName = 'enduserwithoutphoneroles';
    }

    if (this.userRolesFileName !== '') {
      return this.aemClient
        .getVMLPConfig(this.userRolesFileName);
    }
  }

  getFrequentlyViewedPagesJsonFile(): Observable<ResourceBundle> {
    return this.aemClient
      .getVMLPConfig('frequently-user-pages-roles');
  }

  getProductAdministrationJsonFile(): Observable<ResourceBundle> {
    return this.aemClient
      .getVMLPConfig('productadministrationroles');
  }

  getSelectedCard(): string {
    return this.selectedCard;
  }
  setSelectedCart(selectedCard: string) {
    this.selectedCard = selectedCard;
  }
  getUserRolesFeatureDetailsArray(): Observable<any> {
    return this.userRolesFeatureDetailsArray.asObservable();
  }
  setUserRolesFeatureDetailsArray(userRolesFeatureDetailsArray: any[]) {
    this.userRolesFeatureDetailsArray.next(userRolesFeatureDetailsArray);
  }

  getUserProdAdminFeatureDetailsArray() {
    return this.userProdAdminFeatureDetailsArray;
  }
  setUserProdAdminFeatureDetailsArray(userProdAdminFeatureDetailsArray: any[]) {
    this.userProdAdminFeatureDetailsArray = userProdAdminFeatureDetailsArray;
  }

  getMenuNamesArray() {
    return this.menuNamesArray;
  }
  setMenuNamesArray(menuNamesArray: string[]) {
    this.menuNamesArray = menuNamesArray;
  }

  setUserRolesArray(userRolesArray: any[]) {
    this.userRolesArray = userRolesArray;
  }
  getUserRolesArray() {
    return this.userRolesArray;
  }
}
