import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageKeys } from 'common-ui-lib';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AppConfig } from '../core/app.config';
import { UserProfile } from '../shared/models/userprofile/userprofile.model';
import { CBHelperService } from './cbhelper.service';

@Injectable()
export class CsrLoginUserService {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private config: AppConfig,
    private _storageService: LocalStorageService,
    private cbHelper: CBHelperService,
  ) {
  }

  getCsrProfileDetails(masterUserEmail: any): Observable<UserProfile> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': masterUserEmail,
      }),
    };

    const csrloginurl = '/api/cbma/userauthorization/services/loginprofiles/login/csr';
    this.cbHelper.deleteCookies();
    return this.httpClient.get<any>(csrloginurl,
        {headers: httpOptions.headers, observe:'response'}).map((response) => {
          if (response.body.code === '0') {
            this._storageService.set(LocalStorageKeys.Authtoken, response.headers.get('cbma_authtoken'));
            this.cbHelper.processCredentials();
            return UserProfile.fromJSON(response.body);
          } else {
            throw Error;
          }
      });
  }
}
