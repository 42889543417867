export enum EventTypeGroup {
	BCP = 'BCP',
	TRENDING = 'TRENDING',
	CHRONIC = 'CHRONIC',
	UNPLANNED = 'UNPLANNED',
	MAINTENANCE = 'MAINTENANCE',
	NOOUTAGE = 'NOOUTAGE'
}

export interface OutageUpdateInput {
	phoneNumber: string
	accountGuid: string[]
	saveNumber: boolean
}
