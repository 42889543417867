<div>
  <ng-container [ngTemplateOutlet]="titleAndDesc ? titleAndDescTemplate : defaultTemplate"></ng-container>
</div>

<ng-template #defaultTemplate>
  <div class="col-12 p-0 margin-top-40px"
  *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id == 'switchUserMsg'">
  <app-commonmessage [type]="commonMessageService.displayMessage.type"
    [globalMessage]="commonMessageService.displayMessage.message" [level]="'global'"
    *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id == 'switchUserMsg'">
  </app-commonmessage>
</div>

<div class="row bg-white" [ngClass]="deviceService.isMobile() || this.deviceService.isPageZoomedto175() ?'margin-top-20 margin-botton-20px':'navigation-margin'">
  <div [class]="backNavigationClass">
    <app-navigation [selectedAccountDetails]="selectedAccountDetails" [moreLink]="moreLink"
      (backEvent)="backEventOccured($event)" [backLocation]="backLocation" [checkFormDirty]="checkFormDirty" [backWithUnsavedChanges]="backWithUnsavedChanges" [showAccountWidget]="showAccountWidget"></app-navigation>
    <div class="switch-user" *ngIf="showSwitchUser">
      <a attr.aria-label="{{resourceBundle.switchUser}}" class="margin-left-1-6em" role="button"
        href="javascript:void(0)" #tip3="ngbPopover" [autoClose]="'outside'" [ngbPopover]="switchUser"
        [placement]="popoverPlacement" (click)="dropdownopen = false">
        {{resourceBundle.switchUser}} <img alt="View More" class="margin-bottom-5px"
          src="assets/images/blueprint/blue-arrow.svg">
      </a>
    </div>
    <ng-container *ngIf="showAccNumber && widgetLoaded">
      <div class="margin-top-neg-0-5 padding-left-28" *ngIf="!isMobileView">
        <span>
          {{selectedAccountDetails.accountNumber12.substr('0','3')}}-{{selectedAccountDetails.accountNumber12.substr('3','11')}}
        </span>
      </div>
    </ng-container>
  </div>
  <div class="align-center" [class]="accountWidgetClass"
    [ngClass]="{'justify-content-center': deviceService.isMobile() || this.deviceService.isPageZoomedto175(), 'justify-content-end': !deviceService.isMobile()}">
    <app-redesign-accountwidget *ngIf="showAccountWidget" [ngClass]="{'hide': callWidget}" [featureName]="featureName"
      [hideWidgetTitle]="hideWidgetTitle" (selectedAccountEvent)="selectedEvent($event)"
      (errorAlertEvent)="errorOccured($event)" (accountListEvent)="getAccountList($event)"
      (accountWidgetLoading)="updateAccountWidgetLoading($event)">
    </app-redesign-accountwidget>

    <div class="vertical-divider" *ngIf="!deviceService.isMobile() && showAccountWidget && moreLink && !callWidget">
    </div>

    <div class="align-center" *ngIf="moreLink && !deviceService.isMobile()"
      [ngClass]="{'padding-top-25': showAccountWidget, 'margin-top-neg-0-5': !showAccountWidget}">
      <app-more-link [selectedAccountDetails]="selectedAccountDetails" (featuresList)="profileFeaturesList($event)" (cbServices)="availableServices($event)"></app-more-link>
    </div>
  </div>

</div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cb-error-container mt-4 pl-0 pr-0" *ngIf="accessedAs">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0 pl-0 pr-0">
    <ul role="presentation" class="pl-0">
      <li class="msg-info" data-alert="" rel="tooltip" title="info" role="presentation">
        You are editing settings for {{accessedAs}}
         <ng-container *ngIf="!isVoiceSettingsSubMenu"> |
            <a (click)="backToFeatureLinkClick()" *ngIf="enableBackToFeatureLink" title="Back to Feature Settings"> Back to
              Feature Settings</a>
            <a *ngIf="!isHoteling && !enableBackToFeatureLink" href="javascript:void(0)"
              routerLink="/voice/userandsysmanagement/userfeaturesettings" (click)="emitBackToListEvent()"
              title="Back to User List">
              Back to User List
            </a>
            <a *ngIf="isHoteling && !enableBackToFeatureLink" href="javascript:void(0)"
              routerLink="/voice/advancedcallsettings/hoteling" (click)="emitBackToListEvent()" title="Back to User List">
              Back to User List
            </a>
          </ng-container>
      </li>
    </ul>
  </div>
</div>
</ng-template>

<ng-template #titleAndDescTemplate>
  <div *ngIf="titleAndDescDetails; else titleDescSkeleton" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 header-with-title-desc">
    <div *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id === 'pageHeaderMsg'" [ngClass]="isMobileView ? 'margin-top-neg-4em' : 'mt-n4'">
    <app-commonmessage *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id === 'pageHeaderMsg'" [type]="commonMessageService.displayMessage.type"
    [globalMessage]="commonMessageService.displayMessage.message" [listOfErrorMsg]="pageErrorMsgs" [level]="displayLevel">
    </app-commonmessage>
    </div>
    <div>
      <app-navigation [backText]="true" [selectedAccountDetails]="selectedAccountDetails" [moreLink]="moreLink"
      (backEvent)="backEventOccured($event)" [backLocation]="backLocation" [checkFormDirty]="checkFormDirty" [backWithUnsavedChanges]="backWithUnsavedChanges" [showAccountWidget]="showAccountWidget"></app-navigation>
    </div>
    <h1 class="pageheader-title">
      {{titleAndDescDetails.title}}
    </h1>
    <div class="pageheader-description">
      {{titleAndDescDetails.description}}
    </div>
  </div>
</ng-template>

<ng-template #titleDescSkeleton>
  <div class="col-12 justify-content-center">
    <app-skeletonloader freeformSkeleton="true" count="1" sectionName="Page Title and Description"
      widthSizeInPixels="600" heightSizeInPixels="30"></app-skeletonloader>
    <app-skeletonloader freeformSkeleton="true" count="1" sectionName="Page Title and Description"
      widthSizeInPixels="150" heightSizeInPixels="50"></app-skeletonloader>
    <app-skeletonloader freeformSkeleton="true" count="1" sectionName="Page Title and Description"
      widthSizeInPixels="250" heightSizeInPixels="20"></app-skeletonloader>
  </div>
</ng-template>

<ng-template #switchUser let-close="close">
  <div id="switchdivtest" placement="bottom" class="col-lg-12 switch-div"
    attr.aria-label="Dialog {{resourceBundle.switchUser}}" (mousedown)="popovermousedown($event,userselect2);"
    (keyup)="onKeyupSwitchUser($event)">
    <div id="switchuser" class="h4 col-12 display-flex pl-0 pt-3">
      <strong class="col-11 p-0">{{resourceBundle.switchUser}}</strong>
      <div class="col-1 mt-n2">
        <a attr.aria-label="{{resourceBundle.switchUser}} Close" role="button" id="dismiss" href="javascript:void(0)"
          class="cb-modal-close-btn" (click)="cancelClick()" (keydown)="switchUserFocus($event)"></a>
      </div>
    </div>
    <div id="description" class="pt-3">
      <p>{{resourceBundle.switchUserDesc1}}</p>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" id="select2div">
      <div class="select2-user p-0" [class.expanded]="dropdownopen" role="combobox">
        <lib-app-select2 *ngIf="pageHeaderService.userOptions" [options]="{ placeholder: 'Type a name to search' }"
          [TemplateResult]="templatefunction" #userselect2 attr.aria-label="{{resourceBundle.switchUser}}"
          role="application" [data]="pageHeaderService.userOptions" [clearable]="true"
          (valueChanged)="userOptionSelected($event)" (open)="dropdownopen = true;" (close)="dropdownopen = false"
          [value]="pageHeaderService.selectedUserOption" (keydown)="switchUserFocus($event)"></lib-app-select2>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 modal-body">
      <button class="btn-long-text button w-100" type="submit" id="confirm" [disabled]="!swtchbutton"
        (click)="switchinguser();tip3.close()"
        (keydown)="switchUserFocus($event)">{{resourceBundle.switchUser}}</button>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0 pb-4 font">
      <p><span class="star">*</span> {{resourceBundle.switchUserDesc2}}</p>
    </div>
    <!-- Only for screen readers accessibility -->
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 pull-right pr-0 pt-0 sr-only">
      <a attr.aria-label="{{resourceBundle.switchUser}} Close" role="button" id="dismiss" href="javascript:void(0)"
        class="cb-modal-close-btn ml-ng-25 ml" (click)="cancelClick()" (keydown)="switchUserFocus($event)"></a>
    </div>
  </div>
</ng-template>

<ng-template #unSavedChangesModal let-c="close" let-d="dismiss" role="dialog">
  <comn-leavingcoxsitemodal [secondaryBtnRequired]=true [primaryBtnRequired]=true [switchPage]="leavingPage"
      [modaltemplate]="modaltemplate" (onPrimaryClick)="onPrimaryClick(c)"
      (onSecondaryClick)="onSecondaryClick(c)">
  </comn-leavingcoxsitemodal>
</ng-template>