import { Component, OnInit } from '@angular/core';

import { I18nService } from '../../i18n.service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-header',
  template: '',
  styleUrls: []
})
export class HeaderComponent implements OnInit {

  menuHidden = true;

  constructor(private config: AppConfig, private i18nService: I18nService) { }

  ngOnInit() {

  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

}
