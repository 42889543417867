<span>{{dialogOpenCall()}}</span>
<div class="modal-body" role="dialog" aria-label="Timeout Warning">
  <div class="h2">{{resourceBundle.timeoutHeader}}</div>
  <div class="row margin-top-30">
    <div class="col-12">
      {{resourceBundle.timeoutContent}} 
    </div>
  </div>
  <div class="row margin-top-20" aria-hidden="true" tabindex="-1">
    <div class="col-12" aria-hidden="true" tabindex="-1">
      {{resourceBundle.sessionTimeoutInfo}}
    </div>
  </div>
  <!-- Accessibility fix for screen reader only -->
  <span class="sr-only">{{resourceBundle.sessionTimeoutInfo}}</span>
  <div class="row margin-top-20">
    <div class="col-12">
      {{resourceBundle.instructionToContinue}}
    </div>
  </div>
  <div class="modal-footer row-fluid margin-top-20">
    <button type="button" class="button button-secondary" (click)="triggerOkTealium(resourceBundle.extendSessionButton);activeModal.close('close');removeEventListner()">
      {{resourceBundle.extendSessionButton}}
    </button>
  </div>
</div>