import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { AppConfig, CBHelperService, LocalStorageKeys } from 'common-ui-lib';
import { LocalStorageService } from 'ngx-localstorage';

export const UsersAndAccountsGuard: CanActivateFn =
(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean => {
    const router = inject(Router);
    const cbHelperService = inject(CBHelperService);
    const config = inject(AppConfig);
    const _storageService = inject(LocalStorageService);
    const userGuid = cbHelperService.getLoggedInUserDetails().profile.userToken.guid;
    const pathParam = (next.queryParams['path']) ? next.queryParams['path'] : null;
    const managePhoneNumber = _storageService.get(LocalStorageKeys.UnassignedTNDetails, 'cbma');
    if (state.url.includes('/admin/user/edit')) {
      if (next.queryParams.newUser === 'true') {
        router.navigate(['/settings/user/generalsettings/dashboard'],
          { queryParams: next.queryParams, replaceUrl: true });
      } else {
        router.navigate(['/settings/user/generalsettings/dashboard'], { queryParams: next.queryParams });
      }
    } else if (state.url.includes('/admin/account/edit')) {
      router.navigate(['/settings/account/accountinfo'], { queryParams: next.queryParams });
    } else if (state.url.includes('/voice/voicemail')) {
      router.navigate(['/settings/user/voicesettings/voicemailsettings'],
        { queryParams: { guid: userGuid, path: pathParam } });
    }
    if (state.url.includes('/settings/user/generalsettings/dashboard/') && !state.url.includes('#')) {
      const _fragment = state.url.split('?')[0].split('/').pop();
      const url = state.url.includes('?') ? state.url.split('?')[0] : state.url;
      router.navigate([url], { queryParams: next.queryParams, fragment: _fragment , replaceUrl: true });
    }
    if(managePhoneNumber && state.url.includes('/user/voicesettings/callsettings')
     && !state.url.includes('?phonenumber=unassigned')) {
      const url = state.url.includes('?') ? state.url.split('?')[0] : state.url;
      const queryParam = {phonenumber:'unassigned',...next.queryParams};
      router.navigate([url], { queryParams: queryParam});
    }
    return true;
}
