import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CommonMessageService, CBHelperService } from 'common-ui-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import gql from 'graphql-tag';

@Injectable()
export class AEMMessagesService {

    public contentLoaded = false;
    public unreadCount: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public messagesList: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getUserResponse = new BehaviorSubject<any>(null);

    constructor(private apollo: Apollo, public commonMessageService: CommonMessageService,
        public cbHelperService: CBHelperService) { }
    public getMessages(id: string) {
        console.log('OKTAFLOW-AEMMessagesService getMessages query');
        const getMessagesQuery = gql`
        query getMessagesList($id: ID!){ 
            getUser(id: $id) {
                id
                firstName
                lastName
                isValidationShown
                isProfileAdmin
                isProfileOwner
                isSPO
                isEndUser
                isSPA
                login {
                    id
                    contactType
                    contactValue
                    isVerified
                }
                contacts {
                    id
                    contactType
                    contactValue
                    isVerified
                }
                messageNotification(messageLimit: 5, messageStatus: "N") {
                    unreadCount
                    notificationList {
                        time
                        status
                        subject
                        notificationId
                    }
                }
            }
        }
    `;

        return this.apollo.query({
            query: getMessagesQuery,
            variables: {
                id
            },
        });
    }

    public getOutageAlert(accountStatusFilter: string) {
        const profileGuid = this.cbHelperService.getLoggedInUserDetails().profile.userToken.profileGuid;
        const getOutageAlertQuery = gql`
        query getProfile($accountStatusFilter: String!,$profileGuid: String!){  
            getProfile(accountStatusFilter: $accountStatusFilter) {
                indicatorEvents {
                    plannedOutageIndicator
                    promotionalOutageIndicator
                }
                unplannedOutageIndicator
                pinpointAlerts {
                    indicator
                    alertsList {
                        action
                        message
                    }
                }
                hasServiceAppointment(profileGuid: $profileGuid) {
                    accountGuid
                    date
                    isMultiple
                    time
                }
                deviceInstallDetails {
                    selfInstallStatus
                    euAcctGuid
                  }
            }
        }`;
        return this.apollo.query({
            query: getOutageAlertQuery,
            variables: {
                accountStatusFilter,
                profileGuid
            },
        });
    }

    public getOutageList() {
        const getOutageQuery = gql`
        query getOutageList {
            listOutages(filter: PLANNED) {
            accountExternalAttrs {
            serviceAddress {
            city
            houseNumber
            state
            street
            zipCode
            }
        }
            alias
            outage(outageFilter : false) {
            etr    
            outageDate
        }
        }
        }
        `;
        return this.apollo.query({
            query: getOutageQuery,
            variables: {
            },
        });
    }

    public getUserDetails(): Observable<unknown> {
        return this.getUserResponse?.asObservable();
    }

    public setUserDetails(response: unknown) {
        this.getUserResponse?.next(response);
    }

    public getFttxList() {
        const getFttxQuery = gql`
        query getFttxList {
            listOutages(filter: PROMOTIONAL) {
            accountExternalAttrs {
            serviceAddress {
            city
            houseNumber
            state
            street
            zipCode
            }
        }
            alias
        }
        }
        `;
        return this.apollo.query({
            query: getFttxQuery,
            variables: {
            },
        });
    }
}
