<app-loader [overlay]="true" message="{{resourceBundle.loaderMessage}}"></app-loader>
<div role="dialog"  aria-modal="true" aria-describedby="modal-title" [ngClass]="{'defocus-content pointer-none': spinnerService.loseFocus}">
  <span class="sr-only" aria-live="polite" aria-atomic="true"  id="modal-title" >{{resourceBundle.alertDialog}} {{makePaymentService.title}} {{resourceBundle.headingLevel4}}</span>
<div class="modalheader pt-3 pb-3" *ngIf="!isMobileView" [ngClass]="!makePaymentService.showStatement ? 'modal-header' : 'display-flex'">
  <a href="javascript:void(0)" id="navigationComponent" (click)="goToAccounts()" attr.aria-label="{{resourceBundle.backToAccountsAndAmounts}}"
     (keydown)="makePaymentMopFocus($event,navigationComponent,continueBtn)" class="margin-top-10"
    *ngIf="makePaymentService.showStatement" tabindex="0">
    <img src="assets/images/blueprint/admin_redesign/navigate_back.svg" class="vertical-align-text-top"
     alt="navigate_back"  role="presentation" tabindex="-1"/>
  </a>
  <div class="col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
    <ng-container *ngIf="makePaymentService.contentLoaded; else headerSpinTemplate">
    <h4 class="h4" [ngClass]="{'ml-4' : makePaymentService.showStatement}"
    [attr.aria-label]="makePaymentService.title">{{makePaymentService.title}}</h4>
  </ng-container>
  </div>
  <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right">
    <a href="javascript:void(0)" class="cb-modal-close-btn z-index-2" (click)="close('Close click');onClickClose();"
      *ngIf="!makePaymentService.showStatement && makePaymentService.contentLoaded" aria-label="Close" role="button" tabindex="0"
      id="closeBtn" (keydown)="makePaymentMopFocus($event,closeBtn,continueBtn)" appModalfocus></a>
  </div>
  <!--added tabindex="0" id="closeBtn" (keydown)="makePaymentMopFocus($event)" for Accessibility-->

</div>
<div [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-xs-12 modal-body pb-4': !isMobileView,'px-0': isAddPayment, 'px-4 mt-4': isPayment || isPaymentFailure}">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      [ngClass]="{'margin-top-neg-2em' : commonMessageService.displayMessage.show}"
      *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.type == 'error' && commonMessageService.displayMessage.id === 'makePayment'">
      <app-commonmessage [type]="commonMessageService.displayMessage.type"
        [globalMessage]="commonMessageService.displayMessage.message" [level]="'globalPage'">
      </app-commonmessage>
    </div>
  </div>

  <!--End-->
  <ng-container *ngIf="makePaymentService.contentLoaded; else loadSpinTemplate">
    <!-- Multi Account and Single Account Multi Statement Start -->
    <ng-container
      *ngIf="(billingService.isMultiAccount || billingService.isMultiStatement) && !isMobileView && !billingService.payArrangeFlow; else singleAccount">
      <!--Stepper Start -->
      <div class="row margin-bottom-m-2-25" *ngIf="!isPayment && !isPaymentFailure ">
        <app-stepper *ngIf="!isMobileView" class="mt-md-5 mt-5 shortStepper"
          [ngClass]="isMOP ? 'col-md-10 offset-md-1 col-sm-10 offset-sm-1' : 'offset-md-2 col-md-8 col-sm-8 offset-sm-2'"
          [stepIndex]="makePaymentService.currentStep - 1" [steps]="makePaymentService.steps" type="milestone">
        </app-stepper>
      </div>
      <!--Stepper End -->
      <app-accounts-and-amounts [displayAccountList]="displayAccountList" [showGradient]="showGradient"
        (selectedRecord)='showStatementDetails($event)' (mopEvent)="gotoMOP($event)" id="accountsAndAmounts"
        [totalCount]="totalCount"
        *ngIf="!makePaymentService.showStatement && !isMOP && !isReview && !isPayment && !isPaymentFailure && !isAddPayment">
      </app-accounts-and-amounts>
      <app-statement-details [selectedRecord]="selectedRecord" id="statementDetails" (goToAccounts)="goToAccounts()"
        *ngIf="makePaymentService.showStatement && !isMOP && !isReview && !isPayment && !isAddPayment">
      </app-statement-details>

      <app-method-of-payments *ngIf="isMOP && !isReview && !isPayment && !isPaymentFailure  && !isAddPayment"
        [newMOPObj]="makePaymentService.newMOPObj" [editMOPObj]="editMOPObj" (editMOPEmitter)="getEditObj($event)"
        (reviewEvent)="gotoReview($event)" (accAndAmountsEvent)="gotoAccAndAmounts()"
        (addPaymentEvent)="gotoAddPaymentFlow($event)" [isAddSuccess]="isAddSuccess">
      </app-method-of-payments>

      <!--Add Payment Method Form-->
      <app-redesignpaymentmethodform
        *ngIf="isAddPayment && !isMOP && !isReview && !isPayment && !makePaymentService.showStatement"
        (modalClose)="redirectToMOPDetails($event)" (mopEmmiter)="newMOPDetails($event)" [restrictedMop]="restrictedMop"
        [singleAccountSelected]="makePaymentService.isSingleAccountSelected" [isSPMAccount]="isSPMAccount"
        [requestType]="requestType" [editMopObject]="editMopObject" (mopEvent)="gotoMOP($event)" [existingMopList]="mopList"
        [accountGuid]="this.makePaymentService.selectedStatements[0].guid" [hasMaxNumberOfMops]="hasMaxNumberOfMops">
      </app-redesignpaymentmethodform>
      <!-- End -->

      <app-review-and-submit-payment *ngIf="!isMOP && isReview && !isPayment && !isPaymentFailure && !isAddPayment"
        (submitPaymentEvent)="gotoPayment()" (editEvent)="gotoAccAndAmounts()" (backEvent)="gotoMOPfromReview($event)">
      </app-review-and-submit-payment>
      <app-payment-confirmation *ngIf="!isMOP && !isReview && isPayment && !isPaymentFailure && !isAddPayment"
        (backToHomeEvent)="close($event)"></app-payment-confirmation>
      <app-payment-error
        *ngIf="!isMOP && !isReview && !makePaymentService.showStatement && !isPayment && !isAddPayment && isPaymentFailure"
        (backToHomeEvent)="close($event)" (submitPaymentEvent)="gotoPayment()"
        (selectedRecord)='showStatementDetails($event)'></app-payment-error>
    </ng-container>
    <!-- Multi Account and Single Account Multi Statement End -->

    <!-- Single Account Single Statement Start -->
    <ng-template #singleAccount>
      <app-single-statement-mop *ngIf="isMOP && !isReview && !isPayment" [newMOPObj]="makePaymentService.newMOPObj"
        [singleStatementDetails]="singleStatementObj" [isEditMop]="isEditMop" [isAddSuccess]="isAddSuccess" (reviewEvent)="gotoReview($event)"
        (addPaymentEvent)="gotoAddPaymentFlow($event)" (editMOPEmitter)="getEditObj($event)"></app-single-statement-mop>

      <!--Add Payment Method Form-->
      <app-redesignpaymentmethodform *ngIf="isAddPayment" (mopEmmiter)="newMOPDetails($event)"
        [restrictedMop]="restrictedMop" [isSPMAccount]="isSPMAccount" [accountGuid]="singleStatementObj.guid"
        [requestType]="requestType" [editMopObject]="editMopObject" (modalClose)="redirectToMOPDetails($event)"
        (mopEvent)="gotoMOP($event)" [singleAccountSelected]="singleAccountSelected" [existingMopList]="mopList"
        [hasMaxNumberOfMops]="hasMaxNumberOfMops">
      </app-redesignpaymentmethodform>
      <!-- End -->

      <app-review-and-submit-payment *ngIf="!isMOP && isReview && !isPayment"
        [singleStatementDetails]="singleStatementObj" (submitPaymentEvent)="gotoPayment()"
        (backEvent)="gotoMOPfromReview($event)" (editEvent)="gotoMOP($event)">
      </app-review-and-submit-payment>
      <app-payment-confirmation *ngIf="!isMOP && !isReview && isPayment" (backToHomeEvent)="close($event)"
        [singleStatementDetails]="singleStatementObj">
      </app-payment-confirmation>
      <app-payment-error
        *ngIf="!isMOP && !isReview && !makePaymentService.showStatement && !isPayment && !isAddPayment && isPaymentFailure && isMobileView"
        (backToHomeEvent)="close($event)" (submitPaymentEvent)="gotoPayment()"
        (selectedRecord)='showStatementDetails($event)'></app-payment-error>
    </ng-template>
    <!-- Single Account Single Statement End -->
  </ng-container>
</div>
</div>
<ng-template #loadSpinTemplate>
  <div class="col-6 offset-3 mt-2">
    <app-skeletonloader formSkeleton="true" rowCount="1"></app-skeletonloader>
  </div>
  <div class="col-12 mt-3">
    <app-skeletonloader formName="Statement List" formSkeleton="true" rowCount="5"
      datatableName="Statement List"></app-skeletonloader>
  </div>
</ng-template>

<ng-template #headerSpinTemplate>
  <div class="col-12 mb-n5 mt-n2">
    <app-skeletonloader formSkeleton="true" rowCount="1"></app-skeletonloader>
  </div>
</ng-template>