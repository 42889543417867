export const enum Cookie {
    CurrentUser = 'cbma-current-user',
    OktaSessionId = 'cbma-okta-session-id',
    CurrentUserSessionId = 'cbma-current-user-session-id',
    UserType = 'cbma-user-type-csr',
    ReloadPage = 'cbma-reload_page',
    UpdatedField= 'cbma-updatedField',
    External= 'external',
    REMEMBERME = 'cox-cb-rememberme-user',
    CBSID = '_cbsid',
    CBAT = '_cbat',
    USERROLE = 'userrole',
    TalonToken = 't-token',
    uvtoken = 'uv-token',
    adminuser = 'adminuser'
}

export const enum LocalStorageKeys {
    Authtoken = 'authtoken',
    UserToken = 'user_token',
    LoggedInProfileDetails = 'loggedInProfileDetails',
    LoggedInUserDetails = 'loggedInUserDetails',
    BroadworksDetails = 'broadworksDetails',
    Maintenancelist = 'maintenancelist',
    MarketRollOut = 'marketRollOut',
    EditingUserguid = 'user_guid',
    SubmittedUser= 'submittedUser',
    CurrentOffset= 'currentOffset',
    FirstLogin= 'firstLogin',
    EspmToken= 'espm_token',
    SailStatusArray= 'sailStatusArray',
    TimerActive= 'timerActive',
    PrevCall= 'prevCall',
    IdleDetected= 'idle-detected',
    SelectedAccount= 'selecedAccount',
    StepUsed= 'stepused',
    LatestSelectedAccount= 'latestSelectedAccount',
    StepRecall= 'stepRecall',
    CsrAuthtoken= 'csr_authtoken',
    NoCaasDetails = 'noCaasDetails',
    VoiceSettingsSubMenu = 'isVoiceSettingsSubMenu',
    IsConsolidated = 'isConsolidated',
    UnassignedTNDetails = 'unassignedTNDetails',
    ProfileEmail = 'profileEmail',
    ProvisioningUserType = 'provisioningUserType',
    RenewAuthtoken = 'renewAuthtoken',
    ValidUser = 'validUser'
}

export class DeleteCookies{
    cookies = [Cookie.OktaSessionId,Cookie.CurrentUserSessionId,Cookie.CurrentUser, Cookie.CBAT, Cookie.CBSID, Cookie.USERROLE,Cookie.REMEMBERME]
    
}
