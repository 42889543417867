<ng-container *ngIf="!deviceService.isMobile()">
  <div class="modal-content">
    <div role="dialog" aria-modal="true" aria-describedby="modal-title">
      <h4 class="sr-only" id="modal-title">{{resourceBundle.alertDialog}}{{resourceBundle.ipAddressManagement}}
        {{resourceBundle.headingLevel4}}</h4>
      <div class="modalheader pt-3 pb-3">
        <h4 class="h4 longHeader">{{resourceBundle.widgetTitle}}</h4>
        <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn float-right z-index-2"
          (click)="onCloseModal($event)"></a>
      </div>
      <div class="modal-body p-0">
        <ng-container [ngTemplateOutlet]="ipAddressContent"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="deviceService.isMobile()">
  <ng-container [ngTemplateOutlet]="ipAddressContent"></ng-container>
</ng-container>

<ng-template #ipAddressContent>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top-neg-3-1em pr-0 pl-0"
    *ngIf="commonMessageService.displayMessage.show" [ngClass]="{'margin-bottom-neg-2em' : !deviceService.isMobile()}">
    <app-commonmessage [type]="'error'" [globalMessage]="commonMessageService.displayMessage.message" [level]="'global'"
      *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id == 'errorMsg'  
    && commonMessageService.displayMessage.type === 'error'">
    </app-commonmessage>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-botton-30p-imp"
    [ngClass]="{'mt-3' : !deviceService.isMobile() && !commonMessageService.displayMessage.show}">
    <app-redesign-accountwidget class="col-lg-5 col-md-5 col-sm-12 col-xs-12"
      [featureName]="isMyAdmin ? 'ALL' :'INTERNET'" [hideWidgetTitle]="false"
      (selectedAccountEvent)="selectedAccount($event)">
    </app-redesign-accountwidget>
  </div>
  <ng-container *ngIf="accSelected">
    <ng-container *ngIf="ipAddressManagerModalService.contentLoaded; else loadSpinnerTemplate">
      <div class="col-md-12 col-sm-12 col-xs-12 p-0 margin-bottom-20" id='viewIpSection'>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" class="cb-accordions" id="ipAccordion"
          [activeIds]="selectedAccordian">
          <ng-container *ngIf="!showOnlyCidr && overlayTypeCheck !== ripv2String">
            <ngb-panel id="dcip-panel" *ngIf="showCidrIP">
              <ng-template ngbPanelTitle>
                <div class="accordion-trigger" *ngIf="!deviceService.isMobile()">
                  <span class="h3 float-left pl-4">{{resourceBundle.cidrAccordionTitle}}</span>
                </div>
                <div class="accordion-trigger" *ngIf="deviceService.isMobile()">
                  <span class="h3 float-left">&nbsp;{{resourceBundle.cidrAccordionTitle}}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <ng-container [ngTemplateOutlet]="cidrContent"></ng-container>
              </ng-template>
            </ngb-panel>
          </ng-container>
          <ng-container *ngIf="!showOnlyStatic && overlayTypeCheck !== ripv2String">
            <ngb-panel id="static-panel" *ngIf="showStaticIP">
              <ng-template ngbPanelTitle>
                <div class="accordion-trigger" *ngIf="!deviceService.isMobile()">
                  <span class="h3 float-left pl-4">{{resourceBundle.staticAccordionTitle}}</span>
                </div>
                <div class="accordion-trigger" *ngIf="deviceService.isMobile()">
                   <span class="h3 float-left">&nbsp;{{resourceBundle.staticAccordionTitle}}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <ng-container [ngTemplateOutlet]="showCOIip ? combinedViewContent :
                  staticIpContent"></ng-container>
              </ng-template>
            </ngb-panel>
          </ng-container>
          
          <ng-container *ngIf="!shownOnlyDeletePendingIP && combinedDataFlag && overlayTypeCheck === ripv2String && isDeletionPendingIp">
            <ngb-panel id="static-panel">
              <ng-template ngbPanelTitle>
                <div class="accordion-trigger" *ngIf="!deviceService.isMobile()">
                  <span class="h3 float-left pl-4">{{resourceBundle.staticAccordionTitle}}</span>
                </div>
                <div class="accordion-trigger" *ngIf="deviceService.isMobile()">
                   <span class="h3 float-left">&nbsp;{{resourceBundle.staticAccordionTitle}}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <ng-container [ngTemplateOutlet]="combinedViewContent"></ng-container>
              </ng-template>
            </ngb-panel>
          </ng-container>

          <ng-container *ngIf="!showOnlyCidrRipv2 && overlayTypeCheck === ripv2String">
            <ngb-panel id="cidrripv2-panel" *ngIf="showCidrRipv2IP">
              <ng-template ngbPanelTitle>
                <div class="accordion-trigger" *ngIf="!deviceService.isMobile()">
                  <span class="h3 float-left pl-4">{{resourceBundle.staticAccordionTitle}}</span>
                </div>
                <div class="accordion-trigger" *ngIf="deviceService.isMobile()">
                  <span class="h3 float-left">&nbsp;Your Static IP Address Assignments</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <ng-container [ngTemplateOutlet]="cidrRipv2Content"></ng-container>
              </ng-template>
            </ngb-panel>
          </ng-container>
          <ng-container *ngIf="!showOnlyCOI">
            <ngb-panel id="cidrripv2-panel" *ngIf="!showCidrRipv2IP && !showStaticIP && showCOIip">
              <ng-template ngbPanelTitle>
                <div class="accordion-trigger" *ngIf="!deviceService.isMobile()">
                  <span class="h3 float-left pl-4">{{resourceBundle.staticAccordionTitle}}</span>
                </div>
                <div class="accordion-trigger" *ngIf="deviceService.isMobile()">
                  <span class="h3 float-left">&nbsp;Your Static IP Address Assignments</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <ng-container [ngTemplateOutlet]="coiBlockContent"></ng-container>
              </ng-template>
            </ngb-panel>
          </ng-container>
          <ng-container *ngIf="!showOnlyDynamic">
            <ngb-panel id="dynamic-panel" class="accordion-trigger-standalone-MALP" *ngIf="showDynamicIP">
              <ng-template ngbPanelTitle>
                <div class="accordion-trigger" *ngIf="!deviceService.isMobile()">
                  <span class="h3 word-wrap-mobile pl-4">{{resourceBundle.dynamicAccordionTitle}}</span>
                </div>
                <div class="accordion-trigger" *ngIf="deviceService.isMobile()">
                  <span class="h3 float-left">&nbsp;{{resourceBundle.dynamicAccordionTitle}}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <ng-container [ngTemplateOutlet]="dynamicIpContent"></ng-container>
              </ng-template>
            </ngb-panel>
          </ng-container>
        </ngb-accordion>
        <ng-container *ngIf="showOnlyDynamic && showDynamicIP">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 pb-3 pl-3 bold gray-border-bottom-1"
            *ngIf="!deviceService.isMobile()">
            <span class="float-left">{{resourceBundle.dynamicAccordionTitle}}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4 pb-4 pl-1 gray-border-bottom-1"
            *ngIf="deviceService.isMobile()">
             <span class="float-left bold">&nbsp;Dynamic IP Addresses Currently In Use</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
            <ng-container [ngTemplateOutlet]="dynamicIpContent"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="showOnlyStatic && showStaticIP && overlayTypeCheck !== ripv2String">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 pb-3 pl-3 bold" *ngIf="!deviceService.isMobile()">
            <span class="float-left">{{resourceBundle.staticAccordionTitle}}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4 pb-4 pl-1" *ngIf="deviceService.isMobile()">
             <span class="float-left bold">&nbsp;{{resourceBundle.staticAccordionTitle}}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
            <ng-container [ngTemplateOutlet]="combinedDataFlag ? combinedViewContent : staticIpContent"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="combinedDataFlag && overlayTypeCheck === ripv2String && isDeletionPendingIp && shownOnlyDeletePendingIP">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 pb-3 pl-3 bold" *ngIf="!deviceService.isMobile()">
            <span class="float-left">{{resourceBundle.staticAccordionTitle}}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4 pb-4 pl-1" *ngIf="deviceService.isMobile()">
             <span class="float-left bold">&nbsp;{{resourceBundle.staticAccordionTitle}}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
            <ng-container [ngTemplateOutlet]="combinedViewContent"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="showOnlyCOI && showCOIip">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 pb-3 pl-3 bold" *ngIf="!deviceService.isMobile()">
            <span class="float-left">{{resourceBundle.staticAccordionTitle}} </span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4 pb-4 pl-1" *ngIf="deviceService.isMobile()">
             <span class="float-left bold">&nbsp;{{resourceBundle.staticAccordionTitle}}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
            <ng-container [ngTemplateOutlet]="coiBlockContent"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="showOnlyCidr && showCidrIP && overlayTypeCheck !== ripv2String">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 pb-3 pl-3" *ngIf="!deviceService.isMobile()">
            <h4 class="float-left">{{resourceBundle.cidrAccordionTitle}}</h4>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4 pb-4 pl-1" *ngIf="deviceService.isMobile()">
             <span class="float-left bold">&nbsp;Your CIDR Block&nbsp;IP Address<br>&nbsp;Assignments</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
            <ng-container [ngTemplateOutlet]="cidrContent"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="showOnlyCidrRipv2 && showCidrRipv2IP && overlayTypeCheck === ripv2String">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 pb-3 pl-3 bold" *ngIf="!deviceService.isMobile()">
            <span class="float-left">{{resourceBundle.staticAccordionTitle}}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4 pb-4 pl-1" *ngIf="deviceService.isMobile()">
             <span class="float-left bold">&nbsp;{{resourceBundle.staticAccordionTitle}}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
            <ng-container [ngTemplateOutlet]="combinedDataFlag ? combinedViewContent : cidrRipv2Content"></ng-container>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="this.showCidrIP || this.showStaticIP || this.showDynamicIP || showCidrRipv2IP || showCOIip || this.isDeletionPendingIp">
        <div class="pad-left-p-15 p-3" *ngIf="!deviceService.isMobile()">{{ resourceBundle.ipAddressdesc }}</div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right"
          [ngClass]="{'pb-2  pr-4': !deviceService.isMobile()}">
          <button type="button" class="button pull-right"
            (click)="printPdf('print-section')">{{resourceBundle?.buttonLabel?
            resourceBundle.buttonLabel:"Print PDF"}}</button>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-left pb-2"
          [ngClass]="{'pl-4': !deviceService.isMobile(), 'pl-3' : deviceService.isPageZoomed()}">
          <button type="button" class="button button-secondary pull-left"
            (click)="onCloseModal($event)">{{resourceBundle?.cidrclosebuttonlabel? resourceBundle?.cidrclosebuttonlabel:
            "Close"}}</button>
        </div>
      </ng-container>

    </ng-container>
    <ng-template #cidrBlockModal let-close="close" let-dismiss="dismiss">
      <div role="dialog" aria-describedby="modal-heading" aria-modal="true">
        <span class="sr-only" id="modal-heading">{{resourceBundle.cidrBlocklabel}} heading level 4</span>
        <div class="modal-header modalheader margin-bottom-20">
          <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <h4 class="modal-title h4">{{ resourceBundle.cidrBlocklabel }} </h4>
          </div>
          <div class="col-lg-2 col-md-2 col-1 col-sm-2 col-xs-2 text-right">
            <a title="Close" role="button" href="javascript:void(0)"
              class="close-mobile-view cb-modal-close-btn ml-ng-25"
              (click)="tealium.button('ipaddressmanager-close','ipaddressmanager-close');close('Close click');"></a>
          </div>
        </div>
        <div class="modal-body modal-margin-top margin-top-neg-3em">
          <div class="col-md-12 col-sm-12 col-xs-12 mt-4">
            <table>
              <ng-template ngFor let-ipDetail [ngForOf]="cidrBlockView" *ngIf="!deviceService.isMobile();">
                <tr>
                  <td class="width-220px">{{ ipDetail.label }}</td>
                  <td><strong>{{ ipDetail.value }}</strong></td>
                </tr>
              </ng-template>
              <ng-template ngFor let-ipDetail [ngForOf]="cidrBlockView" *ngIf="deviceService.isMobile();">
                <tr>
                  <td>
                    <div>{{ ipDetail.label }}</div>
                    <div><strong>{{ ipDetail.value }}</strong></div>
                  </td>
                </tr>
              </ng-template>
            </table>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 margin-top-20px resetstyle">
            <button id="reset-btn" type="submit" class="button margin-left-0em"
              (click)="tealium.button('ipaddressmanager-close','ipaddressmanager-close');close('Close click');">{{resourceBundle.cidrclosebuttonlabel}}</button>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #staticIpContent>
  <ngx-datatable class="dataTable bootstrap fullscreen staticTable" id="staticTable" [rows]="staticView"
    #staticDatatable [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="0"
    [footerHeight]="0" [limit]="10" comnAccessTable [tableName]="'Static Ip Address'" role="table"
    [table]="staticDatatable" [noofRows]="staticView.length">
    <ngx-datatable-column [sortable]='false' prop="label" *ngIf="!deviceService.isMobile()">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <span class="margin-right-20 margin-left-10px">{{row.label}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]='false' prop="value" *ngIf="!deviceService.isMobile()">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <div class="margin-right-20" *ngIf="rowIndex!=0;else content"><strong>{{value}}</strong></div>
        <ng-template #content>
          <ng-template ngFor let-ip [ngForOf]="value">
            <div class=" text-nowrap display-flex div-center-align">
              <strong class=" flex-grow-1 z-index-2 position-relative margin-right-0-5">{{ ip.ip }}</strong>
              <div class="mr-5" *ngIf="ip?.deletePending" >
                <img class=" help-tooltip:hover justify-content-end margin-left-none-xs" [container]="'body'" [ngbTooltip]="'Pending Deletion'" placement="top" src="assets/images/blueprint/delete-pending.svg" alt="Pending Deletion icon">
              </div>
            </div>
          </ng-template>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]='false' prop="value" *ngIf="deviceService.isMobile()">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <div class="margin-right-20" *ngIf="rowIndex==0;else content">
          <div class="margin-right-20">{{row.label}}</div>
          <ng-template ngFor let-ip [ngForOf]="value">
            <div class=" text-nowrap display-flex div-center-align">
              <strong class=" flex-grow-1 z-index-2 position-relative margin-right-0-5">{{ ip.ip }}</strong>
              <div class="mr-4" *ngIf="ip?.deletePending" >
                <img class=" help-tooltip:hover justify-content-end margin-left-none-xs"  [container]="'body'" [ngbTooltip]="'Pending Deletion'" placement="top" src="assets/images/blueprint/delete-pending.svg" alt="Pending Deletion icon">
              </div>
            </div>
          </ng-template>
        </div>
        <ng-template #content>
          <div class="margin-right-20">{{row.label}}</div>
          <div class="margin-right-20"><strong>{{row.value}}</strong></div>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</ng-template>

<ng-template #dynamicIpContent>
  <div class="black col-xs-12"
    [ngClass]="{'margin-left-40px pad-left-3 text-left': !deviceService.isMobile(),'left-addon input': deviceService.isMobile()}">
    <strong>{{resourceBundle.dynamicAccordionHeader}}</strong>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 parentRes" *ngFor="let data of dynamicIpDataList; let i = index">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" class="cb-accordions">
      <ngb-panel id="dynamic-panel" class="accordion-trigger-standalone-MALP">
        <ng-template ngbPanelTitle>
          <div class="accordion-trigger">
            <span class="h6 child-accordion-header"
              [ngClass]="{'pad-left-1-5 margin-left-10px': !deviceService.isMobile(),'pl-0': deviceService.isMobile()}">
              <span class="mediumfont"
                [ngClass]="{'margin-left-10px': !deviceService.isMobile(),'pl-4': deviceService.isMobile()}">Device{{i+
                1}}:
                &nbsp;{{data.macAddress}}</span></span>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ngx-datatable class="dataTable bootstrap fullscreen" id="dynamicIpTable" #dynamicIpTable
            [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="'auto'" [limit]="10"
            [rows]="data.customerEquipmentInfoList" [columnMode]="'force'"
            [messages]="{emptyMessage: 'No record found'}" comnAccessTable [tableName]="'Dynamic IP'" role="application"
            [table]="dynamicIpTable" [noofRows]="data?.customerEquipmentInfoList.length">
            <ngx-datatable-column name="{{resourceBundle.dynamicTableLabel1}}" class="dynamicIpTableCol"
              [sortable]='false' [resizeable]='false' [maxWidth]="190" *ngIf="!deviceService.isMobile()">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span class="margin-right-20">{{row.hardwareModemMac}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{resourceBundle.dynamicTableLabel2}}" [sortable]='false' [resizeable]='false'
              [maxWidth]="400" *ngIf="!deviceService.isMobile()">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div class="col-md-12 col-sm-12 col-xs-12"><strong>LAN</strong></div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  {{row.ipv6Lan ? row.ipv6Lan : resourceBundle.ip_empty_msg}}</div>
                <div class="col-md-12 col-sm-12 col-xs-12"><strong>WAN</strong></div>
                <div class="col-md-12 col-sm-12 col-xs-12 pad-right-0">
                  {{row.ipv6Wan ? row.ipv6Wan : resourceBundle.ip_empty_msg}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{resourceBundle.dynamicTableLabel3}}" [sortable]='false' [resizeable]='false'
              [maxWidth]="190" *ngIf="!deviceService.isMobile()">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span class="margin-right-20">{{row.ipv4 ? row.ipv4 : resourceBundle.ip_empty_msg}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]='false' *ngIf="deviceService.isMobile()">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div>
                  <span class="margin-right-20"><strong>{{resourceBundle.dynamicTableLabel1}}</strong></span>
                  <div class="margin-right-20 margin-bottom-15">{{row.hardwareModemMac}}</div>
                </div>
                <div>
                  <span class="margin-right-20"><strong>{{resourceBundle.dynamicTableLabel2}}</strong></span>
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-right-20 pad-left-0"><strong>LAN</strong></div>
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-right-20 pad-left-0">
                    {{row.ipv6Lan ? row.ipv6Lan : resourceBundle.ip_empty_msg}}</div>
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-right-20 pad-left-0"><strong>WAN</strong></div>
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-right-20 pad-left-0 margin-bottom-15">
                    {{row.ipv6Wan ? row.ipv6Wan : resourceBundle.ip_empty_msg}}</div>
                </div>
                <div>
                  <span class="margin-right-20"><strong>{{resourceBundle.dynamicTableLabel3}}</strong></span>
                  <div class="margin-right-20">{{row.ipv4 ? row.ipv4 : resourceBundle.ip_empty_msg}}</div>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</ng-template>

<ng-template #cidrContent>
  <ngx-datatable class="dataTable bootstrap fullscreen staticTable" id="cidrTable" [rows]="cidrView" #cidrDatatable
    [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="0" [footerHeight]="0" [limit]="10"
    comnAccessTable [tableName]="'CIDR'" role="application" [table]="cidrDatatable" [noofRows]="cidrView.length">
    <ngx-datatable-column [sortable]='false' prop="label" *ngIf="!deviceService.isMobile()">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <span class="margin-right-20 margin-left-10px">{{ row.label }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]='false' prop="value" *ngIf="!deviceService.isMobile()">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <span class="margin-right-20" *ngIf="rowIndex!=0;else contentcidr"><strong>{{ value }}</strong></span>
        <ng-template #contentcidr>
          <a class="blue mouse-link" href="javascript:void(0)" role="link" (click)="openCidrBlockModal()"
            aria-live="polite">
            <span class="sr-only" aria-live="polite">Customer Network {{ value }}</span><strong aria-hidden="true">{{
              value }}</strong></a>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]='false' prop="label" *ngIf="deviceService.isMobile()">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <div class="margin-right-20">{{row.label}}</div>
        <div class="margin-right-20" *ngIf="rowIndex!=0;else contentcidr"><strong>{{ row.value }}</strong></div>
        <ng-template #contentcidr>
          <div class="margin-right-20" class="blue mouse-link" (click)="openCidrBlockModal()">
            <strong>{{ row.value }}</strong>
          </div>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</ng-template>

<ng-template #cidrRipv2Content>
  <ng-container *ngIf="ipResponse?.ripV2AddressBlocks?.length === 1">
    <ng-container [ngTemplateOutlet]="commonRipv2View"></ng-container>
  </ng-container>
  <ng-container *ngIf="ipResponse?.ripV2AddressBlocks?.length > 1">
    <div class="col-lg-12 col-sm-12 p-0">
      <div class="col-12 pr-0 row justify-content-spaceBtwn">
        <ng-container [ngTemplateOutlet]="showDropdown"></ng-container>
        <div class="pr-0 mr-1 pt-3 row justify-content-end bold"
          [ngClass]="deviceService.isMobile() ? 'col-4' : 'col-3'">
          Block {{ selectedCidrTabIndex + 1 + ' of ' + ipResponse?.ripV2AddressBlocks?.length}}</div>
      </div>
      <ng-template [ngTemplateOutlet]="commonRipv2View"></ng-template>
    </div>
  </ng-container>
</ng-template>

<ng-template #commonRipv2View>
  <div class="col-lg-12 col-sm-12">
    <ngx-datatable class="dataTable bootstrap fullscreen staticTable" id="ripv2Table" [rows]="ripv2View" #ripv2Datatable
      [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="0" [footerHeight]="0"
      [limit]="10" comnAccessTable [tableName]="'RIPV2 Static Ip Address'" role="application" [table]="ripv2Datatable"
      [noofRows]="ripv2View.length">
      <ngx-datatable-column [sortable]='false' [width]="362" [minWidth]="362" [maxWidth]="362" prop="label"
        *ngIf="!deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <span class="margin-right-20 margin-left-10px">{{row.label}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]='false' [width]="362" [minWidth]="362" [maxWidth]="362" prop="value"
        *ngIf="!deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div class="margin-right-20" *ngIf="rowIndex!=0;else content"><strong>{{value}}</strong></div>
          <ng-template #content>
            <ng-template ngFor let-ip [ngForOf]="value">
              <div><strong>{{ ip.ip }}</strong></div>
            </ng-template>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]='false' prop="value" [width]="287" [minWidth]="287" [maxWidth]="287"
        *ngIf="deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div class="margin-right-20" *ngIf="rowIndex==0;else content">
            <div class="margin-right-20">{{row.label}}</div>
            <ng-template ngFor let-ip [ngForOf]="value">
              <div><strong>{{ ip.ip }}</strong></div>
            </ng-template>
          </div>
          <ng-template #content>
            <div class="margin-right-20">{{row.label}}</div>
            <div class="margin-right-20"><strong>{{row.value}}</strong></div>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>

<ng-template #coiBlockContent>
  <ng-container *ngIf="ipResponse?.coIAddressDetails?.length === 1">
    <ng-container [ngTemplateOutlet]="commonCOIBlockView"></ng-container>
  </ng-container>
  <ng-container *ngIf="ipResponse?.coIAddressDetails?.length > 1">
    <div class="col-lg-12 col-sm-12 p-0">
      <div class="col-12 pr-0 row justify-content-spaceBtwn">
        <ng-container [ngTemplateOutlet]="showDropdown"></ng-container>
        <div class="pr-0 mr-1 pt-3 row justify-content-end bold"
          [ngClass]="deviceService.isMobile() ? 'col-4' : 'col-3'">
          Block {{ selectedCidrTabIndex + 1 + ' of ' + ipResponse?.coIAddressDetails?.length}}</div>
      </div>
      <ng-template [ngTemplateOutlet]="commonCOIBlockView"></ng-template>
    </div>
  </ng-container>
</ng-template>

<ng-template #commonCOIBlockView>
  <div class="col-lg-12 col-sm-12">
    <ngx-datatable class="dataTable bootstrap fullscreen staticTable" id="ripv2Table" [rows]="coiBlockView"
      #ripv2Datatable [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="0"
      [footerHeight]="0" [limit]="10" comnAccessTable [tableName]="'COI Ip Address'" role="application"
      [table]="ripv2Datatable" [noofRows]="coiBlockView?.length">
      <ngx-datatable-column [sortable]='false' [width]="362" [minWidth]="362" [maxWidth]="362" prop="label"
        *ngIf="!deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <span class="margin-right-20 margin-left-10px">{{row.label}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]='false' [width]="362" [minWidth]="362" [maxWidth]="362" prop="value"
        *ngIf="!deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div class="margin-right-20" *ngIf="rowIndex!=0;else content"><strong>{{row.value}}</strong></div>
          <ng-template #content>
            <ng-template ngFor let-ip [ngForOf]="value">
              <div><strong>{{ ip.ip }}</strong></div>
            </ng-template>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]='false' prop="value" [width]="287" [minWidth]="287" [maxWidth]="287"
        *ngIf="deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div class="margin-right-20" *ngIf="rowIndex==0;else content">
            <div class="margin-right-20">{{row.label}}</div>
            <ng-template ngFor let-ip [ngForOf]="value">
              <div><strong>{{ ip.ip }}</strong></div>
            </ng-template>
          </div>
          <ng-template #content>
            <div class="margin-right-20">{{row.label}}</div>
            <div class="margin-right-20"><strong>{{row.value}}</strong></div>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>

<ng-template #combinedViewContent>
  <ng-container *ngIf="combinedDataView?.length === 1">
    <ng-container [ngTemplateOutlet]="combinedBlockView"></ng-container>
  </ng-container>
  <ng-container *ngIf="combinedDataView?.length > 1">
    <div class="col-lg-12 col-sm-12 p-0">
      <div class="col-12 pr-0 row justify-content-spaceBtwn">
        <ng-container [ngTemplateOutlet]="showDropdown"></ng-container>
        <div class="pr-0 mr-1 pt-3 row justify-content-end bold"
          [ngClass]="deviceService.isMobile() ? 'col-5' : 'col-3'">
          Block {{ selectedCidrTabIndex + 1 + ' of ' + combinedDataView?.length}}</div>
      </div>
      <ng-template [ngTemplateOutlet]="combinedBlockView"></ng-template>
    </div>
  </ng-container>
</ng-template>

<ng-template #combinedBlockView>
  <div class="col-lg-12 col-sm-12">
    <ngx-datatable class="dataTable bootstrap fullscreen staticTable" id="ripv2Table" [rows]="combinedBlocks"
      #ripv2Datatable [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="0"
      [footerHeight]="0" [limit]="10" comnAccessTable [tableName]="'Combined Ip Address'" role="application"
      [table]="ripv2Datatable" [noofRows]="combinedBlocks?.length">
      <ngx-datatable-column [sortable]='false' [width]="362" [minWidth]="362" [maxWidth]="362" prop="label"
        *ngIf="!deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <span class="margin-right-20 margin-left-10px">{{row.label}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]='false' [width]="343" [minWidth]="343" [maxWidth]="343" prop="value"
        *ngIf="!deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div class="margin-right-20" *ngIf="rowIndex!=0;else content"><strong>{{value}}</strong></div>
          <ng-template #content>
            <ng-template ngFor let-ip [ngForOf]="value">
              <div class=" text-nowrap display-flex div-center-align">
                <strong class=" flex-grow-1 z-index-2 position-relative margin-right-0-5">{{ ip.ip }}</strong>
                <div *ngIf="ip?.deletePending" >
                  <img class=" help-tooltip:hover justify-content-end margin-left-none-xs" [container]="'body'" [ngbTooltip]="'Pending Deletion'" placement="top" src="assets/images/blueprint/delete-pending.svg" alt="Pending Deletion icon">
                </div>
              </div>
            </ng-template>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]='false' prop="value" [width]="287" [minWidth]="287" [maxWidth]="287"
        *ngIf="deviceService.isMobile()">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <div *ngIf="rowIndex==0;else content">
            <div>{{row.label}}</div>
            <ng-template ngFor let-ip [ngForOf]="value">
              <div class="ip-container row pl-2 justify-content-between">
                <strong>{{ ip.ip }}</strong>
                <div *ngIf="ip?.deletePending">
                  <img class="help-tooltip:hover justify-content-end margin-left-none-xs pl-5 pr-5" [ngbTooltip]="'Pending Deletion'" [container]="'body'" placement="top" src="assets/images/blueprint/delete-pending.svg" alt="Pending Deletion icon">
                </div>
              </div>
            </ng-template>
          </div>
          <ng-template #content>
            <div class="margin-right-20">{{row.label}}</div>
            <div class="margin-right-20"><strong>{{row.value}}</strong></div>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>

<ng-template #loadSpinnerTemplate>
  <div class="margin-top-15">
    <app-skeletonloader accordionName="IP Address Manager" accordionSkeleton="true" rowCount="4">
    </app-skeletonloader>
  </div>
</ng-template>

<ng-template #showDropdown>
  <div class="ipAddressBlockSelect mt-2 pl-3 mb-3" [ngClass]="deviceService.isMobile() ? 'col-5' : 'col-3'">
    <app-commonsearch #ipAddressBlockSelect [id]="'accountSelector'" [dropId]="'ipAddressManager'" class="mt-0"
      [userList]="userOptions" [genericList]="true" [showSearchTextbox]="false"
      [ariaLabel]="'Your Static IP Address Assignments'" [displayOptionValue]="displayOptionValue"
      [ngClass]="{'setMaxHeight' : userOptions?.length <= 10}" (selectedGenericName)="userOptionSelected($event)">
    </app-commonsearch>
  </div>
</ng-template>

<div id="print-section" class="hidden">
  <div class="row">
    <img alt="coxbanner" src="../assets/images/blueprint/billing/printreceipt_coxbanner.png">
  </div>
  <div class="row margin-top-20">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" class="text-center">
      <h2><b>IP Addresses</b></h2>
    </div>
  </div>
  <div class="clearfix">&nbsp;</div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="selectedAccountDetails">
      <div><b>Account Name:</b> {{ selectedAccountDetails.accountName }}</div>
      <div><b>Account Alias:</b> {{ selectedAccountDetails.alias }}</div>
      <div><b>Account Number:</b> {{ selectedAccountDetails.accountNumber12 }}</div>
      <div><b>Account Location:</b>
        {{cbHelperService.replaceNull(selectedAccountDetails.accountExternalAttrs?.serviceAddress?.street)}}
        &nbsp;{{cbHelperService.replaceNull(selectedAccountDetails.accountExternalAttrs?.serviceAddress?.houseNumber)}}&nbsp;
        {{cbHelperService.replaceNull(selectedAccountDetails.accountExternalAttrs?.serviceAddress?.city)}}
        &nbsp;{{cbHelperService.replaceNull(selectedAccountDetails.accountExternalAttrs?.serviceAddress?.state,true)}}
        &nbsp;{{cbHelperService.replaceNull(selectedAccountDetails.accountExternalAttrs?.serviceAddress?.zipCode,true)}}
      </div>
    </div>
    <div class="clearfix">&nbsp;</div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="showCidrIP && overlayTypeCheck !== ripv2String">
      <div class="clearfix">&nbsp;</div>
      <h4>Your CIDR Block IP Address Assignments</h4>
      <table [border]="1" class="wide100 border-collapse">
        <ng-template ngFor let-ipDetail [ngForOf]="cidrView">
          <tr>
            <td>{{ ipDetail.label }}</td>
            <td><strong>{{ ipDetail.value }}</strong></td>
          </tr>
        </ng-template>
      </table>
      <div class="clearfix">&nbsp;</div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      *ngIf="overlayTypeCheck === ripv2String && ipResponse?.ripV2AddressBlocks?.length === 1 && !showCOIip">
      <div class="clearfix">&nbsp;</div>
      <h4>Your Static IP Address Assignments</h4>
      <table [border]="1" class="wide100 border-collapse">
        <ng-template ngFor let-ipDetail [ngForOf]="ripv2View" let-i="index">
          <tr>
            <td>{{ ipDetail.label }}</td>
            <td>
              <ng-template [ngIf]="i==0" [ngIfElse]="other">
                <ng-template ngFor let-ip [ngForOf]="ipDetail.value">
                  <div><strong>{{ ip.ip }}</strong></div>
                </ng-template>
              </ng-template>
              <ng-template #other>
                <strong>{{ ipDetail.value }}</strong>
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </table>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      *ngIf="overlayTypeCheck === ripv2String && ipResponse?.ripV2AddressBlocks?.length > 1 && !showCOIip && !this.isDeletionPendingIp">
      <div class="clearfix">&nbsp;</div>
      <h4>Your Static IP Address Assignments</h4>
      <ng-template ngFor let-ipBlock [ngForOf]="newRipV2Block" let-i="index">
        <h4 class="mb-3">Block {{i+1}}</h4>
        <table [border]="1" class="wide100 border-collapse">
          <ng-template ngFor let-ipDetail [ngForOf]="ipBlock" let-i="index">
            <tr>
              <td>{{ ipDetail.label }}</td>
              <td>
                <ng-template [ngIf]="i==0" [ngIfElse]="other">
                  <ng-template ngFor let-ip [ngForOf]="ipDetail.value">
                    <div><strong>{{ ip.ip }}</strong></div>
                  </ng-template>
                </ng-template>
                <ng-template #other>
                  <strong>{{ ipDetail.value }}</strong>
                </ng-template>
              </td>
            </tr>
          </ng-template>
        </table>
      </ng-template>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      *ngIf="!showStaticIP && !showCidrRipv2IP && showCOIip && ipResponse?.coIAddressDetails?.length === 1">
      <div class="clearfix">&nbsp;</div>
      <h4>Your Static IP Address Assignments</h4>
      <table [border]="1" class="wide100 border-collapse">
        <ng-template ngFor let-ipDetail [ngForOf]="coiBlockView" let-i="index">
          <tr>
            <td>{{ ipDetail.label }}</td>
            <td>
              <ng-template [ngIf]="i==0" [ngIfElse]="other">
                <ng-template ngFor let-ip [ngForOf]="ipDetail.value">
                  <div><strong>{{ ip.ip }}</strong></div>
                </ng-template>
              </ng-template>
              <ng-template #other>
                <strong>{{ ipDetail.value }}</strong>
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </table>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      *ngIf="!showStaticIP && !showCidrRipv2IP && showCOIip && ipResponse?.coIAddressDetails?.length > 1">
      <div class="clearfix">&nbsp;</div>
      <h4>Your Static IP Address Assignments</h4>
      <ng-template ngFor let-ipBlock [ngForOf]="newCoiBlock" let-i="index">
        <h4 class="mb-3">Block {{i+1}}</h4>
        <table [border]="1" class="wide100 border-collapse">
          <ng-template ngFor let-ipDetail [ngForOf]="ipBlock" let-i="index">
            <tr>
              <td>{{ ipDetail.label }}</td>
              <td>
                <ng-template [ngIf]="i==0" [ngIfElse]="other">
                  <ng-template ngFor let-ip [ngForOf]="ipDetail.value">
                    <div><strong>{{ ip.ip }}</strong></div>
                  </ng-template>
                </ng-template>
                <ng-template #other>
                  <strong>{{ ipDetail.value }}</strong>
                </ng-template>
              </td>
            </tr>
          </ng-template>
        </table>
      </ng-template>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      *ngIf="showStaticIP && overlayTypeCheck !== ripv2String && !showCOIip">
      <div class="clearfix">&nbsp;</div>
      <h4>Your Static IP Address Assignments</h4>
      <table [border]="1" class="wide100 border-collapse">
        <ng-template ngFor let-ipDetail [ngForOf]="staticView" let-i="index">
          <tr>
            <td>{{ ipDetail.label }}</td>
            <td>
              <ng-template [ngIf]="i==0" [ngIfElse]="other">
                <ng-template ngFor let-ip [ngForOf]="ipDetail.value">
                  <div><strong>{{ ip.ip }}</strong></div>
                </ng-template>
              </ng-template>
              <ng-template #other>
                <strong>{{ ipDetail.value }}</strong>
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </table>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="combinedDataFlag && combinedDataView?.length === 1">
      <div class="clearfix">&nbsp;</div>
      <h4>Your Static IP Address Assignments</h4>
      <table [border]="1" class="wide100 border-collapse">
        <ng-template ngFor let-ipDetail [ngForOf]="coiBlockView" let-i="index">
          <tr>
            <td>{{ ipDetail.label }}</td>
            <td>
              <ng-template [ngIf]="i==0" [ngIfElse]="other">
                <ng-template ngFor let-ip [ngForOf]="ipDetail.value">
                  <div><strong>{{ ip.ip }}</strong></div>
                </ng-template>
              </ng-template>
              <ng-template #other>
                <strong>{{ ipDetail.value }}</strong>
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </table>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="combinedDataFlag && combinedDataView?.length > 1">
      <div class="clearfix">&nbsp;</div>
      <h4>Your Static IP Address Assignments</h4>
      <ng-template ngFor let-ipBlock [ngForOf]="newCombinedDataBlock" let-i="index">
        <h4 class="mb-3">Block {{i+1}}</h4>
        <table [border]="1" class="wide100 border-collapse">
          <ng-template ngFor let-ipDetail [ngForOf]="ipBlock" let-i="index">
            <tr>
              <td>{{ ipDetail.label }}</td>
              <td>
                <ng-template [ngIf]="i==0" [ngIfElse]="other">
                  <ng-template ngFor let-ip [ngForOf]="ipDetail.value">
                    <div><strong>{{ ip.ip }}</strong></div>
                  </ng-template>
                </ng-template>
                <ng-template #other>
                  <strong>{{ ipDetail.value }}</strong>
                </ng-template>
              </td>
            </tr>
          </ng-template>
        </table>
      </ng-template>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="showDynamicIP">
      <div class="clearfix">&nbsp;</div>
      <h4>Dynamic IP Addresses Currently In Use</h4>
      <div>Cable Modem MAC IDs</div>
      <table [border]="1" class="wide100 border-collapse">
        <tr>
          <th>Mac Address</th>
          <th>Customer Equipment</th>
          <th>IPv6</th>
          <th>IPv4</th>
        </tr>
        <ng-template ngFor let-dynamicIP [ngForOf]="dynamicIpDataList"> 
            <ng-template ngFor let-equipment [ngForOf]="dynamicIP?.customerEquipmentInfoList">
              <tr>
                <td> {{ dynamicIP?.macAddress }} </td>
              <td> {{ equipment?.hardwareModemMac }} </td>
              <td>
                <div>LAN</div>
                <div> {{ equipment?.ipv6Lan }} </div>
                <div>WAN</div>
                <div> {{ equipment?.ipv6Wan }} </div>
              </td>
              <td> {{ equipment?.ipv4 }} </td>
            </tr>
            </ng-template>
        </ng-template>
      </table>
    </div>
  </div>
</div>