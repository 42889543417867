import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { AppConfig, CoxHttpClient, IStep } from 'common-ui-lib';
import { Observable } from 'rxjs';
import { NewMethodOfPayment } from '../billing-home.model';
import {
  Statement, StatementList, ReviewAndSubmitObj, UpdateMopList, SubmitAccountBillPaymentDetails,
  PaymentByMop, PaymentConfirmationObj, SubmitPayment, FailedStatement
} from './make-payment.model';
@Injectable({ providedIn: 'root' })
export class MakePaymentService {

  public contentLoaded = false;
  public totalAmount = 0;
  public title: string;
  public showStatement = false;
  public selectedStatements: StatementList[] = [];
  public reviewAndSubmitObj: ReviewAndSubmitObj;
  public paymentConfirmationObj: PaymentConfirmationObj;
  public finalObj: PaymentByMop;
  public selectedMopForPayment = [];
  public singleAccMultiStatement: any = [];
  public multiAccMultiStatement: any = [];
  public paymentFailure = false;
  public resetMopList = false;
  public selectedAccIndex: number;
  public failedStatements: FailedStatement;
  public newMOPObj: NewMethodOfPayment[] = [];
  public steps: IStep[] = [
    {
      icon: '1',
      label: 'Accounts and Amounts',
    },
    {
      icon: '2',
      label: 'Payment Method',
    },
    {
      icon: '3',
      label: 'Submit and Pay',
    },
  ];
  public isSingleAccountSelected: boolean;
  public getStatementUrl: string;
  private _currentStep: number;
  public get currentStep(): number {
    return this._currentStep;
  }
  public set currentStep(value: number) {
    this._currentStep = value;
  }
  constructor( private apollo: Apollo,
               private coxhttp: CoxHttpClient,
               private config: AppConfig) {
   }

  //Query to get list of accounts and statementss
  public getAccountList(after: string, before: string, feature: string, first: number, last: number, searchAccountGuid: string) {
    const getAccountList = gql`
    query getAccountList($after: String, $before: String, $feature: String!, $first: Int, $last: Int, $searchAccountGuid: String){
      listAccountsByFeature(after: $after, before: $before, feature: $feature, first: $first, last: $last, searchAccountGuid: $searchAccountGuid){
        edges {
          cursor
          node {
            accountNumber12
            accountName
            alias
            guid
            accountExternalAttrs {
              status
              serviceAddress {
                houseNumber
                street
                city
                state
                zipCode
              }
            }
            billStatements(requestType: "", statementFilter: "T") {
              accountTotalAmtDue
              accountTotalAmtPastDue
              accountTotalDueDate
              accountTotalOverDue
              statements {
                amountDueToday
                billHandlerCode
                billHandlingPreferenceType
                code
                currentDue
                totalPastAmountDue
                totalAmountDue
                serviceCategories
                nextPaymentDate
                isPaperlessEligible
                isEnrolledInAutoPay
                identifier
                isAutoPayEligible
                dueDate
                customerName
                customerBusinessName
                billingAddress {
                  zipCode
                  street
                  state
                  city
                  houseNumber
                }
              }
            }
            methodOfPayments(requestType: "billpay") {
              isSPMAccount
              restrictedMop
              methodOfPayments {
                bankAccountType
                cardExpirationDate
                code
                customerAccountNumber
                customerMOPStatus
                customerName
                customerZipCode
                description
                id
                type
                statementCodes
                sequence
                routingCode
                isStoredMop
                isEasyPayMop
                isDefaultMop
                countryCode
              }
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
     }`;

    return this.apollo.query({
      query: getAccountList,
      variables: {
        after,
        before,
        feature,
        first,
        last,
        searchAccountGuid,
      },
    });
  }

  public methodofPayments(id: string) {
    const methodofPayments = gql`
    query getAccount($id: ID!){
      getAccount(id: $id) {
        methodOfPayments(requestType: "billpay") {
          restrictedMop
          isSPMAccount
          methodOfPayments {
            id
	          type
	          code
	          sequence
	          description
	          customerMOPStatus
	          bankAccountType
	          routingCode
	          customerAccountNumber
	          customerName
	          cardExpirationDate
	          customerZipCode
	          isDefaultMop
	          isEasyPayMop
	          isStoredMop
            statementCodes
            countryCode
          }
        }
      }
    }
    `;

    return this.apollo.query({
      query: methodofPayments,
      variables: {
        id
      },
    });
  }

  //Api to get statement pdf details
  public getStatementByteArray(guid: string, statementCode?: string,
    cycleId?: string, cycleDate?: string): Observable<Statement> {
    this.getStatementUrl =
    `/api/cbma/billing/services/billing/account/v3/statementpdf?guid=${guid}&statementCode=${statementCode}`;
    if (cycleDate) {
      this.getStatementUrl = this.getStatementUrl + '&statementPdfId=' + cycleId + '&cycleDate=' + cycleDate;
    }
    return this.coxhttp.get<Statement>(this.getStatementUrl,
      { disableSpinner: true, customeErrorMessage: '' }).map((res) => {
        if (res.code !== '0') {
          throw new Error(res.message);
        }
        return res;
      });
  }

  public updateMop(input: UpdateMopList) {
    const UpdateMop = gql`
      mutation UpdateMopDetails($input: UpdateMopList) {
        updateMethodOfPayment(input: $input) {
          statuses {
            responseStatus {
              warning
              transactionId
              message
              id
              code
            }
            statementCode
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: UpdateMop,
      variables: {
        input
      },
    });

  }

  public deleteMop(mopId: string) {
    const DeleteMop = gql`
      mutation UpdateMopDetails($mopId: String!) {
        deleteMethodOfPayment(mopId: $mopId) {
          id
        }
      }
    `;
    return this.apollo.mutate({
      mutation: DeleteMop,
      variables: {
        mopId
      },
    });
  }

  public submitPaymentDetails(input: SubmitPayment) {
    const SubmitPaymentDetails = gql`
      mutation submitPaymentDetails($input: SubmitPayment) {
        submitPayment(input: $input) {
          statuses {
            code
            id
            message
            transactionId
            warning
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: SubmitPaymentDetails,
      variables: {
        input
      },
    });
  }
}