import { Contact } from './contact.model';

export class User {
    id: string;
    guid: string;
    email: string;
    firstName: string;
    lastName: string;
    type: string;
    lastPasswordChangeDate: string;
    requiredPasswordChange: string;
    primaryPhoneNumber: string;
    altPhoneNumber: string;
    status: string;
    voicemanagerAssignedPhoneNum: string;
    voicemanagerAssignedPhoneAccountNumber: string;
    voicemanagerServiceCodeList: string[];
    onlineStorageAllocatedStorage: number;
    onlineStorageUsedStorage: number;
    profilePrivileges: string[];
    accountsConsolidatedData: AccountsConsolidatedData;
    siteIdList: string[];
    vipCodeList: string[];
}

export class AccountsConsolidatedData {
    privileges: string[];
    uniqueServiceCodes: string[];
}

export class ProducedServiceBean {
    transactionId: string;
    errorCode: string;
    errorMessage: string;
    warning: string;
    code: string;
    message: string;
}
export class UserStatusType {
    status: string;
}
export class BaseUserDetails {
    id: string;
    guid: string;
    userType: string;
    contact: Contact;
    status: UserStatusType;
    serviceCodes: string;
    assignedPhoneNumber: string;
}

export class BaseUserList extends ProducedServiceBean {
    userList: BaseUserDetails[];
}

export class OtherUsers {
    fname: string;
    lname: string;
    uname: string;
}

export class UpdateUserPassword {
    id: string;
    oldPassword: string;
    newPassword: string;
}
