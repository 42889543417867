import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime',
})

export class DateTimePipe implements PipeTransform {
  private meridian: string;
  private datePart: string;
  private value: Date;
  private zone: string;

  transform(date: number, convertToMilliseconds: boolean = true) {
    if (date) {
      this.value = convertToMilliseconds ? new Date(date * 1000) : new Date(date);
      this.datePart = new DatePipe('en-US').transform(this.value, 'MM/dd/yyyy');
      this.meridian = new DatePipe('en-US').transform(this.value, 'h:mm a').toString();
      this.zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
      return `${this.datePart} ${this.meridian} ${this.zone}`;
    } else {
      return null;
    }
  }
}