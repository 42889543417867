import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AEMClientService, AppConfig, CBHelperService, DeviceDetectorService, DigitalProfileSharedService, ResourceBundle, SelfInstallService, TealiumUtagService} from 'common-ui-lib';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseNum, ChannelStatus } from './self-install.model';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-self-install',
  templateUrl: './self-install.component.html',
  styleUrls: ['./self-install.component.scss']
})
export class SelfInstallComponent implements OnInit {
  @ViewChild('selfInstallModal') modalRef: ElementRef;
  public orderDetailRows: HTMLElement[]=[];
  public caseNum = CaseNum;
  public resourceBundle: ResourceBundle = {};
  public isMyadmin: boolean;
  public isMobile = false;
  public ChannelStatus = ChannelStatus;
  constructor(private aemClient: AEMClientService,
              public activeModal: NgbActiveModal,
              public selfInstallService: SelfInstallService,
              private meta: Meta,
              public tealium: TealiumUtagService,
              public cbHelperService: CBHelperService,
              public deviceService: DeviceDetectorService,
              public digitalProfileService: DigitalProfileSharedService,
            public appConfig: AppConfig
  ) { } 

  @HostListener('keydown', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    if(this.isMyadmin) {
      if(this.orderDetailRows.length<1) {
        this.orderDetailRows = Array.from(this.modalRef.nativeElement.querySelectorAll(
          '.word-break'
        )) as HTMLElement[];
      }
      if(!event.shiftKey) {
        if(document.activeElement === this.orderDetailRows[this.orderDetailRows.length-1]) {
          event?.preventDefault();
          document.getElementById('closeBtn').focus();
        }
      }
    }
  }

  ngOnInit() {
    this.aemClient.getBundle('selfinstall', 'app')
      .subscribe((data: ResourceBundle) => {
        this.resourceBundle = data;
      });
    this.meta.addTag({ name: 'description', content: this.resourceBundle.oliverChatText });
    this.isMyadmin= this.cbHelperService.isMyAdmin();
    if(this.deviceService.isMobile()){
      this.isMobile = true;
    }
  }

  public triggerTealium(eventName: string){
    if (!this.isMyadmin) {
      this.tealium.button(eventName, eventName);
    }
  }

  onFocus(event: KeyboardEvent) {
    if(this.isMyadmin) {
      event?.preventDefault();
      if(this.orderDetailRows.length>0) {
        this.orderDetailRows[this.orderDetailRows.length-1].focus();
      } else {
        document.getElementById('closeBtn').focus();
      }
    }
  }

  public onModalClose(){
    this.tealium.button('Close', 'Close');
    sessionStorage.removeItem('isNetAssurance');
    this.digitalProfileService.netAssuranceStatus = false;
    if (document?.getElementById('web-messenger-container')) {
      const letsChatButton: HTMLElement = document?.getElementById('web-messenger-container');
      letsChatButton?.classList?.remove('defocus');
    } else if(document?.getElementsByClassName('bcFloat')?.length > 0){
      const letsChatButton = document?.getElementsByClassName('bcFloat')[0];
      letsChatButton?.classList.remove('defocus');
    }

    if(this.isMobile){
      document.getElementById('myMessagesLinkMobile').focus();
    } else{
      (document.getElementsByClassName('messagesLink')[1] as HTMLElement)?.focus();
    }
  }
}
