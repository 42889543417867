import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountWidgetService } from '../accountwidget/accountwidget.service';
import { ErrorMessageService } from '../messages/error-message.service';
import { Message } from '../messages/message.component';
import { BaseAccount } from '../models/accounts/baseaccounts.model';

@Component({
  selector: "app-accountwidget-basic",
  templateUrl: './accountwidget-basic.component.html',
  styleUrls: ['./accountwidget-basic.component.scss'],
})
export class AccountwidgetBasicComponent implements OnInit, OnDestroy {
  @Output() selectedAccount = new EventEmitter<BaseAccount>();
  baseAccounts: BaseAccount[] = [];
  singleAccount = false;
  twotosixAccounts = false;
  sevenplusAccounts = false;
  accountWidgetMessage: Message;
  private onDestroy$ = new Subject<boolean>();

  constructor(private accountWidgetService: AccountWidgetService,
              private errorMessageService: ErrorMessageService) {
    this.accountWidgetMessage = new Message();
  }

  ngOnInit() {
    const pageId: string = window.location.href.split('/').pop();
    this.accountWidgetService.getBaseAccountsList(pageId)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
      (data) => {
        this.baseAccounts = data.baseAccounts;
      },
      (error) => {
        this.errorMessageService.errorMessage.show = true;
        this.errorMessageService.errorMessage.type = 'error';
        this.errorMessageService.errorMessage.level = 'global';
        this.errorMessageService.errorMessage.message = 'We\'re sorry. We can\'t complete your request at this time. Please try again.';
        window.scrollTo(0, 0);
      },
      () => this.accountWidget(),
    );
  }

  accountWidget() {
    if (this.baseAccounts.length === 1) {
      this.singleAccount = true;
    } else if (this.baseAccounts.length > 1 && this.baseAccounts.length < 7) {
      this.twotosixAccounts = true;
    } else {
      this.sevenplusAccounts = true;
    }
  }

  getSelectedAccount(selectedAccountReceived: BaseAccount) {
    this.selectedAccount.emit(selectedAccountReceived);
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
