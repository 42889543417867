import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AppErrorMessageService {
  constructor(private http: HttpClient) {}

  public getErrorMessage(type?: string, errString?: string): Observable<string> {
    let errCode: string;
    if (!errString) {
      return Observable.of('We\'re sorry, an error occurred while updating your settings. ' +
        'Please try again.');
    }

    let assetName = 'assets/config/';
    if (type === 'broadsoft') {
      assetName = assetName + 'broadworks_error_messages.json';
      const matches = errString.match(/\[(.*?)\]/);

      if (matches) {
        const bw_error_code = matches[1].split(' ')[1];
        errCode = bw_error_code;
      }
    }

    return  this.http.get(assetName)
      .map((response) => {
        if (response) {
          return response[errCode];
        } else {
          return Observable.of('We\'re sorry, an error occurred while updating your settings. ' +
            'Please try again.');
        }
      });
  }
}
