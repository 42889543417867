<div class="modal-content">
  <ng-container *ngIf="outageAccountList.length > 0; else loadSpinTemplate">
    <ng-container *ngIf="outageAccountList.length > 1">
      <ng-container *ngTemplateOutlet="multiAccTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="outageAccountList.length === 1">
      <ng-container *ngTemplateOutlet="singleAccTemplate"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #multiAccTemplate>
  <div class="modalheader pt-3 pb-3">
    <span class="h4 longHeader" *ngIf="multiOutagesView">{{resourceBundle.multiOutageHeader}}</span>
    <span class="h4 longHeader" *ngIf="!multiOutagesView">{{resourceBundle.singleOutageHeader}}</span>
    <a href="javascript:void(0)" title="Close Location Selection" class="cb-modal-close-btn float-right"
      (click)="close('Close click');"></a>
  </div>
  <ng-container *ngIf="contentLoaded; else loadSpinTemplate">
    <ng-container *ngIf="multiOutagesView; else singleAccTemplate">
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <app-commonmessage [type]="commonMessageService.displayMessage.type"
            [globalMessage]="commonMessageService.displayMessage.message" [level]="'globalPage'"
            *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id == 'unplannedOutage'">
          </app-commonmessage>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
          {{resourceBundle.multiOutageBodyText}}
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-4 mb-3">
          <div>{{resourceBundle.mobileNumber}}</div>
          <div class="col-12 display-flex align-items-center justify-content-spaceBtwn p-0" *ngIf="!isMobileDevice">
            <input type="tel" class="col-5" [(ngModel)]="phoneNumber" (keyup)="validatePhoneNum()" id="phoneNumber"
              appPhoneMask>
            <button class="button btn-long-text" (click)="getOutageUpdates();close('Close click')" aria-label="Get Outage Updates"
              [disabled]="disableButton">{{resourceBundle.getOutageUpdates}}</button>
          </div>
          <div class="col-12 p-0" *ngIf="isMobileDevice">
            <input type="tel" class="col-12" [(ngModel)]="phoneNumber" (keyup)="validatePhoneNum()" id="phoneNumber"
              appPhoneMask>
          </div>
          <div class="col-md-12 p-0">
            <input type="checkbox" class="mt-3" id="mobileCheckbox" [checked]="isMobileNumber" [(ngModel)]="isMobileNumber">
            <label class="mr-0"><span class="margin-left-neg-0-3">{{resourceBundle.saveNumber}}</span></label>
            <a id="outageTerms" tabindex="0" href="javascript:void(0)" (keyup)="datatableAccessibility($event)" 
              class="ml-4 linkUnderline">{{resourceBundle.terms}}</a><a> ></a>
          </div>
          <div class="col-md-12 p-0" *ngIf="isMobileDevice">
            <button class="button btn-long-text" (click)="getOutageUpdates();close('Close click')" aria-label="Get Outage Updates"
              [disabled]="disableButton">{{resourceBundle.getOutageUpdates}}</button>
          </div>
        </div>
        <div class="col-md-12 col-xs-12 p-0" [ngClass]="{'scrollEffectMobile': isMobileDevice}">
          <ngx-datatable class="dataTable bootstrap fullscreen mb-4 redesignTableHeader" id="outageAccounts" #outageAccounts
            [rows]="outageAccountList" (scroll)="onScroll($event)" tabindex="0"
            [ngClass]="{'scrollEnabled': totalCount > 10, 'scrollEffect': showGradient, 'hoverColor': !isMobileDevice}"
            comnAccessTable [noofRows]="outageAccountList.length" [tableName]="'Current Outage Accounts List'" role="table"
            [table]="outageAccounts" [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'"
            [headerHeight]="'auto'" [scrollbarH]="isMobileDevice" [scrollbarV]="false" (keyup)="activateRow($event)">
            <ngx-datatable-column prop="name" [sortable]="false" [resizeable]='false' [width]="datatableRowWidth[0].name"
              [maxWidth]="datatableRowWidth[0].name" [minWidth]="datatableRowWidth[0].name">
              <ng-template role="group" ngx-datatable-header-template>
                <fieldset>
                  <legend class="sr-only"></legend>
                  <input type="checkbox" role="checkbox" aria-label="Select all Accounts" class="align-middle"
                    id="selectall" [(ngModel)]="selectall" [disabled]="disableSelectAll" (change)="selectAllAccounts($event)" />
                  <label class="m-0 center-align pad-top-md-5p" for="selectall"></label>
                </fieldset>
              </ng-template>
              <ng-template ngx-datatable-cell-template let-value="value" let-row=row let-rowIndex="rowIndex">
                <fieldset>
                  <legend class="sr-only"></legend>
                  <input type="checkbox" class="align-middle" [attr.id]="rowIndex" aria-label="Select Account"
                    (change)="selectAccount(row);checkSelectAll()" [disabled]="row.outage.eventTypeGroup === EventTypeGroup.CHRONIC"
                    [(ngModel)]="row.isEnrolled" />
                  <label class="m-0 center-align pad-top-md-5p" [attr.for]="rowIndex"><span class="sr-only">select
                      Account</span></label>
                </fieldset>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Account" prop="alias" [sortable]="true" [width]="datatableRowWidth[0].accountAlias"
              [minWidth]="datatableRowWidth[0].accountAlias">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <div class="col-lg-12 p-0" (click)="singleAccOutageDetails(row)">
                  <span class="col-lg-12 col-xs-12 p-0 row-text-overflow" title="{{row.alias}}">{{row.alias}}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="serviceAddress" [sortable]="true"
              [width]="datatableRowWidth[0].serviceAddress" [minWidth]="datatableRowWidth[0].serviceAddress"
              *ngIf="!isMobileDevice">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                <div class="row-text-overflow" (click)="singleAccOutageDetails(row)"
                  title="{{row.serviceAddress}}">
                  {{row.serviceAddress}}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Estimated Repair Time" prop="estimatedRepairTime" [sortable]="true"
              [width]="datatableRowWidth[0].estRepairTime" [minWidth]="datatableRowWidth[0].estRepairTime">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                <div class="col-md-12 row-text-overflow p-0" title="{{row.dateTime}}" (click)="singleAccOutageDetails(row)" *ngIf="row.outage.eventTypeGroup !== EventTypeGroup.CHRONIC">
                  {{row.dateTime}}
                </div>
                <div class="col-md-12 p-0" (click)="singleAccOutageDetails(row)" *ngIf="row.outage.eventTypeGroup === EventTypeGroup.CHRONIC">
                  Not Available
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Updates" prop="status" [sortable]="false" [width]="datatableRowWidth[0].status"
              [minWidth]="datatableRowWidth[0].status" *ngIf="!isMobileDevice">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                <div class="col-md-12 pr-0 pl-2"
                  [ngClass]="{'display-flex justify-content-spaceBtwn': row.outage.cnsFeedback !== null}"
                  (click)="singleAccOutageDetails(row)">
                  <img *ngIf="row.outage.cnsFeedback === true" class="iconView"
                    src="assets/images/blueprint/admin_redesign/check-mark-completion-done.svg" alt="Status icon">
                  <img *ngIf="row.outage.cnsFeedback === false" class="iconView"
                    src="assets/images/blueprint/admin_redesign/circle-exclamation.svg" alt="Status icon">
                  <img *ngIf="row.outage.cnsFeedback === true" class="showLink"
                    src="assets/images/blueprint/admin_redesign/check-mark-completion-done-white.svg" alt="Status icon">
                  <img *ngIf="row.outage.cnsFeedback === false" class="showLink"
                    src="assets/images/blueprint/admin_redesign/circle-exclamation-white.svg" alt="Status icon">
                  <a class="showLink pull-right">
                    <img src="assets/images/blueprint/admin_redesign/arrow-right-white.svg" id="arrowImgQc"
                      (click)="singleAccOutageDetails(row)" tabindex="0" role="link" attr.aria-label="{{row.alias}} edit"
                      alt="Edit link">
                  </a>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #singleAccTemplate>
  <div class="col-md-12 margin-bottom-20 modalheader" *ngIf="!isMultiOutages">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 margin-bottom-20 margin-top-15 pr-0">
      <span class="modal-title h4">{{resourceBundle.singleOutageHeader}}</span>
      <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn float-right"
        (click)="close('Close click')"></a>
    </div>
  </div>
  <ng-container *ngIf="contentLoaded; else loadSpinTemplate">
    <ng-container *ngIf="singleAccViewLoaded; else loadSpinTemplate">
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mb-3" *ngIf="outageDesc === resourceBundle.singleOutageDefualtMsg">
          {{resourceBundle.singleOutageDesc}}
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
          <div><span [ngClass]="{'col-xs-12 p-0': isMobileDevice}">{{resourceBundle.serviceAddress}} </span> <span
              class="ceraPro-medium" [ngClass]="{'col-xs-12 p-0 mb-4': isMobileDevice}">{{rowDetails.serviceAddress}}</span></div>
          <div><span [ngClass]="{'col-xs-12 p-0': isMobileDevice}">{{resourceBundle.estimatedTime}} </span> <span
              class="ceraPro-medium" [ngClass]="{'col-xs-12 p-0 mb-4': isMobileDevice}">{{rowDetails.estimatedTime}}</span></div>
          <div *ngIf="rowDetails.eventTypeGroup !== EventTypeGroup.CHRONIC"><span [ngClass]="{'col-xs-12 p-0': isMobileDevice}">{{resourceBundle.enrolledInUpdates}} </span> <span
              class="ceraPro-medium" [ngClass]="{'col-xs-12 p-0 mb-4': isMobileDevice}">{{rowDetails.isEnrolled}}</span></div>
        </div>
        <ng-container *ngIf="rowDetails.eventTypeGroup !== EventTypeGroup.CHRONIC">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-3">
            {{resourceBundle.mobileNumber}}
          </div>
          <div class="col-12 p-0 phoneNumAndTermsLink" [ngClass]="{'display-flex' : !isMobileDevice}">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
              <input type="tel" class="col-10 pl-2" [(ngModel)]="rowDetails.phoneNum" (keyup)="validatePhoneNum()"
                id="phoneNum" appPhoneMask />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
              <input type="checkbox" class="mt-3" id="mobileCheckbox" [checked]="rowDetails.isMobileNum"
                [(ngModel)]="rowDetails.isMobileNum">
              <label>
                {{resourceBundle.saveNumber}}
              </label>
              <a class="mt-1" tabindex="0"><u>{{resourceBundle.terms}} &gt;</u></a>
            </div>
          </div>
        </ng-container>
      </div>
    <ng-container *ngIf="!(!isMultiOutages && rowDetails.eventTypeGroup === EventTypeGroup.CHRONIC)">
      <hr class="hr">
      <div class="row margin-right-25 margin-left-25" *ngIf="!isMobileDevice">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mt-4 pt-2" [ngClass]="{'mb-4': rowDetails.eventTypeGroup === EventTypeGroup.CHRONIC}">
          <a href="javascript:void(0)" *ngIf="isMultiOutages" (click)="backToMultiAccView()">&lt;<u>
              {{resourceBundle.backToOutagesList}}</u></a>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 mt-5" *ngIf="rowDetails.eventTypeGroup !== EventTypeGroup.CHRONIC">
          <button class="button pull-right mr-0 mt-n4 btn-long-text" [disabled]="disableButton"
            (click)="getOutageUpdates();close('Close click')"
            aria-label="Get Outage Updates">{{resourceBundle.getOutageUpdates}}</button>
        </div>
      </div>
      <div class="row margin-right-25 margin-left-25" *ngIf="isMobileDevice">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-5">
          <button *ngIf="rowDetails.eventTypeGroup !== EventTypeGroup.CHRONIC"
            class="button pull-right mr-0 mt-n4 btn-long-text" [disabled]="disableButton"
            (click)="getOutageUpdates();close('Close click')"
            aria-label="Get Outage Updates">{{resourceBundle.getOutageUpdates}}</button>
          <div class="ml-5">
            <a href="javascript:void(0)" *ngIf="isMultiOutages" (click)="backToMultiAccView()">&lt;<u>
                {{resourceBundle.backToOutagesList}}</u></a>
          </div><br />
        </div>
      </div>
    </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #loadSpinTemplate>
  <div>
      <app-skeletonloader formName="Unplanned Outage Details" formSkeleton="true" rowCount="4">
      </app-skeletonloader>
  </div>
</ng-template>