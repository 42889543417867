<div class="col-xs-12 col-sm-12 col-md-12 gray-bg pad-0">
  <div id="lookuphelptext" class="col-xs-12 col-sm-12 col-md-12 pad-top-1">
    <p>
      <b>{{ resourceBundle.selectAnAccount }}</b>
    </p>
    <p>{{ resourceBundle.selectAccountBox }}</p>
  </div>
  <div id="filterOptionsWrapper" class="col-xs-12 col-sm-12 col-md-12 text-center">
    <div id="filterOptions" class="radio-box display-inline-block centered">
      <fieldset>
        <legend class="hidden">Installation Type</legend>
        <input id="accountnum" type="radio" name="filterType" value="an" [checked]="filterType == 'an'" (click)="displayFilterText('an')">
        <label for="accountnum">
          <span> {{ resourceBundle.account}}<wbr>{{ resourceBundle.number}} </span>
        </label>
        <div class="separator">&nbsp;</div>
        <input id="address" type="radio" name="filterType" value="ad" [checked]="filterType == 'ad'" (click)="displayFilterText('ad')">
        <label for="address">
          <span> {{ resourceBundle.address}} </span>
        </label>
        <div class="separator">&nbsp;</div>
        <input id="accountalias" type="radio" name="filterType" value="aa" [checked]="filterType == 'aa'" (click)="displayFilterText('aa')">
        <label for="accountalias">
            <span> {{ resourceBundle.account}}<wbr> {{ resourceBundle.alias}} </span>
        </label>
        <div class="separator">&nbsp;</div>
        <input id="accountname" type="radio" name="filterType" value="aname" [checked]="filterType == 'aname'" (click)="displayFilterText('aname')">
        <label for="accountname">
          <span> {{ resourceBundle.account}}<wbr> {{ resourceBundle.name}} </span>
        </label>
      </fieldset>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12">
      <p id="filterOptionsText">
        <span *ngIf="isSelected('an')">{{ resourceBundle.filterOptionAccountNumber }}</span>
        <span *ngIf="isSelected('ad')">{{ resourceBundle.filterOptionAddress }}</span>
        <span *ngIf="isSelected('aa')">{{ resourceBundle.filterOptionAccountAlias }}</span>
        <span *ngIf="isSelected('aname')">{{ resourceBundle.filterOptionAccountName }}</span>
      </p>
    </div>
  </div>
  <div id="searchCriteriaHelperText" class="col-xs-12 col-sm-12 col-md-12">
    <p>{{ resourceBundle.enter3CharsSearch }}</p>
  </div>
  <div id="searchCriteriaWrapper" class="col-xs-12 col-sm-12 col-md-12">
    <label for="searchCriteria" class="hidden">Input for account search</label>
    <input type="text" id="searchCriteria" autofocus value="" class="mb-20" [(ngModel)]="inputEntered" (ngModelChange)="searchBaseAccountsList($event)"
    />
  </div>
  <div id="searchCriteriaInfoWrapper" class="col-xs-12 col-sm-12 col-md-12">
    <p id="searchResultsInfo" [ngClass]="{'hidden': !resultsLength}">
      {{ resourceBundle.showing }}
      <b> {{resultsDisplayed}}</b> {{ resourceBundle.of }} {{resultsLength}} {{ resourceBundle.resultsReturned }}
      <span *ngIf="resultsDisplayed < resultsLength">{{ resourceBundle.seemoreresults }}</span>
    </p>
  </div>
  <div id="searchResults" class="position-relative">
    <div id="huge" class="wide100 xs-gray-bg pull-left md-no-bottom-border">
      <div *ngIf="oddAccountsFlag; then oddAccountsTemp"></div>
      <div *ngIf="evenAccountsFlag; then evenAccountsTemp"></div>
      <div *ngIf="allAccountsFlag; then allAccountsTemp"></div>
    </div>
  </div>
  <div id="pagingWrapper" class="col-xs-12 col-sm-12 col-md-12">
    <div id="nomatches" class="msg-default" [ngClass]="{'hidden': noResults}">
      <span class="msg-alert">
        <span>{{ resourceBundle.noResultsMessage }}
        </span>
      </span>
    </div>
    <span id="seeMore" class="link-enabled" [ngClass]="{'hidden': !(resultsDisplayed < resultsLength)}" (click)="seeMoreResults()">{{ resourceBundle.seeMore }}</span>
    <span id="searchAgain" class="link-enabled" [ngClass]="{'hidden': !(resultsDisplayed !=0 && resultsDisplayed === resultsLength)}"
      (click)="searchAgain()">{{ resourceBundle.searchAgain }}</span>
  </div>
  <ng-template #oddAccountsTemp>
    <div id="multi-col-2" class="hidden-xs col-sm-6 col-md-6">
      <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of oddAccounts;" (click)="displayAccountSelected(baseAccount)"
        id="{{baseAccount.accountId}}" [ngClass]="{'selected': baseAccount.accountId === accountid}">
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address-postal title="{{baseAccount.address.city}},  
                      {{baseAccount.address.state}} {{baseAccount.address.zip}}">
              {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #evenAccountsTemp>
    <div id="multi-col-1" class="hidden-xs col-sm-6 col-md-6">
      <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of evenAccounts;" (click)="displayAccountSelected(baseAccount)"
        id="{{baseAccount.accountId}}" [ngClass]="{'selected': baseAccount.accountId === accountid}">
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address-postal title="{{baseAccount.address.city}},  
                      {{baseAccount.address.state}} {{baseAccount.address.zip}}">
              {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #allAccountsTemp>
    <div id="multi-col" class="visible-xs-block col-xs-12">
      <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of allAccounts;" (click)="displayAccountSelected(baseAccount)"
        id="{{baseAccount.accountId}}" [ngClass]="{'selected': baseAccount.accountId === accountid}">
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address-postal title="{{baseAccount.address.city}},  
                          {{baseAccount.address.state}} {{baseAccount.address.zip}}">
              {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>