<div class="modal-content">
    <ng-container *ngTemplateOutlet="serviceModal"></ng-container>
</div>

  <ng-template #serviceModal>
    <div role="dialog" aria-modal="true" aria-describedby="modal-heading">
    <span class="sr-only" id="modal-heading">{{headerName | titlecase}} {{resourceBundle?.serviceDetails}} {{resourceBundle?.headingLevel4}}</span>
    <div class="modal-header modalheader">
      <div class="col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
      <h4 class="bold modal-title" [class]="isMobileDevice ? 'h5' : 'h4'" role="heading" aria-level="4" attr.aria-label="{{headerName | titlecase}} {{resourceBundle?.serviceDetails}}">{{headerName | titlecase}} {{resourceBundle?.serviceDetails}}</h4>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right cancel" >
        <a aria-label="Close" class="cb-modal-close-btn" href="javascript:void(0)" role="button" id="closeBtn"
          (click)="close(false)" appModalfocus>
        </a>
      </div>
    </div>
  
    <ng-container *ngIf="this.modalLoaded; else loadSpinTemplate">
    <div class="modal-body serviceModal modalbody">
      <p class="bold">Displaying {{headerName | titlecase}} service details for your account at service address
       {{consolidatedServiceAddress}}
      </p>
      <div class="col-lg-12 ml-n2" *ngIf="sulaDescList.length > 0">
        <div *ngFor="let accountListArray of sulaDescList; let i = index;">
          <span *ngIf="accountListArray.description" class="ml-n2 display-flex">
            <b>{{accountListArray.description}}</b>
          </span>
          <div *ngIf="accountListArray.scheduleDate">
            <p class="red">Scheduled Install Date:
              <br> <span *ngIf="!accountListArray.holidayFlag">{{accountListArray.scheduleDate}}</span>
              <span class="black" *ngIf="accountListArray.holidayFlag">Please contact Cox Business and schedule your installation.</span>
            </p>
          </div>
          <ul *ngIf="accountListArray.details" role="list">
            <div *ngFor="let details of accountListArray.details">
              <li role="listitem">{{details}}</li>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-lg-12 ml-n2 pl-2" *ngIf="sulaDescList.length === 0">
          <p> {{resourceBundle.noSulaMessage}}</p>
      </div>
    </div>
    <div class="display-flex justify-content-center pl-3 pr-3">
      <button id="done" class="button done" (click)="close(true)">Done</button>
    </div>
  </ng-container>
</div>
  </ng-template>
  
  <ng-template #loadSpinTemplate>
    <div>
        <app-skeletonloader formName="serviceDetails" formSkeleton="true" rowCount="5">
        </app-skeletonloader>
    </div>
  </ng-template>
  
  
