import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { UpdateEquipmentPersonalNameInput, UpdateEquipmentWorkorderInput,
   UpdatePersonalizedDeviceNameInput } from 'redesign/sub-landing/business-voice/business-voice.model';
import { DeviceEncryptionInput } from '../../models/userprofile/deviceEncryption.model';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class EquipmentCardDetailService {
  public contentLoaded = false;

  constructor(private apollo: Apollo) { }

  public getOtherDeviceDetails(userId: string, encryptedValue: string) {
    const getDeviceDetailsQuery = gql`
    query getDeviceDetails($userId: ID!, $encryptedValue: String!) {  
      getAccount(id: $userId) {
        rebootHistory(encryptedValue: $encryptedValue) {
          caseInfo {
          caseId
          caseStatus
          caseType
          channelInitiated
          channelStatus
          createDateTime
          createOperator
          currentStage
          macAddress
          serialNumber
          serviceCategoryCode
          updateDateTime
          updateOperator
          }
          detail
          code
          error {
          code
          detail
          }
          status
          }
        }
  }
  `;

    return this.apollo.query({
      query: getDeviceDetailsQuery,
      variables: {
        userId,
        encryptedValue
      },
    });
  }

  public updateName(input: UpdateEquipmentPersonalNameInput): Observable<any> {
    const updateNameMutation = gql`
         mutation updateNameMutation($input: UpdateEquipmentPersonalNameInput!){
          updateEquipmentPersonalName(input: $input) {
            accountNumber12
            }
          }
        `;

    return this.apollo.mutate({
      mutation: updateNameMutation,
      variables: {
        input
      }
    });
  }

  public updateT38Flag(input: UpdateEquipmentWorkorderInput): Observable<any> {
    const updateT38Mutation = gql`
         mutation updateT38Mutation($input: UpdateEquipmentWorkorderInput!){
          updateEquipmentWorkorder(input: $input) {
            accountNumber12
            }
          }
        `;

    return this.apollo.mutate({
      mutation: updateT38Mutation,
      variables: {
        input
      }
    });
  }

  public updatePhoneName(input: UpdatePersonalizedDeviceNameInput): Observable<any> {
    const updatePhoneNameMutation = gql`
         mutation updateNameMutation($input: UpdatePersonalizedDeviceNameInput!){
          updateDeviceDisplayName(input: $input) {
            code
            message
            transactionId
            warning
            }
          }
        `;

    return this.apollo.mutate({
      mutation: updatePhoneNameMutation,
      variables: {
        input
      }
    });
  }

  public getCradlePointDataUtilizationDetails(accountNumber12: string) {
    const dataUtilization = gql`
    query MyQuery($accountNumber12: String!) {
      dataUtilization(accountNumber12: $accountNumber12) {
          accountId
          cycleDataUsages {
            backupUsage
            asOfDate
            billCycleNumber
            billPeriodCharges
            cycleEndDate
            cycleStartDate
            overageRate
            remainingData
          }
        }
      }
    `;

    return this.apollo.query({
      query: dataUtilization,
      variables: {
        accountNumber12
      },
    });
  }
}
