import { Component, OnInit } from '@angular/core';
import { CBHelperService } from '../../services/cbhelper.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-chatdiv',
  templateUrl: './chatdiv.component.html',
  styleUrls: ['./chatdiv.component.scss'],
})
export class ChatdivComponent implements OnInit {
  public isMyAdmin: boolean;
  constructor(
    private cbHelper: CBHelperService,
  ) {
    this.isMyAdmin = this.cbHelper.isMyAdmin();
   }

  ngOnInit() {
  }
  public hideChatDiv() {
    const x = document.getElementById('chatdiv');
    x.style.display = 'none';
  }

}
