import { AppConfig, CBHelperService, CoxHttpClient, MicroserviceResponse } from 'common-ui-lib';
import { LocalStorageService } from 'ngx-localstorage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserSearchResultList, AccountSearchResultList, ExceptionReport, ResponseStatus, MarketRollOutStatus } from './start.model';
import { UserProfile, AuthTokenService, VoiceLandingPageService, LocalStorageKeys } from 'common-ui-lib';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SessionStorageService } from 'ngx-webstorage';


@Injectable()
export class StartService {

  decodedtoken: any;
  tokenPayload: any;
  jwtHelper: JwtHelperService = new JwtHelperService();
  hideParent: boolean = false;
  userSearchUrl: string;
  accountSearchUrl: string;

  constructor(
    private httpClient: HttpClient,
    private coxHttpClient:CoxHttpClient,
    private config: AppConfig,
    private _storageService: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private cbHelper: CBHelperService,
    private authTokenService: AuthTokenService,
    private router: Router,
    public voiceLandingPageService: VoiceLandingPageService,
  ) {}

  getUserSearchResultList(searchBy: any, searchValue: any): Observable<UserSearchResultList> {
      this.userSearchUrl = '/api/cbma/csr/services/csr/search/v2/users?searchBy=' + searchBy +
      '&searchValue=' + searchValue;

    return this.coxHttpClient.get<any>(this.userSearchUrl).pipe(
      map((response: any) => UserSearchResultList.fromJSON(response))
    );
  }

  public getOrderSearchResultList(orderNumber: any): Observable<UserSearchResultList> {
    const userSearchUrlByOrder = '/api/cbma/digitalprofile/services/digitalprofile/search/profile/' + orderNumber;
    return this.coxHttpClient.get<any>(userSearchUrlByOrder);
  }

  getAccountSearchResultList(searchBy: any, searchValue: any): Observable<AccountSearchResultList> {
       this.accountSearchUrl = '/api/cbma/csr/services/csr/search/v1/accounts?searchBy=' + searchBy +
       '&searchValue=' + searchValue;
    return this.coxHttpClient.get(this.accountSearchUrl).pipe(
      map((response: any) => AccountSearchResultList.fromJSON(response))
    );
  }

  public getLoginProfile(masterUserEmail: string, profileEmailOrGuid: string, isStartFlow?: boolean): Observable<UserProfile> {
    console.log('getLoginProfileCalled');
    let loginProfileUri = this.config.getConfig('APIGW')['baseURL'];
    let requestBody;
    if (isStartFlow) {
        loginProfileUri = loginProfileUri + this.config.getConfig('APPURI')['userProfileV4'];
      const profileGuid = profileEmailOrGuid;
      requestBody = { id: null, profileGuid };
    } else {
        loginProfileUri = loginProfileUri + this.config.getConfig('APPURI')['userProfileV3'];

      const userEmail = profileEmailOrGuid;
      requestBody = { id: null, userEmail };
    }
    this.decodedtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    this.tokenPayload = this.jwtHelper.decodeToken(this.decodedtoken);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.cbHelper.getMasterUserEmail(),
        'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      }),
    };
 
    return this.httpClient.post<any>(loginProfileUri, requestBody,
      { headers: httpOptions.headers, observe: 'response' }).pipe(map(response => {
        if (response.body.code === '0') {
          const authToken = response.headers.get('cbma_authtoken');
          this._storageService.set(LocalStorageKeys.Authtoken, authToken);
          this.sessionStorage.store(LocalStorageKeys.FirstLogin, true);
          const loggedInProfileDetails =
           response.body.loggedInProfileDetails ? response.body.loggedInProfileDetails : {};
          this._storageService.set(LocalStorageKeys.LoggedInProfileDetails, JSON.stringify(loggedInProfileDetails));
          const loggedInUserDetails = response.body.loggedInUserDetails ? response.body.loggedInUserDetails : {};
          this._storageService.set(LocalStorageKeys.LoggedInUserDetails, JSON.stringify(loggedInUserDetails));
          this._storageService.set(LocalStorageKeys.BroadworksDetails, JSON.stringify(response.body.broadworksDetails));
          this.cbHelper.processCredentials();
          if (response.body.loggedInUserDetails?.contact?.email) {
            this._storageService.set(LocalStorageKeys.SubmittedUser, response.body.loggedInUserDetails.contact.email);
          }
          const isExpired = this.jwtHelper.isTokenExpired(authToken);
          const decodedToken = this.jwtHelper.decodeToken(authToken);
          const parsedToken = JSON.parse(decodedToken.profile);
          const isCSRProfileNotInContext = parsedToken && parsedToken['userToken']['userType'] === 'CSR';
          const isProfileInContext = parsedToken['userToken']['profileId'] !== null;
          if (isProfileInContext && !isCSRProfileNotInContext) {
          if (!isExpired) {
            this.authTokenService.setGlobals();
            this.voiceLandingPageService.setUserRolesFeatureDetailsArray(null);
          }
        }
          return UserProfile.fromJSON(response.body);
        } else if (response.body.code === '11003') {
          throw Error('11003');
        } else {
          throw Error;
        }
      }));
  }

  generateExceptionReport(): Observable<UserSearchResultList> {
    const exceptionReport: ExceptionReport = new ExceptionReport('exception_report', 'REQUEST_RECEIVED',
      this.cbHelper.getMasterUserEmail(), '', '', 0);
    return this.coxHttpClient.post<any>('/api/cbma/csr/services/csr/ondemand/report', exceptionReport);
  }
  public refreshProfile(): Observable<ResponseStatus> {
    const refreshProfileUrl =
      '/api/cbma/profile/services/profile/refresh';
    return this.coxHttpClient.get(refreshProfileUrl);
  }

  public async voiceFeatureConfigurationExport(): Promise<MicroserviceResponse> {
    const configurationExportUrl = '/api/cbma/voiceutilities/services/voiceutilities/ipc/automationservice';
    return this.coxHttpClient.postAsync<MicroserviceResponse>(
      configurationExportUrl, {});
  }  
}
