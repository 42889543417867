import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[appAutotab]',
})
export class AutotabDirective {

    constructor() { }

    @Input()
    appAutotab: { focus: () => void; };

    @HostListener('input', ['$event.target'])
    onInput(input: { value: { length: number; }; attributes: { maxlength: { value: number; }; }; }) {
        const length = input.value.length;
        const maxLength = input.attributes.maxlength.value;
        if (length >= maxLength) {
            this.appAutotab.focus();
        }
    }

}
