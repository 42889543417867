// eslint-disable-next-line max-classes-per-file
import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'common-ui-lib';
import { CommonMessageService } from './commonmessage.service';

@Component({
  selector: 'app-commonmessage',
  templateUrl: './commonmessage.component.html',
  styleUrls: ['./commonmessage.component.scss'],
})

export class CommonMessageComponent implements OnInit {

// Page level input message
@Input() globalMessage: string;
// Accordion or Section input messages
@Input() accordionMessage: string;
@Input() accordionTitleMsg: string;
// Datatable Row level input message
@Input() rowLevelMessage: string;
// Input to differentiate type of message
@Input() type: string;
// Input to differentiate accordion or global message
@Input() level: string;
// Input flag timer to hide success message
@Input() showSuccessTimeout = false;
@Input() timeout = 2000;
@Input() listOfErrorMsg: string[];
// For error symbol vertically center aligned in the list of error messages
@Input() verticallyCenteredErrorSymbol = false;
public isMobileDevice = false;

  constructor(public commonMessageService: CommonMessageService,
    private deviceService: DeviceDetectorService,
  ) {
  }

  ngOnInit() {
    // Condition to set timer for success message
    if (this.showSuccessTimeout) {
      setTimeout(() => {
        if (this.level === 'global') {
          $('.successMsg').hide();
        }
        if (this.level === 'accordion') {
          $('.successMsgAccordion').hide();
          this.commonMessageService.hideMessage();
        }
        if (this.level === 'datatableRow') {
          $('.successMsgAccordion').hide();
          this.commonMessageService.hideMessage();
        }
        if (this.level === 'overlaySuccessMsg') {
          $('.successMsgAccordion').hide();
          this.commonMessageService.hideMessage();
        }
      }, this.timeout);
    }
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }
  }
}
export class ShowMessage {
  show = false;
  message: string | string[];;
  type: string;
  level: string;
  id?: string;
}
