import { CBHelperService } from "common-ui-lib/lib/services/cbhelper.service";

export class SsoComponent {
  cbHelperService: CBHelperService;
  // Handling SSO login
  onVoiceTrafficClick(url: string, role: string) {
    const userProfile = this.cbHelperService.getLoggedInUserDetails();
    const email = userProfile.profile.userToken.email;
    if (role === 'vtr') {
      let action = url;
      const s = action.split('?');
      action = s[0];
      const co = s[1];
      const voicetrafficservicecode = 'HUTLRPT';
      let element1: HTMLInputElement;
      let element2: HTMLInputElement;
      let element3: HTMLInputElement;

      const form = document.createElement('form');
      form.method = 'post';
      form.action = action;
      form.target = '_blank';
      document.body.appendChild(form);

      element1 = document.createElement('INPUT') as HTMLInputElement;
      element1.name = 'userEmail';
      element1.value = email;
      element1.type = 'hidden';
      form.appendChild(element1);

      element2 = document.createElement('INPUT') as HTMLInputElement;
      element2.name = 'serviceCode';
      element2.value = voicetrafficservicecode;
      element2.type = 'hidden';
      form.appendChild(element2);

      element3 = document.createElement('INPUT') as HTMLInputElement;
      element3.name = 'companyId';
      element3.value = co;
      element3.type = 'hidden';
      form.appendChild(element3);
      form.submit();
      return false;
    }
  }
}
