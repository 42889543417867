<div class="modalheader pt-3 pb-3" *ngIf="deviceService.isMobile()">
    <span class="h4" *ngIf="!hideBeginReboot">{{resourceBundle.rebootDevice}}</span>
    <span class="h4 longHeader" *ngIf="hideBeginReboot">{{resourceBundle.phoneRebootHeader}}</span>
    <a href="javascript:void(0)" title="Close Location Selection" class="cb-modal-close-btn float-right"
        (click)="close('close click')"></a>
</div>
<div class="col-12 p-0" *ngIf="mobileUpdateWarningMsg.show" [ngClass]="{'mt-4': deviceService.isMobile()}">
    <app-commonmessage [type]="mobileUpdateWarningMsg.type" [globalMessage]="mobileUpdateWarningMsg.message"
        [level]="'global'" *ngIf="mobileUpdateWarningMsg.show">
    </app-commonmessage>
</div>
<div class="col-12 text-center" [ngClass]="{'mt-4': deviceService.isMobile()}">
    <ng-container *ngIf="contentLoaded; else loadSpinTemplate">
        <ng-container *ngIf="equipmentType === 'phone' && !isEmtaDeviceType">
            <ng-container *ngIf="!hideBeginReboot">
                <div class="mb-3 display-flex justify-content-center">
                    <span class="icon-grey-border">
                        <img src="assets/images/blueprint/admin_redesign/refresh.svg" alt="Reboot icon" class="m-3">
                    </span>
                </div>
                <div class="bold mb-3">
                    {{resourceBundle.rebootDevice}}
                </div>
                <div class="mb-3">
                    {{resourceBundle.rebootWidgetDesc}}
                </div>

                <hr class="hr">
                <button id="rebootButton" class="button rebootButton track-clicks" [value]="resourceBundle.beginRebootBtn" (click)="beginPhoneReboot();" type="button"
                    (keydown)="cardDetailFocus($event)" #rebootButton>{{resourceBundle.beginRebootBtn}}</button>
            </ng-container>
            <div class="col-12 " id="beginRebootDesc" *ngIf="hideBeginReboot">
                <div class="gray-bg-std" *ngIf="!deviceService.isMobile()">
                    <h5 class="p-3">{{resourceBundle.phoneRebootHeader}}</h5>
                </div>
                <div class="col-lg-12 col-md-12 pl-0 pt-3 text-left">
                    <p class="pl-3">{{resourceBundle.phoneRebootDesc}}</p>
                    <p class="pl-3"><span class="bold font-style-italic">Note:</span>{{resourceBundle.phoneRebootNote}}
                    </p>
                </div>
            </div>

        </ng-container>
        <ng-container *ngIf="equipmentType === 'other' || (equipmentType === 'phone' && isEmtaDeviceType)">
            <ng-container *ngIf="!rebootDone; else RebootStatusTemplate">
                <div class="mb-3 display-flex justify-content-center">
                    <span class="icon-grey-border">
                        <img src="assets/images/blueprint/admin_redesign/refresh.svg" alt="Reboot icon" class="m-3">
                    </span>
                </div>
                <ng-container *ngIf="!isBeginReboot">
                    <ng-container *ngIf="!smartHelpFlag">
                        <div class="bold mb-3">
                            {{resourceBundle.rebootDevice}}
                        </div>
                        <div class="mb-3" *ngIf="selectedOtherEquipment?.equipmentCategory !== equipmentCategoryEnum.TV">
                            {{resourceBundle.rebootWidgetDesc}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="smartHelpFlag">
                        <div class="bold mb-3">
                            {{resourceBundle.troubleShootTxt}}
                        </div>
                        <div class="mb-3">
                            {{resourceBundle.troubleShootMsg}}
                        </div>
                    </ng-container>
                    <div class="mb-3" *ngIf="selectedOtherEquipment?.equipmentCategory === equipmentCategoryEnum.TV">
                        {{resourceBundle.rebootWidgetVideoDeviceDesc}}
                    </div>
                    <button *ngIf="smartHelpFlag" class="button rebootButton track-clicks" [value]="resourceBundle.smartHelpButton" (click)="smartHelpNavigate(formRef)" type="button"
                        #smartHelpButton
                        [disabled]="displaymobilePhoneError || mobilePhoneRequiredError">{{resourceBundle.smartHelpButton}}</button>			
                    <ng-container *ngIf="rebootDeviceDetails?.eligibleDevice">
                        <hr class="hr" *ngIf="!smartHelpFlag">
                        <div class="col-lg-10 col-md-10 col-xs-12 col-sm-12 mb-3"
                            [ngClass]="{'pl-5 ml-3': !deviceService.isMobile()}" *ngIf="!smartHelpFlag">
                            <input class="margin-top-10px" [ngClass]="{'ml-2': deviceService.isMobile()}"
                                type="checkbox" (click)="getNotificationToggle()" [checked]="isNotificationCheck"
                                attr.aria-label={{resourceBundle.getNotificationText}}>
                            <label><span>{{resourceBundle.getNotificationText}}</span></label>
                        </div>
                        <div class="mb-3 pb-2" *ngIf="!smartHelpFlag">
                            <label class="pull-left pl-4 ml-2">
                                {{resourceBundle.mobileNumber}}</label>
                            <br>
                            <input type="tel" maxlength="12" minlength="12" size="15" value="" appPhoneMask
                                class="col-10" (change)="onMobileNumChange($event)" [(ngModel)]="mobileNumber"
                                [ngClass]="{error : displaymobilePhoneError || mobilePhoneRequiredError}"
                                aria-label="Mobile Number" id="mobileNumberInput">
                            <div class="col-10 p-0 text-danger form-error mt-1 mb-0 text-left"
                                *ngIf="displaymobilePhoneError">
                                {{resourceBundle.mobileIncorrectError}}
                            </div>
                            <div class="col-10 p-0 text-danger form-error mt-1 mb-0 text-left"
                                *ngIf="mobilePhoneRequiredError">
                                {{resourceBundle.required}}
                            </div>
                        </div>
                        <div class="col-11 mb-3 pl-5" *ngIf="validMobNumber">
                            <input class="ml-3 margin-top-10px" type="checkbox" [disabled]="!isNotificationCheck"
                                (click)="makeDefaultToggle()" [checked]="makeDefaultCheck"
                                attr.aria-label={{resourceBundle.makeDefaultText}}>
                            <label
                                [ngClass]="!isNotificationCheck ?'text-disabled-color':''"><span>{{resourceBundle.makeDefaultText}}</span></label>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isBeginReboot">
                    <ng-container *ngIf="!rebootDeviceDetails?.eligibleDevice">
                        <ng-container *ngTemplateOutlet="inEligibleTemplate"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="rebootDeviceDetails?.eligibleDevice">
                        <ng-container *ngTemplateOutlet="eligibleTemplate"></ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!(isBeginReboot && !rebootDeviceDetails?.eligibleDevice)">
                    <hr class="hr">
                    <div>
                        
                            
                            <div [ngClass]="{'d-flex justify-content-space-between': deviceService.isMobile() || smartHelpFlag}">
                                <button class="button track-clicks" [ngClass]="{'button-secondary':smartHelpFlag , 'rebootButton': !smartHelpFlag}" [value]="resourceBundle.beginRebootBtn" (click)="beginReboot()" type="button"
                                *ngIf="!showButtonSpinner" #rebootButton
                                [disabled]="displaymobilePhoneError || mobilePhoneRequiredError">{{resourceBundle.beginRebootBtn}}</button>
                                <div class="mb-3 mt-4 pt-1" *ngIf="!showButtonSpinner && equipmentType !== 'phone' && selectedOtherEquipment?.equipmentCategory !== equipmentCategoryEnum.VOICE">
                                    <a href="javascript:void(0)" id="rebootHistory" (click)="rebootHistoryModal()" role="link"
                                    aria-label="Reboot History" (keydown)="cardDetailFocus($event)">{{resourceBundle.rebootHistory}}</a>
                                </div>
                            </div>
                                
                        <button class="button rebootButton" type="button" [disabled]="showButtonSpinner"
                            *ngIf="showButtonSpinner">{{resourceBundle.rebooting}}</button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<!--Device Ineligible for reboot template-->
<ng-template #inEligibleTemplate>
    <div class="bold mb-3">
        {{resourceBundle.rebootDevice}}
    </div>
    <div class="alert-center text-left">
        <app-commonmessage [type]="inEligibleWarningMessage.type" [globalMessage]="inEligibleWarningMessage.message"
            [level]="'global'" *ngIf="inEligibleWarningMessage.show">
        </app-commonmessage>
    </div>
    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12 mt-3 pad-xs-0">
            <div id="reboot-oliver-chat" (click)="triggerTealium('Message-Us', false)" tabindex="0" role="link" [attr.data-chat-text]="resourceBundle.oliverChatText" 
             class="button wide75 display-inline-block chat-trigger" autofocus attr.aria-label={{resourceBundle.messageUslabel}}>{{resourceBundle.messageUs}}                
            </div>
    </div>
</ng-template>

<!--Device eligible for reboot and reboot in progress template-->
<ng-template #eligibleTemplate>
    <div class="bold mb-3">
        {{resourceBundle.rebooting}}
    </div>
    <div class="mb-3">
        {{resourceBundle.rebootinProgressDesc}}
    </div>
    <hr class="hr">
    <div class="mt-3 text-center px-lg-5">
        <span>{{resourceBundle.estimatedTimeVerbiage}}</span>
        <br>
        <label class="rebootTiming bold mt-4">{{rebootTimer}}</label>
    </div>
</ng-template>

<ng-template #RebootStatusTemplate>
    <ng-container *ngIf="rebootSuccess; else failureContent">
        <div class="mb-3 display-flex justify-content-center">
            <span class="icon-grey-border">
                <img src="assets/images/blueprint/sbg/alert_success-big.svg" alt="Success icon" class="m-3">
            </span>
        </div>
        <div class="bold mb-3">
            {{resourceBundle.rebootSuccess}}
        </div>
        <div class="col-12 mb-3">
            {{resourceBundle.successDesc}}
        </div>
    </ng-container>

    <ng-template #failureContent>
        <div class="mb-3 display-flex justify-content-center">
            <span class="icon-grey-border">
                <img src="assets/images/blueprint/sbg/alert_error-big.svg" alt="Success icon" class="m-3">
            </span>
        </div>
        <div class="bold mb-3">
            {{resourceBundle.rebootFailure}}
        </div>
        <div class="col-12 mb-3">
            {{resourceBundle.failureDesc}}
        </div>
        <hr class="hr">
        <div class="mt-3 text-center pb-4 manualRebootSteps mb-1 mx-lg-4">
            <ul class="ml-lg-3">
                <li class="text-disabled-color pb-2"><span>{{resourceBundle.failureSubDesc1}}</span></li>
                <li class="text-disabled-color pb-2"><span>{{resourceBundle.failureSubDesc2}}</span></li>
            </ul>
        </div>
    </ng-template>
    <hr class="hr">
    <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12 pad-xs-0 mt-3 pr-0">
        <label class="h6 bold">{{resourceBundle.issueResolved}}</label>
        <button class="button wide75 track-clicks" [value]="resourceBundle.yesBtn" type="button" (click)="reloadPage()" autofocus
            tabindex="0">{{resourceBundle.yesBtn}}</button>
        <button class="button button-secondary wide75 ml-0 track-clicks" [value]="resourceBundle.noBtn" type="button"
            (click)="helpModalOpen()">{{resourceBundle.noBtn}}</button>
    </div>
</ng-template>

<!-- Help modal -->
<ng-template #helpModal let-close="close" let-dismiss="dismiss" role="dialog">
    <span class="sr-only" aria-label="Let's Help You Further"></span>
    <div class="col-md-12 margin-bottom-20 modalheader">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-0 margin-bottom-20 margin-top-15">
            <span class="modal-title h4">{{resourceBundle.letsHelpModalHeader}}</span>
            <a href="javascript:void(0)" title="Close Help Modal" class="cb-modal-close-btn float-right"
                (click)="close(false)" id="closeBtn"></a>
        </div>
    </div>
    <div class="row margin-left-25 margin-right-25">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 margin-bottom-20 pad-xs-right-0-imp">
            <p>{{resourceBundle.helpModalDesc}}</p>
        </div>
    </div>
    <hr class="hr">
    <div class="row margin-right-25 margin-left-25 margin-bottom-10">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 pl-0 pad-xs-right-0-imp">
            <button class="button button-secondary wide-xs-100 track-clicks" [value]="resourceBundle.exit" (click)="close(false)">{{resourceBundle.exit}}</button>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-right pr-0 pad-xs-left-0">
            <div class="pull-right wide-xs-100">
                    <div id="reboot-oliver-chat" autofocus tabindex="0" role="link" class="button btn-long-text wide-xs-100 display-inline-block chat-trigger" 
                    (click)="triggerTealium('Message-Us', true)" [attr.data-chat-text]="resourceBundle.oliverChatText" attr.aria-label={{resourceBundle.messageUslabel}}>{{resourceBundle.messageUs}}                
            </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Reboot History Modal-->
<ng-template #rebootHistoryModalTem let-close="close" let-dismiss="dismiss">
    <div role="dialog" aria-modal="true" aria-describedby="dialogHeader">
      <div class="modal-header modalheader">
        <h4 id="dialogHeader" attr.aria-label="{{resourceBundle.rebootHistory}} heading level 4" class="h4 modal-title bold">{{resourceBundle.rebootHistory}}</h4>
        <a aria-label="Close" id="closeicon" class="cb-modal-close-btn" href="javascript:void(0)" (click)="close(false);"
          role="button"></a>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
          <p>{{resourceBundle.rebootHistoryDesc}} {{ equipmentName }}.</p>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
          <app-commonmessage [type]="commonMessageService.displayMessage.type"
            [globalMessage]="commonMessageService.displayMessage.message" [level]="'global'" [showSuccessTimeout]="true"
            *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.type === 'error'">
          </app-commonmessage>
        </div>
        <ng-container *ngIf="equipmentCardDetailService.contentLoaded; else loadSpinTemplateDatatable">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" *ngIf="!commonMessageService.displayMessage.show">
            <ngx-datatable class="dataTable bootstrap fullscreen mb-4" id="rebootHistoryTable" #rebootHistoryTable
              [rows]="rebootHistoryRows" tabindex="0" comnAccessTable [noofRows]="rebootHistoryRows.length"
              [tableName]="'Reboot History'" role="table" [table]="rebootHistoryTable" [columnMode]="'force'"
              [messages]="{emptyMessage: 'There are no reboots to display.'}" [reorderable]="false" [rowHeight]="'auto'"
              [headerHeight]="'auto'" [scrollbarH]="false" [scrollbarV]="false">
              <ngx-datatable-column name=" " prop="" [sortable]="false" [maxWidth]="140">
                <ng-template ngx-datatable-header-template>
                  <div class="col-12 pl-0 bold" *ngIf="!cbHelper.isMyAdmin()">
                    <span>Requested By</span>
                  </div>
                  <div class="col-12 pl-0 bold" *ngIf="cbHelper.isMyAdmin()">
                    <span>{{resourceBundle.channel}}</span>
                  </div>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <div class="row-text-overflow" title="{{row.channelInitiated}}">
                    {{row.channelInitiated}}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Date / Time" prop="" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                  <div class="row-text-overflow" title="{{row.date}} {{row.time}}">
                    {{row.date}} {{row.time}}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Status" prop="" [sortable]="false" [maxWidth]="140">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                  <div class="row-text-overflow" title="{{row.caseStatus}}">
                    {{row.caseStatus}}
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </ng-container>
      </div>
      <hr class="hr mb-0">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-left-right-2-xs">
        <button class="button pull-md-right wide-xs-100 track-clicks" value="'Close'"
          (click)="close('Close click');">Close</button>
      </div>
    </div>
  </ng-template>
  
  <ng-template #loadSpinTemplateDatatable>
    <div>
      <app-skeletonloader formName="Reboot History List" formSkeleton="true" rowCount="4"></app-skeletonloader>
    </div>
  </ng-template>

<ng-template #loadSpinTemplate>
    <div>
        <app-skeletonloader formName="Reboot Widget" formSkeleton="true" rowCount="8">
        </app-skeletonloader>
    </div>
</ng-template>
<ng-template #beginRebootModel let-close="close" let-dismiss="dismiss" role="dialog">
    <span class="sr-only" role="alert" aria-hidden="false" aria-label="Reboot Confirmation heading level 4"></span>
    <div class="modal-header modalheader">
        <div class="p-0">
          <h4 class="modal-title">{{resourceBundle.rebootConformation}}</h4>
        </div>
        <div class="text-right">
            <a href="javascript:void(0)" aria-label="Close" role="button" class="cb-modal-close-btn float-right" (click)="close()"></a>
        </div>
      </div>
      <div class="modal-body pt-1 pb-1">
        <div class="row">
            <img src="assets/images/blueprint/icons/alert_warning.svg" class="centered pad-bottom-0-5 pad-top-0-5" alt="Warning Icon">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <p>{{resourceBundle.confirmRebootMsg}}</p>
            </div>
        </div>
        </div>
      <hr class="hr mb-0">
        <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12 p-0 d-flex flex-row justify-content-space-between" [ngClass]="{'d-flex flex-column justify-content-space-between': deviceService.isMobile()}">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-3">
                  <button class="button button-secondary pull-left" (click)="close()">Close</button>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-3">
                    <button class="button pull-right  mr-0"  (click)="confirmReboot()">Confirm</button>
                </div>
        </div>
    </ng-template>
    <form id="smartHelpForm" #formRef>
        <input type="hidden" name="serviceEligible" [(ngModel)]="serviceEligibility">
        <input type="hidden" name="macAddress" [(ngModel)]="macAddress">
        <input type="hidden" name="accountNumber12" [(ngModel)]="accountNumber">
        <!-- <button type="submit">Submit</button> -->
    </form>