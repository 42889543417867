import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, ResourceBundle } from 'common-ui-lib/lib/services/aemclient.service';
import {
  CommonMessageService, DigitalProfileSharedService, LoginProfileClient, PageHeaderService, Role,
  UserType
} from 'common-ui-lib';
import { DeviceDetectorService, TealiumBuilderService } from 'common-ui-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MoreLinkService } from './more-link.service';
import { AccountInfo } from 'common-ui-lib/lib/shared/redesign/pageheader/pageheader.model';
import { CBHelperService } from 'common-ui-lib/lib/services/cbhelper.service';

@Component({
  selector: 'app-more-link',
  templateUrl: './more-link.component.html',
  styleUrls: ['./more-link.component.scss']
})
export class MoreLinkComponent implements OnInit {

  @ViewChild('tip1') tip1: NgbPopover;
  @Input() selectedAccountDetails: AccountInfo;
  @Input() headerWithTitle = false;
  @Output() featuresList = new EventEmitter<unknown>();
  @Output() cbServices = new EventEmitter<boolean>();
  public resourceBundle: ResourceBundle = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public redirectFragment: any;
  public isMobileDevice: boolean;
  public moreIcon = false;
  public featureLength: number;
  public oddFlag: boolean;
  popoverPlacement = 'bottom';
  public more = 'More';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public globalMenuTiles: any;
  public accountGuid: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public subServiceDetailsArr: any = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public role: any[];
  public userType: string;
  public securityPermEU = false;
  public voicePermEU = false;
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService,
    public moreLinkService: MoreLinkService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    public router: Router,
    public commonMessageService: CommonMessageService,
    public cbHelperService: CBHelperService,
    private tealiumbuilder: TealiumBuilderService,
    private digitalService: DigitalProfileSharedService,
    private loginProfileClientService: LoginProfileClient, private pageHeaderService: PageHeaderService) {
    this.aemClient.getSharedBundle('morelink')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
        },
        (error) => {
        },
      );
    this.redirectFragment = this.activatedRoute.snapshot.fragment;
  }

  // Close nav on click of outside
  @HostListener('window:click', ['$event'])
  public onMouseClick(event) {
    if (this.isMobileDevice && !document.getElementById('sidebar')?.contains(event.target) &&
      !document.getElementById('morelinkmobile')?.contains(event.target)) {
      document.getElementById('wrapperMobile')?.classList.remove('show');
      document.getElementsByClassName('overlay-more')[0]?.classList.add('hide');
    }
  }

  ngOnInit(): void {

    this.userType = this.cbHelperService.getLoggedInUserDetails().profile.userToken.userType;
    this.role = this.cbHelperService.getLoggedInUserDetails().profile.roles;
    if ((this.userType === UserType.END_USER) && this.role.includes(Role.ADC_SECURITY_SOLUTIONS_ROLE)) {
      this.securityPermEU = true;
    }
    if ((this.userType === UserType.END_USER) && this.role.includes(Role.VOICE_MANAGERTOOL_ROLE)
      && this.role.includes(Role.MANAGEPHONENUMBERS_ROLE)) {
      this.voicePermEU = true;
    }
    this.isMobileDevice = false;
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }

    if (this.pageHeaderService.services && (this.moreLinkService.profileGuid &&
      (this.moreLinkService.profileGuid === this.cbHelperService.getLoggedInUserDetails()
        .profile.userToken.profileGuid))) {
      this.featuresList.emit(this.pageHeaderService.services.cbFeatures.featuresList);
      const servicesList = this.pageHeaderService.services.cbServices;
      if (servicesList.voiceService && servicesList.ringcentralService) {
        this.cbServices.emit(true);
      }
      this.createMenuTiles(this.pageHeaderService.services);
    } else {
      this.moreLinkService.profileGuid = this.cbHelperService.getLoggedInUserDetails().profile.userToken.profileGuid;
      this.getProfileMenuDetails();
    }
  }

  public toggleMoreIcon() {
    this.moreIcon = !this.moreIcon;
    this.toggleMoreLink(this.moreIcon);
  }

  public toggleMoreLink(isOpen: boolean) {
    this.moreIcon = isOpen;
    if (isOpen) {
      if (this.isMobileDevice) {
        document.getElementById('wrapperMobile').classList?.add('show');
        document.getElementsByClassName('overlay-more')[0].classList.remove('hide');
      }
      this.featureLength = this.globalMenuTiles?.length;
      if (this.featureLength % 2 !== 0) {
        this.oddFlag = true;
      } else {
        this.oddFlag = false;
      }
      document.querySelector('.popover')?.setAttribute('role', 'presentation');
      document.querySelector('.appearance-set').removeAttribute('aria-describedby');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onLinkClick(link: any) {
    const featureNameLink = link.featureName.replace(/\s+/g, '');
    const servicesList = this.pageHeaderService.services.cbServices;
    if (featureNameLink === 'Support') {
      window.open(link.url, link.target);
    } else if (featureNameLink === 'Voice') {
      if (servicesList.ringcentralService && servicesList.voiceService) {
        this.router.navigate([link?.voiceRingCentralOptionUrl], { fragment: featureNameLink });
      } else if (servicesList.ringcentralService && !servicesList.voiceService) {
        window.open(link?.ringCentralLoginUrl, '_blank');
      } else if (!servicesList.ringcentralService && servicesList.voiceService) {
        this.router.navigate([link.url], { fragment: featureNameLink });
      }
    } else {
      this.router.navigate([link.url], { fragment: featureNameLink });
    }
  }

  public closeSideBar() {
    document.getElementById('wrapperMobile').classList.remove('show');
    document.getElementsByClassName('overlay-more')[0].classList.add('hide');
  }

  public onKeyupMoreLinks(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      const moreLink = 'More';
      this.tip1.close();
      this.more = moreLink;
      this.moreIcon = !this.moreIcon;
    }
  }

  // Profile Level Call get Services List
  public getProfileMenuDetails() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.moreLinkService.getProfile().subscribe((results: any) => {
      this.pageHeaderService.services = results.data?.getProfile?.serviceFeature;
      const featureList = results.data?.getProfile?.serviceFeature?.cbFeatures?.featuresList;
      const servicesList = results.data?.getProfile?.serviceFeature?.cbServices;
      if (servicesList?.voiceService && servicesList?.ringcentralService) {
        this.cbServices.emit(true);
      }
      this.featuresList.emit(featureList);
      if (this.pageHeaderService.services !== null) {
        this.createMenuTiles(this.pageHeaderService.services);
      } else {
        const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'getAccountMenuDetails');
        if (errorInfo) {
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error');
        }
      }

      const udoAccounts = this.loginProfileClientService.udoAccounts;
      if (!udoAccounts) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.loginProfileClientService.getAccounts('BILLING').subscribe((result: any) => {
          const userAccounts = result?.data?.userAccountsByFeature;
          this.loginProfileClientService.activeAccountsInProfile = 0;
          userAccounts?.forEach(accounts => {
            if (accounts?.accountExternalAttrs?.status?.toUpperCase() === 'ACTIVE') {
              this.loginProfileClientService.activeAccountsInProfile =
                this.loginProfileClientService.activeAccountsInProfile + 1;
            }
          });
          this.tealiumbuilder.buildTealiumObj(null, userAccounts);
        });
      }
    });
  }

  public addBillPayTiles(localArray) {
    this.subServiceDetailsArr?.forEach(element => {
      if (element.featureName === 'BILLPAY') {
        localArray.push('Billing');
      }
    });
  }

  public createMenuTiles(tilesName) {
    const localArray = [];
    localArray.push('Home');
    const profileStatus = this.cbHelperService.getLoggedInUserDetails()?.profile?.userToken?.profileStatus;
    if (profileStatus !== 'REGISTERED' && ((this.userType !== 'END_USER') || this.securityPermEU || this.voicePermEU)) {
      if (this.role.includes(Role.VOICE_MANAGERTOOL_ROLE)) {
        localArray.push('Users, Accounts and Phone Numbers');
      } else {
        localArray.push('Users And Accounts');
      }
    }
    if (tilesName?.cbServices?.voiceService || tilesName?.cbServices?.ringcentralService) {
      localArray.push('Voice');
    }
    if (tilesName?.cbServices?.tvService) {
      localArray.push('TV');
    }
    if (tilesName?.cbServices?.internetService) {
      localArray.push('Internet');
    }
    if (tilesName?.cbServices?.networkingService) {
      localArray.push('Networking');
    }
    if (tilesName?.cbServices?.securityService) {
      localArray.push('Security');
    }
    if (tilesName?.cbServices?.appService) {
      localArray.push('Apps');
    }
    if (tilesName?.cbFeatures !== null) {
      this.subServiceDetailsArr = tilesName?.cbFeatures?.featuresList;
      if (!this.digitalService.isConsolidated) {
        this.addBillPayTiles(localArray);
      }
    }
    localArray.push('Support');
    this.globalMenuTiles = localArray;
  }

}
