<!-- Description -->
<div class="row cb-title-desc page-description">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20" [ngClass]="deviceService.isMobile()?'pl-2 pr-1 margin-bottom-30px':'mb-20'">
    <p class="m-0" [innerHTML]="description"></p>
  </div>
  <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-1">
    <a href="javascript:void(0)" class="pr-4 pl-3" [ngClass]="{'pb-2 linkUnderline': deviceService.isMobile()}" (click)="openServiceDetailsComponent()" id="serviceDetailsModal" *ngIf="accountList.length">{{resourceBundle.serviceDetailsLink}}</a>
    <span class="verticalLine" *ngIf="accountList.length && featureName === 'INTERNET'" [ngClass]="{'hidden': deviceService.isMobile()}"></span>
    <a href="javascript:void(0)" class="pr-4 pl-3" [ngClass]="{'pb-2 linkUnderline': deviceService.isMobile()}" (click)="openBroadbandFactLabelModal()" id="openBroadbandFactLabelModal" *ngIf="accountList.length && featureName === 'INTERNET'">{{resourceBundle.broadbandFactlabelLinkText}}</a>
    <span class="verticalLine" *ngIf="featureName !== 'APPS' && featureName !== 'SECURITY' && featureName !== 'TV' && isFeatureUpgradeVisible && !hideUpgradeServiceLink" [ngClass]="{'hidden': deviceService.isMobile()}"></span>
    <app-addupgradeservice *ngIf="featureName !== 'SECURITY' && !hideUpgradeServiceLink" [featureName]="featureName" [ngClass]="{'pb-2': deviceService.isMobile()}" [accountInfo]="accountList"> </app-addupgradeservice>
    <span class="verticalLine" [ngClass]="{'hidden': deviceService.isMobile()}" *ngIf="featureName === FeatureName.TV && videoRplcmntVisibility() && isFeatureUpgradeVisible"></span>
    <a href="javascript:void(0)" *ngIf="featureName === FeatureName.TV && videoRplcmntVisibility() && isFeatureUpgradeVisible" class="pr-4" [ngClass]="{'pl-3 pb-2 pt-2 linkUnderline': deviceService.isMobile(), 'pl-4': !deviceService.isMobile()}" (click)="generateSSOUrl()">{{resourceBundle.orderReplacementParts}}</a>
  </div>
</div>
<hr class="hr margin-bottom-30px" *ngIf="deviceService.isMobile()" aria-hidden="true">

<ng-template #selectServiceModal let-close="close" let-dismiss="dismiss">
<div role="dialog" aria-modal="true" aria-describedby="modal-title">
  <span class="sr-only" id="modal-title">{{resourceBundle.serviceDetails}} {{resourceBundle.headingLevel4}}</span>
  <div class="modal-header modalheader">
    <h4 *ngIf="!isBroadbandFacts" class="h4 modal-title">{{resourceBundle.serviceDetails}}</h4>
    <h4 *ngIf="isBroadbandFacts" class="h4 modal-title">{{resourceBundle.broadbandFactLabelHeader}}</h4>
       <a title="Close" id="closeBtn" class="cb-modal-close-btn" href="javascript:void(0)"
          (click)="close(false)" role="button" appModalfocus></a>
  </div>
  <div class="modal-body">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-2" *ngIf="!isMultiAccount">
          <div class="text-center hide">
              <app-redesign-accountwidget class="col-md-12 col-lg-12 col-12 pull-right pt-md-0 pt-3"
                  [featureName]="featureName" [hideWidgetTitle]="true" [accoutList] = "accountList"
                  (selectedAccountEvent)="selectedAccount($event)"></app-redesign-accountwidget>
          </div>
          <p>Add/Upgrade your services at this location(
              {{this.accountAddress?.street}}, 
              {{this.accountAddress?.houseNumber}}, 
              {{this.accountAddress?.city}}, {{this.accountAddress?.state}} 
              {{this.accountAddress?.zipCode}}
              ) or add a new location.</p>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-12 p-0 offset-md-2 pb-2" *ngIf="isMultiAccount">
          <div>
              <app-redesign-accountwidget class="col-md-12 col-lg-12 col-12 pull-right pt-md-0 pt-3"
                  [featureName]="featureName" [hideWidgetTitle]="true" [accoutList] = "accountList"
                  (selectedAccountEvent)="selectedAccount($event)"></app-redesign-accountwidget>
          </div>
      </div>
  </div>
  <hr class="hr mb-0">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <button type="button" id="backBtn" class="button btn-long-text button-secondary pull-left ml-lg-4 hide" (click)="close(true)">Add a
          new location</button>
      <button type="button" id="confirmBtn" (click)="sulaModal();close(true);"
          class="button pull-right margin-left-20" [attr.disabled]="!selectedAccInfo ? true : null">Continue</button>
  </div>
</div>
</ng-template>
