import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef,
  ComponentFactoryResolver, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TypeaheadSearchComponent } from './typeaheadSearch/typeahead.component';

@Component({
  selector: 'app-redesignSearch',
  templateUrl: './redesignSearch.component.html',
  styleUrls: ['./redesignSearch.component.scss']
})
export class RedesignSearchComponent implements OnInit, OnChanges {
  @ViewChild('searchBtn') public searchBtn: ElementRef;
  @ViewChild('searchcontainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  @Input() searchDataSource = [];
  @Input() placeholder: string;
  @Output() selectedItem = new EventEmitter<object>();
  @Output() searchString = new EventEmitter<string>();
  @Input() id: string;
  @Input() tempText: string;                      //to persist search Text
  // @Input() includeCols: string[];
  @Input() datatableSearch: boolean;
  @Input() msTeamsSearch: boolean;
  @Input() voiceEncryptionSearch: boolean;
  @Input() singlePayerSearch: boolean;
  isSearchActive: Observable<boolean>;
  public inActiveSearchSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public componentRef: any;
  public searchFieldHeight = false;

  constructor(private factoryResolver: ComponentFactoryResolver) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchDataSource && !changes.searchDataSource.firstChange) {
      const searchDataSource = changes.searchDataSource.currentValue.map(item => Object.assign({}, item));
      this.componentRef.instance.searchDataSource = searchDataSource;
      this.componentRef.instance.ngOnChanges();
    }
  }
  ngOnInit() {
    this.isSearchActive = this.inActiveSearchSubject.asObservable().pipe(delay(0));
    this.initializeSearch();
  }

  initializeSearch() {
    if (!this.datatableSearch) {
      this.isSearchActive.subscribe((isActive: boolean) => {
        if (isActive) {
          this.searchFieldHeight = false;
          // initialize component
          setTimeout(() => {
            if(!this.msTeamsSearch){
              document.getElementsByClassName('overlay-search')[0].classList.remove('hide');
            }
            this.createFactory();
          });
        }
      });
    } else {
      this.createFactory();
    }
  }

  createFactory() {
    setTimeout(() => {
      this.entry.clear();
      const factory = this.factoryResolver.resolveComponentFactory(TypeaheadSearchComponent);
      this.componentRef = this.entry.createComponent(factory);
      this.componentRef.instance.msTeamsSearch = this.msTeamsSearch;
      this.componentRef.instance.voiceEncryptionSearch = this.voiceEncryptionSearch;
      this.componentRef.instance.singlePayerSearch = this.singlePayerSearch;
      const searchDataSource = this.searchDataSource.map(item => Object.assign({}, item));
      this.componentRef.instance.searchDataSource = searchDataSource;
      this.componentRef.instance.placeholder = this.placeholder;
      this.componentRef.instance.isDatatable = this.datatableSearch;
      this.componentRef.instance.id = this.id;
      this.componentRef.instance.tempText = this.tempText;
      if (!this.datatableSearch) {
        this.searchBtn.nativeElement.style.display = 'none';
      }
      this.initializeSearchSubscriber();
    });
  }

  initializeSearchSubscriber() {
    if (this.componentRef) {
      this.componentRef.instance.userSelectedResult.subscribe((result: any) => {
        if (result) {
          const selectedItem = result.target;
          this.selectedItem.emit(selectedItem);
        }
      });
      this.componentRef.instance.searchString.subscribe((result: any) => {
        this.searchString.emit(result);
      });
      if (!this.datatableSearch) {
        this.componentRef.instance.closeSearchEvent.subscribe(value => {
          this.closeSearch();
          if (this.msTeamsSearch) {
            this.tempText = value.tempText;
          }
          if (this.msTeamsSearch || this.singlePayerSearch || this.voiceEncryptionSearch) {
            this.searchBtn.nativeElement.style.display = 'flex';
            this.searchFieldHeight = true;
          } else {
            this.searchBtn.nativeElement.style.display = 'block';
          }
        });
      }
    }
  }

  searchOnTable() {
    this.inActiveSearchSubject.next(true);
  }

  closeSearch() {
    this.inActiveSearchSubject.next(false);
    if (!this.datatableSearch) {
      document.getElementsByClassName('overlay-search')[0].classList.add('hide');
    }
  }
}
