import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  PLATFORM_ID,
  QueryList,
} from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbSlide } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import {distinctUntilChanged, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
// Defines Slides Direction

export enum NgbSlideEventDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum NgbSlideEventSource {
  TIMER = 'timer',
  ARROW_LEFT = 'arrowLeft',
  ARROW_RIGHT = 'arrowRight',
  INDICATOR = 'indicator',
}

const nextId = 0;

/**
 * Carousel is a component to easily create and control slideshows.
 *
 * Allows to set intervals, change the way user interacts with the slides and provides a programmatic API.
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-custom-carousel',
  exportAs: 'comnCustomcarousel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    'class': 'carousel slide',
    '[style.display]': '"block"',
    'tabIndex': '0',
    '(keydown.arrowLeft)': 'keyboard && arrowLeft()',
    '(keydown.arrowRight)': 'keyboard && arrowRight()',
    '(mouseenter)': 'mouseHover = true',
    '(mouseleave)': 'mouseHover = false',
    '(focusin)': 'focused = true',
    '(focusout)': 'focused = false',
    '[attr.aria-activedescendant]': 'activeId',
  },
  template: `
    <ol class="carousel-indicators"  role="tablist">
      <li *ngFor="let slide of slides" [id]="slide.id" [class.active]="slide.id === activeId"
          role="tab" [attr.aria-labelledby]="slide.id + '-slide'" [attr.aria-controls]="slide.id + '-slide'"
          [attr.aria-selected]="slide.id === activeId"
          (click)="focus();select(slide.id, NgbSlideEventSource.INDICATOR);"></li>
    </ol>
    <div class="carousel-inner">
      <div *ngFor="let slide of slides; index as i; count as c" class="carousel-item"
      [class.active]="slide.id === activeId" [id]="slide.id + '-slide'" role="tabpanel">
        <span class="sr-only" i18n="Currently selected slide number read by screen reader@@ngb.carousel.slide-number">
          Slide {{i + 1}} of {{c}}
        </span>
        <ng-template [ngTemplateOutlet]="slide.tplRef" role="region" aria-label="Promotions Slideshow"></ng-template>
      </div>
    </div>
    <a class="carousel-control-prev" role="presentation" (click)="arrowLeft()">
      <span class="carousel-control-prev-icon" tabindex="0" aria-label="Previous" role="button"></span>
      <span class="sr-only" i18n="@@ngb.carousel.previous">Previous</span>
    </a>
    <a class="carousel-control-next" role="presentation" (click)="arrowRight()">
      <span class="carousel-control-next-icon" tabindex="0" aria-label="Next" role="button"></span>
      <span class="sr-only" i18n="@@ngb.carousel.next">Next</span>
    </a>
  `,
})

export class CustomcarouselComponent implements AfterContentChecked,
    AfterContentInit, OnDestroy {
  @ContentChildren(NgbSlide) slides: QueryList<NgbSlide>;
  public NgbSlideEventSource = NgbSlideEventSource;
  private _destroy$ = new Subject<void>();
  private _interval$ = new BehaviorSubject(0);
  private _mouseHover$ = new BehaviorSubject(false);
  private _focused$ = new BehaviorSubject(false);
  private _pauseOnHover$ = new BehaviorSubject(false);
  private _pauseOnFocus$ = new BehaviorSubject(false);
  private _pause$ = new BehaviorSubject(false);
  private _wrap$ = new BehaviorSubject(false);

  /**
   * The slide id that should be displayed **initially**.
   *
   * For subsequent interactions use methods `select()`, `next()`, etc. and the `(slide)` output.
   */
  @Input() activeId: string;

  /**
   * Time in milliseconds before the next slide is shown.
   */
  @Input()
  set interval(value: number) {
    this._interval$.next(value);
  }

  get interval() { return this._interval$.value; }

  /**
   * If `true`, will 'wrap' the carousel by switching from the last slide back to the first.
   */
  @Input()
  set wrap(value: boolean) {
    this._wrap$.next(value);
  }

  get wrap() { return this._wrap$.value; }

  /**
   * If `true`, allows to interact with carousel using keyboard 'arrow left' and 'arrow right'.
   */
  @Input() keyboard: boolean;

  /**
   * If `true`, will pause slide switching when mouse cursor hovers the slide.
   *
   */
  @Input()
  set pauseOnHover(value: boolean) {
    this._pauseOnHover$.next(value);
  }

  get pauseOnHover() { return this._pauseOnHover$.value; }

  /**
   * If `true`, will pause slide switching when the focus is inside the carousel.
   */
  @Input()
  set pauseOnFocus(value: boolean) {
    this._pauseOnFocus$.next(value);
  }

  get pauseOnFocus() { return this._pauseOnFocus$.value; }

  /**
   * If `true`, 'previous' and 'next' navigation arrows will be visible on the slide.
   *
   *
   */
  @Input() showNavigationArrows: boolean;

  /**
   * If `true`, navigation indicators at the bottom of the slide will be visible.
   *
   *
   */
  @Input() showNavigationIndicators: boolean;

  @Output() slide = new EventEmitter<NgbSlideEvent>();

  set mouseHover(value: boolean) { this._mouseHover$.next(value); }

  get mouseHover() { return this._mouseHover$.value; }

  set focused(value: boolean) { this._focused$.next(value); }

  get focused() { return this._focused$.value; }
   constructor(
      config: NgbCarouselConfig, private _ngZone: NgZone,
      private _cd: ChangeDetectorRef, private _container: ElementRef) {
    this.interval = config.interval;
    this.wrap = config.wrap;
    this.keyboard = config.keyboard;
  }
  arrowLeft() {
    this.focus();
    this.prev(NgbSlideEventSource.ARROW_LEFT);
  }

  arrowRight() {
    this.focus();
    this.next(NgbSlideEventSource.ARROW_RIGHT);
  }

  ngAfterContentInit() {
    this.slides.changes.pipe(takeUntil(this._destroy$)).subscribe(() => this._cd.markForCheck());
  }

  ngAfterContentChecked() {
    const activeSlide = this._getSlideById(this.activeId);
    this.activeId = activeSlide ? activeSlide.id : (this.slides.length ? this.slides.first.id : '');
  }

  ngOnDestroy() { this._destroy$.next(); }

  /**
   * Navigates to a slide with the specified identifier.
   */
  select(slideId: string, source?: NgbSlideEventSource) {
    this._cycleToSelected(slideId, this._getSlideEventDirection(this.activeId, slideId), source);
  }

  /**
   * Navigates to the previous slide.
   */
  prev(source?: NgbSlideEventSource) {
    this._cycleToSelected(this._getPrevSlide(this.activeId), NgbSlideEventDirection.RIGHT, source);
  }

  /**
   * Navigates to the next slide.
   */
  next(source?: NgbSlideEventSource) {
    this._cycleToSelected(this._getNextSlide(this.activeId), NgbSlideEventDirection.LEFT, source);
  }

  /**
   * Pauses cycling through the slides.
   */
  pause() { this._pause$.next(true); }

  /**
   * Restarts cycling through the slides from left to right.
   */
  cycle() { this._pause$.next(false); }

  /**
   * Set the focus on the carousel.
   */
  focus() { this._container.nativeElement.focus(); }

  private _cycleToSelected(slideIdx: string, direction: NgbSlideEventDirection, source?: NgbSlideEventSource) {
    const selectedSlide = this._getSlideById(slideIdx);
    if (selectedSlide && selectedSlide.id !== this.activeId) {
      this.slide.emit(
          {prev: this.activeId, current: selectedSlide.id, direction, paused: this._pause$.value, source});
      this.activeId = selectedSlide.id;
    }

    // we get here after the interval fires or any external API call like next(), prev() or select()
    this._cd.markForCheck();
  }

  private _getSlideEventDirection(currentActiveSlideId: string, nextActiveSlideId: string): NgbSlideEventDirection {
    const currentActiveSlideIdx = this._getSlideIdxById(currentActiveSlideId);
    const nextActiveSlideIdx = this._getSlideIdxById(nextActiveSlideId);

    return currentActiveSlideIdx > nextActiveSlideIdx ? NgbSlideEventDirection.RIGHT : NgbSlideEventDirection.LEFT;
  }

  private _getSlideById(slideId: string): NgbSlide | null {
    return this.slides.find((slide) => slide.id === slideId) || null;
  }

  private _getSlideIdxById(slideId: string): number {
    const slide = this._getSlideById(slideId);
    return slide != null ? this.slides.toArray().indexOf(slide) : -1;
  }

  private _getNextSlide(currentSlideId: string): string {
    const slideArr = this.slides.toArray();
    const currentSlideIdx = this._getSlideIdxById(currentSlideId);
    const isLastSlide = currentSlideIdx === slideArr.length - 1;

    return isLastSlide ? (this.wrap ? slideArr[0].id : slideArr[slideArr.length - 1].id) :
                         slideArr[currentSlideIdx + 1].id;
  }

  private _getPrevSlide(currentSlideId: string): string {
    const slideArr = this.slides.toArray();
    const currentSlideIdx = this._getSlideIdxById(currentSlideId);
    const isFirstSlide = currentSlideIdx === 0;

    return isFirstSlide ? (this.wrap ? slideArr[slideArr.length - 1].id : slideArr[0].id) :
                          slideArr[currentSlideIdx - 1].id;
  }
}

/**
 * A slide change event emitted right after the slide transition is completed.
 */
export interface NgbSlideEvent {
  /**
   * The previous slide id.
   */
  prev: string;

  /**
   * The current slide id.
   */
  current: string;

  /**
   * The slide event direction.
   *
   * Possible values are `'left' | 'right'`.
   */
  direction: NgbSlideEventDirection;

  /**
   * Whether the pause() method was called (and no cycle() call was done afterwards).
   *
   *
   */
  paused: boolean;

  /**
   * Source triggering the slide change event.
   *
   * Possible values are `'timer' | 'arrowLeft' | 'arrowRight' | 'indicator'`
   *
   *
   */
  source?: NgbSlideEventSource;
}

export const NGB_CAROUSEL_DIRECTIVES = [CustomcarouselComponent, NgbSlide];
