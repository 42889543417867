<div class="pf-top-header">
    <a href="https://www.cox.com/residential/home.html" title="Residential">Residential</a>
    <span class="pl-3 pr-3">|</span>
    <a href="https://www.cox.com/business/home.html" title="Cox&#x20;Business" class="selected">Cox Business</a>
</div>
<app-customheader></app-customheader>
<div class="pt-5 pb-5">
    <h2>We're sorry, our web site is currently undergoing maintenance.</h2>
</div>
<p class="margin-top-20px">This web site is temporarily unavailable. We apologize for the inconvenience. We are working to complete the maintenance as quickly as possible. Once the maintenance work is completed, this website will resume its full functionality.
</p>
<p class="margin-top-20px">
    Please know that our employees are working to quickly complete the maintenance operations and restore your services. Thank you for your patience.
</p>
<p *ngIf="isCbCore" class="bold pt-3 pb-4">
    <a routerLink="/standalone/cbcore">Click here</a> to access CORE application.
</p>
<app-customfooter></app-customfooter>