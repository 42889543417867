import { Injectable} from '@angular/core';
import { Message } from './message.component';

@Injectable()
export class ErrorMessageService {

  public errorMessage: Message;
  public errorTransactionIdMessage: Message;
  public welcomewidgetMessage: Message;

  constructor() {
    this.errorMessage = new Message();
    this.errorTransactionIdMessage = new Message();
    this.welcomewidgetMessage = new Message();
  }
  /**
   * Hide message
   */
  public hideMessage(): void {
    this.errorMessage.show = false;
  }

  /**
   * Show generic error message
   */
  public displayGenericError() {
    this.showError('We\'re sorry.  We can\'t complete your request at this time. Please try again later.');
  }

  /**
   * Show error message
   * @param message custom message string
   */
  public showError(message: string): void {
    this.showMessage(message, 'error');
  }

  /**
   * Show success message
   * @param message custom message string
   */
  public showSuccess(message: string): void {
    this.showMessage(message, 'success');
  }

  /**
   * Show alert message
   * @param message custom message string
   */
  public showAlert(message: string): void {
    this.showMessage(message, 'alert');
  }

  /**
   * Show info message
   * @param message custom message string
   */
  public showInfo(message: string): void {
    this.showMessage(message, 'info');
  }

  /**
   * Show message
   * @param message custom message
   * @param type message type
   */

   // tslint:disable-next-line: max-union-size
  public showMessage(message: string, type: 'success' | 'error' | 'info' | 'alert'): void {
    this.errorMessage.show = true;
    this.errorMessage.type = type;
    this.errorMessage.message = message;
  }

}
