import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import OktaSignIn from '@okta/okta-signin-widget';
import oktaConfig from 'assets/config/oktaConfig.json';
import {
  AEMClientService,
  AppConfig,
  CBHelperService,
  LocalStorageKeys,
  LoginProfileClient,
  Message,
  MessageService,
  ResetPasswordComponent,
  ResourceBundle,
  Role,
  SSOService,
} from 'common-ui-lib';
import { SSOPageName } from 'common-ui-lib/lib/constants/ssopagename.model';
import { CBLoaderService } from 'common-ui-lib/lib/shared/cbloader/cbloader.service';
import { LocalStorageService } from 'ngx-localstorage';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { SessionStorageService } from 'ngx-webstorage';
import { OnboardingStepsUrl } from 'onboarding/onboarding.model';
import { OnboardingService } from 'onboarding/onboarding.service';
import { takeUntil } from 'rxjs/operators';
import { interval, Subject, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Meta } from '@angular/platform-browser';
import { HeaderFooterEnum } from 'common-ui-lib/lib/services/cbhelper.service';
import { AbondonedDataResponse, PostLoginService } from 'boot/postlogin/postlogin.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from 'common-ui-lib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy, OnInit, AfterViewInit {
  public resourceBundle: ResourceBundle = {};
  public isMyAdmin = false;
  public isMyAccount = false;
  public isHnPortal = false;
  public entryPointValue = false;
  public loginMessage: Message = new Message();
  public messageBundle: string;
  public usernameDisabled = false;
  public oktaFlag: boolean;
  public isExternalApp = false;
  public appName: string;
  public loginTitle: string;
  public isLongTitle = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signIn: any;
  public talonPwdFlag: boolean;
  public resetPwdModalRef: NgbModalRef;
  public checkHNCust = false;
  public forgotUSerID: string;
  public isGravityMobileApp = false;
  public subscription$: Subscription;
  private aguid: string;
  private email: string;
  private onDestroy$ = new Subject<boolean>();
  private myaccountClientID;
  private myAdminClientID;
  private oktaIssuer;
  private localMyadminFlag: boolean;
  private mobileView = false;
  private externalApps: string[];
  // tslint:disable-next-line: cognitive-complexity

  constructor(
    private aemClient: AEMClientService,
    public cbHelper: CBHelperService,
    private loginProfileClient: LoginProfileClient,
    private _storageService: LocalStorageService,
    public service: PostLoginService,
    private spinnerService: CBLoaderService,
    private router: Router,
    private permissionService: NgxPermissionsService,
    private activatedRoute: ActivatedRoute,
    private config: AppConfig,
    public onboardingService: OnboardingService,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute,
    private ssoService: SSOService,
    private deviceService: DeviceDetectorService,
    private modalService: NgbModal,
    private meta: Meta,
    public messageService: MessageService, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {
    //this change is to not to load polyfills for webex app
    const webex = window.sessionStorage.getItem('referrerPath');

    if (webex?.includes('webex')) {
      window.localStorage.setItem('cbma_webex', 'webex');
    }
    this.externalApps = this.config.getConfig('externalApps');
    this.checkApplicationType();
    if (this.deviceService.isMobile()) {
      this.mobileView = true;
    }
    this.spinnerService.show();
    // localMyadminFlag is used to access Myadmin in LOCAL machine. Always false in all environments.
    this.localMyadminFlag = oktaConfig.okta.localMyadminFlag
      ? JSON.parse(this.config.getConfig('localMyadminFlag'))
      : false;
    this.oktaFlag = JSON.parse(this.config.getConfig('oktamaintenanceFlag'));
    this.talonPwdFlag = JSON.parse(this.config.getConfig('featuredFlag')['talonPwdFlag']);
    const hostname = window.location.hostname;
    // eslint-disable-next-line max-len
    if (
      hostname.indexOf('localhost') > -1 ||
      hostname.indexOf('qa') > -1 ||
      hostname.indexOf('st1') > -1 ||
      hostname.indexOf('dev') > -1 ||
      hostname.indexOf('uat') > -1 ||
      hostname.indexOf('sit') > -1
    ) {
      this.myaccountClientID = oktaConfig.okta['qa'].myAccountClientId;
      this.myAdminClientID = oktaConfig.okta['qa'].myAdminClientId;
      this.oktaIssuer = oktaConfig.okta['qa'].issuer;
      // eslint-disable-next-line max-len
    } else if (
      hostname.indexOf('localhost') === -1 &&
      hostname.indexOf('qa') === -1 &&
      hostname.indexOf('st1') === -1 &&
      hostname.indexOf('dev') === -1 &&
      hostname.indexOf('uat') === -1 &&
      hostname.indexOf('sit') === -1
    ) {
      this.myaccountClientID = oktaConfig.okta['prod'].myAccountClientId;
      this.myAdminClientID = oktaConfig.okta['prod'].myAdminClientId;
      this.oktaIssuer = oktaConfig.okta['prod'].issuer;
    }
    this.loginMessage.show = false;
    if (this.cbHelper.isMyAdmin() || this.localMyadminFlag) {
      this.isMyAdmin = true;
    } else if (this.cbHelper.isUnauthHnURL) {
      this.isHnPortal = true;
    } else if (!this.cbHelper.isMyAdmin()) {
      this.sessionStorage.store(LocalStorageKeys.FirstLogin, true);
      this.isMyAccount = true;
    }

    this.changePasswordError();
    const configLogin = {
      baseUrl: this.oktaIssuer.split('/oauth2')[0],
      clientId: this.cbHelper.isMyAdmin() || this.localMyadminFlag ? this.myAdminClientID : this.myaccountClientID,
      redirectUri:
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      oktaConfig.okta.redirectUri,
        useClassicEngine: true,
      i18n: {
        en: this.getOktaCustomVerbiage(),
      },
      features: {
        rememberMe: !this.isExternalApp,
        showPasswordToggleOnSignInPage: true,
        multiOptionalFactorEnroll: true,
      },
      authParams: {
        pkce: oktaConfig.okta.pkce,
        issuer: this.oktaIssuer,
        scopes:
          this.cbHelper.isMyAdmin() || this.localMyadminFlag
            ? oktaConfig.okta.myAdminScopes
            : oktaConfig.okta.myAccountScopes,
      },
      customButtons: this.getOktaCustomButtons(),
    };
    const configHNLogin = {
      baseUrl: this.oktaIssuer.split('/oauth2')[0],
      clientId: this.cbHelper.isMyAdmin() || this.localMyadminFlag ? this.myAdminClientID : this.myaccountClientID,
      redirectUri:
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      oktaConfig.okta.redirectUri,
        useClassicEngine: true,
      i18n: {
        en: this.getOktaCustomVerbiage(),
      },
      features: {
        rememberMe: true,
        showPasswordToggleOnSignInPage: true,
        multiOptionalFactorEnroll: true,
      },
      authParams: {
        pkce: oktaConfig.okta.pkce,
        issuer: this.oktaIssuer,
        scopes: this.cbHelper.isMyAdmin() ? oktaConfig.okta.myAdminScopes : oktaConfig.okta.myAccountScopes,
        // responseType: 'code',
        // responseMode: 'fragment',
      },
    };
    this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params['cust'] && params['cust'] === 'HN') {
        this.signIn = new OktaSignIn(configHNLogin);
      } else {
        this.signIn = new OktaSignIn(configLogin);
      }

    });
    this.customLogin();
  }

  public setPlaceholderValue() {
    if (document.getElementById('okta-signin-username')) {
      (document.getElementById('okta-signin-username') as HTMLInputElement).placeholder = 'User ID (Email Address)';
    }
    if (document.getElementById('okta-signin-password')) {
      (document.getElementById('okta-signin-password') as HTMLInputElement).placeholder = 'Password';
    }
  }
  public removeLabelMobile() {
    const labels = document.querySelectorAll('.okta-form-label');
    if (labels) {
      labels.forEach((label) => label?.setAttribute('style', 'display:none'));
    }
  }

  public ngAfterViewInit() {
    // For MyAdmin redirect to OKTA for Azure authentication.

    if ((this.cbHelper.isMyAdmin() && !this.cbHelper.isUserAuthenticated()) || this.localMyadminFlag) {
      this.oktaAuth.signInWithRedirect();
      return;
    }
    this.signIn.remove();
    // Render sign-in widget for MyAccount.
    // When navigating to a protected route, the route path will be saved as the `originalUri`
    //If no `originalUri` has been saved, then redirect back to the app root
    this.signIn.showSignInAndRedirect({
      // Assumes there is an empty element on the page with an id of 'osw-container'
      el: '#sign-in-widget'
    }).catch((error) => {
      // This function is invoked with errors the widget cannot recover from:
      // Known errors: CONFIG_ERROR, UNSUPPORTED_BROWSER_ERROR
    });

    if (!this.cbHelper.isMyAdmin() && this.email && this.aguid) {
      let awaitCounter = 0;
      const tHandle = setInterval(() => {
        if (awaitCounter > 500) {
          console.log('looks like oktawidget will never appear. stop watching');
          clearInterval(tHandle);
        } else {
          awaitCounter++;
          if (document.getElementById('okta-signin-username')) {
            (document.getElementById('input42') as HTMLInputElement).checked = false;
            (document.getElementById('okta-signin-username') as HTMLInputElement).disabled = true;
            clearInterval(tHandle);
          }
        }
      }, 20);
    }
    // As it's taking some time to load the widget adding this methods to callback using setTimeout
    setTimeout(() => {
      if (this.mobileView) {
        this.setPlaceholderValue();
        this.removeLabelMobile();
      }
    }, 2000);
  }

  public entryPointRegistered() {
    this.entryPointValue = this.loginTitle ? true : false;
  }

  public updateForgotPassword() {
    if (this.resourceBundle.forgotpassword === '/unauth/forgotpassword') {
      this.resourceBundle.forgotpassword = '/unauth/forgotpassword/' + this.aguid + '/' + this.email;
    }
  }

  public redirectionHandler() {
    if (this.talonPwdFlag) {
      this.modalService
        .open(ResetPasswordComponent, {
          windowClass: this.mobileView ? '' : 'redesign-modal-md',
          backdrop: 'static'
        }).result.then((result) => {
          const checkHNurl = document.URL;
          if (checkHNurl.indexOf('HN') > -1) {
            this.checkHNCust = true;
          }
          if (result === 'changePassword') {
            this.loadingLoginPage('success');
          } else if (result === 'forgotUserId') {
            this.loadingLoginPage('forgotUserIdClicked');
          } else {
            this.loadingLoginPage('close');
          }
        });
    } else {
      const queryParam = this.isExternalApp ? '?app=webex' : this.isHnPortal ? '?cust=HN' : '';
      const url = this.cbHelper.getOriginUrl() + '/unauth/forgotpassword' + queryParam;
      return window.location.href = url;
    }
  }

  private customLogin() {
    const targetedOffers = window.sessionStorage.getItem('referrerPath');
    const interval = setInterval(() => {
      const element = (document.getElementById('okta-signin-submit')) as HTMLElement;
      if (element) {
        clearInterval(interval);
        element?.addEventListener('click', () => {
          if (this.sessionStorage.retrieve('resetfactoruser')) {
            this.loginMessage.type = null;
            this.loginMessage.show = false;
            this.loginMessage.message = null;
          }
          this.loginMessage.show = false;
          if (targetedOffers?.includes('buyflow')) {
            this.setCustomErrorMessage();
          }
        });
      }
    });
  }
  //for mobile app reloading the login page to capture the browser url
  private loadingLoginPage(status: string) {
    if (status === 'success') {
      if (this.checkHNCust) {
        window.location.href = `${window.location.origin}/cbma/unauth/login?passwordchanged=${status}&cust=HN`;
      } else {
        window.location.href = `${window.location.origin}/cbma/unauth/login?passwordchanged=${status}`;
      }
      this.loginMessage.type = 'success';
      this.loginMessage.level = 'global';
      this.loginMessage.message = this.resourceBundle.passwordChangeSuccessmsg;
      this.loginMessage.show = true;

    } else if (status === 'forgotUserIdClicked') {
      if (this.isExternalApp) {
        window.location.href =
        `${this.cbHelper.getOriginUrl()}${this.resourceBundle.useridRecovery}?forgotuserid=true&app=webex`;
      } else {
      if (this.checkHNCust) {
        window.location.href =
          `${this.cbHelper.getOriginUrl()}${this.resourceBundle.useridRecovery}?forgotuserid=true&cust=HN`;
      } else {
        window.location.href =
          `${this.cbHelper.getOriginUrl()}${this.resourceBundle.useridRecovery}?forgotuserid=true`;
      }
    }
    } else {
      if (this.checkHNCust) {
        window.location.href = `${window.location.origin}/cbma/unauth/login?passwordchanged=${status}&cust=HN`;
      } else {
        window.location.href = `${window.location.origin}/cbma/unauth/login?passwordchanged=${status}`;
      }
    }
  }

  public businessLoginHN() {
    window.location.href = window.location.origin + this.resourceBundle.hnBusinessAcc;
  }
  public goToBusinessLink() {
    if (!this.isExternalApp) {
      window.open(this.config.getConfig('coxdotcom')['cbhomeUrl'], '_blank');
    }
  }
  public gotoUserIdRecovery() {
    if (this.isExternalApp) {
      return (window.location.href = this.cbHelper.getOriginUrl() + this.resourceBundle.useridRecovery + '?app=webex');
    } else {
      if (this.isHnPortal) {
        return (window.location.href = this.cbHelper.getOriginUrl() + this.resourceBundle.useridRecovery + '?cust=HN');
      } else {
        return (window.location.href = this.cbHelper.getOriginUrl() + this.resourceBundle.useridRecovery);
      }
    }
  }

  public ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.spinnerService.hide();
    this.subscription$?.unsubscribe();
  }

  private checkApplicationType(): void {
    const refPath = window.sessionStorage.getItem('referrerPath');
    this.appName = this.cbHelper.getQueryStringValues(refPath, 'onsuccess');
    if (this.appName && this.appName.includes('gravity-mobile-app')) {
      this.appName = decodeURIComponent(this.appName).replace(/"/g, '');
      this.isGravityMobileApp = true;
        // Hiding chat icon when user logs in directly to gravity portal
        const interval$ = interval();
        this.subscription$ = new Subscription();
        this.subscription$ = interval$?.subscribe(() => {
          const letsChatButton = document?.getElementsByClassName('bcFloat');
          if (letsChatButton.length > 0) {
            letsChatButton[0]?.classList?.add('hide');
            this.subscription$?.unsubscribe();
          }
        });
    }
    const referrerObj =
      this.config.getConfig('externalAuthUrls')[this.appName] !== undefined
        ? this.config.getConfig('externalAuthUrls')[this.appName]
        : null;
    this.loginTitle = referrerObj
      ? referrerObj.title
      : this.config.getConfig('externalAuthUrls')['defaultCbmaLogin'].title;
    this.isLongTitle = this.loginTitle.length > 16 ? true : false;
    if (this.externalApps.includes(this.appName)) {
      this.isExternalApp = true;
      const container: HTMLElement = document.getElementById('c-container');
      container.style.paddingBottom = '0px';
    }
  }

  /**Setting the place holders for mobile view as we dont have okta properties to set directly
   * labels are used after version ^3.0,no placeholder prop available
   */

  // eslint-disable-next-line
  public ngOnInit() {
    this.aemClient
      .getBundle('loginResourceBundle', 'app')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
          // Temporary fix for R74 to hide landing/start page spinner.
          window.document.getElementById('main-spinner').classList.add('hidden');

          this.activatedRoute.queryParams.subscribe(async (params) => {
            if (params['aguid'] && params['email']) {
              this.updateForgotPassword(); // to append 'true' in URL of forgotpassword
              return;
            }
          });
          if(this.activatedRoute.snapshot?.queryParamMap?.get('duplicateRegistration')){
              const message = this.activatedRoute.snapshot?.queryParamMap?.get('usertype') === 'admin' ?
                this.resourceBundle.duplicateRegistrationAdminMsg : this.resourceBundle.duplicateRegistrationPOMsg;
              this.loginMessage = this.messageService.showErrorMessage(this.loginMessage, message);
          }
          if (this.sessionStorage.retrieve('profilecreated')) {
            const profilecreated = this.sessionStorage.retrieve('profilecreated');
            if (profilecreated) {
              window.scrollTo(0, 0);
              this.loginMessage.type = 'success';
              this.loginMessage.level = 'global';
              this.loginMessage.message = this.resourceBundle.profileCreateSuccessMsg;
              this.loginMessage.show = true;
            }
          }
          if (this.sessionStorage.retrieve('resetfactoruser')) {
            const mfafactorsReset = this.sessionStorage.retrieve('resetfactoruser');
            if (mfafactorsReset) {
              this.loginMessage.type = 'info';
              this.loginMessage.show = true;
              this.loginMessage.message = this.resourceBundle.mfaResetInfoMsg;
            }
          }
          if ((this.route.snapshot.queryParams.passwordchanged === 'success' && this.talonPwdFlag) ||
            (this.route.snapshot.queryParams.passwordchanged === 'true' && !this.talonPwdFlag)) {
            window.scrollTo(0, 0);
            this.loginMessage.type = 'success';
            this.loginMessage.level = 'global';
            this.loginMessage.message = this.resourceBundle.passwordChangeSuccessmsg;
            this.loginMessage.show = true;
          }
        },
        (error) => {
          window.document.getElementById('main-spinner').classList.add('hidden');
        }
      );

    if (this.isExternalApp) {
      this.cbHelper.hideHeaderAndFooter(HeaderFooterEnum.BOTH);
      const container: HTMLElement = document.getElementById('c-container');
      container.style.paddingBottom = '0px';
    } else {
      this.cbHelper.hideHeaderAndFooter(HeaderFooterEnum.NONE);
    }

    if (this._storageService.get(LocalStorageKeys.Authtoken, 'cbma') && this.cbHelper.isCbmaAuthTokenExpired()) {
      this.router.navigate(['/unauth/login']);
      return;
    }
    if (this.cbHelper.isUserAuthenticated()) {
      console.log('OKTATIME - LoginComponent isUserAuthenticated ', new Date().getTime());
      this.service.clearOBSessionVars(); // to clear OB session storage variables if user visits login page
      this.redirect();
      return;
    }
    if (this._storageService.get(LocalStorageKeys.Authtoken, 'cbma') && !this.cbHelper.isUserAuthenticated()) {
      this._storageService.remove(LocalStorageKeys.Authtoken, 'cbma');
    }

    // TODO Revise the account login flow
    this.route.queryParams.subscribe((qparams) => {
      console.log('query params', qparams);
      const showforgotpassword = qparams['showforgotpassword'] ? JSON.parse(qparams['showforgotpassword']) : false;
      if (qparams['aguid'] && qparams['email']) {
        this.aguid = qparams['aguid'];
        this.email = qparams['email'];
        document.cookie = 'ln=' + this.email + ';path=/;secure';
        this.oktaAuth.setOriginalUri('accountlogin/' + this.aguid);
        return;
      } else if (showforgotpassword) {
        this.redirectionHandler();
      }
    });
    this.entryPointRegistered();
  if (!this.aguid || !this.email) {
      const referrer = this.oktaAuth.getOriginalUri();
      if (referrer?.includes('accountlogin/')) {
        this.oktaAuth.setOriginalUri('/');
      } else {
        this.oktaAuth.setOriginalUri(referrer);
      }
    }
    this.cbHelper.processCredentials();
    if (!this.isMyAdmin) {
      this.spinnerService.hide();
    }
    this.meta.addTag({ name: 'description', content: 'Manage your Cox Business services here.' });
  }

  private getOktaCustomButtons() {
    if (this.isExternalApp) {
      return [];
    } else {
      setTimeout(() => {
        document.getElementsByClassName('btn-customOr')[0]?.setAttribute('tabindex', '-1');
      }, 10);
      return [
        {
          title: 'or',
          className: 'btn-customOr',
          click: () => {}
        },
        {
          title: 'Register',
          className: 'btn-Register',
          click: () => {
            // clicking on the button navigates to another page
              window.location.href = window.location.origin + '/cbma' + '/register/welcome';
          },
        },
      ];
    }
  }

  ngAfterContentChecked() {
    if (this.isGravityMobileApp) {
      const letschatFloat: HTMLElement = document?.getElementById('web-messenger-container');
      if (letschatFloat && !letschatFloat?.classList?.contains('hide')) {
        letschatFloat?.classList?.add('hide');
      }
      const letsChatButton = document?.getElementsByClassName('bcFloat');
      if (letsChatButton?.length > 0 && !letsChatButton[0]?.classList?.contains('hide')) {
          letsChatButton[0]?.classList.add('hide');
        }
    }
    document.getElementsByClassName('webMessengerContainerButtonMode')[0]?.setAttribute('aria-label','Chat Window');
    }

  private getOktaCustomVerbiage() {
    return {
      'primaryauth.title': ' ',
      needhelp: ' ',
      'primaryauth.username.placeholder': 'User ID (Email Address)',
      // tslint:disable-next-line: no-hardcoded-credentials
      'primaryauth.password.placeholder': 'Password',
      remember: this.isHnPortal ? 'Remember User ID' : 'Remember Me',
      // tslint:disable-next-line: no-hardcoded-credentials
      'password.expired.title.generic': 'Your password has expired',
      'primaryauth.submit': this.isHnPortal ? 'Sign In' : 'Log In',

      // Select factors widgit
      'factor.sms': 'Text Message',
      'factor.sms.description': ' ',
      'factor.call': 'Voice Call',
      'factor.call.description': ' ',
      'factor.email': 'Email',
      'factor.email.description': ' ',
      // tslint:disable-next-line: max-line-length
      'enroll.choices.description': 'Your administrator has enabled two-step verification to add an additional layer of security when signing in to your account.',
      // tslint:disable-next-line: max-line-length
      'enroll.choices.description.generic': 'Your administrator has enabled two-step verification to add an additional layer of security when signing in to your account.',
      'enroll.choices.title': 'Set up Two-step Verification',
      'enroll.choices.setup': 'Setup Verification Option',
      'enroll.call.setup': ' Voice Call ',
      'enroll.sms.setup': 'Text Message',
      'email.enroll.title': 'Set up Email Verification',
      'enroll.choices.submit.finish': 'Done',
      'enroll.choices.optional': 'Configure additional factors, or click ‘Done’ to complete enrollment.',
      'enroll.choices.list.enrolled': ' ',
      'enroll.choices.list.optional': ' ',

      // MFA send code Page
      'mfa.backToFactors': 'Back to factor list',
      'mfa.challenge.verify': 'Verify',
      'mfa.sendCode': 'Send Code',
      'mfa.sent': 'Code Sent',
      'mfa.challenge.enterCode.placeholder': 'Code',
      'mfa.challenge.enterCode.tooltip': 'Code',
      'mfa.call': 'Call me',
      'email.button.send': 'Send Code',
      'rememberDevice.devicebased': 'Remember this Device',
      'errors.E0000004': 'Incorrect user ID and/or password. Please correct your information and try again.',
      'errors.E0000014': 'The password you entered does not meet the password complexity rules listed above.',
      'errors.E0000068': 'The code you entered is incorrect. Please try again.',
      'error.auth.lockedOut':
        'The user ID has been locked due to too many incorrect log-in attempts.' +
        ' Please contact your company’s MyAccount administrator to unlock the user account.',
      'mfa.factors.dropdown.title': 'Select a verification method',
      'email.mfa.title': 'Verify via Email',
    };
  }
  // TODO generic error msg
  private showError(message?: string): void {
    window.scrollTo(0, 0);
    this.loginMessage.type = 'error';
    this.loginMessage.level = 'global';
    this.loginMessage.message = message ? message : this.resourceBundle.loginFailedMsg;
    this.loginMessage.show = true;
  }

  // TODO generic error msg for account login flow
  private async changePasswordError() {
    const passwordTokenexpired = await this.activatedRoute.snapshot.queryParams['changepassword'];
    if (passwordTokenexpired && passwordTokenexpired === 'false') {
      window.scrollTo(0, 0);
      this.loginMessage.type = 'error';
      this.loginMessage.level = 'global';
      this.loginMessage.message = this.resourceBundle.genericErrorMsg;
      this.loginMessage.show = true;
    }
  }

  private async redirect(isPostLogin: boolean = false) {
    if (this.aguid) {
      const url: string = await this.service.proceedToAccountLogin(this.aguid);
      await this.proceedRedirect(url, isPostLogin);
      return;
    }
    const permissions = this.permissionService.getPermissions();
    try {
      const abondonedOnboardingData = await this.service.getAbondonedObData(
        this.cbHelper.getLoggedInUserDetails().profile.userToken.profileOwnerEmail,
        true
      );
      if (abondonedOnboardingData.accountNumber16 !== null && abondonedOnboardingData.stepName !== null) {
        await this.processAbondonedFlow(abondonedOnboardingData, isPostLogin);
        return;
      }
    } catch (e) { }
    return new Promise<void>(async (resolve, reject) => {
      await this.proceedRedirect(await this.getRedirectUrl(permissions), isPostLogin);
      resolve();
    });
  }

  private async processAbondonedFlow(abondonedOnboardingData: AbondonedDataResponse, isPostLogin: boolean) {
    this.onboardingService.accountNumber16 = abondonedOnboardingData.accountNumber16;
    this.sessionStorage.store(this.onboardingService.ICOMS_ACCOUNT_NUMBER, abondonedOnboardingData.accountNumber16);
    if (!this.cbHelper.getLoggedInUserDetails().profile.roles.includes(Role.PROFILE_HN_FLAG_ROLE)) {
      await this.onboardingService.identifyAccountType(true);
    } else {
      this.onboardingService.isHNProfile = true;
      this.sessionStorage.store(this.onboardingService.HN_PROFILE, this.onboardingService.isHNProfile);
    }
    await this.proceedRedirect(
      OnboardingStepsUrl[abondonedOnboardingData.stepName as keyof typeof OnboardingStepsUrl],
      isPostLogin
    );
  }

  private async getRedirectUrl(roles: NgxPermissionsObject) {
    const landingURL = '/home/landing';
    if (roles[Role.PROFILE_HN_FLAG_ROLE]) {
      return landingURL;
    }
    if (roles[Role.ACTIVATEPROFILE_ROLE]) {
      return landingURL;
    }
    if (roles[Role.ADC_SSO_ROLE]) {
      return landingURL; // replace to correct url
    }
    if (roles[Role.IS_CB_CORE_ROLE_ONLY]) {
      return this.generateCbCoreSSOUrl();
    }
    return landingURL;
  }

  private async proceedRedirect(url: string, isPostLogin: boolean) {
    console.log('***proceedRedirect***', url, isPostLogin);
    if (isPostLogin) {
      this.loginProfileClient.isTimedout = false;
    }
    window.localStorage.removeItem('MA_DEEPLINK_TARGET');
    if (url && url.substr(0, 4).toLowerCase() === 'http') {
      window.location.href = url;
      return;
    }
    this.router.navigateByUrl(url);
    return;
  }

  private async generateCbCoreSSOUrl() {
    try {
      const data = await this.ssoService.getSSOService(SSOPageName.CB_CORE);
      if (data.code === '0') {
        return this.config.getConfig('getCbCoreUrl');
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  private setCustomErrorMessage() {
    const now = new Date().getTime();
    const interval = setInterval(() => {
      const element = document.getElementsByClassName('infobox-error');
      if (element[0]?.children[1]?.innerHTML?.includes('Incorrect user ID and/or password')) {
        element[0].children[1].innerHTML =
          'Incorrect user ID and/or password. Please correct your information and try again. <br><br> Need help with your purchase? <a class=\'chat-trigger\' data-chat-text= \'I want to chat with a Cox Business Sales agent.\'>Contact a Sales Representative</a>';
        clearInterval(interval);
      }
      //by default to clear interval after 60 seconds
      if (new Date().getTime() - now > 60000) {
        clearInterval(interval);
      }
    });
  }
}
