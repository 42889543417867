import { Component, OnInit, OnDestroy } from '@angular/core';
import { AEMClientService } from '../../services/aemclient.service';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

  @Component({
    selector: 'app-autherror',
    template: `<div class="row margin-top-40">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <app-breadcrumb></app-breadcrumb>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 text-cente">
      <h2 class="text-center">{{ resourceBundle.pageHeader }} </h2>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
    {{ resourceBundle.authErrorDesc }}
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
      <a class="hyper-link pt-0" href="{{ resourceBundle.customerSupport }}" target="_blank"> {{ resourceBundle.customerSupportDesc }} </a>
    </div>
  </div>`
})
export class UnauthorizedComponent implements OnInit, OnDestroy {
  resourceBundle: any = {};
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private aemClient: AEMClientService) {

    this.aemClient.getErrorBundle('errorpageResourceBundle')
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
      (data: any) => {
        this.resourceBundle = data;
      },
      error => {
      }
    );
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }   
}
