import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  CBHelperService, CbHeaderService, CommonMessageService, DeviceDetectorService, StringUtilService
} from 'common-ui-lib';
import { AEMClientService, ResourceBundle } from 'common-ui-lib/lib/services/aemclient.service';
import { ValidateContactService } from '../validate-contact.service';
import {
  ContactInfo, ContactType, contacttypes, UserInput, validateOtp, UserType,
  ContactTypeAcc, ValidateMethodInput
} from '../validation-contact.model';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-validation-method',
  templateUrl: './validation-method.component.html',
  styleUrls: ['./validation-method.component.scss']
})
export class ValidationMethodComponent implements OnInit, OnDestroy {
  @Input() validateMethodInput: ValidateMethodInput;
  @Output() codeValidateSuccess = new EventEmitter<boolean>();
  public resourceBundle: ResourceBundle = {};
  public contactMethod: string;
  public mobileContactOption: string;
  public verifiedContactMethods: ContactInfo[] = [];
  public unVerifiedContactMethods: ContactInfo[] = [];
  public contactType = ContactType;
  public showMobileOptionsFlag: boolean;
  public isMobile: boolean;
  public emailFlow: boolean;
  public userType: UserType;
  public primaryContactUpdated: boolean;
  public contactMethodsList: ContactInfo[] = [];
  public contactTypeAcc = ContactTypeAcc;
  public isPageZoomedto400: boolean;
  public stepCount=1;

  constructor(private aemClient: AEMClientService, public validateContactService: ValidateContactService,
    private router: Router,  private deviceDetector: DeviceDetectorService,
    public stringUtilService: StringUtilService, public cbHelper: CBHelperService,
    public verificationSuccess: CommonMessageService,
    public cbHeaderService: CbHeaderService
  ) { }

  ngOnInit(): void {
    this.validateContactService.contentLoaded = false;
    this.aemClient.getRedesignBundle('validate-contact/validation-method')
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
          if (isEmpty(this.validateMethodInput)) {
            this.validateContactService.validateUserInfoContact = false;
            this.getContactInfoDetails();
          } else {
            this.unVerifiedContactMethods = this.validateMethodInput.unVerifiedContactMethods;
            this.contactMethod = this.validateMethodInput.chosenContactMethod;
            this.validateContactService.userGuid = this.validateMethodInput.userGuid;
            this.validateContactService.userId = this.validateMethodInput.userId;
            if (this.contactMethod === ContactType.PHONE) {
              this.mobileContactOption = this.resourceBundle.textMessageGrp;
              this.showMobileOptionsFlag = true;
            }
            this.validateContactService.contentLoaded = true;
            this.validateContactService.validateUserInfoContact = true;
          }
        }
      );
    this.isMobile = this.deviceDetector.isMobile();
    this.isPageZoomedto400 = this.deviceDetector.isPageZoomedto400();
    this.emailFlow = sessionStorage.getItem('validateUser') ? true : false;
  }

  public updateValidationShown(getUserRes) {
    const loginContactInfo: ContactInfo = {
      id: getUserRes.login.id,
      contactType: getUserRes.login.contactType,
      contactValue: getUserRes.login.contactValue,
      isMobileNumber: false,
      preferred: true,

    };
    const payLoadInput: UserInput = {
      firstName: getUserRes.firstName,
      lastName: getUserRes.lastName,
      userType: this.userType,
      isValidationShown: true,
      isContactUpdateReq: this.emailFlow,
      login: this.emailFlow ? loginContactInfo : null
    };
    this.validateContactService.updateValidationShown(this.validateContactService.loggedInUserGuid, payLoadInput)
      .subscribe((results) => {
        if (results.data && results.data.updateUser.guid) {
          this.primaryContactUpdated = true;
          if (this.emailFlow && this.primaryContactUpdated) {
            this.contactMethodsList[0].isVerified = true;
            const altEmailIndex = this.contactMethodsList.findIndex(
              contact => contact.contactType === ContactType.ALTEMAIL);
            if(this.contactMethodsList[0]?.contactValue === this.contactMethodsList[altEmailIndex]?.contactValue) {
              this.contactMethodsList[altEmailIndex].isVerified = true;
            }
          }
          this.filterContactOptions();
        }
        if (results.errors) {
          this.filterContactOptions();
          this.validateContactService.commonMsg
            .showMessage(this.resourceBundle.genericErrorMessage, 'error', 'validationMethodMsg');
        }
      });
    sessionStorage.removeItem('validateUser');
  }

  public getUserType(userDetails) {
    if (userDetails.isEndUser) {
      this.userType = UserType.EU;
    } else if (userDetails.isProfileAdmin) {
      this.userType = UserType.PA;
    } else if (userDetails.isProfileOwner) {
      this.userType = UserType.PO;
    } else if (userDetails.isSPA) {
      this.userType = UserType.SPA;
    } else if (userDetails.isSPO) {
      this.userType = UserType.SPO;
    }
  }

  /**
   * Fetch Users Contact Otpions on page load
   */
  public getContactInfoDetails() {
    let getResponseDataFetched = false;
    this.cbHeaderService.getUserResponse.subscribe((results) => {
      if (results?.data && results?.data?.getUser && !getResponseDataFetched) {
        getResponseDataFetched = true;
        this.contactMethodsList = [];
        this.getUserType(results.data.getUser);
        this.contactMethodsList.push(results.data.getUser?.login);
        this.contactMethodsList = this.contactMethodsList.concat(results.data.getUser?.contacts.slice().reverse());
        if (results?.data?.getUser?.isValidationShown === false) {
          this.updateValidationShown(results.data.getUser);
        } else {
          this.filterContactOptions();
        }
        this.validateContactService.loggedInUserId = results.data?.getUser?.id;
      }
      if (results?.errors) {
        this.validateContactService.contentLoaded = true;
        this.validateContactService.commonMsg
          .showMessage(this.resourceBundle.genericErrorMessage, 'error', 'validationMethodMsg');
      }
    });
  }


  public filterContactOptions() {
    this.verifiedContactMethods = this.contactMethodsList.filter((contact) => Boolean(contact.isVerified));
    this.unVerifiedContactMethods = this.contactMethodsList.filter((contact) => Boolean(!contact.isVerified));
    this.contactMethod = this.unVerifiedContactMethods[0]?.contactType;
    this.onClickOfContactMethodType();
    this.validateContactService.contentLoaded = true;
    if (this.unVerifiedContactMethods?.length === 0) {
      this.verificationSuccess.showMessage(this.resourceBundle.verificationsuccess,
        'success', 'verificationsuccess');
      setTimeout(() => {
        this.router.navigateByUrl(this.resourceBundle.dashboard);
      }, 5000);
    }
  }

  /**
   * Handling reset of options on change of contact method
   */
  public onClickOfContactMethodType() {
    this.showMobileOptionsFlag = false;
    if (this.contactMethod === ContactType.PHONE) {
      this.showMobileOptionsFlag = true;
      this.mobileContactOption = this.resourceBundle.textMessageGrp;
    }
  }

  /**
   * on click of send otp button navigate to verification page
   */
  public onClickofSendverificationcode() {
    if (this.contactMethod === ContactType.PHONE) {
      if (this.mobileContactOption === this.resourceBundle.phoneCallGrp) {
        this.validateContactService.entityType = contacttypes.phone;
      } else {
        this.validateContactService.entityType = contacttypes.sms;
      }
    } else {
      this.validateContactService.entityType = contacttypes.Email;
    }
    this.unVerifiedContactMethods.forEach(verification => {
      if (verification.contactType === this.contactMethod) {
        this.validateContactService.validatecontact = verification;
        return;
      }
    });
    const reqs: validateOtp = {
      guid: this.validateContactService.validateUserInfoContact ? this.validateMethodInput.userGuid
        : this.validateContactService.loggedInUserGuid,
      entityType: this.validateContactService.entityType,
      entityValue: this.validateContactService.validatecontact.contactValue
    };
    this.validateContactService.sendotp(reqs)
      .pipe(finalize(() => {
        this.validateContactService.commonMsg.displayMessage.id = null;
        this.validateContactService.commonMsg.hideMessage();
        if (!this.validateContactService?.validateUserInfoContact) {
          this.router.navigateByUrl(this.resourceBundle.validatecodeurl);
        } else {
          this.stepCount = 2;
          this.validateContactService.contentLoaded = false;
        }
      }))
      .subscribe();
  }

  /**
   * Close Validate Contact Method Modal
   */
  public close() {
    this.validateContactService.close();
  }

  public codeValidated() {
    this.codeValidateSuccess.emit(true);
  }

  ngOnDestroy(): void {
    this.validateContactService.commonMsg?.hideMessage();
  }

}
