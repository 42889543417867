/* eslint-disable max-len */

import { inject, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TealiumUtagService } from '../tealium/utag.service';
import { NewRelicService } from 'common-ui-lib';

@Injectable()
export class CustomModal extends NgbModal{
    public modalRef: any;
    private modalTitle: string | any;
    private tealium = inject(TealiumUtagService);
    private newRelicService = inject(NewRelicService);
    private mutationObserver: MutationObserver;
    private newTitle: string | any;

    open(content: any, options?: NgbModalOptions): NgbModalRef {
        this.modalRef =  super.open(content, options);

        // The observable that emits when modal animation was finished.
        this.modalRef.shown.subscribe({
        complete: () => {

            //Introducting setTimeout for loading time while appending data(Component Instance) to the modal
            setTimeout(() => {
                const headerElement = this.fetchModalRef().querySelector('.modalheader');
                if(headerElement) {

                    //Trying to fetch the modal title in DOM from parent node containing class modalheader
                    //h4 tag and .h4 class will be removed in coming sprint
                    this.modalTitle = (this.fetchModalRef().querySelector('.modalheader .modal-title, .modalheader h4, .modalheader .h4')?.textContent);
                    this.modalTitle = this.modalTitle?.trim().toLowerCase().replaceAll(' ','-');
                    if(this.modalTitle) {
                        this.trackModal();
                    }
                    this.fetchModalRef().addEventListener('click', this.handleContentChange.bind(this));
                    //Detecting dom changes incase if modal heading has been changed dynamically in the same modal
                    this.mutationObserver = new MutationObserver((mutations)=> {
                        if(mutations) {
                            //Trying to fetch the title element again in DOM after changes detected in the parent node containing class modalheader
                            //h4 tag and .h4 class will be removed in coming sprint
                            this.newTitle  = (this.fetchModalRef().querySelector('.modalheader .h4, .modalheader h4, .modalheader .modal-title')?.textContent);
                            this.newTitle = this.newTitle?.trim().toLowerCase().replaceAll(' ','-');
                            //Handled dynamically changing modal title
                            if(this.newTitle && (this.modalTitle !== this.newTitle || !this.modalTitle)) {
                                this.modalTitle = this.newTitle;
                                this.trackModal();
                            } else if (!this.newTitle) {
                                this.trackWithDefaultHeader();
                            }
                        };
                    });
                    this.mutationObserver.observe(headerElement, { attributes: true, childList: true, subtree: true, characterData: true});
                } else {
                    this.trackWithDefaultHeader();
                }
            }, 800);
        }});

        //The observable that emits when modal window is closed
        this.modalRef.hidden.subscribe(data => {
            this.mutationObserver?.disconnect();
            this.fetchModalRef()?.removeEventListener('click', this.handleContentChange.bind(this));
        });
        return this.modalRef;
    }

    fetchModalRef() {
        const nativeElement  = (this.modalRef as any)?._windowCmptRef?.instance?._dialogEl?.nativeElement;
        return nativeElement; //holds the modal ref DOM object
    }

    //When modal contains only body without header then passing default modal header
    trackWithDefaultHeader() {
        this.modalTitle = 'modal-header';
        this.trackModal();
    }

    public handleContentChange(event: any) {
        setTimeout(()=> {
            this.newTitle  = (this.fetchModalRef()?.querySelector('.modalheader .h4, .modalheader h4, .modalheader .modal-title')?.textContent);
            this.newTitle = this.newTitle?.trim()?.toLowerCase()?.replaceAll(' ','-');
                if(this.newTitle && this.newTitle!== this.modalTitle) {
                    this.modalTitle = this.newTitle;
                    this.trackModal();
                }
        }, 1000);
    }

        trackModal() {
        let pageName = window.utag_data.pageName?.split(':');
        pageName = pageName?.slice(3).join(':');   
        //comment to revert CBMA7-3194     
        this.tealium.trackModalTrigger(pageName+':'+this.modalTitle);
        //uncomment to revert CBMA7-3194
        // const modalName = 'cox:cb:modal:'+pageName+':'+this.modalTitle;
        // this.tealium.trackModalTrigger(modalName);
        // this.newRelicService.setModalInteraction(modalName);
    }
}
