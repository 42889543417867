import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService, AppConfig, CBHelperService, CommonMessageService, DigitalProfileSharedService,
  LocalStorageKeys, ResourceBundle,
  Role, SSOService, TealiumUtagService, UserType
} from 'common-ui-lib';
import { SSOPageName } from 'common-ui-lib/lib/constants/ssopagename.model';
import { DeviceDetectorService } from 'common-ui-lib';
import { BillingHomeService } from 'redesign/billing-home/billing-home.service';
import { MalpService } from 'redesign/malp/malp.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FeatureName } from
  'common-ui-lib/lib/shared/redesign/sublanding-page-description/sublanding-page-description.model';
import { MyaccountHomeService } from 'home/myaccounthome.service';
import { LocalStorageService } from 'ngx-localstorage';
import { ServiceName } from './servicename.model';

@Component({
  selector: 'app-addupgradeservice',
  templateUrl: './addupgradeservice.component.html',
  styleUrls: ['./addupgradeservice.component.scss']
})
export class AddupgradeserviceComponent implements OnInit, OnDestroy {
  @Input() featureName: FeatureName = null;
  @ViewChild('addServiceModal') public addServiceModal: NgbModalRef;
  @ViewChild('whichService') public whichService: NgbModalRef;
  @ViewChild('letsConnect') public letsConnect: NgbModalRef;
  @Input() accountInfo = [];
  public showCbcc = true;
  public isMultiAccount: boolean;
  public sublandingView: boolean;
  public addServiceModalRef: NgbModalRef;
  public whichServiceRef: NgbModalRef;
  public letsConnectRef: NgbModalRef;
  public authorizedUser = true;
  resourceBundle: ResourceBundle = {};
  public availableServiceUpgrades = [];
  public allServices = [];
  public availableAddonservices = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public objectselection = null;
  public accountAddress: string;
  public pageTitle: string;
  public backTitle: string;
  public disUpgradeFlow =false;
  guid: string;
  selectedService: string;
  isAuthorizedUser: boolean;
  serviceType: string;
  accountNumber12: string;
  public hasOrderReplacementRole: boolean;
  public isEU: boolean;
  public locationDisableBtn = true;
  public addNewLocDisableBtn = true;
  public serviceDisableBtn = true;
  public chatDisable = false;
  public showAddNetAssurance: boolean;
  public oliverText = '';
  public onContinue: boolean;
  public myAdminWarnMsg: string;
  public addServicemodalTitle: string;
  public houseNumber: string;
  public street: string;
  public isFeatureUpgradeVisible: boolean;
  private onDestroy$ = new Subject<boolean>();
  constructor(public deviceService: DeviceDetectorService, public billingService: BillingHomeService,
    public cbHelperService: CBHelperService,
    private aemClient: AEMClientService,
    public service: MalpService,
    private config: AppConfig,
    private ssoService: SSOService,
    private tealium: TealiumUtagService,
    private modalService: NgbModal,
    private router: Router,
    public commonMessageService: CommonMessageService,
    private myaccountHomeService: MyaccountHomeService,
    public activeModal: NgbActiveModal,
    public dashboardSharedServices: DigitalProfileSharedService,
    private _storageService: LocalStorageService) {
    this.aemClient.getSharedBundle('services')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
          this.allServices = this.resourceBundle.featureDescription.slice(0, 4);
          this.myAdminWarnMsg = this.resourceBundle.myAdminAlert;
        },
        (error) => {
        },
      );
  }

  ngOnInit(): void {
    this.showCbcc = !this.service.profileHasCbcc;
    this.hasOrderReplacementRole = this.cbHelperService.getLoggedInUserDetails().
      profile.roles.includes(Role.BUSINESS_TV_VIDEO_RPLCMNT_ROLE) ? true : false;
    this.showAddNetAssurance = this.myaccountHomeService.getProfile().roles.includes('nar');
    this.isEU = this.myaccountHomeService.getProfile().userToken.userType === UserType.END_USER;
    if (this._storageService.get(LocalStorageKeys.SelectedAccount, 'cbma')) {
      this.locationDisableBtn = false;
      this.addNewLocDisableBtn = false;
    }
    if(this.featureName && this.cbHelperService.isMyAdmin()) {
      this.disUpgradeFlow= true;
    }
    this.isFeatureUpgradeVisible = this.dashboardSharedServices.isAddOnAvailable(this.featureName);
  }

  public setModalTitle(): string{
      if(!this.isMultiAccount && this.featureName){
        return this.resourceBundle.addUpgardeHeaderMultiple;
      } else if(this.isMultiAccount && this.featureName){
        return this.resourceBundle.upgradeWhichLink;
      }else if(!this.isMultiAccount && !this.featureName){
        return this.resourceBundle.addUpgardeHeaderSingle;
      }else if(this.isMultiAccount && !this.featureName){
        return this.resourceBundle.addUpgardeHeaderMultiple;
      }
  }

  // Query to get authorization value along with services list
  getFeatureDetails() {
    this.locationDisableBtn = true;
    this.service.getServiceDetails(this.guid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((results: any) => {
        if (results?.data) {
          this.locationDisableBtn = false;
          this.isAuthorizedUser = results.data?.getAccount?.serviceFeature?.accountAuthUser;
          this.objectselection = results.data?.getAccount?.serviceFeature?.cbServices;
          this.availableServiceUpgrades.length = this.availableAddonservices.length = 0;
          for (const feature in this.objectselection) {
            if (this.objectselection.hasOwnProperty(feature)) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              this.resourceBundle.featureDescription.forEach((item: any) => {
                if (item.value === feature && !this.objectselection[feature]) {
                  this.availableAddonservices.push(item);
                }
                if (item.value === feature && this.objectselection[feature]) {
                  this.availableServiceUpgrades.push(JSON.parse(JSON.stringify(item)));
                }
              });
            }
          }
        }
        if(null != this.featureName){
           this.isFeatureName();
        }
      });
  }

  /**add/upgrade service */
  public addService() {
    this.oliverText = '';
    this.isMultiAccount = this.cbHelperService.getLoggedInUserDetails().profile.userToken.multiAccount;
    this.addServicemodalTitle = this.setModalTitle();
    if (this.service.isEUWithoutPermissions) {
      this.chatNow();
    } else {
      this.addServiceModalRef = this.modalService.open(this.addServiceModal,
        {
          backdrop: 'static',
          windowClass: 'redesign-modal-md',
        });

      this.addServiceModalRef.result.then(() => {
        this.focusServiceLink();
      });
    }
  }

  // Event on account selection from account widget
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedAccount(event: any) {
    if (!this.isMultiAccount) {
      this.accountAddress = event.accountExternalAttrs.serviceAddress;
      this.street=event.accountExternalAttrs.serviceAddress?.street.trim();
      this.houseNumber=event.accountExternalAttrs.serviceAddress?.houseNumber;
      this.houseNumber=`${this.houseNumber.substring(0,this.houseNumber.indexOf(' '))}. ${this.houseNumber.substring(this.houseNumber.indexOf(' ')+1)}`;
      this.guid = event.guid;
      this.accountNumber12 = event.accountNumber12;
    }
    if (this.isMultiAccount) {
      this.guid = event.guid;
      this.accountNumber12 = event.accountNumber12;
    }
    this.locationDisableBtn = false;
    this.addNewLocDisableBtn = false;
  }

  // Open let's connect modal
  public chatNow() {
    this.letsConnectRef = this.modalService.open(this.letsConnect,
      {
        backdrop: 'static',
        windowClass: 'redesign-modal-md',
        ariaLabelledBy: this.resourceBundle.connectHeader
      });

    this.letsConnectRef.result.then(() => {
      this.oliverText = '';
      this.focusServiceLink();
    });
  }

  //On click of service tile
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onLinkClick(service: any, event, type) {
    this.setOliverText(service.featureName, type);
    this.resetCardSelection();
    this.selectedService = service.featureName;
    this.serviceType = type;
    event.currentTarget.classList.add('cardSelected');
    event.currentTarget.ariaLabel = this.selectedService + ' button selected';
    if((this.cbHelperService.isMyAdmin() && this.hasOrderReplacementRole
      && this.isAuthorizedUser && this.selectedService === 'Replacement Parts') ||
      this.cbHelperService.isMyAdmin() && !this.onContinue) {
      this.serviceDisableBtn = true;
    } else {
      this.serviceDisableBtn = false;
    }
  }

  //On click of continue after selection of service tile
  async serviceSelectionContinue() {
    if (this.onContinue) {
      if (this.selectedService === 'Net Assurance') {
        this.addNetAssurance();
        return;
      }
      let data;
      let product;
      if (this.isAuthorizedUser && !this.cbHelperService.isMyAdmin()) {
        if (this.selectedService === ServiceName.VOICE || this.selectedService === ServiceName.INTERNET
          || this.selectedService === ServiceName.REPLACEMENT_PARTS ||
          this.selectedService === ServiceName.COMPLETE_CARE || this.selectedService === ServiceName.TV) {
            switch (this.selectedService) {
              case 'Voice':
                product = SSOPageName.VOICE_CBFORCE;
                break;
              case 'Internet':
                product = SSOPageName.INTERNET_CBFORCE;
                break;
              case 'Replacement Parts':
                product = SSOPageName.VIDEOPART_CBFORCE;
                break;
              case 'Complete Care':
                product = SSOPageName.CBCC_CBFORCE;
                break;
              case 'TV':
                product = SSOPageName.VIDEO_CBFORCE;
                break;
            }
          try {
            data = await this.ssoService.cbForceSSO(product,
              this.serviceType, this.accountNumber12);
          } catch (error) {
          }
        } else {
          this.chatNow();
        }
      } else if (!this.isAuthorizedUser && !this.cbHelperService.isMyAdmin()) {
        this.chatNow();
      } else {
        switch (this.selectedService) {
          case 'Voice':
            window.open(this.config.getConfig('voiceServiceStaticURL'), '_blank');
            break;
          case 'Internet':
            window.open(this.config.getConfig('internetServiceStaticURL'), '_blank');
            break;
          case 'Networking':
            window.open(this.config.getConfig('networkingServiceStaticURL'), '_blank');
            break;
          case 'TV':
            window.open(this.config.getConfig('tvServiceStaticURL'), '_blank');
            break;
          case 'Security':
            window.open(this.config.getConfig('securityServiceStaticURL'), '_blank');
            break;
          case 'Replacement Parts':
            this.chatDisable = !this.isAuthorizedUser;
            this.chatNow();
            break;
        }
      }
    } else {
      let product;
      let data;
      if (!this.cbHelperService.isMyAdmin()) {
        if (this.selectedService === 'Voice' || this.selectedService === 'Internet'
          || this.selectedService === 'TV') {
            switch (this.selectedService) {
              case 'Voice':
                product = SSOPageName.VOICE_CBFORCE;
                break;
              case 'Internet':
                product = SSOPageName.INTERNET_CBFORCE;
                break;
              case 'TV':
                product = SSOPageName.VIDEOPART_CBFORCE;
                break;
            }
          try {
            data = await this.cbForceSSOForAddLoc(product, this.serviceType, this.accountNumber12);
          } catch (error) {

          }
        } else {
          window.open(this.config.getConfig('contactSalesURL'), '_blank');
        }
      }
    }
  }

  //Reset service tile selection
  public resetCardSelection() {
    if (document.getElementsByClassName('cardSelected').length > 0) {
      document.getElementsByClassName('cardSelected')[0].classList.remove('cardSelected');
    }
  }

  //Back to add service modal on click of link
  public backToLocation() {
    this.resetCardSelection();
    this.availableServiceUpgrades.length = this.availableAddonservices.length = 0;
    this.addServiceModalRef = this.modalService.open(this.addServiceModal,
      {
        backdrop: 'static',
        windowClass: 'redesign-modal-md',
      });
    this.addServiceModalRef.result.then(() => {
      this.focusServiceLink();
    });
  }

  //Add new location click
  public goToAddLocationURL() {
    if (this.cbHelperService.isMyAdmin() || !this.isAuthorizedUser) {
      if (this.addServiceModalRef) {
        this.addServiceModalRef.close();
      }
      this.chatDisable = (!this.isAuthorizedUser && this.cbHelperService.isMyAdmin()) ? true : false;
      this.setOliverText('location');
      this.chatNow();
    } else {
      window.open(this.config.getConfig('addNewLocationURL'), '_blank');
    }
  }

  //Go to contact sales url
  public goToContactSalesURL() {
    window.open(this.config.getConfig('contactSalesURL'), '_blank');
  }

  //Trigger cbforce SSP buyflow
  public async cbForceSSOForAddLoc(pageName: string, buyFlow: string, accountNumber?: string) {
    if (this, this.cbHelperService.isCbForceMaintenance) {
      window.open(this.config.getConfig('SSO')['PI9']['getCbForceMaintenanceUrl'], '_blank');
    } else {
      const guid = await this.cbHelperService.getGuid();
      const data = await this.ssoService.getSSOService(pageName, accountNumber);
      if (data.code === '0') {
        window.open(this.config.getConfig('addNewLocationURL'), '_blank');
      } else {
        throw new Error('samlpersistence_fail');
      }
    }
  }

  public isFeatureName(){
    if (!this.cbHelperService.isMyAdmin() && this.isAuthorizedUser) {
      switch (this.featureName) {
        case 'INTERNET': 
        this.callSSO(SSOPageName.INTERNET_CBFORCE);
        break;
      case 'TV':
        this.callSSO(SSOPageName.VIDEOPART_CBFORCE);
        break;
        case 'VOICE':
          this.callSSO(SSOPageName.VOICE_CBFORCE);
          break;
        default: this.chatNow();
      }
    } else if ((this.cbHelperService.isMyAdmin())) {
      switch (this.featureName) {
        case 'VOICE': window.open(this.config.getConfig('voiceServiceStaticURL'), '_blank');
          break;
        case 'INTERNET': window.open(this.config.getConfig('internetServiceStaticURL'), '_blank');
          break;
        case 'TV': window.open(this.config.getConfig('tvServiceStaticURL'), '_blank');
          break;
        case 'NETWORKING':
          window.open(this.config.getConfig('networkingServiceStaticURL'), '_blank');
          break;
        case 'SECURITY':
          window.open(this.config.getConfig('securityServiceStaticURL'), '_blank');
          break;
        default: this.chatNow();
          break;
      }
    } else {
      this.chatNow();
    }
  }

  //trigger buyflow or add new location flow
  public callChatorSSO(onContinue: boolean) {
    this.onContinue = onContinue;
    let data; let product;
    this.pageTitle = (this.onContinue) ? this.resourceBundle.whichServiceHeader : this.resourceBundle.addNewLocation;
    this.backTitle = (!this.onContinue && this.cbHelperService.isMyAdmin) ?
    this.resourceBundle.cancel : this.resourceBundle.backToAccounts;
    this.selectedService = this.featureName;
    if (onContinue) {
      this.objectselection = null;
      this.getFeatureDetails();
    }
    if (this.featureName && !this.onContinue && !this.cbHelperService.isMyAdmin()) {
          if (this.selectedService === 'VOICE' || this.selectedService === 'INTERNET'
            || this.selectedService === 'TV') {
              switch (this.featureName) {
                case 'INTERNET': product = SSOPageName.INTERNET_CBFORCE;
                  this.callSSOAddLoc(product);
                  break;
                case 'TV': product = SSOPageName.VIDEOPART_CBFORCE;
                  this.callSSOAddLoc(product);
                  break;
                case 'VOICE': product = SSOPageName.VOICE_CBFORCE;
                  this.callSSOAddLoc(product);
                  break;
            }
          } else {
            window.open(this.config.getConfig('contactSalesURL'), '_blank');
          }
      this.addServiceModalRef.close();
    } else if(!this.featureName){
      this.whichServiceRef = this.modalService.open(this.whichService,
        {
          backdrop: 'static',
          windowClass: 'redesign-modal-sm',
          ariaLabelledBy: this.resourceBundle.whichServiceHeader
        });

      this.whichServiceRef.result.then(() => {
        this.focusServiceLink();
      });
    }
  }

  //Video replacemenet parts title visibility check
  public videoRplcmntVisibility() {
    if (this.hasOrderReplacementRole && !this.isEU) {
      return true;
    } else {
      return false;
    }
  }

  //order replacement parts trigger
  public orderReplacementsParts(type: string) {
    this.serviceType = type;
    this.callSSO(SSOPageName.VIDEOPART_CBFORCE);
  }

  //Reset continue button
  public resetModal() {
    this.serviceDisableBtn = true;
  }

  public triggerTealium(eventName: string) {
    setTimeout(() => {
      this.activeModal.close();
    }, 10000);
    this.tealium.button(eventName, eventName);
  }

  public getFeatureDescription(featureName: string) {
    return this.resourceBundle.featureDescription.find(feature => feature.value === featureName);
  }

  ngOnDestroy() {
    this.resetCardSelection();
    this.availableServiceUpgrades.length = this.availableAddonservices.length = 0;
  }

  //Focus on add or upgrade link
  private focusServiceLink() {
    if (this.featureName === null) {
      document.getElementById('addService').focus();
    } else {
      document.getElementById('upgradeService').focus();
    }
  }

  //Add net assurance , trigger buyflow based on authorisation
  private async addNetAssurance() {
    if (this.isAuthorizedUser) {
      await this.ssoService.cbForceSSO(SSOPageName.NETASSURANCE_CBFORCE, 'Upgrade', this.accountNumber12);
    }
    if (!this.isAuthorizedUser) {
      this.setOliverText('Net Assurance');
      this.chatNow();
    }
  }

  //Call SSO
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async callSSO(product: any) {
    let data;
    try {
      data = await this.ssoService.cbForceSSO(product, this.serviceType, this.accountNumber12);
    } catch (error) {
    }
  }

  // Call SSO for add location in upgrade flow
  private async callSSOAddLoc(product: SSOPageName) {
    let data;
    try {
      data = await this.cbForceSSOForAddLoc(product, 'Upgrade', this.accountNumber12);
    } catch (error) {
    }
  }

  //Set oliver data chat text based on service
  private setOliverText(service: string, type?: string) {
    if (service === 'location') {
      this.oliverText = this.resourceBundle.locationOliverText;
    } else if (service === ServiceName.NET_ASSURANCE) {
      this.oliverText = this.resourceBundle.netAssuranceOliverText;
    } else if (service === ServiceName.REPLACEMENT_PARTS) {
      this.oliverText = this.resourceBundle.replacementPartsOliverText;
    }  else if (service === ServiceName.COMPLETE_CARE) {
      this.oliverText = this.resourceBundle.cbccOliverText;
    }  else if (service === ServiceName.VOICE) {
      if (type === 'Upgrade') {
        this.oliverText = this.resourceBundle.voiceUpgradeOliverText;
      } else {
        this.oliverText = this.resourceBundle.voiceAddOliverText;
      }
    } else if (service === ServiceName.INTERNET) {
      if (type === 'Upgrade') {
        this.oliverText = this.resourceBundle.internetUpgradeOliverText;
      } else {
        this.oliverText = this.resourceBundle.internetAddOliverText;
      }
    } else if (service === ServiceName.NETWORKING) {
      if (type === 'Upgrade') {
        this.oliverText = this.resourceBundle.networkingUpgradeOliverText;
      } else {
        this.oliverText = this.resourceBundle.networkingAddOliverText;
      }
    } else if (service === ServiceName.TV) {
      if (type === 'Upgrade') {
        this.oliverText = this.resourceBundle.tvUpgradeOliverText;
      } else {
        this.oliverText = this.resourceBundle.tvAddOliverText;
      }
    } else if (service === ServiceName.SECURITY) {
      if (type === 'Upgrade') {
        this.oliverText = this.resourceBundle.securityUpgradeOliverText;
      } else {
        this.oliverText = this.resourceBundle.securityAddOliverText;
      }
    }
    this.oliverText = this.resourceBundle.khorosOliverChatText;
  }
}
