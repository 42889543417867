import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { AEMClientService, ResourceBundle,LocalStorageKeys, CBHelperService} from 'common-ui-lib';
import { finalize } from 'rxjs/operators';
import { AccountInfo, Address } from '../pageheader.model';
import { PageHeaderService } from '../pageheader.service';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-redesign-accountwidget',
  templateUrl: './redesign-accountwidget.component.html',
  styleUrls: ['./redesign-accountwidget.component.scss']
})
export class RedesignAccountwidgetComponent implements OnInit {
  @Input() featureName: string;
  @Input() hideWidgetTitle: boolean;
  @Input() disabledAcc = false;
  @Input() cbccmodalwidth: boolean;
  @Output() selectedAccountEvent = new EventEmitter<AccountInfo>();
  @Output() errorAlertEvent = new EventEmitter<boolean>();
  @Output() accountListEvent = new EventEmitter<AccountInfo[]>();
  @Output() accountWidgetLoading = new EventEmitter<boolean>();
  @Input() accountList: AccountInfo[] = [];
  public searchText: string;
  public selectedAccount: AccountInfo;
  public showDropdown = false;
  public accountListClone: AccountInfo[] = [];
  public contentLoaded = false;
  public accountsListToShow: AccountInfo[] = [];
  public searchFlag = false;
  public resourceBundle: ResourceBundle = {};
  private accountsListToShowIndex: number;

  constructor(
    private router: Router,
    private pageHeaderService: PageHeaderService,
    private _storageService: LocalStorageService,
    public aemClient: AEMClientService,
    public cbHelper: CBHelperService,
    public deviceService: DeviceDetectorService
  ) { }

  // Close dropdown on click of outside
  @HostListener('window:click', ['$event'])
  public onMouseClick(event: MouseEvent) {
    if (
      (document.getElementById('accountBox') &&
        !this.checkEvent(document.querySelectorAll('#accountBox'), event.target)) &&
      (document.getElementById('dropdownContainer') &&
        !this.checkEvent(document.querySelectorAll('#dropdownContainer'), event.target))
    ) {
      this.showDropdown = false;
      this.resetDropdown();
    }
  }

  ngOnInit(): void {
    this.accountWidgetLoading.emit(true);
    if (this.accountList.length === 0) {
      this.getAccounts();
    } else {
      this.createAccountWidget(this.accountList);
      this.contentLoaded = true;
      this.accountWidgetLoading.emit(false);
    }
    this.aemClient.getRedesignBundle('account-widget/account-widget')
      .subscribe(
        (data: any) => {
          this.resourceBundle = data;
        }
      );
  }

  public onClickOfAccountWidget() {
    if (this.accountListClone.length > 1 && !this.disabledAcc) {
      this.showDropdown = !this.showDropdown;
      if(!this.showDropdown) {
        this.resetDropdown();
      }
    }
  }

  public onAccountSelection(obj: AccountInfo) {
    const selectedAccountGuid = this._storageService.get(LocalStorageKeys.SelectedAccount, 'cbma');

    if (selectedAccountGuid) {
      if (selectedAccountGuid !== obj.guid) {
        this._storageService.set(LocalStorageKeys.SelectedAccount, obj.guid);
        this.selectedAccount = obj;
      }
    } else {
      this._storageService.set(LocalStorageKeys.SelectedAccount, obj.guid);
      this.selectedAccount = obj;
    }
    this.selectedAccount = { ...this.selectedAccount, accountsCount: this.accountList.length };
    this.selectedAccountEvent.emit(this.selectedAccount);
    this.showDropdown = false;
    this.resetDropdown();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 50 &&
      this.accountsListToShowIndex < this.accountList.length) {
      this.accountsListToShow = this.accountsListToShow
      .concat(this.accountList.slice(this.accountsListToShowIndex, this.accountsListToShowIndex + 5));
      this.accountsListToShowIndex += 5;
    }
  }

  public onSearch() {
    this.searchFlag = true;
    this.accountList = this.accountListClone.filter((obj) => {
      const address = obj.accountExternalAttrs.serviceAddress;
      return obj.alias.toLowerCase().includes(this.searchText.toLowerCase()) ||
        obj.accountName.toLowerCase().includes(this.searchText.toLowerCase()) ||
        obj.accountNumber.toLowerCase().includes(this.searchText.toLowerCase()) ||
        obj.siteId.toLowerCase().includes(this.searchText.toLowerCase()) ||
        (address.fullAddress && address.fullAddress.toLowerCase().includes(this.searchText.toLowerCase()));
    });
    this.resetDropdown();
  }

  public onClickOfClearSearch() {
    this.searchText = '';
    this.searchFlag = false;
    this.accountList = [...this.accountListClone];
    this.resetDropdown();
  }

  public navigateToAddAccount() {
    this.router.navigate(['/admin/account/addaccount']);
  }

  private getAccounts() {
    this.pageHeaderService
      .getAccounts(this.featureName || 'ALL')
      .pipe(finalize(() => {
        this.contentLoaded = true;
        this.accountWidgetLoading.emit(false);
      }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((results: any) => {
        if (results.data) {
          if(results?.data?.userAccountsByFeature?.length > 0) {
            this.accountList = results.data.userAccountsByFeature;
          } else {
            this.accountList = [];
          }
          this.createAccountWidget(this.accountList);
          this.resetDropdown();
        }
        if (results.errors) {
          this.errorAlertEvent.emit(true);
        }
      });
  }

  private createAccountWidget(accountList: AccountInfo[]) {
    this.accountList = accountList = accountList.map((acc) => ({
        ...acc,
        accountExternalAttrs: {
          ...acc.accountExternalAttrs,
          serviceAddress: {
            ...acc.accountExternalAttrs.serviceAddress,
            fullAddress: this.formFullAddress(acc.accountExternalAttrs.serviceAddress)
          }
        }
      }));
    this.accountListEvent.emit(accountList);
    this.resetDropdown();
    this.accountListClone = [...accountList];
    if (this.accountListClone.length > 1) {
      const selectedAccountGuid = this._storageService.get(LocalStorageKeys.SelectedAccount, 'cbma');
      this.selectedAccount = this.accountListClone.filter((obj) => selectedAccountGuid === obj.guid)[0];
      if (this.selectedAccount) {
        this.selectedAccount = { ...this.selectedAccount, accountsCount: accountList.length };
        this.selectedAccountEvent.emit(this.selectedAccount);
      }
    } else if (this.accountListClone.length === 1) {
      this.selectedAccount = this.accountListClone[0];
      this._storageService.set(LocalStorageKeys.SelectedAccount, this.accountListClone[0].guid);
      this.selectedAccount = { ...this.selectedAccount, accountsCount: accountList.length };
      this.selectedAccountEvent.emit(this.selectedAccount);
    }
  }

  private checkEvent(listOfElements, targetElement) {
    let flag = false;
    listOfElements?.forEach(element => {
      if (element.contains(targetElement)) {
        flag = true;
        return;
      }
    });
    return flag;
  }

  private resetDropdown() {
    this.accountsListToShowIndex = 5;
    this.accountsListToShow = this.accountList.slice(0, this.accountsListToShowIndex);
  }

  private formatAddress(address: string): string {
    return address?.trim().split(' ').filter(word => word !== '').join(' ');
  }

  private formFullAddress(address: Address): string {
    let fullAddress = '';
    let street = '';
    if (address?.street && address?.street.trim() !== '') {
      street = this.formatAddress(address?.street);
    }
    fullAddress = this.cbHelper.replaceNull(street) +
    this.cbHelper.replaceNull(this.formatAddress(address?.houseNumber)) +
    this.cbHelper.replaceNull(address?.city) +
    this.cbHelper.replaceNull(address?.state, true) +
    this.cbHelper.replaceNull(address?.zipCode, true);

    return fullAddress;
  }
}
