import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfig, CBHelperService, LoginProfileClient } from "common-ui-lib";
import { Subject } from "rxjs";
import { CBLoaderService } from "common-ui-lib/lib/shared/cbloader/cbloader.service";
import { DeviceDetectorService } from "ngx-device-detector";
import OktaAuth from "@okta/okta-auth-js";
import { OKTA_AUTH } from "common-ui-lib";
@Component({
  selector: "app-redirect",
  template: `<div class="div-height">
    <app-loader isSecondary="true" isLoading="true" message="Signing In"></app-loader>
  </div>`,
  styleUrls: ["./callback.component.scss"],
})
export class RedirectComponent implements OnDestroy, OnInit {
  public isMyAdmin = false;
  private gravityMobileApp =false;
  private onDestroy$ = new Subject<boolean>();
  constructor(
    @Inject(OKTA_AUTH) private okta: OktaAuth,
    private config: AppConfig,
    private cbHelper: CBHelperService,
    private spinnerService: CBLoaderService,
    private router: Router,
    public loginservice: LoginProfileClient,
    private detectDeviceService: DeviceDetectorService
  ) { }


  
  ngOnInit() {
    this.authenticate();
    this.spinnerService.show();
  }

  private async authenticate() {
    const referrer = this.okta.getOriginalUri();
    
    //setting referrer path again to access the url in post login
    window.sessionStorage.setItem("referrerPath", referrer);

    //to fix logout issue for sso's
    if (referrer?.toLowerCase().includes("onsuccess")) {
      this.config.getConfig("appsWithoutMyaccountSession").forEach((element) => {
        if (referrer.includes(element)) {
          if(element === 'gravity-mobile-app'){
            this.gravityMobileApp = true;
            return window.location.href = (this.config.getConfig("gravityMobileAppUrl"));
          }
          localStorage.removeItem("webex");
          const decodedURI = decodeURIComponent(referrer);
          const redirectURI = decodedURI.split("fromURI=")[1];
          if (redirectURI) {
            window.open(redirectURI, "_self");
            return;
          }
        }
      });
    }

    try {
      await this.okta.handleRedirect();
    } catch (err) {
      if (this.cbHelper.isUserAuthenticated()) {
        window.location.href = `${window.location.origin}/cbma/home/landing`;
      } else {
        this.router.navigate(["/unauth/logout"]);
      }
      return;
    }
    if (referrer.includes('vopon')) {
      window.sessionStorage.setItem('voponReferrer', referrer);
    }

    //Temporarily added to fix receptionist/callcenter logout
    if (referrer.includes("broadworks") || referrer.includes("other")) {
      const decodedURI = decodeURIComponent(referrer);
      const redirectURI = decodedURI.split("fromURI=")[1];
      if (redirectURI) {
        return window.open(redirectURI, "_self");
      }
    }
    if(!this.gravityMobileApp){
    this.cbPostLogin();
    }
  }

  // Handle redirect based on the flags
  public async cbPostLogin() {
    if (this.detectDeviceService.isMobile()) {
      document.getElementById("searchField")?.classList?.remove("hide");
    }
    if (window.location.origin.includes("myadmin")) {
      window.location.href = this.config.getConfig("myAdminSessionURL");
    } else {
      window.location.href = this.config.getConfig("myaccountSessionURL");
    }
  }

  public ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
