<div role="dialog" #selfInstallModal aria-modal="true" aria-describedby="modal-heading" *ngIf="selfInstallService.deviceDetailsContentLoaded; else boxSkeleton">
    <span class="sr-only" id="modal-heading">  {{selfInstallService.selfInstallTitle}}
        {{resourceBundle.headingLevel}}</span>
    <div class="modal-header modalheader">
        <div class="col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
            <h4 class="modal-title" id="dialogHeader" role="heading">
            {{selfInstallService.selfInstallTitle}}</h4>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 text-right margin-left-auto">
            <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn" id="closeBtn"
                aria-label="Close" (click)="activeModal?.close(); onModalClose()" appModalfocus></a>
        </div>
    </div>
    <div id="coxGradientLine" class="gradient-rectangle-header mx-auto"></div>
    <div class="row modal-body pt-0 pb-0">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <app-commonmessage id="selfInstallError"
                *ngIf="selfInstallService.selfInstallMsg.displayMessage.show && selfInstallService.selfInstallMsg.displayMessage.id === 'selfInstallError'"
                [type]="selfInstallService.selfInstallMsg.displayMessage.type" [level]="'global'"
                [globalMessage]="selfInstallService.selfInstallMsg.displayMessage.message">
            </app-commonmessage>
        </div>
        <div *ngIf="selfInstallService?.allDevicesInstalled" id="feedbackContent" class="wide100 ml-3 mt-2 mr-5"></div>
    </div>
    <!-- modal body content -->
    <ng-container [ngSwitch]="selfInstallService.selfInstallStatus">
        <div *ngSwitchCase="caseNum['case1']">
            <app-order-confirmation [resourceBundle]="resourceBundle"></app-order-confirmation>
        </div>
        <div *ngSwitchCase="caseNum['case2']">
            <app-order-tracking [resourceBundle]="resourceBundle"></app-order-tracking>
        </div>
        <div *ngSwitchCase="caseNum['case3']">            
            <div *ngIf="!selfInstallService?.allDevicesInstalled || digitalProfileService.netAssuranceStatus">
                <app-order-delivered-postmvp [resourceBundle]="resourceBundle"></app-order-delivered-postmvp>
            </div>
        </div>
    </ng-container>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-40px margin-top-2em-imp text-center" *ngIf="!selfInstallService?.allDevicesInstalled && selfInstallService.onepStatus !== ChannelStatus.Failure || (digitalProfileService.netAssuranceStatus && !selfInstallService?.netAssuranceUnsuccessful)">
        <a role="link" [class.disabled]="isMyadmin" href="javascript:void(0)" [attr.aria-label]= "isMyadmin ? resourceBundle.getSupportDisabled : resourceBundle.getSupport"
            (click)="triggerTealium(resourceBundle.getSupport)" (focus)="onFocus($event)"
            [attr.data-chat-text]="resourceBundle.khorosOliverChatText"
            class="linkUnderline blue" [ngClass]="!isMyadmin? 'chat-trigger':'mouse-link'">{{resourceBundle.getSupport}}
            <img *ngIf="isMyadmin" src="assets/images/blueprint/admin_redesign/arrow-right-disabled.svg" alt="arrow next icon" role="presentation"
                class="width-20px margin-top-neg-5 pl-1">
            <img *ngIf="!isMyadmin" src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon" role="presentation"
                class="width-20px margin-top-neg-5 pl-1"></a>
    </div>
    <div *ngIf="selfInstallService.selfInstallStatus === caseNum['case3'] && selfInstallService?.allDevicesInstalled && !digitalProfileService.netAssuranceStatus">
        <hr class="hrline">
        <div class="col-sm-12 col-md-12 col-xs-12 col-sm-12 display-flex pl-4 pr-4">
            <div class="col-lg-10 col-md-10 col-9 p-0">
                <button type="button" class="button button-secondary col-lg-8 col-md-8 col-12"
                    (click)="activeModal?.close(); selfInstallService.triggerTealium('Close')">
                    {{resourceBundle.close}}</button>
            </div>
        </div>
    </div>
</div>
<ng-template #boxSkeleton>
    <div class="col-12 justify-content-center my-5">
        <app-skeletonloader formName="Device details" formSkeleton="true" rowCount="4" colCount="1">
        </app-skeletonloader>
    </div>
</ng-template>
