
import { Component, OnInit } from '@angular/core';
import { AEMClientService, ResourceBundle } from 'common-ui-lib';
import { ValidateContactService } from '../validate-contact.service';

@Component({
  selector: 'app-validate-contact-header',
  templateUrl: './validate-contact-header.component.html',
  styleUrls: ['./validate-contact-header.component.scss']
})
export class ValidateContactHeaderComponent implements OnInit {

  public resourceBundle: ResourceBundle = {};

  constructor(private aemClient: AEMClientService, public service: ValidateContactService) { }

  ngOnInit(): void {
    this.aemClient.getRedesignBundle('validate-contact/validation-method')
    .subscribe(
      (data: ResourceBundle) => {
        this.resourceBundle = data;
      }
    );
  }

}
