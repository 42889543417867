<div role="dialog" aria-describedby="modal-heading" aria-modal="true">
    <span *ngIf="!resetPasswordService.forgotPasswordFlow" class="sr-only" id="modal-heading" aria-live="polite" aria-atomic="true">
        {{resourceBundle.resetPasswordModalTitle}} {{resourceBundle.headingLevel4}}</span>
    <span *ngIf="resetPasswordService.forgotPasswordFlow" class="sr-only" id="modal-heading">
        {{resetPasswordService.modalHeading}} {{resourceBundle.headingLevel4}}</span>
    <div class="modal-header modalheader">
        <div *ngIf="resetPasswordService.forgotPasswordFlow" aria-live="polite" class="col-lg-9 col-md-10 col-sm-10 col-xs-10 col-10 p-0">
            <h4 class="modal-title h4">{{resetPasswordService.modalHeading}}</h4>
        </div>
        <div *ngIf="!resetPasswordService.forgotPasswordFlow" class="col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
            <h4 class="modal-title h4">{{resourceBundle.resetPasswordModalTitle}}</h4>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 text-right">
            <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn" id="closeBtn"
                (click)="onResetPwdClose()" appModalfocus></a>
        </div>
    </div>
    <div class="row modal-body pt-0 pb-0">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <app-commonmessage id="resetPwdError"
                *ngIf="resetPasswordService.resetPwdMsg.displayMessage.show && resetPasswordService.resetPwdMsg.displayMessage.id === 'resetPwdError'"
                [type]="resetPasswordService.resetPwdMsg.displayMessage.type" [level]="'global'"
                [globalMessage]="resetPasswordService.resetPwdMsg.displayMessage.message">
            </app-commonmessage>
        </div>
    </div>
    <!-- modal body content -->
    <ng-container [ngSwitch]="resetPasswordService.currentStep">
        <div *ngSwitchCase="stepNum['step1']">
            <app-validate-userid [resourceBundle]="resourceBundle"
                (closeClick)="onResetPwdClose()"></app-validate-userid>
        </div>
        <div *ngSwitchCase="stepNum['step2']">
            <app-choose-verify-method [resourceBundle]="resourceBundle" [userGuid]="userGuid"
                (closeClick)="onResetPwdClose()"></app-choose-verify-method>
        </div>
        <div *ngSwitchCase="stepNum['step2a']">
            <app-scanid-contact-method [resourceBundle]="resourceBundle"></app-scanid-contact-method>
        </div>
        <div *ngSwitchCase="stepNum['step2b']">
            <app-scanid-contact-method-confirmation [resourceBundle]="resourceBundle"
                (closeClick)="onResetPwdClose()"></app-scanid-contact-method-confirmation>
        </div>
        <div *ngSwitchCase="stepNum['step3']">
            <app-verify-otp [resourceBundle]="resourceBundle"></app-verify-otp>
        </div>
        <div *ngSwitchCase="stepNum['step4']">
            <app-change-password [resourceBundle]="resourceBundle"
                (closeClick)="onResetPwdClose()"></app-change-password>
        </div>
    </ng-container>
</div>