export class CoxMiddlewareException extends Error {
    public transactionId: string;
    public code: string;
    public warning: string;
    // tslint:disable-next-line: no-any
    public resp: any;
    constructor(message: string) {
        super(message);
        this.name = 'CoxMiddlewareException';
    }
}
