<div class="modal-body" [ngClass]="{'pt-0' : resetPasswordService.resetPwdMsg.displayMessage.show}">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0" [ngClass]="{'mt-0' : resetPasswordService.resetPwdMsg.displayMessage.show, 
    'margin-top-15' : !isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show,
    'margin-top-10px' : isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show}">
        <h5 *ngIf="!resetPasswordService.forgotPasswordFlow" class="h1 font-cerapro-regular-imp text-center">{{resourceBundle.idScanTitle}}</h5>
        <p class="text-center" [ngClass]="{'pb-4 pad-top-p-20' : !isMobile,
        'pad-bottom-2-188rem pad-top-2-188rem' : isMobile}" [innerHTML]="idScanDescription"></p>
    </div>
</div>
<hr class="hr margin-top-10" />
<div>
    <div class="col-sm-12 col-md-12 col-xs-12 btn-align" *ngIf="!resetPasswordService.isIdScanFailed">
        <ng-container *ngIf="!isMobile">
            <button type="button" class="button button-secondary btn-long-text mt-2" (click)="backToChooseOption()">
                {{resourceBundle.verifyOtpChooseOtherButton}}
            </button>
            <button *ngIf="!showturnOffButtonSpinner" class="button btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em track-clicks" [value]="resourceBundle.continue"  (click)="continueIdScan()">{{resourceBundle.continue}}</button>
            <button class="button continueButton btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em spinButton" *ngIf="showturnOffButtonSpinner" aria-label="Button spinner">
                <div class="button-spinner cb-spinner buttonSpinner">
                    <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100"
                        alt="Processing... please wait" style="position: relative;">
                        <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
                            style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%; stroke: white">
                        </circle>
                    </svg>
                </div>
            </button>
        </ng-container>
        <ng-container *ngIf="isMobile">
            <button *ngIf="!showturnOffButtonSpinner" class="button btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em track-clicks" [value]="resourceBundle.continue"  (click)="continueIdScan()">{{resourceBundle.continue}}</button>
            <button class="button continueButton btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em spinButton-mobile" *ngIf="showturnOffButtonSpinner" aria-label="Button spinner">
                <div class="button-spinner cb-spinner button-spinner-mobile">
                    <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100"
                        alt="Processing... please wait" style="position: relative;">
                        <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
                            style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%; stroke: white">
                        </circle>
                    </svg>
                </div>
            </button>
            <button type="button" class="button button-secondary btn-long-text mt-2" (click)="backToChooseOption()">
                {{resourceBundle.verifyOtpChooseOtherButton}}
            </button>
        </ng-container>
    </div>
    <div class="col-sm-12 col-md-12 col-xs-12 btn-align" *ngIf="resetPasswordService.isIdScanFailed">
        <button *ngIf="!showturnOffButtonSpinner && !hasScanIdAttempts" class="button btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em track-clicks" [value]="resourceBundle.idScanDone"  (click)="closeModal()">
            {{resourceBundle.idScanDone}}
        </button>
        <button *ngIf="!showturnOffButtonSpinner && hasScanIdAttempts" class="button button-secondary btn-long-text mt-2 pull-right" (click)="backToChooseOption()">
            {{resourceBundle.verifyOtpChooseOtherButton}}
        </button>
    </div>
</div>