import { Injectable } from '@angular/core';
import { AppConfig, EditUserService } from 'common-ui-lib';
import { CookieService } from 'ngx-cookie-service';
import { v4 as uuid } from 'uuid';
import config from 'assets/config/graphql.json';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class AwsHelperService {

    public subsTimeBehavior = new BehaviorSubject<string>(null);
    public subsTimer: number;
    constructor(
        private cookieService: CookieService, private appConfig: AppConfig,
        private edituserService: EditUserService
    ) {
        this.subsTimer = JSON.parse(this.appConfig.getConfig('subscriptionTimer'));
    }
    public headers() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let token: any = localStorage.getItem('okta-token-storage');
        token = JSON.parse(token);
        const header = {
            authorization: `Bearer ${token?.accessToken?.accessToken}`,
            referer: this.getEnvironment(),
            loggedinuseremail: this.getLoggedInUseremail(),
            oktasessionid: this.cookieService.get('cbma-okta-session-id'),
            clientid: this.appConfig.getConfig('APIGW')['clientId'],
            matransactionid: uuid(),
        };
        return JSON.stringify(header);
    }

    public configureTimeout(feature: string) {
        setTimeout(() => {
            this.subsTimeBehavior.next(feature);
        }, this.subsTimer);
    }

    private getEnvironment() {
        if (window.location.hostname.includes('local')) {
            return config.graphql['local']['env'];
        } else {
            return window.location.origin;
        }
    }
    private getLoggedInUseremail() {
        return this.edituserService.getCbUser()
            ? this.edituserService.getCbUser()
            : this.cookieService.get('cbma-current-user');
    }
}
