import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CBLoaderService } from './cbloader.service';
import { CBLoader } from './cbloader.enum';

@Component({
  selector: 'app-loader',
  templateUrl: './cbloader.component.html',
  styleUrls: ['./cbloader.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300)
      ]),
      transition(':leave',
        animate(200, style({ opacity: 0 })))
    ])
  ]
})

export class CBLoaderComponent implements OnDestroy, OnInit {
  public isLoggingOut: boolean = false;
  visible: boolean;
  @Input()
  public timeout = 5000;
  @Input()
  public isSecondary = false;
  @Input()
  public isLoading = false;
  @Input()
  public message = '';
  @Input()
  public overlay = false;

  public loader: CBLoader = new CBLoader();

  private _spinnerStateChanged: Subscription;
  ngUnsubscribe: Subject<void> = new Subject();
  constructor(private _spinnerService: CBLoaderService) { }

  ngOnInit() {
    if(this.message === "Logging Out"){
      this.isLoggingOut = true;
    }
    let timeoutTimer: any;
    this._spinnerStateChanged = this._spinnerService.spinnerState
      .subscribe((state: CBLoader) => {
        if (state !== null) {
          if (state.show) {
            this.visible = state.show;
            if (this.timeout !== 0) {
              timeoutTimer = setTimeout(() => {
                timeoutTimer = null;
                this.visible = false;
              }, this.timeout);
            }
          } else {
            if (timeoutTimer) {
              clearTimeout(timeoutTimer);
            }
            timeoutTimer = null;
            this.visible = false;
          }
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this._spinnerStateChanged?.unsubscribe();
  }
}
