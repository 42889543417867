import { Injectable } from '@angular/core';
@Injectable()
export class RingToNumberValidationService {
  constructor() { }

  public validate(key: any, ringtoNumber: string, minExtension: number, maxExtension: number): ValidationObject {
    let emergencyCount = 0;
    let validationObject = {invalid: false, errorMsg: null};
    const regExp = /^0[0-9].*$/;
    const regExp_nx = /([0-9]{2})([1]{2})/;
    const regExp_nx11 = /^([0-9]{1})([1]{2})/;
    const regExp_special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const regExp_Character = /^[A-Za-z]+$/;
    const emergencyNumber = ['911', '211', '311', '411', '511', '611', '711', '811'];

    if (ringtoNumber != null && ringtoNumber !== '') {

      if (regExp_special.test(ringtoNumber) || regExp_Character.test(ringtoNumber)) {
        validationObject.errorMsg = 'Character and special characters not allowed';
        validationObject.invalid = true;
      } else if ((ringtoNumber.length < minExtension && ringtoNumber.length !== 3) && key !== '91') {
        if (regExp.test(ringtoNumber) || (ringtoNumber.length < minExtension && key === 0) || ringtoNumber === '0') {
          validationObject.errorMsg = 'Number cannot start with 0';
          validationObject.invalid = true;
        } else if (regExp.test(ringtoNumber)) {
          validationObject.errorMsg = 'Number cannot start with 0';
          validationObject.invalid = true;
        } else if (ringtoNumber.length < minExtension) {
          if (regExp.test(ringtoNumber)) {
            validationObject.errorMsg = 'Number cannot start with 0';
            validationObject.invalid = true;
          } else {
            validationObject.errorMsg = 'Please enter at least ' + minExtension + ' digits for Extension.';
            validationObject.invalid = true;
          }
        } else {
          validationObject.errorMsg = null;
          validationObject.invalid = false;
        }
      } else if (ringtoNumber.length < 10) {
        emergencyNumber.forEach((ele: any) => {
          if (ringtoNumber === ele) {
            emergencyCount++;
          }
        });
        if (emergencyCount === 0) {
          if (ringtoNumber.length < minExtension) {
            validationObject.errorMsg = 'Please enter at least ' + minExtension + ' digits for Extension.';
            validationObject.invalid = true;
          } else {
            validationObject.errorMsg = null;
            validationObject.invalid = false;
          }
          if (ringtoNumber.length > maxExtension) {
            validationObject.errorMsg = 'Invalid Ring to number';
            validationObject.invalid = true;
          } if (regExp.test(ringtoNumber)) {
            validationObject.errorMsg = 'Number cannot start with 0';
            validationObject.invalid = true;
          }
          if (ringtoNumber.length === 4 && regExp_nx.test(ringtoNumber)) {
            validationObject.errorMsg = 'Please enter a vaild ringdown number';
            validationObject.invalid = true;
          } else if (regExp_nx11.test(ringtoNumber.substring(0, 3))) {
            validationObject.errorMsg = 'Please enter a vaild ringdown number';
            validationObject.invalid = true;
          }
        } else {
          validationObject.errorMsg = null;
          validationObject.invalid = false;
        }
      } else if (ringtoNumber.length > 10) {
        if (regExp_nx11.test(ringtoNumber.substring(0, 3))) {
          validationObject.errorMsg = 'Please enter a vaild ringdown number';
          validationObject.invalid = true;
        } else if (ringtoNumber.charAt(0) === '1') {
          if (ringtoNumber.length > 11) {
            validationObject.errorMsg = 'Customers cannot ringdown calls to international numbers';
            validationObject.invalid = true;
          } else {
            validationObject.errorMsg = '';
            validationObject.invalid = false;
          }
        } else if (ringtoNumber.charAt(0) === '8' || ringtoNumber.charAt(0) === '9') {
          if (ringtoNumber.indexOf('81') === 0 || ringtoNumber.indexOf('91') === 0) {
            if (ringtoNumber.length > 12) {
              validationObject.errorMsg = 'Customers cannot ringdown calls to international numbers';
              validationObject.invalid = true;
            } else {
              validationObject.errorMsg = '';
              validationObject.invalid = false;
            }
          } else {
            if (ringtoNumber.length > 11) {
              validationObject.errorMsg = 'Customers cannot ringdown calls to international numbers';
              validationObject.invalid = true;
            } else {
              validationObject.errorMsg = '';
              validationObject.invalid = false;
            }
          }
        } else {
          validationObject.errorMsg = 'Customers cannot ringdown calls to international numbers';
          validationObject.invalid = true;

        }
      } else if (ringtoNumber.length === 10 && regExp_nx11.test(ringtoNumber.substring(0, 3))) {
        validationObject.errorMsg = 'Please enter a vaild ringdown number';
        validationObject.invalid = true;
      } else {
        if (regExp.test(ringtoNumber)) {
          validationObject.errorMsg = 'Number cannot start with 0';
          validationObject.invalid = true;
        } else {
          validationObject.errorMsg = null;
          validationObject.invalid = false;
        }
      }
    } else {
      validationObject.errorMsg = 'Ring to number cannot be blank';
      validationObject.invalid = true;
    }
    return validationObject;
  }
}

export interface ValidationObject {
  invalid: boolean;
  errorMsg: string;
}
