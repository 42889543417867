import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import crypto from 'crypto-es';
import { SessionStorageService } from 'ngx-webstorage';
import { from } from 'rxjs/internal/observable/from';
import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { AppConfig, EditUserService, OKTA_AUTH, VoiceGlobals } from 'common-ui-lib';
import { CookieService } from 'ngx-cookie-service';
import OktaAuth from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import config from 'assets/config/graphql.json';
import { LocalStorageService } from 'ngx-localstorage';
import { DEFAULT_HELPER } from '../okta/constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public isCBMyAdmin = false;
  private uuid: string;
  private transactionId = null;
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private voiceGlobal: VoiceGlobals,
    private _sessionStorage: SessionStorageService,
    private cookieService: CookieService, private appConfig: AppConfig,
    private edituserService: EditUserService,
    private router: Router, private storageService: LocalStorageService) { }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = request.url;
    if (request.url.charAt(0) === '/') {
      url = window.location.origin + url;
    }

    if (request.url && (request.url.includes('/api/cbma') || request.url.includes('/graphql'))) {
      return from(Observable.of(this.oktaAuth.getAccessToken()))
        .pipe(
          // tslint:disable-next-line: no-any
          switchMap((token) => {
            const cb_session = request.headers.get('CB_SESSION');
            if (token || cb_session === 'unauthenticateduser') {
              this.transactionId = uuid();
              const headers = request.headers
                .set('Authorization', this.authorizationObject(token, request.url))
                .set('token', this.getUUID())
                .set('nonce', this.encrypt())
                .set('MA_TRANSACTION_ID', this.transactionId);
              return next.handle(request.clone({
                headers,
                url,
              }));
            } else {
              this.tokenNotFound();
            }
          }),
        );
    } else {
      return next.handle(request.clone({ url }));
    }
  }

  // Logout myAdmin/myAccount if token is undefined
  private tokenNotFound() {
    const localMyadminFlag = this.appConfig.getConfig('localMyadminFlag') ?
      JSON.parse(this.appConfig.getConfig('localMyadminFlag')) : false;
    this.isCBMyAdmin = (window.location.href.indexOf('myadmin') > -1) || localMyadminFlag;
    if (this.isCBMyAdmin) {
      this.router.navigate(['/unauth/myadminlogout']);
    } else {
      this.router.navigate(['/unauth/logout']);
    }
  }

  private authorizationObject(token, url) {
    if (url.includes('/api/cbma')) {
      return `Bearer ${token}`;
    } else if (url.includes('/graphql')) {
      const authObj = {
        authorization: `Bearer ${token}`,
        referer: this.getEnvironment(),
        loggedinuseremail: this.getLoggedInUseremail(),
        oktasessionid: this.cookieService.get('cbma-okta-session-id'),
        clientid: this.appConfig.getConfig('APIGW')['clientId'],
        matransactionid: this.transactionId,
        response_token: this.storageService.get('response_token', 'cbma') ? this.storageService.get('response_token', 'cbma') : null
      };
      return JSON.stringify(authObj);
    }
  }

  private getEnvironment() {
    if (window.location.hostname.includes('local')) {
      return config.graphql['local']['env'];
    } else {
      return window.location.origin;
    }
  }
  private getLoggedInUseremail() {
    return this.edituserService.getCbUser()
      ? this.edituserService.getCbUser()
      : this.cookieService.get('cbma-current-user');
  }

  private getUUID() {
    this.uuid = uuid();
    const encryptedUUID = crypto.AES.encrypt(this.uuid, DEFAULT_HELPER).toString();
    return encryptedUUID;
  }
  private encrypt() {
    const currentTimeStamp = Math.trunc(this.calculateTimestamp());
    let encryptedToken = `${this.uuid}:${currentTimeStamp}`;
    encryptedToken = crypto.AES.encrypt(encryptedToken, DEFAULT_HELPER).toString();
    return encryptedToken;
  }
  /**
   *
   * @returns the sum of the machine time and offset(if any)
   */
  private calculateTimestamp(): number {
    /**
     * considering value from voiceGlobal if present
     * else-> setting the value from localstorage to the global variable again
     */
    if (this.voiceGlobal.nonceEpochOffset) {
      return new Date().getTime() + this.voiceGlobal.nonceEpochOffset;
    } else {
      this.voiceGlobal.nonceEpochOffset = this._sessionStorage.retrieve('nonceoffset');
      return new Date().getTime() + this.voiceGlobal.nonceEpochOffset;
    }
  }
}
