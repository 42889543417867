import { Component, OnInit } from '@angular/core';
import { AEMClientService, ResourceBundle, SelfInstallService } from 'common-ui-lib';
@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit {
  public resourceBundle: ResourceBundle = {};

  constructor(private aemClient: AEMClientService,
              public selfInstallService: SelfInstallService) { }

  ngOnInit() {
    this.aemClient.getBundle('selfinstall', 'app')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: ResourceBundle) => {
        this.resourceBundle = data;
      });
  }
}
