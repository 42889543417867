<div class="modal-body">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 text-center onFocusOutline" 
            [ngClass]="{'mt-0' : resetPasswordService.resetPwdMsg.displayMessage.show, 
            'margin-top-15' : !isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show,
            'margin-top-10px' : isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show}">
        <h5 *ngIf="!resetPasswordService.forgotPasswordFlow" class="h1 font-cerapro-regular-imp">{{resourceBundle.chooseVerifyMethodsModalTitle}}</h5>
        <div [ngClass]="{'margin-botton-30p-imp' : !isMobile,'pad-bottom-30' : isMobile}" 
            class="text-center pad-top-p-20"><span>{{resourceBundle.chooseVerifyMethodsDescription}}</span>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 display-flex justify-content-center flex-wrap"
        [ngClass]="{'mb-5': isMyadmin}">
            <!-- Show/Hide Sms contact method -->
            <div class="text-center" [ngClass]="{'pad-right-1-875rem col-4' : !isMobile,
                    'pad-bottom-30 col-12 display-flex justify-content-center div-center-align flex-direction-column' : isMobile}" *ngIf="otpPhoneShow && otpSmsShow">
                <div id="textMessage" class="resetRecoveryOption" role="link" tabindex="0" attr.aria-label="Text Message {{otpPhoneValue}}"
                (click)="generateOTP(contactMethod.otpphone, contactMethodType.sms)" (keydown.enter)="generateOTP(contactMethod.otpphone, contactMethodType.sms)"
                >
                    <div class="pt-2">
                        <img alt="Text Message" class="centered pad-bottom-p-10 pad-top-0-5" aria-hidden="true"
                            src="assets/images/blueprint/malandingpage/mobile-phone.svg">
                        <a aria-hidden="true"> {{resourceBundle.textMessageOption}} </a>
                    </div>
                </div>
                <span role="presentation" class="text-blue-colour">{{otpPhoneValue}}</span>
            </div>
            <!-- Disable Sms contact method -->
            <div class="text-center defocus" [ngClass]="{'pad-right-1-875rem' : !isMobile,
                    'pad-bottom-30 col-12 display-flex justify-content-center display-grid' : isMobile}" 
                    *ngIf="!otpPhoneShow">
                <div id="textMessage" class="resetRecoveryOption">
                    <div class="pt-2">
                        <img alt="Text Message" class="centered pad-bottom-p-10 pad-top-0-5" aria-hidden="true"
                            src="assets/images/blueprint/malandingpage/mobile-phone-disabled.svg">
                        <span aria-hidden="true" class="text-disabled-color-828E96"> {{resourceBundle.textMessageOption}} </span>
                    </div>
                </div>
                <div class="text-disabled-color-828E96"> Locked </div>
            </div>
            <!-- Show/Hide Voice contact method -->
            <div class="text-center" [ngClass]="{'pad-right-1-875rem col-4' : !isMobile,
                    'pad-bottom-30 col-12 display-flex justify-content-center div-center-align flex-direction-column' : isMobile}" *ngIf="otpPhoneShow && otpVoiceShow">
                <div id="voiceCall" class="resetRecoveryOption" role="link" tabindex="0" attr.aria-label="Voice Call {{otpPhoneValue}}"
                (click)="generateOTP(contactMethod.otpphone, contactMethodType.voice)"  (keydown.enter)="generateOTP(contactMethod.otpphone, contactMethodType.voice)">
                    <div class="pt-2">
                        <img alt="Voice Call" class="centered pad-bottom-p-10 pad-top-0-5" aria-hidden="true"
                            src="assets/images/blueprint/malandingpage/phone.svg">
                        <a aria-hidden="true"> {{resourceBundle.voiceCallOption}} </a>
                    </div>
                </div>
                <span role="presentation" class="text-blue-colour">{{otpPhoneValue}}</span>
            </div>
            <!-- Disable Voice contact method -->
            <div class="text-center defocus" [ngClass]="{'pad-right-1-875rem' : !isMobile,
                    'pad-bottom-30 col-12 display-flex justify-content-center display-grid' : isMobile}" 
                    *ngIf="!otpPhoneShow">
                <div id="voiceCall" class="resetRecoveryOption">
                    <div class="pt-2">
                        <img alt="Voice Call" class="centered pad-bottom-p-10 pad-top-0-5" aria-hidden="true"
                            src="assets/images/blueprint/malandingpage/phone-disabled.svg">
                        <span aria-hidden="true" class="text-disabled-color-828E96"> {{resourceBundle.voiceCallOption}} </span>
                    </div>
                </div>
                <div class="text-disabled-color-828E96"> Locked </div>
            </div>
            <!-- Show/Hide Email contact method -->
            <div class="text-center" [ngClass]="isMobile ? 'col-7 display-flex justify-content-center div-center-align flex-direction-column' : 'col-4 '"
                *ngIf="otpEmailShow">
                <div id="emailAddress" class="resetRecoveryOption" role="link" tabindex="0" attr.aria-label="Email {{otpEmailValue}}"
                (click)="generateOTP(contactMethod.otpemail, contactMethodType.email)" (keydown.enter)="generateOTP(contactMethod.otpemail, contactMethodType.email)">
                    <div class="pt-2">
                        <img alt="Email Address" class="centered pad-bottom-p-10 pad-top-0-5" aria-hidden="true"
                            src="assets/images/blueprint/malandingpage/envelope-email.svg">
                        <a aria-hidden="true"> {{resourceBundle.emailAddressOption}} </a>
                    </div>
                </div>
                <div role="presentation" class="text-blue-colour width-96 word-wrap">{{otpEmailValue}}</div>
            </div>
            <!-- Disable Email contact method -->
            <div class="text-center defocus" [ngClass]="{'col-12 display-flex justify-content-center display-grid': isMobile}"
            *ngIf="!otpEmailShow">
            <div id="emailAddress" class="resetRecoveryOption">
                <div class="pt-2">
                    <img alt="Email Address" class="centered pad-bottom-p-10 pad-top-0-5" aria-hidden="true"
                        src="assets/images/blueprint/malandingpage/envelope-email-disabled.svg">
                    <span aria-hidden="true" class="text-disabled-color-828E96"> {{resourceBundle.emailAddressOption}} </span>
                </div>
            </div>
            <div class="text-disabled-color-828E96"> Locked </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 mb-5" *ngIf="!isMyadmin">
            <a role="link" target="_blank" [attr.href]="resourceBundle.needHelpLink"
                class="linkUnderline mt-4 pt-1">{{resourceBundle.needHelp}}<img
                    src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon" role="presentation"
                    class="width-20px margin-top-neg-5 pl-1"></a>
        </div>
    </div>
</div>

<ng-container *ngIf="!this.otpPhoneShow && !this.otpEmailShow && (userType === 'profileowner' || userType === 'profileadmin')">
<hr class="hr margin-top-10"/>
<div>
    <div class="col-sm-12 col-md-12 col-xs-12">
        <ng-container *ngIf="!showEditProfile && !hideUseIdScan">
        <button [value]="resourceBundle.useIdScan" class="button btn-long-text mt-2 pull-right pad-left-4-imp pad-right-4em track-clicks" (click)="navigateToScanId()">{{resourceBundle.useIdScan}}</button>
        </ng-container>
    <ng-container  *ngIf="isMyadmin && showEditProfile">
        <button class="button btn-long-text mt-2 pull-right" (click)="navigateToEditProfile(); onchooseCnctMthdClose()">{{resourceBundle.editProfile}}</button>
    </ng-container>
        <button type="button" class="button button-secondary mt-2 pull-right" (click)="onchooseCnctMthdClose()">
            {{resourceBundle.closeBtn}}
        </button>
    </div>
</div>
</ng-container>
