import { MopPaymentType } from 'home/billingtools/billingtools.model';
import { StatementDetails } from 'redesign/admin/account/add-account/add-account.model';

export interface PromotionDetails{
	amount: string;
	description: string;
    currencyType: string;
    expirationDate: string;
}
export interface ActiveAgreements{
	expirationDate: string;
	commercialServiceAgreement: string;
}
export interface UpdateMopList {
	mopList: [UpdateMop];
}
export interface MopFlags {
	isDefaultMop?: boolean;
	isEasyPayMop: boolean;
	saveMop?: boolean;
}
export interface UpdateMop {
	mopFlags: MopFlags;
    mopIdentifier?: MopIdentifier;
    mopDetails: MopDetails;
    statementCodes: [String];
    authorizationDetails: PaymentAuthorizationDetails;
}
export interface MopIdentifier{
	mopCode: string;
	mopSequence: string;
	methodOfPaymentId: string;
}

export interface MopDetails {
	methodOfPaymentId: string;
	customerName?: string;
	cardExpirationDate?: string;
	routingCode?: string;
	holderZip?: string;
	bankAccountType?: BankAccountType;
}

export interface PaymentAuthorizationDetails{
	authorizationName: string;
	authorizationEmail: string;
	authorizationText: string;
	ip?: string;
}

enum BankAccountType{
	savings = 'SAVINGS',
	checking = 'CHECKING'
}

export interface MethodOfPayments {
    restrictedMop: string;
    methodOfPayments: MethodOfPayment[];
}

export interface MethodOfPayment{
    id: string;
    type: string; // MethodOfPaymentTypeEnum
    code: string;
    sequence: string;
    description: string;
    customerMOPStatus: string;
    bankAccountType: string;
    routingCode: string;
    customerAccountNumber: string;
    customerName: string;
    cardExpirationDate: string;
    customerZipCode: string;
    isDefaultMop: boolean;
    isEasyPayMop: boolean;
    isStoredMop: boolean;
    statementCodes: string[];
    labelText?: string;
}

export interface MethodOfPayments {
    restrictedMop: string;
    spmaccount?: boolean;
    methodOfPayments: MethodOfPayment[];
}
export interface MethodOfPayment {
    id: string;
    type: string; // MethodOfPaymentTypeEnum
    code: string;
    sequence: string;
    description: string;
    customerMOPStatus: string;
    bankAccountType: string;
    routingCode: string;
    customerAccountNumber: string;
    customerName: string;
    cardExpirationDate: string;
    customerZipCode: string;
    isDefaultMop: boolean;
    isEasyPayMop: boolean;
    isStoredMop: boolean;
    statementCodes: string[];
    labelText?: string;
}


export enum BankAccountTypeShort {
    S = 'Savings',
    C = 'Checking',
}

export class MopDetail {
    description: string;
    isDefaultMethod: boolean;
    isSaveMethod: boolean;
    isEasyPayMop: boolean;
    paymentType: string;
    name: string;
    mopPaymentType?: MopPaymentType;
    amount: number;
    type: string;
    statementCode?: string;
    easyPayStatementCodeList?: string[];
    methodOfPaymentId: string;
    isEasyPaySingleStmt?: boolean;
    easyPayCurrentAmt?: string;
    easyPasyNextDueDate?: string;
    routingNumber: string;
    customerAccountNumber: string;
    accountType: string;
    accountNumber: string;
    maskedCcNumber?: string;
    expirationYear: string;
    expirationMonth: string;
    customerZipCode: string;
    displayName?: string;
    countryCode?: string;
}

export interface NewMethodOfPayment{
    id: string;
    type: string; // MethodOfPaymentTypeEnum
    code: string;
    sequence: string;
    description: string;
    customerMOPStatus: string;
    bankAccountType: string;
    routingCode: string;
    customerAccountNumber: string;
    customerName: string;
    cardExpirationDate: string;
    customerZipCode: string;
    isDefaultMop: boolean;
    isEasyPayMop: boolean;
    isStoredMop: boolean;
    statementCodes?: string[];
    labelText?: string;
    bankPaymentMethodClass?: string;
    holderZip?: string;
    chaseDetails: CreditChaseInput;
    cardNumberEncrypted: string;
    cardCSVEncrypted: string;
    cardHolderName: string;
    cardPaymentMethodClass: string;
    countryCode?: string;
}

export interface CreditChaseInput {
    integrityCheck: string;
    formatId: string;
    subscriberId: string;
    cardCsV: string;
    cardNumber: string;
}

export interface Address {
	houseNumber: string;
	street: string;
	city: string;
	state: string;
	zipCode: string;
	customerName?: string;
	customerBusinessName?: string;
}

export interface UpdateBillingAddressInput{
	address: Address;
	billingAddressContactEmail: string;
	customerBusinessName: string;
	customerName: string;
	statementCode: string;
}


export interface StatementDescription {
    accountAlias: string;
    statement: string;
    serviceAddress: string;
    amountDue: string;
    accountNumber: string;
    dueDate: string;
    accountName: string;
}

export interface AutopayComponentinput {
    statements: StatementDetails[];
    checkTodayPay: boolean;
    checkTodayPayFuture: boolean;
    checkTodayPayPast: boolean;
    easypay: MethodOfPayment;
    isMultiStatementAccount: boolean;
}


export interface AutopayStatementDetails extends StatementDetails{
    active?: boolean;
    enrollSuccess?: boolean ;
}

export class MultistatementDues {
    totalPastAmountDue?: number;
    totalAmountDue?: number;
    currentDue?: number;
}

export class PaperlessToggleInputList{
    paperlessToggle: PaperlessToggle[];
}

export class PaperlessToggle {
    accountGuid: string;
    enrollInAutoPayPaperlessBillingInput: EnrollInAutoPayPaperlessBillingInput;
}

export class EnrollInAutoPayPaperlessBillingInput{
	billingPreferenceType: BillPreferenceType;
	existingBillingHandleCode: string;
	methodOfPaymentId: string;
	statementCodes: [string];
	isDefaultMop: boolean;
    isEasyPayMop: boolean;
    isAuthorized: boolean;
}

export enum BillPreferenceType{
	PAPER = 'PAPER',
	PAPERLESS = 'PAPERLESS',
	EASYPAY = 'EASYPAY',
	NONE = 'NONE'
}

export interface StatementDetailsModal{
    guid: string;
    code: string;
    identifier: string;
    totalAmountDue: string;
    dueDate: string;
    accountName: string;
    accountAlias: string;
    serviceAddress: string;
    accountNumber12: string;
}

export class Statement {
    guid: string;
    code: string;
    identifier: string;
    totalAmountDue: string;
    dueDate: string;
    accountName: string;
    alias: string;
    serviceAddress: string;
    isPaperlessEligible: boolean;
    billHandlingPreferenceType: string;
    billHandlerCode: string;
    isEnrolledInAutoPay: boolean;
    accountNumber12: string;
    isChecked: boolean;
    cursor: string;
    disable: boolean;
  }

  export interface BillPayInvoices {
    listOfCycleDates: string[];
    cycleDates: BillPayCycleList[];
    statementCode: string;
    stmtIdentifier: string;
  }
  export interface BillPayCycleList {
    value: string;
    id: string;
  }
  export interface MultiSelectDropdown {
      id: number;
      itemName: string;
  }

  export interface CycleDates {
    value: string;
  }

  export enum MOPAccountTypeEnum {
    SAVINGS = 'Savings',
    CHECKING = 'Checking',
    BANK = 'Bank',
    AMEX = 'American Express',
    ATM = 'ATM',
    ACH = 'Bank',
    MASTERCARD = 'Mastercard',
    DISCOVER = 'Discover',
    AMERICANEXPRESS = 'American Express',
    VISA = 'Visa',
    DEBIT = 'ATM',
    UNKNOWN = 'Unknown'
  }

  export class RefreshBillingDataObj {
       private _refreshStatements: boolean;
       private _refreshTransactions: boolean;

        public get refreshstatements(){
            return this._refreshStatements;
        }
        public get refreshTransactions(){
            return this._refreshTransactions;
        }
        public set refreshstatements(refresh: boolean){
            this._refreshStatements = refresh;
        }
        public set refreshTransactions(refresh: boolean){
            this._refreshTransactions = refresh;
        }
  }

//For CBMA1-62903
  export interface BillTransparencyActivityInfo{
    activitydetails: ActivityDetails[];
  }

  export interface ActivityDetails{
	message: [String];
	activityName: string;
}
