/* eslint-disable max-len */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PaymentArrangementsService } from '../payment-arrangements/payment-arrangements.service';
import { BillingHomeService } from '../billing-home.service';
import {
  DeviceDetectorService, ResourceBundle, AEMClientService, CommonMessageService,
  StringUtilService, AppConfig, TealiumUtagService, CBHelperService
} from 'common-ui-lib';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PaymentArrangments, PayArrangeType, MaxCollecDueDatesPaymentArrngType } from './payment-arrangements.model';
import { AccountInfo } from 'common-ui-lib/lib/shared/redesign/pageheader/pageheader.model';
import { StatementDetails } from 'redesign/admin/account/add-account/add-account.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MakePaymentService } from '../make-payment/make-payment.service';
import { MakePaymentComponent } from '../make-payment/make-payment.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { OrderPipe } from 'ngx-order-pipe';
import { BankAccountType, BankAccountTypes, CBAccount, MethodOfPayments, PaymentAuthorizationDetails, PaymentByBankAccount, PaymentByCreditCard, PaymentByMop, PaymentMethodClass, StatementList, SubmitPayment } from '../make-payment/make-payment.model';
import { MopDetail } from '../billing-home.model';
import { createEvent, download } from 'redesign/eventdownload/event-download-utils';
import { Router } from '@angular/router';
@Component({
  selector: 'app-payment-arrangements',
  templateUrl: './payment-arrangements.component.html',
  styleUrls: ['./payment-arrangements.component.scss']
})
export class PaymentArrangementsComponent implements OnInit {
  @Input() directCalendarView = false;
  @Input() error = false;
  @Output() closeClick = new EventEmitter<any>();
  @Output() scheduleFuturePayConfirm = new EventEmitter<any>();
  @Output() backToHomeEvent = new EventEmitter<any>();
  @ViewChild('scheduleconfirm') public scheduleconfirm: NgbModalRef;
  public selectedAccountDetails: AccountInfo;
  public selectedStatement = new BehaviorSubject<StatementDetails>(null);
  public isMobile = false;
  public paymentArrangementOptions: PaymentArrangments[] = [];
  public resourceBundle: ResourceBundle = {};
  public payArrangeType = PayArrangeType;
  public extensionDate: Date;
  public clickedDate = -1;
  public checkForToday = new Date();
  public selectDateModal = false;
  public reminderModal = false;
  public confirmationModal = false;
  public disabled = false;
  public selectedDate: string;
  public selected = true;
  public isMobileView: boolean;
  public isDesktopView: boolean;
  public arrangementTitle: string;
  public arrangementsModal = true;
  public rescheduleOrRequest = false;
  public title: string;
  public weekDay: string;
  public maxDueDate = new Date();
  public minDueAmount: string;
  public maxCollecDueDatesPaymentArrngType = MaxCollecDueDatesPaymentArrngType;
  public selectedOption: string = null;
  public scheduleFuturePaymentModal = false;
  public statementMopDetails: MethodOfPayments;
  public statementDetails: StatementDetails[];
  public singleStatementObj: CBAccount;
  public editMopObject: MopDetail;
  public isAddPayment = false;
  public isMOP = false;
  public isReview = false;
  public isPayment = false;
  public restrictedMop: string;
  public isSPMAccount: boolean;
  public requestType: string;
  public hasMaxNumberOfMops: boolean;
  public singleAccountSelected: boolean;
  editMOPObj: MopDetail;
  public isAddSuccess = false;
  public isScheduleFuturePayMOP: boolean;
  public payType: MaxCollecDueDatesPaymentArrngType;
  public closeResult: string;
  public modalRef: NgbModalRef;
  public unavailableOptions = [];
  public userFirstName: string;
  public userLastName: string;
  public userEmail: string;
  public type: string;
  public count = [];
  public otherOptions = [];
  requestScheduledDate: any;
  calenderEvent: {
    start: Date;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    end: Date; summary: string;
    // eslint-disable-next-line max-len
    description: string; location: string; url: string;
  }[];
  choosePaymentDate: any;
  public shedulefutureupdationfailflag: boolean;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    public payArrangeService: PaymentArrangementsService,
    private deviceDetectorService: DeviceDetectorService,
    public stringUtilService: StringUtilService,
    private aemClient: AEMClientService,
    public activeModal: NgbActiveModal,
    public payArrangeMsg: CommonMessageService,
    public billingService: BillingHomeService,
    public makePaymentService: MakePaymentService,
    private datePipe: DatePipe,
    private orderPipe: OrderPipe,
    private modalService: NgbModal,
    private config: AppConfig,
    private router: Router,
    public tealium: TealiumUtagService,
    public cbHelperService: CBHelperService
  ) { };

  ngOnInit() {
    console.log('OKTA billing 2', new Date().getTime());
    this.error = this.billingService.error;
    this.checkForToday.setDate(this.checkForToday.getDate() + 1);
    this.payArrangeService.selectedAccountDetails = this.billingService.selectedAccountDetails;
    this.payArrangeService.selectedStatement = this.billingService.selectedStatement;
    this.userFirstName = this.cbHelperService.getLoggedInUserDetails().profile.userToken.firstName;
    this.userLastName = this.cbHelperService.getLoggedInUserDetails().profile.userToken.lastName;
    this.userEmail = this.cbHelperService.getLoggedInUserDetails().profile.userToken.email;
    this.aemClient.getRedesignBundle('billing/payment-arrangements/payment-arrangements')
      .subscribe((data: any) => {
        this.resourceBundle = data;
        this.payArrangeService.title = this.resourceBundle.payArrangeModalTitle;
        this.selectedAccountDetails = this.payArrangeService.selectedAccountDetails;
        this.selectedStatement = this.payArrangeService.selectedStatement;
        this.getPaymentArrangementOptions();
        if (this.directCalendarView) {
          this.arrangementsModal = false;
          this.onClickPayArrangeOption(MaxCollecDueDatesPaymentArrngType.PTPE);
        }
      }
      );
    this.payArrangeMsg.displayMessage.show = false;
    this.isMobileView = this.deviceDetectorService.isMobile();
    this.isDesktopView = this.deviceDetectorService.isDesktop() || this.deviceDetectorService.isLargeScreen();
    this.arrangementTitle = 'Request an extension';
  }
  public backfromScheduleFuturePayment(event) {
    this.scheduleFuturePaymentModal = false;
    this.selectDateModal = true;
    this.onClickPayArrangeOption(event);
    document.getElementById('closeBtn').focus();
  }
  //Show eligible Payment Arrangements Options
  public getPaymentArrangementOptions() {
    console.log('OKTA billing 3', new Date().getTime());
    try {
      this.payArrangeService.contentLoaded = false;
      this.payArrangeMsg.displayMessage.show = false;
      this.payArrangeService.getPaymentArrangementOptions
        (this.selectedAccountDetails.guid, this.selectedStatement.value.code, 'ALL')
        .pipe(finalize(() => {
          this.payArrangeService.contentLoaded = true;
        }))
        .subscribe((results: any) => {
        console.log('OKTA billing 4', new Date().getTime());
          if (results?.data) {
            this.paymentArrangementOptions = this.orderPaymentArrangementsList(
              results.data?.getAccount?.billStatementPaymentArrangement?.paymentArrangments);
            this.paymentArrangementOptions = this.orderPipe.transform(this.paymentArrangementOptions, 'order',
              false, true);
            this.unavailableOptions = this.paymentArrangementOptions.filter((obj) => obj.isEligibile === false);
            if (this.error) {
              this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
              window.scroll(0, 0);
            }
          }
          if (results?.errors) {
            for (let i of this.paymentArrangementOptions) {
              this.otherOptions.push(i);
            }
            for (let i of this.resourceBundle.paymentArrangementType) {
              this.paymentArrangementOptions.push(i);
            }
            this.paymentArrangementOptions = this.orderPipe.transform(this.paymentArrangementOptions, 'order',
              false, true);
            for (let i of this.otherOptions) {
              this.count.push(this.paymentArrangementOptions.indexOf(i));
            }
            this.disabled = true;
            this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
            window.scroll(0, 0);
          }
        });
    } catch (error) {
      this.payArrangeService.contentLoaded = true;
      this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
      window.scroll(0, 0);
    }
  }

  // on click of Payment Arrangement option
  public onClickPayArrangeOption(selectedOption: string) {
    this.selectedOption = selectedOption;
    switch (selectedOption) {
      case MaxCollecDueDatesPaymentArrngType.FDP:
        // this.tealium.trackModalTrigger('bill-pay:payment-arrangements:schedule-a-future-payment');
        this.payArrangeService.getRequestRescheduleExtensionDetails(this.selectedAccountDetails.guid,
          this.selectedStatement.value.code, MaxCollecDueDatesPaymentArrngType.FDP,
          this.selectedStatement.value.totalAmountDue.toString(),
          this.selectedStatement.value.totalPastAmountDue.toString(), null)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((details) => {
            this.rescheduleOrRequest = true;
            this.selectDateModal = true;
            this.arrangementsModal = false;
            this.payArrangeService.currentStep = 1;
            this.title = this.resourceBundle.scheduleFuturePaymentTitle;
            this.payArrangeService.optionName = this.resourceBundle.scheduleFuturePaymentTitle;
            this.payType = MaxCollecDueDatesPaymentArrngType.FDP;
            this.payArrangeMsg.displayMessage.show = false;
            if (details.data['getAccount']?.maxCollectionDueDates?.maxDueDate) {
              this.maxDueDate = new Date(`${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.
                substring(0, 4)}/${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.
                  substring(4, 6)}/${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.substring(6, 8)}`);
            }
            if (!details.data['getAccount']?.maxCollectionDueDates?.maxDueDate) {
              this.arrangementsModal = true;
              this.rescheduleOrRequest = false;
              this.payArrangeMsg.displayMessage.show = true;
              this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
              window.scroll(0, 0);
            }
          });
        break;
      case MaxCollecDueDatesPaymentArrngType.PTP:
        // this.tealium.trackModalTrigger('bill-pay:payment-arrangements:request-an-extension');
        this.payArrangeService.getRequestRescheduleExtensionDetails(this.selectedAccountDetails.guid,
          this.selectedStatement.value.code, MaxCollecDueDatesPaymentArrngType.PTP,
          this.selectedStatement.value.totalAmountDue.toString(),
          this.selectedStatement.value.totalPastAmountDue.toString(), null)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((details) => {
            this.rescheduleOrRequest = true;
            this.selectDateModal = true;
            this.arrangementsModal = false;
            this.payArrangeService.currentStep = 1;
            this.title = this.resourceBundle.request;
            this.payArrangeService.optionName = this.resourceBundle.request;
            this.payType = MaxCollecDueDatesPaymentArrngType.PTP;
            this.payArrangeMsg.displayMessage.show = false;
            if (details.data['getAccount']?.maxCollectionDueDates?.maxDueDate) {
              this.maxDueDate = new Date(`${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.
                substring(0, 4)}/${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.
                  substring(4, 6)}/${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.substring(6, 8)}`);
            }
            if (!details.data['getAccount']?.maxCollectionDueDates?.maxDueDate) {
              this.arrangementsModal = true;
              this.rescheduleOrRequest = false;
              this.payArrangeMsg.displayMessage.show = true;
              this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
              window.scroll(0, 0);
            }
          });
        break;
      case MaxCollecDueDatesPaymentArrngType.PTPE:
        // this.tealium.trackModalTrigger('bill-pay:payment-arrangements:reschedule-an-extension');
        this.payArrangeService.getRequestRescheduleExtensionDetails(this.selectedAccountDetails.guid,
          this.selectedStatement.value.code, MaxCollecDueDatesPaymentArrngType.PTPE,
          this.selectedStatement.value.totalAmountDue.toString(),
          this.selectedStatement.value.totalPastAmountDue.toString(), null)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((details) => {
            this.rescheduleOrRequest = true;
            this.selectDateModal = true;
            this.arrangementsModal = false;
            this.payArrangeService.currentStep = 1;
            this.title = this.resourceBundle.reschedule;
            this.payArrangeService.optionName = this.resourceBundle.reschedule;
            this.payType = MaxCollecDueDatesPaymentArrngType.PTPE;
            this.payArrangeMsg.displayMessage.show = false;
            if (details.data['getAccount']?.maxCollectionDueDates?.maxDueDate) {
              this.maxDueDate = new Date(`${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.
                substring(0, 4)}/${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.
                  substring(4, 6)}/${details.data['getAccount']?.maxCollectionDueDates?.maxDueDate.substring(6, 8)}`);
            }
            if (!details.data['getAccount']?.maxCollectionDueDates?.maxDueDate) {
              this.arrangementsModal = true;
              this.rescheduleOrRequest = false;
              this.payArrangeMsg.displayMessage.show = true;
              this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
              window.scroll(0, 0);
            }
          });
        break;
      case MaxCollecDueDatesPaymentArrngType.SCHP:
        // this.tealium.trackModalTrigger('bill-pay:payment-arrangements:pay-today');
        this.billingService.payArrangeFlow = true;
        this.directCalendarView ? this.closeClick.emit() : this.activeModal?.close("SCHP");
        if (this.confirmationModal) {
          this.billingService.setrefreshStatementDetails(this.billingService.setRefreshData(false, true));
        }
        if (this.isMobileView) {
          this.billingService.currentMobilePage = 'make-a-payment';
        } else {
          this.billingService.modalRef = this.modalService.open(MakePaymentComponent, {
            windowClass: 'redesign-modal-lg',
            backdrop: 'static',
          });
          this.billingService.modalRef.componentInstance.currentStatement = this.selectedStatement.value;
        }
        break;
      case MaxCollecDueDatesPaymentArrngType.PMIP:
        // this.tealium.trackModalTrigger('bill-pay:payment-arrangements:pay-by-mail-or-in-person');
        window.open(this.resourceBundle.payByMailURL, '_blank');
        break;
    }
    this.payArrangeService.initializeStepper();
  }

  //Close Payment Arrangements Modal
  public onPayArrangeClose() {
    this.directCalendarView ? this.closeClick.emit() : this.activeModal?.close();
    if (this.confirmationModal) {
      this.payArrangeMsg.showMessage(this.resourceBundle.extensionSuccessMsg,
        'success', 'billingHomeOverlay');
      this.billingService.setrefreshStatementDetails(this.billingService.setRefreshData(false, true));
    }
    document.getElementById('closeBtn')?.focus();
    this.billingService.payArrangeFlow = false;
    if (this.isMobileView) {
      this.shedulefutureupdationfailflag = false;
      this.directCalendarView ? this.closeClick.emit() : this.modalRef?.close();
      if (this.isPayment) {
        this.isPayment = !this.isPayment;
        this.isReview = true;
        this.isMOP = false;
        this.title = this.resourceBundle.scheduleFuturePaymentTitle;
      }
    } else if (!this.isMobileView) {
      this.payArrangeService.newMOPObj = [];
      this.payArrangeService.reviewAndSubmitObj = null;
      this.payArrangeService.editedMOPDetails = null;
    }
    this.billingService.payArrangementModalClose = true;
  }

  //Return to Billing Home Page
  public goToBillingHome() {
    // this.tealium.trackModalTrigger('bill-pay:payment-arrangements:back')
    this.billingService.currentMobilePage = 'home';
    this.payArrangeService.reviewAndSubmitObj = null;
    this.payArrangeService.editedMOPDetails = null;
    this.billingService.payArrangeFlow = false;
    this.directCalendarView ? this.closeClick.emit() : null;
    if (this.confirmationModal) {
      this.billingService.setrefreshStatementDetails(this.billingService.setRefreshData(false, true));
    }
    if (this.isMobileView) {
      this.router.navigateByUrl('/billinghome?paymentarrangement=false');
    }
  }

  public onDateChange() {
    this.selected = false;
    switch (this.extensionDate.getDay()) {
      case 0: this.weekDay = 'Sunday';
        break;
      case 1: this.weekDay = 'Monday';
        break;
      case 2: this.weekDay = 'Tuesday';
        break;
      case 3: this.weekDay = 'Wednesday';
        break;
      case 4: this.weekDay = 'Thursday';
        break;
      case 5: this.weekDay = 'Friday';
        break;
      case 6: this.weekDay = 'Saturday';
        break;
    }
    this.clickedDate = this.extensionDate.getDate();
    this.selectedDate = this.datePipe.transform(this.extensionDate, 'MM/dd/yy');
    this.payArrangeService.scheduledDate = this.selectedDate;
  }

  public reminder() {
    // this.tealium.trackModalTrigger('bill-pay:request-an-extension:select-date:continue');
    if (this.selectedOption !== null && this.selectedOption !== '') {
      const selectedDate = this.datePipe.transform(this.extensionDate, 'MM/dd/yyyy');
      switch (this.selectedOption) {
        case 'PTP':
          this.payArrangeService.getRequestRescheduleExtensionDetails(this.selectedAccountDetails.guid,
            this.selectedStatement.value.code, this.maxCollecDueDatesPaymentArrngType.PTP, this.selectedStatement.value.totalAmountDue.toString(),
            this.selectedStatement.value.totalPastAmountDue.toString(), `${selectedDate.substring(6, 10)}${selectedDate.substring(0, 2)}${selectedDate.substring(3, 5)}`)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((details) => {
              if (details.data['getAccount']?.maxCollectionDueDates?.minDueAmount > '0.00' && !details.errors) {
                this.minDueAmount = details.data['getAccount']?.maxCollectionDueDates?.minDueAmount;
                this.selectDateModal = false;
                this.reminderModal = true;
                this.payArrangeService.currentStep = 2;
              }
              if (details.errors || details.data['getAccount']?.maxCollectionDueDates?.minDueAmount === '0.00') {
                this.selectDateModal = true;
                this.reminderModal = false;
                this.payArrangeService.currentStep = 1;
                this.payArrangeMsg.displayMessage.show = true;
                this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
              }
            });
          break;
        case 'PTPE':
          this.payArrangeService.getRequestRescheduleExtensionDetails(this.selectedAccountDetails.guid,
            this.selectedStatement.value.code, this.maxCollecDueDatesPaymentArrngType.PTPE, this.selectedStatement.value.totalAmountDue.toString(),
            this.selectedStatement.value.totalPastAmountDue.toString(), `${selectedDate.substring(6, 10)}${selectedDate.substring(0, 2)}${selectedDate.substring(3, 5)}`)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((details) => {
              if (details.data['getAccount']?.maxCollectionDueDates?.minDueAmount > '0.00' && !details.errors) {
                this.minDueAmount = details.data['getAccount']?.maxCollectionDueDates?.minDueAmount;
                this.selectDateModal = false;
                this.reminderModal = true;
                this.payArrangeService.currentStep = 2;
              }
              if (details.errors || details.data['getAccount']?.maxCollectionDueDates?.minDueAmount === '0.00') {
                this.selectDateModal = true;
                this.reminderModal = false;
                this.payArrangeService.currentStep = 1;
                this.payArrangeMsg.displayMessage.show = true;
                this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
              }
            });
          break;
        case 'FDP':
          this.payArrangeService.getRequestRescheduleExtensionDetails(this.selectedAccountDetails.guid,
            this.selectedStatement.value.code, this.maxCollecDueDatesPaymentArrngType.FDP, this.selectedStatement.value.totalAmountDue.toString(),
            this.selectedStatement.value.totalPastAmountDue.toString(), `${selectedDate.substring(6, 10)}${selectedDate.substring(0, 2)}${selectedDate.substring(3, 5)}`)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((details) => {
              if (details.data['getAccount']?.maxCollectionDueDates?.minDueAmount > '0.00' && !details.errors) {
                this.payArrangeService.minDueAmt = this.minDueAmount = details.data['getAccount']?.maxCollectionDueDates?.minDueAmount;
                this.selectDateModal = false;
                this.reminderModal = false;
                this.payArrangeService.currentStep = 2;
                this.getStatementMop();
                this.scheduleFuturePaymentModal = true;
              }
              if (details.errors || details.data['getAccount']?.maxCollectionDueDates?.minDueAmount === '0.00') {
                this.selectDateModal = true;
                this.reminderModal = false;
                this.payArrangeService.currentStep = 1;
                this.payArrangeMsg.displayMessage.show = true;
                this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
              }
            });
          break;
        case 'SCHP':
          break;
      }
    }

  }
  public getStatementMop() {
    this.payArrangeService.contentLoaded = false;
    this.statementDetails = this.billingService.statementDetailsList;
    this.billingService.methodofPayments(this.selectedAccountDetails.guid, 'billpay', false)
      .pipe(finalize(() => {
        this.payArrangeService.contentLoaded = true;
      }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe(async (results: any) => {
        if (!results.errors) {
          this.payArrangeService.currentStep = 2;
          this.statementMopDetails = results.data.getAccount.methodOfPayments;
          // this.getMinDue();
        }
        if (results.errors) {
          const errorInfo = this.payArrangeMsg.getErrorInfo(results?.errors, 'payArrange');
          if (errorInfo) {
            this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'payArrange');
          }
        }
      });
  }
  public getMinDue() {
    this.payArrangeService.contentLoaded = false;
    this.statementDetails = this.billingService.statementDetailsList;
    const date = new Date(this.payArrangeService.scheduledDate)
      .toLocaleDateString('en-us').split('/').reverse().join('');
    this.payArrangeService.getMinDue(this.selectedAccountDetails.guid,
      this.selectedStatement.value.totalPastAmountDue.toString(), date,
      this.selectedStatement.value.code, this.selectedStatement.value.totalAmountDue.toString(), this.payType,
    )
      .pipe(finalize(() => {
        this.payArrangeService.contentLoaded = true;
      }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe(async (results: any) => {
        if (!results.errors) {
          this.payArrangeService.currentStep = 2;
          this.payArrangeService.minDueAmt = results.data.getAccount.maxCollectionDueDates.minDueAmount;
        }
        if (results.errors) {
          const errorInfo = this.payArrangeMsg.getErrorInfo(results?.errors, 'payArrange');
          if (errorInfo) {
            this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'payArrange');
          }
        }
      });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gotoAddPaymentFlow(e: any) {
    this.payArrangeService.currentStep = 2;
    this.editMopObject = null;
    this.reminderModal = false;
    this.selectDateModal = false;
    this.arrangementsModal = false;
    this.isAddPayment = true;
    this.scheduleFuturePaymentModal = false;
    this.isMOP = false;
    this.isPayment = false;
    this.isReview = false;
    this.restrictedMop = e.restrictedMOP;
    this.isSPMAccount = e.isSPMAccount;
    this.requestType = e.requestType;
    this.hasMaxNumberOfMops = e.hasMaxNumberOfMops;
    this.singleAccountSelected = e.singleAccountSelected;
    this.isScheduleFuturePayMOP = e.isScheduleFuturePayMOP;
    this.payArrangeService.title = this.resourceBundle.scheduleFuturePaymentTitle;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public newMOPDetails(e: any) {
    if (e.result !== 'EDIT_UNSAVED_EVENT') {
      this.payArrangeService.newMOPObj.push(e);
    } else if (e.result === 'EDIT_UNSAVED_EVENT') {
      this.editMOPObj = e.editedObj;
    }
    this.redirectToMOPDetails();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public redirectToMOPDetails(results?: any) {
    this.isAddPayment = false;
    this.isMOP = true;
    this.isReview = false;
    this.isPayment = false;
    this.reminderModal = false;
    this.selectDateModal = false;
    this.arrangementsModal = false;
    this.isAddPayment = false;
    this.scheduleFuturePaymentModal = true;
    this.isAddSuccess = false;
    this.payArrangeService.title = this.resourceBundle.scheduleFuturePaymentTitle;
    if (results?.result?.includes('_SUCCESS') || results?.result?.includes('CLOSE_EVENT')) {
      this.isAddSuccess = true;
    }
  }
  //Switches to schedule a future payment modal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gotoMOP(e: any) {
    this.payArrangeService.currentStep = 2;
    this.isMOP = e.isMOP;
    this.isAddSuccess = false;
    if (e.isSingleAccountStatement && (this.isAddPayment || this.isReview)) {
      this.payArrangeService.title = this.resourceBundle.scheduleFuturePaymentTitle;
      this.isAddSuccess = true;
      this.isReview = false;
      this.isPayment = false;
      this.reminderModal = false;
      this.selectDateModal = false;
      this.arrangementsModal = false;
      this.isAddPayment = false;
      this.scheduleFuturePaymentModal = true;
    } else {
      this.redirectToMOPDetails();
    }
  }
  public gotoReview(e: any) {
    this.payArrangeService.currentStep = 3;
    this.isReview = e;
    this.isMOP = false;
    this.payArrangeService.title = this.resourceBundle.scheduleFuturePaymentTitle;
    this.scheduleFuturePaymentModal = false;
    this.isAddPayment = false;
    this.isPayment = false;
    this.reminderModal = false;
    this.selectDateModal = false;
    this.arrangementsModal = false;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gotoMOPfromReview(e: any) {
    this.payArrangeService.title = this.resourceBundle.scheduleFuturePaymentTitle;
    this.isReview = false;
    this.isMOP = e.isMOP;
    this.scheduleFuturePaymentModal = true;
    this.isAddPayment = false;
    this.isPayment = false;
    this.reminderModal = false;
    this.selectDateModal = false;
    this.arrangementsModal = false;
    this.payArrangeService.currentStep = 2;
    this.isAddSuccess = false;
  }
  //Switches to payment confirmation modal
  public gotoPayment() {
    if (!this.payArrangeService.paymentFailure && !this.isMobileView) {
      document.getElementById('closeBtn').focus();
      this.isReview = false;
      this.isMOP = false;
      this.isPayment = true;
      this.scheduleFuturePaymentModal = false;
      this.isAddPayment = false;
      this.reminderModal = false;
      this.selectDateModal = false;
      this.arrangementsModal = false;
      this.payArrangeService.title = this.resourceBundle.paymentConfirm;
      this.title = this.resourceBundle.paymentConfirm;
    }
    if (!this.payArrangeService.paymentFailure && this.isMobileView) {
      this.isReview = true;
      this.isMOP = false;
      this.isPayment = false;
      this.scheduleFuturePaymentModal = false;
      this.isAddPayment = false;
      this.reminderModal = false;
      this.selectDateModal = false;
      this.arrangementsModal = false;
      this.modalRef = this.modalService.open(this.scheduleconfirm,
        { windowClass: 'redesign-modal-md top-20percent', backdrop: 'static' });
    }

  }
  public onClickBack(event?:string) {
    if(event==='back'){
      // this.tealium.trackModalTrigger('bill-pay:request-an-extension:back');
    }else if(event==='select-date' || event==='confirm'){
      // this.tealium.trackModalTrigger(`bill-pay:request-an-extension:${event}:back`);
    }
    if (this.selectDateModal) {
      if (this.directCalendarView) {
        this.closeClick.emit();
      } else {
        this.arrangementsModal = true;
        this.extensionDate = this.payArrangeService.scheduledDate = this.selectedDate = null;
        this.payArrangeMsg.displayMessage.show = false;
        this.rescheduleOrRequest = false;
        this.selected = true;
      }
    } else if (this.reminderModal) {
      this.payArrangeMsg.displayMessage.show = false;
      this.selectDateModal = true;
      this.reminderModal = false;
      this.payArrangeService.currentStep = 1;
    } else if (this.confirmationModal) {
      this.reminderModal = true;
      this.confirmationModal = false;
      this.payArrangeService.currentStep = 2;
    } else if (this.scheduleFuturePaymentModal) {
      this.selectDateModal = true;
      this.payArrangeService.currentStep = 1;
      this.scheduleFuturePaymentModal = false;
    } else if (this.isPayment) {
      this.shedulefutureupdationfailflag = false;
      document.getElementById('closeBtn').focus();
      this.isReview = true;
      this.isMOP = false;
      this.isPayment = false;
      this.scheduleFuturePaymentModal = false;
      this.isAddPayment = false;
      this.reminderModal = false;
      this.selectDateModal = false;
      this.arrangementsModal = false;
      this.payArrangeService.currentStep = 3;
      this.title = this.resourceBundle.scheduleFuturePaymentTitle;
    } else if (!this.isMOP && this.isReview) {
      this.isReview = false;
      this.isMOP = false;
      this.isPayment = false;
      this.scheduleFuturePaymentModal = true;
      this.isAddPayment = false;
      this.reminderModal = false;
      this.selectDateModal = false;
      this.arrangementsModal = false;
      this.payArrangeService.currentStep = 2;
      this.title = this.resourceBundle.scheduleFuturePaymentTitle;
      this.isAddSuccess = false;
    }
  }

  public confirmation(event?: string) {
    if(event) {
    // this.tealium.trackModalTrigger(`bill-pay:request-an-extension:${event}:continue`);
    }
    const selectedDate = this.datePipe.transform(this.extensionDate, 'MM/dd/yyyy');
    const payByMopDetails: PaymentByMop[] = [];
    const payByBankAccountDetails: PaymentByBankAccount[] = [];
    const payByCreditCardDetails: PaymentByCreditCard[] = [];
    const authorizationDetails: PaymentAuthorizationDetails = {
      authorizationName: `${this.userFirstName} ${this.userLastName}`,
      authorizationEmail: this.userEmail,
      authorizationText: this.resourceBundle.authorizationText,
    };

    if (this.reminderModal) {
      if (this.selectedOption === 'PTP') {
        this.type = 'PROMISE';
      } else if (this.selectedOption === 'PTPE') {
        this.type = 'EXTENSION';
      }
      const payByMopDetailsObj = {
        accountBillingMopDetails: {
          accountNumber12: this.payArrangeService.selectedAccountDetails.accountNumber12.replace('-', ''),
          billingPaymentDetails: {
            amount: this.minDueAmount,
            dateScheduled: selectedDate,
            statementCode: this.selectedStatement.value.code,
            type: this.type
          },
          mopFlags: {
            isDefaultMop: false,
            isEasyPayMop: false,
          }
        },
        mopIdentifier: {
          methodOfPaymentId: '',
          mopCode: '',
          mopSequence: ''
        }
      };
      payByMopDetails.push(payByMopDetailsObj);
      const reqObj: SubmitPayment = {
        authorizationDetails,
        payByBankAccountDetails,
        payByCreditCardDetails,
        payByMopDetails
      };
      this.payArrangeService.submitPaymentDetails(reqObj)
        .pipe(finalize(() => {
        })).subscribe((results: any) => {
          if (results?.errors || results.data.submitPayment.statuses[0].code !== '0') {
            this.reminderModal = true;
            this.confirmationModal = false;
            this.payArrangeMsg.displayMessage.show = true;
            this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
            window.scroll(0, 0);
          } else {
            this.reminderModal = false;
            this.confirmationModal = true;
            this.payArrangeService.currentStep = 3;
          }
        });
    } else {
      //shedule future payment block
      this.type = 'FUTURE_DATED';
      const index = 0;
      for (const statement of this.payArrangeService.selectedMopForPayment) {
        this.futureDatedReminderModal(statement, selectedDate, payByMopDetails, payByBankAccountDetails,
          payByCreditCardDetails);
      }
      const reqObj: SubmitPayment = {
        authorizationDetails,
        payByBankAccountDetails,
        payByCreditCardDetails,
        payByMopDetails
      };
      this.payArrangeService.submitPaymentDetails(reqObj)
        .pipe(finalize(() => {
        })).subscribe((results: any) => {
          if (results?.errors) {
            this.shedulefutureupdationfailflag = true;
          }
          results.data.submitPayment?.statuses.forEach((element) => {
            if (element.code !== '0') {
              this.shedulefutureupdationfailflag = true;
            }
          });
          this.scheduleFutureUpdationFailStatus(this.shedulefutureupdationfailflag);
        });
    }
  }

  public scheduleFutureUpdationFailStatus(flag: boolean,){
    if(flag){
      this.payArrangeMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'payArrange');
      window.scroll(0, 0);
    } else {
      this.modalRef?.close();
            this.activeModal?.close(true);
            this.billingService.payArrangementModalClose = false;
            this.billingService.currentMobilePage = 'home';
            this.scheduleFuturePayConfirm.emit(true);
            this.billingService.setrefreshStatementDetails(this.billingService.setRefreshData(true, true));
    }
  }

  public futureDatedReminderModal(statement, selectedDate, payByMopDetails, payByBankAccountDetails,
    payByCreditCardDetails){
    if (statement.id !== null) {
      const payByMopDetailsObj = {
        accountBillingMopDetails: {
          accountNumber12: this.payArrangeService.selectedAccountDetails.accountNumber12.replace('-', ''),
          billingPaymentDetails: {
            amount: statement.amountDue,
            dateScheduled: selectedDate,
            statementCode: this.selectedStatement.value.code,
            type: this.type
          },
          mopFlags: {
            isDefaultMop: statement.isDefaultMop,
            isEasyPayMop: statement.isEasyPayMop,
          }
        },
        mopIdentifier: {
          methodOfPaymentId: statement.id,
          mopCode: statement.code,
          mopSequence: statement.sequence
        }
      };

      payByMopDetails.push(payByMopDetailsObj);
    } else if (statement.bankAccountType !== null) { //for the bankdetails
      const payByBankAccountDetailsObj = {
        accountBillingMopDetails: {
          accountNumber12: this.payArrangeService.selectedAccountDetails.accountNumber12.replace('-', ''),
          billingPaymentDetails: {
            amount: statement.amountDue,
            dateScheduled: selectedDate,
            statementCode: this.selectedStatement.value.code,
            type: this.type
          },
          mopFlags: {
            isDefaultMop: statement.isDefaultMop,
            isEasyPayMop: statement.isEasyPayMop,
            saveMop: this.payArrangeService.selectedMopForPayment[0].saveMop
          }
        },
        bankDetails: {
          bankAccountHolderName: statement.customerName,
          bankAccountNumber: statement.customerAccountNumber,
          bankAccountType: statement.bankAccountType === BankAccountTypes.S ?
            BankAccountType.SAVINGS : BankAccountType.CHECKING,
          bankPaymentMethodClass: PaymentMethodClass.ACH,
          bankRoutingNumber: statement.routingCode,
          holderZip: statement.customerZipCode
        }
      };
      payByBankAccountDetails.push(payByBankAccountDetailsObj);
    } else {
      const payByCreditCardDetailsObj = {
        accountBillingMopDetails: {
          accountNumber12: this.payArrangeService.selectedAccountDetails.accountNumber12.replace('-', ''),
          billingPaymentDetails: {
            amount: statement.amountDue,
            dateScheduled: selectedDate,
            statementCode: this.selectedStatement.value.code,
            type: this.type
          },
          mopFlags: {
            isDefaultMop: statement.isDefaultMop,
            isEasyPayMop: statement.isEasyPayMop,
            saveMop: this.payArrangeService.selectedMopForPayment[0].saveMop
          }
        },
        chaseDetails: {
          cardCSV: statement.chaseDetails.cardCsV,
          cardNumber: statement.chaseDetails.cardNumber,
          formatId: statement.chaseDetails.formatId,
          integrityCheck: statement.chaseDetails.integrityCheck,
          subscriberId: statement.chaseDetails.subscriberId
        },
        creditCardDetails: {
          cardCSVEncrypted: statement.cardCSVEncrypted,
          cardExpirationDate: statement.cardExpirationDate,
          cardHolderName: statement.cardHolderName,
          cardNumberEncrypted: statement.cardNumberEncrypted,
          cardPaymentMethod: statement.cardPaymentMethod,
          cardPaymentMethodClass: statement.cardPaymentMethodClass,
          holderZip: statement.holderZip
        }
      };
      payByCreditCardDetails.push(payByCreditCardDetailsObj);
    }
  }


  public dateStringToDate(dateString) {
    const year = dateString.substring(6, 10);
    const month = dateString.substring(3, 5);
    const day = dateString.substring(0, 2);
    const date = new Date(year, month - 1, day);
    this.requestScheduledDate = new Date(date.getTime());
    return this.requestScheduledDate;
  }

  exportCsv() {
    this.tealium.track('link', {
      eventName: 'Download Due Remainder',
      myKey: 'Download Due Remainder',
      myFiltersSelected: ['f1', 'f2']
    });
    const selectedDate = this.datePipe.transform(this.selectedDate, 'dd/MM/yyyy');
    const startDate = this.dateStringToDate(selectedDate);
    const endDate = this.dateStringToDate(selectedDate);
    startDate.setHours(0, 0, 0);
    endDate.setHours(24, 0, 0);
    this.calenderEvent = [{
      start: startDate,
      end: endDate,
      summary: `${this.resourceBundle.remainderSubject}`,
      // eslint-disable-next-line max-len
      description: `${this.resourceBundle.remainderBodyPart1} ${this.selectedDate} ${this.resourceBundle.remainderBodyPart2}`,
      location: `${this.resourceBundle.location} `,
      url: ''
    }];
    const content = createEvent(this.calenderEvent);
    download(this.resourceBundle.remainderFileName, content);
  }

  //rearrange Payment Arrangements Options List
  private orderPaymentArrangementsList(paymentArrangementsList: PaymentArrangments[]) {
    const payArrangeList: PaymentArrangments[] = [];
    paymentArrangementsList?.forEach((element) => {
      if (element.paymentArrangementType !== MaxCollecDueDatesPaymentArrngType.SCHP) {
        element = { ...element, order: Object.keys(PayArrangeType).indexOf(element.paymentArrangementType) };
        payArrangeList.push(element);
      }
    });
    payArrangeList.push({
      paymentArrangementType: 'PMIP',
      isEligibile: true,
      failedRules: null,
      order: 5
    }, {
      paymentArrangementType: 'SCHP',
      isEligibile: true,
      failedRules: null,
      order: 0
    });
    return payArrangeList;
  }
}

