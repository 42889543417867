import {
  AfterViewChecked,
  Component, Input, OnInit,
  ViewChild
} from '@angular/core';
import {
  NgbActiveModal, NgbModal,
  NgbModalRef, NgbPopover
} from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService, AppConfig, CBHelperService,
  ResourceBundle, TealiumUtagService,
  DeviceDetectorService, UserType,
  CbHeaderService,
  CommonMessageService
} from 'common-ui-lib';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CBLoaderService } from 'common-ui-lib/lib/shared/cbloader/cbloader.service';
import {
  Support, UpdatePasscode, ModelTitle,
  Option,
  MenuType,
  CBCare,
  TicketDTO
} from '../cb-header.model';

@Component({
  selector: 'app-support-menu',
  templateUrl: './support-menu.component.html',
  styleUrls: ['./support-menu.component.scss']
})
export class SupportMenuComponent implements OnInit, AfterViewChecked {
  @ViewChild('getSupportModal') public getSupportModal: NgbModalRef;
  @ViewChild('letsConnect') public letsConnect: NgbModalRef;
  @ViewChild('quickLinkPopover') quickLinkPopover: NgbPopover;
  @Input()
  getSupportDetails: Option[];
  @Input() headerTicketList: TicketDTO;
  @Input() popoverOpenState: boolean;

  public letsConnectRef: NgbModalRef;
  public getSupportModalRef: NgbModalRef;
  public noCaasRole: boolean;
  public viewMore = false;
  public viewMoreCbcc = false;
  public title: string;
  public passCode: string;
  public formSubmitAttempt = false;
  public passcodeFlag = false;
  public specialCharacterValidation: boolean;
  public sequentialNumberValidation: boolean;
  public previousPasscodeValidation: boolean;
  public repeatedNumberValidation: boolean;
  public spaceValidation: boolean;
  public letterValidation: boolean;
  public alphaNumericValidation: boolean;
  public serverError = false;
  public oliverIconFlag = false;
  public needHelpFlag = true;
  public successMsg = false;
  public description: [];
  public isMobileDevice = false;
  public updatePasscodeForm: UntypedFormGroup;
  public resourceBundle: ResourceBundle = {};
  public contactUsHref: string;
  public endUser = false;
  public successPasscodeStr: string;
  public popoverPlacement = 'bottom';
  public cbccDetails: CBCare;
  public modalContentLoaded: boolean;
  private decryptedPassCodeToken: string;
  private encryptedPasscode: string;
  private passcodeStr: string;
  public ticketFlowFlag = true;//true for  CBMA6-7609
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private config: AppConfig,
    private modalService: NgbModal,
    public cbHelperService: CBHelperService,
    private aemClient: AEMClientService,
    public cbHeaderService: CbHeaderService,
    public tealium: TealiumUtagService,
    public deviceService: DeviceDetectorService,
    public activeModal: NgbActiveModal,
    public commonMessageService: CommonMessageService,
    private spinnerService: CBLoaderService,
  ) { }

  ngOnInit(): void {
    this.contactUsHref = this.config.getConfig('contactUsUrl');
    this.aemClient.getSharedBundle('cbheader/supportmenu')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data) => {
          this.resourceBundle = data;
        }
      );

    this.isMobileDevice = false;
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }

    this.updatePasscodeForm = new UntypedFormGroup({
      passcodeinputfirst: new UntypedFormControl(null, [Validators.required,
      ]),
      passcodeinputsec: new UntypedFormControl(null, [Validators.required,
      ]),
      passcodeinputthird: new UntypedFormControl(null, [Validators.required,
      ]),
      passcodeinputfourth: new UntypedFormControl(null, [Validators.required,
      ]),
      passcodeinputfifth: new UntypedFormControl(null, [Validators.required,
      ]),
    });
    this.updatePasscodeForm.disable();
  }

  ngAfterViewChecked(): void {
    if (this.cbHeaderService.showSupportMenu) {
      this.quickLinkPopover?.open();
    }
  }

  togglePopover(e: any) {
    this.popoverOpenState = !this.popoverOpenState;
    e.stopPropagation();
  }

  public onClickOfSupportLinks(supportOption: MenuType) {
    this.endUser = false;
    this.oliverIconFlag = false;
    this.commonMessageService.hideMessage();
    switch (supportOption) {
      case MenuType.CBCC:
        if (this.cbHelperService.getLoggedInUserDetails().profile.userToken.userType === UserType.END_USER) {
          this.endUser = true;
        }
        this.getCbccDetails();
        break;
      case MenuType.NOCAAS:
        this.modalContentLoaded = true;
        this.getProfileFeatureList({
          noCaas: true,
          cbCare: null
        });
        this.addService();
        break;
      case MenuType.HELP_AND_SUPPORT:
        window.open(this.resourceBundle.helpAndSupportUrl, '_blank');//Need _blank for CBMA3-44213
        break;
    }
    this.quickLinkPopover?.close();
    this.cbHeaderService.closeSideBar();
  }

  public getCbccDetails() {
    this.modalContentLoaded = false;
    this.addService();
    this.cbHeaderService.fetchCbCareDetails('ALL')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((results: any) => {
        if (results?.data) {
          const cbccDetails = results?.data?.getProfile?.support?.cbCare;
          this.getProfileFeatureList({
            noCaas: false,
            cbCare: cbccDetails
          });
          this.modalContentLoaded = true;
        }
        if (results?.errors) {
          this.modalContentLoaded = true;
          this.cbHeaderService.modalScroll();
          this.commonMessageService.showMessage(
            this.resourceBundle.genericErrorMessage, 'error', 'getSupport'
          );
        }
      });
  }

  public getProfileFeatureList(support: Support) {
    this.noCaasRole = support?.noCaas;
    this.title = support?.cbCare?.title;
    this.description = support?.cbCare?.description;
    this.passCode = support?.cbCare?.passCode;
    this.decryptedPassCodeToken = this.passCode ? this.cbHelperService.decryptWithSaltandPadding(this.passCode) : '';
    if (this.noCaasRole || support?.cbCare?.title) {
      if (this.title === ModelTitle.Complete_Care_HelpDesk || this.title === ModelTitle.Complete_Care) {
        this.oliverIconFlag = true;
      }
    }

    if (this.noCaasRole && !support?.cbCare) {
      this.needHelpFlag = false;
    } else {
      this.needHelpFlag = true;
    }
    this.passcodeValue();
  }

  /**add/upgrade service */
  public addService() {
    this.viewMore = false;
    this.viewMoreCbcc = false;
    this.quickLinkPopover?.close();
    this.tealium.button('Get-Support', 'Get-Support');
    this.getSupportModalRef = this.modalService.open(this.getSupportModal,
      {
        backdrop: 'static',
        windowClass: this.isMobileDevice ? '' : 'redesign-modal-md',
      });
  }

  public openChat() {
    this.activeModal?.close();
    this.tealium.button('Chat Now', 'Chat Now');
  }
  // to capture the default passcode of each inpur
  public passcodeValue() {
    this.updatePasscodeForm?.get('passcodeinputfirst').setValue(this.decryptedPassCodeToken?.charAt(0));
    this.updatePasscodeForm?.get('passcodeinputsec').setValue(this.decryptedPassCodeToken?.charAt(1));
    this.updatePasscodeForm?.get('passcodeinputthird').setValue(this.decryptedPassCodeToken?.charAt(2));
    this.updatePasscodeForm?.get('passcodeinputfourth').setValue(this.decryptedPassCodeToken?.charAt(3));
    this.updatePasscodeForm?.get('passcodeinputfifth').setValue(this.decryptedPassCodeToken?.charAt(4));
  }

  public changePasscode() {
    this.formSubmitAttempt = false;
    this.passcodeFlag = true;
    this.updatePasscodeForm.reset();
    this.updatePasscodeForm.enable();
    // setTimeout is used as passcodeinputfirst is undefined if we are not using it - for accessibility
    setTimeout(() => {
      document.getElementById('passcodeinputfirst').focus();
    }, 0);
  }

  // common function for 'error' validation in HTML
  public errorDisplayCheck(): boolean {
    if ((this.specialCharacterValidation
      || this.sequentialNumberValidation || this.repeatedNumberValidation || this.spaceValidation
      || this.letterValidation || this.alphaNumericValidation
      || this.previousPasscodeValidation || this.serverError) && this.formSubmitAttempt) {
      return true;
    } else {
      return false;
    }
  }

  public onCancel() {
    if (this.successPasscodeStr) {
      this.decryptedPassCodeToken = this.successPasscodeStr;
    }
    this.passcodeValue();
    this.passcodeFlag = false;
    this.updatePasscodeForm.disable();
    this.successMsg = true;
    this.formSubmitAttempt = false;
    this.onPasscodeEdit();
    this.updatePasscodeForm.disable();
  }

  public onClose() {
    if (this.successPasscodeStr) {
      this.decryptedPassCodeToken = this.successPasscodeStr;
    }
    this.passcodeValue();
    this.passcodeFlag = false;
    this.updatePasscodeForm.disable();
    this.successMsg = false;
    this.formSubmitAttempt = false;
    this.onPasscodeEdit();
  }

  public onPasscodeEdit() {
    this.specialCharacterValidation = false;
    this.sequentialNumberValidation = false;
    this.repeatedNumberValidation = false;
    this.previousPasscodeValidation = false;
    this.spaceValidation = false;
    this.letterValidation = false;
    this.alphaNumericValidation = false;
    this.serverError = false;
  }

  // passcode validation function
  public passcodeValidation(): boolean {
    this.onPasscodeEdit();
    const pattern = '0123456789'; // pattern to check sequential digits
    const pattern1 = '9876543210'; // pattern to check sequence in reverse order
    this.passcodeStr = this.updatePasscodeForm.get('passcodeinputfirst').value
      + this.updatePasscodeForm.get('passcodeinputsec').value
      + this.updatePasscodeForm.get('passcodeinputthird').value
      + this.updatePasscodeForm.get('passcodeinputfourth').value
      + this.updatePasscodeForm.get('passcodeinputfifth').value;
    const spaceCheckInStr = this.passcodeStr.replace(/\s/g, '');
    if (this.passcodeStr.match(/\b(\d)\1+\b/)) {
      return this.repeatedNumberValidation = true;
    } else if (pattern.includes(this.passcodeStr) || pattern1.includes(this.passcodeStr)) {
      return this.sequentialNumberValidation = true;
    } else if (spaceCheckInStr.match(/[~`!@#*'"$%^&()_={}[\]:;,.<>+\/?-]/)) {
      return this.specialCharacterValidation = true;
    } else if (spaceCheckInStr.match(/^[a-zA-Z]+$/)) {
      return this.letterValidation = true;
    } else if (spaceCheckInStr.match(/^(?=[^\s]*?[0-9])(?=[^\s]*?[a-zA-Z])[a-zA-Z0-9]*$/)) {
      return this.alphaNumericValidation = true;
    } else if (this.passcodeStr.match(/(\s)/) || (this.updatePasscodeForm.get('passcodeinputfirst').invalid ||
      this.updatePasscodeForm.get('passcodeinputsec').invalid
      || this.updatePasscodeForm.get('passcodeinputthird').invalid ||
      this.updatePasscodeForm.get('passcodeinputfourth').invalid ||
      this.updatePasscodeForm.get('passcodeinputfifth').invalid) || spaceCheckInStr.length !== 5) {
      return this.spaceValidation = true;
    }
  }

  public updatePasscodeRequestBody() {
    return {
      id: '',
      passCode: this.encryptedPasscode,
    };
  }

  // post call on click of save - to update the passcode
  public onPasscodeSave() {
    this.formSubmitAttempt = true;
    if (this.updatePasscodeForm.get('passcodeinputfirst').valid
      && this.updatePasscodeForm.get('passcodeinputsec').valid
      && this.updatePasscodeForm.get('passcodeinputthird').valid
      && this.updatePasscodeForm.get('passcodeinputfourth').valid
      && this.updatePasscodeForm.get('passcodeinputfifth').valid) {
      this.passcodeStr = this.updatePasscodeForm.get('passcodeinputfirst').value
        + this.updatePasscodeForm.get('passcodeinputsec').value
        + this.updatePasscodeForm.get('passcodeinputthird').value
        + this.updatePasscodeForm.get('passcodeinputfourth').value
        + this.updatePasscodeForm.get('passcodeinputfifth').value;
      if (this.passcodeValidation() !== true) {
        this.spinnerService.show();
        this.encryptedPasscode = this.cbHelperService.encryptWithSaltandPadding(this.passcodeStr);
        const passCodeRequestBody: UpdatePasscode = this.updatePasscodeRequestBody();
        this.cbHeaderService.updatePasscode(passCodeRequestBody)
          .pipe()
          .subscribe((data) => {
            this.passcodeFlag = false;
            this.updatePasscodeForm.disable();
            this.successMsg = false;
            this.successPasscodeStr = this.passcodeStr;
            this.spinnerService.hide();
          },
            (error) => {
              if (error.code === '1301') {
                this.repeatedNumberValidation = true;
                this.sequentialNumberValidation = false;
                this.specialCharacterValidation = false;
                this.previousPasscodeValidation = false;
                this.spaceValidation = false;
                this.letterValidation = false;
                this.alphaNumericValidation = false;
                return;
              } else if (error.code === '1302') {
                this.sequentialNumberValidation = true;
                this.repeatedNumberValidation = false;
                this.specialCharacterValidation = false;
                this.previousPasscodeValidation = false;
                this.spaceValidation = false;
                this.letterValidation = false;
                this.alphaNumericValidation = false;
                return;
              } else if (error.code === '1303') {
                this.previousPasscodeValidation = true;
                this.sequentialNumberValidation = false;
                this.repeatedNumberValidation = false;
                this.specialCharacterValidation = false;
                this.spaceValidation = false;
                this.letterValidation = false;
                this.alphaNumericValidation = false;
              } else {
                this.serverError = true;
                this.previousPasscodeValidation = false;
                this.sequentialNumberValidation = false;
                this.repeatedNumberValidation = false;
                this.specialCharacterValidation = false;
                this.spaceValidation = false;
                this.letterValidation = false;
                this.alphaNumericValidation = false;
              }
              this.spinnerService.hide();
            },
          );
      }
    } else {
      document.getElementById('passcodeinputfirst').focus();
      return;
    }
  }

  public setFocus(id: string) {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 0);
  }

  public onClosePopover(event) {
    this.quickLinkPopover?.close();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public offFocus(event: any, index: number) {
    if (event.key !== 'Enter' && index === this.getSupportDetails?.length - 1) {
      this.quickLinkPopover?.close();
      this.cbHeaderService.closeSideBar();
    }
  }

}
