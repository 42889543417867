import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Logger } from '../core/logger.service';
import { environment } from '../environment/environment';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  isSplit = true;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        try {
          if (event instanceof HttpResponse && event.body.code === '113') {
            console.log('OKTAFLOW-Unauthorized URL : ', event.url);
            console.log('CBMA1-55149 - Error handler');
            window.location.href = window.location.origin + '/cbma/home/landing?unauthorized=true';
          }
          return event;
        } catch (error) {
        }
      }))
      .pipe(catchError((error) => {
        if (error.status !== 200) {
          return this.errorHandler(error);
        } else {
          return null;
        }
      }));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      //  log.error('Request error', response);
    }
    if ((response as any).url && !(response as any).url.includes('/aem/content')) {
      const currentURL = (response as any).url;
      if (currentURL.includes('/api/')) {
      } else {
        if (window.location.host.includes('local.coxbusiness.com')) {
          if (this.isSplit) {
            window.location.href = `/errorpage/httperror?errorCode=${(response as any).status}`;
          } else {
            // this.router.navigate(['/httperror'], { queryParams: { errorCode: (<any>response).status } });
          }
        } else {
          if (this.isSplit) {
            window.location.href = `/errorpage/httperror?errorCode=${(response as any).status}`;
          } else {
            // this.router.navigate(['/httperror'], { queryParams: { errorCode: (<any>response).status } });
          }
        }
      }
    }
    return throwError(response);
  }

}
