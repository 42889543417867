
<div class="modal-content">
    <ng-container *ngTemplateOutlet="broadbandFactsModal"></ng-container>
</div>

<ng-template #broadbandFactsModal let-dismiss="dismiss">
    <div role="dialog" aria-modal="true" aria-labelledby="modal-header">
        <div class="modal-header modalheader">
            <h4 class="h4 modal-title">{{resourceBundle.broadbandModalHeader}}</h4>
            <a title="Close" id="closeBtn" class="cb-modal-close-btn" href="javascript:void(0)"
                (click)="resetModal()" role="button" appModalfocus></a>
        </div>
        <div *ngIf="filteredBroadbandFactlabelDetails || error; else skeletonTemplate" class="d-flex justify-content-center mt-2 flex-direction-column" [class]="(isMobileDevice || error) ? 'px-2' : 'px-5'">
            <div *ngFor="let broadbandInfo of filteredBroadbandFactlabelDetails" class="modal-body col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-light-gray font-size-13px mx-auto mb-2" >
                <div class="p-0 margin-bottom-15px margin-top-4 fw-bold ">
                    <h6>{{resourceBundle.broadbandModalHeaderText}}</h6>
                    <h5 >{{broadbandInfo?.billingServiceName}}</h5>
                    <p class="mb-2">{{broadbandInfo?.planType}}{{resourceBundle.broadbandSubText}}</p>
                </div>
                <hr class="hr mb-3" aria-hidden="true">
                <div class="p-0" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <div class="mr-auto">
                        <h6>{{resourceBundle.monthlyPriceSectionHeader}}</h6>
                    </div>
                    <div class="text-left" [ngClass]="{'text-bold-600':!isMobileDevice}">
                        <label>{{broadbandInfo?.monthlyPrice | currency:'USD'}}/mo.</label>
                    </div>
                </div>
                <p class="mb-2">{{resourceBundle.monthlyPriceSectionDesc}}</p>
                <hr class="hr mb-3" aria-hidden="true">

                <h6 class="mb-3">{{resourceBundle.additionalChargesSectionHeader}}</h6>
                <h6>{{resourceBundle.additionalChargesSectionSubHeading}}</h6>
                <div class="d-flex flex-column">
                    <div *ngIf="broadbandInfo.providermonthlyfee?.length" class=" p-0 m-0 mb-2" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                      <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.standardEquipmentRentalFee}}</p>
                      <div>
                        <div *ngFor="let charge of broadbandInfo.providermonthlyfee" [ngClass]="{'d-flex justify-content-end':!isMobileDevice}">
                          <p class="mb-2"><span>{{charge.gatewayEquipmentRentalFee | currency:'USD'}}</span>&nbsp;<span>{{charge.chargeName}}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="p-0 mb-3" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.governmentTaxes}}</p>
                    <p class="mb-2">{{broadbandInfo.governmentTaxes}}</p>
                </div>

                <hr class="hr mb-3" aria-hidden="true">

                <h6>{{resourceBundle.oneTimeFeeSectionHeading}}</h6>
                <div class="d-flex flex-column">
                <div class="p-0 m-0 mb-2" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.InstallationFee}}</p>
                    <div *ngIf="broadbandInfo?.oneTimeFeeInstallations?.length">
                    <div *ngFor="let fee of broadbandInfo.oneTimeFeeInstallations" [ngClass]="{'d-flex justify-content-end':!isMobileDevice}">
                        <p class="mb-2"><span>{{fee.chargeRate | currency:'USD'}}</span>&nbsp;<span>{{fee.chargeName}}</span></p>
                    </div>
                    </div>
                </div>
                </div>

                <hr class="hr mb-3" aria-hidden="true">

                <div class="p-0 m-0" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.equipmentDeposite}}</p>
                    <p class="mb-2">{{broadbandInfo.equipmentDeposit}}</p>
                </div>
                <div class="p-0 m-0" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.lateFee}}</p>
                    <p class="mb-2">{{broadbandInfo?.lateFee | currency:'USD'}}</p>
                </div>
                <div class="p-0 m-0" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.earlyTerminationFee}}</p>
                    <p class="mb-2">{{broadbandInfo?.earlyTerminationFee | currency:'USD'}}</p>
                </div>

                <hr class="hr mb-3" aria-hidden="true">

                <h6>{{resourceBundle.discountsAndBundlesHeader}}</h6>
                <p class="mb-2">{{resourceBundle.available}}<a [href]='broadbandInfo.linkDetails.discountsBundles'
                        tabindex='0' target="_blank" class="text-decoration-underline-imp">{{resourceBundle.billingDiscountsAndPricingLink}}</a>{{resourceBundle.billingDiscountsAndPricingOpt}}
                </p>

                <hr class="hr mb-3" aria-hidden="true">

                <h6>{{resourceBundle.speedsProvided}}</h6>
                <div class="p-0 m-0" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.typicalDownloadSpeed}}</p>
                    <p class="mb-2">{{broadbandInfo?.speedPlans.downloadSpeed}} Mbps</p>
                </div>
                <div class="p-0 m-0" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.typicalUploadSpeed}}</p>
                    <p class="mb-2">{{broadbandInfo?.speedPlans.uploadSpeed}} Mbps</p>
                </div>
                <div class="p-0 m-0" [ngClass]="{'d-flex justify-content-between':!isMobileDevice}">
                    <p class="mb-2" [ngClass]="{'bold':isMobileDevice}">{{resourceBundle.typicalLatency}}</p>
                    <p class="mb-2">{{broadbandInfo?.speedPlans.latency}} ms</p>
                </div>

                <p class="mb-2">{{resourceBundle.typicalSpeedDesc}}</p>
                <hr class="hr mb-3" aria-hidden="true">

                <div class="d-flex justify-content-between p-0 m-0">
                    <h6>{{resourceBundle.dataIncludedWithPlanInGb}}</h6>
                    <h6>{{broadbandInfo?.dataIncludedWithMontlyPrice}}</h6>
                </div>
                <div class="d-flex justify-content-between p-0 m-0">
                    <p class="mb-2">{{resourceBundle.additionalDataChargesPerGb}}</p>
                    <p class="mb-2">{{broadbandInfo?.chargesForAdditionalDataUsage === 'N/A' ? broadbandInfo?.chargesForAdditionalDataUsage : ( broadbandInfo?.chargesForAdditionalDataUsage | currency:'USD')}}</p>
                </div>
                <hr class="hr mb-3" aria-hidden="true">

                <div class="d-flex justify-content-between p-0 m-0">
                    <h6>{{resourceBundle.networkManagement}}</h6>
                    <a [href]="broadbandInfo.linkDetails.networkManagement" target="_blank" class="text-decoration-underline-imp">{{resourceBundle.readOurPolicy}}</a>
                </div>
                <div class="d-flex justify-content-between p-0 m-0">
                    <h6>{{resourceBundle.privacy}}</h6>
                    <a [href]="broadbandInfo.linkDetails.privacy" target="_blank" class="text-decoration-underline-imp">{{resourceBundle.readOurPolicy }}</a>
                </div>
                <hr class="hr mb-3" aria-hidden="true">
                <h6>{{resourceBundle.customerSupport}}</h6>
                <p class="mb-2">{{resourceBundle.contactUs}} <a href='javascript:void(0)' tabindex='0' class="text-decoration-underline-imp">{{broadbandInfo.linkDetails.contactUs}}</a> or <a href='javascript:void(0)' tabindex='0' (click)="triggerTealium('Chat-with-us')" class="chat-trigger text-decoration-underline-imp">{{resourceBundle.chatWithUs}}</a></p>
                <hr class="hr mb-3" aria-hidden="true">
                <p class="mb-2">{{resourceBundle.learnMore}} <a [href]="broadbandInfo.linkDetails.fccgovconsumer" target="_blank" class="text-decoration-underline-imp">{{resourceBundle.fccgovconsumer}}</a></p>
                <p class="mb-2">{{broadbandInfo?.upi}}</p>
            </div>
            <div *ngIf="error" class="modal-body col-lg-12 col-md-12 col-sm-12 col-xs-12 font-size-p-16">
                <p class="mb-2" *ngIf="broadbandFactLabelNotAvailable && !broadbandFactLabelNetWorkErr">{{resourceBundle.broadbandFactLabelNotAvailableMsg}} <a [href]="resourceBundle.learnMoreLink" target="_blank" class="linkUnderline">{{resourceBundle.learnMoreText}}</a></p>
                <p class="mb-2" *ngIf="broadbandFactLabelNetWorkErr && !broadbandFactLabelNotAvailable">{{resourceBundle.unableToLoadbroadbandFactsMsg}}</p>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #skeletonTemplate>
    <div>
        <app-skeletonloader formName="bbfl" formSkeleton="true" rowCount="8">
        </app-skeletonloader>
    </div>
</ng-template>