import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, AppConfig, CBHelperService, PageHeaderService, WebexactivationService } from 'common-ui-lib';
import { MyaccountHomeService } from 'home/myaccounthome.service';
import { AccountInfo } from '../../pageheader/pageheader.model';
import { FeatureName } from '../sublanding-page-description.model';
import { ServiceDetailsService } from './service-details.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit {

  public sulaDescList: any = [];
  public isMobileDevice: boolean = false;
  public sulaList: any;
  public headerName: string;
  public accountAddress: any;
  public sulaFlag: boolean = false;
  public isLoading = true;
  public sulaCalnet: any;
  public calnetService: any;
  public sulaService: any;
  public voiceList: any = [];
  public tvList: any = [];
  public internetList: any = [];
  public securityList: any = [];
  public networkingList: any = [];
  public appsList: any = [];
  public webex: boolean;
  public guid = null;
  public resourceBundle: any;
  public modalLoaded = false;
  public consolidatedServiceAddress:string;
  @Input() isMultiAccount: boolean;
  @Input() sulaFeatureName: FeatureName;
  @Input() selectedAccInfo: AccountInfo;
  @ViewChild('serviceModal') public serviceModal: NgbModalRef;
  @ViewChild('selectServiceModal') public selectServiceModal: NgbModalRef;
  constructor(public serviceDetails: ServiceDetailsService,public activeModal: NgbActiveModal,
    private cbHelperService: CBHelperService,private aemClient: AEMClientService,
    public config: AppConfig,
    private myaccountHomeService: MyaccountHomeService,private webexactivationService: WebexactivationService,
    private modalService: NgbModal, private _storageService: LocalStorageService,
    private pageHeaderService: PageHeaderService,
    private deviceService: DeviceDetectorService) {
     }

  ngOnInit() {
      if (this.sulaFeatureName === FeatureName.VOICE || this.sulaFeatureName === FeatureName.TV ||
      this.sulaFeatureName === FeatureName.APPS || this.sulaFeatureName === FeatureName.SECURITY) {
        this.headerName= this.sulaFeatureName;
      } else{
        this.headerName= this.sulaFeatureName === FeatureName.NETWORKING ? 'Network' : 'Data';
      }
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }
    this.guid = this.selectedAccInfo.guid;
    const address = {
      street:this.cbHelperService.replaceNull(this.selectedAccInfo.accountExternalAttrs?.serviceAddress?.street),
      houseNumber:this.cbHelperService.replaceNull(this.selectedAccInfo.accountExternalAttrs?.serviceAddress?.houseNumber),
      city:this.cbHelperService.replaceNull(this.selectedAccInfo.accountExternalAttrs?.serviceAddress?.city),
      state:this.cbHelperService.replaceNull(this.selectedAccInfo.accountExternalAttrs?.serviceAddress?.state,true),
      zipCode: this.cbHelperService.replaceNull(this.selectedAccInfo.accountExternalAttrs?.serviceAddress?.zipCode,true)
    };
     this.consolidatedServiceAddress = `${address.street}${address.houseNumber}
     ${address.city}${address.state}${address.zipCode}`;
    this.accountAddress = this.selectedAccInfo.accountExternalAttrs.serviceAddress;
    this.getSulaDescription();
    if (this.sulaFeatureName === FeatureName.INTERNET) {
      const internetLandingPageResourceBundles =  this.aemClient.getMyaccountHomeBundle('internet_service_description')
          .subscribe((data: any) => {
              this.resourceBundle = data;
              this.myaccountHomeService.internetLandingPageResourceBundles = this.resourceBundle;
              this.sulaService = Object.assign([], this.myaccountHomeService.internetLandingPageResourceBundles.myservices.suladetails);
              this.calnetService = Object.assign([], this.myaccountHomeService.internetLandingPageResourceBundles.myservices.calnet);
              this.webex = this.webexactivationService.isWebexActivated();
          });
  } else {
      const subscription = this.aemClient.getMyaccountHomeBundle('landingPageResourceBundles')
          .subscribe((data: any) => {
              this.resourceBundle = data;
              this.myaccountHomeService.landingPageResourceBundles = this.resourceBundle;
              this.calnetService = Object.assign([], this.myaccountHomeService.landingPageResourceBundles.myservices.calnet);
              this.sulaService = Object.assign([], this.myaccountHomeService.landingPageResourceBundles.myservices.suladetails);
              this.webex = this.webexactivationService.isWebexActivated();
          });
      window.setTimeout(() => { document.getElementById('closeBtn').focus(); }, 0);
  }
}
  
  /* Sula Description call*/
  public getSulaDescription() {
    this.serviceDetails.getSulaDescription(this.guid).subscribe((results: any) => {
      this.modalLoaded = true;
      this.sulaDescList = [];
      let holidayDate: any;
      this.sulaList = Object.assign([], results?.data?.getAccount?.sulaDetails?.serviceGroupID);
      const serviceGroups = results?.data?.getAccount?.sulaDetails?.serviceGroupID;
      let tvArray: any = []; let internetArray: any = []; let voiceArray: any = []; let networkingArray: any = []; let securityArray: any = []; let appsArray: any = [];
      for (let i = 0; i < serviceGroups.length; i++) {
        if (serviceGroups[i].serviceGroup.length !== 0) {
          break;
        }
        else {
          this.sulaFlag = true;
        }
      }
      for (let i = 0; i < serviceGroups.length; i++) {
        if (serviceGroups[i].serviceGroup.length && serviceGroups[i].serviceGroupIDKey === 'TV') {
          tvArray = serviceGroups[i].serviceGroup;
        }
        if (serviceGroups[i].serviceGroup.length && serviceGroups[i].serviceGroupIDKey === 'Networking') {
          networkingArray = serviceGroups[i].serviceGroup;
        }
        if (serviceGroups[i].serviceGroup.length && serviceGroups[i].serviceGroupIDKey === 'Internet') {
          internetArray = serviceGroups[i].serviceGroup;
        }
        if (serviceGroups[i].serviceGroup.length && serviceGroups[i].serviceGroupIDKey === 'Security') {
          securityArray = serviceGroups[i].serviceGroup;
        }
        if (serviceGroups[i].serviceGroup.length && serviceGroups[i].serviceGroupIDKey === 'Voice') {
          voiceArray = serviceGroups[i].serviceGroup;
        }
        if (serviceGroups[i].serviceGroup.length && serviceGroups[i].serviceGroupIDKey === 'Apps') {
          appsArray = serviceGroups[i].serviceGroup;
        }
      }
      this.sulaCalnet = [];
      if (tvArray.length > 0 || internetArray.length > 0 || networkingArray.length > 0 || voiceArray.length > 0 || securityArray.length > 0 || appsArray.length > 0) {
        if (results.data.getAccount.sulaDetails.isCalNet === true) {
          this.sulaCalnet = this.calnetService;
        } else if (results.data.getAccount.sulaDetails.isCalNet === false) {
          this.sulaCalnet = this.sulaService;
        }
        if (this.sulaFeatureName === FeatureName.VOICE) {
          if(voiceArray.length !== 0){
          for (let j = 0; j < voiceArray.length; j++) {
            for (let i = 0; i < this.sulaCalnet.length; i++) {
              if (voiceArray[j].rank === this.sulaService[i].rank) {
                this.sulaService[i].scheduleDate = voiceArray[j].scheduleDate;
                holidayDate = new Date(this.sulaService[i].scheduleDate);
                const holiday = (holidayDate.getMonth() + 1) + '/' +
                  (holidayDate.getDate()) + '/' + (holidayDate.getFullYear());
                if (holiday.substring(0, 5) === '12/25') {
                  this.sulaService[i].holidayFlag = true;
                }
                if (this.sulaService[i].rank === 'V-1-23' && this.webex) {
                  this.sulaService[i].details = this.myaccountHomeService.landingPageResourceBundles.webexDetailsforProfessional[0].details;
                } else if (this.sulaService[i].rank === 'V-1-7' && this.webex) {
                  this.sulaService[i].details = this.myaccountHomeService.landingPageResourceBundles.webexDetailsforMobile[0].details;
                } else if (this.sulaService[i].rank === 'V-1-24' && this.webex) {
                  this.sulaService[i].details = this.myaccountHomeService.landingPageResourceBundles.webexIpCentrexSelectDetails[0].details;
                }
                this.sulaDescList.push(this.sulaService[i]);
              }
            }
          }
        }
        }
        if (this.sulaFeatureName === FeatureName.TV) {
          if(tvArray.length !== 0){
          for (let j = 0; j < tvArray.length; j++) {
            for (let i = 0; i < this.sulaCalnet.length; i++) {
              if (tvArray[j].rank === this.sulaService[i].rank) {
                this.sulaService[i].scheduleDate = tvArray[j].scheduleDate;
                holidayDate = new Date(this.sulaService[i].scheduleDate);
                const holiday = (holidayDate.getMonth() + 1) + '/' +
                  (holidayDate.getDate()) + '/' + (holidayDate.getFullYear());
                if (holiday.substring(0, 5) === '12/25') {
                  this.sulaService[i].holidayFlag = true;
                }
                this.sulaDescList.push(this.sulaService[i]);
              }
            }
          }
        }
        }
        if (this.sulaFeatureName === FeatureName.NETWORKING) {
          if(networkingArray.length !== 0){
          for (let j = 0; j < networkingArray.length; j++) {
            for (let i = 0; i < this.sulaCalnet.length; i++) {
              if (networkingArray[j].rank === this.sulaService[i].rank) {
                this.sulaService[i].scheduleDate = networkingArray[j].scheduleDate;
                holidayDate = new Date(this.sulaService[i].scheduleDate);
                const holiday = (holidayDate.getMonth() + 1) + '/' +
                  (holidayDate.getDate()) + '/' + (holidayDate.getFullYear());
                if (holiday.substring(0, 5) === '12/25') {
                  this.sulaService[i].holidayFlag = true;
                }
                this.sulaDescList.push(this.sulaService[i]);
              }
            }
          }
        }
        }
        if (this.sulaFeatureName === FeatureName.INTERNET) {
          let count = 0;
          if (internetArray.length !== 0) {
            for (let j = 0; j < internetArray.length; j++) {
              if (internetArray[j].rank === 'IG-5-4' ||  internetArray[j].rank === 'IG-5-5'){
                count++;
              }
            }  
              for (let j = 0; j < internetArray.length; j++) {
                  for (let i = 0; i < this.sulaCalnet.length; i++) {
                      if (internetArray[j].rank === this.sulaService[i].rank) {
                          holidayDate = new Date(this.sulaService[i].scheduleDate);
                          const holiday = (holidayDate.getMonth() + 1) + '/' +
                              (holidayDate.getDate()) + '/' + (holidayDate.getFullYear());
                          if (holiday.substring(0, 5) === '12/25') {
                              this.sulaService[i].holidayFlag = true;
                          }
                          this.sulaService[i].scheduleDate = internetArray[j].scheduleDate;
                        if (count === 2 && this.sulaService[i].rank === 'IG-5-4') {
                          continue;
                        } else {
                          this.sulaDescList.push(this.sulaService[i]);
                        }
                      }
                  }
              }
          }
      }
      
        if (this.sulaFeatureName === FeatureName.APPS) {
          if(appsArray.length !== 0){
          for (let j = 0; j < appsArray.length; j++) {
            for (let i = 0; i < this.sulaCalnet.length; i++) {
              if (appsArray[j].rank === this.sulaService[i].rank) {
                holidayDate = new Date(this.sulaService[i].scheduleDate);
                const holiday = (holidayDate.getMonth() + 1) + '/' +
                  (holidayDate.getDate()) + '/' + (holidayDate.getFullYear());
                if (holiday.substring(0, 5) === '12/25') {
                  this.sulaService[i].holidayFlag = true;
                }
                this.sulaService[i].scheduleDate = appsArray[j].scheduleDate;
                this.sulaDescList.push(this.sulaService[i]);
              }
            }
          }
        }
        }
        if (this.sulaFeatureName === FeatureName.SECURITY) {
          if(securityArray.length !== 0){
          for (let j = 0; j < securityArray.length; j++) {
            for (let i = 0; i < this.sulaCalnet.length; i++) {
              if (securityArray[j].rank === this.sulaService[i].rank) {
                holidayDate = new Date(this.sulaService[i].scheduleDate);
                const holiday = (holidayDate.getMonth() + 1) + '/' +
                  (holidayDate.getDate()) + '/' + (holidayDate.getFullYear());
                if (holiday.substring(0, 5) === '12/25') {
                  this.sulaService[i].holidayFlag = true;
                }
                this.sulaService[i].scheduleDate = securityArray[j].scheduleDate;
                this.sulaDescList.push(this.sulaService[i]);
              }
            }
          }
        }
        }
      }
    });
    this.modalLoaded = false;
  }

  public close(event: any) {
    this.activeModal.close(event);
    document.getElementById('serviceDetailsModal').focus();
  }

}
