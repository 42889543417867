import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-methodofpayment-modal',
  template: `
      <app-redesignmethodofpayments (modalClose)="close($event)" [restrictedMop]="restrictedMop" [isSPMAccount]="isSPMAccount"
      [availableMopList]="availableMopList" [mopLoaded]="mopLoaded"></app-redesignmethodofpayments>
  `,
})
export class MethodOfPaymentModal {
  @Input() restrictedMop: string;
  @Input() availableMopList: [any];
  @Input() mopLoaded: boolean = false;
  @Input() isSPMAccount:boolean;
  constructor(public activeModal: NgbActiveModal) { }
  // tslint:disable-next-line: no-any
  close(event: any) {
    this.activeModal.close(event);
  }
}
