<div>
  <ng-container [ngTemplateOutlet]="serverPaginate ? serverSidePagination : clientSidePagination"></ng-container>
</div>


<ng-template #serverSidePagination>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 flex-wrap mt-5 mb-5" [ngClass]="{ 'display-block' : isTablet, 'display-flex': !isTablet, 'margin-left-10em': nonCentredPaginator && isLargeScreen,
    'div-center-align justify-content-center': (!nonCentredPaginator || (nonCentredPaginator && isMobile))}">
    <div class="pt-3" [id]="options.id"
      [ngClass]="{'min-width-50': ((isDesktop || isLargeScreen) && nonCentredPaginator), 'text-center': (isTablet && !nonCentredPaginator),'mr-3': ((isDesktop || isLargeScreen) && !nonCentredPaginator)}">
      <pagination-controls [id]="options.id" [maxSize]="options.maxSize" [directionLinks]="options.directionLinks"
        [autoHide]="options.autoHide" [responsive]="options.responsive" [previousLabel]="options.previousLabel"
        [nextLabel]="options.nextLabel" [screenReaderPaginationLabel]="options.screenReaderPaginationLabel"
        [screenReaderPageLabel]="options.screenReaderPageLabel"
        [screenReaderCurrentLabel]="options.screenReaderCurrentLabel" (pageChange)="onPageChanges($event)">
      </pagination-controls>
    </div>
    <div class="display-flex flex-wrap" [ngClass]="{
      'justify-content-center': (isTablet && !nonCentredPaginator) || isMobile,
      'div-center-align': (!nonCentredPaginator || (nonCentredPaginator && isMobile))}">
      <div *ngIf="options.showGoto" [ngClass]="{'margin-left-10': (!nonCentredPaginator || (nonCentredPaginator && (isMobile || isLargeScreen))),
        'margin-right-10': (!nonCentredPaginator || (nonCentredPaginator && (isMobile || isTablet))),
        'margin-right-10em': (nonCentredPaginator && (isDesktop || isLargeScreen))}">
        <label for="gotopage" class="gotostyle">{{resourceBundle.goTo}}</label>
        <input id="gotopage" [(ngModel)]="selectedPage" type="text" maxlength="7"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');" class="width-6 margin-left-10" />
        <button type="button"
          [class]="isMobile ? 'button p-2 margin-left-10 width-3 max-width-3' : 'button p-2 pl-3 pr-3 margin-left-10 max-width-3-5'"
          [disabled]="!selectedPage" (click)="onGoToPageClick()">
          Go
        </button>
      </div>

      <div class="margin-right-10" *ngIf="options.showItemsPerpage" #pagination id="pagination"
        [ngClass]="{'margin-left-10 ml-2': (!nonCentredPaginator || (nonCentredPaginator && isMobile))}">
        <label for="records" class="gotostyle">{{resourceBundle.itemsPerPage}}</label>
        <select name="records" id="records" (change)="pageItemCount($event.target.value)"
          class="width-3-5em margin-left-10 padding-5" [(ngModel)]="options.itemsPerPage">
          <ng-container *ngFor="let item of pageOptions">
            <option [value]="item">{{ item }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #clientSidePagination>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 flex-wrap mt-5 mb-5" [ngClass]="{ 'display-block' : isTablet, 'display-flex': !isTablet, 'margin-left-10em': nonCentredPaginator && isLargeScreen,
    'div-center-align justify-content-center': (!nonCentredPaginator || (nonCentredPaginator && isMobile))}">
    <div [id]="options.id"
      [ngClass]="{'min-width-50': ((isDesktop || isLargeScreen) && nonCentredPaginator), 'text-center': (isTablet && !nonCentredPaginator),'mr-3': ((isDesktop || isLargeScreen) && !nonCentredPaginator)}">
      <datatable-pager class="client-side-pagination" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="totalRows"
        (change)="dataTable.onFooterPage($event)">
      </datatable-pager>
    </div>
    <div class="display-flex flex-wrap" [ngClass]="{
      'justify-content-center': (isTablet && !nonCentredPaginator) || isMobile,
      'div-center-align': (!nonCentredPaginator || (nonCentredPaginator && isMobile))}">
      <div *ngIf="options.showGoto" [ngClass]="{'margin-left-10': (!nonCentredPaginator || (nonCentredPaginator && (isMobile || isLargeScreen))),
        'margin-right-10': (!nonCentredPaginator || (nonCentredPaginator && (isMobile || isTablet))),
        'margin-right-10em': (nonCentredPaginator && (isDesktop || isLargeScreen))}">
        <label for="gotopage" class="gotostyle">{{resourceBundle.goTo}}</label>
        <input id="gotopage" [(ngModel)]="selectedPage" type="text" maxlength="7"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');" class="width-6 margin-left-10" />
        <button type="button"
          [class]="isMobile ? 'button p-2 margin-left-10 width-3 max-width-3' : 'button p-2 pl-3 pr-3 margin-left-10 max-width-3-5'"
          [disabled]="!selectedPage" (click)="onGoToPageClick()">
          Go
        </button>
      </div>

      <div class="margin-right-10" *ngIf="options.showItemsPerpage" #pagination id="pagination"
        [ngClass]="{'margin-left-10 ml-2': (!nonCentredPaginator || (nonCentredPaginator && isMobile))}">
        <label for="records" class="gotostyle">{{resourceBundle.itemsPerPage}}</label>
        <select name="records" id="records" (change)="pageItemCount($event.target.value)"
          class="width-3-5em margin-left-10 padding-5" [(ngModel)]="options.itemsPerPage">
          <ng-container *ngFor="let item of pageOptions">
            <option [value]="item">{{ item }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
</ng-template>