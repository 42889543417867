import { Injectable, NgZone, Optional } from '@angular/core';
import { LocalStorageKeys } from 'common-ui-lib';
import { LocalStorageService } from "ngx-localstorage";
import {
    from,
    fromEvent,
    interval,
    merge,
    Observable,
    of,
    Subject,
    Subscription,
    timer,
// tslint:disable-next-line: import-blacklist
} from 'rxjs';
import {
    bufferTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    scan,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { AppConfig } from '../core/app.config';

/**
 * User's idle service.
 */
@Injectable({
    providedIn: 'root',
})
export class UserIdleService {

    /**
     * Events that can interrupts user's inactivity timer.
     */
    protected activityEvents$: Observable<Event>;

    protected timerStart$ = new Subject<boolean>();
    protected idleDetected$ = new Subject<boolean>();
    protected timeout$ = new Subject<boolean>();
    protected idle$: Observable<Event>;
    protected timer$: Observable<number>;
    /**
     * Idle value in milliseconds.
     * Default equals to 10 minutes.
     */
    protected idleMillisec = 600 * 1000;
    /**
     * Idle buffer wait time milliseconds to collect user action
     * Default equals to 1 Sec.
     */
    protected idleSensitivityMillisec = 1000;
    /**
     * Timeout value in seconds.
     * Default equals to 5 minutes.
     */
    protected timeout = 300;
    /**
     * Timeout status.
     */
    protected isTimeout: boolean;
    protected waitForTimeout: boolean;
    /**
     * Timer of user's inactivity is in progress.
     */
    protected isInactivityTimer: boolean;
    private get isIdleDetected(): boolean {
        //return localStorage.getItem(LocalStorageKeys.IdleDetected) === 'true';
        return  this._storageService.get(LocalStorageKeys.IdleDetected, 'cbma') === 'true';
    }

    private set isIdleDetected(value: boolean) {
       // localStorage.setItem(LocalStorageKeys.IdleDetected, value.toString());
       this._storageService.set(LocalStorageKeys.IdleDetected, value.toString());
    }

    protected idleSubscription: Subscription;

    public userLogoutSystemTime = 0;

    constructor(private _ngZone: NgZone, private config: AppConfig, private _storageService: LocalStorageService,) {
        if(this.config.getConfig('userIdle')?.idle && this.config.getConfig('userIdle')?.timeout){
            this.idleMillisec = this.config.getConfig('userIdle')['idle'] * 1000;
            this.timeout = this.config.getConfig('userIdle')['timeout'];
        }
    }

    /**
     * Start watching for user idle and setup timer and ping.
     */
    startWatching() {
        this.isIdleDetected = false;
        this.waitForTimeout = false;
        if (!this.activityEvents$) {
            this.activityEvents$ = merge(
                fromEvent(window, 'mousemove'),
                fromEvent(window, 'resize'),
                fromEvent(document, 'keydown'),
            );
        }

        this.idle$ = from(this.activityEvents$);
        this.idle$.subscribe(() => {
            this.isIdleDetected = false;
            this.waitForTimeout = false;
            this.idleDetected$.next(false);
        });
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }

        // If any of user events is not active for idle-seconds when start timer.
        this.idleSubscription = this.idle$
            .pipe(
                bufferTime(this.idleSensitivityMillisec), // Starting point of detecting of user's inactivity
                filter(
                    (arr) => !arr.length && !this.isInactivityTimer && (!this.isIdleDetected || !this.waitForTimeout),
                ),
                tap(() => {
                    this.isIdleDetected = true;
                    console.log('OKTAFLOW - startWatching Idle Detected :', new Date(),
                        this.idleMillisec, this.timeout);
                    this.idleDetected$.next(true);
                    this.waitForTimeout = true;
                }),
                switchMap(() => {
                    return this._ngZone.runOutsideAngular(() =>
                        interval(1000).pipe(
                            takeUntil(
                                merge(
                                    this.activityEvents$,
                                    timer(this.idleMillisec).pipe(
                                        tap(() => {
                                            this.isInactivityTimer = true;
                                            this.timerStart$.next(true);
                                        }),
                                    ),
                                ),
                            ),
                        ),
                    );
                }),
            )
            .subscribe();
        this.setupTimer(this.timeout);
    }

    stopWatching() {
        this.stopTimer();
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
    }

    stopTimer() {
        this.isInactivityTimer = false;
        this.timerStart$.next(false);
    }

    resetTimer() {
        this.stopTimer();
        this.isTimeout = false;
    }

    /**
     * Return observable for timer's countdown number that emits after idle.
     */
    onTimerStart(): Observable<number> {
        return this.timerStart$.pipe(
            distinctUntilChanged(),
            switchMap((start) => (start ? this.timer$ : of(null))),
        );
    }

    /**
     * Return observable for idle status changed
     */
    onIdleStatusChanged(): Observable<boolean> {
        return this.idleDetected$.asObservable();
    }

    /**
     * Return observable for timeout is fired.
     */
    onTimeout(): Observable<boolean> {
        return this.timeout$.pipe(
            filter((timeout) => !!timeout),
            tap(() => (this.isTimeout = true)),
            map(() => true),
        );
    }

    /**
     * Setup timer.
     *
     * Counts every seconds and return n+1 and fire timeout for last count.
     * @param timeout Timeout in seconds.
     */
    protected setupTimer(timeout: number) {
        this._ngZone.run(() => {
            this.timer$ = interval(1000).pipe(
                take(timeout),
                map(() => 1),
                scan((acc, n) => acc + n),
                tap((count) => {
                    console.log('OKTAFLOW - setupTimer runOutsideAngular:', count, timeout, this.userLogoutSystemTime,
                    this.userLogoutSystemTime - new Date().getTime(), new Date().getTime() > this.userLogoutSystemTime);
                    if ((this.userLogoutSystemTime !== 0 && new Date().getTime() > this.userLogoutSystemTime)
                        || count === timeout) {
                        this.userLogoutSystemTime = 0;
                        console.log('OKTAFLOW - setupTimer runOutsideAngular subscribed:', this.userLogoutSystemTime);
                        this.timeout$.next(true);
                    }
                }),
            );
        });
    }
}
