<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 justify-content-space-between"
    [ngClass]="{'d-flex': !(isMobileDevice || isTabletDevice)}">
    <ng-container *ngIf="mapContentLoaded; else searchFilterLoader">
      <div *ngIf="!isFormerAcct && !hideSearchFilter" class="digitalProfile-search-filter col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-4 pl-0">
        <app-search-filter [rows]="searchObj" (filteredRows)="getSearchResults($event)"
          (filterTags)="getFiltertags($event)" [resetSearch]="resetSearch" [searchString]="addressSelected" [filterName]="'Services'" [clearFilterTag]="deleteTag"
          [filterLabel]="'Advanced Filter'"
          [ngClass]="{'recent-trans-mobile-search': deviceService.isMobile()}"></app-search-filter>
        <ng-container *ngIf="showSearchDropdown">
          <ng-template [ngTemplateOutlet]="searchView"></ng-template>
        </ng-container>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pl-0 pebble-grey font-size-p-14 mb-3" *ngIf="isFormerAcct">
        {{resourceBundle.noActiveLocations}}</div>
      <div *ngIf="lastFetchedDateTime" class="lh-16 text-bold-400 font-size-p-14 pr-1"
        [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0 pl-0': (isMobileDevice || isTabletDevice)}">
        <div *ngIf="refreshFailed"><span class="red">{{resourceBundle.unableToRefresh}}</span>
          <a href="javascript:void(0)" (click)="refreshLargeProfileData()"
            class="lh-16 pl-1 linkUnderline text-bold-400 font-size-p-14">{{resourceBundle.retryLink}}
          </a>
        </div>
        <div class="margin-top-0-7 pl-0 d-flex lh-16 text-bold-400 font-size-p-14" [ngClass]="{'pb-4': isMobileDevice || isTabletDevice, 'margin-top-0-7': !refreshFailed, 'margin-top-6': refreshFailed,
        'pt-1': refreshFailed && isMobileDevice}">
          <div class="pl-0 pr-0 row-text-overflow">
            {{refreshFailed ? resourceBundle.showAsOf : resourceBundle.timeStamp}}
            <span [title]="lastFetchedDateTime | dateTime:false">{{lastFetchedDateTime |
              dateTime:false}}</span>
          </div>
          <div class="pull-right" *ngIf="!refreshFailed">
            <a href="javascript:void(0)" (click)="refreshLargeProfileData()"
              class="lh-16 linkUnderline text-bold-400 margin-left-10px font-size-p-14 pb-1">
              {{resourceBundle.refresh}}<img src="assets/images/blueprint/digitalprofile/refresh-black.svg"
                class="pl-1 pr-1 margin-top-neg-0-4" role="presentation" alt="refresh icon" />
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!isFormerAcct && !hideSearchFilter" class="mb-4">
    <app-filtertags id="cbMaps" [selectedTags]="turnedOnfiltertags"
      (clearFilterTag)="clearFilterTag($event)"></app-filtertags>
    <div *ngIf="noServiceLocationsMsg" class="margin-top-10px">{{noServiceLocationsMsg}}</div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-n3 mb-1 cb-maps-error"
    *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id === 'cbMaps'">
    <app-commonmessage
      *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id === 'cbMaps'"
      [type]="commonMessageService.displayMessage.type" [globalMessage]="commonMessageService.displayMessage.message"
      [level]="'global'">
    </app-commonmessage>
  </div>
  <div class="map-container display-flex" [ngClass]="{'flex-column-reverse': isMobileDevice || isTabletDevice}">
    <ng-container *ngIf="mapContentLoaded; else mapLoader">
      <div class="info-window" *ngIf="showInfoWindow">
        <div class="text-right pr-1 close-background">
          <a id="close-infoWindow" class="cb-modal-close-btn mt-n1 mb-1" href="javascript:void(0)" role="button"
            attr.aria-label="{{infoWindowDetails?.name}} info window close"
            (click)="closeInfoWindow(infoWindowDetails)">
          </a>
        </div>
        <div class="pad-left-p-20 pad-right-p-20 pad-bottom-15 pad-top-p-10">
          <a *ngIf="infoWindowDetails?.multiSuite" href="javascript:void(0)"
            (click)="backToAddressList(infoWindowDetails)" class="text-bold-400 font-size-p-14 linkUnderline 
            margin-top-15" [attr.aria-label]="resourceBundle.backLinkAriaLabel">
            <span class="arrow-next-container mr-2">
              <img src="assets/images/blueprint/arrow_prev.svg" alt="arrow prev icon" height="8.39" width="4.6"
                class="mb-1" />
            </span>{{resourceBundle.backLink}}
          </a>
          <div class="col-12 pl-0 pr-0 pb-3 row-text-overflow font-size-p-18 line-height-24px m-0 bold"
            title="{{infoWindowDetails?.name}}" [ngClass]="{'margin-top-10px': infoWindowDetails?.multiSuite}">
            {{infoWindowDetails?.name}}
          </div>
          <div class="two-line-ellipsis font-size-p-14" title="{{infoWindowDetails?.address?.fullAddress}}">
            {{infoWindowDetails?.address?.fullAddress}}</div>

          <div *ngIf="!healthCheckSpinner">
            <ng-container *ngFor="let outage of outageInfoDetails">
              <div class="display-flex pad-top-p-20">
                <img class="margin-top-0-19 height-24 margin-right-10" [src]="outage?.imgUrl" [alt]="outage?.imgAlt"
                  width="24" height="24" />
                <div class="mt-1 font-cerapro-medium row-text-overflow">
                  {{outage?.header}}
                </div>
              </div>
              <div class="pad-top-p-20 font-size-p-14">
                <ng-container *ngIf="!onlyInternetServiceAvailable">                
                  <div class="col-12 pl-0 pr-0 pad-bottom-p-10 info-window-lh"
                    *ngIf="!(outage?.type === outageType.PLANNED)">
                      {{outage?.subHeader}}
                  </div>                
                  <a href="javascript:void(0)" *ngIf="outage?.type === outageType.NOOUTAGE"
                    (click)="triggerTealium(resourceBundle.link)"
                    class="text-bold-400 card-link linkUnderline pad-top-p-10 chat-trigger"
                    [ngClass]="{'disabled': cbHelper.isMyAdmin()}" [attr.aria-disabled]="cbHelper.isMyAdmin()"
                    [attr.aria-label]="outage?.label" [tabindex]="cbHelper.isMyAdmin() ? '-1' : '0'">
                    {{outage?.link}}
                    <span class="arrow-next-container">
                      <img *ngIf="!cbHelper.isMyAdmin()" src="assets/images/blueprint/arrow_next.svg"
                        alt="arrow next icon" height="8.39" width="4.6" class="margin-left-10-px mb-1" />
                      <img *ngIf="cbHelper.isMyAdmin()" src="assets/images/blueprint/arrow_disabled_next.svg"
                        alt="arrow next icon" height="8.39" width="4.6" class="margin-left-10-px mb-1" />
                    </span>
                  </a>
                </ng-container>
                <!-- Sub Header Text and button for Device Support with Internet Service only-->
                <ng-container *ngIf="onlyInternetServiceAvailable">
                    <div class="col-12 pl-0 pr-0 pad-bottom-p-10 info-window-lh font-size-12px margin-top-neg-10">
                      {{outage?.deviceSupportSubHeader}}
                    </div>
                    <button class="button button-primary btn-long-text" [aria-label]="outage?.buttonText" (keydown)="onClickDeviceSupport($event)" (click)="redirectToInternet()">
                      {{outage?.buttonText}}
                    </button>                  
                </ng-container>
                <div class="pad-top-p-10 info-window-lh pad-bottom-30" *ngIf="(outage?.type === outageType.UNPLANNED)">
                  <span class="font-size-p-14 font-weight-700">{{resourceBundle.estRepairTime}}: </span>
                  <span class="font-size-p-14">{{outage?.etr}}</span>
                </div>
                <div class="pad-top-p-10 info-window-lh pad-bottom-30" *ngIf="(outage?.type === outageType.PLANNED)">
                  <span class="font-size-p-14 font-weight-700">{{resourceBundle.dateTimeOfOutage}}: </span>
                  <span class="font-size-p-14">{{outage?.outageDate}}</span>
                </div>
                <div *ngIf="!(outage?.type === outageType.NOOUTAGE
                || (outage?.type === outageType.UNPLANNED && outage.chronicOutage))">
                  <ngb-accordion #outageDetailsAccordion="ngbAccordion" [closeOthers]="true" id="outage-details"
                    (hidden)="resetPanel(outage?.type)" class="cb-accordions no-highlight">
                    <ngb-panel>
                      <ng-template ngbPanelTitle>
                        <div class="accordion-trigger chevron-right">
                          <span class="line-height-22px black p-0">{{outage?.link}}</span>
                        </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <div class="text-bold-400 pad-bottom-p-10" *ngIf="outage?.type === outageType.PLANNED">
                          {{outage?.subHeader}}
                        </div>
                        <div class="text-bold-400 line-height-18 margin-top-8"
                          *ngIf="outage?.type === outageType.UNPLANNED && !outage.chronicOutage">
                          <div class="margin-botton-20px">
                            {{outage?.notificationFlag ?
                            resourceBundle.enrolledUnplannedDesc :
                            resourceBundle.unenrolledUnplannedDesc}}
                          </div>
                          <form #updatesForm="ngForm">
                            <input type="tel" class="col-12" [(ngModel)]="editedMobileNumber" id="mobileNumber"
                              appPhoneMask placeholder="XXX-XXX-XXXX"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                              maxlength="12">
                          </form>
                          <div class="text-bold-400 lh-22 text-font-16 enroll-updates">
                            <button id="submit" class="button btn-long-text margin-top-20 track-clicks" value = "'Submit'"
                              [aria-label]="resourceBundle.submitBtnAriaLabel" [disabled]="editedMobileNumber?.length < 12 ||
                                ( outage?.notificationFlag && 
                                  editedMobileNumber ===  outage?.enrolledMobileNumber
                                )" (click)="showSaveMobileNumberModal()">
                              {{resourceBundle.submitBtn}}
                            </button>
                            <a id="terms" tabindex="0" href="javascript:void(0)"
                              class="inkUnderline display-flex">{{resourceBundle.terms}}
                              <span class="arrow-next-container margin-left-10-px">
                                <img src="assets/images/blueprint/arrow_next.svg" alt="arrow next icon" width="4.6"
                                  height="8.39" class="mb-1" />
                              </span>
                            </a>
                          </div>
                        </div>
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
                </div>
              </div>
            </ng-container>

          </div>

          <div class="pad-top-p-20" *ngIf="healthCheckSpinner">
            <div class="phone-equipment-header text-center font-weight-700 font-size-p-14">
              {{resourceBundle.checkNetworkHealth}}</div>
            <div class="health-check-spinner height-100px mb-5">
              <app-loader [isLoading]="true" [isSecondary]="true"></app-loader>
            </div>
          </div>

          <div class="margin-top-30" *ngIf="infoWindowDetails?.assets?.length">
            <div class="font-weight-700 font-size-p-14">Services</div>
            <ul class="assets-list list-unstyled">
              <li *ngFor="let asset of infoWindowDetails?.assets" class="pt-3 pb-3"
                (click)="redirectToServices(asset?.assetFamily,asset?.feature)">
                <ng-container>
                  <div *ngIf="asset?.status !== 'INPROGRESS'" class="mouse-link" tabindex="0" (keydown.enter)="redirectToServices(asset?.assetFamily,asset?.feature)">
                    <a href="javascript:void(0)" [attr.aria-label]="asset?.assetName" tabindex="-1"
                    class="button-color ucappTxt font-weight-700">{{asset?.assetName | uppercase}}<img
                      src="assets/images/blueprint/arrow_next_blue.svg" alt="arrow next icon" height="9" width="5"
                      class="pull-right mt-2 margin-left-10-px mb-1" role="presentation" /></a>
                  </div>
                  <div class="d-flex justify-content-space-between" *ngIf="asset?.status === 'INPROGRESS'">
                    <span class="pebble-grey ucappTxt font-weight-700">{{asset?.assetName | uppercase}}</span>
                    <span class="pebble-grey ucappTxt font-weight-700">{{resourceBundle.comingSoon}}</span>
                  </div>
                </ng-container>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="info-window" *ngIf="showAddressListWindow">
        <div class="text-right pr-1 close-background">
          <a class="cb-modal-close-btn mt-n1 mb-1" href="javascript:void(0)" role="button"
            attr.aria-label="Address list window close" id="close-addressList" (click)="closeAddressListWindow()">
          </a>
        </div>
        <div class="col-12 pb-3 margin-top-10 font-size-p-18 line-height-24px m-0 font-weight-700"
          title="{{resourceBundle.serviceLocationAtAddress}}">
          {{resourceBundle.serviceLocationAtAddress}}
        </div>
        <ul class="address-list list-unstyled line-height-18">
          <li *ngFor="let location of clusterServiceLocations" class="font-size-p-14 text-bold-400">
            <div class="display-flex margin-top-10 margin-bottom-10">
              <div class="col-2 pr-0 pl-0 dead-center">
                <img *ngIf="location?.outageDetails?.imgUrl" [src]="location?.outageDetails?.imgUrl"
                  [alt]="location?.outageDetails?.imgAlt" height="24" width="24" />
              </div>
              <div class="col-8 pl-2 pr-2">
                <div class="four-line-ellipsis">
                  <span [title]="location?.name">
                    {{location?.name}}
                  </span><br>
                  <span [title]="location?.address?.fullAddress">
                    {{location?.address?.fullAddress}}
                  </span>
                </div>
              </div>
              <div class="col-2 pl-0" [ngClass]="{'pr-0 dead-center': !isTabletDevice,
               'dead-end div-center-align pr-3': isTabletDevice}">
                <a href="javascript:void(0)" (click)="onClickOfSuiteAddress(location)"
                  [attr.aria-label]="'View details of the suite' + location?.address?.fullAddress">
                  <img src="assets/images/blueprint/arrow-right-large-blue.svg" alt="arrow next icon" />
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </ng-container>
    <div #cbMap class="cb-map" tabindex="0" role="application" aria-labelledby="map"></div>
    <span class="sr-only" id="map">This is Google Maps with Service Locations</span>
  </div>
</div>

<ng-template #mapLoader>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 map-loader">
    <app-skeletonloader freeformSkeleton="true" count="1" sectionName="Map Loader" heightSizeInPixels="550"
      widthSizeInPixels="{{loaderWidth}}">
    </app-skeletonloader>
  </div>
</ng-template>

<ng-template #searchFilterLoader>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
    <app-skeletonloader class="cb-loader-border" freeformSkeleton="true" count="1"
      sectionName="Search and Filter Loader" heightSizeInPixels="46" widthSizeInPixels="{{loaderWidth}}"
      borderRadiusInPixels="3px">
    </app-skeletonloader>
  </div>
</ng-template>

<ng-template #searchView>
  <div id="searchContainer" class="searchContainer" 
    [ngClass]="(serviceLocationsList?.length > 0 || searchValue?.length > 0) ? 'autocomplete' : ''">
    <ng-container *ngIf="serviceLocationsList">
      <ul id="mapSearchList" #filterList class="sub-list">
        <li tabindex="0" role="alert" *ngIf="serviceLocationsList?.length === 0 && searchValue?.length >= 1"
          class="noSearchData pl-2 pr-2">{{resourceBundle.noSearchResults}}</li>
        <li tabindex="0" *ngFor="let result of serviceLocationsList" (click)="selectedResult(result)">
          <a *ngIf="result?.address" class="ml-2 listValue">{{result?.address?.fullAddress}}
          </a>
        </li>
      </ul>
    </ng-container>
  </div>
</ng-template>

<!-- Save Mobile Number Modal Popup -->
<ng-template #saveMobileNumberModal let-close="close" let-dismiss="dismiss">
  <div role="dialog" aria-modal="true" aria-describedby="modal-title">
    <h4 class="sr-only" id="modal-title">
      {{resourceBundle.saveMobileModalTitleAriaLabel}}
    </h4>
    <div class="modal-header modalheader" [ngClass]="{'display-flex': isMobileDevice}">
      <div [ngClass]="{'col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0': !isMobileDevice}">
        <h4 class="modal-title h4">
          {{resourceBundle.saveMobileModalTitle}}
        </h4>
      </div>
      <div class="text-right" [ngClass]="{'col-lg-1 col-md-1 col-sm-1 col-xs-1': !isMobileDevice}">
        <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn"
          (click)="close('Close click')" appModalfocus>
        </a>
      </div>
    </div>
    <div class="modal-body py-0 margin-bottom-40px"
      [ngClass]="{'margin-top-40px': !commonMessageService.displayMessage.show}">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-n2">
        <app-commonmessage [type]="commonMessageService.displayMessage.type"
          [globalMessage]="commonMessageService.displayMessage.message" [level]="'globalPage'" *ngIf="commonMessageService.displayMessage.show
          && commonMessageService.displayMessage.id === 'unplannedOutage'">
        </app-commonmessage>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <span>
            {{ mobileNumber ?
            stringUtilService.formatByPlaceHolder(
            resourceBundle.replaceMobileNumberDesc,[editedMobileNumber, mobileNumber]) :
            stringUtilService.formatByPlaceHolder(
            resourceBundle.addMobileNumberDesc,[editedMobileNumber]) }}
          </span>
        </div>
      </div>
    </div>
    <hr class="hr my-0">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0
          justify-content-center display-flex" [ngClass]="{'flex-column-reverse align-items-center': isMobileDevice}">
      <button class="button button-secondary btn-long-text" [ngClass]="{'wide90 mt-0': isMobileDevice}"
        (click)="enrollPhoneNumber(false)" [aria-label]="resourceBundle.continueWoSavingBtn">
        {{resourceBundle.continueWoSavingBtn}}
      </button>
      <button class="button btn-long-text" [ngClass]="isMobileDevice ? 'wide90' : 'margin-left-20 mr-0'"
        [aria-label]="resourceBundle.saveAndContinueBtn" (click)="enrollPhoneNumber(true)">
        {{resourceBundle.saveAndContinueBtn}}
      </button>
    </div>
  </div>
</ng-template>
