import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService, AEMMessagesService, CBHelperService, CommonMessageService,
  ResourceBundle, TealiumUtagService
} from 'common-ui-lib';
import { DeviceDetectorService } from 'ngx-device-detector';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-fttx-alert',
  templateUrl: './fttx-alert.component.html',
  styleUrls: ['./fttx-alert.component.scss']
})
export class FttxAlertComponent implements OnInit {

  public isMobileDevice = false;
  public resourceBundle: ResourceBundle = {};
  public contentLoad = false;
  public fiberInternetRows = [];
  public fiberInternetDetails = [];

  constructor(
    public aemClient: AEMClientService,
    private deviceService: DeviceDetectorService,
    public messageService: AEMMessagesService,
    public activeModal: NgbActiveModal,
    public tealium: TealiumUtagService,
    public commonMessageService: CommonMessageService,
    public cbHelper: CBHelperService
  ) { }

  ngOnInit() {
    this.aemClient.getSharedBundle('promotionalalert')
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
        }
      );
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }
    this.commonMessageService.displayMessage.show = false;
    this.getFiberInternetDetails();
  }

  public getFiberInternetDetails() {
    this.messageService.getFttxList()
      .pipe(finalize(() => {
        this.contentLoad = true;
      }))
      .subscribe((results: any) => {
        if (!results.errors) {
          this.fiberInternetRows = [];
          this.fiberInternetDetails = results.data?.listOutages;
          this.fiberInternetDetails.forEach(element => {
            const address = {
              street: element?.accountExternalAttrs?.serviceAddress?.street,
              houseNumber: element?.accountExternalAttrs?.serviceAddress?.houseNumber,
              city: element?.accountExternalAttrs?.serviceAddress?.city,
              state: element?.accountExternalAttrs?.serviceAddress?.state,
              zipCode: element?.accountExternalAttrs?.serviceAddress?.zipCode
            };
            const consolidatedServiceAddress =
            this.cbHelper.replaceNull(address.street) + this.cbHelper.replaceNull(address.houseNumber) +
            this.cbHelper.replaceNull(address.city) + this.cbHelper.replaceNull(address.state, true) +
            this.cbHelper.replaceNull(address.zipCode, true);
            const rowDetails = {
              accountAlias: element?.alias,
              serviceAddress: consolidatedServiceAddress
            };
            this.fiberInternetRows.push(rowDetails);
          });
        }
        if (results.errors) {
          this.contentLoad = true;
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'getFttxDetails');
          this.modalScroll();
        }
      });
  }

  public close(eventName: string) {
    this.tealium.button(eventName, eventName);
    this.activeModal.close(eventName);
  }

  public modalScroll() {
    const modalWindow = document.querySelectorAll('ngb-modal-window');
    if (modalWindow && modalWindow.length > 0) {
      modalWindow[0].scrollTop = 0;
    }
  }
}
