import {Injectable, Injector, OnDestroy} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import {LocalStorageService} from 'ngx-localstorage';
//import {JwtHelper} from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';

import {Logger} from '../core/logger.service';
import { AppConfig } from '../core/app.config';
import {RefreshTokenClient} from '../services/keepalive.service';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { LocalStorageKeys } from 'common-ui-lib';

const log = new Logger('AuthInterceptor');

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor, OnDestroy {
  authToken: any;
  jwtHelper: JwtHelperService = new JwtHelperService();
  private onDestroy$ = new Subject<boolean>();
  urlWhiteList = [
    '/api/cbma/userauthorization/services/loginprofiles/login',
    '/api/cbma/userauthorization/services/loginprofiles/keepAlive'
  ];
  currUrl: any;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.currUrl = request.url.match(/^\/api\/.+/);
    const injector = Injector.create({providers: [
      {provide: LocalStorageService, deps: [], multi: true},
      {provide: RefreshTokenClient, useFactory: (config: AppConfig) => () => config.load(), deps: [], multi: true }
    ]});
    const _storageService =  injector.get(LocalStorageService);
    const _tokenService =  injector.get(RefreshTokenClient);
    if (this.currUrl) {
      this.currUrl = this.currUrl[0];
      if (!this.urlWhiteList.includes(this.currUrl[0])) {
        this.authToken =  _storageService.get(LocalStorageKeys.Authtoken, 'cbma');
        if (this.authToken) {
          const now: Date = new Date();
          const expDt: Date = this.jwtHelper.getTokenExpirationDate(this.authToken);
          const diffMs = (<any>expDt - <any>now); // ms
          const diffDays = Math.floor(diffMs / 86400000); // days
          const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
          const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
          console.log(diffMins);
          if (diffMins <= 30) {
           _tokenService.getRefreshToken()
           .pipe(takeUntil(this.onDestroy$))
           .subscribe((data: any) => {
             console.log(data);
           });
          }
        }
      }
    }
    return next.handle(request);
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}

