import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonUiLibModule } from '../common-ui-lib.module';
import { CSRNotAuthorizedComponent } from '../errorpage/csr/csrnotauthorized.component';
import { ErrorpageComponent } from '../errorpage/errorpage.component';
import { UnauthorizedComponent } from './autherror/autherror.component';
import { BookmarkErrorpageComponent } from './bookmark/bookmarkerrorpage.component';
import { HttpErrorComponent } from './http-error/http-errorpage.component';
import { ErrorPageRoutingModule } from './routes/errorpage-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ErrorPageRoutingModule,
    CommonUiLibModule,
  ],
  declarations: [
    ErrorpageComponent,
    UnauthorizedComponent,
    HttpErrorComponent,
    CSRNotAuthorizedComponent,
    BookmarkErrorpageComponent,
  ],
  exports: [
    ErrorpageComponent,
    UnauthorizedComponent,
    CSRNotAuthorizedComponent,
    BookmarkErrorpageComponent,
  ],
})
export class ErrorPageModule { }
