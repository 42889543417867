<mat-tab-header #tabHeader [selectedIndex]="selectedIndex" [disableRipple]="disableRipple"
  (indexFocused)="_focusChanged($event)" (selectFocusedIndex)="selectedIndex = $event">

  <div class="mat-tab-label" role="tab" matTabLabelWrapper mat-ripple *ngFor="let tab of _tabs; let i = index"
    [id]="_getTabLabelId(i)" [attr.tabIndex]="_getTabIndex(tab, i)" [attr.aria-controls]="_getTabContentId(i)"
    [attr.aria-selected]="selectedIndex == i" [class.mat-tab-label-active]="selectedIndex == i"
    [disabled]="tab.disabled" (click)="_handleClick(tab, tabHeader, i)">

    <div class="mat-tab-label-content">
      <!-- If there is a label template, use it. -->
      <ng-template [ngIf]="tab.templateLabel">
        <ng-template [cdkPortalOutlet]="tab.templateLabel"></ng-template>
      </ng-template>

      <!-- If there is not a label template, fall back to the text label. -->
      <ng-template [ngIf]="!tab.templateLabel">{{tab.textLabel}}</ng-template>
    </div>
  </div>
</mat-tab-header>

<div class="mat-tab-body-wrapper" #tabBodyWrapper>
  <mat-tab-body role="tabpanel" *ngFor="let tab of _tabs; let i = index" [id]="_getTabContentId(i)"
    [attr.aria-labelledby]="_getTabLabelId(i)" [class.mat-tab-body-active]="selectedIndex == i" [content]="tab.content"
    [position]="tab.position" [origin]="tab.origin" (_onCentered)="_removeTabBodyWrapperHeight()"
    (_onCentering)="_setTabBodyWrapperHeight($event)">
  </mat-tab-body>
</div>