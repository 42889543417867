import { NgModule } from '@angular/core';
import { RouterModule , Routes  } from '@angular/router';

import { extract, Route } from '../../core/index';
import { UnauthorizedComponent } from '../autherror/autherror.component';
import { BookmarkErrorpageComponent } from '../bookmark/bookmarkerrorpage.component';
import { CSRNotAuthorizedComponent } from '../csr/csrnotauthorized.component';
import { ErrorpageComponent } from '../errorpage.component';
import { HttpErrorComponent } from '../http-error/http-errorpage.component';

const breadCrumbName = 'Error Page';
const dataroutes: Routes = Route.withShell([
  {
    path: 'errorpage',
    component: ErrorpageComponent,
    children: [
      {
        path: 'autherror',
        component: UnauthorizedComponent,
        data: { title: extract('Unauthorized Access'), breadcrumb: breadCrumbName, tealium: 'unauthorizedaccess' },
      },
      {
        path: 'httperror',
        component: HttpErrorComponent,
        data: { title: extract('Http Error'), tealium: 'http-error', breadcrumb: breadCrumbName },
      },
      {
        path: 'bookmarkerror',
        component: BookmarkErrorpageComponent,
        data: { title: extract('Bookmark Error'), tealium: 'bookmark-error', breadcrumb: breadCrumbName },
      },
      {
        path: 'csr',
        component: ErrorpageComponent,
        data:
          { bundle: 'errorpage' },
        children: [
          {
            path: 'unauth', component: CSRNotAuthorizedComponent,
            data: {
              bundle: 'errorpage',
              title: extract('Not Authorized'),
              tealium: 'csr_not_authorized',
              breadcrumb: breadCrumbName
            },
          },
        ]
      },
    ]
  }
]);

@NgModule({
    imports: [RouterModule.forChild(dataroutes)],
    exports: [RouterModule],

})

export class ErrorPageRoutingModule { }
