import { Injectable } from '@angular/core';
import { CoxHttpClient } from './../../services/coxhttpclient.service';
import { Outage } from './outage.model';

@Injectable()
export class OutageService {
    constructor(private coxHttpClient: CoxHttpClient) { }

    public getOutagesDetails(): Promise<Outage> {
        const outageUrl = '/api/cbma/account/services/account/accounts/outage';
        return this.coxHttpClient.getAsync<Outage>(outageUrl, { disableSpinner: true });
    }
}
