/* eslint-disable prefer-const */
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NavigationConstants, NavigationService, AEMClientService, ResourceBundle,
        TealiumUtagService, 
        LocalStorageKeys} from 'common-ui-lib';
import { DeviceDetectorService } from 'common-ui-lib';
import { isEmpty } from 'lodash';
import { AdminLandingService } from 'redesign/admin/admin-landing.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Modaltemplate } from '../../leavingcoxsitemodal/leavingcoxsitemodal.model';
import { AccountInfo } from '../pageheader/pageheader.model';
import { LocationDetailsStack } from './navigation.service';
import { LocalStorageService } from 'ngx-localstorage';
@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

    @Input() page: string;
    @Input() moreLink = true;
    @Input() selectedAccountDetails: AccountInfo;
    @Input() backLocation: string;
    @Input() showAccountWidget: boolean;
    @Input() checkFormDirty = false;
    @Input() backWithUnsavedChanges: string;
    @Input() backText = false;
    @ViewChild('leaveSiteModal') public leaveSiteModal: NgbModalRef;
    @Output() backEvent = new EventEmitter<boolean>();//added for navigation header
    public modaltemplate: Modaltemplate;
	public resourceBundle: ResourceBundle = {};
    private onDestroy$ = new Subject<boolean>();

    constructor(
        private router: Router,
        public activeRoute: ActivatedRoute,
        private location: Location,
        public deviceService: DeviceDetectorService,
		public aemClient: AEMClientService,
        public navigationService: NavigationService,
        public adminService: AdminLandingService,
        public modalService: NgbModal, private _storageService: LocalStorageService,
        public tealium: TealiumUtagService) {
        this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
            if (data instanceof NavigationEnd) {
                //Clearing the previour router title
                const pageTitle = this.navigationService.getPageTitle();
                this.navigationService.setPageTitle(null);
                const routeData = this.activeRoute.snapshot.children[0] ?
                                    this.activeRoute.snapshot.children[0].data : this.activeRoute.snapshot.data;
                if (routeData) {
                    if (routeData['pageTitle'] === NavigationConstants.CUSTOM_TITLE) {
                        this.navigationService
                                    .setPageTitle(this.router.getCurrentNavigation().extras?.state?.pageTitle);
                    } else {
                        this.navigationService.setPageTitle(routeData['pageTitle']);
                    }
                    if(!routeData['pageTitle']){
                        this.navigationService.setPageTitle(pageTitle);
                    }
                }
            }
        });
    }

    ngOnInit() {
		this.aemClient.getRedesignBundle('navigation')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
          this.resourceBundle = data;
          this.modaltemplate = {
            modalTitleRequired: true, modalTitle: this.resourceBundle.leaveThisPageTitle,
            modalBodyDescription: this.resourceBundle.leaveThisPageDesc,
            secondaryButtonText: this.resourceBundle.no, primaryButtonText: this.resourceBundle.leaveThisPageBtn
        };
        }
      );

        this.activeRoute.queryParams.subscribe(params => {
            if(params.url !== undefined) {
                this.navigationService.setUrl(params.url);
            }
        });
    }

    navigateBack() {
        // if (this.navigationService.getBackPath()) {
        //     this.router.navigateByUrl(`${this.navigationService.getBackPath()}`);
        //     this.navigationService.setBackPath(null);
        // } else {
        //   this.location.back();
        // }
        if (this.backLocation && this.backLocation !== null && !this.checkFormDirty) {
            this.backEvent.emit(true);
            if(!isEmpty(this.navigationService.getParam())) {
                let param = this.navigationService.getParam();
                this.navigationService.setParam('');
                this.router.navigate([LocationDetailsStack[this.backLocation]], { queryParams: { tab: param } });
            } else {
                this.router.navigateByUrl(LocationDetailsStack[this.backLocation]);
            }
            this.backLocation = null;
            return;
        }
        let url = this.navigationService.getUrl();
        if(this.page === 'addUserPage') {
            if(url === 'UserList') {
                this.navigationService.setUrl('');
                window.history.pushState(null, '', `${window.location.origin}/cbma/home/landing`);
                this.router.navigateByUrl('/admin/list');
            } else if(url === 'AccountContacts') {
                this.navigationService.setUrl('');
                let guid = this._storageService.get(LocalStorageKeys.SelectedAccount);
                window.history.pushState(null, '', `${window.location.origin}/cbma/home/landing`);
                window.history.pushState(null, '', `${window.location.origin}/cbma/admin/list`);
                this.router.navigateByUrl(`/admin/account/edit?guid=${guid}`);
            } else if (url === 'userFeatureSettings'){
                this.navigationService.setUrl('');
                this.router.navigateByUrl(`/voice/userandsysmanagement/userfeaturesettings/userfeaturesettings`);
            } else {
                this.location.back();
            }
            this.page = '';
        } else if(this.page === 'editAccountPage'){
            window.history.pushState(null, '', `${window.location.origin}/cbma/home/landing`);
            this.router.navigateByUrl('/admin/list');
            this.page = '';
        } else if(this.page === 'addAccountPage' && url === 'AccountList'){
            window.history.pushState(null, '', `${window.location.origin}/cbma/home/landing`);
            this.router.navigateByUrl('/admin/list');
            this.page = '';
        } else if(url==='BacktoPreviousContent'){ //added else if for navigation header
            this.navigationService.setUrl('');
            this.backEvent.emit(true);
        } else {
            if(window.history.length === 0){  //to check if window doesn't have any history
                this.router.navigateByUrl(LocationDetailsStack['landing']);
            } else if(this.checkFormDirty){
                this.handleRedirect();
            }  else{
                this.location.back();
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onPrimaryClick(event: any, close: any): void {
        this.tealium.button('leavingcoxsite-confirm', 'leavingcoxsite-confirm');
        close(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSecondaryClick(close: any) {
        this.tealium.button('leavingcoxsite-cancel', 'leavingcoxsite-cancel');
        close(true);
    }

    // to get the default modal on click browser back link
    public handleRedirect(){
        const subject = new Subject<boolean>();
        this.modalService.open(this.leaveSiteModal).result.then((result) => {
            if (result) {
                subject.next(false);
            } else {
                this.router.navigateByUrl(LocationDetailsStack[this.backWithUnsavedChanges]);
                subject.next(true);
            }
            subject.complete();
        });
        return subject;
    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
