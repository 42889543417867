/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService, BaseAccount, CBHelperService, ErrorMessageService, Role,
  CommonMessageService, RedesignAccountwidgetComponent, TealiumUtagService, DeviceDetectorService
} from 'common-ui-lib';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { finalize } from 'rxjs/operators';
import { IpAddressManagerModalService } from './ip-address-manager-modal.service';
import { CidrData, COIData, DynamicIpDataList, Ripv2Data, StaticData } from './ip-address-manager-modal.model';
import { DataToolsService } from '../data-tools.service';
import { LocalStorageService } from 'ngx-localstorage';
@Component({
  selector: 'app-ip-address-manager-modal',
  templateUrl: './ip-address-manager-modal.component.html',
  styleUrls: ['./ip-address-manager-modal.component.scss']
})
export class IpAddressManagerModalComponent implements OnInit, AfterViewChecked {

  @Input() selectedAccountDetails: any;
  @Output() viewIpAddressMobile = new EventEmitter<boolean>();
  @ViewChild('cidrBlockModal') public cidrBlockModal: NgbModalRef;
  public _account: BaseAccount;
  public resourceBundle: any = {};
  public profileDataToolRoles: string[] = [];
  public cidrView = [];
  public cidrBlockView = [];
  public staticView = [];
  public ripv2View = [];
  public coiBlockView = [];
  public combinedDataView = [];
  public showCOIip = false;
  public showCidrIP = false;
  public showStaticIP = false;
  public isMyAdmin: boolean;
  public showDynamicIP = false;
  public gotoViewIP = false;
  public showOnlyDynamic = false;
  public showOnlyCidr = false;
  public showOnlyStatic = false;
  public staticCidrCall = false;
  public dynamicCall = false;
  public showCidrRipv2IP = false;
  public showOnlyCidrRipv2 = false;
  public showOnlyCOI = false;
  public overlayTypeCheck: string;
  public dynamicIpDataList: DynamicIpDataList[] = [];
  public selectedAccordian = '';
  public isMultiAccount: boolean;
  public accSelected = false;
  public parentAccSelected: any;
  public redesignAccountwidgetComponent: RedesignAccountwidgetComponent;
  public primaryNameServiceFlag: boolean;
  public secondaryNameServerFlag: boolean;
  public selectedCidrTabIndex = 0;
  public stubdataEle = [];
  public ipResponse: any;
  public newRipV2Block = [];
  public newCoiBlock = [];
  public newCombinedDataBlock = [];
  public ripv2String = 'RIPV2';
  public combinedDataFlag = false;
  public combinedBlocks = [];
  public userOptions: any;
  public displayOptionValue: string;
  public pendingDeletionIps = [];
  public isDeletionPendingIp = false;
  public shownOnlyDeletePendingIP = false;
  private ipv6Data: string[] = [];
  private ipv4Data: string[] = [];
  private profileRoles: NgxPermissionsObject;

  constructor(
    public ipAddressManagerModalService: IpAddressManagerModalService,
    public cbHelper: CBHelperService,
    public errorMessageService: ErrorMessageService,
    public commonMessageService: CommonMessageService,
    public activeModal: NgbActiveModal,
    public deviceService: DeviceDetectorService,
    public tealium: TealiumUtagService,
    private modalService: NgbModal,
    private permissionService: NgxPermissionsService,
    private aemClient: AEMClientService,
    private dataToolsService: DataToolsService,
    private _storageService: LocalStorageService,
    public cbHelperService: CBHelperService
  ) {
    this.profileRoles = this.permissionService.getPermissions();
    this.aemClient.getRedesignBundle('malp/ipaddressManagerModal')
      .subscribe(
        (data: any) => {
          this.resourceBundle = Object.assign([], data);
        },
        (error: any) => {
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'errorMsg');
        },
      );
  }

  ngOnInit(): void {
    this.isMultiAccount = this.cbHelper.getLoggedInUserDetails().profile.userToken.multiAccount;
    this.commonMessageService.hideMessage();
    this.isMyAdmin = this.cbHelperService.isMyAdmin();
  }

  ngAfterViewChecked() {
    if (this.showCidrIP) {
      this.selectedAccordian = 'dcip-panel';
    } else if (this.showStaticIP) {
      this.selectedAccordian = 'static-panel';
    } else if (this.showDynamicIP) {
      this.selectedAccordian = 'dynamic-panel';
    } else if (this.showCidrRipv2IP) {
      this.selectedAccordian = 'cidrripv2-panel';
    }
    if (this.staticCidrCall && this.dynamicCall) {
      this.ipAddressManagerModalService.contentLoaded = true;
    }
    this.showOnlyDynamic = (!this.showCidrIP && !this.showStaticIP && this.showDynamicIP
      && !this.showCidrRipv2IP && !this.isDeletionPendingIp) ? true : false;
    this.showOnlyStatic = (!this.showCidrIP && this.showStaticIP && !this.showDynamicIP
      && !this.showCidrRipv2IP) ? true : false;
    this.showOnlyCidr = (this.showCidrIP && !this.showStaticIP && !this.showCOIip && !this.showDynamicIP
      && !this.showCidrRipv2IP) ? true : false;
    this.showOnlyCidrRipv2 = (!this.showCidrIP && !this.showStaticIP && !this.showDynamicIP
      && this.showCidrRipv2IP) ? true : false;
    this.showOnlyCOI = (!this.showCidrIP && !this.showStaticIP && !this.showDynamicIP
      && !this.showCidrRipv2IP && this.showCOIip) ? true : false;
    this.shownOnlyDeletePendingIP = (!this.showCidrIP && !this.showStaticIP && !this.showDynamicIP
      && !this.showCidrRipv2IP && this.isDeletionPendingIp && this.combinedDataFlag) ? true : false;
  }

  public selectedAccount(event: any) {
    this.accSelected = true;
    this.selectedAccountDetails = event;
    this.ipAddressManagerModalService.contentLoaded = false;
    this.showCidrIP = false; this.showStaticIP = false; this.showDynamicIP = false; this.showCidrRipv2IP = false;
    this.showCOIip = false;
    this.combinedDataFlag = false;
    this.selectedCidrTabIndex = 0;
    this.showCOIip = false;
    this.combinedDataFlag = false;
    this.selectedCidrTabIndex = 0;
    this.isDeletionPendingIp = false;
    this.getIPDetails(this.selectedAccountDetails.guid);
  }

  // For Printing
  public printPdf(divId: any): void {
    this.tealium.button('ipaddressmanager-print', 'ipaddressmanager-print');
    const style = '<style>td {padding: 5px;width: 50%;}</style>';
    const divContents = style + document.getElementById(divId).innerHTML;
    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write('<html><head><title>My Account</title>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(divContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    // Settimeout required for chrome browser because javascript is not giving the DOM enough (any)
    // time to load / render the image once it has the src set.
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (printWindow as any).print();
      printWindow.close();
    }, 250);
  }

  // To Open the Modal
  public openCidrBlockModal(): void {
    this.tealium.link('ipaddressmanager-cidrblock');
    this.modalService.open(this.cidrBlockModal, { backdrop: 'static' }).result.then((result) => { });
  }

  public onCloseModal(event: any) {
    this.tealium.button('ipaddressmanager-close', 'ipaddressmanager-close');
    if (this.deviceService.isMobile()) {
      this.viewIpAddressMobile.emit(false);
    } else {
      this.activeModal.close(event);
      localStorage.setItem('selecedAccount', this.dataToolsService.parentAccSelected);
    }
  }

  public buildRipv2View(ripv2Data: any) {
    this.ripv2View = [];
    this.combinedBlocks = [];
    this.ripv2View.push({
      label: this.resourceBundle.staticIpRipv2Label,
      value: (ripv2Data.ipDetailsList && ripv2Data.ipDetailsList.length > 0)
        ? ripv2Data.ipDetailsList
        : [{ ip: this.resourceBundle.ip_empty_msg, macAddress: '', smtpRelayEnabled: false }],
    });
    this.ripv2View.push({
      label: this.resourceBundle.staticGatewayLabel,
      value: ripv2Data.gateway ? ripv2Data.gateway : this.resourceBundle.ip_empty_msg,
    });
    this.ripv2View.push({
      label: this.resourceBundle.staticNetmaskLabel,
      value: ripv2Data.netmask ? ripv2Data.netmask : this.resourceBundle.ip_empty_msg,
    });
    if (this.primaryNameServiceFlag) {
      this.ripv2View.push({
        label: this.resourceBundle.primaryNameServerLabel,
        value: ripv2Data.primaryDNS ? ripv2Data.primaryDNS : this.resourceBundle.ip_empty_msg,
      });
    }
    if (this.secondaryNameServerFlag) {
      this.ripv2View.push({
        label: this.resourceBundle.secondaryNameServerLabel,
        value: ripv2Data.secondaryDNS ? ripv2Data.secondaryDNS : this.resourceBundle.ip_empty_msg,
      });
    }
    if (this.profileRoles[Role.DNS_VIEW_MALBLOCK_ROLE]) {
      this.ripv2View.push({
        label: this.resourceBundle.malBlockPrimaryIPv4Label,
        value: ripv2Data.malblockIpDataPrimaryIPV4 ? ripv2Data.malblockIpDataPrimaryIPV4 :
          this.resourceBundle.ip_empty_msg,
      });
      this.ripv2View.push({
        label: this.resourceBundle.malBlockSecondaryIPv4Label,
        value: ripv2Data.malblockIpDataSecondaryIPV4 ? ripv2Data.malblockIpDataSecondaryIPV4 :
          this.resourceBundle.ip_empty_msg,
      });
      this.ripv2View.push({
        label: this.resourceBundle.malBlockPrimaryIPv6Label,
        value: ripv2Data.malblockIpDataPrimaryIPV6 ? ripv2Data.malblockIpDataPrimaryIPV6 :
          this.resourceBundle.ip_empty_msg,
      });
      this.ripv2View.push({
        label: this.resourceBundle.malBlockSecondaryIPv6Label,
        value: ripv2Data.malblockIpDataSecondaryIPV6 ? ripv2Data.malblockIpDataSecondaryIPV6 :
          this.resourceBundle.ip_empty_msg,
      });
    }
  }

  public buildCOIDataView(coiData: any) {
    this.coiBlockView = [];
    this.combinedBlocks = [];
    this.coiBlockView.push({
      label: this.resourceBundle.staticIpRipv2Label,
      value: (coiData?.ipAddress && coiData?.ipAddress?.length > 0)
        ? [{ ip: coiData?.ipAddress }]
        : [{ ip: this.resourceBundle.ip_empty_msg }],
    });
    if (this.primaryNameServiceFlag) {
      this.coiBlockView.push({
        label: this.resourceBundle.primaryNameServerLabel,
        value: coiData.primaryDNS ? coiData.primaryDNS : this.resourceBundle.ip_empty_msg,
      });
    }
    if (this.secondaryNameServerFlag) {
      this.coiBlockView.push({
        label: this.resourceBundle.secondaryNameServerLabel,
        value: coiData.secondaryDNS ? coiData.secondaryDNS : this.resourceBundle.ip_empty_msg,
      });
    }
    if (this.profileRoles[Role.DNS_VIEW_MALBLOCK_ROLE]) {
      this.coiBlockView.push({
        label: this.resourceBundle.malBlockPrimaryIPv4Label,
        value: coiData.malblockIpDataPrimaryIPV4 ? coiData.malblockIpDataPrimaryIPV4 :
          this.resourceBundle.ip_empty_msg,
      });
      this.coiBlockView.push({
        label: this.resourceBundle.malBlockSecondaryIPv4Label,
        value: coiData.malblockIpDataSecondaryIPV4 ? coiData.malblockIpDataSecondaryIPV4 :
          this.resourceBundle.ip_empty_msg,
      });
      this.coiBlockView.push({
        label: this.resourceBundle.malBlockPrimaryIPv6Label,
        value: coiData.malblockIpDataPrimaryIPV6 ? coiData.malblockIpDataPrimaryIPV6 :
          this.resourceBundle.ip_empty_msg,
      });
      this.coiBlockView.push({
        label: this.resourceBundle.malBlockSecondaryIPv6Label,
        value: coiData.malblockIpDataSecondaryIPV6 ? coiData.malblockIpDataSecondaryIPV6 :
          this.resourceBundle.ip_empty_msg,
      });
    }
  }

  public createMultiBlock() {         // for PrintPDF function
    this.newRipV2Block = [];
    for (let i = 0; i < this.ipResponse?.ripV2AddressBlocks.length; i++) {
      this.buildRipv2View(this.ipResponse?.ripV2AddressBlocks[i]);
      this.newRipV2Block[i] = this.ripv2View;
    }
  }

  public createCoiMultiBlock() {         // for PrintPDF function
    this.newCoiBlock = [];
    for (let i = 0; i < this.ipResponse?.coIAddressDetails?.length; i++) {
      this.buildCOIDataView(this.ipResponse?.coIAddressDetails[i]);
      this.newCoiBlock[i] = this.coiBlockView;
    }
  }

  public createCombinedMultiBlock() {         // for PrintPDF function
    this.newCombinedDataBlock = [];
    for (let i = 0; i < this.combinedDataView?.length; i++) {
      if (i < this.ipResponse?.ripV2AddressBlocks?.length && this.showCidrRipv2IP && !this.isDeletionPendingIp) {
        this.buildRipv2View(this.combinedDataView[i]);
        this.newCombinedDataBlock[i] = this.ripv2View;
      } else if ((this.showStaticIP && i < 1 && this.ipResponse?.staticData?.ipDetailsList?.length) || (this.isDeletionPendingIp && i < 1 && this.ipResponse?.staticData?.ipDetailsList?.length)) {
        this.staticView = [];
        this.buildStaticView(this.combinedDataView[i]);
        this.newCombinedDataBlock[i] = this.staticView;
      } else if (i <= this.ipResponse?.ripV2AddressBlocks?.length && this.isDeletionPendingIp && this.ipResponse?.ripV2AddressBlocks?.length) {
        this.buildRipv2View(this.combinedDataView[i]);
        this.newCombinedDataBlock[i] = this.ripv2View;
      } else {
        const count = this.combinedDataView?.length - i;
        for (let j = 0; j < count && i <= this.combinedDataView?.length - 1; j++) {
          this.buildCOIDataView(this.ipResponse?.coIAddressDetails[j]);
          this.newCombinedDataBlock[i] = this.coiBlockView;
          i += 1;
        }
      }
    }
  }

  /**
   * Change the format to feed dropdown options
   *
   * @param optionsData
   */
  public buildUserOptions(optionsData) {
    this.displayOptionValue = 'Block 1';
    this.userOptions = optionsData.map((_, index) => `Block ${index + 1}`);
  }

  /**
   * Selected User Options
   */
  public userOptionSelected(event) {
    if (event) {
      this.displayOptionValue = event;
      const num = parseInt(event.split(' ')[1], 10);
      this.selectedCidrTabIndex = num - 1;
      if (this.combinedDataFlag) {
        this.combinedBlocks = [];
        const indexData = this.combinedDataView[this.selectedCidrTabIndex];
        if (this.showCidrRipv2IP && this.selectedCidrTabIndex < this.ipResponse?.ripV2AddressBlocks.length) {
          this.buildRipv2View(indexData);
          this.combinedBlocks = this.ripv2View;
        } else if (this.showStaticIP && this.selectedCidrTabIndex < 1 &&
          this.ipResponse?.staticData?.ipDetailsList?.length) {
          this.staticView = [];
          this.buildStaticView(indexData);
          this.combinedBlocks = this.staticView;
        } else if (this.isDeletionPendingIp && this.ipResponse?.ripV2AddressBlocks?.length && this.selectedCidrTabIndex <= this.ipResponse?.ripV2AddressBlocks?.length) {
          if (this.selectedCidrTabIndex < 1 && this.ipResponse?.staticData?.ipDetailsList?.length) {
            this.staticView = [];
            this.buildStaticView(indexData);
            this.combinedBlocks = this.staticView;
          } else {
            this.buildRipv2View(indexData);
            this.combinedBlocks = this.ripv2View;
          }
        } else {
          this.buildCOIDataView(indexData);
          this.combinedBlocks = this.coiBlockView;
        }
      } else if (!this.showCidrRipv2IP && this.showCOIip) {
        const tabIndex = this.ipResponse?.coIAddressDetails[this.selectedCidrTabIndex];
        this.buildCOIDataView(tabIndex);
      } else {
        const tabIndex = this.ipResponse?.ripV2AddressBlocks[this.selectedCidrTabIndex];
        this.buildRipv2View(tabIndex);
      }
    }
  }


  // API call to get IP Details
  private async getIPDetails(accountGuid: string) {
    this.ipv4Data = [];
    this.ipv6Data = [];
    let dynamicResponse;
    try {
      this.commonMessageService.hideMessage();
      this.ipAddressManagerModalService.getStaticIpAddress(accountGuid)
        .pipe(finalize(() => {
          this.staticCidrCall = true;
        }))
        .subscribe((results: any) => {
          if (!results.errors) {
            this.ipResponse = results.data?.getAccount?.ipConfiguration;
            this.overlayTypeCheck = this.ipResponse?.overlayType;
            this.primaryNameServiceFlag = this.ipResponse?.primaryNameServiceFlag;
            this.secondaryNameServerFlag = this.ipResponse?.secondaryNameServerFlag;
            this.ipv4Data.push(this.ipResponse?.malblockIpData?.ipv4Adrress[0],
              this.ipResponse?.malblockIpData?.ipv4Adrress[1]);
            this.ipv6Data.push(this.ipResponse?.malblockIpData?.ipv6Address[0],
              this.ipResponse?.malblockIpData?.ipv6Address[1]);
            this.pendingDeletionIps = (this.ipResponse?.staticData?.ipDetailsList || [])
            .filter(ip => ip.deletePending);
            if (this.ipResponse?.ripV2AddressBlocks?.length > 1) {
              this.createMultiBlock();
            }
            if (this.ipResponse?.coIAddressDetails?.length > 1) {
              this.createCoiMultiBlock();
            }
            this.isDeletionPendingIp = this.ipResponse?.staticData?.ipDetailsList?.some((ipObj)=> ipObj?.deletePending);
            if (this.overlayTypeCheck && this.overlayTypeCheck === this.ripv2String) {
              this.buildIPViews(this.ipResponse?.ripV2AddressBlocks[0], this.ipResponse?.cidrData,
                this.ipResponse?.staticData, this.ipResponse?.coIAddressDetails ?
                this.ipResponse?.coIAddressDetails[0] : this.ipResponse?.coIAddressDetails,
                this.ipResponse?.overlayType, this.ipResponse?.baseAddress);
            } else {
              this.buildIPViews(this.ipResponse?.ripV2AddressBlocks, this.ipResponse?.cidrData,
                this.ipResponse?.staticData, this.ipResponse?.coIAddressDetails ?
                this.ipResponse?.coIAddressDetails[0] : this.ipResponse?.coIAddressDetails,
                this.ipResponse?.overlayType, this.ipResponse?.baseAddress);
            }
          }
          if (results.errors) {
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'errorMsg');
          }
        });

      this.ipAddressManagerModalService.getDynamicIpIpAddress(accountGuid)
        .pipe(finalize(() => {
          this.dynamicCall = true;
        }))
        .subscribe((results: any) => {
          if (results.data) {
            dynamicResponse = results.data?.getAccount.dynamicIpConfiguration;
            if (dynamicResponse && dynamicResponse.dynamicIpDataList && dynamicResponse.dynamicIpDataList.length > 0) {
              this.showDynamicIP = true;
              this.dynamicIpDataList = dynamicResponse.dynamicIpDataList;
            }
          }
          if (results.errors) {
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'errorMsg');
          }
        });
    } catch (error) {
      this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'errorMsg');
      this.ipAddressManagerModalService.contentLoaded = true;
    }
  }

  // Building the json for static and CDIR IP blocks
  private buildIPViews(ripv2Data: Ripv2Data, cidrData: CidrData, staticData: StaticData, coiData: COIData,
    overlayType: string, baseAddress: string): void {
    this.cidrView = [];
    this.staticView = [];
    this.cidrBlockView = [];
    this.ripv2View = [];
    this.coiBlockView = [];
    this.combinedDataView = [];

    if (ripv2Data && overlayType === this.ripv2String && !this.isDeletionPendingIp) {
      this.showCidrRipv2IP = true;
      this.buildRipv2View(ripv2Data);
      if (this.ipResponse?.ripV2AddressBlocks?.length > 1 && !coiData) {
        this.buildUserOptions(this.ipResponse?.ripV2AddressBlocks);
      }
    }
    if (coiData) {
      this.showCOIip = true;
      this.buildCOIDataView(coiData);
      if (this.ipResponse?.coIAddressDetails?.length > 1 && !this.showCidrRipv2IP && !staticData) {
        this.buildUserOptions(this.ipResponse?.coIAddressDetails);
      }
    }
    if (cidrData && overlayType !== this.ripv2String) {
      this.showCidrIP = true;
      this.buildCidrView(cidrData, overlayType, baseAddress);
      this.buildCidrBlockView(cidrData, overlayType, baseAddress);
      this.cidrView.push({
        label: this.resourceBundle.primaryNameServerLabel,
        value: staticData.primaryDNS ? staticData.primaryDNS : this.resourceBundle.ip_empty_msg,
      });
      this.cidrView.push({
        label: this.resourceBundle.secondaryNameServerLabel,
        value: staticData.secondaryDNS ? staticData.secondaryDNS : this.resourceBundle.ip_empty_msg,
      });
    }
    if (staticData && overlayType !== this.ripv2String &&
      (this.ipResponse?.ripV2AddressBlocks?.length === 0 || !this.ipResponse?.ripV2AddressBlocks || overlayType === 'DOCSIS')) {
      this.showStaticIP = true;
      this.buildStaticView(staticData);
    }
    if (coiData && ripv2Data && overlayType === this.ripv2String) {
      this.combinedDataFlag = true;
      for (let i = 0; i < this.ipResponse?.ripV2AddressBlocks.length; i++) {
        this.combinedDataView.push(this.ipResponse?.ripV2AddressBlocks[i]);
      }
      for (let i = 0; i < this.ipResponse?.coIAddressDetails.length; i++) {
        this.combinedDataView.push(this.ipResponse?.coIAddressDetails[i]);
      }
      this.buildUserOptions(this.combinedDataView);
    } else if (coiData && staticData && overlayType !== this.ripv2String) {
      this.combinedDataFlag = true;
      if (staticData?.ipDetailsList?.length > 0) {
        this.combinedDataView.push(this.ipResponse?.staticData);
      }
      for (let i = 0; i < this.ipResponse?.coIAddressDetails?.length; i++) {
        this.combinedDataView.push(this.ipResponse?.coIAddressDetails[i]);
      }
      this.buildUserOptions(this.combinedDataView);
    } else if (staticData && ripv2Data && overlayType === this.ripv2String && this.isDeletionPendingIp) {
       this.combinedDataFlag = true;
       if (staticData?.ipDetailsList?.length > 0) {
        this.combinedDataView.push(this.ipResponse?.staticData);
      }
      for (let i = 0; i < this.ipResponse?.ripV2AddressBlocks.length; i++) {
        this.combinedDataView.push(this.ipResponse?.ripV2AddressBlocks[i]);
      }
      this.buildUserOptions(this.combinedDataView);
    }
    if (this.combinedDataView?.length && this.combinedDataFlag) {
      this.createCombinedMultiBlock();
      if (this.selectedCidrTabIndex === 0 || this.displayOptionValue === 'Block 1') {
        const indexData = this.combinedDataView[this.selectedCidrTabIndex];
        if (overlayType === this.ripv2String && !staticData) {
          this.buildRipv2View(indexData);
          this.combinedBlocks = this.ripv2View;
        } else if (staticData?.ipDetailsList?.length > 0) {
          this.staticView = [];
          this.buildStaticView(indexData);
          this.combinedBlocks = this.staticView;
        } else {
          this.buildCOIDataView(indexData);
          this.combinedBlocks = this.coiBlockView;
        }
      }
    }
  }

  // For Static View JSON
  private buildStaticView(staticData: StaticData): void {
    this.staticView.push({
      label: this.resourceBundle.staticIpLabel,
      value: (staticData.ipDetailsList && staticData.ipDetailsList.length > 0)
        ? staticData.ipDetailsList
        : [{ ip: this.resourceBundle.ip_empty_msg, macAddress: '', smtpRelayEnabled: false }],
    });
    this.staticView.push({
      label: this.resourceBundle.staticGatewayLabel,
      value: staticData.gateway ? staticData.gateway : this.resourceBundle.ip_empty_msg,
    });
    this.staticView.push({
      label: this.resourceBundle.staticNetmaskLabel,
      value: staticData.netmask ? staticData.netmask : this.resourceBundle.ip_empty_msg,
    });
    this.staticView.push({
      label: this.resourceBundle.primaryNameServerLabel,
      value: staticData.primaryDNS ? staticData.primaryDNS : this.resourceBundle.ip_empty_msg,
    });
    this.staticView.push({
      label: this.resourceBundle.secondaryNameServerLabel,
      value: staticData.secondaryDNS ? staticData.secondaryDNS : this.resourceBundle.ip_empty_msg,
    });
    if (this.profileRoles[Role.DNS_VIEW_MALBLOCK_ROLE] && staticData && this.overlayTypeCheck !== this.ripv2String) {
      this.staticView.push({
        label: this.resourceBundle.malBlockPrimaryIPv4Label,
        value: this.ipv4Data && this.ipv4Data.length > 0 ? this.ipv4Data[0] : this.resourceBundle.ip_empty_msg,
      });
      this.staticView.push({
        label: this.resourceBundle.malBlockSecondaryIPv4Label,
        value: this.ipv4Data && this.ipv4Data.length > 1 ? this.ipv4Data[1] : this.resourceBundle.ip_empty_msg,
      });
      this.staticView.push({
        label: this.resourceBundle.malBlockPrimaryIPv6Label,
        value: this.ipv6Data && this.ipv6Data.length > 0 ? this.ipv6Data[0] : this.resourceBundle.ip_empty_msg,
      });
      this.staticView.push({
        label: this.resourceBundle.malBlockSecondaryIPv6Label,
        value: this.ipv6Data && this.ipv6Data.length > 1 ? this.ipv6Data[1] : this.resourceBundle.ip_empty_msg,
      });
    }
  }

  // For CIDR JSON
  private buildCidrView(cidrData: CidrData, overlayType: string, baseAddress: string): void {
    this.cidrView.push({
      label: this.resourceBundle.cidrNetworkLabel,
      value: cidrData.customerNetwork ? cidrData.customerNetwork : this.resourceBundle.ip_empty_msg,
    });
    this.cidrView.push({
      label: this.resourceBundle.cidrWanLabel,
      value: cidrData.wanAddress ? cidrData.wanAddress : this.resourceBundle.ip_empty_msg,
    });
    this.cidrView.push({
      label: this.resourceBundle.cidrGatewayLabel,
      value: cidrData.suggestedDefaultGateway ? cidrData.suggestedDefaultGateway : this.resourceBundle.ip_empty_msg,
    });
    this.cidrView.push({
      label: this.resourceBundle.cidrNetmaskLabel,
      value: cidrData.customerNetmask ? cidrData.customerNetmask : this.resourceBundle.ip_empty_msg,
    });
  }

  private buildCidrBlockView(cidrData: CidrData, overlayType: string, baseAddress: string): void {
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrNetworkLabel,
      value: cidrData.customerNetwork ? cidrData.customerNetwork : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrNetmaskLabel,
      value: cidrData.customerNetmask ? cidrData.customerNetmask : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrnumberOfHostslabel,
      value: cidrData.numberOfHosts ? cidrData.numberOfHosts : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrGatewayLabel,
      value: cidrData.suggestedDefaultGateway ? cidrData.suggestedDefaultGateway : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrfirstUsablelabel,
      value: cidrData.firstUsable ? cidrData.firstUsable : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrlastUsablelabel,
      value: cidrData.lastUsable ? cidrData.lastUsable : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrBroadcastlabel,
      value: cidrData.broadcast ? cidrData.broadcast : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrWanLabel,
      value: cidrData.wanAddress ? cidrData.wanAddress : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrWanNetMaskLabel,
      value: cidrData.wanNetmask ? cidrData.wanNetmask : this.resourceBundle.ip_empty_msg,
    });
    this.cidrBlockView.push({
      label: this.resourceBundle.cidrwanGatewaylabel,
      value: cidrData.wanGateway ? cidrData.wanGateway : this.resourceBundle.ip_empty_msg,
    });
  }

}
