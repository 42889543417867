<div [ngClass]="{'mt-n5': audioPlayerMsg.displayMessage.show && isMobile, 'mt-n4': audioPlayerMsg.displayMessage.show && !isMobile}">
  <app-commonmessage *ngIf="(audioPlayerMsg.displayMessage.id === 'audioErrMsg' && audioPlayerMsg.displayMessage.show)"
    [type]="audioPlayerMsg.displayMessage.type" [globalMessage]="audioPlayerMsg.displayMessage.message"
    [level]="'global'">
  </app-commonmessage>
</div>
<div class="audioPlayerContainer">
  <div class="row margin-bottom-20px"
    id="controls">
    <div
      [class]="isMobile ? 'col-12 p-2' : 'col-4 p-2 margin-top-20'"
      id="volumeControl">
      <ng-container *ngIf="isAudioMute">
        <button tabindex="0" class="muteButton"
          id="muteButton"
          aria-label="Unmute Audio"
          (click)="clickToUnmute()"></button>
      </ng-container>
      <ng-container *ngIf="!isAudioMute">
        <button tabindex="0" class="unmuteButton"
          id="unmuteButton"
          aria-label="Mute Audio"
          (click)="clickToMute()"></button>
      </ng-container>
      <output id="volume-output"></output>
      <input tabindex="0" type="range"
        aria-label="Audio Volume"
        id="volume-slider" class="link-hover"
        max="100" value="100" aria-valuemin="0"
        aria-valuemax="100"
        [attr.aria-valuenow]="audioCurrentVolume">
    </div>
    <ng-container *ngIf="!isAudioPlaying">
      <div
        [class]="isMobile ? 'col-12 p-2' : 'col-4 p-2 margin-top-10'">
        <div class="controlContainer"
          id="controlContainer">
          <button class="rewindButton"
            (click)="rewindAudio($event)"
            tabindex="0" aria-label="Rewind">
            <img role="presentation"
              src="assets/images/blueprint/voicemail/rewind-audio-button.svg" alt="rewind audio button">
          </button>
          <button class="playButton"
            id="playButton"
            (click)="isAudioPlaying = true; controlAudio($event, 'play');"
            tabindex="0" aria-label="Play">
            <img role="presentation"
              src="assets/images/blueprint/voicemail/play-audio-button.svg" alt="play audio button">
          </button>
        </div>
      </div>
      <ng-container *ngIf="!isMobile">
        <div class="col-4 p-2 margin-top-20">
          <ng-container *ngIf="audioPlayedOnce">
            <div
              class="pauseState font-size-p-18 ceraPro-medium">
              Paused</div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isAudioPlaying">
      <div
        [class]="isMobile ? 'col-12 p-2' : 'col-4 p-2 margin-top-10'">
        <div class="controlContainer"
          id="controlContainer">
          <button class="rewindButton"
            (click)="rewindAudio($event)"
            tabindex="0" aria-label="Rewind">
            <img role="presentation"
              src="assets/images/blueprint/voicemail/rewind-audio-button.svg" alt="rewind audio button">
          </button>
          <button class="pauseButton"
            id="pauseButton"
            (click)="isAudioPlaying = false; controlAudio($event, 'pause');"
            tabindex="0" aria-label="Pause">
            <img role="presentation"
              src="assets/images/blueprint/voicemail/pause-audio-button.svg" alt="pause audio button">
          </button>
        </div>
      </div>
      <ng-container *ngIf="!isMobile">
        <div class="col-4 p-2 margin-top-20">
          <ng-container *ngIf="audioPlayedOnce">
            <div
              class="playState font-size-p-18 ceraPro-medium">
              Playing</div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div
    [class]="isMobile ? 'row' : 'row margin-bottom-20'"
    id="progress">
    <div class="col">
      <div id="seekObjContainer">
        <input id="timeline1" type="range"
          aria-label="Audio Progress" tabindex="0"
          class="playhead link-hover"
          style="margin-left: 0px;"
          aria-valuemin="0:00"
          [attr.aria-valuemax]="formattedAudioDuration"
          [attr.aria-valuenow]="audioCurrentTime"
          min="0.0" [attr.max]="audioDuration"
          [attr.value]="audioTime" />
        <output id="progress-output"></output>
      </div>
    </div>
  </div>
  <div class="row margin-bottom-10" id="duration">
    <div class="col pl-0 pr-0">
      <div id="playtime"
        class="playtime font-size-p-18 ceraPro-medium">
        0:00 - {{formattedAudioDuration}}</div>
    </div>
  </div>

  <div class="row pb-3" *ngIf="showTranscript" [ngClass]="isMobile ? 'pt-4' : 'pt-3'">
    <div [class]="isMobile ? 'col-12 p-2' : 'col-12 pb-2 pad-bottom-p-20-imp pad-left-1-2'" id="transcriptContainer">
      <a href="javascript:void(0)" tabindex="0" id="transcriptButton" class="transcriptButton" (click)="onClickOfTranscript()"
        [attr.aria-label]="transcriptToggle ? resourceBundle.expanded : resourceBundle.collapsed">
        <img role="presentation" src="assets/images/blueprint/voicemail/transcript-audio-button.svg" alt="transcript audio button">
        <span class="font-size-p-14 ceraPro-medium pad-left-0-8-imp pad-right-p-0-7-imp">Transcript</span>
        <img role="presentation" [ngClass]="{'rotate-180': transcriptToggle}"  src="assets/images/arrow-down.svg" alt="arrow down">
      </a>
    </div>
    <div class="col-12 pad-left-2 pad-right-2em" *ngIf="transcriptToggle">
      <ng-container *ngIf="transcriptText; else transcriptSkeletonLoader">
        <textarea readonly class="audioTranscipt pt-2" aria-label="Transcript"
         [ngClass]="isMobile ? 'height132' : 'height200'">{{transcriptText}}</textarea>
      </ng-container>
      <ng-template #transcriptSkeletonLoader>
        <div class="col-12 audioTranscipt white-bg" [ngClass]="isMobile ? 'height120 pad-top-0-0-5' : 'height200 pt-2'">
          <app-skeletonloader formName="transcript" formSkeleton="true" [rowCount]="isMobile ? 2 : 3"></app-skeletonloader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<audio id="audioPlayer"
  (ended)="isAudioPlaying = false">
  <source id="audioSource" [type]="mimeType" />
</audio>