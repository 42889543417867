<div class="modal-body">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 text-center" 
            [ngClass]="{'mt-0' : resetPasswordService.resetPwdMsg.displayMessage.show, 
            'margin-top-15' : !isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show,
            'margin-top-10px' : isMobile && !resetPasswordService.resetPwdMsg.displayMessage.show}">
        <h5 *ngIf="!resetPasswordService.forgotPasswordFlow" class="h1 font-cerapro-regular-imp">{{resourceBundle.chooseVerifyMethodsModalTitle}}</h5>
        <div [ngClass]="{'margin-botton-30p-imp' : !isMobile,'pad-bottom-30' : isMobile}"
            class="text-center pad-top-p-20">
            <span *ngIf="!isMyadmin">{{resourceBundle.chooseContMethdScanIdDesc}}</span>
            <span *ngIf="isMyadmin">{{resourceBundle.chooseContMethdScanIdDescMyAdmin}}</span>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 display-flex justify-content-center flex-wrap"
        [ngClass]="{'mb-5': isMyadmin}">
            <!-- Show/Hide Sms contact method -->
            <div class="text-center" [ngClass]="{'pad-right-1-875rem' : !isMobile,
                    'pad-bottom-30 col-12 display-flex justify-content-center div-center-align flex-direction-column' : isMobile}" *ngIf="displayContactMethods && showSmsMethod">
                <div id="textMessage" class="resetRecoveryOption" role="link" tabindex="0" aria-label="Text Message" (click)="generateOTP(contactMethod.authenticid, contactMethodType.sms)" (keydown.enter)="generateOTP(contactMethod.authenticid, contactMethodType.sms)">
                    <div class="pt-2">
                        <img alt="Text Message" class="centered pad-bottom-p-10 pad-top-0-5" aria-hidden="true"
                            src="assets/images/blueprint/malandingpage/mobile-phone.svg">
                        <a aria-hidden="true"> {{resourceBundle.textMessageOption}} </a>
                    </div>
                </div>
                <span role="presentation" class="text-blue-colour">{{otpPhoneValue}}</span>
            </div>
            <!-- Show/Hide Email contact method -->
            <div class="text-center" [ngClass]="isMobile ? 'col-7 display-flex justify-content-center div-center-align flex-direction-column' : 'col-4 '"
                *ngIf="displayContactMethods && showEmailMethod">
                <div id="emailAddress" class="resetRecoveryOption" role="link" tabindex="0" aria-label="Email" (click)="generateOTP(contactMethod.authenticid, contactMethodType.email)" (keydown.enter)="generateOTP(contactMethod.authenticid, contactMethodType.email)">
                    <div class="pt-2">
                        <img alt="Email Address" class="centered pad-bottom-p-10 pad-top-0-5" aria-hidden="true"
                            src="assets/images/blueprint/malandingpage/envelope-email.svg">
                        <a aria-hidden="true"> {{resourceBundle.emailAddressOption}} </a>
                    </div>
                </div>
                <div role="presentation" class="text-blue-colour width-96 word-wrap">{{otpEmailValue}}</div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 mb-5" *ngIf="!isMyadmin">
            <a role="link" target="_blank" [attr.href]="resourceBundle.needHelpLink"
                class="linkUnderline mt-4 pt-1">{{resourceBundle.needHelp}}<img
                    src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon" role="presentation"
                    class="width-20px margin-top-neg-5 pl-1"></a>
        </div>
    </div>
</div>