import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CoxHttpClient} from 'common-ui-lib';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AdminLandingService {

  public adminLandingActiveTab = 'Users';
  public isNewUser = false;
  public accountGuid = null;
  public sectionInView = new Subject<string>();
  constructor(private http: CoxHttpClient, private apollo: Apollo) {}

  public getSectionInView(): Observable<any>{
    return this.sectionInView.asObservable();
  }

  public updateSectionInView(sectionName: string){
    this.sectionInView.next(sectionName);
  }

  public setNavigationForNewUser(isNewUser: boolean) {
    this.isNewUser = isNewUser;
  }

  public getNavigationForNewUser(): boolean {
    return this.isNewUser;
  }

  public saveAccountGuid(accountGuid: string) {
    this.accountGuid = accountGuid;
  }

  public getAccountGuid(): string {
    return this.accountGuid;
  }
}
