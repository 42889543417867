import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { SessionStorageService } from 'ngx-webstorage';
import { takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AEMClientService, ResourceBundle } from '../../../services/aemclient.service';
import {TealiumUtagService} from '../../../tealium/utag.service';
import { BaseAccount } from '../../models/accounts/baseaccounts.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-equipmentaccountwidget7plusaccounts',
  templateUrl: './accountwidget7plusaccountsequipment.component.html',
  styleUrls: ['./accountwidget7plusaccountsequipment.component.scss'],
})
export class Accountwidget7plusaccountsEquipmentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() baseAccounts: BaseAccount[];
  @Output() selectedAccount = new EventEmitter<BaseAccount>();
  public searchItemEquip: string;
  numberofaccounts: number;
  oddAccounts: BaseAccount[] = [];
  evenAccounts: BaseAccount[] = [];
  allAccounts: BaseAccount[] = [];
  accountid: string;
  accountnumber: string;
  accountname: string;
  accountalias: string;
  accountaddress: string;
  accountaddress1: string;
  accountaddress2: string;
  accountaddresspostal: string;
  oddAccountsFlag: boolean;
  evenAccountsFlag: boolean;
  allAccountsFlag = true;
  toggleAccordian = false;
  accountselected = false;
  showselect = false;
  filterTypeequipment = 'anequip';
  inputEntered: string;
  results: BaseAccount[] = [];
  resultsDisplayed = 0;
  resultsLength = 0;
  noResults = true;
  pageLength = 7;
  resourceBundle: ResourceBundle = {};
  selectedEquipmentAccountDetails: BaseAccount;
  viewFlag: boolean;
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService,
              private activatedRoute: ActivatedRoute,
              private _storageService: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private tealium: TealiumUtagService) { }

  ngOnInit() {
    this.aemClient.getSharedBundle('accountWidget7PlusAccountsResourceBundle')
    .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => { this.resourceBundle = data; },
        (error) => { },
      );
    this.selectedEquipmentAccountDetails = JSON.parse(this.sessionStorage.retrieve('selectedEquipmentAccount'));
    this.viewFlag = this.sessionStorage.retrieve('viewAll');
    if (!this.viewFlag || this.viewFlag === undefined || this.viewFlag === null) {
      if ((window.location.href.indexOf('/myequipments') > 0)) {
        this.viewFlag = true;
      } else {
        this.viewFlag = false;
      }
    }
    if (this.selectedEquipmentAccountDetails && this.selectedEquipmentAccountDetails.accountId !== undefined &&
      (window.location.href.indexOf('/myequipments') > 0)) {
      this.displayAccountSelected(this.selectedEquipmentAccountDetails);
    }
  }

  ngOnChanges(): void {
    this.numberofaccounts = this.baseAccounts.length;
  }

  toggleAccordion() {
    this.toggleAccordian = !this.toggleAccordian;
    if (!this.toggleAccordian) {
      if (this.accountnumber != null) {
        this.showselect = true;
      }
    } else {
      this.showselect = false;
    }
  }

  // Function added for Account Widget Expand/Collapse - Accessibility
  public expandWidgetEquip() {
    let accWidgetEquip =
    document.getElementById('account-detailslist-gt7-accordian-titleEquip').getAttribute('aria-expanded');
    if (accWidgetEquip === 'true') {
      accWidgetEquip = 'false';
    } else {
      accWidgetEquip = 'true';
    }
    document.getElementById('account-detailslist-gt7-accordian-titleEquip').
    setAttribute('aria-expanded', accWidgetEquip);
  }
  // Function added for selecting Account Number - Accessibility
  public selectAccNoEquip() {
    this.setAriaLabel('accNumberEquip', 'Account Number Selected');
    this.setAriaLabel('accAddressEquip', 'Address');
    this.setAriaLabel('accAliasEquip', 'AccountAlias');
    this.setAriaLabel('accNameEquip', 'AccountName');
  }
  // tslint:disable-next-line: no-identical-functions  // Function added for selecting Address - Accessibility
  public selectAddrEquip() {
    this.setAriaLabel('accAddressEquip', 'Address Selected');
    this.setAriaLabel('accNumberEquip', 'AccountNumber');
    // tslint:disable-next-line: no-duplicate-string
    this.setAriaLabel('accAliasEquip', 'Account Alias');
    // tslint:disable-next-line: no-duplicate-string
    this.setAriaLabel('accNameEquip', 'Account Name');
  }
  // tslint:disable-next-line: no-identical-functions // Function added for selecting Account Alias - Accessibility
  public selectAliasEquip() {
    this.setAriaLabel('accAliasEquip', 'Account Alias Selected');
    this.setAriaLabel('accAddressEquip', 'Address');
    // tslint:disable-next-line: no-duplicate-string
    this.setAriaLabel('accNumberEquip', 'Account Number');
    this.setAriaLabel('accNameEquip', 'Account Name');
  }
  // tslint:disable-next-line: no-identical-functions // Function added for selecting Account Name - Accessibility
  public selectNameEquip() {
    this.setAriaLabel('accNameEquip', 'Account Name Selected');
    this.setAriaLabel('accAddressEquip', 'Address');
    this.setAriaLabel('accAliasEquip', 'Account Alias');
    this.setAriaLabel('accNumberEquip', 'Account Number');
  }

  private setAriaLabel(elementId: string, attrVal: string): void {
    document.getElementById(elementId).setAttribute('aria-label', attrVal);
  }

  displayFilterTextequip(filterType: string) {
    this.inputEntered = '';
    this.resetSearch();
    this.pageLength = 7;
    this.noResults = true;
    this.filterTypeequipment = filterType;
  }

  isSelectedequip(filterType: string) {
    if (this.filterTypeequipment === filterType) {
      return true;
    } else {
      return false;
    }
  }

  resetSearch() {
    this.results = [];
    this.oddAccounts = [];
    this.oddAccountsFlag = false;
    this.evenAccounts = [];
    this.evenAccountsFlag = false;
    this.allAccounts = [];
    this.allAccountsFlag = false;
    this.resultsDisplayed = 0;
    this.resultsLength = 0;
  }

  searchBaseAccountsList(input: string) {

    if (this.inputEntered.length < 3) {
      this.noResults = true;
      this.resetSearch();
      this.pageLength = 7;
    } else {
      this.handleSearchCriteria();
    }
  }

  handleSearchCriteria() {
    this.noResults = true;
    this.resetSearch();
    this.baseAccounts.forEach((element) => {
      if (this.matches(element)) {
        this.results.push(element);
      }
    });

    this.sortResults();
    this.arrangeAccounts();

  }

  matches(element: BaseAccount) {
    switch (this.filterTypeequipment) {
      // ACCOUNT NUMBER\
      case 'anequip':
        return element.accountNumber.indexOf(this.inputEntered) >= 0;

      // ADDRESS (FORMAT:- ADDRESS1, ADDRESS2, CITY, STATE ZIP)
      case 'adequip':
        let address = element.address.address1;
        address += element.address.address2;
        address += element.address.city;
        address += element.address.state;
        address += element.address.zip;

        // REMOVE NON-ALPHANUMBERIC CHARACTERS FOR THE SEARCH VALUE & CRITERIA
        return this.stripNonAlphaNumericCharacters(address).toUpperCase()
        .indexOf(this.stripNonAlphaNumericCharacters(this.inputEntered).toUpperCase()) >= 0;

      // ACCOUNT ALIAS
      case 'aaequip':
        return element.alias.toUpperCase().indexOf(this.inputEntered.toUpperCase()) >= 0;

      // ACCOUNT NAME
      case 'anameequip':
        return element.name.toUpperCase().indexOf(this.inputEntered.toUpperCase()) >= 0;

      default:
        break;
    }

  }

  stripNonAlphaNumericCharacters(arg: string) {
    const re = /[^a-zA-Z0-9]+/g;
    return arg.replace(re, '');
  }

  sortResults() {
    if (this.results.length === 0) {
      this.noResults = false;
    }

    switch (this.filterTypeequipment) {
      // ACCOUNT NUMBER\
      case 'anequip':
        return this.results.sort(this.sortAccNo);

      // ACCOUNT ALIAS
      case 'aaequip':
        return this.results.sort(this.alphanumSortAccAlias.bind(this));

      // ADDRESS (FORMAT:- ADDRESS1, ADDRESS2, CITY, STATE ZIP)
      case 'adequip':
        return this.results.sort(this.alphanumSortAddress.bind(this));

      // ACCOUNT NAME
      case 'anameequip':
        return this.results.sort(this.alphanumSortAccName.bind(this));

      default:
        break;
    }
  }

  sortAccNo(accObj1: BaseAccount, accObj2: BaseAccount): number {
    return parseInt(accObj1.accountNumber, 10) - parseInt(accObj2.accountNumber, 10);
  }

  alphanumSortAddress(accObj1: BaseAccount, accObj2: BaseAccount) {
    let address1 = accObj1.address.address1;
    address1 += accObj1.address.address2;
    address1 += accObj1.address.city;
    address1 += accObj1.address.state;
    address1 += accObj1.address.zip;
    let address2 = accObj2.address.address1;
    address2 += accObj2.address.address2;
    address2 += accObj2.address.city;
    address2 += accObj2.address.state;
    address2 += accObj2.address.zip;

    const aa = this.chunkify(address1.toLowerCase());
    const bb = this.chunkify(address2.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        const c = Number(aa[x]);
        const d = Number(bb[x]);
        if (c === aa[x] && d === bb[x]) {
          return c - d;
        } else { return (aa[x] > bb[x]) ? 1 : -1; }
      }
    }
    return aa.length - bb.length;
  }

  chunkify(t: string) {
    const tz = new Array();
    let x = 0;
    let y = -1;
    let n = 0;
    let i;
    let j;

    // TODO: recheck that 'while' loop, looks really weird
    // tslint:disable-next-line: no-conditional-assignment
    while (i = (j = t.charAt(x++)).charCodeAt(0)) {
      let m;
      if (i === 46 || (i >= 48 && i <= 57)) {
        m = 1;
      } else {
        m = 0;
      }
      if (m !== n) {
        tz[++y] = '';
        n = m;
      }
      tz[y] += j;
    }
    return tz;
  }

  alphanumSortAccName(accObj1: BaseAccount, accObj2: BaseAccount) {
    const aa = this.chunkify(accObj1.name.toLowerCase());
    const bb = this.chunkify(accObj2.name.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        const c = Number(aa[x]);
        const d = Number(bb[x]);
        if (c === aa[x] && d === bb[x]) {
          return c - d;
        } else { return (aa[x] > bb[x]) ? 1 : -1; }
      }
    }
    return aa.length - bb.length;
  }

  alphanumSortAccAlias(accObj1: BaseAccount, accObj2: BaseAccount) {
    const aa = this.chunkify(accObj1.alias.toLowerCase());
    const bb = this.chunkify(accObj2.alias.toLowerCase());

    for (let x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        const c = Number(aa[x]);
        const d = Number(bb[x]);
        if (c === aa[x] && d === bb[x]) {
          return c - d;
        } else { return (aa[x] > bb[x]) ? 1 : -1; }
      }
    }
    return aa.length - bb.length;
  }

  arrangeAccounts() {
    const scope = this;
    this.resultsLength = this.results.length;
    this.results.forEach((eachresult, i) => {
      if (i <= scope.pageLength) {
        if (i % 2) {
          scope.evenAccountsFlag = true;
          scope.evenAccounts.push(eachresult);
        } else {
          scope.oddAccountsFlag = true;
          scope.oddAccounts.push(eachresult);
        }
        scope.allAccountsFlag = true;
        scope.allAccounts.push(eachresult);
      } else {
        return false;
      }
      scope.resultsDisplayed++;
    });

  }

  seeMoreResults() {
    this.pageLength += 8;
    this.handleSearchCriteria();
  }

  searchAgain() {
    this.inputEntered = '';
    this.resetSearch();
    this.filterTypeequipment = 'anequip';
    this.pageLength = 7;
    if (!(window.location.href.indexOf('/landing') > 0)) {
      window.scrollTo(0, 0);
    }
  }

  displayAccountSelected(baseAccount: BaseAccount) {
    this.tealium.track('link', {
      eventName: 'AccountWidgetSelection',
      accountGuid: baseAccount.guid,
    });
    if (this.selectedEquipmentAccountDetails && this.viewFlag) {
      this.toggleAccordian = !this.toggleAccordian;
      this.sessionStorage.clear('viewAll');
      this.viewFlag = false;
    }
    this.toggleAccordian = !this.toggleAccordian;
    this.selectedAccount.emit(baseAccount);
    this.accountselected = true;
    this.showselect = true;
    this.accountid = baseAccount.accountId;
    this.accountnumber = baseAccount.siteId + '-' + baseAccount.accountNumber;
    this.accountname = baseAccount.name;
    this.accountalias = baseAccount.alias;
    let address2 = '';
    if (baseAccount.address.address2 !== '') {
      address2 = baseAccount.address.address2 + ', ';
    }
    this.accountaddress = baseAccount.address.address1 + ', ' +
      address2 + baseAccount.address.city + ', ' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
    this.accountaddress1 = baseAccount.address.address1;
    this.accountaddress2 = baseAccount.address.address2;
    this.accountaddresspostal = baseAccount.address.city + ',' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
    if (!(window.location.href.indexOf('/landing') > 0)) {
      window.scrollTo(0, 0);
    }
  }

  public enterSearchEquip(event: KeyboardEvent) {
    if (event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    if ( this.filterTypeequipment === 'anequip') {
     this.searchItemEquip = 'Account Number';
    } else if ( this.filterTypeequipment === 'adequip') {
      this.searchItemEquip = 'Address';
    } else if ( this.filterTypeequipment === 'aaequip') {
      this.searchItemEquip = 'Account Alias';
    }  else if ( this.filterTypeequipment === 'anameequip') {
      this.searchItemEquip = 'Account Name';
    }
    document.getElementById('searchCriteriaEquip').setAttribute('aria-label',
    'Please enter at least 3 characters to begin your '
     + this.searchItemEquip + ' search');
  }
  }
  showFilters() {
    this.toggleAccordion();
    this.searchAgain();
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
