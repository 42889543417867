import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { CBHelperService } from "common-ui-lib";
import { Observable } from "rxjs";

export const UnAuthGaurd: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
    const cbhelper = inject(CBHelperService);
    const router = inject(Router);

        if(cbhelper.isUserAuthenticated()){
            router.navigateByUrl('/portal');
            return false;
        }else{
            return true;
        }
}