/* eslint-disable max-len */
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpUrlEncodingCodec  } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'ngx-localstorage';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
// import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { Cookie, DeleteCookies, LocalStorageKeys } from '../constants/storage.model';
import { AppConfig } from '../core/app.config';
import { CommonMessageService } from '../shared/redesign/commonmessage/commonmessage.service';
import { SimpeAccount9 } from '../shared/models/accounts/baseaccounts.model';
import { VoiceGlobals } from '../shared/models/globals/voice.globals';
import { UserProfile } from '../shared/models/userprofile/userprofile.model';
import { TimeOutComponent } from '../shared/timeout/timeoutmodal.component';
import { VoiceLandingPageService } from '../shared/voice-landing-page/voice-landing-page.service';
import { AuthTokenService } from './auth-token.service';
import { CBHelperService } from './cbhelper.service';
import { CoxHttpClient } from './coxhttpclient.service';
import { RefreshTokenClient } from './keepalive.service';
import { UserIdleService } from './userIdleService';
import { CookieDomain } from '../constants/domain.model';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from 'common-ui-lib';
@Injectable()
export class LoginProfileClient {
  authToken: string;
  modalRef: NgbModalRef;
  isTimedout = false;
  isProfileInContext = false;
  isCSRProfileNotInContext = false;
  isCBAdmin = false;
  interval: number;
  codec = new HttpUrlEncodingCodec();
  public simpleAccountUdoData = new Subject<boolean>();
  public userAuthenticated = new BehaviorSubject<boolean>(false);
  public inactivityStartTime = 0;
  public activeAccountsInProfile = 0;
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private _UDOAccounts: string;
  private _UDOSiteIds: string;

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private cookieService: CookieService,
    private _storageService: LocalStorageService,
    private _sessionStorage: SessionStorageService,
    private userIdle: UserIdleService,
    private modalService: NgbModal,
    private refresh: RefreshTokenClient,
    private authTokenService: AuthTokenService,
    private voiceGlobal: VoiceGlobals,
    private router: Router,
    private cbHelper: CBHelperService,
    public voiceLandingPageService: VoiceLandingPageService,
    private location: Location,
    private coxhttpClientService: CoxHttpClient,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private apollo: Apollo,
    private commMessageService: CommonMessageService
  ) {
    // This flag is used to access Myadmin in LOCAL machine. Always false in all environments.
    const localMyadminFlag = this.config.getConfig('localMyadminFlag')
      ? JSON.parse(this.config.getConfig('localMyadminFlag'))
      : false;
    this.isCBAdmin = this.cbHelper.isMyAdmin() || localMyadminFlag;
    this.tokenRenew();
  }

  public initUserIdle() {
    //localStorage.removeItem(LocalStorageKeys.TimerActive);
    this._storageService.remove(LocalStorageKeys.TimerActive);
    // Start watching for user inactivity.
    if (this.cbHelper.isUserAuthenticated()) {
      this.startWatchIdle();
    }

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      console.log('OKTAFLOW - onTimerStart : ', new Date());
      if (this.cbHelper.isUserAuthenticated() && !this.cbHelper.isUnauthUrl() && count === 1) {
        console.log('OKTAFLOW - onTimerStart Modal : ', new Date());
        const modalOption: NgbModalOptions = {};
        // localStorage.setItem(LocalStorageKeys.TimerActive, "true");
        this._storageService.set(LocalStorageKeys.TimerActive, 'true');
        this.inactivityStartTime = new Date().getTime();
        console.log('Inactivity Start Time', this.inactivityStartTime);
        modalOption.backdrop = 'static';
        modalOption.keyboard = false;
        this.modalRef = this.modalService.open(TimeOutComponent, modalOption);
        this.modalRef.result.then(() => {
          // need to call cox.com
          //localStorage.removeItem(LocalStorageKeys.TimerActive);
          this._storageService.remove(LocalStorageKeys.TimerActive);
          this.inactivityStartTime = 0;
          this.userIdle.resetTimer();
        });
        return;
      }
      if (this._storageService.get(LocalStorageKeys.TimerActive, 'cbma') !== 'true' && count > 1) {
        //changed localStorage.getItem
        console.log('OKTAFLOW - onTimerStart close modal : ', new Date());
        if (this.modalRef) {
          this.modalRef.close('');
        }
        this.inactivityStartTime = 0;
        this.userIdle.resetTimer();
        if (!this.cbHelper.isUserAuthenticated()) {
          console.log('OKTAFLOW - logout not authenticated', new Date());
          this.logout();
        }
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log('OKTAFLOW - onTimeout', new Date());
      this.isTimedout = true;
      if (this.modalRef) {
        console.log('OKTAFLOW - onTimeout close Modal', new Date());
        this.modalRef.close('');
      }
      this.userIdle.resetTimer();
      this.inactivityStartTime = 0;
      console.log('OKTAFLOW - onTimeout logout', new Date());
      this.logout();
    });
  }

  // To-Do : Update logic in sprint 140
  public tokenKeepAlive(): void {
    if (!this.isTimedout) {
      this.authToken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
      const isExpired = this.jwtHelper.isTokenExpired(this.authToken);
      const interval = new Date().getTime() - parseInt(this._storageService.get(LocalStorageKeys.PrevCall, 'cbma'), 10); //changed localStorage.getItem
      if (!isExpired) {
        if (interval < this.config.getConfig('userIdle')?.keepAliveInterval * 1000) {
          return;
        }
        this._storageService.set(LocalStorageKeys.PrevCall, new Date().getTime().toString()); //changed localStorage.setItem
        this.refresh.getRefreshToken().subscribe(
          (data) => {
            console.log('Token Refreshed');
          },
          (error) => {
            console.log('failed to refresh token', error);
            if (this.modalRef) {
              this.modalRef.close('');
            }
            console.log('logout 3');
            this.logout();
          }
        );
        const getInactivityStartTime = new Date().getTime();
        console.log('Get Inactivity Start Time', getInactivityStartTime);
        const checkLogout =
          this.inactivityStartTime !== 0 && getInactivityStartTime >= this.inactivityStartTime + 450000;
        if (checkLogout) {
          if (this.modalRef) {
            this.modalRef.close('');
          }
          this.logout();
        }
      } else {
        if (this.modalRef) {
          this.modalRef.close('');
        }
        console.log('logout 4');
        this.logout();
      }
    }
  }

  public async getLoggedInProfile() {
    // Reset timer once user logs-in to application. To fresh start the user timer idle watch
    if (this.userIdle) {
      this.userIdle.resetTimer();
    }
    const _curruser = this.cookieService.get(this.config.getConfig('cookies')['currentUser']);
    const _userType = this.cookieService.get(Cookie.UserType);
    this.authToken = JSON.parse(window.localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken;
    console.log('INITIAL DATA', _curruser, _userType);
    if (this.authToken) {
      const isExpired = this.jwtHelper.isTokenExpired(this.authToken);
      const decodedToken = this.jwtHelper.decodeToken(this.authToken);
      await this.getLoginDetails(decodedToken.sub);
    } else {
      if (_userType === '' || _userType === undefined || _userType === 'false') {
        console.log('this.getLoginDetails(_curruser);', _curruser);
        try {
          await this.getLoginDetails(_curruser);
        } catch (e) { }
      }
    }
  }

  public async getLoginDetails(username: string) {
    console.log('getLoginDetails Called');
    return new Promise<void>((resolve, reject) => {
      if (username === '' || username == null || username === undefined) {
        if (!this.isCBAdmin && !this.cbHelper.isUnauthUrl()) {
          console.log('logout 5');
          this.logout();
        }
        resolve();
        return;
      }
      this.getLoginProfile(username).subscribe(
        () => {
          this.userAuthenticated.next(true);
          this.authTokenService.setGlobals();
          resolve();
        },
        (error) => {
          if (error.message.includes('ExecutionTimeout')) {
            console.log('OKTA FLOW login graph failed ExecutionTimeout');
            this.getLoginRestAPI(username).subscribe(
              () => {
                this.userAuthenticated.next(true);
                this.authTokenService.setGlobals();
                resolve();
              },
              (error) => {
                this.voiceGlobal.isGlobalError = true;
                reject();
              }
            );
          } else {
            this.voiceGlobal.isGlobalError = true;
            reject();
          }
        }
      );
    });
  }

  public getLoginRestAPI(userEmail: string, editUser: boolean = false): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        MA_TRANSACTION_ID: uuid(),
        clientid: this.config.getConfig('APIGW')['clientId'],
        apikey: this.config.getConfig('APIGW')['apiKey'],
        CB_SESSION: userEmail,
      }),
    };
    const loginProfileUri = this.config.getConfig('APIGW')['baseURL'] + this.config.getConfig('APPURI')['loginUrlV1'];
    return this.http
      .get<any>(loginProfileUri, { headers: httpOptions.headers, observe: 'response' })
      .map((response) => this.setLoginDetails(response, editUser, true));
  }

  public getLoginProfile(userEmail: string, editUser: boolean = false): Observable<any> {
    if (this.isCBAdmin) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          MA_TRANSACTION_ID: uuid(),
          clientid: this.config.getConfig('APIGW')['clientId'],
          apikey: this.config.getConfig('APIGW')['apiKey'],
          CB_SESSION: userEmail,
        }),
      };
      const loginProfileUri =
        this.config.getConfig('APIGW')['baseURL'] + this.config.getConfig('APPURI')['myAdminloginUrl'];
      return this.http
        .get<any>(loginProfileUri, { headers: httpOptions.headers, observe: 'response' })
        .map((response) => this.setLoginDetails(response, editUser));
    } else {
      const loginMutation = gql`
        mutation Login($userEmail: String!) {
          login(userEmail: $userEmail) {
            authtoken
            loginSessionObject
          }
        }
      `;
      return this.apollo
        .mutate({
          mutation: loginMutation,
          variables: {
            userEmail,
          },
        })
        .pipe(
          map((response: any) => {
            if (!response?.errors) {
              return this.setLoginDetails(response, editUser);
            } else if (response?.errors && response?.errors[0].errorType?.includes('ExecutionTimeout')) {
              throw new Error('ExecutionTimeout');
            } else if (response.errors && response.errors[0].errorType === '8802') {
              this.router.navigate(['/errorpage/autherror']);
            } else {
              this.router.navigate(['/unauth/logout']);
            }
          })
        );
    }
  }

  public setLoginDetails(response: any, editUser: boolean, isRestApi: boolean = false) {
    let body; let headers; let authToken; let errors; let errorInfo;
    if (this.isCBAdmin) {
      body = response.body;
      headers = response.headers;
      authToken = response.headers.get('cbma_authtoken');
    } else {
      if (isRestApi) {
        body = response.body;
        headers = response.headers;
        authToken = response.headers.get('cbma_authtoken');
      } else {
        body = JSON.parse(response.data.login.loginSessionObject);
        headers = response?.context?.response?.headers;
        authToken = response?.data?.login?.authtoken;
        errors = response?.errors;
        this.cbHelper.isValidRegistration = body.isValidUser ? body.isValidUser : false;
        this._storageService.set(LocalStorageKeys.ValidUser, this.cbHelper.isValidRegistration);
      }
    }
    const clientCurrTime = new Date().getTime();
    if (body.code === '0') {
      /**setting the nonce offset to a Global variable after Login happens */
      // this.voiceGlobal.nonceEpochOffset =
      //   //  response.context.response.
      //   this.offsetTime(clientCurrTime, Number(headers.get("Epoch_Curent_Time")));
      console.log('globaloffset value:', this.voiceGlobal.nonceEpochOffset);
      if (!this.isCBAdmin) {
        this.storeUserAndProfileDetails(response, isRestApi);
      }
      if (editUser) {
        this._storageService.set(LocalStorageKeys.UserToken, authToken);
      } else {
        this._storageService.set(LocalStorageKeys.Authtoken, authToken);
        this.cbHelper.processCredentials();
        return UserProfile.fromJSON(body);
      }
    } else {
      // remove all local storage
      if (editUser) {
        this._storageService.remove(LocalStorageKeys.UserToken, 'cbma');
      } else {
        this._storageService.remove(LocalStorageKeys.Authtoken, 'cbma');
      }
      this.cbHelper.processCredentials();
      // error handling for graphql
      if (errors) {
        errorInfo = this.commMessageService.getErrorInfo(errors, 'login');
      }
      // If user is not authorized to access CSR start page redirect to error page.
      if (body.code === '8802' || errorInfo?.code === '8802') {
        if (this.cbHelper.isMyAdmin()) {
          this.router.navigate(['/errorpage/csr/unauth']);
        } else {
          this.router.navigate(['/errorpage/autherror']);
        }
        throw Error;
      } else if (body.code === '11003' || errorInfo?.code === '11003') {
        console.log('OKTAFLOW - getLoginProfile Profile Merge InProgress');
        this.router.navigateByUrl('/unauth/logout?profilemergemaintenance=true');
        throw Error;
      }
    }
  }

  /**adding additional optional parameter 'unassignedUser' used in scenario
   * where Tn is not assigned yet
   */
  public getEditUserToken(
    userEmail: string,
    telNum: string,
    userType?: string,
    unassignedUser?: boolean
  ): Observable<any> {
    let impersonatorUrl = '';
    if (!userType) {
      impersonatorUrl = '/api/cbma/userauthorization/services/loginprofiles/impersonator/voice/' + telNum;
    } else {
      impersonatorUrl =
        '/api/cbma/voiceprofile/services/voiceprofile/impersonator/voice/' +
        telNum +
        '?userId=' +
        userEmail +
        '&userType=' +
        userType +
        '&unassignedUser=' +
        unassignedUser;
    }
    return this.http
      .get<any>(impersonatorUrl, { headers: this.coxhttpClientService.headers, observe: 'response' })
      .map((response) => {
        if (response.body.code === '0') {
          this._storageService.set(LocalStorageKeys.UserToken, response.headers.get('cbma_authtoken'));
          return UserProfile.fromJSON(response.body);
        } else {
          this._storageService.remove(LocalStorageKeys.UserToken, 'cbma');
          throw Error;
        }
      });
  }

  public reloadProfile(useremail?: string): Observable<UserProfile> {
    if (this.cbHelper.isMyAdmin() || useremail) {
      return this.reloadByImpersonateCall(useremail);
    } else {
      return this.reloadByLoginCall();
    }
  }

  // Hide the Geolocator in AEM Header
  public hideGeoLocator(): void {
    let awaitCounter = 0;
    const intervalHandler = setInterval(() => {
      if (awaitCounter < 500 && document.getElementsByClassName('geo-location').length > 0) {
        document.getElementsByClassName('geo-location')[0].innerHTML = '';
        clearInterval(intervalHandler);
      } else if (awaitCounter > 500) {
        clearInterval(intervalHandler);
      }
      awaitCounter++;
    }, 20);
  }

  /*Adding optional parameter that is exempted from clearing in session storage
      used in mfa reset factors
    */
  public async logout(mfaUser?: string, onSuccessURL?: string) {
    this.userIdle.stopWatching();
    if (this.interval) {
      clearInterval(this.interval);
    }
    const isHN = this.cbHelper.isHNCustomer === true;
    // MW service call to clear cache
    try {
      await this.callLogoutService();
    } catch (error) {
      console.log('Error in Logout');
    } finally {
      // await this.revokeAccessToken();
      const idToken = await this.oktaAuth.getIdToken();
      let redirectUri = this.isCBAdmin
        ? this.codec.decodeValue(`${window.location.origin}`) + '/cbma/unauth/myadminlogout'
        : this.codec.decodeValue(`${window.location.origin}`);
      const issuer = this.config.getConfig('okta')['issuer'];
      this.oktaAuth.signOut({ postLogoutRedirectUri: redirectUri });
      // this.oktaAuth.logout('');
      // Since we need external site url to redirect after logout.
      // So, storage will be cleared in ExternalLogoutComponent if onSuccessURL is present.
      if (!onSuccessURL) {
        this.clearSession(mfaUser);
      } else {
        // Post Logout we will be redirected to onSuccessURL
        redirectUri = onSuccessURL;
        console.log('OKTAFLOW-logout onsuccess url : ', redirectUri);
      }
      this.cbHelper.processCredentials();
      const queryParamDeeplink = isHN ? '/cbma/unauth/login?cust=HN' : '';
      if (idToken) {
        console.log('OKTAFLOW-logout onsuccess idToken && cbloginFlag : ', redirectUri);
        window.location.href =
          `${issuer}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}` + queryParamDeeplink;
      } else {
        console.log('OKTAFLOW-logout onsuccess else : ', redirectUri);
        isHN
          ? this.router.navigate(['/unauth/login'], { queryParams: { cust: 'HN' } })
          : this.router.navigate(['/unauth/login']);
      }
    }
  }

  public externalSiteLogout() {
    const _oktaToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
    this.clearSession();
    if (_oktaToken) {
      const _idToken = _oktaToken.idToken.idToken;
      const issuer = this.config.getConfig('okta')['issuer'];
      const redirectUri = `${window.location.origin}`;
      const cbloginFlag = JSON.parse(this.config.getConfig('coxdotcom')['cbloginFlag']);
      if (_idToken && cbloginFlag) {
        window.location.href =
          `${issuer}/v1/logout?id_token_hint=${_idToken}&post_logout_redirect_uri=` +
          this.config.getConfig('coxdotcom')['cblogoutUrl'] +
          `${redirectUri}`;
      }
    }
  }

  public clearSession(mfaUser?: string) {
    const deleteCookie = new DeleteCookies();
    this._storageService.clear(); // clear local storage
    this._sessionStorage.clear(); // clear session storage
    for (const cookie of Object.keys(this.config.getConfig('cookies'))) {
      this.delete_cookie(this.config.getConfig('cookies')[cookie]);
    }
    if (mfaUser) {
      this._sessionStorage.store('resetfactoruser', mfaUser);
    }
  }

  outageIntiate(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      MA_TRANSACTION_ID: uuid(),
      clientid: this.config.getConfig('APIGW')['clientId'],
      apikey: this.config.getConfig('APIGW')['apiKey'],
      CB_SESSION: this.cookieService.get(this.config.getConfig('cookies')['currentUser']),
      CBMA_AUTHTOKEN: this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
    });
    return this.http.get('/api/cbma/account/services/account/accounts/outage/initiate', {
      headers,
      observe: 'body',
    });
  }

  public get udoAccounts(): string {
    return this._UDOAccounts;
  }

  public get udoSiteIds(): string {
    return this._UDOSiteIds;
  }

  public async getNoCaasDetails(userid: string): Promise<any> {
    return this.coxhttpClientService.getAsync<any>(
      `/api/cbma/user/preferences/v1/services/userpreferences/V3/accounts/${userid}`,
      { disableSpinner: true, customeErrorMessage: '' }
    );
  }

  public async setUDOAccounts(accArray: SimpeAccount9[]) {
    this._UDOAccounts = this.formatUDOAccounts(accArray);
    this._UDOSiteIds = this.getSiteIdList(accArray).join();
    window.utag_data.cAccontNbrCount = this.activeAccountsInProfile;
    this.simpleAccountUdoData.next(true);
  }

  public getSiteIdList(profileAccounts: SimpeAccount9[]): string[] {
    const siteIdList: string[] = [];
    for (const account of profileAccounts) {
      if (!siteIdList.includes(account.siteId)) {
        siteIdList.push(account.siteId);
      }
    }
    return siteIdList;
  }

  public setSpecialCaseUDOAccounts() {
    this.getAccounts('BILLING').subscribe((results: any) => {
      this._UDOAccounts = this.formatUDOAccounts(results.data?.userAccountsByFeature);
      this._UDOSiteIds = this.getSiteIdList(results.data?.userAccountsByFeature).join();
      const userAccounts = results?.data?.userAccountsByFeature;
      this.activeAccountsInProfile = 0;
      userAccounts?.forEach(accounts => {
        if (accounts?.accountExternalAttrs?.status?.toUpperCase() === 'ACTIVE') {
          this.activeAccountsInProfile =
            this.activeAccountsInProfile + 1;
        }
      });
      this.simpleAccountUdoData.next(true);
    });
  }

  public getAccounts(featureName: string): Observable<any> {
    const getAccountInfoQuery = gql`
      query getAccountDetails($featureName: FeatureLookup!) {
        userAccountsByFeature(feature: $featureName) {
          siteId
          accountNumber
          accountExternalAttrs {
            status
          }
        }
      }
    `;

    return this.apollo.query({
      query: getAccountInfoQuery,
      variables: {
        featureName,
      },
    });
  }

  public async callLogoutService() {
    const idTokenSub = await this.cbHelper.getIdTokenSub();
    const logoutUrl = `/api/cbma/external/loginprofiles/logout`;
    await this.coxhttpClientService.postAsync(logoutUrl, {userId: idTokenSub});
  }

  private tokenRenew() {
    this.oktaAuth.tokenManager.on('renewed', async (key, newToken, oldToken) => {
      this.tokenKeepAlive();
    });
    this.oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
      this.tokenKeepAlive();
    });
  }

  private storeUserAndProfileDetails(response: any, isRestApi: boolean = false) {
    const sessionObject = isRestApi ? response.body : JSON.parse(response.data.login.loginSessionObject);
    const loggedInUserDetails = sessionObject.loggedInUserDetails ? sessionObject.loggedInUserDetails : {};
    this._storageService.set(LocalStorageKeys.LoggedInUserDetails, JSON.stringify(loggedInUserDetails));
    const loggedInProfileDetails = sessionObject.loggedInProfileDetails ? sessionObject.loggedInProfileDetails : {};
    this._storageService.set(LocalStorageKeys.LoggedInProfileDetails, JSON.stringify(loggedInProfileDetails));
  }
  /**
   *
   * @param locatime clients machine time
   * @param servertime server time
   * @returns the offset between the clients machinetime and server time
   * Also saving the offset value in session storage to use in case of refresh or value is lost
   */
  private offsetTime(locatime: number, servertime: number) {
    console.log('browser current time::', locatime);
    console.log('server current time::', servertime);
    this._sessionStorage.store('nonceoffset', servertime - locatime);
    return servertime - locatime;
  }

  private reloadByImpersonateCall(useremail?: string): Observable<UserProfile> {
    const myAdminLoginUrl =
      this.config.getConfig('APIGW')['baseURL'] + this.config.getConfig('APPURI')['userProfileV3'];
    const requestBody = useremail
      ? { id: null, userEmail: useremail }
      : { id: null, userEmail: this.cbHelper.getLoggedInUserDetails().profile.userToken.profileOwnerEmail };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        MA_TRANSACTION_ID: uuid(),
        clientid: this.config.getConfig('APIGW')['clientId'],
        apikey: this.config.getConfig('APIGW')['apiKey'],
        CB_SESSION: this.cbHelper.getMasterUserEmail(),
        CBMA_AUTHTOKEN: this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      }),
    };
    return this.http
      .post<any>(myAdminLoginUrl, requestBody, { headers: httpOptions.headers, observe: 'response' })
      .map((response) => {
        if (response.body.code === '0') {
          this._storageService.set(LocalStorageKeys.Authtoken, response.headers.get('cbma_authtoken'));
          this._storageService.set(
            LocalStorageKeys.LoggedInProfileDetails,
            JSON.stringify(response.body.loggedInProfileDetails)
          );
          this._storageService.set(
            LocalStorageKeys.LoggedInUserDetails,
            JSON.stringify(response.body.loggedInUserDetails)
          );
          this._storageService.set(LocalStorageKeys.BroadworksDetails, JSON.stringify(response.body.broadworksDetails));
          this._storageService.set(LocalStorageKeys.SubmittedUser, response.body.loggedInUserDetails.contact.email);
          const authToken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
          const isExpired = this.jwtHelper.isTokenExpired(authToken);
          const decodedToken = this.jwtHelper.decodeToken(authToken);
          const parsedToken = JSON.parse(decodedToken.profile);
          const isCSRProfileNotInContext = parsedToken && parsedToken['userToken']['userType'] === 'CSR';
          const isProfileInContext = parsedToken['userToken']['profileId'] !== null;
          if (isProfileInContext && !isCSRProfileNotInContext && !isExpired) {
            this.authTokenService.setGlobals();
            this.voiceLandingPageService.setUserRolesFeatureDetailsArray(null);
          }
          // Below line is to refresh AEM header and footer in case of HN profile
          this.cbHelper.processCredentials();
          this.cbHelper._hnScenarioObserver.next(this.cbHelper.isHNScenario);
          return UserProfile.fromJSON(response.body);
        } else {
          throw Error;
        }
      });
  }

  private reloadByLoginCall(useremail?: string): Observable<UserProfile> {
    const myAccountLoginUrl = this.config.getConfig('APIGW')['baseURL'] + this.config.getConfig('APPURI')['loginUrl'];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        MA_TRANSACTION_ID: uuid(),
        clientid: this.config.getConfig('APIGW')['clientId'],
        apikey: this.config.getConfig('APIGW')['apiKey'],
        CB_SESSION: this.cbHelper.getMasterUserEmail(),
        CBMA_AUTHTOKEN: this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      }),
    };
    return this.http
      .get<any>(myAccountLoginUrl, { headers: httpOptions.headers, observe: 'response' })
      .map((response) => {
        if (response.body.code === '0') {
          this._storageService.set(LocalStorageKeys.Authtoken, response.headers.get('cbma_authtoken'));
          this._storageService.set(
            LocalStorageKeys.LoggedInProfileDetails,
            JSON.stringify(response.body.loggedInProfileDetails)
          );
          this._storageService.set(
            LocalStorageKeys.LoggedInUserDetails,
            JSON.stringify(response.body.loggedInUserDetails)
          );
          this._storageService.set(LocalStorageKeys.BroadworksDetails, JSON.stringify(response.body.broadworksDetails));
          this._storageService.set(LocalStorageKeys.SubmittedUser, response.body.loggedInUserDetails.contact.email);
          const authToken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
          const isExpired = this.jwtHelper.isTokenExpired(authToken);
          const decodedToken = this.jwtHelper.decodeToken(authToken);
          const parsedToken = JSON.parse(decodedToken.profile);
          const isCSRProfileNotInContext = parsedToken && parsedToken['userToken']['userType'] === 'CSR';
          const isProfileInContext = parsedToken['userToken']['profileId'] !== null;
          if (isProfileInContext && !isCSRProfileNotInContext && !isExpired) {
            this.authTokenService.setGlobals();
            this.voiceLandingPageService.setUserRolesFeatureDetailsArray(null);
          }
          // Below line is to refresh AEM header and footer in case of HN profile
          this.cbHelper.processCredentials();
          this.cbHelper._hnScenarioObserver.next(this.cbHelper.isHNScenario);
          return UserProfile.fromJSON(response.body);
        } else {
          throw Error;
        }
      });
  }

  private delete_cookie(name: string) {
    const domain = new CookieDomain();
    if (this.cbHelper.isLocal()) {
      document.cookie = `${name}=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    } else {
      document.cookie = `${name}=; path=/; domain=${domain.coxdotcom}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      document.cookie = `${name}=; path=/; domain=${domain.cbma}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
  }

  private startWatchIdle(): void {
    this.userIdle.startWatching();
    this.interval = setInterval(() => {
      this.tokenKeepAlive();
      // tslint:disable-next-line: no-any
    }, this.config.getConfig('userIdle')?.keepAliveInterval * 1000) as any;
  }

  // Revoke call - To make access token invalid
  private async revokeAccessToken() {
    const accessToken = await this.oktaAuth.getAccessToken();
    const issuer = this.config.getConfig('okta')['issuer'];
    if (accessToken) {
      return new Promise((reslove, reject) => {
        this.http
          .post(
            `${issuer}/v1/revoke`,
            { token: accessToken, token_type_hint: 'access_token' },
            { withCredentials: true }
          )
          .subscribe(
            (data) => {
              if (data) {
                reslove(data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
  }

  private formatUDOAccounts(baseAccounts: SimpeAccount9[]): string {
    const accountList: string[] = [];
    for (const account of baseAccounts) {
      if (accountList.length === 5) {
        accountList.push(this.config.getConfig('UDOMoreAccounts'));
        return accountList.join();
      }
      accountList.push(account.siteId + '-' + account.accountNumber);
    }
    return accountList.join();
  }
}
