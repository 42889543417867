import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { CoxHttpClient } from '../../services/coxhttpclient.service';
import {
  EventListReqObj, EventListResponse,
  ScheduleList,
} from './scheduleform.model';

@Injectable()
export class SchedulesService {
  isEditUser = false;
  cb_session: string;
  cbma_authToken: string;
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(public coxhttp: CoxHttpClient,
  ) {
  }

  public getSchedulesDetails(type?: string): Observable<ScheduleList> {
    let schedulesUrl = '/api/cbma/voiceusermanagement/services/voiceusermanagement/scheduleList';
    if (type) {
      schedulesUrl = '/api/cbma/voiceusermanagement/services/voiceusermanagement/scheduleList?scheduleType=' + type;
    }
    return this.coxhttp.get<any>(schedulesUrl).map((response) => {
      if (response.hasOwnProperty('code') && response.code === '0') {
        return ScheduleList.fromJSON(response);
      } else {
        throw Error;
      }
    });
  }

  public getEventList(eventBody: EventListReqObj): Observable<EventListResponse> {
    const eventListUrl = '/api/cbma/voiceusermanagement/services/voiceusermanagement/eventList';
    return this.coxhttp.post(eventListUrl, eventBody).map((response: any) => {
      if (response.code === '0') {
        return EventListResponse.fromJSON(response);
      } else {
        throw Error;
      }
    });
  }

  public saveSchedules(eventBody: any): Observable<any> {
    const schSaveUrl = '/api/cbma/voiceusermanagement/services/voiceusermanagement/schedule/add';
    return this.coxhttp.put(schSaveUrl, eventBody).map((response: any) => {
      if (response.code === '0') {
        return response;
      } else {
        throw new Error(response.message.toString());
      }
    });
  }

  public updateSchedules(scheduleBody: any): Observable<any> {
    const schUpdateUrl = '/api/cbma/voiceusermanagement/services/voiceusermanagement/schedule/update';
    return this.coxhttp.post(schUpdateUrl, scheduleBody).map((response: any) => {
      if (response.code === '0') {
        return response;
      } else {
        throw new Error(response.message.toString());
      }
    });
  }

  public deleteSchedules(scheduleBody: any): Observable<any> {
    const schDelUrl = '/api/cbma/voiceusermanagement/services/voiceusermanagement/schedule/delete';
    return this.coxhttp.post(schDelUrl, scheduleBody).map((response: any) => {
      if (response.code === '0') {
        return response;
      } else {
        throw new Error(response.message.toString());
      }
    });
  }

  public updateEvent(scheduleBody: any): Observable<any> {
    const schUpdateEventUrl = '/api/cbma/voiceusermanagement/services/voiceusermanagement/event/update';
    return this.coxhttp.post(schUpdateEventUrl, scheduleBody).map((response: any) => {
      if (response.code === '0') {
        return response;
      } else {
        throw new Error(response.message.toString());
      }
    });
  }

  public deleteEvent(scheduleBody: any): Observable<any> {
    const delEventUrl = '/api/cbma/voiceusermanagement/services/voiceusermanagement/event/delete';
    return this.coxhttp.post(delEventUrl, scheduleBody).map((response: any) => {
      if (response.code === '0') {
        return response;
      } else {
        throw new Error(response.message.toString());
      }
    });
  }

}
