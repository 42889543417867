import { MicroserviceResponse } from 'common-ui-lib';

export class UserSearchResultList {
    transactionId: string;
    code: string;
    message: string;
    warning: string;
    userList:UserList[];
// fromJSON is used to convert an serialized version
// of the User to an instance of the class
static fromJSON(json: UserSearchResultListJSON|string): UserSearchResultList {
    if (typeof json === 'string') {
      // if it's a string, parse it first
      return JSON.parse(json, UserSearchResultList.reviver);
    } else {
      // create an instance of the User class
      const user = Object.create(UserSearchResultList.prototype);
      // copy all the fields from the json object
      return Object.assign(user, json);
    }
  }

// reviver can be passed as the second parameter to JSON.parse
// to automatically call User.fromJSON on the resulting value.
  static reviver(key: string, value: any): any {
    return key === '' ? UserSearchResultList.fromJSON(value) : value;
  }

// toJSON is automatically used by JSON.stringify
  toJSON(): UserSearchResultListJSON {
// copy all fields from `this` to an empty object and return in
    return Object.assign({}, this);
  }
}


export class UserList {
    transactionId: string;
    code: string;
    message: string;
    warning: string;
    firstName: string;
    lastName: string;
    description: string;
    email: string;
    profileOwnerEmail: string;
    profileGuid?: string;
    profileStatus?: string;
    profileHasopenOrders?: boolean;
}

export enum ProfileStatus{
  SHELL = 'shell',
  ACTIVE = 'active',
  REGISTERED = 'registered'
}

export class UserSearchResultListJSON {
  transactionId: string;
  code: string;
  message: string;
  warning: string;
  userList:UserList[];
}

export class AccountSearchResultList {
    transactionId: string;
    code: string;
    message: string;
    warning: string;
    accountList:AccountList[];
// fromJSON is used to convert an serialized version
// of the User to an instance of the class
static fromJSON(json: AccountSearchResultListJSON|string): AccountSearchResultList {
    if (typeof json === 'string') {
      // if it's a string, parse it first
      return JSON.parse(json, AccountSearchResultList.reviver);
    } else {
      // create an instance of the User class
      const user = Object.create(AccountSearchResultList.prototype);
      // copy all the fields from the json object
      return Object.assign(user, json);
    }
  }

// reviver can be passed as the second parameter to JSON.parse
// to automatically call User.fromJSON on the resulting value.
  static reviver(key: string, value: any): any {
    return key === '' ? AccountSearchResultList.fromJSON(value) : value;
  }

// toJSON is automatically used by JSON.stringify
  toJSON(): AccountSearchResultListJSON {
// copy all fields from `this` to an empty object and return in
    return Object.assign({}, this);
  }
}


export class AccountList {
    accountNumber: string;
    alias: string;
    companyId: string;
    divisionId: string;
    name: string;
    siteId: string;
    profileOwnerEmail: string;
    siteName: string;
    aclId:string;
    status:string;
    guid:string;
    type:string;
    accountId:string;
    addressVo:string;
    version:string;
    legacy:string;
    serviceCodeList:string;
    phoneNumber:string;
    profileGuid?: string;
}

export class AccountSearchResultListJSON {
    transactionId: string;
    code: string;
    message: string;
    warning: string;
    accountList:AccountList[];
}

export class ExceptionReport{
    constructor( private jobType: string, private jobStatus: string,
      private lastCsrId: string, private dateCreated?: string,
      private dateUpdated?: string, private id?: number) { }  
}
export class ResponseStatus {
  id: string;
  transactionId: string;
  code: string;
  message: string;
  warning: string;
}

export interface MarketRollOutStatus extends MicroserviceResponse {
  testMarket: boolean;
}
