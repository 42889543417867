import { Injectable } from '@angular/core';

@Injectable()
export class PrintService {
  constructor() { }
  public printDocument(content: string, title: string, styleScript?: string): void {
    const printWindow = window.open('', '_blank', 'height=600,width=800');
    if (styleScript) {
      printWindow.document.write('<style>' + styleScript + '</style>');
    }
    printWindow.document.write('<html><head><title>' + title + '</title>');
    printWindow.document.write('<style type="text/css" media="print">* {-webkit-print-color-adjust: exact !important;');
    printWindow.document.write('color-adjust: exact !important;}</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 250);
  }
}
