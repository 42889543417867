import { MicroserviceResponse } from 'common-ui-lib';
export class ActivateProduct {
    role: string;
    rank: number;
    key: string;
    active: string;
    featureName: string;
    featureUrl: string;
    icon: string;
    target: string;
}

export class ProductsStatus {
    constructor(
        public isDoNotDisturb: boolean,
        public numberOfAccounts: number,
        public productLauncherFlags: {}
    ) {}
}

export class UpdateProductsStatus {
    constructor(
        public doNotShow: string,
        public isDNSStatusChanged: Boolean,
        public productLauncherFlags: {}
    ) {}
}
export interface VoicemailInventory extends MicroserviceResponse {
    unread: string;
    read: string;
    total: string;
}
export interface TargetedOffers extends MicroserviceResponse{
    ssoUrl: string;
}
export interface SymphonyFlow extends MicroserviceResponse{
    swapResponse: string;
    symphonyAlert: string;
}
export interface CbccAlert extends MicroserviceResponse {
    trialEndDate: string;
    authorizedUser: boolean;
    isMultiAccount: boolean;
    status: string;
    endUserWOP?: boolean;
}

export enum ValidateSwap {
    Leadform = 'Lead Form',
    Buyflow = 'Buy Flow',
    Alreadyswapped = 'Already Swapped'
}
