import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig, CBHelperService, CommonMessageService, DeviceDetectorService,
   ResourceBundle, SelfInstallService, TealiumUtagService } from 'common-ui-lib';
import { DigitalProfileSharedService } from 'common-ui-lib';
import { ChannelStatus,DeviceType,SelfInstallStatus,
   NetAssuranceDevices } from 'common-ui-lib/lib/self-install/self-install.model';
import { DeviceInstallationStepsComponent } from '../device-installation-steps/device-installation-steps.component';
import { ContactDetailsModalComponent } from '../contact-details-modal/contact-details-modal.component';

@Component({
  selector: 'app-order-delivered-postmvp',
  templateUrl: './order-delivered-postmvp.component.html',
  styleUrls: ['./order-delivered-postmvp.component.scss']
})
export class OrderDeliveredPostmvpComponent implements OnInit, OnDestroy {
  @Input() resourceBundle: ResourceBundle = {};
  public isMobileDevice = false;
  public installationStepsList = [];
  public startSpinner = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public ChannelStatus = ChannelStatus;
  public deviceType= DeviceType;
  public selfInstallStatus = SelfInstallStatus;
  public failOrSuccess = false;
  public postMvpTitle: string;
  public postMvpDesc: string;
  public isMyadmin: boolean;
  public netAssuranceDevices: NetAssuranceDevices;

  constructor(
    public selfInstallService: SelfInstallService,
    public digitalProfileService: DigitalProfileSharedService,
    private tealium: TealiumUtagService,public deviceDetectorService: DeviceDetectorService,
    private modalService: NgbModal,public activeModal: NgbActiveModal,
    public messageService: CommonMessageService, public cbHelperService: CBHelperService, private config: AppConfig) { }

  ngOnInit(): void {
    this.isMyadmin= this.cbHelperService.isMyAdmin();
    if (this.selfInstallService?.onepStatus === ChannelStatus.Failure) {
      this.messageService.showMessage(this.resourceBundle.failCommonMessage, 'error', 'installationSteps');
    }
    if (this.selfInstallService?.netAssuranceUnsuccessful) {
      this.messageService.showMessage(this.resourceBundle.issueInstalling, 'error', 'netAssuranceError');
    }
    if (this.selfInstallService.deviceInstallComplete === true) {
      this.postMvpTitle = this.resourceBundle.pendingEquipSuccessMsg;
      this.postMvpDesc = this.resourceBundle.pendingEquipSuccessDesc;
    } else if(this.selfInstallService.netAssuranceSuccessful){
      this.postMvpTitle = this.resourceBundle.orderDeliveredPostMvpTitle;
      this.postMvpDesc = this.resourceBundle.completedActivation;
    }else {
      this.postMvpTitle = this.resourceBundle.orderDeliveredPostMvpTitle;
      this.postMvpDesc = this.resourceBundle.orderDeliveredPostMvpDescription;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public openInstallationStepsModal(device: any){
    this.tealium.link({
      selfHelpName:'selfInstallStart',
      equipmentModelNumber: device.itemNumber
    });
    this.stepsForInstallation(device);
  }

  public triggerTealium(eventName: string){
    this.tealium.button(eventName, eventName);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public stepsForInstallation(device: any){
    this.messageService.displayMessage.show = false;
    if (device.deviceType === DeviceType.INTERNETDEVICE) {
      this.startSpinner = true;
      this.selfInstallService.getInstallationSteps(this.selfInstallService.accountGuid, device.channelSolutionID)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((results: any) => {
        if (!results.errors) {
          this.installationStepsList = results?.data?.getAccount?.getSelfInstallDeviceDetails.deviceContent;
          this.activeModal.close();
          this.startSpinner = false;
          const modalRef = this.modalService.open(DeviceInstallationStepsComponent, {
            windowClass: this.isMobileDevice ? '' : 'redesign-modal-md',
            backdrop: 'static'
          });
          modalRef.componentInstance.installationStepsList = this.installationStepsList;
          modalRef.componentInstance.deviceDetails = device;
          modalRef.componentInstance.resourceBundle = this.resourceBundle;
        } else {
          this.messageService.displayMessage.show = true;
          this.startSpinner = false;
          this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'installationSteps');
        }
      });
    } else if(!this.isMyadmin &&
       (device.itemNumber === NetAssuranceDevices.NCPA100 ||
       device.itemNumber === NetAssuranceDevices.NCPA300)){
        if(device.installStatus === this.selfInstallStatus.SUCCESS){
          this.startSpinner = true;
          this.selfInstallService.accountGuid = this.selfInstallService.accountGuid ?
           this.selfInstallService.accountGuid : this.cbHelperService.accountGuid;
          this.selfInstallService.getInstallationSteps(this.selfInstallService.accountGuid, device.channelSolutionID)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .subscribe((results: any) => {
            if (!results.errors) {
              this.activeModal.close();
              this.installationStepsList = results?.data?.getAccount?.getSelfInstallDeviceDetails.deviceContent;
              const modalRef = this.modalService.open(DeviceInstallationStepsComponent, {
                windowClass: this.isMobileDevice ? '' : 'redesign-modal-md',
                backdrop: 'static'
              });
              modalRef.componentInstance.installationStepsList = this.installationStepsList;
              modalRef.componentInstance.deviceDetails = device;
              modalRef.componentInstance.resourceBundle = this.resourceBundle;
            } else {
              this.messageService.displayMessage.show = true;
              this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'installationSteps');
            }
          });
        } else{
          this.activeModal.close();
          const modalRef = this.modalService.open(ContactDetailsModalComponent, {
            windowClass: this.isMobileDevice ? '' : 'redesign-modal-md',
            backdrop: 'static'
          });
          modalRef.componentInstance.deviceDetails = device;
          modalRef.componentInstance.resourceBundle = this.resourceBundle;
        }
    } else{
        this.activeModal.close();
        const modalRef = this.modalService.open(DeviceInstallationStepsComponent, {
          windowClass: this.isMobileDevice ? '' : 'redesign-modal-md',
          backdrop: 'static'
        });
        modalRef.componentInstance.deviceDetails = device;
        modalRef.componentInstance.resourceBundle = this.resourceBundle;
      }
  }

  public selfInstallClick(device: any){
    window.open(this.resourceBundle.selfInstallUrl, '_blank');
    this.tealium.link({
      selfHelpName:'selfInstallStart',
      equipmentModelNumber: device.itemNumber
    });
  }

  ngOnDestroy() {
    this.selfInstallService.deviceInstallComplete = false;
  }

}
