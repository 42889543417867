<div class="row margin-top-40">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <app-breadcrumb></app-breadcrumb>
    </div>
    <div class="row col-md-12 col-sm-12 col-xs-12">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ul>
                <li [innerHTML]="errorMessage" title="error" class="msg-error" rel="tooltip" data-alert></li>
            </ul>
        </div>
    </div>
</div>
