<app-commonmessage [type]="'success'" [timeout]="5000" [showSuccessTimeout]="true" [level]="'overlaySuccessMsg'"
    [accordionTitleMsg]="payArrangeMsg.displayMessage.message"
    *ngIf="payArrangeMsg.displayMessage.show && payArrangeMsg.displayMessage.id == 'billingHomeOverlay' && payArrangeMsg.displayMessage.type === 'success'">
  </app-commonmessage>
<!--Payment Arrangements Modal-->
<div *ngIf="!isMobileView">
    <h4 class="sr-only" id="modal-title" role="alert">
        <span *ngIf="arrangementsModal">{{payArrangeService.title}}</span>
        <span *ngIf="!arrangementsModal">{{title}}</span>
        {{resourceBundle.headingLevel4}}
    </h4>
</div>
<div *ngIf="arrangementsModal">
    <ng-container *ngIf="!isMobileView">
        <div role="dialog" aria-modal="true" aria-describedby="modal-title">
            <div class="modal-header modalheader">
                <div class="col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
                    <h4 class="modal-title h4">{{payArrangeService.title}}</h4>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right">
                    <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn"
                        (click)="onPayArrangeClose()" appModalfocus></a>
                </div>
            </div>
            <div class="modal-body" id="payArrangeModal"
                [ngClass]="payArrangeMsg.displayMessage.show ? '' : 'pad-2-5rem-3-25rem'">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-n3">
                        <app-commonmessage
                            *ngIf="payArrangeMsg.displayMessage.show && payArrangeMsg.displayMessage.id === 'payArrange'"
                            [type]="payArrangeMsg.displayMessage.type" [level]="'datatableRow'"
                            [rowLevelMessage]="payArrangeMsg?.displayMessage?.message">
                        </app-commonmessage>
                    </div>
                </div>
                <div *ngIf="payArrangeService.contentLoaded; else loadSkeletonTemplate">
                    <ng-container *ngTemplateOutlet="PayArrangeOptions"></ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isMobileView">
        <a class="linkUnderline track-clicks" href="javascript:void(0)" (click)="goToBillingHome()">{{resourceBundle.backLink}}</a>
        <div class="pl-2 pr-2">
            <div class="text-center pt-3" [ngClass]="payArrangeMsg.displayMessage.show ? 'mb-n4' : 'pb-3'">
                <div class="text-center" [ngClass]="{'pb-3': !payArrangeMsg.displayMessage.show }">
                    <h1>{{payArrangeService.title}}</h1>
                </div>
            </div>
            <app-commonmessage *ngIf="payArrangeMsg.displayMessage.show  && payArrangeMsg.displayMessage.type === 'error' 
                && payArrangeMsg.displayMessage.id === 'payArrange'" [type]="payArrangeMsg.displayMessage.type"
                [globalMessage]="payArrangeMsg.displayMessage.message" [level]="'globalPage'"></app-commonmessage>
            <ng-container *ngIf="payArrangeService.contentLoaded; else loadSkeletonTemplate">
                <ng-container *ngTemplateOutlet="PayArrangeOptions"></ng-container>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #PayArrangeOptions>
        <ng-container *ngFor="let option of paymentArrangementOptions;let i=index">
            <div [id]="option.paymentArrangementType" class="buttonBox mb-20 mouse-link track-clicks" [ngClass]="{'defocus': disabled && !count.includes(i)}" *ngIf="option.isEligibile"
                tabindex="0"(click)="onClickPayArrangeOption(option.paymentArrangementType)">
                <span>{{payArrangeType[option.paymentArrangementType]}}</span>
                <img src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" class="pb-2 pull-right"
                    alt="arrow next icon" role="presentation">
            </div>
        </ng-container>
        <ng-container *ngIf="unavailableOptions.length">
            <div class="margin-top-30 pad-left-2 mb-20 pad-right-1-875rem text-color-malp" tabindex="0">   
                <h5>{{resourceBundle.unavailable}}</h5>
            </div>
            <div *ngFor="let option of unavailableOptions" class="pad-left-2 mb-20 pad-right-1-875rem text-color-malp">
                <span class="margin-bottom-5px">{{payArrangeType[option.paymentArrangementType]}}</span> <br>
                <span class="font-size-p-14">{{option.failedRules[0].failureReason}}</span>
            </div>
        </ng-container>
    </ng-template>
</div>
<!--scheduleConfirmTemplate-->
<ng-template #scheduleconfirm let-close="close" let-dismiss="dismiss">
    <div class="modal-content">
        <div role="dialog" aria-modal="true" aria-describedby="modal-title">
            <h4 class="sr-only" aria-live="polite" aria-atomic="true" id="modal-title">{{resourceBundle.paymentConfirm}}{{resourceBundle.headingLevel4}}
            </h4>
            <div class="modal-header modalheader">
                <div class="col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
                    <h4 class="modal-title" [ngClass]="isMobileView ? 'h5 max-content' : 'h4'">
                        {{resourceBundle.paymentConfirm}}</h4>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right cancel">
                    <a aria-label="Close" class="cb-modal-close-btn" href="javascript:void(0)" role="button"
                        id="closeBtn" (click)="onPayArrangeClose()" appModalfocus>
                    </a>
                </div>
            </div>
            <div class="modal-body modalbody">
                <div class="pl-2">
                    <app-commonmessage *ngIf="shedulefutureupdationfailflag"
                    [type]="payArrangeMsg.displayMessage.type" [globalMessage]="resourceBundle.genericErrorMessage"
                    [level]="'globalPage'"></app-commonmessage>
                </div>
                <div id="Payment">
                    <div class="mt-3">{{resourceBundle.paymentConfirmText}}</div>
                    <div class="display-flex justify-content-end div-center-align" [ngClass]="{'margin-left-6': !isMobileView }">
                        <a href="javascript:void(0)" aria-label="Back"
                            (click)="onPayArrangeClose()" class="mt-3 pt-2 nowrap"><U>{{resourceBundle.back}}</U><img
                                src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon"
                                 class="width-20px mt-n1" /></a>
                        <div class="pl-3 pr-3 mt-3">
                            <button id="continueBtn" class="button done btn-long-text-schedule"
                                (click)="confirmation()">{{resourceBundle.schedulePaymentButton}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Request Or Reschedule Modal-->

    <div class="modal-content" *ngIf="(!isMobileView && rescheduleOrRequest)">
        <div  aria-modal="true" role="dialog">
            
            <div class="modal-header modalheader">
                <div class="col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
                    <h4  [ngClass]="isMobileView ? 'h5 max-content' : 'h4'">
                        {{title}}</h4>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right cancel">
                    <a #closeBtn aria-label="Close" class="cb-modal-close-btn" href="javascript:void(0)" role="button"
                    id="closeBtn" (click)="onPayArrangeClose()" appModalfocus>
                    </a>
                </div>
            </div>
            <div class="modal-body modalbody">
                <ng-container *ngTemplateOutlet="requestOrRescheduleFlow"></ng-container>
            </div>
        </div>
    </div>
<div *ngIf="isMobileView && rescheduleOrRequest">
    <div class="pl-2 mb-4">
        <a class="linkUnderline track-clicks" href="javascript:void(0)" (click)="onClickBack('back')" id="backBtn">
            {{resourceBundle.back}}<img
            src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon"
            class="width-20px mt-n1" /></a>
    </div>
    <div class="col-12 pb-4">
        <span class="h2 mb-0">{{title}}</span>
        <div class="col-12 p-0 mt-1">{{selectedAccountDetails.alias}}</div>
        <span>{{selectedAccountDetails.accountNumber12}}</span>
    </div>
      <ng-container *ngTemplateOutlet="requestOrRescheduleFlow"></ng-container>
</div>

<ng-template #requestOrRescheduleFlow>
    <div *ngIf="!isPayment">
        <app-stepper [ngClass]="isMobileView ? 'wide90 margin-left-1em mobStepper margin-top-3em mt-md-5' : 'margin-top-50'"
            class="mt-md-5 shortStepper requestStepper col-md-10 offset-md-1 col-sm-10 offset-sm-1"
            [stepIndex]="payArrangeService.currentStep - 1" [steps]="payArrangeService.steps" type="milestone">
        </app-stepper>
    </div>
    <!--Replace [maxDate] with the query response maxdate-->
    <div *ngIf="selectDateModal">
        <div class="pl-2">
            <app-commonmessage *ngIf="payArrangeMsg.displayMessage.show"
            [type]="payArrangeMsg.displayMessage.type" [globalMessage]="resourceBundle.genericErrorMessage"
            [level]="'globalPage'"></app-commonmessage>
        </div>
        <p-calendar [inline]="true" inputId="extensionDate" name="extensionDate" [minDate]="checkForToday"
            [maxDate]="maxDueDate" (onSelect)="onDateChange()" [(ngModel)]="extensionDate" baseZIndex="1"
            dateFormat="dd.mm.yy" class="extensionDate" [ngClass]="isMobileView ? 'display-flex justify-content-center' : 'margin-left-3em'" appPcalendarinline>
            <ng-template pTemplate="footer">
                <div class="display-flex pt-2 pb-3 justify-content-center">
                    <div class="square"></div><span class="margin-top-neg-5 pl-1 help_text calendarText">{{resourceBundle.paymentDate}}</span>
                </div>
            </ng-template>
            <ng-template pTemplate="date" let-date>
                <span class="calendarText"><span *ngIf="date.day>=1 && date.day<=9">0</span>{{date.day}}</span>
            </ng-template>
        </p-calendar>
        <div class="display-flex justify-content-end ml-4 div-center-align">
            <a href="javascript:void(0)" aria-label="Back"
                (click)="onClickBack('select-date')" class="mt-3 pt-2 nowrap track-clicks"><U>{{resourceBundle.back}}</U><img
                    src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon"
                     class="width-20px mt-n1" /></a>
            <div class="pl-3 pr-3 mt-3">
                <button id="continueBtn" class="button done track-clicks" (click)="reminder('select-date')"
                    [disabled]="selected">{{resourceBundle.continue}}</button>
            </div>
        </div>
    </div>

    <!--Remainder Modal-->
    <div *ngIf="reminderModal">
        <div class="pl-2">
            <app-commonmessage *ngIf="payArrangeMsg.displayMessage.show"
            [type]="payArrangeMsg.displayMessage.type" [globalMessage]="resourceBundle.genericErrorMessage"
            [level]="'globalPage'"></app-commonmessage>
        </div>
        <div class="col-12 mb-3" [ngClass]="!isMobileView ? 'ml-4 pl-0' : ''">
            <div class="mb-4">{{resourceBundle.thankYouMsg}}</div>
            <!--Replace totalAmountDue with query minDueAmount-->
            <h5 class="text-center" [ngClass]="!isMobileView ? 'd-flex justify-content-center mr-5' : ''">{{stringUtilService.formatByPlaceHolder(resourceBundle.billAndDateDesc,
                [this.minDueAmount, this.weekDay,this.selectedDate])}}</h5>
            <div class="mt-4 p-0" [ngClass]="isMobileView ? 'col-12' : 'col-11'">{{resourceBundle.remainderDesc}}</div>
        </div>
        <div class="display-flex justify-content-end div-center-align" [ngClass]="{'margin-left-6': !isMobileView }">
            <a href="javascript:void(0)" aria-label="Back"
                (click)="onClickBack('confirm')" class="mt-3 pt-2 nowrap track-clicks"><U>{{resourceBundle.back}}</U><img
                    src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon"
                     class="width-20px mt-n1" /></a>
            <div class="pl-3 pr-3 mt-3">
                <button id="continueBtn" class="button done track-clicks"
                    (click)="confirmation('confirm')">{{resourceBundle.continue}}</button>
            </div>
        </div>
    </div>

    <!--Confirmation Modal-->
    <div *ngIf="confirmationModal">
        <div class="col-12" [ngClass]="{'ml-4' : !isMobileView}">
            <h5>{{resourceBundle.thanks}}</h5>
            <span>{{resourceBundle.confirmationDesc}}</span>
            <h5 class="margin-top-32 margin-bottom-23px" id="download">{{resourceBundle.download}}</h5>
            <a href="javascript:void(0)" *ngIf="!isMobileView" (click)="exportCsv()" role="link" aria-labelledby="yesPlease download" class="display-flex"><img
                    role="presentation" src="assets/images/blueprint/admin_redesign/download-icon.svg"
                    alt="Download icon" class="mt-n2">
                    <div class="ml-2 text-decoration-underline" id="yesPlease">
                    <span>{{resourceBundle.yesPlease}}</span>
                </div></a>
        </div>
        <div class="pull-right" [ngClass]="{'margin-top-pos-1-5': !isMobileView}">
            <div class="pad-left-2 pr-3" *ngIf="!isMobileView">
                <button id="done" class="button done  margin-bottom-2-1em pad-left-4-imp pad-right-4em" (click)="onPayArrangeClose()">{{resourceBundle.done}}</button>
            </div>
            <div class="width-58 margin-left-45 margin-right-2rem" *ngIf="isMobileView">
                <button type="button" id="yesBtn"
                    class="pl-0 pr-0 pull-right margin-botton-22px justify-content-center display-flex button" aria-labelledby="yesBtn download" (click)="exportCsv()">{{resourceBundle.yesPlease}}</button>
                <button type="button" id="noThanksBtn"
                    class="button button-secondary pull-left btn-long-text margin-neg-top-2"
                    (click)="goToBillingHome()">{{resourceBundle.noThanks}}</button>
            </div>
        </div>
    </div>
    <div *ngIf="scheduleFuturePaymentModal">
        <ng-container *ngIf="payArrangeService.contentLoaded; else loadSpinTemplate">
            <app-schedule-future-payment [statementMopDetails]="statementMopDetails"
                [statementDetails]="statementDetails" (optionCode)="backfromScheduleFuturePayment($event)"
                (addPaymentEvent)="gotoAddPaymentFlow($event)" [isAddSuccess]="isAddSuccess"
                [newMOPObj]="payArrangeService.newMOPObj" (reviewEvent)="gotoReview($event)">
            </app-schedule-future-payment>
        </ng-container>
    </div>
    <!--Add Payment Method Form-->
    <div *ngIf="isAddPayment">
        <h4 class="text-center font-size-p-20">{{resourceBundle.addMOPHeader}}</h4>
        <app-redesignpaymentmethodform (modalClose)="redirectToMOPDetails($event)" (mopEmmiter)="newMOPDetails($event)"
            [restrictedMop]="restrictedMop" [singleAccountSelected]="singleAccountSelected"
            [isSPMAccount]="isSPMAccount" [requestType]="requestType" [editMopObject]="editMopObject"
            (mopEvent)="gotoMOP($event)" [accountGuid]="selectedAccountDetails.guid"
            [hasMaxNumberOfMops]="hasMaxNumberOfMops" [isScheduleFuturePayMOP]="isScheduleFuturePayMOP">
        </app-redesignpaymentmethodform>
    </div>
    <!-- End -->
    <div *ngIf="!isMOP && isReview">
        <app-review-submit-schedulepayment [singleStatementDetails]="statementDetails"
            (submitPaymentEvent)="gotoPayment()" (backEvent)="gotoMOPfromReview($event)" (editEvent)="gotoMOP($event)">
        </app-review-submit-schedulepayment>
    </div>
    <div *ngIf="isPayment && !isMobileView" id="Payment">
        <div class="pl-2">
            <app-commonmessage *ngIf="shedulefutureupdationfailflag"
            [type]="payArrangeMsg.displayMessage.type" [globalMessage]="resourceBundle.genericErrorMessage"
            [level]="'globalPage'"></app-commonmessage>
        </div>
        <div class="mt-3">{{resourceBundle.paymentConfirmText}}</div>
        <div class="display-flex justify-content-end div-center-align" [ngClass]="{'margin-left-6': !isMobileView }">
            <a href="javascript:void(0)" aria-label="Back"
                (click)="onClickBack()" class="mt-3 pt-2 nowrap"><U>{{resourceBundle.back}}</U><img
                    src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon"
                     class="width-20px mt-n1" /></a>
            <div class="pl-3 pr-3 mt-3">
                <button id="continueBtn" class="button done btn-long-text-schedule"
                    (click)="confirmation()">{{resourceBundle.schedulePaymentButton}}</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #loadSpinTemplate>
    <div class="col-6 offset-3 mt-2">
        <app-skeletonloader formSkeleton="true" rowCount="1"></app-skeletonloader>
    </div>
    <div class="col-12 mt-3">
        <app-skeletonloader formName="Mop List" formSkeleton="true" rowCount="5" datatableName="Mop List">
        </app-skeletonloader>
    </div>
</ng-template>

<ng-template #loadSkeletonTemplate>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
        <app-skeletonloader formName="payArrangeForm" formSkeleton="true" rowCount="5"></app-skeletonloader>
    </div>
</ng-template>