<div class="d-flex">
    <span *ngIf="featureName && isFeatureUpgradeVisible && featureName !== 'APPS' && featureName !== 'TV'">
        <a href="javascript:void(0)" class="pr-4" (click)="addService()" id="upgradeService"
            [ngClass]="{'pl-3 pb-2 linkUnderline': deviceService.isMobile(), 'pl-4': !deviceService.isMobile()}">{{resourceBundle.upgradeServiceLink}}</a>
    </span>
    <a *ngIf="!featureName" href="javascript:void(0)" id="addService" (click)="addService()" tabindex="0" role="link"
        [attr.aria-label]="resourceBundle.addUpgradeService" class="justify-content-center mx-auto"
        [ngClass]="{'w-50': deviceService.isMobile()}">
        <span><img src="assets/images/blueprint/admin_redesign/add_upgrade.svg"
                class="mt-3 mb-3 mouse-link d-block mx-auto" alt="Add Upgrade Icon"></span>
        <span>{{resourceBundle.addUpgradeService}}</span>
    </a>
</div>
<!--Add Service Modal-->
<ng-template #addServiceModal let-close="close" let-dismiss="dismiss">
    <div role="dialog" aria-modal="true" aria-describedby="modal-heading">
        <span class="sr-only" id="modal-heading">{{resourceBundle.alertDialog}}{{resourceBundle.addUpgardeHeaderSingle}}
            {{resourceBundle.headingLevel4}}</span>
        <div class="modal-header modalheader">
            <h4 class="h4 modal-title" id="modal-title">{{resourceBundle.addUpgardeHeaderSingle}}</h4>

            <a title="Close" id="closeBtn" class="cb-modal-close-btn" href="javascript:void(0)"
                (click)="close();resetModal();" role="button" appModalfocus></a>
        </div>
        <div class="modal-body col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
            <div class="margin-top-neg-40 margin-bottom-neg-10" *ngIf="disUpgradeFlow">
                <app-commonmessage [type]="'info'" [globalMessage]="myAdminWarnMsg" [level]="'global'">
                </app-commonmessage>
            </div>
            <div class="col-12 p-0 margin-bottom-15px margin-top-4" [class]="cbHelperService.isMyAdmin() && this.featureName ? 'margin-top-neg-15' : 'margin-top-4'">{{resourceBundle.accountModalDesc}}</div>
            <div class="col-lg-8 col-md-8 col-sm-12 col-12 p-0 offset-md-2">
                <app-redesign-accountwidget class="col-md-12 col-lg-12 col-12 pull-right pt-md-0 pt-3"
                    [accountList]="accountInfo" [featureName]="featureName" [hideWidgetTitle]="true"
                    (selectedAccountEvent)="selectedAccount($event)">
                </app-redesign-accountwidget>
            </div>
        </div>
        <hr class="hr mb-0">
        <div class="col-lg-12 col-md-12 d-flex justify-content-flexEnd pr-4 pb-4" *ngIf="!deviceService.isMobile()">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <button type="button" id="backBtn" [value]="resourceBundle.addNewLocationBtn" class="button button-secondary btn-long-text mr-3 mb-0 track-clicks"
                    (click)="close();callChatorSSO(false)" [ngClass]="{'btn-secondary-disabled': addNewLocDisableBtn || disUpgradeFlow}"
                    [disabled]="addNewLocDisableBtn || disUpgradeFlow">{{resourceBundle.addNewLocationBtn}}</button>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-right">
                <button type="button" id="confirmBtn" [disabled]="locationDisableBtn || disUpgradeFlow"
                    (click)="close();callChatorSSO(true);resetModal();" [value]="resourceBundle.continueBtn"
                    class="button mr-0 mb-0 track-clicks">{{resourceBundle.continueBtn}}</button>
            </div>
        </div>
        <div class="col-sm-12 col-xs-12 button-padding" *ngIf="deviceService.isMobile()">
            <button type="button" id="confirmBtn" [disabled]="locationDisableBtn || disUpgradeFlow"
                (click)="close();callChatorSSO(true);resetModal();" [value]="resourceBundle.continueBtn"
                class="button pull-right track-clicks">{{resourceBundle.continueBtn}}</button>
            <button type="button" id="backBtn" class="button button-secondary pull-left btn-long-text track-clicks"
                (click)="close();callChatorSSO(false)" [value]="resourceBundle.addNewLocationBtn" [ngClass]="{'btn-secondary-disabled': addNewLocDisableBtn || disUpgradeFlow}"
                [disabled]="addNewLocDisableBtn || disUpgradeFlow">{{resourceBundle.addNewLocationBtn}}</button>
        </div>
    </div>
</ng-template>

<!--Select which service Modal-->
<ng-template #whichService let-close="close" let-dismiss="dismiss">
    <div role="dialog" aria-modal="true" aria-describedby="modal-heading">
        <span class="sr-only" id="modal-heading">{{resourceBundle.alertDialog}}{{pageTitle}}
            {{resourceBundle.headingLevel4}}</span>
        <div class="modal-header modalheader">
            <h4 class="h4 modal-title" id="modal-title">{{pageTitle}}</h4>
            <a title="Close" id="closeBtn" class="cb-modal-close-btn" href="javascript:void(0)"
                (click)="close();resetModal();" role="button" appModalfocus></a>
        </div>
        <ng-container *ngIf="objectselection !== null || !onContinue; else boxSkeleton">
            <div class="modal-body pb-0" *ngIf="availableServiceUpgrades.length > 0 && onContinue">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-2" role="group">
                    <h4 class="ceraPro-medium" id="label_upgradeservice">{{resourceBundle.upgradeService}}</h4>
                </div>
                <div>
                    <ul class="p-0 row" aria-labelledby="label_upgradeservice"
                        [ngClass]="{'feature-wrapper': deviceService.isMobile()}">
                        <div *ngFor="let featureName of availableServiceUpgrades;let index=index;"
                            [ngClass]="{'col-4': !deviceService.isMobile()}">
                            <div class="service-tile d-flex" (click)="onLinkClick(featureName,$event,'Upgrade')"
                                (keydown.enter)="onLinkClick(featureName,$event,'Upgrade')" id="feature_{{index}}"
                                tabindex="0">
                                <span id="{{featureName.featureName}}" class="mouse-link align-items-center"
                                    role="button">
                                    <img alt="{{featureName.featureName}}" [src]="featureName.icon"
                                        class="img-size img-height-32" role="presentation">
                                    <figcaption class="blue" role="presentation">{{featureName.featureName}}
                                    </figcaption>
                                </span>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="modal-body pb-0 pt-0" *ngIf="availableAddonservices.length > 0 || !onContinue">
                <div class="margin-top-neg-40 margin-bottom-neg-10"
                    *ngIf="!this.onContinue && this.cbHelperService.isMyAdmin()">
                    <app-commonmessage [type]="'info'" [globalMessage]="myAdminWarnMsg" [level]="'global'">
                    </app-commonmessage>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-2" role="group">
                    <h4 class="ceraPro-medium" id="label_addservice">{{resourceBundle.addService}}</h4>
                </div>
                <div *ngIf="onContinue">
                    <ul class="p-0 row" aria-labelledby="label_addservice"
                        [ngClass]="{'feature-wrapper': deviceService.isMobile()}">
                        <div *ngFor="let featureName of availableAddonservices"
                            [ngClass]="{'col-4': !deviceService.isMobile()}">
                            <div class="service-tile d-flex" (click)="onLinkClick(featureName,$event,'Add')"
                                (keydown.enter)="onLinkClick(featureName,$event,'Add')" tabindex="0">
                                <span id="{{featureName.featureName}}" class="mouse-link align-items-center"
                                    role="button">
                                    <img alt="{{featureName.featureName}}" [src]="featureName.icon"
                                        class="img-size img-height-32" role="presentation">
                                    <figcaption class="blue" role="presentation">{{featureName.featureName}}
                                    </figcaption>
                                </span>
                            </div>
                        </div>
                    </ul>
                </div>
                <div *ngIf="!onContinue" [class]="cbHelperService.isMyAdmin() ? 'disable-section' : ''">
                    <ul class="p-0 row" [ngClass]="{'feature-wrapper': deviceService.isMobile()}">
                        <div *ngFor="let featureName of allServices" [ngClass]="{'col-4': !deviceService.isMobile()}">
                            <div class="service-tile d-flex" (click)="onLinkClick(featureName,$event,'Add')"
                                (keydown.enter)="onLinkClick(featureName,$event,'Add')" tabindex="0">
                                <span id="{{featureName.featureName}}" class="mouse-link align-items-center"
                                    role="button">
                                    <img alt="{{featureName.featureName}}" [src]="featureName.icon"
                                        class="img-size img-height-32" role="presentation">
                                    <figcaption class="blue" role="presentation">{{featureName.featureName}}
                                    </figcaption>
                                </span>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>

            <div class="modal-body pb-0"
                *ngIf="onContinue && (videoRplcmntVisibility() || (showAddNetAssurance && !cbHelperService.isMyAdmin()) || (showCbcc && !cbHelperService.isMyAdmin()))">
                
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-2" role="group">
                    <h4 class="ceraPro-medium" id="label_manageaddon">{{resourceBundle.manageAddOn}}</h4>
                </div>

                <div>
                    <ul class="p-0 row" aria-labelledby="label_manageaddon"
                        [ngClass]="{'feature-wrapper': deviceService.isMobile()}">
                        <div [ngClass]="{'col-4': !deviceService.isMobile()}" *ngIf="videoRplcmntVisibility()">
                            <div class="service-tile d-flex"
                                (click)="onLinkClick(getFeatureDescription('replacementParts'),$event,'Add')"
                                tabindex="0"
                                (keydown.enter)="onLinkClick(getFeatureDescription('replacementParts'),$event,'Add')">
                                <span id="{{ getFeatureDescription('replacementParts').featureName}}"
                                    class="mouse-link align-items-center" role="button">
                                    <img alt="{{ getFeatureDescription('replacementParts').featureName}}"
                                        [src]="getFeatureDescription('replacementParts').icon"
                                        class="img-size img-height-32" role="presentation">
                                    <figcaption class="blue overflow-wrap" role="presentation">{{
                                        getFeatureDescription('replacementParts').featureName}}
                                    </figcaption>
                                </span>
                            </div>
                        </div>

                        <div [ngClass]="{'col-4': !deviceService.isMobile()}"
                            *ngIf="showAddNetAssurance && !cbHelperService.isMyAdmin()">
                            <div class="service-tile d-flex"
                                (click)="onLinkClick(getFeatureDescription('netAssurance'),$event)" tabindex="0"
                                (keydown.enter)="onLinkClick(getFeatureDescription('netAssurance'),$event)">
                                <span id="{{ getFeatureDescription('netAssurance').featureName}}"
                                    class="mouse-link align-items-center" role="button">
                                    <img alt="{{ getFeatureDescription('netAssurance').featureName}}"
                                        [src]="getFeatureDescription('netAssurance').icon"
                                        class="img-size img-height-32" role="presentation">
                                    <figcaption class="blue" role="presentation">{{
                                        getFeatureDescription('netAssurance').featureName}}
                                    </figcaption>
                                </span>
                            </div>
                        </div>
                        <div [ngClass]="{'col-4': !deviceService.isMobile()}"
                            *ngIf="showCbcc && !cbHelperService.isMyAdmin()">
                            <div class="service-tile d-flex"
                                (click)="onLinkClick(getFeatureDescription('cbcc'),$event, 'Add')" tabindex="0"
                                (keydown.enter)="onLinkClick(getFeatureDescription('cbcc'),$event)">
                                <span id="{{ getFeatureDescription('cbcc').featureName}}"
                                    class="mouse-link align-items-center" role="button">
                                    <img alt="{{ getFeatureDescription('cbcc').featureName}}"
                                        [src]="getFeatureDescription('cbcc').icon"
                                        class="img-size img-height-32" role="presentation">
                                    <figcaption class="blue" role="presentation">{{
                                        getFeatureDescription('cbcc').featureName}}
                                    </figcaption>
                                </span>
                            </div>
                        </div>
                    </ul>
                </div>

            </div>
        </ng-container>
        <hr class="hr mb-0">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 display-flex justify-content-flexEnd pr-4">
            <a type="button" href="javascript:void(0)" id="backBtn" (click)="close();backToLocation();resetModal();"
                tabindex="0" class="mt-1 pt-4 mr-3"><U>{{backTitle}}</U>
                <img src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon"
                    role="presentation" class="width-20px mt-n1">
            </a>
            <button type="button" [disabled]="serviceDisableBtn" [value]="resourceBundle.continueBtn"
                (click)="close();serviceSelectionContinue();resetModal();" id="continueBtn"
                class="button mr-0 track-clicks">{{resourceBundle.continueBtn}}</button>
        </div>
    </div>
</ng-template>
<ng-template #boxSkeleton>
    <div class="col-12 justify-content-center" *ngFor="let number of [0,1,2]">
        <app-skeletonloader freeformSkeleton="true" count="1" sectionName="service title" widthSizeInPixels="200"
            heightSizeInPixels="30"></app-skeletonloader>
        <app-skeletonloader freeformSkeleton="true" count="3" sectionName="Services Cards" widthSizeInPixels="122"
            heightSizeInPixels="112"></app-skeletonloader>
    </div>
</ng-template>

<!--lets connect Modal-->
<ng-template #letsConnect let-close="close" let-dismiss="dismiss">
    <div role="dialog" aria-modal="true" aria-describedby="modal-heading">
        <span class="sr-only" id="modal-heading">{{resourceBundle.alertDialog}}{{resourceBundle.connectHeader}}
            {{resourceBundle.headingLevel4}}</span>
        <div class="modal-header modalheader">
            <span class="h4 modal-title" id="modal-title">{{resourceBundle.connectHeader}}</span>
            <a title="Close" id="closeBtn" class="cb-modal-close-btn" href="javascript:void(0)"
                (click)="close();resetModal();" role="button" appModalfocus></a>
        </div>
        <div class="modal-body col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-8 col-md-8 col-sm-12 p-0 offset-md-2">
                <a href="https://www.cox.com/business/contact-us.html" (click)="triggerTealium('Chat-With-Sales', true)"
                    class="service-tile row chat-trigger" target="_blank"
                    [ngClass]="{'defocus cursor-notAllowed': chatDisable}" tabindex="0"
                    [attr.data-chat-text]="oliverText" role="button" aria-label="Chat with sales now">
                    <div class="col-xs-12 col-md-12 col-lg-12 display-flex"
                        [ngClass]="{'pb-3': deviceService.isMobile()}">
                        <div class="col-md-6">
                            <img src="assets/images/blueprint/admin_redesign/oliver_icon.svg" alt="Oliver Icon"
                                class="mt-3 ml-5">
                        </div>
                        <div class="col-md-6 pl-0 mt-4 margin-left-neg-1">
                            <span class="black">{{resourceBundle.chatWith}}</span>
                            <br />
                            <span class="black">{{resourceBundle.salesNow}}</span>
                        </div>
                    </div>
                </a>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <a href="javascript:void(0)" type="button" (click)="goToContactSalesURL()" id="backBtn"
                        class="ml-lg-4 mt-4 linkUnderline">{{resourceBundle.leaveMsg}}
                        <img src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon"
                            role="presentation" class="width-20px mt-n1"></a>
                </div>
            </div>
        </div>
    </div>
</ng-template>