import { Injectable } from '@angular/core';
import { ProfileMicroservice } from './profile';

@Injectable()
export class Microservices {
    constructor(
        public profile: ProfileMicroservice,
    ) {}
}

export * from './profile';
