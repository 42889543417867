import { NgModule } from '@angular/core';

export { Select2OptionData, Select2TemplateFunction } from './ng2-select2.interface';
import { Select2libComponent } from './ng2-select2.component';

export { Select2libComponent } from './ng2-select2.component';

@NgModule({
    declarations: [Select2libComponent],
    exports: [Select2libComponent],
})
export class Select2Module {}
