<ng-container *ngIf="selectedTags.length > 0">
  <ng-container *ngFor="let tag of selectedTags">
    <span class="ml-2 display-inline-block redesign-filter__tag" role="text" attr.aria-label="filter + {{tag.value | textTransform:tag.value | titlecase }}">
      <span *ngIf="!tag?.avoidTitleCase">{{tag.value | textTransform:tag.value | titlecase }}</span>
      <span *ngIf="tag?.avoidTitleCase">{{tag.value}}</span>
        <a title="Clear filter {{tag.value | textTransform:tag.value | titlecase }}" class="redesign-filter__tag__close cb-modal-close-btn" tabindex="0" role="button" href="javascript:void(0)"
        (click)="deleteTag({clear :tag.value})"></a>
    </span>

  </ng-container>
  <label class="ml-3 mt-4"><a href="javascript:void(0)" class="linkUnderline" role="button" aria-label="Clear all filter tags"
      (click)="deleteTag({clear :'clearall'})">Clear all</a></label>
</ng-container>