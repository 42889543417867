import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModalRef,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, ResourceBundle, AppConfig } from 'common-ui-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  VoiceFileConversionRequest,
} from './voice-conversion.model';
import { VoiceConversionService } from './voice-conversion.service';

@Component({
  selector: 'app-voice-conversion',
  templateUrl: './voice-conversion.component.html',
  styleUrls: ['./voice-conversion.component.scss']
})
export class VoiceConversionComponent implements OnInit {

  @Input() fileBase64Data: string;
  @Input() fileName: string;
  @Input() voiceMailGreetingsFlag?: boolean;
  @Output() convertedFilename: EventEmitter<object>;
  @Output() onCancel: EventEmitter<boolean>;
  public fileConversionfailed = false;
  public voiceSupportUrl: string;
  public resourceBundle: ResourceBundle = {};
  modalService: any;
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService,
              private config: AppConfig,
              public voiceConversionService: VoiceConversionService,
              public activeModal: NgbActiveModal) {
                this.convertedFilename = new EventEmitter<object>();
                this.onCancel = new EventEmitter<boolean>();
              }

  ngOnInit(): void {
    this.aemClient.getSharedBundle('voiceConversion')
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
      (data: any) => {
        this.resourceBundle = data;
      }
    );
    this.voiceSupportUrl = this.config.getConfig('WC_WIDGET')?.voiceSupport;
  }

  public convertFile() {
    const request: VoiceFileConversionRequest = {
      contents: this.fileBase64Data,
      description: this.fileName,
      voiceMailGreetingsFlag: this.voiceMailGreetingsFlag
    };

    this.voiceConversionService.convertAudioFile(request).subscribe((data) => {
      if(data.code === '0') {
        const file = data.filename;
        this.convertedFilename.emit(data);
        this.activeModal?.close();
      } else {
        this.fileConversionfailed = true;
      }
    }, (error) => {
     this.fileConversionfailed = true;
    });
  }

  public onClickOfTryAgain() {
    this.fileConversionfailed = false;
    this.convertFile();
  }

  public onClickOfCancel(event: any) {
    this.fileBase64Data = null;
    this.fileName = null;
    this.onCancel.emit(true);
    this.activeModal?.close(event);
  }
}
