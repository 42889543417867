<div role="dialog" aria-modal="true" aria-describedby="dialogHeader">
  <div class="modal-header modalheader">
    <h4 class="modal-title" id="dialogHeader" attr.aria-label="{{resourceBundle.cbccModalHeader}} {{resourceBundle.cbccHeaderAriaLabel}}">{{resourceBundle.cbccModalHeader}}</h4>
    <a href="javascript:void(0)" aria-label="Close" role="button" class="cb-modal-close-btn float-right" id="cbccCloseBtn"
      (click)="close('Close click'); triggerTealium('close')" appModalfocus></a>
  </div>
  <div class="modal-body">
    <div class="mt-n5" *ngIf="isCSR">
      <app-commonmessage *ngIf="commonmessage.displayMessage.show" [type]="commonmessage.displayMessage.type"
        [globalMessage]="commonmessage.displayMessage.message" [level]="'global'">
      </app-commonmessage>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" [ngClass]="{'mb-4': isCSR}" *ngIf="!optedIn">
        <p>{{resourceBundle.cbccModalDesc1}}</p>
        <p>{{resourceBundle.cbccModalCsrDesc2}}</p>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0  margin-bottom-2em" *ngIf="optedIn">
        <p class="margin-botton-30p-imp" *ngIf="!cbHeaderService.showAccountModal">{{resourceBundle.cbccMultiDesc1}}</p>
        <p class="margin-botton-30p-imp" *ngIf="cbHeaderService.showAccountModal">{{resourceBundle.cbccMultiDescEmail}}</p>
      <div class="col-lg-8 col-md-8 col-sm-12 col-12 offset-md-2">
        <app-redesign-accountwidget [featureName]="featureName" [hideWidgetTitle]="true"
        (accountListEvent)="getAccountInfoList($event)" (selectedAccountEvent)="selectedAccount($event)">
        </app-redesign-accountwidget>
    </div>
    </div>
  </div><hr class="hr m-0">
  <div *ngIf="optedIn" class="col-md-12 col-lg-12" [ngClass]="{'display-flex justify-content-flexEnd pr-0': !isMobileDevice}">
      <button (click)="close('Close click'); triggerTealium('Close')" class="button button-secondary">
        Close          
      </button>
      <button class="button button-primary" [ngClass]="{'ml-4': !isMobileDevice}" [disabled]="accSelected" (click)="ssoRedirect();triggerTealium('Continue')">{{resourceBundle.cbccContinue}}</button>
  </div>
  <hr class="hr m-0">
  <div *ngIf="!optedIn" class="col-md-12 col-lg-12" [ngClass]="{'display-flex justify-content-flexEnd pr-0': !isMobileDevice}">
      <button (click)="close('Close click'); triggerTealium('Close')" class="button button-secondary">
        Close          
      </button>
      <button class="button button-primary" (click)="onClickOfOptIn();triggerTealium('Opt-In')" [ngClass]="{'ml-4': !isMobileDevice,'disableOptIn': isCSR}" [disabled]="isCSR">{{resourceBundle.cbccOptIn}}</button>
  </div>
</div>