import { Injectable } from '@angular/core';
import { CoxHttpClient } from 'common-ui-lib';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class IpAddressManagerModalService {

  public contentLoaded: boolean = false;

  constructor(private httpClient: CoxHttpClient,
    private apollo: Apollo) { }

  public getDynamicIpIpAddress(userId: String) {

    const getAccountQuery = gql`
    query getAccountDetails($userId: ID!){
      getAccount(id: $userId) { 

        dynamicIpConfiguration { 
    
          dynamicIpDataList { 
    
            customerEquipmentInfoList { 
    
              hardwareModemMac 
    
              ipv4 
    
              ipv6Lan 
    
              ipv6Wan 
    
            } 
    
            macAddress 
    
          } 
    
        } 
    
      }
    }
  `;

    return this.apollo.query({
      query: getAccountQuery,
      variables: {
        userId
      },
    });
  }

  public getStaticIpAddress(userId: String) {
    const getAccountQuery = gql`
    query getAccountDetails($userId: ID!){
      getAccount(id: $userId) {
        ipConfiguration {
          baseAddress
          primaryNameServiceFlag
          secondaryNameServerFlag
          ripV2AddressBlocks {
            gateway
            netmask
            primaryDNS
            secondaryDNS
            ipDetailsList { 
              ip 
              macAddress 
              smtpRelayEnabled 
            }
            malblockIpDataPrimaryIPV4
            malblockIpDataSecondaryIPV4
            malblockIpDataPrimaryIPV6
            malblockIpDataSecondaryIPV6
          }
          coIAddressDetails {
            ipAddress
            malblockIpDataPrimaryIPV4
            malblockIpDataPrimaryIPV6
            malblockIpDataSecondaryIPV4
            malblockIpDataSecondaryIPV6
            primaryDNS
            secondaryDNS
          }
          cidrData { 
            broadcast 
            customerNetmask 
            customerNetwork 
            firstUsable 
            lastUsable 
            numberOfHosts 
            pendingDeletes { 
              deletionMessage 
              ip 
            }
            suggestedDefaultGateway 
            wanAddress 
            wanGateway 
            wanNetmask 
          }
          dynamicData { 
            ipDetailsList { 
              ip 
              macAddress 
              smtpRelayEnabled 
            }
          }
          isValid
          overlayType
          staticData {
            gateway
            ipDetailsList {
              deletePending
              ip
              macAddress
              smtpRelayEnabled
            }
            netmask
            primaryDNS 
            secondaryDNS
          }
          malblockIpData {
            ipv4Adrress 
            ipv6Address 
          }
        }
      } 
    }`

    return this.apollo.query({
      query: getAccountQuery,
      variables: {
        userId
      },
    });
  }
}
