/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import crypto from 'crypto-es';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { CookieDomain, Directory } from '../constants/domain.model';

import { MaintenanceCategory } from '../constants/maintenancecategory.model';
import { Role } from '../constants/role.model';
import { LocalStorageKeys } from '../constants/storage.model';
import { AppConfig } from '../core/app.config';
import { PendoService } from '../services/pendo.service';
import { EditUserService } from './edituser.service';
import { CookieService } from 'ngx-cookie-service';
import { RegisterContext } from 'customer-registration/customer-registration.model';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from 'common-ui-lib';
import { DEFAULT_HELPER, IV, SALT } from '../okta/constants';
const oktaTokenStorage = 'okta-token-storage';

@Injectable()
export class CBHelperService {
    public accountGuid: string;
    public oktaAccessToken: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public HNScenario: Observable<boolean>;
    public _hnScenarioObserver: Subscriber<boolean>; // set proper type
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    public hideHeaderFooterSub = new BehaviorSubject<string>(HeaderFooterEnum.NONE);
    public initiateHeaderRefreshSub = new BehaviorSubject<boolean>(false);
    public selectedAccountNumber12: string;
    public showFavoriteIcon = false;
    public registerContextDetails: RegisterContext;
    public isValidRegistration = false;
    jwtHelper: JwtHelperService = new JwtHelperService();
    private _userDecodedToken: UserTokenData;
    // eslint-disable-next-line @typescript-eslint/ban-types
    private configRoles: object;
    private _isCalnet: boolean;
    private _isHNCustomer: boolean;
    private _isHNScenario: boolean;
    private _isWebex: boolean;
    private unsecuredPagesArray: string[] = [];
    private _guid: string;
    private _isMarketRollOut = 'false';
    constructor(
        private _storageService: LocalStorageService,
        private config: AppConfig,
        private editUser: EditUserService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
        private pendoService: PendoService,
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        private cookieService: CookieService
    ) {
        this.getJSONContent();
        this.processCredentials();
        this.HNScenario = new Observable<boolean>((observer) => {
            this._hnScenarioObserver = observer;
        });
        this.unsecuredPagesArray = this.config?.getConfig('unsecuredPages');
        const referrerPath = window.sessionStorage.getItem('referrerPath');
        if (referrerPath && referrerPath.toLowerCase().includes('onsuccess=webex')) {
            this._isWebex = true;
        }
        if (this.config?.getConfig('showFavoriteIcon')) {
            this.showFavoriteIcon = JSON.parse(this.config.getConfig('showFavoriteIcon'));
        }
    }

    /**
     * To Identify MyAdmin VS Myacccount
     */
    public isMyAdmin(): boolean {
        if (this.isUserAuthenticated() && this._userDecodedToken) {
            const loggedInUserType = this._userDecodedToken.profile.userToken.loggedInUserType;
            const userType = this._userDecodedToken.profile.userToken.userType;
            return 'CSR' === userType || (loggedInUserType && 'CSR' === loggedInUserType);
        }
        return window.location.hostname.split('.')[0].indexOf('myadmin') > -1 ||
            localStorage.getItem('ismyadmin') !== null;
    }

    /** check if application is working locally or not */
    public isLocal(): boolean {
        return window.location.hostname === 'local.coxbusiness.com' || window.location.hostname === 'localhost';
    }

    /**
     * Show/Hide header/footer based on HeaderFooterEnum value e.g. hideHeaderAndFooter(HeaderFooterEnum.HEADER))
     *
     * @param element
     * @returns viod
     */
    public hideHeaderAndFooter(element: string) {
        this.hideHeaderFooterSub.next(element);
    }

    /**
     * Refresh header to reload mega nav, tickets and messages
     *
     * @returns viod
     */
    public refreshHeader() {
        this.initiateHeaderRefreshSub.next(true);
    }

    public getQueryStringValues(url: string, key: string) {
        const strArray = url?.split('?');
        if (strArray && strArray.length > 1) {
            const params = strArray[1].split('&');
            for (const param of params) {
                if (param.split('=').length === 2 && param.split('=')[0].toLowerCase() === key.toLowerCase()) {
                    return param.split('=')[1];
                }
            }
            return null;
        } else {
            return null;
        }
    }

    //Restricted input for first and last name fields
    public restrictedInput(value: string) {
        let result = value;
        const regex = /((cmd)|(\.exe)|(links))/i;
        const isMatch = regex.test(value);
        result = isMatch ? value.replace(regex, '') : result;
        return result;
    }

    public isUserAuthenticated(): boolean {
        const oktaStorageJson = localStorage.getItem(oktaTokenStorage);
        if (oktaStorageJson) {
            const oktaData = JSON.parse(oktaStorageJson);
            return oktaData && (oktaData.idToken || oktaData.accessToken);
        }
        return false;
    }

    public get isHNCustomer(): boolean {
        return this._isHNCustomer;
    }

    public get getMarketRollOut(): boolean {
        if (!JSON.parse(this._isMarketRollOut ? this._isMarketRollOut : 'false') &&
            this._storageService.get(LocalStorageKeys.MarketRollOut, 'cbma')) {
            const isMarketRollOut = this._storageService.get(LocalStorageKeys.MarketRollOut, 'cbma');
            this._isMarketRollOut = isMarketRollOut ? isMarketRollOut : 'false';
        }
        return JSON.parse(this._isMarketRollOut);
    }

    public clearMarketRollOut(): void {
        this._isMarketRollOut = 'false';
        this._storageService.remove(LocalStorageKeys.MarketRollOut);
    }

    public get isCalnet(): boolean {
        return this._isCalnet;
    }

    public get isCbForceMaintenance(): boolean {
        const maintenanceList: string[] = this._storageService.get(LocalStorageKeys.Maintenancelist, 'cbma');
        return maintenanceList ? maintenanceList.includes(MaintenanceCategory.CBFORCE) : false;
    }

    public isUnauthUrl(): boolean {
        const pageUrl = window.location.href;
        for (const unsecuredPage of this.unsecuredPagesArray) {
            if (pageUrl.indexOf(unsecuredPage) !== -1) {
                return true;
            }
        }
        return false;
    }

    public get isUnauthHnURL(): boolean {
        return (!this.isMyAdmin() && this.isUnauthUrl && this.getQueryVariable('cust') === 'HN');
    }

    public get isHNScenario(): boolean {
        return this._isHNScenario;
    }

    public isOBUrl(): boolean {
        return window.location.href.indexOf('/onboarding/') !== -1;
    }

    public getLoggedInUserDetails(): UserTokenData {
        if (!this.isUserAuthenticated()) {
            return null;
        }
        const _authToken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
        if (_authToken) {
            const decodedToken = this.jwtHelper.decodeToken(_authToken);
            decodedToken.profile = JSON.parse(decodedToken.profile);
            return decodedToken;
        }
    }

    public getMasterUserEmail(): string {
        if (!this.isUserAuthenticated()) {
            return null;
        }
        return this._userDecodedToken?.sub;
    }

    public getAomsRole(): string {
        if (this.getLoggedInUserDetails()) {
            return this.getLoggedInUserDetails().profile.userToken.aomsRole;
        } else {
            return null;
        }
    }
    /**
     * To Convert UTC into local time
     *
     * @param inputdate
     * @returns
     */
    public convertingUTCtolocaltime(inputdate: string) {
        if (inputdate === undefined || inputdate == null) {
            return;
        }
        const localDate = new Date(inputdate);
        const utcTime = new Date(
            localDate.getTime() - localDate.getTimezoneOffset() * 60000
        );
        const targetTimeZone = this.getTimeZone();
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: targetTimeZone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        });
        const localtime = formatter.format(utcTime);
        return localtime;
    }
    public getTimeZone() {
        const region = new Intl.DateTimeFormat();
        const timeoptions = region.resolvedOptions();
        return timeoptions.timeZone;
    }
    /**
     * this method must be called after authtoken or cbsession was set/deleted/changed!
     */
    public processCredentials(): void {
        const authtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
        if (authtoken) {
            const decodedToken = this.jwtHelper.decodeToken(authtoken);
            decodedToken.profile = JSON.parse(decodedToken.profile);
            this._userDecodedToken = decodedToken;
        }
        if (this.isUserAuthenticated() && this._userDecodedToken) {
            this._isHNCustomer = !this.isMyAdmin() &&
                this._userDecodedToken.profile.roles.includes(Role.PROFILE_HN_FLAG_ROLE) &&
                (this._userDecodedToken.profile.userToken.loggedInUserType === null ||
                    this._userDecodedToken.profile.userToken.loggedInUserEmail ===
                    this._userDecodedToken.profile.userToken.email);
            this._isCalnet = this._userDecodedToken.profile.roles.includes(Role.CALNET_ROLE);
            this.pendoService.intializePendo(true, this._userDecodedToken, this.configRoles, this.isProfileInContext());
        } else {
            this._isCalnet = false;
            this._isHNCustomer = false;
            this.pendoService.intializePendo(false);
        }
        this._isHNScenario = this.isUserAuthenticated() ?
            this._isHNCustomer : (this.getQueryVariable('cust') === 'HN' && !this.isMyAdmin());
    }

    public getCustomerRegistrationDetails() {
        const registerContext = this._storageService.get('register-context', 'cbma');
        this.registerContextDetails = JSON.parse(registerContext);
        return this.registerContextDetails;
    }

    public async getIdTokenSub(): Promise<string> {
        const oktaIdToken = await this.oktaAuth.getIdToken();
        return this.jwtHelper?.decodeToken(oktaIdToken)?.sub;
    }

    public async getAccessTokenSub(): Promise<string> {
        this.oktaAccessToken = await this.oktaAuth.getAccessToken();
        return this.jwtHelper?.decodeToken(this.oktaAccessToken)?.sub;
    }

    public getAccessToken(): string {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let token: any = window.localStorage.getItem(oktaTokenStorage);
        if (token) {
            token = JSON.parse(token);
            if (token.accessToken && token.accessToken.accessToken) {
                token = token.accessToken.accessToken;
            }
        }
        return token;
    }

    public getBearerAccessToken(): string {
        return `Bearer ${this.getAccessToken()}`;
    }
    public getIdToken(): string {
        const oktaStorageJson = localStorage.getItem(oktaTokenStorage);
        if (oktaStorageJson) {
            const oktaData = JSON.parse(oktaStorageJson);
            return oktaData.idToken;
        }
    }

    public async getGuid() {
        this._guid = await this.oktaAuth.getIdToken();
        if (this._guid) {
            this._guid = this.jwtHelper.decodeToken(this._guid);
            if (this.isMyAdmin()) {
                this._guid = this._guid['csr.guid'];
            } else {
                this._guid = this._guid['customer.guid'];
            }
            return this._guid;
        }

    }

    // To get the loggedIn userType from AuthToken
    public getUserType(): string {
        const loggedinUserDetails = this.getLoggedInUserDetails();
        if (loggedinUserDetails && loggedinUserDetails.profile && loggedinUserDetails.profile.userToken) {
            return loggedinUserDetails.profile.userToken.userType;
        }
    }

    /**
     *  used in UFS to identify personal vs group features
     *
     *  @returns boolean
     */
    public isEditingUserSameasLoggedInUser(): boolean {
        if (this.editUser.isEditingUser()) {
            const loggedInUser = this.jwtHelper.decodeToken(this.editUser.getToken());
            if (loggedInUser) {
                const editUserMail = JSON.parse(loggedInUser.profile).userToken.email;
                if (this.getLoggedInUserDetails().profile.userToken.email === editUserMail) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
    public isCbmaAuthTokenExpired(): boolean {
        const authtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
        return this.jwtHelper.isTokenExpired(authtoken);
    }

    public checkForTokenInPassword(password: string, token: string, tokenLength: number): boolean {
        if (password && token) {
            password = password.toLowerCase();
            token = token.toLowerCase();
            for (let i = 0; (i + (tokenLength - 1)) < token.length; i++) {
                if (password.indexOf(token.substring(i, i + tokenLength)) !== -1) {
                    return false;
                }
            }
        }
        return true;
    }

    public isWebexActivated(): boolean {
        const bwGrpId = this.getLoggedInUserDetails().profile.userToken.bwGroupId;
        if (bwGrpId !== null) {
            const marketId = bwGrpId.substr(0, 3);
            const ucEnabledArray = this.config.getConfig('ucEnabled')['ucEnabledMarket'];
            if (ucEnabledArray.length === 0) {
                return true; // Webex
            } else if (ucEnabledArray.includes(marketId)) {
                return false; // UC App
            } else {
                return true; // Webex
            }
        } else {
            return false;
        }
    }
    public getOriginUrl(isCbmaRequired: boolean = true) {
        return isCbmaRequired ? window.location.origin + '/cbma' : window.location.origin;
    }

    public async getJSONContent() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let data: any;
        const path = 'assets/config/myaccounthome/landingPageRoles.json';
        if (!this.configRoles) {
            // tslint:disable-next-line: no-invalid-await
            data = await this.http.get(path)
                .subscribe((res) => {
                    this.configRoles = Object.assign([], res);
                });
        }
        return data;
    }


    public encryptWithSaltandPadding(plainText, key = DEFAULT_HELPER) {
        const encrypted = crypto.AES.encrypt(plainText, this.getKey(key), { iv: crypto.enc.Hex.parse(IV) });
        const cipherText = encrypted.ciphertext.toString(crypto.enc.Base64);
        const encString = (IV + '::' + SALT + '::' + cipherText);
        return btoa(encString);
    }

    public decryptWithSaltandPadding(encString, key = DEFAULT_HELPER) {
        const decodedString = atob(encString);
        const cipherText = this.sanitize(decodedString.split('::')[2]);
        const cipherParams = crypto.lib.CipherParams.create({ ciphertext: crypto.enc.Base64.parse(cipherText) });
        const decrypted = crypto.AES.decrypt(cipherParams, this.getKey(key), { iv: crypto.enc.Hex.parse(IV) });
        return decrypted.toString(crypto.enc.Utf8);
    }

    public encryptByCrypto(token: string, key = DEFAULT_HELPER): string {
        return crypto.AES.encrypt(token, key).toString();
    }

    public decryptByCrypto(encryptedToken: string, key = DEFAULT_HELPER): string {
        return crypto.AES.decrypt(encryptedToken, key).toString(crypto.enc.Utf8);
    }

    public encodeBase64(token: string): string {
        return crypto.enc.Base64.stringify(crypto.enc.Utf8.parse(token));
    }

    public decodeBase64(token: string): string {
        return crypto.enc.Utf8.stringify(crypto.enc.Base64.parse(token));
    }
    // Copy to Clipboard
    public copyMessageToClipboard(value: string) {
        const copyTextMessage = document.createElement('textarea');
        copyTextMessage.style.position = 'fixed';
        copyTextMessage.style.left = '0';
        copyTextMessage.style.top = '0';
        copyTextMessage.style.opacity = '0';
        copyTextMessage.value = value;
        document.body.appendChild(copyTextMessage);
        copyTextMessage.focus();
        copyTextMessage.select();
        document.execCommand('copy');
        document.body.removeChild(copyTextMessage);
    }
    /**
     *
     * @returns  a boolean value to use the upgraded broadsoft api's
     */
    public isBroadsoftUpgradeEnabled(featureApi?: string): boolean {
        // to the Microservice name to use disable api's from that MS
        const msToDisable = featureApi ? featureApi.split('/')[0] : featureApi;
        const microServices: string[] = [];
        const featureApis: string[] = [];
        if (microServices.includes(msToDisable)) {
            // check the ms ,if its to be disabled
            return false;
        } else if (featureApis.includes(featureApi)) {
            // check for individual api to be disabled
            return false;
        } else {
            // use the global flag
            return true;
        }
    }

    /**
     * To check if profile is in context
     */
    public isProfileInContext(): boolean {
        if (this.isUserAuthenticated() && this._userDecodedToken) {
            const userType = this._userDecodedToken.profile.userToken.userType;
            if (userType === 'CSR') {
                return false;
            } else {
                return true;
            }
        }
    }

    public directoryCheck(control: UntypedFormControl): { [s: string]: boolean } {
        const email: string = ' ' + control.value;
        if (email.endsWith(Directory.directory_url)) {
            return { formDirectory: true };
        }
        return null;
    }

    public get isWebEx(): boolean {
        return this._isWebex;
    }

    public deleteCookies() {
        const domain = new CookieDomain();
        // eslint-disable-next-line @typescript-eslint/ban-types
        const allCookies: {} = this.cookieService.getAll();
        const cookieName = this.config.getConfig('retainedCookies').split(',');
        for (const prop in allCookies) {
            if (!cookieName.includes(prop)) {
                this.cookieService.delete(prop, '/', domain.cbma);
            }
        }
    }


    public replaceNull(address: string, lastEntry?: boolean) {
        return address ? lastEntry ? `${address} ` : `${address}, ` : '';
    }

    private getKey(key) {
        return crypto.PBKDF2(key, crypto.enc.Hex.parse(SALT), { keySize: 4, iterations: 1000, hasher: crypto.algo.SHA1 });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private sanitize(cipherText: any) {
        return cipherText.replaceAll('_', '/').replaceAll('-', '+');
    }

    private getQueryVariable(variable: string) {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (const param of vars) {
            const pair = param.split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return null;
    }
}

export interface UserTokenData {
    exp: number;
    sub: string;
    iss: string;
    profile: {
        roles: string[];
        userToken: {
            assignedPhoneNumber: string;
            bwGroupId: string;
            bwSpId: string;
            bwSwitchInstance: string;
            email: string;
            firstName: string;
            foneAdmin: boolean;
            guid: string;
            hasTrunk: boolean;
            id: string;
            isAdmin: string;
            isEmailCampaignAdmin: string;
            isEmailCampaignUser: string;
            isOpsUtilityAdmin: string;
            isOpsUtilityUser: string;
            isSuperAdmin: string;
            lastName: string;
            loggedInUserEmail: string;
            loggedInUserFirstName: string;
            loggedInUserGuid: string;
            loggedInUserType: string;
            phoneNumber: string;
            profileGuid: string;
            profileId: string;
            profileName: string;
            profileOwnerEmail: string;
            profileOwnerFirstName: string;
            profileOwnerGuid: string;
            profileOwnerId: string;
            profileOwnerLastName: string;
            profileOwnerPhoneNumber: string;
            profileStatus: string;
            switchPackage: string;
            timeZone: string;
            timeZoneDisplayName: string;
            userType: string;
            aomsRole: string;
            multiAccount: boolean;
        };
    };
}

// eslint-disable-next-line no-shadow
export enum HeaderFooterEnum {
    HEADER = 'HEADER',
    FOOTER = 'FOOTER',
    BOTH = 'BOTH',
    NONE = 'NONE',
}
