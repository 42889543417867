<div class="col-xs-12 col-sm-12 col-md-12 gray-bg pad-0">
  <div class="col-xs-12 col-sm-12 col-md-12 pad-top-1">
    <p class="bold">{{resourceBundle.selectAnAccount}}</p>
  </div>
  <div id="multiaccount">
    <div *ngIf="oddAccountsFlag; then oddAccountsTemp"></div>
    <div *ngIf="evenAccountsFlag; then evenAccountsTemp"></div>
    <div *ngIf="allAccountsFlag; then allAccountsTemp"></div>
  </div>
  <ng-template #oddAccountsTemp>
    <div id="multi-col-2" class="hidden-xs col-sm-6 col-md-6">
      <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of oddAccounts;" (click)="accountSelected(baseAccount)"
        id="oddAccountsTemp_{{baseAccount.accountId}}" [ngClass]="{'selected': baseAccount.accountId === accountid}">
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address-postal title="{{baseAccount.address.city}},  
                    {{baseAccount.address.state}} {{baseAccount.address.zip}}">
              {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #evenAccountsTemp>
    <div id="multi-col-1" class="hidden-xs col-sm-6 col-md-6">
      <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of evenAccounts;" (click)="accountSelected(baseAccount)"
        id="evenAccountsTemp_{{baseAccount.accountId}}" [ngClass]="{'selected': baseAccount.accountId === accountid}">
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address-postal title="{{baseAccount.address.city}},  
                    {{baseAccount.address.state}} {{baseAccount.address.zip}}">
              {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #allAccountsTemp>
    <div id="multi-col" class="visible-xs-block col-xs-12">
      <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" *ngFor="let baseAccount of allAccounts;" (click)="accountSelected(baseAccount)"
        id="allAccountsTemp_{{baseAccount.accountId}}" [ngClass]="{'selected': baseAccount.accountId === accountid}">
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
          <div class="col-xs-12">
            <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span>
          </div>
          <div class="col-xs-12">
            <span data-account-address-postal title="{{baseAccount.address.city}},  
                        {{baseAccount.address.state}} {{baseAccount.address.zip}}">
              {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>