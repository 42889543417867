<ng-container *ngIf="!isMobileDevice">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 feature-title px-0">
        <div id="headingLevel" translate>
            <h3 class="m-0">{{title}}</h3>
        </div>
    </div>
    <div *ngIf="description">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20">
            <p class="m-0" [innerHTML]="description"></p>
        </div>
    </div>
    <div id="featuresSection" *ngIf="createWorkOrderErrorMsg || ddosFeatureStatus === ddosStatus.WARNING">
        <app-commonmessage
            *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.id === 'featuresSection'"
            [type]="commonMessageService.displayMessage.type"
            [globalMessage]="commonMessageService.displayMessage.message" [level]="'global'">
        </app-commonmessage>
    </div>
    <div class="col-lg-12 col-md-12 mb-20 pl-0">
        <div class="col-lg-6 col-md-10 col-sm-10 col-xs-12 pl-0" [ngClass]="isTabletDevice ? 'pb-3' : ''">
            <app-search-filter [rows]="searchObj" (filteredRows)="getSearchResults($event)"></app-search-filter>
        </div>
        <ng-container *ngIf="getFeatureArr.length !== 0 || featuresList.length !== 0 || noFeatureDisplay; else loadSpinTemplate">
            <span class="pull-right feature-count" *ngIf="categoryName !== 'Apps'">{{featuresList?.length}}
                Features</span>
            <span class="pull-right feature-count" *ngIf="categoryName === 'Apps'">{{featuresList?.length}} Apps</span>
            <span class="sr-only" *ngIf="categoryName !== 'Apps'" aria-live="polite"
                id="featureCount">{{stringUtilService.formatByPlaceHolder(resourceBundle.featuresCount,
                [featuresList?.length, featuresListLength])}}</span>
            <span class="sr-only" *ngIf="categoryName === 'Apps'" aria-live="polite"
                id="appCount">{{stringUtilService.formatByPlaceHolder(resourceBundle.appsCount, [featuresList?.length,
                featuresListLength])}}</span>
        </ng-container>
    </div>
    <ng-container *ngIf="getFeatureArr.length !== 0 || featuresList.length !== 0 || noFeatureDisplay; else boxSkeleton">

        <div class="col-lg-3 col-md-3 col-sm-3 tile-new-container pl-0" *ngFor="let feature of featuresList"
            [ngClass]="{'mouse-default': !isMyadmin && showDdosTile() && feature.featureName === resourceBundle.ddosHeading}">
            <div [ngClass]="{ 'width-180': isTabletDevice, 'feature-card-width': !isTabletDevice, 'disable-card': isMyadmin && feature.role === 'appdir', 'mouse-default tile-without-focus': !isMyadmin && showDdosTile() && feature.featureName === resourceBundle.ddosHeading,'new-tile': !(!isMyadmin && showDdosTile() && feature.featureName === resourceBundle.ddosHeading)}"
                tabindex="{{((isMyadmin && feature.role === 'appdir') || (showDdosTile() && !cbHelper.isMyAdmin())) ? -1 : 0}}"
                [attr.id]="isMyadmin && feature.role === 'appdir' ? 'appMarketplace' : ''" (click)="onClickUrl(feature)"
                (keydown.enter)="onClickUrl(feature)" (focus)="onTileFocus()" (focusout)="onTileFocusOut()">
                <div class="col-lg-12 col-md-12 pt-3 linkUnderline-custom tile-title-height" role="link"
                    [ngClass]="{'mouse-default': !isMyadmin && showDdosTile() && feature.featureName === resourceBundle.ddosHeading}">
                    <span class="text-center h6"
                        [attr.aria-label]="(feature.framework === 'externalLink' || feature.framework === 'externalSSOLink') ? feature.featureName + resourceBundle.openLinkNewWindow : feature.featureName">{{feature.featureName}}</span>
                </div>
                <div class="col-lg-12 col-md-12 p-2 raster-mask">
                    <img alt="{{feature.featureName}}"
                        [src]="isMyadmin && feature.role === 'appdir' ? feature.myAdminicon : feature.icon"
                        aria-hidden="true">
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4">
                    <p class="m-0">{{feature.featureDescription}}</p>
                </div>
                <div *ngIf="!feature.role && ddosErrorMsgFlag" class="statusMsg col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-2">{{ddosErrorMsg}}</div>
                <div *ngIf="!feature.role && !toggleCheck  && cbHelper.isMyAdmin()" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" role="region" aria-live="polite" [ngClass]="{'margin-top-2em-imp':!ddosErrorMsgFlag}">
                    <button *ngIf="!spinnerFlag" id="ddosFeatureButton" class="button btn-long-text px-2 margin-top-6 wide95 button-primary"
                    [disabled]="(ddosFeature === ddosStatus.ACTIVATION_IN_PROGRESS || ddosFeatureStatus === ddosStatus.WARNING)" (click)="ddosActivate(installedWorkOrder)" [attr.aria-label]="ddosFeature">{{ddosFeature}}</button>
                    <span class="sr-only" *ngIf="(ddosFeature === ddosStatus.ACTIVATION_IN_PROGRESS || ddosFeatureStatus === ddosStatus.WARNING)" aria-describedby="ddosFeatureButton" aria-disabled="true"></span>
                    <button role="alert" class="button continueButton btn-long-text margin-top-6 pad-left-4-imp pad-right-4em spinButton px-2 wide95" *ngIf="spinnerFlag" aria-label="Button spinner">
                        <div class="button-spinner cb-spinner buttonSpinner ">
                            <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100"
                                alt="Processing... please wait" style="position: relative;">
                                <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
                                    style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%; stroke: white">
                                </circle>
                            </svg>
                        </div>
                    </button>
                </div>
                <div *ngIf="!feature.role && toggleCheck && cbHelper.isMyAdmin()"
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                    <ui-switch id="display" [checked]="toggleStatus" [(ngModel)]="toggleStatus"
                        (change)="openDisableModel()" [uiSwitchAriaLabel]="resourceBundle.ddosHeading" appUiSwitchLabel
                        size="medium"></ui-switch>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #ddosDisableModal let-close="close" let-dismiss="dismiss">
    <div role="dialog" aria-modal="true" aria-describedby="modal-heading">
        <span class="sr-only" id="modal-heading">{{resourceBundle.ddosDisableHeading}}
            {{resourceBundle.headingLevel4}}</span>
        <div class="margin-bottom-20 col-md-12 modalheader modal-header">
            <div class="col-lg-12 col-md-11 col-sm-12 col-12 p-0">
                <span class="modal-title h4" role="heading">{{resourceBundle.ddosDisableHeading}}</span>
                <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn float-right"
                    (click)="onCancel(close)" appModalFocus></a>
            </div>
        </div>
        <div class="row margin-left-25 margin-right-25">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-2 my-2 text-center">
                <p>{{resourceBundle.ddosDisableDescription}}<br><br>
                    <strong>{{resourceBundle.note}}:</strong> {{resourceBundle.ddosDisableNote}}
                </p>
            </div>
        </div>
        <hr class="hr m-0">
        <div class="row dead-center">
            <div class="margin-right-25">
                <a href="javascript:void(0)" (click)="onCancel(close)"
                    class="cursor-pointer text-decoration-underline-imp">{{resourceBundle.cancel}}
                    <img src="assets/images/blueprint/arrow_next.svg" width="5px" class="ml-2 mt-n2" role="presentation"
                        alt="arrow next icon" /></a>
            </div>
            <div>
                <button class="button btn-long-text"
                    (click)="onClickOfDisable(close)">{{resourceBundle.disable}}</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #loadSpinTemplate>
    <div [ngClass]="!isMobileDevice ? 'col-3 pl-0 pull-right bottom-6px' : 'col-12 pr-0 pl-0'">
        <app-skeletonloader formName="user name" formSkeleton="true" rowCount="1"></app-skeletonloader>
    </div>
</ng-template>
<ng-template #boxSkeleton>
    <div class="col-12 justify-content-center">
        <app-skeletonloader freeformSkeleton="true" count="4" sectionName="Features Cards" widthSizeInPixels="208"
            heightSizeInPixels="286"></app-skeletonloader>
    </div>
</ng-template>
<ng-container *ngIf="isMobileDevice">
    <ngb-accordion #featureAccordion="ngbAccordion" [closeOthers]="true" class="cb-accordions">
        <ngb-panel id="featureMobileAccordion">
            <ng-template ngbPanelTitle>
                <div class="accordion-trigger">
                    <img alt="Feature icon" class="margin-left-1em mb-2" aria-hidden="true"
                        src="assets/images/blueprint/admin_redesign/feature-accordion-icon.svg">
                    <span class="pl-2">{{resourceBundle.featureAccordionHeading}}</span>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div *ngIf="description">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-4">
                        <p class="m-0" [innerHTML]="description"></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0 pb-4 pr-0">
                    <app-search-filter [rows]="searchObj" (filteredRows)="getSearchResults($event)"></app-search-filter>
                </div>
                <ul class="ul-style">
                    <ng-container
                        *ngIf="getFeatureArr.length !== 0 || featuresList.length !== 0 || noFeatureDisplay; else loadSpinTemplate">
                        <li *ngFor="let feature of featuresList" class="mb-2 linkUnderline pl-3"
                            [attr.id]="isMyadmin && feature.role === 'appdir' ? 'appMarketplace' : ''">
                            <a href="javascript:void(0)"
                                [ngClass]="isMyadmin && feature.role === 'appdir' ? 'disabled' : ''"
                                (click)="onClickUrl(feature)" role="link">{{feature.featureName}}</a>
                        </li>
                    </ng-container>
                </ul>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

</ng-container>