<div class="col-xs-12 col-sm-12 col-md-12 gray-bg">
  <div class="col-xs-12 col-sm-12 col-md-12 pad-top-1 pl-0">
    <p class="bold">{{resourceBundle.selectAnAccount}}</p>
  </div>
  <div class="account col-xs-12 col-md-6 col-sm-6 selected">
    <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
      <div class="col-xs-12">
        <span class="truncate" [title]="accountName" data-account-name>{{ accountName }}</span>
      </div>
      <div class="col-xs-12">
        <span class="truncate" [title]="accountAlias" data-account-alias>{{ accountAlias }}</span>
      </div>
      <div class="col-xs-12">
        <span [title]="accountNumber" data-account-number>{{ accountNumber }}</span>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 col-sm-6 pad-0">
      <div class="col-xs-12">
        <span class="truncate" [title]="accountAddress1" data-account-address1>{{ accountAddress1 }}</span>
      </div>
      <div class="col-xs-12">
        <span class="truncate" [title]="accountAddress2" data-account-address2>{{ accountAddress2 }}</span>
      </div>
      <div class="col-xs-12">
        <span class="truncate" [title]="accountAddressPostal" data-account-address-postal>{{ accountAddressPostal }}</span>
      </div>
    </div>
  </div>
</div>