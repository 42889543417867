<nav class="cox-header white-bg position-relative">
  <a [href]="skipToMainContentLink" class="skip" id="skip">Skip to Main Content</a>
  <div id="cbDefaultHeader" class="container-fluid p-0">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
      <div class="top-header">
        <div
          [ngClass]="{'margin-left-10 margin-right-10': isMobileDevice, 'margin-left-20 margin-right-20px': !isMobileDevice}">
          <div [innerHTML]="topHeaderBundle">
          </div>
        </div>
      </div>
      <!--Desktop View Template below-->
      <div *ngIf="!isMobileDevice" class="d-flex margin-left-20 margin-right-20px"
        [ngClass]="{'hide': !showHeaderBundle}">
        <div class="col-lg-2 col-md-2 col-2 p-0 coxLogo">
          <a (click)="goToBusinessLink()" tabindex="0" aria-label="Cox Business Logo opens in a new window" role="button"
            class="display-inline-block">
            <img id="header-logo" class="height-45 margin-top-10px margin-bottom-10"
              src="assets/images/blueprint/onboarding/CoxBusiness_logo.svg" alt="Cox Business Logo" aria-hidden="true">
          </a>
        </div>
        <div class="col-10 p-0 display-flex justify-content-flexEnd"
          [ngClass]="{'hide': !appLoaded, 'display-contents': appLoaded}">
          <div class="search-container"
            *ngIf="(cbHelper.isUserAuthenticated() && (!cbHelper.isMyAdmin() || cbHelper.isProfileInContext()))">
            <div (click)="showDropdown = !showDropdown;" (keydown.enter)="showDropdown=!showDropdown" 
            class="search-bar popover-img-box div-focus-visible" [ngClass]="{'margin-bottom-neg-8px gray-bg-std': showDropdown}"
             [attr.aria-label]="showDropdown ? 'Search expanded' : 'Search collapsed'" role="button" tabindex="0">
              <div #searchBtn href="javascript:void(0)" #searchPopover="ngbPopover" [ngClass]="{'hide': showDropdown}"
                [popoverClass]="isTabletDevice ? 'headerPopover hidePopover' : 'headerPopover'" ngbPopover="Search"
                triggers="mouseenter:mouseleave" [placement]="popoverPlacement"
                class="globalHeaderStyle searchIconText" id="searchForm">
                <img src="assets/images/blueprint/magnifying-glass.svg" alt="Search icon" role="presentation"
                  class="searchIcon pad-top-p-2" />
                <img src="assets/images/blueprint/cb_header/arrow_down.svg" class="searchIcon" alt="arrow down" role="presentation">
              </div>
              <div class="searchIconText globalHeaderStyle"
               [ngClass]="{'hide': !showDropdown}">
                <img src="assets/images/blueprint/magnifying-glass.svg" alt="Search icon" role="presentation"
                  class="searchIcon pad-top-p-2" />
                <img src="assets/images/blueprint/cb_header/arrow_up.svg" class="searchIcon" alt="arrow up" role="presentation">
              </div>
            </div>
            <div class="search-wrapper">
              <div class="hide overflow-hidden custom_search_form yxt-SearchBar-wrapper" id="search-field"></div>
              <button type="submit" form="header-search-form" class="yxt-SearchBar-button" title="Search Icon"
                [ngClass]="{'hide': !isMobileDevice}" id="search-button" tabindex="0"></button>
            </div>
          </div>
          <ng-container *ngIf="cbHelper.isUserAuthenticated() && cbHelper.isProfileInContext()">
            <div *ngIf="menuOptions?.cbEmailOption?.enabled" class="margin-right-30px icon-padding">
              <div #cbEmailBtn aria-label="Business Email" #cbEmailPopover="ngbPopover"
                [popoverClass]="isTabletDevice ? 'headerPopover hidePopover' : 'headerPopover'"
                (click)="navigateOnClick(menuOptions?.cbEmailOption?.url, menuOptions?.cbEmailOption?.openInNewWindow)"
                (keydown.enter)="navigateOnClick(menuOptions?.cbEmailOption?.url, menuOptions?.cbEmailOption?.openInNewWindow)"
                ngbPopover="Business Email" triggers="mouseenter:mouseleave" [placement]="popoverPlacement"
                role="button" tabindex="0" class="div-focus-visible">
                <img src="assets/images/blueprint/cb_header/cbEmail.svg" alt="Business Email icon"
                  role="presentation" />
              </div>
            </div>
            <div *ngIf="menuOptions?.voiceMailOption?.enabled" class="margin-right-30px icon-padding">
              <div #vmBtn aria-label="Voicemail" #vmPopover="ngbPopover"
                [popoverClass]="isTabletDevice ? 'headerPopover hidePopover' : 'headerPopover'" ngbPopover="Voicemail"
                triggers="mouseenter:mouseleave" [placement]="popoverPlacement" role="button"
                (click)="navigateOnClick(menuOptions?.voiceMailOption?.url, menuOptions?.voiceMailOption?.openInNewWindow)"
                (keydown.enter)="navigateOnClick(menuOptions?.voiceMailOption?.url, menuOptions?.voiceMailOption?.openInNewWindow)"
                tabindex="0" class="div-focus-visible">
                <img src="assets/images/blueprint/cb_header/voicemail.svg" alt="Voicemail icon"
                  role="presentation" /><span *ngIf="voiceMailCount>0"
                  class="position-absolute badge pad-top-6-imp px-0 rounded-circle" id="badge">{{voiceMailCount}}</span>
              </div>
            </div>
            <div class="popover-img-box div-focus-visible" aria-label="Get Support" role="application"
              [ngClass]="{'gray-bg-std margin-bottom-neg-8px': cbHeaderService.showSupportMenu}" tabindex="0"
              (keydown.enter)="cbHeaderService.showSupportMenu=!cbHeaderService.showSupportMenu"
              (click)="cbHeaderService.showSupportMenu=!cbHeaderService.showSupportMenu" 
              [attr.aria-label]="cbHeaderService.showSupportMenu ? 'Get Support expanded' : 'Get Support collapsed'">
              <div #supportBtn #supportPopover="ngbPopover"
                [popoverClass]="isTabletDevice ? 'headerPopover hidePopover' : 'headerPopover'" ngbPopover="Get Support"
                triggers="mouseenter:mouseleave" [placement]="popoverPlacement" role="presentation"
                [ngClass]="{'hide': cbHeaderService.showSupportMenu}">
                <div>
                  <img src="assets/images/blueprint/cb_header/support.svg" alt="Support icon" role="presentation" />
                </div>
              </div>
              <div [ngClass]="{'hide': !cbHeaderService.showSupportMenu}">
                <app-support-menu [getSupportDetails]="menuOptions?.supportOptions" [headerTicketList] = "headerTicketList" [popoverOpenState]="cbHeaderService.showSupportMenu">
                </app-support-menu>
              </div>
            </div>
            <div class="div-focus-visible popover-img-box" [ngClass]="{'gray-bg-std alert-active-padding margin-bottom-neg-8px': cbHeaderService.showNotifications,
              '.margin-bottom-neg-24px': cbHeaderService.showNotifications && cbHeaderService.alertCheck}" tabindex="0"
              (keydown.enter)="cbHeaderService.showNotifications=!cbHeaderService.showNotifications" aria-label="Notifications"
              (click)="cbHeaderService.showNotifications=!cbHeaderService.showNotifications;" 
              [attr.aria-label]="cbHeaderService.showNotifications ? 'Notifications expanded' : 'Notifications collapsed'"
              role="application">
              <div #alertsBtn #alertsPopover="ngbPopover"
                [popoverClass]="isTabletDevice ? 'headerPopover hidePopover' : 'headerPopover'" 
                ngbPopover="Notifications" triggers="mouseenter:mouseleave" [placement]="popoverPlacement"
                role="presentation" [ngClass]="{'hide': cbHeaderService.showNotifications}">
                <img src="assets/images/blueprint/cb_header/notification.svg" alt="Notification icon" role="presentation" />
                <span class="red-indicator" *ngIf="cbHeaderService.alertCheck"></span>
              </div>
              <div [ngClass]="{'hide': !cbHeaderService.showNotifications}">
                <app-alert-menu [alerts]="alerts">
                </app-alert-menu>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="cbHelper.isUserAuthenticated()">
            <div class="gray-border margin-top-10px mb-2"></div>
            <div class="pad-left-p-20 main-menu-padding" id="main-menu"
              [ngClass]="{'mega-menu': cbHeaderService.showMainMenu,'margin-top-8 pad-top-p-12':!cbHeaderService.showMainMenu}">
              <div #megaMenuBtn aria-label="Main Menu" #megaNavPopover="ngbPopover"
                [ngClass]="{'hide': cbHeaderService.showMainMenu}"
                [popoverClass]="isTabletDevice ? 'headerPopover hidePopover' : 'headerPopover'" (click)="onMenuClick()" 
                [attr.aria-label]="cbHeaderService.showMainMenu ? 'Hi, '+ menuOptions?.firstName + 'Main menu expanded' : 'Hi, '+ menuOptions?.firstName + 'Main menu collapsed'"
                (keydown.enter)="cbHeaderService.showMainMenu=!cbHeaderService.showMainMenu" ngbPopover="Main Menu"
                triggers="mouseenter:mouseleave" [placement]="popoverPlacement" role="button" tabindex="0"
                class="div-focus-visible">
                <img src="assets/images/blueprint/cb_header/profile.svg" alt="profile icon" role="presentation"
                  class="mb-2 pr-2" />
                <span class="font-size-p-14 font-weight-5 line-height-18 black">Hi, {{menuOptions?.firstName |
                  truncate:['10', '...']}}</span>
                <img src="assets/images/blueprint/cb_header/arrow_down.svg" alt="profile icon" role="presentation"
                  class="mb-2 pl-2 mt-1 mr-2" />
              </div>
              <div [ngClass]="{'hide': !cbHeaderService.showMainMenu}">
                <app-main-menu [menuItems]="menuOptions"></app-main-menu>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!--Mobile View Template below-->
      <ng-container *ngIf="isMobileDevice">
        <div class="d-flex div-center-align margin-left-10 margin-right-10">
          <div class="col-4 p-0" *ngIf="cbHelper.isUserAuthenticated() && cbHelper.isProfileInContext()">
            <a role="button" tabindex="0" (click)="toggleHeaderLinks(headerLinks.MENU_LINKS,true)" (keydown.enter)="toggleHeaderLinks(headerLinks.MENU_LINKS,true)">
              <img id="hamburger-menu" class="margin-top-10px margin-bottom-10"
                src="assets/images/blueprint/cb_header/hamburger_menu.svg" alt="Hamburger menu Logo">
            </a>
          </div>
          <div class="col-4 p-0 text-center">
            <a role="button" tabindex="0" (click)="goToBusinessLink()" (keydown.enter)="goToBusinessLink()"><img id="header-logo" class="height-45 margin-top-10px margin-bottom-10"
                src="assets/images/blueprint/onboarding/CoxBusiness_logo.svg" alt="Cox Business Logo"></a>
          </div>
          <div id="searchBtnIcon" role="button" tabindex="0" class="col-2 div-focus-visible d-flex mobile-menu search-bar" *ngIf="cbHelper.isUserAuthenticated()"
          (keydown)="setFocus($event, 'yxt-SearchBar-input--SearchBar', 'search-button')">
              <img src="assets/images/blueprint/magnifying-glass.svg" alt="Search icon" role="presentation"
                class="searchIcon avatar" />
              <img src="assets/images/blueprint/cb_header/arrow_down.svg" class="searchIcon" alt="arrow down" role="presentation">
          </div>
          <div class="pull-right mr-4 ml-auto col-2" *ngIf="cbHelper.isUserAuthenticated()">
            <a id="main-menu" role="button" tabindex="0" (click)="toggleHeaderLinks(headerLinks.ACCOUNT_DROPDOWN)" (keydown.enter)="toggleHeaderLinks(headerLinks.ACCOUNT_DROPDOWN)" class="p-0 mobile-menu">
              <img id="header-logo" class="avatar mx-10" src="assets/images/blueprint/cb_header/profile.svg"
                alt="Profile Logo" />
              <img src="assets/images/blueprint/cb_header/arrow_down.svg" alt="profile icon" role="presentation" />
            </a>
          </div>
        </div>
        <div class="search-wrapper">
          <div class="hide overflow-hidden custom_search_form yxt-SearchBar-wrapper" id="search-field"></div>
          <button type="submit" form="header-search-form" class="yxt-SearchBar-button hide" title="Search Icon" (keydown)="setFocus($event, 'searchBtnIcon', 'search-field')"
           id="search-button" tabindex="0"></button>
        </div>
        <div class="header-content" id="header" [ngClass]="{'header-zoom-scroll': isPageZoomed}">
          <div class="header-title" tabindex="0" role="button"
            [ngClass]="{'pl-4': !(cbHeaderService.hamburgerHeader === resourceBundle?.hamburgerBackHeader)}">
            <p class="display-inline-block" id="hamburgerBackHeader"
              [ngClass]="{'mouse-link': cbHeaderService.hamburgerHeader === resourceBundle?.hamburgerBackHeader }"
              (click)="toggleHeaderLinks(headerLinks.MENU_LINKS)" (keydown.enter)="toggleHeaderLinks(headerLinks.MENU_LINKS)">
              <img *ngIf="cbHeaderService.hamburgerHeader === resourceBundle?.hamburgerBackHeader"
                src="assets/images/blueprint/cb_header/double_arrow_back.svg" alt="back arrow" role="presentation"
                class="mb-2 mouse-link" />
              {{cbHeaderService.hamburgerHeader}}
            </p>
            <img id="headerLinksClose" class="header-close-img pull-right" src="assets/images/blueprint/cb_header/hamburger_close.svg" role="button"
              (click)="cbHeaderService.closeSideBar()" (keydown.enter)="cbHeaderService.closeSideBar()" tabindex="0" alt="close">
          </div>
          <ng-conatiner [ngSwitch]="cbHeaderService.hamburgerTemplate">
            <ng-conatiner *ngSwitchCase="headerLinks.ACCOUNT_DROPDOWN">
              <app-main-menu [menuItems]="menuOptions"></app-main-menu>
            </ng-conatiner>
            <ng-conatiner *ngSwitchCase="headerLinks.MENU_LINKS">
              <div class="col-lg-12 col-md-12 col-xs-12 mt-3 header-links">
                <ul class="ul-style p-0">
                  <li (click)="toggleHeaderLinks(headerLinks.SUPPORT_LINKS)" tabindex="0" role="button"
                  (keydown.enter)="toggleHeaderLinks(headerLinks.SUPPORT_LINKS)"><span>Support</span>
                    <img src="assets/images/blueprint/arrow_next_blue.svg" alt="arrow next icon" height="12" width="7"
                      class="pull-right mt-2 margin-left-10-px mb-1" role="presentation" />
                  </li>
                  <li (click)="toggleHeaderLinks(headerLinks.ALERT_LINKS)" tabindex="0"
                  (keydown.enter)="toggleHeaderLinks(headerLinks.ALERT_LINKS)"><span>Alerts</span>
                    <img src="assets/images/blueprint/arrow_next_blue.svg" alt="arrow next icon" height="12" width="7"
                      class="pull-right mt-2 margin-left-10-px mb-1" role="presentation" />
                  </li>
                  <li tabindex="0"
                    (click)="navigateOnClick(menuOptions?.voiceMailOption?.url, menuOptions?.voiceMailOption?.openInNewWindow)"
                    (keydown.enter)="navigateOnClick(menuOptions?.voiceMailOption?.url, menuOptions?.voiceMailOption?.openInNewWindow)"
                    *ngIf="menuOptions?.voiceMailOption?.enabled"><span>Voicemail</span>
                  </li>
                  <li *ngIf="menuOptions?.cbEmailOption?.enabled">
                    <a (click)="navigateOnClick(menuOptions?.cbEmailOption?.url, menuOptions?.cbEmailOption?.openInNewWindow)"
                    (keydown.enter)="navigateOnClick(menuOptions?.cbEmailOption?.url, menuOptions?.cbEmailOption?.openInNewWindow)"
                      tabindex="0" class="div-focus-visible">Business Email</a>
                  </li>
                </ul>
              </div>
            </ng-conatiner>
            <ng-conatiner *ngSwitchCase="headerLinks.SUPPORT_LINKS">
              <app-support-menu [getSupportDetails]="menuOptions?.supportOptions">
              </app-support-menu>
            </ng-conatiner>
          </ng-conatiner>
          <ng-conatiner [ngClass]="{'hide': cbHeaderService.hamburgerTemplate !== headerLinks.ALERT_LINKS}">
            <app-alert-menu [alerts]="alerts"></app-alert-menu>
          </ng-conatiner>
        </div>
      </ng-container>
      <div class="cb-header-gradientline" [ngClass]="showHeaderBundle ? 'mt-0' : 'margin-top-5em'">
      </div>
    </div>
  </div>
</nav>
<div *ngIf="!showExternalSearchImg" class="grey-background"></div>