import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageKeys } from 'common-ui-lib/lib/constants/storage.model';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AppConfig } from '../../core/app.config';
import { CBHelperService } from '../../services/cbhelper.service';
import { CoxHttpClient } from '../../services/coxhttpclient.service';
import { EditUserService } from '../../services/edituser.service';
import { Select2libComponent } from '../ng2-select2/lib/ng2-select2';
import { BusinessEmailUnreadCount, UserListObject, UserRoles, VoicemailInventory } from './welcomewidget.model';

@Injectable()
export class WelcomeWidgetService {
  cb_session: string;
  cbma_authToken: string;
  tip3: NgbPopover;
  userselect2: Select2libComponent;
  jwtHelper: JwtHelperService = new JwtHelperService();
  refreshUserList: EventEmitter<void> = new EventEmitter<void>();
  public get popover(): NgbPopover {
    return this.tip3;
  }
  public set popover(value: NgbPopover) {
    this.tip3 = value;
  }
  public get select2(): Select2libComponent {
    return this.userselect2;
  }
  public set select2(value: Select2libComponent) {
    this.userselect2 = value;
  }
  constructor(
    private config: AppConfig,
    private _storageService: LocalStorageService,
    private editUser: EditUserService,
    private cbHelper: CBHelperService,
    private coxhttpClient: CoxHttpClient,
    private http: HttpClient) {
  }

  public getVoicemailInventory(): Observable<VoicemailInventory> {
    return this.coxhttpClient.get<VoicemailInventory>('/api/cbma/voicemail/services/voicemail/inventory' )
      .catch((error) => {
        throw new Error(JSON.stringify({
          code: error.error ? error.error.code : '',
          message: error.error ? error.error.message : '',
        }));
        // tslint:disable-next-line: no-any
      }).map((response: any) => {
        if (response.code === '0') {
          return response as VoicemailInventory;
        } else {
          throw new Error(JSON.stringify({
            code: response.code,
            message: response.message,
          }));
        }
      });
  }
  public loadLocationMappings(key: any) {
    return new Promise((resolve, reject) => {
      const request = this.http.get('assets/config/userFeature.json');
      if (request) {
        request
          .subscribe((responseData: any) => {
            for (let i = 0; i < responseData.locationMapping.length; i++) {
              if (responseData.locationMapping[i].location === key) {
                resolve(responseData.locationMapping[i].featurelocation);
              }
            }
          });
      }
    });
  }
  // tslint:disable-next-line: no-any
  public getreadUnreadMessages(userID: any): Observable<any> {
    const geturl = '/api/cbma/notificationhistory/services/notificationhistory/recentnotifications/' + userID;
    // tslint:disable-next-line: no-any
    return this.coxhttpClient.get<any>(geturl);
  }

  public async getBusinessEmailNotificationCount(userId: string): Promise<BusinessEmailUnreadCount> {
    return this.coxhttpClient.getAsync<BusinessEmailUnreadCount>(
      '/api/cbma/datainternetgateway/services/datainternetgateway/getMessageCountMailbox?userId='
      + userId + '&queryType=message_count');
  }

  // tslint:disable-next-line: bool-param-default
  public async getUsersListinProfile(isSortingReq?: boolean): Promise<UserListObject> {
    return this.coxhttpClient.getAsync<UserListObject>(
      `/api/cbma/user/services/user/getusers?isSortingReq=${isSortingReq}`);
  }

  public async getUsersinPage(pageid: string): Promise<UserRoles> {
    let result = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'MA_TRANSACTION_ID': uuid(),
      'clientid': this.config.getConfig('APIGW')['clientId'],
      'apikey': this.config.getConfig('APIGW')['apiKey'],
    });
    result = result.append('CB_SESSION', this.editUser.getCbUser());
    result = result.append('CBMA_AUTHTOKEN', this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'));
    return this.coxhttpClient.getAsync<UserRoles>('/api/cbma/profile/services/profile/userroles/' + pageid,
      { disableSpinner: true, customeErrorMessage: '', headers: result });
  }
}
