import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICBLoader, CBLoader } from './cbloader.enum';

@Injectable({ providedIn: 'root' })
export class CBLoaderService {
  public loseFocus=false;
  private spinnerObservable = new BehaviorSubject<CBLoader>(null);

  spinnerState = <Observable<ICBLoader>>this.spinnerObservable;

  show() {
    setTimeout(() => {
      const showPromise = new Promise((resolve, _reject) => {
          this.spinnerObservable.next({ show: true });
          resolve(true);
      });
      return showPromise;
    }, 10);
  }

  hide() {
    setTimeout(() => {
      const hidePromise = new Promise((resolve, _reject) => {
        this.spinnerObservable.next({ show: false });
        resolve(true);
      });
      return hidePromise;
    }, 10);    
  }
}
