import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appModalfocus]'
})
export class ModalfocusDirective {
  constructor(private el: ElementRef) {
    this.getFocus();
    }
    public getFocus() {
    setTimeout(() => {
    this.el.nativeElement.focus();
    }, 0);
    }


}
