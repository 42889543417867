
import { Injectable } from '@angular/core';

@Injectable()
export class ComponentInstanceService {
    // tslint:disable-next-line: no-any
    private componentInstance: any;

    constructor() {}

    // tslint:disable-next-line: no-any
    setComponentInstance(instance: any) {
      this.componentInstance = instance;
    }

    getComponentInstance() {
      return this.componentInstance;
    }
}
