import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { debounceTime, share } from 'rxjs/operators';
import { AppConfig } from '../core/app.config';
import { CBHelperService } from './cbhelper.service';
@Injectable()
export class AEMClientService {
  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private _sanitizer: DomSanitizer,
    private cbHelper: CBHelperService,
  ) {
  }
  private activeRequests: ActiveRequests = {};
  private aemCache: ResourceBundle = {};

  // TODO:  is that method used in any library? if not - please remove it
  // tslint:disable-next-line: no-any
  public getContent(filename?: string): Observable<any> {
    if (!filename) {
      return Observable.of('');
    }
    const fileTypeRegEx = /(?:\.([^.]+))?$/;
    const fileType = fileTypeRegEx.exec(filename)[1];
    const _aemBaseURL = this.config.getConfig('AEM')['getContentURL'];

    if (fileType === 'html') {
      return this.http.get(_aemBaseURL + filename, { responseType: 'text' })
        .map((response) => {
          return this._sanitizer.sanitize(SecurityContext.HTML, response);
        });
    } else {
      return this.http.get(_aemBaseURL + filename)
        .map((response) => {
          return response;
        });
    }
  }

  /**
   * AEM file content should not make use single quotes i.e ' anywhere in property values.
   * Below method can handle escape character followed by double quotes i.e \" anywhere in the property values.
   * Valid Examples: <span class=\"h3\"> or <span class=   \"h3\"  > or <span class=   \"    h3   \"  >
   * Invalid Examples: <span class=\'h3\'> or <span class='h3'>
   * AEM content should not have encoded values. Ex: &amp; or &quot;.
   * &amp; should be given only as & will be handled automatically.
   * &quot; should be given as escape character followed by double quote \" and will be handled.
   */
  public getJSONContent(filename: string, moduleName: string): Observable<ResourceBundle> {
    if (this.aemCache[moduleName] && this.aemCache[moduleName][filename]) {
      return Observable.of(JSON.parse(this.aemCache[moduleName][filename]));
    }
    const _aemBaseURL = this.cbHelper.isUserAuthenticated() ? this.config.getConfig('AEM')['getResourceBundleURL'] :
      this.config.getConfig('AEM')['getResourceunAuthBundelURL'];
    const aemFilename = filename + '.xhtml';
    const request = this.activeRequests[moduleName + '/' + aemFilename] ?
      this.activeRequests[moduleName + '/' + aemFilename] :
      this.http.get(_aemBaseURL + moduleName + '/' + aemFilename, { responseType: 'text' }).pipe(share());
    this.activeRequests[moduleName + '/' + aemFilename] = request;
    return request.map((response: string) => {
      this.activeRequests[moduleName + '/' + aemFilename] = null;
      const data = JSON.parse(this.convertToParsableString(response));
      this.storeCache(filename, moduleName, data);
      return data;
    }).catch((error: HttpErrorResponse) => {
      if (moduleName === 'voice' && error && error.status === 404 && !aemFilename.includes('ResourceBundle')) {
        // only voice modules should throw error. other modules still read from local config
        throw new Error(error.message);
      }
      console.error('Failed to fetch file from aem hence fetching it from assets');
      return this.getJsonFromAssets(moduleName, filename);
    });
  }

  // TESTING CONTENT FRAGMENT TO BE MODIFIED OR REMOVED LATER
  public getContentFragment(filename: string, moduleName: string): Observable<ResourceBundle> {
    if (this.aemCache[moduleName] && this.aemCache[moduleName][filename]) {
      return Observable.of(JSON.parse(this.aemCache[moduleName][filename]));
    }
    const _aemBaseURL = this.cbHelper.isUserAuthenticated() ? this.config.getConfig('AEM')['getResourceBundleURL'] :
    this.config.getConfig('AEM')['getResourceunAuthBundelURL'];
    const aemFilename = filename + '.model.json';
    const request = this.activeRequests[moduleName + '/' + aemFilename] ?
    this.activeRequests[moduleName + '/' + aemFilename] :
    this.http.get(_aemBaseURL + moduleName + '/' + aemFilename, { responseType: 'text' }).pipe(share());
    this.activeRequests[moduleName + '/' + aemFilename] = request;
    return request.map((response: string) => {
        this.activeRequests[moduleName + '/' + aemFilename] = null;
        const data = JSON.parse(this.convertToParsableString(response));
        this.storeCache(filename, moduleName, data);
        return data;
      }).catch((error: HttpErrorResponse) => {
        if (moduleName === 'voice' && error && error.status === 404 && !aemFilename.includes('ResourceBundle')) {
          // only voice modules should throw error. other modules still read from local config
          throw new Error(error.message);
        }
        console.error('Failed to fetch file from aem hence fetching it from assets');
        return this.getJsonFromAssets(moduleName, filename);
      });
  }

  convertToParsableString(unAlteredString: string) {
    return unAlteredString
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '\"')
      .replace(/&nbsp;/g, ' ')
      .replace(/"\\"/g, '\\"')
      .replace(/">/g, '\">')
      .replace(/="/g, '=\"')
      .replace(/='/g, '') // handles spaces
      .replace(/="/g, '') // handles spaces
      .replace(/'>/g, '">');
  }
  public getHTMLContent(filename: string, moduleName: string): Observable<string> {
    if (this.aemCache[moduleName] && this.aemCache[moduleName][filename]) {
      return Observable.of(JSON.parse(this.aemCache[moduleName][filename]));
    }
    const aemFilename = filename + '.xhtml';
    const _aemBaseURL = this.config.getConfig('AEM')['getResourceBundleURL'];
    if (!this.cbHelper.isUserAuthenticated()) {
      return this.getHTMLFromAssets(moduleName, aemFilename);
    }
    const request = this.activeRequests[moduleName + '/' + aemFilename] ?
      this.activeRequests[moduleName + '/' + aemFilename] :
      this.http.get(_aemBaseURL + moduleName + '/' + aemFilename, { responseType: 'text' });
    this.activeRequests[moduleName + '/' + aemFilename] = request;
    return request.map((response: string) => {
      this.activeRequests[moduleName + '/' + aemFilename] = null;
      const tempDiv = document.createElement('DIV');
      tempDiv.innerHTML = response;
      let resString: string;
      if (aemFilename.includes('messagecenterwidget')) {
        resString = (tempDiv.getElementsByClassName('htmlCodeBlock')[0] as HTMLElement).innerHTML;
        this.storeCache(filename, moduleName, resString);
        return resString;
      }
      return '';
    }).catch((err) => {
      console.error('failed to fetch data from AEM', err);
      return this.getHTMLFromAssets(moduleName, aemFilename);
    });
  }

  public getBundle(filename: string, moduleName: string): Observable<ResourceBundle> {
    return this.getJSONContent(filename, moduleName);
  }

  public getVMLPConfig(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'vmlp');
  }

  public getOnboardingBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'onboarding');
  }

  public getDataBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'data');
  }

  public getVoiceBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'voice');
  }

  public getSharedBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'shared');
  }

  public getAdministrationBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'administration');
  }

  public getRedesignBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'redesign');
  }

  public getMyaccountHomeBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'myaccounthome');
  }

  public getCsrAdminBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'csradmin');
  }

  // TESTING CONTENT FRAGMENT TO BE MODIFIED OR REMOVED LATER
  public getContentFragmentBundle(filename: string): Observable<ResourceBundle> {
    return this.getContentFragment(filename, 'csradmin');
  }

  public getErrorBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'errorpage');
  }

  public getCbotBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'cbot');
  }

  public getSecuritySolBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'securitysolution');
  }
  public getBundleMessageCenter(filename: string, moduleName: string): Observable<string> {
    return this.getHTMLContent(filename, moduleName);
  }
  public getSharedMessageCenterBundle(filename: string): Observable<string> {
    return this.getBundleMessageCenter(filename, 'shared');
  }

  public getAomsBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'aoms');
  }
  public getAppsBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'apps');
  }

  public getDigitalProfileBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'digitalprofile');
  }

  public getCustomerRegistrationBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'customerregistration');
  }

  public getOrdersBundle(filename: string): Observable<ResourceBundle> {
    return this.getBundle(filename, 'orders');
  }

  public replacer(data: string, object?: {[key: string]: string}) {
      Object.keys(object).forEach((key) => {
        data = data.replace('{' + key + '}', object[key]);
      });
      return data;
    }

    /**
     * To Fetch the Message Center Content in Login Page From AEM
     */
    public getLoginHTMLContent(filename: string, moduleName: string): Observable<string> {
      const aemFilename = filename + '.html';
      const _aemBaseURL = this.config.getConfig('AEM')['getResourceunAuthBundelURL'];
      return this.http.get(_aemBaseURL + moduleName + '/' + aemFilename, { responseType: 'text' })
          .map((response) => {
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = response;
              let resString: string;
              resString = (tempDiv.getElementsByClassName('htmlCodeBlock')[0] as HTMLElement).innerHTML;
              resString = this._sanitizer.sanitize(SecurityContext.HTML, resString);
              return resString;
          }).catch(() => {
              console.error('Failed to fetch file from aem hence fetching it from assets');
              return this.getHTMLFromAssets(moduleName, aemFilename);
          });
    }

  /**
  * To Fetch the Top Content in Default Header From AEM
  */
  public getTopHeaderHTMLContent(filename: string, moduleName: string): Observable<string> {
    const aemFilename = filename + '.html';
    const _aemBaseURL = this.config.getConfig('AEM')['getResourceunAuthBundelURL'];
    return this.http.get(_aemBaseURL + moduleName + '/' + aemFilename, { responseType: 'text' })
      .map((response) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = response;
        let resString: string;
        resString = (tempDiv.getElementsByClassName('htmlCodeBlock')[0] as HTMLElement).innerHTML;
        resString = this._sanitizer.sanitize(SecurityContext.HTML, resString);
        return resString;
      }).catch(() => {
        console.error('Failed to fetch file from aem hence fetching it from assets');
        return this.getHTMLFromAssets(moduleName, aemFilename);
      });
  }

  public getHTMLFromAssets(moduleName: string, filename: string): Observable<string> {
    const configFilename = 'assets/config/' + moduleName + '/' + filename;
    return this.http.get(configFilename, { responseType: 'text' }).map((data) => {
      return data;
    });
  }
  // Below function is written for fetching file from AEM to display Okta Maintenance Deployment Message
  public getOktaMaintenanceContent(moduleName: string): Observable<ResourceBundle> {
    const _aemBaseURL = this.config.getConfig('AEM')['getResourceBundleURL'];
    const aemFilename = 'oktamaintenance.xhtml';
    const request = this.activeRequests[moduleName + '/' + aemFilename] ?
      this.activeRequests[moduleName + '/' + aemFilename] :
      this.http.get(_aemBaseURL + moduleName + '/' + aemFilename, { responseType: 'text' }).pipe(share());
    this.activeRequests[moduleName + '/' + aemFilename] = request;
    return request.map((response: string) => {
      this.activeRequests[moduleName + '/' + aemFilename] = null;
      return JSON.parse(this.convertToParsableString(response));
    }).catch((error) => {
      return error;
    });
  }

  private storeCache(filename: string, moduleName: string, data: ResourceBundle | string) {
    if (!this.aemCache[moduleName]) {
      this.aemCache[moduleName] = {};
    }
    this.aemCache[moduleName][filename] = JSON.stringify(data);
  }

  private getJsonFromAssets(moduleName: string, filename: string): Observable<ResourceBundle> {
    return this.getFromAssets(moduleName, filename + '.json');
  }

  private getFromAssets(moduleName: string, filename: string): Observable<ResourceBundle> {
    const configFilename = 'assets/config/' + moduleName + '/' + filename;
    const request = this.activeRequests[configFilename] ? this.activeRequests[configFilename] :
      this.http.get(configFilename, { responseType: 'json' })
      .pipe(share(),debounceTime(80)) as Observable<ResourceBundle>;
    this.activeRequests[configFilename] = request;
    return request.map((data: ResourceBundle) => {
      this.activeRequests[configFilename] = null;
      this.storeCache(filename.split('.')[0], moduleName, data);
      return data;
    }, (error) => { console.error('LOAD FROM ASSETS ERROR', error); });
  }

}

interface ActiveRequests {
  [key: string]: Observable<ResourceBundle | string>;
}

export interface ResourceBundle {
  // tslint:disable-next-line: no-any
  [key: string]: any;
}