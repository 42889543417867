import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CBHelperService, CoxHttpClient, DigitalProfileSharedService, ResourceBundle, TealiumUtagService } from 'common-ui-lib';
import {
  AddUpgradeServiceData, BillingSummary, PortalEventsTrack
} from './digital-profile-dashboard.model';
import { BehaviorSubject, Observable } from 'rxjs';
import {v4 as uuid} from 'uuid';
@Injectable({
  providedIn: 'root',
})
export class DigitalProfileDashboardService {

  public serviceLocation = [];
  public billingSummary = new BehaviorSubject<BillingSummary>(null);
  public addUpgradeData = new BehaviorSubject<AddUpgradeServiceData>(null);
  public resourceBundle: ResourceBundle = {};
  public isFormerAcct = false;
  public isEUWithoutPermission = false;
  public isMyAdmin = false;
  public isMobileDevice = false;
  public selectedService: string;
  public currentMobilePage = 'home';
  public isNewCustomer: boolean;
  public hasCbCore: boolean;
  public portalEventsTrack: PortalEventsTrack = {
    selfInstall: false,
    outage: false
  };
  public eventsData = {
    selfInstall: {name:'orderDeliveryStatus', value:null},
    outage: {name: 'networkStatus', value:null}
  };
  public eventsObject: { [key: string]: string } = {};
  public viewTriggered = false;
  private pageEvents = new BehaviorSubject<PortalEventsTrack>(this.portalEventsTrack);

  constructor(private http: CoxHttpClient, private apollo: Apollo, private tealium: TealiumUtagService,
    private cbHelper: CBHelperService, private digitalProfileService: DigitalProfileSharedService) {
      this.getPageEvents().subscribe(events => {
        const setView = Object.values(events).every(event => event);
        if(setView) {
          for (const key in events) {
            if(this.eventsData[key].value) {
              const keyName = this.eventsData[key].name;
              this.eventsObject[keyName] = this.eventsData[key].value;
            }
          }
          this.triggerPageView();
        }
      });
      this.digitalProfileService.hasOutageResponse().subscribe(data => {
        if(!this.pageEvents.getValue().outage && this.pageEvents.getValue().selfInstall && !this.viewTriggered) {
          this.eventsObject.networkStatus = data ? 'outage-found': 'outage-not-found';
          if(this.eventsData.selfInstall.value) {
            this.eventsObject.orderDeliveryStatus = this.eventsData.outage.value;
          }
          this.triggerPageView();
        }
      });

      this.digitalProfileService.getErrorResponse().subscribe((response) => {
        if (response === 'OUTAGE' && this.digitalProfileService.outageHeathError
           && !this.viewTriggered && this.eventsData.selfInstall.value) {
          this.eventsObject.orderDeliveryStatus = this.eventsData.selfInstall.value;
          this.triggerPageView();
        }
      });
  }

  public setEventData(key: string, value: string) {
    if(this.eventsData[key]) {
      this.eventsData[key].value = value;
    }
  }
  public setPageEvent(event: keyof PortalEventsTrack, value: boolean) {
    const currentEvents = this.pageEvents.getValue();
    this.pageEvents.next({...currentEvents, [event]:value});
  }

  public getPageEvents(): Observable<any> {
      return this.pageEvents.asObservable();
    }

  public resetPageEvents() {
      this.pageEvents.next(this.portalEventsTrack);
      this.eventsObject = {};
  }

  public triggerPageView() {
    if(Object.keys(this.eventsObject).length !== 0) {
      this.tealium.view(this.eventsObject);
      this.viewTriggered = true;
      this.resetPageEvents();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getBillingProfile(): Observable<any> {
    return this.billingSummary.asObservable();
  }

  public setBillingProfile(billingSummary: BillingSummary) {
    this.billingSummary.next(billingSummary);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getAddUpgradeData(): Observable<any> {
    return this.addUpgradeData.asObservable();
  }

  public setAddUpgradeData(response) {
    this.addUpgradeData.next(response);
  }

  public getCbFeaturesList() {
    const quickLinkList = gql`
    query getCbFeaturesList{
      getProfile{
        serviceFeature(featureCategoryName:ALL){
            cbFeatures{
              featuresList{
                role
                featureName
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: quickLinkList,
    });
  }

  public getCbServiceDetails(accountGuid: string) {
    const cbServiceDetailsQuery = gql`
    query getAccountDetails($accountGuid: ID!) {
      getAccount(id: $accountGuid) {
        serviceFeature(featureCategoryName: ALL) {
          cbFeatures {
            userAccountsFlag
            featuresList{
              featureName
              role
            }
          }
          cbServices {
              tvService
              internetService
              voiceService
              networkingService
              securityService
              appService
              subServiceDetails {
                category
                subServiceList {
                  role
                  subCategory
                  featureName
                }
              }
          }
          accountAuthUser
        }
      }
    }
  `;
    return this.apollo.query({
      query: cbServiceDetailsQuery,
      variables: {
        accountGuid
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intiateBuyFlow(data: any) {
    const id = uuid();
    return this.http.putAsync(`/api/cbma/user/services/user/offers/${id}`, data);
  }
}
