import { AfterViewChecked, Directive, ElementRef, Host, HostListener, Input, Renderer2 } from '@angular/core';
import { Calendar } from 'primeng/calendar';

@Directive({
  selector: '[appPcalendar]',
})
export class PcalendarDirective implements AfterViewChecked {
  @Input() calendarName: string;
  constructor(private element: ElementRef, private renderer: Renderer2) {
  }
  // Directive created for p-calendar with calendar icon
  @HostListener('window:keyup', ['$event'])
  @HostListener('window:keydown', ['$event'])
  @HostListener('focusin', ['$event'])
  @HostListener('click', ['$event'])

  keyEvent(event: any) {
    this.element.nativeElement.firstElementChild.children[1].ariaLabel = 'Date Picker';
    if (this.element && this.element.nativeElement && this.element.nativeElement.firstElementChild &&
      this.element.nativeElement.firstElementChild.children[2]) {
      this.element.nativeElement.firstElementChild.children[2].firstElementChild.
        firstElementChild.children[1].firstElementChild.firstElementChild.
        firstElementChild.firstElementChild.innerHTML =
        '<span class="ng-tns-c140-0" aria-label="Sunday" role="text" tabIndex="0">SUN</span>';
      this.element.nativeElement.firstElementChild.children[2].firstElementChild.
        firstElementChild.children[1].firstElementChild.firstElementChild.
        firstElementChild.children[1].innerHTML =
        '<span class="ng-tns-c140-0" aria-label="Monday" role="text" tabIndex="0">MON</span>'
      this.element.nativeElement.firstElementChild.children[2].firstElementChild.
        firstElementChild.children[1].firstElementChild.firstElementChild.
        firstElementChild.children[2].innerHTML =
        '<span class="ng-tns-c140-0" aria-label="Tuesday" role="text" tabIndex="0">TUE</span>';
      this.element.nativeElement.firstElementChild.children[2].firstElementChild.
        firstElementChild.children[1].firstElementChild.firstElementChild.
        firstElementChild.children[3].innerHTML =
        '<span class="ng-tns-c140-0" aria-label="Wednesday" role="text" tabIndex="0">WED</span>';
      this.element.nativeElement.firstElementChild.children[2].firstElementChild.
        firstElementChild.children[1].firstElementChild.firstElementChild.
        firstElementChild.children[4].innerHTML =
        '<span class="ng-tns-c140-0" aria-label="Thursday" role="text" tabIndex="0">THU</span>';
      this.element.nativeElement.firstElementChild.children[2].firstElementChild.
        firstElementChild.children[1].firstElementChild.firstElementChild.
        firstElementChild.children[5].innerHTML =
        '<span class="ng-tns-c140-0" aria-label="Friday" role="text" tabIndex="0">FRI</span>';
      this.element.nativeElement.firstElementChild.children[2].firstElementChild.
        firstElementChild.children[1].firstElementChild.firstElementChild.
        firstElementChild.children[6].innerHTML =
        '<span class="ng-tns-c140-0" aria-label="Saturday" role="text" tabIndex="0">SAT</span>';
    }
    if (event.keyCode === 9 || event.keyCode === 13) {
      // this.element.nativeElement.tabIndex = '0';
      if (this.element && this.element.nativeElement && this.element.nativeElement.firstElementChild) {
        this.element.nativeElement.firstElementChild.children[1].ariaHidden = 'true';
        this.element.nativeElement.firstElementChild.children[1].tabIndex = '-1';
        this.element.nativeElement.firstElementChild.children[0].ariaLabel =
        this.calendarName + ' Please enter a date. Use arrow keys to access the date picker';

        const rows =
          this.element.nativeElement.firstElementChild.children[2]?.firstElementChild.
            firstElementChild.children[1].firstElementChild.children[1].children[0].childElementCount;
        for (let j = 0; j < rows; j++) {
          for (let i = 0; i < rows; i++) {
            this.element.nativeElement.firstElementChild.children[2].firstElementChild.
              firstElementChild.children[1].firstElementChild.children[1]?.children[j]?.children[i]?.
              firstElementChild?.setAttribute('role', 'button');
            if (this.element.nativeElement.firstElementChild.children[2].firstElementChild.
              firstElementChild.children[1].firstElementChild.children[1]?.children[j]?.children[i]?.
              firstElementChild?.classList.contains('p-disabled')) {
              this.element.nativeElement.firstElementChild.children[2].firstElementChild.
                firstElementChild.children[1].firstElementChild.children[1]?.children[j]?.children[i]?.
                firstElementChild?.setAttribute('aria-disabled', 'true');
            }
          }
        }
      }
    }
    document?.querySelector('.p-datepicker-prev')?.setAttribute('aria-label','Previous');
    document?.querySelector('.p-datepicker-next')?.setAttribute('aria-label','Next');
    document?.querySelector('.p-datepicker-month')?.setAttribute('role','presentation');
    document?.querySelector('.p-datepicker-year')?.setAttribute('role','presentation');
    document?.querySelector('.p-highlight')?.setAttribute('aria-label','selected');
  }

  ngAfterViewChecked(){
    this.renderer.setAttribute(this.element.nativeElement.firstElementChild.children[1],'aria-label','Date Picker');
  }

}
