import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { SwitchuserData, UserRoles } from './pageheader.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'common-ui-lib/lib/core/app.config';
import { v4 as uuid } from 'uuid';
import { EditUserService } from 'common-ui-lib/lib/services/edituser.service';
import { LocalStorageService } from 'ngx-localstorage';
import { CoxHttpClient } from 'common-ui-lib/lib/services/coxhttpclient.service';
import { CBHelperService } from 'common-ui-lib';
import { Router } from '@angular/router';
import { UserTokenData } from 'common-ui-lib/lib/services/cbhelper.service';
import { Select2OptionData } from '../../ng2-select2/lib/ng2-select2';
import gql from 'graphql-tag';

@Injectable()
export class PageHeaderService {
  public userProfile = null;
  public userOptions: Select2OptionData[];
  public usersList: SwitchuserData[];
  public selectedUserOption: string;
  public lastName: string;
  public poUserObj: Select2OptionData;
  public services = null;
  constructor(
    private apollo: Apollo,
    private config: AppConfig,
    private editUser: EditUserService,
    private _storageService: LocalStorageService,
    private coxhttpClient: CoxHttpClient,
    private http: HttpClient, private cbHelperService: CBHelperService,
    private router: Router
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getAccounts(featureName: string): Observable<any> {
    const getAccountInfoQuery = gql`
            query getAccountDetails($featureName: FeatureLookup!){
              userAccountsByFeature(feature: $featureName) {
                id
	            guid
	            siteId
	            accountName
	            accountNumber
	            accountNumber9
	            accountNumber12
	            accountNumber13
                accountNumber16
	            alias
                accountExternalAttrs {
                    status
                    serviceAddress {
                        city
                        houseNumber
                        state 
                        street
                        zipCode
                    }
                    vipCodes
                }
              }
            }`;

    return this.apollo.query({
      query: getAccountInfoQuery,
      variables: {
        featureName
      },
    });
  }

  public async getUsersinPage(pageid: string): Promise<UserRoles> {
    return this.coxhttpClient.getAsync<UserRoles>('/api/cbma/profile/services/profile/userroles/' + pageid,
      { disableSpinner: true, customeErrorMessage: ''});
  }

  public loadLocationMappings(key: any) {
    return new Promise((resolve, reject) => {
      const request = this.http.get('assets/config/userFeature.json');
      if (request) {
        request
          .subscribe((responseData: any) => {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < responseData.locationMapping.length; i++) {
              if (responseData.locationMapping[i].location === key) {
                resolve(responseData.locationMapping[i].featurelocation);
              }
            }
          });
      }
    });
  }

  //getting the users consuming new api
  async getUserList(lastName: string) {
    this.userProfile = this.cbHelperService.getLoggedInUserDetails().profile.userToken;
    // tslint:disable-next-line:max-line-length
    const url = this.router.url.split('?')[0];
    const pageid = this.config.getloadPageIdMappings(url) ? this.config.getloadPageIdMappings(url) : 'default';
    const userData = await this.getUsersinPage(pageid);
    this.usersList = userData.userList;
    this.userOptions = [];
    this.selectedUserOption = lastName;
    for (const rolelist of this.usersList) {
      if (rolelist.userType === 'PO') {
        this.poUserObj = {
          id: rolelist.userId.toString(),
          text: `${rolelist.lastName}, ${rolelist.firstName}(Profile Owner)`,
          mail: `${rolelist.email}`,
        };
        if (rolelist.userId.toString() !== this.userProfile.id) {
          this.poUserObj.type = true;
        }
      } else if (rolelist.userType === 'PA') {
        this.userOptions.push({
          id: rolelist.userId.toString(),
          text: `${rolelist.lastName}, ${rolelist.firstName}(Profile Admin)`,
          mail: `${rolelist.email}`,
        });
      } else {
        this.userOptions.push({
          id: rolelist.userId.toString(),
          text: `${rolelist.lastName}, ${rolelist.firstName}`,
          mail: `${rolelist.email}`,
        });
      }
    }
    this.selectedUserOption = this.userProfile.id;
    this.userOptions = [this.poUserObj, ...this.userOptions];
  }
}
