import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class ServiceDetailsService {
  constructor(private apollo: Apollo) { }

  public getSulaDescription(id: string){
    const getSulaDetailsQuery=gql`
     query getSulaDetailsQuery($id: ID!){
     getAccount(id: $id){
      sulaDetails {
        isCalNet
        serviceGroupID {
          serviceGroupIDKey
          serviceGroup {
            scheduleDate
            rank
          }
        }
      }
    }
  }
    `;
    return this.apollo.query({
      query: getSulaDetailsQuery,
      variables: {
          id
      },
  });
  }

}
