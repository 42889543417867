
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AEMClientService, CBHelperService, Cookie, DeviceDetectorService, ResourceBundle,
   TealiumUtagService } from 'common-ui-lib';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Modaltemplate } from 'common-ui-lib/lib/shared/leavingcoxsitemodal/leavingcoxsitemodal.model';
import { Router } from '@angular/router';
import { ValidateContactService } from '../validate-contact.service';
import { CookieService } from 'ngx-cookie-service';
import { validateOtp, verifyOtp } from '../validation-contact.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-code-validation',
  templateUrl: './code-validation.component.html',
  styleUrls: ['./code-validation.component.scss']
})
export class CodeValidationComponent implements OnInit, OnDestroy {

  @Output() codeValidateSuccess = new EventEmitter<boolean>();
  @ViewChild('leaveSiteModal') public leaveSiteModal: NgbModalRef;
  public resourceBundle: ResourceBundle;
  public modaltemplate: Modaltemplate;
  public isMobile: boolean;
  public reSendVerificationCodeFlag = true;
  public invalidCode: boolean;
  public codeForm: FormGroup;
  public redirectUrl: string;
  public showBtnSpinner = false;
  public isTablet: boolean;

  constructor(private aemClientService: AEMClientService, public validateContactService: ValidateContactService,
              private modalService: NgbModal, private router: Router, public tealium: TealiumUtagService,
              private cbHelper: CBHelperService,private cookieService: CookieService,
              private deviceDetector: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.codeForm = new FormGroup(
      {
        code: new FormControl('', [Validators.required, Validators.maxLength(6)])
      }
    );
    this.aemClientService.getRedesignBundle('validate-contact/code-validation')
      .subscribe((data: ResourceBundle) => {
        this.resourceBundle = data;
        this.validateContactService.contentLoaded = true;
      });
    this.isMobile = this.deviceDetector.isMobile();
    this.isTablet = this.deviceDetector.isTablet();
  }

  /**
   * @param url
   * Handling redirection
   */
  public handleRedirect(url: string) {
    this.redirectUrl = url;
    this.modaltemplate = {
      modalTitleRequired: true, modalTitle: this.resourceBundle.leave,
      modalBodyDescription: this.resourceBundle.leavedesc,
      secondaryButtonText:this.resourceBundle.no , primaryButtonText:this.resourceBundle.leavethispage
    };
      if(!isEmpty(this.codeForm.get('code')?.value)){
      this.modalService.open(this.leaveSiteModal)
        .result.then((result) => { });
      } else{
        this.router.navigateByUrl(url);
    }
  }

 /**
  * @param url
  * on click of validate later link
  */
  public onclickofvalidate(url: string){
    this.handleRedirect(url);
  }

  /**
   * @param event
   * @param close
   * Handling Leave Site Modal Leave Otpion
   */
  public onPrimaryClick(event, close): void {
    this.tealium.button('navigate to myaccount_page', 'navigate to myaccount_page');
    this.router.navigateByUrl(this.redirectUrl);
    close(false);
  }

  /**
   * @param close
   * Handling Leave Site Modal Cancel Otpion
   */
  public onSecondaryClick(close) {
    this.tealium.button('cancel button', 'cancel button');
    close(true);
  }

  /**
   * on click of continue button for OTP verification
   */
  public async onClickOfContinue() {
    this.validateContactService.commonMsg.hideMessage();
    this.cookieService.set(Cookie.uvtoken, this.validateContactService.uvtoken, null, '/');
    const element: any = document?.getElementById('code');
    const reqs: verifyOtp = {
      contactType: this.validateContactService.entityType,
      contactValue: this.validateContactService.validatecontact.contactValue,
      token: this.cbHelper.encryptWithSaltandPadding(element.value),
      userGuid: this.validateContactService.validateUserInfoContact ?
        this.validateContactService.userGuid : this.validateContactService.loggedInUserGuid,
      userId: this.validateContactService.validateUserInfoContact ?
        this.validateContactService.userId : this.validateContactService.loggedInUserId
    };
    try {
      this.validateContactService.verifyOtp(reqs).subscribe(data => {
        this.cookieService.delete(Cookie.uvtoken);
        if (this.cbHelper.decryptWithSaltandPadding(data?.code) === '0') {
          this.invalidCode = false;
          if (this.validateContactService.validateUserInfoContact) {
            this.showBtnSpinner = true;
            this.codeValidateSuccess.emit(true);
          } else {
            this.validateContactService.commonMsg
              .showMessage(this.resourceBundle.validatesuccess, 'success', 'codeValidationMsg');
            this.router.navigateByUrl(this.resourceBundle.contactMethodUrl);
          }
        } else {
          window.scroll(0, 0);
          document.getElementById('code')?.focus();
          this.invalidCode = true;
          this.validateContactService.commonMsg
            .showMessage(this.resourceBundle.incorrectCodeMsg, 'error', 'validationMethodMsg');
        }
      }, (error) => {
        window.scroll(0, 0);
        this.validateContactService.commonMsg
          .showMessage(this.resourceBundle.genericErrorMessage, 'error', 'validationMethodMsg');
      });
    } catch (error) {
      window.scroll(0, 0);
      this.validateContactService.commonMsg
        .showMessage(this.resourceBundle.genericErrorMessage, 'error', 'validationMethodMsg');
    }
  }
  /**
   * onclick of resend verification code link
   */
  public onclickOfResendVerificationCode(reSendcCodeFlag: boolean) {
    if (reSendcCodeFlag) {
      this.reSendVerificationCodeFlag = false;
      const reqs: validateOtp = {
        guid: this.validateContactService.validateUserInfoContact ?
          this.validateContactService.userGuid : this.validateContactService.loggedInUserGuid,
        entityType: this.validateContactService.entityType,
        entityValue: this.validateContactService.validatecontact.contactValue
      };
      this.validateContactService.sendotp(reqs).subscribe();
      this.resetError();
      this.codeForm.reset();
      this.validateContactService.commonMsg
      .showMessage(this.resourceBundle.verificationCodeSentMsg, 'success', 'validationMethodMsg');
      setTimeout(async () => {
        this.reSendVerificationCodeFlag = true;
      }, 5000);
    }
  }

  /**
   * Reset the form error
   */
  public resetError() {
    this.invalidCode = false;
  }

  /**
   * Close Validate Contact Method Modal
   */
  public close() {
    this.validateContactService.close();
  }

  ngOnDestroy(): void {
    this.validateContactService.commonMsg?.hideMessage();
  }

}
