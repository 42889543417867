import { Injectable } from '@angular/core';
import {
  AppConfig, CBHelperService, CommonMessageService,
  Cookie,
  CoxHttpClient
} from 'common-ui-lib';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { LocalStorageService } from 'ngx-localstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserInput, validateOtp, verifyOtp } from './validation-contact.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class ValidateContactService {

  public loggedInUserGuid: string;
  public contentLoaded = false;
  public uvtoken: string;
  public entityType: string;
  public validatecontact: any;
  public loggedInUserId: string;
  public validateUserInfoContact: boolean;
  public userGuid: string;
  public userId: string;
  public headers: unknown = new HttpHeaders();
  jwtHelper: JwtHelperService = new JwtHelperService();
  public origin = window.location.origin;

  constructor(public commonMsg: CommonMessageService, private apollo: Apollo,
    private cBHelperService: CBHelperService, private config: AppConfig,
    private _storageService: LocalStorageService, public activeModal: NgbModal,
    private http: HttpClient, private coxHttp: CoxHttpClient, private cookieService: CookieService) {
    this.loggedInUserGuid = this.cBHelperService.getLoggedInUserDetails().profile.userToken.guid;
  }

  public updateValidationShown(guid: string, input: UserInput): Observable<any> {
    const updateValidationShownMutation = gql`
     mutation updateValidationShownMutation($guid: String!, $input:UserInput) {
      updateUser(guid: $guid, input: $input) {
          guid
        } 
      }
    `;
    return this.apollo.mutate({
      mutation: updateValidationShownMutation,
      variables: {
        guid,
        input
      }
    });
  }


  public sendotp(payload: validateOtp) {
    const requestUrl =
      `/api/cbma/user/identity/services/useridentity/user/verifyContact`;
    return this.http
      .post<any>(requestUrl, payload, { headers: this.coxHttp.headers, observe: 'response' })
      .map((response) => {
        if (response.body.code === '0') {
          this.uvtoken = response.headers.get('uv-token');
          return response.body;
        }
      });
  }

  public verifyOtp(payload: verifyOtp) {
    const requestUrl = `/api/cbma/user/identity/services/useridentity/user/contact/validate`;
    const headers = this.coxHttp.headers;
    this.cookieService.set(Cookie.uvtoken, this.uvtoken, null, '/');
    return this.http
      .post<any>(requestUrl, payload, { headers, observe: 'response' })
      .map((response) => response.body);
  }

  /**
   * closing the current active modal
   */
  public close() {
    this.activeModal?.dismissAll();
    this.validateUserInfoContact = false;
  }
}
