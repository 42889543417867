import { Injectable } from '@angular/core';
import { AppConfig } from '../core/app.config';
import { CBHelperService } from './cbhelper.service';

@Injectable({
  providedIn: 'root',
})
export class WebexactivationService {
  constructor(private cbhelper: CBHelperService,
              private config: AppConfig) { }
  public isWebexActivated(): boolean {
    const bwGrpId = this.cbhelper.getLoggedInUserDetails().profile.userToken.bwGroupId;
    if (bwGrpId !== null) {
        const marketId = bwGrpId.substr(0, 3);
        const ucEnabledArray = this.config.getConfig('ucEnabled')['ucEnabledMarket'];
        if (ucEnabledArray.length === 0) {
            return true; // Webex
        } else if (ucEnabledArray.includes(marketId)) {
            return false; // UC App
        } else {
            return true; // Webex
        }
    } else {
        return false; // UC App
    }
  }
}
