import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { LocalStorageKeys } from 'common-ui-lib';
import {CookieService} from 'ngx-cookie-service';
import {LocalStorageService} from 'ngx-localstorage';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AppConfig } from '../core/app.config';
import { CreateUserRequest } from '../shared/models/userprofile/createUserCommon.model';
import { CBHelperService } from './cbhelper.service';

@Injectable()
export class CreateUserCommonService {
  constructor(private http: HttpClient, private config: AppConfig, private cookieService: CookieService,
              private _storageService: LocalStorageService,private cbhelperService:CBHelperService) {
  }
  // tslint:disable-next-line: no-any
  public createuser(payload: CreateUserRequest, setHeader: boolean = false): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.cookieService.get('cbma-current-user'),
        'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      }),
    };

    const createUserUrl = '/api/cbma/voiceprofile/services/voiceprofile/createUser';
    this.cbhelperService.deleteCookies();
    // tslint:disable-next-line: no-any
    return this.http.put<any>(createUserUrl, payload,
       { headers: httpOptions.headers, observe: 'response' }).map((response: any) => {
      if (response && response.body && response.body.code === '0') {
        if (setHeader) {
          this._storageService.set(LocalStorageKeys.EspmToken, response.headers.get('cbma_authtoken'));
        }
        return response.body;
      } else {
          throw new Error(JSON.stringify(response.body));
      }
    });
  }

  
}
