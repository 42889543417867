import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AEMClientService, ResourceBundle } from '../../../services/aemclient.service';
import { BaseAccount } from '../../models/accounts/baseaccounts.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-equipmentaccountwidgetsingleaccount',
  templateUrl: './accountwidgetsingleaccountequipment.component.html',
  styleUrls: ['./accountwidgetsingleaccountequipment.component.scss'],
})
export class AccountwidgetsingleaccountEquipmentComponent implements OnInit, OnDestroy {
  @Input() baseAccounts: BaseAccount[];
  @Output() selectedAccount = new EventEmitter<BaseAccount>();
  accountName: string;
  accountAlias: string;
  accountNumber: string;
  accountAddress: string;
  accountAddress1: string;
  accountAddress2: string;
  accountAddressPostal: string;
  resourceBundle: ResourceBundle =  {};
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService) { }

  ngOnInit() {
    this.aemClient.getSharedBundle('accountWidgetSingleAccountResourceBundles')
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((data: ResourceBundle) => { this.resourceBundle = data; });
    this.selectedAccount.emit(this.baseAccounts[0]);
    this.accountName = this.baseAccounts[0].name;
    this.accountAlias = this.baseAccounts[0].alias;
    this.accountNumber = this.baseAccounts[0].siteId + '-' + this.baseAccounts[0].accountNumber;
    let address2 = '';
    if (this.baseAccounts[0].address.address2 !== '' ) {
      address2 = this.baseAccounts[0].address.address2 + ', ';
    }
    this.accountAddress = this.baseAccounts[0].address.address1 + ', ' +
      address2 + this.baseAccounts[0].address.city + ', ' +
      this.baseAccounts[0].address.state + ' ' +
      this.baseAccounts[0].address.zip;
    this.accountAddress1 = this.baseAccounts[0].address.address1;
    this.accountAddress2 = this.baseAccounts[0].address.address2;
    this.accountAddressPostal = this.baseAccounts[0].address.city + ',' +
      this.baseAccounts[0].address.state + ' ' +
      this.baseAccounts[0].address.zip;
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
