import {
  Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges,
  ViewChild, Output, EventEmitter, HostListener, AfterViewInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { CBHelperService, DeviceDetectorService, MenuOptionsService } from 'common-ui-lib';
import { CBUser } from 'redesign/users-and-accounts/user-settings/user-settings.model';
import { NavigationService } from '../navigation/navigation.service';

@Component({
  selector: 'app-menu-options',
  templateUrl: './menu-options.component.html',
  styleUrls: ['./menu-options.component.scss']
})
export class MenuOptionsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild(NgbNav, { static: true }) ngbNav: NgbNav;
  @Input() menuOptions = [];
  @Input() userDetails: any;
  @Input() setActiveMenu: boolean;
  @Input() isAccountSettings: boolean;
  @Input() removeLink: {};
  @Output() featureName = new EventEmitter<string>();
  @Output() emitRemoveAccValue = new EventEmitter<boolean>();
  public fullName: string;
  public phoneNumber: string;
  public userEmail: string;
  public role: string;
  public activeTab: string;
  public guid: string;
  public isMobileView: boolean;
  public isTabletView: boolean;
  public pageZommed: boolean;
  public isHidden = false;
  private windowWidth: number;

  constructor(
    private cbHelper?: CBHelperService,
    public activeRoute?: ActivatedRoute,
    public router?: Router,
    public navigationService?: NavigationService,
    public deviceService?: DeviceDetectorService,
    public menuOptionsService?: MenuOptionsService,
  ) { }

  // To check the zoom in and zoom out and accordingly change the view to desktop, tablet or mobile
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.windowWidth !== window.innerWidth) {
      if (this.deviceService.isMobile()) {
        document.getElementById('navContent')?.classList.add('hide');
      } else {
        document.getElementById('navContent')?.classList.remove('hide');
      }
    }
    this.setLetsChatButtonPosition();
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.isMobileView = this.deviceService.isMobile();
    this.isTabletView = this.deviceService.isTablet();
    this.menuOptionsService.getUserDetails().subscribe(data => {
      if(data) {
        this.userDetails = data;
        this.phoneNumber = this.userDetails?.contacts[0]?.contactValue;
      }
    })
    if(!this.isAccountSettings){
      this.activeTab= 'UserInfo';
    }else{
      this.activeTab= 'Account Information';
    }
      if (this.activeRoute.snapshot.queryParamMap) {
        this.guid = this.activeRoute.snapshot.queryParamMap.get('guid');
      }
    this.setActiveMenuItem();
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      const activeEle = document.getElementById(this.activeTab);
      activeEle?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }, 400);
    this.setLetsChatButtonPosition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.userDetails) {
      this.fullName = `${this.userDetails.firstName} ${this.userDetails.lastName}`;
      if(!this.userDetails.guid){
        this.isHidden = true;
        this.phoneNumber = this.userDetails.phoneNumber.toString().trim().replace(/^\+1-/, '');
      } else {
        this.isHidden = false;
        for (const value in this.userDetails['contacts']) {
          if (this.userDetails['contacts'][value]['contactType'] === 'PRIMARY_PHONE') {
            this.phoneNumber = this.userDetails['contacts'][value]['contactValue'];
          }
        }
        if(this.userDetails['login']['contactType'] === 'EMAIL') {
          this.userEmail = this.userDetails['login']['contactValue'];
        }
        if(this.userDetails.isProfileOwner) {
          this.role = 'Profile Owner';
        } else if(this.userDetails.isProfileAdmin) {
          this.role = 'Administrator';
        } else if(this.userDetails.isEndUser) {
          this.role = 'User';
        }
      }
    }
    if(changes?.setActiveMenu?.currentValue){
      this.setActiveMenuItem();
    }
    if(changes['menuOptions'] && changes['menuOptions'].currentValue){
      this.setActiveMenuItem();
    }
  }
  public setActiveMenuItem() {
    let featureUrl = window.location.href.split('?')[0];
    featureUrl = featureUrl.split('/').pop();
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.menuOptions.length; i++) {
      if (this.menuOptions[i].userSubOptions !== undefined) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let j = 0; j < this.menuOptions[i].userSubOptions.length; j++) {
          if (featureUrl === this.menuOptions[i].userSubOptions[j].displayname.replace(/\s+/g, '').toLowerCase()) {
            this.activeTab = this.menuOptions[i].userSubOptions[j].displayname;
            return;
          } else if (!this.isAccountSettings) {
            this.activeTab = 'UserInfo';
          }
        }
      } else {
        if (featureUrl === this.menuOptions[i].shortName) {
          this.activeTab = this.menuOptions[i].displayname;
        }
      }
    }
  }

  public menuNavigation(path: string, displayName: string) {
    const isUnassigned = this.activeRoute.snapshot?.queryParamMap.get('phonenumber') === 'unassigned';
    if(isUnassigned && path.indexOf('?phonenumber=') < 0){
      path = path + '?phonenumber=unassigned';
    } else if(this.userDetails?.guid !== null){
      if (!this.isAccountSettings) {
        if (path.indexOf('?guid=') < 0) {
          path = path + `?guid=${this.userDetails?.guid}`;
        }
      } else {
        if (path.indexOf('?guid=') < 0) {
          path = path + `?guid=${this.guid}`;
        }
      }
      if(this.activeRoute.snapshot.queryParamMap.get('phonenumber') === 'assigned') {
        path = path + '&phonenumber=assigned';
      } else if(path.includes('assigned')) {
        path = path.replace('&phonenumber=assigned', '');
      }
      if(this.activeRoute.snapshot.queryParamMap.get('path') === 'header') {
        path = path + '&path=header';
      } else {
        path = path.replace('&path=header', '');
      }
    }
      this.router.navigateByUrl(path, { replaceUrl: true });
      if (this.isMobileView) {
        document.getElementById('navLinks').classList.toggle('hide');
        document.getElementById('navContent').classList.toggle('hide');
        this.featureName.emit(displayName);
      }
      document.getElementById('navContent').focus();
  }

  public checkSubMenu(subMenuList) {
    for (const menuItem of subMenuList) {
      if (menuItem.isEnabled) {
        return true;
      }
    }
    return false;
  }

  public emitRemoveAccount() {
    this.emitRemoveAccValue.emit(true);
  }

  // "Let's Chat" button hiding content on zoom in - accessibility fix
  public setLetsChatButtonPosition() {
    if (this.deviceService.isPageZoomed()) {
      const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
      if (zoom > 198) {
        this.pageZommed = true;
      }
    }
  }
  
  ngAfterContentChecked(){
    if(this.pageZommed){
      const letschatFloat: HTMLElement = document?.getElementById('web-messenger-container'); 
      if (letschatFloat &&  !letschatFloat?.classList?.contains('chatFloat-accessibility')) {
        letschatFloat?.classList.add('chatFloat-accessibility'); 
      }
    }
  }

  public ngOnDestroy(): void {
    document?.getElementById('web-messenger-container')?.classList?.remove('chatFloat-accessibility');
  }
}
