import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CBHelperService, Cookie, Regex, ResetPasswordService, ResourceBundle } from 'common-ui-lib';
import { CookieService } from 'ngx-cookie-service';
import { StepNum, UpdatePassword } from '../../reset-password.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Input() public resourceBundle: ResourceBundle = {};
  @Output() closeClick = new EventEmitter();
  public isMobile: boolean;
  public showEyeNew = false;
  public showEyeConfirm = false;
  public changePasswordForm: UntypedFormGroup;
  public newPwdErrorMsg: string;
  public  confirmPwdErrorMsg: string;
  public displayNewPasswordError = false;
  public displayConfirmPasswordError = false;
  public showturnOffButtonSpinner: boolean;
  public isMyAdmin = false;
  public tempPswdContentLoaded: boolean;
  constructor(public resetPasswordService: ResetPasswordService,
    public cbHelper: CBHelperService,
    public activeModal: NgbActiveModal, public formBuilder: UntypedFormBuilder,
    public router: Router,
    public cookieService: CookieService) {
      this.isMyAdmin = this.cbHelper.isMyAdmin();
    }

  ngOnInit(): void {
      this.changePasswordForm = this.formBuilder.group({
      newPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(Regex.PASSWORD_REGEX).bind(this),
        this.checkSpaceNewPassword,
      ]),
      confirmPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(Regex.PASSWORD_REGEX).bind(this),
        this.checkSpaceConfirmPassword,
      ]),
    },
    {validators: this.mustMatch('newPassword', 'confirmPassword')}
    );
    this.isMobile = this.resetPasswordService.isMobile;
    document.getElementById('closeBtn').focus();
    if(this.isMyAdmin){
      this.generateTempPassword();
    }
  }
  public async generateTempPassword(){
    this.tempPswdContentLoaded = false;
    const response = await this.resetPasswordService.pwdResetComplete();
    if (response.code === '0') {
      const tempPassword = response.password;
      this.resetPasswordService.encryptedPassword = this.cbHelper.decryptWithSaltandPadding(tempPassword);
      this.tempPswdContentLoaded = true;
    } else {
      this.tempPswdContentLoaded = true;
      this.resetPasswordService.resetPwdMsg
        .showMessage(this.resourceBundle.genericerrorMsg, 'error', 'resetPwdError');
      this.resetPasswordService.modalScroll();
    }
  }
  public showNewPasswordClick(id: string, type: string) {
   document.getElementById(id).setAttribute('type', type);
  }
  public showConfirmPasswordClick(id: string, type: string) {
    document.getElementById(id).setAttribute('type', type);
   }
  public checkSpaceNewPassword(form: UntypedFormGroup) {
    if (form.get('newPassword')?.value) {
      return form.get('newPassword').value.length !==
      form.get('newPassword').value.trim().length ? { spaceNotRequiredNP: true } : null;
    }
  }
  public checkSpaceConfirmPassword(form: UntypedFormGroup) {
    if (form?.get('confirmPassword')?.value) {
      return form.get('confirmPassword').value.length !==
      form.get('confirmPassword').value.trim().length ? { spaceNotRequiredCNP: true } : null;
    }
  }
  public mustMatch(password: string, confirmPassword: string) {
    return (formGroup: UntypedFormGroup) => {
      const newPasswordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];
      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (newPasswordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ mustMatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }
  public async submitPassword(){
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    this.displayErrorOrSuccessNewPwd();
    this.displayErrorOrSuccessConfirmPwd();
    const checkPassword = this.cbHelper.checkForTokenInPassword(this.changePasswordForm.get('newPassword').value,
    this.resetPasswordService.userEmail, 5);
    if (!checkPassword) {
      this.changePasswordForm.reset();
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.passwordShldnotContainUsername, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    }
     if (this.changePasswordForm.valid) {
      const updatePassword: UpdatePassword= {
        id: null,
        oldPassword: null,
        newPassword: this.changePasswordForm.get('newPassword').value,
        userEmail: this.resetPasswordService.userEmail,
      };
      try {
        this.showturnOffButtonSpinner = true;
        const response = await this.resetPasswordService.changePassword(updatePassword);
        if (response.code === '0') {
          this.activeModal?.close('changePassword');
          this.cookieService.delete(Cookie.TalonToken, this.resetPasswordService.attributes);
          this.resetPasswordService.currentStep = StepNum.step1;
          this.resetPasswordService.modalHeading = this.resourceBundle.forgotPassword;
        } else {
          this.errorMessagesBasedOnCodes(response.code);
        }
      } catch (error) {
        this.errorMessagesBasedOnCodes(error.code);
      }
      this.showturnOffButtonSpinner = false;
    }
  }
  public displayErrorOrSuccessNewPwd() {
    if (this.changePasswordForm.get('newPassword').errors) {
      if (this.changePasswordForm.get('newPassword').errors['required']) {
        this.newPwdErrorMsg = this.resourceBundle.requiredErrorMsg;
        this.displayNewPasswordError = true;
      } else if (this.changePasswordForm.get('newPassword').errors['minlength']) {
        this.newPwdErrorMsg = this.resourceBundle.minCharErrorMsg;
        this.displayNewPasswordError = true;
      } else if (
        this.changePasswordForm.get('newPassword').errors['pattern'] ||
        this.changePasswordForm.get('newPassword').errors['minLength']
      ) {
        this.newPwdErrorMsg = this.resourceBundle.invalidPasswordErrorMsg;
        this.displayNewPasswordError = true;
      }
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.correctInfoErrorMsg, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else if (this.changePasswordForm.errors && this.changePasswordForm.errors.spaceNotRequiredNP) {
      this.newPwdErrorMsg = this.resourceBundle.invalidPasswordErrorMsg;
      this.displayNewPasswordError = true;
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.correctInfoErrorMsg, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else {
      this.newPwdErrorMsg = '';
      this.displayNewPasswordError = false;
      this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    }
  }

  public displayErrorOrSuccessConfirmPwd() {
    if (this.changePasswordForm.get('confirmPassword').errors) {
      if (this.changePasswordForm.get('confirmPassword').errors['required']) {
        this.confirmPwdErrorMsg =this.resourceBundle.requiredErrorMsg;
        this.displayConfirmPasswordError = true;
      } else if (this.changePasswordForm.get('confirmPassword').errors['minlength']) {
        this.confirmPwdErrorMsg = this.resourceBundle.minCharErrorMsg;
        this.displayConfirmPasswordError = true;
      } else if (
        this.changePasswordForm.get('confirmPassword').errors['pattern'] ||
        this.changePasswordForm.get('confirmPassword').errors['minLength']
      ) {
        this.confirmPwdErrorMsg = this.resourceBundle.invalidPasswordErrorMsg;
        this.displayConfirmPasswordError = true;
      } else if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.confirmPassword) {
        this.confirmPwdErrorMsg = this.resourceBundle.newAndConfirmPasswordMatchErrorMsg;
        this.displayConfirmPasswordError = true;
      }
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.correctInfoErrorMsg, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else if (this.changePasswordForm.errors && this.changePasswordForm.errors.spaceNotRequiredCNP) {
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.correctInfoErrorMsg, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else {
      this.confirmPwdErrorMsg = '';
      this.displayConfirmPasswordError = false;
      this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    }
    if(this.displayNewPasswordError){
      document.getElementById('newPwd').focus();
    }
    else if(this.displayConfirmPasswordError){
      document.getElementById('confirmPwd').focus();
    }
    else if((this.displayNewPasswordError) && (this.displayConfirmPasswordError)){
      document.getElementById('newPwd').focus();
    } 
    }

  public resetPwdDone() {
    this.activeModal?.close();
    this.closeClick.emit();
    this.resetPasswordService.encryptedPassword= null;
  }
  public showHideEyeImg(id: string, type?: string) {
    document.getElementById(id).setAttribute('type', type);
    if (id === 'newPwd') {
        this.showEyeNew = !this.showEyeNew;
        if (this.showEyeNew) {
            this.showNewPasswordClick('newPwd','text');
            setTimeout(() => {
                document.getElementById('showNewEye').focus();
            }, 0);
        } else {
            this.showNewPasswordClick('newPwd','password');
            setTimeout(() => {
                document.getElementById('hideNewEye').focus();
            }, 0);
        }
    } else if (id === 'confirmPwd') {
        this.showEyeConfirm = !this.showEyeConfirm;
        if (this.showEyeConfirm) {
            this.showConfirmPasswordClick('confirmPwd', 'text');
            setTimeout(() => {
                document.getElementById('showConfirmEye').focus();
            }, 0);
        } else {
            this.showConfirmPasswordClick('confirmPwd', 'password');
            setTimeout(() => {
                document.getElementById('hideConfirmEye').focus();
            }, 0);
        }
    }
}
  public errorMessagesBasedOnCodes(code: string){
    if (code === '8806') {
      this.changePasswordForm.reset();
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.oldPasswordError, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else if (code === '224') {
      this.changePasswordForm.reset();
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.correctInfoErrorMsg, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
      this.newPwdErrorMsg = this.resourceBundle.invalidPasswordErrorMsg;
      this.displayNewPasswordError = true;
      this.confirmPwdErrorMsg = this.resourceBundle.invalidPasswordErrorMsg;
      this.displayConfirmPasswordError = true;
    } else if (code === '227') {
      this.changePasswordForm.reset();
      this.newPwdErrorMsg = this.resourceBundle.invalidPasswordErrorMsg;
      this.displayNewPasswordError = true;
      this.confirmPwdErrorMsg = this.resourceBundle.invalidPasswordErrorMsg;
      this.displayConfirmPasswordError = true;
    } else if (code === '20004') {
      window.scroll(0, 0);
      this.changePasswordForm.reset();
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.genericInvalidPasswordError, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else if (code === '1006') {
      this.changePasswordForm.reset();
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.tooManyInvalidAttemptsError, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else if (code === '200010') {
      this.changePasswordForm.reset();
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.commonlyUsedPasswordError, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else if (code === '200011') {
      this.changePasswordForm.reset();
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.previousCoxPasswordUsedError, 'error','resetPwdError');
            this.resetPasswordService.modalScroll();
    } else {
      this.resetPasswordService.resetPwdMsg.
      showMessage(this.resourceBundle.genericerrorMsg, 'error','resetPwdError');
      this.resetPasswordService.modalScroll();
    }
  }
}
