/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  AfterViewInit, Component, DoCheck, ElementRef, HostListener, Input,
  OnChanges, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { } from 'google.maps';
import { Cluster, MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import {
  MarkerIcon, Outage, OutageType, ServiceLocations,
  US_BOUNDS, Status, OutageSearchType, OutageInfoDetails,
  FilterParentCategory,
  ServiceLocationOutageInput,
  EventTypeGroup,
  AssetFamily,
  Asset
} from './cb-maps.model';
import {
  AEMClientService, CBHelperService,
  CbMapsService, DeviceDetectorService,
  DigitalProfileSharedService,
  ResourceBundle, TealiumUtagService,
  StringUtilService,
  PhonePipe,
  CbHeaderService,
  CommonMessageService,
  LocalStorageKeys,
  AppConfig
} from 'common-ui-lib';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateTimePipe } from '../directives/dateTime.pipe';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactInfo, ContactType } from '../redesign/validate-contact/validation-contact.model';
import { NgForm } from '@angular/forms';
import { FeatureInfo, FeatureName, Features } from 'digital-profile-dashboard/digital-profile-dashboard.model';
import { LocalStorageService } from 'ngx-localstorage';
@Component({
  selector: 'app-cb-maps',
  templateUrl: './cb-maps.component.html',
  styleUrls: ['./cb-maps.component.scss']
})
export class CbMapsComponent implements OnInit, OnChanges, AfterViewInit, DoCheck, OnDestroy {

  @ViewChild('cbMap') mapElement: ElementRef;
  @Input() styleType = 'default';
  @Input() serviceLocations: ServiceLocations[] = [];
  @ViewChild('saveMobileNumberModal') public saveMobileNumberModal: NgbModalRef;
  @ViewChild('updatesForm') public updatesForm: NgForm;
  public map: google.maps.Map;
  public mapProperties = {};
  public allMarkers = [];
  public showInfoWindow = false;
  public mapContentLoaded = false;
  public loaderWidth: number;
  public infoWindowDetails: ServiceLocations;
  public isMobileDevice: boolean;
  public isTabletDevice: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public searchObj: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public deleteTag: any;
  public turnedOnfiltertags = [];
  public searchValue = null;
  public noServiceLocationsMsg = '';
  public serviceLocationsList = [];
  public showSearchDropdown = false;
  public filterApplied = false;
  public searchData = [];
  public resourceBundle: ResourceBundle = {};
  public outageInfoDetails: OutageInfoDetails[];
  public outageType = OutageType;
  public showSingleOutageCluster = true;
  public outageAlertClicked = false;
  public selectedOutage: OutageType;
  public showAddressListWindow = false;
  public clusterServiceLocations: ServiceLocations[] = [];
  public mobileNumber: string;
  public editedMobileNumber: string;
  public healthCheckSpinner: boolean;
  public profileGuid: string;
  public isFormerAcct = false;
  public saveMobileNumberModalRef: NgbModalRef;
  public lastFetchedDateTime = 0;
  public refreshFailed: boolean;
  public showMapMarkerLoader: boolean;
  public addressSelected: string;
  public resetSearch: boolean;
  public mapuuid: string;
  public hideSearchFilter = false;
  public onlyInternetServiceAvailable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cluster: any;
  private getUserDetailsSubscription: Subscription;
  private outageNotificationDetails: Subscription;
  private outageHoverWindow: google.maps.InfoWindow;
  private onDestroy$ = new Subject<boolean>(); 

  constructor(public deviceService: DeviceDetectorService,
    public aemClient: AEMClientService,
    private tealium: TealiumUtagService,
    public cbMapsService: CbMapsService,
    public digitalProfileService: DigitalProfileSharedService,
    public cbHelper: CBHelperService,
    public router: Router,
    public stringUtilService: StringUtilService,
    public modalService: NgbModal,
    public commonMessageService: CommonMessageService,
    private cbHeaderService: CbHeaderService,
    private config: AppConfig,
    private readonly phonePipe: PhonePipe,
    private readonly _storageService: LocalStorageService,) {
    this.loadScripts();
    this.aemClient.getSharedBundle('cbmaps')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (jsonData: ResourceBundle) => {
          this.resourceBundle = jsonData;
        },
      );
  }

  @HostListener('window:click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearchandFilterFocus(event: any) {
    if (event?.target?.id === '_search') {
      this.showSearchDropdown = true;
    } else {
      this.showSearchDropdown = false;
    }
    if (event?.target?.classList?.contains('filter-checkbox')) {
      this.filterApplied = true;
    } else {
      this.filterApplied = false;
    }
  }

  ngOnInit(): void {
    this.isMobileDevice = this.deviceService.isMobile();
    this.isTabletDevice = this.deviceService.isTablet();
    this.loaderWidth = this.isTabletDevice ? 380 : 750;
    this.getUserContactNumberDetails();
    this.outageNotificationDetails =
      this.cbMapsService.outageViewDetailsClicked.subscribe((notification) => {
        if (notification && notification?.clicked) {
          this.outageAlertClicked = true;
          this.selectedOutage = notification?.outageType;
          this.onClickOfOutageViewDetails(notification?.outageType);
        }
      });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.cbHeaderService.getProfileDetails().subscribe((response: any) => {
      if (response) {
        this.profileGuid = response?.data?.getDigitalProfile?.profileGuid;
        if (!response?.data?.getDigitalProfile?.profileAssets?.assets &&
          response?.data?.getDigitalProfile?.serviceLocations?.length === 0) {
          this.isFormerAcct = true;
          this.createMap();
        } else {
          this.isFormerAcct = false;
        }
      }
    });
    this.digitalProfileService.getErrorResponse().subscribe((response) => {
      if (response === Features.OUTAGE_LARGE_PROFILE) {
        this.refreshFailed = true;
      }
      if (this.lastFetchedDateTime) {
        this.showMapMarkerLoader = false;
        this.createMap();
      }
    });
  }

  ngOnChanges() {
    if (document.getElementsByClassName('redesign-filter__tag')[0]?.hasChildNodes()) {
      this.searchObj.searchArray = this.serviceLocations.map((v) => ({ ...v, marker: null }));
      this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, billingAccounts: null }));
      this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, singleSuiteMarker: null }));
      this.searchObj = Object.assign({}, this.searchObj);
    } else {
      this.mapContentLoaded = false;
      this.createMap();
    }
  }

  ngDoCheck() {
    if (this.digitalProfileService.outageHeathError) {
      this.showNetworkHealthError();
      this.digitalProfileService.outageHeathError = false;
    }
    if (this.digitalProfileService.mapDetails?.serviceLocations?.length > 0 &&
      this.mapuuid !== this.digitalProfileService.mapDetails?.uuid) {
      this.lastFetchedDateTime = this.digitalProfileService.mapDetails?.lastFetchedDateTime;
      if (this.digitalProfileService.mapDetails?.serviceLocations?.length > 1000) {
        console.log('Start - build service Execution started in map', new Date());
        this.serviceLocations = [];
        this.pushInIntervalsForLargeProfile(this.digitalProfileService.mapDetails?.serviceLocations, 0, 999);
        console.log('End - build service Execution started in map', new Date());
      } else {
        this.lastFetchedDateTime = 0;
        this.serviceLocations = this.digitalProfileService.mapDetails?.serviceLocations;
        this.hideSearchFilter = this.serviceLocations.length === 1 ? true : false;
        this.serviceLocations.forEach((element) => {
          const multiSuiteLoc = this.serviceLocations.filter(loc => loc.address?.latitude ===
            element.address?.latitude && loc.address?.longitude === element.address?.longitude);
          if (multiSuiteLoc.length > 1) {
            element.multiSuite = true;
          }
        });
        if (this.searchObj) {
          this.searchObj.searchArray = this.serviceLocations.map((v) => ({ ...v, marker: null }));
          this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, billingAccounts: null }));
          this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, singleSuiteMarker: null }));
          this.searchObj = Object.assign({}, this.searchObj);
        }
      }
      this.refreshFailed = false;
      this.ngOnChanges();
      this.mapuuid = this.digitalProfileService.mapDetails.uuid;
    }
  }

  ngAfterViewInit() {
    this.loaderWidth = document.getElementsByClassName('map-container')[0]?.clientWidth;
    this.map?.setZoom(this.map.getZoom());
  }

  // To create Map with all markers and properties
  public createMap() {
    if (this.mapElement) {
      this.showSingleOutageCluster = true;
      this.showAddressListWindow = false;
      this.clusterServiceLocations = [];
      this.showInfoWindow = false;
      this.infoWindowDetails = null;
      this.outageInfoDetails = null;
      this.allMarkers = [];
      if (!this.mapContentLoaded) {
        this.searchLocations(this.serviceLocations);
        this.serviceLocationsList = this.serviceLocations;
        // Below code is added to hide filter till outage data is received
        if (this.serviceLocations[0]?.outages) {
          this.searchObj.filteredArray = this.setFilterObject(this.serviceLocations);
        } else {
          this.searchObj.filteredArray = null;
        }
        this.searchObj = Object.assign({}, this.searchObj);
      }
      this.createMapProperties();
      const latLngValue = this.serviceLocations?.some(obj => obj.address?.latitude?.trim().length &&
       obj.address?.longitude?.trim().length);
      if (this.serviceLocations?.length && latLngValue) {
        this.isFormerAcct = false;
        this.serviceLocations.forEach((location, index) => {
          this.markServiceAddress(location, index);
        });
        this.createMarkerCluster();
        this.setMapCenterBasedOnMarkers();
        if (this.serviceLocations?.length === 1 && !this.showMapMarkerLoader) {
          this.openInfoWindow(this.serviceLocations[0], false);
          this.map.setZoom(15);
          this.map.panTo(this.serviceLocations[0].marker.getPosition());
        } else {
          setTimeout(() => {
            let markerIconType;
            let outageCluster;
            let outageMultiSuiteClustersCount = 0;
            let serviceLocationIndex;
            const markerCluster = this.cluster.clusters;
            markerCluster?.forEach(_cluster => {
              const _clusterMarkers = _cluster.markers;
              if (_clusterMarkers.length > 1) {
                markerIconType = this.getClusterMarkerType(_clusterMarkers);
                serviceLocationIndex = this.serviceLocations?.findIndex(loc =>
                  loc.markerIconType === markerIconType
                );
                const allEqual = _cluster.markers.every(val =>
                (val.position.lat() === _cluster.markers[0].position.lat() &&
                  val.position.lng() === _cluster.markers[0].position.lng()));
                if (allEqual && (markerIconType === OutageType.PLANNED || markerIconType === OutageType.UNPLANNED)) {
                  outageCluster = _cluster;
                  outageMultiSuiteClustersCount++;
                }
              }
            });
            let outageCounter = 0;
            if (outageMultiSuiteClustersCount === 1) {
              outageCluster?.markers?.forEach((cluster) => {
                if (cluster.label.text === OutageType.UNPLANNED || cluster.label.text === OutageType.PLANNED) {
                  outageCounter++;
                }
              });
              if (outageCounter === 1 && !this.searchValue?.length) {
                this.openAddressListWindow(outageCluster);
                this.onClickOfSuiteAddress(this.serviceLocations[serviceLocationIndex]);
              }
            }
          }, 800);
        }
        if (this.outageAlertClicked) {
          this.onClickOfOutageViewDetails(this.selectedOutage);
        }
      }
    }
  }

  /* To create map with all the required properties only */
  public createMapProperties() {
    this.mapProperties = {
      center: {
        lat: this.serviceLocations[0]?.address?.latitude?.trim().length ?
          parseFloat(this.serviceLocations[0]?.address?.latitude) : 47.116386,
        lng: this.serviceLocations[0]?.address?.longitude?.trim().length
          ? parseFloat(this.serviceLocations[0]?.address?.longitude) : -101.299591
      },
      restriction: {
        latLngBounds: US_BOUNDS,
        strictBounds: false,
      },
      zoom: 5,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      // disable the default User Interface
      disableDefaultUI: true,
      streetViewControl: false,
      // add back fullscreen, streetview, zoom
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      clickableIcons: false
    };
    this.map = new google.maps.Map(this.mapElement?.nativeElement, this.mapProperties);
    this.map.setOptions({ styles: this.getStyleType(this.styleType) });
    this.mapContentLoaded = true;
    this.outageHoverWindow = new google.maps.InfoWindow();
    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      if (this.showAddressListWindow && !document.fullscreenElement) {
        this.showSingleOutageCluster = true;
      }
      if (this.showInfoWindow && this.serviceLocations.length !== 1 && this.infoWindowDetails.multiSuite) {
        setTimeout(() => {
          const markerCluster = this.cluster?.clusters;
          markerCluster?.forEach(_cluster => {
            const _clusterMarkers = _cluster.markers;
            if (_clusterMarkers.length > 1) {
              const markerIconType = this.getClusterMarkerType(_clusterMarkers);
              _cluster.marker.setIcon({
                url: MarkerIcon[`${markerIconType}_CLUSTER`],
                scaledSize: (markerIconType === OutageType.PLANNED || markerIconType === OutageType.UNPLANNED)
                 ? new google.maps.Size(36, 36) : new google.maps.Size(45, 45),
              });
              this.clusterServiceLocations = [];
              this.openAddressListWindow(_cluster);
            }
          });
        }, 500);
      }
      this.serviceLocations.forEach((location) => {
        if (location.multiSuite) {
          this.resetClusterMarker(location);
        }
      });
      if (this.infoWindowDetails && !this.infoWindowDetails?.multiSuite) {
        setTimeout(() => {
          const markerCluster = this.cluster?.clusters;
          markerCluster?.forEach(_cluster => {
            const _clusterMarkers = _cluster.markers;
            if (_clusterMarkers.length > 1) {
              const markerIconType = this.getClusterMarkerType(_clusterMarkers);
              _cluster.marker.setIcon({
                url: MarkerIcon[`${markerIconType}_CLUSTER`],
                scaledSize: (markerIconType === OutageType.PLANNED || markerIconType === OutageType.UNPLANNED)
                ? new google.maps.Size(36, 36) : new google.maps.Size(45, 45),
              });
            }
          });
        }, 500);
      }
    });
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fullScreenElement = document.querySelector('.gm-fullscreen-control') as any;
      fullScreenElement?.addEventListener('click', () => {
        if (document.fullscreenElement) {
          this.map.setZoom(2);
        }
      });
    }, 2000);
    google.maps.event.addListener(this.map, 'bounds_changed', () => {
      if (this.allMarkers.length > 100) {
        const markerCounter = [];
        for (const marker of this.allMarkers) {
          if (this.map.getBounds().contains(marker.getPosition())) {
            markerCounter.push(marker);
          }
        }
        this.cluster?.clearMarkers();
        this.cluster?.addMarkers(markerCounter);
      }
    });
  }

  /* To pick map type styles depending on type selected */
  public getStyleType(style: string) {
    switch (style) {
      case 'default': {
        const mapStyle: google.maps.MapTypeStyle[] = [];
        return mapStyle;
      }
      case 'silver': {
        const mapStyle: google.maps.MapTypeStyle[] = [
          {
            elementType: 'geometry',
            stylers: [{ color: '#f5f5f5' }],
          },
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [{ color: '#616161' }],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#f5f5f5' }],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#bdbdbd' }],
          },
          {
            featureType: 'administrative.province',
            elementType: 'geometry',
            stylers: [{ color: '#bdbdbd' }],
          },
          {
            featureType: 'administrative.country',
            elementType: 'geometry',
            stylers: [{ color: '#bdbdbd' }],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#eeeeee' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#757575' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#e5e5e5' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#757575' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#dadada' }, { visibility: 'off' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#616161' }],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{ color: '#e5e5e5' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: '#eeeeee' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#c9c9c9' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
          },
        ];
        return mapStyle;
      }

      case 'night': {
        const mapStyle: google.maps.MapTypeStyle[] = [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
          },
        ];
        return mapStyle;
      }

      case 'retro': {
        const mapStyle: google.maps.MapTypeStyle[] = [
          { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#c9b2a6' }],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#dcd2be' }],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#ae9e90' }],
          },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#93817c' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ color: '#a5b076' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#447530' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#f5f1e6' }],
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#fdfcf8' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#f8c967' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#e9bc62' }],
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{ color: '#e98d58' }],
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#db8555' }],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#806b63' }],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }],
          },
          {
            featureType: 'transit.line',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8f7d77' }],
          },
          {
            featureType: 'transit.line',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#ebe3cd' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{ color: '#b9d3c2' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#92998d' }],
          },
        ];
        return mapStyle;
      }

    }
  }

  /* To create the custom markers and its info window on the maps */
  public markServiceAddress(markAddress: ServiceLocations, index: number) {
    if (markAddress) {
      let markerIconType;
      if (this.showMapMarkerLoader) {
        markerIconType = OutageType.HEALTHCHECK;
      } else {
        markerIconType = markAddress.outages ? markAddress.outages.outageDetails.length > 0
          ? this.getMarkerType(markAddress.outages, index) : OutageType.NOOUTAGE : OutageType.HEALTHCHECK;
      }
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(parseFloat(markAddress?.address?.latitude),
          parseFloat(markAddress?.address?.longitude)),
        map: this.map,
        title: markAddress.address.fullAddress,
        label: { text: markerIconType, className: 'invisible' },
        icon: {
          url: (this.serviceLocations.length === 1 && !this.showMapMarkerLoader) ?
            MarkerIcon[`${markerIconType}_PIN`] : MarkerIcon[`${markerIconType}_MARKER`],
          scaledSize: (this.serviceLocations.length === 1 && !this.showMapMarkerLoader) ?
            (markerIconType === OutageType.NOOUTAGE) ?
            new google.maps.Size(45, 45) : new google.maps.Size(90, 90) :
            new google.maps.Size(30, 30),
        },
      });
      this.serviceLocations[index].marker = marker;
      this.serviceLocations[index].markerIconType = markerIconType;
      this.allMarkers.push(marker);
      marker.addListener('click', () => {
        if (this.infoWindowDetails) {
          if (this.infoWindowDetails?.multiSuite) {
            this.resetClusterMarker(this.infoWindowDetails);
          } else {
            if (this.infoWindowDetails?.marker.getIcon()['url'].includes('map-location-tags')) {
              this.infoWindowDetails?.marker.setIcon({
                url: MarkerIcon[`${this.infoWindowDetails?.marker.getLabel()['text']}_MARKER`],
                scaledSize: new google.maps.Size(30, 30)
              });
            }
          }
        }
        setTimeout(() => {
          const markerCluster = this.cluster?.clusters;
          markerCluster?.forEach(_cluster => {
            const _clusterMarkers = _cluster.markers;
            if (_clusterMarkers.length > 1) {
              const markerPinType = this.getClusterMarkerType(_clusterMarkers);
              _cluster.marker.setIcon({
                url: MarkerIcon[`${markerPinType}_CLUSTER`],
                scaledSize: (markerIconType === OutageType.PLANNED || markerIconType === OutageType.UNPLANNED)
                ? new google.maps.Size(36, 36) : new google.maps.Size(45, 45),
              });
            }
          });
        }, 100);
        this.openInfoWindow(markAddress);
        // setTimeout(() => document.getElementById('close-infoWindow')?.focus());
      });
    }
  }

  /* To make all changes on opening the  info window */
  public openInfoWindow(markAddress: ServiceLocations, multiSuite?: boolean) {
    if (markAddress) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      this.showAddressListWindow = false;
      // To change the clicked marker to pin icon
      const markerIconType = markAddress.marker?.getLabel()['text'];
      const marker = markAddress.marker;
      if (multiSuite) {
        markAddress.singleSuiteMarker?.marker?.setLabel({
          text: markerIconType,
          className: 'invisible'
        });
        markAddress.singleSuiteMarker?.marker?.setTitle(markAddress.address.fullAddress);
        markAddress.singleSuiteMarker?.marker?.setIcon({
          url: MarkerIcon[`${markerIconType}_PIN`],
          scaledSize: (markerIconType === OutageType.NOOUTAGE || markerIconType === OutageType.HEALTHCHECK) ?
            new google.maps.Size(45, 45) : new google.maps.Size(90, 90),
        });
      } else {
        marker.setIcon({
          url: MarkerIcon[`${markerIconType}_PIN`],
          scaledSize: (markerIconType === OutageType.NOOUTAGE || markerIconType === OutageType.HEALTHCHECK) ?
            new google.maps.Size(45, 45) : new google.maps.Size(90, 90),
        });
        //this.map.setZoom(15);
        this.map.panTo(marker.getPosition());
        this.map.setCenter(marker.getPosition());
      }
      this.showInfoWindow = true;
      this.outageInfoDetails = [];
      this.infoWindowDetails = markAddress;
      this.infoWindowDetails.multiSuite = multiSuite ? true : false;
      markAddress.multiSuite = this.infoWindowDetails.multiSuite;
      if (markerIconType !== OutageType.HEALTHCHECK) {
        this.healthCheckSpinner = false;
        this.createOutageInfoForEachServiceLocation(markAddress);
      } else if (!this.digitalProfileService.isNewCustomer) {
        this.healthCheckSpinner = true;
      } else {
        this.healthCheckSpinner = false;
      }
    }
  }

  // To set the map center to accomodate all the markers to view on load
  public setMapCenterBasedOnMarkers() {
    const bounds = new google.maps.LatLngBounds();
    for (const marker of this.allMarkers) {
      bounds.extend(marker.getPosition());
    }
    if (!isNaN(bounds.getCenter().lat()) && !isNaN(bounds.getCenter().lng())) {
      if ((bounds.getNorthEast().lat() !== bounds.getSouthWest().lat()) &&
        (bounds.getNorthEast().lng() !== bounds.getSouthWest().lng())) {
        this.map.setCenter(bounds.getCenter());
        this.map.panToBounds(bounds, 0);
        this.map.setZoom(10);
        this.map.fitBounds(bounds);
      }
    }
  }

  /* To create marker clusters with custom icons and count on the map */
  public createMarkerCluster() {
    const renderObj = {
      render: ({ count, position }) =>
        new google.maps.Marker({
          label: {
            text: String(count), color: '#fff', fontSize: '16px',
            fontFamily: 'cerapro-bold', fontWeight: '700'
          },
          position,
          icon: {
            url: MarkerIcon[`${OutageType.NOOUTAGE}_CLUSTER`],
            scaledSize: new google.maps.Size(45, 45),
          },
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          title: `Cluster of ${count} markers`
        }),
    };
    const algorithm = new SuperClusterAlgorithm({ maxZoom: 22 });
    this.cluster = new MarkerClusterer({
      map: this.map,
      markers: this.allMarkers,
      algorithm,
      renderer: renderObj,
    });
    this.cluster.addListener('clusteringend', (_markerCluster) => {
      if (!this.showInfoWindow) {
        let outageMultiSuiteClustersCount = 0;
        let outageClusterCount = 0;
        let markerIconType: OutageType;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let outageCluster: any;
        _markerCluster.clusters.forEach(_cluster => {
          const _clusterMarkers = _cluster.markers;
          if (_clusterMarkers.length > 1) {
            markerIconType = this.getClusterMarkerType(_clusterMarkers);
            _cluster.marker.setIcon({
              url: MarkerIcon[`${markerIconType}_CLUSTER`],
              scaledSize: (markerIconType === OutageType.PLANNED || markerIconType === OutageType.UNPLANNED)
              ? new google.maps.Size(36, 36) : new google.maps.Size(45, 45),
            });
            const allEqual = _cluster.markers.every(val =>
            (val.position.lat() === _cluster.markers[0].position.lat() &&
              val.position.lng() === _cluster.markers[0].position.lng()));
            if (markerIconType === OutageType.PLANNED || markerIconType === OutageType.UNPLANNED) {
              if (allEqual) {
                outageCluster = _cluster;
                outageMultiSuiteClustersCount++;
              } else {
                outageClusterCount++;
              }
            }
          }
        });
        if (outageMultiSuiteClustersCount === 1 && !outageClusterCount && this.showSingleOutageCluster) {
          this.openAddressListWindow(outageCluster);
        }
        this.showSingleOutageCluster = false;
      }
      _markerCluster.clusters.forEach((cluster)=> {
        cluster.marker.addListener('mouseover', ()=> {
          const markerIconType = this.getClusterMarkerType(cluster.markers);
          if (cluster.markers.length > 1 &&
             (markerIconType === OutageType.PLANNED || markerIconType === OutageType.UNPLANNED)) {
            const windowContent = markerIconType === OutageType.PLANNED ?
             this.resourceBundle.plannedHoverWindowText : this.resourceBundle.unplannedHoverWindowText;
            this.outageHoverWindow.setContent(windowContent);
            this.outageHoverWindow.open({
              map: this.map,
              anchor: cluster.marker
            });
          }
        });
        cluster.marker.addListener('mouseout', ()=> {
          this.outageHoverWindow.close();
        });
      });
    });
    this.cluster.addListener('click', (_cluster) => {
      this.serviceLocations.forEach((ele) => {
        // To set back single marker icon if any icon has pin icon
        if (ele.multiSuite) {
          this.resetClusterMarker(ele);
        } else {
          if (ele.marker.getIcon()['url'].includes('map-location-tags')) {
            ele.marker.setIcon({
              url: MarkerIcon[`${ele.marker.getLabel()['text']}_MARKER`],
              scaledSize: new google.maps.Size(30, 30)
            });
          }
        }
      });
      const setBackZoom = this.map.getZoom();
      const bounds = new google.maps.LatLngBounds();
      for (const marker of _cluster.markers) {
        bounds.extend(marker.position);
      }
      this.map.fitBounds(bounds);
      const allEqual = _cluster.markers.every(val =>
      (val.position.lat() === _cluster.markers[0].position.lat() &&
        val.position.lng() === _cluster.markers[0].position.lng()));
      if (allEqual) {
        setTimeout(() => {
          this.map.setZoom(setBackZoom);
        }, 0);
        this.clusterServiceLocations = [];
        if (this.showInfoWindow) {
          this.resetClusterMarker(this.infoWindowDetails);
        }
        this.openAddressListWindow(_cluster);
        setTimeout(() => document.getElementById('close-addressList')?.focus());
      }
    });
  }

  /**
   *  * @param _cluster
   *  * fetching all the service locations on a cluster
   *  * on opening the address list window
   * */
  public openAddressListWindow(_cluster: Cluster) {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.showInfoWindow = false;
    this.infoWindowDetails = null;
    this.outageInfoDetails = null;
    this.showAddressListWindow = true;
    if (this.clusterServiceLocations.length === 0) {
      for (const marker of _cluster?.markers) {
        const index = this.serviceLocations.findIndex(serviceLocation =>
          serviceLocation.address.fullAddress === marker.getTitle() &&
          !this.clusterServiceLocations.includes(serviceLocation));
        const outageType = this.serviceLocations[index].marker.getLabel()['text'];
        const loc = this.serviceLocations[index];
        loc.outageDetails = this.resourceBundle[outageType];
        loc.singleSuiteMarker = _cluster;
        loc.clusterMarker = {
          clusterCount: _cluster.marker?.getLabel()['text'] ?
            _cluster.marker?.getLabel()['text'] : this.serviceLocations?.length,
          clusterMarkerUrl: _cluster.marker?.getIcon()['url'] ?
            _cluster.marker?.getIcon()['url'] : this.serviceLocations[index]?.marker?.getIcon()['url']
        };
        this.clusterServiceLocations.push(loc);
      }
      this.clusterServiceLocations = this.sortMultiSuites();
      this.map.panTo(this.clusterServiceLocations[0]?.marker?.getPosition());
      this.map.setCenter(this.clusterServiceLocations[0]?.marker?.getPosition());
    }
  }

  //sorting the service addresses based on suite #
  public sortMultiSuites() {
    const sortedClusterServiceLocations = [...this.clusterServiceLocations]
      .sort((a: ServiceLocations, b: ServiceLocations) => {
        const aAddress = parseInt(a.address.addressLine1.replace(/\D/g, ''), 10);
        const bAddress = parseInt(b.address.addressLine1.replace(/\D/g, ''), 10);
        return aAddress - bAddress;
      });
    return sortedClusterServiceLocations;
  }

  /**
   * @param serviceLocation
   * resetting info window on click of a particular service address
   */
  public onClickOfSuiteAddress(serviceLocation: ServiceLocations) {
    this.showAddressListWindow = false;
    this.showInfoWindow = true;
    this.infoWindowDetails = null;
    this.outageInfoDetails = null;
    this.openInfoWindow(serviceLocation, true);
    // setTimeout(() => document.getElementById('close-infoWindow')?.focus());
  }

  /**
   * @param serviceLocation
   * displaying address list on click of back
   */
  public backToAddressList(serviceLocation: ServiceLocations) {
    this.triggerTealium(this.resourceBundle.backLinkAriaLabel);
    this.showInfoWindow = false;
    this.showAddressListWindow = true;
    this.resetClusterMarker(serviceLocation);
  }

  /**
   * @param serviceLocation
   * resetting the cluster marker back to its original state
   */
  public resetClusterMarker(serviceLocation: ServiceLocations) {
    serviceLocation.singleSuiteMarker?.marker?.setLabel({
      text: serviceLocation.clusterMarker.clusterCount, color: '#fff', fontSize: '14px',
      fontFamily: 'cerapro-bold'
    });
    serviceLocation.singleSuiteMarker?.marker?.setTitle
      (`Cluster of ${serviceLocation.clusterMarker.clusterCount} markers`);
    serviceLocation.singleSuiteMarker?.marker?.setIcon({
      url: serviceLocation.clusterMarker.clusterMarkerUrl,
      scaledSize: new google.maps.Size(45, 45),
    });
  }

  /**
   * resetting address list window on click of close
   */
  public closeAddressListWindow() {
    this.showAddressListWindow = false;
    this.clusterServiceLocations = [];
  }

  // On click of close icon on left panel
  public closeInfoWindow(serviceLocation: ServiceLocations) {
    this.showInfoWindow = false;
    this.infoWindowDetails = null;
    this.outageInfoDetails = null;
    if (serviceLocation?.multiSuite) {
      this.resetClusterMarker(serviceLocation);
      this.closeAddressListWindow();
    } else {
      serviceLocation.marker.setIcon({
        url: MarkerIcon[`${serviceLocation.marker.getLabel()['text']}_MARKER`],
        scaledSize: new google.maps.Size(30, 30),
      });
    }
  }

  /* To return marker type based on outages priority*/
  public getMarkerType(outage: Outage, index: number) {
    const outageTypeMarkerIndex = outage?.outageDetails?.findIndex(outageDetail =>
      outageDetail.outageType === OutageType.UNPLANNED || outageDetail.status === Status.INPROGRESS
    );
    if (outageTypeMarkerIndex > -1) {
      this.serviceLocations[index].outages.outageDetails[outageTypeMarkerIndex].outageType = OutageType.UNPLANNED;
      return OutageType.UNPLANNED;
    } else {
      return OutageType.PLANNED;
    }
  }

  /* To return cluster marker type based on outage priority*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getClusterMarkerType(markers: any[]) {
    if (markers.findIndex(marker => marker.label.text === OutageType.UNPLANNED) > -1) {
      return OutageType.UNPLANNED;
    } else if (markers.findIndex(marker => marker.label.text === OutageType.PLANNED) > -1) {
      return OutageType.PLANNED;
    } else if (markers.findIndex(marker => marker.label.text === OutageType.HEALTHCHECK) > -1) {
      return OutageType.HEALTHCHECK;
    } else {
      return OutageType.NOOUTAGE;
    }
  }

  /* To handle Outage View Detail click event */
  public onClickOfOutageViewDetails(selectedOutage: OutageType) {
    if (this.mapContentLoaded && this.searchObj.filteredArray) {
      this.outageAlertClicked = false;
      const _clearAll = { ...{ clear: 'clearall', initialLoad: true } };
      this.deleteTag = _clearAll;
      this.searchObj.searchArray = this.searchObj?.searchArray?.map((v) => ({ ...v, singleSuiteMarker: null }));
      this.searchObj.filteredArray =
        this.searchObj?.filteredArray?.map((v) => ({
          ...v,
          options: [...v.options.map(option => ({
            ...option,
            isChecked: option.optionValue === OutageSearchType[selectedOutage] ? true : false
          }))]
        }));
      this.searchObj = Object.assign({}, this.searchObj);
      setTimeout(() => {
        document.getElementById('cbMap').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 200);
    }
  }

  public triggerTealium(eventName: string) {
    this.tealium.link(eventName);
  }

  public searchLocations(locationData: ServiceLocations[]) {
    const _clearAll = { ...{ clear: 'clearall', initialLoad: true } };
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (locationData.length !== 0) ? this.deleteTag = _clearAll : this.turnedOnfiltertags = [];
    let serviceLoc;
    serviceLoc = locationData.map((v) => ({ ...v, marker: null }));
    serviceLoc = serviceLoc.map((v) => ({ ...v, billingAccounts: null }));
    serviceLoc = serviceLoc.map((v) => ({ ...v, singleSuiteMarker: null }));
    this.searchObj = {
      searchType: 'client',
      searchArray: serviceLoc,
      placeHolder: 'Ex. Street Address',
      searchColumns: ['name', 'address', 'billingAccounts'],
      filteredArray: this.setFilterObject(serviceLoc),
      searchPreceedFilter: true,
      avoidTitleCase: true
    };
    this.setCategoryOptions(serviceLoc);
  }

  public onClickDeviceSupport(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.redirectToInternet();
    }
  }

  public redirectToInternet() {
    this.router.navigate(['/sublanding/datatools']);
    sessionStorage.setItem('isSmartHelp', 'true');
    if (this.infoWindowDetails?.billingAccounts?.length === 1) {
      this._storageService.set(LocalStorageKeys.SelectedAccount,
        this.infoWindowDetails?.billingAccounts[0]?.accountGuid);
    }
  }

  /**
   * we will this method to send filtered tags to filter component
   *
   * @param event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getFiltertags(event: any) {
    this.turnedOnfiltertags = [...event];
  }
  /**
   * will use this emitter to send the tag we wish to delete to search component
   *
   * @param event
   */
  public clearFilterTag(event: string) {
    this.noServiceLocationsMsg = '';
    this.deleteTag = event;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getSearchResults(event: any) {
    this.noServiceLocationsMsg = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const element: any = document.getElementById('_search');
    this.searchValue = element?.value;
    this.addressSelected = this.searchValue;
    this.showSearchDropdown = (this.searchValue?.length > 0 && !this.filterApplied);
    this.serviceLocationsList = event;
    if (!this.showSearchDropdown) {
      if (this.serviceLocationsList.length === 0) {
        this.noServiceLocationsMsg = this.resourceBundle.noFilterResults;
        this.showInfoWindow = false;
        this.showAddressListWindow = false;
        this.showSearchDropdown = false;
        this.createMapProperties();
        return;
      }
      setTimeout(() => {
        if (event.length === 1 && event[0].multiSuite) {
          const tempList = this.searchObj.searchArray.filter(
            loc => loc.address?.latitude === event[0].address?.latitude &&
            loc.address?.longitude === event[0].address?.longitude);
          this.serviceLocations = tempList;
          this.createMap();
          const id = this.serviceLocations.findIndex(loc => loc.saSfdcId === event[0].saSfdcId);
          setTimeout(() => {
            this.openAddressListWindow(this.cluster.clusters[0]);
            this.onClickOfSuiteAddress(this.serviceLocations[id]);
          }, 200);
        } else {
          const multiSuiteLoc = event.filter(loc => loc.multiSuite === true);
          if (multiSuiteLoc.length > 0) {
            multiSuiteLoc.forEach((location)=> {
              const temp = this.searchObj.searchArray.filter
              (loc => loc.address?.latitude === location.address?.latitude &&
                loc.address?.longitude === location.address?.longitude);
                temp.forEach((serviceLoc)=> {
                  if(!this.serviceLocationsList.includes(serviceLoc)) {
                    this.serviceLocationsList.push(serviceLoc);
                  }
                });
            });
          }
          this.serviceLocations = this.serviceLocationsList;
          this.showInfoWindow = false;
          this.infoWindowDetails = null;
          this.outageInfoDetails = null;
          this.showAddressListWindow = false;
          this.clusterServiceLocations = [];
          this.createMap();
        }
        this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, marker: null }));
        this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, singleSuiteMarker: null }));
      });
    }
  }

  public selectedResult(result: ServiceLocations) {
    if (result) {
      this.addressSelected = result.address.fullAddress;
      let tempList = [];
      this.showSearchDropdown = false;
      if (result.multiSuite) {
        tempList = this.searchObj.searchArray.filter(loc => loc.address?.latitude === result.address?.latitude &&
          loc.address?.longitude === result.address?.longitude);
        this.serviceLocations = tempList;
        this.createMap();
        const id = this.serviceLocations.findIndex(loc => loc.saSfdcId === result.saSfdcId);
        setTimeout(() => {
          this.openAddressListWindow(this.cluster.clusters[0]);
          this.onClickOfSuiteAddress(this.serviceLocations[id]);
        }, 1000);
      } else {
        tempList.push(result);
        this.serviceLocations = tempList;
        this.createMap();
      }
      this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, marker: null }));
      this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, singleSuiteMarker: null }));
    }
  }

  public redirectToServices(asset, feature?: FeatureInfo[]) {
    this.resourceBundle.services.forEach((ele) => {
      if(ele.assetName === 'VOICE' && feature?.length){
        if (feature[0]?.featureName === FeatureName.RINGCENTRAL) {
          window.open(ele?.ringCentralLoginUrl, '_blank');
        } else if (feature[0]?.featureName === FeatureName.VOICE_WITH_RINGCENTRAL) {
          this.router.navigateByUrl(ele?.voiceRingCentralOptionUrl);
        }
      }else if (ele.assetName === asset) {
        this.router.navigateByUrl(ele.assetUrl);
      }
      if (this.infoWindowDetails?.billingAccounts?.length === 1) {
        this._storageService.set(LocalStorageKeys.SelectedAccount,
          this.infoWindowDetails?.billingAccounts[0]?.accountGuid);
      }
    });
  }

  // Show Save Mobile Number modal
  public showSaveMobileNumberModal() {
    if (this.editedMobileNumber === this.mobileNumber) {
      this.enrollPhoneNumber(true);
    } else {
      this.commonMessageService.hideMessage();
      this.saveMobileNumberModalRef =
        this.modalService.open(this.saveMobileNumberModal, {
          windowClass:
            this.isMobileDevice ? '' : 'redesign-modal-md',
          backdrop: 'static'
        });
    }
  }

  /**
   * get the user primary phone number from getUser call
   */
  public getUserContactNumberDetails() {
    this.getUserDetailsSubscription = this.cbHeaderService.getUserResponse
      .subscribe((results) => {
        const contactInfo: ContactInfo[] = results?.data?.getUser?.contacts
          .filter((contact: ContactInfo) =>
            contact.contactType === ContactType.PHONE && contact.isMobileNumber
          );
        this.mobileNumber = contactInfo?.length ? contactInfo[0]?.contactValue : null;
        this.mobileNumber = this.phonePipe.transform(this.mobileNumber);
      });
  }

  public enrollPhoneNumber(saveNumber: boolean) {
    this.commonMessageService.hideMessage();
    const input: ServiceLocationOutageInput = {
      phoneNumber: this.editedMobileNumber.replace(/-/g, ''),
      saSfdcId: [this.infoWindowDetails.saSfdcId],
      saveNumber
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.cbMapsService.getOutagePhoneUpdates(input).subscribe((results: any) => {
        if (results?.data?.createOutageNotification?.profileGuid) {
          const outageIndex = this.outageInfoDetails.findIndex(outage => outage.type === OutageType.UNPLANNED);
          this.outageInfoDetails[outageIndex].notificationFlag = true;
          this.outageInfoDetails[outageIndex].enrolledMobileNumber = this.editedMobileNumber;
          this.mobileNumber = saveNumber ? this.editedMobileNumber : this.mobileNumber;
          this.saveMobileNumberModalRef?.close();
          this.infoWindowDetails.outages.outageDetails.forEach(element => {
            if (element.outageType === OutageType.UNPLANNED) {
              element.notificationFlag = true;
              element.phoneNumber = this.editedMobileNumber;
            }
          });
          const index = this.serviceLocations
            .findIndex(loc => loc.saSfdcId === this.infoWindowDetails.saSfdcId);
          this.searchLocations[index] = this.infoWindowDetails;
          this.updatesForm.form.markAsPristine();
        }
        if (results.errors) {
          const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'updatePhoneNumber');
          if (errorInfo) {
            this.cbMapsService.modalScroll();
            this.commonMessageService.showMessage(
              this.resourceBundle.genericErrorMessage, 'error', 'unplannedOutage'
            );
          }
        }
      });
  }

  // Resetting user mobile Number on close of panel
  public resetPanel(outageType: OutageType) {
    if (outageType === OutageType.UNPLANNED) {
      const index = this.outageInfoDetails.findIndex(outage => outage.type === OutageType.UNPLANNED);
      this.editedMobileNumber = this.outageInfoDetails[index].notificationFlag ?
        this.outageInfoDetails[index].enrolledMobileNumber : this.mobileNumber;
    }
  }

  public getNetworkHealth() {
    this.digitalProfileService.outageSubCounter = 1;
    this.digitalProfileService.isOutageData = false;
    this.digitalProfileService.outageSubscriptionCall(this.profileGuid);
    this.digitalProfileService.getOutageInfo(this.profileGuid);
  }

  public showNetworkHealthError() {
    const errorMsg = `${this.resourceBundle.healthCheckErrorMsg} ${this.resourceBundle.refreshLink}`;
    this.commonMessageService.showMessage(errorMsg, 'error', 'cbMaps');
    setTimeout(() => {
      const refreshLink = document?.getElementsByClassName('refresh-link')[0];
      refreshLink?.setAttribute('tabindex', '0');
      refreshLink?.addEventListener('click', () => {
        this.getNetworkHealth();
        this.commonMessageService.hideMessage();
      });
    }, 1000);
  }

  public refreshLargeProfileData() {
    this.showMapMarkerLoader = true;
    this.createMap();
    this.digitalProfileService.outageSubCounter = 1;
    this.digitalProfileService.getRefreshDetails(this.profileGuid, Features.OUTAGE_LARGE_PROFILE);
  }

  ngOnDestroy() {
    this.getUserDetailsSubscription?.unsubscribe();
    if (this.outageNotificationDetails) {
      this.cbMapsService.setOutageViewDetailsClicked(false, null);
      this.outageNotificationDetails.unsubscribe();
    }
    this.digitalProfileService.setUpdatedServiceLocationDetails(null);
    this.commonMessageService.hideMessage();
    this.addressSelected = null;
    this.digitalProfileService.mapDetails = null;
    const mapsScript = document.getElementById('maps');
    if(mapsScript) {
      document.body.removeChild(mapsScript);
    }
  }

  /**
   * push category iff it has uniques values
   *
   * @returns categories list
   */
  private setFilterObject(source) {
    const categoriesList = [];
    const category = {
      categoryTitle: FilterParentCategory.SERVICES,
      options: this.setCategoryOptions(source)
    };
    if (category.options.length) {
      categoriesList.push(category);
    }
    //Adding static outage options
    const outageFilterCategory = {
      categoryTitle: FilterParentCategory.NETWORK_HEALTH,
      options: this.categoryOptions(Object.values(OutageSearchType),
        Object.keys(OutageSearchType))
    };
    categoriesList.push(outageFilterCategory);
    return categoriesList;
  }
  /**
   * creates a object with unique values for each category required
   */
  private setCategoryOptions(source) {
    const categories = new Set();
    if (source?.length > 0) {
      for (const location of source) {
        if (location?.assets?.length > 0) {
          for (const asset of location?.assets) {
            categories.add(asset.assetName);
          }
        }
      }
    }
    return this.categoryOptions([...categories].sort());
  }
  /**
   * below  methods creates options for specified category provided as input
   * we check if list is not having only null values the push
   *
   * @returns options
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private categoryOptions(categories: [] | any, searchValueCategories?: any[]) {
    const categoryList = [];
    categories?.forEach((category, index) => {
      if (category) {
        const option = {
          optionValue: category,
          isChecked: false,
          searchValue: searchValueCategories ? searchValueCategories[index] : null
        };
        categoryList.push(option);
      }
    });
    return categoryList;
  }

  private createOutageInfoForEachServiceLocation(serviceLocation: ServiceLocations) {
    const dateTimePipe = new DateTimePipe();
    if (serviceLocation.outages.outageDetails.length) {
      for (const outage of serviceLocation.outages.outageDetails) {
        let outageDetails: OutageInfoDetails;
        switch (outage.outageType) {
          case OutageType.UNPLANNED:
            this.healthCheckSpinner = true;
            outageDetails = this.resourceBundle[OutageType.UNPLANNED];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.cbMapsService.getOutageDescription(serviceLocation.saSfdcId).subscribe((results: any) => {
              if (results?.data?.getServiceLocation?.outages) {
                outageDetails.subHeader =
                  results?.data?.getServiceLocation?.outages?.outageDetails[0].message;
                this.healthCheckSpinner = false;
              }
            });
            outageDetails.etr = outage?.etr ?
              dateTimePipe.transform(outage?.etr) : 'Not Available';
            outageDetails.outageDate = dateTimePipe.transform(outage?.outageDate);
            outageDetails.notificationFlag = outage?.notificationFlag;
            outageDetails.chronicOutage =
              outage?.eventTypeGroup === EventTypeGroup.CHRONIC ? true : false;
            outageDetails.enrolledMobileNumber = this.phonePipe.transform(outage?.phoneNumber);
            this.editedMobileNumber = outage?.notificationFlag ?
              outageDetails.enrolledMobileNumber : this.mobileNumber;
            this.outageInfoDetails.push(outageDetails);
            if (serviceLocation?.billingAccounts?.length) {
              this.tealium.trackOutageStatus(serviceLocation?.profileGuid,
                serviceLocation?.billingAccounts[0]?.accountGuid, 'outage-found');
            }
            break;

          case OutageType.PLANNED:
            outageDetails = this.resourceBundle[OutageType.PLANNED];
            outageDetails.outageDate = dateTimePipe.transform(outage?.outageDate);
            this.outageInfoDetails.push(outageDetails);
            if (serviceLocation?.billingAccounts?.length) {
              this.tealium.trackOutageStatus(serviceLocation?.profileGuid,
                serviceLocation?.billingAccounts[0]?.accountGuid, 'outage-found');
            }
        }
      }
    } else {
      this.outageInfoDetails.push(this.resourceBundle[OutageType.NOOUTAGE]);
      const assets:Asset[] = this.infoWindowDetails?.assets;
      if (assets?.length === 1 && assets[0].assetName === AssetFamily.INTERNET) {
        this.onlyInternetServiceAvailable = true;
        }
      this.healthCheckSpinner = false;
      if (serviceLocation?.billingAccounts?.length) {
        this.tealium.trackOutageStatus(serviceLocation?.profileGuid,
          serviceLocation?.billingAccounts[0]?.accountGuid, 'outage-not-found');
      }
    }
  }

  private pushInIntervalsForLargeProfile(serviceLocs: ServiceLocations[], startIndex: number, endIndex: number) {
    if (startIndex <= serviceLocs.length) {
      this.mapContentLoaded = false;
      this.showMapMarkerLoader = false;
      const array = serviceLocs.slice(startIndex, endIndex);
      array.forEach((ele) => {
        this.serviceLocations.push(ele);
        this.markServiceAddress(ele, this.serviceLocations.length - 1);
      });
      if (startIndex === 0) {
        this.createMarkerCluster();
      } else {
        this.redrawMarkerCluster();
      }
      startIndex = startIndex + 999;
      endIndex = endIndex + 999;
      this.pushInIntervalsForLargeProfile(serviceLocs, startIndex, endIndex);
    } else {
      this.serviceLocations.forEach((element) => {
        const multiSuiteLoc = this.serviceLocations.filter(loc => loc.address?.latitude ===
          element.address?.latitude && loc.address?.longitude === element.address?.longitude);
        if (multiSuiteLoc.length > 1) {
          element.multiSuite = true;
        }
      });
      if (this.searchObj) {
        this.searchObj.searchArray = this.serviceLocations.map((v) => ({ ...v, marker: null }));
        this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, billingAccounts: null }));
        this.searchObj.searchArray = this.searchObj.searchArray.map((v) => ({ ...v, singleSuiteMarker: null }));
        this.searchObj = Object.assign({}, this.searchObj);
      }
      this.mapContentLoaded = true;
      console.log('End - build service large profile map creation', new Date());
    }
  }

  private redrawMarkerCluster() {
    this.cluster.addMarkers(this.allMarkers);
  }

  private async loadScripts() {
    await this.getScript('https://maps.google.com/maps/api/js?client=gme-coxenterprises&v=3&libraries=places');
  }

  private getScript = (url: string) => new Promise<void>((resolve) => {
    const scriptEle = document.createElement('script');
    scriptEle.async = true;
    document.getElementsByTagName('body')[0].appendChild(scriptEle);
    scriptEle.src = url;
    scriptEle.id = 'maps';
    scriptEle.addEventListener('load', () => resolve(), false);
  });
}
