/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AEMClientService, CommonMessageService, DeviceDetectorService, ResourceBundle } from 'common-ui-lib';
import { isEmpty } from 'lodash';
import { TranscriptRequest } from './audio-player.model';
import { AudioPlayerService } from './audio-player.service';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit {
  @Input() mimeType: string;
  @Input() duration: number;
  @Input() showTranscript = true;
  @Input() transcriptRequest: TranscriptRequest;
  @Output() voiceMessagePlayed = new EventEmitter<boolean>();
  public isAudioPlaying = false;
  public player: HTMLElement;
  public progressSlider: HTMLElement;
  public audioDuration: number;
  public audioTime: number;
  public formattedAudioDuration: string | number;
  public audioCurrentTime: string | number;
  public audioCurrentVolume: number;
  public isAudioMute: boolean;
  public lastRecordedVolume: number;
  public audioPlayedOnce: boolean;
  public isMobile = false;
  public isDesktop: boolean;
  public isTablet: boolean;
  public audioDurationForFormat: number;
  public transcriptText: string;
  public transcriptToggle: boolean;
  public resourceBundle: ResourceBundle = {};
  constructor(private deviceService: DeviceDetectorService, private audioPlayerService: AudioPlayerService,
              public audioPlayerMsg: CommonMessageService, private aemClient: AEMClientService) { }

  ngOnInit() {
    this.aemClient.getSharedBundle('audio-player')
    .subscribe(
      (data: any) => {
        this.resourceBundle = data;
      }
    );
    this.player = document.getElementById('audioPlayer');
    this.progressSlider = document.getElementById('timeline1');
    if (this.deviceService.isMobile()) {
      this.isMobile = true;
      this.isTablet = false;
    } else if (this.deviceService.isTablet()) {
      this.isTablet = true;
      this.isMobile = false;
    } else {
      this.isMobile = false;
      this.isTablet = false;
    }
    this.player.onloadedmetadata = () => {
      this.audioTime = this.player['currentTime'];
      this.audioDuration = Math.round(this.player['duration']);
      this.audioDurationForFormat =
        this.duration && this.duration >= Math.round(this.player['duration']) ? this.duration : this.player['duration'];
      this.audioCurrentVolume = this.player['volume'] * 100;
      this.audioCurrentTime = `${Math.floor(this.player['currentTime'] / 60)}:${Math.floor(this.player['currentTime'] % 60) < 10
          ? `0${Math.floor(this.player['currentTime'] % 60)}`
          : Math.floor(this.player['currentTime'] % 60)
        }`;
      this.formattedAudioDuration = `${Math.floor(this.audioDurationForFormat / 60)}:${Math.floor(this.audioDurationForFormat % 60) < 10
          ? `0${Math.floor(this.audioDurationForFormat % 60)}`
          : Math.floor(this.audioDurationForFormat % 60)
        }`;
      this.controlProgressBar();
      this.controlVolume();
      this.controlProgressHead();
      if (this.player['volume'] > 0) {
        this.isAudioMute = false;
        this.lastRecordedVolume = this.player['volume'];
      }
      this.audioPlayedOnce = false;
    };
  }

  public controlAudio(event: any, audioAction: string) {
    if (audioAction === 'play') {
      this.player['play']();
      this.audioPlayedOnce = true;
      this.voiceMessagePlayed.emit(true);
      setTimeout(() => {
        document.getElementById('pauseButton').focus();
      }, 2);
    } else {
      this.player['pause']();
      setTimeout(() => {
        document.getElementById('playButton').focus();
      }, 2);
    }
  }

  public controlProgressBar() {
    this.player.ontimeupdate = () => {
      this.audioTime = this.player['currentTime'];
      let currentTime = this.player['currentTime'];
      this.audioCurrentTime = `${Math.floor(this.player['currentTime'] / 60)}:${Math.floor(this.player['currentTime'] % 60) < 10
          ? `0${Math.floor(this.player['currentTime'] % 60)}`
          : Math.floor(this.player['currentTime'] % 60)
        }`;
      if (this.player['currentTime'] === this.player['duration']) {
        currentTime = this.audioDurationForFormat;
      }
      document.getElementById('playtime').innerHTML = `${Math.floor(currentTime / 60)}:${Math.floor(currentTime % 60) < 10 ? `0${Math.floor(currentTime % 60)}` : Math.floor(currentTime % 60)
        } - ${Math.floor(this.audioDurationForFormat / 60)}:${Math.floor(this.audioDurationForFormat % 60) < 10
          ? `0${Math.floor(this.audioDurationForFormat % 60)}`
          : Math.floor(this.audioDurationForFormat % 60)
        }`;
      this.progressSlider['value'] = this.player['currentTime'];
      if (this.player['currentTime'] === 0.0 || this.player['currentTime'] === this.player['duration']) {
        this.audioPlayedOnce = false;
      }
    };
  }

  public controlProgressHead() {
    this.progressSlider.addEventListener('input', (e) => {
      this.player['currentTime'] = Math.round(e.target['value']);
    });
  }

  public controlVolume() {
    const volumeSlider = document.getElementById('volume-slider');
    volumeSlider.addEventListener('input', (e) => {
      const value = e.target['value'];
      this.lastRecordedVolume = value / 100;
      this.player['volume'] = this.lastRecordedVolume;
      this.audioCurrentVolume = this.player['volume'] * 100;
      if (this.player['volume'] === 0) {
        this.isAudioMute = true;
      } else {
        this.isAudioMute = false;
      }
    });
  }

  public rewindAudio(event: any) {
    this.player['pause']();
    this.player['currentTime'] = 0.0;
    this.isAudioPlaying = false;
    this.audioTime = this.player['currentTime'];
    this.progressSlider['value'] = this.audioTime;
    this.audioPlayedOnce = false;
  }

  public clickToMute() {
    this.isAudioMute = true;
    this.player['volume'] = 0;
    this.audioCurrentVolume = 0;
    setTimeout(() => {
      document.getElementById('muteButton').focus();
    }, 2);
  }

  public clickToUnmute() {
    this.isAudioMute = false;
    this.player['volume'] = this.lastRecordedVolume;
    this.audioCurrentVolume = this.player['volume'] * 100;
    setTimeout(() => {
      document.getElementById('unmuteButton').focus();
    }, 2);
  }

  /*
   * API call to fetch audio transcript
   */
  public async onClickOfTranscript() {
    this.audioPlayerMsg.hideMessage();
    this.transcriptToggle = !this.transcriptToggle;
    if (this.transcriptToggle) {
      try {
        const data = await this.audioPlayerService.getTranscript(this.transcriptRequest?.rowId, this.transcriptRequest?.assignedPhoneNumber);
        this.transcriptText = data.transcribeMessage;
        if (isEmpty(this.transcriptText)) {
          this.getTranscriptPolling();
        }
      } catch (error) {
        this.audioPlayerMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'audioErrMsg');
        this.modalScroll();
      }
    } else {
      this.transcriptText = '';
    }
  }

  /*
   * polling calls to fetch audio transcript
   */
  private async getTranscriptPolling() {
    let count = 1;
    const interval = setInterval(async () => {
      try {
        const data =  await this.audioPlayerService.getTranscript(this.transcriptRequest?.rowId, this.transcriptRequest?.assignedPhoneNumber);
        this.transcriptText = data.transcribeMessage;
        if(count === this.resourceBundle.maxPollingCount && isEmpty(this.transcriptText)) {
          this.audioPlayerMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'audioErrMsg');
          this.modalScroll();
          clearInterval(interval);
        } else if(isEmpty(this.transcriptText)) {
          count++;
        } else if(!isEmpty(this.transcriptText)) {
          clearInterval(interval);
        }
      } catch (error) {
        clearInterval(interval);
        this.audioPlayerMsg.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'audioErrMsg');
        this.modalScroll();
      }
    }, this.resourceBundle.pollingTimer);
  }

  /*
   * scroll to the top of the modal
   */
  private modalScroll() {
    const modalWindow = document.querySelectorAll('ngb-modal-window');
    if (modalWindow && modalWindow.length > 0) {
      modalWindow[0].scrollTop = 0;
    }
  }
}
