import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import gql from 'graphql-tag';

@Injectable()
export class MegaMenuService {

    public contentLoaded = false;
    public ticketsCount = '10';
    public ticketsList: any;
    constructor(private apollo: Apollo) { }
    public getMegaMenuList() {
        const megaMenuQuery = gql`
        query toGetMegaMenuList {  
            headerMenu {
                firstName
                options {
                    url
                    enabled
                    id
                    name
                    openInNewWindow
                }
            }
        }
    `;

        return this.apollo.query({
            query: megaMenuQuery,
        });
    }
}
