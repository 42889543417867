import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    AEMClientService, AppConfig, CBHelperService, ResourceBundle, StringUtilService, TealiumUtagService,
    DeviceDetectorService } from 'common-ui-lib';
import { ManageE911NotificationsService } from 'voice-utilities-ui/lib/userandsystemmanagement/managee911/manage-e911-notifications/manage-e911-notifications.service';

@Component({
  selector: 'app-kari-law-alert',
  templateUrl: './kari-law-alert.component.html',
  styleUrls: ['./kari-law-alert.component.scss']
})
export class KariLawAlertComponent implements OnInit {

  public isMobileDevice = false;
  public resourceBundle: ResourceBundle = {};
  public magnifiedDateTimeView = false;
  public kariLawAlertReminder: string;
  public optOut: boolean;
  public declineModal = false;

  constructor(
    public aemClient: AEMClientService,
    private deviceService: DeviceDetectorService,
    public activeModal: NgbActiveModal,
    public tealium: TealiumUtagService,
    public cbHelper: CBHelperService,
    private config: AppConfig,
    public stringUtilService: StringUtilService,
    public router: Router,
    public manageE911NotificationService: ManageE911NotificationsService
  ) { }

  ngOnInit() {
    this.kariLawAlertReminder = this.config.getConfig('kariLawAlertReminder');
    this.aemClient.getSharedBundle('kari_law_alert')
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
        }
      );
    this.magnifiedDateTimeView = this.deviceService.isPageZoomed();
    if (this.deviceService.isMobile()) {
      this.isMobileDevice = true;
    }
  }

  public triggerTealium(eventName: string){
    this.tealium.button(eventName, eventName);
  }

  public close(eventName: string) {
    this.tealium.button(eventName, eventName);
    this.activeModal.close(eventName);
  }

  public onDecline() {
    this.declineModal = true;
    const reqBody = {
      e911optOutStatus: true
    }
    this.manageE911NotificationService.updateOptOutStatus(reqBody).subscribe((res: any)=>{
      if (res?.data?.updateE911ReminderOptout?.code === '0') {
        this.manageE911NotificationService.declineAction();
      }
    });
  }

  public openMoreDetails() {
    window.open('https://www.cox.com/business/support/karis-law-and-e911-notifications.html', '_blank');
  }

  public navigateToE911Notifications() {
    this.activeModal.close();
    this.router.navigate(['/voice/userandsysmanagement/manageE911/manageE911notifications']);
  }
}
