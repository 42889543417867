import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
  SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, AppConfig, DeviceDetectorService, ResourceBundle, TealiumUtagService } from 'common-ui-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppMessage, Modaltemplate } from './leavingcoxsitemodal.model';

@Component({
  selector: 'comn-leavingcoxsitemodal',
  templateUrl: './leavingcoxsitemodal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LeavingcoxsitemodalComponent implements OnChanges, OnInit {
  @Input() modaltemplate?: Modaltemplate;
  @Input() showAppMsg?: boolean;
  @Input() secondaryBtnRequired?: boolean;
  @Input() primaryBtnRequired?: boolean;
  @Input() appMessage: AppMessage;
  @Input() featureNames?: string[] = null;
  @Input() switchPage?: boolean = true;
  @Input() showBtnLongText?: boolean = true;
  @Output() onPrimaryClick = new EventEmitter();
  @Output() onSecondaryClick = new EventEmitter();

  public features = null;
  public unsavedfeaturetabs: string;
  public resourceBundle: ResourceBundle = {};
  public isMobileDevice = false;
  private onDestroy$ = new Subject<boolean>();
  constructor(public tealium: TealiumUtagService, public activeModal: NgbActiveModal,
    private aemClient: AEMClientService, public deviceService: DeviceDetectorService,) { }
  ngOnInit(): void {
    this.aemClient.getRedesignBundle('unsavedChangesModal/unsavedChangesModal')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: ResourceBundle) => {
        this.resourceBundle = data;
      });
    this.isMobileDevice = this.deviceService.isMobile();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.featureNames) {
      this.features = [...new Set(this.featureNames)];
      this.unsavedfeaturetabs = this.features.join(', ').replace(/, ([^,]*)$/,
        '<span class="font-normal"> and </span> $1');
    }
  }

  // On click of OK/Yes/Confirm
  onPrimaryBtnClick(event: Event): void {
    this.onPrimaryClick.emit(event);
  }

  // On Click of Cancel/No
  onSecondaryBtnClick(): void {
    this.onSecondaryClick.emit();
  }

}
