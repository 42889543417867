import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, AppConfig, CBHelperService, CommonMessageService,
   DeviceDetectorService, DigitalProfileSharedService, ResourceBundle, TealiumUtagService } from 'common-ui-lib';
import { SelfInstallComponent } from 'common-ui-lib/lib/self-install/self-install.component';
import { ChannelStatus, deviceDetails, DeviceType, SelfInstallStatus } from
 'common-ui-lib/lib/self-install/self-install.model';
import { SelfInstallService } from 'common-ui-lib/lib/self-install/self-install.service';
import { ContactDetailsModalComponent } from '../contact-details-modal/contact-details-modal.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-device-installation-steps',
  templateUrl: './device-installation-steps.component.html',
  styleUrls: ['./device-installation-steps.component.scss']
})
export class DeviceInstallationStepsComponent implements OnInit, OnDestroy {
  //public installationStepsList = [];
  @ViewChild('modalTop') modalTop!: ElementRef;
  @Input() resourceBundle: ResourceBundle = {};
  @Input() installationStepsList = [];
  @Input() deviceDetails: deviceDetails;
  public stepNumber: number;
  public stepContent: string;
  public contentLoaded =false;
  public isMobileDevice = false;
  public deviceType = DeviceType;
  public disableNextBtn = false;
  public isSelfInstallClicked = false;
  public isMyadmin: boolean;
  public isNetAssuranceDevice = false;
  public selfInstallStatus = SelfInstallStatus;
  public activationStarted = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private interval: any;
  constructor(public selfInstallService: SelfInstallService,public activeModal: NgbActiveModal,
    private tealium: TealiumUtagService,private modalService: NgbModal,
    public deviceDetectorService: DeviceDetectorService,private aemClient: AEMClientService,
    public messageService: CommonMessageService, private sanitizer: DomSanitizer,
    private cBHelperService: CBHelperService, private config: AppConfig,
     private digitalProfileService: DigitalProfileSharedService) { }

  ngOnInit(): void {
    this.isMobileDevice = this.deviceDetectorService.isMobile();
    this.isMyadmin = this.cBHelperService.isMyAdmin();
    this.isNetAssuranceDevice = (this.deviceDetails.itemNumber === 'NCPA100' ||
       this.deviceDetails.itemNumber === 'NCPA300');
    if (this.deviceDetails.deviceType === DeviceType.INTERNETDEVICE) {
      this.stepNumber = 0;
      this.stepContent = this.sanitizeContent(this.installationStepsList[this.stepNumber].content);
      //comment for CBMA1-62302, Net Assurance: Next button logic for non-1p self install
    }else if (!this.isMyadmin && this.isNetAssuranceDevice) {
      this.stepNumber = 0;
      this.stepContent = this.sanitizeContent(this.installationStepsList[this.stepNumber].content);
    }
  }

  ngOnDestroy() {
    if(this.interval){
      clearInterval(this.interval);
    }
  }

  public nextStep(){
    this.modalScroll();
    if (this.deviceDetails.deviceType === '1p') {
      const length = this.installationStepsList.length;
      if (this.stepNumber < length-1) {
        this.stepNumber += 1;
        this.stepContent =  this.sanitizeContent(this.installationStepsList[this.stepNumber].content);
      }else{
          this.disableNextBtn = true;
          let timer = 0;
          this.interval = setInterval(()=>{
            timer+=1;
            this.selfInstallService.deviceDetails(this.selfInstallService.accountGuid).subscribe((results)=>{
              if(results?.data?.getAccount){
                const deviceDetailsList = results?.data?.getAccount?.deviceInstallDetails?.deviceList;
                deviceDetailsList.find((obj)=>{
                  if(obj?.itemNumber === this.deviceDetails.itemNumber){
                    if(obj?.installStatus === 'F' || obj.installStatus === 'Y'){
                      clearInterval(this.interval);
                      this.activeModal?.close();
                      this.disableNextBtn = false;
                      this.selfInstallService.openOrderDetailsModal();
                    }
                    return true;
                  }
                });
              }else{
                clearInterval(this.interval);
                this.disableNextBtn = false;
                this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'installResponse');
              }
            });
            if(timer === 5){
              clearInterval(this.interval);
              this.disableNextBtn = false;
              this.activeModal?.close();
              this.selfInstallService.openOrderDetailsModal();
            }
          },60000);
      }
    } else if(this.isNetAssuranceDevice && !this.isMyadmin){
      const length = this.installationStepsList.length;
      if (this.stepNumber < length-1) {
        this.stepNumber += 1;
        this.stepContent =  this.sanitizeContent(this.installationStepsList[this.stepNumber].content);
      }else{
        if(this.deviceDetails.installStatus === this.selfInstallStatus.SUCCESS){
          this.updateNonInternetDeviceStatus();
        } else{
            this.selfInstallService.getInstallStatus(this.deviceDetails.caseId, this.deviceDetails?.serialNumber)
            .subscribe((result)=>{
              if(result?.errors){
                this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'installResponse');
              }
            });
            this.activationStarted = true;
            this.digitalProfileService.netAssuranceStatus = false;
        }
      }
    } else {
        this.updateNonInternetDeviceStatus();
    }
  }

  public modalScroll() {
    this.modalTop?.nativeElement?.scrollTo({ top: 0, behavior: 'smooth'});
  }

  public triggerTealium(eventName: string){
    this.tealium.button(eventName, eventName);
  }

  public backStep(){
    this.modalScroll();
    if(this.stepNumber>0 && !this.activationStarted){
      this.stepNumber-=1;
      this.stepContent= this.sanitizeContent(this.installationStepsList[this.stepNumber].content);
    } else if(this.stepNumber===0 && !this.isMyadmin &&
       this.deviceDetails.deviceType === DeviceType.NONINTERNETDEVICE
       && this.deviceDetails.installStatus !== this.selfInstallStatus.SUCCESS){
      this.activeModal.close();
      const modalRef = this.modalService.open(ContactDetailsModalComponent, {
        windowClass: this.isMobileDevice ? '' : 'redesign-modal-md',
        backdrop: 'static'
      });
      modalRef.componentInstance.deviceDetails = this.deviceDetails;
      modalRef.componentInstance.resourceBundle = this.resourceBundle;
    } else if(this.activationStarted){
      this.stepContent= this.sanitizeContent(this.installationStepsList[this.stepNumber].content);
      this.activationStarted = false;
    }else{
      this.activeModal?.close();
       this.modalService.open(SelfInstallComponent,{
      windowClass: this.isMobileDevice ? '' : 'redesign-modal-md',
      backdrop: 'static'
    });
    }
  }

   public closeModal(){
    this.activeModal?.close();
    sessionStorage.removeItem('isNetAssurance');
    this.digitalProfileService.netAssuranceStatus = false;
   }

  public selfInstallClick(itemNumber){
    let url = this.resourceBundle.selfInstallUrl;
    if(itemNumber === 'NCPA300' || itemNumber === 'NCPA100'){
      url = this.resourceBundle.selfInstallUrlForNetAssurance;
    }else if(itemNumber === 'DCS9202'){
      url = this.resourceBundle.selfInstallUrlForNonInternetDevices;
    }
    this.isSelfInstallClicked = false;
    window.open(url, '_blank');
  }

  public sanitizeContent(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content) as string;
  }

  private updateNonInternetDeviceStatus(){
    this.selfInstallService.updateNon1pDeviceStatus(this.deviceDetails.itemNumber,this.deviceDetails.serialNumber)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .subscribe((results: any)=>{
      const deviceName = this.deviceDetails?.name?.toLowerCase()?.split(' ').join('-');
      if(!results?.errors){
        if(results?.data?.updateSelfInstallNon1PStatus?.code !== '0'){
          this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'installResponse');
          this.tealium.trackSuccessFailureEvents('selfInstallFail', `${deviceName}:self-install:fail`);
        }else{
          this.activeModal?.close();
          this.digitalProfileService.netAssuranceStatus = false;
          this.selfInstallService.openOrderDetailsModal();
        }
      }else{
        this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'installResponse');
        this.tealium.trackSuccessFailureEvents('selfInstallFail', `${deviceName}:self-install:fail`);
      }
    });
  }
}
