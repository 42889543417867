export interface EquipmentDetailsObj {
    status: string;
    operatingMode?: string;
    macAddress: string;
    ipv4Address?: string;
    ipv6Address?: string;
    connectedSince?: string;
    installedDate?: string;
    serialNumber: string;
    vendor: string;
    type: string;
    equipmentType: string;
    backupWANStatus: string;
    primaryWANStatus: string;
    wanMac: string;
    batteryType: string;
}

export interface PhoneEquipmentDetailsObj {
    status: string;
    macAddress: string;
    serialNumber: string;
    equipmentType: string;
}

export interface UpdateEncryptionResponse {
    id: string;
    encryptionPermission: string;
    encrypted: boolean;
    editable: boolean;
    encryptionValid: boolean;
    transactionId: string;
    code: string;
    message: string;
    warning: string;
}

export interface DataUtilization {
    backupUsage: string;
    asOfDate: string;
    billCycleNumber: string;
    billPeriodCharges: string;
    cycleEndDate: string;
    cycleStartDate: string;
    overageRate: string;
    remainingData: string;
}
export enum EncryptionPemissions {
    DISALLOWED = 'DISALLOWED',
}
export enum OperatingMode {
    ETHERNET = 'Ethernet',
    DOCSIS = 'DOCSIS'
}
