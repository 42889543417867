<div class="cb-spinner " *ngIf="isSecondary && isLoading" role="alert" aria-live="polite" aria-label="Loading">
    <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100" alt="Processing... please wait">
      <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
        style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%;">
      </circle>
    </svg>
    <div *ngIf="message" class="loading-text margin-top-5em position-absolute" [style.z-index]="99999" [ngClass]="isLoggingOut ? 'left-15px': '' ">
        {{message}}
    </div>
</div>

<ng-container *ngIf="visible && !isSecondary && !overlay">
    <div class="cb-secondary-spinner" *ngIf="!isSecondary" role="alert" aria-live="polite" aria-label="Loading">
        <div class="cb-spinner">
            <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100" alt="Processing... please wait">
        <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
          style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-dashoffset: 56.5487px; stroke-width: 10%;">
        </circle>
      </svg>
        </div>
    </div>
</ng-container>

<div class="cb-spinner position-absolute margin-top-neg-15" *ngIf="overlay && visible" role="alert" aria-live="polite" aria-label="Loading">
  <div *ngIf="message" class="text-center width-14 margin-left-neg-4-5 margin-top-neg-5em position-absolute bold" [style.z-index]="99999" [ngClass]="isLoggingOut ? 'left-15px': '' ">
    {{message}}
 </div>
  <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 100 100" alt="Processing... please wait">
    <circle cx="50%" cy="50%" r="45" class="ng-star-inserted"
      style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%;">
    </circle>
  </svg>
</div>
