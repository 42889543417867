import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
// import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AppConfig } from '../core/app.config';
import { ErrorMessageService } from '../shared/messages/error-message.service';
import { CoxMiddlewareException } from '../shared/models/system/coxMiddlewareException';
import { CBHelperService } from './cbhelper.service';
import { EditUserService } from './edituser.service';
@Injectable()
export class CoxHttpClient {
    private _unauthErr = 'Not Authenticated!';
    private _defaultErrorMsg = 'We\'re sorry. We can\'t complete your request at this time. Please try again.';
    private _defaultConfig: CoxHttpConfig = {
        customeErrorMessage: this._defaultErrorMsg,
        isUnAuthenticatedUrl: false,
    };
    // tslint:disable-next-line: no-any
    private activeRequests: CoxActiveHttpRequests<any> = {};
    constructor(
        private httpClient: HttpClient,
        private config: AppConfig,
        private cookieService: CookieService,
        private editUser: EditUserService,
        private errorMessageService: ErrorMessageService,
        private router: Router,
        private cbHelper: CBHelperService,
    ) {

    }

    /**
     * HTTP GET CALL
     * Cookies are cleaned up automatically!
     * Errors handled!
     * Headers passed!
     * @param url
     * @param config optional config override
     * @returns Observable<T>
     * ```typescript
     * get<MySuperData>('/api....')...
     *
     * interface MySuperData extends MicroserviceResponse {
     *  ...
     * }
     * ```
     */
    public get<T extends MicroserviceResponse>(url: string, config?: CoxHttpConfig): Observable<T> {
        if ((!this.cbHelper.isUserAuthenticated() || this.cbHelper.isCbmaAuthTokenExpired())
        && ((config && !config.isUnAuthenticatedUrl) || !config) ) {
            throw Error(this._unauthErr);
        }
        const existingRequest = this.getActiveRequest<T>(url, 'GET', '');
        if (existingRequest !== null) {
            return existingRequest;
        }
        const id = uuid();
        this.errorMessageService.errorMessage.show = false;
        const conf = config ? this.getConfigObj(config) : this._defaultConfig;
        this.activeRequests[id] = {
            method: 'GET',
            url,
            payload: '',
            observable: this.httpClient.get<T>(url, { headers: conf.headers ? conf.headers : this.headers })
            .pipe(share()).map(this.mapSuccess(id, conf), () => {
                this.activeRequests[id] = null;
            }),
        } as CoxActiveHttpRequest<T>;
        return this.activeRequests[id].observable;
    }

    /**
     * HTTP POST CALL
     * Cookies are cleaned up automatically!
     * Errors handled!
     * Headers passed!
     * @param url
     * @param body request body
     * @param config optional config override
     * @returns Observable<T>
     * ```typescript
     * post<MySuperData>('/takeBeer', { beer: 2 })...
     *
     * interface MySuperData extends MicroserviceResponse {
     *  ...
     * }
     * ```
     */
    // tslint:disable-next-line: no-any
    public post<T extends MicroserviceResponse>(url: string, body: any, config?: CoxHttpConfig): Observable<T> {
        if ((!this.cbHelper.isUserAuthenticated() || this.cbHelper.isCbmaAuthTokenExpired())
        && ((config && !config.isUnAuthenticatedUrl) || !config) ) {
            throw Error(this._unauthErr);
        }
        const existingRequest = this.getActiveRequest<T>(url, 'POST', JSON.stringify(body));
        if (existingRequest !== null) {
            return existingRequest;
        }
        const id = uuid();
        this.errorMessageService.errorMessage.show = false;
        const conf = config ? this.getConfigObj(config) : this._defaultConfig;
        this.activeRequests[id] = {
            method: 'POST',
            url,
            payload: JSON.stringify(body),
            observable: this.httpClient.post<T>(url, body, { headers: conf.headers ? conf.headers : this.headers })
            .pipe(share()).map(this.mapSuccess(id, conf), () => {
                this.activeRequests[id] = null;
            }),
        } as CoxActiveHttpRequest<T>;
        return this.activeRequests[id].observable;
    }

    /**
     * HTTP PUT CALL
     * Cookies are cleaned up automatically!
     * Errors handled!
     * Headers passed!
     * @param url
     * @param body request body
     * @param config optional config override
     * @returns Observable<T>
     * ```typescript
     * put<MySuperData>('/savesomething', { importantThing: 1 })...
     *
     * interface MySuperData extends MicroserviceResponse {
     *  ...
     * }
     * ```
     */
    // tslint:disable-next-line: no-any
    public put<T extends MicroserviceResponse>(url: string, body: any, config?: CoxHttpConfig): Observable<T> {
        if ((!this.cbHelper.isUserAuthenticated() || this.cbHelper.isCbmaAuthTokenExpired())
        && ((config && !config.isUnAuthenticatedUrl) || !config) ) {
            throw Error(this._unauthErr);
        }
        const existingRequest = this.getActiveRequest<T>(url, 'PUT', JSON.stringify(body));
        if (existingRequest !== null) {
            return existingRequest;
        }
        const id = uuid();
        this.errorMessageService.errorMessage.show = false;
        const conf = config ? this.getConfigObj(config) : this._defaultConfig;
        this.activeRequests[id] = {
            method: 'PUT',
            url,
            payload: JSON.stringify(body),
            observable: this.httpClient.put<T>(url, body, { headers: conf.headers ? conf.headers : this.headers })
            .pipe(share()).map(this.mapSuccess(id, conf), () => {
                this.activeRequests[id] = null;
            }),
        } as CoxActiveHttpRequest<T>;
        return this.activeRequests[id].observable;
    }

    /**
     * HTTP DELETE CALL
     * Cookies are cleaned up automatically!
     * Errors handled!
     * Headers passed!
     * @param url
     * @param config optional config override
     * @returns Observable<T>
     * ```typescript
     * delete<MySuperData>('/deletesomething')...
     *
     * interface MySuperData extends MicroserviceResponse {
     *  ...
     * }
     * ```
     */
    public delete<T extends MicroserviceResponse>(url: string, config?: CoxHttpConfig): Observable<T> {
        if ((!this.cbHelper.isUserAuthenticated() || this.cbHelper.isCbmaAuthTokenExpired())
        && ((config && !config.isUnAuthenticatedUrl) || !config) ) {
            throw Error(this._unauthErr);
        }
        const existingRequest = this.getActiveRequest<T>(url, 'DELETE', '');
        if (existingRequest !== null) {
            return existingRequest;
        }
        const id = uuid();
        this.errorMessageService.errorMessage.show = false;
        const conf = config ? this.getConfigObj(config) : this._defaultConfig;
        this.activeRequests[id] = {
            method: 'DELETE',
            payload: '',
            url,
            observable: this.httpClient.delete<T>(url, { headers: conf.headers ? conf.headers : this.headers })
            .pipe(share()).map(this.mapSuccess(id, conf), () => {
                this.activeRequests[id] = null;
            }),
        } as CoxActiveHttpRequest<T>;
        return this.activeRequests[id].observable;
    }

    /**
     * HTTP GET CALL
     * Cookies are cleaned up automatically!
     * Errors handled!
     * Headers passed!
     * @param url
     * @param config optional config override
     * @returns Observable<T>
     * ```typescript
     * await getAsync<MySuperData>('/getsomething')...
     *
     * interface MySuperData extends MicroserviceResponse {
     *  ...
     * }
     * ```
     */
    public getAsync<T extends MicroserviceResponse>(url: string, config?: CoxHttpConfig): Promise<T> {
        if ((!this.cbHelper.isUserAuthenticated() || this.cbHelper.isCbmaAuthTokenExpired())
        && ((config && !config.isUnAuthenticatedUrl) || !config) ) {
            throw Error(this._unauthErr);
        }
        let existingRequest = this.getActiveRequest<T>(url, 'GET', '');
        const conf = config ? this.getConfigObj(config) : this._defaultConfig;
        const id = uuid();
        if (!existingRequest) {
            this.errorMessageService.errorMessage.show = false;
            existingRequest = this.httpClient.get<T>(url, { headers: conf.headers ? conf.headers : this.headers })
            .pipe(share());
            this.activeRequests[id] = {
                url,
                method: 'GET',
                payload: '',
                observable: existingRequest,
            };
        }
        return this.processRequest(existingRequest, id, conf, url);
    }

    /**
     * HTTP POST CALL
     * Cookies are cleaned up automatically!
     * Errors handled!
     * Headers passed!
     * @param url
     * @param body request body
     * @param config optional config override
     * @returns Observable<T>
     * ```typescript
     * await postAsync<MySuperData>('/postsomething', { importantThing: 1 })...
     *
     * interface MySuperData extends MicroserviceResponse {
     *  ...
     * }
     * ```
     */
    public postAsync<T extends MicroserviceResponse>(
        url: string,
        // tslint:disable-next-line: no-any
        body: any,
        config?: CoxHttpConfig): Promise<T> {
            if ((!this.cbHelper.isUserAuthenticated() || this.cbHelper.isCbmaAuthTokenExpired())
            && ((config && !config.isUnAuthenticatedUrl) || !config) ) {
            throw Error(this._unauthErr);
        }
            let existingRequest = this.getActiveRequest<T>(url, 'POST', JSON.stringify(body));
            const conf = config ? this.getConfigObj(config) : this._defaultConfig;
            const id = uuid();
            if (!existingRequest) {
                this.errorMessageService.errorMessage.show = false;
                existingRequest = this.httpClient.post<T>(url, body,
                    { headers: conf.headers ? conf.headers : this.headers })
                .pipe(share());
                this.activeRequests[id] = {
                    url,
                    method: 'POST',
                    payload: JSON.stringify(body),
                    observable: existingRequest,
                };
            }
            return this.processRequest(existingRequest, id, conf, url);
    }

    /**
     * HTTP PUT CALL
     * Cookies are cleaned up automatically!
     * Errors handled!
     * Headers passed!
     * @param url
     * @param body request body
     * @param config optional config override
     * @returns Observable<T>
     * ```typescript
     * await putAsync<MySuperData>('/putsomething', { importantThing: 1 })...
     *
     * interface MySuperData extends MicroserviceResponse {
     *  ...
     * }
     * ```
     */
    public putAsync<T extends MicroserviceResponse>(
        // tslint:disable-next-line: no-any
        url: string, body: any, config?: CoxHttpConfig): Promise<T> {
            if ((!this.cbHelper.isUserAuthenticated() || this.cbHelper.isCbmaAuthTokenExpired())
            && ((config && !config.isUnAuthenticatedUrl) || !config) ) {
            throw Error(this._unauthErr);
        }
            let existingRequest = this.getActiveRequest<T>(url, 'PUT', JSON.stringify(body));
            const conf = config ? this.getConfigObj(config) : this._defaultConfig;
            const id = uuid();
            if (!existingRequest) {
                this.errorMessageService.errorMessage.show = false;
                existingRequest = this.httpClient.put<T>(url, body,
                    { headers: conf.headers ? conf.headers : this.headers })
                .pipe(share());
                this.activeRequests[id] = {
                    url,
                    method: 'PUT',
                    payload: JSON.stringify(body),
                    observable: existingRequest,
                };
            }
            return this.processRequest(existingRequest, id, conf, url);
    }

    /**
     * HTTP DELETE CALL
     * Cookies are cleaned up automatically!
     * Errors handled!
     * Headers passed!
     * @param url
     * @param config optional config override
     * @returns Observable<T>
     * ```typescript
     * await deleteAsync<MySuperData>('/getsomething')...
     *
     * interface MySuperData extends MicroserviceResponse {
     *  ...
     * }
     * ```
     */
    public deleteAsync<T extends MicroserviceResponse>(url: string, config?: CoxHttpConfig): Promise<T> {
        if ((!this.cbHelper.isUserAuthenticated() || this.cbHelper.isCbmaAuthTokenExpired())
        && ((config && !config.isUnAuthenticatedUrl) || !config) ) {
            throw Error(this._unauthErr);
        }
        let existingRequest = this.getActiveRequest<T>(url, 'DELETE', '');
        const conf = config ? this.getConfigObj(config) : this._defaultConfig;
        const id = uuid();
        if (!existingRequest) {
            this.errorMessageService.errorMessage.show = false;
            existingRequest = this.httpClient.delete<T>(url, { headers: conf.headers ? conf.headers : this.headers })
            .pipe(share());
            this.activeRequests[id] = {
                url,
                method: 'DELETE',
                payload: '',
                observable: existingRequest,
            };
        } else {
            console.log('identical requests detected! merging...', url);
        }
        return this.processRequest(existingRequest, id, conf, url);
    }

    private mapSuccess(id: string, conf: CoxHttpConfig) {
        return (response) => {
            this.activeRequests[id] = null;
            return this.handleGenericResponse(response, conf.customeErrorMessage);
        };
    }

    private processRequest<T extends MicroserviceResponse>(
        request: Observable<T>,
        id: string,
        conf: CoxHttpConfig,
        url: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            if (!this.cbHelper.isUserAuthenticated() &&
             this.cbHelper.isCbmaAuthTokenExpired() && !conf.isUnAuthenticatedUrl) {
                reject(this._unauthErr);
                return;
            }
            this.activeRequests[id].subscription = request.subscribe((result) => {
                this.activeRequests[id] = null;
                try {
                    resolve(this.handleGenericResponse(result, conf.customeErrorMessage));
                } catch (e) {
                    this.showError(conf.customeErrorMessage);
                    reject(e);
                }
            }, (err) => {
                this.activeRequests[id] = null;
                this.showError(conf.customeErrorMessage);
                reject(err);
            });
        });
    }

    private getConfigObj(config: CoxHttpConfig): CoxHttpConfig {
        return {
            customeErrorMessage: config.customeErrorMessage !== undefined ?
            config.customeErrorMessage : this._defaultConfig.customeErrorMessage,
            headers: config.headers !== undefined ? config.headers : this.headers,
            isUnAuthenticatedUrl: config.isUnAuthenticatedUrl !== undefined ?
            config.isUnAuthenticatedUrl : this._defaultConfig.isUnAuthenticatedUrl,
        };
    }

    public get headers(): HttpHeaders {
        let result = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'clientid': this.config.getConfig('APIGW')['clientId'],
            'apikey': this.config.getConfig('APIGW')['apiKey'],
        });
        if (this.cbHelper.isUserAuthenticated()) {
            result = result.append('CB_SESSION', this.editUser.getCbUser());
            result = result.append('CBMA_AUTHTOKEN', JSON.parse(this.editUser.getToken()));
        } else {
            result = result.append('CB_SESSION', 'unauthenticateduser');
        }
        this.cbHelper.deleteCookies();
        return result;
    }


    private handleGenericResponse<T extends MicroserviceResponse>(response: T, errorMsg?: string): T {
        // Stop all the API calls running parallelly, when any of the API returns code 11003
        if (response.code === '11003') {
            for (const key in this.activeRequests) {
                if (this.activeRequests[key] && this.activeRequests[key].subscription) {
                    this.activeRequests[key].subscription.unsubscribe();
                }
            }
            console.log('OKTAFLOW - CoxHttpClient Profile Merge InProgress');
            this.router.navigateByUrl('/unauth/logout?profilemergemaintenance=true');
            return;
        }
        if (response.code !== '0') {
            this.showError(errorMsg);
            const err = new CoxMiddlewareException(response.message);
            err.code = response.code;
            err.transactionId = response.transactionId;
            err.warning = response.warning;
            err.resp = response;
            throw err;
        }
        return response;
    }

    private showError(errorMsg: string = this._defaultErrorMsg) {
        if (!errorMsg) { return; }
        this.errorMessageService.errorMessage.show = true;
        this.errorMessageService.errorMessage.type = 'error';
        this.errorMessageService.errorMessage.level = 'global';
        this.errorMessageService.errorMessage.message = errorMsg;
    }

    private getActiveRequest<T extends MicroserviceResponse>(
        url: string, method: string, payload: string): Observable<T> {
            // Commented temporarily for this release 113
        // for (const key in this.activeRequests) {
        //     if (!this.activeRequests.hasOwnProperty(key)) {
        //         continue;
        //     }
        //     const req = this.activeRequests[key];
        //     if (req && req.url === url && req.method === method && req.payload === payload) {
        //         return req.observable;
        //     }
        // }
        return null;
    }
}

export interface MicroserviceResponse {
    transactionId: string;
    code: string;
    message: string;
    warning: string;
}

/**
 * Custom configuration object
 * ```typescript
 * {
 *  customErrorMessage: 'my custom message'
 * //pass custom error message instead of default one, empty string may disable error message
 *  headers: new HttpHeaders() // pass custom http headers to override default
 * }
 * ```
 */
export interface CoxHttpConfig {
    /**
     * Obsolete option
     */
    disableSpinner?: boolean;
    /**
     * pass custom error message, empty string will disable message
     */
    customeErrorMessage?: string;
    /**
     * pass custom HttpHeaders, default headers will be overrided
     */
    headers?: HttpHeaders;
    /**
     * pass custom isUnAuthenticatedUrl, to Modify all API calls that includes /release to /api
     */
    isUnAuthenticatedUrl?: boolean;
}

interface CoxActiveHttpRequests<T extends MicroserviceResponse> {
    [key: string]: CoxActiveHttpRequest<T>;
}

interface CoxActiveHttpRequest<T extends MicroserviceResponse> {
    observable: Observable<T>;
    // tslint:disable-next-line: max-union-size
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    payload: string;
    subscription?: Subscription;
}