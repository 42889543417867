export enum TransformedResult {
    turnedonn = 'ON',
    turnedoff = 'OFF',
    read = 'Read',
    unred = 'Unread',
    flagged = 'Flagged',
    last30days = 'Last 30 days',
    last60days = 'Last 60 days',
    last90days = 'Last 90 days'
}

export enum TextTransformRequest {
    turnedonn = 'turnedonn',
    turnedoff = 'turnedoff',
    read = 'read',
    unred = 'unred',
    flagged = 'flagged',
    last30days = 'last30days',
    last60days = 'last60days',
    last90days = 'last90days'
}
