import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { EquipmentListObj } from 'home/myequipment/myequipment.model';
import { Observable } from 'rxjs-compat/Observable';
import { CoxHttpClient } from '../../services/coxhttpclient.service';
import { DeviceEncryptionInput } from '../models/userprofile/deviceEncryption.model';
import {
  ConnectionStatusEnum, EncryptionImages, EncryptionStatus, EquipmentImageEnum, EquipmentStatus,
  EquipmentStatusImageEnum, EquipmentTypeEnum, ItemNumberEnum, NetAssuranceTopology, TopologyStatusImage,
} from './equipmentcommon.model';
import gql from 'graphql-tag';
import { AppConfig } from 'common-ui-lib';

@Injectable()
export class EquipmentUtilService {
  constructor(
    private readonly coxhttp: CoxHttpClient,
    private readonly apollo: Apollo, private readonly config: AppConfig) {
   }

  public getConnectionStatusImage(status: string, backupWANStatus?: string): string {
    if (status === ConnectionStatusEnum.CONNECTED_VIA_LTE) {
      return EquipmentStatusImageEnum.WARNING;
    } else if (status === ConnectionStatusEnum.NOT_CONNECTED) {
      return EquipmentStatusImageEnum.ERROR;
    } else if (status === ConnectionStatusEnum.CONNECTED &&
      backupWANStatus === ConnectionStatusEnum.NOT_CONNECTED) {
      return EquipmentStatusImageEnum.WARNING;
    } else if (status === ConnectionStatusEnum.CONNECTED) {
      return EquipmentStatusImageEnum.CONFIRMATION;
    } else if (status === ConnectionStatusEnum.UNKNOWN) {
      return EquipmentStatusImageEnum.ERROR;
    } else {
      return EquipmentStatusImageEnum.ERROR;
    }
  }

  public getTopologyStatusImage(deviceStatus: string, backupWANStatus?: string): string {
    if (deviceStatus === ConnectionStatusEnum.CONNECTED_VIA_LTE) {
      return TopologyStatusImage.ICON_YELLOW_WARNING_TRIANGLE;
    } else if (deviceStatus === ConnectionStatusEnum.NOT_CONNECTED || deviceStatus === ConnectionStatusEnum.UNKNOWN) {
      return TopologyStatusImage.ICON_RED_WARNING_TRIANGLE;
    } else if (deviceStatus === ConnectionStatusEnum.CONNECTED &&
      backupWANStatus === ConnectionStatusEnum.NOT_CONNECTED) {
      return TopologyStatusImage.ICON_YELLOW_WARNING_TRIANGLE;
    } else if (deviceStatus === ConnectionStatusEnum.CONNECTED) {
      return TopologyStatusImage.ICON_GREEN_CHECK_MARK;
    }
  }


  public getStatusClass(equipmentStatus: string, backupWANStatus?: string, isTopology?: boolean): string {
    let statusClass = '';
    switch(equipmentStatus) {
      case ConnectionStatusEnum.UNKNOWN:
            statusClass = 'device-unknown';
            break;
      case ConnectionStatusEnum.CONNECTED:
        statusClass = backupWANStatus === ConnectionStatusEnum.NOT_CONNECTED ?
        'device-connectioninterpreted' : 'device-connected';
            break;
      case ConnectionStatusEnum.INTERRUPTED:
            statusClass = 'device-connectioninterpreted';
            break;
      case ConnectionStatusEnum.NOT_CONNECTED:
            statusClass = 'device-notconnected';
            break;
      case ConnectionStatusEnum.CONNECTED_VIA_LTE:
              statusClass = isTopology ? 'device-connectioninterpreted' : 'device-connectedvia-lte';
            break;
      case ConnectionStatusEnum.UNPLUGGED:
            statusClass = 'device-unplugged';
            break;
      case ConnectionStatusEnum.READY:
            statusClass = 'device-connected';
            break;
      default:
            statusClass = 'device-nostatus';
            break;
    }
    return statusClass;
  }

  public getEquipmentImage(equipmentType: string, itemNumber?: string): string {
    switch (equipmentType.toUpperCase()) {
      case EquipmentTypeEnum.EMTA_MODEM.toUpperCase(): return EquipmentImageEnum.EMTA_MODEM;
      case EquipmentTypeEnum.ATA_DEVICE.toUpperCase(): return this.getAtaImageByItemNumber(itemNumber);
      case EquipmentTypeEnum.POE_SWITCH.toUpperCase(): return this.getSwitchImageByItemNumber(itemNumber);
      case EquipmentTypeEnum.ESBC_DEVICE.toUpperCase(): return EquipmentImageEnum.ESBC_DEVICE;
      case EquipmentTypeEnum.CABLE_MODEM.toUpperCase(): return itemNumber === 'DTC4332' ?
      EquipmentImageEnum.Device_4332 : (itemNumber === 'DT4004B')?
      EquipmentImageEnum.CVA4004_CABLE_MODEM : (itemNumber === 'DCL1101') ? EquipmentImageEnum.CALIX_ONT : EquipmentImageEnum.CABLE_MODEM;
      case EquipmentTypeEnum.NOKIA_ONT.toUpperCase(): return EquipmentImageEnum.CABLE_MODEM;
      case EquipmentTypeEnum.CALIX_ONT.toUpperCase(): return EquipmentImageEnum.CALIX_ONT;
      case EquipmentTypeEnum.GATEWAY.toUpperCase(): return itemNumber === 'DTC4332' ?
      EquipmentImageEnum.Device_4332 : EquipmentImageEnum.CABLE_MODEM;
      case EquipmentTypeEnum.LTE_ROUTER.toUpperCase(): return EquipmentImageEnum.LTE_ROUTER;
      case EquipmentTypeEnum.SET_TOP_BOX.toUpperCase(): return EquipmentImageEnum.SET_TOP_BOX;
      case EquipmentTypeEnum.IAD_DEVICE.toUpperCase(): return EquipmentImageEnum.IAD_DEVICE;

    }
  }

  public getEquipmentLockImage(editable: string, encrypted?: boolean): string {
    if (!editable) {
      return EncryptionImages.DisabledLock;
    } else {
      return encrypted ? EncryptionImages.Lock : EncryptionImages.Unlock;
    }
  }
  public getLockImageAltText(editable: string, encrypted?: boolean): string {
    if (!editable) {
      return EncryptionStatus.EncryptionUnavailable;
    } else {
      return encrypted ? EncryptionStatus.EncryptionActive : EncryptionStatus.EncryptionInactive;
    }
  }
  public getAtaImageByItemNumber(itemNumber: string): string {
    switch (itemNumber) {
      case ItemNumberEnum.CISCO_SPA_122: return EquipmentImageEnum.ATA_DEVICE_122;
      case ItemNumberEnum.POLY_OBI302: return EquipmentImageEnum.ATA_POLY_OBI;
      case ItemNumberEnum.POLYCOM_ROVE_R8: return EquipmentImageEnum.POLYCOM_ROVE_R8;
      default: return EquipmentImageEnum.ATA_DEVICE;
    }
  }

  //Net Assurance Topology Network image Selection
  public getTopologyImage(linkedNetAssuranceDevices: number, otherEquipmentsList: EquipmentListObj[]) {
    console.log('Topology 8 ', linkedNetAssuranceDevices);
    if (linkedNetAssuranceDevices === 2) {
      if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.UNPLUGGED &&
        (otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED ||
          otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED_VIA_LTE)) {
        return NetAssuranceTopology.TWO_DEVICES_8A_8NC;
      } else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.UNPLUGGED &&
        otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED) {
        return NetAssuranceTopology.TWO_DEVICES_7_7NC_9_9NC;
      } else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.NOT_CONNECTED &&
        otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED) {
        return NetAssuranceTopology.TWO_DEVICES_4_6;
      } else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.NOT_CONNECTED &&
        (otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED ||
          otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED_VIA_LTE)) {
        return NetAssuranceTopology.TWO_DEVICES_RED_GREEN;
      }  else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.CONNECTED &&
        (otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED ||
          otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED_VIA_LTE)) {
        return NetAssuranceTopology.TWO_DEVICES_1_1A_2_3_3A_5_5A;
      }
    } else if (linkedNetAssuranceDevices > 2) {
      console.log('linkedDevices', linkedNetAssuranceDevices);
      if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.UNPLUGGED &&
        (otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED ||
          otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED_VIA_LTE) &&
        otherEquipmentsList[2].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED) {
        return NetAssuranceTopology.THREE_DEVICES_8A_8NC;
      } else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.UNPLUGGED &&
        otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED &&
        otherEquipmentsList[2].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED) {
        return NetAssuranceTopology.THREE_DEVICES_7_7NC_9_9NC;
      } else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.NOT_CONNECTED &&
        otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED &&
        otherEquipmentsList[2].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED) {
        return NetAssuranceTopology.THREE_DEVICES_4_6;
      } else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.NOT_CONNECTED &&
        (otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED ||
          otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED_VIA_LTE) &&
        otherEquipmentsList[2].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED) {
        console.log(NetAssuranceTopology.THREE_DEVICES_RED_GREEN_RED);
        return NetAssuranceTopology.THREE_DEVICES_RED_GREEN_RED;
      } else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.CONNECTED &&
        (otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED ||
          otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED_VIA_LTE) &&
        otherEquipmentsList[2].equipmentStatus === ConnectionStatusEnum.NOT_CONNECTED) {
        return NetAssuranceTopology.THREE_DEVICES_1A_2_3A_5_5A;
      } else if (otherEquipmentsList[1].primaryWANStatus === ConnectionStatusEnum.CONNECTED &&
        (otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED ||
          otherEquipmentsList[1].equipmentStatus === ConnectionStatusEnum.CONNECTED_VIA_LTE) &&
        otherEquipmentsList[2].equipmentStatus === ConnectionStatusEnum.CONNECTED) {
        return NetAssuranceTopology.THREE_DEVICES_1_3;
      }
    } else {
      return '';
    }
  }

  public getConnectionStatusByKey(key: string, rebootStatusFlag: boolean = false): Observable<EquipmentStatus> {
    const requestBody = { encryptedValue: key };
    const url = '/api/cbma/accountequipment/services/accountequipment/v1/gatewaydevice/equipmentstatus';
    return this.coxhttp.post(rebootStatusFlag ? url + '?rebootStatusFlag=true' : url,
      requestBody);
  }

  public updateDeviceEncryption(input: DeviceEncryptionInput) {
    const updateEquipmentEncryption = gql`
        mutation updateEquipmentEncryption($input: DeviceEncryptionInput!) {
          updatePhoneEquipmentEncryption(input: $input) {
            updateEncryptionResponse {
                  id
                  code
                  message
                  transactionId
                  encryptionPermission
	              encrypted
	             editable
	             encryptionValid
              }
          }
        }
        `;
    return this.apollo.mutate({
      mutation: updateEquipmentEncryption,
      variables: {
        input
      }
    });
  };

  private getSwitchImageByItemNumber(itemNumber: string): string {
    switch (itemNumber) {
      case ItemNumberEnum.CBS350_24P_4G_NA: return EquipmentImageEnum.CBS350_24P_4G_NA;
      case ItemNumberEnum.CBS350_24FP_4G_NA: return EquipmentImageEnum.CBS350_24FP_4G_NA;
      case ItemNumberEnum.CBS350_48P_4G_NA: return EquipmentImageEnum.CBS350_48P_4G_NA;
      case ItemNumberEnum.CBS350_48FP_4G_NA: return EquipmentImageEnum.CBS350_48FP_4G_NA;
      case ItemNumberEnum.CBS350_8S_E_2G_NA: return EquipmentImageEnum.CBS350_8S_E_2G_NA;
      case ItemNumberEnum.CBS350_8FP_2G_NA: return EquipmentImageEnum.CBS350_8FP_2G_NA;
      default: return EquipmentImageEnum.POE_SWITCH;
    }
  }
}
