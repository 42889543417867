import { AfterViewChecked, Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AEMClientService, AppConfig, CBHelperService, CommonMessageService, DeviceDetectorService,
  DigitalProfileSharedService,
  ResourceBundle,
  StringUtilService
} from 'common-ui-lib';
import { filter, takeUntil } from 'rxjs/operators';
import { search } from 'assets/scripts/search.js';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CbHeaderService } from './cb-header.service';
import { HeaderLinks, MenuOption, Profile, TicketDTO } from './cb-header.model';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-cb-header',
  templateUrl: './cb-header.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./cb-header.component.scss']
})

export class CbHeaderComponent implements OnInit, AfterViewChecked {
  @ViewChild('searchPopover') searchPopover: NgbPopover;
  @ViewChild('cbEmailPopover') cbEmailPopover: NgbPopover;
  @ViewChild('vmPopover') vmPopover: NgbPopover;
  @ViewChild('supportPopover') supportPopover: NgbPopover;
  @ViewChild('alertsPopover') alertsPopover: NgbPopover;
  @ViewChild('megaNavPopover') megaNavPopover: NgbPopover;
  public showHeaderBundle = true;
  public topHeaderBundle: string;
  public resourceBundle: ResourceBundle = {};
  public isMobileDevice = false;
  public isTabletDevice = false;
  public isPageZoomed = false;
  public showExternalSearchImg: boolean;
  public isSearchDropdownOpen = false;
  public popoverPlacement = 'bottom';
  public appLoaded = false;
  public isOpen = true;
  public skipToMainContentLink: string;
  public menuOptions: MenuOption;
  public headerTicketList: TicketDTO;
  public alerts: Profile;
  public headerLinks = HeaderLinks;
  public voiceMailCount: string;
  public showDropdown = false;
  private searchField: HTMLElement;
  private getProfileSubscription: Subscription;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    public cbHelper: CBHelperService,
    public digitalProfileService: DigitalProfileSharedService,
    public deviceService: DeviceDetectorService,
    public cbHeaderService: CbHeaderService,
    public stringUtilService: StringUtilService,
    private config: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly aemClient: AEMClientService,
    private readonly commonMessageService: CommonMessageService,
  ) {
    this.showHeaderBundle = (this.router.url.indexOf('unauth/login') > -1) ? false : true;
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.cbHelper.isProfileInContext() && (!this.router.url.includes('/unauth/logout')
        && !this.router.url.includes('/unauth/login') && !this.cbHelper.isOBUrl() &&
        !this.router.url.includes('/unauth/myadminlogout') && !this.router.url.includes('/implicit/callback')
        && !this.router.url.includes('/postlogin'))) {
        this.cbHeaderService.setUserDetails(null);
        this.cbHeaderService.setProfileDetails(null);
        if (this.cbHelper.getLoggedInUserDetails()?.profile?.userToken?.profileStatus !== 'REGISTERED') {
          this.getUserDetails();
        }
        if (this.cbHelper.getMarketRollOut) {
          const profileGuid = this.cbHelper.getLoggedInUserDetails()?.profile?.userToken?.profileGuid;
          this.getDigitalProfileDetails(profileGuid);
        } else {
          this.getProfileDetails();
        }
      }
      this.cbHelper.initiateHeaderRefreshSub.subscribe((data: boolean) => {
        if (data) {
          this.appLoaded = true;
        }
      });
      this.skipToMainContentLink = '';
      this.getSkipLink(window.location.href);
      if (!(this.router.url.includes('/implicit/callback') || this.router.url.includes('postlogin') ||
        this.router.url.includes('/unauth/login') || this.router.url.includes('/unauth/logout')
        || this.router.url.includes('/unauth/myadminlogout')) && this.cbHelper.isUserAuthenticated()) {
        this.getHeaderMenuList();
        this.appLoaded = true;
      } else {
        this.appLoaded = false;
      }
    });
  }

  // Close dropdown on click of outside
  @HostListener('window:click', ['$event'])
  @HostListener('window:keydown.enter', ['$event'])
  public onMouseClick(event) {
    if (($(event.target).hasClass('searchIcon') || $(event.target).hasClass('searchIconText')
      || $(event.target).hasClass('search-bar') || $(event.target).hasClass('searchBtnIcon'))) {
      if (!this.isSearchDropdownOpen) {
        this.isSearchDropdownOpen = true;
        document.getElementsByClassName('yxt-SearchBar-form')[0]?.
          setAttribute('id', 'header-search-form');
        if (this.isMobileDevice) {
          document.getElementsByClassName('search-wrapper')[0]?.classList.add('slide-left');
        }
        this.searchOnInput();
      } else {
        this.isSearchDropdownOpen = false;
        document.getElementById('search-field')?.classList.add('yxt-SearchBar-close');
        if (this.isMobileDevice) {
          document.getElementsByClassName('search-wrapper')[0]?.classList.remove('slide-left');
        }
        this.showExternalSearchImg = true;
        document.getElementById('search-field')?.classList.add('hide');
        document.getElementById('clear-button')?.classList.add('hide');
        document.getElementById('search-button')?.classList.add('hide');
        if (document.querySelector('.yxt-SearchBar-input')) {
          this.clearSearch(event);
        }
      }
    } else if ($(event.target).hasClass('yxt-SearchBar-input') ||
      $(event.target).hasClass('yxt-SearchBar-button')) {
      this.isSearchDropdownOpen = true;
      document.getElementById('search-field')?.classList.remove('hide');
      document.getElementById('search-field')?.classList.remove('yxt-SearchBar-close');
      document.getElementById('search-button')?.classList.remove('hide');
      document.getElementsByClassName('yxt-SearchBar-form')[0]?.
        setAttribute('id', 'header-search-form');
    } else if (!($(event.target).hasClass('searchIcon') || $(event.target).hasClass('search-container') ||
      $(event.target).hasClass('searchIconText') || $(event.target).hasClass('search-bar') ||
      $(event.target).hasClass('yxt-SearchBar-container') ||
      $(event.target).hasClass('search-wrapper') || $(event.target).hasClass('yxt-SearchBar-wrapper'))) {
      this.isSearchDropdownOpen = false;
      this.showDropdown = false;
      this.showExternalSearchImg = true;
      if (document.querySelector('.yxt-SearchBar-input')) {
        this.clearSearch(event);
      }
      if (this.isMobileDevice) {
        document.getElementsByClassName('search-wrapper')[0]?.classList.remove('slide-left');
        setTimeout(() => {
          document.getElementById('search-field')?.classList.add('yxt-SearchBar-close');
          document.getElementById('search-field')?.classList.add('hide');
          document.getElementById('search-button')?.classList.add('hide');
        }, 500);
      } else {
        document.getElementById('search-field')?.classList.add('yxt-SearchBar-close');
        document.getElementById('search-field')?.classList.add('hide');
        document.getElementById('search-button')?.classList.add('hide');
      }
    }
    if (!document.getElementById('search-field')?.classList.contains('hide') &&
      document.getElementsByClassName('search-wrapper')[0] &&
      this.cbHelper.isUserAuthenticated() && (!this.cbHelper.isMyAdmin() || this.cbHelper.isProfileInContext())) {
      document.getElementsByClassName('cox-header')[0]?.classList.add('search-overlap');
    } else {
      document.getElementsByClassName('cox-header')[0]?.classList.remove('search-overlap');
    }
  }

  ngOnInit() {
    if (this.router.url.includes('/implicit/callback') || this.router.url.includes('postlogin') ||
      this.router.url.includes('/unauth/login') || this.router.url.includes('/unauth/logout')
      || this.router.url.includes('/unauth/myadminlogout')) {
      this.appLoaded = false;
    } else {
      this.appLoaded = true;
    }
    this.aemClient.getTopHeaderHTMLContent('topHeaderContent', 'app').subscribe((data: string) => {
      this.topHeaderBundle = data;
    },
      (error) => {
        console.error(error);
      });
    this.aemClient.getSharedBundle('cbheader/cbheader')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: ResourceBundle) => {
          this.resourceBundle = data;
        }
      );
    this.isMobileDevice = this.deviceService.isMobile();
    this.isTabletDevice = this.deviceService.isTablet();
    this.isPageZoomed = this.deviceService.isPageZoomed() || this.deviceService.isPageZoomedto400();
    this.showExternalSearchImg = true;
    this.isSearchDropdownOpen = false;
  }

  ngAfterViewChecked(): void {
    document.getElementsByClassName('webMessengerContainerButtonMode')[0]?.setAttribute('aria-label', 'Chat Window');
  }

  // Digital Profile Query
  public getDigitalProfileDetails(profileGuid: string) {
    if (this.router.url.includes('/portal')) {
      this.digitalProfileService.getDigitalProfileDetails(profileGuid);
    } else {
      this.digitalProfileService.fetchHeaderDetails(profileGuid);
    }
    this.getProfileSubscription = this.digitalProfileService.getDigitalProfileResponse
      .subscribe((response) => {
        if (response) {
          this.headerTicketList = response?.headerTickets;
          this.alerts = response?.headerNotifications;
        }
      });
  }

  // Profile Query
  public getProfileDetails() {
    console.log('OKTAFLOW-DefaultHeaderComponent getOutageAndCBOTDetails');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.cbHeaderService.fetchProfileDetails('ALL').subscribe((results: any) => {
      if (results) {
        this.cbHeaderService.setProfileDetails(results);
        this.getProfileSubscription = this.cbHeaderService.getProfileResponse
          .subscribe((response) => {
            if (response?.data !== null) {
              this.headerTicketList = response?.data?.getProfile?.headerTicketList;
              this.alerts = response?.data?.getProfile;
            }
          });
      }

    });
  }

  public onMenuClick() {
    this.cbHeaderService.showMainMenu = !this.cbHeaderService.showMainMenu;
  }

  // Get User Query
  public getUserDetails() {
    const loggedInUserGuid = this.cbHelper.getLoggedInUserDetails()?.profile?.userToken?.guid;
    this.cbHeaderService.fetchUserDetails(loggedInUserGuid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((results: any) => {
        this.cbHeaderService.setUserDetails(results);
      });
  }

  //Query to get voice mail count
  public getVoiceMailCount() {
    const loggedInUserGuid = this.cbHelper.getLoggedInUserDetails()?.profile?.userToken?.guid;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.cbHeaderService.getVoiceMailCount(loggedInUserGuid).subscribe((results: any) => {
      if (results.data) {
        this.voiceMailCount = results.data?.getUser?.voiceMailCount?.unread;
        this.voiceMailCount = parseInt(this.voiceMailCount, 10) > 99 ? '\'99+\'' : this.voiceMailCount;
      }
      if (results.errors) {
        const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'voiceMailCount');
        if (errorInfo) {
          window.scroll(0, 0);
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMsg, 'error', 'digitalProfile');
        }
      }
    });
  }

  public getHeaderMenuList() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.cbHeaderService.getHeaderOptionList().subscribe((results: any) => {
      if (results?.data?.headerMenu) {
        this.menuOptions = results.data.headerMenu;
      }
      if (this.menuOptions?.voiceMailOption?.enabled && (!this.cbHelper.isMyAdmin())) {
        this.getVoiceMailCount();
      }
      if (results?.data?.headerMenu?.footerOptions) {
        this.cbHeaderService.setFooterDetails(results.data.headerMenu.footerOptions);
      }
    });
  }

  public goToBusinessLink() {
    const subscription = this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.app !== 'webex') {
        window.open(this.config.getConfig('coxdotcom')['cbhomeUrl'], '_blank');
      }
    });
    subscription.unsubscribe();
  }

  public clearSearch(event) {
    if (event.key === 'Enter' || event.button === 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const input: any = document.querySelectorAll('.yxt-SearchBar-input')[0];
      if (input) {
        input.value = '';
      }
    }
  }

  public toggleHeaderLinks(link: string, onInitialLoad?: boolean) {
    document.getElementById('header')?.classList?.add('show');
    document.getElementById('headerLinksClose')?.focus();
    this.cbHeaderService.hamburgerTemplate = link;
    switch (link) {
      case HeaderLinks.ACCOUNT_DROPDOWN:
        this.cbHeaderService.hamburgerHeader = '';
        this.cbHeaderService.headerActiveButton = 'main-menu';
        break;
      case HeaderLinks.MENU_LINKS:
        this.cbHeaderService.hamburgerHeader = this.stringUtilService.formatByPlaceHolder(
          this.resourceBundle?.hamburgerHeader, [this.menuOptions?.firstName]
        );
        this.cbHeaderService.headerActiveButton = 'hamburger-menu';
        break;
      case HeaderLinks.ALERT_LINKS:
      case HeaderLinks.SUPPORT_LINKS:
        this.cbHeaderService.hamburgerHeader = this.resourceBundle?.hamburgerBackHeader;
        document.getElementById('hamburgerBackHeader')?.focus();
        break;
    }
    if (onInitialLoad && this.isMobileDevice) {
      const profileGuid = this.cbHelper.getLoggedInUserDetails()?.profile?.userToken?.profileGuid;
      this.getDigitalProfileDetails(profileGuid);
    }
  }

  public searchOnInput() {
    this.searchField = document.getElementById('search-field');
    if (!document.querySelector('.yxt-SearchBar')) {
      this.loadScripts();
    }
    this.showExternalSearchImg = false;
    document.getElementById('search-field')?.classList.remove('hide');
    document.getElementById('search-button')?.classList.remove('hide');
    document.getElementById('search-field')?.classList.remove('yxt-SearchBar-close');
    const interval = setInterval(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const inputTxtFocus: any = document.getElementsByClassName('yxt-SearchBar-input')[0];
      if (inputTxtFocus) {
        inputTxtFocus.focus();
        inputTxtFocus.value = '';
        clearInterval(interval);
      }
    }, 300);
  }

  public navigateOnClick(path: string, openInNewWindow: boolean) {
    let url;
    if (path?.startsWith('https') || path?.startsWith('http')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      openInNewWindow ? window.open(path) : window.open(path, '_self');
    } else if (path && openInNewWindow) {
      url = `${window.location.origin}/cbma${path}`;
      window.open(url);
    } else {
      const menuPath = path.includes('?') ? `${path}&path=header` : `${path}?path=header`;
      this.router.navigateByUrl(menuPath);
    }
    this.cbHeaderService.closeSideBar();
  }

  public setFocus(event: KeyboardEvent, firsElementId: string, lastElementId: string) {
    setTimeout(() => {
      const firstFocusableEl = document.getElementById(firsElementId);
      const lastFocusableEl = document.getElementById(lastElementId);
      if (!event.shiftKey && event.key === 'Tab') {
        firstFocusableEl.focus();
        event.preventDefault();
      } else if (event.shiftKey && event.key === 'Tab') {
        lastFocusableEl.focus();
        event.preventDefault();
      }
    }, 100);
  }

  private async loadScripts() {
    await this.getScript(this.config.getConfig('answersUrl'));
  }

  private getScript = (url: string) => new Promise<void>((resolve) => {
    const scriptEle = document.createElement('script');
    scriptEle.type = 'text/javascript';
    scriptEle.async = true;
    scriptEle.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(scriptEle);
    scriptEle.src = url;
    scriptEle.onload = search;
    scriptEle.addEventListener('load', () => resolve(), false);
  });

  private getSkipLink(url: string) {
    if (url.indexOf('#mainContent') > -1) {
      const newLink = url.replace('#mainContent', '');
      this.skipToMainContentLink = `${newLink}#mainContent`;
    } else {
      this.skipToMainContentLink = `${url}#mainContent`;
    }
  }
}
