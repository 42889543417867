
<ng-container *ngIf="contentLoaded; else boxSkeleton">
<div class="modal-header modalheader">
    <div class="col-lg-5 col-md-6 col-sm-6 col-xs-6 p-0">
        <h4 class="modal-title" role="alert" attr.aria-label="{{resourceBundle.opensDialog}} {{resourceBundle.modalTitleOrderDelivery}} {{resourceBundle.headingLevel}}">
        {{resourceBundle.modalTitleOrderDelivery}}</h4>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1
     p-0 text-right margin-left-auto">
     <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn" id="closeBtn"
     (click)="modalClose(); selfInstallService.triggerTealium('Close')" appModalfocus></a>
    </div>
</div>
<div id="coxGradientLine" class="gradient-rectangle-header mx-auto"></div>
<div class="modal-body modal-content install-steps-modal-scroll">
    <app-commonmessage [type]="messageService.displayMessage.type" class="d-block margin-top-neg-40 margin-bottom-neg-8px"
      [rowLevelMessage]="messageService.displayMessage.message" [level]="'datatableRow'"
      *ngIf="messageService.displayMessage.show && messageService.displayMessage.id === 'contactResponse'">
    </app-commonmessage>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 margin-bottom-15px">
            <h5 class="bold mt-1">{{resourceBundle.orderDeliveredPostMvpTitle}}</h5>
            <p class="my-3">{{resourceBundle.contactDesc1}}</p>
            <p class="my-3">{{resourceBundle.contactDesc2}}</p>
        </div>
        <!-- Contact Information Section -->
        <div class="mb-2">
            <form [formGroup]="contactInfoForm">
                <div class="row mb-3">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <label for="contactNumber" class="pad-bottom-075em mb-0 asterisk" [ngClass]="{ 'labelRedColor': contactInfoForm?.get('contactNumber').errors && contactInfoForm.get('contactNumber').dirty}">
                            {{resourceBundle?.contactNumber}}
                        </label>
                    </div>
                    <div class="col-md-10 col-sm-12 col-xs-12 margin-md-top-4p">
                        <input id="contactNumber" type="text" formControlName="contactNumber" maxlength="12" minlength="12" (input)="getMobileNumber()" [ngClass]="{'error': 
                            contactInfoForm.get('contactNumber').errors && contactInfoForm.get('contactNumber').dirty}" appPhoneMask>
                            <a id="self-install-terms" href="javascript:void(0)" class="pull-right link linkUnderline mt-1">{{resourceBundle?.terms}} <img src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon" role="presentation" class="width-20px mt-n1"></a>
                            <div *ngIf="contactInfoForm?.get('contactNumber').errors && contactInfoForm.get('contactNumber').dirty"
                            class="pt-2">
                            <span *ngIf="contactInfoForm?.get('contactNumber').errors?.required"
                                class="text-danger form-error">{{resourceBundle?.reqField}}</span>
                            <span *ngIf="contactInfoForm?.get('contactNumber').errors?.pattern || contactInfoForm?.get('contactNumber').errors?.minlength || contactInfoForm?.get('contactNumber').errors?.maxlength"
                                class="text-danger form-error">{{resourceBundle?.mobileNumIncorrectFormat}}</span>
                        </div>

                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <label for="emailId" class="pad-bottom-075em mb-0" [ngClass]="{ 'labelRedColor': contactInfoForm?.get('emailId').errors}">
                            {{resourceBundle?.emailId}}
                        </label>
                    </div>
                    <div class="col-md-10 col-sm-9 col-xs-12 margin-md-top-4p">
                        <input id="emailId" type="text" formControlName="emailId" [ngClass]="{'error': 
                            contactInfoForm.get('emailId').errors && contactInfoForm.get('emailId').dirty}">
                        <div *ngIf="contactInfoForm?.get('emailId').errors"
                            class="pt-2">
                            <span *ngIf="contactInfoForm?.get('emailId').errors?.pattern"
                                class="text-danger form-error">{{resourceBundle?.emailIdIncorrectFormat}}</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>  
        <hr class="hr">
        <div class="col-sm-12 col-md-12 col-xs-12 col-sm-12 display-flex p-0">
            <div class="col-lg-10 col-md-10 col-9 p-0">
                <button type="button" (click)="updateContactInfo(contactInfoForm)" class="button btn-long-text col-lg-8 col-md-8 col-12" [disabled]="isMobileNumberAvailable">
                    {{resourceBundle.next}}</button>
            </div>
            <a href="javascript:void(0)" aria-label="Chat" class="display-flex pl-4 chat-trigger"
                [class]="{'pl-3': isMobileDevice}" (click)="triggerTealium(resourceBundle.getSupport)"><img src="assets/images/blueprint/admin_redesign/chat-question-mark.svg"
                    alt="Chat Icon" />
            </a>
        </div>      
</div>
</ng-container>
<ng-template #boxSkeleton>
    <div class="col-12 justify-content-center my-5">
        <app-skeletonloader formName="Device details" formSkeleton="true" rowCount="4" colCount="1">
        </app-skeletonloader>
    </div>
</ng-template>