import { Injectable } from '@angular/core';
import { CoxHttpClient } from 'common-ui-lib';
import { Apollo, gql } from 'apollo-angular';
import { E911ReminderOptoutRequest, NotificationUserPreferenceInput } from '../managee911tab/managee911tab.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageE911NotificationsService {

  public declineActionSource = new Subject<void>();
  public declineNotify: Observable<void> = this.declineActionSource.asObservable();

  constructor(private apollo: Apollo, private http: CoxHttpClient ) {
}

  public declineAction() {
    this.declineActionSource.next();
  }

  public getOptOutStatus(profileGuid: string) {
    const e911OptOutExpirationStatus = gql`
    query MyQuery($profileGuid: ID!) {
    getDigitalProfile(id: $profileGuid) {
      e911OptOutExpirationStatus {
        e911OptedOut
        optOutDate
        userEmail
        }
      }
    }`;

    return this.apollo.use('digitalProfile').query({
      query: e911OptOutExpirationStatus,
      variables: {
        profileGuid
      },
    });
  }

  public getE911ReceipientDetails(profileGuid: string) {
    const e911ReceipientDetails = gql`
    query MyQuery($profileGuid: ID!) {
    getDigitalProfile(id: $profileGuid) {
      e911ReceipientDetails {
        receipientsList {
          account
          accountGuid
          receipientDetails {
            emailAddress
            mobilePhone
            userGuid
            receipient
            role
          }
          serviceAddress {
            addressLine1
            addressLine2
            city
            houseNumber
            state
            street
            zip
          }
        }
      }  
      }
    }`;

    return this.apollo.use('digitalProfile').query({
      query: e911ReceipientDetails,
      variables: {
        profileGuid
      },
    });
  }


  public updateOptOutStatus(input: E911ReminderOptoutRequest) {
   const updateE911ReminderOptout = gql`
    mutation updateE911ReminderOptout($input: E911ReminderOptoutRequest!) {
      updateE911ReminderOptout(input: $input) {
        transactionId	
        code	
        message	
        warning
        id
       }
     }`;
    return this.apollo.use('digitalProfile').mutate({
      mutation: updateE911ReminderOptout, variables: {
        input
      },
    });
  }

  public updateUserPreferences(filter: string, input: NotificationUserPreferenceInput) {
    const updatePreference = gql`
      mutation updateUserPreferences($filter: String!,$input: NotificationUserPreferenceInput) {
        updateUserPreference(filter: $filter, input: $input) {
          notifications {
          categoryName
          isChildCategory
          isEmailPreference
          parentCategoryName
          notificationLevel
          isSmsPreference
        }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: updatePreference,
      variables: {
        input,
        filter
      }
    });
  }


  public getRecepientDetails(profileGuid: string, accountGuid: string) {
    const getUsersWithE911Permission = gql`
  query MyQuery($profileGuid: ID!,$accountGuid: String!) {
    getDigitalProfile(id: $profileGuid) {
      getUsersWithE911Permission(input: {accountGuid: $accountGuid}) {
        nodes {
          firstName
          guid
          id
          lastName
          login {
            contactValue
            isMobileNumber
          }
          notificationPreferences {
            isEmailEnabled
            isSMSEnabled
          }
        }
      }
    }
  }
`;

    return this.apollo.use('digitalProfile').query({
      query: getUsersWithE911Permission,
      variables: {
        profileGuid,
        accountGuid
      },
    });
  }

}
