import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { NotificationUserPreferenceInput } from './notification-template.model';
import gql from 'graphql-tag';
//import { NotificationUserPreferenceInput } from '../edit-user.model';

@Injectable()

export class NotificationTemplateService {

  public mobileChildCategoryActive: boolean;
  constructor(private apollo: Apollo) { }

  public getNotificationAccounts(userId: string, after: string, before: string,
     first: number, last: number, search: string, type: string) {
    const getNotificationAcountsQuery = gql`
    query getUserDetails($userId: ID!, $after: String, $before: String, $first: Int,
     $last: Int, $search: String, $type: NotificationCategoryEnum){
      getUser(id: $userId){
        notificationCollection(after: $after, before: $before, first: $first,
         last: $last, search: $search, type: $type) {
          edges {
            cursor
            node {
              accountGuid
              accountName
              accountAlias
              accountServiceAddress{
                houseNumber
                street
                city
                zipCode
                state
              }
              isEmailEnabled
              hasAccountEUPABillingUpdatesOptedin
              isSMSEnabled
              notificationCategory
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  `;

    return this.apollo.query({
      query: getNotificationAcountsQuery,
      variables: {
        userId,
        after,
        first,
        last,
        type,
        search,
        before
      }
    });
  }


  public updateUserPreferences(filter: string, input: NotificationUserPreferenceInput) {
    const updatePreference = gql`
      mutation updateUserPreferences($filter: String!,$input: NotificationUserPreferenceInput) {
        updateUserPreference(filter: $filter, input: $input) {
          notifications {
          categoryName
          isChildCategory
          isEmailPreference
          parentCategoryName
          notificationLevel
          isSmsPreference
          path
          displayname
        }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: updatePreference,
      variables: {
        input,
        filter
      }
    });
  }
}
