import { Injectable, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  CBHelperService, CoxHttpClient,
  MicroserviceResponse
} from 'common-ui-lib';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable, Subscription, interval } from 'rxjs';
import { HeaderLinks, Option, UpdatePasscode } from './cb-header.model';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class CbHeaderService {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getUserResponse = new BehaviorSubject<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getProfileResponse = new BehaviorSubject<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getFooterResponse = new BehaviorSubject<any>(null);

  public messagesList: any;
  public showNotifications: boolean;
  public showMainMenu: boolean;
  public isSwapAlert = false;
  public cbccApiError = false;
  public showSupportMenu: boolean;
  public contentLoaded = false;
  public unreadCount: string;
  public alertCheck: boolean;
  public hamburgerHeader: string;
  public showAccountModal = false;
  public optedInInfo: boolean;
  public hamburgerTemplate: string;
  public emailTrialDate: string;
  public checkMultiAccount = false;
  public headerActiveButton = null;

  constructor(
    private apollo: Apollo,
    public cbHelperService: CBHelperService,
    private coxHttpClient: CoxHttpClient,
    private router: Router,
    private sessionStorage: SessionStorageService) { }

  public getHeaderOptionList() {
    const headerOptionsQuery = gql`
        query toGetHeaderOptions {
          headerMenu {
          	firstName
            headerOptions {
              url
              enabled
              id
              name
							displayName
              openInNewWindow
            }
						supportOptions {
              url
              enabled
              name
							displayName
              openInNewWindow
            }
            footerOptions {
              url
              enabled
              name
              displayName
              openInNewWindow
            }
						voiceMailOption {
              url
              enabled
              name
							displayName
              openInNewWindow
            }
						cbEmailOption {
              url
              enabled
              name
							displayName
              openInNewWindow
            }
          }
        }
    `;

    return this.apollo.query({
      query: headerOptionsQuery,
    });
  }

  public fetchUserDetails(id: string) {
    const getUserDetailsQuery = gql`
        query getMessagesList($id: ID!){ 
            getUser(id: $id) {
                id
                firstName
                lastName
                isValidationShown
                isProfileAdmin
                isProfileOwner
                isSPO
                isEndUser
                isSPA
                login {
                    id
                    contactType
                    contactValue
                    isVerified
                }
                contacts {
                    id
                    contactType
                    contactValue
                    isVerified
                    isMobileNumber
                }
                messageNotification(messageLimit: 5, messageStatus: "N") {
                    unreadCount
                    notificationList {
                        time
                        status
                        subject
                        notificationId
                    }
                }
                
            }
        }
    `;

    return this.apollo.query({
      query: getUserDetailsQuery,
      variables: {
        id
      },
    });
  }

  public fetchProfileDetails(accountStatusFilter: string) {
    const profileGuid = this.cbHelperService.getLoggedInUserDetails().profile.userToken.profileGuid;
    const getOutageAlertQuery = gql`
    query getProfile($accountStatusFilter: String!,$profileGuid: String!){  
      getProfile(accountStatusFilter: $accountStatusFilter) {
        deviceInstallDetails {
          selfInstallStatus
          euAcctGuid
        }
        indicatorEvents {
          plannedOutageIndicator
          promotionalOutageIndicator
        }
        unplannedOutageIndicator
        pinpointAlerts {
          indicator
          alertsList {
            action
            message
          }
        }
        headerTicketList {
          ticketsCount
          ticketsList {
            workOrderNumber
            truckRoll
            statusType
            requestType
            id
            createDateTime
          }
        }
        bwGroupId
        bwSpId
        companyName
        guid
        id
        isMultiAccount
        profileStatus(profileGuid: $profileGuid) {
          hasActiveAccount
          hasFormerAccount
        }
        hasServiceAppointment(profileGuid: $profileGuid) {
          accountGuid
          date
          isMultiple
          time
        }
        serviceFeature(featureCategoryName: ALL) {
          cbFeatures {
            userAccountsFlag
            featuresList {
              featureName
              role
            }
          }
          cbServices {
            voiceService
            tvService
            securityService
            networkingService
            internetService
            appService
            subServiceDetails {
              category
              subServiceList {
                subCategory
                featureName
                role
              }
            }
          }
          accountAuthUser
        }
        support {
          noCaas
          cbCare {
            description
            passCode
            title
          }
        }  
      }
    }`;

    return this.apollo.query({
      query: getOutageAlertQuery,
      variables: {
        accountStatusFilter,
        profileGuid
      },
    });
  }

  public getOutageAlert(accountStatusFilter: string) {
    const profileGuid = this.cbHelperService.getLoggedInUserDetails().profile.userToken.profileGuid;
    const getOutageAlertQuery = gql`
        query getProfile($accountStatusFilter: String!,$profileGuid: String!){  
            getProfile(accountStatusFilter: $accountStatusFilter) {
                indicatorEvents {
                    plannedOutageIndicator
                    promotionalOutageIndicator
                }
                unplannedOutageIndicator
                pinpointAlerts {
                    indicator
                    alertsList {
                        action
                        message
                    }
                }
                hasServiceAppointment(profileGuid: $profileGuid) {
                    accountGuid
                    date
                    isMultiple
                    time
                }
                deviceInstallDetails {
                    selfInstallStatus
                    euAcctGuid
                  }
            }
        }`;
    return this.apollo.query({
      query: getOutageAlertQuery,
      variables: {
        accountStatusFilter,
        profileGuid
      },
    });
  }

  public getFttxList() {
    const getFttxQuery = gql`
        query getFttxList {
            listOutages(filter: PROMOTIONAL) {
            accountExternalAttrs {
            serviceAddress {
            city
            houseNumber
            state
            street
            zipCode
            }
        }
            alias
        }
        }
        `;
    return this.apollo.query({
      query: getFttxQuery,
      variables: {
      },
    });
  }

  public fetchCbCareDetails(accountStatusFilter: string) {
    const profileGuid = this.cbHelperService.getLoggedInUserDetails().profile.userToken.profileGuid;
    const fetchCbCareDetailsQuery = gql`
      query getProfile($accountStatusFilter: String!){  
        getProfile(accountStatusFilter: $accountStatusFilter) {
          support {
            cbCare {
              description
              passCode
              title
            }
          }
          
        }
      }`;
    return this.apollo.query({
      query: fetchCbCareDetailsQuery,
      variables: {
        accountStatusFilter
      }
    });
  }

  public getVoiceMailCount(id: string) {
    const voiceMailCountQuery = gql`
    query getVoiceMailCount($id:  ID!) {
      getUser(id: $id) {
        voiceMailCount {
          read
          total
          unread
        }
      }
    }
    `;
    return this.apollo.query({
      query: voiceMailCountQuery,
      variables: {
        id
      },
    });
  }

  public updatePasscode(updatePasscode: UpdatePasscode): Observable<MicroserviceResponse> {
    const updatePassCodeUrl = `/api/cbma/profile/services/profile/customercare/passcode`;
    return this.coxHttpClient.post<MicroserviceResponse>(updatePassCodeUrl, updatePasscode,
      { disableSpinner: true, customeErrorMessage: '' });
  }

  public authorizeCheck(guid: any): Observable<any> {
    const authorizeCheckURL = `/api/cbma/account/services/account/getcbccalert/authorization/${guid}`;
    return this.coxHttpClient.get(authorizeCheckURL);
  }

  public closeSideBar() {
    document.getElementById('header')?.classList?.remove('show');
    document.getElementById(this.headerActiveButton)?.focus();
  }

  public modalScroll() {
    const modalWindow = document.querySelectorAll('ngb-modal-window');
    if (modalWindow && modalWindow.length > 0) {
      modalWindow[0].scrollTop = 0;
    }
  }

  public getUserDetails(): Observable<unknown> {
    return this.getUserResponse?.asObservable();
  }

  public setUserDetails(response: unknown) {
    this.getUserResponse?.next(response);
  }

  public getProfileDetails(): Observable<unknown> {
    return this.getProfileResponse.asObservable();
  }

  public setProfileDetails(response: unknown) {
    this.getProfileResponse.next(response);
  }

  public setFooterDetails(response: Option[]) {
    this.getFooterResponse.next(response);
  }
 
  public getFooterDetails(): Observable<Option[]> {
    return this.getFooterResponse.asObservable();
  }
 
}
