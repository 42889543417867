
import {BroadworksDetails} from './broadworksdetails.model';
import {LoggedInProfileDetails} from './loggedinprofiledetails.model';
import {LoggedInUserDetails} from './loggedinuserdetails.model';

export class UserProfile {
  transactionId: string;
  code: string;
  message: string;
  warning?: any;
  loggedInUserDetails: LoggedInUserDetails;
  loggedInProfileDetails: LoggedInProfileDetails;
  broadworksDetails?: BroadworksDetails;

  // fromJSON is used to convert an serialized version
  // of the User to an instance of the class
  public static fromJSON(json: UserProfileJSON|string): UserProfile {
    if (typeof json === 'string') {
      // if it's a string, parse it first
      return JSON.parse(json, UserProfile.reviver);
    } else {
      // create an instance of the User class
      const user = Object.create(UserProfile.prototype);
      // copy all the fields from the json object
      return Object.assign(user, json);
    }
  }

  // reviver can be passed as the second parameter to JSON.parse
  // to automatically call User.fromJSON on the resulting value.
  static reviver(key: string, value: any): any {
    return key === '' ? UserProfile.fromJSON(value) : value;
  }

  // toJSON is automatically used by JSON.stringify
  toJSON(): UserProfileJSON {
    // copy all fields from `this` to an empty object and return in
    return Object.assign({}, this);
  }
}

export interface UserProfileJSON {
  transactionId: string;
  code: string;
  message: string;
  warning?: any;
  loggedInUserDetails: LoggedInUserDetails;
  loggedInProfileDetails: LoggedInProfileDetails;
  broadworksDetails?: BroadworksDetails;
}
