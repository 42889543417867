/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { CBHelperService, ResourceBundle, StringUtilService } from 'common-ui-lib';
import { ContactMethodType, SelectedContactMethodType, StepNum } from '../reset-password.model';
import { ResetPasswordService } from '../reset-password.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  @Input()
  public resourceBundle: ResourceBundle = {};
  public isMobile: boolean;
  public otp = '';
  public checkOtpMaxLength = false;
  public isMyAdmin = false;
  public tempPassword: string;
  public verifyOtpDesc: string;
  constructor(
    public resetPasswordService: ResetPasswordService,
    private cbHelper: CBHelperService,
    private stringUtilService: StringUtilService
  ) {
    this.isMobile = this.resetPasswordService.isMobile;
    this.isMyAdmin = this.cbHelper.isMyAdmin();
  }

  ngOnInit() {
    this.verifyOtpDesc = this.stringUtilService
      .formatByPlaceHolder(this.resourceBundle.verifyOtpDesc, [this.resetPasswordService.maskedContact]);
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    document.getElementById('closeBtn').focus();
  }

  public async validateOtp() {
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    const encryptedOtp = this.cbHelper.encryptWithSaltandPadding(this.otp);
    try {
      const contactmethod = this.resetPasswordService.generateOtpRequest.contactmethod;
      const contactType = this.resetPasswordService.generateOtpRequest.contacttype;
      if (contactType === ContactMethodType.voice || contactType === ContactMethodType.sms) {
        this.resetPasswordService.selectedContactType = SelectedContactMethodType.otpSmsOrVoice;
      } else if (contactType === ContactMethodType.email) {
        this.resetPasswordService.selectedContactType = SelectedContactMethodType.otpEmail;
      }
      const data =
        await this.resetPasswordService.validateOtp(contactmethod, encryptedOtp, this.resetPasswordService.userEmail);
      const encryptedResult = data?.result;
      const decryptedResult = this.cbHelper.decryptWithSaltandPadding(encryptedResult);
      const values = decryptedResult.split(';');
      const successOrFailure = values[1];
      const emailAddress = values[2];
      if (data.code === '0' && successOrFailure === 'Success' && emailAddress === this.resetPasswordService.userEmail) {
          this.resetPasswordService.currentStep = StepNum.step4;
          this.resetPasswordService.modalHeading = this.resourceBundle.resetPasswordModalTitle;
      } else {
        this.resetPasswordService.verificationMethodsResponse.verificationMethodsList = data.verificationMethodsList;
        this.resetPasswordService.verificationMethodsResponse.code = data.code;
        for (let i = 0; i <= data.verificationMethodsList.length; i++) {
          if (data.verificationMethodsList[i].contactmethod === contactmethod) {
            const checkFinalAttempt = data.verificationMethodsList[i].lastattempt;
            const checkMaxAttempt = data.verificationMethodsList[i].maxattempt;
            const enableContactMethod = data.verificationMethodsList[i].enabled;
            if (checkFinalAttempt) {
              // Error message for last attempt otp
              this.resetPasswordService.resetPwdMsg.showMessage(
                this.resourceBundle.finalAttemptOtpErrMsg + this.resetPasswordService.selectedContactType + '.',
                'warning', 'resetPwdError'
              );
              this.resetPasswordService.modalScroll();
              this.otp = '';
              this.checkOtpMaxLength = false;
              document.getElementById('otp').focus();
              break;
            } else if (!checkFinalAttempt && checkFinalAttempt !== null) {
              // Error message for many attempts otp available
              this.resetPasswordService.resetPwdMsg
                .showMessage(this.resourceBundle.incorrectOtpErrMsg, 'error', 'resetPwdError');
              this.resetPasswordService.modalScroll();
              this.otp = '';
              this.checkOtpMaxLength = false;
              document.getElementById('otp').focus();
              break;
            } else if (!enableContactMethod && checkMaxAttempt === 0 && checkFinalAttempt === null) {
              // Error message for no attempt otp
              this.resetPasswordService.checkNoAttempts = true;
              this.resetPasswordService.displayErrMsg = true;
              this.backToChooseOption();
              break;
            } else {
              this.resetPasswordService.resetPwdMsg
                .showMessage(this.resourceBundle.genericerrorMsg, 'error', 'resetPwdError');
              this.resetPasswordService.modalScroll();
              break;
            }
          }
        }
      }
    } catch (error) {
      this.resetPasswordService.resetPwdMsg.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'resetPwdError');
      this.resetPasswordService.modalScroll();
    }
  }

  public async backToChooseOption() {
    this.resetPasswordService.resetPwdMsg.displayMessage.show = false;
    this.resetPasswordService.currentStep = StepNum.step2;
    this.resetPasswordService.modalHeading = this.resourceBundle.selectContactMethod;
  }

  public async resendOtp() {
    this.resetPasswordService.generateOtp(this.resetPasswordService.generateOtpRequest);
  }

  public enableVerifyOtpBtn(event: KeyboardEvent) {
    if (this.otp && this.otp.match(/\D/g)) {
      this.otp = this.otp.replace(/\D/g, '');
    }
    if (this.otp.length === 6) {
      this.checkOtpMaxLength = true;
    } else {
      this.checkOtpMaxLength = false;
    }
  }
}
