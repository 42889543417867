import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import {
  AccountWidgetService,
  AomsRole,
  AppConfig,
  BaseAccount,
  BaseAccountObject,
  CBHelperService,
  CoxHttpClient,
  DigitalProfileSharedService,
  ErrorMessageService,
  LocalStorageKeys,
  LoginProfileClient,
  Maintenance,
  MaintenanceCategory,
  MaintenanceObject,
  MicroserviceResponse,
  Role,
  SSOService,
  UserType,
} from "common-ui-lib";
import { CBLoaderService } from "common-ui-lib/lib/shared/cbloader/cbloader.service";
import { StartService } from "csradmin/start/start.service";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "ngx-localstorage";
import { NgxPermissionsService, NgxPermissionsObject } from "ngx-permissions";
import { SessionStorageService } from "ngx-webstorage";
import { OnboardingStepsUrl, Account } from "onboarding/onboarding.model";
import { OnboardingService } from "onboarding/onboarding.service";
import { Subject, BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";
import { MarketRollOut, API } from "./postlogin.model";
import { ProfileStatus } from "csradmin/start/start.model";
import { AlignedHierarchy, RegistrationCode } from "customer-registration/customer-registration.model";
@Injectable({
  providedIn: 'root',
})
export class PostLoginService {
  public jsfCookieReady: Subject<void> = new Subject();
  public httpBodyCP: object;
  public decodedUrl: string;
  jwtHelper: JwtHelperService = new JwtHelperService();
  public maintenanceSub = new BehaviorSubject<boolean>(false);
  public ORIGINS: string[] = [];
  public profileAccounts: BaseAccount[];
  private accSite: string;
  private accNumber: string;
  private searchValue: string;
  private searchBy: string;
  constructor(
    public httpClient: HttpClient,
    private spinnerService: CBLoaderService,
    private activatedRouter: ActivatedRoute,
    private http: CoxHttpClient,
    private config: AppConfig,
    private cookieService: CookieService,
    private loginService: LoginProfileClient,
    private sessionStorage: SessionStorageService,
    private _storageService: LocalStorageService,
    public digitalProfile:  DigitalProfileSharedService,
    public onboardingService: OnboardingService,
    private cbHelper: CBHelperService,
    private startService: StartService,
    public errorMessageService: ErrorMessageService,
    // private spinnerService: Ng4LoadingSpinnerService,
    private permissionService: NgxPermissionsService,
    private router: Router,
    private accountWidgetService: AccountWidgetService,
    private ssoService: SSOService
  ) {
    const origin = window.location.origin;
    this.ORIGINS.push(origin);
    this.ORIGINS.push(origin + "/");
  }

  public async redirect(isPostLogin: boolean = false, referrer: string) {
    // console.log("OKTATIME - LoginService profile load start", new Date().getTime());
    await this.loginService.getLoggedInProfile();
    // console.log("OKTATIME - LoginService profile loaded checking for deeplink/hn/core etc..", new Date().getTime());
    const permissions = this.permissionService.getPermissions();

    // checking for deployment in progress
    if (this.isDeploymentInProgress()) {
      if (permissions[Role.IS_CB_CORE_ROLE_ONLY]) {
        await this.proceedRedirect('/standalone/cbcore', isPostLogin);
      } else if (this.isExternalLogin(referrer)) {
        await this.proceedRedirect(referrer, isPostLogin);
      } else {
        this.router.navigate(['/upgrading']);
      }
      return;
    }

    // console.log("OKTAFLOW-LoginService.redirect.referrer", referrer);
    if (this.cbHelper.isMyAdmin()) {
      // console.log("OKTAFLOW-LoginService.proceedRedirect1", referrer);
      // Redirect to AOMS page for AOMS Users isAomsUser
      if (this.cbHelper.getAomsRole() && this.cbHelper.getAomsRole() !== AomsRole.NCOA_Admin) {
        await this.proceedRedirect('/aoms', isPostLogin);
        return;
      }

      // Handling deeplink for MyAdmin
      if (referrer && !this.ORIGINS.includes(referrer) && this.checkTarget(referrer)) {
        await this.handleMyadminDeeplinking();
        this.sessionStorage.store('firstLogin', true);
        return;
      }
      await this.proceedRedirect('/csradmin/start', isPostLogin);
      return;
    }
    // console.log("OKTAFLOW-LoginService.redirect.isMyAdmin.referrer Out", referrer + " : " + referrer);
    await this.setMarketRollOut();
    await this.setScheduleMaintanceDetaisAsync();
    // Handling deeplink for MyAccount
    const currentUser = this.cookieService.get('cbma-current-user');
    if (referrer && !this.ORIGINS.includes(referrer)) {
      const profileCreated = this.sessionStorage.retrieve('profilecreated')
        ? this.sessionStorage.retrieve('profilecreated').toLowerCase()
        : null;
      if (referrer.includes('accountlogin/')) {
        const aguid = referrer.split('/')[1];
        const url: string = await this.proceedToAccountLogin(aguid);
        // console.log("OKTAFLOW-LoginService.proceedRedirect3", url);
        await this.proceedRedirect(url, isPostLogin);
        return;
      } else if (currentUser === this.sessionStorage.retrieve('profilecreated')) {
        this.sessionStorage.clear('profilecreated');
        if (!permissions[Role.PROFILE_HN_FLAG_ROLE]) {
          await this.onboardingService.identifyAccountType();
        } else {
          this.onboardingService.isHNProfile = true;
          this.onboardingService.showOBHeader = false;
          this.sessionStorage.store(this.onboardingService.HN_PROFILE, this.onboardingService.isHNProfile);
        }
        await this.oktaRedirect(referrer, isPostLogin);
        // console.log("OKTAFLOW-LoginService.proceedRedirect2", referrer);
        await this.proceedRedirect(referrer, isPostLogin);
        return;
      } else if (
        this.sessionStorage.retrieve('profilecreated') &&
        currentUser !== this.sessionStorage.retrieve('profilecreated')
      ) {
        this.sessionStorage.clear('profilecreated');
        this.router.navigate(['/home/landing']);
      } else {
        // will enable while going live with domain consolidation
        const absoluteUrl = this.getRelativePath(referrer);
        await this.proceedRedirect(absoluteUrl, isPostLogin);
        this.sessionStorage.store('firstLogin', true);
        return;
      }
    } else if (
      this.sessionStorage.retrieve('resetfactoruser') &&
      currentUser === this.sessionStorage.retrieve('resetfactoruser')
    ) {
      await this.proceedRedirect('/admin/list', isPostLogin);
      return;
    }
    try {
      const abondonedOnboardingData = await this.getAbondonedObData(
        this.cbHelper.getLoggedInUserDetails().profile.userToken.profileOwnerEmail,
        true
      );
      // console.log(
      //   "OKTAFLOW-stepper response",
      //   abondonedOnboardingData.accountNumber16,
      //   abondonedOnboardingData.stepName
      // );
      if (abondonedOnboardingData.accountNumber16 !== null && abondonedOnboardingData.stepName !== null) {
        await this.processAbondonedFlow(abondonedOnboardingData, isPostLogin);
        return;
      }
    } catch (e) { }
    return new Promise<void>(async (resolve, reject) => {
      this.sessionStorage.clear('resetfactoruser');
      await this.proceedRedirect(await this.getRedirectUrl(permissions), isPostLogin);
      // console.log("OKTAFLOW-LoginService.redirect_end");
      resolve();
    });
  }

  public async setMarketRollOut() {
    return new Promise((resolve, reject) => {
      this.cbHelper.clearMarketRollOut();
        this.http.getAsync<MarketRollOut>(API.ELIGIBILTY_API).then((response) => {
          if (response.testMarket) {
            this._storageService.set(LocalStorageKeys.MarketRollOut, 'true');
          } else {
            this._storageService.set(LocalStorageKeys.MarketRollOut, 'false');
          }
          const encryptedConsolidatedFlag = this.cbHelper.encryptByCrypto(response?.mmconsolidated?.toString());
          this._storageService.set(LocalStorageKeys.IsConsolidated, encryptedConsolidatedFlag);
          this.digitalProfile.isConsolidated = response?.mmconsolidated;
          resolve(response);
        }).catch((error) => {
          this._storageService.set(LocalStorageKeys.MarketRollOut, 'false');
          throw error;
          reject(error);
        });
    });
  }

  private getRelativePath(absoluteUrl: string): string {
    if (absoluteUrl.includes('/cbma/')) {
      return absoluteUrl.split('/cbma')[1];
    } else if (absoluteUrl.includes('cox.com')) {
      return absoluteUrl.split('cox.com')[1];
    } else if (absoluteUrl.includes('8080')) {
      return absoluteUrl.split('8080')[1];
    } else {
      return absoluteUrl;
    }
  }
  public async getAbondonedObData(isProfileOwnerEmail: string, isAbabandon: boolean): Promise<AbondonedDataResponse> {
    const stepdetailUrl = '/api/cbma/account/services/account/v1/onboarding/stepdetails';
    const requestBody = {
      id: null,
      profileOwnerEmail: isProfileOwnerEmail,
      obabandon: isAbabandon,
    };
    return this.http.postAsync<AbondonedDataResponse>(stepdetailUrl, requestBody);
  }

  public async proceedToAccountLogin(aguid: string): Promise<string> {
    return new Promise<string>(async (resolve) => {
      const baseAccountRes = await this.onboardingService.getBaseAccountFromAguid(aguid);
      // console.log("OKTAFLOW-baseAccountRes", baseAccountRes);
      this.onboardingService.accountFlow = 'accountlogin';
      this.sessionStorage.store(this.onboardingService.ACCOUNT_FLOW, 'accountlogin');
      if (baseAccountRes && baseAccountRes.baseAccount && baseAccountRes.baseAccount.accountNumber12) {
        await this.accountLoginFlow(baseAccountRes);
        resolve('/onboarding/addnewuser?cust=accountlogin');
      }
    });
  }
  private async accountLoginFlow(baseAccountRes: Account) {
    this.onboardingService.accountNumber16 = baseAccountRes.baseAccount.accountNumber16;
    this.sessionStorage.store(this.onboardingService.ICOMS_ACCOUNT_NUMBER, baseAccountRes.baseAccount.accountNumber16);
    if (!this.cbHelper.getLoggedInUserDetails().profile.roles.includes(Role.PROFILE_HN_FLAG_ROLE)) {
      await this.onboardingService.identifyAccountType(true);
    } else {
      this.onboardingService.isHNProfile = true;
      this.sessionStorage.store(this.onboardingService.HN_PROFILE, this.onboardingService.isHNProfile);
    }
  }
  public clearOBSessionVars() {
    this.sessionStorage.clear('accountNumber16');
    this.sessionStorage.clear('accountFlow');
    this.sessionStorage.clear('voiceAccount');
    this.sessionStorage.clear('internetAccount');
    this.sessionStorage.clear('voiceMailAccount');
    this.sessionStorage.clear('voiceCallForwardAccount');
    this.sessionStorage.clear('hasVoiceReadableRole');
    this.sessionStorage.clear('assignedPhone');
    this.sessionStorage.clear('accServiceCodes');
    this.sessionStorage.clear('isHNProfile');
  }
  public async checkTarget(referrer: string): Promise<boolean> {
    this.accSite = this.accNumber = this.searchBy = this.searchValue = '';
    if (referrer && referrer.indexOf('?') > -1) {
      const urlParts = referrer.split('?');
      this.decodedUrl = urlParts[0];
      // console.log("OKTAFLOW-URLs", this.decodedUrl, referrer);
      if (this.isValidDeeplinlkUrl(this.decodedUrl)) {
        const hasDeeplinkParams = await this.extractQueryParams(urlParts[1]);
        if (hasDeeplinkParams) {
          // console.log("OKTAFLOW-checkTarget = ", this.searchBy, this.searchValue);
          return true;
        }
      }
    }
    // console.log("OKTAFLOW-checkTarget=false");
    return false;
  }
  private isValidDeeplinlkUrl(decodedUrl: string) {
    const myAdminDeeplinkUrls = this.config.getConfig('myAdminDeeplinkUrls')
      ? this.config.getConfig('myAdminDeeplinkUrls').split(',')
      : [];
    for (const url of myAdminDeeplinkUrls) {
      if (decodedUrl.includes(url)) {
        return true;
      }
    }
    return false;
  }

  // To extract query qarams from the referrer URL and assign to global varriables
  private extractQueryParams(paramsString: string): boolean {
    const params = paramsString.split('&');
    for (const param of params) {
      if (param.includes(SearchParams.SITE_ID)) {
        this.accSite = param.split('=')[1];
      } else if (param.includes(SearchParams.ACCOUNT_NUMBER)) {
        this.accNumber = param.split('=')[1];
      } else if (param.includes(SearchParams.PHONE_NUMBER)) {
        this.searchBy = SearchKeys.PHONE_NUMBER;
        this.searchValue = param.split('=')[1];
        return true;
      } else if (param.includes(SearchParams.ACCOUNT_ALIAS)) {
        this.searchBy = SearchKeys.ACCOUNT_ALIAS;
        this.searchValue = param.split('=')[1];
        return true;
      } else if (param.includes(SearchParams.ACCOUNT_NAME)) {
        this.searchBy = SearchKeys.ACCOUNT_NAME;
        this.searchValue = param.split('=')[1];
        return true;
      } else if (param.includes(SearchParams.EMAIL)) {
        this.searchBy = SearchKeys.EMAIL;
        this.searchValue = param.split('=')[1];
        return true;
      } else if (param.includes(SearchParams.ORDER_NUMBER)) {
        this.searchBy = SearchKeys.ORDER_NUMBER;
        this.searchValue = param.split("=")[1];
        return true;
      }
    }
    if (this.accSite && this.accNumber) {
      this.searchBy = 'AccountNumber';
      this.searchValue = this.accSite + this.accNumber;
      return true;
    }
    return false;
  }

  // Handling deeplinking for myadmin
  public async handleMyadminDeeplinking() {
    try {
      await this.getAccountList();
      this.setScheduleMaintanceDetais();
      await this.setMarketRollOut();
      await this.proceedRedirect(this.decodedUrl, true);
      return;
    } catch {
      await this.proceedRedirect('/csradmin/start?noresult=true', true);
      return;
    }
  }
  // To Search for profiles by query parms
  private async getAccountList(): Promise<void> {
    if (this.searchBy !== SearchKeys.EMAIL && this.searchBy !== SearchKeys.ORDER_NUMBER) {
      // console.log("OKTAFLOW-getAccountList-goto searchAccounts");
      await this.searchAccounts();
    } else if (this.searchBy === SearchKeys.EMAIL) {
      // console.log("OKTAFLOW-getAccountList-goto searchUsers");
      await this.searchUsers();
    } else if(this.searchBy === SearchKeys.ORDER_NUMBER){
      await this.searchByOrder();
    }
  }

  // tslint:disable-next-line: cognitive-complexity
  private async searchAccounts(): Promise<void> {
    const profileEmailOrGuidFlag = this.searchBy !== SearchKeys.EMAIL;
    return new Promise<void>((resolve, reject) => {
      this.startService
        .getAccountSearchResultList(this.searchBy, this.searchValue)
        .pipe(
          finalize(() => {
            //this.spinnerService.hide();
          })
        )
        .subscribe(
          async (data) => {
            if (data) {
              if (data.accountList.length === 1) {
                try {
                  this.sessionStorage.store('selectedCbForceAccount', JSON.stringify(data.accountList[0]));
                  await this.loadProfile(
                    profileEmailOrGuidFlag ? data.accountList[0].profileGuid : data.accountList[0].profileOwnerEmail,
                    this.searchBy
                  );
                  this.setDecodedUrl();
                  resolve();
                } catch (err) {
                  // console.log("OKTAFLOW-searchAccounts loadProfile failed", err);
                  reject(err);
                }
              } else if (data.accountList.length === 0) {
                // console.log("OKTAFLOW-searchAccounts NO_RESULT");
                await this.redirectNoResult();
              } else if (data.accountList.length > 1) {
                // console.log("OKTAFLOW-searchAccounts MORE_THAN_ONE_RESULT");
                await this.redirectMultipleResult();
              }
            } else {
              throw Error('NO_RESPONSE');
            }
          },
          (error) => {
            // console.log("OKTAFLOW-getAccountList searchAccounts failed", error);
            reject(error);
          }
        );
    });
  }

  private setDecodedUrl(): void {
    this.decodedUrl = this.decodedUrl?.includes('managecpniprofile')
      ? this.decodedUrl + '?accountNumber=' + this.searchValue
      : this.decodedUrl;
  }

  // tslint:disable-next-line: cognitive-complexity
  private async searchUsers(): Promise<void> {
    const profileEmailOrGuidFlag = this.searchBy === SearchKeys.EMAIL;
    return new Promise<void>((resolve, reject) => {
      this.startService
        .getUserSearchResultList(this.searchBy, this.searchValue)
        .pipe(
          finalize(() => {
            //this.spinnerService.hide();
          })
        )
        .subscribe(
          async (data) => {
            if (data) {
              if (data.userList.length === 1) {
                try {
                  await this.loadProfile(
                    profileEmailOrGuidFlag ? data.userList[0].profileGuid : data.userList[0].profileOwnerEmail,
                    this.searchBy
                  );
                  resolve();
                } catch (err) {
                  // console.log("OKTAFLOW-searchUsers loadProfile failed", err);
                  reject(err);
                }
              } else if (data.userList.length === 0) {
                // console.log("OKTAFLOW-searchUsers NO_RESULT");
                await this.redirectNoResult();
              } else if (data.userList.length > 1) {
                // console.log("OKTAFLOW-searchUsers MORE_THAN_ONE_RESULT");
                await this.redirectMultipleResult();
              }
            } else {
              throw Error('NO_RESPONSE');
            }
          },
          (error) => {
            // console.log("OKTAFLOW-getAccountList error2", error);
          }
        );
    });
  }
  private async searchByOrder(): Promise<void> {
    const profileEmailOrGuidFlag = this.searchBy === SearchKeys.ORDER_NUMBER;
    return new Promise<void>((resolve, reject) => {
      this.startService
        .getOrderSearchResultList(this.searchValue).subscribe(
          async (data) => {
            if (data) {
              if (data.userList.length === 1 &&
                data?.userList[0]?.profileStatus !== ProfileStatus.SHELL && data?.userList[0]?.profileHasopenOrders) {
                try {
                  await this.loadProfile(
                    profileEmailOrGuidFlag ? data.userList[0].profileGuid : data.userList[0].profileOwnerEmail,
                    this.searchBy
                  );
                  resolve();
                } catch (err) {
                  reject(err);
                }
              } else if (data.userList.length === 0) {
                await this.redirectNoResult();
              }
            } else {
              throw Error("NO_RESPONSE");
            }
          },
          (error) => {
            throw Error("NO_RESPONSE");
          }
        );
    });
  }
  // Load PO's profile for the searched profile
  private async loadProfile(profileOwnerEmailOrGuid: string, searchBy: string): Promise<void> {
    const masterUserEmail = this.cbHelper.getMasterUserEmail();
    const impersonatorVersionFlag =
      (searchBy === SearchKeys.EMAIL) ||
      (searchBy !== SearchKeys.EMAIL);
    return new Promise<void>((resolve, reject) => {
      this.startService.getLoginProfile(masterUserEmail, profileOwnerEmailOrGuid, impersonatorVersionFlag).subscribe(
        (data) => {
          // console.log("OKTAFLOW-loadProfile", profileOwnerEmailOrGuid, masterUserEmail, data);
          resolve();
        },
        (error) => {
          reject(error);
          // console.log("OKTAFLOW-LOAD_PROFILE_FAILED", error);
          throw Error('LOAD_PROFILE_FAILED');
        }
      );
    });
  }

  // To redirect to start page with query param(If multiple profile found)
  private async redirectMultipleResult(): Promise<void> {
    const redirectUrl =
      '/cbma/csradmin/start?key=' +
      this.searchBy +
      '&value=' +
      this.searchValue +
      '&referrer=' +
      this.decodedUrl;
    await this.proceedRedirect(redirectUrl, true);
  }
  // To redirect to start page(If no profile found)
  private async redirectNoResult(): Promise<void> {
    await this.proceedRedirect('/csradmin/start?noresult=true', true);
  }
  public isExternalLogin(referrer: string): boolean {
    const externalLoginUrls: string[] = this.config.getConfig('externalLoginUrls');
    for (const url of externalLoginUrls) {
      if (referrer.includes(url)) {
        return true;
      }
    }
    return false;
  }
  public isDeploymentInProgress(): boolean {
    return this.config.getConfig('deploymentInProgressFlag')
      ? JSON.parse(this.config.getConfig('deploymentInProgressFlag'))
      : false;
  }

  private async oktaRedirect(referrer: string, isPostLogin: boolean) {
    if (referrer.includes('/onboarding/addnewuser?profilecreated=true')) {
      try {
        const abondonedOnboardingData = await this.getAbondonedObData(
          this.cbHelper.getLoggedInUserDetails().profile.userToken.profileOwnerEmail,
          false
        );
        if (abondonedOnboardingData.accountNumber16 !== null && abondonedOnboardingData.stepName !== null) {
          await this.processAbondonedFlow(abondonedOnboardingData, isPostLogin);
        }
      } catch (e) {
        // console.log(e);
      }
    }
  }
  private async processAbondonedFlow(abondonedOnboardingData: AbondonedDataResponse, isPostLogin: boolean) {
    this.onboardingService.accountNumber16 = abondonedOnboardingData.accountNumber16;
    this.sessionStorage.store(this.onboardingService.ICOMS_ACCOUNT_NUMBER, abondonedOnboardingData.accountNumber16);
    if (!this.cbHelper.getLoggedInUserDetails().profile.roles.includes(Role.PROFILE_HN_FLAG_ROLE)) {
      await this.onboardingService.identifyAccountType(true);
    } else {
      this.onboardingService.isHNProfile = true;
      this.sessionStorage.store(this.onboardingService.HN_PROFILE, this.onboardingService.isHNProfile);
    }
    await this.proceedRedirect(
      OnboardingStepsUrl[abondonedOnboardingData.stepName as keyof typeof OnboardingStepsUrl],
      isPostLogin
    );
  }
  private async getRedirectUrl(roles: NgxPermissionsObject) {
    const landingURL = '/home/landing';
    // console.log("OKTAFLOW-LoginService.getRedirectUrl", roles);
    if (roles[Role.PROFILE_HN_FLAG_ROLE]) {
      return landingURL;
    }
    if (roles[Role.ACTIVATEPROFILE_ROLE]) {
      return landingURL;
    }
    if (roles[Role.ADC_SSO_ROLE]) {
      return landingURL; // replace to correct url
    }
    if (roles[Role.IS_CB_CORE_ROLE_ONLY]) {
      // redirecting to cbcore router to accomodate cblogin call
      return '/standalone/cbcore';
    }
    // console.log("OKTAFLOW-LoginService.getRedirectUrl", landingURL);
    return landingURL;
  }

  // eslint-disable-next-line
  private async proceedRedirect(url: string, isPostLogin: boolean) {
    // console.log("OKTATIME - LoginService proceedRedirect start ", new Date().getTime());
    // console.log("OKTAFLOW-proceedRedirect.Stack", url, isPostLogin);
    const urlWithoutQueryParams = url.split('?')[0];
    if (urlWithoutQueryParams.includes(window.location.origin)) {
      url = url.split(window.location.origin)[1];
    }
    if (url.includes('/onboarding/tour')) {
      url = `/home/landing`;
    }
    const registerContext = this.cbHelper.getCustomerRegistrationDetails();
    const userEmail = await this.cbHelper.getAccessTokenSub();
    if (registerContext?.userEmail?.toLowerCase() === userEmail?.toLowerCase()
      && registerContext?.loggedInUserType === UserType?.PROFILE_OWNER && !registerContext?.existingCustomer) {
      url = '/register/buildteam';
    }
    if (registerContext?.existingCustomer && (registerContext?.hierarchyCode === AlignedHierarchy.ALIGNED_PO_PA||
      registerContext?.hierarchyCode === AlignedHierarchy.ALIGNED_END_USER)) {
      url = '/register/confirmaccounts?customer=existing';
    }
    if (registerContext?.existingCustomer && registerContext?.hierarchyCode === RegistrationCode.NON_ALIGNED_USER) {
      url = '/register/verifyemail?customer=existing';
    }
    this.router.navigateByUrl(url);
    // this.router.navigate([url], { replaceUrl: true });
    this.cbHelper.refreshHeader();
    return;
  }
  private getEncryptedAndEncoadedBase64(token: string): string {
    if (token) {
      // Logs to be removed.
      const encryptedToken = this.cbHelper.encryptByCrypto(token);
      const encodedToken = this.cbHelper.encodeBase64(encryptedToken);
      const decodedToken = this.cbHelper.decodeBase64(encodedToken);
      const decryptedToken = this.cbHelper.decryptByCrypto(decodedToken);
      // console.log(
      //   "token : ",
      //   token + " : " + encryptedToken + " : " + encodedToken + " : " + decodedToken + " : " + decryptedToken
      // );
      return encodedToken;
      // tslint:disable-next-line: no-commented-code
      // return this.cbHelper.encodeBase64(this.cbHelper.encryptByCrypto(token));
    } else {
      return null;
    }
  }
  public updateMaintenanceList(maintenanceList: Maintenance[], profileAccounts: BaseAccount[]): void {
    // console.log("Maintenance List : " + maintenanceList);
    const siteIdList = this.loginService.getSiteIdList(profileAccounts);
    const _maintenanceList: string[] = [];
    for (const maintenance of maintenanceList) {
      if (maintenance.status === 'INPROGRESS' && this.checkSiteId(maintenance.location, siteIdList)) {
        if (maintenance.feature === MaintenanceCategory.ALL) {
          _maintenanceList.push(maintenance.feature);
          break;
        } else {
          const features = maintenance.feature.split(',');
          features.forEach((feature) => {
            if (!_maintenanceList.includes(feature) && !_maintenanceList.includes(MaintenanceCategory.ALL)) {
              _maintenanceList.push(feature);
            }
          });
        }
      }
    }
    this._storageService.set(LocalStorageKeys.Maintenancelist, JSON.stringify(_maintenanceList));
  }
  private checkSiteId(location: string, siteIdList: string[]): boolean {
    let hasMaintenance = false;
    if (location === 'ALL') {
      hasMaintenance = true;
    } else {
      siteIdList.forEach((sitId) => {
        if (location.includes(sitId)) {
          hasMaintenance = true;
        }
      });
    }
    return hasMaintenance;
  }

  public async setScheduleMaintanceDetais(): Promise<void> {
    const maintenanceObj = await this.getscheduledMaintenances();

    if (maintenanceObj && maintenanceObj.activeSchedulesList && maintenanceObj.activeSchedulesList.length > 0) {
        this._storageService.remove(LocalStorageKeys.Maintenancelist);
        this.accountWidgetService.getBaseAccountsList("newticket").subscribe((data: BaseAccountObject) => {
          this.profileAccounts = data.baseAccounts;
          this.updateMaintenanceList(maintenanceObj.activeSchedulesList, data.baseAccounts);
        });
    }
  }

  // Handling deeplinking logic for MyAccount
  public async setScheduleMaintanceDetaisAsync(): Promise<void> {
     // console.log("OKTATIME - LoginService check for ScheduleMaintance Async start", new Date().getTime());
     const maintenanceObj = await this.getscheduledMaintenances();
     // console.log("OKTATIME - LoginService check for ScheduleMaintance Async API end", new Date().getTime());

    if (maintenanceObj && maintenanceObj.activeSchedulesList && maintenanceObj.activeSchedulesList.length > 0) {
      if (this.profileAccounts && this.profileAccounts.length !== 0) {
        this.updateMaintenanceList(maintenanceObj.activeSchedulesList, this.profileAccounts);
      } else {
        const data = await this.getBaseAccountsList('newticket');
        // console.log("get Base Account list : ", data);
        this.profileAccounts = data.baseAccounts;
        this.updateMaintenanceList(maintenanceObj.activeSchedulesList, data.baseAccounts);
      }
    }
    // console.log("OKTATIME - LoginService check for ScheduleMaintance end", new Date().getTime());
  }

  // Async API for myaccount deeplinking
  private async getBaseAccountsList(pageId: string): Promise<BaseAccountObject> {
    return this.http.getAsync<BaseAccountObject>('/api/cbma/account/services/account/list/' + pageId);
  }

  public isFeaturesUnderMaintenance(features: string[]): boolean {
    const maintenanceList: string[] = this._storageService.get(LocalStorageKeys.Maintenancelist, 'cbma');

    if (maintenanceList && features && maintenanceList.length > 0 && features.length > 0) {
      if (maintenanceList.includes(MaintenanceCategory.ALL)) {
        return true;
      } else {
        for (const feature of features) {
          if (maintenanceList.includes(feature)) {
            return true;
          }
        }
        return false;
      }
    } else {
      return false;
    }
  }
  public async getscheduledMaintenances(): Promise<MaintenanceObject> {
    return this.http.getAsync<MaintenanceObject>('/api/cbma/user/services/user/activeScheduledEvents/list');
  }

  public async loadAccount(searchBy: string, searchValue: string) {
    this.searchBy = searchBy;
    this.searchValue = searchValue;
    await this.getAccountList();
  }

  public async setCbccCookie(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(this.config.getConfig('cbccCookieUrl'), { withCredentials: true }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    }).catch((error) => {
      throw error;
    });
  }
}

export interface MaintenanceMap {
  feature: string;
  isMaintance: boolean;
}

export interface AbondonedDataResponse extends MicroserviceResponse {
  stepName: string;
  accountNumber16: string;
}

export interface LoginResponse extends MicroserviceResponse {
  voiceStatus: string;
  broadworksDetails: {
    bwGroupId: string;
    bwSpId: string;
    bwSwitchInstance: string;
  };
  loggedInProfileDetails: {
    id: string;
    guid: string;
    name: string;
    status: string;
    // unfinished
  };
  loggedInUserDetails: {
    id: string;
    guid: string;
    userType: string;
    contact: {
      alternatePhoneNumber: string;
      lastName: string;
      phoneNumber: string;
      email: string;
      firstName: string;
      alternateEmail: string;
    };
    // unfinished
  };
}
export const enum SearchParams {
  SITE_ID = "siteId",
  ACCOUNT_NUMBER = "accountNumber",
  PHONE_NUMBER = "phoneNumber",
  ACCOUNT_ALIAS = "accountAlias",
  ACCOUNT_NAME = "accountName",
  EMAIL = "email",
  ORDER_NUMBER = "orderNumber"
}

export const enum SearchKeys {
  ACCOUNT_NUMBER = "AccountNumber",
  PHONE_NUMBER = "PhoneNumber",
  ACCOUNT_ALIAS = "AccountAlias",
  ACCOUNT_NAME = "AccountName",
  EMAIL = "UserEmail",
  ORDER_NUMBER = "OrderNumber"
}
