<div class="mat-tab-header-pagination mat-tab-header-pagination-before mat-elevation-z4" aria-hidden="true"
  [class.mat-tab-header-pagination-disabled]="_disableScrollBefore" (click)="_scrollHeader('before')">
  <div class="mat-tab-header-pagination-chevron"></div>
</div>

<div class="mat-tab-label-container" #tabListContainer (keydown)="_handleKeydown($event)">
  <div class="mat-tab-list" #tabList role="tablist" (cdkObserveContent)="_onContentChanges()">
    <div class="mat-tab-labels">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="mat-tab-header-pagination mat-tab-header-pagination-after mat-elevation-z4" aria-hidden="true"
  [class.mat-tab-header-pagination-disabled]="_disableScrollAfter" (click)="_scrollHeader('after')">
  <div class="mat-tab-header-pagination-chevron"></div>
</div>