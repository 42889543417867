import { MicroserviceResponse } from 'common-ui-lib';

export enum StepNum {
	step1 = 'step1',
    step2 = 'step2',
    step2a = 'step2a',
    step2b = 'step2b',
    step3 = 'step3',
    step4 = 'step4'
}
export interface GetVerificationMethodsResponse extends MicroserviceResponse {
    verificationMethodsList: VerificationMethod[];
    userType: string;
    paCount: number;
}
export interface ValidateOtpResponse extends MicroserviceResponse {
    verificationMethodsList: VerificationMethod[];
    result: string;
}

export interface PwdResetCompleteResponse extends MicroserviceResponse {
    password: string;
}
export interface VerificationMethod {
    lastattempt: boolean;
    maxattempt: number;
    enabled: boolean;
    contactmethod: string;
    contacttype: ContactType[];
}

export interface ContactType {
    type: string;
    value: string;
    maskedvalue: string;
}

export enum ContactMethod {
	otpphone = 'otpphone',
    otpemail = 'otpemail',
    authenticid = 'authenticid'
}

export enum ContactMethodType {
	sms = 'sms',
    voice = 'voice',
    email = 'email',
}

export interface GenerateOtpRequest {
    contactmethod: string;
    contacttype: string;
    userEmail: string;
}

export enum SelectedContactMethodType {
    otpSmsOrVoice = 'Voice call and Text Message',
    otpEmail = 'Email'
}
export interface UpdatePassword{
    id: string;
    oldPassword: string;
    newPassword: string;
    userEmail: string;
}
export interface VerifyAuthenticIdRequest {
    authenticid: string;
    userEmail: string;
}

export interface ValidateUserId {
    userEmail: string;
    recaptchaCode: string;
}

export interface VerifyAuthenticIdResponse extends MicroserviceResponse {
    result: string;
}
