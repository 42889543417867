// TODO This component will be moved to common-ui

import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { OKTA_AUTH } from "common-ui-lib";
import OktaAuth from "@okta/okta-auth-js";
import { PostLoginService } from "boot/postlogin/postlogin.service";
import {
  AppConfig,
  CBHelperService,
  LoginProfileClient,
  Role,
  SSOService,
} from 'common-ui-lib';
import { SSOPageName } from 'common-ui-lib/lib/constants/ssopagename.model';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class CbmaAuthGuard implements CanActivate {
  constructor(
    @Inject(OKTA_AUTH) private okta: OktaAuth,
    private router: Router,
    public cbHelper: CBHelperService,
    private config: AppConfig,
    private ssoService: SSOService,
    private permissionService: NgxPermissionsService,
    private loginProfileClient: LoginProfileClient,
    private loginService: PostLoginService,
    private cookieService: CookieService
  ) {}

  // eslint-disable-next-line
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authenticated = await this.okta.isAuthenticated();
    const hasAllCookies =
      this.cookieService.get(this.config.getConfig("cookies")["currentUser"]) &&
      this.cookieService.get(this.config.getConfig("cookies")["currentUserSessionId"]) &&
      this.cookieService.get(this.config.getConfig("cookies")["oktaSessionId"]);
      const usertype = this.cbHelper.getLoggedInUserDetails()?.profile?.userToken.userType;
    if (authenticated) {
      // Check for deployment status
      if (this.loginService.isDeploymentInProgress()) {
        if (this.loginService.isExternalLogin(state.url)) {
          return true;
        }
        this.router.navigate(['/upgrading']);
        return false;
      }

      if (this.cbHelper.isMyAdmin()) {
        const isDeeplink = await this.loginService.checkTarget(state.url);
        if (isDeeplink) {
          this.loginService.handleMyadminDeeplinking();
          return false;
        }
        //Check and allow urls only if profile is in context
        if (!this.cbHelper.isProfileInContext() && this.myAdminUrlsCheck(state.url)) {
          this.router.navigate(['/errorpage/httperror'], { queryParams: { errorCode: '404' } });
        }
      }

      // Check for cookies and authtoken expiry while routing
      if (!hasAllCookies || this.cbHelper.isCbmaAuthTokenExpired()) {
        console.log('OKTAFLOW-AuthGuard Cookie Not Found');
        this.router.navigate(['/unauth/logout']);
        return false;
      }

      //EU Can't access customercopyright
      if(state.url.includes('customercopyright') && usertype === 'END_USER' && !this.cbHelper.isHNScenario){
        this.router.navigate(['/home/landing'], { queryParams: { unauthorized: 'true' } });
        return false;
      }

      // Continue if cookies are set and authtoken is valid
      console.log('OKTAFLOW-AuthGuard Authenticated');
      const permissions = this.permissionService.getPermissions();
      if (permissions && permissions[Role.IS_CB_CORE_ROLE_ONLY]) {
        this.cbCoreSSOUrl();
      } else if (
        !this.cbHelper.isMyAdmin() &&
        (state.url.includes('/csradmin/') || state.url.includes('/productadministration/'))
      ) {
        this.router.navigate(['/errorpage/autherror']);
        return false;
      }
      return true;
    } else {
      if (state.url === '/?cust=HN') {
        this.router.navigate(['/unauth/login'], { queryParams: { cust: 'HN' } });
        return false;
      }
      console.log('setOriginalUri', state.url);
      this.okta.setOriginalUri(state.url);
      this.router.navigate(['/unauth/login']);
      return false;
    }
  }

  public async cbCoreSSOUrl() {
    try {
      const data = await this.ssoService.getSSOService(SSOPageName.CB_CORE);
      if (data.code === '0') {
        window.location.href = this.config.getConfig('getCbCoreUrl');
      } else {
        // Todo - if login fails redirect to login page and display error message
        this.loginProfileClient.logout();
        return null;
      }
    } catch (error) {
      this.loginProfileClient.logout();
      return null;
    }
  }

  public myAdminUrlsCheck(stateurl: string) {
    let checkUrl = true;
    this.config.getConfig('myAdminUrls').forEach((element) => {
      if (stateurl.includes(element)) {
        checkUrl = false;
      }
    });
    return checkUrl;
  }
}
