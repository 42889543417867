<div class="car-detail-container">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <app-commonmessage [type]="commonMessageService.displayMessage.type"
      [globalMessage]="commonMessageService.displayMessage.message" [level]="'globalPage'"
      *ngIf="commonMessageService.displayMessage.show && commonMessageService.displayMessage.type == 'error' && commonMessageService.displayMessage.id === 'equipmentCardDetail'">
    </app-commonmessage>
  </div>

  <div>
    <img class="mouse-link" id="cardDetailClose" tabindex="0" role="button" (keydown)="cardDetailFocus($event)"
      (click)="onClickOfCloseButton()" (keydown.enter)="onClickOfCloseButton()"
      src="assets/images/blueprint/close-black.svg" alt="Close">
  </div>
  <ng-container *ngIf="contentLoaded; else loadSpinTemplate">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 pr-0 mt-4" [ngClass]="{'card-content-divider': !isMobileDevice}">

      <div *ngIf="!showInput" class="mb-5 equipment-vertical-center">
        <h3 class="h5 mb-0 margin-right-10">{{ equipmentName }}</h3>
        <a class="equipment-edit-link" (click)="onClickOfEdit()" href="javascript:void(0)" role="button"
        attr.aria-label="{{resourceBundle.edit}} {{equipmentName}}">{{resourceBundle.edit}}</a>
      </div>

      <div *ngIf="showInput" class="mb-5 equipment-vertical-center">
        <label class="sr-only" id="equipmentLabel"></label>
        <input appModalfocus type="text" maxlength="35" (keyup)="onChange()" aria-labelledby="equipmentLabel" [(ngModel)]="equipmentName"
          class="mb-0 margin-right-10">
        <a id="saveEquipmentName" class="equipment-save-link" (click)="onSave()" href="javascript:void(0)" role="button"
          [ngClass]="{ 'disabled': !isInputChange || isspace }" [tabindex]="!isInputChange || isspace? -1: 0"
          [attr.aria-hidden]="!isInputChange || isspace">{{resourceBundle.save}}</a>
        <a class="equipment-cancel-link" href="javascript:void(0)" role="button"
          (click)="onClickOfCancel()">{{resourceBundle.cancel}}</a>
      </div>

      <ng-container *ngIf="equipmentType === 'phone'">
        <ng-container *ngIf="isEmtaDeviceType">
          <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.status}}</div>
            <ng-container *ngIf="phoneEquipmentDetailsObj?.status; else equipmentStatusLoader">
              <div class="col-7 pl-0 pr-0 row-text-overflow"
                [ngClass]="{'display-flex flex-wrap': deviceService.isMobile()}">
                <span>{{phoneEquipmentDetailsObj?.status}}
                  <img src="assets/images/blueprint/icons/alert_success.svg" alt="Success Icon"
                    *ngIf="phoneEquipmentDetailsObj?.status?.toUpperCase()==='CONNECTED'" class="statusImg ml-1 mb-2">
                  <img src="assets/images/blueprint/icons/alert_error_icon.svg"
                    *ngIf="phoneEquipmentDetailsObj?.status?.toUpperCase()==='NOT CONNECTED' || phoneEquipmentDetailsObj?.status?.toUpperCase()==='UNKNOWN' || !phoneEquipmentDetailsObj?.status"
                    alt="error Icon" class="statusImg ml-1 mb-2"></span>
                <span *ngIf="!this.noStatusDevices.includes(selectedPhoneEquipment?.deviceDetails?.voiceDeviceType)">
                  <a *ngIf="!isRefreshing" [ngClass]="{'ml-2 pl-2 gray-border-left-1': !deviceService.isMobile()}"
                    href="javascript:void(0)" (click)="getPhoneEquipmentStatus()">{{resourceBundle.refresh}}</a>
                  <span *ngIf="isRefreshing"
                    [ngClass]="{'ml-2 pl-2 gray-border-left-1': !deviceService.isMobile()}">{{resourceBundle.refreshing}}</span>
                </span>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center"
          *ngIf="!selectedPhoneEquipment?.deviceDetails?.isMultiPortDevice">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.phoneNumber}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'"
            title="{{ selectedPhoneEquipment?.deviceDetails?.phoneNumber | phone }}">
            {{ selectedPhoneEquipment?.deviceDetails?.phoneNumber | phone }}</div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center"
          *ngIf="isValidDeviceType">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.phoneNumber}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'">
          <ol class="pl-0 mb-0" *ngFor="let phonenumberList of selectedPhoneEquipment.phoneNumbers"
            title="{{ phonenumberList | phone }}">
            {{ phonenumberList | phone }}</ol>
          </div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center"
          *ngIf="!selectedPhoneEquipment?.deviceDetails?.isMultiPortDevice">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.userName}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'"
            title="{{ selectedPhoneEquipment?.userDetails?.lastName }}, {{ selectedPhoneEquipment?.userDetails?.firstName }}">
            {{
            selectedPhoneEquipment?.userDetails?.lastName }},
            {{ selectedPhoneEquipment?.userDetails?.firstName }}</div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center"
          *ngIf="!selectedPhoneEquipment?.deviceDetails?.isMultiPortDevice">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.email}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{ selectedPhoneEquipment?.userDetails?.userId }}">{{
            selectedPhoneEquipment?.userDetails?.userId }}</div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.model}}</div>
          <div *ngIf="selectedPhoneEquipment?.deviceDetails?.purchaseStatus" class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{ selectedPhoneEquipment?.deviceDetails?.description }} ({{ selectedPhoneEquipment?.deviceDetails?.purchaseStatus }})">{{
            selectedPhoneEquipment?.deviceDetails?.description }} ({{ selectedPhoneEquipment?.deviceDetails?.purchaseStatus }})</div>
          <div *ngIf="!selectedPhoneEquipment?.deviceDetails?.purchaseStatus" class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{ selectedPhoneEquipment?.deviceDetails?.description }}">{{
            selectedPhoneEquipment?.deviceDetails?.description }}</div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.serialNumber}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{ selectedPhoneEquipment?.deviceDetails?.serialNumber }}">{{
            selectedPhoneEquipment?.deviceDetails?.serialNumber }}
          </div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.macAddres}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{ selectedPhoneEquipment?.deviceDetails?.macAddress }}">{{
            selectedPhoneEquipment?.deviceDetails?.macAddress }}</div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center"
          *ngIf="selectedPhoneEquipment?.deviceDetails?.isMultiPortDevice">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.switchName}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{ selectedPhoneEquipment?.deviceDetails?.deviceName }}">{{ selectedPhoneEquipment?.deviceDetails?.deviceName }}</div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center"
          *ngIf="cBHelperService.isMyAdmin() && selectedPhoneEquipment?.deviceDetails?.t38Flag">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.t38Configuration}}</div>
            <div class="col-7 pl-0 row-text-overflow">
              <ui-switch title="{{resourceBundle.t38Configuration}}" appUiSwitchLabel [uiSwitchAriaLabel]="resourceBundle.t38Configuration" [(ngModel)]="t38Checked"
                (change)="onChangeOfT38($event)">
              </ui-switch>
            </div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center"
          *ngIf="selectedPhoneEquipment?.deviceDetails?.purchaseStatus?.toLowerCase() === 'purchased'">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.purchaseDate}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{ phoneEquipAdditionalDetails?.purchaseDt }}">{{
            phoneEquipAdditionalDetails?.purchaseDt }}</div>
        </div>

        <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center"
          *ngIf="selectedPhoneEquipment?.deviceDetails?.purchaseStatus?.toLowerCase() === 'purchased' || selectedPhoneEquipment?.deviceDetails?.purchaseStatus?.toLowerCase() === 'owned' || selectedPhoneEquipment?.deviceDetails?.purchaseStatus?.toLowerCase() === 'leased'">
          <div class="bold col-5 pl-0 pr-0">{{resourceBundle.inServiceDate}}</div>
          <div class="col-6 pl-0" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{ phoneEquipAdditionalDetails?.installedDt }}">{{
            phoneEquipAdditionalDetails?.installedDt }}</div>
        </div>

        <ng-container *ngIf="this.cBHelperService.isMyAdmin()">
          <div class="col-12 pl-0 pr-0 mt-3 mb-3 display-flex"
            *ngIf="!selectedPhoneEquipment?.deviceDetails?.isMultiPortDevice">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.packageType}}</div>
            <div class="col-6 pl-0" >
              <div *ngFor="let packType of packageTypeList" [ngClass]="isDesktopDevice? 'row-text-overflow': 'word-break'" title="{{packType}}">
                {{packType}}
              </div>
            </div>
          </div>

          <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">
              <div>{{resourceBundle.deviceEncryption}}</div>
              <div *ngIf="selectedPhoneEquipment?.deviceDetails?.encryptionPermission">
                ({{ selectedPhoneEquipment?.deviceDetails?.encryptionPermission }})
              </div>
            </div>
            <div class="col-6 pl-0">
                <span id="switchStatus" class="sr-only" *ngIf="phoneDeviceEncrypted">{{resourceBundle.deviceEncryption}}
                  {{resourceBundle.isTurnedOn}}</span>
                <span id="switchStatus" class="sr-only"
                  *ngIf="!phoneDeviceEncrypted">{{resourceBundle.deviceEncryption}}
                  {{resourceBundle.isturnedOff}}</span>
                <ui-switch [id]="selectedPhoneEquipment?.deviceDetails?.serialNumber" role="application" *ngIf="selectedPhoneEquipment?.deviceDetails?.encryptionValid"
                  name="DeviceEncryption" aria-labelledby="switchStatus" [(ngModel)]="phoneDeviceEncrypted"
                  [attr.tabindex]="phoneDeviceEncrypted ? 0 : -1" (change)="toggleEncryption($event)"
                  [disabled]="!selectedPhoneEquipment?.deviceDetails?.editable"></ui-switch>
                <span *ngIf="!selectedPhoneEquipment?.deviceDetails?.encryptionValid" class="red"> {{resourceBundle.invalid}} </span>
            </div>
          </div>
          <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center" *ngIf="this.encryptionError.showError">
            <span class="text-danger">
              {{this.encryptionError.errorMessage}}
            </span>
          </div>
        </ng-container>

        <ng-container
          *ngIf="selectedPhoneEquipment?.deviceDetails?.isIPPhoneWithSoftKeys && !selectedPhoneEquipment?.deviceDetails?.isMultiPortDevice">
          <div class="col-12 pl-0 pr-0 mb-3 equipment-vertical-center mt-3">
            <a href="javascript:void(0)" (click)="navigateToDevicedDetail()">{{resourceBundle.advancedSettings}}</a>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedPhoneEquipment?.deviceDetails?.deviceType.startsWith('dect')">
          <div class="col-12 pl-0 pr-0 mb-3 equipment-vertical-center mt-3">
            <a href="javascript:void(0)" (click)="navigateToDevicedDetail()">{{resourceBundle.deviceconfiguration}}</a>
          </div>
        </ng-container>

      </ng-container>
      <ng-container *ngIf="equipmentType === 'other'">
        <div>
          <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.status}}</div>
            <ng-container *ngIf="equipmentDetailsObj?.status; else equipmentStatusLoader">
              <div class="col-7 pl-0 pr-0 row-text-overflow"
                [ngClass]="{'display-flex flex-wrap': deviceService.isMobile()}">
                <span>{{equipmentDetailsObj?.status}}
                  <img
                    src="assets/images/blueprint/icons/alert_success.svg"
                    alt="Success Icon"
                    *ngIf="equipmentDetailsObj?.status?.toUpperCase()==='CONNECTED'"
                    class="statusImg ml-1 mb-2">
                  <img
                    src="assets/images/blueprint/equipment/circular_error.svg"
                    *ngIf="equipmentDetailsObj?.status?.toUpperCase()==='NOT CONNECTED' || equipmentDetailsObj?.status?.toUpperCase()==='UNKNOWN' || !equipmentDetailsObj?.status"
                    alt="error Icon" class="statusImg ml-1 mb-2"></span>
                <span *ngIf="!this.noStatusDevices.includes(selectedOtherEquipment?.equipmentType)">
                  <a *ngIf="!isRefreshing" [ngClass]="{'ml-2 gray-border-left-1': !deviceService.isMobile()}" class="pl-2"
                    href="javascript:void(0)" (click)="getEquipmentStatus()">{{resourceBundle.refresh}}</a>
                  <span *ngIf="isRefreshing"
                    [ngClass]="{'ml-2 gray-border-left-1': !deviceService.isMobile()}" class="pl-2">{{resourceBundle.refreshing}}</span>
                </span>
              </div>
            </ng-container>
          </div>

          <div *ngIf="sbgDevices" class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.operatingMode}}</div>
            <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.operatingMode}}">
              {{equipmentDetailsObj?.operatingMode}}</div>
          </div>

          <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0" *ngIf="equipmentDetailsObj?.operatingMode === 'DOCSIS' && sbgDevices">
              {{resourceBundle.ecmMAC}}</div>
            <div class="bold col-5 pl-0 pr-0" *ngIf="equipmentDetailsObj?.operatingMode === 'Ethernet' && sbgDevices">
              {{resourceBundle.wanMAC}}</div>
            <div *ngIf="!sbgDevices" class="bold col-5 pl-0 pr-0">{{resourceBundle.macAddres}}</div>
            <ng-container
              *ngIf="equipmentDetailsObj?.macAddress || equipmentDetailsObj?.wanMac; else equipmentStatusLoader">
              <div class="col-7 pl-0 row-text-overflow" *ngIf="equipmentDetailsObj?.operatingMode !== 'Ethernet'"
                title="{{equipmentDetailsObj?.macAddress}}">
                {{equipmentDetailsObj?.macAddress}}
              </div>
              <div class="col-7 pl-0 row-text-overflow" *ngIf="equipmentDetailsObj?.operatingMode === 'Ethernet'"
                title="{{equipmentDetailsObj?.wanMac}}">
                {{equipmentDetailsObj?.wanMac}}
              </div>
            </ng-container>
          </div>

          <div *ngIf="sbgDevices" class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.ipv4}}</div>
            <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.ipv4Address}}">
              {{equipmentDetailsObj?.ipv4Address}}</div>
          </div>

          <div *ngIf="sbgDevices" class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.ipv6}}</div>
            <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.ipv6Address}}">
              {{equipmentDetailsObj?.ipv6Address}}</div>
          </div>

          <div *ngIf="sbgDevices" class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.connectedSince}}</div>
            <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.connectedSince}}">
              {{equipmentDetailsObj?.connectedSince}}</div>
          </div>

          <div *ngIf="sbgDevices" class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.inServiceDate}}</div>
            <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.installedDate}}">
              {{equipmentDetailsObj?.installedDate}}</div>
          </div>

          <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.serialNumber}}</div>
            <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.serialNumber}}">
              {{equipmentDetailsObj?.serialNumber}}</div>
          </div>

          <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.vendor}}</div>
            <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.vendor}}">
              {{equipmentDetailsObj?.vendor}}</div>
          </div>

          <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
            <div class="bold col-5 pl-0 pr-0">{{resourceBundle.type}}</div>
            <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.type}}">
              {{equipmentDetailsObj?.type}}
            </div>
          </div>

          <ng-container *ngIf="equipmentDetailsObj?.equipmentType === 'LTE Router'">
            <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
              <div class="bold col-5 pl-0 pr-0">{{resourceBundle.primaryWANStatus}}</div>
              <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.primaryWANStatus}}">
                {{equipmentDetailsObj?.primaryWANStatus}}
              </div>
            </div>
            <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
              <div class="bold col-5 pl-0 pr-0">{{resourceBundle.backupWANStatus}}</div>
              <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.backupWANStatus}}">
                {{equipmentDetailsObj?.backupWANStatus}}
              </div>
            </div>
            <div *ngIf="showDataUtilization">
              <div class="col-12 pl-0 pr-0 mt-3 equipment-vertical-center">
                <div class="col-5 pl-0 pr-0 bold">{{resourceBundle.backupUsage}}</div>
                <div *ngIf="dataUtilization?.asOfDate && dataUtilization?.backupUsage"
                  class="col-7 pl-0 row-text-overflow" title="{{resourceBundle.backupUsageDetailWithDate}}">
                  {{resourceBundle.backupUsageDetailWithDate}}
                </div>
                <div *ngIf="!dataUtilization?.asOfDate && dataUtilization?.backupUsage"
                  class="col-7 pl-0 row-text-overflow" title="{{resourceBundle.backupUsage}}">
                  {{resourceBundle.backupUsage}}
                </div>
              </div>
              <div *ngIf="dataUtilization?.cycleEndDate" class="col-12 pl-0 pr-0 mb-3 equipment-vertical-center">
                <div class="col-5 pl-0 pr-0">{{resourceBundle.resets}}</div>
              </div>
              <div *ngIf="!isInFootprint && dataUtilization?.remainingData && dataUtilization?.overageRate"
                class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
                <p class="pl-0 pr-0">{{resourceBundle.outFootPrintDetail_1}}</p>
              </div>
              <div *ngIf="!isInFootprint && dataUtilization?.billPeriodCharges"
                class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
                <p class="pl-0 pr-0">{{resourceBundle.outFootPrintDetail_2}}</p>
              </div>
            </div>
            <div *ngIf="equipmentDetailsObj?.batteryType" class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
              <div class="bold col-5 pl-0 pr-0">{{resourceBundle.batteryType}}</div>
              <div class="col-7 pl-0 row-text-overflow" title="{{equipmentDetailsObj?.batteryType}}">
                {{equipmentDetailsObj?.batteryType}}
              </div>
            </div>

          </ng-container>

          <ng-container *ngIf="this.cBHelperService.isMyAdmin() 
          && (this.selectedOtherEquipment.equipmentCategory === equipmentCategoryEnum.VOICE)">
            <div class="col-12 pl-0 pr-0 mt-3 mb-3 display-flex">
              <div class="bold col-5 pl-0 pr-0">{{resourceBundle.packageType}}</div>
              <div class="col-6 pl-0">
                <div *ngFor="let packType of packageTypeList" class="row-text-overflow" title="{{packType}}">
                  {{packType}}
                </div>
              </div>
            </div>

            <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center">
              <div class="bold col-5 pl-0 pr-0">
                <div>{{resourceBundle.deviceEncryption}}</div>
                <div>
                  ({{ (selectedOtherEquipment?.deviceDetails &&
                  selectedOtherEquipment?.deviceDetails?.encryptionPermission) ?
                  selectedOtherEquipment?.deviceDetails?.encryptionPermission : encryptionDisallowed}})
                </div>
              </div>
              <div class="col-6 pl-0">
                <span id="switchStatus" class="sr-only" *ngIf="phoneDeviceEncrypted">{{resourceBundle.deviceEncryption}}
                  {{resourceBundle.isTurnedOn}}</span>
                <span id="switchStatus" class="sr-only"
                  *ngIf="!phoneDeviceEncrypted">{{resourceBundle.deviceEncryption}}
                  {{resourceBundle.isturnedOff}}</span>
                <ui-switch [id]="selectedOtherEquipment?.deviceDetails?.serialNumber" role="application"
                  name="DeviceEncryption" aria-labelledby="switchStatus" [(ngModel)]="phoneDeviceEncrypted"
                  (change)="toggleEncryption($event)"
                  [disabled]="!selectedOtherEquipment?.deviceDetails?.editable"></ui-switch>
              </div>
            </div>
            <div class="col-12 pl-0 pr-0 mt-3 mb-3 equipment-vertical-center" *ngIf="this.encryptionError.showError">
              <span class="text-danger">
                {{this.encryptionError.errorMessage}}
              </span>
            </div>
          </ng-container>

          <div class="mt-5">
            <div *ngIf="selectedOtherEquipment?.sbgFlag && showManageGateway"
              class="col-12 pl-0 pr-0 mb-3 equipment-vertical-center">
              <a href="javascript:void(0)" (click)="navigateToGateway(false)"
                *ngIf="!sbgDevices">{{resourceBundle.advancedSettings}}
                <img src="assets/images/blueprint/arrow_next.svg" alt="arrow next icon" class="mt-n1 ml-1">
              </a>
              <a href="javascript:void(0)" (click)="navigateToGateway(false)"
                *ngIf="sbgDevices">{{resourceBundle.deviceconfiguration}}
                <img src="assets/images/blueprint/arrow_next.svg" alt="arrow next icon" class="mt-n1 ml-1">
              </a>
            </div>
          </div>

        </div>
      </ng-container>
      <div class="col-xs-12" *ngIf="deviceService.isMobile()">
        <button class="button" (click)="openRebootWidget()">{{resourceBundle.rebootDevice}}</button>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-4 left-section seat-type"
      *ngIf="!deviceService.isMobile()">
      <app-redesign-reboot-widget [equipmentType]="equipmentType" [selectedPhoneEquipment]="selectedPhoneEquipment" [voiceFlag]="voiceFlag"
        (focusTrap)="cardDetailFocus($event)" [selectedOtherEquipment]="selectedOtherEquipment"
        [equipmentName]="equipmentName" [accountGuid]="accountGuid" [accountNumber]="accountNumber12" [macAddress]="this.selectedOtherEquipment?.macAddress" [smartHelpFlag]="smartHelpFlag"></app-redesign-reboot-widget>
    </div>
  </ng-container>

  <ng-template #loadSpinTemplate>
    <div>
      <app-skeletonloader formName="Equipment Card" formSkeleton="true" rowCount="4">
      </app-skeletonloader>
    </div>
  </ng-template>

</div>

<ng-template #equipmentStatusLoader>
  <div class="col-7 p-0 margin-left-neg-1 mb-n4">
    <app-skeletonloader formName="Equipment Status" formSkeleton="true" rowCount="1">
    </app-skeletonloader>
  </div>
</ng-template>