import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class BroadbandFactLabelService {
    constructor(private apollo: Apollo) { }

    public getBroadbandFactlabelDetails(accountNumber: string, siteId: string) {
        const getBroadbandFactlabelDetailsQuery = gql`
    query broadbandFactlabelDetailsQuery($accountNumber: String!, $siteId: String!) {
     broadBandFactLabelDetails(accountNumber: $accountNumber, siteId: $siteId) {
      bBFLDetails {
        billingServiceName
        planType
        chargesForAdditionalDataUsage
        dataIncludedWithMontlyPrice
        earlyTerminationFee
        equipmentDeposit
        governmentTaxes
        lateFee
        linkDetails {
            link
            linkName
            staticText
        }
        monthlyPrice
        oneTimeFee {
            catalogCode
            chargeBillingCode
            chargeName
            chargeRate
            installationFee
            activationFee
        }
        providermonthlyfee {
            catalogCode
            chargeBillingCode
            chargeName
            gatewayEquipmentRentalFee
            chargePromotionalRate
            chargePromotionalTermInMonths
        }
        speedPlans {
            downloadSpeed
            latency
            uploadSpeed
        }
        upi
        planCode
        planBillingCode
        lateFeeType
        accessTechnology
        maxTypicalDownloadSpeedInMb
        maxTypicalUploadSpeedInMb
        serviceInstallDate
        accountBillType
        }
    }
}`;
        return this.apollo.query({
            query: getBroadbandFactlabelDetailsQuery,
            variables: {
                accountNumber,
                siteId
            },
        });
    }

}
