import { Component, OnInit, OnDestroy, Input, Output, EventEmitter ,ViewEncapsulation } from '@angular/core';
import { BaseAccount } from '../../models/accounts/baseaccounts.model';
import { AEMClientService } from '../../../services/aemclient.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TealiumUtagService } from '../../../tealium/utag.service';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-accountwidgetsingleaccount',
  templateUrl: './accountwidgetsingleaccount.component.html',
  styleUrls: ['./accountwidgetsingleaccount.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountwidgetsingleaccountComponent implements OnInit, OnDestroy {
  private _preselectedAccount: BaseAccount;
  private onDestroy$ = new Subject<boolean>();
  @Input()
  public set baseAccounts(value: BaseAccount[]) {
    if (this._preselectedAccount) { return; }
    this.setAccount(value[0]);
  }
  @Input()
  public set selectedAccount(value: BaseAccount) {
    if (!value) { return; }
    this._preselectedAccount = value;
    this.setAccount(value);
  }
  @Output()
  public selectedAccountChange = new EventEmitter<BaseAccount>();
  public accountName: string;
  public accountAlias: string;
  public accountNumber: string;
  public accountAddress: string;
  public accountAddress1: string;
  public accountAddress2: string;
  public accountAddressPostal: string;
  public resourceBundle: any = {};
  constructor(private aemClient: AEMClientService,
              private tealium: TealiumUtagService) { }
  ngOnInit() {
    this.aemClient.getSharedBundle('accountWidgetSingleAccountResourceBundles')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => { this.resourceBundle = data; },
        error => { }
      );
  }
  private setAccount(baseAccount: BaseAccount): void {
    this.tealium.track('link', {
      eventName: 'AccountWidgetSelection',
      accountGuid: baseAccount.guid,
    });
    this.selectedAccountChange.emit(baseAccount);
    this.accountName = baseAccount.name;
    this.accountAlias = baseAccount.alias;
    this.accountNumber = baseAccount.siteId + '-' + baseAccount.accountNumber;
    let address2 = '';
    if (baseAccount.address.address2 !== '') {
      address2 = baseAccount.address.address2 + ', ';
    }
    this.accountAddress = baseAccount.address.address1 + ', ' +
      address2 + baseAccount.address.city + ', ' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
    this.accountAddress1 = baseAccount.address.address1;
    this.accountAddress2 = baseAccount.address.address2;
    this.accountAddressPostal = baseAccount.address.city + ',' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}