import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
// tslint:disable-next-line: import-blacklist
import { Observable } from 'rxjs';
import { CBHelperService } from '../../services/cbhelper.service';

export const Aomsauthguard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
  const cbHelper = inject(CBHelperService);
  const router = inject(Router);

  if (cbHelper.getLoggedInUserDetails() && cbHelper.getLoggedInUserDetails().profile.userToken.aomsRole
    && cbHelper.getLoggedInUserDetails().profile && cbHelper.getLoggedInUserDetails().profile.userToken) {
    const getAomsRole = cbHelper.getLoggedInUserDetails().profile.userToken.aomsRole;
    if (getAomsRole === 'NCOA') {
      router.navigate(['/aoms/ordermanagement'], {
          queryParams:
            { unauthorized: 'true' },
        });
        return true;
      } else if (getAomsRole === 'NOC') {
        router.navigate(['/aoms/otherorders'], {
          queryParams:
            { unauthorized: 'true' },
        });
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
}
