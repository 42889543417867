<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
  <ng-container *ngIf="!isMobile">
    <mat-tab-group [dynamicHeight]="true" class="mat-elevation-z4 notificationTab" [disableRipple]="true"
      (selectedIndexChange)="ontabChange($event)" id="npChildTab" [selectedIndex]="selectedTabIndex">
      <mat-tab *ngFor="let category of subCategories; let index = index" [label]="category.displayname"
        [title]="category.displayname">
        <ng-template matTabContent>
          <ng-container *ngTemplateOutlet="npTemplate; context: {categoryData: category}"></ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <ng-container *ngIf="isMobile">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" id="npChildTab">
        <ul ngbNav [(activeId)]="activeTab" id="npSubMenu" class="nav-pills vertical" [keyboard]="true"
          orientation="vertical">
          <ng-container *ngFor="let category of subCategories; let i=index">
            <li [ngbNavItem]="category.displayname">
              <a ngbNavLink (click)="loadSubMenuContent(category)">
                <div class="pt-1 pad-bottom-3 row-text-overflow">
                  <span>{{category.displayname}}</span><img
                    src="assets/images/blueprint/admin_redesign/arrow-right-black.svg" class="mt-1 pull-right"
                    role="presentation" alt="arrow next icon" />
                </div>
              </a>
            </li>
          </ng-container>
        </ul>
        <div id="npSubMenuContent" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 hide">
          <ng-container *ngTemplateOutlet="npTemplate; context: {categoryData: selectedCategory}"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>


<ng-template #npTemplate let-category="categoryData">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3"
      [ngClass]="{'margin-bottom-40px': (isMobile || isTablet), 'mb-3': (!isMobile && !isTablet)}">
      {{guidanceText}}
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-40px" *ngIf="!isMobile && !isTablet">
      {{resourceBundle.note}}
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mb-3"
      [ngClass]="{'display-flex align-items-center': isLargeScreen}">
      <div *ngIf="isMultiAccount" class="notification-search-filter col-lg-6 col-md-12 col-sm-12 col-xs-12 pl-2">
        <app-search-filter [rows]="searchObj" (filteredRows)="getSearchResults($event)"
          (filterTags)="getFiltertags($event)" [clearFilterTag]="deleteTag">
        </app-search-filter>
      </div>
      <ng-container *ngIf="tableContentLoaded; else allNotificationsLoader">
        <div *ngIf="totalCount > 1" class="pr-0 display-flex"
          [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-xs-12': !isLargeScreen, 'col-6 justify-content-flexEnd': isLargeScreen, 'flex-column margin-top-30': (isMobile || isTablet), 'margin-top-40px': (!isLargeScreen && !isMobile && !isTablet)}">
          <span class="pad-right-p-20 pt-1">{{resourceBundle.allNotifications}}</span>
          <div class="p-0 display-flex"
            [ngClass]="{'col-5': !isLargeScreen && !isMobile && !isTablet, 'col-6': isLargeScreen, 'col-8 mt-3': (isMobile || isTablet)}">
            <div class="col-6 p-0">
              <ui-switch color="blue" size="small" appUiSwitchLabel [uiSwitchAriaLabel]="'Master Email Enable/Disable'"
                role="application" [checked]="masterEmail" (change)="onSwitchToggle($event, null, 'EMAIL', 'ALL')">
              </ui-switch>
              <label class="pad-left-p-15">{{resourceBundle.email}}</label>
            </div>
            <div class="col-6 p-0">
              <ui-switch color="blue" size="small" appUiSwitchLabel [uiSwitchAriaLabel]="'Master SMS Enable/Disable'"
                role="application" [checked]="masterSms" (change)="onSwitchToggle($event, null, 'SMS', 'ALL')">
              </ui-switch>
              <label class="pad-left-p-15">{{resourceBundle.sms}}</label>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
      <app-filtertags [selectedTags]="turnedOnfiltertags" (clearFilterTag)="clearFilterTag($event)">
      </app-filtertags>
    </div>
    <ng-container *ngIf="tableContentLoaded; else loadSpinTemplate">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-2 pr-2">
        <ngx-datatable class="dataTable bootstrap fullscreen no-border" id="notificationTable" #notificationTable
          [rows]="notificationTableData | paginate: pageOptions" [columnMode]="'force'" comnAccessTable
          [tableName]="'Notification'" role="table" [table]="notificationTable" [ngxPagination]="true"
          [noofRows]="notificationTableData?.length" [reorderable]="false" [rowHeight]="'auto'" [columnMode]="'force'"
          [headerHeight]="'auto'" [footerHeight]="'auto'" [limit]="10">
          <ngx-datatable-column name="Account Details" [sortable]="false" prop="" [resizeable]='false'
            [maxWidth]="datatableRowWidth[0].accountDetails" [minWidth]="datatableRowWidth[0].accountDetails"
            *ngIf="!isLargeScreen">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 row-text-overflow">
                  <div class="bold">{{resourceBundle.accountAlias}}</div>
                  <div>{{row.node.accountAlias}}</div>
                  <div class="bold">{{resourceBundle.serviceAddress}}</div>
                  <div>{{row.serviceAddress}}</div>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Account Alias" [sortable]="true" prop="row.node.accountAlias" [resizeable]='false'
            [maxWidth]="datatableRowWidth[0].accountAlias" *ngIf="isLargeScreen">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex">
              <div class="row flex-nowrap">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row-text-overflow"
                  [attr.title]="row.node.accountAlias">
                  {{row.node.accountAlias}}
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="serviceAddress" name="Service Address" [sortable]="true" [resizeable]='false'
            [maxWidth]="datatableRowWidth[0].serviceAddress" *ngIf="isLargeScreen">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex">
              <div class="row flex-nowrap">
                <div [attr.title]="row.serviceAddress"
                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 notifyMessage text-ellipsis">
                  {{row.serviceAddress}}
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column prop="" name="Notification" [resizeable]='false'
            [maxWidth]='datatableRowWidth[0].notification' [minWidth]="datatableRowWidth[0].notification"
            [sortable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex">
              <div class="display-flex" [ngClass]="{'flex-nowrap': isLargeScreen, 'flex-column': !isLargeScreen}">
                <div class="p-0"
                  [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-xs-12': !isLargeScreen, 'col-6': isLargeScreen}">
                  <ng-container>
                    <div *ngIf="isMobile || isTablet">{{resourceBundle.email}}</div>
                    <ui-switch id="emailSwitch_{{row.node.accountGuid}}" color="blue" size="small" appUiSwitchLabel
                      [uiSwitchAriaLabel]="row.node.accountAlias + 'Email Enable/Disable'" role="application"
                      [checked]="row.node.isEmailEnabled"
                      (change)="onSwitchToggle($event, row.node.accountGuid, 'EMAIL', 'SINGLE')">
                    </ui-switch>
                    <label *ngIf="!isMobile && !isTablet" class="pl-3">{{resourceBundle.email}}</label>
                  </ng-container>
                </div>
                <div class="p-0"
                  [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2': !isLargeScreen, 'col-6': isLargeScreen}">
                  <ng-container>
                    <div *ngIf="isMobile || isTablet">{{resourceBundle.sms}}</div>
                    <ui-switch id="smsSwitch_{{row.node.accountGuid}}" color="blue" size="small" appUiSwitchLabel
                      [uiSwitchAriaLabel]="row.node.accountAlias + 'SMS Enable/Disable'" role="application"
                      [checked]="row.node.isSMSEnabled"
                      (change)="onSwitchToggle($event, row.node.accountGuid, 'SMS', 'SINGLE')">
                    </ui-switch>
                    <label *ngIf="!isMobile && !isTablet" class="pl-3">{{resourceBundle.sms}}</label>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer *ngIf="showPagination">
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
              let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
              <div *ngIf="notificationTableData?.length"
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 display-flex justify-content-center" role="application">
                <app-pagination [options]="pageOptions" (paginationResult)="clickNextOrPrevTable($event)">
                </app-pagination>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loadSpinTemplate>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
    <app-skeletonloader datatableName="Notification Template" datatableSkeleton="true">
    </app-skeletonloader>
  </div>
</ng-template>

<ng-template #allNotificationsLoader>
  <div class="col-6 mb-n5" [ngClass]="{'mt-4': !isLargeScreen}">
    <app-skeletonloader formName="All Notifications" formSkeleton="true" rowCount="1">
    </app-skeletonloader>
  </div>
</ng-template>