import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig, LocalStorageKeys } from 'common-ui-lib';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { VipCodes } from '../constants/vipcodes.model';
import { UserTokenData } from './cbhelper.service';

@Injectable()
export class PendoService {
    private _userDecodedToken: UserTokenData;
    // tslint:disable-next-line: no-any
    private configRoles: any;
    jwtHelper: JwtHelperService = new JwtHelperService();
    private selfInstallRoles: string;

    constructor(
        private _storageService: LocalStorageService,
        private httpClient: HttpClient,
        private config: AppConfig,
    ) { }

    // tslint:disable-next-line: no-any
    public intializePendo(isAuthenticated: boolean, _userDecodedToken?: UserTokenData, configRoles?: any, isProfileInContext?: boolean) {
        if (isAuthenticated) {
            if (this.selfInstallRoles) {
                this.setPendoObject(isAuthenticated, _userDecodedToken, configRoles);
            } else {
                if (isProfileInContext) {
                    this.getPendoSelfInstall(_userDecodedToken.sub)
                        .pipe(finalize(() => {
                        }))
                        .subscribe(
                            (data) => {
                                if (data && data.code === '0') {
                                    this.selfInstallRoles = data.selfInstall ? JSON.stringify(data.selfInstall) : null;
                                    this.setPendoObject(isAuthenticated, _userDecodedToken, configRoles);
                                } else {
                                    this.setPendoObject(isAuthenticated, _userDecodedToken, configRoles);
                                }
                            },
                            (error) => {
                                this.setPendoObject(isAuthenticated, _userDecodedToken, configRoles);
                            },
                        );
                } else {
                    this.setPendoObject(isAuthenticated, _userDecodedToken, configRoles);
                }
            }
        } else {
            this.setPendoObject(isAuthenticated, _userDecodedToken, configRoles);
        }
    }

    public setPendoObject(isAuthenticated: boolean, _userDecodedToken?: UserTokenData, configRoles?: any) {
        try {
            if (isAuthenticated) {
                this._userDecodedToken = _userDecodedToken;
                this.configRoles = configRoles;
                if (window['pendo']?.isReady()) {
                    window['pendo']?.identify({
                        visitor: this.pendoVistiorObject(),
                        account: this.pendoAccountObject(),
                    });
                } else {
                    window['pendo']?.initialize({
                        visitor: this.pendoVistiorObject(),
                        account: this.pendoAccountObject(),
                    });
                }
            } else {
                window['pendo']?.initialize({
                    visitor: {},
                    account: {},
                });
            }
        } catch (error) {
            console.log('Pendo Error :', error);
        }
    }

    private getPendoSelfInstall(masterEmail: string): Observable<SelfInstallRole> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'MA_TRANSACTION_ID': uuid(),
                'clientid': this.config.getConfig('APIGW')['clientId'],
                'apikey': this.config.getConfig('APIGW')['apiKey'],
                'CB_SESSION': masterEmail,
                'CBMA_AUTHTOKEN': JSON.parse(localStorage.getItem('cbma_authtoken')),
            }),
        };
        const url = '/api/cbma/userauthorization/services/loginprofiles/pendoRoles?selfInstallRoles=true';
        return this.httpClient.get<SelfInstallRole>(url, { headers: httpOptions.headers });
    }

    private pendoVistiorObject() {
        const loggedInUserDetails = this._storageService.get(LocalStorageKeys.LoggedInUserDetails, 'cbma');
        const loggedInUser = loggedInUserDetails ?  JSON.parse(loggedInUserDetails) : null;
        if (this._userDecodedToken && this._userDecodedToken?.profile && this._userDecodedToken?.profile?.userToken) {
            return {
                id: this._userDecodedToken.profile.userToken.guid,
                role: this._userDecodedToken.profile.userToken.userType,
                userHasPermission: this._userDecodedToken.profile.userToken.foneAdmin,
                siteID: this._userDecodedToken.profile.userToken.bwGroupId ?
                    this._userDecodedToken.profile.userToken.bwGroupId.substr(0, 3) : '',
                roles: this._userDecodedToken.profile.roles || [],
                vipCodes: this.getVipCodes(),
                teCodes: loggedInUser ? loggedInUser.accountDetails.teCodes : '',
                categories: this.getCategories() || [],
                hasAccountRecoveryEnabled: loggedInUser ? loggedInUser.hasAccountRecoveryEnabled
                    ? loggedInUser.hasAccountRecoveryEnabled : false : false,
                selfInstallRoles: this.selfInstallRoles ? this.selfInstallRoles : '',
            };
        }
    }

    private pendoAccountObject() {
        let obj = {};
        if (this._userDecodedToken?.profile?.userToken?.profileGuid) {
            obj = {
                id: this._userDecodedToken.profile.userToken.profileGuid,
            };
        }
        return obj;
    }

    private getVipCodes(): string {
        const loggedInUser = JSON.parse(this._storageService.get(LocalStorageKeys.LoggedInProfileDetails, 'cbma'));
        if (loggedInUser && loggedInUser?.accountDetails?.vipCodes?.includes(VipCodes.platinum)) {
            return 'PLATINUM';
        } else if (loggedInUser && loggedInUser?.accountDetails?.vipCodes?.includes(VipCodes.diamond)) {
            return 'DIAMOND';
        } else {
            return '';
        }
    }

    private getCategories(): string[] {
        const categories = [];
        if (this.configRoles) {
            this.configRoles.forEach((obj) => {
                if (this._userDecodedToken?.profile?.roles?.includes(obj.role) && !categories?.includes(obj.category)) {
                    categories.push(obj.category);
                }
            });
        }
        return categories;
    }
}


export interface SelfInstallRole {
    transactionId: string;
    code: string;
    message: string;
    warning: string;
    selfInstall: RoleList[];
}

export interface RoleList {
    accountGuid: string;
    selfInstallRoles: string;
}
