import { BaseAccount, MicroserviceResponse } from 'common-ui-lib';

export interface OnboardingAccountTracking {
    id: string;
    currentStepName: CurrentStepName;
    source: string;
    decision: string;
    onBoardingStatus: string;
    voiceNextStepName: string;
    accountNumber16: string;
    isAddAccountFlow: string;
}
export interface GUIDResponse extends MicroserviceResponse {
    emailId: string;
    accountNumber: string;
    customerType: string;
}
export interface Account extends MicroserviceResponse {
    baseAccount: BaseAccount;
}
export interface OnboardingAccountTrackingResponse extends MicroserviceResponse {
    id: string;
}
export interface ExtendedAccountDetails extends MicroserviceResponse {
    voiceManagerDetails: VoiceManagerDetails;
    accountDetails: AccountConsolidatedDetails;
    loggedInSecurityUserType: string;
    userAssignedSecurityPermissionName: string;
    isHotCut: boolean;
    isTransferProcess: boolean;
}

interface AccountConsolidatedDetails {
    privileges: string;
    serviceCodes: string;
    siteIds: string;
    vipCodes: string;
    customerStatusCode: string;
    userAssignedSecurityPermissionName: string;
}

interface VoiceManagerDetails {
    assignedPhoneNumber: string;
    accountId: string;
    siteId: string;
    accountNumber: string;
    serviceCodes: string;
    status: FeatureStatusType;
    hasCallCentre: boolean;
    hasProvisionedTrunkGroup: boolean;
    hasIPDevices: boolean;
    hasIPPhonesWithSoftKeys: boolean;
    hasLCDIPPhones: boolean;
    hasIPPhonesWithDeviceCredentials: boolean;
    hasSpoofableFlag: boolean;
    switchType: PhoneSwitchType;
    hasE911Licence: boolean;
    hasUCEnabled: boolean;
    // TelephonyData telephonyData;
    // TimeZoneDetails timeZoneDetails;
    switchPackages: string;
}

enum PhoneSwitchType {
    BTS = 'BTS',
    CIRCUIT = 'CIRCUIT',
    CS2K = 'CS2K',
    UNKNOWN = 'UNKNOWN',
}
export interface BaseAccountsResponse extends MicroserviceResponse {
    baseAccounts: BaseAccount[];
}

export enum OnboardingStepsUrl {
    ADD_NEW_USER = '/onboarding/addnewuser',
    EDIT_USER = '/onboarding/edituser',
    VIEW_USERS = '/onboarding/viewalluser',
    ACCOUNT_CONTACTS = '/onboarding/accountcontacts',
    AUTHORIZED_USERS = '/onboarding/accountauthorization',
    ASSIGN_VOICE_PHONE = '/onboarding/managephonenumbers',
    VOICE_MAIL = '/onboarding/voicemailsettings',
    CALL_FORWARDING = '/onboarding/callforwarding',
    AUTO_PAYMENTS = '/onboarding/obmanageautopay',
    PAPERLESS = '/onboarding/obpaperless',
    VIEW_IP_ADDRESS = '/onboarding/viewipaddresses',
    LANDING_TOUR = '/onboarding/tour',
}

export enum FeatureStatusType {
    UNAVAILABLE = 'UNAVAILABLE',
    AVAILABLE = 'AVAILABLE',
    INELIGIBLE = 'INELIGIBLE',
    ELIGIBLE = 'ELIGIBLE',
    PENDING = 'PENDING',
    UNKNOWN = 'UNKNOWN',
    ACTIVE = 'ACTIVE',
    ACTIVATIONPENDING = 'ACTIVATIONPENDING',
    SUSPENDED = 'SUSPENDED',
    ELIGIBLEMIG = 'ELIGIBLEMIG',
}

export enum CurrentStepName {
    ADD_ACCOUNT = 'ADD_ACCOUNT',
    AUTHORIZED_USERS = 'AUTHORIZED_USERS',
    ACCOUNT_CONTACTS = 'ACCOUNT_CONTACTS',
    ADD_NEW_USER = 'ADD_NEW_USER',
    EDIT_USER = 'EDIT_USER',
    VIEW_USERS = 'VIEW_USERS',
    CALL_FORWARDING = 'CALL_FORWARDING',
    ASSIGN_VOICE_PHONE = 'ASSIGN_VOICE_PHONE',
    PAPERLESS = 'PAPERLESS',
    VIEW_IP_ADDRESS = 'VIEW_IP_ADDRESS',
    VOICE_MAIL = 'VOICE_MAIL',
    LANDING_TOUR = 'LANDING_TOUR',
}

export interface MarketRollOut extends MicroserviceResponse{
    testMarket: boolean;
}

export enum API{
    ELIGIBILTY_API = '/api/cbma/profile/services/profile/treatment/eligibility' 
}
