
import { Injectable } from '@angular/core';
import {
  AppConfig, CBHelperService, CommonMessageService,
  DeviceDetectorService, MicroserviceResponse, LocalStorageKeys, Cookie
} from 'common-ui-lib';
import {
  StepNum, GenerateOtpRequest, GetVerificationMethodsResponse, ValidateOtpResponse,
  SelectedContactMethodType, UpdatePassword, PwdResetCompleteResponse, VerifyAuthenticIdRequest,
  ValidateUserId, VerifyAuthenticIdResponse
} from './reset-password.model';
import { v4 as uuid } from 'uuid';
import { LocalStorageService } from 'ngx-localstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpHeaders } from '@angular/common/http';
import { CookieDomain } from '../constants/domain.model';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable()
export class ResetPasswordService {

  public currentStep = StepNum.step1;
  public isMobile: boolean;
  public generateOtpRequest: GenerateOtpRequest;
  public verificationMethodsResponse: GetVerificationMethodsResponse;
  public checkNoAttempts = false;
  public displayErrMsg = false;
  public selectedContactMethodType = SelectedContactMethodType;
  public selectedContactType: string;
  public maskedContact: string;
  public maskedScanIdContact: string;
  public userName: string;
  public userEmail: string;
  public encryptedPassword: string;
  public authenticid: string;
  public loggedInUserType: string;
  public jwtHelper: JwtHelperService = new JwtHelperService();
  public headers: any = new HttpHeaders();
  public talonToken: string;
  public domain: any;
  public attributes: string;
  public isMyAdmin = false;
  public decodedtoken: string;
  public tokenPayload: any;
  public masterUserEmail: string;
  public isIdScanFailed = false;
  public modalHeading: string;
  public forgotPasswordFlow = false;


  constructor(private config: AppConfig,
    private deviceDetectorService: DeviceDetectorService,
    public resetPwdMsg: CommonMessageService,
    private _storageService: LocalStorageService,
    public cbHelperService: CBHelperService,
    public cookieService: CookieService,
    private router: Router) {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.domain = new CookieDomain();
    this.attributes = this.cbHelperService.isLocal() ? ';path=/;'
      : `;path=/;domain=${this.domain.coxdotcom};SameSite=None;secure`;
    this.isMyAdmin = this.cbHelperService.isMyAdmin();
    this.decodedtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    this.tokenPayload = this.jwtHelper.decodeToken(this.decodedtoken);
    this.masterUserEmail = this.tokenPayload?.sub;
    if(this.router.url.includes('unauth/login') && !this.cbHelperService.isMyAdmin()) {
      this.forgotPasswordFlow = true;
    }
  }

  public getHeaders(cbsession: string) {
   return this.headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'MA_TRANSACTION_ID': uuid(),
      'clientid': this.config.getConfig('APIGW')['clientId'],
      'apikey': this.config.getConfig('APIGW')['apiKey'],
      'CB_SESSION': cbsession,
    }
  }

  public modalScroll() {
    const modalWindow = document.querySelectorAll('ngb-modal-window');
    if (modalWindow && modalWindow.length > 0) {
      modalWindow[0].scrollTop = 0;
    }
  }

  public async getVerificationMethods(reqBody: ValidateUserId): Promise<GetVerificationMethodsResponse> {
    this.userEmail = reqBody.userEmail;
    const uri = `${window.location.origin}/api/cbma/user/identity/services/useridentity/user/pwd/reset/v1/getverificationmethods/${reqBody.userEmail}`;
    return new Promise((reslove, reject) => fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.isMyAdmin? this.masterUserEmail: uuid(),
        'CBMA_AUTHTOKEN': this.isMyAdmin? this._storageService.get(LocalStorageKeys.Authtoken, 'cbma') : null
      },
      body: JSON.stringify(reqBody),
    })
      .then(response => {
       this.talonToken = response.headers.get('t-token');
       return response.json()
      })
      .then(data => reslove(data))
      .catch(error => reject(error)));
  }

  public generateOtp(reqBody: GenerateOtpRequest): Promise<MicroserviceResponse> {
    const uri = `${window.location.origin}/api/cbma/user/identity/services/useridentity/user/pwd/reset/v1/generatetoken`;
    return new Promise((reslove, reject) => fetch(uri, {
      method: 'POST',
      headers: this.getHeaders(uuid()),
      body: JSON.stringify(reqBody)
    }
    )
      .then(response => response.json())
      .then(data => reslove(data))
      .catch(error => reject(error)));
  }

  public validateOtp(contactMethod: string, encryptedOtp: string, userEmailId: string): Promise<ValidateOtpResponse> {
    const reqBody = {
      contactmethod: contactMethod,
      tokenvalue: encryptedOtp,
      userEmail : userEmailId,
    };
    const uri = `${window.location.origin}/api/cbma/user/identity/services/useridentity/user/pwd/reset/validatetoken`;
    return new Promise((reslove, reject) => fetch(uri, {
      method: 'POST',
      headers: this.getHeaders(uuid()),
      body: JSON.stringify(reqBody)
    }
    )
      .then(response => response.json())
      .then(data => reslove(data))
      .catch(error => reject(error)));
  }

  public changePassword(password: UpdatePassword): Promise<MicroserviceResponse> {
    const uri = `${window.location.origin}/api/cbma/user/identity/services/useridentity/user/pwd/v2/change`;
    return new Promise((reslove, reject) => fetch(uri, {
      method: 'PUT',
      headers: this.getHeaders(uuid()),
      body: JSON.stringify(password)
    }
    )
      .then(response => response.json())
      .then(data => reslove(data))
      .catch(error => reject(error)));
  }

  public pwdResetComplete(): Promise<PwdResetCompleteResponse> {
    const reqBody = {
      userEmail: this.userEmail
    };
    const uri = `${window.location.origin}/api/cbma/user/identity/services/useridentity/user/pwd/temporary`;
    return new Promise((reslove, reject) => fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.masterUserEmail,
        'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      },
      body: JSON.stringify(reqBody)
    }
    )
      .then(response => response.json())
      .then(data => reslove(data))
      .catch(error => reject(error)));
  }

  public verifyAuthenticId(reqBody: VerifyAuthenticIdRequest): Promise<VerifyAuthenticIdResponse> {
    const uri = `${window.location.origin}/api/cbma/user/identity/services/useridentity/user/pwd/authenticid/verification`;
    return new Promise((reslove, reject) => fetch(uri, {
      method: 'POST',
      headers: this.getHeaders(uuid()),
      body: JSON.stringify(reqBody)
    }
    )
      .then(response => response.json())
      .then(data => reslove(data))
      .catch(error => reject(error)));
  }

}
