<div [ngClass]="{'defocus' :verificationSuccess.displayMessage.show && verificationSuccess.displayMessage.id == 'verificationsuccess' && verificationSuccess.displayMessage.type === 'success' }">
  <app-validate-contact-header *ngIf="!validateContactService?.validateUserInfoContact"></app-validate-contact-header>

<ng-container *ngIf="stepCount===1">
   <!-- Atleast one verified contact method section -->
  <ng-container *ngIf="verifiedContactMethods?.length > 0">
    <div class="text-center">
      <span>{{stringUtilService.formatByPlaceHolder(resourceBundle.pageDescriptionContactSelected,[verifiedContactMethods?.length.toString()])}}</span>
    </div>
    <div class="mt-4 pt-2 pb-2 mb-4 dead-center">
      <div class="wide-auto oneColumnGrid">
        <ng-container *ngFor="let verifiedContact of verifiedContactMethods; let i = index">
          <div [ngClass]="{'mt-2 pt-2': i !== 0}">
            <img src="assets/images/blueprint/icons/alert_success.svg" class="mr-3 pr-1 align-bottom" alt="Success Icon" />
            <span>{{verifiedContact.contactValue | phone}}</span>
            <span class="pl-1" *ngIf="verifiedContact?.contactType === contactType.PHONE">
              ({{resourceBundle.mobileNum}})</span>
          </div>
        </ng-container>
      </div>
    </div>
    <hr *ngIf="!isMobile">
    <div class="pt-2" [ngClass]="{'mt-4': !isMobile, 'mt-2': isMobile}">
      <div class="text-center">{{resourceBundle.additionalContactText}}</div>
      <ng-container *ngTemplateOutlet="contactMethodsRadioOptions"></ng-container>
    </div>
  </ng-container>

  <!-- No verified Contact Methods Section -->
  <ng-container *ngIf="!verifiedContactMethods?.length">
    <div [ngClass]="{'modal-body': validateContactService?.validateUserInfoContact}">
      <div class="text-center">
        <span
          *ngIf="!validateContactService?.validateUserInfoContact">{{resourceBundle.pageDescriptionNoContactSelected}}</span>
        <span *ngIf="validateContactService?.validateUserInfoContact">
          {{(unVerifiedContactMethods.length === 1) ? resourceBundle.pagedesclenghtequalsto1 :
          resourceBundle.pagedesclenghtmorethan1}}</span><br>
        <div *ngIf="unVerifiedContactMethods?.length===1" class="mt-4 pt-3">
          <span>{{unVerifiedContactMethods[0].contactValue | phone}}</span><span class="pl-1"
            *ngIf="unVerifiedContactMethods[0]?.contactType === contactType.PHONE">({{resourceBundle.mobileNum}})</span>
        </div>
      </div>
      <div class="ml-4 mt-2 pt-2 dead-center"
        *ngIf="unVerifiedContactMethods?.length===1 && showMobileOptionsFlag && unVerifiedContactMethods[0]?.contactType === contactType.PHONE">
        <div>
          <ng-container *ngTemplateOutlet="phoneContactMethodsRadioOptions"></ng-container>
        </div>
      </div>
      <ng-container
        *ngIf="(unVerifiedContactMethods?.length>1 && validateContactService?.validateUserInfoContact) || !validateContactService?.validateUserInfoContact">
        <ng-container *ngTemplateOutlet="contactMethodsRadioOptions"></ng-container>
      </ng-container>
    </div>
  </ng-container>

  <div *ngIf="!validateContactService?.validateUserInfoContact" class="text-center mb-5" [ngClass]="{'mt-4': !isMobile, 'mt-3': isMobile}">
    <button *ngIf="isMobile" class="button btn-long-text button-primary wide-auto track-clicks" [value]="resourceBundle.sendVerificationCode" (click)="onClickofSendverificationcode()">{{resourceBundle.sendVerificationCode}}</button><br>
    <a href="javascript:void(0)" [routerLink]="[resourceBundle.dashboard]" 
     >{{resourceBundle.validateLaterLink}}</a>
    <button *ngIf="!isMobile" class="ml-5 button btn-long-text button-primary wide-auto track-clicks" [value]="resourceBundle.sendVerificationCode" (click)="onClickofSendverificationcode()">{{resourceBundle.sendVerificationCode}}</button>
  </div>

  <div *ngIf="validateContactService?.validateUserInfoContact">
    <hr class="hr">
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 display-flex mb-1"
      [ngClass]="{'flex-column-reverse align-items-center': isMobile, 'justify-content-flexEnd': !isMobile}">
      <button id="closebutton" class="button wide-auto button-secondary width-228-px pr-0 pl-0"
       [ngClass]="isMobile ? 'mt-0 mb-3' : 'margin-right-20px'"
        (click)="close()">{{resourceBundle.cancel}}</button>
      <button type="button" class="button button-primary width-228-px pr-0 pl-0 track-clicks"
       [ngClass]="{'mr-2': !isMobile}" [value]="resourceBundle.sendVerificationCode"
        (click)="onClickofSendverificationcode()">{{resourceBundle.sendVerificationCode}}</button>
    </div>
  </div>

  <ng-template #contactMethodsRadioOptions>
      <div class="mt-4 dead-center pt-2" [ngClass]="{'pt-3': !isMobile}">
        <ng-container *ngIf="validateContactService.contentLoaded; else loadSkeletonRadioOptionsTemplate">
          <form>
            <ng-container *ngFor="let contact of unVerifiedContactMethods; let i = index">
              <input id="contactMethodRadio" tabindex="0" type="radio" attr.aria-label="{{resourceBundle.radioBtnGroup}} 
                    {{resourceBundle.grouping}} {{contactTypeAcc[contactMethod]}}" [value]="contact?.contactType"
                [ngClass]="{'pt-2 mt-2': i !== 0}" name="contactMethod" [(ngModel)]="contactMethod"
                (change)="onClickOfContactMethodType()">
              <label for="{{contact?.contactType}}" [ngClass]="{'pt-2 mt-2': i !== 0, 'mr-0': 'isMobile','width-17 pl-2 row-text-overflow' : isMobile && isPageZoomedto400}">{{isMobile ? (contact?.contactValue | phone | truncate:[30, '...']) : (contact?.contactValue | phone)}}<span
                  class="pl-1" *ngIf="contact?.contactType === contactType.PHONE">({{resourceBundle.mobileNum}})</span></label><br>
              <div class="ml-5 mt-2 pt-2" *ngIf="showMobileOptionsFlag && contact?.contactType === contactType.PHONE">
                <ng-container *ngTemplateOutlet="phoneContactMethodsRadioOptions"></ng-container>
              </div>
            </ng-container>
          </form>
        </ng-container>
      </div>
  </ng-template>

  <ng-template #phoneContactMethodsRadioOptions>
    <form>
      <input tabindex="0" type="radio" attr.aria-label="{{resourceBundle.mobRadioBtnGrp}} 
                                  {{resourceBundle.grouping}} {{resourceBundle.textMessage}}" value="textMessage"
        name="mobileContactOption" [(ngModel)]="mobileContactOption">
      <label for="textMessage">{{resourceBundle.textMessage}}</label><br>
      <input class="mt-1 pt-1" tabindex="0" type="radio" attr.aria-label="{{resourceBundle.mobRadioBtnGrp}} 
                              {{resourceBundle.grouping}} {{resourceBundle.phoneCall}}" value="phoneCall"
        name="mobileContactOption" [(ngModel)]="mobileContactOption">
      <label class="mt-1 pt-1" for="phoneCall">{{resourceBundle.phoneCall}}</label><br>
    </form>
  </ng-template>

  <ng-template #loadSkeletonRadioOptionsTemplate>
    <div [ngClass]="(!isMobile && validateContactService?.validateUserInfoContact) ? 'col-lg-7 col-md-7 col-sm-7 col-xs-7' : 'col-lg-4 col-md-4 col-xs-4 col-sm-4'" >
      <app-skeletonloader formName="contactMethodForm" formSkeleton="true" rowCount="3"></app-skeletonloader>
    </div>  
  </ng-template>
</ng-container>
<ng-container *ngIf="stepCount===2">
  <app-code-validation (codeValidateSuccess)="codeValidated()"></app-code-validation>
</ng-container>
</div>

<app-commonmessage [type]="'success'" [timeout]="5000" [showSuccessTimeout]="true" [level]="'overlaySuccessMsg'"
[accordionTitleMsg]="verificationSuccess.displayMessage.message"
*ngIf="verificationSuccess.displayMessage.show && verificationSuccess.displayMessage.id == 'verificationsuccess' && verificationSuccess.displayMessage.type === 'success'">
</app-commonmessage>