/* eslint-disable max-classes-per-file */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
export enum MenuType {
	MYACCOUNT_HOME = 'MYACCOUNT_HOME',
	MYADMIN_HOME = 'MYADMIN_HOME',
	USERS_AND_ACCOUNTS = 'USERS_AND_ACCOUNTS',
	COX_USER_MANAGEMENT = 'COX_USER_MANAGEMENT',
	SERVICE_ORDERS = 'SERVICE_ORDERS',
	CORE = 'CORE',
	COPYRIGHT_INFRINGEMENT = 'COPYRIGHT_INFRINGEMENT',
	HELP_AND_SUPPORT = 'HELP_AND_SUPPORT',
	VIEW_AND_PAY_BILL = 'VIEW_AND_PAY_BILL',
	ACCESS_COX_BUSINESS_EMAIL = 'ACCESS_COX_BUSINESS_EMAIL',
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
	LOGOUT = 'LOGOUT',
	CAPP = 'CAPP',
	PRODUCT_ADMINISTRATION = 'PRODUCT_ADMINISTRATION',
	LOG_IN_AS = 'LOG_IN_AS',
	CBCC = 'CBCC',
	NOCAAS = 'NOCAAS',
	MANAGE_PROFILE = 'MANAGE_PROFILE',
	VOICEMAIL = 'VOICEMAIL',
	VOICE_TOOLS = 'VOICE_TOOLS',
	DATA_TOOLS = 'DATA_TOOLS',
	PROFILE_ADMINISTRATION = 'PROFILE_ADMINISTRATION'
}

export interface MenuOption {
	firstName: string;
	headerOptions: Option[];
	supportOptions: Option[];
	footerOptions: Option[];
	voiceMailOption: Option;
	cbEmailOption: Option;
}

export interface Option {
	id: string;
	name: MenuType;
	displayName: string;
	enabled: boolean;
	openInNewWindow: boolean;
	url: string;
}

export class Profile {
	indicatorEvents: IndicatorEvents;
	unplannedOutageIndicator: boolean;
	pinpointAlerts?: PinpointAlerts;
	ticketsCount?: number;
	ticketsList?: Ticket[];
	hasServiceAppointment?: ServiceAppointment;
	deviceInstallDetails?: SelfInstallStatus;
	profileStatus?: ProfileStatus;
	isMultiAccount?: boolean;
	cbccTrialAlert?: CbccTrialAlert;
}

export interface CbccTrialAlert {
	alertStatus: boolean;
	trialEndDate: string;
	lastTrialAccountNum: string;
}

export interface IndicatorEvents {
	plannedOutageIndicator: boolean;
	promotionalOutageIndicator: boolean;
}

export interface SelfInstallStatus {
	selfInstallStatus: string;
	euAcctGuid: string;
}

export interface ProfileStatus {
	hasFormerAccount: boolean;
	hasActiveAccount: boolean;
	hasNeverAccount: boolean;
}
export interface ServiceAppointment {
	accountGuid: string;
	date: string;
	isMultiple?: boolean;
	time: string;
}

export interface PinpointAlerts {
	indicator: boolean;
	alertsList: Alert[];
}

export interface Alert {
	action: string;
	message: string;
}

export interface Ticket {
	createDateTime: string;
	id: string;
	requestType: string;
	statusType: string;
	truckRoll: string;
	workOrderNumber: string;
}

export interface TicketDTO {
	ticketsCount: number;
	vipCustomer: boolean;
	ticketsList: Ticket[];
}

export class DigitalProfileData {
	headerNotifications: Profile;
	headerTickets?: TicketDTO;
}

export enum HeaderLinks {
	ACCOUNT_DROPDOWN = 'AccountDropDown',
	MENU_LINKS = 'MenuLinks',
	ALERT_LINKS = 'AlertLinks',
	SUPPORT_LINKS = 'SupportLinks'
}

export interface BillingSummary {
	balanceDue: number;
	dueDate: string;
	pastDueStementCount: number;
	accountCount: number;
	currentStatement: Statement[];
	pastDueStatements: Statement[];
}

export interface Statement {
	accountNumber: string;
	statementCode: string;
	statementPdfId: string;
	cycleDate: string;
}

export interface Support {
	noCaas: boolean;
	cbCare: CBCare;
}

export interface CBCare {
	description: [];
	passCode: string;
	title: string;
}

export enum ModelTitle {
	Complete_Care = 'Complete Care',
	Complete_Care_Proactive = 'Complete Care Proactive',
	Complete_Care_HelpDesk = 'Complete Care Helpdesk',
	Complete_Proactive_HelpDesk = 'Complete Care Proactive and Help Desk'
}

export interface UpdatePasscode {
	id: string;
	passCode: string;
}

export enum FeatureName {
	CBCCOPTIN = 'CBCCOPTIN',
	ALL = 'ALL'
}

export enum NewRelicAttributes {
	CompanyProfile = 'CompanyProfile',
	UserID = 'UserID',
	VisitorType = 'VisitorType'
}

export enum VisitorType {
	CSR = 'CSR',
	CUSTOMER = 'Customer'
}
