import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const XLS_TYPE = 'application/vnd.ms-excel';
import 'jspdf-autotable';
import { autoTable, RowInput } from 'jspdf-autotable';
import { jsPDF } from 'jspdf';
@Injectable()
export class ExportFileService {
constructor() { }
  public exportCsvOrExcelFile(json: object[], documentFileName: string, fileType: string = 'xlsx', column?: string[], sheetName: string = 'data'): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName ? sheetName : documentFileName)

    if (column && column.length > 0) {
      worksheet.addRow(column);
      json.forEach((data) => {
        const row = column.map((header) => data[header] ?? '');
        worksheet.addRow(row);
      })
    }
    else if (json && json?.length > 0) {
      const headers = Object.keys(json[0]);
      worksheet.addRow(headers);

      json.forEach(row => {
        const rowData = Object.values(row).map((value) => {
          if (Array.isArray(value) && value.length === 0) {
            return ' ';
          }
          return value;
        })
        worksheet.addRow(rowData);
      });
    }

    if (fileType === 'xlsx') {
      workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, documentFileName);
      })
    } else if (fileType === 'csv') {
      workbook.csv.writeBuffer().then((buffer: ArrayBuffer) => {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, documentFileName);
      })
    }
  }

public exportPdf(column: object[], data: object[],
                 filename: string, pageTitle: string,
                 // tslint:disable-next-line: no-any
                 pageContent?: string, htmlContent?: string, style?: any, rows?: object[]) {
  const doc = new jsPDF('p', 'pt');
  doc.addFont('./assets/fonts/cera-pro/CeraPro-regular.ttf', 'Cera-Pro-Regular', 'normal');
  doc.addFont('./assets/fonts/cera-pro/CeraPro-bold.ttf', 'Cera-Pro-Bold', 'bold');
  (doc as jsPDF & { autoTable: autoTable }).autoTable({
    theme: 'grid',
    head: [column],
    body: rows as RowInput[],
    styles: {
      cellPadding: style ? style.cellPadding : 10,
      minCellHeight: style ? style.minCellHeight : 10,
      fillColor: false,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
      fontSize: 8,
      textColor: 20,
      font: 'cerapro-regular, Helvetica, sans-serif',
    }, margin: {top: 80},
  });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setTextColor(0, 0, 255);
  doc.setFontSize(12);
  doc.setFont('Cera-Pro-Bold','bold');
  doc.setFont('cerapro-regular, Helvetica, sans-serif');
  doc.text(pageTitle, pageWidth / 2, 20, {align: 'center'});
  doc.text(pageContent, 1, 20, {align: 'center'});
  doc.html(htmlContent);
  doc.save(filename + '.pdf');
}
}
