import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common';
import { AEMClientService, AppConfig, CbHeaderService, CBHelperService, DeviceDetectorService, DigitalProfileSharedService, ResourceBundle } from 'common-ui-lib';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Option } from '../cb-header/cb-header.model';

@Component({
  selector: 'app-default-footer',
  templateUrl: './defaultFooter.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./defaultFooter.component.scss']
})

export class DefaultFooterComponent implements OnInit {

  @ViewChild('footerLinkPopover') footerLinkPopover: NgbPopover;
  public resourceBundle: ResourceBundle = {};
  public currentYear: string;
  public imageToggle = '';
  public popoverList = null;
  public commonTaskPopoverList = null;
  public reduceLoginPadding = true;
  public selectedLinks = [];
  public contactCustomerNumber: string;
  public contactSalesNumber: string;
  public isPopoverListOpen = false;
  private onDestroy$ = new Subject<boolean>();
  private commonTasksList = ['View & Pay Bill', 'Voice Tools', 'Voicemail', 'Data Tools', 'Profile Administration'];
  constructor(
    public aemClient: AEMClientService,
    public cbHelper: CBHelperService,
    public digitalService: DigitalProfileSharedService,
    public cbHeaderService: CbHeaderService,public deviceService: DeviceDetectorService,
    private config: AppConfig,
    private router: Router) {
    this.aemClient.getSharedBundle('defaultFooter')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
        this.resourceBundle = data;
      }
      );
    this.router.events.subscribe(val => {
      this.reduceLoginPadding = (window.location.href.indexOf('unauth/login') > -1) ? false : true;
      if (!this.reduceLoginPadding && document.getElementById('cbDefaultFooter')) {
        document.getElementById('cbDefaultFooter').style.paddingTop = '40px';
      }
      const dashboardPadding = (window.location.href.indexOf('/dashboard') > -1) ? false : true;
      if (dashboardPadding && document.getElementById('cbDefaultFooter')) {
        document.getElementById('cbDefaultFooter').style.paddingTop = '20px';
      } else {
        if (document.getElementById('cbDefaultFooter')) {
          document.getElementById('cbDefaultFooter').style.paddingTop = '0px';
        }
      }
      const tileView = (window.location.href.indexOf('/settings') > -1) ? true : false;
      if (tileView && document.getElementById('cbDefaultFooter')) {
        document.getElementById('cbDefaultFooter').style.paddingTop = '0px';
      }
    });
  }

  @HostListener('window:click', ['$event'])
  @HostListener('window:keydown.enter', ['$event'])
  public onMouseClick(event) {
    if(!document.getElementById('search-field')?.classList.contains('hide') &&
     document.getElementsByClassName('search-wrapper')[0] &&
     this.cbHelper.isUserAuthenticated() && (!this.cbHelper.isMyAdmin() || this.cbHelper.isProfileInContext())) {
      document.getElementsByClassName('defaultFooter-gradientline')[0]?.classList.add('search-overlap');
      document.getElementsByClassName('bottom-footer')[0]?.classList.add('search-overlap');
    } else {
      document.getElementsByClassName('defaultFooter-gradientline')[0]?.classList.remove('search-overlap');
      document.getElementsByClassName('bottom-footer')[0]?.classList.remove('search-overlap');
    }
  }

  ngOnInit(): void {
    this.contactCustomerNumber = this.config.getConfig('contactCustomerNumber');
    this.contactSalesNumber = this.config.getConfig('contactSalesNumber');
    this.currentYear = formatDate(new Date(), 'yyyy', 'en');
    this.cbHeaderService.getFooterDetails().subscribe((response: Option[]) => {
      if (response) {
        this.commonTaskPopoverList = response.filter(task => task.enabled);
      }
    });
  }

  public isPopoverOpen(isOpen: boolean, linkName: string) {
    if (isOpen) {
      document.querySelector('.defaultFooterPopover')?.setAttribute('role', 'presentation');
      document.querySelector('.footer-sublinks').removeAttribute('aria-describedby');
      this.isPopoverListOpen = isOpen;
      this.imageToggle = 'open' + linkName;
      switch (linkName) {
        case 'CommonTasks': {
          this.popoverList = [];
          break;
        }
        case 'Help': {
          this.popoverList = this.resourceBundle.Help;
          break;
        }
        case 'AboutUs': {
          this.popoverList = this.resourceBundle.AboutUs;
          break;
        }
        case 'Social': {
          this.popoverList = this.resourceBundle.Social;
          break;
        }
        case 'Legal': {
          if (this.cbHelper.isCalnet) {
            this.popoverList = [];
            this.resourceBundle.Legal.forEach((element) => {
              if (element.linkName !== 'All Pricing and Plans') {
                this.popoverList.push(element);
              }
            });
          } else {
            this.popoverList = this.resourceBundle.Legal;
          }
          break;
        }
        case 'Companies': {
          this.popoverList = this.resourceBundle.Companies;
          break;
        }
        case 'Partners': {
          this.popoverList = this.resourceBundle.Partners;
          break;
        }
      }
    } else {
      this.imageToggle = 'close' + linkName;
    }

  }

  public navigateOnClick(url: string, tab: string, linkName?: string) {
    if (linkName && this.commonTasksList.includes(linkName)) {
      if (linkName === 'View and Pay Bill') {
        this.router.navigate([url], { queryParams: { payBill: true } });
      }
      if (linkName === 'Voice Tools') {
        if (url === this.resourceBundle?.ringCentralLoginUrl) {
          window.open(url, '_blank');
        } else {
          this.router.navigate([url]);
        }
      } else {
        this.router.navigate([url]);
      }
    } else {
      window.open(url, tab);
    }
  }

  public onClickofCommonTasksUrl(path: string, linkName?: string, openInNewWindow?: boolean) {
    let url;
    if (path?.startsWith('https') || path?.startsWith('http')) {
      openInNewWindow ? window.open(path) : window.open(path, '_self');
    } else if (path && openInNewWindow) {
      url = `${window.location.origin}/cbma${path}`;
      window.open(url);
    } else if (linkName === 'View and Pay Bill') {
      this.router.navigate([path], { queryParams: { payBill: true } });
    } else {
      this.router.navigate([path]);
    }
  }
}
