import { inject, Type } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { PostLoginService } from "boot/postlogin/postlogin.service";
import { AppConfig } from "common-ui-lib";

export const MaintenanceGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const _router = inject(Router);
    const loginService = inject(PostLoginService);
    const config = inject(AppConfig);
    const features: string[] = (route.component as Type<any>).prototype.coxMWdependencies;
    console.log("COMPONENT REQUIRED SERVICES1", features);
    if (loginService.isFeaturesUnderMaintenance(features)) {
      _router.navigate(["/home/maintenance"]);
    }
    return true;
  }

  export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return MaintenanceGuard(route, state);
  }
