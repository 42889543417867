import { CookieService } from 'ngx-cookie-service';
import { v4 as uuid } from 'uuid';
import { LocalStorageService } from 'ngx-localstorage';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseAccount, AppConfig, CBHelperService, LocalStorageKeys } from 'common-ui-lib';
import { UserProfile } from 'common-ui-lib';
import { MyServiceObj, SulaObj, ServiceObj, MyServicesResponse, OnlineStorageObj, securitySuiteObj, VoiceManagerDetailsObj, AccountDetailsObj, WorkOrderDetailsObj } from './myservice.model';
import { map } from 'rxjs/operators';

@Injectable()
export class MyService {

  decodedtoken: any;
  tokenPayload: any;
  loggedInUserType: any;
  masterUserEmail: any;
  jwtHelper: JwtHelperService = new JwtHelperService();
  hideParent: Boolean = false;
  selectedAccount: BaseAccount;
  headers = new HttpHeaders();
  loginresponse: any;
  token: any;
  profileCheck: Boolean = false;
  extendedRoles: any = [];
  refreshPage: Boolean = false;
  isAuthorizedUser: Boolean;
  loginProfileUri = this.config.getConfig('APIGW')['baseURL'] +
    this.config.getConfig('APPURI')['userProfileV3'];

  constructor(
    private httpClient: HttpClient,
    private http: HttpClient,
    private cookieService: CookieService,
    private config: AppConfig,
    private sessionStorage: SessionStorageService,
    private _storageService: LocalStorageService,
    private cbHelper: CBHelperService,
  ) {
    this.decodedtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    this.tokenPayload = this.jwtHelper.decodeToken(this.decodedtoken);
    this.loggedInUserType = this._storageService.get('IMPERSONATOR_TYPE', 'MA');
    this.masterUserEmail = this.tokenPayload.sub;
    this.sessionStorage.store('accountLevelSelected', this.profileCheck);
  }

  public getExtendedAccountDetails(selectedAccountNumber16: any) {
    const impersonateAccountReqBody = {
      accountNumber16 : selectedAccountNumber16,
      loggedInUserProfile: this.loginresponse,
    };
    this.profileCheck = true;
    this.sessionStorage.store('accountLevelSelected', this.profileCheck);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.masterUserEmail,
        'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      })
    };
    this.cbHelper.deleteCookies();
    let impersonateaccountUrl = '';
    if(this.cbHelper.isBroadsoftUpgradeEnabled('loginprofiles/v2/impersonateAccount')){
      impersonateaccountUrl = '/api/cbma/userauthorization/services/loginprofiles/v2/impersonateAccount';
    } else {
      impersonateaccountUrl = '/api/cbma/userauthorization/services/loginprofiles/v1/impersonateAccount';
    }
    return this.httpClient.post<MyServicesResponse>
    (impersonateaccountUrl, impersonateAccountReqBody,
      { headers: httpOptions.headers, observe: 'response' })
      .map((response: any) => {
        if (response.body.code === '0') {
          let authToken = response.headers.get('CBMA_AUTHTOKEN');
          let token = this.jwtHelper.decodeToken(authToken);
          this.extendedRoles = JSON.parse(token.profile).roles;
          this.isAuthorizedUser = response.body.isAuthorizedUser;
        }
      });
  }


  public getLoginProfileDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.masterUserEmail,
        'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      })
    };
    this.cbHelper.deleteCookies();
    let loginDetailsUrl = '';
    if (this.cbHelper.isBroadsoftUpgradeEnabled('loginprofiles/v1/login ')) {
      loginDetailsUrl = '/api/cbma/userauthorization/services/loginprofiles/v1/login ';
    } else {
      loginDetailsUrl = '/api/cbma/userauthorization/services/loginprofiles/login';
    }
    return this.httpClient.get<UserProfile>(loginDetailsUrl,
      httpOptions
    );
  }

  public getActiveAccountStatus(accountNumber12: any, profileFlag: Boolean): Observable<ServiceObj> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.masterUserEmail,
        'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      })
    };
    this.cbHelper.deleteCookies();
    let url = '';
    if (!profileFlag) {
      url = '/api/cbma/account/services/account/accounts/activeAccounts?accountNumber12=' + accountNumber12;
    } else {
      url = '/api/cbma/account/services/account/accounts/activeAccounts';
    }
    return this.httpClient.get<ServiceObj>(url, httpOptions);
  }

  public getLoginImpersonator(userEmail: string): Observable<UserProfile> {
    this.decodedtoken = this._storageService.get(LocalStorageKeys.Authtoken, 'cbma');
    this.tokenPayload = this.jwtHelper.decodeToken(this.decodedtoken);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.masterUserEmail,
        'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      })
    };
    const requestBody = { id: null, userEmail };

    return this.httpClient.post<any>(this.loginProfileUri, requestBody,
      { headers: httpOptions.headers, observe: 'response' }).pipe(map(response => {
        if (response.body.code === '0') {
          this._storageService.set(LocalStorageKeys.Authtoken, response.headers.get('cbma_authtoken'));
          this.cbHelper.processCredentials();
          return UserProfile.fromJSON(response.body);
        } else {
          throw Error;
        }
      }));
  }

  public getSulaDetails(accountNumber16: any): Observable<SulaObj> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.masterUserEmail,
        'CBMA_AUTHTOKEN':this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      })
    };
    this.cbHelper.deleteCookies();
    return this.httpClient.get<SulaObj>(
      '/api/cbma/account/services/account/sulaDetails/' + accountNumber16,
      httpOptions
    );
    // return this.httpClient.get<SulaObj>('../assets/stubdata/sulalist.json', httpOptions);
  }

  public getSecurityServiceDetails(accountNumber12: any): Observable<ServiceObj> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'MA_TRANSACTION_ID': uuid(),
        'clientid': this.config.getConfig('APIGW')['clientId'],
        'apikey': this.config.getConfig('APIGW')['apiKey'],
        'CB_SESSION': this.masterUserEmail,
        'CBMA_AUTHTOKEN': this._storageService.get(LocalStorageKeys.Authtoken, 'cbma'),
      }),
    };
    this.cbHelper.deleteCookies();
    return this.httpClient.get<ServiceObj>(
      '/api/cbma/user/services/user/securityServiceDetails?accountNumber12=' + accountNumber12,
      httpOptions
    );
    // return this.httpClient.get<SulaObj>('../assets/stubdata/sulalist.json', httpOptions);
  }

  public getExtendedRoles() {
    return this.extendedRoles;
  }
}
