import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs/Observable';
import { UpdatePasscode } from './getsupportservice.model';
import { CoxHttpClient, MicroserviceResponse } from 'common-ui-lib';

@Injectable({
    providedIn: 'root'
})
export class GetSupportService {

    constructor(private apollo: Apollo,
        private coxHttpClient: CoxHttpClient) { }

    public updatePasscode(updatePasscode: UpdatePasscode): Observable<MicroserviceResponse> {
        const updatePassCodeUrl = `/api/cbma/profile/services/profile/customercare/passcode`;
        return this.coxHttpClient.post<MicroserviceResponse>(updatePassCodeUrl, updatePasscode,
            { disableSpinner: true, customeErrorMessage: '' });
    }
}