<div class="modal-content" role="dialog" aria-labelledby="dialogHeader">
    <div class="modalheader pt-3 pb-3">
      <span class="h4" id="dialogHeader" attr.aria-label="{{npdDetails.action}} heading level 4" *ngIf = "npdAlert">{{npdDetails.action}}</span>
      <span class="h4" id="dialogHeader" attr.aria-label="{{paperlessBillDetails.action}} heading level 4" *ngIf = "paperlessBill">{{paperlessBillDetails.action}}</span>
      <a href="javascript:void(0)" aria-label="Close" role="button" class="cb-modal-close-btn float-right"
        (click)="close('Close click');"></a>
    </div>
    <div class="modal-body">
        <div *ngIf = "npdAlert" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mb-3">
          {{npdDetails.message}}
        </div>
        <div *ngIf = "paperlessBill" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mb-3" [innerHTML]="paperlessBillDetails.message">
          
        </div>
    </div>
    <hr class="hr m-0">
    <div class="row margin-top-10 margin-right-25 margin-left-25 " *ngIf = "npdAlert" >
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0" [ngClass]="{'cursor-notAllowed': isMyAdmin}">
          <div id="npd-oliver" (click)="triggerTealium('NPD-Chat-Now')" role="link" class="button button-secondary display-inline-block chat-trigger"
          [attr.data-chat-text]="'Payment Arrangement'" [ngClass]="{'defocus': isMyAdmin}" [attr.tabindex]="isMyAdmin ? '-1' : '0'"
          >Chat Now                
            </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
        <button class="button btn-long-text pull-right" [disabled]="isMyAdmin" (click)="navigateToPayments()">Make a Payment</button>
      </div>
    </div>

    <div class="row margin-right-25 margin-left-25 mb-2" *ngIf = "paperlessBill" >
      <div class="col-md-12 display-flex justify-content-flexEnd p-0" >
        <a href="javascript:void(0)" (click)="close('close click')" tabindex="0"
        class="ml-5 pl-1 mt-4 pt-1"><U>Cancel</U>
        <span>
          <img src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon" role="presentation" class="width-20px mt-n1">
      </span></a>
        <button *ngIf="!digitalService.isConsolidated" type="button" autofocus id="continueBtn" class="button btn-long-text ml-4" (click)="navigateToBilling()" tabindex="0">Sign up Today</button>
        </div>
    </div>
</div>
