import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AppConfig {

  public mapId = new Map();
  private config: object = null;
  private appconfig: object = null;
  private env: object = null;
  private myAccountAppConfig: object = null;
  private myAccountRolesAppConfig: object = null;
  private serverErrMsg = 'Server error';
  private message: object = null;
  private configList = ['assets/config/config.json', 'assets/config/external_urls.json'];
  constructor(private http: HttpClient
  ) {

  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  public getMessage(key: any) {
    return this.message[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * Use to get the data found in the application config file (application.config file)
   */
  public getAppConfig(key: any) {
    return this.appconfig[key];
  }

  /**
   * Use to get the data found in the Myaccount aconfig file (myaccount.config file)
   */
  public getMyAccountAppConfig(key: any) {
    return this.myAccountAppConfig[key];
  }

  /**
   * Use to get the data found in the Myaccount aconfig file (myaccount.config file)
   */
  public getloadMyAccountRolesAppConfig(key: any) {
    return this.myAccountRolesAppConfig[key];
  }

  public getloadPageIdMappings(key: any) {
    return this.mapId.get(key);
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      const isProduction = environment['production'];
      console.log(isProduction);
      // if(isProduction){
      //   this.configList =
      //   [environment.cdnUrl+'assets/config/config.json', environment.cdnUrl+'assets/config/external_urls.json'];
      // }
      for (const config of this.configList) {
      const request = this.http.get(config);

      if (request) {
        request
          .catch((error: any) => {
            console.error('Error reading configuration file');
            resolve(error);
            return Observable.throw(error.json().error || this.serverErrMsg);
          })
          .subscribe((responseData: any) => {
            this.config = { ...this.config, ...responseData };
            resolve(true);
          });
      } else {
        console.error('Env config file "config.json" is not valid');
        resolve(true);
      }
    }
    });
  }
  /**Loading Form Validations Save Message **/
  public loadMessages() {
    return new Promise((resolve, reject) => {
      let request: any = null;
      request = this.http.get('assets/config/messages.json');

      if (request) {
        request
          .catch((error: any) => {
            console.error('Error reading configuration message file');
            resolve(error);
            return Observable.throw(error.json().error || this.serverErrMsg);
          })
          .subscribe((responseData: any) => {
            this.message = responseData;
            resolve(true);
          });
      } else {
        console.error('Env config file "messages.json" is not valid');
        resolve(true);
      }

    });
  }
  /*
  Load application properties
   */
  public loadAppConfig() {
    return new Promise((resolve, reject) => {
      let request: any = null;

      request = this.http.get('assets/config/application.config.json');

      if (request) {
        request
          .catch((error: any) => {
            console.error('Error reading application configuration file');
            resolve(error);
            return Observable.throw(error.json().error || this.serverErrMsg);
          })
          .subscribe((responseData: any) => {
            this.appconfig = responseData;
            resolve(true);
          });
      } else {
        console.error('App config file "application.config.json" is not valid');
        resolve(true);
      }

    });
  }

  /*
  Load application properties
   */
  public loadMyAccountAppConfig() {
    return new Promise((resolve, reject) => {
      let request: any = null;

      request = this.http.get('assets/config/myaccount.config.json');

      if (request) {
        request
          .catch((error: any) => {
            console.error('Error reading application configuration file');
            resolve(error);
            return Observable.throw(error.json().error || this.serverErrMsg);
          })
          .subscribe((responseData: any) => {
            this.myAccountAppConfig = responseData;
            resolve(true);
          });
      } else {
        console.error('App config file "application.config.json" is not valid');
        resolve(true);
      }

    });
  }

  /*
  Load application roles
   */
  public loadMyAccountRolesAppConfig() {
    return new Promise((resolve, reject) => {
      let request: any = null;

      request = this.http.get('assets/config/shared/myaccountroles.json');

      if (request) {
        request
          .catch((error: any) => {
            console.error('Error reading application configuration file');
            resolve(error);
            return Observable.throw(error.json().error || this.serverErrMsg);
          })
          .subscribe((responseData: any) => {
            this.myAccountRolesAppConfig = responseData;
            resolve(true);
          });
      } else {
        console.error('App config file "application.config.json" is not valid');
        resolve(true);
      }

    });
  }

  public loadPageIdMappings() {
    return new Promise((resolve,reject) => {
      let request: any = null;

      request = this.http.get('assets/config/pageidmapping.json');

      if (request) {
        request
          .catch((error: any) => {
            console.error('Error reading application configuration file');
            resolve(error);
            return Observable.throw(error.json().error || this.serverErrMsg);
          })
          .subscribe((responseData: any) => {
            for(let i=0; i<responseData.pageIdMapping.length;i++) {
              this.mapId.set(responseData.pageIdMapping[i].location, responseData.pageIdMapping[i].pageid);
            }
            resolve(true);
          });
      } else {
        console.error('App config file "application.config.json" is not valid');
        resolve(true);
      }
    });
  }
}