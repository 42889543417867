<div class="modal-body modal-content c-btn scrollbox">
  <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">
    <h5 class="bold mt-1 mb-3"> {{resourceBundle.orderId}} #{{selfInstallService.workOrderId}} </h5>
    <span class="my-3">{{resourceBundle.orderPendingDescription}}</span>
  </div>
  <ngx-datatable class="dataTable bootstrap" id="orderConfirmationDatatable" #orderConfirmationDatatable
    *ngIf="!selfInstallService.isMobileDevice && selfInstallService?.orderDetailsEquipmentRows?.length > 0" [rows]="selfInstallService?.orderDetailsEquipmentRows"
    [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="'auto'" [footerHeight]="'auto'"
    comnAccessTable [noofRows]="selfInstallService?.orderDetailsEquipmentRows?.length"
    [tableName]="'Order Confirmation Details'" role="table" [table]="orderConfirmationDatatable">
    <ngx-datatable-column prop="name" name="Equipment" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <ngx-datatable class="dataTable bootstrap" id="orderConfirmationDatatable" #orderConfirmationDatatable
    *ngIf="selfInstallService.isMobileDevice && selfInstallService?.orderDetailsEquipmentRows?.length > 0" [rows]="selfInstallService?.orderDetailsEquipmentRows"
    [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="'auto'" [footerHeight]="'auto'"
    comnAccessTable [noofRows]="selfInstallService?.orderDetailsEquipmentRows?.length"
    [tableName]="'Order Confirmation Details'" role="table" [table]="orderConfirmationDatatable">
    <ngx-datatable-column prop="name" name="" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
        <div class="bold">{{resourceBundle.equipment}}</div>
        <div>{{value}}</div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>