import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AEMClientService } from '../../services/aemclient.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-maintenance-page',
  template: `
    <div [innerHtml]="content"></div>
  `,
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  @Input() content: string;
  @Input() featureName: string;
  @Output() mPageNotFound = new EventEmitter();
  show = false;
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService) {}

  ngOnInit() {
    if (this.featureName) {
    this.aemClient.getHTMLContent('voice', this.featureName + '_maintenance')
    .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (htmlData) => {
          this.content = htmlData;
        },
        (htmlError) => {
          this.mPageNotFound.emit(true);
        },
      );
    }
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
