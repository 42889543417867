import {
    AfterViewChecked, Component,
    Input, OnChanges, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
    CommonMessageService,
    DeviceDetectorService,
    CBHelperService, CbHeaderService, AppConfig, DigitalProfileSharedService
} from 'common-ui-lib';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuOption } from '../cb-header.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, AfterViewChecked, OnChanges {
    @ViewChild('mainMenuLinkPopover') mainMenuLinkPopover: NgbPopover;
    @Input()
    public menuItems: MenuOption;
    public leftAlignmentInPixel: number;
    public isCollapsed = false;
    public darkBackgroundArr = [
        'COPYRIGHT_INFRINGEMENT',
        'PRODUCT_ADMINISTRATION',
        'LOG_IN_AS',
        'LOGOUT',
    ];
    public isMobileDevice: boolean;
    public popoverPlacement = 'bottom';

    constructor(
        private router: Router,
        public commonMessageService: CommonMessageService,
        public deviceService: DeviceDetectorService,
        public cbHeaderService: CbHeaderService,
        private activatedRoute: ActivatedRoute,
        private cbhelperService: CBHelperService,
        private config: AppConfig,
        private digitalService: DigitalProfileSharedService
    ) {
        this.cbhelperService.initiateHeaderRefreshSub.subscribe((data) => {
            if (data) {
                this.ngOnInit();
            }
        });

        // this logic has been added when we do switch user or navigate to different pages
        // to fetch the data at compoent level
        router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            if (this.activatedRoute.snapshot.queryParamMap.get('switchuser')) {
                this.ngOnInit();
                this.mainMenuLinkPopover?.close();
                this.router.navigate([], { queryParams: { switchuser: null }, queryParamsHandling: 'merge' });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.menuItems) {
            this.getMenuDetails();
            if (!this.isMobileDevice) {
                const mainMenuElement = document.getElementById('main-menu');
                const mainMenuWidth = mainMenuElement?.offsetWidth;
                this.leftAlignmentInPixel = Math.trunc((155 * (-75)) / mainMenuWidth) + 2;
            }
        }
    }

    ngOnInit() {
        if (this.deviceService.isMobile()) {
            this.isMobileDevice = true;
        }
        this.getMenuDetails();
    }

    ngAfterViewChecked(): void {
        if (this.cbHeaderService.showMainMenu) {
            this.mainMenuLinkPopover?.open();
        }
    }

    // Query call to get menu list content
    public getMenuDetails() {
        this.digitalService.isConsolidatedAccount();
        if (this.menuItems?.headerOptions?.length) {
            if (this.digitalService.isConsolidated) {
                const itemId = this.menuItems.headerOptions.findIndex(item => item.name === 'VIEW_AND_PAY_BILL');
                this.menuItems.headerOptions.splice(itemId, 0);
            }
        }
    }

    // On click of list item navigate to respective path
    public navigateOnClick(path: string, openInNewWindow: boolean, name?: string) {
        const featureName = name.split('_').join('').toLowerCase();
        let url;
        this.closeSideBar();
        if (this.isCollapsed) {
            this.isCollapsed = false;
        }
        if (path?.startsWith('https') || path?.startsWith('http')) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            openInNewWindow ? window.open(path) : window.open(path, '_self');
        } else if (path && openInNewWindow) {
            url = `${window.location.origin}/cbma${path}`;
            window.open(url);
        } else {
            if (this.config.getConfig('featureNames')?.includes(featureName)) {
                this.router.navigate([path], { fragment: featureName });
            } else {
                const menuPath = path.includes('?') ? `${path}&path=header` : `${path}?path=header`;
                this.router.navigateByUrl(menuPath);
            }
        }
        this.mainMenuLinkPopover?.close();
        this.cbHeaderService.closeSideBar();
    }

    // Mobile view - to close the sidebar
    public closeSideBar() {
        if (this.deviceService.isMobile()) {
            this.cbHeaderService.closeSideBar();
        }
    }

    // Accessibility - close the menu when focus comes out of the list
    public outOfMegaMenu(event, elementId: string) {
        if (elementId === 'LOGOUT') {
            if (event.key === 'Tab' && !event.shiftKey) {
                this.mainMenuLinkPopover?.close();
                this.isCollapsed = false;
            }
        }
    }
}

