import { Component, OnInit } from '@angular/core';
import { AEMClientService, ResourceBundle } from '../../services/aemclient.service';
import { Message } from '../messages/message.component';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-oktamaintenance',
  templateUrl: './oktamaintenance.component.html',
})
export class OktamaintenanceComponent implements OnInit {
  public oktaMessage: Message = new Message();
  public resourceBundle: ResourceBundle = {};
  constructor(
    private aemClient: AEMClientService,
  ) {
    this.oktaMessage = new Message();
  }

  ngOnInit() {
    this.aemClient.getOktaMaintenanceContent('app')
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
          this.oktaMessage.show = true;
          this.oktaMessage.type = 'info';
          this.oktaMessage.message = this.resourceBundle.description +
            '<a href = "https://www.cox.com/business/contact-us.html" target="_blank">Customer Care</a>.';
        },
        (error) => {
          if (error.code === '404') {
            this.oktaMessage.show = false;
          }
        },
      );
  }
}
