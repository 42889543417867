import { RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * This class is deprecated, use UnsavedPromptService
 */
export abstract class ComponentCanDeactivate {
    constructor() {}
    abstract  canDeactivate(nextState?: RouterStateSnapshot): boolean;
    abstract handleRedirect(): Observable<boolean>;
}
