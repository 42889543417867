<app-loader></app-loader>
<app-oktamaintenance *ngIf="oktaFlag">
</app-oktamaintenance>
<div class="margin-top-2em-imp" *ngIf="!isMyAdmin">
    <div class="row mb-2">
    </div>

    <app-message *ngIf="loginMessage.show"
        [type]="loginMessage.type"
        [message]="loginMessage.message"
        role="alert"></app-message>
    <div class="row" *ngIf="(isMyAdmin) || (isHnPortal)">
        <h1 class="h2 login-title">
            <ng-template
                [ngIf]="isMyAdmin">
                {{resourceBundle.pageAdminTitle}}
            </ng-template>
            <ng-template
                [ngIf]="isHnPortal">
                {{resourceBundle.pageHnTitle}}
            </ng-template>
        </h1>
    </div>
    <!--HN View-->
    <div class="row" *ngIf="isHnPortal && !isExternalApp">
        <div class="col-12 col-md-7 d-none d-md-block description pt-0">
            <p> {{resourceBundle.welcomeHnText}}</p>
        </div>
        <div class="col-12 col-md-5 loginForm margin-top-neg-20">
            <div id="sign-in-widget" [ngClass]="{'loginHN': isHnPortal}"></div>
            <div class="col-lg-12 col-md-12 pad-left-p-14 margin-top-neg-10">
                <div>
                    <hr [ngClass]="isExternalApp? 'hr mb-2':'hr mt-3 mb-2'">
                    <span><b>Forgot<a
                                (click)="gotoUserIdRecovery()"
                                href="javascript:void(0)"
                                aria-label="Forgot User ID"
                                role="link"> User
                                ID</a><span> or
                            </span><a
                                href="javascript:void(0)"
                                aria-label="Forgot Password"
                                role="link"
                                (click)="redirectionHandler()">Password</a>?</b></span>
                    <hr [ngClass]="isExternalApp ? 'hr mb-2' : 'hr mt-3 mb-2'">
                </div>
                <b>
                    <p>Cox Business customer?<a (click)="businessLoginHN()" tabindex="0"> Sign In</a></p>
                </b>
                <b>
                    <p>Cox Residential customer?<a [attr.href]=" resourceBundle.hnResidentialCus">Sign In</a></p>
                </b>
            </div>
        </div>
    </div>
    <div class="mt-4" *ngIf="!isHnPortal && !isMyAdmin" [class]="mobileView ? '' : 'row'">
        <div class="webex-user mobile-xs-widget" [ngClass]="isExternalApp ? '' : 'margin-top-neg-20'">
            <div class="cox-logo__title" [ngClass]="{longTitle: isLongTitle}">
                <div class="display-flex">
                    <div [ngClass]="{'mr-4': loginTitle}">
                         <a (click)="goToBusinessLink()">
                            <img id="header-logo" class="webex-logo__coxbusiness" src="assets/images/blueprint/onboarding/CoxBusiness_logo.svg" alt="LOGO">
                        </a>
                    </div>                    
                    <div [class]="entryPointValue ? 'side-border' : ''">
                        <ng-container *ngTemplateOutlet="loginEntryPoint"></ng-container>
                    </div>
                </div>
            </div>
            <div *ngIf="!isExternalApp" class="cox-logo__title mt-3">
                <p><b>Cox Business Login</b></p>
            </div>
            <div id="sign-in-widget" [ngClass]="{'loginexternal': isExternalApp}"></div>
            <div class="pt-0 mt-0 mb-2">
                <div>
                    <hr class="hr  mb-2">
                    <div class="mb-3 mt-3" [class]="isExternalApp ? 'cox-logo__title' : 'ml-3' ">
                        <span><b>Forgot<a (click)="gotoUserIdRecovery()" href="javascript:void(0)" aria-label="Forgot User ID" role="link">
                                    User
                                    ID</a><span>
                                    or
                                </span><a href="javascript:void(0)" aria-label="Forgot Password" role="link"
                                    (click)="redirectionHandler()">Password</a>?</b></span>
                    </div>
                    <div *ngIf="!isExternalApp" [class]="isExternalApp ? '' : 'ml-3' ">
                        <span>
                            <b>
                                <p>Are you a Cox Residential customer?<span class="sr-only" id="signin">Cox
                                        Residential Sign
                                        in</span><br><a aria-labelledby="signin" [attr.href]="resourceBundle.residentialCusSignIn"
                                        role="link"> Sign
                                        in here</a>
                                </p>
                            </b>
                        </span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #loginEntryPoint let-row="data">
    <label for="title" class="bold login-custom__title" [ngClass]="{longTitle: isLongTitle, 'font-size-p-16 margin-top-20': (mobileView && isGravityMobileApp), 'font-size-1-5rem margin-top-20 ': (isGravityMobileApp && !mobileView) }">{{loginTitle}}</label>
</ng-template>
