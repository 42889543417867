/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export interface DigitalProfileResponse {
    ticketDetails: TicketDetails;
    billingProfile?: BillingProfile;
    billingSummary: BillingSummary;
    profileAssets: AssetsResponse;
    events: Event[];
    outageData?: OutageDetailsResponse;
    serviceLocations: ServiceLocations[];
    e911NotificationStatus: E911NotificationStatus; // Need for CBMA1-60462
    profileGuid?: string;
}
export interface E911NotificationStatus{
    e911OptedOut: boolean;
}

export interface AssetsResponse {
    assets: Asset[];
	serviceLocationAssets: ServiceLocationAssets[];
}

export interface ServiceLocationAssets {
    saSfdcId: string;
	assets: Asset[];
}

export interface OutageDetailsResponse {
    plannedOutageIndicator: boolean;
    unplannedOutageIndicator: boolean;
    lastFetchedDateTime: number;
    outages: Outage[];
}

export interface Outage {
    saSfdcId: string;
    outageDetails: OutageDetails[];
}

export interface OutageDetails {
   outageType: OutageType;
   outageDate: number;
   etr: number;
   eventTypeGroup: EventTypeGroup;
   notificationFlag: boolean;
   phoneNumber: string;
   symptoms: string;
   message: string;
   status: string;
}

export enum EventTypeGroup {
	BCP = 'BCP',
	TRENDING = 'TRENDING',
	CHRONIC = 'CHRONIC',
	UNPLANNED = 'UNPLANNED',
	MAINTENANCE = 'MAINTENANCE',
	NODEHEALTH = 'NODEHEALTH',
	NOOUTAGE = 'NOOUTAGE',
	PLANNEDMAINTENANCE = 'PLANNEDMAINTENANCE',
	MIDSPLIT = 'MIDSPLIT',
	HFC = 'HFC',
	NPLUSO = 'NPLUSO'
}

export enum OutageType {
    PLANNED = 'PLANNED',
    UNPLANNED = 'UNPLANNED'
}

export interface TicketDetails {
    totalCount: number;
    openCount: number;
    resolvedCount: number;
    tickets: Ticket[];
    vipCustomer: boolean;
}

export interface CbccTrialAlert {
    alertStatus: boolean;
    trialEndDate: string;
}

export interface Ticket {
    id: string;
    requestType: string;
    createDateTime: string;
    statusType: TicketStatus;
    truckRoll: string;
    workOrderNumber: string;
}

export enum TicketStatus {
    OPEN = 'Open',
    PENDING = 'Pending',
    RESOLVED = 'Resolved'
}
export interface Event {
    type: EventType;
    data: [EventData];
}

enum EventType {
    serviceappointment = 'SERVICEAPPOINTMENT'
}
export interface EventData {
    saSfdcId: string;
    serviceType: string;
    subType: string;
    scheduleDate: string;
    timeSlot: string;
    address: string;
    status: DeliveryTrackerStatus;
}

export enum Month {
    Month01 = 'January',
    Month02 = 'February',
    Month03 = 'March',
    Month04 = 'April',
    Month05 = 'May',
    Month06 = 'June',
    Month07 = 'July',
    Month08 = 'August',
    Month09 = 'September',
    Month10 = 'October',
    Month11 = 'November',
    Month12 = 'December'
}
export interface Asset {
    assetType: AssetType;
    assetFamily: AssetFamily;
    assetName: string;
    id: string;
    status: OrderStatus;
    feature: FeatureDetails[];
}

export interface SelfInstallStatus {
    selfInstallStatus: string;
    euAcctGuid: string;
}

export interface FeatureDetails {
    featureName: FeatureName;
    buyFlowEligible: boolean;
}

export enum FeatureName {
    MALBLOCK = 'MALBLOCK',
    NET_ASSURANCE = 'NET_ASSURANCE',
    TV_REPLACEMENT_PART = 'TV_REPLACEMENT_PART',
    RINGCENTRAL = 'RINGCENTRAL',
    VOICE_WITH_RINGCENTRAL = 'VOICE_WITH_RINGCENTRAL'
}

export interface FeatureInfo {
    featureName: FeatureName;
}

export enum AssetType {
    SERVICES = 'Services'
}

export enum AssetFamily {
    VOICE = 'VOICE',
    INTERNET = 'INTERNET',
    SECURITY = 'SECURITY',
    PHONE = 'PHONE',
    TV = 'TV',
    NETWORKING = 'NETWORKING',
    SUPPORT = 'SUPPORT'
}
export interface BillingSummary {
    balanceDue?: number;
    dueDate?: string;
    pastDueStatementCount?: number;
    currentStatementCount?: number;
    accountCount?: number;
    isIBill?: boolean;
    isSmartBill?: boolean;
    currentStatements?: Statement[];
    pastDueStatements?: Statement[];
}

export interface Statement {
    accountNumber: string;
    statementCode: string;
    statementPdfId: string;
    cycleDate: string;
}
export interface BillingProfile {
    profileGuid: string;
    name: string;
    billingSummary: BillingSummary;
}

export interface AddUpgradeServiceData {
    title: string;
    description: string;
}

export enum TimeSlotPriority {
    '8am - 10am' = 1,
    '10am - 12pm' = 2,
    '1pm - 3pm' = 3,
    '3pm - 5pm' = 4
}

export enum Features {
    BILLING = 'BILLING',
    OUTAGE = 'OUTAGE',
    BILLING_LARGE_PROFILE = 'BILLING_LARGE_PROFILE',
    OUTAGE_LARGE_PROFILE = 'OUTAGE_LARGE_PROFILE'
}

export interface EvictionRequest {
    sessionId: string;
    entityId: string;
    cacheType: CacheType;
    profileGuid: string;
    rebuild: boolean;
    evictionType: EvictionType;
}

export enum CacheType {
    BILLING_LARGE_PROFILE = 'PROFILE_BILLING_SUMMARY',
    OUTAGE_LARGE_PROFILE = 'PROFILE_OUTAGE'
}

export enum EvictionType {
    BY_SESSION_ID = 'BY_SESSION_ID',
    BY_PROFILE_GUID = 'BY_PROFILE_GUID',
    BY_PROFILE_CACHE_TYPE = 'BY_PROFILE_CACHE_TYPE',
    REFRESH = 'REFRESH'
}
export interface ServiceLocations {
    serviceLocationId: string;
    saSfdcId: string;
    name: string;
    address: Address;
    billingAccounts: BillingAccount[];
    profileGuid: string;
    status: string;
}

export interface Address {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    zipExtension: string;
    latitude: string;
    longitude: string;
    fullAddress: string;
    titleCaseAddress?: string;
}
export interface BillingAccount {
    accountGuid: string;
    billingAccountNumber: string;
}

export enum OrderStatus {
    INPROGRESS = 'INPROGRESS'
}

export interface OrderConnection {
    edges: OrderEdge[];
    totalCount: number;
}

export interface OrderEdge {
    cursor: string;
    node: Order;
}
export interface Order {
    orderId: string;
    orderNumber: string;
    products: Product[];
    selfInstallCount: number;
}

export interface Product {
    appointment: Event[];
    devices: Devices[];
}

export interface Devices {
    selfInstall: boolean;
    itemNumber: string;
    accountGuid: string;
    deliveryTracker: DeliveryTracker;
}

export interface DeliveryTracker {
    status: DeliveryTrackerStatus;
    trackingReferenceNumber: string;
    trackingUrl: string;
}

export enum DeliveryTrackerStatus {
    processing = 'PROCESSING',
    shipped = 'SHIPPED',
    scheduled = 'SCHEDULED',
    delivered = 'DELIVERED',
    installed = 'INSTALLED'
}

export interface PortalEventsTrack {
    selfInstall: boolean;
    outage: boolean;
}
