<div *ngIf="!isMobileDevice" class="titleIcon">
    <a href="javascript:void(0)" [ngbPopover]="messagesPopover" [placement]="popoverPlacement"
        #quickLinkPopover="ngbPopover" role="button" [autoClose]="true" class="messagesLink text-center"
        [popoverTitle]="alertsTitle" popoverClass="quickLinkPopover" (hidden)="isPopoverOpen(false)" [attr.aria-label]="alertCheck ? resourceBundle.availableNotifications : resourceBundle.messages"
        (shown)="isPopoverOpen(true)">
        <img src="assets/images/blueprint/admin_redesign/mymessages.svg" alt="Messages" />
        <span class="redDot" *ngIf="alertCheck"></span>
        <span class="headerTitle" id="msgsLink" *ngIf="!isMobileDevice">{{resourceBundle.messages}}</span>
    </a>
    <div id="closeMsgs" class="sr-only" aria-live="polite"></div>
</div>
<div *ngIf="isMobileDevice" id="messagelinkmobile">
    <a href="javascript:void(0)" aria-label="Messages" role="button" (click)="toggleMessageLink()" id="myMessagesLinkMobile">
        <img src="assets/images/blueprint/admin_redesign/mymessages.svg" alt="Messages" role="presentation"/>
        <span class="redDot" *ngIf="alertCheck"></span>
    </a>
</div>
<ng-template #alertsTitle>{{alert}}</ng-template>
<div class="wrapper" id="wrappermsgMobile" *ngIf="isMobileDevice">
    <!-- Sidebar -->
    <nav id="sidebar">
        <div class="col-lg-8 col-md-8 col-xs-8 mt-2">
            <div class="row">
                <h4 class="col-6 pl-0">{{alert}}</h4>
                <div class="col-6 pr-0">
                    <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn float-right mr-2"
                        (click)="closeSideBar()"></a>
                </div>
            </div>
        </div>
        <ul class="messageList margin-top-3em ul-style p-0 mb-2">
            <li *ngIf="alreadySwapped && !this.cbHelperService.isMyAdmin() && symphonyResponse?.swapResponse === validateSwap.Alreadyswapped"
                title="Already Swapped" class="msg-info outageAlert chat-trigger mb-2 pl-5 onFocusOutline" data-alert role="alert"
                tabindex="0" (click)="triggerTealium('Conatct-Us')">
                <label class="bold">{{resourceBundle.alreadySwapped}}</label><br>
                <span>{{resourceBundle.alreadySwappedDescription}}</span>
            </li>
            <li *ngIf="npdAlert && billingFlag" title="NPD Alert" class="msg-error outageAlert mb-2 pl-5" (click)="openNpdAlertModal()" data-alert role="alert" tabindex="0">
                <label class="bold">{{npdAlertDetails.action}}</label><br>
                 <span>{{npdAlertDetails.message | truncate:[30, '...']}}</span>
            </li>
            <li *ngIf="alerts?.unplannedOutageIndicator" title="Unplanned Outage" class="msg-error outageAlert mb-2 pl-5 pr-1" (click)="openUnplannedOutageModal()" data-alert role="button">
                <label class="bold display-block truncate">{{resourceBundle.unplannedOutage}}</label>
                <span>{{isMarketRollOutFlag ? resourceBundle.viewOutageMapLink : resourceBundle.clickToView}}</span>
            </li>
            <li *ngIf="alerts?.indicatorEvents?.plannedOutageIndicator" 
                title="Planned Outage" class="msg-alert outageAlert mb-2 pl-5 pr-1" 
                (click)="openPlannedOutageModal()" data-alert role="button">
                <label class="bold display-block truncate">{{resourceBundle.plannedOutage}}</label>
                <span>{{isMarketRollOutFlag ? resourceBundle.viewOutageMapLink : resourceBundle.clickToView}}</span>
            </li>
            <li *ngIf="swapAlert && !this.cbHelperService.isMyAdmin()" title="Swap" class="msg-info outageAlert mb-2 pl-5 onFocusOutline"
                data-alert role="alert" tabindex="0" (click)="onClickOfSwapAlert(symphonyResponse?.swapResponse)">
                <label class="bold">{{resourceBundle.swapAlert}}</label><br>
                <span>{{resourceBundle.swapAlertDescription}}</span>
            </li>
            <li *ngIf="swapAlert && !this.cbHelperService.isMyAdmin()" title="Swap" class="msg-info outageAlert mb-2 pl-5 onFocusOutline"
                data-alert role="alert" tabindex="0" (click)="onClickOfSwapAlert(symphonyResponse?.swapResponse)">
                <label class="bold">{{resourceBundle.swapAlert}}</label><br>
                <span>{{resourceBundle.swapAlertDescription}}</span>
            </li>
            <li id="serviceApptAlert" *ngIf="alerts?.hasServiceAppointment?.accountGuid" title="Upcoming Service Appointment"
            class="msg-info msg-serviceAppt-info-mob outageAlert mouse-default mb-2 pl-5 display-flex" data-alert tabindex="0">
                <div>
                    <label title="Upcoming Service Appointment" class="bold display-block truncate">{{resourceBundle.upcomingServiceAppt}}</label>
                    <span title="Upcoming Service Appointment">{{serviceAppointmentDate | date:'EEEE, MMMM d'}}, {{serviceAppointmentTime}}</span>
                    <div [title]="resourceBundle.manageAppointment" class="pull-right">
                        <a href="javascript:void(0)" class="linkUnderline pr-2" 
                        (click)="navigateToServiceAppts()">{{resourceBundle.manageAppointment}}</a>
                    </div>
                </div>
            </li>
            <li *ngIf="deviceStatus?.status && !hideSelfInstall" title="Self-Install Status Alert" class="msg-info outageAlert mb-2 pl-5" data-alert role="button" tabindex="0" (click)="selfInstallService.openOrderDetailsModal()"
            attr.aria-label="{{deviceStatus.title}} {{resourceBundle.trackingInfoTitle}}" >
                <label class="bold">{{deviceStatus.title}}</label><br>
                <span aria-hidden="true" id="description">{{deviceStatus.description}}</span>
            </li>
            <li *ngIf="paperlessBillAlert && billingFlag" title="Paperless Billing Alert" class="msg-info outageAlert mb-2 pl-5" (click)="openPaperlessBillAlertModal()" data-alert role="alert" tabindex="0">
                <label class="bold">{{paperlessBillDetails.action}}</label><br>
                <span>{{paperlessBillDetails.message | truncate:[30, '...']}}</span>
           </li>
        <li *ngIf="alerts?.indicatorEvents?.promotionalOutageIndicator" title="FTTX alert" class="msg-info outageAlert mb-2 pl-5" (click)="openFiberInternetModal()" data-alert role="button">
            <label class="bold display-block truncate">{{resourceBundle.fttxInfo}}</label>
            <span>{{resourceBundle.clickToView}}</span>
        </li>
            <li *ngIf="!alertCheck" title="No Alerts" class="msg-success outageAlert mb-2 pl-5" data-alert role="alert">
                <label class="bold">{{resourceBundle.noAlertsHeader}}</label><br>
                <span>{{resourceBundle.noAlertsSubject}}</span>
            </li>
        </ul>
        
        <a href="javascript:void(0)" (click)="onViewAllMsgClick()"
            class="col-md-12 mb-2 pr-0 linkUnderline justify-content-space-between display-flex">
            {{resourceBundle.viewAll}}{{viewCount}}
            <span>
                <img alt="arrow next icon" src="assets/images/blueprint/admin_redesign/arrow-right-black.svg">
            </span>
        </a>
    </nav>
    <div class="overlay-msg hide"></div>
</div>
<ng-template #noMsgs>
    <div>{{resourceBundle.noMsgs}}</div>
</ng-template>
<ng-template #messagesPopover>
    <span class="sr-only">expanded</span>
    <ul class="messageList ul-style p-0 mb-2">
        <li *ngIf="alreadySwapped && !this.cbHelperService.isMyAdmin() && symphonyResponse?.swapResponse === validateSwap.Alreadyswapped"
            title="Already Swapped" class="msg-info outageAlert mb-2 chat-trigger pl-5 onFocusOutline" data-alert role="alert"
            tabindex="0" (click)="triggerTealium('Conatct-Us')">
            <label class="bold">{{resourceBundle.alreadySwapped}}</label><br>
            <span>{{resourceBundle.alreadySwappedDescription}}</span>
        </li>
        <li *ngIf="npdAlert && billingFlag" title="NPD Alert" class="msg-error outageAlert mb-2 pl-5 onFocusOutline" (click)="openNpdAlertModal()" data-alert role="alert" tabindex="0">
            <label class="bold">{{npdAlertDetails.action}}</label><br>
             <span>{{npdAlertDetails.message | truncate:[30, '...']}}</span>
        </li>
        <li *ngIf="alerts?.unplannedOutageIndicator" title="Unplanned Outage" class="msg-error outageAlert mb-2 pl-5 onFocusOutline" (click)="openUnplannedOutageModal()" data-alert >
            <label class="bold" >{{resourceBundle.unplannedOutage}}</label><br>
            <span tabindex="0" 
                attr.aria-label="{{resourceBundle.unplannedOutage}}
                {{isMarketRollOutFlag ? resourceBundle.viewOutageMapLink : resourceBundle.clickToView}}" 
                role="button">{{isMarketRollOutFlag ? resourceBundle.viewOutageMapLink : resourceBundle.clickToView}}
            </span>
        </li>
        <li *ngIf="alerts?.indicatorEvents?.plannedOutageIndicator" title="Planned Outage" class="msg-alert outageAlert mb-2 pl-5 onFocusOutline" (click)="openPlannedOutageModal()" data-alert role="button" tabindex="0">
            <label class="bold">{{resourceBundle.plannedOutage}}</label><br>
            <span>{{isMarketRollOutFlag ? resourceBundle.viewOutageMapLink : resourceBundle.clickToView}}</span>
        </li>
        <li *ngIf="swapAlert && !this.cbHelperService.isMyAdmin()" title="Swap" class="msg-info mb-2 outageAlert pl-5 onFocusOutline"
            data-alert role="alert" tabindex="0" (click)="onClickOfSwapAlert(symphonyResponse?.swapResponse)">
            <label class="bold">{{resourceBundle.swapAlert}}</label><br>
            <span>{{resourceBundle.swapAlertDescription}}</span>
        </li>
        <li *ngIf="swapAlert && !this.cbHelperService.isMyAdmin()" title="Swap" class="msg-info mb-2 outageAlert pl-5 onFocusOutline"
            data-alert role="alert" tabindex="0" (click)="onClickOfSwapAlert(symphonyResponse?.swapResponse)">
            <label class="bold">{{resourceBundle.swapAlert}}</label><br>
            <span>{{resourceBundle.swapAlertDescription}}</span>
        </li>
        <li id="serviceApptAlert" *ngIf="alerts?.hasServiceAppointment?.accountGuid" title="Upcoming Service Appointment"
            class="msg-info msg-serviceAppt-info outageAlert mouse-default mb-2 pl-5 display-flex" data-alert tabindex="0">
            <div>
                <label title="Upcoming Service Appointment" class="bold display-block truncate"
                    id="serviceApptAlertLabel">{{resourceBundle.upcomingServiceAppt}}</label>
                <span title="Upcoming Service Appointment">{{serviceAppointmentDate | date:'EEEE, MMMM d'}},
                    {{serviceAppointmentTime}}</span>
                <div [title]="resourceBundle.manageAppointment" class="pull-right">
                    <a href="javascript:void(0)" class="linkUnderline pr-2" id="serviceApptLink"
                        (click)="navigateToServiceAppts()">{{resourceBundle.manageAppointment}}</a>
                </div>
            </div>
        </li>
        <li *ngIf="deviceStatus?.status && !hideSelfInstall" title="Self-Install Status Alert" class="msg-info outageAlert mb-2 pl-5 onFocusOutline" data-alert (click)="selfInstallService.openOrderDetailsModal()" tabindex="0" role="alert">
            <label class="bold">{{deviceStatus.title}}</label><br>
            <span>{{deviceStatus.description}}</span>
        </li>
        <li *ngIf="paperlessBillAlert && billingFlag" title="Paperless Billing Alert" class="msg-info outageAlert mb-2 pl-5 onFocusOutline" (click)="openPaperlessBillAlertModal()" data-alert role="alert" tabindex="0">
             <label class="bold">{{paperlessBillDetails.action}}</label><br>
             <span>{{paperlessBillDetails.message | truncate:[30, '...']}}</span>
        </li>
        <li *ngIf="alerts?.indicatorEvents?.promotionalOutageIndicator" title="FTTX alert" class="msg-info outageAlert mb-2 pl-5 onFocusOutline" (click)="openFiberInternetModal()" (keydown)="openFiberInternetAccModal($event)" data-alert role="button" tabindex="0">
            <label class="bold display-block truncate">{{resourceBundle.fttxInfo}}</label>
            <span>{{resourceBundle.clickToView}}</span>
        </li>
        <li *ngIf="!alertCheck" title="No Alerts" class="msg-success outageAlert mb-2 pl-5 onFocusOutline" data-alert role="alert" >
            <label class="bold">{{resourceBundle.noAlertsHeader}}</label><br>
            <span tabindex="0" role="button" attr.aria-label="{{resourceBundle.noAlertsHeader}} {{resourceBundle.noAlertsSubject}}">{{resourceBundle.noAlertsSubject}}</span>
        </li>
    </ul>
    <a href="javascript:void(0)" [routerLink]="['/notificationhistory']"
        class="pull-right mb-2 linkUnderline" (blur)="offFocus($event)">{{resourceBundle.viewAll}}{{viewCount}}</a>
</ng-template>
