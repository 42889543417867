/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

export interface PaymentArrangments {
    paymentArrangementType: string;
    isEligibile: boolean;
    failedRules: FailedRule[];
    order?: number;
}

export interface FailedRule {
    failureReason: string;
    ruleId: string;
}

export enum PayArrangeType {
    SCHP = 'Pay today',
    FDP = 'Schedule a future payment',
    PTP = 'Request an extension',
    PTPE = 'Reschedule an extension',
    PMIP = 'Pay by mail or in-person'
}
export enum MaxCollecDueDatesPaymentArrngType{
	FDP= 'FDP',
	PTP= 'PTP',
	PTPE= 'PTPE',
    PMIP= 'PMIP',
    SCHP= 'SCHP',
}
