const config =require('/src/assets/config/config.json');

export const search = function initAnswers() {
    ANSWERS.init({
      apiKey: config.headerSearchApiKey,
      experienceKey: "cox_business_answers",
      experienceVersion: "PRODUCTION",
      businessId: "866292",
      onReady: function () {
        ANSWERS.addComponent("SearchBar", {
          container: ".custom_search_form",
          redirectUrl: "https://www.cox.com/search/business/",
          promptHeader: "You can ask us:",
          searchText: "What can we help you find?",
          placeholderText: "Search Cox Business",
        });
      },
    });
  }
