import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, ResourceBundle, SelfInstallService } from 'common-ui-lib';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnInit {
  public resourceBundle: ResourceBundle = {};
  
  constructor(private aemClient: AEMClientService,
              public selfInstallService: SelfInstallService) { }

  ngOnInit() {
    this.aemClient.getBundle('selfinstall', 'app')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: ResourceBundle) => {
        this.resourceBundle = data;
      });
  }
}
