    import { Injectable } from '@angular/core';
    import { AppConfig, CBHelperService, CoxHttpClient, IStep, LocalStorageKeys, MicroserviceResponse, Role } from 'common-ui-lib';
    import { indexOf } from 'lodash';
    import { DeviceDetectorService } from 'ngx-device-detector';
    import { SessionStorageService } from '../../node_modules/ngx-webstorage';
    import {
    Account,
    BaseAccountsResponse,
    ExtendedAccountDetails,
    FeatureStatusType,
    GUIDResponse,
    OnboardingAccountTracking,
    OnboardingAccountTrackingResponse,
} from './onboarding.model';
import { LocalStorageService } from 'ngx-localstorage';
import { MarketRollOut, API } from "./onboarding.model";

    @Injectable()
export class OnboardingService {
    public baseAccountResponse: BaseAccountsResponse;
    public accountNumber16: string;
    public accountFlow = 'createprofile';
    public voiceAccount: boolean;
    public voiceMailAccount: boolean;
    public voiceCallForwardAccount: boolean;
    public internetAccount: boolean;
    public assignedPhone: string;
    public accServiceCodes: string[];
    public isHNProfile: boolean;
    public showOBHeader = false;
    public ICOMS_ACCOUNT_NUMBER = 'accountNumber16';
    public VOICE_ACCOUNT = 'voiceAccount';
    public INTERNET_ACCOUNT = 'internetAccount';
    public VOICEMAIL_ACCOUNT = 'voiceMailAccount';
    public CALL_FORWARD_ACCOUNT = 'voiceCallForwardAccount';
    public HAS_VOICE_READABLE_ROLE = 'hasVoiceReadableRole';
    public ONBRD_ACC_SERVICECODES = 'accServiceCodes';
    public ASSIGNED_PHONE = 'assignedPhone';
    public ACCOUNT_FLOW = 'accountFlow';
    public HN_PROFILE = 'isHNProfile';

    public hasVoiceReadableRole: boolean;
    public INTERNET_SERVICECODES = 'onboarding_viewip_servicecode_list';
    public VOICE_SERVICECODES = 'voice_dtwpush_servicecode_list';
    public VOICE_MAIL_SERVICECODES = 'acision_voicemail_servicecode_list';
    public VOICE_CALLFORWARD_SERVICECODES = 'callforwarding_servicecode_list';
    private allIcons = {
        card: {
            icon: 'card',
            label: 'Create<br />Profile',
        },
        user: {
            icon: 'user',
            label: 'Add<br />Users',
        },
        billing: {
            icon: 'billing',
            label: 'Set Up<br />Billing',
        },
        phone: {
            icon: 'phone',
            label: 'Set Up<br />Voice',
        },
        window: {
            icon: 'window',
            label: 'View IP<br />Addresses',
        },
        shield: {
            icon: 'shield',
            label: 'Account<br />Login',
        },
        star: {
            icon: 'star',
            label: 'Welcome to<br />MyAccount',
        },
    };

    private mobileAllIcons = {
        card: {
            icon: 'card',
            label: 'Create Profile',
        },
        user: {
            icon: 'user',
            label: 'Add Users',
        },
        billing: {
            icon: 'billing',
            label: 'Set Up Billing',
        },
        phone: {
            icon: 'phone',
            label: 'Set Up Voice',
        },
        window: {
            icon: 'window',
            label: 'View IP Addresses',
        },
        shield: {
            icon: 'shield',
            label: 'Account Login',
        },
    };
    constructor(
        private httpClient: CoxHttpClient,
        private config: AppConfig,
        private sessionStorage: SessionStorageService,
        private cbHelper: CBHelperService,
        public deviceService: DeviceDetectorService,
        private _storageService: LocalStorageService
    ) { }

    public async getRespFromGUID(guid: string): Promise<GUIDResponse> {
        const getRespFromGUIDUrl =
            '/api/cbma/account/services/account/workordertracking/' + guid;
        return this.httpClient.getAsync<GUIDResponse>(getRespFromGUIDUrl,
            { disableSpinner: true, customeErrorMessage: '',
            isUnAuthenticatedUrl: !this.cbHelper.isUserAuthenticated() });
    }

    public async getBaseAccountFromAguid(aguid: string): Promise<Account> {
        const getBaseAccountFromAguidUrl =
            `/api/cbma/account/services/account/${aguid}?contactType=ALL`;
        return this.httpClient.getAsync<Account>(getBaseAccountFromAguidUrl,
            { disableSpinner: true, customeErrorMessage: '' });
    }

    public async getBaseAccountsList()
        : Promise<BaseAccountsResponse> {
        const getBaseAccountsListUrl = '/api/cbma/account/services/account/list/home';
        return this.httpClient.getAsync<BaseAccountsResponse>
            (getBaseAccountsListUrl, { disableSpinner: true, customeErrorMessage: '' });
    }
    public async sendTrackingDetails(onboardingAccountTracking: OnboardingAccountTracking)
        : Promise<OnboardingAccountTrackingResponse> {
        return this.httpClient.postAsync<OnboardingAccountTrackingResponse>
            (`/api/cbma/account/services/account/onboarding/tracking`, onboardingAccountTracking,
                { disableSpinner: true, customeErrorMessage: '' });
    }
    public async identifyAccountTypeMS(accountNumber16: string)
        : Promise<ExtendedAccountDetails> {
        const extendedAccountDetailsUrl = '/api/cbma/account/services/account/accountprofile/' + accountNumber16;
        return this.httpClient.getAsync<ExtendedAccountDetails>
            (extendedAccountDetailsUrl, { disableSpinner: true, customeErrorMessage: '' });
    }
    public async submitDTWProvision(accountNumber12: string): Promise<MicroserviceResponse> {
        const dtwpushUrl =
            '/api/cbma/voiceprofile/services/voiceprofile/dialToneWorkSheet/enable?accountNumber='
            + accountNumber12 + '&isOb=true';
        return this.httpClient.putAsync<MicroserviceResponse>
            (dtwpushUrl, null, { disableSpinner: true, customeErrorMessage: '' });
    }
    public checkViewIpRole(): boolean {
        if (this.cbHelper.isUserAuthenticated()) {
            const roles = this.cbHelper.getLoggedInUserDetails().profile.roles;
            return (roles.includes(Role.VIEWIPADDRESS_ROLE) || roles.includes(Role.DNS_MALBLOCK_SECURITY_ROLE));
        } else {
            return false;
        }
    }

    public get stepsArray(): IStep[] {
        let array = null;
        const hasViewIPRole = this.checkViewIpRole();
        if (this.accountFlow === 'addaccount') {
            this.allIcons.card.label = 'Add<br />Account';
        }

        if (this.voiceAccount && this.internetAccount) {
            array = [this.allIcons.card, this.allIcons.user, this.allIcons.phone, this.allIcons.billing];
            if (hasViewIPRole) {
                array.push(this.allIcons.window);
            }
            array.push(this.allIcons.star);
        } else if (this.voiceAccount) {
            array = [this.allIcons.card, this.allIcons.user, this.allIcons.phone,
            this.allIcons.billing, this.allIcons.star];
        } else if (this.internetAccount) {
            array = [this.allIcons.card, this.allIcons.user, this.allIcons.billing];
            if (hasViewIPRole) {
                array.push(this.allIcons.window);
            }
            array.push(this.allIcons.star);
        } else {
            if (this.isHNProfile) {
                array = [this.allIcons.card, this.allIcons.user,
                this.allIcons.star];
            } else {
                array = [this.allIcons.card, this.allIcons.user,
                this.allIcons.billing, this.allIcons.star];
            }
        }
        if (this.accountFlow === 'accountlogin') {
            array.splice(0, 1, this.allIcons.shield);
        }
        if (this.deviceService.isMobile()) {
            array.splice(array.length - 1, 1);
        }
        for (const stepNum of array) {
            stepNum.icon = array.indexOf(stepNum) + 1;
        }
        return array;
    }

    public get mobileStepsArray(): IStep[] {
        let array = null;
        const hasViewIPRole = this.checkViewIpRole();
        if (this.accountFlow === 'addaccount') {
            this.mobileAllIcons.card.label = 'Add Account';
        }

        if (this.voiceAccount && this.internetAccount) {
            array = [this.mobileAllIcons.card, this.mobileAllIcons.user, this.mobileAllIcons.phone, this.mobileAllIcons.billing];
            if (hasViewIPRole) {
                array.push(this.mobileAllIcons.window);
            }
        } else if (this.voiceAccount) {
            array = [this.mobileAllIcons.card, this.mobileAllIcons.user, this.mobileAllIcons.phone, this.mobileAllIcons.billing];
        } else if (this.internetAccount) {
            array = [this.mobileAllIcons.card, this.mobileAllIcons.user, this.mobileAllIcons.billing];
            if (hasViewIPRole) {
                array.push(this.mobileAllIcons.window);
            }
        } else {
            if (this.isHNProfile) {
                array = [this.mobileAllIcons.card, this.mobileAllIcons.user];
            } else {
                array = [this.mobileAllIcons.card, this.mobileAllIcons.user, this.mobileAllIcons.billing];
            }
        }
        if (this.accountFlow === 'accountlogin') {
            array.splice(0, 1, this.mobileAllIcons.shield);
        }
        return array;
    }

    public get verticalStepper(): boolean {
        if (this.deviceService.isMobile() && (this.internetAccount || this.voiceAccount)) {
            return true;
        }
        return false;
    }

    public stepIndex(stepName: string): number {
        if (stepName === 'billing') {
            if (this.voiceAccount) {
                return 3;
            } else {
                return 2;
            }
        } else if (stepName === 'viewip') {
            if (this.voiceAccount) {
                return 4;
            } else {
                return 3;
            }
        }
    }

    public async checkServiceExists(propKey: string, userServiceCodes: string[]): Promise<boolean> {
        const eligibleServiceCodes = this.config.getConfig(propKey);
        let contains = false;
        if (userServiceCodes) {
            for (const serviceCode of userServiceCodes) {
                contains = eligibleServiceCodes.includes(serviceCode);
                if (contains) {
                    break;
                }
            }
        }
        return contains;
    }

    public async identifyAccountType(isAbandonFlow: boolean = false) {
        try {
            if (!this.accountNumber16) {
                this.accountNumber16 = this.sessionStorage.retrieve(this.ICOMS_ACCOUNT_NUMBER);
            }
            const extendedAccountResponse: ExtendedAccountDetails = await this.identifyAccountTypeMS
                (this.accountNumber16);
            if (extendedAccountResponse && extendedAccountResponse.accountDetails &&
                extendedAccountResponse.accountDetails.serviceCodes) {
                await this.setOnboardingFlowVariables(extendedAccountResponse, isAbandonFlow);
            }
        } catch (e) {
            return;
        }
    }

    public async setMarketRollOut() {
        return new Promise((resolve, reject) => {
            this.cbHelper.clearMarketRollOut();
            this.httpClient.getAsync<MarketRollOut>(API.ELIGIBILTY_API).then((response) => {
                if (response.testMarket) {
                    this._storageService.set(LocalStorageKeys.MarketRollOut, 'true');
                } else {
                    this._storageService.set(LocalStorageKeys.MarketRollOut, 'false');
                }
                resolve(response);
            }).catch((error) => {
                this._storageService.set(LocalStorageKeys.MarketRollOut, 'false');
                throw error;
                reject(error);
            });
        });
    }

    private async setOnboardingFlowVariables(extendedAccountDetails: ExtendedAccountDetails, isAbandonFlow: boolean) {
        this.accServiceCodes =
            extendedAccountDetails.accountDetails.serviceCodes.split(',');
        this.sessionStorage.store(this.ONBRD_ACC_SERVICECODES, JSON.stringify(this.accServiceCodes));
        if (await this.checkServiceExists(this.INTERNET_SERVICECODES, this.accServiceCodes)) {
            this.internetAccount = true;
            this.sessionStorage.store(this.INTERNET_ACCOUNT, true);
        }
        if (!isAbandonFlow) {
            if (await this.checkServiceExists(this.VOICE_SERVICECODES, this.accServiceCodes)) {
                if (extendedAccountDetails.voiceManagerDetails && extendedAccountDetails.voiceManagerDetails.status &&
                    (extendedAccountDetails.voiceManagerDetails.status === FeatureStatusType.ACTIVE ||
                        extendedAccountDetails.voiceManagerDetails.status === FeatureStatusType.PENDING &&
                        !extendedAccountDetails.isHotCut)) {
                    this.voiceAccount = true;
                    this.sessionStorage.store(this.VOICE_ACCOUNT, true);
                } else if (extendedAccountDetails.isHotCut !== null && this.accountFlow !== 'accountlogin') {
                    await this.automateDTWProvision(extendedAccountDetails.isHotCut);
                }
            }
        } else {
            await this.abandonFlowsetOnboardingFlowVoiceVariables(extendedAccountDetails);
        }
    }

    private async abandonFlowsetOnboardingFlowVoiceVariables(extendedAccountDetails: ExtendedAccountDetails) {
        console.log('OKTAFLOW - ***Abandon Flow***');
        if (!extendedAccountDetails.isHotCut && !extendedAccountDetails.isTransferProcess &&
            extendedAccountDetails.voiceManagerDetails && extendedAccountDetails.voiceManagerDetails.status &&
            (extendedAccountDetails.voiceManagerDetails.status === FeatureStatusType.ACTIVE)) {
            if (await this.checkServiceExists(this.VOICE_SERVICECODES, this.accServiceCodes)) {
                this.voiceAccount = true;
                this.sessionStorage.store(this.VOICE_ACCOUNT, true);
            }
            if (extendedAccountDetails.voiceManagerDetails.assignedPhoneNumber) {
                this.assignedPhone = extendedAccountDetails.voiceManagerDetails.assignedPhoneNumber;
                this.sessionStorage.store(this.ASSIGNED_PHONE,
                    extendedAccountDetails.voiceManagerDetails.assignedPhoneNumber);
                if (await this.checkServiceExists(this.VOICE_MAIL_SERVICECODES, this.accServiceCodes)) {
                    this.voiceMailAccount = true;
                    this.sessionStorage.store(this.VOICEMAIL_ACCOUNT, true);
                }
                if (await this.checkServiceExists(this.VOICE_CALLFORWARD_SERVICECODES, this.accServiceCodes)) {
                    this.voiceCallForwardAccount = true;
                    this.sessionStorage.store(this.CALL_FORWARD_ACCOUNT, true);
                }
            }
        }

    }
    private async automateDTWProvision(isHotCut: boolean) {
        const accountNumber12 = this.accountNumber16.substring(0, 3) +
            this.accountNumber16.substring(7);
        try {
            const dtwResponse: MicroserviceResponse = await this.submitDTWProvision(accountNumber12);
            if (dtwResponse.code === '0' && !isHotCut) {
                this.voiceAccount = true;
                this.sessionStorage.store(this.VOICE_ACCOUNT, true);
            }
        } catch (e) {
            console.log('DTW push failed');
            return;
        }
    }

    public clearOBSessionVars() {
        this.sessionStorage.clear(this.ICOMS_ACCOUNT_NUMBER);
        this.sessionStorage.clear(this.ACCOUNT_FLOW);
        this.sessionStorage.clear(this.VOICE_ACCOUNT);
        this.sessionStorage.clear(this.INTERNET_ACCOUNT);
        this.sessionStorage.clear(this.VOICEMAIL_ACCOUNT);
        this.sessionStorage.clear(this.CALL_FORWARD_ACCOUNT);
        this.sessionStorage.clear(this.HAS_VOICE_READABLE_ROLE);
        this.sessionStorage.clear(this.ASSIGNED_PHONE);
        this.sessionStorage.clear(this.ONBRD_ACC_SERVICECODES);
        this.sessionStorage.clear(this.HN_PROFILE);
    }

    public retrieveOBSessionVars() {
        if (this.sessionStorage.retrieve(this.ICOMS_ACCOUNT_NUMBER) !== null) {
            this.accountNumber16 = this.sessionStorage.retrieve(this.ICOMS_ACCOUNT_NUMBER);
        }
        if (this.sessionStorage.retrieve(this.ACCOUNT_FLOW) !== null) {
            this.accountFlow = this.sessionStorage.retrieve(this.ACCOUNT_FLOW);
        }
        if (this.sessionStorage.retrieve(this.VOICE_ACCOUNT) !== null) {
            this.voiceAccount = this.sessionStorage.retrieve(this.VOICE_ACCOUNT);
        }
        if (this.sessionStorage.retrieve(this.INTERNET_ACCOUNT) !== null) {
            this.internetAccount = this.sessionStorage.retrieve(this.INTERNET_ACCOUNT);
        }
        if (this.sessionStorage.retrieve(this.VOICEMAIL_ACCOUNT) !== null) {
            this.voiceMailAccount = this.sessionStorage.retrieve(this.VOICEMAIL_ACCOUNT);
        }
        if (this.sessionStorage.retrieve(this.CALL_FORWARD_ACCOUNT) !== null) {
            this.voiceCallForwardAccount = this.sessionStorage.retrieve(this.CALL_FORWARD_ACCOUNT);
        }
        if (this.sessionStorage.retrieve(this.INTERNET_ACCOUNT) !== null) {
            this.hasVoiceReadableRole = this.sessionStorage.retrieve(this.HAS_VOICE_READABLE_ROLE);
        }
        if (this.sessionStorage.retrieve(this.ASSIGNED_PHONE) !== null) {
            this.assignedPhone = this.sessionStorage.retrieve(this.ASSIGNED_PHONE);
        }
        if (this.sessionStorage.retrieve(this.ONBRD_ACC_SERVICECODES) !== null) {
            this.accServiceCodes = JSON.parse(this.sessionStorage.retrieve(this.ONBRD_ACC_SERVICECODES));
        }
        if (this.sessionStorage.retrieve(this.HN_PROFILE) !== null) {
            this.isHNProfile = JSON.parse(this.sessionStorage.retrieve(this.HN_PROFILE));
        }

    }

    // used for local testing of onboarding flow
    public storeOBSessionVars() {
        this.sessionStorage.store(this.ICOMS_ACCOUNT_NUMBER, '2165110140232001');
        this.sessionStorage.store(this.INTERNET_ACCOUNT, false);
        this.sessionStorage.store(this.VOICE_ACCOUNT, false);
        this.sessionStorage.store(this.VOICEMAIL_ACCOUNT, false);
        this.sessionStorage.store(this.CALL_FORWARD_ACCOUNT, false);
    }

    public isWebexEnabled(): boolean {
        if (this.accountNumber16) {
            /**BwgroupId will be generated after DTW push.
             *  So we have to take side id from the 16 digit account number from onboarding service.
             */
            const siteId = this.accountNumber16.substr(0, 3);
            const ucEnabledArray = this.config.getConfig('ucEnabled')['ucEnabledMarket'];
            if (ucEnabledArray.length === 0) {
                return true; // Webex
            } else if (ucEnabledArray.includes(siteId)) {
                return false; // UC App
            } else {
                return true; // Webex
            }
        } else {
            return false; // UC App
        }
    }

}
