import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ErrorMessageService } from '../messages/error-message.service';
import { BaseAccount } from '../models/accounts/baseaccounts.model';
import { AccountWidgetService } from './accountwidget.service';

@Component({
  selector: "app-accountwidget",
  templateUrl: './accountwidget.component.html',
  styleUrls: ['./accountwidget.component.scss'],
  providers: [AccountWidgetService],
})
export class AccountwidgetComponent implements OnInit, OnDestroy {
  @Input() accountDeselect = true;
  @Input() pageId = '';
  @Output() selectedAccount = new EventEmitter<BaseAccount>();
  @Output() totalAccounts = new EventEmitter<number>();
  @Input() preselectedAccount: BaseAccount;
  baseAccounts: BaseAccount[] = [];
  singleAccount = false;
  twotosixAccounts = false;
  sevenplusAccounts = false;
  assignedStorage = false;
  private onDestroy$ = new Subject<boolean>();

  constructor(private httpClient: HttpClient,
              private accountWidgetService: AccountWidgetService,
              private errorMessageService: ErrorMessageService,
              private sessionStorage: SessionStorageService) {
  }

  ngOnInit() {
    let pageId: string;
    // On reload of Updatecontactinformation pages we need to send update contact Pgid(updatecontactinformation).
    if (this.pageId) {
      pageId = this.pageId;
    } else if (window.location.href.indexOf('/updatecontactinformation') > 0) {
      pageId = 'updatecontactinformation';
    } else {
      pageId = window.location.href.split('/').pop();
    }
    this.accountWidgetService.getBaseAccountsList(pageId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data) => {
          this.baseAccounts = data.baseAccounts;
          if (data.assignedStorage !== null) {
            this.assignedStorage = data.assignedStorage;
          }
          this.sessionStorage.store('talength', this.baseAccounts.length);
        },
        (error) => {
          this.errorMessageService.errorMessage.show = true;
          this.errorMessageService.errorMessage.type = 'error';
          this.errorMessageService.errorMessage.level = 'global';
          window.scrollTo(0, 0);
        },
        () => this.accountWidget(),
      );
  }

  accountWidget() {
    this.singleAccount = false;
    this.twotosixAccounts = false;
    this.sevenplusAccounts = false;
    if (this.baseAccounts.length === 1) {
      this.singleAccount = true;
    } else if (this.baseAccounts.length > 1 && this.baseAccounts.length < 7) {
      this.twotosixAccounts = true;
    } else {
      this.sevenplusAccounts = true;
    }
  }

  getSelectedAccount(selectedAccountReceived: BaseAccount) {
    this.selectedAccount.emit(selectedAccountReceived);
    this.totalAccounts.emit(this.baseAccounts.length);
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
