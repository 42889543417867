import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CustomerheaderComponent } from '@app/customerheader/customerheader.component';
import { SharedModule } from '@app/shared';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
    CommonUiLibModule,
    CustomFooterComponent, CustomHeaderComponent, extract, InfomessageService
} from 'common-ui-lib';
import { StartService } from 'csradmin/start/start.service';
import { CbmaAuthGuard } from 'home/auth.guard';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { OnboardingService } from 'onboarding/onboarding.service';
import { BootComponent } from './boot.component';
import { DeploymentInProgressComponent } from './deployment/deploymentinprogress.component';
import { LoginComponent } from './login';
import { LoginService } from './login/login.service';
import { RedirectComponent } from './login/redirect/redirect.component';
import { MaintenanceGuard } from './maintenance/maintenance.guard';
import { PostCbLoginComponent } from './postcblogin/postcblogin.component';
import { PostloginComponent } from './postlogin/postlogin.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { MyaccountHomeService } from 'home/myaccounthome.service';

declare global {
    interface Window {
        utag_data: any;
        utag: any;
        utag_cfg_ovrd: any;
        adobe: any;
        animationCloseMenu: any;
        KAMPYLE_ONSITE_SDK: any;
    }
}
@NgModule({
    imports: [
        BrowserModule,
        CommonUiLibModule,
        BrowserAnimationsModule,
        NgxWebstorageModule.forRoot({ prefix: 'cbma', separator: '_' }),
        NgxLocalStorageModule.forRoot({ prefix: 'cbma', allowNull: true }),
        NgbModule,
        RecaptchaModule,
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot(),
        RouterModule.forRoot([
            {
                path: '',
                component: PostloginComponent,
                canActivate: [CbmaAuthGuard],
            },
            {
                path: 'postlogin',
                component: PostloginComponent,
            },
            {
                path: 'upgrading',
                component: DeploymentInProgressComponent,
            },
            {
                path: 'unauth/login',
                component: LoginComponent,
                data: { title: extract('Login'), tealium: 'login' },
            },
            {
                path: 'implicit/callback',
                component: RedirectComponent,
            },
            {
                path: 'implicit/postcblogin',
                component: PostCbLoginComponent,
            },
            {
                path: '',
                pathMatch: 'prefix',
                loadChildren: () => import('../app/app.module').then(m => m.AppModule),
            },
            {
                path: '**',
                redirectTo: '/errorpage/httperror?errorCode=404',
                pathMatch: 'full'
            }, // TODO: load lazy module
        ], {
    useHash: false
}),
        SharedModule.forRoot(),
        FontAwesomeModule,
    ],
    declarations: [
        BootComponent,
        LoginComponent,
        CustomerheaderComponent,
        RedirectComponent,
        PostloginComponent,
        PostCbLoginComponent,
        DeploymentInProgressComponent,
        CustomHeaderComponent,
        CustomFooterComponent,
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: window.location.origin + '/cbma/' },
        CookieService,
        LoginService,
        StartService,
        OnboardingService,
        // MaintenanceGuard,
        { provide: 'MaintenanceGuard', useValue: MaintenanceGuard },
        InfomessageService,
        CbmaAuthGuard,
        DeviceDetectorService,
        MyaccountHomeService
        // base href added as origin to override with Angular router to fetch assets from CDN
    ],
    bootstrap: [BootComponent],
})
export class BootModule { }
