import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { BaseAccount } from '../../models/accounts/baseaccounts.model';
import { AEMClientService } from '../../../services/aemclient.service';
import { Router, ActivatedRoute, UrlSegment, UrlSegmentGroup, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { SessionStorageService } from 'ngx-webstorage';
import { TealiumUtagService } from '../../../tealium/utag.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageKeys } from 'common-ui-lib';

@Component({
  selector: 'app-accountwidget2to6accounts',
  templateUrl: './accountwidget2to6accounts.component.html',
  styleUrls: ['./accountwidget2to6accounts.component.scss']
})
export class Accountwidget2to6accountsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() baseAccounts: BaseAccount[];
  @Input() accountDeselect: Boolean = true;
  @Output() selectedAccountChange = new EventEmitter<BaseAccount>();
  private _preselectedAcc: BaseAccount;
  @Input()
  public set selectedAccount(value: BaseAccount) {
    this._preselectedAcc = value;
    this.selectedLatestAccount = this.selectedAccount;
    if (this.selectedAccount) {
      this.accountSelected(this.selectedAccount, false);
    }
  }
  public get selectedAccount(): BaseAccount {
    return this._preselectedAcc;
  }
  numberofaccounts: number;
  oddAccounts: BaseAccount[] = [];
  evenAccounts: BaseAccount[] = [];
  allAccounts: BaseAccount[] = [];
  accountid: any;
  accountnumber: any;
  accountname: any;
  accountalias: any;
  accountaddress: any;
  accountaddress1: any;
  accountaddress2: any;
  accountaddresspostal: any;
  oddAccountsFlag: boolean;
  evenAccountsFlag: boolean;
  allAccountsFlag: boolean = true;
  toggleAccordian: boolean = false;
  accountselected: boolean = false;
  showselect: boolean = false;
  resourceBundle: any = {};
  selectedLatestAccount: any;
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService,
    private activatedRoute: ActivatedRoute,
    private _storageService: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private tealium: TealiumUtagService) { }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error("Method not implemented.");
    this.numberofaccounts = this.baseAccounts.length;
    if (this.accountDeselect === false) {
      this.showselect = false;
      this.accountselected = false;
      this.accountnumber = null;
      this.resetView();
    }
  }

  ngOnInit() {
    this.aemClient.getSharedBundle('accountWidget2To6AccountsResourceBundle')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => { this.resourceBundle = data; },
        error => { }
      );
    let scope = this;
    this.baseAccounts.forEach(function (baseAccount, i) {
      if (i % 2) {
        scope.evenAccountsFlag = true;
        scope.evenAccounts.push(baseAccount);
      } else {
        scope.oddAccountsFlag = true;
        scope.oddAccounts.push(baseAccount);
      }
      scope.allAccounts.push(baseAccount);
    });
    if (this.selectedAccount) {
      this.selectedLatestAccount = this.selectedAccount;
      this.accountSelected(this.selectedAccount, false);
    }
    if (window.location.href.indexOf('/updatecontactinformation') > 0) {
      if (this._storageService.get(LocalStorageKeys.StepUsed, 'cbma') === 'true') {
        this.selectedLatestAccount = JSON.parse(this._storageService.get(LocalStorageKeys.LatestSelectedAccount, 'cbma'));
        if (this.selectedLatestAccount.accountId !== undefined) {
          this.accountSelected(this.selectedLatestAccount);
        }
        this._storageService.remove(LocalStorageKeys.StepUsed);
      }
    } else if (window.location.href.indexOf('/malblockservice') > 0) {
      this.selectedLatestAccount = JSON.parse(this.sessionStorage.retrieve('selectedMyServicesAccount'));
      if (this.selectedLatestAccount.accountId !== undefined) {
        this.accountSelected(this.selectedLatestAccount);
      }
    } else if (window.location.href.indexOf('/malblockipaddressmanagement') > 0) {
      this.selectedLatestAccount = JSON.parse(this.sessionStorage.retrieve('selectedAccountForMalblockService'));
      if (this.selectedLatestAccount.accountId !== undefined) {
        this.accountSelected(this.selectedLatestAccount);
      }
    } else if (window.location.href.indexOf('/datatool') > 0) {
      this.selectedLatestAccount = JSON.parse(this.sessionStorage.retrieve('selectedMyServicesAccount'));
      if (this.selectedLatestAccount.accountId !== undefined) {
        this.accountSelected(this.selectedLatestAccount);
      }
    }
  }

  toggleAccordion() {
    this.toggleAccordian = !this.toggleAccordian;
    if (!this.toggleAccordian) {
      if (this.accountnumber != null) {
        this.showselect = true;
      }
    } else {
      this.showselect = false;
    }
  }
  // Function added only for screen readers accessibility
  public expandWidget() {
    let accWidget = document.getElementById('account-detailslist-2to6-accordian-title').getAttribute('aria-expanded');
    if (accWidget === 'true') {
      accWidget = 'false';
    } else {
      accWidget = 'true';
    }
    document.getElementById('account-detailslist-2to6-accordian-title').setAttribute('aria-expanded', accWidget);
  }

  accountSelected(baseAccount: BaseAccount, silent: boolean = false) {
    this.tealium.track('link', {
      eventName: 'AccountWidgetSelection',
      accountGuid: baseAccount.guid
    });
    if (!silent) {
      this.selectedAccountChange.emit(baseAccount);
    }
    this.accountselected = true;
    this.showselect = true;
    this.accountid = baseAccount.accountId;
    this.accountnumber = baseAccount.siteId + '-' + baseAccount.accountNumber;
    this.accountname = baseAccount.name;
    this.accountalias = baseAccount.alias;
    let address2 = '';
    if (baseAccount.address.address2 != '')
      address2 = baseAccount.address.address2 + ', ';
    this.accountaddress = baseAccount.address.address1 + ', ' +
      address2 +
      baseAccount.address.city + ', ' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
    this.accountaddress1 = baseAccount.address.address1;
    this.accountaddress2 = baseAccount.address.address2;
    this.accountaddresspostal = baseAccount.address.city + ',' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
  }

  resetView() {
    this.oddAccounts = [];
    this.oddAccountsFlag = false;
    this.evenAccounts = [];
    this.evenAccountsFlag = false;
    this.allAccounts = [];
    this.allAccountsFlag = false;
    let scope = this;
    this.baseAccounts.forEach(function (baseAccount, i) {
      if (i % 2) {
        scope.evenAccountsFlag = true;
        scope.evenAccounts.push(baseAccount);
      } else {
        scope.oddAccountsFlag = true;
        scope.oddAccounts.push(baseAccount);
      }
      scope.allAccounts.push(baseAccount);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
