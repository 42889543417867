import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-filtertags",
  templateUrl: "./filtertags.component.html",
  styleUrls: ["./filtertags.component.scss"],
})
export class FiltertagsComponent implements OnInit {
  @Input() selectedTags: string[];
  @Output() clearFilterTag = new EventEmitter();
  constructor() { }
  ngOnInit(): void {

  }

  /**
   * we are geeting tag as object 
   * to overcome triggering same value simultaneously
   * @param tag which we wish to clear
   */
  public deleteTag(tag: any) {
    const _temp = { ...tag }
    this.clearFilterTag.emit(_temp);
  }
}
