import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { SessionStorageService } from 'ngx-webstorage';
import { takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AEMClientService, ResourceBundle } from '../../../services/aemclient.service';
import { TealiumUtagService } from '../../../tealium/utag.service';
import { BaseAccount } from '../../models/accounts/baseaccounts.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-equipmentaccountwidget2to6accounts',
  templateUrl: './accountwidget2to6accountsequipment.component.html',
  styleUrls: ['./accountwidget2to6accountsequipment.component.scss'],
})
export class Accountwidget2to6accountsEquipmentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() baseAccounts: BaseAccount[];
  @Output() selectedAccount = new EventEmitter<BaseAccount>();
  numberofaccounts: number;
  oddAccounts: BaseAccount[] = [];
  evenAccounts: BaseAccount[] = [];
  allAccounts: BaseAccount[] = [];
  accountid: string;
  accountnumber: string;
  accountname: string;
  accountalias: string;
  accountaddress: string;
  accountaddress1: string;
  accountaddress2: string;
  accountaddresspostal: string;
  oddAccountsFlag: boolean;
  evenAccountsFlag: boolean;
  allAccountsFlag = true;
  toggleAccordian = false;
  accountselected = false;
  showselect = false;
  resourceBundle: ResourceBundle = {};
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService,
              private activatedRoute: ActivatedRoute,
              private _storageService: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private tealium: TealiumUtagService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.numberofaccounts = this.baseAccounts.length;
  }

  ngOnInit() {
    this.aemClient.getSharedBundle('accountWidget2To6AccountsResourceBundle')
    .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => { this.resourceBundle = data; },
        (error) => { },
      );
    const scope = this;
    this.baseAccounts.forEach((baseAccount, i) => {
      if (i % 2) {
        scope.evenAccountsFlag = true;
        scope.evenAccounts.push(baseAccount);
      } else {
        scope.oddAccountsFlag = true;
        scope.oddAccounts.push(baseAccount);
      }
      scope.allAccounts.push(baseAccount);
    });
    const selectedEquipmentAccountDetails = JSON.parse(this.sessionStorage.retrieve('selectedEquipmentAccount'));
    if (selectedEquipmentAccountDetails && selectedEquipmentAccountDetails.accountId !== undefined &&
      (window.location.href.indexOf('/myequipments') > 0)) {
      this.accountSelected(selectedEquipmentAccountDetails);
    }
  }

  toggleAccordion() {
    this.toggleAccordian = !this.toggleAccordian;
    if (!this.toggleAccordian) {
      if (this.accountnumber != null) {
        this.showselect = true;
      }
    } else {
      this.showselect = false;
    }
  }
  // Function added only for screen readers accessibility
  public expandWidgetEquip() {
    let accWidget =
    document.getElementById('account-detailslist-2to6-accordian-titleEquip').getAttribute('aria-expanded');
    if (accWidget === 'true') {
      accWidget = 'false';
    } else {
      accWidget = 'true';
    }
    document.getElementById('account-detailslist-2to6-accordian-titleEquip').setAttribute('aria-expanded', accWidget);
  }

  accountSelected(baseAccount: BaseAccount) {
    this.tealium.track('link', {
      eventName: 'AccountWidgetSelection',
      accountGuid: baseAccount.guid,
    });
    this.selectedAccount.emit(baseAccount);
    this.accountselected = true;
    this.showselect = true;
    this.accountid = baseAccount.accountId;
    this.accountnumber = baseAccount.siteId + '-' + baseAccount.accountNumber;
    this.accountname = baseAccount.name;
    this.accountalias = baseAccount.alias;
    let address2 = '';
    if (baseAccount.address.address2 !== '') {
      address2 = baseAccount.address.address2 + ', ';
    }
    this.accountaddress = baseAccount.address.address1 + ', ' +
      address2 +
      baseAccount.address.city + ', ' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
    this.accountaddress1 = baseAccount.address.address1;
    this.accountaddress2 = baseAccount.address.address2;
    this.accountaddresspostal = baseAccount.address.city + ',' +
      baseAccount.address.state + ' ' +
      baseAccount.address.zip;
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
