<ng-template #tipMoreIcon>
    <div placement="bottom">
        <div class="width-xs-25-06em quick-links-more-section">
            <div *ngIf="!isMobileDevice">
                <div class=" col-md-12 col-sm-12 col-xs-12 margin-md-bottom-1" (keyup)="onKeyupMoreLinks($event)">
                    <div class=" quick-link-ul">
                        <ul class="ul-style p-0">
                            <div *ngFor="let featureName of resourceBundle?.featureDescription">   
                                <div *ngFor="let globalTiles of globalMenuTiles">
                                    <div *ngIf="globalTiles == featureName.featureName && featureName.featureName === 'Home'" [ngClass]="oddFlag ? 'col-md-12 col-sm-12 col-xs-12 pl-1 pr-1' : 'more-container col-md-6 col-sm-6 col-xs-6 pl-0'">
                                        <a href="javascript:void(0)" [attr.aria-label]="featureName.featureName" tabindex="0" [target]="featureName.target" (click)="onLinkClick(featureName)">
                                            <span id="{{featureName.featureName}}" [ngClass]="[oddFlag ? 'more-tile-home': 'more-tile width-230',  redirectFragment == featureName.featureName.replaceAll(' ','') ? 'active-tile' : '']">
                                                <img alt="{{featureName.featureName}}" [src]="featureName.icon" role="presentation"
                                            class="pad-top-1 mb-0-1-rem img-size img-height">
                                                <figcaption>{{featureName.featureName}}</figcaption>
                                            </span>
                                        </a>
                                    </div>
                                    <div *ngIf="(globalTiles == featureName.featureName && featureName.featureName !== 'Home') && !(digitalService.isConsolidated && featureName.featureName === 'Billing')" [ngClass]="oddFlag ? 'col-md-6 col-sm-6 col-xs-6 pl-1 pr-1' : 'more-container col-md-6 col-sm-6 col-xs-6 pl-0'">
                                        <a [attr.aria-label]="featureName.featureName" role="link" tabindex="0" [target]="featureName.target" (click)="onLinkClick(featureName)">
                                            <span id="{{featureName.featureName}}" [ngClass]="[oddFlag ? 'more-tile-home': 'more-tile width-230', redirectFragment == featureName.featureName.replaceAll(' ','')  ? 'active-tile' : '']">
                                                <img alt="{{featureName.featureName}}" [src]="featureName.icon"  role="presentation"
                                            class="pad-top-1 mb-0-1-rem img-size img-height">
                                                <figcaption class="two-line-ellipsis">{{featureName.featureName}}</figcaption>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<div class="quick-links-more text-center pull-right" *ngIf="!isMobileDevice">
    <a role="button" href="JavaScript:void(0)" class="appearance-set" (shown)="toggleMoreLink(true)" (hidden)="toggleMoreLink(false)" id="moreMenu"
    [ngbPopover]="tipMoreIcon" [autoClose]="true" #tip1="ngbPopover" [placement]="popoverPlacement">
        <span class="sr-only" aria-live="polite">{{resourceBundle.menu}} {{moreIcon ? 'expanded' : 'collapsed'}}</span>
        <div class="ae-img" aria-hidden="true">
            <ng-container *ngTemplateOutlet="moreicon"></ng-container>
        </div>
        <figcaption class="pl-0" [ngClass]="{'mt-n2': headerWithTitle}" aria-hidden="true">{{resourceBundle.menu}}</figcaption>
    </a>
</div>
<div class="mt-3 text-center" id="morelinkmobile" *ngIf="isMobileDevice">
    <a id="more-link" aria-label="Menu"  role="button" href="JavaScript:void(0)" class="appearance-set" (click)="toggleMoreIcon();">
        <span class="sr-only" aria-live="polite">{{resourceBundle.menu}} {{moreIcon ? 'expanded' : 'collapsed'}}</span>
        <div class="ae-img" aria-hidden="true">
            <ng-container *ngTemplateOutlet="moreicon"></ng-container>
        </div>
        <figcaption class="pl-0" [ngClass]="{'mt-n2': headerWithTitle}" aria-hidden="true">{{resourceBundle.menu}} </figcaption>
    </a>
</div>
<div class="wrapper" id="wrapperMobile" *ngIf="isMobileDevice">
    <!-- Sidebar -->
    <nav id="sidebar">
        <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn float-right mr-2" (click)="closeSideBar()" [autofocus]="true"></a>
        <ul class="mt-5 ul-style pl-0">
            <li *ngFor="let featureName of resourceBundle?.featureDescription"  class="mb-2 onFocusOutline" (click)="onLinkClick(featureName)" tabindex="0">
                <div *ngFor="let globalTiles of globalMenuTiles">
                    <div *ngIf="globalTiles == featureName.featureName" [ngClass]="[redirectFragment == featureName.featureName.replaceAll(' ','') ? 'active-link' : '']">
                        <span class="d-flex div-center-align">
                            <img [src]="featureName.icon" [width]="24" [height]="24" alt="{{featureName.featureName}}" class="ml-3 mr-3 mb-2 mt-2"
                        role="presentation" />
                            <span class="text-bold-600" *ngIf="featureName.featureName !== 'Users And Accounts'"
                        [ngClass]="[redirectFragment == featureName.featureName.replaceAll(' ','') ? 'feature-underline' : '']">{{featureName.featureName}}</span>
                            <span class="text-bold-600" *ngIf="featureName.featureName == 'Users And Accounts'" [ngClass]="[redirectFragment == featureName.featureName.replaceAll(' ','') ? 'feature-underline' : '']">Manage</span>
                            <img class="pull-right" src="assets/images/blueprint/admin_redesign/arrow-right-black.svg" [width]="24" [height]="24" alt="Next Icon" role="presentation" />
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
    <div class="overlay-more hide"></div>
</div>
<ng-template #moreicon>
    <svg width="32px" height="32px" viewBox="0 0 18 4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title aria-hidden="true">More Icon</title>
        <defs>
            <path
                d="M9,4 C7.8954305,4 7,3.1045695 7,2 C7,0.8954305 7.8954305,0 9,0 C10.1045695,0 11,0.8954305 11,2 C11,3.1045695 10.1045695,4 9,4 Z M2,4 C0.8954305,4 0,3.1045695 0,2 C0,0.8954305 0.8954305,0 2,0 C3.1045695,0 4,0.8954305 4,2 C4,3.1045695 3.1045695,4 2,4 Z M16,4 C14.8954305,4 14,3.1045695 14,2 C14,0.8954305 14.8954305,0 16,0 C17.1045695,0 18,0.8954305 18,2 C18,3.1045695 17.1045695,4 16,4 Z"
                id="path-1"></path>
            <linearGradient x1="22.892761%" y1="16.8467829%" x2="81.7701602%" y2="82.0326194%" id="linearGradient-3">
                <stop stop-color="#00AAF4" offset="0%"></stop>
                <stop stop-color="#022F87" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="Page-1-moreIcon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="My-Account-Landing-Page-moreIcon" transform="translate(-183.000000, -499.000000)">
                <g id="more" transform="translate(180.000000, 489.000000)">
                    <g id="Colors/202020" transform="translate(3.000000, 10.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlink:href="#path-1"></use>
                        </mask>
                        <use id="Mask-moreIcon" fill="#000000" xlink:href="#path-1"></use>
                        <g mask="url(#mask-2)" fill="url(#linearGradient-3)" id="Rectangle-4-Copy">
                            <g transform="translate(-3.000000, -10.000000)">
                                <rect x="0" y="0" width="24" height="24"></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</ng-template>