<div class="modal-body modal-content c-btn scrollbox">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">
        <h5 class="bold mt-1 mb-3"> {{resourceBundle.orderId}} #{{selfInstallService.workOrderId}} </h5>
        <span class="my-3">{{resourceBundle.orderTrackingDesc}}</span>
    </div>
    <ngx-datatable class="dataTable bootstrap" id="orderTrackingDatatable" #orderTrackingDatatable
        *ngIf="!selfInstallService.isMobileDevice && selfInstallService?.orderDetailsEquipmentRows?.length > 0" [rows]="selfInstallService.orderDetailsEquipmentRows"
        [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="'auto'"
        [footerHeight]="'auto'" comnAccessTable
        [noofRows]="selfInstallService.orderDetailsEquipmentRows.length" [tableName]="'Order Tracking Details'"
        role="table" [table]="orderTrackingDatatable">
        <ngx-datatable-column prop="name" name="Equipment" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                <span>{{value}}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="shippingTrackingNumber" name="Tracking Number" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                <span *ngIf="row.trackingUrl && row.trackingUrl !== ''; else showPending"><a class="linkUnderline word-break" href="{{row.trackingUrl}}"
                        target="_blank" tabindex="0" role="link" (click)="selfInstallService.triggerTealium('Tracking Url')"
                        attr.aria-label="{{row.name}} of {{resourceBundle.trackingNumber}} {{row.shippingTrackingNumber}} {{resourceBundle.newWindow}}">
                        {{value}}</a></span>
                <ng-template #showPending><span>{{resourceBundle.pending}}</span></ng-template>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <ngx-datatable class="dataTable bootstrap" id="orderTrackingDatatable" #orderTrackingDatatable
        *ngIf="selfInstallService.isMobileDevice && selfInstallService?.orderDetailsEquipmentRows?.length > 0" [rows]="selfInstallService?.orderDetailsEquipmentRows"
        [columnMode]="'force'" [reorderable]="false" [rowHeight]="'auto'" [headerHeight]="'auto'"
        [footerHeight]="'auto'" comnAccessTable
        [noofRows]="selfInstallService?.orderDetailsEquipmentRows?.length" [tableName]="'Order Tracking Details'"
        role="table" [table]="orderTrackingDatatable">
        <ngx-datatable-column prop="name" name="" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                <div class="bold">{{resourceBundle.equipment}}</div>
                <div>{{row.name}}</div>
                <div class="bold">{{resourceBundle.trackingNumber}}</div>
                <div *ngIf="row.trackingUrl && row.trackingUrl !== ''; else showPending"><a class="linkUnderline word-break" href="{{row.trackingUrl}}"
                        target="_blank" (click)="selfInstallService.triggerTealium('Tracking Url')">{{row.shippingTrackingNumber}}</a></div>
                <ng-template #showPending><div>{{resourceBundle.pending}}</div></ng-template>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>