<div *ngIf="!isMobileDevice" id="support-menu-icon" class="popover-active-box" (click)="togglePopover($event)">
  <a href="javascript:void(0)" [ngbPopover]="supportMenuPopover" [placement]="popoverPlacement"
    #quickLinkPopover="ngbPopover" role="button" [autoClose]="'outside'" popoverClass="quickLinkPopover"
    (hidden)="cbHeaderService.showSupportMenu=false" aria-label="Get Support"   tabindex="-1" aria-hidden="true">
    <div>
      <img src="assets/images/blueprint/cb_header/support_active.svg" alt="Support icon" role="presentation" />
    </div>
  </a>
</div>

<ng-container *ngIf="isMobileDevice">
  <ng-container *ngTemplateOutlet="supportMenuPopover"></ng-container>
</ng-container>

<ng-template #supportMenuPopover tabindex="-1" aria-hidden="true">
  <!-- Tickets -->
  <div *ngIf="!ticketFlowFlag" class="col-lg-12 col-md-12 col-xs-12
  text-font-16 lh-22 font-cerapro-regular font-weight-5 pl-0 pr-0 tickets">
    <ngb-accordion #ticketDetailsAccordion="ngbAccordion" [closeOthers]="false" id="ticket-details"
      class="cb-accordions no-highlight" (keydown.enter)="$event.stopPropagation();">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <div class="accordion-trigger chevron-right">
            <span class="line-height-22px black p-0">{{resourceBundle.tickets}}</span>
          </div>
        </ng-template>
        <ng-template ngbPanelContent tabindex="-1">
          <app-header-tickets 
            (closePopover)="onClosePopover($event)" 
            class="margin-bottom-0-75rem" 
            [headerTicketList]="headerTicketList">
          </app-header-tickets>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <div *ngIf="ticketFlowFlag" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-font-16 lh-22 font-cerapro-regular font-weight-5 tickets" id="ticket-details">
    <div class="accordion-trigger">
    <div class="font-weight-bold line-height-22px black p-3 pl-3">{{resourceBundle.tickets}}</div>
    </div>
    <app-header-tickets 
        (closePopover)="onClosePopover($event)" 
        class="margin-bottom-0-75rem" 
        [headerTicketList]="headerTicketList">
    </app-header-tickets>
  </div>

  <!-- CBCC, NOCAAS, Help & Support -->
  <div class="col-lg-12 col-md-12 col-xs-12 margin-top-7 header-links
  text-font-16 lh-22 font-cerapro-regular font-weight-5">
    <ul class="ul-style p-0 mb-0" role="list">
      <div *ngFor="let item of getSupportDetails; let i = index" role="presentation">
        <li *ngIf="(item.name && item.enabled)" role="listitem">
          <a href="javascript:void(0)" class="black" (click)="onClickOfSupportLinks(item.name)"
            (keydown.enter)="$event.stopPropagation();onClickOfSupportLinks(item.name)" (blur)="offFocus($event, i)">
            {{item.displayName}}
          </a>
        </li>
      </div>
    </ul>
  </div>
</ng-template>

<ng-template #getSupportModal let-close="close" let-dismiss="dismiss">
  <div role="dialog" aria-describedby="modal-heading" aria-modal="true">
    <div class="col-md-12 col-sm-12 col-xs-12 modalheader pt-2 pb-2" [ngClass]="{'mb-4': !modalContentLoaded}">
      <h4 class="h4 modal-title col-xs-11 p-0" id="modal-heading"
        attr.aria-label="{{resourceBundle.modalHeading}} {{resourceBundle.headingLevel4}}">
        {{resourceBundle.modalHeading}}
      </h4>
      <div class="col-xs-1 text-center p-0">
        <a title="Close" class="cb-modal-close-btn pull-right" href="javascript:void(0)" role="button"
          (click)="close('Close click');onClose()"></a>
      </div>
    </div>
    <ng-container *ngIf="modalContentLoaded; else modalLoader">
      <div class="modal-body" *ngIf="commonMessageService.displayMessage.show">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-n4" [ngClass]="isMobileDevice ? 'mt-n4' : 'mt-n2'">
          <app-commonmessage [type]="commonMessageService.displayMessage.type"
            [globalMessage]="commonMessageService.displayMessage.message" [level]="'globalPage'" *ngIf="commonMessageService.displayMessage.show &&
            commonMessageService.displayMessage.id === 'getSupport'">
          </app-commonmessage>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-3" *ngIf="oliverIconFlag">
        <ng-template [ngTemplateOutlet]="oliverIcon"></ng-template>
      </div>
      <hr class="hr partition" *ngIf="oliverIconFlag">
      <div class="row ml-4 mr-4" *ngIf="noCaasRole">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 height-md-450p margin-top-1 ">
          <div class="row need-help-border" id="needNoCaasHelpSection">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top-20px">
              <span class="float-left pad-right-p-10 mt-1">
                <img src="assets/images/blueprint/malandingpage/need-help.svg" alt="NeedHelp" role="presentation" />
                <label class="m-0 pad-top-md-5p" for="NeedHelpimg"></label>
              </span>
              <div class="margin-bottom-neg-10">
                <h4><b>{{resourceBundle.noCaasHeader}}</b></h4>
                <p> {{resourceBundle.noCaasDescription}} </p>
                <span
                  class="text-bold-600 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">{{resourceBundle.noCaasSupport}}</span>
                <span class="need-help-phone-header h5">{{resourceBundle.noCaasNumber}}</span>
                <div class="col-12 p-0 margin-bottom-1">
                  <a href="javascript:void(0)" id="noCaasView" class="bold"
                    (click)="viewMore = !viewMore;setFocus('noCaasView')"
                    [attr.aria-label]=" resourceBundle.noCaasHeader + ' ' + (viewMore ?  resourceBundle.viewLess : resourceBundle.viewMore)"
                    [attr.aria-expanded]="viewMore">
                    <span *ngIf="!viewMore">{{resourceBundle.viewMore}}
                      <img src="assets/images/arrow-down.svg" class="pb-5px" role="presentation"></span>
                    <span *ngIf="viewMore">{{resourceBundle.viewLess}}<img src="assets/images/arrow-up.svg"
                        class="pb-5px" role="presentation"></span>
                  </a>
                </div>
                <ul *ngIf="viewMore" class="pad-bottom-1">
                  <li>{{resourceBundle.noCaasBulletPoint1}}</li>
                  <li>{{resourceBundle.noCaasBulletPoint2}}</li>
                  <li>{{resourceBundle.noCaasBulletPoint3}}</li>
                  <li>{{resourceBundle.noCaasBulletPoint4}}</li>
                  <li>{{resourceBundle.noCaasBulletPoint5}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ml-4 mr-4" *ngIf="needHelpFlag">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 height-md-450p margin-top-30">
          <div class="row need-help-border" id="needHelpSection">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-2 margin-top-20px">
              <div class="float-left pad-right-p-10 mt-1">
                <img src="assets/images/blueprint/malandingpage/need-help.svg" alt="NeedHelp" role="presentation" />
                <label class="m-0 pad-top-md-5p" for="NeedHelpimg"></label>
                <h4 class="display-inline-block pl-3"><b>{{title}}</b></h4>
              </div>
              <div class="row pt-4 pl-3 margin-left-2-1em margin-bottom-neg-10">
                <p class="mr-2">{{resourceBundle.cbccDescription}}</p>
              </div>
              <div class="col-12 margin-left-2-3em margin-bottom-1 mt-2 pl-3">
                <a href="javascript:void(0)" id="cbccView" class="bold"
                  (click)="viewMoreCbcc = !viewMoreCbcc;setFocus('cbccView')"
                  [attr.aria-label]="title + ' ' + (viewMoreCbcc ?  resourceBundle.viewLess : resourceBundle.viewMore)"
                  [attr.aria-expanded]="viewMoreCbcc">
                  <span *ngIf="!viewMoreCbcc">{{resourceBundle.viewMore}}
                    <img src="assets/images/arrow-down.svg" class="pb-5px" role="presentation"></span>
                  <span *ngIf="viewMoreCbcc">{{resourceBundle.viewLess}}<img src="assets/images/arrow-up.svg"
                      class="pb-5px" role="presentation"></span>
                </a>
              </div>
              <ul class="margin-left-3-75em p-0" [ngClass]="{'boxGrid': !isMobileDevice}" *ngIf="viewMoreCbcc">
                <li *ngFor="let list of description">{{list}}</li>
                <li><a href="https://www.cox.com/business/completecare.html" target="_blank"
                    aria-label="Read more link opens a new window"><U>{{resourceBundle.readMoreLink}}</U> ></a></li>
              </ul>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-input-box gray-bg-std">
              <span class="margin-top-28 text-bold-600">{{resourceBundle.needHelpPhNoHeader}}</span>
              <div class="need-help-phone-header h5">{{resourceBundle.phoneNumber}}</div>
              <p>{{resourceBundle.passcodeDescription}}</p>
              <div class="margin-top-p-5 col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-0">
                <ng-template [ngTemplateOutlet]="displayPasscodeRef"></ng-template>
              </div>
              <div *ngIf="!endUser" class="col-lg-4 col-md-4 col-sm-4 col-xs-4 p-0"
                [ngClass]="{'mt-2': !this.deviceService.isMobile()}">
                <div class="bold" [ngClass]="{'mb-4': this.deviceService.isMobile()}">
                  <a href="javascript:void(0)" aria-label="Change passcode"
                    (click)="changePasscode()">{{resourceBundle.changePasscodeLink}}</a>
                </div>
              </div>
              <div *ngIf="passcodeFlag" class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                [ngClass]="(repeatedNumberValidation || sequentialNumberValidation || specialCharacterValidation || previousPasscodeValidation || letterValidation || alphaNumericValidation || spaceValidation || serverError) ? 'margin-bottom-10' : ''">
                <a href="javascript:void(0)" aria-label="Passcode cancel"
                  (click)="onCancel()">{{resourceBundle.passcodeCancelLink}}</a>
                <a href="javascript:void(0)" aria-label="Passcode save" class="pull-right mr-md-3 mr-lg-4"
                  [attr.aria-disabled]="updatePasscodeForm.get('passcodeinputfirst').invalid || updatePasscodeForm.get('passcodeinputsec').invalid || updatePasscodeForm.get('passcodeinputthird').invalid || updatePasscodeForm.get('passcodeinputfourth').invalid || updatePasscodeForm.get('passcodeinputfifth').invalid || repeatedNumberValidation || sequentialNumberValidation || specialCharacterValidation || previousPasscodeValidation || letterValidation || alphaNumericValidation || spaceValidation || serverError"
                  [ngClass]="{'defocus': (updatePasscodeForm.get('passcodeinputfirst').invalid || updatePasscodeForm.get('passcodeinputsec').invalid || updatePasscodeForm.get('passcodeinputthird').invalid || updatePasscodeForm.get('passcodeinputfourth').invalid || updatePasscodeForm.get('passcodeinputfifth').invalid || repeatedNumberValidation || sequentialNumberValidation || specialCharacterValidation || previousPasscodeValidation || letterValidation || alphaNumericValidation || spaceValidation || serverError)}"
                  (click)="onPasscodeSave()">{{resourceBundle.passcodeSaveLink}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="hr partition">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        [ngClass]="{'ml-4': !this.deviceService.isMobile(), 'text-center': this.deviceService.isMobile()}">
        <button class="button button-secondary" (click)="close('Close click'); onClose()"
          [ngClass]="{'width-60': this.deviceService.isMobile()}">{{resourceBundle.close}}</button>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- common template to display form and validation -->
<ng-template #displayPasscodeRef>
  <form [formGroup]="updatePasscodeForm" class="pad-bottom-15">
    <div>
      <input type='text' minlength="1" maxlength="1" id="passcodeinputfirst" (keyup)="onPasscodeEdit()"
        formControlName="passcodeinputfirst" aria-label="Passcode first input"
        [attr.disabled]="!passcodeFlag ? 'disabled' : null"
        [ngClass]="{'defocusinput' : !passcodeFlag, 'error': errorDisplayCheck()}"
        class="margin-right-10 margin-xs-right-p-5 width-2 height-2em text-center" [appAutotab]="cbccpasscode2"
        #cbccpasscode1>
      <input type='text' minlength="1" maxlength="1" id="passcodeinputsec" (keyup)="onPasscodeEdit()"
        formControlName="passcodeinputsec" aria-label="Passcode second input"
        [attr.disabled]="!passcodeFlag ? 'disabled' : null"
        [ngClass]="{'defocusinput' : !passcodeFlag, 'error': errorDisplayCheck()}"
        class="margin-right-10 margin-xs-right-p-5 width-2 height-2em text-center" [appAutotab]="cbccpasscode3"
        #cbccpasscode2>
      <input type='text' minlength="1" maxlength="1" id="passcodeinputthird" (keyup)="onPasscodeEdit()"
        formControlName="passcodeinputthird" aria-label="Passcode third input"
        [attr.disabled]="!passcodeFlag ? 'disabled' : null"
        [ngClass]="{'defocusinput' : !passcodeFlag, 'error': errorDisplayCheck()}"
        class="margin-right-10 margin-xs-right-p-5 width-2 height-2em text-center" [appAutotab]="cbccpasscode4"
        #cbccpasscode3>
      <input type='text' minlength="1" maxlength="1" id="passcodeinputfourth" (keyup)="onPasscodeEdit()"
        formControlName="passcodeinputfourth" aria-label="Passcode fourth input"
        [attr.disabled]="!passcodeFlag ? 'disabled' : null"
        [ngClass]="{'defocusinput' : !passcodeFlag, 'error': errorDisplayCheck()}"
        class="margin-right-10 margin-xs-right-p-5 width-2 height-2em text-center" [appAutotab]="cbccpasscode5"
        #cbccpasscode4>
      <input type='text' minlength="1" maxlength="1" id="passcodeinputfifth" (keyup)="onPasscodeEdit()"
        formControlName="passcodeinputfifth" aria-label="Passcode fifth input"
        [attr.disabled]="!passcodeFlag ? 'disabled' : null"
        [ngClass]="{'defocusinput' : !passcodeFlag, 'error': errorDisplayCheck()}"
        class="width-2 height-2em text-center" #cbccpasscode5>
    </div>
    <ng-container *ngIf="passcodeFlag && formSubmitAttempt">
      <div *ngIf="serverError">
        <span class="col-12 pl-0 pr-3 text-danger form-error margin-bottom-0 margin-top-2"
          role="alert">{{resourceBundle.serverErrorMsg}}</span>
      </div>
      <div *ngIf="repeatedNumberValidation">
        <span class="col-12 pl-0 pr-3 text-danger form-error margin-bottom-0 margin-top-2"
          role="alert">{{resourceBundle.repeatedNumberError}}</span>
      </div>
      <div *ngIf="sequentialNumberValidation">
        <span class="col-12 pl-0 pr-3 text-danger form-error margin-bottom-0 margin-top-2"
          role="alert">{{resourceBundle.sequenceNumberError}}</span>
      </div>
      <div *ngIf="previousPasscodeValidation">
        <span class="col-12 pl-0 pr-3 text-danger form-error margin-bottom-0 margin-top-2"
          role="alert">{{resourceBundle.previousPasscodeError}}</span>
      </div>
      <div *ngIf="specialCharacterValidation">
        <span class="col-12 pl-0 pr-3 text-danger form-error margin-bottom-0 margin-top-2"
          role="alert">{{resourceBundle.errorMsg}} {{resourceBundle.specialCharacterRefNo}}</span>
      </div>
      <div *ngIf="letterValidation">
        <span class="col-12 pl-0 pr-3 text-danger form-error margin-bottom-0 margin-top-2"
          role="alert">{{resourceBundle.errorMsg}} {{resourceBundle.letterValidationRefNo}}</span>
      </div>
      <div *ngIf="alphaNumericValidation">
        <span class="col-12 pl-0 pr-3 text-danger form-error margin-bottom-0 margin-top-2"
          role="alert">{{resourceBundle.errorMsg}} {{resourceBundle.alphaNumericRefNo}}</span>
      </div>
      <div *ngIf="spaceValidation">
        <span class="col-12 pl-0 pr-3 text-danger form-error margin-bottom-0 margin-top-2"
          role="alert">{{resourceBundle.errorMsg}} {{resourceBundle.spaceValidationRefNo}}</span>
      </div>
    </ng-container>
    <div class="pt-2" *ngIf="!successMsg && formSubmitAttempt && !passcodeFlag" role="alert">
      <img class="ae-img" src="assets/images/blueprint/icons/alert_success.svg" role="presentation">
      <span class="pl-1">{{resourceBundle.successMsg}}</span>
    </div>
  </form>
</ng-template>

<ng-template #oliverIcon>
  <div class="d-flex">
    <span class="col-12 p-0">
      <div (click)="openChat()" tabindex="0" role="link"
        attr.aria-label="{{resourceBundle.contactUsHelpMessage}} {{resourceBundle.targetWindow}}"
        class="pl-3 mouse-link link col-xs-12 col-md-12 col-lg-12 display-flex div-center-align get-support-oliver chat-trigger">
        <div class="col-2 mr-3"><img src="assets/images/blueprint/admin_redesign/oliver_icon.svg" alt="Oliver Icon"
            class="mt-2 mb-3 mouse-link ae-img" role="presentation">
        </div>
        <span class="linkUnderline bold">{{resourceBundle.contactUsHelpMessage}}</span>
      </div>
    </span>
  </div>
</ng-template>

<ng-template #modalLoader>
  <div class="col-12 mt-4">
    <app-skeletonloader formName="Get Support" formSkeleton="true" rowCount="5"
      datatableName="Get Support"></app-skeletonloader>
  </div>
</ng-template>