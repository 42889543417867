import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from "@angular/core";
import { BaseAccount } from "../../models/accounts/baseaccounts.model";
import { AEMClientService } from '../../../services/aemclient.service';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

@Component({
  selector: "app-accountwidgetsingleaccount-basic",
  templateUrl: "./accountwidgetsingleaccount-basic.component.html",
  styleUrls: ["./accountwidgetsingleaccount-basic.component.scss"]
})
export class AccountwidgetsingleaccountBasicComponent implements OnInit, OnDestroy {
  @Input() baseAccounts: BaseAccount[];
  @Output() selectedAccount = new EventEmitter<BaseAccount>();
  accountName: string;
  accountAlias: string;
  accountNumber: string;
  accountAddress: string;
  accountAddress1: string;
  accountAddress2: string;
  accountAddressPostal: string;
  resourceBundle: any = {};
  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService) {
    this.aemClient.getSharedBundle('accountwidget2to6accounts-basicResourceBundles')
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
      (data: any) => { this.resourceBundle = data; },
      error => {}
    );
  }

  ngOnInit() {
    this.selectedAccount.emit(this.baseAccounts[0]);
    this.accountName = this.baseAccounts[0].name;
    this.accountAlias = this.baseAccounts[0].alias;
    this.accountNumber = this.baseAccounts[0].siteId + "-" + this.baseAccounts[0].accountNumber;
    let address2 = "";
    if (this.baseAccounts[0].address.address2 != "") address2 = this.baseAccounts[0].address.address2 + ", ";
    this.accountAddress =
      this.baseAccounts[0].address.address1 +
      ", " +
      address2 +
      this.baseAccounts[0].address.city +
      ", " +
      this.baseAccounts[0].address.state +
      " " +
      this.baseAccounts[0].address.zip;
    this.accountAddress1 = this.baseAccounts[0].address.address1;
    this.accountAddress2 = this.baseAccounts[0].address.address2;
    this.accountAddressPostal =
      this.baseAccounts[0].address.city +
      "," +
      this.baseAccounts[0].address.state +
      " " +
      this.baseAccounts[0].address.zip;
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }   
}
