import {
    Component, EventEmitter, HostListener, Input,
    OnChanges, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { SearchPipe } from '../pipes/search.pipe';
import { MultiSelectDropdown, Search } from './redesign-search-filter.model';
import _, { isEqual } from 'lodash';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'common-ui-lib';

@Component({
    selector: 'app-search-filter',
    templateUrl: './redesign-search-filter.component.html',
    styleUrls: ['./redesign-search-filter.component.scss']
})
export class SearchAndFilter implements OnChanges {
    @ViewChild('modalFilter') public modalFilter: NgbModalRef;
    @Input() resetSearch: boolean;
    @Input() numericalSearch: boolean;
    @Input() rows: Search;
    @Output() filteredRows = new EventEmitter<any>();
    @Input() clearFilterTag: string;
    @Input() searchId = '_search';
    @Input() filterName: string;
    @Input() hasRightSidedFilter = false;
    @Input() filterLabel = 'Filter';
    @Input() showFilterModal: boolean;
    @Input() modalDetails: any;
    @Input() searchString: string;
    @Input() checkboxFilterApplied = false;
    @Output() filterTags = new EventEmitter<any>();
    @Output() inputText = new EventEmitter<string>();
    public filteredTmpArray = [];
    public categoryOptions = [];
    public showFilter = false;
    public uniqueOptionsList: string[];
    public filterMultiSelectDropdownSettings = {};
    public filterMultiSelectDropdownArray: MultiSelectDropdown[] = [];
    public firstDropdownValue = [];
    public secondDropdownValue = null;
    public modalFilterDirty = false;
    public firstOptionFiltered = false;
    public secondOptionFiltered = false;
    public phoneTmpArray:any= [];
    public filteredArraysOtherUsa: any;
    public  filteredArray:any ;
    public filterUSA: any;
    public filtersvalue: any;
    private _filterSearchArray: any[];
    constructor(
        public deviceService: DeviceDetectorService,
        public modalService: NgbModal,
        private searchPipe: SearchPipe
    ) {}
    /**
     * close the filter popover on clicking outside of filter
     *
     * @param event click
     */
    @HostListener('document:focusin', ['$event'])
    @HostListener('window:click', ['$event'])
    public onMouseClick(event: any) {
        if (this.rows?.filteredArray && this.rows?.filteredArray?.length > 0) {
            if (!document.getElementById('filterpopover')?.contains(event.target) &&
                (!document.getElementById('filtericon')?.contains(event.target))) {
                if (this.showFilter) {
                    this.showFilter = false;
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.modalDetails) {
            if(this.showFilterModal) {
                this.filterMultiSelectDropdownSettings = {
                    singleSelection: false,
                    searchPlaceholderText: 'Search',
                    enableSearchFilter: true,
                    noDataLabel: 'No results found',
                    text: '',
                    enableCheckAll: true,
                    enableFilterSelectAll: true,
                    searchBy: ['itemName'],
                    position: 'bottom',
                    maxHeight: 200,
                    autoPosition: false,
                    lazyLoading: true
                  };
                  this.filterMultiSelectDropdownArray = [];
                  this.modalDetails?.firstDropdownArray?.forEach((code: string, i) => {
                    this.filterMultiSelectDropdownArray.push({ id: String(i), itemName: code });
                  });
            }
        }
        if (changes.clearFilterTag && changes.clearFilterTag.currentValue && changes.clearFilterTag.currentValue.clear) {
            /**scenario when filters are present & then data is updated LOAD */
            if (changes?.clearFilterTag.currentValue?.initialLoad) {
                this.clearFilterTagAndSearch();
            } else { //cear tags
                this.deleteTag(changes?.clearFilterTag?.currentValue?.clear);
            }
        }
        if (changes.rows) {
            this.filteredTmpArray = [];
            this.filterTags.emit('');
            this.categoryOptions = [];
            if (this.rows?.filteredArray?.length) {
                this.rows?.filteredArray?.forEach((category: any) => {
                    category.options.forEach((element) => {
                        if (element.isChecked) {
                            const filteredObject = { value: element.optionValue, avoidTitleCase: this.rows.avoidTitleCase };
                            this.filteredTmpArray.push(filteredObject);
                            this.formCategoryOptionsList(element.optionValue, element.searchValue, category.categoryTitle);
                            this.filterItems(this.filteredTmpArray);
                        }
                    });
                });
            }
        }
        /**scenario when filters are present & then no data is present when(data updation happened) call is made LOAD*/
        if (this.rows && this.rows?.filteredArray?.length === 0) {
            this.clearFilterTagAndSearch();
        }
        if (changes?.resetSearch?.currentValue) {
            this.searchString = '';
        }
        // This is triggered when checkbox filter is applied and new search made
        if (this.checkboxFilterApplied) {
            const element: any = document.getElementById(this.searchId);
            this.search(element?.value);
        }
    }

    public onFilterClick(filterStatus: string) {
        if (this.showFilterModal) {
            this.modalService.open(this.modalFilter,
                { windowClass: 'redesign-modal-md', backdrop: 'static' }).result.then(result => {
                }).catch((e) => {
                    console.log(e);
                });
        }
        this.formOptionsList();
        if (filterStatus === 'open' && this.rows?.filteredArray && this.rows?.filteredArray?.length > 0) {
            this.showFilter = true;
        } else {
            this.showFilter = false;
        }
    }

    public closeFilterModal(close) {
        if(!this.firstOptionFiltered && this.secondOptionFiltered && this.firstDropdownValue?.length > 0) {
            this.firstDropdownValue = [];
        } else if(!this.secondOptionFiltered && this.firstOptionFiltered && this.secondDropdownValue?.length > 0) {
            this.secondDropdownValue = null;
        } else if(!this.firstOptionFiltered && !this.secondOptionFiltered) {
            this.firstDropdownValue = [];
            this.secondDropdownValue = null;
            this.modalFilterDirty = false;
        }
        close('Close click');
    }

    public closeFilterModalAfterApplyingFilter(close) {
        close('Close click');
    }

    public search(event?: any) {
        if (this.rows?.searchType === 'client') {
            return this.clientSideSearch(event);
        } else if (this.rows?.searchType === 'server' && event.key !== 'Tab' && event.key !== 'Shift') {
            this.serverSideSearch(event);
        }
    }
    public serverSideSearch(event: any) {
        if (event.target.value) {
            this.filteredRows.emit(event.target.value);
        } else {
            this.filteredRows.emit('');
        }
        this.inputText.emit(this.searchString);
    }

    public clientSideSearch(event) {
        if(this.numericalSearch) {
            this.searchString = event.target.value.replace(/[^0-9\-]/g, '');
        } else {
            if (this.checkboxFilterApplied) {
                const ele: any = document.getElementById(this.searchId);
                this.searchString = ele?.value;
            } else {
                this.searchString = event?.target?.value;
            }
        }
        this.filterItems(this.filteredTmpArray, this.searchString);
    }

    public filterItems(_filteredTmpArray: any[], inputtext?: string) {
        let _filterItems = [];
        let filterItemsArr = [];
        if (_filteredTmpArray.length === 0) {
            this.filterTags.emit(_filteredTmpArray);
            this._filterSearchArray = [...this.rows.searchArray];
            if (this.searchString && this.searchString.length > 0) {
                this.searchResultsIndependent(_filteredTmpArray, this.searchString, this._filterSearchArray);
            } else {
                this.inputText.emit(this.searchString);
                return this.filteredRows.emit(this.rows.searchArray);
            }
        } else {
            if (this.showFilterModal && inputtext !== undefined) {
                this._filterSearchArray = this.filtersvalue ? this.filtersvalue : this.rows.searchArray;
            } else {
                this._filterSearchArray = [...this.rows.searchArray];
            }
            _filterItems = this._filterSearchArray;
            const strictFilter = this.rows.strictFilter === 'no' ? false : true;
            for (const category of this.categoryOptions) {
                for (const option of category.categoryOptionsArray) {
                    const tempFilterRes =
                        this.searchPipe.transform(_filterItems, option.searchValue, { strict: strictFilter });
                    filterItemsArr = filterItemsArr.concat(tempFilterRes);
                }
                _filterItems = filterItemsArr;
                filterItemsArr = [];
            }
            const filterSet = new Set();
            const uniqueFilterItems = [];
            _filterItems.forEach((element)=> {
                if (!filterSet.has(element)) {
                    filterSet.add(element);
                    uniqueFilterItems.push(element);
                }
            });
            _filterItems = uniqueFilterItems;
            this.searchResultsIndependent(this.filteredTmpArray, this.searchString, _filterItems);
        }

    }

    /**
     * Thise method performs search on the original or filtered data
     *
     * @param _filteredTmpArray
     * @param inputtext
     * @param _filterItems
     * @returns
     */
    public searchResultsIndependent(_filteredTmpArray: any[], inputtext: string, _filterItems: any) {
        if ((inputtext && inputtext?.length > 0) || inputtext === '') {
            const searchColumns = this.rows?.searchColumns?.length > 0 ? this.rows?.searchColumns : [];
            const searchSpecificColumns = this.rows?.expicitSearches?.length > 0 ? this.rows?.expicitSearches : [];
            const tmpSearchArray =
                this.searchPipe.transform(_filterItems, inputtext?.trim(),
                    {
                        minSearchLength: null,
                        includeCols: searchColumns,
                        specificCols: searchSpecificColumns,
                        strict: false
                    });
            this.filterTags.emit(_filteredTmpArray);
            this.filteredArray = tmpSearchArray;
            this.inputText.emit(this.searchString);
            return this.filteredRows.emit(tmpSearchArray);
        } else {
            this.filterTags.emit(_filteredTmpArray);
            this.inputText.emit(this.searchString);
            return this.filteredRows.emit(_filterItems);
        }
    }

    public filtereData(event: any, searchValue?: string, categoryTitle?: string, avoidTitleCase?: boolean)  {
        if(this.showFilterModal) {
            this.filteredTmpArray = [];
            this.categoryOptions = [];
            const uniqueSet = new Set();
            const uniquecountrySet = new Set();
            this.phoneTmpArray =[];
            if(this.firstDropdownValue?.length === 0 && this.secondDropdownValue === null) {
                this.filteredTmpArray = [];
                this.filterItems(this.filteredTmpArray);
                this.modalFilterDirty = false;
                this.firstOptionFiltered = this.secondOptionFiltered = false;
            }
            if(this.modalDetails?.firstDropdownType === 'multiSelectDropdown' && this.firstDropdownValue?.length > 0) {
                this.firstDropdownValue?.forEach((item: MultiSelectDropdown) => {
                    const numeric = item.itemName.split(' - ')[0];
                    if(numeric === '+1' && this.showFilterModal){
                    const countryName = item.itemName.split(' - ')[1];
                    this.modalDetails?.firstDropdownFilter?.push(countryName);
                    uniquecountrySet.add(countryName);
                    }
                    uniqueSet.add(numeric);
                });
                uniqueSet?.forEach((item: string) => {
                    this.filteredTmpArray.push(item);
                    this.formCategoryOptionsList(null, item, 'Country Code');
                    this.filterItems(this.filteredTmpArray);
                });
                if (this.showFilterModal) {
                    this._filterSearchArray?.map(items => {
                        items.phNoShortForm = items?.phoneNumber?.substring(0, 3);
                    });
                    uniquecountrySet?.forEach((item: string) => {
                        if (item !== 'United States of America') {
                            this.phoneTmpArray?.push(this.modalDetails?.filterList[item]);
                        }
                    });
                    this.filteredArraysOtherUsa = this.filteredArray?.filter(items => items.countryCode !== '+1');
                    this.filterUSA = this.filteredArray?.filter(items => (items.countryCode === '+1' &&
                        !this.modalDetails?.filterListNotOfUSA?.includes(items.phNoShortForm)));
                    this.filteredArray = this.filteredArray?.filter(items => items.countryCode === '+1');
                    const tempSerchArray = this.rows?.searchArray;
                    this.rows.searchArray = this.filteredArray;
                    this.phoneTmpArray?.forEach((items: any) => {
                        this.filteredTmpArray = items;
                        items?.forEach((itemes: string) => {
                            this.formCategoryOptionsList(null, itemes, 'phNoShortForm');
                            this.filterItems(this.filteredTmpArray);
                        });
                    });

                    let filteredArray: any = {};
                    if (this.showFilterModal) {
                        if(uniquecountrySet.has('United States of America')&&this.filterUSA?.length > 0 ){
                            filteredArray = this.filterUSA;
                        }

                        if((this.filteredArray?.length> 0 && uniquecountrySet.size >1) ||
                                 (!uniquecountrySet.has('United States of America')&& this.filteredArray?.length> 0)){
                            if(filteredArray?.length >0){
                                filteredArray = filteredArray.concat(this.filteredArray);
                            }else{
                                filteredArray = this.filteredArray;
                            }
                        }

                        if(this.filteredArraysOtherUsa){
                            if(filteredArray?.length >0){
                                filteredArray = filteredArray.concat(this.filteredArraysOtherUsa);
                            }else{
                                filteredArray = this.filteredArraysOtherUsa;
                            }
                        }
                    } 
                   this.filtersvalue = filteredArray;
                    this.filteredRows?.emit(filteredArray);
                    this.rows.searchArray = tempSerchArray;
                }
                this.firstOptionFiltered = true;
            }
            if(this.modalDetails?.secondDropdownType === 'nativeDropdown' && this.secondDropdownValue?.length > 0) {
                this.filteredTmpArray.push(this.secondDropdownValue);
                this.formCategoryOptionsList(null, this.secondDropdownValue, 'Treatment');
                this.filterItems(this.filteredTmpArray);
                this.secondOptionFiltered = true;
            }
        } else {
            if (event?.target?.checked && event?.target?.value) {
                const filteredObject = {value: event.target.value, avoidTitleCase};
                this.filteredTmpArray.push(filteredObject);
                this.formCategoryOptionsList(event, searchValue, categoryTitle);
                this.filterItems(this.filteredTmpArray);
            } else {
                this.uncheckedData(event.target.value, categoryTitle);
            }
        }
    }

    public uncheckedData(value: string, categoryTitle: string) {
        const index = this.filteredTmpArray.findIndex(object => object.value === value);
        if (index > -1 || this.filteredTmpArray.length === 0) {
            this.filteredTmpArray.splice(index, 1);
            const categoryIndex = this.categoryOptions.findIndex(category => category.categoryTitle === categoryTitle);
            if (this.categoryOptions[categoryIndex].categoryOptionsArray.length === 1) {
                this.categoryOptions.splice(categoryIndex, 1);
            } else {
                const categoryOptionIndex = this.categoryOptions[categoryIndex].categoryOptionsArray
                    .findIndex(option => option.displayValue === value);
                this.categoryOptions[categoryIndex].categoryOptionsArray.splice(categoryOptionIndex, 1);
            }
            this.filterItems(this.filteredTmpArray);
        }
    }

    public deleteTag(optionValue: string) {
        if (optionValue === 'clearall') {
            this.rows?.filteredArray?.forEach((data: any) => {
                data.options.forEach((element) => {
                    element.isChecked = false;
                });
            });
            this.filteredTmpArray = [];
            this.categoryOptions = [];
            if(this.showFilterModal) {
                this.firstDropdownValue = [];
                this.secondDropdownValue = null;
                this.firstOptionFiltered = this.secondOptionFiltered = false;
                this.modalFilterDirty = false;
            }
            if (this.searchString?.length > 0) {
                this.searchResultsIndependent(this.filteredTmpArray, this.searchString, this.rows.searchArray);
            } else {
                this.filterTags.emit('');
                this.inputText.emit(this.searchString);
                this.filteredRows.emit(this.rows.searchArray);
            }
        } else {
            if(this.showFilterModal) {
                if(optionValue === 'Country Code') {
                    this.firstDropdownValue = [];
                    this.filterTags.emit([]);
                    this.filtereData(null);
                    this.firstOptionFiltered = false;
                } else if(optionValue === 'Treatment') {
                    this.secondDropdownValue = null;
                    this.filterTags.emit([]);
                    this.filtereData(null);
                    this.secondOptionFiltered = false;
                }
            } else {
                this.rows?.filteredArray?.forEach((data: any) => {
                    data.options.forEach((element) => {
                        if (optionValue === element.optionValue && optionValue !== 'clearall') {
                            element.isChecked = false;
                            return this.uncheckedData(optionValue, data.categoryTitle);
                        }
                    });
                });
            }
        }
        return this.rows;
    }

    public clearSearch() {
        let searchResults;
        const element: any = document.getElementById(this.searchId);
        if (element) {
            element.value = '';
            this.searchString = '';
            if (this.rows?.searchType === 'client') {
                this.filterItems(this.filteredTmpArray, this.searchString);
            } else if (this.rows?.searchType === 'server') {
                this.inputText.emit(this.searchString);
                this.filteredRows.emit('');
            }
        }
    }

    public formCategoryOptionsList(event: any, searchValue?: string, categoryTitle?: string) {
        let value = null;
        if(this.showFilterModal && event === null) {
            value = searchValue;
        } else {
            value = event.target?.value ? event.target.value : event;
        }
        const categoryIndex = this.categoryOptions.findIndex(ele => ele.categoryTitle === categoryTitle);
        if (categoryIndex > -1) {
            this.categoryOptions[categoryIndex].categoryOptionsArray.push({
                displayValue: value,
                searchValue: searchValue ? searchValue : value
            });
        } else {
            this.categoryOptions.push({
                categoryTitle,
                categoryOptionsArray: [{
                    displayValue: value,
                    searchValue: searchValue ? searchValue : value
                }]
            });
        }
    }

    /**
     * forming a list of options for accesibilty usage
     */
    public formOptionsList() {
        this.uniqueOptionsList = [];
        this.rows?.filteredArray?.forEach((category: any) => {
            category.options.forEach((element) => {
                this.uniqueOptionsList.push(element.optionValue);
            });
        });
    }
    /**
     * Accessibility -> closing of filter when not focused
     */
    public filterFocus(event: KeyboardEvent, op?: string) {
        if (event.key === 'Tab' && this.showFilter) {
            if (!this.uniqueOptionsList.includes(op)) {
                this.showFilter = false;
            }
        }
        if (event.key === 'Escape' && this.showFilter) {
            this.showFilter = false;
        }
    }

    private clearFilterTagAndSearch() {
        const element: any = document.getElementById(this.searchId);
        if (element) {
            element.value = '';
            this.searchString = '';
        }
        this.filteredTmpArray = [];
        this.filterTags.emit('');
        this.categoryOptions = [];
    }

}
