<div class="overlay-search hide" *ngIf="!datatableSearch"></div>
<div class="container row col-lg-2 col-md-2" [ngClass]="{'height-40': searchFieldHeight}">
    <div [ngClass]="{'row col-lg-2 col-md-12 col-sm-2': !msTeamsSearch}">
        <a #searchBtn href="javascript:void(0)" id="searchBtn" aria-label="Search" role="button" class="text-center" (click)="searchOnTable()" *ngIf="!datatableSearch" 
        [ngClass]="{'globalHeaderStyle': msTeamsSearch || singlePayerSearch || voiceEncryptionSearch, 'text-color-blue': singlePayerSearch, 'voice-encryption-text-color': voiceEncryptionSearch, 'width-120': msTeamsSearch}">
            <img src="assets/images/blueprint/magnifying-glass.svg" [ngClass]="{'mt-2': singlePayerSearch}" alt="search" role="presentation"/>
            <figcaption class="margin-top-0-5 position-relative" [ngClass]="{'ml-2': msTeamsSearch || singlePayerSearch || voiceEncryptionSearch, 'mb-2' : msTeamsSearch || singlePayerSearch || voiceEncryptionSearch, 'top-7': voiceEncryptionSearch}">Search</figcaption>
        </a>
        <template #searchcontainer></template>
    </div>
</div>