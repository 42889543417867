import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig, CBHelperService, DigitalProfileSharedService, TealiumUtagService } from 'common-ui-lib';
import { BillingHomeService } from 'redesign/billing-home/billing-home.service';
import { MakePaymentComponent } from 'redesign/billing-home/make-payment/make-payment.component';
import { Alert } from '../../cb-header.model';

@Component({
  selector: 'app-npd-alert',
  templateUrl: './npd-alert.component.html'
})
export class NpdAlertComponent implements OnInit, AfterViewChecked {

  @Input() npdDetails: Alert;
  @Input() paperlessBillDetails: Alert;
  public npdAlert = false;
  public paperlessBill = false;
  public isMyAdmin = false;
  constructor(
    public activeModal: NgbActiveModal,
    public cBHelperService: CBHelperService,
    public router: Router,
    public modalService: NgbModal,
    public billingService: BillingHomeService,
    public digitalService: DigitalProfileSharedService,
    private tealium: TealiumUtagService,
    private config: AppConfig,) { }

  ngOnInit(): void {
    this.isMyAdmin = this.cBHelperService.isMyAdmin();
    if (this.npdDetails != null || this.npdDetails !== undefined) {
      this.npdAlert = true;
    }
    if (this.paperlessBillDetails != null ||
      this.paperlessBillDetails !== undefined) {
      this.paperlessBill = true;
    }
  }

  ngAfterViewChecked() {
    if (this.isMyAdmin) {
      document.getElementById('npd-oliver')?.setAttribute('tabindex', '-1');
    } else {
      document.getElementById('npd-oliver')?.setAttribute('tabindex', '0');
    }
  }

  close(event) {
    this.activeModal.close(event);
  }

  public triggerTealium(eventName: string) {
    setTimeout(() => {
      this.close('Close click');
    }, 1000);
    this.tealium.button(eventName, eventName);
  }

  public navigateToPayments() {
    this.billingService.modalRef = this.modalService.open(MakePaymentComponent, {
      windowClass: 'redesign-modal-lg',
      backdrop: 'static',
    });
    this.close('Close click');
  }

  public navigateToBilling() {
    this.router.navigate(['/billinghome']);
    this.close('Close click');
  }

}
