import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService, CBHelperService, ResourceBundle,
 TealiumUtagService, DeviceDetectorService
} from 'common-ui-lib';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FeatureName } from
  'common-ui-lib/lib/shared/redesign/sublanding-page-description/sublanding-page-description.model';
import { BroadbandFactLabelService } from './broadbandfactlabel.service';
import { AccountInfo } from 'common-ui-lib/lib/shared/redesign/pageheader/pageheader.model';


@Component({
  selector: 'app-broadbandfactlabel',
  templateUrl: './broadbandfactlabel.component.html',
  styleUrls: ['./broadbandfactlabel.component.scss']
})
export class BroadbandFactLabelComponent implements OnInit {


  @ViewChild('broadbandFactsModal') public broadbandFactsModal: NgbModalRef;
  @Input() sulaFeatureName: FeatureName;
  @Input() selectedAccInfo: AccountInfo;

  public resourceBundle: ResourceBundle = {};
  public broadbandfactlabelModalRef: NgbModalRef;
  public isMultiAccount: boolean;
  public disableContinue = true;
  public contentLoaded = false;

  public filteredBroadbandFactlabelDetails: any;
  public error = false;
  public broadbandFactLabelNotAvailable = false;
  public broadbandFactLabelNetWorkErr = false;
  public isMobileDevice = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(private aemClient: AEMClientService,
    public deviceService: DeviceDetectorService,
    public cbHelperService: CBHelperService,
    public broadbandFactLabelService: BroadbandFactLabelService,
    public activeModal: NgbActiveModal,
    private tealium: TealiumUtagService,
  ) {
    this.aemClient.getSharedBundle('services')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: ResourceBundle) => {
          this.resourceBundle = data;
        },
        (error) => {
        }
      );
  }

  ngOnInit() {
    this.broadbandFactLabelService
      .getBroadbandFactlabelDetails(this.selectedAccInfo.accountNumber, this.selectedAccInfo.siteId)
      .subscribe((result: any) => {
        if (result.data?.broadBandFactLabelDetails) {
          this.filterBroadbandFactlabelDetails(result);
        } else {
          this.error = true;
          this.displayBroadbandFactlabelError(result);
        }
      },
      (error) => {
      }
    );
      if (this.deviceService.isMobile()) {
        this.isMobileDevice = true;
      }
  }

  public resetModal() {
    this.close(false);
  }

  public close(event: any) {
    this.activeModal.close(event);
  }

  public triggerTealium(eventName: string) {
    this.tealium.button(eventName, eventName);
  }

  private displayBroadbandFactlabelError(result: any) {
    if (result?.errors?.filter((error) => error.errorInfo.code === '8113').length) {
      this.broadbandFactLabelNotAvailable = true;
    } else if (result?.errors?.filter((error) => error.errorInfo.code === '8101').length) {
      this.broadbandFactLabelNetWorkErr = true;
    }
  }

  private filterBroadbandFactlabelDetails(result: any) {
    const bbflDetails = result.data.broadBandFactLabelDetails.bBFLDetails;

    this.filteredBroadbandFactlabelDetails = bbflDetails?.map((object: any) => {

      const newEntry = Object.assign({}, object);

      newEntry.linkDetails = object.linkDetails.reduce((acc, curr) => {
        const camelCasing = this.toCamelCase(curr.linkName);
        acc[camelCasing] = curr.link;
        return acc;
      }, {});

      newEntry.oneTimeFeeInstallations = object.oneTimeFee.map((fee: any) => ({
        chargeRate: fee.chargeRate,
        chargeName: fee.chargeName
      }));

      newEntry.providermonthlyfee = object.providermonthlyfee.map((curr: any) => ({
        gatewayEquipmentRentalFee: curr.gatewayEquipmentRentalFee,
        chargeName: curr.chargeName
      }));

      return newEntry;
    });
  }

  private toCamelCase(key: string) {
    return key
      .replace(/[^a-zA-Z\s]/g, '')
      .split(/\s+/)
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join('');
  }

}

