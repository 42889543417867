<ul ngbNav #menuTabs="ngbNav" [(activeId)]="activeTab" id="navLinks" class="nav-links nav-pills vertical" appTabAccessbility [keyboard]="true" orientation="vertical">
        <div id="menu-links" *ngIf="!isAccountSettings">
            <div class="pb-3 font-size-p-24 ceraPro-medium" *ngIf="isMobileView">{{fullName}}</div>
            <li [ngbNavItem]="'UserInfo'" class="user-card" *ngIf="!isMobileView">
                <a ngbNavLink
                    (click)="menuNavigation('settings/user/generalsettings/dashboard')">
                    <div class="user-content mb-3">
                        <div class="user-info pl-1">
                            <div class="row-text-overflow bold margin-bottom-10" title="{{fullName}}">{{fullName}}</div>
                            <div class="font-cerapro-regular margin-bottom-10" *ngIf="!isHidden">({{role}})</div>
                            <div class="two-line-ellipsis font-cerapro-regular margin-bottom-10" title="{{userEmail}}" *ngIf="!isHidden">{{userEmail}}</div>
                            <div class="font-cerapro-regular margin-bottom-10">
                                {{phoneNumber?.substr('0','3')}}-{{phoneNumber?.substr('3','3')}}-{{phoneNumber?.substr('6','4')}}
                            </div>
                        </div>
                    </div>
                </a>
            </li>
    
            <ng-container *ngFor="let menu of menuOptions; let i=index">
                <hr class="horizontal-divider" *ngIf="menu.name === 'Administrator Settings'">
                <div class="feature-section" role="group" attr.aria-label="{{menu.name}}">
                    <li class="section-header" *ngIf="checkSubMenu(menu.userSubOptions)">
                        <div class="row-text-overflow">{{menu.name}}</div>
                    </li>
                    <ng-container *ngFor="let submenu of menu.userSubOptions">
                        <li [ngbNavItem]="submenu.displayname" *ngIf="submenu.isEnabled" id="{{submenu.displayname}}" [ngClass]="{'defocus': !submenu.authorized}">
                            <a ngbNavLink (click)="menuNavigation(submenu.path, submenu.displayname)">
                                <div class="pt-1 pad-bottom-3 row-text-overflow">
                                    <span>{{submenu.displayname}}</span><img
                                        src="assets/images/blueprint/admin_redesign/arrow-right-black.svg"
                                        *ngIf="isMobileView" class="mt-1 pull-right"
                                        role="presentation" alt="arrow next icon" />
                                </div>
                            </a>
                        </li>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div id="menu-links" *ngIf="isAccountSettings">
            <ng-container *ngFor="let menu of menuOptions; let i=index">
                <li [ngbNavItem]="menu.displayname" *ngIf="menu.isEnabled" id="{{menu.displayname}}" class="account-options">
                    <a ngbNavLink (click)="menuNavigation(menu.path, menu.displayname)">
                        <div class="pt-1 pad-bottom-3 row-text-overflow account-item">
                            <div *ngIf="isMobileView" class="margin-right-icon mb-2 mr-4">
                                <span class="row-text-overflow">{{menu.displayname}} <img
                                    src="assets/images/blueprint/admin_redesign/arrow-right-black.svg"
                                    *ngIf="isMobileView" class="mt-1 pull-right" role="presentation" alt="arrow next icon" /></span>
                            </div>
                            <div *ngIf="!isMobileView" class="mb-2 {{menu.shortName}}">
                                <div class="row-text-overflow">{{menu.displayname}}
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </ng-container>
        </div>
    <li *ngIf="removeLink && !removeLink[0]?.defocusRemoveAccount && removeLink[0]?.linkName !== null"
         class="margin-left-5px margin-top-28" [ngClass]="{'defocus': userDetails?.hasSecurityPermissions}" role="presentation">
            <a href="javascript:void(0)" (click)="emitRemoveAccount()" role="button">
            <img src="assets/images/blueprint/icons/delete-trash-desktop.svg" attr.alt="{{removeLink[0]?.linkName}} icon" class="mr-3 margin-bottom-5px" role="presentation"/>
            <span>{{removeLink[0]?.linkName}}</span>
            </a>
    </li>
</ul>
