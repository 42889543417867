import {
    Component, HostListener, Input, OnChanges, OnInit, OnDestroy,
    SimpleChanges, ViewChild, ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
    AEMClientService, CBHelperService, CbMapsService, CommonMessageService, DefaultHeaderService,
    LocalStorageKeys, ResourceBundle, UnplannedOutageModalComponent, DigitalProfileSharedService,
    TealiumUtagService, AppConfig, SSOService,StringUtilService
} from 'common-ui-lib';
import { DeviceDetectorService } from 'common-ui-lib';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { AEMMessagesService } from './aemMessages.service';
import { PlannedoutageComponent } from './plannedoutage/plannedoutage.component';
import { NpdAlertComponent } from './npd-alert/npd-alert.component';
import { SessionStorageService } from 'ngx-webstorage';
import { Alert, Profile } from '../defaultHeader.model';
import { LocalStorageService } from 'ngx-localstorage';
import { SelfInstallService } from 'common-ui-lib/lib/self-install/self-install.service';
import { FttxAlertComponent } from './fttx-alert/fttx-alert.component';
import { OutageType } from 'common-ui-lib/lib/shared/cb-maps/cb-maps.model';
import { MyaccountHomeService } from 'home/myaccounthome.service';
import { SymphonyFlow, ValidateSwap } from 'home/myaccounthome.model';
import { SSOPageName } from 'common-ui-lib/lib/constants/ssopagename.model';
@Component({
    selector: 'app-aem-messages',
    templateUrl: './aemMessages.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./aemMessages.component.scss']
})

export class AEMMessagesComponent implements OnInit, OnChanges {
    @ViewChild('quickLinkPopover') quickLinkPopover: NgbPopover;
    @Input()
    public alerts: Profile;

    public msgs = null;
    public resourceBundle: ResourceBundle = {};
    public popoverPlacement = 'bottom';
    public popoverList = null;
    public guid = null;
    public alertCheck: boolean;
    public alertCount: number;
    public alert: string;
    public viewCount: string;
    public npdAlert = false;
    public npdAlertDetails: Alert;
    public billingFlag = false;
    public paperlessBillAlert = false;
    public paperlessBillDetails: Alert;
    public isMobileDevice = false;
    public serviceAppointmentDate: Date;
    public serviceAppointmentTime: string;
    public deviceStatus = {};
    public isMarketRollOutFlag: boolean;
    public profileGuid: string;
    public alreadySwapped = false;
    public swapAlert = false;
    public symphonyResponse: SymphonyFlow;
    public validateSwap = ValidateSwap;
    public alertAutoPopup= true;
    public hideSelfInstall = false;
    public apiCallMade = false;
    private onDestroy$ = new Subject<boolean>();
    constructor(
        public messageService: AEMMessagesService, public aemClient: AEMClientService, private modalService: NgbModal,
        public deviceService: DeviceDetectorService,
        public cbHelperService: CBHelperService, public commonMessageService: CommonMessageService,
        private router: Router, private sessionStorage: SessionStorageService,
        private defaultHeaderService: DefaultHeaderService,
        private _storageService: LocalStorageService,
        public selfInstallService: SelfInstallService,
        private cbMapsService: CbMapsService,
        public digitalProfileService: DigitalProfileSharedService,
        private tealium: TealiumUtagService,
        private homeService: MyaccountHomeService,
        private stringUtilService: StringUtilService,
        private config: AppConfig, private ssoService: SSOService) {
        if (deviceService.isMobile()) {
            this.popoverPlacement = 'bottom-left';
        }
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            if (this.cbHelperService.isProfileInContext() && (!window.location.href.includes('/unauth/logout')
                && !window.location.href.includes('/unauth/myadminlogout') && !this.cbHelperService.isOBUrl())) {
                this.ngOnInit();
                this.quickLinkPopover?.close();
            }
        });
        this.cbHelperService.initiateHeaderRefreshSub.subscribe((data) => {
            if (data) {
                this.ngOnInit();
            }
        });
    }

    // Close nav on click of outside
    @HostListener('window:click', ['$event'])
    public onMouseClick(event) {
        if (this.deviceService.isMobile()) {
            if (!document.getElementById('sidebar')?.contains(event.target)
                && !document.getElementById('messagelinkmobile')?.contains(event.target)) {
                this.closeSideBar();
            }
        }
    }

    ngOnInit() {
        this.deviceStatus = {};
        this.isMarketRollOutFlag = this.cbHelperService.getMarketRollOut;
        this.profileGuid = this.cbHelperService.getLoggedInUserDetails().profile.userToken.profileGuid;
        if (this.cbHelperService.isProfileInContext() && (!window.location.href.includes('/unauth/logout')
            && !window.location.href.includes('/unauth/myadminlogout'))) {
            // this.getOutageAlert("ALL");
            this.aemClient.getSharedBundle('mymessages_redesign')
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (data: any) => {
                        this.resourceBundle = data;
                    }
                );
            this.guid = this.cbHelperService.getLoggedInUserDetails().profile.userToken.guid;
            if (this.cbHelperService.getLoggedInUserDetails().profile.roles.includes('bpr')) {
                this.billingFlag = true;
            }
            console.log('OKTAFLOW-AEMMessagesComponent ngOnInit');
            this.messageService.setUserDetails(null);
            this.getNotificationList(this.guid);
            if (this.deviceService.isMobile()) {
                this.isMobileDevice = true;
            }
            if (this.defaultHeaderService?.isSwapAlert || sessionStorage.getItem('isSwap')) {
                this.alreadySwapped = true;
            }
            if (!this.cbHelperService.isMyAdmin() && !this.symphonyResponse) {
                this.getSymphonySwapResponse();
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('OKTAFLOW - Changes AEMMessagesComponent : ', changes);
        if (changes.alerts && changes.alerts.currentValue) {
            this.buildOutageAlert(this.alerts);
        }
    }

    public async getSymphonySwapResponse() {
      if (!this.apiCallMade) {
        this.apiCallMade = true;
        try {
            this.symphonyResponse = await this.homeService.getSymphonyFlow();
        } catch (error) {
            this.alreadySwapped = false;
            this.swapAlert = false;
        }
      }
    }

    public async onClickOfSwapAlert(response: string) {
        try {
            const data = await this.ssoService.getSSOService(SSOPageName.SYMPHONY_CBFORCE);
            if (data?.code === '0') {
                if (response === ValidateSwap.Buyflow) {
                    window.open(this.config.getConfig('SSO')['PI9']['getCbForceUpdateUrl'], '_blank');
                }
                 else {
                    window.open(this.config.getConfig('leadformurl'), '_blank');
                }
            }
        } catch (error) {
            window.open(this.config.getConfig('leadformurl'), '_blank');
        }
    }

    public buildDeviceStatus(status: string) {
        switch (status) {
            case this.resourceBundle.pendingStatus:
                this.deviceStatus = {
                    title: this.resourceBundle.pendingStatustitle,
                    description: this.resourceBundle.pendingStatusDescription,
                    status: this.resourceBundle.pendingStatus,
                    statusTitle: this.resourceBundle.pendingTitle
                };
                break;
            case this.resourceBundle.shippedStatus:
                this.deviceStatus = {
                    title: this.resourceBundle.shippedStatustitle,
                    description: this.resourceBundle.shippedStatusDescription,
                    status: this.resourceBundle.shippedStatus,
                    statusTitle: this.resourceBundle.shippedTitle
                };
                break;
            case this.resourceBundle.deliveredStatus:
                this.deviceStatus = {
                    title: this.resourceBundle.deliveredStatustitle,
                    description: this.resourceBundle.deliveredStatusDescription,
                    status: this.resourceBundle.deliveredStatus,
                    statusTitle: this.resourceBundle.deliveredTitle
                };
                break;

        }
    }

    //Query call to get notification list
    public getNotificationList(guid: string) {
        this.viewCount = '';
        console.log('OKTAFLOW-AEMMessagesComponent getNotificationList');
        this.messageService.getMessages(guid)
            .pipe(finalize(() => {
                this.messageService.contentLoaded = true;
            }))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .subscribe((results: any) => {
                this.messageService.setUserDetails(results);
                if (results?.data !== null && results?.data?.messageNotification !== null) {
                    this.msgs = results?.data?.getUser?.messageNotification;
                    if (this.msgs?.unreadCount <= 99) {
                        this.messageService.unreadCount = this.msgs?.unreadCount;
                    } else {
                        this.messageService.unreadCount = '99+';
                    }
                    this.messageService.messagesList = this.msgs?.notificationList;
                    this.viewCount = ' (' + this.messageService.unreadCount.toString() + ')';
                }
                // if (results.errors) {
                //     let errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'getUser');
                //     if (errorInfo) {
                //         window.scroll(0, 0);
                //         this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error');
                //     }
                // }
            });
    }

    //Creating the list for popover
    public isPopoverOpen(isOpen: boolean) {
        this.popoverList = this.messageService.messagesList;
        const closeLabel = document.querySelector('#closeMsgs');
        if (isOpen) {
            document.getElementById('msgsLink')?.classList.add('popoverOpeningLink');
            if (this.isMarketRollOutFlag) {
                this.getDigitalProfileIndicators(this.profileGuid);
            } else {
                this.getOutageAlert('ALL');
            }
            closeLabel.textContent = '';
        } else {
            document.getElementById('msgsLink')?.classList.remove('popoverOpeningLink');
            closeLabel.textContent = this.resourceBundle.notificationsCollapsed;
        }
    }

    public closeSideBar() {
        if (this.deviceService.isMobile()) {
            document.getElementById('wrappermsgMobile')?.classList?.remove('show');
            document.getElementsByClassName('overlay-msg')[0]?.classList?.add('hide');
        }
    }

    public toggleMessageLink() {
        this.popoverList = this.messageService.messagesList;
        if (this.isMarketRollOutFlag) {
            this.getDigitalProfileIndicators(this.profileGuid);
        } else {
            this.getOutageAlert('ALL');
        }
        document.getElementById('wrappermsgMobile')?.classList?.add('show');
        document.getElementsByClassName('overlay-msg')[0]?.classList.remove('hide');
    }

    //Moadal popup open on click
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onNotificationClick(notificationId: any) {
        this.closeSideBar();
        this.router.navigate(['/notificationhistory'], { queryParams: { notification: 'yes', id: notificationId } });
    }

    public onViewAllMsgClick() {
        this.closeSideBar();
        this.router.navigate(['/notificationhistory']);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public offFocus(event: any) {
        const closeLabel = document.querySelector('#closeMsgs');
        closeLabel.textContent = '';
        if (event) {
            this.quickLinkPopover.close();
            closeLabel.textContent = this.resourceBundle.notificationsCollapsed;
        }
    }

    public openPlannedOutageModal() {
        this.closeSideBar();
        if (this.isMarketRollOutFlag) {
            this.onOutageClick(OutageType.PLANNED);
        } else {
            this.modalService.open(PlannedoutageComponent,
                { windowClass: 'redesign-modal-md', backdrop: 'static' }).result.then((result) => { });
        }
    }

    public openUnplannedOutageModal() {
        this.closeSideBar();
        if (this.isMarketRollOutFlag) {
            this.onOutageClick(OutageType.UNPLANNED);
        } else {
            this.modalService.open(
                UnplannedOutageModalComponent, { windowClass: 'redesign-modal-md', backdrop: 'static' });
        }
    }

    public openFiberInternetModal() {
        this.closeSideBar();
        this.modalService.open(FttxAlertComponent, {windowClass: 'redesign-modal-md', backdrop: 'static'});
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openFiberInternetAccModal(event: any) {
        this.quickLinkPopover.close();
        if (event.key === 'Enter') {
            this.closeSideBar();
            this.modalService.open(FttxAlertComponent, { windowClass: 'redesign-modal-md', backdrop: 'static' });
        }
    }
    public openNpdAlertModal() {
        this.closeSideBar();
        const modalRef = this.modalService.open(NpdAlertComponent,
            { windowClass: 'redesign-modal-md', backdrop: 'static' });
        modalRef.componentInstance.npdDetails = this.npdAlertDetails;
    }
    public openPaperlessBillAlertModal() {
        this.closeSideBar();
        const modalRef = this.modalService.open(NpdAlertComponent,
            { windowClass: 'redesign-modal-md', backdrop: 'static' });
        modalRef.componentInstance.paperlessBillDetails = this.paperlessBillDetails;
    }

    public navigateToServiceAppts() {
        this.closeSideBar();
        const isMultiple = this.alerts?.hasServiceAppointment?.isMultiple;
        if (isMultiple) {
            this.router.navigateByUrl(`cbportal?isMultiple=${isMultiple}`);
        } else {
            this._storageService.set(LocalStorageKeys.SelectedAccount, this.alerts?.hasServiceAppointment?.accountGuid);
            this.router.navigateByUrl('cbportal');
        }
    }

    public triggerTealium(eventName: string) {
        this.tealium.button(eventName, eventName);
      }

    // Profile Query
    private getOutageAlert(filter: string) {
        this.messageService.getOutageAlert(filter)
            .pipe(finalize(() => {
                this.messageService.contentLoaded = true;
            }))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .subscribe((results: any) => {
                if (results.data !== null && results.data?.getProfile !== null) {
                    const alerts = results.data?.getProfile;
                    this.buildOutageAlert(alerts);
                }
                if (results.errors) {
                    const errorInfo = this.commonMessageService.getErrorInfo(results.errors, 'getProfile');
                    if (errorInfo) {
                        window.scroll(0, 0);
                        this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error');
                    }
                }
            });
    }

    // Digital Profile Query
    private getDigitalProfileIndicators(profileGuid) {
        this.digitalProfileService.fetchHeaderDetails(profileGuid);
        this.digitalProfileService.getDigitalProfileResponse.subscribe((response: any) => {
            if (response) {
                const alerts = response?.headerNotifications;
                this.buildOutageAlert(alerts);
            }
        });
    }
    private buildOutageAlert(alerts: Profile) {
        this.alertCount = 0;
        this.alertCheck = false;
        this.npdAlert = false;
        this.paperlessBillAlert = false;
        if (alerts?.indicatorEvents?.plannedOutageIndicator) {
            this.alertCount = this.alertCount + 1;
            this.alertCheck = true;
            this.alertAutoPopup = true;
        }
        if (alerts?.unplannedOutageIndicator) {
            this.alertCount = this.alertCount + 1;
            this.alertCheck = true;
            this.alertAutoPopup = true;
        }
        if (alerts?.indicatorEvents?.promotionalOutageIndicator) {
            this.alertCount = this.alertCount + 1;
            this.alertCheck = true;
            this.alertAutoPopup = true;
        }
        if (alerts?.hasServiceAppointment?.accountGuid) {
            this.alertCount = this.alertCount + 1;
            this.alertCheck = true;
            this.alertAutoPopup = true;
            this.serviceAppointmentDate = this.dateStringToDate(alerts?.hasServiceAppointment.date);
            this.serviceAppointmentTime = alerts?.hasServiceAppointment.time;
        }
        if (alerts?.deviceInstallDetails?.selfInstallStatus &&
            alerts?.deviceInstallDetails?.selfInstallStatus !== 'No Install' &&
            alerts?.deviceInstallDetails?.selfInstallStatus !== 'All Installed') {
            this.hideSelfInstall = false;
            this.alertCount = this.alertCount + 1;
            this.alertCheck = true;
            this.alertAutoPopup = true;
            const deviceInstallStatus = alerts?.deviceInstallDetails?.selfInstallStatus?.toLowerCase();
            const accountGuid = alerts?.deviceInstallDetails?.euAcctGuid;
            const getUserType = this.cbHelperService.getLoggedInUserDetails()?.profile?.userToken?.userType;
            if (getUserType === 'END_USER') {
                this.cbHelperService.accountGuid = accountGuid;
            }
            this.buildDeviceStatus(deviceInstallStatus);
        } else {
            this.hideSelfInstall = true;
        }
        if (alerts?.pinpointAlerts?.indicator) {
            this.alertCount = this.alertCount + 1;
            this.alertCheck = true;
            this.alertAutoPopup = true;
            alerts.pinpointAlerts?.alertsList.forEach((ele) => {
                if (ele.action === this.resourceBundle.npdAction) {
                    this.npdAlert = true;
                    this.npdAlertDetails = {
                        action: ele.action,
                        message: ele.message
                    };
                } else if (ele.action === this.resourceBundle.paperlessBillAction) {
                    this.paperlessBillAlert = true;
                    this.paperlessBillDetails = {
                        action: ele.action,
                        message: ele.message
                    };
                }
            });
        }
        if (this.symphonyResponse?.swapResponse === ValidateSwap.Alreadyswapped &&
            !this.cbHelperService.isMyAdmin() && this.alreadySwapped && !this.symphonyResponse.symphonyAlert) {
            this.alertCount = this.alertCount + 1;
            this.alertCheck = true;
            this.alertAutoPopup = true;
        }
        if ((this.symphonyResponse?.swapResponse === ValidateSwap.Buyflow &&
            this.symphonyResponse?.symphonyAlert === ValidateSwap.Buyflow) ||
            (this.symphonyResponse?.swapResponse === ValidateSwap.Leadform &&
            this.symphonyResponse?.symphonyAlert === ValidateSwap.Leadform) && !this.cbHelperService.isMyAdmin()) {
            this.alreadySwapped = false;
            this.swapAlert = true;
            this.alertCount = this.alertCount + 1;
            this.alertCheck = true;
            this.alertAutoPopup = true;
        }
        if (this.alertCheck) {
            this.alert = this.resourceBundle.alerts + ' (' + this.alertCount.toString() + ')';
        } else {
            this.alert = this.resourceBundle.alerts;
        }
        if (this.sessionStorage.retrieve('firstLogin') && this.alertCheck && this.alertAutoPopup) {
            if (this.deviceService.isMobile()) {
                document.getElementById('wrappermsgMobile')?.classList?.add('show');
                document.getElementsByClassName('overlay-msg')[0]?.classList.remove('hide');
            } else {
                this.checkPageloaded();
            }
        }
        this.sessionStorage.clear('firstLogin');
    }

    private dateStringToDate(dateString) {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        const date = new Date(year, month - 1, day);
        return new Date(date.getTime());
    }

    private checkPageloaded() {
        let subscription$ = new Subscription();
        const interval$ = interval();
        subscription$ = interval$.subscribe((data) => {
            if (!this.router.url.includes('/home/landing') || !this.router.url.includes('/')) {
                console.log('OKTAFLOW : page loaded!!!! and unsubscribing', this.router.url);
                this.quickLinkPopover.open();
                subscription$.unsubscribe();
            }
            if (data === 20) {
                subscription$.unsubscribe();
            }

        });
    }

    private onOutageClick(outageType: OutageType) {
        this.router.navigateByUrl('/portal');
        this.cbMapsService.setOutageViewDetailsClicked(true, outageType);
        setTimeout(() => {
            document.getElementById('cbMap').scrollIntoView({ behavior: 'smooth' });
        }, 200);
    }
}
