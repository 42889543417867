import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AEMClientService, ResourceBundle } from '../services/aemclient.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.scss'],
})
export class ErrorpageComponent implements OnInit, OnDestroy {

  dispError = false;
  resourceBundle: ResourceBundle = {};
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private cookieService: CookieService,
    private aemClient: AEMClientService,
  ) { }

  ngOnInit() {
    this.aemClient.getErrorBundle('errorpageResourceBundle')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: ResourceBundle) => {
        this.resourceBundle = data;
      });
    this.cookieService.delete('s_sq');
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
