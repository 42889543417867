import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CBHelperService, CommonMessageService, DeviceDetectorService, DigitalProfileSharedService,
   PhonePipe, Regex, ResourceBundle, TealiumUtagService } from 'common-ui-lib';
import { deviceDetails } from 'common-ui-lib/lib/self-install/self-install.model';
import { SelfInstallService } from 'common-ui-lib/lib/self-install/self-install.service';
import { DeviceInstallationStepsComponent } from '../device-installation-steps/device-installation-steps.component';

@Component({
  selector: 'app-contact-details-modal',
  templateUrl: './contact-details-modal.component.html',
  styleUrls: ['./contact-details-modal.component.scss']
})
export class ContactDetailsModalComponent implements OnInit {
  @Input() resourceBundle: ResourceBundle = {};
  @Input() deviceDetails: deviceDetails;
  public isMobileNumberAvailable: boolean;
  contactInfoForm: FormGroup;
  public isMobileDevice = false;
  public phone: PhonePipe = new PhonePipe();
  public contentLoaded = false;
  public installationStepsList = [];

  constructor(public selfInstallService: SelfInstallService,
    private tealium: TealiumUtagService,
    public deviceDetectorService: DeviceDetectorService,
    public activeModal: NgbActiveModal,
    public messageService: CommonMessageService,
    public cbHelperService: CBHelperService,
    public digitalProfileService: DigitalProfileSharedService,
    private modalService: NgbModal
    ) {
     }

  ngOnInit(): void {
    this.isMobileDevice = this.deviceDetectorService.isMobile();
    this.contactInfoForm = new FormGroup({
      emailId: new FormControl(null, [
        Validators.pattern(Regex.EMAIL_REGEX).bind(this)
      ]),
      contactNumber: new FormControl(null, [
        Validators.required,
        Validators.pattern(Regex.PHONE_PATTERN_REGEX).bind(this)
      ]),
    });
    this.selfInstallService
      .getContactDetails().subscribe(
        (result) => {
          if(!result?.errors){
            this.contentLoaded = true;
            const netAssuranceContactDetails = result?.data?.getNetAssuranceDeviceContactDetails;
            if(netAssuranceContactDetails?.isMobileNumber === 'Y'){
              const contactValue = this.phone.transform(netAssuranceContactDetails?.phoneNumber);
              this.contactInfoForm.controls['contactNumber'].setValue(contactValue);
              this.isMobileNumberAvailable = false;
            } else{
              this.contactInfoForm.controls['contactNumber'].setValue('');
              this.isMobileNumberAvailable = true;
            }
            this.contactInfoForm.controls['emailId'].setValue(netAssuranceContactDetails?.email);
          } else{
            this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'contactResponse');
          }
        });
  }


 public getMobileNumber(){
  if(this.contactInfoForm.get('contactNumber').errors){
    this.isMobileNumberAvailable = true;
  } else {
    this.isMobileNumberAvailable = false;
  }
 }

  public triggerTealium(eventName: string){
    this.tealium.button(eventName, eventName);
  }

  public updateContactInfo(form){
    const email = form.get('emailId').value;
    const phoneNumber = form.get('contactNumber').value.replaceAll('-', '');
    const serialNumber = this.deviceDetails.serialNumber;
    this.selfInstallService.updateContactDetails(email, phoneNumber, serialNumber).subscribe((result)=>{
      if(result?.errors){
        this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'contactResponse');
      } else{
        this.onNextClick();
      }
    });
  }

  public onNextClick(){
    this.selfInstallService.accountGuid = this.selfInstallService.accountGuid ?
     this.selfInstallService.accountGuid : this.cbHelperService.accountGuid;
    this.selfInstallService.getInstallationSteps(this.selfInstallService.accountGuid,
       this.deviceDetails.channelSolutionID)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .subscribe((results: any) => {
      if (!results.errors) {
        this.installationStepsList = results?.data?.getAccount?.getSelfInstallDeviceDetails.deviceContent;
        this.activeModal.close();
        const modalRef = this.modalService.open(DeviceInstallationStepsComponent, {
          windowClass: this.isMobileDevice ? '' : 'redesign-modal-md',
          backdrop: 'static'
        });
        modalRef.componentInstance.installationStepsList = this.installationStepsList;
        modalRef.componentInstance.deviceDetails = this.deviceDetails;
        modalRef.componentInstance.resourceBundle = this.resourceBundle;
      } else {
        this.messageService.displayMessage.show = true;
        this.messageService.showMessage(this.resourceBundle.genericerrorMsg, 'error', 'installationSteps');
      }
    });
  }

  public modalClose(){
    this.activeModal.close();
    sessionStorage.removeItem('isNetAssurance');
    this.digitalProfileService.netAssuranceStatus = false;
  }
}
