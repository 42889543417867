import {Directive, TemplateRef} from '@angular/core';

/** Decorates the `ng-template` tags and reads out the template from it. */
// tslint:disable-next-line: max-classes-per-file
// tslint:disable-next-line: directive-selector
@Directive({selector: '[matTabContent]'})
// tslint:disable-next-line: directive-class-suffix
export class MatTabContent {
  // tslint:disable-next-line: no-any
  constructor(public template: TemplateRef<any>) { }
}
