<div id="CoxHNHeader" class="row">
  <div class="col-lg-12 col-md-12 hidden-sm hidden-xs">
    <img alt="LOGO" class="margin-top-1 ae-img" src="assets/images/coxbusiness_logo.png">
    <img alt="LOGO" class="margin-top-1 ae-img pull-right pad-md-right-p-10" src="assets/images/hn_logo_lg.png">
  </div>
  <div class="col-lg-12 col-md-12 hidden-md hidden-lg">
    <img alt="LOGO" class="margin-top-1 ae-img margin-left-36p" src="assets/images/coxbusiness_logo.png">
    <img alt="LOGO" class="margin-top-1 ae-img pull-right" src="assets/images/hn_logo_xs.png">
  </div>
  <div class="pf-header-business-hn col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
</div>