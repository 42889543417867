<!-- Title -->
<div class="row cb-feature-title text-center">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div [ngClass] ="{'h1':!hasWelcomeWidget, 'h2': hasWelcomeWidget}" id="headingLevel" role="heading" translate  aria-level="2">
          <span>{{title}}</span>
          <!--redesigning the old screens where content is center aligned needs the indicator in  new line-->
        <div *ngIf="indicator" [class]="(deviceService.isMobile() && contentCenter) ?'vertical-align-middle mediumfont' : 'pagehero'">
          <span *ngIf="status" class="badge badge-pill badge-success margin-bottom-10 margin-left-10">ON</span>
          <span *ngIf="!status" class="badge badge-pill badge-danger margin-bottom-10 margin-left-10">OFF</span>
        </div>
      </div>
    </div>
  </div>
  <!-- Description -->
  <div class="row cb-title-desc page-hero-description" *ngIf="description">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <p class="m-0" [ngClass]="{'text-center':contentCenter}" [innerHTML]="description"></p>
    </div>
  </div>
  <hr *ngIf="!hideRule" class="cb-title-rule">
