import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AEMClientService, CommonMessageService, MegaMenuService,
  ResourceBundle, DeviceDetectorService, AppConfig } from 'common-ui-lib';
import { CBHelperService } from 'common-ui-lib/lib/services/cbhelper.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss'],
})
export class MegaMenuComponent implements OnInit {
  public menuItems: any;
  public isCollapsed = false;
  public resourceBundle: ResourceBundle = {};
  public expand: string;
  public userType: any;
  public userProfile: any;
  public loggedInUserType: any;
  public isMobileDevice = false;
  public darkBackgroundArr = [
    'VIEW_AND_PAY_BILL',
    'ACCESS_COX_BUSINESS_EMAIL',
    'CHANGE_PASSWORD',
    'PRODUCT_ADMINISTRATION',
    'LOG_IN_AS',
    'LOGOUT',
  ];
  private onDestroy$ = new Subject<boolean>();
  constructor(
    public aemClient: AEMClientService,
    private router: Router,
    public megaMenuService: MegaMenuService,
    public deviceService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    private cbhelperService: CBHelperService,
    private config: AppConfig) {
    this.cbhelperService.initiateHeaderRefreshSub.subscribe((data) => {
      if (data) {
        this.ngOnInit();
      }
    });

    // this logic has been added when we do switch user or navigate to different pages
    // to fetch the data at compoent level
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (this.activatedRoute.snapshot.queryParamMap.get('switchuser')) {
        this.ngOnInit();
        this.onMegaMenuClick('close');
        this.router.navigate([], { queryParams: { switchuser: null }, queryParamsHandling: 'merge' });
      }
    });
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event) {
    let tagName;
    let parentTagName;
    if (typeof event?.target?.className === 'string') {
      tagName = event?.target?.className?.toLowerCase();
    }
    if (typeof event?.target?.parentNode?.className === 'string') {
      parentTagName = event?.target?.parentNode?.className?.toLowerCase();
    }
    if (
      (tagName?.includes('mega-menu') || parentTagName?.includes('mega-menu') || tagName?.includes('sub-category')) &&
      !tagName?.includes('mega-menu-close-img')
    ) {
      this.isCollapsed = !this.isCollapsed;
    } else {
      this.isCollapsed = false;
    }
  }

  ngOnInit() {
    if (!window.location.href.includes('/unauth/logout') && !window.location.href.includes('/unauth/myadminlogout')
    && !window.location.href.includes('/unauth/okta-api')) {
      this.aemClient
        .getSharedBundle('megaMenu')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data: any) => {
          this.resourceBundle = data;
        });
      this.userProfile = this.cbhelperService.getLoggedInUserDetails().profile.userToken;
      this.userType = this.userProfile.userType;
      this.loggedInUserType = this.userProfile.loggedInUserType;
      this.expand = 'collapsed';
      if(this.deviceService.isMobile()) {
        this.isMobileDevice = true;
      }
    }
  }

  // Query call to get menu list content
  public getMenuDetails() {
    this.megaMenuService.getMegaMenuList().subscribe((results: any) => {
      if (results?.data !== null) {
        this.menuItems = results.data?.headerMenu;
        this.menuItems.options.forEach((item)=> {
            if(item.name === 'MYACCOUNT_HOME') {
              item.url = this.cbhelperService.getMarketRollOut ? '/portal' : '/dashboard';
            }
          });
      } else {
        this.menuItems = [];
      }
    });
  }

  // Open the mega menu list on click of Avatar
  public onMegaMenuClick(value?: string) {
    this.isCollapsed = !this.isCollapsed;
    if (value === 'close') {
      this.isCollapsed = false;
    }
  }

  // On click of list item navigate to respective path
  public navigateOnClick(path: string, openInNewWindow: boolean) {
    let url;
    this.closeSideBar();
    if (this.isCollapsed) {
      this.isCollapsed = false;
    }
    if (path?.startsWith('https') || path?.startsWith('http')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      openInNewWindow ? window.open(path) : window.open(path, '_self');
    } else if (path && openInNewWindow) {
      url = `${window.location.origin}/cbma${path}`;
      window.open(url);
    } else {
      this.router.navigateByUrl(path);
    }
  }

  // Mobile view - display the sidebar on click of Avatar
  public toggleMegaMenuLink() {
    document.getElementById('megaMenuMobile').classList?.add('show');
    this.expand = 'expanded';
  }

  // Mobile view - to close the sidebar
  public closeSideBar() {
    if (this.deviceService.isMobile()) {
      document.getElementById('megaMenuMobile').classList?.remove('show');
      this.expand = 'collapsed';
    }
  }

  // Accessibility - close the menu when focus comes out of the list
  public outOfMegaMenu(event, elementId: string) {
    if (elementId === 'LOGOUT') {
      if (event.key === 'Tab' && !event.shiftKey) {
        this.isCollapsed = false;
      }
    }
  }

}
