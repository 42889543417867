import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MalpService {
  public profileHasCbcc = false;
  public outageIndicator: boolean;
  public isFormer: boolean;
  public loadOutageIndicator: boolean;
  public isEUWithoutPermissions = false;
  public accountListLength: number;

  constructor(private apollo: Apollo) { }

  public getServiceDetails(accountGuid: string) {
    const getServiceDetails = gql`
    query getAccountDetails($accountGuid: ID!) {
      getAccount(id: $accountGuid) {
        serviceFeature(featureCategoryName: ALL) {
          cbFeatures {
            userAccountsFlag 
            featuresList{
              featureName
              role
            }
          }
          cbServices {
              tvService
              internetService
              voiceService
              networkingService
              securityService
              appService
              subServiceDetails {
                category
                subServiceList {
                  role
                  subCategory
                  featureName
                }
              }
          }
          accountAuthUser
        }
      }
    }
  `;
    return this.apollo.query({
      query: getServiceDetails,
      variables: {
        accountGuid
      },
    });
  }

  public getProfileLevelServices(profileGuid: string) {
    const getProfileLevelServicesQuery = gql`
    query getProfileDetails($profileGuid: String!) {
      getProfile(accountStatusFilter: "ALL") {
        unplannedOutageIndicator
        pinpointAlerts {
                    indicator
                    alertsList {
                        action
                        message
                    }
        }
        bwGroupId
        bwSpId
        companyName
        guid
        id
        isMultiAccount
        profileStatus(profileGuid: $profileGuid) {
          hasActiveAccount
          hasFormerAccount
        }
        serviceFeature(featureCategoryName: ALL) {
          cbFeatures {
            userAccountsFlag
            featuresList {
              featureName
              role
            }
          }
          cbServices {
            voiceService
            tvService
            securityService
            networkingService
            internetService
            appService
            subServiceDetails {
              category
              subServiceList {
                subCategory
                featureName
                role
              }
            }
          }
          accountAuthUser
        }        
        support {
            noCaas
            cbCare {
              description
              passCode
              title
            }
          }
      }
    }
    `;
    return this.apollo.query({
      query: getProfileLevelServicesQuery,
      variables: {
        profileGuid
      }
    });
  }

  public billStatementDetails(id: string): Observable<any> {
    const statetementDetails = gql`
      query statetementDetails($id: ID!) {
        getAccount(id: $id) {
          serviceFeature(featureCategoryName: BILLING) {
            cbServices {
              subServiceDetails {
                category
                subServiceList {
                  featureName
                  role
                  subCategory
                }
              }
            }
          }
          billStatements(requestType: "paperless") {
            statements {
              status
              code
              identifier
              totalAmountDue
              totalPastAmountDue
              isPaperlessEligible
              isAutoPayEligible
              isEnrolledInAutoPay
              billHandlingPreferenceType
              billHandlerCode
              currentDue
              amountDueToday
              nextPaymentDate
              dueDate
              statementDueDate
              billingAddress {
                houseNumber
                street
                city
                state
                zipCode
              }
              customerBusinessName
              customerName
            }
          }
          billPayInvoices {
            cycleDates {
            value
            id
          }
            listOfCycleDates
            statementCode
            stmtIdentifier
          }
        }
      }
    `;

    return this.apollo.query({
      query: statetementDetails,
      variables: {
        id
      },
    });
  }
}
