export class PaginationOptions {
    /**
     * An optional ID for the pagination instance, useful if you wish to
     * have more than once instance at a time in a given component.
     */
    id?: string;
    /**
     * The number of items per paginated page.
     */
    itemsPerPage: number;
    /**
     * The current (active) page.
     */
    currentPage: number;
    /**
     * The total number of items in the collection. Only useful when
     * doing server-side paging.
     */
    totalItems?: number;
    /**
     * boolean value to display goto section
     */
    showGoto?: boolean;
    /**
     * boolean value to display items per page dropdown(10,15,20..)
     */
    showItemsPerpage?: boolean;
    /**
     *  The label displayed on the "previous" link.
     */
    previousLabel?: string;
    /**
     * The label displayed on the "next" link.
     */
    nextLabel?: string;
    /**
     * below three are for screen readers, pass meaning full text
     */
    screenReaderPaginationLabel?: string; //eg.pagination
    screenReaderPageLabel?: string; //e.g: next page
    screenReaderCurrentLabel?: string; //e.g: your're on page
    /**
     * Defines the maximum number of page links to display in pagination
     */
    maxSize?: number;
    /**
     *  If set to false, the "previous" and "next" links will not be displayed.
     */
    directionLinks?: boolean;
    //remove pagination when data fits in a page
    autoHide?: boolean;
    //defaulted to false as we need pagination in small screens also
    responsive?: boolean;
    /**
     *  The total number of items in the collection. Only useful when
     * doing client-side paging, to caluculate pages for goto & items change
     */
    clientSideTotalRecords?: number;
    /**
     * boolean value to indicate once the table is loaded
     */
    datatableLoaded?: boolean;

    constructor() {
        this.currentPage = 1;
        this.itemsPerPage = 10;
        this.previousLabel= '';
        this.nextLabel= '';
        this.screenReaderPaginationLabel =  'Pagination';
        this.screenReaderPageLabel =  'Page';
        this.screenReaderCurrentLabel= `You're on page`;
        this.directionLinks= true;
        this.autoHide= false;
        this.responsive= false;
        this.showItemsPerpage= true;
        this.showGoto= true;
        this.datatableLoaded= false;
    }
}

/**
 * Current Page details
 */
export interface PageDetail {
    startIndex: number;
    endIndex: number;
    currentPage: number;
    itemsPerPage?: number;
}
