import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
   @Input() type: string;
   @Input() message = 'We\'re sorry, we can\'t find this part of the page right now.';
}

export class Message {
  show = false;
  message = 'We\'re sorry, we can\'t find this part of the page right now.';
  type: string;
  level: string; // application or generic
}
