import { Injectable } from '@angular/core';
import { CoxHttpClient } from 'common-ui-lib';
import { TranscriptResponse } from './audio-player.model';


@Injectable()
export class AudioPlayerService {

    constructor(private coxhttp: CoxHttpClient) {}

    public getTranscript(rowid: number, phoneNumber: string): Promise<TranscriptResponse> {
        const getTranscriptUrl =
          `/api/cbma/voicemail/services/voicemail/inbox/transcribeMessage/${phoneNumber}?uid=${rowid}`;
        return this.coxhttp
          .getAsync<TranscriptResponse>(getTranscriptUrl);
    }
}
