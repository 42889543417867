
import { DatePipe, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { MalblockService } from '@data/malblock/malblockservice/malblockservice.service';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Select2Component } from 'angular-select2-component'; // TODO: should get rid of this
import { NgxPaginationModule } from 'ngx-pagination';
import config from 'assets/config/oktaConfig.json';
import { BroadbandFactLabelService } from
'common-ui-lib/lib/shared/redesign/sublanding-page-description/broadbandfactlabel/broadbandfactlabel.service';
import {
  AccountWidgetService,
  AEMClientService,
  AppConfig,
  AppErrorMessageService,
  AuthorizationInterceptor,
  AuthTokenService,
  CacheInterceptor,
  CBHelperService,
  CommonUiLibModule,
  ComponentInstanceService,
  configLoad,
  CoreSharedModule,
  CoxHttpClient,
  CreateUserCommonService,
  CsrLoginUserService,
  EditUserService,
  ErrorHandlerInterceptor,
  ErrorMessageService,
  CommonMessageService,
  HttpCacheService,
  I18nService,
  loadMessages,
  loadMyAccountAppConfig,
  loadMyAccountRolesAppConfig,
  loadPageIdMappings,
  LoginProfileClient,
  MessageService,
  Microservices,
  OutageService,
  PendoService,
  StringUtilService,
  PhonePipe, ProfileMicroservice,
  RefreshTokenClient,
  RouteReusableStrategy,
  SchedulesService,
  SearchPipe,
  SplitIdentifierService,
  SSOService,
  TealiumBuilderService,
  TealiumUtagService,
  TokenInterceptor,
  UnsavedChangeGuard,
  UnsavedPromptService,
  VoiceGlobals,
  VoiceLandingPageService,
  WelcomeWidgetService,
  RedesignGuardService,
  AEMMessagesService,
  HeaderTicketService,
  DownloadstatementService,
  MegaMenuService,
  ExportFileService,
  PrintService,
  NavigationService,
  UnplannedOutageModalService,
  VoiceConversionService,
  MarketRollOutGuard,
  DeviceDetectorService,
  UsersAndAccountsGuard,
  TileMenuViewService,
  ResetPasswordService,
  SelfInstallService,
  DefaultHeaderService,
  AudioPlayerService,
  AwsHelperService,
  CbMapsService,
  CbHeaderService,
  DigitalProfileSharedService,
  CSVExportMultipleTablesService,
  NewRelicService,
  UnAuthGaurd,
  CustomModal,
  MenuOptionsService
} from 'common-ui-lib';
import { PaperlessbillingService } from 'home/billingtools/paperlessbilling/paperlessbilling.service';
import { MyService } from 'home/myservices/myservices.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OrderPipe } from 'ngx-order-pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { GraphQLModule } from './graphql.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RingToNumberValidationService } from 'common-ui-lib/lib/services/ring-to-number-validation.service';
import { PageHeaderService } from 'common-ui-lib/lib/shared/redesign/pageheader/pageheader.service';
import { DateTimePipe } from 'common-ui-lib/lib/shared/directives/dateTime.pipe';
import { AwsModule } from './aws.module';
import OktaAuth from '@okta/okta-auth-js';
import {
  OktaAuthModule
} from 'common-ui-lib';
let myaccountClientID;
let myAdminClientID;
let oktaIssuer;
let redirectUrl;
const hostname = window.location.hostname;

// eslint-disable-next-line max-len
if (hostname.indexOf('localhost') > -1 || hostname.indexOf('qa') > -1 || hostname.indexOf('st1') > -1 || hostname.indexOf('dev') > -1 || hostname.indexOf('uat') > -1 || hostname.indexOf('sit') > -1) {
  myaccountClientID = config.okta['qa'].myAccountClientId;
  myAdminClientID = config.okta['qa'].myAdminClientId;
  oktaIssuer = config.okta['qa'].issuer;
  // eslint-disable-next-line max-len
} else if (hostname.indexOf('localhost') === -1 && hostname.indexOf('qa') === -1 && hostname.indexOf('st1') === -1 && hostname.indexOf('dev') === -1 && hostname.indexOf('uat') === -1 && hostname.indexOf('sit') === -1) {
  myaccountClientID = config.okta['prod'].myAccountClientId;
  myAdminClientID = config.okta['prod'].myAdminClientId;
  oktaIssuer = config.okta['prod'].issuer;
}

const oktaConfig = {
  // localMyadminFlag is used to access Myadmin in LOCAL machine. Always false in all environments.
  clientId: ((window.location.href.indexOf('localhost') === -1 && window.location.href.indexOf('myadmin') > -1)
    || JSON.parse(config.okta.localMyadminFlag)) ? myAdminClientID : myaccountClientID,
  issuer: oktaIssuer,
  redirectUri: window.location.protocol + '//'
  + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + config.okta.redirectUri,
  scopes: (window.location.href.indexOf('localhost') === -1 && window.location.href.indexOf('myadmin') > -1) ?
    config.okta.myAdminScopes : config.okta.myAccountScopes,
  pkce: config.okta.pkce,
  tokenManager: config.okta.tokenManager,
};

const oktaAuth = new OktaAuth(oktaConfig);
@NgModule({
  imports: [
    RouterModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    CommonUiLibModule,
    NgbModule,
    TooltipModule,
    HttpClientModule,
    CoreSharedModule,
    GraphQLModule,
    NgxSkeletonLoaderModule.forRoot(),
    NgxPaginationModule,
    AwsModule,
    OktaAuthModule.forRoot({ oktaAuth })
  ],
  declarations: [
    Select2Component
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {provide: NgbModal, useClass: CustomModal},
  ],
  exports: [
    NgxPermissionsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    Select2Component,
    NgbModule,
    CommonUiLibModule,
    TooltipModule,
    CoreSharedModule,
    NgxSkeletonLoaderModule,
    NgxPaginationModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CreateUserCommonService,
        PaperlessbillingService,
        MyService,
        MalblockService,
        ProfileMicroservice,
        Microservices,
        OrderPipe,
        DatePipe,
        TitleCasePipe,
        PhonePipe,
        DateTimePipe,
        TealiumBuilderService,
        TealiumUtagService,
        AEMClientService,
        DeviceDetectorService,
        AppConfig,
        AppErrorMessageService,
        EditUserService,
        RefreshTokenClient,
        SchedulesService,
        LoginProfileClient,
        VoiceGlobals,
        ErrorMessageService,
        CommonMessageService,
        MessageService,
        { provide: 'UnsavedChangeGuard', useValue: UnsavedChangeGuard },
        AuthorizationInterceptor,
        CacheInterceptor,
        ErrorHandlerInterceptor,
        TokenInterceptor,
        AccountWidgetService,
        WelcomeWidgetService,
        VoiceLandingPageService,
        AuthTokenService,
        CsrLoginUserService,
        ComponentInstanceService,
        SplitIdentifierService,
        CBHelperService,
        PendoService,
        StringUtilService,
        DownloadstatementService,
        RingToNumberValidationService,
        UnsavedPromptService,
        SSOService,
        OutageService,
        SearchPipe,
        ExportFileService,
        PrintService,
        NavigationService,
        CSVExportMultipleTablesService,
        {
          provide: APP_INITIALIZER, useFactory: configLoad,
          deps: [AppConfig], multi: true,
        },
        {
          provide: APP_INITIALIZER, useFactory: loadMyAccountAppConfig,
          deps: [AppConfig], multi: true,
        },
        {
          provide: APP_INITIALIZER, useFactory: loadMyAccountRolesAppConfig,
          deps: [AppConfig], multi: true,
        },
        {
          provide: APP_INITIALIZER, useFactory: loadPageIdMappings,
          deps: [AppConfig], multi: true,
        },
        {
          provide: APP_INITIALIZER, useFactory: loadMessages,
          deps: [AppConfig], multi: true,
        },
        I18nService,
        HttpCacheService,
        {
          provide: RouteReuseStrategy,
          useClass: RouteReusableStrategy,
        },
        CoxHttpClient,
        // RedesignGuardService,
        { provide: 'RedesignGuardService', useValue: RedesignGuardService },
        AEMMessagesService,
        HeaderTicketService,
        PageHeaderService,
        MegaMenuService,
        UnplannedOutageModalService, // MarketRollOutGuard,
        { provide: 'MarketRollOutGuard', useValue: MarketRollOutGuard },
        VoiceConversionService,
        // UsersAndAccountsGuard,
        { provide: 'UsersAndAccountsGuard', useValue: UsersAndAccountsGuard },
        TileMenuViewService,
        BroadbandFactLabelService,
        ResetPasswordService,
        SelfInstallService,
        DefaultHeaderService,
        SelfInstallService,
        NgbActiveModal,
        AudioPlayerService,
        CbMapsService,
        AwsHelperService,
        DigitalProfileSharedService,
        CbHeaderService,
        NewRelicService,
        MenuOptionsService,
        { provide: 'UnAuthGaurd', useValue: UnAuthGaurd }
      ],
    };
  }
}
