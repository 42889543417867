
<div role="dialog" aria-labelledby="modal-heading" aria-modal="true">
    <span class="sr-only" id="modal-heading">{{resourceBundle.modalTitleOrderDelivery}} {{resourceBundle.headingLevel}}</span>
<div class="modal-header modalheader">
    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 mt-2 p-0">
       <a href="javascript:void(0)" aria-label="Back" (click)="backStep()" class="black" [ngClass]="{'defocus' : disableNextBtn}" [attr.tabindex]="disableNextBtn ? '-1' : '0'"><img src="assets/images/blueprint/admin_redesign/arrow-back-black.svg" alt="Chat Icon" class="p-1 margin-top-neg-0-3" />{{resourceBundle.back}}</a>
    </div>
    <div class="col-lg-5 col-md-6 col-sm-6 col-xs-6 p-0">
        <h4 class="modal-title">
        {{resourceBundle.modalTitleOrderDelivery}}</h4>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 text-right margin-left-auto">
        <a href="javascript:void(0)" title="Close" role="button" class="cb-modal-close-btn" id="closeBtn"
            (click)="closeModal(); selfInstallService.triggerTealium('Close')" appModalfocus></a>
    </div>
</div>
<div id="coxGradientLine" class="gradient-rectangle-header mx-auto"></div>
<div #modalTop class="modal-body modal-content pl-0 pr-0 install-steps-modal-scroll">
    <app-commonmessage [type]="messageService.displayMessage.type" class="d-block margin-top-neg-40"
      [rowLevelMessage]="messageService.displayMessage.message" [level]="'datatableRow'"
      *ngIf="messageService.displayMessage.show && messageService.displayMessage.id === 'installResponse'">
    </app-commonmessage>
    
    <div *ngIf="deviceDetails.deviceType === deviceType.INTERNETDEVICE || (isNetAssuranceDevice && !isMyadmin && !activationStarted)" [ngClass]="{'margin-top-neg-25' : (stepNumber > 0 && stepNumber < 4)}">
        <div class="col-12" aria-live="polite" aria-atomic="true" [innerHTML]="stepContent">
        </div>
        <hr class="hr">
    </div>
    <ng-container *ngIf="deviceDetails.deviceType === deviceType.NONINTERNETDEVICE && (isMyadmin || (!isMyadmin && !isNetAssuranceDevice))">
        <div class="col-sm-12 col-xs-12 col-lg-11 col-md-11 pl-4 pr-0 ml-1" [class]="isMobileDevice? 'pr-4': 'pr-0'">
            <h5 class="mb-3 mt-2">{{resourceBundle.orderDeliveredTitle}}</h5>
            <div class="col-12 p-0 mb-4">
                {{resourceBundle.non1pDesc1}}
            </div>
            <span>{{resourceBundle.non1pDesc2}}</span>
            <div class="mt-4">
                <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 bold mt-3 p-0 display-flex mb-3" [class]="{'justify-content-center': isMobileDevice}">{{deviceDetails.name}}</div>
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-4 p-0">
                    <button type="button" id="orderDeliveredTitle" class="button btn-long-text mt-2 col-lg-12 col-md-12 col-sm-11 col-xs-11" (click)="selfInstallClick(deviceDetails?.itemNumber)">
                        {{resourceBundle.orderDeliveredTitle}}</button>
                </div>
            </div>
        </div>
        <hr class="hr">
    </ng-container>
    <ng-container *ngIf="activationStarted">
        <div class="col-sm-12 col-xs-12 col-lg-11 col-md-11 pl-4 pr-0 ml-1" [class]="isMobileDevice? 'pr-4': 'pr-0'">
            <div class="col-12 p-0 mb-4">
                {{resourceBundle.activationText1}}
            </div>
            <span>{{resourceBundle.activationText2}}</span>
            
        </div>  
        <hr class="hr">  
    </ng-container>
    <div class="col-sm-12 col-md-12 col-xs-12 col-sm-12 display-flex pl-4 pr-4">
        <div class="col-lg-10 col-md-10 col-9 p-0">
            <!-- Add  || isSelfInstallClicked to disable for CBMA1-62302, Net Assurance: Next button logic for non-1p self install -->
            <button type="button" class="button btn-long-text col-lg-8 col-md-8 col-12 track-clicks"
                [value]="(stepNumber === installationStepsList.length - 1) ? 'selfinstallfinish':'navigate to next step'"
                (click)="nextStep()" [disabled]="disableNextBtn || isSelfInstallClicked" *ngIf="!activationStarted">
                {{resourceBundle.next}}</button>
            <button type="button" class="button btn-long-text col-lg-8 col-md-8 col-12" (click)="closeModal()"
                *ngIf="activationStarted">
                {{resourceBundle.close}}</button>
        </div>
        <a href="javascript:void(0)" aria-label="Chat" class="display-flex pl-4 chat-trigger"
            [class]="{'pl-3': isMobileDevice}" (click)="triggerTealium(resourceBundle.getSupport)"><img src="assets/images/blueprint/admin_redesign/chat-question-mark.svg"
                alt="Chat Icon" />
        </a>
    </div>
</div>
</div>
