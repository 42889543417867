// TODO This component will be moved to common-ui

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AppConfig, LocalStorageKeys } from 'common-ui-lib';
import { CBHelperService } from '../../services/cbhelper.service';
import { LocalStorageService } from 'ngx-localstorage';



export const MarketRollOutGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean => {
  const router = inject(Router);
  const cbHelper = inject(CBHelperService);
  const _storageService = inject(LocalStorageService);

  const isMarketRollOut = cbHelper.getMarketRollOut;
  const role = cbHelper.getLoggedInUserDetails().profile?.roles;
  const isVoiceSettingsSubMenu = _storageService.get(LocalStorageKeys.VoiceSettingsSubMenu, 'cbma') ?
    _storageService.get(LocalStorageKeys.VoiceSettingsSubMenu, 'cbma') : false;
    let url = state.url.includes('?') ? state.url.split('?')[0] : state.url;
    url = url.includes('/billing/') ? '/billing' : url.includes('#') ? url.split('#')[0] : url;
    url = url.includes('/ticket/')  ? '/ticket' : url;
    url = (url === '/voice/userandsysmanagement/userfeaturesettings/edit')
      && isVoiceSettingsSubMenu ? '/voicesettingssubsmenu' : url;
    const redirectMappings = {
      '/home/landing': isMarketRollOut ? '/portal' : '/dashboard',
      '/billing': '/billinghome',
      '/cbvm/voicemanagertools': '/sublanding/voicetools',
      '/voice/cbvm/home': '/sublanding/voicetools',
      '/voice/cbvm/voicemanagertools': '/sublanding/voicetools',
      '/voice/phoneequipment/phoneequipment': '/sublanding/voicetools',
      '/data/datatool': '/sublanding/datatools',
      '/ticket': state.url.replace('/ticket/', '/onlineticket/').split('?')[0],
      '/voicesettingssubsmenu': '/settings/user/voicesettings/callsettings'
    };

    if ((!role?.includes('phnf') || (role?.includes('phnf') &&
      cbHelper.isMyAdmin())) && redirectMappings[url]) {
      router.navigate([redirectMappings[url]], { queryParams: next.queryParams, fragment: next.fragment });
    }

    return true;
}



