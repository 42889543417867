import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EquipmentListObj, EquipmentObj, EquipmentObjList } from 'home/myequipment/myequipment.model';
import { CoxHttpClient } from 'common-ui-lib';
import { OtherEquipmentsEncryptionReq } from './business-voice.model';

@Injectable()
export class BusinessVoiceService {

  public selectedAccountGuid: string;
  public backLocation: string;
  public otherEquipmentData: EquipmentListObj[];
  public emtaDeviceTypes = ['emta', 'emtapc20'];
  public vocieDeviceTypes = ['ata','emta', 'emtapc20'];

  constructor(
    private apollo: Apollo,
    private coxhttp: CoxHttpClient
  ) {

  }

  public getPhoneEquipmentDetails(): Observable<any> {
    const phoneEquipmentDetails = gql`
        query listPhoneEquipments {
            listPhoneEquipments {
              equipments {
                deviceDetails {
                  deviceName
                  deviceType
                  itemNumber
                  macAddress
                  deviceModel
                  serialNumber
                  personalizedDeviceName
                  purchaseStatus
                  phoneNumber
                  deviceManufacturer
                  description
                  editable
                  encrypted
                  encryptionPermission
                  encryptionValid
                  isMultiPortDevice
                  voiceDeviceType
                  isIPPhoneWithSoftKeys
                  encryptedValue
                }
                kemDetails {
                  kemType
                  kemProvisioned
                  kemCount
                }
                userDetails {
                  firstName
                  lastName
                  userId
                  seatType
                }
                multiPortUserDetails {
                  firstName
                  lastName
                  userId
                  seatType
                }
                phoneNumbers
              }
              accountNumber12
            }
          }
        `;

    return this.apollo.query({
      query: phoneEquipmentDetails
    });
  }

  getOtherEquipmentDetails(selectedAccountGuid: string): Promise<EquipmentObjList> {
    return this.coxhttp.getAsync<EquipmentObjList>(
     // '/api/cbma/accountequipment/services/accountequipment/equipments/' + selectedAccountNumber12 + '?realTime=F');
      '/api/cbma/accountequipment/services/accountequipment/accounts/equipments/'+ selectedAccountGuid + '?type=VOICE&topology=false');
  }

  public getOtherEquipmentEncryptionStatus(accountNumber12: string): Promise<any> {
    return this.coxhttp.getAsync<any>
    ('/api/cbma/voicedevicesettings/services/voicedevicesettings/otherequipment/encryption/status/'+ accountNumber12,
    { disableSpinner: true, customeErrorMessage: '' });
  }

  public getPhoneEquipmentAdditionalDetails(accountNumber12: string, serialNumber: string): Observable<any> {
    const getAdditionalDetailsQuery = gql`
    query getAdditionalDetailsQuery($accountNumber12: String, $serialNumber: String) {
      getAccount(id: "") {
        equipmentViewMore(accountNumber12: $accountNumber12,serialNumber: $serialNumber) {
          installedDt
          purchaseDt
        }
      }
    }`;

    return this.apollo.query({
      query: getAdditionalDetailsQuery,
      variables: {
        accountNumber12,
        serialNumber
      }
    });
  }

  public getVoiceProfileFeatures(){
    const getProfileDetailsQuery = gql`
    query getProfileDetails{
        getProfile{
            serviceFeature(featureCategoryName: VOICE){
                cbServices{
                    subServiceDetails{
                        category
                        subServiceList{
                            subCategory
                            featureName
                            role
                        }
                    }
                }
            }
        }
    }
    `;
    return this.apollo.query({
      query: getProfileDetailsQuery
    });
  }
  public requestCvtCache() {
    return this.coxhttp.postAsync<any>
    ('/api/cbma/voicecallreceive/services/voicecallreceive/getAccountPhoneList', {});
  }

  public isValidVoiceDeviceType(type: string, isEMTADeviceType: boolean) {
    return isEMTADeviceType ? this.emtaDeviceTypes.includes(type) : this.vocieDeviceTypes.includes(type);
  }
}
