<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12">
    <div (click)="toggleAccordion()" id="account-detailslist-2to6" class="col-xs-12 col-md-12 accordion-trigger-standalone-MALP
     xs-gray-bg card" [ngClass]="{ 'accordion-trigger-standalone-open-MALP': toggleAccordian }">
      <span id="selectedaccountdivwrapper" *ngIf="accountselected; then selectedaccountdiv"></span>
        <ng-template #selectedaccountdiv>
            <div class="accordion-trigger-standalone-table col-md-12 col-sm-12 col-xs-12 selected landscape" [ngClass]="{ 'hidden': toggleAccordian }"> 
              <table class="accTable  table-layout  visible-sm visible-md visible-lg" tabindex="0" role="text"> 
                <tr aria-hidden="true"> 
                  <th class="pad-bottom-0-5">{{resourceBundle.accountName}}</th>
                  <th class="pad-bottom-0-5">{{resourceBundle.accountAlias}}</th>
                  <th class="pad-bottom-0-5">{{resourceBundle.accountNumber}}</th>
                  <th class="pad-bottom-0-5">{{resourceBundle.locationAddress}}</th> 
                </tr> 
                <tr>
                  <td data-account-name [title]="accountname"><p class="sr-only" aria-label="Account Name" role="text"></p>{{ accountname }}</td> 
                  <td class="truncate" data-account-alias [title]="accountalias"><p class="sr-only" aria-label="Account Alias" role="text"></p>{{ accountalias }}</td> 
                  <td  data-account-number [title]="accountnumber"><p class="sr-only" aria-label="Account Number" role="text"></p>{{ accountnumber }}</td> 
                  <td class="truncate" [title]="accountaddress"><p class="sr-only" aria-label="Location Address" role="text"></p>{{ accountaddress | truncate:['21', '...'] }}</td>
                </tr>
              </table> 
              <div id="xs-selected-account" class="visible-xs col-xs-12">
                <div class="col-xs-12 margin-left-2">
                  <span data-account-name>{{ accountname }}</span>
                </div>
                <div class="col-xs-12 margin-left-2 break-word">
                  <span data-account-alias>{{ accountalias }}</span>
                </div>
                <div class="col-xs-12 margin-left-2">
                  <span data-account-number >{{ accountnumber }}</span>
                </div>
                <div class="col-xs-12 margin-left-2">
                  <span data-account-address1>{{ accountaddress1 }}</span>
                </div>
                <div class="col-xs-12 margin-left-2">
                  <span data-account-address2>{{ accountaddress2 }}</span>
                </div>
                <div class="col-xs-12 margin-left-2">
                  <span  data-account-addresspostal>{{ accountaddresspostal }}</span>
                </div>
              </div>
            </div>
        </ng-template>
        <div [ngClass]="{ 'hidden': showselect }">
        <span class="h2" (click)="expandWidget();" id="account-detailslist-2to6-accordian-title" aria-controls="multiaccount" tabindex="0" aria-expanded="false" aria-labelledby="selectAnAccount numberofaccounts accounts" role="button">{{resourceBundle.selectAnAccount}}
          <span class="sr-only"> with </span> 
          <span class="hidden-md">({{ numberofaccounts }})</span>
          <span class="pull-right black pad-right-1 hidden-xs hidden-xs-landscape-visible">{{ numberofaccounts }} {{resourceBundle.accounts}}</span>
        </span>
        <div id="multiaccount" class="wide100 xs-gray-bg pull-left md-no-bottom-border" [ngClass]="{ 'hidden': !toggleAccordian }" aria-labelledby="account-detailslist-2to6-accordian-title">
          <div *ngIf="oddAccountsFlag; then oddAccountsTemp" ></div> 
          <div *ngIf="evenAccountsFlag; then evenAccountsTemp"></div>  
          <div *ngIf="allAccountsFlag; then allAccountsTemp"></div>
        </div>
      </div>
      
    </div>
    <ng-template #oddAccountsTemp>
      <div  id="multi-col-2" class="hidden-xs col-sm-6 col-md-6">
        <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" 
        *ngFor="let baseAccount of oddAccounts;" (click)="accountSelected(baseAccount)"
        id="oddAccountsTemp_{{baseAccount.accountId}}" aria-label="Account Details" tabindex="0" [ngClass]="{'selected': baseAccount.accountId === accountid}"> 
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0"> 
            <div class="col-xs-12"> 
              <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span> 
            </div> 
            <div class="col-xs-12"> 
              <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span> 
            </div> 
            <div class="col-xs-12"> 
              <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span> 
            </div> 
          </div> 
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0"> 
              <div class="col-xs-12"> 
                <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span> 
              </div> 
              <div class="col-xs-12"> 
                <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span> 
              </div> 
              <div class="col-xs-12"> 
                <span data-account-address-postal title="{{baseAccount.address.city}},  
                {{baseAccount.address.state}} {{baseAccount.address.zip}}">
                {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span> 
              </div> 
          </div>
              <div id="select_oddAccountsTemp_{{baseAccount.accountId}}" class="col-xs-12 col-md-12 col-sm-12 pad-0">
                <a class="pull-right pad-right-1" name="selectName_oddAccountsTemp_{{baseAccount.accountId}}"><b class="selected">{{resourceBundle.select}}</b></a> 
              </div>         
        </div>
      </div>
    </ng-template>
    <ng-template #evenAccountsTemp>
      <div id="multi-col-1"  class="hidden-xs col-sm-6 col-md-6" >
        <div class="account col-xs-12 col-md-12 col-sm-12 pad-0"
        *ngFor="let baseAccount of evenAccounts;" (click)="accountSelected(baseAccount)"
        id="evenAccountsTemp_{{baseAccount.accountId}}" aria-label="Account Details" tabindex="0" [ngClass]="{'selected': baseAccount.accountId === accountid}"> 
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0"> 
            <div class="col-xs-12"> 
              <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span> 
            </div> 
            <div class="col-xs-12"> 
              <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span> 
            </div> 
            <div class="col-xs-12"> 
              <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span> 
            </div> 
          </div> 
          <div class="col-xs-12 col-md-6 col-sm-6 pad-0"> 
              <div class="col-xs-12"> 
                <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span> 
              </div> 
              <div class="col-xs-12"> 
                <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span> 
              </div> 
              <div class="col-xs-12"> 
                <span data-account-address-postal title="{{baseAccount.address.city}},  
                {{baseAccount.address.state}} {{baseAccount.address.zip}}">
                {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span> 
              </div> 
            </div> 
              <div id="select_evenAccountsTemp_{{baseAccount.accountId}}" class="col-xs-12 col-md-12 col-sm-12 pad-0">
                <a class="pull-right pad-right-1" name="selectName_evenAccountsTemp_{{baseAccount.accountId}}" ><b class="selected">{{resourceBundle.select}}</b></a> 
              </div> 
           
          </div>
        </div>
    </ng-template>
    <ng-template #allAccountsTemp>
      <div id="multi-col"  class="visible-xs-block col-xs-12" >
        <div class="account col-xs-12 col-md-12 col-sm-12 pad-0" 
        *ngFor="let baseAccount of allAccounts;" (click)="accountSelected(baseAccount)"
        id="allAccountsTemp_{{baseAccount.accountId}}" aria-label="Account Details" tabindex="0" [ngClass]="{'selected': baseAccount.accountId === accountid}"> 
            <div class="col-xs-12 col-md-6 col-sm-6 pad-0"> 
                <div class="col-xs-12"> 
                  <span data-account-name title="{{baseAccount.name}}">{{baseAccount.name}}</span> 
                </div> 
                <div class="col-xs-12"> 
                  <span data-account-alias title="{{baseAccount.alias}}">{{baseAccount.alias}}</span> 
                </div> 
                <div class="col-xs-12"> 
                  <span data-account-number title="{{baseAccount.siteId}}-{{baseAccount.accountNumber}}">{{baseAccount.siteId}}-{{baseAccount.accountNumber}}</span> 
                </div> 
              </div> 
              <div class="col-xs-12 col-md-6 col-sm-6 pad-0"> 
                  <div class="col-xs-12"> 
                    <span data-account-address1 title="{{baseAccount.address.address1}}">{{baseAccount.address.address1}}</span> 
                  </div> 
                  <div class="col-xs-12"> 
                    <span data-account-address2 title="{{baseAccount.address.address2}}">{{baseAccount.address.address2}}</span> 
                  </div> 
                  <div class="col-xs-12"> 
                    <span data-account-address-postal title="{{baseAccount.address.city}},  
                    {{baseAccount.address.state}} {{baseAccount.address.zip}}">
                    {{baseAccount.address.city}}, {{baseAccount.address.state}} {{baseAccount.address.zip}}</span> 
                  </div> 
                </div> 
                  <div id="select_allAccountsTemp_{{baseAccount.accountId}}" class="col-xs-12 col-md-12 col-sm-12 pad-0">
                    <a class="pull-right pad-right-1" name="selectName_allAccountsTemp_{{baseAccount.accountId}}"><b class="selected">{{resourceBundle.select}}</b></a> 
                  </div> 
                
          </div>
        </div>
    </ng-template>
  </div>
</div>
