import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, Location } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { AccordionModule } from 'ngx-bootstrap/accordion';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { CalendarModule } from 'primeng/calendar';
import { CommonUiLibComponent } from './common-ui-lib.component';
import { AppConfig } from './core/app.config';
import { AuthService } from './core/http/oauth.service';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { Microservices } from './microservices/index';
import { ProfileMicroservice } from './microservices/profile';
import { AEMClientService } from './services/aemclient.service';
import { DeviceDetectorService } from './services/device-detector.service';
import { AppErrorMessageService } from './services/app-error-message.service';
import { AuthTokenService } from './services/auth-token.service';
import { CBHelperService } from './services/cbhelper.service';
import { ComponentInstanceService } from './services/component-instance.service';
import { CoxHttpClient } from './services/coxhttpclient.service';
import { CreateUserCommonService } from './services/createUserCommon.service';
import { CsrLoginUserService } from './services/csrloginuser.service';
import { EditUserService } from './services/edituser.service';
import { ExportFileService } from './services/exportfile.service';
import { RefreshTokenClient } from './services/keepalive.service';
import { PendoService } from './services/pendo.service';
import { PrintService } from './services/print.service';
import { SplitIdentifierService } from './services/split-idententifier.service';
import { WebexactivationService } from './services/webexactivation.service';
import { AccountwidgetBasicComponent } from './shared/accountwidget-basic/accountwidget-basic.component';
import { AddupgradeserviceComponent } from 'redesign/addupgradeservice/addupgradeservice.component';
import { BroadbandFactLabelComponent } from
'common-ui-lib/lib/shared/redesign/sublanding-page-description/broadbandfactlabel/broadbandfactlabel.component';
import { GetsupportserviceComponent } from 'redesign/getsupportservice/getsupportservice.component';
import { GetSupportService } from 'redesign/getsupportservice/getsupportservice.service';
// tslint:disable-next-line: max-line-length
import { Accountwidget2to6accountsBasicComponent } from
'./shared/accountwidget-basic/accountwidget2to6accounts-basic/accountwidget2to6accounts-basic.component';
// tslint:disable-next-line: max-line-length
import { Accountwidget7plusaccountsBasicComponent } from
'./shared/accountwidget-basic/accountwidget7plusaccounts-basic/accountwidget7plusaccounts-basic.component';
// tslint:disable-next-line: max-line-length
import { AccountwidgetsingleaccountBasicComponent } from
'./shared/accountwidget-basic/accountwidgetsingleaccount-basic/accountwidgetsingleaccount-basic.component';
import { AccountwidgetComponent } from './shared/accountwidget/accountwidget.component';
import { AccountWidgetService } from './shared/accountwidget/accountwidget.service';
// tslint:disable-next-line: max-line-length
import { Accountwidget2to6accountsComponent } from
'./shared/accountwidget/accountwidget2to6accounts/accountwidget2to6accounts.component';
// tslint:disable-next-line: max-line-length
import { Accountwidget7plusaccountsComponent } from
'./shared/accountwidget/accountwidget7plusaccounts/accountwidget7plusaccounts.component';
// tslint:disable-next-line: max-line-length
import { AccountwidgetsingleaccountComponent } from
'./shared/accountwidget/accountwidgetsingleaccount/accountwidgetsingleaccount.component';
// tslint:disable-next-line: max-line-length
import { Accountwidget2to6accountsEquipmentComponent } from
'./shared/accountwidgetequipment/accountwidget2to6accountsequipment/accountwidget2to6accountsequipment.component';
// tslint:disable-next-line: max-line-length
import { Accountwidget7plusaccountsEquipmentComponent } from
'./shared/accountwidgetequipment/accountwidget7plusaccountsequipment/accountwidget7plusaccountsequipment.component';
import { AccountwidgetEquipmentComponent } from './shared/accountwidgetequipment/accountwidgetequipment.component';
// tslint:disable-next-line: max-line-length
import { AccountwidgetsingleaccountEquipmentComponent } from
'./shared/accountwidgetequipment/accountwidgetsingleaccountequipment/accountwidgetsingleaccountequipment.component';
import { AomswelcomewidgetComponent } from './shared/aomswelcomewidget/aomswelcomewidget.component';
import { BannercarouselComponent } from './shared/bannercarousel/bannercarousel.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { ChatdivComponent } from './shared/chatdiv/chatdiv.component';
import { CustomcarouselComponent } from './shared/customcarousel/customcarousel.component';
import { AutotabDirective } from './shared/directives/autotab.directive';
import { DatatableDirective } from './shared/directives/datatable.directive';
import { GotoComponent } from './shared/directives/goto.component';
import { GotoPageComponent } from './shared/directives/gotopage.component';
import { IgnoreDirtyDirective } from './shared/directives/ignoredirty.component';
import { PcalendarDirective } from './shared/directives/pcalendar.directive';
import { PcalendarinputDirective } from './shared/directives/pcalendarinput.directive';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { PicklistDirective } from './shared/directives/picklist.directive';
import { TabDirective } from './shared/directives/tab.directive';
import { TimeMaskDirective } from './shared/directives/timemask.directive';
import { UnsavedChangeGuard } from './shared/guards/unsavedchanges.guard';
import { UnsavedChangesPromptComponent } from './shared/guards/unsavedPrompt.component';
import { UnsavedPromptService } from './shared/guards/unsavedPrompt.service';
import { InfomessageComponent } from './shared/infomessage/infomessage.component';
import { InfomessageService } from './shared/infomessage/infomessage.service';
import { LeavingcoxsitemodalComponent } from './shared/leavingcoxsitemodal/leavingcoxsitemodal.component';
import { CBLoaderComponent } from './shared/cbloader/cbloader.component';
import { MaintenanceComponent } from './shared/maintenance/maintenancepage.component';
import { ErrorMessageService } from './shared/messages/error-message.service';
import { MessageComponent } from './shared/messages/message.component';
import { MessageService } from './shared/messages/message.service';
import { VoiceGlobals } from './shared/models/globals/voice.globals';
import { Select2Module } from './shared/ng2-select2/lib/ng2-select2';
import { OktamaintenanceComponent } from './shared/oktamaintenance/oktamaintenance.component';
import { OutageComponent } from './shared/outage/outage.component';
import { OutageService } from './shared/outage/outage.service';
import { PageHeroComponent } from './shared/pagehero/pagehero.component';
import { PhonePipe } from './shared/pipes/phone.pipe';
import { SearchPipe } from './shared/pipes/search.pipe';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { ScheduleModalComponent } from './shared/scheduleforms/schedule.modal.component';
import { ScheduleFormComponent } from './shared/scheduleforms/scheduleform.component';
import { SchedulesService } from './shared/scheduleforms/scheduleform.service';
import { StepComponent } from './shared/stepperwidget/step.component';
import { StepperComponent } from './shared/stepperwidget/stepper.component';
import { DataTableCounterComponent } from './shared/tableItemCounter/itemCounter.component';
import { MatTab } from './shared/tabmobileview/mat-tab/mattab.component';
import { MatTabBody, MatTabBodyPortal } from './shared/tabmobileview/tab-body/tab-body.component';
import { MatTabContent } from './shared/tabmobileview/tab-content/tab-content.component';
import { MatTabHeader } from './shared/tabmobileview/tab-header/tab-header.component';
import { MatTabLabelWrapper } from './shared/tabmobileview/tab-label-wrapper/tab-label-wrapper.component';
import { MatTabLabel } from './shared/tabmobileview/tab-label/tab-label.component';
import { MatTabGroup } from './shared/tabmobileview/tab-mobile.component';
import { TimeOutComponent } from './shared/timeout/timeoutmodal.component';
import { UsernametooltipComponent } from './shared/usernametooltip/usernametooltip.component';
import { VerticalMenuComponent } from './shared/verticalmenu/verticalmenu.component';
import { VoiceLandingPageService } from './shared/voice-landing-page/voice-landing-page.service';
import { UserInfoService } from './shared/welcomewidget/userinfo.service';
import { WelcomeWidgetComponent } from './shared/welcomewidget/welcomewidget.component';
import { WelcomeWidgetService } from './shared/welcomewidget/welcomewidget.service';
import { TealiumBuilderService } from './tealium/tealiumbuilder';
import { TealiumUtagService } from './tealium/utag.service';
import { RedesignSearchComponent } from './shared/redesign/redesign-search/redesignSearch.component';
import { TypeaheadSearchComponent } from './shared/redesign/redesign-search/typeaheadSearch/typeahead.component';
import { SkeletonLoaderComponent } from './shared/redesign/skeletonloader/skeletonloader.component';
import { CommonSearchComponent } from './shared/redesign/commonsearch/commonsearch.component';
import { CommonMessageComponent } from './shared/redesign/commonmessage/commonmessage.component';
import { CommonMessageService } from './shared/redesign/commonmessage/commonmessage.service';
import { NavigationComponent } from './shared/redesign/navigation/navigation.component';
import { NavigationService } from './shared/redesign/navigation/navigation.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AccountNumberMaskDirective } from './shared/directives/accountNumberMask.directive';
import { CarouselModule } from './shared/redesign/redesignCarousel/redesignCarousel';
import { RedesignGuardService } from './shared/guards/redesign-guard.service';
import { DefaultHeaderComponent } from './shared/redesign/defaultHeader/defaultHeader.component';
import { AEMMessagesComponent } from './shared/redesign/defaultHeader/messages/aemMessages.component';
import { AEMMessagesService } from './shared/redesign/defaultHeader/messages/aemMessages.service';
import { HeaderTicketsComponent } from
    './shared/cb-header/support-menu/header-tickets/header-tickets.component';
import { HeaderTicketService } from './shared/redesign/defaultHeader/cbot/headerTickets.service';
import { PageheaderComponent } from './shared/redesign/pageheader/pageheader.component';
import { MoreLinkComponent } from './shared/redesign/pageheader/more-link/more-link.component';
import { RedesignAccountwidgetComponent } from
'./shared/redesign/pageheader/redesign-accountwidget/redesign-accountwidget.component';
import { RingToNumberValidationService } from './services/ring-to-number-validation.service';
import { PageHeaderService } from './shared/redesign/pageheader/pageheader.service';
import { MegaMenuComponent } from './shared/redesign/defaultHeader/mega-menu/mega-menu.component';
import { MegaMenuService } from './shared/redesign/defaultHeader/mega-menu/mega-menu.service';
import { PlannedoutageComponent } from './shared/redesign/defaultHeader/messages/plannedoutage/plannedoutage.component';
import { DateTimePipe } from './shared/directives/dateTime.pipe';
import { UnplannedOutageModalComponent } from './shared/redesign/unplannedOutageModal/unplanned-outage-modal.component';
import { UnplannedOutageModalService } from './shared/redesign/unplannedOutageModal/unplanned-outage-modal.service';
import { VoiceConversionComponent } from './shared/redesign/voice-conversion/voice-conversion.component';
import { VoiceConversionService } from './shared/redesign/voice-conversion/voice-conversion.service';
import { SubLandingPageDescriptionComponent } from
'./shared/redesign/sublanding-page-description/sublanding-page-description.component';
import { FeaturecardsComponent } from './shared/redesign/featurecards/featurecards.component';
import { ServiceDetailsComponent } from
'./shared/redesign/sublanding-page-description/service-details/service-details.component';
import { SearchAndFilter } from './shared/redesign-search-filter/redesign-search-filter.component';
import { DefaultFooterComponent } from './shared/defaultFooter/defaultFooter.component';
import { FiltertagsComponent } from './shared/redesign-search-filter/filtertags/filtertags.component';
import { EquipmentCardDetailComponent } from './shared/redesign/equipment-card-detail/equipment-card-detail.component';
import { RedesignRebootWidgetComponent } from
'./shared/redesign/redesign-reboot-widget/redesign-reboot-widget.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DefaultHeaderService } from './shared/redesign/defaultHeader/defaultHeader.service';
import { ModalfocusDirective } from './shared/directives/modalfocus.directive';
import { TextTransformPipe } from './shared/pipes/text-transform.pipe';
import { MenuOptionsComponent } from './shared/redesign/menu-options/menu-options.component';
import { AudioPlayerComponent } from './shared/redesign/audio-player/audio-player.component';
import { CBLoaderService } from './shared/cbloader/cbloader.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { ValidateUseridComponent } from './reset-password/step-1/validate-userid.component';
import { ChooseVerifyMethodComponent } from './reset-password/step-2/choose-verify-method.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyOtpComponent } from './reset-password/step-3/verify-otp.component';
import { ResetPasswordService } from './reset-password/reset-password.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { ChangePasswordComponent } from './reset-password/step-4/change-password/change-password.component';
import { ScanidContactMethodComponent } from
'./reset-password/step-2a/scanid-contact-method/scanid-contact-method.component';
import { ScanidContactMethodConfirmationComponent } from
'./reset-password/step-2b/scanid-contact-method-confirmation/scanid-contact-method-confirmation.component';
import { UiSwitchDirective } from './shared/directives/uiSwitch.directive';
import { PcalendarinlineDirective } from './shared/directives/pcalendarinline.directive';
import { SelfInstallComponent } from './self-install/self-install.component';
import { OrderTrackingComponent } from './self-install/case-2/order-tracking.component';
import { OrderConfirmationComponent } from './self-install/case-1/order-confirmation.component';
import { SelfInstallService } from './self-install/self-install.service';
import { OrderDeliveredComponent } from './self-install/case-3/mvp/order-delivered.component';
import { OrderDeliveredPostmvpComponent } from
'./self-install/case-3/post-mvp/order-delivered-postmvp/order-delivered-postmvp.component';
import { DeviceInstallationStepsComponent } from
'./self-install/case-3/post-mvp/device-installation-steps/device-installation-steps.component';
import { KeepSafeHtml } from './shared/directives/keepSafeHtml.pipe';
import { FttxAlertComponent } from './shared/cb-header/alert-menu/fttx-alert/fttx-alert.component';
import { ValidateContactService } from './shared/redesign/validate-contact/validate-contact.service';
import { ValidationMethodComponent } from
'./shared/redesign/validate-contact/validation-method/validation-method.component';
import { ValidateContactHeaderComponent } from
'./shared/redesign/validate-contact/validate-contact-header/validate-contact-header.component';
import { CodeValidationComponent } from './shared/redesign/validate-contact/code-validation/code-validation.component';
import { AwsHelperService } from './services/awshelper.service';
import { DigitalProfileSharedService } from './services/digitalProfile.service';
import { CbHeaderService } from './shared/cb-header/cb-header.service';
import { CbHeaderComponent } from './shared/cb-header/cb-header.component';
import { CbMapsComponent } from './shared/cb-maps/cb-maps.component';
import { CbMapsService } from './shared/cb-maps/cb-maps.service';
import { AlertMenuComponent } from './shared/cb-header/alert-menu/alert-menu.component';
import { MainMenuComponent } from './shared/cb-header/main-menu/main-menu.component';
import { NpdAlertComponent } from './shared/cb-header/alert-menu/npd-alert/npd-alert.component';
import { SupportMenuComponent } from './shared/cb-header/support-menu/support-menu.component';
import { CarouselWithScrollbarComponent } from
'./shared/redesign/carousel-with-scrollbar/carousel-with-scrollbar.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CBCCAlertComponent } from './shared/cb-header/alert-menu/cbcc-alert/cbcc-alert.component';
import { TileMenuViewComponent } from './shared/redesign/tile-menu-view/tile-menu-view.component';
import { TileMenuViewService } from './shared/redesign/tile-menu-view/tile-menu-view.service';
import { NotificationTemplateService } from './shared/redesign/notification-template/notification-template.service';
import { NotificationTemplateComponent } from './shared/redesign/notification-template/notification-template.component';
import { AudioPlayerService } from './shared/redesign/audio-player/audio-player.service';
import { CSVExportMultipleTablesService } from './services/csv-export-multiple-tables.service';
import { NewRelicService } from './services/newrelic.service';
import { KariLawAlertComponent } from './shared/cb-header/alert-menu/kari-law-alert/kari-law-alert.component';
import { MultiSelectDirective } from './shared/directives/multiselect.directive';
import { UnAuthGaurd } from './shared/guards/unauth.gaurd';
import { ContactDetailsModalComponent } from './self-install/case-3/post-mvp/contact-details-modal/contact-details-modal.component';
import { BroadbandFactLabelService } from './shared/redesign/sublanding-page-description/broadbandfactlabel/broadbandfactlabel.service';
import { NgbAccordionDirective } from './shared/directives/ngbAccordion.directive';
import { MenuOptionsService } from 'common-ui-lib';
import { OktaAuthGuard } from './okta/okta.guard';

@NgModule({
    imports: [
        CommonModule,
        // DeviceDetectorModule,
        FormsModule,
        // Ng4LoadingSpinnerModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        Select2Module,
        CalendarModule,
        NgbModule,
        AccordionModule.forRoot(),
        RouterModule,
        PortalModule,
        CarouselModule,
        UiSwitchModule,
        NgxSkeletonLoaderModule.forRoot(),
        NgxPaginationModule,
        RecaptchaModule,
        AngularMultiSelectModule
    ],
    declarations: [
        ValidationMethodComponent,
        ValidateContactHeaderComponent,
        CodeValidationComponent,
        AccountNumberMaskDirective,
        ChatdivComponent,
        CommonUiLibComponent,
        PageHeroComponent,
        MessageComponent,
        PhonePipe,
        TruncatePipe,
        DateTimePipe,
        BannercarouselComponent,
        OktamaintenanceComponent,
        WelcomeWidgetComponent,
        MaintenanceComponent,
        TimeOutComponent,
        GotoComponent,
        GotoPageComponent,
        IgnoreDirtyDirective,
        TimeMaskDirective,
        CBLoaderComponent,
        Accountwidget2to6accountsComponent,
        Accountwidget7plusaccountsComponent,
        AccountwidgetsingleaccountComponent,
        AccountwidgetComponent,
        AccountwidgetEquipmentComponent,
        Accountwidget7plusaccountsEquipmentComponent,
        AccountwidgetsingleaccountEquipmentComponent,
        Accountwidget2to6accountsEquipmentComponent,
        Accountwidget2to6accountsBasicComponent,
        Accountwidget7plusaccountsBasicComponent,
        AccountwidgetBasicComponent,
        AccountwidgetsingleaccountBasicComponent,
        BreadcrumbComponent,
        NavigationComponent,
        UsernametooltipComponent,
        UnsavedChangesPromptComponent,
        AutotabDirective,
        PhoneMaskDirective,
        StepperComponent,
        StepComponent,
        DataTableCounterComponent,
        AomswelcomewidgetComponent,
        InfomessageComponent,
        PcalendarDirective,
        NgbAccordionDirective,
        PcalendarinputDirective,
        DatatableDirective,
        PicklistDirective,
        CustomcarouselComponent,
        TabDirective,
        ScheduleModalComponent,
        ScheduleFormComponent,
        LeavingcoxsitemodalComponent,
        OutageComponent,
        VerticalMenuComponent,
        SearchPipe,
        MatTabGroup,
        MatTab,
        MatTabBody,
        MatTabBodyPortal,
        MatTabContent,
        MatTabHeader,
        MatTabLabel,
        MatTabLabelWrapper,
        RedesignSearchComponent,
        TypeaheadSearchComponent,
        SkeletonLoaderComponent,
        CommonSearchComponent,
        CommonMessageComponent,
        DefaultHeaderComponent,
        AEMMessagesComponent,
        PlannedoutageComponent,
        HeaderTicketsComponent,
        PageheaderComponent,
        MoreLinkComponent,
        DefaultHeaderComponent,
        RedesignAccountwidgetComponent,
        MegaMenuComponent,
        UnplannedOutageModalComponent,
        VoiceConversionComponent,
        NpdAlertComponent,
        SubLandingPageDescriptionComponent,
        FeaturecardsComponent,
        ServiceDetailsComponent,
        SearchAndFilter,
        DefaultFooterComponent,
        FiltertagsComponent,
        AddupgradeserviceComponent,
        BroadbandFactLabelComponent,
        GetsupportserviceComponent,
        EquipmentCardDetailComponent,
        RedesignRebootWidgetComponent,
        ModalfocusDirective,
        TextTransformPipe,
        MenuOptionsComponent,
        AudioPlayerComponent,
        PaginationComponent,
        VerifyOtpComponent,
        ResetPasswordComponent,
        ChooseVerifyMethodComponent,
        ValidateUseridComponent,
        ChangePasswordComponent,
        ScanidContactMethodComponent,
        ScanidContactMethodConfirmationComponent,
        UiSwitchDirective,
        PcalendarinlineDirective,
        SelfInstallComponent,
        OrderConfirmationComponent,
        OrderTrackingComponent,
        OrderDeliveredComponent,
        OrderDeliveredPostmvpComponent,
        DeviceInstallationStepsComponent,
        ContactDetailsModalComponent,
        KeepSafeHtml,
        FttxAlertComponent,
        CbMapsComponent,
        CbHeaderComponent,
        CarouselWithScrollbarComponent,
        MainMenuComponent,
        AlertMenuComponent,
        SupportMenuComponent,
        CBCCAlertComponent,
        TileMenuViewComponent,
        NotificationTemplateComponent,
        KariLawAlertComponent,
        MultiSelectDirective
    ],
    exports: [
        ValidationMethodComponent,
        ValidateContactHeaderComponent,
        CodeValidationComponent,
        AccountNumberMaskDirective,
        ChatdivComponent,
        CommonUiLibComponent,
        PageHeroComponent,
        KeepSafeHtml,
        LeavingcoxsitemodalComponent,
        MessageComponent,
        PhonePipe,
        TruncatePipe,
        DateTimePipe,
        BannercarouselComponent,
        OktamaintenanceComponent,
        WelcomeWidgetComponent,
        AomswelcomewidgetComponent,
        MaintenanceComponent,
        TimeOutComponent,
        GotoComponent,
        GotoPageComponent,
        IgnoreDirtyDirective,
        TimeMaskDirective,
        CBLoaderComponent,
        Accountwidget2to6accountsComponent,
        Accountwidget7plusaccountsComponent,
        AccountwidgetsingleaccountComponent,
        AccountwidgetComponent,
        AccountwidgetEquipmentComponent,
        Accountwidget7plusaccountsEquipmentComponent,
        AccountwidgetsingleaccountEquipmentComponent,
        Accountwidget2to6accountsEquipmentComponent,
        Accountwidget2to6accountsBasicComponent,
        Accountwidget7plusaccountsBasicComponent,
        AccountwidgetBasicComponent,
        AccountwidgetsingleaccountBasicComponent,
        BreadcrumbComponent,
        NavigationComponent,
        FeaturecardsComponent,
        UsernametooltipComponent,
        NgbModule,
        Select2Module,
        UnsavedChangesPromptComponent,
        AutotabDirective,
        ScheduleModalComponent,
        ScheduleFormComponent,
        PhoneMaskDirective,
        StepperComponent,
        DataTableCounterComponent,
        InfomessageComponent,
        PcalendarDirective,
        NgbAccordionDirective,
        DatatableDirective,
        PcalendarinputDirective,
        PicklistDirective,
        TabDirective,
        OutageComponent,
        VerticalMenuComponent,
        SearchPipe,
        MatTabGroup,
        MatTab,
        MatTabBody,
        MatTabBodyPortal,
        MatTabContent,
        MatTabHeader,
        MatTabLabel,
        MatTabLabelWrapper,
        RedesignSearchComponent,
        TypeaheadSearchComponent,
        SkeletonLoaderComponent,
        CommonSearchComponent,
        CommonMessageComponent,
        CarouselModule,
        DefaultHeaderComponent,
        AEMMessagesComponent,
        PlannedoutageComponent,
        HeaderTicketsComponent,
        MoreLinkComponent,
        PageheaderComponent,
        RedesignAccountwidgetComponent,
        SubLandingPageDescriptionComponent,
        SearchAndFilter,
        DefaultFooterComponent,
        FiltertagsComponent,
        AddupgradeserviceComponent,
        BroadbandFactLabelComponent,
        GetsupportserviceComponent,
        EquipmentCardDetailComponent,
        RedesignRebootWidgetComponent,
        ModalfocusDirective,
        VoiceConversionComponent,
        MenuOptionsComponent,
        AudioPlayerComponent,
        PaginationComponent,
        VerifyOtpComponent,
        ResetPasswordComponent,
        ChooseVerifyMethodComponent,
        ValidateUseridComponent,
        ScanidContactMethodComponent,
        UiSwitchDirective,
        PcalendarinlineDirective,
        CbMapsComponent,
        CbHeaderComponent,
        CarouselWithScrollbarComponent,
        MainMenuComponent,
        AlertMenuComponent,
        SupportMenuComponent,
        TileMenuViewComponent,
        NotificationTemplateComponent,
        MultiSelectDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonUiLibModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: CommonUiLibModule,
            providers: [
                TealiumBuilderService,
                TealiumUtagService,
                AEMClientService,
                DeviceDetectorService,
                AppConfig,
                AppErrorMessageService,
                EditUserService,
                ExportFileService,
                RefreshTokenClient,
                VoiceGlobals,
                ErrorMessageService,
                MessageService,
                PhonePipe,
                TruncatePipe,
                DateTimePipe,
                UserInfoService,
                SchedulesService,
                // UnsavedChangeGuard,
                { provide: 'UnsavedChangeGuard', useValue: UnsavedChangeGuard },
                AuthService,
                AuthorizationInterceptor,
                CacheInterceptor,
                ErrorHandlerInterceptor,
                AccountWidgetService,
                VoiceLandingPageService,
                AuthTokenService,
                CsrLoginUserService,
                CreateUserCommonService,
                ComponentInstanceService,
                SplitIdentifierService,
                CBHelperService,
                CBLoaderService,
                NavigationService,
                PendoService,
                RingToNumberValidationService,
                PrintService,
                WelcomeWidgetService,
                UnsavedPromptService,
                CoxHttpClient,
                Microservices,
                ProfileMicroservice,
                Location,
                InfomessageService,
                WebexactivationService,
                NewRelicService,
                OutageService,
                CommonMessageService,
                // RedesignGuardService,
                { provide: 'RedesignGuardService', useValue: RedesignGuardService },
                AEMMessagesService,
                DefaultHeaderService,
                HeaderTicketService,
                PageHeaderService,
                MegaMenuService,
                UnplannedOutageModalService,
                VoiceConversionService,
                GetSupportService,
                ResetPasswordService,
                SelfInstallService,
                NgbActiveModal,
                ValidateContactService,
                AwsHelperService,
                DigitalProfileSharedService,
                CbHeaderService,
                CbMapsService,
                TileMenuViewService,
                NotificationTemplateService,
                AudioPlayerService,
                CSVExportMultipleTablesService,
                NewRelicService,
                OktaAuthGuard,
                BroadbandFactLabelService,
                MenuOptionsService,
                { provide: 'UnAuthGaurd', useValue: UnAuthGaurd },
                BroadbandFactLabelService
            ],
        };
    }
}
