<div class="modal-body modal-content c-btn modal-height-max post-mvp-scroll">
    <div *ngIf="startSpinner">
        <img src="assets/images/blueprint/admin_redesign/throbber-blue.svg" alt="spinner" role="presentation" />
    </div>
    <app-commonmessage [type]="messageService.displayMessage.type" class="d-block margin-top-neg-40"
      [rowLevelMessage]="messageService.displayMessage.message" [level]="'datatableRow'"
      *ngIf="messageService.displayMessage.show && messageService.displayMessage.id === 'installationSteps'">
    </app-commonmessage>
    <div *ngIf="!startSpinner">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 margin-bottom-15px" *ngIf="selfInstallService?.onepStatus !== ChannelStatus.Failure && !digitalProfileService.netAssuranceStatus">
            <h5 class="bold mt-1">{{postMvpTitle}}</h5>
            <p class="my-3">{{postMvpDesc}}</p>
        </div>
        <div *ngIf="selfInstallService?.onepStatus === ChannelStatus.Failure">
            <div *ngIf="selfInstallService.netAssuranceUnsuccessful">
                <app-commonmessage [type]="messageService.displayMessage.type" class="d-block margin-top-neg-40"
                [rowLevelMessage]="messageService.displayMessage.message" [level]="'datatableRow'"
                *ngIf="messageService.displayMessage.show && messageService.displayMessage.id === 'netAssuranceError'">
              </app-commonmessage>
            </div>
            <p>{{resourceBundle.failedDesc}}</p>
            <div class="display-flex justify-content-center">
                <button type="button" class="button btn-long-text mt-2 col-6 chat-trigger" (click)="triggerTealium(resourceBundle.getSupport)" [attr.data-chat-text]="resourceBundle.khorosOliverChatText">
                {{resourceBundle.chatAgent}}</button>
            </div>
        </div>
        <div *ngIf="digitalProfileService.netAssuranceStatus && !selfInstallService.netAssuranceUnsuccessful">
            <h5 class="bold mt-1">{{postMvpTitle}}</h5>
            <p class="my-3">
               {{ selfInstallService.netAssuranceSuccessful ? resourceBundle.completedActivation : resourceBundle.orderDeliveredPostMvpDescription }}
            </p>
        </div>
        <div *ngIf="!(selfInstallService?.orderDetailsEquipmentRows.length<2 && this.selfInstallService.onepStatus === ChannelStatus.Failure) || (digitalProfileService.netAssuranceStatus && !selfInstallService?.netAssuranceUnsuccessful)">
            <h5 class="bold-header mediumfont mt-4">{{resourceBundle.equipmentsToInstall}}</h5>
            <ul class="list-unstyled">
                <li *ngFor="let device of selfInstallService?.orderDetailsEquipmentRows" class="mt-2">
                    <div class="col-sm-12 col-md-12 col-xs-12 pl-0 display-flex">
                        <div class="col-sm-8 col-md-8 col-xs-8 pl-0 mt-4">{{device.name}}</div>
                        <div class="col-sm-4 col-md-4 col-xs-4">
                            <button
                                *ngIf="device?.installStatus === 'L' && (device.deviceType=== deviceType.INTERNETDEVICE || device?.itemNumber === 'NCPA300' || device?.itemNumber === 'NCPA100')"
                                class="button btn-long-text pull-right wide100" attr.aria-label="{{resourceBundle.install}}"
                                (click)="selfInstallClick(device)">{{resourceBundle.install}}</button>
                                <button
                                *ngIf="device?.installStatus === selfInstallStatus.SUCCESS && device.deviceType=== deviceType.NONINTERNETDEVICE && (device?.itemNumber === 'NCPA300' || device?.itemNumber === 'NCPA100') && !isMyadmin"
                                class="button btn-long-text pull-right wide100 pl-0 pr-0" attr.aria-label="{{resourceBundle.continueInstall}}"
                                (click)="openInstallationStepsModal(device)" 
                                >{{resourceBundle.continueInstall}}</button>
                            <button
                                *ngIf="device?.installStatus === 'N' && device.deviceType=== deviceType.INTERNETDEVICE"
                                class="button btn-long-text pull-right wide100" [disabled]="selfInstallService?.netAssuranceUnsuccessful"  attr.aria-label="{{resourceBundle.install}}"
                                (click)="openInstallationStepsModal(device)">{{resourceBundle.install}}</button>
                            <button
                                *ngIf="device?.installStatus === 'N' && device.deviceType=== deviceType.NONINTERNETDEVICE || ((device?.itemNumber === 'NCPA300' || device?.itemNumber === 'NCPA100') &&
                                (device?.installStatus === selfInstallStatus.FAILED || device?.installStatus === selfInstallStatus.INPROGRESS || device?.installStatus === selfInstallStatus.SUCCESS) && isMyadmin)"
                                class="button btn-long-text pull-right wide100" [disabled]="selfInstallService?.isNonIpEnabled"
                                attr.aria-label="{{resourceBundle.install}}"
                                (click)="openInstallationStepsModal(device)">{{resourceBundle.install}}</button>
                            <p *ngIf="device?.installStatus === 'Y'" class="bold green mt-4 text-center">
                                {{resourceBundle.complete}}</p>
                                <p *ngIf="device?.installStatus === selfInstallStatus.INPROGRESS && (device?.itemNumber === 'NCPA300' || device?.itemNumber === 'NCPA100') && !isMyadmin" class="bold green mt-4 text-center">
                                    {{resourceBundle.inProgress}}</p>
                            <p *ngIf="device?.installStatus === 'F' || (device?.installStatus === selfInstallStatus.FAILED && device.deviceType=== deviceType.NONINTERNETDEVICE && 
                                                        !isMyadmin && (device?.itemNumber === 'NCPA300' || device?.itemNumber === 'NCPA100'))"
                                class="bold red mt-4 text-center">
                                {{resourceBundle.unsuccessful}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div *ngIf="selfInstallService?.onepStatus !== ChannelStatus.Failure && (!selfInstallService?.netAssuranceInprogress)">
            <h5 class="bold-header mediumfont mt-4 margin-bottom-13px">{{resourceBundle.orderDetails}}</h5>
            <ng-container *ngIf="!selfInstallService.isMobileDevice">
                <ngx-datatable class="dataTable bootstrap" id="deviceDeliveryDetails" #deviceDeliveryDetails
                    [rows]="selfInstallService?.orderDetailsEquipmentRows" [columnMode]="'force'" [reorderable]="false"
                    [rowHeight]="'auto'" [headerHeight]="'auto'" [footerHeight]="'auto'" comnAccessTable
                    [noofRows]="selfInstallService?.orderDetailsEquipmentRows?.length" [tableName]="'Device Delivery Details'"
                    role="table" [table]="deviceDeliveryDetails">
                    <ngx-datatable-column prop="{{resourceBundle.equipment}}" name="{{resourceBundle.equipment}}"
                        [sortable]="false" [width]="400" [width]="400">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                            <div>{{row.name}}</div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="{{resourceBundle.trackingNumber}}" name="{{resourceBundle.trackingNumber}}"
                        [sortable]="false" [width]="200" [width]="200">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                            <div *ngIf="row.trackingUrl"><a class="linkUnderline word-break"
                                    attr.aria-label="{{row.name}} {{resourceBundle.trackingNumber}} {{row.shippingTrackingNumber}}"
                                    href="{{row.trackingUrl}}" target="_blank" (click)="selfInstallService.triggerTealium('Tracking Url')">{{row.shippingTrackingNumber}}</a>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </ng-container>
            <ng-container *ngIf="selfInstallService.isMobileDevice">
                <ngx-datatable class="dataTable bootstrap" id="deviceDeliveryDetails" #deviceDeliveryDetails
                    [rows]="selfInstallService?.orderDetailsEquipmentRows" [columnMode]="'force'" [reorderable]="false"
                    [rowHeight]="'auto'" [headerHeight]="'auto'" [footerHeight]="'auto'" comnAccessTable
                    [noofRows]="selfInstallService?.orderDetailsEquipmentRows?.length" [tableName]="'Device Delivery Details'"
                    role="table" [table]="deviceDeliveryDetails">
                    <ngx-datatable-column [sortable]="false" [width]="400" [width]="400">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                            <p class="bold mb-0">{{resourceBundle.equipment}}</p>
                            <div>{{row.name}}</div>
                            <p class="bold mb-0">{{resourceBundle.trackingNumber}}</p>
                            <div *ngIf="row.trackingUrl"><a class="linkUnderline word-break"
                                    attr.aria-label="{{row.name}} {{resourceBundle.trackingNumber}} {{row.shippingTrackingNumber}}"
                                    href="{{row.trackingUrl}}" target="_blank" (click)="selfInstallService.triggerTealium('Tracking Url')">{{row.shippingTrackingNumber}}</a>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </ng-container>
        </div>
    </div>
</div>
