
import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-skeletonloader',
  templateUrl: './skeletonloader.component.html',
  styleUrls: ['./skeletonloader.component.scss'],
})
export class SkeletonLoaderComponent {
  @Input() rowCount: number;
  // Datatable skeleton Inputs
  @Input() datatableSkeleton = false;
  @Input() datatableName: string;
  // Form skeleton Inputs
  @Input() colCount: number;
  @Input() formSkeleton = false;
  @Input() formName: string;
  @Input() subsidyCards = false;
  // PickList skelton Inputs
  @Input() pickListSkeleton = false;
  @Input() picklistName: string;
  // Accordion skeleton Inputs
  @Input() accordionSkeleton = false;
  @Input() accordionName: string;
  //
  @Input() freeformSkeleton = false;
  @Input() responsiveSkeleton = false;
  @Input() count: number;
  @Input() sectionName: string;
  @Input() widthSizeInPixels: number;
  @Input() heightSizeInPixels: number;
  @Input() borderRadiusInPixels = '10px';
  public numberOfRows(rowCount: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < rowCount; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
