import {
    Component, EventEmitter, OnDestroy,
    Output, OnInit,
    ViewChild,
    OnChanges,
    SimpleChanges,
    Input
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
    AEMClientService, CBHelperService,
    CbHeaderService,
    DigitalProfileSharedService, ResourceBundle, Role,
} from 'common-ui-lib';
import { DeviceDetectorService } from 'common-ui-lib';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Ticket, TicketDTO } from '../../cb-header.model';

@Component({
    selector: 'app-header-tickets',
    templateUrl: './header-tickets.component.html',
    styleUrls: ['./header-tickets.component.scss']
})

export class HeaderTicketsComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild('quickLinkPopover') quickLinkPopover: NgbPopover;
    @Input() headerTicketList: TicketDTO;
    @Output() closePopover  = new EventEmitter<boolean>();
    public resourceBundle: ResourceBundle = {};
    public tickets = null;
    public isVipCust: boolean;
    public isMobileDevice = false;
    public ticketsCount = '10';
    public ticketsList: Ticket[];
    public isNewCustomer = false;
    private getTicketDetailsSubscription: Subscription;
    private onDestroy$ = new Subject<boolean>();

    constructor(public aemClient: AEMClientService,
        public deviceService: DeviceDetectorService,
        public cbHelperService: CBHelperService,
        public cbHeaderService: CbHeaderService,
        public digitalProfileService: DigitalProfileSharedService,
        private router: Router,
        private sessionStorage: SessionStorageService) {
        // this logic has been added when we do switch user or
        // navigate to different pages to fetch the data at compoent level
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            if (this.cbHelperService.isProfileInContext() && (!window.location.href.includes('/unauth/logout')
                && !window.location.href.includes('/unauth/myadminlogout'))) {
                this.closePopover.emit(true);
                this.cbHeaderService.closeSideBar();
            }
        });
        this.cbHelperService.initiateHeaderRefreshSub.subscribe((data) => {
            if (data) {
                this.ngOnInit();
            }
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.headerTicketList?.currentValue) {
            this.headerTicketList = changes?.headerTicketList?.currentValue;
            this.updateCbotDetails(changes?.headerTicketList?.currentValue);
        }
    }

    ngOnInit() {
        this.isNewCustomer =
         this.cbHelperService.getLoggedInUserDetails()?.profile?.userToken?.profileStatus === 'REGISTERED';
        if (this.cbHelperService.isProfileInContext() && (!window.location.href.includes('/unauth/logout')
            && !window.location.href.includes('/unauth/myadminlogout'))) {
            this.aemClient.getSharedBundle('ticketsRedesign')
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                    (data) => {
                        this.resourceBundle = data;
                    }
                );
            this.getTicketDetailsSubscription = this.digitalProfileService.getDigitalProfileResponse
                .subscribe((response) => {
                    if (response?.headerTickets) {
                        this.headerTicketList = response?.headerTickets;
                        this.updateCbotDetails(this.headerTicketList);
                        this.isVipCust = this.checkVipCustomer();
                    }
                });
            if (this.deviceService.isMobile()) {
                this.isMobileDevice = true;
            }
        }

    }

    public updateCbotDetails(ticketList: TicketDTO) {
        if (ticketList) {
            this.tickets = ticketList;
        }
        if (parseInt(this.tickets?.ticketsCount, 10) < 100) {
            this.ticketsCount = this.tickets?.ticketsCount;
        } else {
            this.ticketsCount = '99+';
        }
        this.ticketsList = this.tickets?.ticketsList;
        this.isVipCust = this.checkVipCustomer();
    }

    //On click of ticket navigate to ticket details page
    public onTicketClick(ticketId: string) {
        this.closePopover.emit(true);
        this.cbHeaderService.closeSideBar();
        if (this.ticketsList.length > 0) {
            this.ticketsList.forEach((obj) => {
                if (ticketId === obj.id) {
                    this.sessionStorage.store('createDateTime', obj.createDateTime);
                    this.sessionStorage.store('truckRoll', (obj.truckRoll === 'YES') ? 'Yes' : 'No');
                    this.sessionStorage.store('workOrderNumber', obj.workOrderNumber);
                }
            });
        }
        this.router.navigate(['/onlineticket/ticketsummary/ticketdetails/' + ticketId]);
    }

    // On click of 'Create A Ticket' link
    public navigateToCreateTicket() {
        this.closePopover.emit(true);
        this.cbHeaderService.closeSideBar();
        this.router.navigate(['/onlineticket/ticketsummary/createticket/stepone']);
    }

    // On click of 'View Ticket History' link
    public navigateToTicketHistory() {
        this.closePopover.emit(true);
        this.cbHeaderService.closeSideBar();
        this.router.navigate(['/onlineticket/ticketsummary'], { queryParams: { tickethistory: true } });
    }

    ngOnDestroy(): void {
        this.getTicketDetailsSubscription?.unsubscribe();
    }

    //logic will be removed after 7/26 release
    private checkVipCustomer(): boolean {
        const role = this.cbHelperService.getLoggedInUserDetails().profile.roles;
        if (this.router.url.includes('/dashboard') || !this.cbHelperService.getMarketRollOut) {
            if (role.includes(Role.ONLINE_TICKETING_ROLE)) {
                return true;
            } else {
                return false;
            }
        } else {
            return this.headerTicketList.vipCustomer;
        }
    }

}
