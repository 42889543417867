/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum NotificationResourcebundleEnum {
	OUTAGE = 'outage',
	E911 = 'e911',
	MAINTENANCE = 'maintenance',
	BILLING = 'billing',
	DEVICE_FAILURE = 'deviceFailure',
	INTERNET_DOWN = 'internetDown',
	INTERNET_BACKUP_CONNECTION_CHANGES = 'internetBackup',
	BILLING_PAYMENT_DUE = 'billingPaymentDue',
	BILLING_PAYMENT_STATUS = 'billingPaymentStatus',
	BILLING_PAYMENT_UPDATE = 'billingPaymentUpdate',
	SERVICE_NOTIFICATIONS = 'serviceNotifications',
	SERVICE_UPDATES = 'serviceUpdates'
}

export enum NotificationCategoryEnum {
	OUTAGE = 'OUTAGE',
	E911 = 'E911',
	BILLING = 'BILLING',
	MAINTENANCE = 'MAINTENANCE',
	DEVICE_FAILURE = 'DEVICE_FAILURE',
	INTERNET_BACKUP_CONNECTION_CHANGES = 'INTERNET_BACKUP_CONNECTION_CHANGES',
	INTERNET_DOWN = 'INTERNET_DOWN',
	BILLING_PAYMENT_DUE = 'BILLING_PAYMENT_DUE',
	BILLING_PAYMENT_STATUS = 'BILLING_PAYMENT_STATUS',
	BILLING_PAYMENT_UPDATE = 'BILLING_PAYMENT_UPDATE',
	SERVICE_UPDATES='SERVICE_UPDATES',
	SERVICE_NOTIFICATIONS = 'SERVICE_NOTIFICATIONS'
}

export class NotificationUserPreferenceInput {
	value: boolean;
	userGuid: string;
	accountGuid: string;
	notificationCategory: NotificationCategoryEnum;
	notificationChannel: NotificationChannelEnum;
}


export enum NotificationChannelEnum {
	EMAIL = 'EMAIL',
	SMS = 'SMS'
}

export enum FilterEnum {
	ALL = 'ALL',
	SINGLE = 'SINGLE'
}
