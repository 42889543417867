import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AppConfig, CoxHttpClient} from 'common-ui-lib';
import gql from 'graphql-tag';
import { EquipmentListObj } from 'home/myequipment/myequipment.model';
import { Observable } from 'rxjs';
import { DdosRequest } from '../sub-landing.model';

@Injectable()
export class DataToolsService {

    public parentAccSelected: any;
    public selectedAccountGuid: string;
    public otherEquipmentData: EquipmentListObj[];
    constructor(public coxHttp: CoxHttpClient, private readonly apollo: Apollo, private readonly config: AppConfig) {
    }

    public getProfileFeatures(){
        const getProfileDetailsQuery = gql`
        query getProfileDetails{
            getProfile{
                serviceFeature(featureCategoryName: INTERNET){
                    cbServices{
                        subServiceDetails{
                            category
                            subServiceList{
                                subCategory
                                featureName
                                role
                            }
                        }
                    }
                }
            }
        }
        `;
    return this.apollo.query({
        query: getProfileDetailsQuery,
    });
}

    public getAccountFeatures(userId: string){
        const getAccountDetailsQuery = gql`
        query getProfileDetails($userId: ID!){
            getAccount(id: $userId){
                serviceFeature(featureCategoryName:INTERNET){
                    cbServices{
                        subServiceDetails{
                            category
                            subServiceList{
                                subCategory
                                featureName
                                role
                            }
                        }
                    }
                    ddosFeatures
                }
            }
        }
       `;
        return this.apollo.query({
            query: getAccountDetailsQuery,
            variables: {
                userId
            },
        });
    }

    public getDDosStatus(requestBody): Observable<any>{
    const  url = `/api/cbma/account/services/account/ddos/createworkorder`;
      return this.coxHttp.post<any>(url, requestBody);
    }

    public getDdosTileStatus(accountGuid: string, userId: string) {
        const ddosDetails = gql`
        query getProfileDetails($userId: ID!, $accountGuid: String!){
            getAccount(id: $userId){
                getDdosTileStatus(accountGuid: $accountGuid){
                    status
                }
            }
        }
       `;
        return this.apollo.query({
            query: ddosDetails,
            variables: {
                accountGuid,
                userId
            },
        });
    }

    public updateToggle(guid: string, isEnabled: boolean): Observable<any> {
        const input: DdosRequest={
            guid,
            isEnabled
        };
        const updateDdosTileStatus = gql`
        mutation updateDdosTileStatus($input: DdosRequest) {
          updateDdosTileStatus(input: $input){
            code
            id
            message
          }
        }
        `;
        return this.apollo.mutate({
          mutation: updateDdosTileStatus,
          variables: {
            input
          }
        });
    }

}
