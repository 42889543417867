<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 px-0 bg-white content_center" [ngClass]="{ 'right-offset' : deviceService.isMobile}">
    <div class="width-300">
        <span *ngIf="accountListClone?.length > 0 && !hideWidgetTitle" id="account">{{resourceBundle.account}}</span>
        <span *ngIf="accountListClone?.length === 0 && !hideWidgetTitle && contentLoaded">{{resourceBundle.noActiveAccounts}}</span>
            <ng-container *ngIf="contentLoaded; else loadSpinTemplate">
                <div class="account_box div-center-align" *ngIf="accountListClone?.length === 0">
                    <a href="javascript:void(0)" (click)="navigateToAddAccount()">{{resourceBundle.addAnAccount}}</a>
                    <div class="ml-2 mr-2">or</div>
                    <a href="https://www.cox.com/business/contact-us.html">{{resourceBundle.contactUS}}</a>
                </div>

                <div [attr.aria-expanded]="accountListClone?.length > 1? showDropdown : null" class="account_box div-center-align space_beween" (click)="onClickOfAccountWidget()" tabindex="{{accountListClone?.length > 1 ? 0 : -1}}" id="accountBox"
                    [ngClass]="{'mouse-link account_box_shadow': accountListClone?.length > 1}" (keyup.enter)="onClickOfAccountWidget()"
                    *ngIf="accountListClone?.length > 0" [attr.role]="accountListClone?.length > 1 ? 'combobox' : null" aria-labelledby="account" aria-live="polite">
                    <div *ngIf="selectedAccount; else noAccountSelected" class="wide90">
                        <div class="bold ellipsis" [attr.title]="selectedAccount?.alias">{{ selectedAccount?.alias}}
                        </div>
                        <div class="ellipsis col-12 p-0" *ngIf="selectedAccount?.accountExternalAttrs?.serviceAddress?.fullAddress" 
                            [attr.title]="selectedAccount?.accountExternalAttrs?.serviceAddress?.fullAddress + ' selected'">
                            {{ selectedAccount?.accountExternalAttrs?.serviceAddress?.fullAddress }}
                            <span class="sr-only"> selected</span>
                        </div>
                    </div>
                    <ng-template #noAccountSelected>
                        <div tabindex="-1">
                            {{resourceBundle.selectAnAccount}}
                        </div>
                    </ng-template>
                    <div *ngIf="accountListClone?.length > 1 && !this.disabledAcc">
                        <img src="assets/images/blueprint/admin_redesign/arrow-down-small-grey.svg" alt=""
                            class="dropdown_arrow ae-img mr-3 mb-2" [ngClass]="{ 'rotate': showDropdown }" aria-hidden="true">
                    </div>
                </div>
            </ng-container>
        <div *ngIf="showDropdown" class="account_widget_dropdown" id="dropdownContainer" (scroll)="onScroll($event)" role="listbox">
            <span class="sr-only" role="alert"></span>
            <div class="acc_widget_container" role="alert">
                <div class="search_container">
                    <input type="text" placeholder="Search" class="search_input" (input)="onSearch()"
                        [(ngModel)]="searchText" aria-describedby="accLength noCreteria accCount">
                    <img src="assets/images/blueprint/admin_redesign/magnifying-glass.svg" alt="Search Icon"
                        class="search_icon" role="presentation">
                        <img src='assets/images/blueprint/close-black.svg' role="button"
                        class="acc-clear-search" [ngClass]="{'hide':!searchFlag}" (click)="onClickOfClearSearch()" (keyup.enter)="onClickOfClearSearch()" alt="clear search">
                </div>
                <div class="font-italic dropdown_page" *ngIf="!searchText" id="accLength">Showing {{ accountList?.length }} of
                    {{ accountListClone?.length }} accounts</div>
                <div class="font-italic dropdown_page" *ngIf="searchText && accountList?.length === 0" id="noCreteria">{{resourceBundle.searchCriteria}}</div>
                <div class="font-italic dropdown_page"
                    *ngIf="searchText && accountList?.length > 0 && accountList?.length <= 5" id="accCount"> Showing {{ accountList?.length }} of
                    {{ accountListClone?.length }} accounts
                </div>
                <div class="font-italic dropdown_page" *ngIf="searchText && accountList?.length > 10">{{resourceBundle.continueTyping}}</div>
                <div *ngFor="let obj of accountsListToShow; let i = index" class="dropdown_content pt-2" tabindex="0" role="option" [id]="obj.id"
                    [ngClass]="{'border_top_grey': i !== 0, 'selected_account': obj?.accountNumber9 == selectedAccount?.accountNumber9 }"
                    (click)="onAccountSelection(obj)" [attr.aria-selected]="selectedAccount?.id === obj?.id ? true : false" (keyup.enter)="onAccountSelection(obj)">
                    <div>
                        <div class="bold">{{ obj?.alias }}</div>
                        <div *ngIf="obj?.accountExternalAttrs?.serviceAddress?.fullAddress">
                            {{ obj?.accountExternalAttrs?.serviceAddress?.fullAddress }}
                        </div>
                        <div>{{ obj?.siteId }} - {{ obj?.accountNumber }}</div>
                        <div>{{ obj?.accountName }}</div>
                        <span class="sr-only" *ngIf="selectedAccount?.id === obj?.id">selected</span>
                    </div>
                    <div class="account_selection_arrow">
                        <img src="assets/images/blueprint/admin_redesign/arrow-right-large-black.svg" role="presentation" alt ="arrow_right_large_black">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadSpinTemplate>
    <div class="account_box">
        <app-skeletonloader formName="Reboot Widget" formSkeleton="true" rowCount="1" class="wide100">
        </app-skeletonloader>
    </div>
</ng-template>