<ng-container>
    <span class="sr-only">expanded</span>
    <div class="text-bold-400 text-font-16 line-height-24px 
        ticket-content margin-bottom-0-75rem">
        <div class="ticketContainer p-0 mb-0">
            <ng-container *ngIf="ticketsList?.length > 0; else noTickets">
                <div *ngFor="let list of ticketsList | keyvalue" (click)="onTicketClick(list.value.id)" role="link" class="ticketsList" tabindex="0">
                    <div [ngClass]="isMobileDevice ? 'twoColumnGridMob' : 'twoColumnGridDesktop'">
                        <div title="{{list.value.requestType}}" class="row-text-overflow">
                            {{!isMobileDevice ? list.value.requestType : list.value.requestType}}</div>
                        <div class="d-flex justify-end">
                            <div class="pl-1 row-text-overflow">-
                                <span class="pl-1" title="{{list.value.id}}">{{list.value.id}}</span>
                            </div>
                            <span>
                                <img src="assets/images/blueprint/admin_redesign/arrow-right-black.svg"
                                    alt="right-arrow" class="mb-2 right-arrow" role="presentation">
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noTickets tabindex="-1">
                <span aria-hidden="true">{{resourceBundle.noTickets}}</span>
            </ng-template>
        </div>
    </div>
    <div *ngIf="!isNewCustomer" class="text-bold-400 font-size-13px line-height-24px">
        <div class="mb-2 display-flex justify-content-flexEnd" (blur)="!isVipCust">
            <a href="javascript:void(0)" (click)="navigateToTicketHistory()"
                class="linkUnderline">{{resourceBundle.viewticketHistory}}
                <span class="arrow-next-container margin-left-10-px">
                    <img src="assets/images/blueprint/cb_header/arrow_right.svg" alt="arrow next icon" width="4.6"
                        height="8.39" class="mb-1" />
                </span>
            </a>
        </div>
        <div *ngIf="isVipCust" class="mb-2 display-flex justify-content-flexEnd">
            <a href="javascript:void(0)" class="linkUnderline" (click)="navigateToCreateTicket()">
                {{resourceBundle.createTicket}}
                <span class="arrow-next-container margin-left-10-px">
                    <img src="assets/images/blueprint/cb_header/arrow_right.svg" alt="arrow next icon" width="4.6"
                        height="8.39" class="mb-1" />
                </span>
            </a>
        </div>
    </div>
</ng-container>