import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MenuOptionsService {
  public contentLoaded = false;

  constructor() { }
  public UserDetails = new BehaviorSubject<any>(null);
 
  public getUserDetails(): Observable<any> {
    return this.UserDetails.asObservable();
  }

  public setUserDetails(UserDetails: any) {
    this.UserDetails.next(UserDetails);
  }
}
