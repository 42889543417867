import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig } from '../../core/app.config';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-bannercarousel',
  templateUrl: './bannercarousel.component.html',
  styleUrls: ['./bannercarousel.component.scss'],
})
export class BannercarouselComponent implements OnInit {
  images = ['assets/images/blueprint/banner-bg.png', 'assets/images/blueprint/cbcc-banner-bg.png'];
  showNavigationArrows: boolean;
  learnMoreHref = '#';
  tracedActInfo = '#';
  raybaumActInfo = '#';
  isLandingPage = false;
  constructor(
    public configCar: NgbCarouselConfig,
    public config: AppConfig,
    private router: Router) { }

  ngOnInit() {
    this.learnMoreHref = this.config.getConfig('learnMoreURL');
    this.tracedActInfo = this.config.getConfig('tracedActInfo');
    this.raybaumActInfo = this.config.getConfig('raybaumActInfo');
  }
}
