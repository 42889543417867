<div *ngIf="!isMobileDevice" class="notification-title-icon popover-active-box"
[ngClass]="alertCheck ? 'mb-n2': 'popover-no-alert-img-box'" (click)="cbHeaderService.showNotifications=!cbHeaderService.showNotifications">
    <a href="javascript:void(0)" [ngbPopover]="messagesPopover" [placement]="popoverPlacement"
        #quickLinkPopover="ngbPopover" role="button" [autoClose]="true" class="text-center"
        [attr.aria-label]="alertCheck ? resourceBundle.availableNotifications : resourceBundle.messages"
        [popoverTitle]="alertsTitle" popoverClass="quickLinkPopover" (hidden)="isPopoverOpen(false)" aria-hidden="true"
        (shown)="isPopoverOpen(true)">
        <img src="assets/images/blueprint/cb_header/notification_active.svg" alt="Messages" role="presentation" tabindex="-1" />
        <span class="red-indicator" *ngIf="cbHeaderService.alertCheck"></span>
    </a>
    <div id="closeMsgs" class="sr-only" aria-live="polite"></div>
</div>

<div *ngIf="isMobileDevice">
    <div class="bold alerts-title pl-4">{{alertCheck ?
        resourceBundle?.alerts + ' (' + this.alertCount.toString() + ')' : resourceBundle?.alerts}}
    </div>
    <ng-container *ngTemplateOutlet="messagesPopover"></ng-container>
</div>

<ng-template #alertsTitle class="alerts-title">{{alert}}</ng-template>

<ng-template #noMsgs>
    <div>{{resourceBundle.noMsgs}}</div>
</ng-template>

<ng-template #messagesPopover>
    <span class="sr-only">expanded</span>
    <div class="d-flex flex-direction-column alertsList margin-bottom-20px"
        [ngClass]="{'alertListMob': isMobileDevice}">
        <ng-container *ngIf="contentLoaded; else alertsLoader">
            <ul id="alertList" [ngClass]="!alertLoader ? 'messageList ul-style mb-0':'margin-zero'" role="presentation">
                <!-- mock data to show kari's law E911 Notification Compliance alert -->
                <li *ngIf="isKariLawE911Alert && isPOorPA" class="msg-alert outageAlert mb-2 pl-5 onFocusOutline" data-alert role="button"
                    tabindex="0" (click)="openKariLawAlertModal()" (keydown.enter)="openKariLawAlertModal()">
                    <label class="bold">{{resourceBundle.kariLawE911Notification}}</label><br>
                    <span>{{resourceBundle.kariLawE911Details}}</span>
                </li>
                <li *ngIf="alreadySwapped && !this.cbHelperService.isMyAdmin() && symphonyResponse?.swapResponse === validateSwap.Alreadyswapped"
                    title="Already Swapped" class="msg-info outageAlert mb-2 chat-trigger pl-5 onFocusOutline" data-alert role="alert"
                    tabindex="0" (click)="triggerTealium('Conatct-Us')" (keydown.enter)="triggerTealium('Conatct-Us')">
                    <label class="bold">{{resourceBundle.alreadySwapped}}</label><br>
                    <span>{{resourceBundle.alreadySwappedDescription}}</span>
                </li>
                <li *ngIf="npdAlert && billingFlag" title="NPD Alert" class="msg-error outageAlert  pl-5 onFocusOutline"
                    (click)="openNpdAlertModal()" data-alert role="alert" tabindex="0">
                    <label class="bold mouse-link">{{npdAlertDetails.action}}</label><br>
                    <span>{{npdAlertDetails.message | truncate:[35, '...']}}</span>
                </li>
                <li *ngIf="alerts?.unplannedOutageIndicator" title="Unplanned Outage" role="button" tabindex="0"
                    class="msg-error outageAlert  pl-5 onFocusOutline" (click)="openUnplannedOutageModal()"
                    (keydown.enter)="openUnplannedOutageModal()" data-alert>
                    <label class="bold mouse-link">{{resourceBundle.unplannedOutage}}</label><br>
                    <span
                        attr.aria-label="{{resourceBundle.unplannedOutage}} {{isMarketRollOutFlag ? resourceBundle.viewOutageMapLink : resourceBundle.clickToView}}">{{isMarketRollOutFlag
                        ? resourceBundle.viewOutageMapLink : resourceBundle.clickToView}}</span>
                </li>
                <li *ngIf="alerts?.indicatorEvents?.plannedOutageIndicator" title="Planned Outage"
                    class="msg-alert outageAlert  pl-5 onFocusOutline" (click)="openPlannedOutageModal()"
                    (keydown.enter)="openPlannedOutageModal()" data-alert role="button" tabindex="0">
                    <label class="bold mouse-link">{{resourceBundle.plannedOutage}}</label><br>
                    <span>{{isMarketRollOutFlag ? resourceBundle.viewOutageMapLink : resourceBundle.clickToView}}</span>
                </li>
                <li *ngIf="swapAlert && !this.cbHelperService.isMyAdmin()" title="Swap"
                    class="msg-info mb-2 outageAlert pl-5 onFocusOutline" data-alert role="alert" tabindex="0"
                    (click)="onClickOfSwapAlert(symphonyResponse?.swapResponse)" (keydown.enter)="onClickOfSwapAlert(symphonyResponse?.swapResponse)">
                    <label class="bold">{{resourceBundle.swapAlert}}</label><br>
                    <span>{{resourceBundle.swapAlertDescription}}</span>
                </li>
                <li id="serviceApptAlert" *ngIf="alerts?.hasServiceAppointment?.accountGuid"
                    title="Upcoming Service Appointment"
                    class="msg-info msg-serviceAppt-info outageAlert mouse-default  pl-5 display-flex" data-alert
                    tabindex="0">
                    <div>
                        <label title="Upcoming Service Appointment" class="bold display-block truncate"
                            id="serviceApptAlertLabel">{{resourceBundle.upcomingServiceAppt}}</label>
                        <span title="Upcoming Service Appointment">{{serviceAppointmentDate | date:'EEEE, MMMM d'}},
                            {{serviceAppointmentTime}}</span>
                        <div [title]="resourceBundle.manageAppointment" class="pull-right">
                            <a href="javascript:void(0)" class="linkUnderline pr-2" id="serviceApptLink"
                                (click)="navigateToServiceAppts()"
                                (keydown.enter)="navigateToServiceAppts()">{{resourceBundle.manageAppointment}}</a>
                        </div>
                    </div>
                </li>
                <li *ngIf="deviceStatus?.status && !hideSelfInstall" title="Self-Install Status Alert"
                    class="msg-info outageAlert  pl-5 onFocusOutline track-clicks" data-alert
                    (click)="selfInstallService.openOrderDetailsModal()" (keydown.enter)="selfInstallService.openOrderDetailsModal()" tabindex="0" role="alert">
                    <label class="bold pointer-none">{{deviceStatus.title}}</label><br>
                    <span class="pointer-none">{{deviceStatus.description}}</span>
                </li>
                <li *ngIf="alerts?.cbccTrialAlert?.alertStatus" id="cbcc" title="Complete Care Trial Active" class="msg-info outageAlert mb-2 pl-5 onFocusOutline"
                    data-alert role="button" tabindex="0" (click)="openCBCCAlertModal()" (keydown)="openCBCCAlertModal()" attr.aria-label="Complete Care Trial Active">
                    <label class="bold">{{resourceBundle.cbccTitle}}</label><br>
                    <span>{{stringUtilService
                        .formatByPlaceHolder(resourceBundle.cbccDescription, [digitalProfileService?.alertDesc])}}</span>
                </li>
                <li *ngIf="paperlessBillAlert && billingFlag" title="Paperless Billing Alert"
                    class="msg-info outageAlert  pl-5 onFocusOutline" (click)="openPaperlessBillAlertModal()" data-alert
                    role="alert" tabindex="0">
                    <label class="mouse-link bold">{{paperlessBillDetails.action}}</label><br>
                    <span>{{paperlessBillDetails.message | truncate:[35, '...']}}</span>
                </li>
                <li *ngIf="alerts?.indicatorEvents?.promotionalOutageIndicato" title="FTTX alert"
                    class="msg-info outageAlert  pl-5 onFocusOutline" (click)="openFiberInternetModal()"
                    (keydown)="openFiberInternetAccModal($event)" data-alert role="button" tabindex="0">
                    <label class="mouse-link bold display-block truncate">{{resourceBundle.fttxInfo}}</label>
                    <span>{{resourceBundle.clickToView}}</span>
                </li>
                <li *ngIf="!alertCheck" title="No Alerts" class="msg-success outageAlert  pl-5 onFocusOutline"
                    data-alert  role="button" aria-hidden="true">
                    <label class="bold">{{resourceBundle.noAlertsHeader}}</label><br>
                    <span attr.aria-label="{{resourceBundle.noAlertsHeader}} {{resourceBundle.noAlertsSubject}}">
                        {{resourceBundle.noAlertsSubject}}
                    </span>
                </li>
            </ul>
            <ng-container *ngIf="alertLoader">
                <ng-container *ngTemplateOutlet="alertsLoader"></ng-container>
            </ng-container>
            <div class="margin-right-20px" *ngIf="!isNewCustomer && !alertLoader">
                <a href="javascript:void(0)" [routerLink]="['/notificationhistory']"
                    class="pull-right margin-bottom-15px linkUnderline" (blur)="offFocus($event)"
                    (keydown.enter)="$event.stopPropagation();onViewAllMsgClick()"
                    (click)="cbHeaderService.closeSideBar()">
                    {{resourceBundle.viewAll}}{{viewCount}}
                    <span class="arrow-next-container margin-left-10-px">
                        <img src="assets/images/blueprint/cb_header/arrow_right.svg" alt="arrow next icon" width="4.6" role="presentation"
                            height="8.39" class="mb-1"/>
                    </span>
                </a>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #alertsLoader>
    <div class="messageList">
        <app-skeletonloader freeformSkeleton="true" count="1" sectionName="Alerts Section" heightSizeInPixels="72"
            [widthSizeInPixels]="300" borderRadiusInPixels="3">
        </app-skeletonloader>
    </div>
</ng-template>