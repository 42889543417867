import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AEMClientService, CBHelperService, CommonMessageService, DigitalProfileSharedService, LocalStorageKeys, ResourceBundle } from 'common-ui-lib';
import { FocusTrapsService } from 'common-ui-lib/lib/services/focustraps.service';
import { CBLoaderService } from 'common-ui-lib/lib/shared/cbloader/cbloader.service';
import { isEmpty } from 'lodash';
import { DeviceDetectorService } from 'common-ui-lib';
import { LocalStorageService } from 'ngx-localstorage';
import { BillStatement, StatementDetails } from 'redesign/admin/account/add-account/add-account.model';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MopDetail } from '../billing-home.model';
import { BillingHomeService } from '../billing-home.service';
import { AccountStatusEnum, CBAccount, StatementList } from './make-payment.model';
import { MakePaymentService } from './make-payment.service';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss'],
})
export class MakePaymentComponent implements OnInit, OnChanges {
  @Input() currentStatement: StatementDetails;
  @Output() mopEmmiter = new EventEmitter();
  public resourceBundle: ResourceBundle = {};
  public isTabletDevice = false;
  public totalCount = 0;
  public paginationCheck = true;
  public checkPreviousPage: boolean;
  public checkNextPage: boolean;
  public billingView: boolean;
  public getLastCursorValue: string;
  public getFirstCursorValue: string;
  public displayAccountList: StatementList[] = [];
  public searchDataSource = [];
  public showGradient: boolean;
  public selectedRecord: StatementList;
  public isDummy = false;
  public isMOP = false;
  public isReview = false;
  public isPayment = false;
  public isPaymentFailure = false;
  public isAddPayment = false;
  public singleStatementObj: CBAccount;
  public statementObj: StatementList = null;
  public editMopObject: MopDetail;
  public isSingleAccountSelected: boolean;
  public isSPMAccount: boolean;
  public hasMaxNumberOfMops: boolean;
  public requestType: string;
  public singleAccountSelected: boolean;
  public restrictedMop: string;
  public isAddSuccess = false;
  public initialQueryCall: boolean;
  public accountCount = 0;
  editMOPObj: MopDetail;
  public isMobileView: boolean;
  public isEditMop: boolean;
  public mopList = [];
  public getLastIndexValue = 0;
  private guid: string;
  private profileGuid: string;
  private allStatementsLoaded = false;
  private statementsLoading = false;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    public makePaymentService: MakePaymentService,
    public spinnerService: CBLoaderService,
    public commonMessageService: CommonMessageService,
    public activeModal: NgbActiveModal,
    public billingService: BillingHomeService,
    private aemClient: AEMClientService,
    public deviceService: DeviceDetectorService,
    public focusTrapService: FocusTrapsService,
    public router: Router,
    private _storageService: LocalStorageService,
    public cbHelperService: CBHelperService,
    public digitalProfileService: DigitalProfileSharedService
  ) { }

  ngOnChanges() {
    this.setHeaderTitle();
  }

  ngOnInit(): void {
    this.billingView = this.router.url.includes('/billinghome') ? true : false;
    this.isMobileView = this.deviceService.isMobile();
    this.aemClient
      .getRedesignBundle('billing/make-payment/make-payment')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
          this.resourceBundle = data;
        }
      );
    this.makePaymentService.currentStep = 1;
    this.makePaymentService.contentLoaded = false;
    this.guid = this._storageService.get(LocalStorageKeys.SelectedAccount, 'cbma')
      ? this._storageService.get(LocalStorageKeys.SelectedAccount, 'cbma')
      : '';
    this.initialQueryCall = true;
    this.profileGuid = this.cbHelperService.getLoggedInUserDetails()?.profile?.userToken?.profileGuid;
    this.getAccountStatements('0', null, 'billing_payment', 10, 0, this.guid);
  }

  // Focus trap for makePaymentMopModal - Accessibility--starts
  public makePaymentMopFocus(event: KeyboardEvent, firstBtn, lastBtn) {
    this.focusTrapService.makePaymentMopFocus(event, firstBtn, lastBtn);
  }
  // Focus trap for makePaymentMopModal - Accessibility--ends

  // Query call on datatable pagination
  public onScroll(event) {
    if ((event.currentTarget?.scrollLeft === 0 && event.offsetX === undefined) ||
      (event.offsetX === 0 && event.currentTarget?.scrollLeft === undefined)
    ) {
      if (event.currentTarget?.scrollTop) {
        if (
          event.currentTarget.scrollTop >= (event.currentTarget.scrollHeight - event.currentTarget.offsetHeight) / 2 &&
          this.checkNextPage
        ) {
          this.getAccountStatements(this.getLastCursorValue, '', 'billing_payment', 10, 0, this.guid);
        }
        if (
          event.currentTarget.scrollTop + 25 >= event.currentTarget.scrollHeight - event.currentTarget.offsetHeight &&
          !this.checkNextPage
        ) {
          this.showGradient = false;
        } else {
          this.showGradient = true;
        }
      }
    }
    this.paginationCheck = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public close(event: any) {
    this.makePaymentService.totalAmount = 0;
    this.makePaymentService.isSingleAccountSelected = false;
    this.makePaymentService.selectedStatements = [];
    this.makePaymentService.title = '';
    this.makePaymentService.paymentFailure = false;
    this.activeModal?.close(event);
    this.isAddSuccess = false;
    this.commonMessageService.displayMessage.show = null;
    this.billingService.setrefreshStatementDetails((this.isPaymentFailure || this.isPayment) ? this.billingService.setRefreshData(true, true) : this.billingService.setRefreshData(false, false));
    this.makePaymentService.reviewAndSubmitObj = null;
    this.makePaymentService.newMOPObj = [];
    if (this.isMobileView) {
      this.billingService.currentMobilePage = 'home';
    }
    this.billingService.payArrangeFlow = false;
    if(this.cbHelperService.getMarketRollOut) {
      this.digitalProfileService.setBillingSummary(null);
      this.digitalProfileService.billingSubCounter = 1;
      this.digitalProfileService.isBillingData = false;
      this.digitalProfileService.billingSummarySubscription(this.profileGuid);
      this.digitalProfileService.getBillingInfo(this.profileGuid);
    }
  }

  //Switch to statement details modal
  public showStatementDetails(row: StatementList) {
    this.selectedRecord = row;
    this.makePaymentService.title = this.resourceBundle.statementDetails;
  }

  //Switch to Multi account modal
  public goToAccounts() {
    this.makePaymentService.showStatement = false;
    if (this.makePaymentService.paymentFailure) {
      this.makePaymentService.title = this.resourceBundle.paymentFailure;
    } else {
      this.setHeaderTitle();
    }
  }

  //Switches to multi account mop modal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gotoMOP(e: any) {
    this.makePaymentService.currentStep = 2;
    if (!this.isMobileView) {
      this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-md';
    }
    this.isMOP = e.isMOP;
    this.isAddSuccess = false;
    this.isEditMop = true;
    this.makePaymentService.isSingleAccountSelected = e.isSingleAccountStatement;
    if (e.isSingleAccountStatement && (this.isAddPayment || this.isReview)) {
      this.makePaymentService.title = this.resourceBundle.chooseMethod;
      this.isAddSuccess = true;
      this.isAddPayment = false;
      this.isReview = false;
      this.isPayment = false;
    } else {
      this.redirectToMOPDetails();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gotoMOPfromReview(e: any) {
    this.makePaymentService.title = this.resourceBundle.chooseMethod;
    this.isEditMop = true;
    this.isReview = false;
    this.isMOP = e.isMOP;
    if (this.billingService.isMultiAccount || this.billingService.isMultiStatement) {
      this.makePaymentService.currentStep = 2;
      if (!this.isMobileView) {
        this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-md';
      }
    }
  }

  //Switches to review and payment modal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gotoReview(e: any) {
    if (!this.isMobileView) {
      this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-lg';
    }
    this.makePaymentService.currentStep = 3;
    this.isReview = e;
    this.isMOP = false;
    this.makePaymentService.title = this.resourceBundle.reviewAndSubmit;
  }

  public gotoAccAndAmounts() {
    this.makePaymentService.currentStep = 1;
    if (!this.isMobileView) {
      this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-lg';
    }
    this.isReview = false;
    this.isMOP = false;
    this.isPayment = false;
    this.makePaymentService.showStatement = false;
    this.setHeaderTitle();
    this.makePaymentService.newMOPObj = [];
    this.makePaymentService.selectedMopForPayment = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gotoAddPaymentFlow(e: any) {
    if (!this.isMobileView) {
      this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-md';
    }
    this.makePaymentService.title = this.resourceBundle.addPayment;
    this.makePaymentService.currentStep = 2;
    this.editMopObject = null;
    this.isAddPayment = true;
    this.isMOP = false;
    this.isPayment = false;
    this.makePaymentService.showStatement = false;
    this.isReview = false;
    this.restrictedMop = e.restrictedMOP;
    this.isSPMAccount = e.isSPMAccount;
    this.requestType = e.requestType;
    this.hasMaxNumberOfMops = e.hasMaxNumberOfMops;
    this.singleAccountSelected = e.singleAccountSelected;
    this.mopList = e.mopList
    document.getElementById('closeBtn')?.focus();
  }

  //Switches to payment confirmation modal
  public gotoPayment() {
    if (!this.isMobileView) {
      this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-md';
    }
    if (!this.makePaymentService.paymentFailure) {
      this.isReview = false;
      this.isMOP = false;
      this.isPayment = true;
      this.makePaymentService.title = this.resourceBundle.paymentConfirmation;
    } else if (
      this.makePaymentService.paymentFailure &&
      (this.billingService.isMultiAccount || this.billingService.isMultiStatement)
    ) {
      this.isReview = false;
      this.isMOP = false;
      this.isPayment = false;
      this.isPaymentFailure = true;
      this.makePaymentService.title = this.resourceBundle.paymentFailure;
    } else if (!this.isMobileView) {
      this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-lg';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public newMOPDetails(e: any) {
    if (e.result !== 'EDIT_UNSAVED_EVENT') {
      this.makePaymentService.newMOPObj.push(e);
    } else if (e.result === 'EDIT_UNSAVED_EVENT') {
      this.editMOPObj = e.editedObj;
    }
    this.redirectToMOPDetails();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getEditObj(e: any) {
    if (!this.isMobileView) {
      this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-md';
    }
    this.editMopObject = e;
    this.isAddPayment = true;
    this.isMOP = false;
    this.isPayment = false;
    this.makePaymentService.showStatement = false;
    this.isReview = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public redirectToMOPDetails(results?: any) {
    this.isAddPayment = false;
    this.isMOP = true;
    this.isReview = false;
    this.isPayment = false;
    this.isAddSuccess = false;
    this.makePaymentService.title = this.resourceBundle.chooseMethod;
    if (this.billingService.isMultiAccount) {
      if (!this.isMobileView) {
        this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-md';
      }
    }
    if (results?.result?.includes('_SUCCESS') || results?.result?.includes('CLOSE_EVENT')) {
      this.isAddSuccess = true;
    }
  }

  //To download statement pdf details
  public downloadStatementAsPDF(guid: string, stmCode: string, fileName: string) {
    this.makePaymentService
      .getStatementByteArray(guid, stmCode)
      .pipe(finalize(() => { }))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data) => {
          if (data && data.code.toString() === '0') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const byteCharacters = atob(data.pdfData as any);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const navigate = window.navigator as any;
            if (navigate?.msSaveOrOpenBlob) {
              navigate?.msSaveOrOpenBlob(blob, fileName + '.pdf');
              return;
            }
            const blobData = window.URL.createObjectURL(blob);
            window.open(blobData);
          } else {
            window.scroll(0, 0);
            this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'statementPdf');
          }
        },
        () => {
          window.scroll(0, 0);
          this.commonMessageService.showMessage(this.resourceBundle.genericErrorMessage, 'error', 'statementPdf');
        }
      );
  }

  //List Account call to get billing statements
  public getAccountStatements(
    after: string,
    before: string,
    feature: string,
    first: number,
    last: number,
    searchAccountGuid: string
  ) {
    this.makePaymentService
      .getAccountList(after, before, feature, first, last, searchAccountGuid)
      .pipe(
        finalize(() => {
          this.makePaymentService.contentLoaded = this.statementsLoading ? false : true;
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((results: any) => {
        if (results.data) {
          this.accountCount = results.data.listAccountsByFeature?.totalCount;
          if (this.paginationCheck) {
            let accountsList = [];
            accountsList = results.data.listAccountsByFeature?.edges ? results.data.listAccountsByFeature?.edges : [];
            this.checkPreviousPage = results.data.listAccountsByFeature?.pageInfo.hasPreviousPage;
            this.checkNextPage = results.data.listAccountsByFeature?.pageInfo.hasNextPage;
            const statementArray = [];
            if (this.initialQueryCall) {
              if (accountsList.length > 1) {
                this.billingService.isMultiAccount = true;
                this.billingService.isMultiStatement = true;
              } else if (accountsList.length === 1 && accountsList[0].node?.billStatements?.statements?.length > 1) {
                this.billingService.isMultiAccount = false;
                this.billingService.isMultiStatement = true;
              } else {
                this.billingService.isMultiAccount = false;
                this.billingService.isMultiStatement = false;
              }
            }
            if (
              (accountsList.length === 1 &&
                accountsList[0].node?.billStatements?.statements?.length === 1 &&
                this.initialQueryCall) || this.isMobileView  || this.billingService.payArrangeFlow) {
              this.isMOP = true;
              this.makePaymentService.title = this.resourceBundle.chooseMethod;
              if ((this.isMobileView || this.billingService.payArrangeFlow) && accountsList?.length > 1) {
                const obj = accountsList.find(
                  (element) => element.node.guid === this.billingService?.selectedAccountDetails?.guid
                );
                this.singleStatementObj = obj?.node;
              } else {
                this.singleStatementObj = accountsList[0]?.node;
              }
              if(this.billingService.payArrangeFlow) {
                this.singleStatementObj = {
                  ...this.singleStatementObj,
                  billStatements: {
                    ...this.singleStatementObj?.billStatements,
                    accountTotalAmtDue: this.currentStatement.totalAmountDue.toString()
                  },
                };
              } else {
                this.singleStatementObj = {
                  ...this.singleStatementObj,
                  billStatements: {
                    ...this.singleStatementObj?.billStatements,
                    accountTotalAmtDue: this.billingService.selectedStatement.value && this.isMobileView ?
                      this.billingService.selectedStatement.value.totalAmountDue.toString():
                      this.singleStatementObj?.billStatements.accountTotalAmtDue,
                  },
                };
              }
              this.billingService.isMultiAccount = false;
              this.billingService.isMultiStatement = false;
              this.makePaymentService.isSingleAccountSelected = true;
              if (!this.isMobileView) {
                this.billingService.modalRef._windowCmptRef.instance.windowClass = 'redesign-modal-md';
              }
            } else {
              const filterFormerNoStatement = accountsList.filter(
                (obj) => obj.node?.accountExternalAttrs.status !== AccountStatusEnum.FORMER_NO_STATEMENTS
              );
              accountsList.forEach((element, i) => {
                if (element.node?.accountExternalAttrs.status !== AccountStatusEnum.FORMER_NO_STATEMENTS) {
                  this.statementObj = null;
                  const serviceAddress =
                  this.cbHelperService.replaceNull(element.node?.accountExternalAttrs?.serviceAddress?.street)+
                  this.cbHelperService.replaceNull(element.node?.accountExternalAttrs?.serviceAddress?.houseNumber)+
                  this.cbHelperService.replaceNull(element.node?.accountExternalAttrs?.serviceAddress?.city)+
                  this.cbHelperService.replaceNull(element.node?.accountExternalAttrs?.serviceAddress?.state,true)+
                  this.cbHelperService.replaceNull(element.node?.accountExternalAttrs?.serviceAddress?.zipCode,true);
                  if (element.node?.billStatements?.statements?.length >= 1) {
                    element.node.billStatements.statements.forEach((statement, j) => {
                      this.statementObj = {
                        guid: element.node.guid,
                        alias: element.node.alias,
                        serviceAddress,
                        accountNumber12: element.node.accountNumber12,
                        accountName: element.node.accountName,
                        totalAmountDue: statement.totalAmountDue,
                        paymentAmount: statement.totalAmountDue,
                        dueDate: statement.dueDate,
                        nextPaymentDate: statement.nextPaymentDate,
                        identifier: statement.identifier,
                        totalPastAmountDue: statement.totalPastAmountDue,
                        name: element.node.alias,
                        code: statement.code,
                        isChecked:
                          !isEmpty(searchAccountGuid) &&
                            accountsList.length !== 1 &&
                            statement.totalAmountDue !== 0 &&
                            filterFormerNoStatement.length > 1 &&
                            statement.identifier === this.currentStatement?.identifier &&
                            statement.code === this.currentStatement?.code
                            ? true
                            : false,
                        isEdit: false,
                        cursor: element.cursor,
                        methodOfPayments: element.node.methodOfPayments,
                        isEnrolledInAutoPay: statement.isEnrolledInAutoPay,
                      };
                      if (this.statementObj.isChecked && this.makePaymentService.selectedStatements.length < 5) {
                        this.makePaymentService.selectedStatements.push(this.statementObj);
                      }
                      statementArray.push(this.statementObj);
                    });
                  } else {
                    this.statementObj = {
                      guid: element.node.guid,
                      alias: element.node.alias,
                      serviceAddress,
                      accountNumber12: element.node.accountNumber12,
                      accountName: element.node.accountName,
                      totalAmountDue: 0,
                      paymentAmount: 0,
                      dueDate: null,
                      nextPaymentDate: null,
                      identifier: null,
                      totalPastAmountDue: 0,
                      name: element.node.alias,
                      code: null,
                      isChecked: false,
                      isEdit: false,
                      cursor: element.cursor,
                      methodOfPayments: element.node.methodOfPayments,
                      isEnrolledInAutoPay: element.node.isEnrolledInAutoPay,
                    };
                  statementArray.push(this.statementObj);
                  }
                }
              });
            }
            this.displayAccountList = this.displayAccountList.concat(statementArray);
            this.totalCount = this.displayAccountList.length;
            if (this.billingView) {
              if (this.currentStatement?.totalAmountDue > 0 && this.billingService.isMultiAccount) {
                if (this.currentStatement?.totalAmountDue > 0 && this.billingService.isMultiAccount) {
                  for (let i = 0; i < this.displayAccountList.length; i++) {
                    if (
                      this.displayAccountList[i].identifier === this.currentStatement?.identifier &&
                      this.displayAccountList[i].code === this.currentStatement?.code
                    ) {
                      [this.displayAccountList[0], this.displayAccountList[i]] = [
                        this.displayAccountList[i],
                        this.displayAccountList[0],
                      ];
                    }
                  }
                }
              }
            }
            if (this.totalCount > 5) {
              this.showGradient = true;
            }
            this.displayAccountList = [...this.displayAccountList];
            this.getLastIndexValue += 10;
            this.getLastCursorValue = this.getLastIndexValue.toString();
            this.setHeaderTitle();

            this.paginationCheck = false;
            this.initialQueryCall = false;
          }
          if (this.accountCount > 10 && this.totalCount < 6 && !this.billingService.payArrangeFlow) {
            if (this.getLastIndexValue < this.accountCount) {
              this.paginationCheck = true;
              this.statementsLoading = true;
              this.getAccountStatements(this.getLastCursorValue, '', 'billing_payment', 10, 0, this.guid);
            } else {
              this.makePaymentService.contentLoaded = true;
              this.statementsLoading = false;
              this.allStatementsLoaded = true;
            }
          } else {
            this.makePaymentService.contentLoaded = true;
            this.statementsLoading = false;
            this.allStatementsLoaded = true;
          }
        }

        if (results.errors) {
          const errorInfo = this.commonMessageService.getErrorInfo(results?.errors, 'listAccounts');
          if (errorInfo) {
            window.scroll(0, 0);
            this.commonMessageService.showMessage(this.resourceBundle.errorMessage, 'error', 'makePayment');
          }
        }
      });
  }

  public onClickClose() {
    if (this.billingService.payArrangeFlow) {
      this.billingService.viewPaymentArrangements();
    }
  }

  //Set modal header based on single and multi account scenarios
  private setHeaderTitle() {
    if (
      this.billingService.isMultiAccount ||
      (!this.billingService.isMultiAccount && this.billingService.isMultiStatement)
    ) {
      this.makePaymentService.title = this.resourceBundle.multiAccountTitle;
    } else {
      this.makePaymentService.title = this.resourceBundle.chooseMethod;
    }
  }
}
