<!-- Page level Message-->
<div class="row cb-error-container" *ngIf="level === 'global' || level === 'globalPage'" 
[ngClass]="{'mt-5': isMobileDevice}">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0" [ngClass]="{'pr-0 pl-0': level === 'globalPage'}">
      <ul role="region" aria-label="alert" class="pad-left-0" [ngClass]="{'pr-1': isMobileDevice}">
        <li [type]="type" title="message" [ngClass]="{'errorMsg': type === 'error', 
        'successMsg': type === 'success', 'warningMsg': type === 'warning', 'infoMsg': type === 'info'}"
         role="alert" [innerHTML]="globalMessage"></li>
      </ul>
    </div>
</div>

<!-- Section level Message -->
<div [type]="type" *ngIf="level === 'accordion'" title="message" role="alert" class="position-relative">
  <div class="col-md-12 display-flex position-absolute section sectionLevel pl-0">
    <div class="row display-flex flex-nowrap">
    <div class="mt-2 ml-3 col-md-2 pr-0 margin_top_3em" [ngClass]="{'errorImg': type === 'error', 'successImg successMsgAccordion': type === 'success'}">
      <div class="margin-top-3em margin-left-neg-10">{{accordionTitleMsg}}
      </div>
    </div>
    <div class="ml-1 col-md-10 margin_top_3_75em" [ngClass]="{'successMsgAccordion': type === 'success'}">{{accordionMessage}}</div>
    </div>
    </div>
</div>

<!-- Datatable Row level Message -->
<div *ngIf="level === 'datatableRow'">
  <div *ngIf="type === 'error'" id="rowLevelErrorDiv" class="row cb-error-container mt-3">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
      <ul role="region" aria-label="alert" class="pad-left-0">
        <li [type]="type" title="message" [ngClass]="{'errorMsg': type === 'error'}" role="alert">{{rowLevelMessage}}
        </li>
      </ul>
    </div>
  </div>
  <div [type]="type" *ngIf="type === 'success'" id="rowLevelSuccessDiv" title="message" role="alert">
    <div class="col-md-12 display-flex section sectionLevel pl-0">
      <div class="row display-flex flex-nowrap">
        <div class="ml-3 col-md-12 pr-0" [ngClass]="{'successImg successMsgAccordion': type === 'success'}">
          <div class="margin-top-3em margin-left-neg-10">{{rowLevelMessage}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div [type]="type" *ngIf="level === 'overlaySuccessMsg'" title="message" role="alert" class="position-relative" style="z-index: 5;">
  <div class="col-md-12 display-flex right-neg-5em position-absolute section sectionLevel pl-0">
    <div class="row justify-content-center overlayMsg py-2">
      <div class="text-center" [ngClass]="{ 'overlaysuccessImg successMsgAccordion': type === 'success', 'height-200': isMobileDevice}">
        <div  [ngClass]="{'margin-top-6em': !isMobileDevice,'margin-top-8em': isMobileDevice,'': accordionTitleMsg === 'Success', 'margin-left-neg-10' : accordionTitleMsg !== 'Success'}">{{accordionTitleMsg}}</div>
      </div>
    </div>
  </div>
</div>

  <div [type]="type" class="bulletMsg errorMsg mt-1 pr-2" *ngIf="level === 'bulletErrorMsg' && listOfErrorMsg.length>0"
    [ngClass]="{'centeredErrorSymbol': verticallyCenteredErrorSymbol}">
    <span role="alert">There was a problem:</span>
    <ul class="pl-4 mb-0" type="disk">
      <li *ngFor="let error of listOfErrorMsg" role="alert" [innerHTML]="error"></li>
    </ul>
  </div>
