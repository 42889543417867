
import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appTabAccessbility]',
})
export class TabDirective implements AfterViewChecked {
  public currentTab = 0;
  public iterationStarted = false;
  constructor(private el: ElementRef) { }

  // Directive created for Tab Panel
  @HostListener('keyup', ['$event'])
  // tslint:disable-next-line: cognitive-complexity
  keyEvent(event: KeyboardEvent) {
    const navLinkElements = this.el.nativeElement.querySelectorAll('.nav-link');
    const navItemElements = this.el.nativeElement.querySelectorAll('.nav-item');
    const countTab = navItemElements?.length;
    navItemElements.forEach(element => {
      element.setAttribute('role','presentation');
    });
    if (event.key === 'Enter' && countTab>0) {
      for (let j = 0; j < countTab; j++) {
        navLinkElements[j].tabIndex = '-1';
      }
      document.activeElement.setAttribute('tabindex', '0');
    }
    this.iterationStarted = true;
    if (event.key === 'Tab' && this.el.nativeElement.classList.contains('nav') && countTab>0) {
      for (let i = 0; i < countTab; i++) {
        if (navLinkElements[i].classList.contains('active')) {
          if(this.el.nativeElement?.nextElementSibling?.firstElementChild) {
            this.el.nativeElement.nextElementSibling.firstElementChild.tabIndex = '0';
          } else {
            navLinkElements[i].removeAttribute('aria-controls');
          }
          for (let j = 0; j < countTab; j++) {
            navLinkElements[j].tabIndex = '-1';
          }
          navLinkElements[i].tabIndex = '0';
        }
      }
    }
    if (event.shiftKey && event.key === 'Tab' && countTab>0) {
      for (let i = 0; i < countTab; i++) {
        if (navLinkElements[i].classList.contains('active')) {
          navLinkElements[i].focus();
        }
      }
    }
  }
  ngAfterViewChecked(): void {
    if(this.el.nativeElement?.children[0]?.children[0] &&
      (!this.iterationStarted || document.activeElement.classList.contains('nav-link active')) ) {
      const navLinkElements = this.el.nativeElement.querySelectorAll('.nav-link');
      const navItemElements = this.el.nativeElement.querySelectorAll('.nav-item');
      navItemElements?.forEach(element => {
        element.setAttribute('role','presentation');
      });
      navLinkElements?.forEach(element => {
        if (element.classList.contains('active')) {
          const id = element.getAttribute('aria-controls');
          if(!document.getElementById(id)) {
            element.removeAttribute('aria-controls');
          }
        }
      });
    }
  }
}
