/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */

import { Cluster } from '@googlemaps/markerclusterer';
import { MicroserviceResponse } from 'common-ui-lib';

export interface DigitalProfile extends MicroserviceResponse {
    serviceLocations: ServiceLocations[];
    profileGuid: string;
    companyProfileId: string;
    profileName: string;
    profileOwnerId: string;
    status: string;
    mfaEnabled: boolean;
    isHNProfile: boolean;
    unplannedOutageIndicator: boolean;
    profileType: string;
    isSpmFlag: boolean;
    enterpriseCustomer: boolean;
}
export interface MapDetails {
    serviceLocations: ServiceLocations[];
    lastFetchedDateTime: number;
    uuid: string;
}

export interface ServiceLocations {
    serviceLocationId: string;
    saSfdcId: string;
    name: string;
    baSfdcId: string;
    address: Address;
    assets?: Asset[];
    billingAccounts: BillingAccount[];
    outages?: Outage;
    profileGuid: string;
    status: string;
    multiSuite?: boolean;
    marker?: google.maps.Marker;
    outageDetails?: OutageInfoDetails;
    singleSuiteMarker?: Cluster;
    clusterMarker?: ClusterMarkerDetails;
    markerIconType?: OutageType;
}
export interface Address {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    zipExtension: string;
    latitude: string;
    longitude: string;
    fullAddress: string;
    titleCaseAddress?: string;
}

export interface Asset {
    assetType: AssetType;
    id: string;
    status: string;
    assetFamily: AssetFamily;
    assetName: string;
}

export interface BillingAccount {
    accountGuid: string;
    billingAccountNumber: string;
}

export interface Outage {
    outageDetails: OutageDetail[];
    saSfdcId: string;
}

export interface OutageDetail {
    outageType: OutageType;
    outageDate: number;
    etr: number;
    eventTypeGroup: EventTypeGroup;
    notificationFlag: boolean;
    phoneNumber: string;
    symptoms: string;
    message: string;
    status: string;
}

export interface OutageNotificationInfo {
    clicked: boolean;
    outageType: OutageType;
}

export interface OutageInfoDetails {
    type: string;
    imgUrl: string;
    imgAlt: string;
    header: string;
    subHeader: string;
    link: string;
    label: string;
    etr?: string;
    outageDate?: string;
    notificationFlag?: boolean;
    chronicOutage?: boolean;
    enrolledMobileNumber?: string;
}

export interface ClusterMarkerDetails {
    clusterCount: string;
    clusterMarkerUrl: string;
}

// eslint-disable-next-line no-shadow
export enum MarkerIcon {
    ACTIVE_MARKER = 'assets/images/blueprint/digitalprofile/map-pointer-tag.svg',
    ACTIVE_PIN = 'assets/images/blueprint/digitalprofile/map-location-tags.svg',
    UNPLANNED_MARKER = 'assets/images/blueprint/digitalprofile/map-pointer-tag-red.svg',
    UNPLANNED_PIN = 'assets/images/blueprint/digitalprofile/map-location-tags-red-glow.svg',
    PLANNED_MARKER = 'assets/images/blueprint/digitalprofile/map-pointer-tag-amber.svg',
    PLANNED_PIN = 'assets/images/blueprint/digitalprofile/map-location-tags-amber.svg',
    HEALTHCHECK_MARKER = 'assets/images/blueprint/digitalprofile/map-pointer-healthcheck.svg',
    HEALTHCHECK_PIN = 'assets/images/blueprint/digitalprofile/map-location-tags-healthcheck.svg',
    ACTIVE_CLUSTER = 'assets/images/blueprint/digitalprofile/map-pointer-tag.svg',
    UNPLANNED_CLUSTER = 'assets/images/blueprint/digitalprofile/map-pointer-red-dot.svg',
    PLANNED_CLUSTER = 'assets/images/blueprint/digitalprofile/map-pointer-amber-dot.svg',
    HEALTHCHECK_CLUSTER = 'assets/images/blueprint/digitalprofile/map-pointer-healthcheck.svg',
}

export const US_BOUNDS = {
    north: 49.38,
    south: 23.82,
    west: -124.39,
    east: -66.94
};

export enum AssetType {
    SERVICES = 'Services'
}
export enum AssetFamily {
    VOICE = 'Voice',
    INTERNET = 'Internet',
    SECURITY = 'Security',
    PHONE = 'Phone',
    TV = 'TV',
    NETWORKING = 'Networking'
}

export enum OutageType {
    PLANNED = 'PLANNED',
    UNPLANNED = 'UNPLANNED',
    NOOUTAGE = 'ACTIVE',
    HEALTHCHECK = 'HEALTHCHECK'
}

export enum OutageSearchType {
    UNPLANNED = 'Network Outage',
    PLANNED = 'Upcoming Maintenance',
}

export enum EventTypeGroup {
    BCP = 'BCP',
    TRENDING = 'TRENDING',
    CHRONIC = 'CHRONIC',
    UNPLANNED = 'UNPLANNED',
    MAINTENANCE = 'MAINTENANCE',
    NODEHEALTH = 'NODEHEALTH',
    NOOUTAGE = 'NOOUTAGE',
    PLANNEDMAINTENANCE = 'PLANNEDMAINTENANCE',
    MIDSPLIT = 'MIDSPLIT',
    HFC = 'HFC',
    NPLUSO = 'NPLUSO'
}

export enum Status {
    INPROGRESS = 'Inprogress',
    UPCOMING = 'Upcoming'
}

export enum FilterParentCategory {
    SERVICES = 'Services',
    NETWORK_HEALTH = 'Network Health'
}

export interface ServiceLocationOutageInput {
    phoneNumber: string;
    saSfdcId: string[];
    saveNumber: boolean;
}
