import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  AEMClientService,
  AppConfig,
  CBHelperService,
  DigitalProfileSharedService,
  ErrorMessageService,
  LocalStorageKeys,
  ResourceBundle,
  Role,
  SSOService,
  TealiumUtagService,
  UserType,
} from 'common-ui-lib';
import { SSOPageName } from 'common-ui-lib/lib/constants/ssopagename.model';
import { DeviceDetectorService } from 'common-ui-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountInfo, Address } from '../pageheader/pageheader.model';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ServiceDetailsService } from './service-details/service-details.service';
import { BroadbandFactLabelService }
  from 'common-ui-lib/lib/shared/redesign/sublanding-page-description/broadbandfactlabel/broadbandfactlabel.service';
import { FeatureName } from './sublanding-page-description.model';
import { MyaccountHomeService } from 'home/myaccounthome.service';
import { LocalStorageService } from 'ngx-localstorage';
import { BroadbandFactLabelComponent }
  from 'common-ui-lib/lib/shared/redesign/sublanding-page-description/broadbandfactlabel/broadbandfactlabel.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-sublanding-page-description',
  templateUrl: './sublanding-page-description.component.html',
  styleUrls: ['./sublanding-page-description.component.scss'],
})
export class SubLandingPageDescriptionComponent implements OnInit {
  @ViewChild('selectServiceModal') public selectServiceModal: NgbModalRef;
  @Input() featureName: FeatureName;
  @Input() selectedAccInfo: AccountInfo;
  @Input() accountList = [];
  @Input() isAuthorizedUser: boolean;
  @Input() hideUpgradeServiceLink = false;
  public description: string;
  public isMultiAccount: boolean;
  public accountAddress: Address;
  public isChecked = false;
  public guid = null;
  public isEU: boolean;
  public hasOrderReplacementRole: boolean;
  public resourceBundle: ResourceBundle = {};
  public isFeatureUpgradeVisible: boolean;
  public isBroadbandFacts = false;
  private onDestroy$ = new Subject<boolean>();
  private accountSelectedOnLoad = false;

  constructor(
    public aemClient: AEMClientService,
    public deviceService: DeviceDetectorService,
    private modalService: NgbModal,
    public serviceDetails: ServiceDetailsService,
    public broadbandFactLabelService: BroadbandFactLabelService,
    private router: Router,
    private cbHelperService: CBHelperService,
    private myaccountHomeService: MyaccountHomeService,
    private ssoService: SSOService,
    public errorMessageService: ErrorMessageService,
    private _storageService: LocalStorageService,
    public tealium: TealiumUtagService,
    public dashboardSharedServices: DigitalProfileSharedService
  ) {}

  ngOnInit() {
    if (this._storageService.get(LocalStorageKeys.SelectedAccount, 'cbma')) {
      this.isChecked = true;
      this.accountSelectedOnLoad = true;
      if (this.selectedAccInfo) {
        this.guid = this.selectedAccInfo.guid;
        this.accountAddress = this.selectedAccInfo.accountExternalAttrs.serviceAddress;
      }
    }
    this.isMultiAccount = this.cbHelperService.getLoggedInUserDetails().profile.userToken.multiAccount;
    this.aemClient
      .getRedesignBundle('malp/sublanding')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
          this.resourceBundle = data;
          this.getFeatureDescription(this.featureName);
          this.isFeatureUpgradeVisible = this.dashboardSharedServices.isAddOnAvailable(this.featureName);
        }
      );
    this.isEU = this.myaccountHomeService.getProfile().userToken.userType === UserType.END_USER;
    this.hasOrderReplacementRole = this.cbHelperService
      .getLoggedInUserDetails()
      .profile.roles.includes(Role.BUSINESS_TV_VIDEO_RPLCMNT_ROLE)
      ? true
      : false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedAccount(event: any) {
    this.accountAddress = event.accountExternalAttrs.serviceAddress;
    this.guid = event.guid;
    this.selectedAccInfo = event;
  }

  public getFeatureDescription(featureName) {
    switch (featureName) {
      case FeatureName.APPS:
        this.description = this.resourceBundle.appsDesc;
        break;

      case FeatureName.INTERNET:
        this.description = this.resourceBundle.dataToolDesc;
        break;

      case FeatureName.NETWORKING:
        this.description = this.resourceBundle.networkingDesc;
        break;

      case FeatureName.SECURITY:
        this.description = this.resourceBundle.securityDesc;
        break;

      case FeatureName.TV:
        this.description = this.resourceBundle.tvDesc;
        break;

      case FeatureName.VOICE:
        this.description = this.resourceBundle.voiceDesc;
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public get FeatureName() {
    return FeatureName;
  }

  public sulaModal() {
    let modalRef: NgbModalRef;
    if(!this.isBroadbandFacts){
      modalRef = this.modalService.open(ServiceDetailsComponent, {
        windowClass: 'modal-lg-363px',
        backdrop: 'static',
      });
    }else{
      modalRef = this.modalService.open(BroadbandFactLabelComponent,
        {
          backdrop: 'static',
          windowClass: this.deviceService.isMobile()?'redesign-modal-md':'redesign-modal-lg',
        });
    }
    modalRef.componentInstance.sulaFeatureName = this.featureName;
    modalRef.componentInstance.selectedAccInfo = this.selectedAccInfo;
  }

  public openSulaModal() {
    if (this.isMultiAccount && this.accountList.length >1) {
      const modalRef = this.modalService.open(this.selectServiceModal, {
        windowClass: 'modal-lg-wid45',
        backdrop: 'static',
      });
    } else {
      this.sulaModal();
    }
  }

  openBroadbandFactLabelModal(){
    this.isBroadbandFacts = true;
    this.openSulaModal();
    this.triggerTealium(this.resourceBundle.broadbandFactlabelLinkText);
  }

  openServiceDetailsComponent(){
    this.isBroadbandFacts = false;
    this.openSulaModal();
    this.triggerTealium(this.resourceBundle.serviceDetailsLink);
  }
  public redirectToSchedule() {
    this.router.navigate(['/voice/userandsysmanagement/schedules']);
  }

  public videoRplcmntVisibility() {
    if (this.isAuthorizedUser && this.hasOrderReplacementRole && !this.cbHelperService.isMyAdmin() && !this.isEU) {
      return true;
    } else {
      return false;
    }
  }

  public async generateSSOUrl() {
    const accountNumber12 = this.selectedAccInfo.accountNumber12;
    try {
      const data = await this.ssoService.cbForceSSO(SSOPageName.VIDEOPART_CBFORCE, 'Add', accountNumber12);
    } catch (error) {
      this.genericErrorMessage();
    }
  }

  public triggerTealium(eventName: string){
    this.tealium.button(eventName, eventName);
  }

  private genericErrorMessage() {
    window.scrollTo(0, 0);
    this.errorMessageService.errorMessage.show = true;
    this.errorMessageService.errorMessage.type = 'error';
    this.errorMessageService.errorMessage.level = 'global';
    this.errorMessageService.errorMessage.message = this.resourceBundle.genericErrorMessage;
  }

}
