import { Directive, ElementRef, HostListener, Input, OnInit, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[appUiSwitchLabel]',
})
export class UiSwitchDirective implements OnInit, AfterViewChecked {
  @Input() uiSwitchAriaLabel: string;
  constructor(private el: ElementRef) {
  }

  @HostListener('window:keyup', ['$event'])
  @HostListener('window:keydown', ['$event'])
  @HostListener('focusin', ['$event'])
  @HostListener('click', ['$event'])
  // tslint:disable-next-line: cognitive-complexity
  keyEvent(event: KeyboardEvent) {
    if(event.key === 'Tab' || (event.shiftKey && event.key === 'Tab')){
      if(this.el.nativeElement?.ariaHidden === 'true'){
        this.el.nativeElement.firstElementChild.toggleAttribute('disabled');
      }else{
        this.el.nativeElement.firstElementChild.ariaLabel= this.uiSwitchAriaLabel;
      }
      if(this.el.nativeElement.firstElementChild.classList.contains('disabled') ){
        this.el.nativeElement.firstElementChild.ariaHidden = 'true';
        this.el.nativeElement.firstElementChild.tabIndex = '-1';
      }
    }
  }

  ngOnInit() {

  }

  ngAfterViewChecked(){
    this.el.nativeElement.firstElementChild.ariaLabel= this.uiSwitchAriaLabel;
  }
}
