<!-- Desktop Search Views start -->
<ng-container *ngIf="!isMobileDevice && !isTabletDevice; else mobileTabletViewSearch">
  <div class="search" *ngIf="!isDatatable && !msTeamsSearch && !singlePayerSearch && !voiceEncryptionSearch"
    [@detailExpand]="expand" id="typeAhead">
    <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
      aria-hidden="true" aria-autocomplete="true" (keyup)="searchQueryOnDataSource($event)" [placeholder]="placeholder"
      [@detailExpand]="inputCollapsed" (keydown)="outOfSearchList($event,'filterInput')" />
  </div>
  <div class="search search-spm margin-right-neg-80" *ngIf="voiceEncryptionSearch"
    [@detailExpandVoiceEncryptionSearch]="expand">
    <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
      aria-hidden="true" aria-autocomplete="true" (keyup)="searchQueryOnDataSource($event)" [placeholder]="placeholder"
      [@detailExpandVoiceEncryptionSearch]="inputCollapsed" (keydown)="outOfSearchList($event,'filterInput')" />
  </div>
  <div class="search liveSearch" *ngIf="isDatatable" [@detailExpandLiveSearch]="expand">
    <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
      aria-hidden="true" aria-autocomplete="true" (keyup)='searchQueryOnDataSource($event)' [placeholder]="placeholder"
      [@detailExpandLiveSearch]="inputCollapsed" (keydown)="outOfSearchList($event,'filterInput')" />
  </div>
</ng-container>
<!-- Desktop Search Views end -->

<!-- Mobile/Table Search Views start -->
<ng-template #mobileTabletViewSearch>
  <ng-container *ngIf="isMobileDevice; else tabletViewSearch">
    <div class="search" [ngClass]="{'msTeamsSearchText': msTeamsSearch}" *ngIf="!voiceEncryptionSearch && !isZoomed400"
      [@detailExpandMobile]="expand">
      <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
        aria-hidden="true" aria-autocomplete="true" (keyup)='searchQueryOnDataSource($event)'
        [placeholder]="placeholder" [@detailExpandMobile]="inputCollapsed"
        (keydown)="outOfSearchList($event,'filterInput')" />
    </div>
    <div class="search" [ngClass]="{'msTeamsSearchText': msTeamsSearch}" *ngIf="!voiceEncryptionSearch && isZoomed400"
      [@detailExpandZoomedView]="expand">
      <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
        aria-hidden="true" aria-autocomplete="true" (keyup)='searchQueryOnDataSource($event)'
        [placeholder]="placeholder" [@detailExpandZoomedView]="inputCollapsed"
        (keydown)="outOfSearchList($event,'filterInput')" />
    </div>
    <div class="search search_form voiceEncryptionSearchMobileSearch" *ngIf="voiceEncryptionSearch"
      [@detailExpandVoiceEncryptionSearchMobile]="expand">
      <input #filterInput id="filterInput" type="text" maxlength="20" tabindex="-1" aria-hidden="true"
        class="voiceEncryptionSearchMobileInput" (keyup)="searchQueryOnDataSource($event)"
        [placeholder]="placeholder == undefined ? '' : placeholder"
        [@detailExpandVoiceEncryptionSearchMobile]="inputCollapsed" />
    </div>
  </ng-container>
  <!-- Tablet View Start -->
  <ng-template #tabletViewSearch>
    <div class="search" *ngIf="!singlePayerSearch && !voiceEncryptionSearch && !msTeamsSearch" [@detailExpandTablet]="expand">
      <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
        aria-hidden="true" aria-autocomplete="true" (keyup)='searchQueryOnDataSource($event)'
        [placeholder]="placeholder" [@detailExpandTablet]="inputCollapsed"
        (keydown)="outOfSearchList($event,'filterInput')" />
    </div>
    <div class="search voiceEncTablet" *ngIf="voiceEncryptionSearch"
      [@detailExpandVoiceEncryptionSearchTablet]="expand">
      <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
        aria-hidden="true" aria-autocomplete="true" (keyup)='searchQueryOnDataSource($event)'
        [placeholder]="placeholder" [@detailExpandVoiceEncryptionSearchTablet]="inputCollapsed"
        (keydown)="outOfSearchList($event,'filterInput')" />
    </div>
  </ng-template>
  <!-- Tablet View End -->
</ng-template>
<!-- Mobile/Table Search Views end -->

<!-- Single Payer Search Desktop/Tablet View Start -->
<div class="search search-spm margin-right-neg-80 singlePayerSearchInput" *ngIf="singlePayerSearch && !isMobileDevice"
  [@detailExpandSinglePayerSearch]="expand">
  <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
    aria-hidden="true" aria-autocomplete="true" (keyup)="searchQueryOnDataSource($event)" [placeholder]="placeholder"
    [@detailExpandSinglePayerSearch]="inputCollapsed" (keydown)="outOfSearchList($event, 'filterInput')" />
</div>
<!-- Single Payer Search Desktop/Tablet View End -->

<!-- MS Teams Search View Start -->
<div class="search liveSearch" [@detailExpandMsTeamsSearch]="expand" *ngIf="msTeamsSearch && !isMobileDevice">
  <input #filterInput id="filterInput" type="text" maxlength="20" [(ngModel)]="searchValue" tabindex="-1"
    aria-hidden="true" aria-autocomplete="true" (keyup)='searchQueryOnDataSource($event)'
    [placeholder]="placeholder" [@detailExpandMsTeamsSearch]="inputCollapsed"
    (keydown)="outOfSearchList($event,'filterInput')" />
</div>
<!-- MS Teams Search View End -->

<!-- Search and Clear Icon -->
<ng-container *ngIf="showIcons">
  <div id="search-icon"
    [ngClass]="{'liveSearch' : !isMobileDevice && !isTabletDevice && isDatatable , 'teamsSearch' : msTeamsSearch, 'singlePayerSearch': singlePayerSearch, 'voiceEncryptionSearch': voiceEncryptionSearch && !isMobileDevice && !isTabletDevice, 'voiceEncryptionSearchMobile': voiceEncryptionSearch && isMobileDevice, 'voiceEncryptionSearchTablet': voiceEncryptionSearch && isTabletDevice}">
    <img src='assets/images/blueprint/magnifying-glass.svg' alt="search" role="presentation">
  </div>
  <button id="search-clear" tabindex="0" class="row" aria-label="Clear Search Box" (click)="clearSearchBox()"
    [ngClass]="{'liveSearch' : !isMobileDevice && !isTabletDevice && isDatatable, 'clearTeamsSearch' : msTeamsSearch,
    'clearSearchSPMBtn': singlePayerSearch, 'clearSearchVoiceEncBtn': voiceEncryptionSearch && !isMobileDevice && !isTabletDevice,
    'clearSearchVoiceEncBtnMobile': voiceEncryptionSearch && isMobileDevice, 'clearSearchVoiceEncBtnTablet': voiceEncryptionSearch && isTabletDevice}" (keydown)="outOfSearchList($event,'search-clear')">
    <img src='assets/images/blueprint/close-black.svg' [ngClass]="{'clearSearchSPM': singlePayerSearch}"
      alt="clear search" role="presentation">
  </button>
  <ng-container *ngIf="!isDatatable && !msTeamsSearch">
    <ng-template [ngTemplateOutlet]="searchView"></ng-template>
  </ng-container>
</ng-container>

<!-- MS Teams Search bottom Text -->
<div *ngIf="msTeamsSearch && !isMobileDevice" [@detailExpandMsTeamsSearch]="expand" [@detailExpandMsTeamsSearch]="inputCollapsed" class="searchText">
  Search by phone number, username, or email address.
</div>
<div *ngIf="msTeamsSearch && isMobileDevice" [@detailExpandMobile]="expand" [@detailExpandMobile]="inputCollapsed" class="searchText">
  Search by phone number, username, or email address.
</div>

<!-- Common view template for search -->
<ng-template #searchView>
  <div [ngClass]="resultDataList? 'autocomplete' : ''">
    <ng-container *ngIf="resultDataList && searchValue?.length!= 0">
      <div role="alert" class="searchResult text-center"
        *ngIf="searchValue?.length >= 1 && resultDataList?.length < 10">
        Search Results</div>
      <div role="alert" class="searchResult text-center"
        *ngIf="resultDataList?.length >= 10 && searchValue?.length >= 1" (keydown)="outOfSearchList($event)">
        Your current search contains a large number of results. Continue typing to refine your search</div>
      <ul #filterList class="sub-list">
        <li tabindex="0" role="alert"
          *ngIf="resultDataList?.length === 0 && searchValue?.length >= 1 && searchList === 'userList'"
          class="noSearchData pl-3" (keydown)="outOfSearchList($event)">There are no users
          that match the search criteria</li>
        <li tabindex="0" role="alert"
          *ngIf="resultDataList?.length === 0 && searchValue?.length >= 1 && searchList === 'accountList'"
          class="noSearchData pl-3" (keydown)="outOfSearchList($event)">There are no accounts that match the search
          criteria
        </li>
        <li tabindex="0" *ngFor="let result of resultDataList" (click)="selectedResult(result)"
          (keydown)="outOfSearchList($event)">
          <a *ngIf="searchList === 'userList' && result?.phoneNumber !=null && !result?.phoneNumber.includes('undefined')"
            class="ml-2 listValue">{{result?.name}} |
            {{result?.email}} |
            {{result?.phoneNumber}}
          </a>
          <a *ngIf="searchList === 'userList' && (result?.phoneNumber == null || result?.phoneNumber.includes('undefined')) && result?.name != null"
            class="ml-2 listValue">{{result?.name}} |
            {{result?.email}}
          </a>
          <a *ngIf="searchList === 'accountList' && result?.accountAlias !== null && result?.accountServiceAddress !== null && result?.accountNumber12 !== null"
            class="ml-2 mr-2 listValue notifyMessage text-ellipsis">
            {{result?.accountAlias}} | {{result?.accountNumber12}} | {{result?.accountServiceAddress}}</a>
        </li>
      </ul>
    </ng-container>
  </div>
</ng-template>