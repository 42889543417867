import { Injectable } from '@angular/core';
import { SSOPageName } from '../constants/ssopagename.model';
import { AppConfig } from '../core/app.config';
import { CBHelperService } from './cbhelper.service';
import { CoxHttpClient, MicroserviceResponse } from './coxhttpclient.service';

@Injectable()
export class SSOService {
    constructor(private coxHttp: CoxHttpClient,
        private cbHelperService: CBHelperService,
        private config: AppConfig,
    ) { }

    public async getSSOService(pageName: string, accountNumber?: string) {
        const guid = await this.cbHelperService.getGuid();
        switch (pageName) {
            case SSOPageName.UNIFIED_WIFI_PORTAL:
                return this.getHttp(pageName, guid);
            case SSOPageName.TOLLFREE:
                return this.getHttp(pageName, guid);
            case SSOPageName.SMART_BILL:
                return this.getHttp(pageName, guid);
            case SSOPageName.IBILL:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.SDWAN:
                return this.getHttp(pageName, guid);
            case SSOPageName.ALARM:
                return this.getHttp(pageName, guid);
            case SSOPageName.AKAMAI_BASIC:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.AKAMAI_PREMIUM:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.MANAGED_ROUTER_SECURITY:
                return this.getHttp(pageName, guid);
            case SSOPageName.RAPID_SCALE:
                return this.getHttp(pageName, guid);
            case SSOPageName.CB_CORE:
                return this.getHttp(pageName, guid);
            case SSOPageName.RSSDWAN3:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.VIDEOPART_CBFORCE:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.ENDPOINT_PROTECT:
                return this.getHttp(pageName, guid);
            case SSOPageName.UNIFIED_WIFI_PORTAL_HN:
                return this.getHttp(pageName, guid);
            case SSOPageName.VIDEO_CBFORCE:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.VSB_APPDIRECT:
                return this.getHttp(pageName, guid);
            case SSOPageName.VOICE_CBFORCE:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.INTERNET_CBFORCE:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.IBILL:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.SBWG:
                return this.getHttp(pageName, guid, accountNumber);
            case SSOPageName.SYMPHONY_CBFORCE:
                return this.getHttp(pageName, guid);
            case SSOPageName.CBCCOPTIN_CBFORCE:
                return this.getHttp(pageName, guid, accountNumber);
        }
    }

    public async cbForceSSO(pageName: string, buyFlow: string, accountNumber?: string) {
        if (this, this.cbHelperService.isCbForceMaintenance) {
            window.open(this.config.getConfig('SSO')['PI9']['getCbForceMaintenanceUrl'], '_blank');
        } else {
            const guid = await this.cbHelperService.getGuid();
            const data = await this.getHttp(pageName, guid, accountNumber);
            if (data.code === '0') {
                if (buyFlow === 'Add') {
                    window.open(this.config.getConfig('SSO')['PI9']['getCbForceAddUrl'], '_blank');
                } else {
                    window.open(this.config.getConfig('SSO')['PI9']['getCbForceUpdateUrl'], '_blank');
                }
            } else {
                throw new Error('samlpersistence_fail');
            }
        }
    }


    private async getHttp(pageName: string, guid: string, accNumber?: string) {
        const url = '/api/cbma/identityservice/services/identity/sso/samlpersistence';
        let data = {};
        if (accNumber) {
            data = {
                pageId: pageName,
                userGuid: guid,
                accountNumber: accNumber
            };
        } else {
            data = {
                pageId: pageName,
                userGuid: guid
            };
        }
        return await this.coxHttp.postAsync<MicroserviceResponse>(url, data);
    }
}
