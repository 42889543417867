<div aria-label="Footer" role="application" id="cbDefaultFooter">
    <div class="top-footer">
        <div class="col-12 top-footer-content px-4 pt-4" [ngClass]="{'pb-4': deviceService.isMobile()}">
            <a href="https://www.cox.com/business/contact-us.html" class="mr-5" tabindex="0">
                <div class="display-flex">
                    <div class="mr-2 footer-item">
                        <img src="assets/images/blueprint/admin_redesign/icon-phone-solutions-contact-white.png"
                            alt="Call 1-888-876-3882 for Support and Service" role="presentation">
                    </div>
                    <div>
                        <div class="margin-bottom-15px">{{resourceBundle?.contactCustomerService}}</div>
                        <span class="footer-number">{{contactCustomerNumber}}</span>
                    </div>
                </div>
            </a>
            <a href="https://www.cox.com/business/contact-us.html" class="mr-5">
                <div class="display-flex">
                    <div class="mr-2 footer-item">
                        <img src="assets/images/blueprint/admin_redesign/icon-support-white.png" alt="Chat Now"
                        role="presentation">
                    </div>
                    <div>
                        <div class="margin-bottom-15px">{{resourceBundle?.contactUs}}</div>
                        <span class="footer-number">{{resourceBundle?.chatNow}}</span>
                    </div>
                </div>
            </a>
            <a href="https://www.cox.com/business/contact-us.html">
                <div class="display-flex">
                    <div class="mr-2 footer-item">
                        <img src="assets/images/blueprint/admin_redesign/icon-phone-solutions-contact-white.png"
                            alt="Call 1-844-201-8018 for Support and Service" role="presentation">
                    </div>
                    <div>
                        <div class="margin-bottom-15px">{{resourceBundle?.contactSales}}</div>
                        <span class="footer-number">{{contactSalesNumber}}</span>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="defaultFooter-gradientline position-relative"></div>
    <div class="bottom-footer position-relative" [ngClass]="{'myaccount-footer' : !cbHelper.isMyAdmin}">
        <div class="col-12 bottom-footer-content p-0">
            <div class="footer-links">
                <ul class="m-0 p-0">
                    <li *ngIf="commonTaskPopoverList?.length">
                        <a href="javascript:void(0)" aria-label="Common Tasks" [ngbPopover]="footerPopover"
                            [placement]="'top'" #footerLinkPopover="ngbPopover" role="button" [autoClose]="true"
                            class="footer-sublinks" popoverClass="defaultFooterPopover" aria-haspopup="menu"
                            (hidden)="isPopoverOpen(false, 'CommonTasks')"
                            (shown)="isPopoverOpen(true, 'CommonTasks')">
                            <span aria-hidden="true">{{resourceBundle?.commonTasksTitle}}</span>
                            <img *ngIf="imageToggle !== 'openCommonTasks'"
                                src="assets/images/blueprint/admin_redesign/arrow-up-small-black.svg" class="mb-1"
                                alt="up arrow" role="presentation">
                            <img *ngIf="imageToggle === 'openCommonTasks'"
                                src="assets/images/blueprint/admin_redesign/arrow-down-small-black.svg" class="mb-1"
                                alt="down arrow" role="presentation">
                                <span class="sr-only" aria-live="polite">{{resourceBundle?.commonTasksTitle}}
                                    {{isPopoverListOpen ? 'expanded' : 'collapsed'}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" aria-label="Help" [ngbPopover]="footerPopover" [placement]="'top'"
                            #footerLinkPopover="ngbPopover" role="button" [autoClose]="true" class="footer-sublinks"
                            popoverClass="defaultFooterPopover" aria-haspopup="menu" (hidden)="isPopoverOpen(false, 'Help')"
                            (shown)="isPopoverOpen(true, 'Help')">
                            <span aria-hidden="true">{{resourceBundle?.helpTitle}}</span>
                            <img *ngIf="imageToggle !== 'openHelp'"
                                src="assets/images/blueprint/admin_redesign/arrow-up-small-black.svg" class="mb-1"
                                alt="up arrow" role="presentation">
                            <img *ngIf="imageToggle === 'openHelp'"
                                src="assets/images/blueprint/admin_redesign/arrow-down-small-black.svg" class="mb-1"
                                alt="down arrow" role="presentation">
                                <span class="sr-only" aria-live="polite">{{resourceBundle?.helpTitle}}
                                    {{isPopoverListOpen ? 'expanded' : 'collapsed'}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" aria-label="About Us" [ngbPopover]="footerPopover"
                            [placement]="'top'" #footerLinkPopover="ngbPopover" role="button" [autoClose]="true"
                            class="footer-sublinks" popoverClass="defaultFooterPopover" aria-haspopup="menu"
                            (hidden)="isPopoverOpen(false, 'AboutUs')"
                            (shown)="isPopoverOpen(true, 'AboutUs')">
                            <span aria-hidden="true">{{resourceBundle?.aboutUsTitle}}</span>
                            <img *ngIf="imageToggle !== 'openAboutUs'"
                                src="assets/images/blueprint/admin_redesign/arrow-up-small-black.svg" class="mb-1"
                                alt="up arrow" role="presentation">
                            <img *ngIf="imageToggle === 'openAboutUs'"
                                src="assets/images/blueprint/admin_redesign/arrow-down-small-black.svg" class="mb-1"
                                alt="down arrow" role="presentation">
                                <span class="sr-only" aria-live="polite">{{resourceBundle?.aboutUsTitle}}
                                    {{isPopoverListOpen ? 'expanded' : 'collapsed'}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" aria-label="Social" [ngbPopover]="footerPopover"
                            [placement]="'top'" #footerLinkPopover="ngbPopover" role="button" [autoClose]="true"
                            class="footer-sublinks" popoverClass="defaultFooterPopover" aria-haspopup="menu"
                            (hidden)="isPopoverOpen(false, 'Social')"
                            (shown)="isPopoverOpen(true, 'Social')">
                            <span aria-hidden="true">{{resourceBundle?.socialTitle}}</span>
                            <img *ngIf="imageToggle !== 'openSocial'"
                                src="assets/images/blueprint/admin_redesign/arrow-up-small-black.svg" class="mb-1"
                                alt="up arrow" role="presentation">
                            <img *ngIf="imageToggle === 'openSocial'"
                                src="assets/images/blueprint/admin_redesign/arrow-down-small-black.svg" class="mb-1"
                                alt="down arrow" role="presentation">
                                <span class="sr-only" aria-live="polite">{{resourceBundle?.socialTitle}}
                                    {{isPopoverListOpen ? 'expanded' : 'collapsed'}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" aria-label="Legal" [ngbPopover]="footerPopover" [placement]="'top'"
                            #footerLinkPopover="ngbPopover" role="button" [autoClose]="true" class="footer-sublinks"
                            popoverClass="defaultFooterPopover" aria-haspopup="menu" (hidden)="isPopoverOpen(false, 'Legal')"
                            (shown)="isPopoverOpen(true, 'Legal')">
                            <span aria-hidden="true">{{resourceBundle?.legalTitle}}</span>
                            <img *ngIf="imageToggle !== 'openLegal'"
                                src="assets/images/blueprint/admin_redesign/arrow-up-small-black.svg" class="mb-1"
                                alt="up arrow" role="presentation">
                            <img *ngIf="imageToggle === 'openLegal'"
                                src="assets/images/blueprint/admin_redesign/arrow-down-small-black.svg" class="mb-1"
                                alt="down arrow" role="presentation">
                                <span class="sr-only" aria-live="polite">{{resourceBundle?.legalTitle}}
                                    {{isPopoverListOpen ? 'expanded' : 'collapsed'}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" aria-label="Companies" [ngbPopover]="footerPopover"
                            [placement]="'top'" #footerLinkPopover="ngbPopover" role="button" [autoClose]="true"
                            class="footer-sublinks" popoverClass="defaultFooterPopover" aria-haspopup="menu"
                            (hidden)="isPopoverOpen(false, 'Companies')"
                            (shown)="isPopoverOpen(true, 'Companies')">
                            <span aria-hidden="true">{{resourceBundle?.companiesTitle}}</span>
                            <img *ngIf="imageToggle !== 'openCompanies'"
                                src="assets/images/blueprint/admin_redesign/arrow-up-small-black.svg" class="mb-1"
                                alt="up arrow" role="presentation">
                            <img *ngIf="imageToggle === 'openCompanies'"
                                src="assets/images/blueprint/admin_redesign/arrow-down-small-black.svg" class="mb-1"
                                alt="down arrow" role="presentation">
                                <span class="sr-only" aria-live="polite">{{resourceBundle?.companiesTitle}}
                                    {{isPopoverListOpen ? 'expanded' : 'collapsed'}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" aria-label="Partners" [ngbPopover]="footerPopover"
                            [placement]="'top'" #footerLinkPopover="ngbPopover" role="button" [autoClose]="true"
                            class="footer-sublinks" popoverClass="defaultFooterPopover" aria-haspopup="menu"
                            (hidden)="isPopoverOpen(false, 'Partners')"
                            (shown)="isPopoverOpen(true, 'Partners')">
                            <span aria-hidden="true">{{resourceBundle?.partnersTitle}}</span>
                            <img *ngIf="imageToggle !== 'openPartners'"
                                src="assets/images/blueprint/admin_redesign/arrow-up-small-black.svg" class="mb-1"
                                alt="up arrow" role="presentation">
                            <img *ngIf="imageToggle === 'openPartners'"
                                src="assets/images/blueprint/admin_redesign/arrow-down-small-black.svg" class="mb-1"
                                alt="down arrow" role="presentation">
                                <span class="sr-only" aria-live="polite">{{resourceBundle?.partnersTitle}}
                                    {{isPopoverListOpen ? 'expanded' : 'collapsed'}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" class="footer-sublinks"
                            (click)="navigateOnClick(resourceBundle.privacy.linkUrl, resourceBundle.privacy.tab)"
                            aria-label="Privacy">{{resourceBundle.privacy?.linkName}}</a>
                    </li>
                </ul>
            </div>
            <div class="copyright-details">
                © 1998 - {{currentYear}} Cox Communications, Inc.
            </div>
        </div>
    </div>
</div>

<ng-template #footerPopover>
    <ul class="messageList ul-style p-0 mb-2" role="menu">
        <ng-container *ngIf="popoverList?.length > 0">
            <li class="popover-list onFocusOutline" *ngFor="let list of popoverList" (click)="navigateOnClick(list.linkUrl, list.tab, list.linkName, list?.ringcentralUrl)"
            (keydown.enter)="navigateOnClick(list.linkUrl, list.tab, list.linkName, list?.ringcentralUrl)"
                role="menuitem" aria-label="{{list.linkName}}" tabindex="0">
                <span>{{list.linkName}}</span>
            </li>
        </ng-container>
        <ng-container *ngIf="popoverList?.length === 0 && commonTaskPopoverList?.length > 0">
            <li class="popover-list onFocusOutline" *ngFor="let list of commonTaskPopoverList"
            (click)="onClickofCommonTasksUrl(list.url, list.displayName,list.openInNewWindow)"
            (keydown.enter)="onClickofCommonTasksUrl(list.url, list.displayName,list.openInNewWindow)"
                role="menuitem" aria-label="{{list.displayName}}" tabindex="0">
                <span>{{list.displayName}}</span>
            </li>
        </ng-container>
    </ul>
</ng-template>