<div class="row" [ngClass]="{'voiceMessageFilter': hasRightSidedFilter}">
  <!-- Below implementation was introduced to support accessibility-->
  <!--First search box then filter popover-->
  <ng-container *ngIf="rows?.searchPreceedFilter">
    <ng-container [ngTemplateOutlet]="searchTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="filtetButton"></ng-container>
  </ng-container>

  <!--First filter popover then search box-->
  <ng-container *ngIf="!rows?.searchPreceedFilter">
    <ng-container [ngTemplateOutlet]="filtetButton"></ng-container>
    <ng-container [ngTemplateOutlet]="searchTemplate"></ng-container>
  </ng-container>

</div>


<ng-template #filtetButton>
  <div class="display-flex">
    <div id="filtericon" class="redesign-search-filter"
      [ngClass]="{'filteractive': filteredTmpArray?.length>0, 'voiceMessageFilter': hasRightSidedFilter}"
      *ngIf="rows?.filteredArray" (click)="onFilterClick('open')">
      <div attr.aria-label="Filter {{filterName}}" tabindex="0" role="button"
        (keyup)="filterFocus($event,'filterSelection')"> <img
          src="assets/images/blueprint/admin_redesign/filter_participant.svg" alt="filter records" role="presentation" class="margin-neg-top-0-52rem">
        <span class="display-inline-block pl-2">{{filterLabel}}</span>
      </div>
    </div>
    <div *ngIf="showFilter" class="redesign-filter__parent" id="filterpopover"
      [ngClass]="{'voiceMessageFilter': hasRightSidedFilter}">
      <ng-container *ngIf="!showFilterModal" [ngTemplateOutlet]="filter">
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #searchTemplate>
  <div class="redesign-parent__inputSearch col-lg  col-sm-7 col-xs-7"
    [ngClass]="(rows?.filteredArray?.length > 0? 'ml-2':'ml-0')">
    <label *ngIf="rows?.placeHolder !== 'Search'" class="sr-only" [attr.for]="searchId" aria-live="polite">Search by
    </label>
    <input type="text" autocomplete="off" class="redesign-inputSearch"
      [ngClass]="{'searchactive': searchString?.length>0, 'voiceMessageInput': hasRightSidedFilter}"
      (keyup)="search($event);filterFocus($event,'input')" [(ngModel)]="searchString"
      [attr.aria-label]="(searchString ?  'Search ' + searchString : '')" [attr.id]="searchId"
      [placeholder]="(rows?.placeHolder ? rows?.placeHolder : '')"
      [title]="(searchString ? searchString : '')">
    <img *ngIf="searchString?.length>0" src="assets/images/blueprint/close-black.svg" alt="close"
      class="redesign-parent__inputSearch__close" (click)="clearSearch()" (keyup.enter)="clearSearch()" aria-label="Clear Search Box" role="button"
      tabindex="0" aria-autocomplete="list" [ngClass]="{'voiceMessageInput': hasRightSidedFilter}">
  </div>
</ng-template>

<ng-template #filter>
  <div class="redesign-filter__container">
    <span class="redesign-filter__grey">
      <img alt="filter" src="assets/images/blueprint/admin_redesign/filter-icon-grey.svg">
    </span>
    <span class="redesign-filter__close" (click)="onFilterClick('close')">
      <a attr.aria-label="{{ filterName }} Close" class="cb-modal-close-btn" id="filterCloseBtn" role="button"
        href="javascript:void(0)" appModalfocus></a>
    </span>
    <div class="redesign-filter__data" [ngClass]="{'voiceMessageFilter': hasRightSidedFilter}">
      <ng-container *ngFor="let category of rows?.filteredArray">
        <div role="list" attr.aria-label="{{category?.categoryTitle}}">
          <label aria-hidden="true" class="bold mb-1 mt-3 display-block text-nowrap">
            {{category?.categoryTitle}} </label>
          <ng-container *ngFor="let options of category?.options">
            <div role="listitem">
              <input [attr.id]="options.optionValue" type="checkbox" tabindex="0"
                (change)="filtereData($event,options.searchValue,category?.categoryTitle, rows?.avoidTitleCase)" (keyup)="filterFocus($event,options.optionValue)"
                [value]="options.optionValue" [(ngModel)]="options.isChecked" class="mt-3 filter-checkbox"
                [attr.aria-label]="options.optionValue | textTransform:options?.optionValue | titlecase"
                role="checkbox">
              <label *ngIf="rows?.avoidTitleCase" [attr.for]="options.optionValue">{{options.optionValue}}</label>
              <label *ngIf="!rows?.avoidTitleCase" [attr.for]="options.optionValue">{{options.optionValue | textTransform:options?.optionValue |
                titlecase }}</label> 
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #modalFilter let-close="close" let-dismiss="dismiss">
  <div role="dialog" aria-modal="true" aria-describedby="modal-heading">
      <span class="sr-only" id="modal-heading">{{modalDetails.modalHeading}}
          {{modalDetails.headingLevel4}}</span>
      <div class="col-md-12 modalheader modal-header">
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-10 pl-0">
              <h4 class="modal-title " role="heading" aria-level="4">{{modalDetails.modalHeading}}</h4>
          </div>
          <div
              [ngClass]=" deviceService.isPageZoomed() ? 'row col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right pr-0 pl-2' : 'col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right pr-0'">
              <a title="Close" role="button" class="cb-modal-close-btn" href="javascript:void(0)"
                  (click)="closeFilterModal(close)">
                  <span class="sr-only">{{modalDetails?.close}}</span>
              </a>
          </div>
      </div>
  </div>
  <div class="modal-body col-md-12 robocallFilterModalBody">
      <div class="row pl-3 mt-2 mb-2">
          <span>{{modalDetails?.firstDropdownHeading}}</span>
      </div>
      <div class="row pl-3 pr-3">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 multiSelectParent display-flex">
              <div class="contentDiv filterContentDiv col-md-10 contentDivDimensions">
                  <span *ngIf="firstDropdownValue?.length > 0">{{firstDropdownValue?.length}} {{modalDetails?.selected}}</span>
                  <span *ngIf="firstDropdownValue?.length === 0">{{modalDetails?.select}}</span>
              </div>
              <div class="contentDiv2 col-md-2 contentDiv2Dimensions filterContentDiv">
                  <div class="outer-div">
                  </div>
              </div>
              <angular2-multiselect [tabIndex]="0" [focusable]="true" [data]="filterMultiSelectDropdownArray"
                  [settings]="filterMultiSelectDropdownSettings" data-dropup-auto="false"
                  class="searchPlaceholderSpacing selectpicker"
                  [ngClass]="{'outer-border':deviceService.isTablet()||deviceService.isMobile()}"
                  [(ngModel)]="firstDropdownValue" [ngModelOptions]="{standalone: true}" (ngModelChange)="modalFilterDirty = true;">
              </angular2-multiselect>
          </div>
      </div>
      <div class="row pl-3 mt-3 mb-2">
          <span>{{modalDetails?.secondDropdownHeading}}</span>
      </div>
      <div class="row ml-0 mr-0 mt-2 mb-3 wide100 treatmentDropdownContainer">
          <select name="filterTreatmentOptions" id="filterTreatmentOptions" [(ngModel)]="secondDropdownValue" (ngModelChange)="modalFilterDirty = true;" class="w-100">
              <option [ngValue]="null">{{modalDetails?.select}}</option>
              <option *ngFor="let o of modalDetails?.secondDropdownArray" [ngValue]="o">{{o}}</option>
          </select>
      </div>
      <div class="row">
        <hr class="hr position-absolute left-0-rem" />
        <ng-container *ngIf="deviceService.isMobile()">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-4 display-flex justify-content-center">
            <button class="button button-primary mt-2 mb-0"
              (click)="filtereData(null); closeFilterModalAfterApplyingFilter(close)"
              [disabled]="!modalFilterDirty">Apply</button>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-4 display-flex justify-content-center">
            <button class="button button-secondary mt-2 mb-0" (click)="closeFilterModal(close)">Cancel</button>
          </div>
        </ng-container>
        <ng-container *ngIf="!deviceService.isMobile()">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 display-flex justify-content-center">
            <a href="javascript:void(0)" class="linkUnderline margin-top-pos-1-2 mr-5" (click)="closeFilterModal(close)" role="button">Cancel<img 
              src="assets/images/blueprint/admin_redesign/arrow-right-blue.svg" alt="arrow next icon"
                  role="img" aria-hidden="true" class="width-20px margin-top-neg-5">
            </a>
            <button class="button button-primary mt-2 mb-0"
              (click)="filtereData(null); closeFilterModalAfterApplyingFilter(close)"
              [disabled]="!modalFilterDirty">Apply</button>
          </div>
        </ng-container>
      </div>
  </div>
</ng-template>