import { Injectable } from '@angular/core';

@Injectable()
export class StringUtilService {

    constructor() { }

    /**
    * returns formatted string (replacing placeholders with the respective element in array)
    *
    * @param message string literal with placeholders
    * @param params String array of placeholder values
    * @returns {string} formatted string
    */

    public formatByPlaceHolder(message: string, params: string[]) {
        return message?.replace(/{([0-9]+)}/g, (match, index) => params[index] === 'undefined' ? match : params[index]);
    }

}
