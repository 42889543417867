import { Injectable} from '@angular/core';
import { AppConfig } from '../../core/app.config';
import { Message } from './message.component';

@Injectable()
export class MessageService {
  constructor(private message: AppConfig) {}

  /** To show generic success message from messages.json */
  public showSuccessMessage(messageObj: Message, message?: string): Message {
    messageObj.show = true;
    messageObj.type = 'success';
    messageObj.message = message ? message : this.message.getMessage('successMessage');
    window.scrollTo(0, 0);
    return messageObj;
  }

  /** To show generic error message from messages.json */
  public showErrorMessage(messageObj: Message, message?: string): Message {
    messageObj.type = 'error';
    messageObj.show = true;
    messageObj.message = message ? message : this.message.getMessage('errorMessage');
    window.scroll(0, 0);
    return messageObj;
  }

  /** To show form generic error message from messages.json */
  public showFormGenericError(messageObj: Message): Message {
    messageObj.show = true;
    messageObj.type = 'error';
    messageObj.message = this.message.getMessage('formGenericMessage');
    window.scrollTo(0, 0);
    return messageObj;
  }

  /** To hide form generic error message */
  public hideFormGenericError(messageObj: Message): void {
    messageObj.show = false;
  }
}
