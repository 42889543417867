<div class="modal-content">
  <ng-container>
    <ng-container *ngIf="isMultiOutages">
      <ng-container *ngTemplateOutlet="futureOutageMultiAcc"></ng-container>
    </ng-container>
    <ng-container *ngIf="!isMultiOutages">
      <ng-container *ngTemplateOutlet="futureOutageSingleAcc"></ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-template #futureOutageSingleAcc>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 modalheader modal-header">
    <span class="h4 modal-title longHeader col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0">
      {{resourceBundle.futureOutageHeaderSingleAcc}}
    </span>
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-center">
      <a title="Close" class="cb-modal-close-btn pull-right" href="javascript:void(0)" role="button"
        (click)="close($event)"></a>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pb-2">
        {{resourceBundle.futureOutageDesc}}
      </div>
      <br>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" *ngIf="contentLoad; else loadSpinnerTemplate">
        <div class="col-lg-12 col-md-12 col-sm-12 pb-2">
          <span>{{resourceBundle.futureOutageAddressLabel}}</span>
          <span class="bold">
            {{serviceAddress}}
          </span>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 pb-2">
          {{resourceBundle.futureOutageETRLabel}}
          <span class="bold">
            {{dateTime}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <hr class="hr mb-0">
  <div class="col-lg-12 col-md-12 col-sm-12 col-12 py-2 pr-4" *ngIf="!isMultiOutages">
    <div class="col-md-5 col-sm-12 pull-right px-0">
      <button class="button pull-right" aria-label="Done" (click)="close($event)">{{resourceBundle.doneBtn}}</button>
    </div>
  </div>
</ng-template>


<ng-template #futureOutageMultiAcc>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 modalheader modal-header">
    <span class="h4 modal-title longHeader col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0" *ngIf="!showSingleAcc">
      {{resourceBundle.futureOutageHeaderMultiAcc}}
    </span>
    <span class="h4 modal-title longHeader col-lg-9 col-md-10 col-sm-10 col-xs-10 p-0" *ngIf="showSingleAcc">
      {{resourceBundle.futureOutageHeaderSingleAcc}}
    </span>
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-center">
      <a title="Close" class="cb-modal-close-btn pull-right" href="javascript:void(0)" role="button"
        (click)="close($event)" (keyup)="datatableAccessibility($event)"></a>
    </div>
  </div>
  <div class="modal-body" *ngIf="!showSingleAcc">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-2">
        {{resourceBundle.futureOutageDescMulti}}
      </div>
      <h5 class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 pb-1">{{resourceBundle.tableHeader}}</h5>
      <div class="col-xs-12 col-lg-12 col-md-12 col-sm-12" *ngIf="!isMobileDevice">
        <div *ngIf="contentLoad; else loadSpinnerTemplate">
          <ngx-datatable class="dataTable bootstrap fullscreen hidden-sm hidden-xs mb-4 hoverColor redesignTableHeader"
            comnAccessTable [noofRows]="outageRows.length" [tableName]="'Planned Outage'" role="table"
            [table]="futureOutage" (keyup)="activateRow($event)" id="futureOutage" #futureOutage [reorderable]="false"
            [columnMode]="'force'" [rows]="outageRows" [scrollbarV]="false"
            [ngClass]="{'scrollEnabled': totalCount > 10, 'scrollEffect': showGradient}" (scroll)="onScroll($event)"
            [rowHeight]="'auto'" [headerHeight]="'auto'">
            <ngx-datatable-column name="Account Alias" prop="accountAlias" [sortable]="true" [width]="140"
              [maxWidth]="140">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                {{value}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Service Address" prop="serviceAddress" [sortable]="true" [width]="165"
              [maxWidth]="165">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                <div class="row-text-overflow"
                  title="{{value}}">
                  {{value}}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date and Time" prop="dateTime" [sortable]="true" [width]="155" [maxWidth]="155">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                <div class="col-md-12 p-0 display-flex justify-content-spaceBtwn" title="{{value}}"
                  (click)="multiAccountModal(row)">
                  <span class="row-text-overflow col-md-10"> {{value}}</span>
                  <a class="showLink pull-right">
                    <img src="assets/images/blueprint/admin_redesign/arrow-right-white.svg"  alt ="arrow-right-white" class="margin-bottom-5px"
                      id="arrowImgQc" (click)="multiAccountModal(row)" tabindex="0" role="link">
                  </a>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="showSingleAcc">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pb-2">
        {{resourceBundle.futureOutageDesc}}
      </div>
      <br>
      <div class="col-lg-12 col-md-12 col-sm-12 pb-2">
        {{resourceBundle.futureOutageAddressLabel}}
        <span class="bold">
          {{serviceAddress}}
        </span>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 pb-2">
        {{resourceBundle.futureOutageETRLabel}}
        <span class="bold">
          {{dateTime}}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="isMobileDevice && !showSingleAcc" class="col-xs-12">
    <div *ngIf="contentLoad; else loadSpinnerTemplate">
      <ngx-datatable class="dataTable bootstrap fullscreen redesignTableHeader" id="futureOutageMobile"
        #futureOutageMobile [columnMode]="'force'" [reorderable]="false" [rows]="outageRows" [rowHeight]="'auto'"
        [headerHeight]="'auto'">
        <ngx-datatable-column name="Account Alias" prop="accountAlias" [sortable]="true" [width]="150" [maxWidth]="140">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Date and Time" prop="dateTime" [sortable]="true" [width]="180" [maxWidth]="160">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
            <a (click)="multiAccountModal(row)" class="row-text-overflow" title="{{value}}">
              {{value}}
            </a>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
  <hr class="hr mb-0">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 py-2" *ngIf="showSingleAcc"
    [ngClass]="{'d-flex justify-content-flexEnd pr-4': !isMobileDevice}">
    <div class="mt-4"
      [ngClass]="{'col-lg-6 col-md-6 col-sm-12 col-xs-12 text-right pr-0' : isMobileDevice, 'mr-4': !isMobileDevice}">
      <a href="javascript:void(0)" (click)="populateDatatable()">
        <span [ngClass]="{'margin-left-4-5em pull-left': isMobileDevice}">
           < </span>
            <span class="linkUnderline ml-2" [ngClass]="{'pull-left': isMobileDevice}"> {{resourceBundle.backLink}}</span>
      </a>
    </div>
    <div [ngClass]="{'col-lg-6 col-md-6 col-sm-12 col-xs-12 text-right pr-0' : isMobileDevice}">
      <button class="button" aria-label="Done" (click)="close($event)">{{resourceBundle.doneBtn}}</button>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-4 py-2" *ngIf="!showSingleAcc">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right text-right pr-0">
      <button class="button" aria-label="Done" (click)="close($event)">{{resourceBundle.doneBtn}}</button>
    </div>
  </div>
</ng-template>
<ng-template #loadSpinnerTemplate>
  <div class="col-md-12 p-0">
    <app-skeletonloader accordionName="planned Outage" accordionSkeleton="true" rowCount="2">
    </app-skeletonloader>
  </div>
</ng-template>