<div role="dialog" aria-modal="true" aria-describedby="modal-heading">
    <span class="sr-only" id="modal-heading">{{!declineModal ? resourceBundle.modalHeader : resourceBundle?.declineModalHeader}} {{resourceBundle.headingLevel4}}</span>
    <div class="col-md-12 modalheader modal-header">
        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-10 pl-0">
            <h4 class="modal-title">{{!declineModal ? resourceBundle.modalHeader : resourceBundle?.declineModalHeader}}</h4>
        </div>
        <div [ngClass]="magnifiedDateTimeView? 'row col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right pr-4 pl-2 justify-content-end' :
            'text-right pr-0'">
            <a title="Close" id="closeBtn" role="button" class="cb-modal-close-btn float-right" href="javascript:void(0)"
                (click)="close('Close click'); triggerTealium('close')" appModalfocus>
            </a>
        </div>
    </div>

    <div class="modal-body col-md-12 mt-2">
        <div class="col-lg-12 col-md-12 col-sm-12 pl-0 pr-0">
            <div *ngIf="!declineModal">
                <div class="mb-3">
                    {{resourceBundle.kariLawDesc1}}
                </div>
                <div class="mb-3">
                    {{resourceBundle.kariLawDesc2}}
                </div>
                <div class="mb-3">
                    {{resourceBundle.kariLawDesc3}}
                </div>
                <div>
                    {{resourceBundle.moreDetails}} <a href="javascript:void(0)" (click)="openMoreDetails()"
                        class="linkUnderline">{{resourceBundle.karisLawAndE911Notifications}}</a>.
                </div>
            </div>
            <div *ngIf="declineModal">
                {{resourceBundle.declineModalDesc}}
            </div>
        </div>
    </div>
    <ng-conatiner *ngIf="!declineModal; else showDeclineModal">
        <ng-container *ngIf="isMobileDevice; else desktopAndTabletView">
            <hr class="mb-0 margin-top-2em-imp gray-border-bottom-1">
            <div class="col-md-5 pl-4 pr-4 mb-2 mt-3">
                <button class="button" (click)="navigateToE911Notifications()">{{resourceBundle.continue}}</button>
                <button class="button button-secondary" (click)="onDecline()">{{resourceBundle.decline}}</button>
            </div>
        </ng-container>
    </ng-conatiner>
    <ng-template #showDeclineModal>
        <ng-container *ngIf="isMobileDevice; else declineModaldesktopAndTabletView">
            <hr class="mb-0 margin-top-2em-imp gray-border-bottom-1">
            <div class="col-md-5 pl-4 pr-4 mb-2 mt-3">
                <button class="button" (click)="close(false);">{{resourceBundle.done}}</button>
                <button class="button button-secondary"
                    (click)="navigateToE911Notifications()">{{resourceBundle.manageE911Page}}</button>
            </div>
        </ng-container>
    </ng-template>
</div>
<ng-template #desktopAndTabletView>
    <div class="col-md-12 col-lg-12 display-flex justify-content-center">
        <button (click)="onDecline()"
            class="button button-secondary mr-3 width-11 pl-3 pr-3">{{resourceBundle.decline}}</button>
        <button class="button button-primary ml-3 mr-0 width-11 pl-3 pr-3"
            (click)="navigateToE911Notifications()">{{resourceBundle.continue}}</button>
    </div>
</ng-template>

<ng-template #declineModaldesktopAndTabletView>
    <div class="col-md-12 col-lg-12 display-flex justify-content-center">
        <button (click)="navigateToE911Notifications()"
            class="button button-secondary mr-3 width-11 pl-3 pr-3">{{resourceBundle.manageE911Page}}</button>
        <button class="button button-primary ml-3 mr-0 width-11 pl-3 pr-3"
            (click)="close(false);">{{resourceBundle.done}}</button>
    </div>
</ng-template>