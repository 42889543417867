import { NgModule } from '@angular/core';
import { Amplify } from 'aws-amplify';
import config from 'assets/config/graphql.json';
@NgModule()
export class AwsModule {
    constructor() {
        this.configure();
    }

    private configure() {
        Amplify.configure({
            'aws_appsync_graphqlEndpoint': this.getEnvironment(),
        });
    }

    private getEnvironment() {
        let URI;
        for (const env of config.environments) {
            if (window.location.hostname.includes(env)) {
                URI = config.graphql[env]['digitalProfile_graphqlEndpoint'];
                break;
            }
            if (window.location.hostname.includes('uat')) {
                URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['uat']['subscriptionEndpoint'] :
                    config.graphql['uat']['digitalProfile_graphqlEndpoint'];
                break;
            }
            if (window.location.hostname.includes('ppe')) {
                URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['ppe']['subscriptionEndpoint'] :
                    config.graphql['ppe']['digitalProfile_graphqlEndpoint'];
                break;
            }
            URI = window.location.href.indexOf('myaccount') > -1 ? config.graphql['prod']['subscriptionEndpoint'] :
                config.graphql['prod']['digitalProfile_graphqlEndpoint'];
        }

        return URI;
    }
}
